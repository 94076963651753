import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
});

@withNamespaces()
@withStyles(styles)
export default class PracticalFieldList extends Component {
  static propTypes = {
    practicalFieldList: PropTypes.array,
    questionTypeList: PropTypes.array,
    editPracticalField: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderQuestionType(questionTypeId) {
    const { questionTypeList } = this.props;

    return questionTypeList.find((questionType) => questionType.id === questionTypeId).name;
  }

  renderPracticalFieldList = (practicalFieldList) => {
    const { editPracticalField } = this.props;

    return practicalFieldList.map((practicalField) => {
      return (
        <TableRow key={`practicalField-${practicalField.id}`}>
          <TableCell>{practicalField.name}</TableCell>
          <TableCell>{this.renderQuestionType(practicalField.questionType.id)}</TableCell>
          <TableCell numeric>
            <Checkbox
              color="primary"
              checked={practicalField.isActive}
              onChange={() => editPracticalField(practicalField)}
              value="True"
            />
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes, practicalFieldList, questionTypeList, t } = this.props;

    if (_isEmpty(practicalFieldList) || _isEmpty(questionTypeList)) {
      return null;
    }

    return (
      <div className="pl-sm-5">
        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table:name')}</TableCell>
                  <TableCell>{t('table:fieldType')}</TableCell>
                  <TableCell numeric>{t('table:isActive')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderPracticalFieldList(practicalFieldList)}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}
