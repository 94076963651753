import { Link } from 'react-router-dom';
import { MODULES } from '../../../../constants/modules';
import Menu from './Menu';
import { PERMISSIONS } from '../../../../constants/permissions';
import React from 'react';
import { renderModule } from '../../../decorators/modules';
import { renderPermission } from '../../../decorators/permissions';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class RiskManagementMenu extends Menu {
  renderHeader() {
    const { t } = this.props;

    return (
      <Link to="/sms" className="nav-link-item" onClick={() => this.closeMenu()}>
        {t('navigation:sms')}
      </Link>
    );
  }

  renderArrow() {
    return null;
  }

  @renderModule(MODULES.fleet)
  @renderPermission(PERMISSIONS.viewFleets)
  render() {
    return this.renderMenu();
  }
}
