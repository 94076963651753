import '../styles.scss';

import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../../common/components/forms/Form';
import Icon from '@material-ui/core/Icon';
import Loader from '../../../../../common/components/Loader';
import PropTypes from 'prop-types';
import TechnicalFaultReporTable from './TechnicalFaultReportTable';
import TechnicalFaultReportGraph from './TechnicalFaultReportGraph';
import { connect } from 'react-redux';
import { fetchReports } from '../../../../actions/reports';
import { getDateFormat } from '../../../../utils/time';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const STYLES = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  open: false,
  searchSettings: {
    sortAsc: true,
    search: '',
    userId: '',
  },
  filter: {
    fromDate: moment().subtract(1, 'months'),
    toDate: moment(),
  },
};

const mapStateToProps = (state) => {
  return {
    reportList: state.reports.reportList,
    reportRequest: state.reports.reportRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchReports: (sortData) => dispatch(fetchReports(sortData, true)),
  };
}

@withNamespaces()
@withStyles(STYLES)
@connect(mapStateToProps, mapDispatchToProps)
export default class TechnicalFaultReportList extends Component {
  static propTypes = {
    userId: PropTypes.number,
    reportList: PropTypes.array,
    reportRequest: PropTypes.bool.isRequired,
    fetchReports: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.onSave();
  }

  handleSearch = (search) => {
    const { sortAsc, userId } = this.state.searchSettings;
    const searchData = {
      sortAsc,
      search,
      userId,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchReports(searchData);
  };

  handleDatePickerChange = (item, date) => {
    const filter = { ...this.state.filter };
    filter[item] = date;

    this.setState({ filter });
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  onSave = () => {
    const { fromDate, toDate } = this.state.filter;

    this.props.fetchReports({
      fromDate: fromDate.format(getDateFormat()),
      toDate: toDate.format(getDateFormat()),
    });

    this.closeDialog();
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  render() {
    const { classes, reportList, reportRequest, t } = this.props;
    const { filter, searchSettings, open } = this.state;
    const { fromDate, toDate } = filter;

    if (reportRequest) {
      return <Loader />;
    }

    return (
      <>
        <div className="row justify-content-lg-between justify-content-end align-items-center reports">
          <div className="col-lg-8 col-sm-12 section-title">
            <header>
              <span>
                <Icon color="primary">description</Icon>
                <h1>Analysis</h1>
              </span>
              <p>{t('report:description')}</p>
            </header>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-10">
            <Button
              onClick={this.openDialog}
              className="float-right"
              variant="outlined"
            >
              <Icon color="primary">tune</Icon>
              &nbsp;{t('button:Filter')}
            </Button>
            <TechnicalFaultReportGraph reportList={reportList} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TechnicalFaultReporTable
              reportList={reportList}
              sortAsc={searchSettings.sortAsc}
              handleSearch={this.handleSearch}
              search={searchSettings.search}
            />
          </div>
        </div>
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={open}
          onClose={this.closeDialog}
          maxWidth="md"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle>{t('report:Filter analysis data')}</DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row">
                <div className="col-4">
                  <DateTimePicker
                    fullWidth
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={(date) =>
                      this.handleDatePickerChange('fromDate', date)
                    }
                    dateFormat={getDateFormat()}
                    value={fromDate}
                    name="fromDate"
                    label={t('input:From date')}
                  />
                </div>
                <div className="col-4">
                  <DateTimePicker
                    fullWidth
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={(date) =>
                      this.handleDatePickerChange('toDate', date)
                    }
                    dateFormat={getDateFormat()}
                    value={toDate}
                    name="toDate"
                    label={t('input:To date')}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.closeDialog}>
                {t('Close')}
              </Button>
              <Button color="primary" onClick={this.onSave}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    );
  }
}
