import { URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';

export const FETCH_WAREHOUSE_CONSUMABLES_REQUEST =
  'FETCH_WAREHOUSE_CONSUMABLES_REQUEST';
export const FETCH_WAREHOUSE_CONSUMABLES_SUCCESS =
  'FETCH_WAREHOUSE_CONSUMABLES_SUCCESS';
export const ADD_WAREHOUSE_CONSUMABLE = 'ADD_WAREHOUSE_CONSUMABLE';
export const UPDATE_WAREHOUSE_CONSUMABLE = 'UPDATE_WAREHOUSE_CONSUMABLE';
export const REMOVE_WAREHOUSE_CONSUMABLE = 'REMOVE_WAREHOUSE_CONSUMABLE';
export const CLEAN_WAREHOUSE_DATA = 'CLEAN_WAREHOUSE_DATA';

export function fetchWarehouseConsumablesRequest() {
  return {
    type: FETCH_WAREHOUSE_CONSUMABLES_REQUEST,
  };
}

export function fetchWarehouseConsumablesSuccess(consumables) {
  return {
    type: FETCH_WAREHOUSE_CONSUMABLES_SUCCESS,
    payload: {
      consumables,
    },
  };
}

export function addWarehouseConsumableSuccess(consumable) {
  return {
    type: ADD_WAREHOUSE_CONSUMABLE,
    payload: {
      consumable,
    },
  };
}

export function updateWarehouseConsumableSuccess(consumable) {
  return {
    type: UPDATE_WAREHOUSE_CONSUMABLE,
    payload: {
      consumable,
    },
  };
}

export function removeWarehouseConsumableSuccess(id) {
  return {
    type: REMOVE_WAREHOUSE_CONSUMABLE,
    payload: {
      id,
    },
  };
}

export function cleanWarehouse() {
  return {
    type: CLEAN_WAREHOUSE_DATA,
  };
}

export function fetchWarehouseConsumables(warehouseId) {
  return (dispatch) => {
    dispatch(fetchWarehouseConsumablesRequest());

    return axios
      .get(URLS.warehouseConsumables.replace(':id', warehouseId))
      .then(({ data }) =>
        dispatch(fetchWarehouseConsumablesSuccess(data.results)),
      )
      .catch((error) => {
        dispatch(
          addError(
            `Error during warehouse consumables fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function addWarehouseConsumable(warehouseId, data) {
  return (dispatch) => {
    return axios
      .post(URLS.warehouseConsumables.replace(':id', warehouseId), data)
      .then(({ data }) => dispatch(addWarehouseConsumableSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during add warehouse consumable ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function updateWarehouseConsumable(warehouseId, id, data) {
  return (dispatch) => {
    return axios
      .patch(
        URLS.warehouseConsumables.replace(':id', warehouseId) + id + '/',
        data,
      )
      .then(({ data }) => dispatch(updateWarehouseConsumableSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during update warehouse consumable ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function removeWarehouseConsumable(warehouseId, id) {
  return (dispatch) => {
    return axios
      .delete(URLS.warehouseConsumables.replace(':id', warehouseId) + id + '/')
      .then(() => dispatch(removeWarehouseConsumableSuccess(id)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during removing warehouse consumable ${error.message}`,
            error,
          ),
        );
      });
  };
}
