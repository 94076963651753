import {
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_REQUEST,
  FETCH_SETTINGS_FAILURE,
  EDIT_SETTING_REQUEST,
  EDIT_SETTING,
} from '../actions/settings';

const INITIAL_STATE = {
  settings: [],
  settingsRequest: false,
  settingsEditRequest: false,
};

function fetchSettingsSuccess(state, payload) {
  return {
    ...state,
    settings: payload.settings,
    settingsRequest: false,
  };
}

function fetchSettingsRequest(state) {
  return {
    ...state,
    settingsRequest: true,
  };
}

function fetchSettingsFailure(state) {
  return {
    ...state,
    settingsRequest: false,
  };
}

function editSettingRequest(state) {
  return {
    ...state,
    settingsEditRequest: true,
  };
}

function editSetting(state, payload) {
  return {
    ...state,
    settingsEditRequest: false,
    settings: state.settings.map((setting) => {
      if (setting.id === payload.setting.id) {
        return payload.setting;
      }

      return setting;
    })
  };
}


const actions = {
  [FETCH_SETTINGS_SUCCESS]: fetchSettingsSuccess,
  [FETCH_SETTINGS_REQUEST]: fetchSettingsRequest,
  [FETCH_SETTINGS_FAILURE]: fetchSettingsFailure,
  [EDIT_SETTING_REQUEST]: editSettingRequest,
  [EDIT_SETTING]: editSetting,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
