import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import { routerMiddleware } from 'react-router-redux';

import rootReducer from '../reducers';
import history from './history';

export default ((history) => {
  const router = routerMiddleware(history);

  const enhancer = compose(
    applyMiddleware(thunk, multi, router)
  );

  const stateToRestore = JSON.parse(window.localStorage.getItem('savedState')) || {};

  // Create Store
  const store = createStore(rootReducer, stateToRestore, enhancer);

  return store;
})(history);


