import '../styles.scss';

import React, { Component } from 'react';
import {
  addLicense,
  addLicensePlannedAction,
  deactivateLicense,
  editLicense,
  removeLicense,
} from '../../../actions/licenses';
import {
  attachLicenseFile,
  removeLicenseFile,
} from '../../../actions/fileUpload';
import {
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import LicenseList from './LicenseList';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { getDateFormat } from '../../../utils/time';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  id: null,
  type: '',
  stateOfIssue: '',
  number: '',
  expiryDate: null,
  isFormValid: false,
};

const mapStateToProps = (state) => {
  return {
    licenseList: state.licenses.licenseList || [],
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addLicense: (license) => dispatch(addLicense(license)),
    addLicensePlannedAction: (license) =>
      dispatch(addLicensePlannedAction(license)),
    removeLicense: (license) => dispatch(removeLicense(license)),
    editLicense: (license) => dispatch(editLicense(license)),
    deactivateLicense: (license) => dispatch(deactivateLicense(license)),
    attachLicenseFile: (file, id, userId) =>
      dispatch(attachLicenseFile(file, id, userId)),
    removeLicenseFile: (fileId, id) => dispatch(removeLicenseFile(fileId, id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UserLicenses extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    licenseList: PropTypes.array,
    editMode: PropTypes.bool,
    addLicense: PropTypes.func.isRequired,
    addLicensePlannedAction: PropTypes.func.isRequired,
    removeLicense: PropTypes.func.isRequired,
    editLicense: PropTypes.func.isRequired,
    deactivateLicense: PropTypes.func.isRequired,
    attachLicenseFile: PropTypes.func.isRequired,
    removeLicenseFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    documentTypeList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  onChange = (formData) => {
    this.formData = formData;
    _each(formData, (value, key) => {
      this.setState({
        [key]: value.value,
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid && this.formData) {
        this.state.addOpen ? this.addLicense() : this.editLicense();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (license) => {
    this.setState({
      editOpen: true,
      id: license.id,
      number: license.number,
      stateOfIssue: license.stateOfIssue,
      type: license.type,
      expiryDate: license.expiryDate,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addLicense = () => {
    const { type, stateOfIssue, number, expiryDate } = this.state;
    const { userId } = this.props;
    const newLicense = {
      type,
      number,
      stateOfIssue,
      userId,
      expiryDate: reformatPickerDateToDB(expiryDate),
    };

    this.props.addLicense(newLicense);
    this.handleDialogClose();
  };

  removeLicense = (license) => {
    this.props.removeLicense(license);
  };

  editLicense = () => {
    const { type, stateOfIssue, id, number, expiryDate } = this.state;
    const newLicense = {
      id,
      type,
      number,
      stateOfIssue,
      expiryDate: reformatPickerDateToDB(expiryDate),
    };

    this.props.editLicense(newLicense);
    this.handleDialogClose();
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDatePickerChange = (date) => {
    this.setState({
      expiryDate: date,
    });
  };

  dialogContent = (classes, title, t) => {
    const { type, stateOfIssue, number, expiryDate } = this.state;

    return (
      <div>
        <DialogTitle id="add-license-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              {this.renderSelect('type', 'type', type, this.handleSelectChange)}
            </div>
            <div className="col-md-6 col-12">
              <MaterialInput
                margin="dense"
                name="number"
                label={t('input:number')}
                validators={[new validators.IsRequired(t)]}
                defaultValue={number}
                fullWidth
              />
            </div>
            <div className="col-md-6 col-12">
              <MaterialInput
                margin="dense"
                name="stateOfIssue"
                label={t('input:stateOfIssue')}
                validators={[new validators.IsRequired(t)]}
                defaultValue={stateOfIssue}
                fullWidth
              />
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                acceptEmptyInput
                value={reformatPickerDateFromDB(expiryDate)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange}
                dateFormat={getDateFormat()}
                name="expiryDate"
                label={t('input:expiryDate')}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
      </div>
    );
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  attachLicenseFile = (file) => (id) => {
    const { attachLicenseFile, userId } = this.props;

    attachLicenseFile(file, id, userId);
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  renderSelect = (inputName, inputLabel, value, changeFunction) => {
    const { documentTypeList } = this.props;

    return (
      <AutoSuggestion
        label={inputName}
        inputName={inputLabel}
        defaultValue={value ? value : ''}
        handleChange={changeFunction}
        typeList={documentTypeList}
        fullWidth
      />
    );
  };

  render() {
    const {
      licenseList,
      editMode,
      classes,
      removeLicenseFile,
      addSimpleError,
      onElementDelete,
      addLicensePlannedAction,
      t,
      deactivateLicense,
    } = this.props;
    const { addOpen, editOpen } = this.state;

    return (
      <div className="col-12 user">
        <LicenseList
          licenseList={licenseList}
          addLicensePlannedAction={addLicensePlannedAction}
          removeLicense={(license) =>
            onElementDelete(() => this.removeLicense(license))
          }
          handleAddOpen={this.handleAddOpen}
          editMode={editMode}
          handleEditOpen={this.handleEditOpen}
          attachLicenseFile={this.attachLicenseFile}
          removeLicenseFile={(fileId, id) =>
            onElementDelete(() => removeLicenseFile(fileId, id))
          }
          addSimpleError={addSimpleError}
          deactivateLicense={deactivateLicense}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={addOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="add-license-dialog-title"
          >
            {this.dialogContent(classes, t('user:addLicense'), t)}
            {this.dialogActions(t)}
          </Dialog>
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={editOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="edit-license-dialog-title"
          >
            {this.dialogContent(classes, t('user:editLicense'), t)}
            {this.dialogActions(t)}
          </Dialog>
        </Form>
      </div>
    );
  }
}
