import React, { Component } from 'react';
import { sortStatusWeight, sortString } from '../../../../utils/sort';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import Table from '../../../../common/components/Table';
import { downloadAsExcel } from '../../../../common/utils/export';
import { withNamespaces } from 'react-i18next';

const HEADER = [
  {
    prop: 'name',
    sort: true,
  },
  {
    prop: 'partNumber',
    sort: true,
  },
  {
    prop: 'serialNumber',
    sort: true,
  },
  {
    prop: 'hoursToService',
    sort: true,
  },
  {
    prop: 'cyclesToService',
    sort: true,
  },
  {
    prop: 'serviceDate',
    sort: true,
  },
  {
    prop: 'eolDate',
    sort: true,
  },
];

@withNamespaces()
export default class StatusReportList extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    statusReportList: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
    },
  };

  exportAsExcel(statusReportList) {
    const { fleet, t } = this.props;
    const { name, evn } = fleet.vehicle;

    const data = statusReportList.map((report) => {
      const {
        name,
        partNumber,
        serialNumber,
        hoursToServiceData,
        cyclesToServiceData,
        serviceDateData,
        eolDateData,
      } = report;

      return {
        name,
        partNumber,
        serialNumber,
        hoursToService: hoursToServiceData,
        cyclesToService: cyclesToServiceData,
        serviceDate: serviceDateData,
        eolDate: eolDateData,
      };
    });

    const header = {};

    HEADER.forEach((h) => {
      header[h.prop] = t(`table:${h.prop}`);
    });

    downloadAsExcel(
      `qrosscheck-fleet-${name}-${evn}-status-report`,
      header,
      data,
    );
  }

  handleSort = (columnName) => {
    const { sortAsc } = this.state.searchSettings;

    this.setState({
      searchSettings: {
        sortAsc: !sortAsc,
        columnToSort: columnName,
      },
    });
  };

  sort(list, column, sortAsc) {
    switch (column) {
      case 'status':
        list.sort((a, b) => {
          return sortAsc
            ? sortStatusWeight(a.statusData, b.statusData)
            : sortStatusWeight(b.statusData, a.statusData);
        });

        break;
      case 'name':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.name, b.name)
            : sortString(b.name, a.name);
        });

        break;
      case 'partNumber':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.partNumber, b.partNumber)
            : sortString(b.partNumber, a.partNumber);
        });

        break;
      case 'serialNumber':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.serialNumber, b.serialNumber)
            : sortString(b.serialNumber, a.serialNumber);
        });

        break;
      case 'hoursToService':
        list.sort((a, b) => {
          if (!a.hoursToService) {
            return sortAsc ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
          } else if (!b.hoursToService) {
            return sortAsc ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
          }

          return sortAsc
            ? a.hoursToServiceData - b.hoursToServiceData
            : b.hoursToServiceData - a.hoursToServiceData;
        });

        break;
      case 'cyclesToService':
        list.sort((a, b) => {
          if (!a.cyclesToService) {
            return sortAsc ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
          } else if (!b.cyclesToService) {
            return sortAsc ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
          }

          return sortAsc
            ? a.cyclesToServiceData - b.cyclesToServiceData
            : b.cyclesToServiceData - a.cyclesToServiceData;
        });

        break;
      case 'serviceDate':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.serviceDateData, b.serviceDateData)
            : sortString(b.serviceDateData, a.serviceDateData);
        });

        break;
      case 'eolDate':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.eolDateData, b.eolDateData)
            : sortString(b.eolDateData, a.eolDateData);
        });

        break;
    }
  }

  render() {
    const { t } = this.props;
    const { columnToSort, sortAsc } = this.state.searchSettings;

    const sortedStatusReport = [...this.props.statusReportList];

    this.sort(sortedStatusReport, columnToSort, sortAsc);

    return (
      <div className="row">
        <div className="col-12 no-gutters">
          <Table
            data={sortedStatusReport}
            header={HEADER}
            handleSort={this.handleSort}
            hideSearch
            sortAsc={sortAsc}
            columnToSort={columnToSort}
            handleSearch={() => {}}
          />
        </div>
        <div className="col-12">
          <Button
            color="primary"
            onClick={() => this.exportAsExcel(sortedStatusReport)}
          >
            <Icon color="primary">download</Icon>
            {t('folder:Export as excel')}
          </Button>
        </div>
      </div>
    );
  }
}
