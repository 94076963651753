import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
});

@withNamespaces()
@withStyles(styles)
export default class TypeList extends Component {
  static propTypes = {
    userId: PropTypes.string,
    typeList: PropTypes.array,
    removeType: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderTypeList = (typeList) => {
    const { removeType, onElementDelete } = this.props;

    return typeList.map((type) => {
      return (
        <TableRow key={`type-${type.id}`}>
          <TableCell>{type.name}</TableCell>
          <TableCell numeric>
            <IconButton onClick={() => onElementDelete(() => removeType(type))}><Icon color="primary">delete</Icon></IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { classes, handleAddOpen, typeList, userId, t } = this.props;

    if (_isEmpty(typeList)) {
      return (
        <div className={userId ? 'pl-sm-3' : 'pl-sm-5'}>
          <Button color="primary" onClick={handleAddOpen}>{t('settings:addTypeButton')}</Button>
        </div>
      );
    }

    return (
      <div className={userId ? 'pl-sm-3' : 'pl-sm-5'}>
        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table:name')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderTypeList(typeList)}
              </TableBody>
            </Table>
          </div>
        </div>

        <Button color="primary" onClick={handleAddOpen}>{t('settings:addTypeButton')}</Button>
      </div>
    );
  }
}
