import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import LanguageSelect from '../../../common/components/LanguageSelect';
import { Link } from 'react-router-dom';
import Logout from '../Authentication/Logout';
import { MODULES } from '../../../constants/modules';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import _isEmpty from 'lodash/isEmpty';
import companyLogo from '../../../images/qcLogo.png';
import { connect } from 'react-redux';
import { fetchSchoolLogo } from '../../actions/fileUpload';
import { renderModule } from '../../decorators/modules';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    file: state.schoolLogo.file,
    fileRequest: state.schoolLogo.fileRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchSchoolLogo: (id) => dispatch(fetchSchoolLogo(id)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class Navigation extends Component {
  static propTypes = {
    schoolLogoId: PropTypes.number.isRequired,
    fetchSchoolLogo: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    fileRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const { schoolLogoId, fetchSchoolLogo } = this.props;

    fetchSchoolLogo(schoolLogoId);
  }

  @renderModule(MODULES.training)
  renderMyCourses(t) {
    return (
      <Link to="/my_education" className="nav-link">
        <Tooltip title={t('navigation:myCourses')} placement="top">
          <Icon color="primary" className="mx-1">
            content_paste
          </Icon>
        </Tooltip>
        <div className="nav-text">{t('navigation:myCourses')}</div>
      </Link>
    );
  }

  @renderModule(MODULES.exams)
  renderMyExams(t) {
    return (
      <Link to="/my_exams" className="nav-link">
        <Tooltip title={t('navigation:myExams')} placement="top">
          <Icon color="primary" className="mx-1">
            playlist_add_check
          </Icon>
        </Tooltip>
        <div className="nav-text">{t('navigation:myExams')}</div>
      </Link>
    );
  }

  @renderModule(MODULES.logbook)
  renderMyLogbook(t) {
    return (
      <Link to="/my_logbook" className="nav-link">
        <Tooltip title={t('navigation:myLogbook')} placement="top">
          <Icon color="primary" className="mx-1">
            directions_railway
          </Icon>
        </Tooltip>
        <div className="nav-text">{t('navigation:myLogbook')}</div>
      </Link>
    );
  }

  @renderModule(MODULES.report)
  renderMyReports(t) {
    return (
      <Link to="/my_reports" className="nav-link">
        <Tooltip title={t('navigation:myReports')} placement="top">
          <Icon color="primary" className="mx-1">
            description
          </Icon>
        </Tooltip>
        <div className="nav-text">{t('navigation:myReports')}</div>
      </Link>
    );
  }

  @renderModule(MODULES.library)
  renderLibrary(t) {
    return (
      <Link to="/library" className="nav-link">
        <Tooltip title={t('navigation:libraryMenu')} placement="top">
          <Icon color="primary" className="mx-1">
            book
          </Icon>
        </Tooltip>
        <div className="nav-text">{t('navigation:libraryMenu')}</div>
      </Link>
    );
  }

  @renderModule(MODULES.booking)
  renderBooking(t) {
    return (
      <Link to="/booking" className="nav-link">
        <Tooltip title={t('navigation:bookingMenu')} placement="top">
          <Icon color="primary" className="mx-1">
            event
          </Icon>
        </Tooltip>
        <div className="nav-text">{t('navigation:bookingMenu')}</div>
      </Link>
    );
  }

  renderMyProfile(t) {
    return (
      <Link to="/my_profile" className="nav-link">
        <Tooltip title={t('navigation:myProfile')} placement="top">
          <Icon color="primary" className="mx-1">
            person
          </Icon>
        </Tooltip>
        <div className="nav-text">{t('navigation:myProfile')}</div>
      </Link>
    );
  }

  renderFleet(t) {
    return (
      <Link to="/fleet" className="nav-link">
        <Tooltip title={t('navigation:fleetLink')} placement="top">
          <Icon color="primary" className="mx-1">
            train
          </Icon>
        </Tooltip>
        <div className="nav-text">{t('navigation:fleet')}</div>
      </Link>
    );
  }

  renderParts(t) {
    return (
      <Link to="/parts" className="nav-link">
        <Tooltip title={t('navigation:partsLink')} placement="top">
          <Icon color="primary" className="mx-1">
            settings_suggest
          </Icon>
        </Tooltip>
        <div className="nav-text">{t('navigation:parts')}</div>
      </Link>
    );
  }

  getSchoolLogo = () => {
    const { file } = this.props;

    return file ? (_isEmpty(file.path) ? companyLogo : file.path) : companyLogo;
  };

  render() {
    const { t } = this.props;

    return (
      <div className="nav-wrapper">
        <div>
          <Link to="/">
            <img className="nav-img" src={this.getSchoolLogo()} />
          </Link>
        </div>
        <nav>
          {this.renderLibrary(t)}
          {this.renderBooking(t)}
          {this.renderFleet(t)}
          {this.renderMyReports(t)}
          {this.renderMyLogbook(t)}
          {this.renderMyProfile(t)}
          <LanguageSelect />
          <Logout />
        </nav>
      </div>
    );
  }
}
