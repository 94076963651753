import axios from 'axios';
import qs from 'qs';
import history from '../store/history';
import _isEmpty from 'lodash/isEmpty';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_COURSES_REQUEST = 'FETCH_COURSES_REQUEST';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';
export const FETCH_COURSE_REQUEST = 'FETCH_COURSE_REQUEST';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const FETCH_COURSE_FAILURE = 'FETCH_COURSE_FAILURE';
export const ADD_COURSE = 'ADD_COURSE';
export const EDIT_COURSE = 'EDIT_COURSE';

export function fetchCoursesRequest() {
  return {
    type: FETCH_COURSES_REQUEST
  };
}

export function fetchCoursesSuccess(courses) {
  return {
    type: FETCH_COURSES_SUCCESS,
    payload: {
      courses
    }
  };
}

export function fetchCoursesFailure() {
  return {
    type: FETCH_COURSES_FAILURE
  };
}

export function fetchCourseRequest() {
  return {
    type: FETCH_COURSE_REQUEST
  };
}

export function fetchCourseSuccess(course) {
  return {
    type: FETCH_COURSE_SUCCESS,
    payload: {
      course
    }
  };
}

export function fetchCourseFailure() {
  return {
    type: FETCH_COURSE_FAILURE
  };
}

export function addCourseSuccess(course) {
  return {
    type: ADD_COURSE,
    payload: {
      course
    }
  };
}

export function editCourseSuccess(course) {
  return {
    type: EDIT_COURSE,
    payload: {
      course
    }
  };
}

export function removeCourseSuccess(course) {
  return {
    type: EDIT_COURSE,
    payload: {
      course
    }
  };
}

export function fetchCourses(sortingData) {
  return (dispatch) => {
    dispatch(fetchCoursesRequest());

    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData) {
      queryParameters.ordering = `${(sortingData.sortAsc ? '' : '-')}name`;
    }

    queryParameters = qs.stringify(queryParameters);
    queryParameters = _isEmpty(queryParameters) ? '' : `?${queryParameters}`;

    return axios.get(urlJoin(URLS.courses, queryParameters))
      .then(({ data }) => dispatch(fetchCoursesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchCoursesFailure());
        dispatch(addError(`Error during courses fetching ${error.message}`, error));
      });
  };
}

export function addCourse(course) {
  return (dispatch) => {
    return axios.post(URLS.courses, course)
      .then(({ data }) => {
        dispatch(addCourseSuccess(data));
        history.push('/course_admin');
      })
      .catch((error) => {
        dispatch(addError(`Error during course adding ${error.message}`, error));
      });
  };
}

export function editCourse(course) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.courses, course.id), course)
      .then(({ data }) => {
        dispatch(editCourseSuccess(data));
        history.push('/course_admin');
      })
      .catch((error) => {
        dispatch(addError(`Error during course editing ${error.message}`, error));
      });
  };
}

export function fetchCourse(courseId) {
  return (dispatch) => {
    dispatch(fetchCourseRequest());

    return axios.get(urlJoin(URLS.courses, courseId))
      .then(({ data }) => {
        dispatch(fetchCourseSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchCourseFailure());
        dispatch(addError(`Error during course fetching ${error.message}`));
      });
  };
}

export function removeCourse(course) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.manageCourses, course.id), { isActive: false })
      .then(({ data }) => dispatch(removeCourseSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during course removing ${error.message}`, error));
      });
  };
}
