import React, { Component } from 'react';
import { getMinutes, reformatTime } from '../../../utils/time';

import Form from '../../../../common/components/forms/Form';
import FormControl from '@material-ui/core/FormControl';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { ZERO_TIME } from '../../../../constants';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  input: {
    fontSize: '2rem',
  },
  formControl: {
    width: '100%',
  },
  timeInputWidth: {
    width: 205,
  },
});

@withNamespaces()
@withStyles(styles)
export default class BasicCourseInfo extends Component {
  static propTypes = {
    basicInfo: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    estimatedTime: PropTypes.string.isRequired,
    registerComponentForm: PropTypes.func.isRequired,
    formValidated: PropTypes.func.isRequired,
    accumulatedTheoretical: PropTypes.string,
    accumulatedPractical: PropTypes.string,
    assignedCourse: PropTypes.bool,
    preview: PropTypes.bool,
    t: PropTypes.func.isRequired,
    studentFullName: PropTypes.string,
  };

  state = {
    maxTime: _get(this.props.basicInfo, 'maxTime', ZERO_TIME),
    minTime: _get(this.props.basicInfo, 'minTime', ZERO_TIME),
    name: _get(this.props.basicInfo, 'name', ''),
    description: _get(this.props.basicInfo, 'description', ''),
  };

  componentWillMount() {
    this.formData = {};
  }

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      if (key === 'minTime' || key === 'maxTime') {
        this.handleTimeInputChange(key, value);
      } else {
        this.setState({
          [key]: value,
        });
      }
    });
  };

  mapState() {
    const { maxTime, minTime, name, description } = this.state;

    const formChanges = {
      maxTime,
      minTime,
      name,
      description,
    };

    return formChanges;
  }

  onFormValidated = (isFormValid) => {
    this.props.formValidated('BasicCourseInfo', isFormValid, this.mapState());
  };

  registerForm = (triggerFormValidation) => {
    this.props.registerComponentForm(triggerFormValidation);
  };

  handleTimeInputChange = (key, value) => {
    if (value.indexOf(':') > -1) {
      if (_isEmpty(value)) {
        this.setState({ [key]: ZERO_TIME });
      } else if (getMinutes(value)) {
        this.setState({ [key]: value });
      }
    }
  };

  renderAccumulatedTime = () => {
    const { assignedCourse } = this.props;

    if (!assignedCourse) {
      return <div />;
    }

    return <div className="col-6 col-xl-4" />;
  };

  renderAccumulatedTheoreticalTime = (t) => {
    const { classes, accumulatedTheoretical, assignedCourse } = this.props;

    if (!assignedCourse) {
      return <div />;
    }

    return (
      <div className="col-6 col-xl-4">
        <TextField
          className={classes.timeInputWidth}
          disabled
          name="accumulatedTheoreticalTime"
          label={t('input:accumulatedTheoreticalTime')}
          value={accumulatedTheoretical}
          margin="dense"
          type="text"
        />
      </div>
    );
  };

  renderAccumulatedPracticalTime = (t) => {
    const { classes, accumulatedPractical, assignedCourse } = this.props;

    if (!assignedCourse) {
      return <div />;
    }

    return (
      <div className="col-6 col-xl-4">
        <TextField
          className={classes.timeInputWidth}
          disabled
          name="accumulatedPracticalTime"
          label={t('input:accumulatedPracticalTime')}
          value={accumulatedPractical}
          margin="dense"
          type="text"
        />
      </div>
    );
  };

  render() {
    const { classes, preview, studentFullName, t } = this.props;
    const { maxTime, minTime, description, name } = this.state;

    return (
      <Form
        onChange={this.onChange}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <div>
          <div>
            <FormControl
              className={classes.formControl}
              margin="dense"
              disabled={preview}
            >
              <MaterialInput
                name="name"
                disabled={preview}
                defaultValue={name}
                label={t('input:name')}
                className={classes.input}
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200),
                ]}
              />
            </FormControl>
          </div>
          {studentFullName ? (
            <div className="row justify-content-between">
              <div className="col-6 col-xl-4">
                <TextField
                  type="text"
                  name="student"
                  margin="dense"
                  label={t('input:student name')}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={studentFullName}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="row justify-content-between">
            <div className="col-6 col-xl-4">
              <MaterialInput
                type="text"
                className={classes.timeInputWidth}
                name="minTime"
                margin="dense"
                label={t('input:minTime')}
                disabled={preview}
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={reformatTime(minTime)}
                validators={[new validators.IsTimeFormatValid(t)]}
              />
            </div>
            <div className="col-6 col-xl-4">
              <MaterialInput
                type="text"
                name="maxTime"
                label={t('input:maxTime')}
                defaultValue={reformatTime(maxTime)}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={preview}
                margin="dense"
                className={classes.timeInputWidth}
                validators={[new validators.IsTimeFormatValid(t)]}
              />
            </div>

            {this.renderAccumulatedTheoreticalTime(t)}
            {this.renderAccumulatedPracticalTime(t)}
            <div className="col-12">
              <FormControl
                className={classes.formControl}
                margin="dense"
                disabled={preview}
              >
                <MaterialInput
                  multiline
                  disabled={preview}
                  label={t('input:description')}
                  name="description"
                  defaultValue={description}
                  validators={[new validators.IsRequired(t)]}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
