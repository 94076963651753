import { URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { urlJoin } from '../../utils';

export const ADD_FOLDER = 'ADD_FOLDER';
export const REMOVE_FOLDER = 'REMOVE_FOLDER';
export const EDIT_FOLDER = 'EDIT_FOLDER';
export const FETCH_FOLDERS_SUCCESS = 'FETCH_FOLDERS_SUCCESS';
export const FETCH_FOLDERS_UNREAD_FILES_REQUEST =
  'FETCH_FOLDERS_UNREAD_FILES_REQUEST';
export const FETCH_FOLDERS_UNREAD_FILES_SUCCESS =
  'FETCH_FOLDERS_UNREAD_FILES_SUCCESS';

export function fetchFoldersSuccess(folders) {
  return {
    type: FETCH_FOLDERS_SUCCESS,
    payload: { folders },
  };
}

export function fetchFoldersUnreadFilesRequest() {
  return {
    type: FETCH_FOLDERS_UNREAD_FILES_REQUEST,
  };
}

export function fetchFoldersUnreadFilesSuccess(files) {
  return {
    type: FETCH_FOLDERS_UNREAD_FILES_SUCCESS,
    payload: { files },
  };
}

export function addFolderSuccess(folder) {
  return {
    type: ADD_FOLDER,
    payload: {
      folder,
    },
  };
}

export function removeFolderSuccess(folder) {
  return {
    type: REMOVE_FOLDER,
    payload: {
      folder,
    },
  };
}

export function editFolderSuccess(folder) {
  return {
    type: EDIT_FOLDER,
    payload: {
      folder,
    },
  };
}

export function addFolder(folder) {
  return (dispatch) => {
    return axios
      .post(URLS.folders, folder)
      .then(({ data }) => dispatch(addFolderSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during folder adding ${error.message}`, error),
        );
      });
  };
}

export function removeFolder(folder) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.folders, folder.id))
      .then(() => dispatch(removeFolderSuccess(folder)))
      .catch((error) => {
        dispatch(
          addError(`Error during folder removing ${error.message}`, error),
        );
      });
  };
}

export function editFolder(folder) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.folders, folder.id), folder)
      .then(({ data }) => dispatch(editFolderSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during folder editing ${error.message}`, error),
        );
      });
  };
}

export function fetchFolders(userId, folderId) {
  return (dispatch) => {
    return axios
      .get(`${URLS.folders}?userId=${userId}&folderId=${folderId}`)
      .then(({ data }) => dispatch(fetchFoldersSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during folders fetching ${error.message}`, error),
        );
      });
  };
}

export function fetchUnreadFoldersFiles() {
  return (dispatch) => {
    dispatch(fetchFoldersUnreadFilesRequest());

    return axios
      .get(`${URLS.folders}unread/files`)
      .then(({ data }) => dispatch(fetchFoldersUnreadFilesSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during folders unread files fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}
