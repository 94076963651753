import React, { Component } from 'react';
import { fetchFleets, removeFleet } from '../../../actions/fleet';
import {
  getFleetStatus,
  getMinTickerToService,
  getNextServiceDate,
  renderDateColor,
  renderFleetColor,
  renderPartsNonSafetyRelatedColor,
  renderTickerToService,
} from '../fleetHelper';
import { sortStatusWeight, sortString } from '../../../../utils/sort';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FleetList from './FleetList';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { reformatPickerDateFromDB } from '../../../utils/time';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    fleetList: state.fleet.fleetList,
    fleetRequest: state.fleet.fleetRequest,
    fleetListPaginationNext: state.fleet.fleetListPaginationNext,
    fleetPaginationRequest: state.fleet.fleetPaginationRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    fetchFleets: (sortingData, paginationParams) =>
      dispatch(fetchFleets(sortingData, paginationParams)),
    removeFleet: (id) => dispatch(removeFleet(id)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class CrossFleetListView extends Component {
  static propTypes = {
    fetchFleets: PropTypes.func.isRequired,
    removeFleet: PropTypes.func.isRequired,
    fleetList: PropTypes.array.isRequired,
    fleetRequest: PropTypes.bool.isRequired,
    fleetPaginationRequest: PropTypes.bool.isRequired,
    fleetListPaginationNext: PropTypes.string.isRequired,
    settings: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: '',
    },
    fleetToRemove: null,
    removeDialogOpen: false,
    openPlannedActionsDialog: false,
    plannedActions: [],
  };

  componentDidMount() {
    const { fetchFleets } = this.props;
    const { searchSettings } = this.state;

    fetchFleets(searchSettings);
  }

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    const found = _find(settings, { name: fieldName });

    if (!found) {
      return 0;
    }

    return found.value == parseInt(found.textValue)
      ? found.value
      : parseInt(found.textValue);
  }

  handleSort = (columnName) => {
    const { search, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData,
    });
  };

  handleSearch = (search) => {
    const { columnToSort, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort,
      sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchFleets(searchData);
  };

  handleDialogClose = () => {
    this.setState({
      openPlannedActionsDialog: false,
      plannedActions: [],
    });
  };

  handleDialogOpen = (plannedActions) => {
    this.setState({
      openPlannedActionsDialog: true,
      plannedActions,
    });
  };

  onFetchNextFleetList = () => {
    const { fetchFleets, fleetListPaginationNext } = this.props;
    const { searchSettings } = this.state;

    fetchFleets(searchSettings, fleetListPaginationNext);
  };

  removeFleet = () => {
    const { fleetToRemove } = this.state;

    this.props.removeFleet(fleetToRemove);
  };

  openRemoveDialog = (fleetId) => {
    this.setState({
      removeDialogOpen: true,
      fleetToRemove: fleetId,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      fleetToRemove: null,
    });
  };

  renderDeleteIcon(fleetId) {
    return (
      <IconButton
        key="remove-icon"
        onClick={() => this.openRemoveDialog(fleetId)}
      >
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  renderEditIcon(fleetId) {
    return (
      <Link key="fleet-button-edit" to={`/fleet/edit/${fleetId}`}>
        <IconButton>
          <Icon color="primary">mode_edit</Icon>
        </IconButton>
      </Link>
    );
  }

  renderIcons(fleet) {
    const fleetId = fleet.id;

    return [
      this.renderPlannedActionIcon(fleet),
      this.renderEditIcon(fleetId),
      this.renderDeleteIcon(fleetId),
    ];
  }

  renderFleetDetailsLink(id, prop) {
    return (
      <Link className="table-row-link" to={`/fleet/details/${id}`}>
        {prop}
      </Link>
    );
  }

  renderPlannedActionIcon(fleet) {
    const { t } = this.props;

    const plannedActions = fleet.inspections.filter(
      ({ plannedAction }) => plannedAction,
    );

    if (!plannedActions.length) {
      return <></>;
    }

    return (
      <Tooltip title={t('Has planned actions')} placement="top">
        <IconButton onClick={() => this.handleDialogOpen(plannedActions)}>
          <Icon color="primary">alarm</Icon>
        </IconButton>
      </Tooltip>
    );
  }

  manageFleetList(fleetList, searchSettings) {
    const { columnToSort, sortAsc } = searchSettings;
    let parsedFleetList = [];

    if (_isEmpty(fleetList)) {
      return parsedFleetList;
    }

    const fleetSettings = {
      hoursWarning: this.setDefaultValue('hours_warning'),
      cyclesWarning: this.setDefaultValue('cycles_warning'),
    };

    parsedFleetList = fleetList.map((fleet) => {
      fleet.parts = fleet.installations.reduce((result, installation) => {
        if (installation.isActive) {
          result.push(installation.part);
        }

        return result;
      }, []);

      fleet.nextService = getNextServiceDate(
        fleet.inspections,
        fleet.parts,
      ).value;
      fleet.serviceCycles = getMinTickerToService(
        fleet.parts,
        fleet.inspections,
        'serviceCycles',
      ).value;

      return {
        fleet,
        id: fleet.id,
        name: this.renderFleetDetailsLink(fleet.id, fleet.vehicle.name),
        type: this.renderFleetDetailsLink(fleet.id, fleet.vehicle.type.name),
        evn: this.renderFleetDetailsLink(fleet.id, fleet.vehicle.evn),
        maintainer: this.renderFleetDetailsLink(fleet.id, fleet.maintainer),
        lastService: this.renderFleetDetailsLink(
          fleet.id,
          reformatPickerDateFromDB(fleet.lastService, false),
        ),
        nextService: this.renderFleetDetailsLink(
          fleet.id,
          renderDateColor(fleet, 'nextService'),
        ),
        currentHours: this.renderFleetDetailsLink(fleet.id, fleet.currentHours),
        currentCycles: this.renderFleetDetailsLink(
          fleet.id,
          fleet.currentCycles,
        ),
        serviceCycles: this.renderFleetDetailsLink(
          fleet.id,
          fleet.serviceCycles,
        ),
        cyclesToService: this.renderFleetDetailsLink(
          fleet.id,
          renderTickerToService(
            fleet,
            'serviceCycles',
            fleetSettings.cyclesWarning,
            fleet.currentCycles,
          ),
        ),
        fleetStatus: getFleetStatus(fleet, fleetSettings),
        //fleetReportStatus: getFleetReportStatus(fleet),
        icons: this.renderIcons(fleet),
        status: renderFleetColor(fleet, fleetSettings),
        colorNonSafetyRelated: renderPartsNonSafetyRelatedColor(
          fleet,
          fleetSettings,
        ),
      };
    });

    this.sort(parsedFleetList, columnToSort, sortAsc);

    return parsedFleetList;
  }

  sort(list, column, sortAsc) {
    switch (column) {
      case 'status':
        list.sort((a, b) => {
          return sortAsc
            ? sortStatusWeight(a.fleetStatus, b.fleetStatus)
            : sortStatusWeight(b.fleetStatus, a.fleetStatus);
        });

        break;
      case 'name':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.fleet.vehicle.name, b.fleet.vehicle.name)
            : sortString(b.fleet.vehicle.name, a.fleet.vehicle.name);
        });

        break;
      case 'type':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.fleet.vehicle.type.name, b.fleet.vehicle.type.name)
            : sortString(b.fleet.vehicle.type.name, a.fleet.vehicle.type.name);
        });

        break;
      case 'reportStatus':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.fleetReportStatus, b.fleetReportStatus)
            : sortString(b.fleetReportStatus, a.fleetReportStatus);
        });

        break;
      case 'evn':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.fleet.vehicle.evn, b.fleet.vehicle.evn)
            : sortString(b.fleet.vehicle.evn, a.fleet.vehicle.evn);
        });

        break;
      case 'maintainer':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.fleet.maintainer, b.fleet.maintainer)
            : sortString(b.fleet.maintainer, a.fleet.maintainer);
        });

        break;
      case 'currentHours':
        list.sort((a, b) => {
          return sortAsc
            ? a.fleet.currentHours - b.fleet.currentHours
            : b.fleet.currentHours - a.fleet.currentHours;
        });

        break;
      case 'lastService':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.fleet.lastService, b.fleet.lastService)
            : sortString(b.fleet.lastService, a.fleet.lastService);
        });

        break;
    }
  }

  renderPlannedActions() {
    const { t } = this.props;

    return this.state.plannedActions.map((inspection) => {
      return (
        <div className="row no-gutters">
          <div className="col">
            {!inspection.plannedActionDate
              ? t('fleet:fleetListPlannedActionDescriptiveText')
                  .replace('{name}', inspection.name)
                  .replace('{action}', inspection.plannedAction)
              : t('fleet:fleetListPlannedActionWithDateDescriptiveText')
                  .replace('{name}', inspection.name)
                  .replace('{action}', inspection.plannedAction)
                  .replace('{dueDate}', inspection.plannedActionDate)}
          </div>
        </div>
      );
    });
  }

  render() {
    const {
      fleetList,
      fleetPaginationRequest,
      fleetListPaginationNext,
      fleetRequest,
      classes,
      t,
    } = this.props;
    const { removeDialogOpen, searchSettings, openPlannedActionsDialog } =
      this.state;
    const { search, columnToSort, sortAsc } = this.state.searchSettings;

    if (fleetRequest) {
      return <Loader />;
    }

    return (
      <>
        <FleetList
          fleetList={this.manageFleetList(fleetList, searchSettings)}
          openRemoveDialog={this.openRemoveDialog}
          closeRemoveDialog={this.closeRemoveDialog}
          handleSort={this.handleSort}
          handleSearch={this.handleSearch}
          removeFleet={this.removeFleet}
          removeDialogOpen={removeDialogOpen}
          search={search}
          columnToSort={columnToSort}
          sortAsc={sortAsc}
        />
        <InfiniteScroll
          loadMore={this.onFetchNextFleetList}
          hasMore={!fleetPaginationRequest && fleetListPaginationNext}
          loader={<>Loading ...</>}
        />
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={openPlannedActionsDialog}
          onClose={this.handleDialogClose}
          aria-labelledby="add-inspection-type-dialog"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="add-edit-inspection-type-dialog">
            {t('Planned actions')}
          </DialogTitle>
          <DialogContent className={classes.root}>
            {this.renderPlannedActions()}
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleDialogClose}>
              {t('buttonBack')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
