import axios from 'axios';

import { URLS } from '../../constants';
import { addError } from './errors';
import { urlJoin } from '../../utils';
import history from '../store/history';

export const EDIT_ASSIGNED_TEST = 'EDIT_ASSIGNED_TEST';

export function calculateCompletedTestResultSuccess(assignedTest) {
  return {
    type: EDIT_ASSIGNED_TEST,
    payload: {
      assignedTest
    }
  };
}

export function calculateResult(testId, testData) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.completedTests, testId), testData)
      .then(({ data }) => {
        dispatch(calculateCompletedTestResultSuccess(data));
        history.push(`/completed_test/preview/${testId}`);
      })
      .catch((error) => {
        dispatch(addError(`Error during completed test result calculating ${error.message}`, error));
      });
  };
}
