import React, { Component } from 'react';
import {
  addInstallation,
  editInstallation,
} from '../../../actions/installations';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import Loader from '../../../../common/components/Loader';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { fetchFleets } from '../../../actions/fleet';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  showRemoveDialog: false,
  id: '',
  name: '',
  mountingDate: moment(),
  unmountingDate: moment(),
  removeCallback: () => {},
  fleet: null,
};

const mapStateToProps = (state) => {
  return {
    installationList: state.installations.installationList,
    fleetList: state.fleet.fleetList,
    fleetRequest: state.fleet.fleetRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addInstallation: (data) => dispatch(addInstallation(data)),
    editInstallation: (data) => dispatch(editInstallation(data)),
    fetchFleets: (sortingData) => dispatch(fetchFleets(sortingData)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class InstallationsManager extends Component {
  static propTypes = {
    part: PropTypes.object.isRequired,
    installation: PropTypes.object.isRequired,
    addInstallation: PropTypes.func.isRequired,
    editInstallation: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    fleetList: PropTypes.array.isRequired,
    fetchFleets: PropTypes.func.isRequired,
    fleetRequest: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { fetchFleets } = this.props;
    const { searchSettings } = this.state;

    this.formData = {};
    fetchFleets(searchSettings);
  }

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = () => {
    const { installation } = this.props;

    this.setState({
      editOpen: true,
      id: installation.id,
      mountingDate: reformatPickerDateFromDB(
        _get(installation, 'mountingDate', moment()),
      ),
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      [name]: dateFromPicker,
    });
  };

  onChange = (formData, name) => {
    this.setState({
      [name]: formData[name].value,
    });
  };

  addInstallation = () => {
    const { addInstallation, part } = this.props;
    const { mountingDate, fleet } = this.state;
    const data = {
      mountingDate: reformatPickerDateToDB(mountingDate),
      part: part.id,
      fleet,
    };

    addInstallation(data);
    this.handleDialogClose();
  };

  editInstallation = () => {
    const { editInstallation } = this.props;
    const { id, unmountingDate } = this.state;
    const data = {
      id,
      unmountingDate: reformatPickerDateToDB(unmountingDate),
    };

    editInstallation(data);
    this.handleDialogClose();
  };

  onFormValidated = (isFormValid) => {
    const { addOpen } = this.state;

    if (isFormValid && this.formData) {
      addOpen ? this.addInstallation() : this.editInstallation();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  renderDocumentButton() {
    const { editMode, installation, t } = this.props;

    if (!editMode) {
      return <></>;
    }

    if (!_isEmpty(installation)) {
      return (
        <Button color="primary" onClick={this.handleEditOpen}>
          {t('fleet:Unmount part')}
        </Button>
      );
    }

    return (
      <Button color="primary" onClick={this.handleAddOpen}>
        {t('fleet:Mount part')}
      </Button>
    );
  }

  render() {
    const { classes, fleetList, fleetRequest, t } = this.props;
    const {
      addOpen,
      editOpen,
      mountingDate,
      unmountingDate,
      showRemoveDialog,
      removeCallback,
      fleet,
    } = this.state;

    if (fleetRequest) {
      return <Loader />;
    }

    return (
      <div className="installation-settings">
        {this.renderDocumentButton()}
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={addOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="add-installation-dialog"
          maxWidth="md"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle id="add-installation-dialog">
              {t('fleet:addInstallationDialogTitle')}
            </DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row">
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:mountingDate')}
                    name="mountingDate"
                    value={mountingDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange(
                      'mountingDate',
                    )}
                    dateFormat={getDateFormat()}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialSelect
                    id="fleet"
                    name="fleet"
                    label={t('input:fleet')}
                    defaultValue={fleet}
                    options={fleetList.map((fleet) => ({
                      value: fleet.id,
                      label: `${fleet.vehicle.name} / ${fleet.vehicle.evn}`,
                    }))}
                    validators={[new validators.IsRequired(t)]}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={editOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="edit-installation-dialog"
          maxWidth="md"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle id="edit-installation-dialog">
              {t('fleet:editInstallationDialogTitle')}
            </DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row">
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:unmountingDate')}
                    name="unmountingDate"
                    value={unmountingDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange(
                      'unmountingDate',
                    )}
                    dateFormat={getDateFormat()}
                    fullWidth
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </div>
    );
  }
}
