import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { isProgressCheckLesson, capitalizeFirstLetter } from '../../../../utils';
import { calculateLessonTime } from '../../../utils/time';

import '../styles.scss';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
});

@withNamespaces()
@withStyles(styles)
export default class LessonList extends Component {
  static propTypes = {
    lessonList: PropTypes.array,
    courseId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderCompletedTemplate = (color, icon, text, lessonResult) => {
    return (
      <div className="row align-items-center">
        <Icon color={color} className="mr-3">{icon}</Icon>
        <p className={`${lessonResult}-lesson`}>{text}</p>
      </div>
    );
  };

  renderCompleted = (lesson, lessonType) => {
    if (isProgressCheckLesson(lessonType)) {
      if (lesson.isPassed) {
        return this.renderCompletedTemplate('action', 'done', 'passed', 'completed');
      }

      if (lesson.isCompleted) {
        return this.renderCompletedTemplate('error', 'clear', 'failed', 'failed');
      }

      return '-';
    }

    if (lesson.itemsCompleted) {
      return this.renderCompletedTemplate('action', 'done', 'completed', 'completed');
    }

    if (lesson.itemsPending) {
      return this.renderCompletedTemplate('secondary', 'alarm', 'in progress', 'pending');
    }

    return '-';
  };

  makeLink = (lesson, text) => {
    const { courseId } = this.props;

    if (lesson.isCompleted) {
      return (
        <Link className="table-row-link" to={`/course_list/details/${courseId}/lesson/details/${lesson.id}`}>
          {text}
        </Link>
      );
    }

    return (
      <Link className="table-row-link" to={`/course_list/details/${courseId}/lesson/edit/${lesson.id}`}>
        {text}
      </Link>
    );
  };

  getInstructorName = (lesson) => {
    const firstName = _get(lesson, 'instructor.firstName', '');
    const lastName = _get(lesson, 'instructor.lastName', '');

    return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`;
  };

  renderLessonList = (lessonList, t) => {
    return _sortBy(lessonList, ['order']).map((lesson) => {
      const lessonId = lesson.id;
      const lessonTime = calculateLessonTime(lesson);
      const { lessonType } = lesson;
      const lessonTypeName = `course:${lessonType.shortname}`;

      return (
        <TableRow key={`lesson-${lessonId}`}>
          <TableCell>{this.makeLink(lesson, lesson.name)}</TableCell>
          <TableCell>{this.makeLink(lesson, t(lessonTypeName))}</TableCell>
          <TableCell>{this.makeLink(lesson, `${lessonTime}`)}</TableCell>
          <TableCell>{this.makeLink(lesson, lesson.actualTime)}</TableCell>
          <TableCell>{this.makeLink(lesson, this.getInstructorName(lesson))}</TableCell>
          <TableCell>{this.makeLink(lesson, this.renderCompleted(lesson, lessonType))}</TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { classes, lessonList, t } = this.props;

    if (_isEmpty(lessonList)) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:name')}</TableCell>
              <TableCell>{t('table:lessonType')}</TableCell>
              <TableCell>{t('table:estimatedTime')}</TableCell>
              <TableCell>{t('table:actualTime')}</TableCell>
              <TableCell>{t('table:instructor')}</TableCell>
              <TableCell>{t('table:status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderLessonList(lessonList, t)}
          </TableBody>
        </Table>
      </div>
    );
  }
}
