import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import Table from '../../../../common/components/Table';

import { renderPermission } from '../../../decorators/permissions';
import { PERMISSIONS } from '../../../../constants/permissions';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
});

const tableHeader = [
  {
    prop: 'courseName',
    sort: true,
    profileView: false
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true
  },
];


@withStyles(styles)
export default class CourseList extends Component {
  static propTypes = {
    courseList: PropTypes.array,
    removeCourse: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    sortAsc: PropTypes.bool.isRequired,
    handleSort: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleAssignCourseOpen: PropTypes.func.isRequired,
    openRemoveDialog: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: this.props.search,
    };
  }

  renderCourseName(course) {
    return (
      <Link className="table-row-link" to={`/course_admin/details/${course.id}`}>{course.name}</Link>
    );
  }

  @renderPermission(PERMISSIONS.deleteCourse)
  renderTrashIcon(course) {
    return <IconButton onClick={() => this.props.openRemoveDialog(course)}><Icon color="primary">delete</Icon></IconButton>;
  }

  @renderPermission(PERMISSIONS.editCourse)
  renderEditIcon(courseId) {
    return (
      <Link to={`/course_admin/edit/${courseId}`}><IconButton><Icon color="primary">mode_edit</Icon></IconButton></Link>
    );
  }

  renderManageIcons(course) {
    const { handleAssignCourseOpen } = this.props;
    const courseId = course.id;

    return (
      <div className="icons-display-wrapper">
        {this.renderEditIcon(courseId)}
        {this.renderTrashIcon(course)}
        <IconButton onClick={() => handleAssignCourseOpen(courseId)}><Icon color="primary">assignment</Icon></IconButton>
      </div>
    );
  }

  getGroupList() {
    const { courseList } = this.props;

    const parsedCourse = courseList.map((course) => ({
      ...course,
      courseName: this.renderCourseName(course),
      icons: this.renderManageIcons(course),
    }));

    return parsedCourse;
  }

  render() {
    const { sortAsc, handleSort, handleSearch, search } = this.props;

    return (
      <Table
        data={this.getGroupList()}
        header={tableHeader}
        handleSort={handleSort}
        sortAsc={sortAsc}
        columnToSort={'courseName'}
        handleSearch={handleSearch}
        search={search}
      />
    );
  }
}
