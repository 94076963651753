import React, { Component } from 'react';
import { getActionIcons, renderFiles } from '../usersHelper';
import {
  getVerifiedBy,
  isVerified,
} from '../PlannedAction/plannedActionHelper';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { reformatPickerDateFromDB } from '../../../utils/time';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 320,
  },
});

@withNamespaces()
@withStyles(styles)
export default class ExternalCourseList extends Component {
  static propTypes = {
    externalCourseList: PropTypes.array,
    addExternalCoursePlannedAction: PropTypes.func.isRequired,
    removeExternalCourse: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    handleEditOpen: PropTypes.func.isRequired,
    attachExternalCourseFile: PropTypes.func.isRequired,
    removeExternalCourseFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    deactivateExternalCourse: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderExternalCourseList() {
    const {
      externalCourseList,
      editMode,
      classes,
      handleEditOpen,
      attachExternalCourseFile,
      addSimpleError,
      addExternalCoursePlannedAction,
      deactivateExternalCourse,
      removeExternalCourse,
      removeExternalCourseFile,
    } = this.props;
    const actions = {
      handleEditOpen,
      addSimpleError,
      deactivateItem: deactivateExternalCourse,
      removeItem: removeExternalCourse,
      attachItemFile: attachExternalCourseFile,
      addItemPlannedAction: addExternalCoursePlannedAction,
      documentName: 'ExternalCourse',
    };
    const documentName = 'external-course';

    return externalCourseList.map((externalCourse) => {
      return (
        <TableRow key={`${documentName}-${externalCourse.id}`}>
          <TableCell className={classes.longCell}>
            {externalCourse.type}
          </TableCell>
          <TableCell className={classes.longCell}>
            {reformatPickerDateFromDB(externalCourse.expiryDate, false)}
          </TableCell>
          <TableCell className={classes.longCell}>
            {reformatPickerDateFromDB(externalCourse.dateOfIssue, false)}
          </TableCell>
          <TableCell className={classes.longCell}>
            {isVerified(externalCourse.isVerified)}
          </TableCell>
          <TableCell className={classes.longCell}>
            {getVerifiedBy(externalCourse.verifiedBy)}
          </TableCell>
          <TableCell className={classes.longCell}>
            {reformatPickerDateFromDB(externalCourse.verificationDate, false)}
          </TableCell>
          <TableCell className={classes.longCell}>
            {externalCourse.plannedAction}
          </TableCell>
          <TableCell className={classes.longCell}>
            {reformatPickerDateFromDB(externalCourse.plannedActionDate, false)}
          </TableCell>
          <TableCell>
            {renderFiles(
              editMode,
              externalCourse,
              removeExternalCourseFile,
              documentName,
            )}
          </TableCell>
          <TableCell className={classes.longCell}>
            {isVerified(externalCourse.isActive)}
          </TableCell>
          <TableCell numeric className={classes.iconsCell}>
            {getActionIcons(editMode, externalCourse, actions)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderAddButton(editMode, handleAddOpen, t) {
    if (editMode) {
      return (
        <Button className="my-2" color="primary" onClick={handleAddOpen}>
          {t('user:addExternalCourseButton')}
        </Button>
      );
    }

    return null;
  }

  render() {
    const { classes, handleAddOpen, editMode, externalCourseList, t } =
      this.props;

    if (_isEmpty(externalCourseList)) {
      return (
        <div>
          <h4>{t('user:externalCoursesSection')}</h4>
          {this.renderAddButton(editMode, handleAddOpen, t)}
          <Divider className="my-3" />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <h4>{t('user:externalCoursesSection')}</h4>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:type')}</TableCell>
              <TableCell>{t('table:expiryDate')}</TableCell>
              <TableCell>{t('table:dateOfIssue')}</TableCell>
              <TableCell>{t('table:isVerified')}</TableCell>
              <TableCell>{t('table:verifiedBy')}</TableCell>
              <TableCell>{t('table:verificationDate')}</TableCell>
              <TableCell>{t('table:plannedAction')}</TableCell>
              <TableCell>{t('table:plannedActionDate')}</TableCell>
              <TableCell>{t('table:attachments')}</TableCell>
              <TableCell>{t('table:isActive')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderExternalCourseList()}</TableBody>
        </Table>
        {this.renderAddButton(editMode, handleAddOpen, t)}
        <Divider className="my-3" />
      </div>
    );
  }
}
