import React, { Component } from 'react';

import LogbookList from './LogbookList';
import { PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

@componentPermission(PERMISSIONS.viewAllLogbooks)
export default class CrossLogbookList extends Component {
  render() {
    return (
      <LogbookList />
    );
  }
}
