import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Loader from '../../../../common/components/Loader';

import { fetchGradings } from '../../../actions/gradings';
import { editSetting } from '../../../actions/settings';

const INITIAL_STATE = {
  grade: false
};

const styles = () => ({
  gradings: {
    minWidth: '120px',
  },
});

const mapStateToProps = (state) => {
  return {
    gradingList: state.gradings.gradingList,
    gradingRequest: state.gradings.gradingRequest,
    settings: state.settings.settings
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchGradings: () => dispatch(fetchGradings()),
    editSetting: (setting) => dispatch(editSetting(setting)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class GradingsManager extends Component {
  static propTypes = {
    gradingList: PropTypes.array,
    settings: PropTypes.array.isRequired,
    gradingRequest: PropTypes.bool.isRequired,
    fetchGradings: PropTypes.func.isRequired,
    editSetting: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.fetchGradings();
  }

  onChange = (formData) => {
    this.formData = formData;
  };

  handleChange = (event) => {
    const { settings, editSetting } = this.props;

    this.setState({ [event.target.name]: event.target.value });
    editSetting({
      id: _find(settings, { name: event.target.name }).id,
      value: event.target.value,
    });
  };

  renderMenuItems(gradingList, t) {
    if (_isEmpty(gradingList)) {
      return null;
    }

    return gradingList.map((grading) => {
      const gradingType = `settings:${grading.name}`;

      return <MenuItem key={`grading-${grading.id}`} value={grading.id}>{t(gradingType)}</MenuItem>;
    });

  }

  setDefaultGrade() {
    const { settings } = this.props;
    let settingsGrade = _find(settings, { name: 'grade' });

    settingsGrade = settingsGrade ? settingsGrade.value : false;

    return this.state.grade ? this.state.grade : settingsGrade;
  }

  render() {
    const { gradingList, gradingRequest, classes, t } = this.props;

    if (gradingRequest || _isEmpty(gradingList)) {
      return <Loader />;
    }

    const grade = this.setDefaultGrade();

    return (
      <div className="gradings-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:gradingSection')}</h4>
          <form autoComplete="off">
            <FormControl className="ml-2 ml-sm-3 my-2">
              <InputLabel className={classes.gradings} htmlFor="grading">{t('input:defaultGrading')}</InputLabel>
              <Select
                className={classes.gradings}
                value={grade || []}
                onChange={this.handleChange}
                inputProps={{
                  name: 'grade',
                  id: 'grading',
                }}
              >
                {this.renderMenuItems(gradingList, t)}
              </Select>
            </FormControl>
          </form>
        </div>
      </div>
    );
  }
}
