const collator = new Intl.Collator('sv', {
  numeric: true,
  sensitivity: 'base',
});

function sortString(a, b) {
  return collator.compare(a, b);
}

function sortStatusWeight(a, b) {
  const statusWeight = {
    'expired-date': 2,
    'expiry-date': 1,
    'ok-date': 0,
  };

  return statusWeight[a] - statusWeight[b];
}

export { sortStatusWeight, sortString };
