import React, { Component } from 'react';
import { fetchWarehouses, removeWarehouse } from '../../../actions/warehouse';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import WarehouseList from './WarehouseList';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    warehouseList: state.warehouse.warehouseList,
    warehouseRequest: state.warehouse.warehouseRequest,
    warehousePaginationRequest: state.warehouse.warehousePaginationRequest,
    partList: state.parts.partList,
    warehouseListPaginationNext: state.warehouse.warehouseListPaginationNext,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    fetchWarehouses: (sortingData, paginationUrl) =>
      dispatch(fetchWarehouses(sortingData, paginationUrl)),
    removeWarehouse: (id) => dispatch(removeWarehouse(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossWarehouseListView extends Component {
  static propTypes = {
    fetchWarehouses: PropTypes.func.isRequired,
    removeWarehouse: PropTypes.func.isRequired,
    warehouseList: PropTypes.array.isRequired,
    warehouseRequest: PropTypes.bool.isRequired,
    settings: PropTypes.array.isRequired,
    warehousePaginationRequest: PropTypes.bool.isRequired,
    warehouseListPaginationNext: PropTypes.string.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: '',
    },
    warehouseToRemove: null,
    removeDialogOpen: false,
  };

  componentDidMount() {
    const { fetchWarehouses } = this.props;
    const { searchSettings } = this.state;

    fetchWarehouses(searchSettings);
  }

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    return _find(settings, { name: fieldName }).textValue;
  }

  handleSort = (columnName) => {
    const { search, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchWarehouses(searchData);
  };

  handleSearch = (search) => {
    const { columnToSort, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort,
      sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchWarehouses(searchData);
  };

  removeWarehouse = () => {
    const { warehouseToRemove } = this.state;

    this.props.removeWarehouse(warehouseToRemove);
  };

  openRemoveDialog = (warehouseId) => {
    this.setState({
      removeDialogOpen: true,
      warehouseToRemove: warehouseId,
    });
  };

  onFetchNextWarehouseList = () => {
    const { fetchWarehouses, warehouseListPaginationNext } = this.props;
    const { searchSettings } = this.state;

    fetchWarehouses(searchSettings, warehouseListPaginationNext);
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      warehouseToRemove: null,
    });
  };

  renderDeleteIcon(warehouseId) {
    return (
      <IconButton
        key="remove-icon"
        onClick={() => this.openRemoveDialog(warehouseId)}
      >
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  renderEditIcon(warehouseId) {
    return (
      <Link key="warehouse-button-edit" to={`/warehouse/edit/${warehouseId}`}>
        <IconButton>
          <Icon color="primary">mode_edit</Icon>
        </IconButton>
      </Link>
    );
  }

  renderIcons(warehouse) {
    const warehouseId = warehouse.id;

    return [
      this.renderEditIcon(warehouseId),
      this.renderDeleteIcon(warehouseId),
    ];
  }

  renderWarehouseDetailsLink(id, prop) {
    return (
      <Link className="table-row-link" to={`/warehouse/details/${id}`}>
        {prop}
      </Link>
    );
  }

  manageWarehouseList(warehouseList) {
    let parsedWarehouseList = [];

    if (_isEmpty(warehouseList)) {
      return parsedWarehouseList;
    }

    parsedWarehouseList = warehouseList.map((warehouse) => {
      return {
        id: warehouse.id,
        name: this.renderWarehouseDetailsLink(warehouse.id, warehouse.name),
        address: this.renderWarehouseDetailsLink(
          warehouse.id,
          warehouse.address,
        ),
        location: this.renderWarehouseDetailsLink(
          warehouse.id,
          warehouse.location,
        ),
        contactDetails: this.renderWarehouseDetailsLink(
          warehouse.id,
          warehouse.contactDetails,
        ),
        icons: this.renderIcons(warehouse),
      };
    });

    return parsedWarehouseList;
  }

  render() {
    const {
      warehouseList,
      warehouseRequest,
      warehousePaginationRequest,
      warehouseListPaginationNext,
    } = this.props;
    const { removeDialogOpen } = this.state;
    const { search, columnToSort, sortAsc } = this.state.searchSettings;

    if (warehouseRequest) {
      return <Loader />;
    }

    return (
      <>
        <WarehouseList
          warehouseList={this.manageWarehouseList(warehouseList)}
          openRemoveDialog={this.openRemoveDialog}
          closeRemoveDialog={this.closeRemoveDialog}
          handleSort={this.handleSort}
          handleSearch={this.handleSearch}
          removeWarehouse={this.removeWarehouse}
          removeDialogOpen={removeDialogOpen}
          search={search}
          columnToSort={columnToSort}
          sortAsc={sortAsc}
        />
        <InfiniteScroll
          loadMore={this.onFetchNextWarehouseList}
          hasMore={!warehousePaginationRequest && warehouseListPaginationNext}
          loader={<>Loading...</>}
        />
      </>
    );
  }
}
