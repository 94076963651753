
export const tabActiveHeader = [
  {
    name: 'date',
    prop: 'startDate',
    sort: false,
    profileView: false
  },
  {
    prop: 'firstName',
    sort: true,
    profileView: false
  },
  {
    prop: 'lastName',
    sort: true,
    profileView: false
  },
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true
  },
];

export const tabInactiveHeader = [
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'firstName',
    sort: true,
    profileView: false
  },
  {
    prop: 'lastName',
    sort: true,
    profileView: false
  },
  {
    prop: 'scheduleTime',
    sort: false,
    profileView: false
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true
  },
];

export const tabCompletedHeader = [
  {
    prop: 'dateCompleted',
    sort: false,
    profileView: false
  },
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'firstName',
    sort: true,
    profileView: false
  },
  {
    prop: 'lastName',
    sort: true,
    profileView: false
  },
  {
    prop: 'result',
    sort: false,
    profileView: false
  },
  {
    prop: 'preview',
    sort: false,
    profileView: false,
    isNumeric: true
  },
  {
    prop: 'certificate',
    sort: false,
    profileView: false,
    isNumeric: true
  },
];
