import React, { Component } from 'react';
import { addFleet, cleanFleetData, editFleet } from '../../../actions/fleet';
import {
  getLastInspection,
  getMinTickerToService,
  getNextServiceDate,
} from '../fleetHelper';

import FleetNew from './FleetNew';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { fetchPartStatuses } from '../../../actions/partStatuses';
import { fetchVehicleTypes } from '../../../actions/vehicleTypes';
import { fetchVehicles } from '../../../actions/vehicles';
import moment from 'moment';
import { reformatPickerDateFromDB } from '../../../utils/time';
import userService from '../../../../utils/userService';

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    fleet: state.fleet.fleet,
    fleetRequest: state.fleet.fleetRequest,
    vehicleList: state.vehicles.vehicleList,
    vehicleRequest: state.vehicles.vehicleRequest,
    vehicleTypeList: state.vehicleTypes.vehicleTypeList,
    vehicleTypeRequest: state.vehicleTypes.vehicleTypeRequest,
    partStatusList: state.partStatuses.partStatusList,
    inspectionList: state.inspections.inspectionList,
    partList: state.parts.partList,
    partFleetList: state.parts.partFleetList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addFleet: (fleet) => dispatch(addFleet(fleet)),
    editFleet: (id, fleet) => dispatch(editFleet(id, fleet)),
    cleanFleetData: () => dispatch(cleanFleetData()),
    fetchVehicles: () => dispatch(fetchVehicles()),
    fetchVehicleTypes: () => dispatch(fetchVehicleTypes()),
    fetchPartStatuses: () => dispatch(fetchPartStatuses()),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossFleetNewView extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    fleetRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    cleanFleetData: PropTypes.func.isRequired,
    addFleet: PropTypes.func.isRequired,
    editFleet: PropTypes.func.isRequired,
    vehicleList: PropTypes.array.isRequired,
    vehicleRequest: PropTypes.bool.isRequired,
    fetchVehicles: PropTypes.func.isRequired,
    vehicleTypeList: PropTypes.array.isRequired,
    vehicleTypeRequest: PropTypes.bool.isRequired,
    fetchPartStatuses: PropTypes.func.isRequired,
    partStatusList: PropTypes.array.isRequired,
    fetchVehicleTypes: PropTypes.func.isRequired,
    settings: PropTypes.array.isRequired,
    partList: PropTypes.array.isRequired,
    partFleetList: PropTypes.array.isRequired,
    inspectionList: PropTypes.array.isRequired,
    previewMode: PropTypes.bool,
    editMode: PropTypes.bool,
  };

  componentDidMount() {
    const { fetchVehicles, fetchVehicleTypes, fetchPartStatuses } = this.props;

    fetchVehicles();
    fetchVehicleTypes();
    fetchPartStatuses();
  }

  componentWillUnmount() {
    const { cleanFleetData } = this.props;

    cleanFleetData();
  }

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    const found = _find(settings, { name: fieldName });

    if (!found) {
      return 0;
    }

    return found.value == parseInt(found.textValue)
      ? found.value
      : parseInt(found.textValue);
  }

  render() {
    const {
      fleet,
      fleetRequest,
      editMode,
      previewMode,
      vehicleRequest,
      vehicleList,
      addFleet,
      editFleet,
      vehicleTypeRequest,
      vehicleTypeList,
      inspectionList,
      partList,
      partFleetList,
      partStatusList,
    } = this.props;
    const currentUser = userService.getCurrentUser();
    const preparedFleet = {
      id: _get(fleet, 'id', ''),
      maintainer: _get(fleet, 'maintainer', ''),
      trackingId: _get(fleet, 'trackingId', ''),
      lastService: getLastInspection(inspectionList),
      nextService: getNextServiceDate(inspectionList, partFleetList),
      notificationEmailsAsString: _get(fleet, 'notificationEmails', []).join(
        ',',
      ),
      vehicle: _get(fleet, 'vehicle', null),
      currentHours: _get(fleet, 'currentHours', 0),
      currentCycle: _get(fleet, 'currentCycle', 0),
      currentMileage: _get(fleet, 'currentMileage', 0),
      serviceMileage: getMinTickerToService(
        partFleetList,
        inspectionList,
        'serviceMileage',
      ),
      serviceHours: getMinTickerToService(
        partFleetList,
        inspectionList,
        'serviceHours',
      ),
      serviceOperationalDays: getMinTickerToService(
        partFleetList,
        inspectionList,
        'serviceOperationalDays',
      ),
      operationalDays: _get(fleet, 'operationalDays', 0),
      updatedBy: _get(fleet, 'updatedBy', ''),
      updatedAt: reformatPickerDateFromDB(_get(fleet, 'updatedAt', moment())),
    };
    const fleetSettings = {
      hoursWarning: this.setDefaultValue('hours_warning'),
      distanceWarning: this.setDefaultValue('distance_warning'),
      operationalDaysWarning: this.setDefaultValue('operational_days_warning'),
    };

    if (fleetRequest || vehicleRequest || vehicleTypeRequest) {
      return <Loader />;
    }

    return (
      <FleetNew
        fleet={preparedFleet}
        previewMode={previewMode}
        editMode={editMode}
        addFleet={addFleet}
        editFleet={editFleet}
        linkTo={'/fleet'}
        vehicleList={vehicleList}
        vehicleTypeList={vehicleTypeList}
        currentUser={currentUser}
        fleetSettings={fleetSettings}
        inspectionList={inspectionList}
        partList={partList}
        partFleetList={partFleetList}
        partStatusList={partStatusList}
      />
    );
  }
}
