import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _each from 'lodash/each';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Form from '../../../common/components/forms/Form';
import MaterialInput from '../../../common/components/forms/MaterialInput';
import Confirmation from '../../../common/components/Confirmation';
import validators from '../../../utils/validators';

import { addUser, resetAddUserFlag } from '../../actions/users';

import './styles.scss';

const INITIAL_STATE = {
  open: false,
  firstName: '',
  lastName: '',
  email: '',
};

const mapStateToProps = (state) => {
  return {
    addUserSuccess: state.users.addUserSuccess,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addUser: (userForm) => dispatch(addUser(userForm)),
    resetAddUserFlag: () => dispatch(resetAddUserFlag()),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class AddUser extends Component {
  static propTypes = {
    addUser: PropTypes.func.isRequired,
    addUserSuccess: PropTypes.bool.isRequired,
    resetAddUserFlag: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.formData = {};
  }

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = (isFormValid) => {
    this.setState({
      isFormValid
    });

    if (isFormValid && this.formData) {
      this.addUser();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState(INITIAL_STATE);
  };

  addUser = () => {
    const userForm = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email.toLocaleLowerCase(),
    };

    this.setState({
      ...INITIAL_STATE,
    });
    this.props.addUser(userForm);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { addUserSuccess, t } = this.props;
    const { open, firstName, lastName, email } = this.state;

    return (
      <div className="col-auto add-user">
        <Button color="primary" onClick={this.handleClickOpen}>
          <Icon color="primary" className="mr-3">playlist_add</Icon>
          {t('user:addUserButton')}
        </Button>
        <Form
          onChange={this.onChange}
          validateForm={this.validateForm}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="add-user"
            fullWidth
          >
            <DialogTitle id="add-user">{t('user:createUserDialogTitle')}</DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <MaterialInput
                    label={t('input:firstName')}
                    defaultValue={firstName}
                    name="firstName"
                    margin="dense"
                    autoFocus
                    validators={[
                      new validators.IsRequired(t),
                    ]}
                  />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <MaterialInput
                    label={t('input:lastName')}
                    defaultValue={lastName}
                    name="lastName"
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                    ]}
                  />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <MaterialInput
                    label={t('input:email')}
                    defaultValue={email}
                    name="email"
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.Email(t),
                    ]}
                  />
                </div>
                <div className="col-12 pt-5">
                  <div className="row align-items-center">
                    <Icon color="primary" className="col-1">info_outline</Icon>
                    <p className="col-11">{t('user:userWillBeNotified')}</p>
                  </div>
                </div>
              </div>

            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleClose} >
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonCreateAndSend')}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
        <Dialog
          open={addUserSuccess}
          onClose={this.props.resetAddUserFlag}
          aria-labelledby="confirmation-add-user"
          fullWidth
        >
          <Confirmation
            message={t('user:userCreatedMessage')}
            closeConfirmationDialog={this.props.resetAddUserFlag}
          />
        </Dialog>
      </div>
    );
  }
}
