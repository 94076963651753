import history from '../store/history';

import { capitalizeFirstLetter } from '../../utils';

export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const ERROR = 'ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

export function addAuthenticationError(message) {
  return {
    type: AUTHENTICATION_ERROR,
    payload: {
      message
    }
  };
}

export function addError(message, error) {
  const { response = {} } = error;
  const status = response.status || 400;
  const data = response.data || false;
  let errorMessage = message;

  if (status === 403) {
    errorMessage = 'You have no permissions for this action.';
  } else if (status === 404) {
    history.push('/not-found');
    errorMessage = 'Resource not found.';
  } else if (status >= 500 && status <= 511) {
    history.push('/server-error');
    errorMessage = 'Server Error.';
  } else if (data && data.nonFieldErrors) {
    errorMessage = data.nonFieldErrors[0];
  } else if (data && typeof data === 'string') {
    errorMessage = capitalizeFirstLetter(data);
  } else if (data) {
    errorMessage = Object.values(data).map((errorName) => {
      if (errorName && typeof errorName === 'string') {
        return capitalizeFirstLetter(errorName);
      } else if (errorName[0] && typeof errorName[0] === 'string') {
        return capitalizeFirstLetter(errorName[0]);
      }

      return '';
    }).join(' ');
  }

  return {
    type: ERROR,
    payload: {
      message: errorMessage
    }
  };
}

export function addSimpleError(message) {
  return {
    type: ERROR,
    payload: {
      message,
    }
  };
}

export function removeError() {
  return {
    type: REMOVE_ERROR,
  };
}

