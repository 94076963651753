import './styles.scss';

import React, { Component } from 'react';

import GroupsManager from './User/GroupsManager';
import Icon from '@material-ui/core/Icon';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../common/components/RemoveDialogContainer';
import RolesManager from './User/RolesManager';
import VehicleGroupUsersManager from './User/VehicleGroupUsersManager';
import { componentPermission } from '../../decorators/permissions';
import { withNamespaces } from 'react-i18next';

const INITIAL_STATE = {
  showRemoveDialog: false,
  removeCallback: () => {},
};

@withNamespaces()
@componentPermission(PERMISSIONS.userSettingsView)
export default class UserSettings extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState(INITIAL_STATE);
  };

  render() {
    const { t } = this.props;
    const { showRemoveDialog, removeCallback } = this.state;

    return (
      <div className="settings row">
        <div className="col-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">settings</Icon>
              <h1>
                {t('settings:name')}
                <span className="h1-subheader">
                  {' '}
                  /{t('settings:usersSection')}
                </span>
              </h1>
            </span>
            <p>{t('settings:description')}</p>
          </header>
        </div>
        <div className="col-xl-6">
          <h2>{t('settings:rolesSection')}</h2>
          <RolesManager onElementDelete={this.openRemoveDialog} />
        </div>
        <div className="col-xl-6">
          <h2>{t('settings:groupsSection')}</h2>
          <GroupsManager onElementDelete={this.openRemoveDialog} />
        </div>
        <div className="col-xl-6">
          <h2>{t('settings:vehicleGroupUsersSection')}</h2>
          <VehicleGroupUsersManager onElementDelete={this.openRemoveDialog} />
        </div>
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
      </div>
    );
  }
}
