import {
  ADD_VEHICLE_GROUP_USER_SUCCESS,
  FETCH_VEHICLE_GROUP_USERS_FAILURE,
  FETCH_VEHICLE_GROUP_USERS_REQUEST,
  FETCH_VEHICLE_GROUP_USERS_SUCCESS,
  REMOVE_VEHICLE_GROUP_USER_SUCCESS,
} from '../actions/vehicleGroupUsers';

const INITIAL_STATE = {
  vehicleGroupUserList: [],
  vehicleGroupUserRequest: false,
};

function fetchVehicleGroupsSuccess(state, payload) {
  return {
    ...state,
    vehicleGroupUserList: payload.vehicleGroupUserList,
    vehicleGroupUserRequest: false,
  };
}

function fetchVehicleGroupsRequest(state) {
  return {
    ...state,
    vehicleGroupUserRequest: true,
  };
}

function fetchVehicleGroupsFailure(state) {
  return {
    ...state,
    vehicleGroupUserRequest: false,
  };
}

function addVehicleGroup(state, payload) {
  return {
    ...state,
    vehicleGroupUserList: [
      ...state.vehicleGroupUserList,
      payload.vehicleGroupUserList,
    ],
  };
}

function removeVehicleGroup(state, payload) {
  return {
    ...state,
    vehicleGroupUserList: state.vehicleGroupUserList.filter(
      (vehicleGroupUser) => vehicleGroupUser.id !== payload.vehiceGroupUserId,
    ),
  };
}

const actions = {
  [FETCH_VEHICLE_GROUP_USERS_SUCCESS]: fetchVehicleGroupsSuccess,
  [FETCH_VEHICLE_GROUP_USERS_REQUEST]: fetchVehicleGroupsRequest,
  [FETCH_VEHICLE_GROUP_USERS_FAILURE]: fetchVehicleGroupsFailure,
  [ADD_VEHICLE_GROUP_USER_SUCCESS]: addVehicleGroup,
  [REMOVE_VEHICLE_GROUP_USER_SUCCESS]: removeVehicleGroup,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
