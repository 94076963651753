import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import Item from './Item';

import { removeDuplicates, isProgressCheckLesson } from '../../../../utils';
import { getClassName } from '../../../../utils/courseService';

const styles = () => ({
  root: {
    maxHeight: 425,
    overflowY: 'auto',
  },
  small: {
    maxHeight: 115,
    overflowY: 'auto',
  },
  medium: {
    maxHeight: 215,
    overflowY: 'auto',
  },
  large: {
    maxHeight: 320,
    overflowY: 'auto',
  },
});

@withNamespaces()
@withStyles(styles)
export default class PendingItemList extends Component {
  static propTypes = {
    lessons: PropTypes.array.isRequired,
    preview: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    addPendingItem: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {};

  componentDidMount() {
    this.handleScroll();

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll = () => {
    const scrollTop = window.scrollY;
    const { innerWidth, innerHeight } = window;

    this.setState({
      transform: scrollTop,
      innerWidth,
      innerHeight,
    });
  }

  setItemsHeight = (elem) => {
    if (elem) {
      let location = 0;

      if (elem.offsetParent) {
        do {
          location += elem.offsetTop;
          elem = elem.offsetParent;
        } while (elem);
      }

      this.itemsOffset = location >= 0 ? location : 0;
    }
  };

  getTransformation = () => {
    const { itemsOffset } = this;
    const { transform } = this.state;
    const additionalMargin = 49;

    if (!(transform && itemsOffset) || window.innerWidth < 992) {
      return 0;
    }

    if (itemsOffset - additionalMargin < transform) {
      return -(itemsOffset - additionalMargin - transform);
    }

    return 0;
  }


  isItemPending(lessons, item) {
    if (this.isItemCompleted(lessons, item)) {
      return false;
    }

    return lessons.some((lesson) => {
      if (!lesson.isCompleted || isProgressCheckLesson(lesson.lessonType)) {
        return false;
      }

      return lesson.items.some((lessonItem) => {
        return lessonItem.id === item.id && !lessonItem.isCompleted;
      });
    });
  }

  isItemCompleted(lessons, item) {
    return lessons.some((lesson) => {
      if (!lesson.isCompleted || isProgressCheckLesson(lesson.lessonType)) {
        return false;
      }

      return lesson.items.some((lessonItem) => {
        return lessonItem.id === item.id && lessonItem.isCompleted;
      });
    });
  }

  renderItemList = () => {
    const { lessons, preview, addPendingItem } = this.props;
    const courseItems = lessons.reduce((result, lesson) => {
      if (isProgressCheckLesson(lesson.lessonType)) {
        return result;
      }

      return result.concat(lesson.items);
    }, []);
    const uniqueItems = removeDuplicates(courseItems, 'id').sort((item1, item2) => item1.id - item2.id);

    return uniqueItems.map((item) => {
      return (
        <Item
          key={`pending-item-${item.id}`}
          item={item}
          preview={preview}
          addPendingItem={addPendingItem}
          isPending={this.isItemPending(lessons, item)}
          isCompleted={this.isItemCompleted(lessons, item)}
        />
      );
    });
  }

  render() {
    const { classes, t } = this.props;
    const { innerHeight, innerWidth } = this.state;
    const style = { transform: `translateY(${this.getTransformation()}px)` };
    const steps = {
      small: 470,
      medium: 570,
      large: 670,
    };

    return (
      <div className="items" id="items" style={style} ref={(elem) => this.setItemsHeight(elem)}>
        <div className="col-xs-12">
          <h4>{t('course:courseContents')}</h4>
        </div>
        <Divider color="secondary" />
        <div className={classes[getClassName(innerHeight, innerWidth, steps)]}>
          {this.renderItemList()}
        </div>
      </div>
    );
  }
}
