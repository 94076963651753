import {
  FETCH_PRACTICAL_FIELDS_SUCCESS,
  FETCH_PRACTICAL_FIELDS_REQUEST,
  FETCH_PRACTICAL_FIELDS_FAILURE,
  EDIT_PRACTICAL_FIELD,
} from '../actions/practicalFields';

const INITIAL_STATE = {
  practicalFieldList: [],
  practicalFieldRequest: false,
};

function fetchPracticalFieldsSuccess(state, payload) {
  return {
    ...state,
    practicalFieldList: payload.practicalFields,
    practicalFieldRequest: false,
  };
}

function fetchPracticalFieldsRequest(state) {
  return {
    ...state,
    practicalFieldRequest: true,
  };
}

function fetchPracticalFieldsFailure(state) {
  return {
    ...state,
    practicalFieldRequest: false,
  };
}

function editPracticalField(state, payload) {
  return {
    ...state,
    practicalFieldList: state.practicalFieldList.map((practicalField) => {
      if (practicalField.id === payload.practicalField.id) {
        return payload.practicalField;
      }

      return practicalField;
    })
  };
}


const actions = {
  [FETCH_PRACTICAL_FIELDS_SUCCESS]: fetchPracticalFieldsSuccess,
  [FETCH_PRACTICAL_FIELDS_REQUEST]: fetchPracticalFieldsRequest,
  [FETCH_PRACTICAL_FIELDS_FAILURE]: fetchPracticalFieldsFailure,
  [EDIT_PRACTICAL_FIELD]: editPracticalField,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
