import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import './styles.scss';


@withNamespaces()
export default class ServerError extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;

    return (
      <div className="text-center">
        <div className="error-header">{t('error:500')}</div>
        <h4>{t('error:500ErrorHeader')}</h4>
        <h4>{t('error:500ErrorContent')}</h4>
      </div>
    );
  }
}
