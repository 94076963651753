import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import DateTextField from './CustomInput';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

export default class DateTimePicker extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    showMonthDropdown: PropTypes.bool,
    showTimeSelect: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    handleDatePickerChange: PropTypes.func,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    acceptEmptyInput: PropTypes.bool,
    className: PropTypes.string,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    minTime: PropTypes.object,
    maxTime: PropTypes.object,
    timeIntervals: PropTypes.number,
  };

  selectedValue = () => {
    const { value, acceptEmptyInput = false, minDate } = this.props;

    if (_isEmpty(value)) {
      if (minDate) {
        return minDate;
      }

      if (acceptEmptyInput) {
        return null;
      }

      return moment();

    }

    return value;

  };

  render() {
    const { minDate = null, maxDate = null, minTime = null, maxTime = null, dateFormat,
      disabled = false, fullWidth = false, showMonthDropdown, showTimeSelect, showYearDropdown,
      label, handleDatePickerChange, timeFormat, timeIntervals = 5 } = this.props;

    if (minDate || maxDate) {
      return (
        <div className={fullWidth ? 'root full-width' : 'root'}>
          <DatePicker
            timeIntervals={timeIntervals}
            disabled={disabled}
            showTimeSelect={showTimeSelect}
            onChange={handleDatePickerChange}
            selected={this.selectedValue()}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            minDate={minDate}
            maxDate={maxDate}
            minTime={minTime}
            maxTime={maxTime}
            customInput={
              <DateTextField
                label={label}
                disabled={disabled}
                fullWidth={fullWidth}
              />
            }
          />
        </div>
      );
    }

    return (
      <div className={fullWidth ? 'root full-width' : 'root'}>
        <DatePicker
          timeIntervals={timeIntervals}
          disabled={disabled}
          showTimeSelect={showTimeSelect}
          onChange={handleDatePickerChange}
          selected={this.selectedValue()}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          dropdownMode="select"
          customInput={
            <DateTextField
              label={label}
              disabled={disabled}
              fullWidth={fullWidth}
            />
          }
        />
      </div>
    );

  }
}
