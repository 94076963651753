import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
});

@withNamespaces()
@withStyles(styles)
export default class CategoriesSummary extends Component {
  static propTypes = {
    categories: PropTypes.array,
    classes: PropTypes.object.isRequired,
    removeCategory: PropTypes.func.isRequired,
    editCategory: PropTypes.func.isRequired,
    preview: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  renderCategories = (categories) => {
    const { editCategory, removeCategory, preview = false } = this.props;

    return categories.map((category) => {
      return (
        <TableRow key={`category-${category.categoryId}`}>
          <TableCell>{category.categoryName}</TableCell>
          <TableCell>{category.numberOfQuestions}</TableCell>
          <TableCell numeric>
            {!preview && <IconButton onClick={() => editCategory(category)}><Icon color="primary">mode_edit</Icon></IconButton>}
            {!preview && <IconButton onClick={() => removeCategory(category.categoryId)}><Icon color="primary">delete</Icon></IconButton>}
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { classes, categories, t } = this.props;

    if (_isEmpty(categories)) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:questionCategory')}</TableCell>
              <TableCell>{t('table:numberOfQuestions')}</TableCell>
              <TableCell numeric />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderCategories(categories)}
          </TableBody>
        </Table>
      </div>
    );
  }
}
