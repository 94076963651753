import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import ItemTypeList from './ItemTypeList';
import Loader from '../../../../common/components/Loader';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';

import { fetchItemTypes, addItemType, removeItemType } from '../../../actions/itemTypes';
import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  itemTypeName: '',
  itemTypeId: null
};

const mapStateToProps = (state) => {
  return {
    itemTypeList: state.itemTypes.itemTypeList,
    itemTypeRequest: state.itemTypes.itemTypeRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchItemTypes: () => dispatch(fetchItemTypes()),
    addItemType: (name) => dispatch(addItemType(name)),
    removeItemType: (name) => dispatch(removeItemType(name)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class ItemTypesManager extends Component {
  static propTypes = {
    itemTypeList: PropTypes.array,
    itemTypeRequest: PropTypes.bool.isRequired,
    fetchItemTypes: PropTypes.func.isRequired,
    addItemType: PropTypes.func.isRequired,
    removeItemType: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.props.fetchItemTypes();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleAddClose = () => {
    this.setState(INITIAL_STATE);
  };

  addItemType = () => {
    const itemType = {
      name: this.state.itemTypeName
    };

    this.props.addItemType(itemType);
    this.handleAddClose();
  };

  removeItemType = (itemType) => {
    this.props.removeItemType(itemType);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  addDialogContent = (t) => {
    return (
      <MaterialInput
        margin="dense"
        name="itemTypeName"
        label={t('input:name')}
        autoFocus
        validators={[
          new validators.IsRequired(t),
          new validators.MaxLength(t, 200)
        ]}
        defaultValue={this.state.itemTypeName}
      />
    );
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid && this.formData) {
        this.addItemType();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { itemTypeList, itemTypeRequest, onElementDelete, t } = this.props;

    if (itemTypeRequest) {
      return <Loader />;
    }

    return (
      <div className="item-types-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:contentTypesSection')}</h4>
        </div>
        <ItemTypeList
          itemTypeList={itemTypeList}
          removeItemType={this.removeItemType}
          handleAddOpen={this.handleAddOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={this.state.addOpen}
            onClose={this.handleAddClose}
            aria-labelledby="add-item-type-dialog"
            fullWidth
          >
            <DialogTitle id="add-item-type-dialog">{t('settings:addContentTypeDialogTitle')}</DialogTitle>
            <DialogContent>
              { this.addDialogContent(t) }
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleAddClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}

