import '../styles.scss';

import React, { Component } from 'react';
import {
  addExternalCourse,
  addExternalCoursePlannedAction,
  deactivateExternalCourse,
  editExternalCourse,
  removeExternalCourse,
} from '../../../actions/externalCourses';
import {
  attachExternalCourseFile,
  removeExternalCourseFile,
} from '../../../actions/fileUpload';
import {
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExternalCourseList from './ExternalCourseList';
import Form from '../../../../common/components/forms/Form';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { getDateFormat } from '../../../utils/time';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  type: '',
  expiryDate: null,
  dateOfIssue: null,
  id: null,
  isFormValid: false,
};

const mapStateToProps = (state) => {
  return {
    externalCourseList: state.externalCourses.externalCourseList || [],
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addExternalCourse: (externalCourse) =>
      dispatch(addExternalCourse(externalCourse)),
    addExternalCoursePlannedAction: (externalCourse) =>
      dispatch(addExternalCoursePlannedAction(externalCourse)),
    removeExternalCourse: (externalCourse) =>
      dispatch(removeExternalCourse(externalCourse)),
    editExternalCourse: (externalCourse) =>
      dispatch(editExternalCourse(externalCourse)),
    deactivateExternalCourse: (externalCourse) =>
      dispatch(deactivateExternalCourse(externalCourse)),
    attachExternalCourseFile: (file, id, userId) =>
      dispatch(attachExternalCourseFile(file, id, userId)),
    removeExternalCourseFile: (fileId, id) =>
      dispatch(removeExternalCourseFile(fileId, id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UserExternalCourses extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    externalCourseList: PropTypes.array,
    editMode: PropTypes.bool,
    addExternalCourse: PropTypes.func.isRequired,
    addExternalCoursePlannedAction: PropTypes.func.isRequired,
    removeExternalCourse: PropTypes.func.isRequired,
    editExternalCourse: PropTypes.func.isRequired,
    deactivateExternalCourse: PropTypes.func.isRequired,
    attachExternalCourseFile: PropTypes.func.isRequired,
    removeExternalCourseFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    documentTypeList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  onChange = (formData) => {
    this.formData = formData;
    _each(formData, (value, key) => {
      this.setState({
        [key]: value.value,
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.state.addOpen
          ? this.addExternalCourse()
          : this.editExternalCourse();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation; // this function can be used for full form validation
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (externalCourse) => {
    this.setState({
      editOpen: true,
      id: externalCourse.id,
      expiryDate: externalCourse.expiryDate,
      dateOfIssue: externalCourse.dateOfIssue,
      type: externalCourse.type,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addExternalCourse = () => {
    const { type, expiryDate, dateOfIssue } = this.state;
    const { userId } = this.props;
    const newExternalCourse = {
      type,
      userId,
      dateOfIssue: reformatPickerDateToDB(dateOfIssue),
      expiryDate: reformatPickerDateToDB(expiryDate),
    };

    this.props.addExternalCourse(newExternalCourse);
    this.handleDialogClose();
  };

  removeExternalCourse = (externalCourse) => {
    this.props.removeExternalCourse(externalCourse);
  };

  editExternalCourse = () => {
    const { type, id, expiryDate, dateOfIssue } = this.state;
    const { userId } = this.props;
    const newExternalCourse = {
      id,
      type,
      userId,
      dateOfIssue: reformatPickerDateToDB(dateOfIssue),
      expiryDate: reformatPickerDateToDB(expiryDate),
    };

    this.props.editExternalCourse(newExternalCourse);
    this.handleDialogClose();
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  renderSelect = (inputName, inputLabel, value, changeFunction) => {
    const { documentTypeList } = this.props;

    return (
      <AutoSuggestion
        label={inputName}
        inputName={inputLabel}
        defaultValue={value ? value : ''}
        handleChange={changeFunction}
        typeList={documentTypeList}
        fullWidth
      />
    );
  };

  dialogContent = (classes, title, t) => {
    const { type, expiryDate, dateOfIssue } = this.state;

    return (
      <div>
        <DialogTitle id="add-external-course-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              {this.renderSelect('type', 'type', type, this.handleSelectChange)}
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                acceptEmptyInput
                value={reformatPickerDateFromDB(dateOfIssue)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'dateOfIssue',
                )}
                dateFormat={getDateFormat()}
                name="dateOfIssue"
                label={t('input:dateOfIssue')}
                fullWidth
              />
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                acceptEmptyInput
                value={reformatPickerDateFromDB(expiryDate)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'expiryDate',
                )}
                dateFormat={getDateFormat()}
                name="expiryDate"
                label={t('input:expiryDate')}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleDialogClose}>
            {t('buttonCancel')}
          </Button>
          <Button color="primary" onClick={this.sendHandler}>
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </div>
    );
  };

  attachExternalCourseFile = (file) => (id) => {
    const { attachExternalCourseFile, userId } = this.props;

    attachExternalCourseFile(file, id, userId);
  };

  render() {
    const {
      externalCourseList,
      editMode,
      classes,
      removeExternalCourseFile,
      addSimpleError,
      onElementDelete,
      t,
      addExternalCoursePlannedAction,
      deactivateExternalCourse,
    } = this.props;
    const { addOpen, editOpen } = this.state;

    return (
      <div className="col-12 user">
        <ExternalCourseList
          externalCourseList={externalCourseList}
          addExternalCoursePlannedAction={addExternalCoursePlannedAction}
          removeExternalCourse={(externalCourse) =>
            onElementDelete(() => this.removeExternalCourse(externalCourse))
          }
          handleAddOpen={this.handleAddOpen}
          editMode={editMode}
          handleEditOpen={this.handleEditOpen}
          attachExternalCourseFile={this.attachExternalCourseFile}
          removeExternalCourseFile={(fileId, id) =>
            onElementDelete(() => removeExternalCourseFile(fileId, id))
          }
          addSimpleError={addSimpleError}
          deactivateExternalCourse={deactivateExternalCourse}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={addOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="add-external-course-dialog-title"
          >
            {this.dialogContent(classes, t('user:addExternalCourse'), t)}
          </Dialog>
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={editOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="edit-external-course-dialog-title"
          >
            {this.dialogContent(classes, t('user:editExternalCourse'), t)}
          </Dialog>
        </Form>
      </div>
    );
  }
}
