import {
  FETCH_VEHICLE_TYPES_SUCCESS,
  FETCH_VEHICLE_TYPES_REQUEST,
  FETCH_VEHICLE_TYPES_FAILURE,
  ADD_VEHICLE_TYPE,
  EDIT_VEHICLE_TYPE,
  REMOVE_VEHICLE_TYPE,
} from '../actions/vehicleTypes';

const INITIAL_STATE = {
  vehicleTypeList: [],
  vehicleTypeRequest: false,
};

function fetchVehicleTypesSuccess(state, payload) {
  return {
    ...state,
    vehicleTypeList: payload.vehicleTypes,
    vehicleTypeRequest: false,
  };
}

function fetchVehicleTypesRequest(state) {
  return {
    ...state,
    vehicleTypeRequest: true,
  };
}

function fetchVehicleTypesFailure(state) {
  return {
    ...state,
    vehicleTypeRequest: false,
  };
}

function addVehicleType(state, payload) {
  return {
    ...state,
    vehicleTypeList: [
      ...state.vehicleTypeList,
      payload.vehicleType
    ],
  };
}

function editVehicleType(state, payload) {
  return {
    ...state,
    vehicleTypeList: state.vehicleTypeList.map((vehicleType) => {
      if (vehicleType.id === payload.vehicleType.id) {
        return payload.vehicleType;
      }

      return vehicleType;
    })
  };
}

function removeVehicleType(state, payload) {
  return {
    ...state,
    vehicleTypeList: state.vehicleTypeList.filter((vehicleType) => vehicleType.id !== payload.vehicleType.id),
  };
}

const actions = {
  [FETCH_VEHICLE_TYPES_SUCCESS]: fetchVehicleTypesSuccess,
  [FETCH_VEHICLE_TYPES_REQUEST]: fetchVehicleTypesRequest,
  [FETCH_VEHICLE_TYPES_FAILURE]: fetchVehicleTypesFailure,
  [ADD_VEHICLE_TYPE]: addVehicleType,
  [EDIT_VEHICLE_TYPE]: editVehicleType,
  [REMOVE_VEHICLE_TYPE]: removeVehicleType,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
