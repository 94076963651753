import axios from 'axios';

import { addError } from './errors';
import { getUserSettingsParametersFilter, urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_USER_COMPETENCE_REQUEST = 'FETCH_USER_COMPETENCE_REQUEST';
export const FETCH_USER_COMPETENCIES_SUCCESS = 'FETCH_USER_COMPETENCIES_SUCCESS';
export const ADD_USER_COMPETENCE = 'ADD_USER_COMPETENCE';
export const REMOVE_USER_COMPETENCE = 'REMOVE_USER_COMPETENCE';
export const EDIT_USER_COMPETENCE = 'EDIT_USER_COMPETENCE';


export function fetchUserCompetenceRequest() {
  return {
    type: FETCH_USER_COMPETENCE_REQUEST
  };
}

export function fetchUserCompetenciesSuccess(userCompetencies) {
  return {
    type: FETCH_USER_COMPETENCIES_SUCCESS,
    payload: {
      userCompetencies
    }
  };
}

export function addUserCompetenceSuccess(userCompetence) {
  return {
    type: ADD_USER_COMPETENCE,
    payload: {
      userCompetence
    }
  };
}

export function removeUserCompetenceSuccess(userCompetence) {
  return {
    type: REMOVE_USER_COMPETENCE,
    payload: {
      userCompetence
    }
  };
}

export function editUserCompetenceSuccess(userCompetence) {
  return {
    type: EDIT_USER_COMPETENCE,
    payload: {
      userCompetence
    }
  };
}

export function addUserCompetence(userCompetence) {
  return (dispatch) => {
    return axios.post(URLS.userCompetencies, userCompetence)
      .then(({ data }) => dispatch(addUserCompetenceSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during user competence adding ${error.message}`, error));
      });
  };
}

export function removeUserCompetence(userCompetence) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.userCompetencies, userCompetence.id))
      .then(() => dispatch(removeUserCompetenceSuccess(userCompetence)))
      .catch((error) => {
        dispatch(addError(`Error during user competence removing ${error.message}`, error));
      });
  };
}

export function editUserCompetence(userCompetence) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.userCompetencies, userCompetence.id), userCompetence)
      .then(({ data }) => dispatch(editUserCompetenceSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during user competence editing ${error.message}`, error));
      });
  };
}

export function fetchUserCompetence({ userId }) {
  return (dispatch) => {
    dispatch(fetchUserCompetenceRequest());

    const queryParameters = `?${getUserSettingsParametersFilter(userId)}`;

    return axios.get(urlJoin(URLS.userCompetencies, queryParameters))
      .then(({ data }) => dispatch(fetchUserCompetenciesSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during user competence fetching ${error.message}`, error));
      });
  };
}
