import React, { Component } from 'react';
import { componentPermission } from '../../../decorators/permissions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import UserTabsView from '../UserTabsView';

@componentPermission(PERMISSIONS.myProfileView)
export default class MyProfile extends Component {
  render() {
    const user = localStorage.getItem('user');

    return (
      <UserTabsView
        userId={JSON.parse(user).id.toString()}
        isMyProfile
        editMode
      />
    );
  }
}
