import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';

import _each from 'lodash/each';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Form from '../../../common/components/forms/Form';
import MaterialInput from '../../../common/components/forms/MaterialInput';
import { fetchRssNews } from '../../actions/rssNews';
import { editUsersProfile } from '../../actions/users';
import validators from '../../../utils/validators';

import './styles.scss';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  uploadContainer: {
    position: 'relative',
    overflow: 'hidden'
  },
  textField: {
    padding: '1px'
  },
  iconColor: {
    color: '#ee802f'
  }
});

const mapStateToProps = (state) => {
  return {
    rssNews: state.rssNews.rssNews,
    rssNewsRequest: state.rssNews.rssNewsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchRssNews: (urlNews) => dispatch(fetchRssNews(urlNews)),
    editUsersProfile: (id, data) => dispatch(editUsersProfile(id, data)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class RssNews extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    editUsersProfile: PropTypes.func.isRequired,
    fetchRssNews: PropTypes.func.isRequired,
    rssNews: PropTypes.array.isRequired,
    rssNewsRequest: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = {
      editOpen: false,
      rssUrl: _get(this.props.userProfile, 'rssUrl', '')
    };
  }

  componentDidMount() {
    if (!_isEmpty(this.state.rssUrl)) {
      this.props.fetchRssNews(this.state.rssUrl);
    }
  }

   onChange = (formData) => {
     _each(formData, ({ value }, key) => {
       this.setState({
         [key]: value,
       });
     });
   };


  onFormValidated = async (isFormValid) => {
    if (isFormValid) {
      const userId = this.props.userProfile.id;
      const editUsersProfile = this.props.editUsersProfile(userId, { rssUrl: this.state.rssUrl });
      const fetchRssNews = this.props.fetchRssNews(this.state.rssUrl);

      await editUsersProfile;
      await fetchRssNews;

      this.handleDialogClose();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  dialogContent = (classes, title, t) => {
    const { rssUrl } = this.state;

    return (
      <div>
        <DialogTitle id="add-license-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div>
            <MaterialInput
              className={classes.textField}
              margin="dense"
              name={'rssUrl'}
              label={t('input:rssUrl')}
              fullWidth
              validators={[
                new validators.IsRequired(t),
                new validators.AdvertUrl(t)
              ]}
              defaultValue={rssUrl}
            />
          </div>
        </DialogContent>
      </div>
    );
  };

  handleDialogClose = () => {
    this.setState({
      editOpen: false,
    });
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  handleEditOpen = () => {
    this.setState({
      editOpen: true
    });
  };

  renderRssNews = (rssNews) => {
    const { classes, t } = this.props;

    if (rssNews) {
      return rssNews.map((news, index)=> {
        return (
          <>
          <div>
            <a
              target="_blank"
              href={news.link}
            >
              <h5 className={classNames(classes.iconColor, 'rss-news-title pb-2')}>{news.title}</h5>
            </a>
            <h6 className="rss-news-description pl-2 my-1 pb-4" dangerouslySetInnerHTML={{ __html: news.description }} />
          </div>
            { index < 2 && <Divider /> }
          </>
        );
      });
    }

    return (
      <div className="p-2">
        {t('dashboard:thereIsNoNews')}
      </div>
    );
  };

  render() {
    const { classes, rssNews, t } = this.props;
    const { editOpen } = this.state;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <header className="row justify-content-lg-between justify-content-end align-items-center header-dashboard pr-4">
          <span className={classNames('span-dashboard', 'py-3 pl-3')}>
            <Icon className={classNames(classes.iconColor, 'notification-icon')}>
                rss_feed
            </Icon>
            <h4 className="h4-dashboard">{t('dashboard:rssName')}</h4>
          </span>
          <IconButton
            color="primary"
            className={classes.iconColor}
            onClick={() => this.handleEditOpen()}
          >
            <Icon
              color="primary"
              className={classNames(classes.iconColor, 'edit-pen-icon')}
            >
                mode_edit
            </Icon>
          </IconButton>
        </header>
        <div className="pb-2 px-2 dashboard">
          <div>
            { this.renderRssNews(rssNews)}
          </div>
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <Dialog
              PaperProps={{
                classes: { root: classes.root }
              }}
              open={editOpen}
              onClose={this.handleDialogClose}
              fullWidth
            >
              {this.dialogContent(classes, t('dashboard:rssDialogTitle'), t)}
              {this.dialogActions(t)}
            </Dialog>
          </Form>
        </div>
      </Paper>
    );
  }
}
