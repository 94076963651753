import {
  ADD_FLEET,
  CLEAN_FLEET_DATA,
  EDIT_FLEET,
  FETCH_FLEETS_PAGINATION_REQUEST,
  FETCH_FLEETS_PAGINATION_SUCCESS,
  FETCH_FLEETS_REQUEST,
  FETCH_FLEETS_SUCCESS,
  FETCH_FLEET_REQUEST,
  FETCH_FLEET_SUCCESS,
  REMOVE_FLEET,
} from '../actions/fleet';

const INITIAL_STATE = {
  fleet: {},
  fleetList: [],
  fleetListPaginationNext: null,
  fleetPaginationRequest: false,
  fleetRequest: false,
};

function fetchFleetsSuccess(state, payload) {
  return {
    ...state,
    fleetList: payload.fleet.results,
    fleetListPaginationNext: payload.fleet.next,
    fleetRequest: false,
  };
}

function fetchFleetsPaginationSuccess(state, payload) {
  return {
    ...state,
    fleetList: [...state.fleetList, ...payload.fleet.results],
    fleetListPaginationNext: payload.fleet.next,
    fleetRequest: false,
    fleetPaginationRequest: false,
  };
}

function fetchFleetsRequest(state) {
  return {
    ...state,
    fleetRequest: true,
    fleetPaginationRequest: false,
  };
}

function fetchFleetsPaginationRequest(state) {
  return {
    ...state,
    fleetRequest: false,
    fleetPaginationRequest: true,
  };
}

function fetchFleetSuccess(state, payload) {
  return {
    ...state,
    fleet: payload.fleet,
    fleetRequest: false,
  };
}

function fetchFleetRequest(state) {
  return {
    ...state,
    fleetRequest: true,
  };
}

function addFleet(state, payload) {
  return {
    ...state,
    fleetList: [...state.fleetList, payload.fleet],
  };
}

function editFleet(state, payload) {
  return {
    ...state,
    fleetList: state.fleetList.map((fleet) => {
      if (fleet.id === payload.fleet.id) {
        return payload.fleet;
      }

      return fleet;
    }),
  };
}

function removeFleet(state, payload) {
  return {
    ...state,
    fleetList: state.fleetList.filter((fleet) => fleet.id !== payload.fleetId),
  };
}

function cleanFleet(state) {
  return {
    ...state,
    fleet: {},
  };
}

const actions = {
  [FETCH_FLEETS_SUCCESS]: fetchFleetsSuccess,
  [FETCH_FLEETS_REQUEST]: fetchFleetsRequest,
  [FETCH_FLEET_SUCCESS]: fetchFleetSuccess,
  [FETCH_FLEETS_PAGINATION_REQUEST]: fetchFleetsPaginationRequest,
  [FETCH_FLEETS_PAGINATION_SUCCESS]: fetchFleetsPaginationSuccess,
  [FETCH_FLEET_REQUEST]: fetchFleetRequest,
  [ADD_FLEET]: addFleet,
  [EDIT_FLEET]: editFleet,
  [REMOVE_FLEET]: removeFleet,
  [CLEAN_FLEET_DATA]: cleanFleet,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
