import React, { Component } from 'react';
import {
  renderDateColor,
  renderPartColor,
  renderTickerToService,
} from '../fleetHelper';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import FileInput from '../../../../common/components/upload/FileInput';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 180,
  },
  colorCell: {
    width: 20,
  },
});

@withNamespaces()
@withStyles(styles)
export default class PartList extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    partList: PropTypes.array.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    handleUnmountOpen: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    attachPartFile: PropTypes.func.isRequired,
    removePartFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    fleetSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    undoPart: PropTypes.func.isRequired,
  };

  renderFiles(part) {
    const { editMode, removePartFile, onElementDelete } = this.props;
    const partId = part.id;

    return part.files.map((file) => {
      if (editMode) {
        return (
          <Chip
            key={`part-${partId}-file-${file.id}`}
            label={file.name}
            onClick={() => window.open(file.path, '_blank')}
            onDelete={() =>
              onElementDelete(() => removePartFile(file.id, partId))
            }
            className="my-1"
          />
        );
      }

      return (
        <Chip
          key={`part-${partId}-file-${file.id}`}
          label={file.name}
          onClick={() => window.open(file.path, '_blank')}
          className="my-1"
        />
      );
    });
  }

  renderButtons(part) {
    const {
      handleEditOpen,
      handleUnmountOpen,
      fleet,
      attachPartFile,
      addSimpleError,
      editMode,
    } = this.props;

    if (!editMode) {
      return null;
    }

    return (
      <>
        <span className="file-inputs">
          <CustomIconButton>
            <FileInput
              attachFile={(file) => attachPartFile(file, part.id, fleet.id)}
              addSimpleError={addSimpleError}
              required
            />
            <Icon color="primary">attach_file</Icon>
          </CustomIconButton>
        </span>
        <IconButton onClick={() => handleEditOpen(part)}>
          <Icon color="primary">mode_edit</Icon>
        </IconButton>
        <IconButton onClick={() => handleUnmountOpen(part)}>
          <Icon color="primary">delete</Icon>
        </IconButton>
      </>
    );
  }

  renderPartList() {
    const { partList, classes, fleet, fleetSettings } = this.props;

    return partList.map((part) => {
      return (
        <TableRow key={`part-${part.id}`}>
          <TableCell className={classes.colorCell}>
            {renderPartColor(part, fleet, fleetSettings)}
          </TableCell>
          <TableCell className={classes.longCell}>{part.name}</TableCell>
          <TableCell className={classes.longCell}>{part.partNumber}</TableCell>
          <TableCell className={classes.longCell}>
            {part.serialNumber}
          </TableCell>
          <TableCell className={classes.longCell}>{part.partStatus}</TableCell>

          <TableCell className={classes.longCell}>
            {renderTickerToService(
              part,
              'serviceHours',
              fleetSettings.hoursWarning,
              fleet.currentHours,
            )}
          </TableCell>
          <TableCell className={classes.longCell}>
            {renderTickerToService(
              part,
              'serviceCycles',
              fleetSettings.cyclesWarning,
              fleet.currentCycles,
            )}
          </TableCell>
          <TableCell className={classes.longCell}>
            {renderTickerToService(
              part,
              'eolCycles',
              fleetSettings.cyclesWarning,
              fleet.currentCycles,
            )}
          </TableCell>

          <TableCell className={classes.longCell}>
            {renderDateColor(part, 'serviceDate')}
          </TableCell>
          <TableCell className={classes.longCell}>
            {renderDateColor(part, 'eolDate')}
          </TableCell>

          <TableCell className={classes.longCell}>
            {this.renderFiles(part)}
          </TableCell>

          <TableCell className={classes.iconsCell} numeric>
            {this.renderButtons(part)}
          </TableCell>
        </TableRow>
      );
    });
  }

  handleUndoPart = () => {
    const { partList, undoPart } = this.props;

    const lastInstallation = partList[partList.length - 1];

    undoPart(lastInstallation);
  };

  renderAddPartButton() {
    const { editMode, handleAddOpen, partList, t } = this.props;

    if (!editMode) {
      return null;
    }

    return (
      <div className="col-12">
        <Button color="primary" className="mt-3" onClick={handleAddOpen}>
          {t('fleet:installPart')}
        </Button>
        {partList.length > 0 && (
          <Button
            color="secondary"
            variant="raised"
            className="ml-3 mt-3"
            onClick={this.handleUndoPart}
          >
            {t('fleet:undo')}
          </Button>
        )}
      </div>
    );
  }

  render() {
    const { partList, classes, t } = this.props;

    if (_isEmpty(partList)) {
      return this.renderAddPartButton();
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:color')}</TableCell>
              <TableCell>{t('table:name')}</TableCell>
              <TableCell>{t('table:partNumber')}</TableCell>
              <TableCell>{t('table:serialNumber')}</TableCell>
              <TableCell>{t('table:status')}</TableCell>
              <TableCell>{t('table:hoursToService')}</TableCell>
              <TableCell>{t('table:cyclesToService')}</TableCell>
              <TableCell>{t('table:cyclesToEol')}</TableCell>
              <TableCell>{t('table:serviceDate')}</TableCell>
              <TableCell>{t('table:eolDate')}</TableCell>
              <TableCell>{t('table:attachments')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderPartList()}</TableBody>
        </Table>
        {this.renderAddPartButton()}
      </div>
    );
  }
}
