import {
  FETCH_LOCATIONS_SUCCESS,
  FETCH_ALL_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_FAILURE,
  ADD_LOCATION,
  EDIT_LOCATION,
  REMOVE_LOCATION,
} from '../actions/locations';

const INITIAL_STATE = {
  locationList: [],
  allLocationList: [],
  locationRequest: false,
};

function fetchLocationsSuccess(state, payload) {
  return {
    ...state,
    locationList: payload.locations,
    locationRequest: false,
  };
}

function fetchAllLocationsSuccess(state, payload) {
  return {
    ...state,
    allLocationList: payload.allLocations,
    locationRequest: false,
  };
}

function fetchLocationsRequest(state) {
  return {
    ...state,
    locationRequest: true,
  };
}

function fetchLocationsFailure(state) {
  return {
    ...state,
    locationRequest: false,
  };
}

function addLocation(state, payload) {
  return {
    ...state,
    locationList: [
      ...state.locationList,
      payload.location
    ],
    allLocationList: [
      ...state.allLocationList,
      payload.location
    ]
  };
}

function editLocation(state, payload) {
  return {
    ...state,
    allLocationList: state.allLocationList.map((location) => {
      if (location.id === payload.location.id) {
        return payload.location;
      }

      return location;
    }),
    locationList: state.locationList.map((location) => {
      if (location.id === payload.location.id) {
        return payload.location;
      }

      return location;
    })
  };
}

function removeLocation(state, payload) {
  return {
    ...state,
    allLocationList: state.allLocationList.filter((location) => location !== payload.location),
    locationList: state.locationList.filter((location) => location !== payload.location)
  };
}


const actions = {
  [FETCH_LOCATIONS_SUCCESS]: fetchLocationsSuccess,
  [FETCH_ALL_LOCATIONS_SUCCESS]: fetchAllLocationsSuccess,
  [FETCH_LOCATIONS_REQUEST]: fetchLocationsRequest,
  [FETCH_LOCATIONS_FAILURE]: fetchLocationsFailure,
  [ADD_LOCATION]: addLocation,
  [EDIT_LOCATION]: editLocation,
  [REMOVE_LOCATION]: removeLocation,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
