import { LESSON_TYPES, ROLES } from '../constants';

import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import properUrlJoin from 'proper-url-join';

const hasRole = (user, role) => {
  if (!user.groups) {
    return false;
  }

  return !!user.groups.find((group) => group.name === role);
};

export const answerLetters = ['a', 'b', 'c', 'd'];
export const defaultCrewStatusFilerMonthsValue = 36;

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);

  error.response = response;
  throw error;
}

export function parseJSON(response) {
  return response.json();
}

export function toUppercase(string) {
  return string.toUpperCase();
}

export function toLowercase(string) {
  return string.toLowerCase();
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDjangoError(error) {
  return (
    _get(error, 'response.data.detail') ||
    _get(error, 'response.data.nonFieldErrors[0]')
  );
}

export function urlJoin(...args) {
  return properUrlJoin(...args, { trailingSlash: true });
}

export function isAdmin(user) {
  return hasRole(user, ROLES.admin);
}

export function isInstructor(user) {
  return hasRole(user, ROLES.instructor);
}

export function isSuperAdmin(user) {
  return !!user.isSuperuser;
}

export function isManager(user) {
  return hasRole(user, ROLES.manager);
}

export function swapItems(items, dragIndex, hoverIndex) {
  const dragItem = items[dragIndex];

  items.splice(dragIndex, 1);
  items.splice(hoverIndex, 0, dragItem);
}

export function isTheoreticalLesson(lessonType) {
  return lessonType.shortname === LESSON_TYPES.theoretical;
}

export function isProgressCheckLesson(lessonType) {
  return lessonType.shortname === LESSON_TYPES.progressCheck;
}

export function isPracticalLesson(lessonType) {
  return lessonType.shortname === LESSON_TYPES.practical;
}

export function bytesToMegaBYTES(bytes) {
  return bytes * 0.000001;
}

export function trimToLongText(text, numberOfCharacters) {
  if (text.length > numberOfCharacters) {
    return `${text.substring(0, numberOfCharacters)}...`;
  }

  return text;
}

export function isCourseCompleted(course) {
  return !_isNil(course.endDate);
}

export function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export function getUserSettingsParametersFilter(userId) {
  return `user__id=${encodeURIComponent(userId)}`;
}

export function convertHex(hex, opacity) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
}
