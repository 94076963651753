import '../styles.scss';

import {
  DEPENDENT_NAMES_DICTIONARY,
  DEPENDENT_NAMES_LIST,
  ZERO_TIME,
} from '../../../../constants';
import React, { Component } from 'react';
import {
  addJourneyLog,
  cleanJourneyLogData,
  editJourneyLog,
} from '../../../actions/journeyLog';
import {
  checkEnteredTimeFormat,
  dateTimeDiffInTimeFormat,
  getDateFormat,
  getDateTimeFormat,
  getDependentTimesDistance,
  getTimeFormat,
  reformatPickerDateFromDB,
  reformatPickerDateTimeFromDB,
  timeToMinutes,
} from '../../../utils/time';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Form from '../../../../common/components/forms/Form';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import { addJourneyLogFunction } from './addJourneyLogHelper';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { editUserSettings } from '../../../actions/userSettings';
import { fetchLocations } from '../../../actions/locations';
import { fetchTypes } from '../../../actions/types';
import moment from 'moment';
import userService from '../../../../utils/userService';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '105px',
  },
  coPilotName: {
    width: '200px',
  },
  totalTime: {
    width: '120px',
    fontWeight: 'bold',
  },
});

const mapStateToProps = (state) => {
  return {
    locationList: state.locations.locationList.filter(
      (location) => location.isActive,
    ),
    typeList: state.types.typeList,
    typeRequest: state.types.typeRequest,
    locationRequest: state.locations.locationRequest,
    addJourneyLogSuccess: state.journeyLog.addJourneyLogSuccess,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchData: (userId) => {
      dispatch(fetchLocations(userId));
      dispatch(fetchTypes(userId));
    },
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    editUserSettings: (userId, userSettings) =>
      dispatch(editUserSettings(userId, userSettings)),
    addJourneyLog: (journeyLog) => dispatch(addJourneyLog(journeyLog)),
    editJourneyLog: (id, journeyLog) =>
      dispatch(editJourneyLog(id, journeyLog)),
    cleanJourneyLogData: () => dispatch(cleanJourneyLogData()),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class AddJourneyLog extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    addJourneyLog: PropTypes.func.isRequired,
    editJourneyLog: PropTypes.func.isRequired,
    cleanJourneyLogData: PropTypes.func.isRequired,
    userSettings: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    typeList: PropTypes.array,
    locationList: PropTypes.array,
    typeRequest: PropTypes.bool.isRequired,
    locationRequest: PropTypes.bool.isRequired,
    addJourneyLogSuccess: PropTypes.bool.isRequired,
    fetchData: PropTypes.func,
    previewMode: PropTypes.bool,
    editMode: PropTypes.bool,
    journeyLog: PropTypes.object,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = {
      remarks: this.getValueFromJourneyLogOrUserSettings(
        'remarks',
        'remarks',
        '',
      ),
      departure: this.getValueFromJourneyLogOrUserSettings(
        'departure',
        'departure.name',
        '',
      ),
      arrival: this.getValueFromJourneyLogOrUserSettings(
        'arrival',
        'arrival.name',
        '',
      ),
      type: this.getValueFromJourneyLogOrUserSettings('type.id', 'type.id', ''),
      dateOfJourneyLog: this.getValueFromJourneyLogOrUserSettings(
        'dateOfJourneyLog',
        'dateOfJourneyLog',
        moment(),
      ),
      departureTime: this.getValueFromJourneyLogOrUserSettings(
        'departureDate',
        'departureDate',
        moment(),
      ),
      arrivalTime: this.getValueFromJourneyLogOrUserSettings(
        'arrivalDate',
        'arrivalDate',
        moment(),
      ),
      totalTime: this.getValueFromJourneyLogOrUserSettings(
        'totalTime',
        'totalTime',
        ZERO_TIME,
      ),
      isFormValid: false,
      autoSuggestionDepartureisValid: false,
      autoSuggestionArrivalisValid: false,
    };
  }

  componentDidMount() {
    const { fetchData } = this.props;
    const user = localStorage.getItem('user');
    const userId = JSON.parse(user).id.toString();

    this.formData = {};

    fetchData(userId);
  }

  componentWillUnmount() {
    this.props.cleanJourneyLogData();
  }

  getValueFromJourneyLogOrUserSettings = (
    valueFromJourneyLog,
    valueFromUserSettings,
    defaultValue,
  ) => {
    const { previewMode, editMode } = this.props;

    if (previewMode || editMode) {
      return _get(this.props.journeyLog, valueFromJourneyLog, defaultValue);
    }

    return _get(this.props.userSettings, valueFromUserSettings, defaultValue);
  };

  getTotalTime = (name, date) => {
    const { arrivalTime, departureTime } = this.state;
    const { editMode } = this.props;
    let totalTime = ZERO_TIME;

    if (name === 'arrivalTime') {
      totalTime = editMode
        ? dateTimeDiffInTimeFormat(
            date,
            reformatPickerDateTimeFromDB(departureTime),
          )
        : dateTimeDiffInTimeFormat(date, departureTime);
    } else {
      totalTime = editMode
        ? dateTimeDiffInTimeFormat(
            reformatPickerDateTimeFromDB(arrivalTime),
            date,
          )
        : dateTimeDiffInTimeFormat(arrivalTime, date);
    }

    return totalTime;
  };

  calculateTheDependentTimes = (changedValue) => {
    const { totalTime } = this.state;
    let result = ZERO_TIME;

    if (checkEnteredTimeFormat(changedValue)) {
      result = getDependentTimesDistance(totalTime, changedValue);
    }

    return result;
  };

  checkValue = (value) => {
    const { totalTime } = this.state;

    if (
      checkEnteredTimeFormat(value) &&
      timeToMinutes(value) > timeToMinutes(totalTime)
    ) {
      return ZERO_TIME;
    }

    return value;
  };

  onChange = (formData, name) => {
    const { value } = formData[name];

    if (DEPENDENT_NAMES_LIST.includes(name)) {
      this.setState({
        [name]: this.checkValue(value),
        [DEPENDENT_NAMES_DICTIONARY[name]]: this.calculateTheDependentTimes(
          this.checkValue(value),
        ),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  getUserFromSessionStorage = () => {
    return userService.getCurrentUser().id;
  };

  getUserId = () => {
    const { journeyLog, editMode } = this.props;

    return editMode
      ? _get(journeyLog, 'user.id', this.getUserFromSessionStorage())
      : this.getUserFromSessionStorage();
  };

  prepareJourneyLogData = () => {
    const { typeList, locationList, userSettings, editMode } = this.props;
    const {
      remarks,
      type,
      dateOfJourneyLog,
      totalTime,
      departure,
      departureTime,
      arrivalTime,
      arrival,
    } = this.state;

    const userId = this.getUserId();

    let departureDateTime = departureTime;
    let arrivalDateTime = arrivalTime;

    if (editMode) {
      departureDateTime = reformatPickerDateTimeFromDB(departureTime);
      arrivalDateTime = reformatPickerDateTimeFromDB(arrivalTime);
    }

    const newJourneyLog = addJourneyLogFunction({
      userId,
      remarks,
      type,
      dateOfJourneyLog,
      totalTime,
      departure,
      departureDateTime,
      arrivalDateTime,
      arrival,
      typeList,
      locationList,
      userSettings,
    });

    return newJourneyLog;
  };

  addJourneyLog = () => {
    const newJourneyLog = this.prepareJourneyLogData();

    this.props.addJourneyLog(newJourneyLog);
  };

  editJourneyLog = () => {
    const { journeyLog } = this.props;
    const newJourneyLog = this.prepareJourneyLogData();

    this.props.editJourneyLog(journeyLog.id, newJourneyLog);
  };

  onFormValidated = (isFormValid) => {
    const { editMode } = this.props;

    if (isFormValid) {
      editMode ? this.editJourneyLog() : this.addJourneyLog();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.triggerFormValidation();
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  handleFlightDatePickerChange = (name) => (date) => {
    const { conditionTime, flightRule } = this.props.userSettings;
    const totalTime = this.getTotalTime(name, date);

    this.setState({
      [name]: date,
      totalTime,
      [conditionTime]: totalTime,
      [flightRule]: totalTime,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  menuItems(dataToShow, name, registration = false) {
    if (_isEmpty(dataToShow)) {
      return null;
    }

    return dataToShow.map((data) => {
      return (
        <MenuItem key={`${name}-${data.id}`} value={data.id}>
          {registration ? data.registration : data.name}
        </MenuItem>
      );
    });
  }

  renderDateTimePicker = (data) => {
    return (
      <DateTimePicker
        fullWidth
        value={data.value}
        showMonthDropdown
        showTimeSelect={data.showTimeSelect}
        showYearDropdown
        handleDatePickerChange={data.handleDatePickerChange}
        dateFormat={data.dateFormat}
        name={data.name}
        timeFormat={getTimeFormat()}
        label={data.label}
        disabled={data.disabled}
      />
    );
  };

  registerComponentForm(componentName) {
    return (triggerFn) => {
      this.triggers[componentName] = triggerFn;
    };
  }

  formValidated = (formName, isFormValid, formChanges) => {
    this.formData = {
      ...this.formData,
      [`${formName}Changes`]: formChanges,
      [`${formName}isValid`]: isFormValid,
    };
  };

  renderSelect = (
    inputName,
    inputLabel,
    value,
    changeFunction,
    previewMode,
  ) => {
    const { locationList } = this.props;

    return (
      <AutoSuggestion
        label={inputName}
        inputName={inputLabel}
        defaultValue={value ? value : ''}
        handleChange={changeFunction}
        typeList={locationList}
        preview={previewMode}
        fullWidth
      />
    );
  };

  renderTextField = (data) => {
    const { classes } = this.props;

    return (
      <div
        className={
          data.divStyle ? data.divStyle : 'col-lg-3 col-sm-5 col-xs-12'
        }
      >
        <TextField
          className={data.textStyle ? data.textStyle : classes.root}
          name={data.name}
          label={data.label}
          value={data.value}
          disabled
        />
      </div>
    );
  };

  renderJourneyLogEditButton() {
    const { journeyLog, previewMode } = this.props;

    if (!previewMode) {
      return <div />;
    }

    return (
      <div className="col-auto">
        <Link
          to={{
            pathname: `/journey_log/edit/${journeyLog.id}`,
            state: { editMode: true },
          }}
        >
          <IconButton>
            <Icon>mode_edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  renderViewLabel = () => {
    const { editMode, previewMode } = this.props;

    if (editMode) {
      return '/ edit mode';
    } else if (previewMode) {
      return '/ preview mode';
    }

    return '';
  };

  render() {
    const { classes, locationRequest, typeRequest, typeList, previewMode, t } =
      this.props;
    const {
      dateOfJourneyLog,
      departureTime,
      arrivalTime,
      departure,
      arrival,
      remarks,
      type,
      totalTime,
    } = this.state;

    if (locationRequest || typeRequest) {
      return <Loader />;
    }

    return (
      <div className="col-auto logbook-wrapper add-logbook ">
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <div className="row justify-content-lg-between justify-content-end align-items-center">
            <div className="col-auto section-title pb-5">
              <header>
                <span>
                  <Icon color="primary">directions_railway</Icon>
                  <h1>
                    {t('logbook:name')}
                    <span className="h1-subheader">
                      {this.renderViewLabel()}
                    </span>
                  </h1>
                </span>
              </header>
            </div>
            {this.renderJourneyLogEditButton()}
          </div>
          <div className="col-xl-3 col-sm-5 col-xs-12 py-2 pl-0">
            {this.renderDateTimePicker({
              value: reformatPickerDateFromDB(dateOfJourneyLog),
              showTimeSelect: false,
              handleDatePickerChange:
                this.handleDatePickerChange('dateOfJourneyLog'),
              dateFormat: getDateFormat(),
              name: 'dateOfJourneyLog',
              label: t('input:dateOfJourneyLog'),
              disabled: previewMode,
            })}
          </div>
          <div className="col-xs-12 py-3">
            <p>{t('logbook:journeySection')}</p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-5 col-xs-12">
              {this.renderSelect(
                'departure',
                'departure',
                departure,
                this.handleSelectChange,
                previewMode,
              )}
            </div>
            <div className="col-lg-2 col-sm-5 col-xs-12 p-lg-0">
              {this.renderDateTimePicker({
                value: reformatPickerDateTimeFromDB(departureTime),
                showTimeSelect: true,
                handleDatePickerChange:
                  this.handleFlightDatePickerChange('departureTime'),
                dateFormat: getDateTimeFormat(),
                name: 'departureTime',
                label: t('input:departureTimeJourneyLog'),
                disabled: previewMode,
              })}
            </div>
            <div className="col-lg-3 col-sm-5 col-xs-12">
              {this.renderSelect(
                'arrival',
                'arrival',
                arrival,
                this.handleSelectChange,
                previewMode,
              )}
            </div>
            <div className="col-lg-2 col-sm-5 col-xs-12 p-lg-0">
              {this.renderDateTimePicker({
                value: reformatPickerDateTimeFromDB(arrivalTime),
                showTimeSelect: true,
                handleDatePickerChange:
                  this.handleFlightDatePickerChange('arrivalTime'),
                dateFormat: getDateTimeFormat(),
                name: 'arrivalTime',
                label: t('input:arrivalTimeInJourneyLog'),
                disabled: previewMode,
              })}
            </div>
            {this.renderTextField({
              divStyle: 'col-lg-2 col-sm-5 col-xs-12 pt-2' + '',
              textStyle: classes.totalTime,
              name: 'totalTime',
              label: t('input:totalTime'),
              value: totalTime,
            })}
          </div>
          <div className="col-xs-12 py-3">
            <p>{t('type')}</p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-5 col-xs-12">
              <MaterialSelect
                id="type"
                name="type"
                label={t('input:type')}
                defaultValue={type}
                options={typeList.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
                disabled={previewMode}
                validators={[new validators.IsRequired(t)]}
              />
            </div>
          </div>
          <div className="col-xs-12 pt-3">
            <p>{t('logbook:notesSection')}</p>
          </div>
          <div className="col-xs-12 pl-0">
            <TextField
              label={t('input:remarksAndEndorsments')}
              value={remarks}
              margin="dense"
              fullWidth
              multiline
              disabled={previewMode}
              onChange={this.handleChange('remarks')}
            />
          </div>
          <div className="justify-content-center row py-5">
            <div className="col-auto">
              <Link to={'/my_logbook'}>
                <Button variant="raised">{t('buttonCancel')}</Button>
              </Link>
            </div>
            <div className="col-auto">
              {!previewMode && (
                <Button
                  color="primary"
                  variant="raised"
                  onClick={this.sendHandler}
                >
                  {t('buttonSave')}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
