import {
  ADD_LICENSE,
  REMOVE_LICENSE,
  EDIT_LICENSE,
  FETCH_LICENSES_SUCCESS,
  FETCH_ALL_LICENSES_SUCCESS,
  FETCH_LICENSES
} from '../actions/licenses';
import {
  REMOVE_LICENSE_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  licenseList: [],
  allLicensesList: [],
  licenseRequest: false
};

function fetchLicensesSuccess(state, payload) {
  return {
    ...state,
    licenseList: payload.licenses,
    licenseRequest: false,
  };
}

function fetchAllLicensesSuccess(state, payload) {
  return {
    ...state,
    allLicensesList: payload.allLicenses,
    licenseRequest: false
  };
}

function fetchAllLicenses(state) {
  return {
    ...state,
    licenseRequest: true
  };
}

function addLicense(state, payload) {
  return {
    ...state,
    licenseList: [
      ...state.licenseList,
      payload.license
    ]
  };
}

function removeLicense(state, payload) {
  return {
    ...state,
    licenseList: state.licenseList.filter((license) => license !== payload.license)
  };
}

function editLicense(state, payload) {
  return {
    ...state,
    licenseList: state.licenseList.map((license) => {
      if (license.id === payload.license.id) {
        return payload.license;
      }

      return license;
    }),
    allLicensesList: state.allLicensesList.map((license) => {
      if (license.id === payload.license.id) {
        return payload.license;
      }

      return license;
    })
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    licenseList: state.licenseList.map((license) => {
      if (license.id === payload.licenseId) {
        return {
          ...license,
          files: license.files.filter((file) => file.id !== payload.fileId)
        };
      }

      return license;
    })
  };
}

const actions = {
  [ADD_LICENSE]: addLicense,
  [REMOVE_LICENSE]: removeLicense,
  [EDIT_LICENSE]: editLicense,
  [FETCH_LICENSES_SUCCESS]: fetchLicensesSuccess,
  [FETCH_ALL_LICENSES_SUCCESS]: fetchAllLicensesSuccess,
  [FETCH_LICENSES]: fetchAllLicenses,
  [REMOVE_LICENSE_FILE_SUCCESS]: removeFile,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
