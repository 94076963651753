import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import VehicleTypeList from './VehicleTypeList';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import Loader from '../../../../common/components/Loader';
import Form from '../../../../common/components/forms/Form';

import { fetchVehicleTypes, addVehicleType, removeVehicleType } from '../../../actions/vehicleTypes';
import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  vehicleTypeName: '',
};

const mapStateToProps = (state) => {
  return {
    vehicleTypeRequest: state.vehicleTypes.vehicleTypeRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchVehicleTypes: () => dispatch(fetchVehicleTypes()),
    addVehicleType: (name) => dispatch(addVehicleType(name)),
    removeVehicleType: (name) => dispatch(removeVehicleType(name)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleTypesManager extends Component {
  static propTypes = {
    vehicleTypeList: PropTypes.array.isRequired,
    vehicleTypeRequest: PropTypes.bool.isRequired,
    fetchVehicleTypes: PropTypes.func.isRequired,
    addVehicleType: PropTypes.func.isRequired,
    removeVehicleType: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { fetchVehicleTypes } = this.props;

    fetchVehicleTypes();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleAddClose = () => {
    this.setState(INITIAL_STATE);
  };

  addVehicleType = () => {
    const { vehicleTypeName } = this.state;
    const dataToSave = { name: vehicleTypeName };

    this.props.addVehicleType(dataToSave);
    this.handleAddClose();
  };

  removeVehicleType = (vehicleType) => {
    this.props.removeVehicleType(vehicleType);
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.addVehicleType();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { vehicleTypeList, vehicleTypeRequest, onElementDelete, t } = this.props;
    const { addOpen, vehicleTypeName } = this.state;

    if (vehicleTypeRequest) {
      return <Loader />;
    }

    return (
      <div className="vehicle-types-settings">
        <div className={'pl-sm-5'}>
          <h4>{t('settings:vehicleTypesSection')}</h4>
        </div>
        <VehicleTypeList
          vehicleTypeList={vehicleTypeList}
          removeVehicleType={this.removeVehicleType}
          handleAddOpen={this.handleAddOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen}
            onClose={this.handleAddClose}
            aria-labelledby="add-vehicle-type-dialog"
            fullWidth
          >
            <DialogTitle id="add-vehicle-type-dialog">{t('settings:addVehicleTypeDialogTitle')}</DialogTitle>
            <DialogContent>
              <MaterialInput
                label={t('input:name')}
                autoFocus
                name="vehicleTypeName"
                defaultValue={vehicleTypeName}
                margin="dense"
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleAddClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
