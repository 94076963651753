import axios from 'axios';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';
import history from '../store/history';
import { fileUploadPromise } from '../../utils/fileUpload';

export const FETCH_QUESTIONS_REQUEST = 'FETCH_QUESTIONS_REQUEST';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_FAILURE = 'FETCH_QUESTIONS_FAILURE';
export const EDIT_QUESTION = 'EDIT_QUESTION';
export const ADD_QUESTION = 'ADD_QUESTION';
export const FETCH_QUESTION_REQUEST = 'FETCH_QUESTION_REQUEST';
export const FETCH_QUESTION_SUCCESS = 'FETCH_QUESTION_SUCCESS';
export const FETCH_QUESTION_FAILURE = 'FETCH_QUESTION_FAILURE';

export function fetchQuestionsRequest() {
  return {
    type: FETCH_QUESTIONS_REQUEST
  };
}

export function fetchQuestionsSuccess(questions) {
  return {
    type: FETCH_QUESTIONS_SUCCESS,
    payload: {
      questions
    }
  };
}

export function fetchQuestionsFailure() {
  return {
    type: FETCH_QUESTIONS_FAILURE
  };
}

export function addQuestionSuccess(question) {
  return {
    type: ADD_QUESTION,
    payload: {
      question
    }
  };
}

export function fetchQuestionRequest() {
  return {
    type: FETCH_QUESTION_REQUEST
  };
}

export function fetchQuestionSuccess(question) {
  return {
    type: FETCH_QUESTION_SUCCESS,
    payload: {
      question
    }
  };
}

export function fetchQuestionFailure() {
  return {
    type: FETCH_QUESTION_FAILURE
  };
}

export function editQuestionSuccess(question) {
  return {
    type: EDIT_QUESTION,
    payload: {
      question
    }
  };
}

export function fetchQuestions(sortingData) {
  return (dispatch) => {
    dispatch(fetchQuestionsRequest());

    let queryParameters = {};
    let queryParametersFilter = '';

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData && !_isEmpty(sortingData.columnToSort)) {
      const parsedColumnName = _snakeCase(sortingData.columnToSort);

      queryParameters.ordering = `${(sortingData.sortAsc ? '' : '-')}${parsedColumnName}`;
    }

    if (sortingData && !_isEmpty(sortingData.filter)) {
      queryParametersFilter = sortingData.filter.map((filter) => {
        return `${'categories'}=${encodeURIComponent(filter.name)}`;
      }).join('&');
    }

    queryParameters = `${qs.stringify(queryParameters)}&${queryParametersFilter}`;
    queryParameters = _isEmpty(queryParameters) ? '' : `?${queryParameters}`;

    return axios.get(urlJoin(URLS.questions, queryParameters))
      .then(({ data }) => dispatch(fetchQuestionsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchQuestionsFailure());
        dispatch(addError(`Error during questions fetching ${error.message}`, error));
      });
  };
}

export function addQuestion(question, files, goBack) {
  return (dispatch) => {
    return axios.post(URLS.questions, question)
      .then(({ data }) => {
        if (!_isEmpty(files)) {
          const filesPromises = files.map((file) => {
            return fileUploadPromise(file, 'PATCH', urlJoin(URLS.attachQuestionFile, data.id), () => {});
          });

          data.files = [];

          return Promise.all(filesPromises).then(() => {
            return data;
          });
        }

        return data;
      })
      .then((data) => {
        dispatch(addQuestionSuccess(data));
        history.push(goBack);
      })
      .catch((error) => {
        dispatch(addError(`Error during question adding ${error.message}`, error));
      });
  };
}

export function fetchQuestion(questionId) {
  return (dispatch) => {
    dispatch(fetchQuestionRequest());

    return axios.get(urlJoin(URLS.questions, questionId))
      .then(({ data }) => {
        dispatch(fetchQuestionSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchQuestionFailure());
        dispatch(addError(`Error during question fetching ${error.message}`, error));
      });
  };
}

export function removeQuestion(question) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.removeQuestion, question.id), { isActive: false })
      .then(({ data }) => dispatch(editQuestionSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during question removing ${error.message}`, error));
      });
  };
}

export function editQuestion(question, updatedFiles, deletedFiles) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.questions, question.id), question)
      .then((data) => {
        deletedFiles.map((fileId) => {
          axios.delete(urlJoin(URLS.fileRemove, fileId));
        });

        return data;
      })
      .then(({ data }) => {
        if (!_isEmpty(updatedFiles)) {
          const filesPromises = updatedFiles.map((file) => {
            return fileUploadPromise(file, 'PATCH', urlJoin(URLS.attachQuestionFile, data.id), () => {
            });
          });

          data.files = [];

          return Promise.all(filesPromises).then(() => {
            return data;
          });
        }

        return data;
      })
      .then((data) => {
        dispatch(editQuestionSuccess(data));
        history.push('/question_bank');
      })
      .catch((error) => {
        dispatch(addError(`Error during question editing ${error.message}`, error));
      });
  };
}

