import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import ComplementaryCertificateList from './ComplementaryCertificateList';
import ComplementaryCertificateDialog from './ComplementaryCertificateDialog';
import { addComplementaryCertificate, removeComplementaryCertificate } from '../../../actions/complementaryCertificates';

import '../styles.scss';

const INITIAL_STATE = {
  open: false,
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addComplementaryCertificate: (data) => dispatch(addComplementaryCertificate(data)),
    removeComplementaryCertificate: (complementaryCertificate) => dispatch(removeComplementaryCertificate(complementaryCertificate)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class ComplementaryCertificate extends Component {
  static propTypes = {
    userDetails: PropTypes.object.isRequired,
    settings: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired,
    complementaryCertificateList: PropTypes.array.isRequired,
    addComplementaryCertificate: PropTypes.func.isRequired,
    removeComplementaryCertificate: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  renderAddButton(editMode, t) {
    if (editMode) {
      return (
        <Button className="my-2" color="primary" onClick={this.handleClickOpen}>{t('user:addcomplementaryCertificateButton')}</Button>
      );
    }

    return null;
  }

  renderComplementaryCertificateDialog() {
    const { complementaryCertificateList, addComplementaryCertificate, editMode, userId, settings, userDetails } = this.props;
    const { open } = this.state;

    if (open) {
      return (
        <ComplementaryCertificateDialog
          userId={userId}
          open={open}
          editMode={editMode}
          settings={settings}
          userDetails={userDetails}
          addComplementaryCertificate={addComplementaryCertificate}
          complementaryCertificateList={complementaryCertificateList}
          handleDialogClose={this.handleDialogClose}
        />
      );
    }

    return null;
  }

  render() {
    const { complementaryCertificateList, editMode, userId, t, removeComplementaryCertificate } = this.props;

    return (
      <div className="col-12 complementary-certificate">
        <h4>{t('user:complementaryCertificatesSection')}</h4>
        <ComplementaryCertificateList
          userId={userId}
          editMode={editMode}
          complementaryCertificateList={complementaryCertificateList}
          removeComplementaryCertificate={removeComplementaryCertificate}
        />
        {this.renderAddButton(editMode, t)}
        {this.renderComplementaryCertificateDialog(editMode, t)}
        <Divider className="my-3" />
      </div>
    );
  }
}
