import React, { Component } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { ROLES } from '../../../../constants';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { capitalizeFirstLetter } from '../../../../utils';
import userDefault from '../../../../images/profileDefault.png';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  smallAvatar: {
    width: 32,
    height: 32,
  },
  avatarCell: {
    width: 100,
  },
});

@withNamespaces()
@withStyles(styles)
export default class VehicleGroupUserList extends Component {
  static propTypes = {
    userList: PropTypes.array.isRequired,
    vehicleGroup: PropTypes.object.isRequired,
    removeRole: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderRemoveIcon = (removeRole, user, vehicleGroup, length) => {
    if (vehicleGroup.name === ROLES.admin && length <= 1) {
      return null;
    }

    return (
      <IconButton onClick={() => removeRole(user, vehicleGroup)}>
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  };

  renderUserList = () => {
    const { userList, removeRole, vehicleGroup, classes } = this.props;

    return userList.map((user, index, users) => {
      const profilePicture =
        user.userprofile && user.userprofile.profilePicture;
      const profileImage = profilePicture ? profilePicture : userDefault;

      return (
        <TableRow key={`group-${user.id}`}>
          <TableCell className={classes.avatarCell}>
            <Avatar src={profileImage} className={classes.smallAvatar} />
          </TableCell>
          <TableCell>
            {capitalizeFirstLetter(user.firstName)}{' '}
            {capitalizeFirstLetter(user.lastName)}
          </TableCell>
          <TableCell numeric>
            {this.renderRemoveIcon(
              removeRole,
              user,
              vehicleGroup,
              users.length,
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { vehicleGroup, handleAddOpen, classes, t } = this.props;
    const userAccounts = `settings:${vehicleGroup.name}`;

    return (
      <div className="pl-sm-5">
        <h4>{t(userAccounts)}</h4>

        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableBody>{this.renderUserList(vehicleGroup)}</TableBody>
            </Table>
          </div>
        </div>

        <Button color="primary" onClick={() => handleAddOpen(vehicleGroup)}>
          + {t('user:addUserButton')}
        </Button>
      </div>
    );
  }
}
