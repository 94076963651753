import React, { Component } from 'react';
import { getDateFormat, reformatPickerDateToDB } from '../../../utils/time';

import Button from '@material-ui/core/Button';
import ConsumablesManager from './ConsumablesManager';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Divider from '@material-ui/core/Divider';
import Form from '../../../../common/components/forms/Form';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PartsManager from './PartsManager';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import classNames from 'classnames';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const TABS = {
  parts: 'parts',
  consumables: 'consumables',
};

const TAB_COMPONENTS = {
  [TABS.parts]: PartsManager,
  [TABS.consumables]: ConsumablesManager,
};

const styles = () => ({
  tabs: {
    borderBottom: '4px solid #41A3F3',
  },
  basicTab: {
    marginRight: '5px',
  },
  active: {
    color: 'white',
    background: 'linear-gradient(#0D5AA7, #41A3F3)',
  },
  root: {
    color: '#4B4B4B',
    background: '#D3E5F6',
    '&:hover': {
      color: 'black',
      background: 'linear-gradient(#BCDEFF, #9CD1F9)',
    },
  },
  indicator: {
    display: 'none',
  },
});

@withNamespaces()
@withStyles(styles)
export default class WarehouseNew extends Component {
  static propTypes = {
    warehouse: PropTypes.object.isRequired,
    linkTo: PropTypes.string.isRequired,
    addWarehouse: PropTypes.func.isRequired,
    editWarehouse: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    warehouseSettings: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    partStatusList: PropTypes.array.isRequired,
    inspectionList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    previewMode: PropTypes.bool,
    editMode: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    let updatedBy = props.currentUser;
    let updatedAt = moment();

    if (props.previewMode) {
      updatedBy = props.warehouse.updatedBy;
      updatedAt = props.warehouse.updatedAt;
    }

    this.formData = {};
    this.state = {
      currentTab: TABS.parts,
      name: props.warehouse.name,
      address: props.warehouse.address,
      location: props.warehouse.location,
      contactDetails: props.warehouse.contactDetails,
      updatedById: updatedBy.id,
      updatedBy: `${updatedBy.firstName} ${updatedBy.lastName}`,
      updatedAt,
    };
  }

  renderViewLabel = (preview, editMode, t) => {
    if (preview) {
      return t('warehouse:previewWarehouseSection');
    } else if (editMode) {
      return t('warehouse:editWarehouseSection');
    }

    return t('warehouse:addWarehouseSection');
  };

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      [name]: dateFromPicker,
    });
  };

  onChange = (formData, name, form) => {
    const { t } = this.props;
    const { value } = formData[name];
    const errorTxt = t('error:isRequired');

    this.formData = formData;

    if (name === 'vehicleType') {
      form.invalidateRequired('vehicle', errorTxt);

      this.setState(() => ({
        [name]: value,
      }));
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  onFormValidated = (isFormValid) => {
    const { addWarehouse, editWarehouse, editMode, warehouse } = this.props;
    const { name, address, location, contactDetails, updatedById, updatedAt } =
      this.state;

    if (isFormValid && this.formData) {
      const warehouseData = {
        name,
        address,
        location,
        contactDetails,
        updatedBy: updatedById,
        updatedAt: reformatPickerDateToDB(updatedAt),
      };

      editMode
        ? editWarehouse({ ...warehouseData, id: warehouse.id })
        : addWarehouse(warehouseData);
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  renderButtons = (t) => {
    const { previewMode, linkTo } = this.props;

    return (
      <div className="justify-content-center row pt-1">
        <div className="col-auto">
          <Link to={linkTo}>
            <Button variant="raised">{t('buttonCancel')}</Button>
          </Link>
        </div>
        <div className="col-auto">
          {!previewMode && (
            <Button color="primary" variant="raised" onClick={this.sendHandler}>
              {t('buttonSave')}
            </Button>
          )}
        </div>
      </div>
    );
  };

  renderEditButton() {
    const { warehouse } = this.props;

    return (
      <div className="col-auto">
        <Link
          to={{
            pathname: `/warehouse/edit/${warehouse.id}`,
            state: { editMode: true },
          }}
        >
          <IconButton>
            <Icon>edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  handleTabChange = (event, value) => {
    this.setState({
      currentTab: value,
    });
  };

  renderAdditionalFields() {
    const { warehouse, t, classes } = this.props;
    const { currentTab } = this.state;

    if (!warehouse.id) {
      return <div />;
    }

    return (
      <>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          onChange={this.handleTabChange}
          classes={{ indicator: classes.indicator }}
        >
          {Object.keys(TABS).map((tab) => {
            const label = `fleet:${TABS[tab]}`;
            const isActive = TABS[tab] === currentTab;
            const className = classNames(
              classes.basicTab,
              isActive ? classes.active : classes.root,
            );

            return (
              <Tab
                className={className}
                key={`tab-${TABS[tab]}`}
                value={TABS[tab]}
                label={t(label)}
              />
            );
          })}
        </Tabs>
      </>
    );
  }

  renderTab() {
    const {
      warehouse,
      editMode,
      warehouseSettings,
      partStatusList,
      inspectionList,
    } = this.props;
    const { currentTab } = this.state;
    const TabName = TAB_COMPONENTS[currentTab];

    if (!warehouse.id) {
      return <div />;
    }

    return (
      <TabName
        warehouse={warehouse}
        editMode={editMode}
        warehouseSettings={warehouseSettings}
        inspectionList={inspectionList}
        partStatusList={partStatusList}
      />
    );
  }

  render() {
    const { t, previewMode, editMode } = this.props;
    const { name, address, location, contactDetails, updatedBy, updatedAt } =
      this.state;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center warehouse">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">store</Icon>
              <h1>{t('warehouse:name')}</h1>
            </span>
            <p>{this.renderViewLabel(previewMode, editMode, t)}</p>
          </header>
        </div>
        {previewMode && this.renderEditButton()}
        <div className="mt-4 col-12">
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <div className="row">
              <div className="col-6">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="name"
                  label={t('input:name')}
                  defaultValue={name}
                  disabled={previewMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-6">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="address"
                  label={t('input:address')}
                  defaultValue={address}
                  disabled={previewMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-6">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="location"
                  label={t('input:location')}
                  defaultValue={location}
                  disabled={previewMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-6">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="contactDetails"
                  label={t('input:contactDetails')}
                  defaultValue={contactDetails}
                  disabled={previewMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-6">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="updatedBy"
                  label={t('input:updatedBy')}
                  defaultValue={updatedBy}
                  disabled
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-6">
                <DateTimePicker
                  fullWidth
                  value={updatedAt}
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={this.handleDatePickerChange(
                    'updatedAt',
                  )}
                  dateFormat={getDateFormat()}
                  disabled
                  name="updatedAt"
                  label={t('input:updatedAt')}
                />
              </div>
              <div className="col-12 my-3">{this.renderButtons(t)}</div>
              <div className="col-12">
                <Divider className="mt-3 mb-5" />
                {this.renderAdditionalFields()}
                {this.renderTab()}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
