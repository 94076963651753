import '../styles.scss';

import React, { Component } from 'react';
import { addReport, editReport } from '../../../actions/reports';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';
import { isAdmin, isSuperAdmin } from '../../../../utils';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import FileInput from '../../../../common/components/upload/FileInput';
import Form from '../../../../common/components/forms/Form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { fetchFleets } from '../../../actions/fleet';
import { fetchReportCategories } from '../../../actions/reportCategories';
import { fetchReportFleetCategories } from '../../../actions/reportFleetCategories';
import { hasPermission } from '../../../decorators/permissions';
import { initializeReportCategories } from './helper';
import moment from 'moment';
import userService from '../../../../utils/userService';
import uuidv4 from 'uuid/v4';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    users: state.users.userList,
    settings: state.settings.settings,
    reportCategoryList: state.reportCategories.reportCategoryList,
    reportFleetCategoryRequest:
      state.reportFleetCategories.reportFleetCategoryRequest,
    reportFleetCategoryList:
      state.reportFleetCategories.reportFleetCategoryList,
    reportCategoryRequest: state.reportCategories.reportCategoryRequest,
    reportSaveRequest: state.reports.reportSaveRequest,
    fleets: state.fleet.fleetList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchReportCategories: () => dispatch(fetchReportCategories()),
    fetchReportFleetCategories: () => dispatch(fetchReportFleetCategories()),
    fetchFleets: () => dispatch(fetchFleets(null, 'no_pagination')),
    addReport: (report, files, userId) =>
      dispatch(addReport(report, files, userId)),
    editReport: (report, files) => dispatch(editReport(report, files, '')),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class ManageReports extends Component {
  static propTypes = {
    redirectUrl: PropTypes.string,
    report: PropTypes.object,
    reportCategoryList: PropTypes.array,
    reportFleetCategoryList: PropTypes.array,
    reportCategoryRequest: PropTypes.bool.isRequired,
    reportFleetCategoryRequest: PropTypes.bool.isRequired,
    reportSaveRequest: PropTypes.bool.isRequired,
    fetchFleets: PropTypes.func.isRequired,
    fetchReportCategories: PropTypes.func.isRequired,
    fetchReportFleetCategories: PropTypes.func.isRequired,
    fleets: PropTypes.array.isRequired,
    addReport: PropTypes.func.isRequired,
    editReport: PropTypes.func.isRequired,
    settings: PropTypes.array.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    preview: PropTypes.bool,
    myReports: PropTypes.bool,
    canEdit: PropTypes.bool,
    users: PropTypes.array.isRequired,
    closeReport: PropTypes.func,
  };

  state = {
    id: _get(this.props.report, 'id', ''),
    subject: _get(this.props.report, 'subject', ''),
    date: reformatPickerDateFromDB(_get(this.props.report, 'date', moment())),
    senderEmail: _get(this.props.report, 'senderEmail', ''),
    senderFirstName: _get(this.props.report, 'senderFirstName', ''),
    senderLastName: _get(this.props.report, 'senderLastName', ''),
    isConfidentiality: _get(this.props.report, 'isConfidentiality', false),
    isSent: _get(this.props.report, 'isSent', false),
    reportCategories: initializeReportCategories(this.props.report),
    flightNumber: _get(this.props.report, 'flightNumber', ''),
    departure: _get(this.props.report, 'departure', ''),
    destination: _get(this.props.report, 'destination', ''),
    issue: _get(this.props.report, 'issue', ''),
    solution: _get(this.props.report, 'solution', ''),
    files: _get(this.props.report, 'files', []),
    isTechnical: _get(this.props.report, 'isConfidentiality', false),
    nonSafetyRelated: _get(this.props.report, 'nonSafetyRelated', false),
    myReports: !hasPermission(PERMISSIONS.allReportsView),
    editMode: _get(this.props, 'canEdit', true),
    category: _get(this.props.report, 'subcategory', false)
      ? ''
      : _get(this.props.report, 'reportFleetCategory', ''),
    subcategory: _get(this.props.report, 'subcategory', false)
      ? _get(this.props.report, 'reportFleetCategory', '')
      : '',
    status: _get(this.props.report, 'status', 'NEW'),
  };

  componentDidMount() {
    const { fetchReportCategories, fetchReportFleetCategories, fetchFleets } =
      this.props;

    fetchReportCategories();
    fetchReportFleetCategories();
    fetchFleets();
  }

  renderReportStatus = (status, t) => {
    let badgeClass = 'h1-subheader badge bg-info text-light';

    switch (status) {
      case 'NEW':
      case 'CREATED':
        badgeClass = 'h1-subheader badge bg-success text-light';
        break;
      case 'CLOSED':
        badgeClass = 'h1-subheader badge bg-secondary text-light';
        break;
    }

    return <span className={badgeClass}>{t(`report:${status}`)}</span>;
  };

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    return _find(settings, { name: fieldName }).textValue;
  }

  onChange = (formData, name) => {
    this.formData = formData;

    if (name === 'category') {
      this.setState({
        [name]: this.formData[name].value,
        subcategory: '',
      });
    } else if (name === 'subcategory') {
      this.setState({
        [name]: this.formData[name].value,
      });
    }
  };

  handleEditSettingError = () => {
    if (this.state.infoSuccessfullySaved) {
      this.setState({
        infoSuccessfullySaved: false,
      });
    }
  };

  saveAndComplete = (e) => {
    this.setState({
      status: 'DONE',
    });
    this.sendHandler(e);
  };

  sendEmail = (e) => {
    this.setState({
      status: 'SENT',
    });
    this.sendHandler(e);
  };

  onFormValidated = (isFormValid) => {
    const { addReport, editReport, report } = this.props;
    const {
      date,
      isConfidentiality,
      nonSafetyRelated,
      reportCategories,
      files,
      status,
      category,
      subcategory,
    } = this.state;
    const userId = userService.getCurrentUser().id;

    const editMode = _get(report, 'id', false);

    const reportFleetCategory = subcategory || category;

    if (isFormValid && this.formData) {
      if (editMode) {
        const updateReport = Object.keys(this.formData).reduce(
          (result, keyName) => {
            if (!['reporter', 'category', 'subcategory'].includes(keyName)) {
              if (this.formData[keyName].value !== report[keyName]) {
                result[keyName] = this.formData[keyName].value;
              }
            }

            return result;
          },
          {},
        );

        const newFiles = files.filter((file) => {
          return _isEmpty(file.path);
        });

        if (status) {
          updateReport.status = status;
        }

        editReport(
          {
            ...updateReport,
            id: report.id,
            isConfidentiality,
            nonSafetyRelated,
            reportFleetCategory,
          },
          newFiles,
        );
      } else {
        const newReport = Object.keys(this.formData).reduce(
          (result, keyName) => {
            if (!['reporter', 'category', 'subcategory'].includes(keyName)) {
              result[keyName] = this.formData[keyName].value;
            }

            return result;
          },
          {},
        );

        newReport.reportFleetCategory = reportFleetCategory;
        newReport.nonSafetyRelated = nonSafetyRelated;
        newReport.isConfidentiality = isConfidentiality;
        newReport.date = reformatPickerDateToDB(date);

        const activeReportCategories = Object.keys(reportCategories)
          .filter((id) => reportCategories[id])
          .map((id) => ({ id }));

        newReport.reportCategories = activeReportCategories;
        newReport.user = { id: userId };

        addReport(newReport, files, userId);
      }
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  handleReportCategoriesChange = (name) => (event) => {
    this.setState({
      reportCategories: {
        ...this.state.reportCategories,
        [name]: event.target.checked,
      },
    });
  };

  handleDatePickerChange = (date) => {
    this.setState({
      date,
    });
  };

  renderButtons = () => {
    const { redirectUrl, report, t, users, closeReport } = this.props;
    const { editMode } = this.state;

    const status = _get(report, 'status', '');

    const user = userService.getCurrentUser();

    const userModel = users.find((u) => user.id === u.id) || {};

    const canCloseReport =
      (isAdmin(userModel) || isSuperAdmin(userModel)) && status !== 'CLOSED';

    return status.startsWith('SENT') || status.startsWith('DONE') ? (
      ''
    ) : (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to={redirectUrl ? redirectUrl : '/report'}>
            <Button variant="raised">
              {status ? t('buttonBack') : t('buttonCancel')}
            </Button>
          </Link>
        </div>
        {!editMode ? (
          ''
        ) : (
          <div className="col-auto">
            <Button color="primary" variant="raised" onClick={this.sendHandler}>
              {t('buttonSave')}
            </Button>
          </div>
        )}
        {!editMode || !status.startsWith('CREATED') ? (
          ''
        ) : (
          <div className="col-auto">
            <Button color="primary" variant="raised" onClick={this.sendEmail}>
              {t('buttonSend')}
            </Button>
          </div>
        )}
        {!editMode || !status.startsWith('ASSIGNED') ? (
          ''
        ) : (
          <div className="col-auto">
            <Button
              color="primary"
              variant="raised"
              onClick={this.saveAndComplete}
            >
              {t('Save and complete')}
            </Button>
          </div>
        )}
        {!canCloseReport ? (
          ''
        ) : (
          <div className="col-auto">
            <Button color="secondary" variant="raised" onClick={closeReport}>
              {t('Close report')}
            </Button>
          </div>
        )}
      </div>
    );
  };

  renderComments() {
    const { report, t } = this.props;

    const { editMode } = this.state;

    const status = _get(report, 'status', false);
    const comments = _get(report, 'comments', false);

    if (!comments && (!status || !status.startsWith('ASSIGNED'))) {
      return '';
    }

    const user = userService.getCurrentUser();

    const firstName = _get(report.assignee, 'firstName', '');
    const lastName = _get(report.assignee, 'lastName', '');

    const isAssigned = report.assignee && report.assignee.id === user.id;

    return isAssigned || report.comments ? (
      <div className="row">
        <div className="col-12">
          {!firstName || editMode ? (
            ''
          ) : (
            <h3>
              {`${t('report:Report resolved by ')}${firstName} ${lastName}`}
            </h3>
          )}
          <MaterialInput
            fullWidth
            multiline
            margin="dense"
            name="comments"
            rows={5}
            disabled={!editMode}
            defaultValue={report.comments}
            label={t('report:Comments')}
            validators={[new validators.IsRequired(t)]}
          />
        </div>
      </div>
    ) : (
      ''
    );
  }

  renderReportCategories = (preview) => {
    const { reportCategoryList } = this.props;
    const { reportCategories } = this.state;

    return reportCategoryList.map((reportCategory) => {
      return (
        <div
          className="col-12"
          key={`report-category-checkbox-${reportCategory.id}`}
        >
          <FormControlLabel
            disabled={preview}
            control={
              <Checkbox
                color="primary"
                checked={reportCategories[reportCategory.id.toString()]}
                onChange={this.handleReportCategoriesChange(reportCategory.id)}
                value={reportCategory.id.toString()}
              />
            }
            label={reportCategory.category}
          />
        </div>
      );
    });
  };

  renderFiles = (preview) => {
    const { files } = this.state;

    return files.map((file) => {
      const onClick = () => window.open(file.path, '_blank');
      const onDelete = preview
        ? () => {}
        : () => this.removeReportFile(file.id);

      if (preview) {
        return (
          <Chip
            key={`report-file-${file.id}`}
            label={file.name}
            onClick={onClick}
            className="my-1 mx-1"
          />
        );
      }

      return (
        <Chip
          key={`report-file-${file.id}`}
          label={file.name}
          onClick={onClick}
          onDelete={onDelete}
          className="my-1 mx-1"
        />
      );
    });
  };

  attachReportFile = (file) => {
    const { files } = this.state;

    file.id = uuidv4();

    this.setState({
      files: [...files, file],
    });
  };

  removeReportFile = (fileId) => {
    const { files } = this.state;

    this.setState({
      files: files.filter((file) => file.id !== fileId),
    });
  };

  renderAttachments() {
    const { preview, t } = this.props;
    const { editMode, files } = this.state;

    if (preview && !editMode && _isEmpty(files)) {
      return <></>;
    }

    return (
      <div className="my-3">
        <div>
          <p>{t('report:attachments')}</p>
        </div>
        {this.renderAttachButton(preview && !editMode)}
        <span>{this.renderFiles(preview && !editMode)}</span>
      </div>
    );
  }

  renderAttachButton = (preview) => {
    if (preview) {
      return <span />;
    }

    return (
      <span className="file-inputs">
        <CustomIconButton>
          <FileInput
            attachFile={this.attachReportFile}
            addSimpleError={addSimpleError}
            required
            isEmail
          />
          <Icon color="primary">attach_file</Icon>
        </CustomIconButton>
      </span>
    );
  };

  renderFleet() {
    const { fleets, reportFleetCategoryList, report, t, preview } = this.props;
    const { editMode, nonSafetyRelated, category, subcategory } = this.state;

    const fleetId = _get(report, 'fleet', '');

    if (!editMode && preview) {
      const fleetData = fleets.find((f) => fleetId === f.id);

      return fleetData ? (
        <div className="row">
          <div className="col-4">
            <MaterialInput
              margin="dense"
              name="fleet"
              label={t('input:Fleet')}
              defaultValue={`${fleetData.vehicle.name} (${fleetData.vehicle.evn})`}
              disabled
            />
          </div>
        </div>
      ) : (
        ''
      );
    }

    return (
      <>
        <div className="row">
          <div className="col-6">
            <h3>{t('Fleet')}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <MaterialSelect
              id="fleet"
              name="fleet"
              label={t('input:Fleet')}
              defaultValue={fleetId}
              options={fleets.map((fleet) => ({
                value: fleet.id,
                label: `${fleet.vehicle.name} (${fleet.vehicle.evn})`,
              }))}
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={nonSafetyRelated}
                  onChange={this.handleChange('nonSafetyRelated')}
                />
              }
              label={t('input:criticalReport')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <MaterialSelect
              id="category"
              name="category"
              label={t('input:Category')}
              defaultValue={category}
              stateValue={category}
              options={reportFleetCategoryList
                .filter((category) => !category.parent)
                .map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
            />
          </div>
          <div className="col-4">
            <MaterialSelect
              id="subcategory"
              name="subcategory"
              label={t('input:Subcategory')}
              defaultValue={subcategory}
              stateValue={subcategory}
              options={reportFleetCategoryList
                .filter((cat) => cat.parent && cat.parent == category)
                .map((cat) => ({
                  value: cat.id,
                  label: cat.name,
                }))}
            />
          </div>
        </div>
      </>
    );
  }

  renderReporter() {
    const { preview, report, t } = this.props;
    const { isConfidentiality } = this.state;

    const user = userService.getCurrentUser();

    const reporter =
      preview && report.user
        ? `${report.user.firstName} ${report.user.lastName} (${report.user.email})`
        : `${user.firstName} ${user.lastName} (${user.email})`;

    return (
      <>
        <div className="row">
          <div className="col-6">
            <h3>{t('Reporter')}</h3>
          </div>
        </div>
        {isConfidentiality && preview ? (
          ''
        ) : (
          <div className="row">
            <div className="col-6">
              <MaterialInput
                fullWidth
                margin="dense"
                name="reporter"
                label={t('input:Reporter')}
                value={isConfidentiality ? ' ' : reporter}
                InputProps={{
                  readOnly: true,
                }}
                disabled={preview}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col mb-3">
            <FormControlLabel
              disabled={preview}
              control={
                <Checkbox
                  color="primary"
                  checked={isConfidentiality}
                  onChange={this.handleChange('isConfidentiality')}
                  value="isConfidentiality"
                />
              }
              label={t('input:confidentiality')}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      reportFleetCategoryList,
      reportCategoryRequest,
      reportFleetCategoryRequest,
      reportSaveRequest,
      preview,
      t,
    } = this.props;
    const {
      subject,
      date,
      flightNumber,
      departure,
      destination,
      issue,
      solution,
      editMode,
      id,
      category,
      subcategory,
      status,
    } = this.state;

    if (
      reportCategoryRequest ||
      reportSaveRequest ||
      reportFleetCategoryRequest ||
      (this.props.hasOwnProperty('canEdit') && !id)
    ) {
      return <Loader />;
    }

    // Fix category
    if (subcategory && !category) {
      this.setState({
        category: reportFleetCategoryList.find((cat) => cat.id === subcategory)
          .parent,
      });
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center reports">
        <div className="col section-title pb-5">
          <header>
            <span>
              <Icon color="primary">description</Icon>
              <h1>{t('report:name')}</h1>
              {this.renderReportStatus(status, t)}
            </span>
          </header>
        </div>
        <div className="col-12">
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <div className="row">
              <div className="col">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="subject"
                  label={t('input:Subject')}
                  defaultValue={subject}
                  disabled={!editMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-auto">
                <DateTimePicker
                  fullWidth
                  value={date}
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={this.handleDatePickerChange}
                  dateFormat={getDateFormat()}
                  disabled={preview}
                  name="date"
                  label={t('input:occurenceDate')}
                />
              </div>
            </div>
            <div>
              <p>{t('report:isAddressed')}</p>
            </div>
            <div>{this.renderReportCategories(!editMode)}</div>
            <div className="row">
              <div className="col">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="flightNumber"
                  label={this.setDefaultValue('flight_number')}
                  defaultValue={flightNumber}
                  disabled={!editMode}
                />
              </div>
              <div className="col">
                <MaterialInput
                  className="col"
                  fullWidth
                  margin="dense"
                  name="departure"
                  label={this.setDefaultValue('departure')}
                  defaultValue={departure}
                  disabled={!editMode}
                />
              </div>
              <div className="col">
                <MaterialInput
                  className="col"
                  fullWidth
                  margin="dense"
                  name="destination"
                  label={this.setDefaultValue('destination')}
                  defaultValue={destination}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div>
              <MaterialInput
                fullWidth
                multiline
                margin="dense"
                name="issue"
                label={this.setDefaultValue('issue')}
                rows={5}
                defaultValue={issue}
                disabled={!editMode}
                validators={[new validators.IsRequired(t)]}
              />
            </div>
            <div>
              <MaterialInput
                fullWidth
                multiline
                margin="dense"
                name="solution"
                rows={5}
                label={this.setDefaultValue('solution')}
                defaultValue={solution}
                disabled={!editMode}
              />
            </div>
            {this.renderFleet()}
            {this.renderAttachments()}
            {this.renderReporter()}
            {this.renderComments()}
            <div className="my-3">{this.renderButtons()}</div>
          </Form>
        </div>
      </div>
    );
  }
}
