
export function getClassName(innerHeight, innerWidth, steps) {
  let className = 'root';

  if (innerWidth > 991) {
    if (innerHeight < steps.small) {
      className = 'small';
    } else if (innerHeight < steps.medium) {
      className = 'medium';
    } else if (innerHeight < steps.large) {
      className = 'large';
    }
  }

  return className;
}
