import { cleanHistorys, fetchHistorysSuccess } from './historys';
import { cleanInstallations, fetchInstallationsSuccess } from './installations';

import { URLS } from '../../constants';
import _isEmpty from 'lodash/isEmpty';
import { addError } from './errors';
import axios from 'axios';
import { fileUploadPromise } from '../../utils/fileUpload';
import history from '../store/history';
import qs from 'qs';
import { urlJoin } from '../../utils';

export const FETCH_PART_REQUEST = 'FETCH_PART_REQUEST';
export const FETCH_PARTS_REQUEST = 'FETCH_PARTS_REQUEST';
export const FETCH_PARTS_PAGINATION_REQUEST = 'FETCH_PARTS_PAGINATION_REQUEST';
export const FETCH_PARTS_SUCCESS = 'FETCH_PARTS_SUCCESS';
export const FETCH_PARTS_FLEET_SUCCESS = 'FETCH_PARTS_FLEET_SUCCESS';
export const FETCH_PART_SUCCESS = 'FETCH_PART_SUCCESS';
export const FETCH_PARTS_PAGINATION_SUCCESS = 'FETCH_PARTS_PAGINATION_SUCCESS';
export const FETCH_PARTS = 'FETCH_PARTS';
export const ADD_PART = 'ADD_PART';
export const REMOVE_PART = 'REMOVE_PART';
export const EDIT_PART = 'EDIT_PART';
export const CLEAN_PART_DATA = 'CLEAN_PART_DATA';
export const UNDO_PART = 'UNDO_PART';
export const MOUNT_PART_DATA = 'MOUNT_PART_DATA';
export const UNMOUNT_PART_DATA = 'UNMOUNT_PART_DATA';
export const MOUNT_WAREHOUSE_PART_DATA = 'MOUNT_WAREHOUSE_PART_DATA';
export const UNMOUNT_WAREHOUSE_PART_DATA = 'UNMOUNT_WAREHOUSE_PART_DATA';
export const UNMOUNT_PART_LIST = 'UNMOUNT_PART_LIST';

const sortingPossibitilies = {
  name: 'name',
  partNumber: 'part_number',
  serialNumber: 'serial_number',
  installationDate: 'installation_date',
};

export function fetchPartsRequest() {
  return {
    type: FETCH_PARTS_REQUEST,
  };
}

export function fetchPartsSuccess(parts) {
  return {
    type: FETCH_PARTS_SUCCESS,
    payload: {
      parts,
    },
  };
}

export function fetchPartRequest() {
  return {
    type: FETCH_PART_REQUEST,
  };
}

export function fetchPartFleetSuccess(installations) {
  return {
    type: FETCH_PARTS_FLEET_SUCCESS,
    payload: {
      installations,
    },
  };
}

export function fetchPartSuccess(part) {
  return {
    type: FETCH_PART_SUCCESS,
    payload: {
      part,
    },
  };
}

export function fetchPartsPaginationRequest() {
  return {
    type: FETCH_PARTS_PAGINATION_REQUEST,
  };
}

export function fetchPartPaginationSuccess(parts) {
  return {
    type: FETCH_PARTS_PAGINATION_SUCCESS,
    payload: {
      parts,
    },
  };
}

export function fetchAllPartsRequest() {
  return {
    type: FETCH_PARTS,
  };
}

export function addPartSuccess(part) {
  return {
    type: ADD_PART,
    payload: {
      part,
    },
  };
}

export function removePartSuccess(part) {
  return {
    type: REMOVE_PART,
    payload: {
      part,
    },
  };
}

export function editPartSuccess(part) {
  return {
    type: EDIT_PART,
    payload: {
      part,
    },
  };
}

export function cleanPart() {
  return {
    type: CLEAN_PART_DATA,
  };
}

export function undoPartSuccess(installation) {
  return {
    type: UNDO_PART,
    payload: {
      installation,
    },
  };
}

export function mountPartSuccess(part) {
  return {
    type: MOUNT_PART_DATA,
    payload: {
      part,
    },
  };
}

export function unmountPartSuccess(part) {
  return {
    type: UNMOUNT_PART_DATA,
    payload: {
      part,
    },
  };
}

export function mountWarehousePartSuccess(part) {
  return {
    type: MOUNT_WAREHOUSE_PART_DATA,
    payload: {
      part,
    },
  };
}

export function unmountWarehousePartSuccess(part) {
  return {
    type: UNMOUNT_WAREHOUSE_PART_DATA,
    payload: {
      part,
    },
  };
}

export function fetchUnmountedPartsSuccess(parts) {
  return {
    type: UNMOUNT_PART_LIST,
    payload: {
      parts,
    },
  };
}

export function fetchParts(sortingData, paginationParams) {
  return (dispatch) => {
    const params = [];

    if (paginationParams) {
      dispatch(fetchPartsPaginationRequest());
      params.push(paginationParams);
    } else {
      dispatch(fetchPartsRequest());
    }

    if (sortingData) {
      if (sortingData.search && !_isEmpty(sortingData.search)) {
        params.push(`search=${sortingData.search}`);
      }

      if (sortingData.columnToSort && !_isEmpty(sortingData.columnToSort)) {
        params.push(
          `ordering=${sortingData.sortAsc ? '' : '-'}${
            sortingPossibitilies[sortingData.columnToSort]
          }`,
        );
      }
    }

    const queryParameters = params.length ? `?${params.join('&')}` : '';

    return axios
      .get(urlJoin(URLS.parts, queryParameters))
      .then(({ data }) =>
        dispatch(
          paginationParams
            ? fetchPartPaginationSuccess(data)
            : fetchPartsSuccess(data),
        ),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during parts fetching ${error.message}`, error),
        );
      });
  };
}

export function fetchUnmountedParts() {
  return (dispatch) => {
    return axios
      .get(URLS.unmountedParts)
      .then(({ data }) => dispatch(fetchUnmountedPartsSuccess(data.results)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during unmounted parts fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function fetchPart(partId) {
  return (dispatch) => {
    dispatch(fetchPartRequest());

    return axios
      .get(urlJoin(URLS.parts, partId))
      .then(({ data }) => {
        dispatch(fetchInstallationsSuccess(data.installations));
        dispatch(fetchHistorysSuccess(data.history));

        return data;
      })
      .then((data) => {
        dispatch(fetchPartSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during part fetching ${error.message}`));
      });
  };
}

export function addPart(part, redirectToPartList, files) {
  return (dispatch) => {
    return axios
      .post(URLS.parts, part)
      .then(({ data }) => {
        if (!_isEmpty(files)) {
          const filesPromises = files.map((file) => {
            return fileUploadPromise(
              file,
              'PATCH',
              urlJoin(URLS.attachPartFile, data.id),
              () => {},
            );
          });

          data.files = [];

          return Promise.all(filesPromises).then(() => {
            return data;
          });
        }

        return data;
      })
      .then((data) => {
        dispatch(addPartSuccess(data));

        if (redirectToPartList) {
          history.push(`/parts/edit/${data.id}`);
        }
      })
      .catch((error) => {
        dispatch(addError(`Error during part adding ${error.message}`, error));
      });
  };
}

export function editPart(part, redirectToPartList, updatedFiles, deletedFiles) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.parts, part.id), part)
      .then(({ data }) => {
        if (!_isEmpty(deletedFiles)) {
          const filesPromises = deletedFiles.map((fileId) => {
            return axios.delete(urlJoin(URLS.fileRemove, fileId));
          });

          return Promise.all(filesPromises).then(() => {
            return data;
          });
        }

        return data;
      })
      .then((data) => {
        if (!_isEmpty(updatedFiles)) {
          const filesPromises = updatedFiles.map((file) => {
            return fileUploadPromise(
              file,
              'PATCH',
              urlJoin(URLS.attachPartFile, data.id),
              () => {},
            );
          });

          data.files = [];

          return Promise.all(filesPromises).then(() => {
            return data;
          });
        }

        return data;
      })
      .then((data) => {
        dispatch(editPartSuccess(data));

        if (redirectToPartList) {
          history.push('/parts');
        }
      })
      .catch((error) => {
        dispatch(addError(`Error during part editing ${error.message}`, error));
      });
  };
}

export function mountPart(fleet, part) {
  return (dispatch) => {
    const installation = {
      fleet,
      part: part.id,
      mountingDate: part.mountingDate,
    };

    return axios
      .post(URLS.installations, installation)
      .then(() => axios.patch(urlJoin(URLS.parts, part.id), part))
      .then(({ data }) => dispatch(mountPartSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during part removing ${error.message}`, error),
        );
      });
  };
}

export function unmountPart(installation) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.installations, installation.id), installation)
      .then(() => axios.get(urlJoin(URLS.parts, installation.partId)))
      .then(({ data }) => dispatch(unmountPartSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during part removing ${error.message}`, error),
        );
      });
  };
}

export function mountWarehousePart(partId, warehouse) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.parts, partId), { warehouse })
      .then(({ data }) => dispatch(mountWarehousePartSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during part removing ${error.message}`, error),
        );
      });
  };
}

export function unmountWarehousePart(part) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.parts, part.id), part)
      .then(({ data }) => dispatch(unmountWarehousePartSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during part removing ${error.message}`, error),
        );
      });
  };
}

export function removePart(part) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.parts, part.id))
      .then(() => dispatch(removePartSuccess()))
      .catch((error) => {
        dispatch(
          addError(`Error during part removing ${error.message}`, error),
        );
      });
  };
}

export function cleanPartData() {
  return (dispatch) => {
    dispatch(cleanPart());
    dispatch(cleanInstallations());
    dispatch(cleanHistorys());
  };
}

export function undoPart(installation) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.installations, installation.installationId))
      .then(() => dispatch(undoPartSuccess(installation)))
      .catch((error) => {
        dispatch(addError(`Error during part undo ${error.message}`, error));
      });
  };
}
