import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import _range from 'lodash/range';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class TechnicalFaultReportGraph extends Component {
  static propTypes = {
    reportList: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  asUniqueFleets() {
    return [
      ...new Set(
        this.props.reportList.map((report) => `${report.fleet.vehicle}`),
      ),
    ];
  }

  data(labels) {
    const { reportList } = this.props;

    return {
      labels,
      datasets: [
        {
          barPercentage: 0.2,
          label: '# Open',
          data: labels.map(
            (category) =>
              reportList
                .filter((r) => r.status !== 'CLOSED')
                .filter((r) => r.category === category).length,
          ),
          backgroundColor: 'rgb(255, 99, 132)',
        },
        {
          barPercentage: 0.2,
          label: '# Closed',
          data: labels.map(
            (category) =>
              reportList
                .filter((r) => r.status === 'CLOSED')
                .filter((r) => r.category === category).length,
          ),
          backgroundColor: 'rgb(75, 192, 192)',
        },
      ],
    };
  }

  options() {
    return {
      scales: {
        y: {
          min: 0,
          ticks: {
            stepSize: 1,
          },
        },
      },
    };
  }

  render() {
    const labels = [
      ...new Set(
        this.props.reportList
          .filter((report) => report.category)
          .map((report) => report.category),
      ),
    ];

    return labels.length ? (
      <Bar data={this.data(labels)} options={this.options()} height={75} />
    ) : (
      <div />
    );
  }
}
