import store from '../store';

function _getUserPermissions() {
  return store.getState().authentication.userPermissions;
}

export function hasPermission(permission) {
  const userPermissions = _getUserPermissions();

  console.log(permission, userPermissions.includes(permission));

  return userPermissions.includes(permission);
}

function _checkPermissionAndCall(permission, originalFunction, args = []) {
  return hasPermission(permission)
    ? originalFunction.call(this, ...args)
    : null;
}

export function componentPermission(permission) {
  return function (Class) {
    return function (...args) {
      const component = new Class(...args);
      const methodsNames = ['componentDidMount', 'render'];

      methodsNames
        .filter((methodName) => component[methodName])
        .forEach((methodName) => {
          const originalFunction = component[methodName];

          component[methodName] = function () {
            return _checkPermissionAndCall.call(
              this,
              permission,
              originalFunction,
            );
          };
        });

      return component;
    };
  };
}

export function renderPermission(permission) {
  return function (target, name, descriptor) {
    const originalFunction = descriptor.value;

    descriptor.value = function (...args) {
      return _checkPermissionAndCall.call(
        this,
        permission,
        originalFunction,
        args,
      );
    };

    return descriptor;
  };
}
