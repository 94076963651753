import axios from 'axios';

import history from '../store/history';
import { URLS_RAIL as URLS } from '../../constants';
import { getDjangoError, urlJoin } from '../../utils';
import { addAuthenticationError, addError } from './errors';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const RESET_AUTHENTICATION = 'RESET_AUTHENTICATION';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS';
export const PASSWORD_RECOVERY_FAILURE = 'PASSWORD_RECOVERY_FAILURE';
export const PASSWORD_TOKEN_SUCCESS = 'PASSWORD_TOKEN_SUCCESS';
export const PASSWORD_TOKEN_FAILURE = 'PASSWORD_TOKEN_FAILURE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const RESET_PASSWORD_RECOVERING = 'RESET_PASSWORD_RECOVERING';

export function loginRequest() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function loginSuccess(token, user) {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));

  return {
    type: LOGIN_SUCCESS,
    payload: {
      token,
      user,
    },
  };
}

export function loginFailure() {
  localStorage.removeItem('token');

  return {
    type: LOGIN_ERROR,
  };
}

function loginError(dispatch, error, defaultMessage) {
  const errorMessage = getDjangoError(error);

  dispatch(loginFailure());

  if (errorMessage) {
    dispatch(addAuthenticationError(errorMessage));
  } else {
    dispatch(addError(defaultMessage, error));
  }
}

export function resetAuthentication() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');

  return {
    type: RESET_AUTHENTICATION,
  };
}

export function login(loginForm) {
  return (dispatch) => {
    dispatch(loginRequest());
    const userLogin = loginForm.login.toString().toLocaleLowerCase();

    const auth = btoa(`${userLogin}:${loginForm.password}`);
    const loginData = {
      headers: {
        Authorization: `Basic ${auth}`,
      },
    };

    return axios
      .post(urlJoin(URLS.accounts, 'login'), {}, loginData)
      .then(({ data }) => dispatch(loginSuccess(data.token, data.user)))
      .catch((error) => {
        loginError(
          dispatch,
          error,
          'Error during login. Please contact with administrator.',
        );
      });
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(resetAuthentication());
  };
}

export function passwordRecoverySuccess() {
  return {
    type: PASSWORD_RECOVERY_SUCCESS,
  };
}

export function recoverPassword(email) {
  return (dispatch) => {
    return axios
      .post(URLS.passwordRecovery, { email })
      .then(() => dispatch(passwordRecoverySuccess()))
      .catch((error) => {
        loginError(
          dispatch,
          error,
          'Error during password reseting. Please contact with administrator.',
        );
      });
  };
}

export function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePassword(password, token) {
  return (dispatch) => {
    return axios
      .get(urlJoin(URLS.passwordRecovery, token))
      .then((passwordRecoveryData) =>
        axios.patch(
          urlJoin(URLS.changePassword, passwordRecoveryData.data.userId),
          { password, token },
        ),
      )
      .then(() => axios.delete(urlJoin(URLS.passwordRecovery, token)))
      .then(() => dispatch(changePasswordSuccess()))
      .catch((error) => {
        loginError(
          dispatch,
          error,
          'Error during password changing. Please contact with administrator.',
        );
      });
  };
}

export function setNewPassword(passwordData, token) {
  let userId;

  return (dispatch) => {
    return (
      axios
        .get(urlJoin(URLS.passwordSet, token))
        .then((passwordRecoveryData) => {
          userId = passwordRecoveryData.data.userId;

          return axios.patch(urlJoin(URLS.changePassword, userId), {
            password: passwordData.password,
            token,
          });
        })
        //.then(() => axios.patch(urlJoin(URLS.changePin, userId), { pin: passwordData.pin, token }))
        .then(() => axios.delete(urlJoin(URLS.passwordRecovery, token)))
        .then(() => dispatch(changePasswordSuccess()))
        .catch((error) => {
          loginError(
            dispatch,
            error,
            'Error during password changing. Please contact with administrator.',
          );
        })
    );
  };
}

export function passwordTokenSuccess() {
  return {
    type: PASSWORD_TOKEN_SUCCESS,
  };
}

export function passwordTokenFailure() {
  return {
    type: PASSWORD_TOKEN_FAILURE,
  };
}

export function resetPasswordRecovering() {
  return (dispatch) => {
    history.push('/login');

    return dispatch({
      type: RESET_PASSWORD_RECOVERING,
    });
  };
}

export function isTokenValid(token) {
  return (dispatch) => {
    return axios
      .get(urlJoin(URLS.passwordRecovery, token))
      .then(() => dispatch(passwordTokenSuccess()))
      .catch(() => dispatch(passwordTokenFailure()));
  };
}

export function isNewPasswordTokenValid(token) {
  return (dispatch) => {
    return axios
      .get(urlJoin(URLS.passwordSet, token))
      .then(() => dispatch(passwordTokenSuccess()))
      .catch(() => dispatch(passwordTokenFailure()));
  };
}
