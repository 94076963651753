import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

@withNamespaces()
export default class Confirmation extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    closeRemoveDialog: PropTypes.func.isRequired,
    removeFunction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { dialogTitle, message, closeRemoveDialog, removeFunction, t } = this.props;

    return (
      <div>
        <DialogTitle id="remove-dialog">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRemoveDialog} color="secondary">
            {t('buttonCancel')}
          </Button>
          <Button onClick={removeFunction} color="primary">
            {t('buttonRemove')}
          </Button>
        </DialogActions>
      </div>
    );
  }
}
