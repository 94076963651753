import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';
import PropTypes from 'prop-types';

import PrivateRoute from '../../routing/PrivateRoute';
import Login from '../Authentication/Login';
import BookingRequest from '../Booking/BookingRequest';
import PasswordRecovery from '../Authentication/PasswordRecovery';
import SetNewPassword from '../Authentication/SetNewPassword';
import ChangePassword from '../Authentication/ChangePassword';
import Main from '../Main';
import OfflineView, { isOffline } from '../../../common/components/Offline';

class GeneralRouting extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
  };

  render() {
    const { isAuthenticated } = this.props;

    return (
      <Switch>
        <Route
          path="/login"
          render={(routeProps) => {
            if (isOffline('/login')) {
              return <OfflineView />;
            }

            return <Login {...routeProps} isAuthenticated={isAuthenticated} />;
          }}
        />
        <Route
          path="/password-recovery"
          render={(routeProps) => {
            if (isOffline('/password-recovery')) {
              return <OfflineView />;
            }

            return <PasswordRecovery {...routeProps} isAuthenticated={isAuthenticated} />;
          }}
        />
        <Route
          path="/change-password/:id"
          render={(routeProps) => {
            if (isOffline('/change-password')) {
              return <OfflineView />;
            }

            return <ChangePassword {...routeProps} isAuthenticated={isAuthenticated} />;
          }}
        />
        <Route
          path="/new-password/:id"
          render={(routeProps) => {
            if (isOffline('/new-password')) {
              return <OfflineView />;
            }

            return <SetNewPassword {...routeProps} isAuthenticated={isAuthenticated} />;
          }}
        />
        <Route
          path="/booking/:token"
          render={(routeProps) => {
            if (isOffline('/login')) {
              return <OfflineView />;
            }

            return <BookingRequest {...routeProps} />;
          }}
        />
        <PrivateRoute
          path="/"
          component={Main}
          isAuthenticated={isAuthenticated}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authentication.loginSuccess
  };
};

export default withRouter(connect(mapStateToProps)(GeneralRouting));
