import {
  FETCH_PART_STATUSES_FAILURE,
  FETCH_PART_STATUSES_REQUEST,
  FETCH_PART_STATUSES_SUCCESS,
} from '../actions/partStatuses';

const INITIAL_STATE = {
  partStatusList: [],
  partStatusRequest: false,
};

function fetchPartStatusesSuccess(state, payload) {
  return {
    ...state,
    partStatusList: payload.partStatuses,
    partStatusRequest: false,
  };
}

function fetchPartStatusesRequest(state) {
  return {
    ...state,
    partStatusRequest: true,
  };
}

function fetchPartStatusesFailure(state) {
  return {
    ...state,
    partStatusRequest: false,
  };
}

const actions = {
  [FETCH_PART_STATUSES_SUCCESS]: fetchPartStatusesSuccess,
  [FETCH_PART_STATUSES_REQUEST]: fetchPartStatusesRequest,
  [FETCH_PART_STATUSES_FAILURE]: fetchPartStatusesFailure,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
