import {
  FETCH_USER_SETTINGS_SUCCESS,
  FETCH_USER_SETTINGS_REQUEST,
  FETCH_USER_SETTINGS_FAILURE,
  EDIT_USER_SETTINGS,
  RESET_USER_SETTINGS_SUCCESS
} from '../actions/userSettings';

const INITIAL_STATE = {
  userSettings: [],
  userSettingsRequest: false,
  changeUserSettingsSuccess: false,
};

function fetchUserSettings(state, payload) {
  return {
    ...state,
    userSettings: payload.userSettings,
    userSettingsRequest: false,
  };
}

function fetchUserSettingsRequest(state) {
  return {
    ...state,
    userSettingsRequest: true,
  };
}

function fetchUserSettingsFailure(state) {
  return {
    ...state,
    userSettingsRequest: false,
  };
}

function editUserSettingsSuccess(state, payload) {
  return {
    ...state,
    userSettings: payload.userSettings,
    changeUserSettingsSuccess: true,
  };
}

function resetUserSettingsSuccess(state) {
  return {
    ...state,
    changeUserSettingsSuccess: false
  };
}

const actions = {
  [FETCH_USER_SETTINGS_SUCCESS]: fetchUserSettings,
  [FETCH_USER_SETTINGS_REQUEST]: fetchUserSettingsRequest,
  [FETCH_USER_SETTINGS_FAILURE]: fetchUserSettingsFailure,
  [EDIT_USER_SETTINGS]: editUserSettingsSuccess,
  [RESET_USER_SETTINGS_SUCCESS]: resetUserSettingsSuccess,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
