import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReportList from '../ReportList/ReportList';
import userService from '../../../../utils/userService';

export default class MyReports extends Component {
    static propTypes = {
      userId: PropTypes.string,
    };

    render() {
      const id = this.props.userId || userService.getCurrentUser().id;

      return (
        <ReportList userId={Number(id)} />
      );
    }
}
