import axios from 'axios';
import localStorage from './utils/local-storage-facade';

function getContent({ data }) {
  if (data === 'AVIATION') {
    require('./aviation/index');
  } else if (data === 'RAIL') {
    require('./rail/index');
  }
}

// TODO - AVIATION or RAIL
const response = localStorage.getItem('/application_type');

if (!response) {
  axios
    .get('/application_type')
    .then(getContent)
    .catch((error) => {
      console.log(error);
    });
} else {
  getContent(response);
}
