import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  matrix: {
    width: '58%'
  },

  col: {

    '&::after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%'
    },

    margin: '6px',
    textAlign: 'center',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '12px',

    '&::before': {
      content: '"\n"',
      whiteSpace: 'pre'
    }
  },
  text: {
    fontWeight: 'bold',
    fontSize: '12px !important'
  },
  weight: {
    fontWeight: 'bold',
    fontSize: '25px'
  },
  weightActive: {
    fontSize: '30px'
  },
  weightInactive: {
    opacity: '20%'
  },
  weightOne: {
    backgroundColor: '#2ECC71'
  },
  weightTwo: {
    backgroundColor: '#BBEED0'
  },
  weightThree: {
    backgroundColor: '#F7C948'
  },
  weightFour: {
    backgroundColor: '#F6AD55'
  },
  weightFive: {
    backgroundColor: '#ED8936'
  },
  weightSix: {
    backgroundColor: '#FC8181'
  },
  weightSeven: {
    backgroundColor: '#F56565'
  },
  weightEight: {
    backgroundColor: '#E53E3E'
  },

});

@withStyles(styles)
@withNamespaces()
export default class RiskMatrix extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    hazards: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired
  };

  renderBoxContent(weight, text, customClasses, stateClass) {
    const { classes } = this.props;

    if (text) {
      return (
        <div className={`col-sm ${classes.col} ${classes.text} ${customClasses} ${stateClass}`}>
          { text }
        </div>
      );
    }

    return (
      <div className={`col-sm ${classes.col} ${classes.weight} ${customClasses} ${stateClass}`}>
        { weight }
      </div>
    );
  }

  getWeightClass(hazard, likelihood, severity) {
    const { classes } = this.props;

    return this.isActive(hazard, likelihood, severity) ? classes.weightActive : classes.weightInactive;
  }

  isActive(hazard, likelihood, severity) {
    return parseInt(hazard.likelihood, 10) === likelihood && parseInt(hazard.severity, 10) === severity;
  }

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  renderBoxContentTitle(title) {
    const { classes } = this.props;

    return (
      <div className={`col-sm ${classes.col}`}>
        <div className={classes.title}>{ title }</div>
      </div>);
  }

  renderHazard(hazard) {

    const { classes, t } = this.props;

    return (
      <div className={classes.matrix}>
        <div className="row">
          <div className={`col-sm ${classes.col}`} />
          { this.renderBoxContentTitle(t('sms:Catastrophic')) }
          { this.renderBoxContentTitle(t('sms:Hazardous')) }
          { this.renderBoxContentTitle(t('sms:Major')) }
          { this.renderBoxContentTitle(t('sms:Minor')) }
          { this.renderBoxContentTitle(t('sms:Negligible')) }
        </div>
        <div className="row">
          { this.renderBoxContentTitle(t('sms:Frequent')) }
          { this.renderBoxContent('25', t('sms:Unacceptable'), classes.weightEight, this.getWeightClass(hazard, 4, 4)) }
          { this.renderBoxContent('20', t('sms:Unacceptable'), classes.weightEight, this.getWeightClass(hazard, 4, 3)) }
          { this.renderBoxContent('15', t('sms:Urgent review'), classes.weightSix, this.getWeightClass(hazard, 4, 2)) }
          { this.renderBoxContent('10', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 4, 1)) }
          { this.renderBoxContent('5', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 4, 0)) }
        </div>
        <div className="row">
          { this.renderBoxContentTitle(t('sms:Occasional')) }
          { this.renderBoxContent('20', t('sms:Unacceptable'), classes.weightEight, this.getWeightClass(hazard, 3, 4)) }
          { this.renderBoxContent('16', t('sms:Urgent review'), classes.weightSix, this.getWeightClass(hazard, 3, 3)) }
          { this.renderBoxContent('12', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 3, 2)) }
          { this.renderBoxContent('8', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 3, 1)) }
          { this.renderBoxContent('4', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 3, 0)) }
        </div>
        <div className="row">
          { this.renderBoxContentTitle(t('sms:Remote')) }
          { this.renderBoxContent('15', t('sms:Urgent review'), classes.weightSix, this.getWeightClass(hazard, 2, 4)) }
          { this.renderBoxContent('12', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 2, 3)) }
          { this.renderBoxContent('9', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 2, 2)) }
          { this.renderBoxContent('6', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 2, 1)) }
          { this.renderBoxContent('3', t('sms:Acceptable'), classes.weightOne, this.getWeightClass(hazard, 2, 0)) }
        </div>
        <div className="row">
          { this.renderBoxContentTitle(t('sms:Improbable')) }
          { this.renderBoxContent('10', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 1, 4)) }
          { this.renderBoxContent('8', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 1, 3)) }
          { this.renderBoxContent('6', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 1, 2)) }
          { this.renderBoxContent('4', t('sms:Acceptable'), classes.weightOne, this.getWeightClass(hazard, 1, 1)) }
          { this.renderBoxContent('2', t('sms:Acceptable'), classes.weightOne, this.getWeightClass(hazard, 1, 0)) }
        </div>
        <div className="row">
          { this.renderBoxContentTitle(t('sms:Extremely Improbable')) }
          { this.renderBoxContent('5', t('sms:Review'), classes.weightThree, this.getWeightClass(hazard, 0, 4)) }
          { this.renderBoxContent('4', t('sms:Acceptable'), classes.weightOne, this.getWeightClass(hazard, 0, 3)) }
          { this.renderBoxContent('3', t('sms:Acceptable'), classes.weightOne, this.getWeightClass(hazard, 0, 2)) }
          { this.renderBoxContent('2', t('sms:Acceptable'), classes.weightOne, this.getWeightClass(hazard, 0, 1)) }
          { this.renderBoxContent('1', t('sms:Acceptable'), classes.weightOne, this.getWeightClass(hazard, 0, 0)) }
        </div>
      </div>);
  }

  render() {

    const { hazards } = this.props;

    return (
      <div className="container">
        { hazards.map((hazard) => {
          return this.renderHazard(hazard);
        })}
      </div>
    );
  }
}
