import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';

import Icon from '@material-ui/core/Icon';

import ItemList from './ItemList';
import Loader from '../../../../common/components/Loader';
import {
  fetchAssignedCourse,
  editAssignedCourse,
  fetchAssignedCourseStudents,
  updateTheoreticalLessons,
} from '../../../actions/assignedCourses';

import { fetchPracticalFields } from '../../../actions/practicalFields';
import { addSimpleError } from '../../../actions/errors';
import { fetchAllTypes } from '../../../actions/types';
import { fetchAllLocations } from '../../../actions/locations';
import { addJourneyLog } from '../../../actions/journeyLog';
import { fetchGradings } from '../../../actions/gradings';

import '../styles.scss';

const mapStateToProps = (state) => {
  return {
    assignedCourse: state.assignedCourses.assignedCourse,
    assignedCourseRequest: state.assignedCourses.assignedCourseRequest,
    practicalFieldList: state.practicalFields.practicalFieldList.filter((field) => field.isActive),
    practicalFieldRequest: state.practicalFields.practicalFieldRequest,
    typeList: state.types.allTypeList.filter((type) => !type.user),
    typeRequest: state.types.typeRequest,
    locationList: state.locations.allLocationList.filter((location) => location.isActive && !location.user),
    locationRequest: state.locations.locationRequest,
    assignedCourseStudentsRequest: state.assignedCourses.assignedCourseStudentsRequest,
    assignedCourseStudentsList: state.assignedCourses.assignedCourseStudentsList,
    gradingList: state.gradings.gradingList,
    gradingRequest: state.gradings.gradingRequest,
    settings: state.settings.settings
  };
};


function mapDispatchToProps(dispatch) {
  return {
    fetchAllTypes: () => dispatch(fetchAllTypes()),
    fetchAllLocations: () => dispatch(fetchAllLocations()),
    fetchPracticalFields: () => dispatch(fetchPracticalFields()),
    fetchAssignedCourse: (id) => dispatch(fetchAssignedCourse(id)),
    fetchAssignedCourseStudents: (assignedCourseId, lessonId) => dispatch(fetchAssignedCourseStudents(assignedCourseId, lessonId)),
    updateTheoreticalLessons: (msg, assignedCourseId, lessonId, redirect) => {
      return dispatch(updateTheoreticalLessons(msg, assignedCourseId, lessonId, redirect));
    },
    editAssignedCourse: (id, courseData, redirect) => dispatch(editAssignedCourse(id, courseData, redirect)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    addJourneyLog: (journeyLog) => dispatch(addJourneyLog(journeyLog, false)),
    fetchGradings: () => dispatch(fetchGradings()),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class LessonEdit extends Component {
  static propTypes = {
    editAssignedCourse: PropTypes.func.isRequired,
    fetchAssignedCourse: PropTypes.func.isRequired,
    fetchAllTypes: PropTypes.func.isRequired,
    fetchAllLocations: PropTypes.func.isRequired,
    fetchPracticalFields: PropTypes.func.isRequired,
    fetchAssignedCourseStudents: PropTypes.func.isRequired,
    fetchGradings: PropTypes.func.isRequired,
    assignedCourseStudentsRequest: PropTypes.bool.isRequired,
    assignedCourseStudentsList: PropTypes.array,
    updateTheoreticalLessons: PropTypes.func.isRequired,
    addJourneyLog: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    practicalFieldList: PropTypes.array.isRequired,
    practicalFieldRequest: PropTypes.bool.isRequired,
    assignedCourse: PropTypes.object.isRequired,
    typeList: PropTypes.array,
    typeRequest: PropTypes.bool.isRequired,
    locationList: PropTypes.array.isRequired,
    locationRequest: PropTypes.bool.isRequired,
    gradingList: PropTypes.array.isRequired,
    settings: PropTypes.array.isRequired,
    gradingRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    assignedCourseRequest: PropTypes.bool.isRequired,
    preview: PropTypes.bool,
    newLesson: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  state = {
    courseStartDate: null,
    course: null,
    lesson: null,
    lessons: [],
  }

  componentDidMount() {
    this.props.fetchAllTypes();
    this.props.fetchAllLocations();
    this.props.fetchGradings();
    this.props.fetchPracticalFields();
    this.props.fetchAssignedCourse(this.props.match.params.id);

    if (!this.props.newLesson) {
      this.props.fetchAssignedCourseStudents(this.props.match.params.id, this.props.match.params.lessonId);
    }
  }

  updateTheoreticalLessons = (msg, assignedCourseId, redirect) => {
    const { updateTheoreticalLessons, match } = this.props;

    updateTheoreticalLessons(msg, assignedCourseId, match.params.lessonId, redirect);
  };

  setNewLesson = () => {
    const { t, match, newLesson, assignedCourse } = this.props;
    const { lesson } = this.state;
    const { lessonId } = match.params;

    if (!_isEmpty(lesson) || _isEmpty(assignedCourse.courseData)) {
      return;
    }

    const course = _get(assignedCourse, 'courseData', '{}');
    const courseStartDate = _get(assignedCourse, 'startDate', '');
    const lessons = course.lessons.map((courseLesson) => {
      return {
        ...courseLesson,
        items: _sortBy(courseLesson.lessonsItems.map(({ item, order }, index) => {
          return {
            ...item,
            order,
            lessonItemId: index,
          };
        }), ['order'])
      };
    });

    let currentLesson = {};

    if (newLesson) {
      const nextNumber = lessons.filter((lesson) => lesson.lessonType && lesson.lessonType.isAdditional).length + 1;

      currentLesson = {
        id: uuidv4(),
        order: lessons.length,
        name: `${t('course:newPracticalLessonName')} ${nextNumber}`,
        items: [],
        lessonType: {
          id: 1,
          name: 'practical',
          shortname: 'PR',
          isAdditional: true
        }
      };
      lessons.push(currentLesson);
    } else {
      currentLesson = lessons.find((courseLesson) => courseLesson.id.toString() === lessonId.toString());
    }

    this.setState({
      lesson: currentLesson,
      lessons,
      courseStartDate,
      course,
    });
  }

  render() {
    const {
      assignedCourse,
      assignedCourseRequest,
      editAssignedCourse,
      assignedCourseStudentsRequest,
      assignedCourseStudentsList,
      preview,
      practicalFieldList,
      typeList,
      practicalFieldRequest,
      typeRequest,
      locationList,
      gradingList,
      settings,
      locationRequest,
      gradingRequest,
      match,
      addSimpleError,
      addJourneyLog,
    } = this.props;
    const { lesson, course, courseStartDate, lessons } = this.state;


    if (_isEmpty(assignedCourse)) {
      return null;
    }

    if (assignedCourseRequest || practicalFieldRequest || locationRequest || typeRequest || assignedCourseStudentsRequest || gradingRequest) {
      return <Loader />;
    }

    this.setNewLesson();

    if (_isEmpty(lesson)) {
      return <Loader />;
    }

    if (lesson.isCompleted && match.url.includes('edit')) {
      return <Redirect to={{ pathname: match.url.replace('edit', 'details') }} />;
    }

    return (
      <div className="assigned-course-details row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-12 section-title pb-5">
          <header>
            <span>
              <Icon color="primary">
                content_paste
              </Icon>
              <h1>{lesson.name}</h1>
            </span>
          </header>
        </div>
        <div className="col-12">
          <ItemList
            assignedCourseId={assignedCourse.id}
            lesson={lesson}
            course={course}
            lessons={lessons}
            courseStartDate={courseStartDate}
            preview={preview}
            user={assignedCourse.user}
            practicalFieldList={practicalFieldList}
            editAssignedCourse={editAssignedCourse}
            locationList={locationList}
            typeList={typeList}
            gradingList={gradingList}
            settings={settings}
            addSimpleError={addSimpleError}
            courseParticipants={assignedCourseStudentsList}
            updateTheoreticalLessons={this.updateTheoreticalLessons}
            addJourneyLog={addJourneyLog}
          />
        </div>
      </div>
    );
  }
}
