import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler';
import { getTimeFormat } from '../../utils/time';

const SCHEDULER_MAX_WIDTH = 1118;

export default function(aircraftList, events, t, lang) {
  moment.locale(lang);
  const schedulerWidth = document.getElementById('inner-section').clientWidth - 15;
  const minuteStep = 15;
  const schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Day, false, false, {
    checkConflict: true,
    eventItemPopoverEnabled: true,
    views: [
      { viewName: t('day'), viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false },
      { viewName: t('week'), viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false },
      { viewName: t('month'), viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
    ],
    schedulerWidth: Math.min(schedulerWidth, SCHEDULER_MAX_WIDTH),
    dayCellWidth: 10,

    dayMaxEvents: 99,
    weekMaxEvents: 99,
    monthMaxEvents: 99,
    quarterMaxEvents: 99,
    yearMaxEvents: 99,

    eventItemHeight: 26,
    eventItemLineHeight: 34,
    nonAgendaSlotMinHeight: 0,
    dayStartFrom: 0,
    dayStopTo: 23,
    defaultEventBgColor: '#0D5AA7',
    selectedAreaColor: '#9fa8da',
    nonWorkingTimeHeadColor: '#1a1a1a',
    nonWorkingTimeHeadBgColor: '#CCC',
    nonWorkingTimeBodyBgColor: '#DCDCDC',
    summaryColor: '#666',

    nonAgendaDayCellHeaderFormat: getTimeFormat(),
    resourceName: t('booking:bookingObjectUpper'),
  });

  schedulerData.setLocaleMoment(moment);
  schedulerData.setResources(aircraftList);
  schedulerData.setEvents(events);
  schedulerData.setMinuteStep(minuteStep);

  return schedulerData;
}
