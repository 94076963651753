import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _each from 'lodash/each';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  table: {
    minWidth: 500,
  },
});

@withStyles(styles)
@withNamespaces()
export default class ReadStatusDialog extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderReadStatus() {
    const { readStatus } = this.props.file;

    return Object.keys(readStatus).map((key) => {
      const status = readStatus[key];

      return (
        <TableRow key={`read-status-${key}`}>
          <TableCell>{status.name}</TableCell>
          <TableCell>{status.timestamp}</TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { file, onCloseDialog, open, t, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="read-status-dialog"
        fullWidth
      >
        <DialogTitle id="read-status-dialog">
          {t('folder:readStatusDialogTitle')}:{file.name}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('table:name')}</TableCell>
                    <TableCell>{t('table:time')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{this.renderReadStatus()}</TableBody>
              </Table>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>{t('buttonBack')}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
