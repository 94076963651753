import axios from 'axios';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { addError } from './errors';
import { urlJoin, defaultCrewStatusFilerMonthsValue } from '../../utils';
import { prepareCrewStatusFilter, getDashboardQueryParametersFilter } from '../utils/time';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_QUALIFICATIONS_SUCCESS = 'FETCH_QUALIFICATIONS_SUCCESS';
export const FETCH_QUALIFICATIONS = 'FETCH_QUALIFICATIONS';
export const FETCH_ALL_QUALIFICATIONS_SUCCESS = 'FETCH_ALL_QUALIFICATIONS_SUCCESS';
export const ADD_QUALIFICATION = 'ADD_QUALIFICATION';
export const REMOVE_QUALIFICATION = 'REMOVE_QUALIFICATION';
export const EDIT_QUALIFICATION = 'EDIT_QUALIFICATION';

export function fetchQualificationsSuccess(qualifications) {
  return {
    type: FETCH_QUALIFICATIONS_SUCCESS,
    payload: {
      qualifications
    }
  };
}

export function fetchAllQualificationsSuccess(allQualifications) {
  return {
    type: FETCH_ALL_QUALIFICATIONS_SUCCESS,
    payload: {
      allQualifications
    }
  };
}

export function fetchAllQualificationsRequest() {
  return {
    type: FETCH_QUALIFICATIONS,
  };
}

export function addQualificationSuccess(qualification) {
  return {
    type: ADD_QUALIFICATION,
    payload: {
      qualification
    }
  };
}

export function removeQualificationSuccess(qualification) {
  return {
    type: REMOVE_QUALIFICATION,
    payload: {
      qualification
    }
  };
}

export function editQualificationSuccess(qualification) {
  return {
    type: EDIT_QUALIFICATION,
    payload: {
      qualification
    }
  };
}

export function addQualification(qualification) {
  return (dispatch) => {
    return axios.post(URLS.qualifications, qualification)
      .then(({ data }) => dispatch(addQualificationSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during qualification adding ${error.message}`, error));
      });
  };
}

export function addQualificationPlannedAction(qualification) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.qualificationsPlannedAction, qualification.id), qualification)
      .then(({ data }) => dispatch(editQualificationSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during adding qualification planned action ${error.message}`, error));
      });
  };
}

export function removeQualification(qualification) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.qualifications, qualification.id))
      .then(() => dispatch(removeQualificationSuccess(qualification)))
      .catch((error) => {
        dispatch(addError(`Error during qualification removing ${error.message}`, error));
      });
  };
}

export function editQualification(qualification) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.qualifications, qualification.id), qualification)
      .then(({ data }) => dispatch(editQualificationSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during qualification editing ${error.message}`, error));
      });
  };
}

export function deactivateQualification(qualification) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.qualificationsDeactivate, qualification.id), qualification)
      .then(({ data }) => dispatch(editQualificationSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during qualification editing ${error.message}`, error));
      });
  };
}

export function fetchQualifications(userId) {
  return (dispatch) => {
    const queryParameters = `?${getDashboardQueryParametersFilter(userId)}&ordering=expiry_date`;

    return axios.get(urlJoin(URLS.qualifications, queryParameters))
      .then(({ data }) => dispatch(fetchQualificationsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during qualification fetching ${error.message}`, error));
      });
  };
}

export function fetchAllQualifications(sortingData) {
  return (dispatch) => {
    dispatch(fetchAllQualificationsRequest());
    let crewStatusParameters = '';
    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData && !_isEmpty(sortingData.filter)) {
      const monthId = _get(sortingData.filter, 'months[0].id', defaultCrewStatusFilerMonthsValue);

      crewStatusParameters = prepareCrewStatusFilter(monthId);

      if (sortingData.filter.isVerified) {
        crewStatusParameters += '&is_verified=False';
      }
    }

    queryParameters = _isEmpty(queryParameters) ? `?${crewStatusParameters}` : `?${qs.stringify(queryParameters)}&${crewStatusParameters}`;

    return axios.get(urlJoin(URLS.allQualifications, queryParameters))
      .then(({ data }) => dispatch(fetchAllQualificationsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during qualification fetching ${error.message}`, error));
      });
  };
}
