import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddJourneyLog from '../LogbookNew/addJourneyLog';
import Loader from '../../../../common/components/Loader';
import { fetchJourneyLog } from '../../../actions/journeyLog';
import { addSimpleError } from '../../../actions/errors';
import { fetchUserSettings } from '../../../actions/userSettings';

const mapStateToProps = (state) => {
  return {
    journeyLog: state.journeyLog.journeyLog,
    journeyLogsRequest: state.journeyLog.journeyLogsRequest,
    userSettings: state.userSettings.userSettings,
    userSettingsRequest: state.userSettings.userSettingsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchJourneyLog: (id) => dispatch(fetchJourneyLog(id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    fetchUserSettings: (userId) => dispatch(fetchUserSettings(userId)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class LogbookEdit extends Component {
  static propTypes = {
    journeyLog: PropTypes.object,
    fetchJourneyLog: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    journeyLogsRequest: PropTypes.bool,
    fetchUserSettings: PropTypes.func,
    userSettingsRequest: PropTypes.bool,
    userSettings: PropTypes.object,
  };

  componentDidMount() {
    const { fetchUserSettings, fetchJourneyLog } = this.props;
    const user = localStorage.getItem('user');
    const userId = JSON.parse(user).id.toString();

    fetchUserSettings(userId);
    fetchJourneyLog(this.props.match.params.id);
  }

  render() {
    const {
      journeyLog,
      journeyLogsRequest,
      userSettingsRequest,
      userSettings,
    } = this.props;

    if (journeyLogsRequest || !journeyLog || userSettingsRequest) {
      return <Loader />;
    }

    return (
      <AddJourneyLog
        journeyLog={journeyLog}
        userSettings={userSettings}
        editMode
      />
    );
  }
}
