import './styles.scss';

import React, { Component } from 'react';
import {
  checkExpiredDate,
  checkExpiryDate,
  reformatPickerDateFromDB,
} from '../../utils/time';
import { documents, months, tableHeader } from './crewStatusConfig';
import { sortStatusWeight, sortString } from '../../../utils/sort';

import CustomFilter from '../../../common/components/CustomFilter';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Loader from '../../../common/components/Loader';
import { MODULES } from '../../../constants/modules';
import { PERMISSIONS } from '../../../constants/permissions';
import PropTypes from 'prop-types';
import Table from '../../../common/components/Table';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { componentPermission } from '../../decorators/permissions';
import { connect } from 'react-redux';
import { fetchAllEngineerAircraftTypeExperience } from '../../actions/engineerAircraftTypeExperience';
import { fetchAllExternalCourses } from '../../actions/externalCourses';
import { fetchAllLicenses } from '../../actions/licenses';
import { fetchAllMedicals } from '../../actions/medicals';
import { fetchAllQualifications } from '../../actions/qualifications';
import { fetchAllRatings } from '../../actions/ratings';
import { hasModule } from '../../decorators/modules';
import { isVerified } from '../Users/PlannedAction/plannedActionHelper';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    allEngineerAircraftTypeExperience:
      state.engineerAircraftTypeExperience.allAircraftTypeExperienceList,
    allRatings: state.ratings.allRatingsList,
    ratingsRequest: state.ratings.ratingsRequest,
    allLicenses: state.licenses.allLicensesList,
    licensesRequest: state.licenses.licensesRequest,
    allMedicals: state.medicals.allMedicalsList,
    medicalsRequest: state.medicals.medicalsRequest,
    allQualifications: state.qualifications.allQualificationList,
    qualificationRequest: state.qualifications.qualificationRequest,
    allExternalCourses: state.externalCourses.allExternalCoursesList,
    externalRequest: state.externalCourses.externalCoursesRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAllRatings: (searchData) => dispatch(fetchAllRatings(searchData)),
    fetchAllLicenses: (searchData) => dispatch(fetchAllLicenses(searchData)),
    fetchAllMedicals: (searchData) => dispatch(fetchAllMedicals(searchData)),
    fetchAllQualifications: (searchData) =>
      dispatch(fetchAllQualifications(searchData)),
    fetchAllExternalCourses: (searchData) =>
      dispatch(fetchAllExternalCourses(searchData)),
    fetchAllEngineerAircraftTypeExperience: (searchData) =>
      dispatch(fetchAllEngineerAircraftTypeExperience(searchData)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.crewStatusView)
export default class CrewStatus extends Component {
  static propTypes = {
    allRatings: PropTypes.array.isRequired,
    ratingsRequest: PropTypes.bool,
    allLicenses: PropTypes.array.isRequired,
    licensesRequest: PropTypes.bool,
    allMedicals: PropTypes.array.isRequired,
    medicalsRequest: PropTypes.bool,
    allQualifications: PropTypes.array.isRequired,
    qualificationRequest: PropTypes.bool,
    allExternalCourses: PropTypes.array.isRequired,
    externalRequest: PropTypes.bool,
    allEngineerAircraftTypeExperience: PropTypes.array.isRequired,
    fetchAllRatings: PropTypes.func.isRequired,
    fetchAllLicenses: PropTypes.func.isRequired,
    fetchAllMedicals: PropTypes.func.isRequired,
    fetchAllQualifications: PropTypes.func.isRequired,
    fetchAllExternalCourses: PropTypes.func.isRequired,
    fetchAllEngineerAircraftTypeExperience: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: 'expiryDate',
      sortAsc: true,
      search: '',
      filter: {},
      filterIsUsed: false,
    },
  };

  componentDidMount() {
    this.props.fetchAllRatings();
    this.props.fetchAllLicenses();
    this.props.fetchAllMedicals();
    this.props.fetchAllQualifications();
    this.props.fetchAllExternalCourses();
    this.props.fetchAllEngineerAircraftTypeExperience();
  }

  renderIcons(user) {
    return (
      <Link
        className="table-row-link"
        key="user-profile-icon"
        to={{
          pathname: `/users/${user.id}`,
          state: { editMode: false },
        }}
      >
        <IconButton>
          <Icon color="primary">person</Icon>
        </IconButton>
      </Link>
    );
  }

  prepareList = (list, document) => {
    if (_isEmpty(list)) {
      return [];
    }

    return list.map((element) => {
      return {
        ...element,
        document,
      };
    });
  };

  getStatus = (expiryDate) => {
    let expiryClass = 'ok-date';

    if (expiryDate) {
      if (checkExpiredDate(expiryDate)) {
        expiryClass = 'expired-date';
      } else if (checkExpiryDate(expiryDate)) {
        expiryClass = 'expiry-date';
      }
    }

    return expiryClass;
  };

  getBackgroundColor = (expiryDate) => {
    return <div className={`status-circle ${this.getStatus(expiryDate)}`} />;
  };

  prepareElements = () => {
    const {
      allRatings,
      allLicenses,
      allMedicals,
      allQualifications,
      allExternalCourses,
      allEngineerAircraftTypeExperience,
      t,
    } = this.props;
    const preparedRatingList = this.prepareList(
      allRatings,
      t('rating', { context: 'lowercase' }),
    ).map((e) => {
      return hasModule(MODULES.engineerMode) && e.aircraftType
        ? { ...e, type: `${e.aircraftType.name} (${e.aircraftType.info})` }
        : e;
    });

    const preparedLicensesList = this.prepareList(
      allLicenses,
      t('license', { context: 'lowercase' }),
    );
    const preparedMedicalList = this.prepareList(
      allMedicals,
      t('medical', { context: 'lowercase' }),
    );
    const preparedQualificationList = this.prepareList(
      allQualifications,
      t('qualification', { context: 'lowercase' }),
    );
    const preparedExternalCourseList = this.prepareList(
      allExternalCourses,
      t('externalCourse', { context: 'lowercase' }),
    );

    const preparedUserExperienceList = this.prepareList(
      allEngineerAircraftTypeExperience,
      t('userExperience', { context: 'lowercase' }),
    ).map((e) => {
      return { ...e, type: `${e.type.name} (${e.type.info})` };
    });

    const elements = [];

    return elements.concat(
      preparedRatingList,
      preparedLicensesList,
      preparedMedicalList,
      preparedQualificationList,
      preparedExternalCourseList,
      preparedUserExperienceList,
    );
  };

  handleSort = (column) => {
    const { columnToSort, sortAsc, filter, search, filterIsUsed } =
      this.state.searchSettings;

    const searchData = {
      columnToSort: column,
      sortAsc: column === columnToSort ? !sortAsc : true,
      filter,
      search,
      filterIsUsed,
    };

    this.setState({
      searchSettings: searchData,
    });
  };

  handleSearch = (search) => {
    const { columnToSort, sortAsc, filter, filterIsUsed } =
      this.state.searchSettings;
    const searchData = {
      columnToSort,
      sortAsc,
      filter,
      filterIsUsed,
      search,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchAllRatings(searchData);
    this.props.fetchAllLicenses(searchData);
    this.props.fetchAllMedicals(searchData);
    this.props.fetchAllQualifications(searchData);
    this.props.fetchAllExternalCourses(searchData);
    this.props.fetchAllEngineerAircraftTypeExperience(searchData);
  };

  getElements = () => {
    const { searchSettings } = this.state;
    const elements = this.prepareElements();

    if (_isEmpty(searchSettings.filter.documents)) {
      return elements;
    }
    const selectedDocuments = searchSettings.filter.documents.map(
      (document) => document.id,
    );

    return elements.filter((element) =>
      selectedDocuments.includes(element.document),
    );
  };

  addDisplayName = (collection) => {
    const { t } = this.props;

    return collection.map((item) => {
      const displayName = `table:${item.id}`;

      return {
        ...item,
        name: item.id,
        displayName: t(displayName),
      };
    });
  };

  getUserFilters = () => {
    const { searchSettings } = this.state;
    const selectedMonths = _get(searchSettings, 'filter.months', []);
    const selectedDocuments = _get(searchSettings, 'filter.documents', []);

    return (
      <CustomFilter
        groups={this.addDisplayName(documents)}
        roles={this.addDisplayName(months)}
        radioButton
        isFilterUsed={searchSettings.filterIsUsed}
        handleFilter={this.handleFilter}
        filterSettings={selectedDocuments.concat(selectedMonths)}
      />
    );
  };

  manageData() {
    const elements = this.getElements();

    const parsedUser = elements.map((element) => ({
      statusData: this.getStatus(element.expiryDate),
      status: this.getBackgroundColor(element.expiryDate),
      type: element.type,
      document: element.document,
      expiryDate: reformatPickerDateFromDB(element.expiryDate, false),
      firstName: element.user.firstName,
      lastName: element.user.lastName,
      isVerified: isVerified(element.isVerified),
      plannedAction: element.plannedAction,
      plannedActionDate: reformatPickerDateFromDB(
        element.plannedActionDate,
        false,
      ),
      icons: this.renderIcons(element.user),
      id: `${element.user.id}-${element.type}-${element.id}-${element.document}`,
    }));

    return parsedUser;
  }

  handleFilter = (values) => {
    const { columnToSort, sortAsc, search } = this.state.searchSettings;
    const preparedValues = values.filter((value) => value && value.id);
    const months = preparedValues.filter((value) => value.type === 'radio');
    const monthIds = months.map((month) => Number(month.id));
    const maxMonthId = Math.max(...monthIds);
    const month = months.find((month) => Number(month.id) === maxMonthId);

    const filterData = {
      columnToSort,
      sortAsc,
      filter: {
        documents: preparedValues.filter((value) => value.type !== 'radio'),
        months: [month],
      },
      search,
      filterIsUsed: !_isEmpty(preparedValues),
    };

    this.setState({
      searchSettings: filterData,
    });

    this.props.fetchAllRatings(filterData);
    this.props.fetchAllLicenses(filterData);
    this.props.fetchAllMedicals(filterData);
    this.props.fetchAllQualifications(filterData);
    this.props.fetchAllExternalCourses(filterData);
  };

  sort(list, column, sortAsc) {
    switch (column) {
      case 'status':
        list.sort((a, b) => {
          return sortAsc
            ? sortStatusWeight(a.statusData, b.statusData)
            : sortStatusWeight(b.statusData, a.statusData);
        });

        break;
      case 'type':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.type, b.type)
            : sortString(b.type, a.type);
        });

        break;
      case 'document':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.document, b.document)
            : sortString(b.document, a.document);
        });

        break;
      case 'expiryDate':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.expiryDate, b.expiryDate)
            : sortString(b.expiryDate, a.expiryDate);
        });

        break;
      case 'firstName':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.firstName, b.firstName)
            : sortString(b.firstName, a.firstName);
        });

        break;
      case 'lastName':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.lastName, b.lastName)
            : sortString(b.lastName, a.lastName);
        });

        break;
      case 'isVerified':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.isVerified, b.isVerified)
            : sortString(b.isVerified, a.isVerified);
        });

        break;
      case 'plannedAction':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.plannedAction, b.plannedAction)
            : sortString(b.plannedAction, a.plannedAction);
        });

        break;
      case 'plannedActionDate':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.plannedActionDate, b.plannedActionDate)
            : sortString(b.plannedActionDate, a.plannedActionDate);
        });

        break;
    }

    return list;
  }

  renderTable = () => {
    const { t } = this.props;
    const { searchSettings } = this.state;

    return (
      <Table
        data={this.sort(
          this.manageData(),
          searchSettings.columnToSort,
          searchSettings.sortAsc,
        )}
        header={tableHeader}
        handleSort={this.handleSort}
        sortAsc={searchSettings.sortAsc}
        columnToSort={searchSettings.columnToSort}
        handleSearch={this.handleSearch}
        search={searchSettings.search}
        getUserFilters={this.getUserFilters}
        searchLabel={t('input:searchLabel')}
      />
    );
  };

  render() {
    const {
      ratingsRequest,
      licensesRequest,
      medicalsRequest,
      qualificationRequest,
      externalRequest,
      t,
    } = this.props;

    if (
      ratingsRequest ||
      licensesRequest ||
      medicalsRequest ||
      qualificationRequest ||
      externalRequest
    ) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center crew-status">
        <div className="col-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">person</Icon>
              <h1>{t('crewStatus:name')}</h1>
            </span>
            <p>{t('crewStatus:description')}</p>
          </header>
        </div>
        {this.renderTable()}
      </div>
    );
  }
}
