import React, { Component } from 'react';
import {
  addInspection,
  editInspection,
  removeInspection,
} from '../../../actions/inspections';
import {
  attachInspectionFile,
  removeInspectionFile,
} from '../../../actions/fileUpload';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InspectionList from './InspectionList';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import _get from 'lodash/get';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const RECURRENT_CYCLES = 'cycles';
const RECURRENT_HOURS = 'hrs';

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  id: '',
  name: '',
  description: '',
  serviceHours: null,
  serviceCycles: null,
  serviceDate: null,
  inspectionDate: null,
  isCompleted: false,
  showRemoveDialog: false,
  isRecurrent: false,
  recurrent: {},
  serviceActualHours: null,
  serviceActualCycles: null,
  plannedAction: null,
  plannedActionDate: null,
  removeCallback: () => {},
};

function mapDispatchToProps(dispatch) {
  return {
    addInspection: (data) => dispatch(addInspection(data)),
    editInspection: (data) => dispatch(editInspection(data)),
    removeInspection: (data) => dispatch(removeInspection(data)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    attachInspectionFile: (fileData, id, fleetId) =>
      dispatch(attachInspectionFile(fileData, id, fleetId)),
    removeInspectionFile: (fileId, id) =>
      dispatch(removeInspectionFile(fileId, id)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(() => ({}), mapDispatchToProps)
export default class InspectionsManager extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    inspectionList: PropTypes.array.isRequired,
    addInspection: PropTypes.func.isRequired,
    editInspection: PropTypes.func.isRequired,
    removeInspection: PropTypes.func.isRequired,
    attachInspectionFile: PropTypes.func.isRequired,
    removeInspectionFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    fleetSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.formData = {};
  }

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (inspection) => {
    this.setState({
      editOpen: true,
      id: inspection.id,
      name: inspection.name,
      description: inspection.description,
      serviceHours: inspection.serviceHours,
      serviceCycles: inspection.serviceCycles,
      isCompleted: inspection.isCompleted,
      serviceDate: reformatPickerDateFromDB(
        _get(inspection, 'serviceDate', null),
      ),
      inspectionDate: reformatPickerDateFromDB(
        _get(inspection, 'inspectionDate', null),
      ),
      serviceActualHours: inspection.serviceActualHours,
      serviceActualCycles: inspection.serviceActualCycles,
      plannedAction: inspection.plannedAction,
      plannedActionDate: reformatPickerDateFromDB(
        _get(inspection, 'plannedActionDate', null),
      ),
    });

    if (inspection.recurrent) {
      const recurrent = JSON.parse(inspection.recurrent);

      // Backward compatible
      if (_get(recurrent, 'recurrentType', false)) {
        recurrent[recurrent.recurrentType] = Number(recurrent.recurrentValue);

        delete recurrent.recurrentType;
        delete recurrent.recurrentValue;
      }

      this.setState({
        isRecurrent: true,
        recurrent,
      });
    }
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      [name]: dateFromPicker,
    });
  };

  onChange = (formData, name) => {
    this.setState({
      [name]: formData[name].value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    if (event.target.checked) {
      if (name === 'isRecurrent') {
        const recurrent = {
          RECURRENT_CYCLES: 0,
          RECURRENT_HOURS: 0,
        };

        this.setState({
          [name]: event.target.checked,
          recurrent,
        });
      } else {
        this.setState({
          [name]: event.target.checked,
          inspectionDate: moment(),
        });
      }
    } else {
      this.setState({
        [name]: event.target.checked,
      });
    }
  };

  addInspection = () => {
    const { fleet } = this.props;
    const {
      name,
      description,
      serviceHours,
      serviceCycles,
      serviceDate,
      inspectionDate,
      isCompleted,
      isRecurrent,
      cycles,
      hrs,
      serviceActualHours,
      serviceActualCycles,
      plannedAction,
      plannedActionDate,
    } = this.state;

    let data = {
      name,
      description,
      serviceHours: parseInt(serviceHours) > 0 ? Number(serviceHours) : null,
      serviceCycles: parseInt(serviceCycles) > 0 ? Number(serviceCycles) : null,
      isCompleted,
      serviceDate: reformatPickerDateToDB(serviceDate),
      inspectionDate: reformatPickerDateToDB(inspectionDate),
      plannedAction,
      plannedActionDate: reformatPickerDateToDB(plannedActionDate),
      fleet: fleet.id,
    };

    const recurrentCycles = parseInt(cycles) > 0 ? Number(cycles) : 0;
    const recurrentHours = parseInt(hrs) > 0 ? Number(hrs) : 0;

    if (isCompleted && isRecurrent) {
      const refinedServiceHours =
        parseInt(serviceActualHours) > 0
          ? Number(serviceActualHours)
          : data.serviceHours;

      const refinedServiceCycles =
        parseInt(serviceActualCycles) > 0
          ? Number(serviceActualCycles)
          : data.serviceCycles;

      data = {
        ...data,
        isCompleted: false,
        serviceCycles: recurrentCycles > 0 ? refinedServiceCycles : null,
        serviceHours: recurrentHours > 0 ? refinedServiceHours : null,
        serviceDate: null,
        inspectionDate: null,
        plannedAction: null,
        plannedActionDate: null,
      };

      data.serviceCycles = data.serviceCycles
        ? data.serviceCycles + recurrentCycles
        : null;

      data.serviceHours = data.serviceHours
        ? data.serviceHours + recurrentHours
        : null;
    }

    if (isRecurrent) {
      const recurrent = {};

      if (recurrentCycles > 0) {
        recurrent[RECURRENT_CYCLES] = cycles;
      }

      if (recurrentHours > 0) {
        recurrent[RECURRENT_HOURS] = hrs;
      }

      data = Object.assign({}, data, { recurrent: JSON.stringify(recurrent) });
    } else {
      data = Object.assign({}, data, { recurrent: null });
    }

    this.props.addInspection(data);
    this.handleDialogClose();
  };

  editInspection = () => {
    const { fleet } = this.props;
    const {
      id,
      name,
      description,
      serviceCycles,
      serviceHours,
      serviceDate,
      inspectionDate,
      isCompleted,
      isRecurrent,
      cycles,
      hrs,
      days,
      serviceActualHours,
      serviceActualCycles,
      plannedAction,
      plannedActionDate,
    } = this.state;

    let data = {
      id,
      name,
      description,
      serviceHours: parseInt(serviceHours) > 0 ? Number(serviceHours) : null,
      serviceCycles: parseInt(serviceCycles) > 0 ? Number(serviceCycles) : null,
      isCompleted,
      serviceDate: reformatPickerDateToDB(serviceDate),
      inspectionDate: reformatPickerDateToDB(inspectionDate),
      serviceActualHours:
        parseInt(serviceActualHours) > 0 ? serviceActualHours : null,
      serviceActualCycles:
        parseInt(serviceActualCycles) > 0 ? serviceActualCycles : null,
      fleet: fleet.id,
      plannedAction,
      plannedActionDate: reformatPickerDateToDB(plannedActionDate),
    };

    if (isRecurrent && !isCompleted) {
      const recurrentCycles = parseInt(cycles) > 0 ? Number(cycles) : 0;
      const recurrentHours = parseInt(hrs) > 0 ? Number(hrs) : 0;
      const recurrentDays = parseInt(days) > 0 ? Number(days) : 0;

      const recurrent = {};

      if (recurrentCycles > 0) {
        recurrent[RECURRENT_CYCLES] = cycles;
      }

      if (recurrentHours > 0) {
        recurrent[RECURRENT_HOURS] = hrs;
      }

      data = Object.assign({}, data, { recurrent: JSON.stringify(recurrent) });
    } else if (isCompleted && isRecurrent) {
      this.addInspection();
      data = Object.assign({}, data, {
        recurrent: null,
        plannedAction: null,
        plannedActionDate: null,
      });
    }

    this.props.editInspection(data);
    this.handleDialogClose();
  };

  onFormValidated = (isFormValid) => {
    const { addOpen } = this.state;

    if (isFormValid && this.formData) {
      addOpen ? this.addInspection() : this.editInspection();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const {
      fleet,
      inspectionList,
      removeInspection,
      classes,
      t,
      attachInspectionFile,
      removeInspectionFile,
      addSimpleError,
      editMode,
      fleetSettings,
    } = this.props;
    const {
      addOpen,
      editOpen,
      name,
      description,
      serviceHours,
      serviceCycles,
      serviceDate,
      inspectionDate,
      isCompleted,
      showRemoveDialog,
      removeCallback,
      isRecurrent,
      recurrent,
      serviceActualHours,
      serviceActualCycles,
      plannedAction,
      plannedActionDate,
    } = this.state;

    return (
      <div className="inspection-settings">
        <h4>{t('fleet:inspectionsSection')}</h4>
        <InspectionList
          fleet={fleet}
          inspectionList={inspectionList}
          removeInspection={removeInspection}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          onElementDelete={this.openRemoveDialog}
          attachInspectionFile={attachInspectionFile}
          removeInspectionFile={removeInspectionFile}
          addSimpleError={addSimpleError}
          editMode={editMode}
          fleetSettings={fleetSettings}
        />
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={addOpen || editOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="add-inspection-type-dialog"
          maxWidth="md"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle id="add-edit-inspection-type-dialog">
              {addOpen
                ? t('fleet:addInspectionDialogTitle')
                : t('fleet:editInspectionDialogTitle')}
            </DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row">
                <div className="col-6">
                  <MaterialInput
                    label={t('input:name')}
                    autoFocus
                    name="name"
                    defaultValue={name}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                    ]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:serviceDate')}
                    name="serviceDate"
                    value={serviceDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange(
                      'serviceDate',
                    )}
                    dateFormat={getDateFormat()}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <MaterialInput
                    label={t('input:description')}
                    name="description"
                    defaultValue={description}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                    ]}
                    multiline
                    fullWidth
                  />
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-7">
                  <MaterialInput
                    type="text"
                    label={t('input:plannedAction')}
                    name="plannedAction"
                    defaultValue={plannedAction}
                    margin="dense"
                    validators={[new validators.MaxLength(t, 250)]}
                    fullWidth
                  />
                </div>
                <div className="col-3 offset-1">
                  <DateTimePicker
                    label={t('input:plannedActionDate')}
                    name="plannedActionDate"
                    value={plannedActionDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange(
                      'plannedActionDate',
                    )}
                    dateFormat={getDateFormat()}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-3">
                  <MaterialInput
                    type="number"
                    step="0.1"
                    type="number"
                    label={t('input:serviceHours')}
                    name="serviceHours"
                    defaultValue={serviceHours}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-3 offset-1">
                  <MaterialInput
                    type="number"
                    label={t('input:serviceCycles')}
                    name="serviceCycles"
                    defaultValue={serviceCycles}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <FormControl margin="dense">
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={isRecurrent}
                          onChange={this.handleCheckboxChange('isRecurrent')}
                        />
                      }
                      label={t('input:isRecurrent')}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-3">
                  <MaterialInput
                    type="number"
                    label={t('input:recurrentValueHours')}
                    name={RECURRENT_HOURS}
                    defaultValue={recurrent[RECURRENT_HOURS]}
                    margin="dense"
                    disabled={!isRecurrent}
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-3 offset-1">
                  <MaterialInput
                    type="number"
                    label={t('input:recurrentValueCycles')}
                    name={RECURRENT_CYCLES}
                    defaultValue={recurrent[RECURRENT_CYCLES]}
                    margin="dense"
                    disabled={!isRecurrent}
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-12">
                  <FormControl margin="dense">
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={isCompleted}
                          onChange={this.handleCheckboxChange('isCompleted')}
                        />
                      }
                      label={t('input:isCompleted')}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-3">
                  <DateTimePicker
                    label={t('input:inspectionDate')}
                    name="inspectionDate"
                    value={inspectionDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange(
                      'inspectionDate',
                    )}
                    dateFormat={getDateFormat()}
                    disabled={!isCompleted}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-3">
                  <MaterialInput
                    type="number"
                    step="0.1"
                    type="number"
                    label={t('input:actual hours')}
                    name="serviceActualHours"
                    disabled={!isCompleted}
                    defaultValue={serviceActualHours}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-3 offset-1">
                  <MaterialInput
                    type="number"
                    label={t('input:actual cycles')}
                    name="serviceActualCycles"
                    disabled={!isCompleted}
                    defaultValue={serviceActualCycles}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </div>
    );
  }
}
