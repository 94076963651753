import '../styles.scss';

import {
  CO_PILOT,
  DEPENDENT_NAMES_DICTIONARY,
  DEPENDENT_NAMES_LIST,
  SIMULATOR_TYPE,
  ZERO_TIME,
} from '../../../../constants/index';
import React, { Component } from 'react';
import {
  addFlightLog,
  cleanFlightLogData,
  editFlightLog,
} from '../../../actions/flightLog';
import { addFlightLogFunction, getDataFromList } from './addFlightLogHelper';
import {
  checkEnteredTimeFormat,
  dateTimeDiffInTimeFormat,
  getDateFormat,
  getDateTimeFormat,
  getDependentTimesDistance,
  getTimeFormat,
  reformatPickerDateFromDB,
  reformatPickerDateTimeFromDB,
  timeToMinutes,
} from '../../../utils/time';
import {
  isAdmin,
  isInstructor,
  isManager,
  isSuperAdmin,
} from '../../../../utils';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Form from '../../../../common/components/forms/Form';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { fetchAircrafts } from '../../../actions/aircrafts';
import { fetchLocations } from '../../../actions/locations';
import { fetchPilotFunctions } from '../../../actions/pilotFunction';
import { fetchUsers } from '../../../actions/users';
import moment from 'moment';
import userService from '../../../../utils/userService';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '105px',
  },
  conditionTime: {
    width: '150px',
  },
  totalTime: {
    width: '120px',
    fontWeight: 'bold',
  },
});

const mapStateToProps = (state) => {
  return {
    pilotFunctionList: state.pilotFunction.pilotFunctionList,
    pilotFunctionsRequest: state.pilotFunction.pilotFunctionsRequest,
    locationList: state.locations.locationList.filter(
      (location) => location.isActive,
    ),
    aircraftList: state.aircrafts.aircraftList.filter(
      (aircraft) => aircraft.isActive,
    ),
    aircraftRequest: state.aircrafts.aircraftRequest,
    locationRequest: state.locations.locationRequest,
    addFlightLogSuccess: state.flightLog.addFlightLogSuccess,
    userList: state.users.userList,
    getUsersRequest: state.users.getUsersRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchData: (userId) => {
      dispatch(fetchPilotFunctions());
      dispatch(fetchLocations(userId));
      dispatch(fetchAircrafts(userId));
    },
    fetchUsers: () => dispatch(fetchUsers()),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    addFlightLog: (flightLog) => dispatch(addFlightLog(flightLog)),
    editFlightLog: (id, flightLog) => dispatch(editFlightLog(id, flightLog)),
    cleanFlightLogData: () => dispatch(cleanFlightLogData()),
  };
}

const PILOT_FUNCTION_PIC = '1';

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class AddFlightLog extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    addFlightLog: PropTypes.func.isRequired,
    editFlightLog: PropTypes.func.isRequired,
    cleanFlightLogData: PropTypes.func.isRequired,
    userSettings: PropTypes.object,
    pilotFunctionList: PropTypes.array,
    aircraftList: PropTypes.array,
    locationList: PropTypes.array,
    aircraftRequest: PropTypes.bool.isRequired,
    locationRequest: PropTypes.bool.isRequired,
    pilotFunctionsRequest: PropTypes.bool,
    addFlightLogSuccess: PropTypes.bool.isRequired,
    fetchData: PropTypes.func,
    fetchUsers: PropTypes.func,
    userList: PropTypes.array.isRequired,
    getUsersRequest: PropTypes.bool,
    previewMode: PropTypes.bool,
    editMode: PropTypes.bool,
    flightLog: PropTypes.object,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = {
      remarks: this.getValueFromFlightLogOrUserSettings('remarks', ''),
      departure: this.getValueFromFlightLogOrUserSettings(
        'departure',
        'departure.name',
        '',
      ),
      arrival: this.getValueFromFlightLogOrUserSettings(
        'arrival',
        'arrival.name',
        '',
      ),
      aircraft: this.getValueFromFlightLogOrUserSettings(
        'aircraft.id',
        'aircraft.id',
        '',
      ),
      pilotFunction: this.getValueFromFlightLogOrUserSettings(
        'pilotFunction',
        'pilotFunction.id',
        '',
      ),
      dayLandings: this.getValueFromFlightLogOrUserSettings(
        'dayLandings',
        'dayLandings',
        0,
      ),
      nightLandings: this.getValueFromFlightLogOrUserSettings(
        'nightLandings',
        'nightLandings',
        0,
      ),
      dateOfFlight: this.getValueFromFlightLogOrUserSettings(
        'dateOfFlight',
        'dateOfFlight',
        moment(),
      ),
      departureTime: this.getValueFromFlightLogOrUserSettings(
        'departureDate',
        'departureDate',
        moment(),
      ),
      arrivalTime: this.getValueFromFlightLogOrUserSettings(
        'arrivalDate',
        'arrivalDate',
        moment(),
      ),
      day: this.getValueFromFlightLogOrUserSettings('day', 'day', ZERO_TIME),
      night: this.getValueFromFlightLogOrUserSettings(
        'night',
        'night',
        ZERO_TIME,
      ),
      vfr: this.getValueFromFlightLogOrUserSettings('vfr', 'vfr', ZERO_TIME),
      ifr: this.getValueFromFlightLogOrUserSettings('ifr', 'ifr', ZERO_TIME),
      totalTime: this.getValueFromFlightLogOrUserSettings(
        'totalTime',
        'totalTime',
        ZERO_TIME,
      ),
      isCoPilotFunction: false,
      isFormValid: false,
      autoSuggestionDepartureisValid: false,
      autoSuggestionArrivalisValid: false,
      additionalPilot: this.getValueFromFlightLogOrUserSettings(
        'additionalPilot.id',
        'additionalPilot.id',
        null,
      ),
      additionalPilotFunction: this.getValueFromFlightLogOrUserSettings(
        'additionalPilotFunction',
        'additionalPilotFunction',
        '',
      ),
      user: this.getValueFromFlightLogOrUserSettings('user.id', 'user.id', ''),
    };
  }

  componentDidMount() {
    const { fetchData, fetchUsers } = this.props;
    const user = localStorage.getItem('user');
    const userId = JSON.parse(user).id.toString();

    this.formData = {};

    fetchData(userId);
    fetchUsers();
  }

  componentWillUnmount() {
    this.props.cleanFlightLogData();
  }

  getValueFromFlightLogOrUserSettings = (
    valueFromFlightLog,
    valueFromUserSettings,
    defaultValue,
  ) => {
    const { previewMode, editMode } = this.props;

    if (previewMode || editMode) {
      return _get(this.props.flightLog, valueFromFlightLog, defaultValue);
    }

    return _get(this.props.userSettings, valueFromUserSettings, defaultValue);
  };

  getValuesFromList = (name, value) => {
    return name ? name : _get(this.props.userSettings, value, '');
  };

  getTotalTime = (name, date) => {
    const { arrivalTime, departureTime } = this.state;
    const { editMode } = this.props;
    let totalTime = ZERO_TIME;

    const departureDateTime = editMode
      ? reformatPickerDateTimeFromDB(departureTime)
      : departureTime;
    const arrivalDateTime = editMode
      ? reformatPickerDateTimeFromDB(arrivalTime)
      : arrivalTime;

    if (name === 'arrivalTime') {
      totalTime = dateTimeDiffInTimeFormat(date, departureDateTime);
    } else {
      totalTime = dateTimeDiffInTimeFormat(arrivalDateTime, date);
    }

    return totalTime;
  };

  calculateTheDependentTimes = (changedValue) => {
    const { totalTime } = this.state;
    let result = ZERO_TIME;

    if (checkEnteredTimeFormat(changedValue)) {
      result = getDependentTimesDistance(totalTime, changedValue);
    }

    return result;
  };

  checkValue = (value) => {
    const { totalTime } = this.state;

    if (
      checkEnteredTimeFormat(value) &&
      timeToMinutes(value) > timeToMinutes(totalTime)
    ) {
      return ZERO_TIME;
    }

    return value;
  };

  detectCoPilotFunction = (nameToDetect, id) => {
    const { pilotFunctionList } = this.props;
    const selectedRecord = pilotFunctionList.find(
      (pilotFunction) => pilotFunction.name === nameToDetect,
    );

    return selectedRecord ? selectedRecord.id.toString() === id : false;
  };

  onChange = (formData, name) => {
    const { value } = formData[name];
    const isCoPilotFunction = this.detectCoPilotFunction(CO_PILOT, value);

    if (DEPENDENT_NAMES_LIST.includes(name)) {
      this.setState({
        [name]: this.checkValue(value),
        [DEPENDENT_NAMES_DICTIONARY[name]]: this.calculateTheDependentTimes(
          this.checkValue(value),
        ),
        isCoPilotFunction,
      });
    } else {
      this.setState({
        [name]: value,
        isCoPilotFunction,
      });
    }
  };

  getUserFromSessionStorage = () => {
    return userService.getCurrentUser().id;
  };

  getUserId = () => {
    const { flightLog, editMode } = this.props;

    return editMode
      ? _get(flightLog, 'user.id', this.getUserFromSessionStorage())
      : this.getUserFromSessionStorage();
  };

  prepareFlightLogData = () => {
    const {
      aircraftList,
      pilotFunctionList,
      locationList,
      userSettings,
      editMode,
    } = this.props;
    const {
      remarks,
      aircraft,
      dateOfFlight,
      day,
      night,
      vfr,
      ifr,
      totalTime,
      dayLandings,
      nightLandings,
      pilotFunction,
      additionalPilot,
      additionalPilotFunction,
      departureTime,
      arrivalTime,
      departure,
      arrival,
      instructorCheck,
    } = this.state;

    const userId = this.getUserId();

    let departureDateTime = departureTime;
    let arrivalDateTime = arrivalTime;

    if (editMode) {
      departureDateTime = reformatPickerDateTimeFromDB(departureTime);
      arrivalDateTime = reformatPickerDateTimeFromDB(arrivalTime);
    }

    const newFlightLog = addFlightLogFunction({
      userId,
      remarks,
      aircraft,
      dateOfFlight,
      day,
      night,
      vfr,
      ifr,
      totalTime,
      dayLandings,
      nightLandings,
      pilotFunction,
      additionalPilot,
      additionalPilotFunction,
      departure,
      departureTime: departureDateTime,
      arrivalTime: arrivalDateTime,
      arrival,
      instructorCheck,
      aircraftList,
      pilotFunctionList,
      locationList,
      userSettings,
    });

    return newFlightLog;
  };

  addFlightLog = () => {
    const { userId } = this.state;

    const newFlightLog = userId
      ? { ...this.prepareFlightLogData(), userId }
      : this.prepareFlightLogData();

    this.props.addFlightLog(newFlightLog);
  };

  editFlightLog = () => {
    const { flightLog } = this.props;

    const updatedFlightLog = this.prepareFlightLogData();

    this.props.editFlightLog(flightLog.id, updatedFlightLog);
  };

  onFormValidated = (isFormValid) => {
    const { editMode } = this.props;

    if (isFormValid) {
      editMode ? this.editFlightLog() : this.addFlightLog();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.triggerFormValidation();
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  handleFlightDatePickerChange = (name) => (date) => {
    const { conditionTime, flightRule } = this.props.userSettings;
    const totalTime = this.getTotalTime(name, date);

    this.setState({
      [name]: date,
      totalTime,
      [conditionTime]: totalTime,
      [flightRule]: totalTime,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  isChecked(name) {
    return !!this.state[name];
  }

  handleCheckedSchedule = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  menuItems(dataToShow, name, registration = false) {
    if (_isEmpty(dataToShow)) {
      return null;
    }

    return dataToShow.map((data) => {
      return (
        <MenuItem key={`${name}-${data.id}`} value={data.id}>
          {registration ? data.registration : data.name}
        </MenuItem>
      );
    });
  }

  detectPilotFunction = (nameToDetect) => {
    const { pilotFunction } = this.state;
    const { pilotFunctionList } = this.props;
    const pilotFunctionId = this.getValuesFromList(
      pilotFunction,
      'pilotFunction.id',
    );
    const selectedRecord = pilotFunctionList.find(
      (pilotFunction) => pilotFunction.name === nameToDetect,
    );

    return selectedRecord
      ? selectedRecord.id.toString() === pilotFunctionId
      : false;
  };

  renderDateTimePicker = (data) => {
    return (
      <DateTimePicker
        fullWidth
        value={data.value}
        showMonthDropdown
        showTimeSelect={data.showTimeSelect}
        showYearDropdown
        handleDatePickerChange={data.handleDatePickerChange}
        dateFormat={data.dateFormat}
        name={data.name}
        timeFormat={getTimeFormat()}
        label={data.label}
        disabled={data.disabled}
      />
    );
  };

  registerComponentForm(componentName) {
    return (triggerFn) => {
      this.triggers[componentName] = triggerFn;
    };
  }

  formValidated = (formName, isFormValid, formChanges) => {
    this.formData = {
      ...this.formData,
      [`${formName}Changes`]: formChanges,
      [`${formName}isValid`]: isFormValid,
    };
  };

  renderSelect = (
    style,
    inputName,
    inputLabel,
    value,
    changeFunction,
    previewMode,
    registerComponentName,
    formName,
  ) => {
    const { locationList } = this.props;

    return (
      <div className={style}>
        <AutoSuggestion
          label={inputName}
          inputName={inputLabel}
          defaultValue={value ? value : ''}
          handleChange={changeFunction}
          typeList={locationList}
          preview={previewMode}
          registerComponentForm={registerComponentName}
          formName={formName}
          fullWidth
        />
      </div>
    );
  };

  renderMaterialInput = (data) => {
    return (
      <MaterialInput
        className={data.style}
        name={data.name}
        label={data.label}
        type={data.type}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        validators={data.validators}
        defaultValue={data.defaultValue}
        value={data.value}
        disabled={data.disabled}
      />
    );
  };

  renderTextField = (data) => {
    const { classes } = this.props;

    return (
      <div
        className={
          data.divStyle ? data.divStyle : 'col-lg-3 col-sm-5 col-xs-12'
        }
      >
        <TextField
          className={data.textStyle ? data.textStyle : classes.root}
          name={data.name}
          label={data.label}
          value={data.value}
          disabled
        />
      </div>
    );
  };

  renderFlightLogEditButton() {
    const { flightLog, previewMode } = this.props;

    if (!previewMode) {
      return <div />;
    }

    return (
      <div className="col-auto">
        <Link
          to={{
            pathname: `/flight_log/edit/${flightLog.id}`,
            state: { editMode: true },
          }}
        >
          <IconButton>
            <Icon>mode_edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  renderViewLabel = () => {
    const { editMode, previewMode } = this.props;

    if (editMode) {
      return '/ edit mode';
    } else if (previewMode) {
      return '/ preview mode';
    }

    return '';
  };

  getAircraftData = () => {
    const { aircraftList, userSettings } = this.props;
    const { aircraft } = this.state;

    return getDataFromList(aircraft, aircraftList, 'aircraft.id', userSettings);
  };

  isSimulatorType = () => {
    const aircraftData = this.getAircraftData();
    const aircraftType = _get(aircraftData, 'type', '');

    return aircraftType === SIMULATOR_TYPE;
  };

  renderAircraftType = (t) => {
    const { classes } = this.props;
    const aircraftData = this.getAircraftData();

    return this.renderTextField({
      divStyle: 'col-lg-3 col-sm-5 col-xs-12 pl-0',
      textStyle: classes.root,
      name: 'type',
      label: t('input:type'),
      value: _get(aircraftData, 'type', ''),
    });
  };

  renderAircraftDetails = (t) => {
    const aircraftData = this.getAircraftData();
    const type = `settings:${(aircraftData && aircraftData.type) || ' '}`;
    const engine = `settings:${(aircraftData && aircraftData.engine) || ' '}`;
    const propulsion = `settings:${
      (aircraftData && aircraftData.propulsion) || ' '
    }`;
    const pilot = `settings:${(aircraftData && aircraftData.pilot) || ' '}`;

    return (
      <div className="row pl-3">
        {this.renderTextField({
          name: 'type',
          label: t('input:type'),
          value: t(type),
        })}
        {this.renderTextField({
          name: 'engine',
          label: t('input:engine'),
          value: t(engine),
        })}
        {this.renderTextField({
          name: 'engineType',
          label: t('input:engineType'),
          value: t(propulsion),
        })}
        {this.renderTextField({
          name: 'pilot',
          label: t('input:pilot'),
          value: t(pilot),
        })}
      </div>
    );
  };

  getDisabledFlag = (isCoPilotFunction, previewMode) => {
    return previewMode || !isCoPilotFunction;
  };

  renderPilot = () => {
    const { userList, t, previewMode, editMode } = this.props;

    const currentUserId = this.getUserFromSessionStorage();

    const user = userList.find(({ id }) => id === currentUserId);

    if (user && (editMode || previewMode)) {
      return (
        <div className="col-lg-3 col-sm-5 col-xs-12">
          <TextField
            margin="dense"
            label={t('input:Pilot')}
            value={`${user.firstName} ${user.lastName}`}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            disabled={previewMode}
          />
        </div>
      );
    }

    return user &&
      (isAdmin(user) ||
        isManager(user) ||
        isInstructor(user) ||
        isSuperAdmin(user)) ? (
      <div className="col-lg-3 col-sm-5 col-xs-12">
        <MaterialSelect
          id="userId"
          name="userId"
          label={t('input:pilot')}
          defaultValue={currentUserId}
          options={userList.map(({ id, firstName, lastName }) => ({
            value: id,
            label: `${firstName} ${lastName}`,
          }))}
        />
      </div>
    ) : (
      ''
    );
  };

  render() {
    const {
      classes,
      pilotFunctionsRequest,
      locationRequest,
      aircraftRequest,
      pilotFunctionList,
      userList,
      aircraftList,
      previewMode,
      getUsersRequest,
      t,
    } = this.props;
    const {
      dateOfFlight,
      departureTime,
      arrivalTime,
      remarks,
      departure,
      arrival,
      aircraft,
      user,
      pilotFunction,
      additionalPilot,
      additionalPilotFunction,
      dayLandings,
      nightLandings,
      day,
      night,
      vfr,
      ifr,
      totalTime,
      userId,
    } = this.state;

    if (
      pilotFunctionsRequest ||
      locationRequest ||
      aircraftRequest ||
      getUsersRequest
    ) {
      return <Loader />;
    }

    const currentUserId = userId
      ? Number(userId)
      : this.getUserFromSessionStorage();

    const additionalPilots = userList.filter(({ id }) => currentUserId !== id);

    const additionalPilotFunctions = pilotFunctionList.filter(
      ({ id }) =>
        !(
          PILOT_FUNCTION_PIC === String(pilotFunction) &&
          PILOT_FUNCTION_PIC === String(id)
        ),
    );

    const pilotFunctions = pilotFunctionList.filter(
      ({ id }) =>
        !(
          PILOT_FUNCTION_PIC === String(additionalPilotFunction) &&
          PILOT_FUNCTION_PIC === String(id)
        ),
    );

    return (
      <div className="col-auto logbook-wrapper add-logbook ">
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <div className="row justify-content-lg-between justify-content-end align-items-center">
            <div className="col-auto section-title pb-5">
              <header>
                <span>
                  <Icon color="primary">airplanemode_active</Icon>
                  <h1>
                    {t('logbook:name')}
                    <span className="h1-subheader">
                      {this.renderViewLabel()}
                    </span>
                  </h1>
                </span>
              </header>
            </div>
            {this.renderFlightLogEditButton()}
          </div>
          <div className="col-xl-3 col-sm-5 col-xs-12 py-2 pl-0">
            {this.renderDateTimePicker({
              value: reformatPickerDateFromDB(dateOfFlight),
              showTimeSelect: false,
              handleDatePickerChange:
                this.handleDatePickerChange('dateOfFlight'),
              dateFormat: getDateFormat(),
              name: 'dateOfFlight',
              label: t('input:dateOfFlight'),
              disabled: previewMode,
            })}
          </div>
          <div className="col-xs-12 py-3">
            <p>{t('logbook:flightSection')}</p>
          </div>
          <div className="row">
            {this.renderSelect(
              'col-lg-3 col-sm-5 col-xs-12',
              'departure',
              'departure location',
              departure,
              this.handleSelectChange,
              previewMode,
              'autoSuggestionDeparture',
              'Departure',
              t('input:departure'),
            )}
            <div className="col-lg-2 col-sm-5 col-xs-12 p-lg-0">
              {this.renderDateTimePicker({
                value: reformatPickerDateTimeFromDB(departureTime),
                showTimeSelect: true,
                handleDatePickerChange:
                  this.handleFlightDatePickerChange('departureTime'),
                dateFormat: getDateTimeFormat(),
                name: 'departureTime',
                label: t('input:departureTime'),
                disabled: previewMode,
              })}
            </div>
            {this.renderSelect(
              'col-lg-3 col-sm-5 col-xs-12',
              'arrival',
              'arrival location',
              arrival,
              this.handleSelectChange,
              previewMode,
              'autoSuggestionArrival',
              'Arrival',
              t('input:arrival'),
            )}
            <div className="col-lg-2 col-sm-5 col-xs-12 p-lg-0">
              {this.renderDateTimePicker({
                value: reformatPickerDateTimeFromDB(arrivalTime),
                showTimeSelect: true,
                handleDatePickerChange:
                  this.handleFlightDatePickerChange('arrivalTime'),
                dateFormat: getDateTimeFormat(),
                name: 'arrivalTime',
                label: t('input:arrivalTime'),
                disabled: previewMode,
              })}
            </div>
            {this.renderTextField({
              divStyle: 'col-lg-2 col-sm-5 col-xs-12 pt-2' + '',
              textStyle: classes.totalTime,
              name: 'totalTime',
              label: t('input:totalTime'),
              value: totalTime,
            })}
          </div>
          <div className="col-xs-12 py-3">
            <p>{t('logbook:aircraftSection')}</p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-5 col-xs-12">
              <MaterialSelect
                id="aircraft"
                name="aircraft"
                label={t('input:aircraft')}
                defaultValue={aircraft}
                options={aircraftList.map((aircraft) => ({
                  value: aircraft.id,
                  label: aircraft.registration,
                }))}
                disabled={previewMode}
                validators={[new validators.IsRequired(t)]}
              />
            </div>
            {this.isSimulatorType()
              ? this.renderAircraftType(t)
              : this.renderAircraftDetails(t)}
          </div>
          <div className="col-xs-12 py-3">
            <p>{t('logbook:pilotSection')}</p>
          </div>
          <div className="row">
            {this.renderPilot()}
            <div className="col-lg-3 col-sm-5 col-xs-12">
              <MaterialSelect
                id="pilotFunction"
                name="pilotFunction"
                label={t('input:pilotFunction')}
                defaultValue={pilotFunction}
                options={pilotFunctions.map((pilotFunction) => ({
                  value: pilotFunction.id,
                  label: pilotFunction.name,
                }))}
                disabled={previewMode}
                validators={[new validators.IsRequired(t)]}
              />
            </div>
          </div>
          <div className="col-xs-12 py-3">
            <p>{t('logbook:additionalPilotSection')}</p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-5 col-xs-12">
              <MaterialSelect
                id="additionalPilot"
                name="additionalPilot"
                label={t('input:additionalPilot')}
                defaultValue={additionalPilot}
                options={additionalPilots.map(
                  ({ id, firstName, lastName }) => ({
                    value: id,
                    label: `${firstName} ${lastName}`,
                  }),
                )}
                disabled={previewMode}
              />
            </div>
            <div className="col-lg-3 col-sm-5 col-xs-12">
              <MaterialSelect
                id="additionalPilotFunction"
                name="additionalPilotFunction"
                label={t('input:pilotFunction')}
                defaultValue={additionalPilotFunction}
                options={additionalPilotFunctions.map((pilotFunction) => ({
                  value: pilotFunction.id,
                  label: pilotFunction.name,
                }))}
                disabled={previewMode || !additionalPilot}
                validators={
                  additionalPilot ? [new validators.IsRequired(t)] : []
                }
              />
            </div>
          </div>
          <div className="col-xs-12 py-3 pl-0">
            <p>{t('logbook:landingSection')}</p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-5 col-xs-12">
              {this.renderMaterialInput({
                style: classes.root,
                name: 'dayLandings',
                label: t('input:dayLandings'),
                type: 'number',
                validators: [
                  new validators.IsRequired(t),
                  new validators.IsNumber(t),
                ],
                defaultValue: dayLandings,
                value: dayLandings,
                disabled: previewMode,
              })}
            </div>
            <div className="col-lg-3 col-sm-5 col-xs-12">
              {this.renderMaterialInput({
                style: classes.root,
                name: 'nightLandings',
                label: t('input:nightsLandings'),
                type: 'number',
                validators: [
                  new validators.IsRequired(t),
                  new validators.IsNumber(t),
                ],
                defaultValue: nightLandings,
                value: nightLandings,
                disabled: previewMode,
              })}
            </div>
          </div>
          <div className="col-xs-12 py-3 pl-0">
            <p>{t('logbook:operationalConditionTimeSection')}</p>
          </div>
          <div className="row">
            <div className="row col-auto">
              <div className="col-auto">
                {this.renderMaterialInput({
                  style: classes.conditionTime,
                  name: 'day',
                  label: t('table:day'),
                  type: 'text',
                  validators: [new validators.IsTimeFormatValid(t)],
                  defaultValue: day,
                  value: day,
                  disabled: previewMode,
                })}
              </div>
              <div className="verticalDivider" />
              <div className="col-auto">
                {this.renderMaterialInput({
                  style: classes.conditionTime,
                  name: 'night',
                  label: t('table:night'),
                  type: 'text',
                  validators: [new validators.IsTimeFormatValid(t)],
                  defaultValue: night,
                  value: night,
                  disabled: previewMode,
                })}
              </div>
            </div>
            <div className="row col-auto">
              <div className="col-auto">
                {this.renderMaterialInput({
                  style: classes.conditionTime,
                  name: 'vfr',
                  label: t('table:vfr'),
                  type: 'text',
                  validators: [new validators.IsTimeFormatValid(t)],
                  defaultValue: vfr,
                  value: vfr,
                  disabled: previewMode,
                })}
              </div>
              <div className="verticalDivider" />
              <div className="col-auto">
                {this.renderMaterialInput({
                  style: classes.conditionTime,
                  name: 'ifr',
                  label: t('table:ifr'),
                  type: 'text',
                  validators: [new validators.IsTimeFormatValid(t)],
                  defaultValue: ifr,
                  value: ifr,
                  disabled: previewMode,
                })}
              </div>
            </div>
          </div>
          <div className="col-xs-12 pt-3">
            <p>{t('logbook:notesSection')}</p>
          </div>
          <div className="col-xs-12 pl-0">
            <TextField
              label={t('input:remarksAndEndorsments')}
              value={remarks}
              margin="dense"
              fullWidth
              multiline
              disabled={previewMode}
              onChange={this.handleChange('remarks')}
            />
          </div>
          <div className="justify-content-center row py-5">
            <div className="col-auto">
              <Link to={'/my_logbook'}>
                <Button variant="raised">{t('buttonCancel')}</Button>
              </Link>
            </div>
            <div className="col-auto">
              {!previewMode && (
                <Button
                  color="primary"
                  variant="raised"
                  onClick={this.sendHandler}
                >
                  {t('buttonSave')}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
