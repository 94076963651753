import {
  FETCH_TESTS_SUCCESS,
  FETCH_TESTS_REQUEST,
  FETCH_TESTS_FAILURE,
  FETCH_TEST_SUCCESS,
  FETCH_TEST_REQUEST,
  FETCH_TEST_FAILURE,
  ADD_TEST,
  EDIT_TEST,
} from '../actions/tests';

const INITIAL_STATE = {
  test: {},
  testList: [],
  testRequest: false,
  testDetailsRequest: false,
};

function fetchTestsSuccess(state, payload) {
  return {
    ...state,
    testList: payload.tests,
    testRequest: false,
  };
}

function fetchTestsRequest(state) {
  return {
    ...state,
    testRequest: true,
  };
}

function fetchTestsFailure(state) {
  return {
    ...state,
    testRequest: false,
  };
}

function fetchTestSuccess(state, payload) {
  return {
    ...state,
    test: payload.test,
    testDetailsRequest: false,
  };
}

function fetchTestRequest(state) {
  return {
    ...state,
    testDetailsRequest: true,
  };
}

function fetchTestFailure(state) {
  return {
    ...state,
    testDetailsRequest: false,
  };
}

function addTest(state, payload) {
  return {
    ...state,
    testList: [
      ...state.testList,
      payload.test
    ]
  };
}

function editTest(state, payload) {
  return {
    ...state,
    testList: state.testList.map((test) => {
      if (test.id === payload.test.id) {
        return payload.test;
      }

      return test;
    })
  };
}

const actions = {
  [FETCH_TESTS_SUCCESS]: fetchTestsSuccess,
  [FETCH_TESTS_REQUEST]: fetchTestsRequest,
  [FETCH_TESTS_FAILURE]: fetchTestsFailure,
  [FETCH_TEST_SUCCESS]: fetchTestSuccess,
  [FETCH_TEST_REQUEST]: fetchTestRequest,
  [FETCH_TEST_FAILURE]: fetchTestFailure,
  [ADD_TEST]: addTest,
  [EDIT_TEST]: editTest,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
