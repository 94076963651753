import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { withNamespaces } from 'react-i18next';
import uuidv4 from 'uuid/v4';

import _get from 'lodash/get';
import _each from 'lodash/each';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import FormHelperText from '@material-ui/core/FormHelperText';

import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import ItemList from './ItemList';
import Loader from '../../../../common/components/Loader';
import SelectCollections from '../../Select/SelectCollections';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import FileInput from '../../../../common/components/upload/FileInput';
import CustomFilter from '../../../../common/components/CustomFilter';

import { fetchItems, addItem, editItem, removeItem } from '../../../actions/items';
import { EDITOR_OPTIONS, ZERO_TIME } from '../../../../constants';
import { fetchItemTypes } from '../../../actions/itemTypes';
import { attachItemFile, removeItemFile } from '../../../actions/fileUpload';
import { addSimpleError } from '../../../actions/errors';
import { getMinutes, reformatTime } from '../../../utils/time';
import validators from '../../../../utils/validators';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import generateDuplicateName from '../../../../utils/generateDuplicateName';

const firstHtmlTagLength = 4;
const lastHtmlTagLength = -4;

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  descriptionIsValid: true,
  id: '',
  name: '',
  description: '',
  estimatedTime: '',
  collection: [],
  itemTypeList: '',
  fileList: [],
  editorState: EditorState.createEmpty(),
  searchSettings: {
    filter: [],
    filterIsUsed: false
  },
  updatedFiles: [],
  deletedFiles: []
};

const styles = () => ({
  wrapperClassName: {
    border: 'solid 1px rgba(0, 0, 0, 0.54)'
  },
  wrapperClassNameError: {
    border: 'solid 1px rgba(244, 67, 54, 0.9)'
  },
  editorClassName: {
    padding: '0 5px',
    maxHeight: '350px',
    overflowY: 'scroll',
  },
  timeInputWidth: {
    width: 180,
  },
  uploadContainer: {
    position: 'relative',
    overflow: 'hidden'
  }
});

const mapStateToProps = (state) => {
  return {
    itemList: state.items.itemList,
    itemRequest: state.items.itemRequest,
    currentItem: state.items.currentItem,
    itemTypeList: state.itemTypes.itemTypeList,
    itemTypeRequest: state.itemTypes.itemTypeRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchItemTypes: () => dispatch(fetchItemTypes()),
    fetchItems: (filterData) => dispatch(fetchItems(filterData)),
    addItem: (item, files) => dispatch(addItem(item, files)),
    editItem: (item, updatedFiles, deletedFiles) => dispatch(editItem(item, updatedFiles, deletedFiles)),
    removeItem: (item) => dispatch(removeItem(item)),
    attachItemFile: (file, itemId) => dispatch(attachItemFile(file, itemId)),
    removeItemFile: (fileId, itemId) => dispatch(removeItemFile(fileId, itemId)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class ItemsManager extends Component {
  static propTypes = {
    itemList: PropTypes.array,
    itemTypeList: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]).isRequired,
    itemRequest: PropTypes.bool.isRequired,
    itemTypeRequest: PropTypes.bool.isRequired,
    fetchItems: PropTypes.func.isRequired,
    fetchItemTypes: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    editItem: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    lessons: PropTypes.array.isRequired,
    attachItemFile: PropTypes.func.isRequired,
    removeItemFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    currentItem: PropTypes.object,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.formData = {};
    this.handleScroll();
    this.props.fetchItems();
    this.props.fetchItemTypes();

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll = () => {
    const scrollTop = window.scrollY;
    const { innerWidth, innerHeight } = window;

    this.setState({
      transform: scrollTop,
      innerWidth,
      innerHeight,
    });
  };

  setItemsHeight = (elem) => {
    if (elem) {
      let location = 0;

      if (elem.offsetParent) {
        do {
          location += elem.offsetTop;
          elem = elem.offsetParent;
        } while (elem);
      }

      this.itemsOffset = location >= 0 ? location : 0;
    }
  };

  handleFilter = (values) => {
    const { fetchItems } = this.props;
    const preparedValues = values.filter((value) => value.id);
    const filterData = {
      filter: preparedValues,
      filterIsUsed: !_isEmpty(preparedValues)
    };

    this.setState({
      searchSettings: filterData,
    });

    fetchItems(filterData);
  };

  getUserFilters = () => {
    const { searchSettings } = this.state;
    const { itemTypeList } = this.props;

    return (
      <CustomFilter
        groups={itemTypeList}
        roles={[]}
        isFilterUsed={searchSettings.filterIsUsed}
        handleFilter={this.handleFilter}
        filterSettings={searchSettings.filter}
      />
    );
  };

  getTransformation = () => {
    const { itemsOffset } = this;
    const { transform } = this.state;
    const additionalMargin = 29;

    if (!(transform && itemsOffset) || window.innerWidth < 992) {
      return 0;
    }

    if (itemsOffset - additionalMargin < transform) {
      return -(itemsOffset - additionalMargin - transform);
    }

    return 0;
  };

  handleAddOpen = async () => {
    const { fetchItems } = this.props;
    const filterData = {
      filter: {},
      filterIsUsed: false
    };

    this.setState({
      addOpen: true,
      searchSettings: filterData
    });

    await fetchItems(filterData);
  };

  handleEditOpen = (item) => {
    const { fetchItems } = this.props;
    const blocksFromHtml = htmlToDraft(item.description);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const filterData = {
      filter: {},
      filterIsUsed: false
    };

    this.setState({
      editOpen: true,
      id: item.id,
      name: item.name,
      description: item.description,
      estimatedTime: item.estimatedTime,
      collection: item.itemTypes.map((itemType) => ({ value: itemType.id, label: itemType.name })),
      editorState: EditorState.createWithContent(contentState),
      itemTypeList: item.itemTypes,
      fileList: _get(item, 'files', []),
      searchSettings: filterData
    });

    fetchItems(filterData);
  };

  handleDuplicate = ({ name, estimatedTime, description, itemTypes, files }) => {
    const names = this.props.itemList.filter((item) => item.isActive).map((item) => item.name);

    const duplicateName = generateDuplicateName(name, names, this.props.t('course:copy'));

    this.props.addItem({ name: duplicateName, estimatedTime, description, itemTypes, files }, files);
  };

  handleCloseDialog = () => {
    const { removeItem, currentItem } = this.props;

    if (currentItem && !_isEmpty(currentItem)) {
      const defaultItem = {
        id: currentItem.id,
      };

      removeItem(defaultItem);
    }

    this.setState(INITIAL_STATE);
  };

  handleClose = () => {
    this.setState(INITIAL_STATE);
  };

  addItem = () => {
    const { addItem } = this.props;
    const { fileList, name, estimatedTime, description, collection } = this.state;

    const newItem = {
      name,
      estimatedTime,
      description,
      itemTypes: _isEmpty(collection) ? [] : collection.map((itemType) => ({ id: itemType.value }))
    };

    addItem(newItem, fileList);
    this.handleClose();
  };

  editItem = () => {
    const { editItem } = this.props;
    const { id, name, estimatedTime, description, collection, updatedFiles, deletedFiles } = this.state;
    const newItem = {
      id,
      name,
      estimatedTime,
      description,
      itemTypes: _isEmpty(collection) ? [] : collection.map((itemType) => ({ id: itemType.value }))
    };

    editItem(newItem, updatedFiles, deletedFiles);
    this.handleClose();
  };

  deactivateItem = (item) => {
    const { editItem } = this.props;
    const newItem = {
      ...item,
      isActive: false,
    };

    editItem(newItem);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSelectChange = (name) => (value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      if (key === 'estimatedTime') {
        this.handleTimeInputChange(key, value);
      } else {
        this.setState({
          [key]: value
        });
      }
    });
  };

  onFormValidated = (isFormValid) => {
    const { editOpen, description } = this.state;
    const { currentItem } = this.props;
    const rawDescription = description.slice(0, lastHtmlTagLength).slice(firstHtmlTagLength);

    this.setState({
      isFormValid,
      descriptionIsValid: !_isEmpty(rawDescription)
    });

    if (isFormValid && this.formData && !_isEmpty(rawDescription)) {
      editOpen ? this.editItem(currentItem) : this.addItem();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  onEditorStateChange = (editorState) => {
    const currentRawContent = convertToRaw(editorState.getCurrentContent());
    const description = draftToHtml(currentRawContent);

    if (_isEmpty(currentRawContent)) {
      this.setState({
        descriptionIsValid: false,
      });
    } else {
      this.setState({
        descriptionIsValid: true,
      });
    }

    this.setState({
      editorState,
      description,
    });
  };

  handleTimeInputChange = (key, value) => {
    if (value.indexOf(':') > -1) {
      if (_isEmpty(value)) {
        this.setState({ [key]: ZERO_TIME });
      } else if (getMinutes(value)) {
        this.setState({ [key]: value });
      }
    }
  };

  dialogContent = (title) => {
    const { classes, itemTypeList, t } = this.props;
    const { name, estimatedTime, editorState, collection, descriptionIsValid } = this.state;

    return (
      <div className="col-12 row justify-content-between">
        <DialogTitle id="add-item">{title}</DialogTitle>
        <DialogContent>
          <div className="col-12 row justify-content-between">
            <div className="col-8">
              <MaterialInput
                fullWidth
                name="name"
                label={t('input:name')}
                autoFocus
                defaultValue={name}
                margin="dense"
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
              />
            </div>
            <div className="col-auto">
              <MaterialInput
                className={classes.timeInputWidth}
                type="text"
                name="estimatedTime"
                label={t('input:estimatedTime')}
                defaultValue={reformatTime(estimatedTime)}
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                validators={[
                  new validators.IsTimeRequired(t),
                  new validators.IsTimeFormatValid(t),
                ]}
              />
            </div>
          </div>
          <div className="col-12 mt-3 mb-1">
            <InputLabel error={!descriptionIsValid} focused shrink>{t('input:description')}</InputLabel>
            <Editor
              editorState={editorState}
              wrapperClassName={descriptionIsValid ? classes.wrapperClassName : classes.wrapperClassNameError}
              editorClassName={classes.editorClassName}
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                options: EDITOR_OPTIONS,
              }}
            />
            {!descriptionIsValid ?
              <FormHelperText error={!descriptionIsValid}>{t('error:isTimeRequired')}</FormHelperText> :
              <div />
            }
          </div>
          <div className="row justify-content-between mx-2">
            <h5 className="col mt-4">{t('course:attachments')}</h5>
            {this.addFile()}
          </div>
          <div>
            {this.renderFiles()}
          </div>
          <div className="mx-4">
            <h5>{t('course:tags')}</h5>
          </div>
          <div className="col-12">
            <SelectCollections
              preview={false}
              collection={collection}
              collectionList={itemTypeList}
              handleSelectChange={this.handleSelectChange}
              placeholder={''}
            />
          </div>
        </DialogContent>
      </div>
    );
  };

  addFile() {
    return (
      <span className="file-inputs">
        <CustomIconButton>
          <FileInput
            attachFile={this.attachItemFile}
            addSimpleError={addSimpleError}
            required
          />
          <Icon color="primary">attach_file</Icon>
        </CustomIconButton>
      </span>
    );
  }

  renderFiles() {
    const { fileList } = this.state;

    if (_isEmpty(fileList)) {
      return <div />;
    }

    return fileList && fileList.map((file) => {
      return (
        <Chip
          key={`item-file-${file.id}`}
          label={file.name}
          onClick={() => window.open(file.path, '_blank')}
          onDelete={() => this.removeEquipmentFile(file)}
          className="my-1 mx-2"
        />
      );
    });
  }

  removeEquipmentFile = (file) => {
    const { updatedFiles, deletedFiles, editOpen, fileList } = this.state;
    const fileId = file.id;

    const preparedFiles = fileList.filter((file) => file.id !== fileId);
    let preparedUpdatedFiles = updatedFiles;
    let preparedDeleteFiles = deletedFiles;
    const isServerFile = _get(file, 'path', false);

    if (editOpen) {
      if (isServerFile) {
        preparedDeleteFiles = [
          ...deletedFiles,
          fileId,
        ];
      } else {
        preparedUpdatedFiles = updatedFiles.filter((file) => file.id !== fileId);
      }
    }

    this.setState({
      fileList: preparedFiles,
      deletedFiles: preparedDeleteFiles,
      updatedFiles: preparedUpdatedFiles
    });
  };


  attachItemFile = (file) => {
    const { updatedFiles, editOpen, fileList } = this.state;

    file.id = uuidv4();

    if (editOpen) {
      this.setState({
        ...this.state,
        fileList: [
          ...fileList,
          file,
        ],
        updatedFiles: [
          ...updatedFiles,
          file,
        ]
      });
    } else {
      this.setState({
        fileList: [
          ...fileList,
          file,
        ],
      });
    }
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  dialogActions = (t) => {
    const { editOpen } = this.state;

    return (
      <DialogActions>
        <Button color="secondary" onClick={editOpen ? this.handleClose : this.handleCloseDialog}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
      </DialogActions>
    );
  };

  render() {
    const { itemList, itemTypeList, itemRequest, lessons, addSimpleError, removeItemFile, onElementDelete, t } = this.props;
    const { addOpen, editOpen, innerHeight, innerWidth } = this.state;
    const style = { transform: `translateY(${this.getTransformation()}px)` };

    if (itemRequest) {
      return <Loader />;
    }

    return (
      <div className="items" id="items" style={style} ref={(elem) => this.setItemsHeight(elem)}>
        <div className="row justify-content-between align-items-center">
          <div className="col-xs-7 pl-3">
            <h4>{t('course:availableContents')}</h4>
          </div>
          <div className="col-xs-5">
            {!_isEmpty(itemTypeList) && this.getUserFilters()}
          </div>
        </div>
        <Divider color="secondary" />
        <ItemList
          itemList={itemList}
          lessons={lessons}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          handleDuplicate={this.handleDuplicate}
          removeItem={(item) => onElementDelete(() => this.deactivateItem(item))}
          attachItemFile={this.attachItemFile}
          removeItemFile={removeItemFile}
          addSimpleError={addSimpleError}
          innerHeight={innerHeight}
          innerWidth={innerWidth}
        />
        <Form
          onChange={this.onChange}
          validateForm={this.validateForm}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen}
            onClose={this.handleCloseDialog}
            aria-labelledby="add-item"
            maxWidth="md"
            fullWidth
          >
            {this.dialogContent(t('course:addContentDialogTitle'))}
            {this.dialogActions(t)}
          </Dialog>

          <Dialog
            open={editOpen}
            onClose={this.handleClose}
            aria-labelledby="edit-item"
            maxWidth="md"
            fullWidth
          >
            {this.dialogContent(t('course:editContentDialogTitle'))}
            {this.dialogActions(t)}
          </Dialog>
        </Form>
      </div>
    );
  }
}
