import '../styles.scss';

import React, { Component } from 'react';
import {
  addMedical,
  addMedicalPlannedAction,
  deactivateMedical,
  editMedical,
  removeMedical,
} from '../../../actions/medicals';
import {
  attachMedicalFile,
  removeMedicalFile,
} from '../../../actions/fileUpload';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import MedicalList from './MedicalList';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  type: '',
  expiryDate: null,
  dateOfIssue: moment(),
  id: null,
  isFormValid: false,
};

const mapStateToProps = (state) => {
  return {
    medicalList: state.medicals.medicalList || [],
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addMedical: (medical) => dispatch(addMedical(medical)),
    addMedicalPlannedAction: (medical) =>
      dispatch(addMedicalPlannedAction(medical)),
    removeMedical: (medical) => dispatch(removeMedical(medical)),
    editMedical: (medical) => dispatch(editMedical(medical)),
    deactivateMedical: (medical) => dispatch(deactivateMedical(medical)),
    attachMedicalFile: (file, id, userId) =>
      dispatch(attachMedicalFile(file, id, userId)),
    removeMedicalFile: (fileId, id) => dispatch(removeMedicalFile(fileId, id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UserMedicals extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    medicalList: PropTypes.array,
    editMode: PropTypes.bool,
    addMedical: PropTypes.func.isRequired,
    addMedicalPlannedAction: PropTypes.func.isRequired,
    removeMedical: PropTypes.func.isRequired,
    editMedical: PropTypes.func.isRequired,
    deactivateMedical: PropTypes.func.isRequired,
    attachMedicalFile: PropTypes.func.isRequired,
    removeMedicalFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    documentTypeList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  onChange = (formData) => {
    this.formData = formData;
    _each(formData, (value, key) => {
      this.setState({
        [key]: value.value,
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.state.addOpen ? this.addMedical() : this.editMedical();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (medical) => {
    this.setState({
      editOpen: true,
      id: medical.id,
      dateOfIssue: medical.dateOfIssue,
      expiryDate: medical.expiryDate,
      type: medical.type,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addMedical = () => {
    const { type, dateOfIssue, expiryDate } = this.state;
    const { userId } = this.props;
    const newMedical = {
      type,
      dateOfIssue: reformatPickerDateToDB(dateOfIssue ? dateOfIssue : moment()),
      userId,
      expiryDate: reformatPickerDateToDB(expiryDate),
    };

    this.props.addMedical(newMedical);
    this.handleDialogClose();
  };

  removeMedical = (medical) => {
    this.props.removeMedical(medical);
  };

  editMedical = () => {
    const { type, id, dateOfIssue, expiryDate } = this.state;
    const { userId } = this.props;
    const newMedical = {
      id,
      type,
      dateOfIssue: reformatPickerDateToDB(dateOfIssue),
      userId,
      expiryDate: reformatPickerDateToDB(expiryDate),
    };

    this.props.editMedical(newMedical);
    this.handleDialogClose();
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  renderSelect = (inputName, inputLabel, value, changeFunction) => {
    const { documentTypeList } = this.props;

    return (
      <AutoSuggestion
        label={inputName}
        inputName={inputLabel}
        defaultValue={value ? value : ''}
        handleChange={changeFunction}
        typeList={documentTypeList}
        fullWidth
      />
    );
  };

  dialogContent = (classes, title, t) => {
    const { type, expiryDate, dateOfIssue } = this.state;

    return (
      <div>
        <DialogTitle id="add-medical-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              {this.renderSelect('type', 'type', type, this.handleSelectChange)}
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                value={reformatPickerDateFromDB(dateOfIssue)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'dateOfIssue',
                )}
                dateFormat={getDateFormat()}
                name="dateOfIssue"
                label={t('input:dateOfIssue')}
                fullWidth
              />
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                acceptEmptyInput
                value={reformatPickerDateFromDB(expiryDate)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'expiryDate',
                )}
                dateFormat={getDateFormat()}
                name="expiryDate"
                label={t('input:expiryDate')}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
      </div>
    );
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  attachMedicalFile = (file) => (id) => {
    const { attachMedicalFile, userId } = this.props;

    attachMedicalFile(file, id, userId);
  };

  render() {
    const {
      medicalList,
      editMode,
      classes,
      removeMedicalFile,
      addSimpleError,
      onElementDelete,
      t,
      addMedicalPlannedAction,
      deactivateMedical,
    } = this.props;
    const { addOpen, editOpen } = this.state;

    return (
      <div className="col-12 user">
        <MedicalList
          medicalList={medicalList}
          addMedicalPlannedAction={addMedicalPlannedAction}
          removeMedical={(medical) =>
            onElementDelete(() => this.removeMedical(medical))
          }
          handleAddOpen={this.handleAddOpen}
          editMode={editMode}
          handleEditOpen={this.handleEditOpen}
          attachMedicalFile={this.attachMedicalFile}
          removeMedicalFile={(fileId, id) =>
            onElementDelete(() => removeMedicalFile(fileId, id))
          }
          addSimpleError={addSimpleError}
          deactivateMedical={deactivateMedical}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={addOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="add-medical-dialog-title"
          >
            {this.dialogContent(classes, t('user:addMedical'), t)}
            {this.dialogActions(t)}
          </Dialog>
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={editOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="edit-medical-dialog-title"
          >
            {this.dialogContent(classes, t('user:editMedical'), t)}
            {this.dialogActions(t)}
          </Dialog>
        </Form>
      </div>
    );
  }
}
