/* eslint-disable promise/no-nesting */

import { URLS } from '../../constants';
import _isEmpty from 'lodash/isEmpty';
import { addError } from './errors';
import axios from 'axios';
import { fileUploadPromise } from '../../utils/fileUpload';
import history from '../store/history';
import { urlJoin } from '../../utils';

export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';
export const FETCH_REPORTS_PAGINATION_REQUEST =
  'FETCH_REPORTS_PAGINATION_REQUEST';
export const FETCH_REPORTS_PAGINATION_SUCCESS =
  'FETCH_REPORTS_PAGINATION_SUCCESS';
export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';
export const SAVE_REPORT = 'SAVE_REPORT';
export const ADD_REPORT = 'ADD_REPORT';
export const EDIT_REPORT = 'EDIT_REPORT';
export const REMOVE_REPORT = 'REMOVE_REPORT';

export function fetchReportsRequest() {
  return {
    type: FETCH_REPORTS_REQUEST,
  };
}

export function fetchReportsSuccess(reports) {
  return {
    type: FETCH_REPORTS_SUCCESS,
    payload: {
      reports,
    },
  };
}

export function fetchReportsPaginationRequest() {
  return {
    type: FETCH_REPORTS_PAGINATION_REQUEST,
  };
}

export function fetchReportsPaginationSuccess(reports) {
  return {
    type: FETCH_REPORTS_PAGINATION_SUCCESS,
    payload: {
      reports,
    },
  };
}

export function fetchReportsFailure() {
  return {
    type: FETCH_REPORTS_FAILURE,
  };
}

export function fetchReportRequest() {
  return {
    type: FETCH_REPORT_REQUEST,
  };
}

export function fetchReportSuccess(report) {
  return {
    type: FETCH_REPORT_SUCCESS,
    payload: {
      report,
    },
  };
}

export function fetchReportFailure() {
  return {
    type: FETCH_REPORT_FAILURE,
  };
}

export function saveReportRequest() {
  return {
    type: SAVE_REPORT,
  };
}

export function addReportSuccess(report) {
  return {
    type: ADD_REPORT,
    payload: {
      report,
    },
  };
}

export function editReportSuccess(report) {
  return {
    type: EDIT_REPORT,
    payload: {
      report,
    },
  };
}

export function removeReportSuccess(report) {
  return {
    type: REMOVE_REPORT,
    payload: {
      report,
    },
  };
}

export function fetchReports(sortingData, fleetAnalysis, paginationParams) {
  return (dispatch) => {
    const params = [];

    if (paginationParams) {
      params.push(paginationParams);
      dispatch(fetchReportsPaginationRequest());
    } else {
      dispatch(fetchReportsRequest());
    }

    if (sortingData) {
      if (!_isEmpty(sortingData.search)) {
        params.push(`search=${sortingData.search}`);
      }

      if (sortingData.unread) {
        params.push('unread=');
      }

      if (sortingData.userId) {
        params.push(`user__id=${sortingData.userId}`);
      }

      if (sortingData.fleetId) {
        params.push(`fleet__id=${sortingData.fleetId}`);
      }

      if (sortingData.fromDate) {
        params.push(`from_date=${sortingData.fromDate}`);
      }

      if (sortingData.toDate) {
        params.push(`to_date=${sortingData.toDate}`);
      }

      params.push(`ordering=${sortingData.sortAsc ? '' : '-'}subject`);
    }

    if (fleetAnalysis) {
      params.push('fleetAnalysis');
    }

    return axios
      .get(
        urlJoin(URLS.reports, _isEmpty(params) ? '' : `?${params.join('&')}`),
      )
      .then(({ data }) =>
        dispatch(
          paginationParams
            ? fetchReportsPaginationSuccess(data)
            : fetchReportsSuccess(data),
        ),
      )
      .catch((error) => {
        dispatch(fetchReportsFailure());
        dispatch(
          addError(`Error during reports fetching ${error.message}`, error),
        );
      });
  };
}

export function addReport(report, files, userId) {
  return (dispatch) => {
    dispatch(saveReportRequest());

    return axios
      .post(URLS.reportsCreate, report)
      .catch((error) => {
        return Promise.reject(error);
      })
      .then(({ data }) => {
        if (!_isEmpty(files)) {
          const filesPromises = files.map((file) => {
            return fileUploadPromise(
              file,
              'PATCH',
              urlJoin(URLS.attachReportFile, data.id, userId),
              () => {},
            );
          });

          data.files = [];

          return Promise.all(filesPromises).then(() => {
            return data;
          });
        }

        return data;
      })
      .then((data) => {
        dispatch(addReportSuccess(data));
        history.push(`/report/${data.id}`);
      })
      .catch((error) => {
        dispatch(
          addError(`Error during report adding ${error.message}`, error),
        );
      });
  };
}

export function editReport(report, myReports = false) {
  return (dispatch) => {
    dispatch(saveReportRequest());

    return axios
      .patch(urlJoin(URLS.reports, report.id), report)
      .then(({ data }) => {
        dispatch(editReportSuccess(data));

        history.push(myReports ? '/my_reports' : '/report');
      })
      .catch((error) => {
        dispatch(
          addError(`Error during report editing ${error.message}`, error),
        );
      });
  };
}

export function fetchReport(reportId, userId) {
  return (dispatch) => {
    dispatch(fetchReportRequest());

    return axios
      .get(`${urlJoin(URLS.reports, reportId)}?userId=${userId}`)
      .then(({ data }) => {
        dispatch(fetchReportSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchReportFailure());
        dispatch(
          addError(`Error during report fetching ${error.message}`, error),
        );
      });
  };
}

export function removeReport(report) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.reports, report.id))
      .then(() => dispatch(removeReportSuccess(report)))
      .catch((error) => {
        dispatch(
          addError(`Error during report removing ${error.message}`, error),
        );
      });
  };
}
