import React, { Component } from 'react';
import {
  getDateFormat,
  reformatPickerDateFromDB,
} from '../../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../../common/components/forms/DateTimePicker';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Form from '../../../../../common/components/forms/Form';
import MaterialInput from '../../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import validators from '../../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const categoriesNumber = 9;
const languageElements = 4;
const styles = () => ({
  textFieldSM: {
    width: 120,
    marginRight: '1rem',
  },
});

@withNamespaces()
@withStyles(styles)
export default class Page3 extends Component {
  static propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    isFirstPage: PropTypes.func.isRequired,
    isLastPage: PropTypes.func.isRequired,
    saveComplementaryCourse: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = [...Array(languageElements).keys()].reduce((result, key) => {
    result[`languageDate_${key}`] = reformatPickerDateFromDB(
      this.props.defaultSettings[`languageDate_${key}`],
    );

    return result;
  }, {});

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  onFormValidated = (isFormValid) => {
    const { changePage } = this.props;
    const formData = Object.keys(this.formData).reduce((result, keyName) => {
      result[keyName] = this.formData[keyName].value;

      return result;
    }, {});
    const state = this.state || {};

    if (isFormValid && this.formData) {
      changePage(this.page, { ...formData, ...state });
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e, page) => {
    e.preventDefault();
    e.stopPropagation();

    this.page = page;
    this.triggerFormValidation();
  };

  renderCategories = () => {
    const { t, classes, defaultSettings } = this.props;

    return [...Array(categoriesNumber).keys()].map((index) => {
      const count = index + 1;
      const field = `category_${count}`;

      return (
        <MaterialInput
          key={field}
          className={classes.textFieldSM}
          margin="dense"
          name={field}
          label={t('input:category')}
          validators={[new validators.MaxLength(t, 3)]}
          defaultValue={defaultSettings[field]}
        />
      );
    });
  };

  renderLanguages = () => {
    const { t, defaultSettings } = this.props;

    return [...Array(languageElements).keys()].map((index) => {
      const count = index + 1;
      const field1 = `languageDate_${count}`;
      const field2 = `language_${count}`;
      const field3 = `languageNotes_${count}`;

      return (
        <div
          key={`${field2}-field-list`}
          className="row align-items-center justify-content-between"
        >
          <div className="col">
            <DateTimePicker
              value={this.state[field1]}
              showMonthDropdown
              showTimeSelect={false}
              showYearDropdown
              handleDatePickerChange={this.handleDatePickerChange(field1)}
              dateFormat={getDateFormat()}
              name={field1}
              label={t('input:languageDate')}
              acceptEmptyInput
              fullWidth
            />
          </div>
          <div className="col">
            <MaterialInput
              margin="dense"
              name={field2}
              label={t('input:language')}
              validators={[new validators.MaxLength(t, 15)]}
              defaultValue={defaultSettings[field2]}
              fullWidth
            />
          </div>
          <div className="col">
            <MaterialInput
              margin="dense"
              name={field3}
              label={t('input:languageNotes')}
              validators={[new validators.MaxLength(t, 35)]}
              defaultValue={defaultSettings[field3]}
              fullWidth
            />
          </div>
        </div>
      );
    });
  };

  render() {
    const {
      t,
      defaultSettings,
      handleDialogClose,
      isFirstPage,
      isLastPage,
      saveComplementaryCourse,
    } = this.props;

    return (
      <Form
        onChange={this.onChange}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <h4 className="text-center">
                {t('complementaryCertificate:categoriesofDrivingSection')}
              </h4>
              <h4 className="text-center">
                {t('complementaryCertificate:categoriesofDrivingSection2')}
              </h4>
            </div>
            <div className="col-12">{this.renderCategories()}</div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="notes"
                label={t('input:notes')}
                validators={[new validators.MaxLength(t, 150)]}
                defaultValue={defaultSettings.notes}
                fullWidth
              />
            </div>
            <div className="col-12">
              <h4 className="text-center">
                {t('complementaryCertificate:additionalInformationSection')}
              </h4>
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="additionalInfo"
                label={t('input:additionalInformation')}
                validators={[new validators.MaxLength(t, 350)]}
                defaultValue={defaultSettings.additionalInfo}
                fullWidth
              />
            </div>
            <div className="col-12">
              <h4 className="text-center">
                {t('complementaryCertificate:languageSkillsSection')}
              </h4>
            </div>
            <div className="col-12">{this.renderLanguages()}</div>
            <div className="col-12">
              <h4 className="text-center">
                {t('complementaryCertificate:restrictionsSection')}
              </h4>
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="restrictions"
                label={t('input:restrictions')}
                validators={[new validators.MaxLength(t, 350)]}
                defaultValue={defaultSettings.restrictions}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {!isFirstPage() ? (
            <Button onClick={(e) => this.sendHandler(e, -1)}>
              {t('buttonPrevious')}
            </Button>
          ) : (
            <div />
          )}
          {!isLastPage() ? (
            <Button onClick={(e) => this.sendHandler(e, 1)}>
              {t('buttonNext')}
            </Button>
          ) : (
            <div />
          )}
          <Button color="secondary" onClick={handleDialogClose}>
            {t('buttonCancel')}
          </Button>
          {isLastPage() ? (
            <Button color="primary" onClick={saveComplementaryCourse}>
              {t('buttonSave')}
            </Button>
          ) : (
            <div />
          )}
        </DialogActions>
      </Form>
    );
  }
}
