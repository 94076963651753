import React, { Component } from 'react';
import { addCourse, editCourse } from '../../../actions/courses';
import {
  componentPermission,
  hasPermission,
  renderPermission,
} from '../../../decorators/permissions';

import BasicCourseInfo from './BasicCourseInfo';
import Button from '@material-ui/core/Button';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ItemsManager from './ItemsManager';
import LessonsManager from './LessonsManager';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import { PERMISSIONS } from '../../../../constants/permissions';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import _omit from 'lodash/omit';
import _sortBy from 'lodash/sortBy';
import { addSimpleError } from '../../../actions/errors';
import { calculateLessonsTime } from '../../../utils/time';
import { connect } from 'react-redux';
import { editAssignedCourse } from '../../../actions/assignedCourses';
import { fetchGradings } from '../../../actions/gradings';
import { fetchLessonTypes } from '../../../actions/lessonTypes';
import { swapItems } from '../../../../utils';
import uuidv4 from 'uuid/v4';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    lessonTypeList: state.lessonTypes.lessonTypeList,
    lessonTypeRequest: state.lessonTypes.lessonTypeRequest,
    gradingList: state.gradings.gradingList,
    settings: state.settings.settings,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchLessonTypes: () => dispatch(fetchLessonTypes()),
    fetchGradings: () => dispatch(fetchGradings()),
    addCourse: (course) => dispatch(addCourse(course)),
    editCourse: (course) => dispatch(editCourse(course)),
    editAssignedCourse: (assignedCourseId, courseData, goBack) =>
      dispatch(editAssignedCourse(assignedCourseId, courseData, goBack)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@DragDropContext(HTML5Backend)
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.courseCreate)
export default class CourseNew extends Component {
  static propTypes = {
    lessonTypeList: PropTypes.array.isRequired,
    lessonTypeRequest: PropTypes.bool.isRequired,
    fetchLessonTypes: PropTypes.func.isRequired,
    fetchGradings: PropTypes.func.isRequired,
    gradingList: PropTypes.array.isRequired,
    addCourse: PropTypes.func.isRequired,
    editCourse: PropTypes.func.isRequired,
    editAssignedCourse: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    course: PropTypes.object,
    assignedCourseId: PropTypes.string,
    preview: PropTypes.bool,
    editMode: PropTypes.bool,
    t: PropTypes.func.isRequired,
    studentFullName: PropTypes.string,
    settings: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = {
      basicInfo: {
        id: _get(this.props.course, 'id', ''),
        name: _get(this.props.course, 'name', ''),
        minTime: _get(this.props.course, 'minTime', 0),
        maxTime: _get(this.props.course, 'maxTime', 0),
        estimatedTime: _get(this.props.course, 'estimatedTime', 0),
        description: _get(this.props.course, 'description', ''),
      },
      showRemoveDialog: false,
      removeCallback: () => {},
      lessons:
        this.props.course && this.props.course.lessons
          ? _sortBy(
              this.props.course.lessons.map((lesson) => {
                const lessonUniqueId = uuidv4();

                return {
                  ...lesson,
                  uniqueId: lessonUniqueId,
                  lessonType: String(lesson.lessonType.id),
                  items: _sortBy(
                    lesson.lessonsItems.map(({ item, order }) => {
                      return {
                        ...item,
                        order,
                        uniqueId: uuidv4(),
                        parent: lessonUniqueId,
                      };
                    }),
                    ['order'],
                  ),
                };
              }),
              ['order'],
            )
          : [],
    };
  }

  componentDidMount() {
    const { fetchLessonTypes, fetchGradings } = this.props;

    fetchLessonTypes();
    fetchGradings();
  }

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  createGrading = () => {
    const { gradingList, settings } = this.props;

    const grade = _find(settings, { name: 'grade' });
    const gradeId = grade ? grade.value : 0;

    return {
      gradeId,
      expectedGradeValue:
        gradingList.find(({ id }) => id === gradeId).options[0] || 0,
    };
  };

  addLesson = () => {
    const newLesson = {
      name: '',
      lessonType: '1',
      items: [],
      uniqueId: uuidv4(),
      grades: [this.createGrading()],
    };

    this.setState({
      lessons: [...this.state.lessons, newLesson],
    });
  };

  changeLesson = (editedLesson) => (event) => {
    const { gradingList } = this.props;

    this.setState({
      lessons: this.state.lessons.map((lesson) => {
        if (lesson.uniqueId === editedLesson.uniqueId) {
          const { name, gradeIndex } = editedLesson;

          if (editedLesson.name === 'addGrading') {
            return {
              ...lesson,
              grades: [...lesson.grades, this.createGrading()],
            };
          } else if (!isNaN(gradeIndex)) {
            const grades = [...lesson.grades];

            grades[gradeIndex] = {
              ...grades[gradeIndex],
              [name]: event.target.value,
            };

            if (name === 'gradeId') {
              const { gradeId } = grades[gradeIndex];

              grades[gradeIndex].expectedGradeValue =
                gradingList.find(({ id }) => id === gradeId).options[0] || 0;
            }

            return {
              ...lesson,
              grades,
            };
          }

          return {
            ...lesson,
            [name]: event.target.value,
          };
        }

        return lesson;
      }),
    });
  };

  removeLesson = (deleteLesson) => {
    const lessonId = deleteLesson.uniqueId;
    const componentName = `LessonsManager-${lessonId}`;

    this.triggers = _omit(this.triggers, componentName);

    this.setState({
      lessons: this.state.lessons.filter((lesson) => lesson !== deleteLesson),
    });
  };

  dropMoveItem = (editedLesson, item) => {
    let removeItem = false;

    this.setState({
      lessons: this.state.lessons.map((lesson) => {
        if (
          lesson.uniqueId === editedLesson.uniqueId &&
          lesson.uniqueId !== item.parent
        ) {
          let uuid = uuidv4();

          if (!_isUndefined(item.parent)) {
            uuid = item.uniqueId;
            removeItem = true;
          }

          return {
            ...lesson,
            items: [
              ...lesson.items,
              {
                ...item,
                uniqueId: uuid,
                parent: lesson.uniqueId,
              },
            ],
          };
        }

        return lesson;
      }),
    });

    if (removeItem) {
      this.dropRemoveItem(item);
    }
  };

  dropRemoveItem = (item) => {
    this.setState({
      lessons: this.state.lessons.map((lesson) => {
        if (lesson.uniqueId === item.parent) {
          return {
            ...lesson,
            items: lesson.items.filter((lessonItem) => {
              return lessonItem.uniqueId !== item.uniqueId;
            }),
          };
        }

        return lesson;
      }),
    });
  };

  moveItemSortable = (dragIndex, hoverIndex, lessonId) => {
    const { lessons } = this.state;
    const newLessons = lessons.map((lesson) => {
      if (lesson.uniqueId !== lessonId) {
        return lesson;
      }

      const { items } = lesson;

      swapItems(items, dragIndex, hoverIndex);

      return {
        ...lesson,
        items: [...items],
      };
    });

    this.setState({
      lessons: newLessons,
    });
  };

  moveLesson = (dragIndex, hoverIndex) => {
    const { lessons } = this.state;

    swapItems(lessons, dragIndex, hoverIndex);

    this.setState({
      lessons: [...lessons],
    });
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  parseLessonType(lesson) {
    const { lessonTypeList } = this.props;

    return lessonTypeList.find(
      (type) => String(type.id) === String(lesson.lessonType),
    );
  }

  saveCourse = async () => {
    const {
      editMode,
      assignedCourseId,
      editAssignedCourse,
      editCourse,
      addCourse,
      t,
    } = this.props;
    const { lessons } = this.state;
    const triggerKeys = Object.keys(this.triggers);

    for (let i = 0; i < triggerKeys.length; i++) {
      await this.triggers[triggerKeys[i]]();
    }

    const formDataIsValidObjects = Object.keys(this.formData).filter((key) => {
      return key.indexOf('isValid') > -1;
    });

    const isFormValid = formDataIsValidObjects.every(
      (key) => this.formData[key],
    );

    if (isFormValid) {
      const { name, minTime, maxTime, description } =
        this.formData.BasicCourseInfoChanges;

      const newCourse = {
        name,
        minTime,
        maxTime,
        description,
        estimatedTime: calculateLessonsTime(lessons),
        lessons: lessons.map((lesson, lessonIndex) => {
          const newLesson = {
            ...lesson,
            name: lesson.name,
            order: lessonIndex,
            lessonType: this.parseLessonType(lesson),
            lessonsItems: lesson.items.map((item, index) => {
              const newItem = {
                order: index,
                item: {
                  id: item.id,
                  name: item.name,
                  description: item.description,
                  estimatedTime: item.estimatedTime,
                  itemTypes: item.itemTypes,
                  isActive: item.isActive,
                  isCompleted: item.isCompleted,
                  files: item.files,
                },
              };

              if (assignedCourseId) {
                newItem.id = index;
              }

              return newItem;
            }),
          };

          if (editMode) {
            newLesson.id = lesson.id;
          }

          if (assignedCourseId) {
            newLesson.id = lesson.id || lesson.uniqueId;
          }

          return newLesson;
        }),
      };

      if (editMode) {
        newCourse.id = this.state.basicInfo.id;

        if (assignedCourseId) {
          editAssignedCourse(
            assignedCourseId,
            { courseData: newCourse },
            `/course_list/details/${assignedCourseId}`,
          );

          return;
        }

        editCourse(newCourse);

        return;
      }

      addCourse(newCourse);
    } else {
      this.props.addSimpleError(t('error:checkIsFilledIn'));
    }
  };

  renderItemManager = (lessons, preview) => {
    if (preview) {
      return null;
    }

    return (
      <div className="col-lg-6 col-xs-12">
        <ItemsManager
          lessons={lessons}
          onElementDelete={this.openRemoveDialog}
        />
      </div>
    );
  };

  renderButtons = (preview, t) => {
    const { assignedCourseId } = this.props;

    if (preview) {
      return (
        <div className="row justify-content-center">
          <div className="col-auto">
            <Link to="/course_admin">
              <Button variant="raised">{t('buttonBack')}</Button>
            </Link>
          </div>
        </div>
      );
    }

    if (assignedCourseId) {
      return (
        <div className="row justify-content-center">
          <div className="col-auto">
            <Link to={`/course_list/details/${assignedCourseId}`}>
              <Button variant="raised">{t('buttonCancel')}</Button>
            </Link>
          </div>
          <div className="col-auto">
            <Button color="primary" variant="raised" onClick={this.saveCourse}>
              {t('buttonSave')}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to="/course_admin">
            <Button variant="raised">{t('buttonCancel')}</Button>
          </Link>
        </div>
        <div className="col-auto">
          <Button color="primary" variant="raised" onClick={this.saveCourse}>
            {t('buttonSave')}
          </Button>
        </div>
      </div>
    );
  };

  @renderPermission(PERMISSIONS.editCourse)
  renderCourseEditButton(preview) {
    const { course } = this.props;

    if (!preview) {
      return null;
    }

    return (
      <div className="col-auto">
        <Link to={`/course_admin/edit/${course.id}`}>
          <IconButton>
            <Icon>mode_edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  renderViewLabel = (t) => {
    const { editMode = false, preview = false } = this.props;

    if (editMode) {
      return t('course:editCourseSection');
    } else if (preview) {
      return t('course:previewCourseSection');
    }

    return t('course:addCourseSection');
  };

  registerComponentForm = (componentName) => {
    return (triggerFn) => {
      this.triggers[componentName] = triggerFn;
    };
  };

  formValidated = (formName, isFormValid, formChanges) => {
    this.formData = {
      ...this.formData,
      [`${formName}Changes`]: formChanges,
      [`${formName}isValid`]: isFormValid,
    };
  };

  render() {
    const {
      preview,
      editMode,
      lessonTypeRequest,
      lessonTypeList,
      t,
      studentFullName,
      gradingList,
    } = this.props;
    const { basicInfo, lessons, showRemoveDialog, removeCallback } = this.state;

    if (!hasPermission(PERMISSIONS.addNewCourse)) {
      if (!preview && !editMode) {
        return null;
      }
    }

    if (lessonTypeRequest) {
      return <Loader />;
    }

    return (
      <div className="new-course row justify-content-lg-between justify-content-end align-items-center">
        <div className="col section-title pb-3">
          <header>
            <span>
              <Icon color="primary">content_paste</Icon>
              <h1>
                {t('course:name')}
                <span className="h1-subheader">
                  {' '}
                  /{this.renderViewLabel(t)}
                </span>
              </h1>
            </span>
          </header>
        </div>
        {this.renderCourseEditButton(preview)}
        <div className="col-12">
          <BasicCourseInfo
            basicInfo={basicInfo}
            registerComponentForm={this.registerComponentForm(
              'BasicCourseInfo',
            )}
            formValidated={this.formValidated}
            preview={preview}
            estimatedTime={calculateLessonsTime(lessons)}
            studentFullName={studentFullName}
          />
        </div>
        <div className="col-12">
          <div className="row">
            <div className={`col-lg-${preview ? '12' : '6'} col-xs-12`}>
              <LessonsManager
                lessons={lessons}
                lessonTypeList={lessonTypeList}
                addLesson={this.addLesson}
                changeLesson={this.changeLesson}
                moveLesson={this.moveLesson}
                removeLesson={(lesson) =>
                  this.openRemoveDialog(() => this.removeLesson(lesson))
                }
                dropRemoveItem={this.dropRemoveItem}
                dropMoveItem={this.dropMoveItem}
                moveItemSortable={this.moveItemSortable}
                handleRegisterComponentForm={this.registerComponentForm}
                handleFormValidated={this.formValidated}
                preview={preview}
                gradingList={gradingList}
              />
            </div>
            {this.renderItemManager(lessons, preview)}
          </div>
        </div>
        <div className="col-12 my-3">{this.renderButtons(preview, t)}</div>
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
      </div>
    );
  }
}
