export const TABLE_HEADER = [
  // {
  //   prop: 'color',
  //   sort: false,
  //   profileView: false
  // },
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'address',
    sort: true,
    profileView: false
  },
  {
    prop: 'location',
    sort: true,
    profileView: false
  },
  {
    prop: 'contactDetails',
    sort: true,
    profileView: false
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true,
    iconWidth: true
  }
];
