import {
  ADD_GRADING,
  EDIT_GRADING,
  FETCH_GRADINGS_FAILURE,
  FETCH_GRADINGS_REQUEST,
  FETCH_GRADINGS_SUCCESS,
  REMOVE_GRADING,
} from '../actions/gradings';

const INITIAL_STATE = {
  gradingList: [],
  gradingRequest: false,
};

function fetchGradings(state, payload) {
  return {
    ...state,
    gradingList: payload.gradings,
    gradingRequest: false,
  };
}

function fetchGradingsRequest(state) {
  return {
    ...state,
    gradingRequest: true,
  };
}

function fetchGradingsFailure(state) {
  return {
    ...state,
    gradingRequest: false,
  };
}

function addGrading(state, payload) {
  return {
    ...state,
    gradingList: [...state.gradingList, payload.grading],
  };
}

function editGrading(state, payload) {
  return {
    ...state,
    gradingList: state.gradingList.map((grading) => {
      if (grading.id === payload.grading.id) {
        return payload.grading;
      }

      return grading;
    }),
  };
}

function removeGrading(state, payload) {
  return {
    ...state,
    gradingList: state.gradingList.filter(
      (grading) => grading !== payload.grading,
    ),
  };
}

const actions = {
  [FETCH_GRADINGS_SUCCESS]: fetchGradings,
  [FETCH_GRADINGS_REQUEST]: fetchGradingsRequest,
  [FETCH_GRADINGS_FAILURE]: fetchGradingsFailure,
  [ADD_GRADING]: addGrading,
  [EDIT_GRADING]: editGrading,
  [REMOVE_GRADING]: removeGrading,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
