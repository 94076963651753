export const TABLE_HEADER = [
  {
    name: 'date',
    prop: 'dateOfFlight',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'crewOne',
    sort: false,
    logbook: true,
    profileView: false,
  },
  {
    prop: 'crewTwo',
    sort: false,
    logbook: true,
    profileView: false,
  },
  {
    prop: 'totalTime',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'departure',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'departureDate',
    sort: true,
    profileView: false,
    logbook: true,
    longLogbook: true,
    boldText: true,
  },
  {
    prop: 'arrival',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'arrivalDate',
    sort: true,
    profileView: false,
    logbook: true,
    longLogbook: true,
    boldText: true,
  },
  {
    prop: 'aircraft',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'registration',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'spSe',
    sort: false,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'spMe',
    sort: false,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'multiPilot',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'coPilotName',
    sort: false,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'dayLandings',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'nightLandings',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'day',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'night',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'vfr',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'ifr',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'pic',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'sic',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'dual',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'instructor',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'fstdType',
    sort: false,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'fstdTime',
    sort: false,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true,
    iconWidth: true,
  },
];
