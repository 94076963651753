import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _each from 'lodash/each';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Form from '../../../../common/components/forms/Form';
import Loader from '../../../../common/components/Loader';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import validators from '../../../../utils/validators';
import DefaultNotificationRecipientList from './DefaultNotificationRecipientList';

import {
  editDefaultNotificationRecipient, addDefaultNotificationRecipient,
  removeDefaultNotificationRecipient, fetchDefaultNotificationRecipients
} from '../../../actions/defaultNotificationRecipients';

const styles = () => ({
  textField: {
    width: 150,
    marginRight: 30,
  },
  categoryLabelStyle: {
    fontSize: '0.75em'
  }
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  defaultNotificationRecipientFirstName: '',
  defaultNotificationRecipientLastName: '',
  defaultNotificationRecipientEmail: '',
  defaultNotificationRecipientId: null
};

const mapStateToProps = (state) => {
  return {
    defaultNotificationRecipientList: state.defaultNotificationRecipients.defaultNotificationRecipientList,
    defaultNotificationRecipientRequest: state.defaultNotificationRecipients.defaultNotificationRecipientRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchDefaultNotificationRecipients: () => dispatch(fetchDefaultNotificationRecipients()),
    addDefaultNotificationRecipient: (name) => dispatch(addDefaultNotificationRecipient(name)),
    removeDefaultNotificationRecipient: (name) => dispatch(removeDefaultNotificationRecipient(name)),
    editDefaultNotificationRecipient: (name) => dispatch(editDefaultNotificationRecipient(name)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class DefaultNotificationRecipientsManager extends Component {
  static propTypes = {
    defaultNotificationRecipientList: PropTypes.array,
    defaultNotificationCategoryList: PropTypes.array,
    defaultNotificationRecipientRequest: PropTypes.bool.isRequired,
    defaultNotificationCategoryRequest: PropTypes.bool.isRequired,
    fetchDefaultNotificationRecipients: PropTypes.func.isRequired,
    fetchDefaultNotificationCategories: PropTypes.func.isRequired,
    addDefaultNotificationRecipient: PropTypes.func.isRequired,
    removeDefaultNotificationRecipient: PropTypes.func.isRequired,
    editDefaultNotificationRecipient: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.props.fetchDefaultNotificationRecipients();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleEditOpen = (defaultNotificationRecipient) => {
    this.setState({
      editOpen: true,
      defaultNotificationRecipientId: defaultNotificationRecipient.id,
      defaultNotificationRecipientFirstName: defaultNotificationRecipient.firstName,
      defaultNotificationRecipientLastName: defaultNotificationRecipient.lastName,
      defaultNotificationRecipientEmail: defaultNotificationRecipient.email,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addDefaultNotificationRecipient = () => {
    const dataToSave = {
      firstName: this.state.defaultNotificationRecipientFirstName,
      lastName: this.state.defaultNotificationRecipientLastName,
      email: this.state.defaultNotificationRecipientEmail
    };

    this.props.addDefaultNotificationRecipient(dataToSave);
    this.handleDialogClose();
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid && this.formData) {
      this.state.addOpen ? this.addDefaultNotificationRecipient() : this.editDefaultNotificationRecipient();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  editDefaultNotificationRecipient = () => {
    const dataToUpdate = {
      id: this.state.defaultNotificationRecipientId,
      firstName: this.state.defaultNotificationRecipientFirstName,
      lastName: this.state.defaultNotificationRecipientLastName,
      email: this.state.defaultNotificationRecipientEmail
    };


    this.props.editDefaultNotificationRecipient(dataToUpdate);
    this.handleDialogClose();
  };

  removeDefaultNotificationRecipient = (defaultNotificationRecipient) => {
    this.props.removeDefaultNotificationRecipient(defaultNotificationRecipient);
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  render() {
    const {
      defaultNotificationRecipientList, defaultNotificationRecipientRequest,
      defaultNotificationCategoryRequest, onElementDelete, classes, t
    } = this.props;
    const {
      defaultNotificationRecipientFirstName, defaultNotificationRecipientLastName,
      defaultNotificationRecipientEmail, addOpen, editOpen
    } = this.state;

    if (defaultNotificationRecipientRequest || defaultNotificationCategoryRequest) {
      return <Loader />;
    }

    return (
      <div className="default-notification-recipients-settings">
        <div className="pl-sm-5">
          <h4> {t('settings:defaultNotificationRecipients')}</h4>
        </div>
        <DefaultNotificationRecipientList
          defaultNotificationRecipientList={defaultNotificationRecipientList}
          removeDefaultNotificationRecipient={this.removeDefaultNotificationRecipient}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen || editOpen}
            onClose={this.handleDialogClose}
            aria-labelledby="add-default-notification-recipient-dialog"
            fullWidth
          >
            <DialogTitle id="add-default-notification-recipient-dialog">
              {addOpen ? t('settings:addDefaultNotificationRecipient') : t('settings:editDefaultNotificationRecipient')}
            </DialogTitle>
            <DialogContent>
              <MaterialInput
                className={classes.textField}
                margin="dense"
                name="defaultNotificationRecipientFirstName"
                label={t('table:firstName')}
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
                defaultValue={defaultNotificationRecipientFirstName}
                autoFocus
              />
              <MaterialInput
                className={classes.textField}
                margin="dense"
                name="defaultNotificationRecipientLastName"
                label={t('table:lastName')}
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
                defaultValue={defaultNotificationRecipientLastName}
              />
              <MaterialInput
                className={classes.textField}
                margin="dense"
                name="defaultNotificationRecipientEmail"
                label={t('table:email')}
                validators={[
                  new validators.IsRequired(t),
                  new validators.Email(t)
                ]}
                defaultValue={defaultNotificationRecipientEmail}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
