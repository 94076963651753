import axios from 'axios';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import { addError } from './errors';
import { URLS_RAIL as URLS } from '../../constants';
import { urlJoin } from '../../utils';
import history from '../store/history';

export const ADD_JOURNEY_LOG = 'ADD_JOURNEY_LOG';
export const FETCH_JOURNEY_LOGS_REQUEST = 'FETCH_JOURNEY_LOGS_REQUEST';
export const FETCH_JOURNEY_LOGS_SUCCESS = 'FETCH_JOURNEY_LOGS_SUCCESS';
export const FETCH_JOURNEY_LOG_SUCCESS = 'FETCH_JOURNEY_LOG_SUCCESS';
export const FETCH_JOURNEY_LOGS_FAILURE = 'FETCH_JOURNEY_LOGS_FAILURE';
export const REMOVE_JOURNEY_LOG_SUCCESS = 'REMOVE_JOURNEY_LOG_SUCCESS';
export const RESET_ADD_JOURNEY_LOG_SUCCESS = 'RESET_ADD_JOURNEY_LOG_SUCCESS';
export const CLEAN_JOURNEY_LOG_DATA = 'CLEAN_JOURNEY_LOG_DATA';
export const EDIT_JOURNEY_LOG = 'EDIT_JOURNEY_LOG';

const sortingPossibitilies = {
  totalTime: 'total_time',
  dateOfFlight: 'date_of_journey',
  departure: 'departure',
  departureDate: 'departure_date',
  arrival: 'arrival',
  arrivalDate: 'arrival_date',
  type: 'type__name',
  firstName: 'user__first_name',
  lastName: 'user__last_name'
};


export function fetchJourneyLogsRequest() {
  return {
    type: FETCH_JOURNEY_LOGS_REQUEST
  };
}

export function fetchJourneyLogsSuccess(journeyLogs) {
  return {
    type: FETCH_JOURNEY_LOGS_SUCCESS,
    payload: {
      journeyLogs
    }
  };
}

export function fetchJourneyLogSuccess(journeyLog) {
  return {
    type: FETCH_JOURNEY_LOG_SUCCESS,
    payload: {
      journeyLog
    }
  };
}

export function fetchJourneyLogsFailure() {
  return {
    type: FETCH_JOURNEY_LOGS_FAILURE
  };
}

export function removeJourneyLogSuccess(journeyLogId) {
  return {
    type: REMOVE_JOURNEY_LOG_SUCCESS,
    payload: {
      journeyLogId
    }
  };
}

export function addJourneyLogSuccess(journeyLog) {
  return {
    type: ADD_JOURNEY_LOG,
    payload: {
      journeyLog
    }
  };
}

export function cleanJourneyLog() {
  return {
    type: CLEAN_JOURNEY_LOG_DATA,
  };
}

export function editJourneyLogSuccess(journeyLog) {
  return {
    type: EDIT_JOURNEY_LOG,
    payload: {
      journeyLog
    }
  };
}

export function getQueryParametrFilter(queryParametersFilter) {
  if (_isEmpty(queryParametersFilter)) {
    return '';
  }

  return `${queryParametersFilter}&`;
}

export function generateQueryParameters(sortingData) {
  let queryParameters = {};
  let queryParametersFilter = '';

  if (sortingData) {
    if (sortingData.userId || !_isEmpty(sortingData.secondSearch) || !_isEmpty(sortingData.search)) {
      queryParametersFilter = `user__id=${encodeURIComponent(sortingData.userId)}`;
    }

    if (!_isEmpty(sortingData.search)) {
      queryParametersFilter = `${getQueryParametrFilter(queryParametersFilter)}${'departure'}=${encodeURIComponent(sortingData.search)}`;
    }

    if (!_isEmpty(sortingData.secondSearch)) {
      queryParametersFilter = `${getQueryParametrFilter(queryParametersFilter)}${'arrival'}=${encodeURIComponent(sortingData.secondSearch)}`;
    }

    if (!_isEmpty(sortingData.columnToSort)) {
      queryParameters.ordering = `${sortingData.sortAsc ? '' : '-'}${sortingPossibitilies[sortingData.columnToSort]}`;
    }
  }

  queryParameters = qs.stringify(queryParameters);

  if (!_isEmpty(queryParametersFilter)) {
    queryParameters = `${queryParameters}&${queryParametersFilter}`;
  }

  return _isEmpty(queryParameters) ? '' : `?${queryParameters}`;
}

export function fetchJourneyLogs(sortingData) {
  return (dispatch) => {
    dispatch(fetchJourneyLogsRequest());

    const queryParameters = generateQueryParameters(sortingData);

    return axios.get(urlJoin(URLS.journeyLog, queryParameters))
      .then(({ data }) => {
        dispatch(fetchJourneyLogsSuccess(data.results));
      })
      .catch((error) => {
        dispatch(fetchJourneyLogsFailure());
        dispatch(addError(`Error during journey logs fetching ${error.message}`, error));
      });
  };
}

export function fetchJourneyLog(journeyLogId) {
  return (dispatch) => {
    dispatch(fetchJourneyLogsRequest());

    return axios.get(urlJoin(URLS.journeyLog, journeyLogId))
      .then(({ data }) => {
        dispatch(fetchJourneyLogSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchJourneyLogsFailure());
        dispatch(addError(`Error during journey log fetching ${error.message}`, error));
      });
  };
}

export function removeJourneyLog(journeyLogId) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.journeyLog, journeyLogId))
      .then(() => dispatch(removeJourneyLogSuccess(journeyLogId)))
      .catch((error) => {
        dispatch(addError(`Error during journey log removing ${error.message}`, error));
      });
  };
}

export function addJourneyLog(journeyLog, changeDestination = true) {
  return (dispatch) => {
    return axios.post(URLS.journeyLog, journeyLog)
      .then(({ data }) => {
        dispatch(addJourneyLogSuccess(data));

        if (changeDestination) {
          history.push('/my_logbook');
        }
      })
      .catch((error) => {
        dispatch(addError(`Error during journey log adding ${error.message}`, error));
      });
  };
}

export function cleanJourneyLogData() {
  return (dispatch) => dispatch(cleanJourneyLog());
}

export function editJourneyLog(journeyLogId, updatedJourneyLog) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.journeyLog, journeyLogId), updatedJourneyLog)
      .then(({ data }) => {
        dispatch(editJourneyLogSuccess(data));
        history.push('/logbook_list');
      })
      .catch((error) => {
        dispatch(addError(`Error during journey log editing ${error.message}`, error));
      });
  };
}
