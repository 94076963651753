import React from 'react';
import compose from 'recompose/compose';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

import { FileInputWithoutStyles } from './FileInput';
import { ALLOWED_IMG_FILES_EXT } from '../../../constants';

const styles = ({
  fileInput: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    height: '100%',
    width: '500%'
  }
});

class ImageInput extends FileInputWithoutStyles {
  avaliableExtentions() {
    return ALLOWED_IMG_FILES_EXT;
  }

  getIncorrectFileFormatError() {
    const { t } = this.props;

    return t('error:incorrectFileFormat');
  }

  getFileSizeToBigError(megaBYTESSize) {
    const { t } = this.props;

    return `${t('error:fileSizeToBig')} ${megaBYTESSize} MB`;
  }

  render() {
    const { classes } = this.props;

    return (
      <input
        accept="image/*"
        className={classes.fileInput}
        id="button-file"
        type="file"
        onClick={super.createOnBlurHandler()}
        onChange={super.createOnChangeFileHandler()}
      />
    );
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles)
)(ImageInput);
export { ImageInput as ImageInputWithoutStyles };
