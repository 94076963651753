import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Icon from '@material-ui/core/Icon';

import { renderPermission, hasPermission } from '../../decorators/permissions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';
import { renderModule } from '../../decorators/modules';
import { MODULES } from '../../../constants/modules';


@withNamespaces()
export default class NavigationLower extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  renderArrow() {
    return (
      <Icon className="mx-1 nav-drop-icon">arrow_drop_down</Icon>
    );
  }

  renderUserMenu(t) {
    return (
      <div className="nav-link">
        {t('navigation:usersMenu')}
        {this.renderArrow()}
        <div className="dropdown">
          <Link to="/users" className="nav-link-item">
            {t('navigation:usersLink')}
          </Link>
          <Link to="/personnel_status" className="nav-link-item">
            {t('navigation:crewStatusLink')}
          </Link>
          <Link to="/users_categories" className="nav-link-item">
            {t('navigation:usersCategories')}
          </Link>
        </div>
      </div>
    );
  }

  @renderModule(MODULES.training)
  renderTrainingMenu(t) {
    return (
      <div className="nav-link">
        {t('navigation:trainingMenu')}
        {this.renderArrow()}
        <div className="dropdown">
          <Link to="/course_list" className="nav-link-item">
            {t('navigation:courseListLink')}
          </Link>
          <Link to="/course_admin" className="nav-link-item">
            {t('navigation:courseAdminLink')}
          </Link>
        </div>
      </div>
    );
  }

  @renderPermission(PERMISSIONS.questionView)
  renderQuestionBankLink(t) {
    return (
      <Link to="/question_bank" className="nav-link-item">
        {t('navigation:questionBankLink')}
      </Link>
    );
  }

  @renderModule(MODULES.exams)
  renderExamMenu(t) {
    return (
      <div className="nav-link">
        {t('navigation:examsMenu')}
        {this.renderArrow()}
        <div className="dropdown">
          <Link to="/exam_list" className="nav-link-item">
            {t('navigation:examListLink')}
          </Link>
          <Link to="/exam_admin" className="nav-link-item">
            {t('navigation:examAdminLink')}
          </Link>
          {this.renderQuestionBankLink(t)}
        </div>
      </div>
    );
  }

  @renderModule(MODULES.booking)
  renderBookingMenu(t) {
    return (
      <div className="nav-link single-link">
        <Link to="/booking" className="nav-link-item">
          {t('navigation:bookingLink')}
        </Link>
      </div>
    );
  }

  @renderPermission(PERMISSIONS.allReportsView)
  @renderModule(MODULES.report)
  renderReportMenu(t) {
    return (
      <div className="nav-link single-link">
        <Link to="/report" className="nav-link-item">
          {t('navigation:reportsMenu')}
        </Link>
      </div>
    );
  }

  @renderPermission(PERMISSIONS.viewAllLogbooks)
  @renderModule(MODULES.logbook)
  renderLogbookMenu(t) {
    return (
      <div className="nav-link single-link">
        <Link to="/logbook_list" className="nav-link-item">
          {t('navigation:logbookMenu')}
        </Link>
      </div>
    );
  }

  @renderPermission(PERMISSIONS.userSettingsView)
  createUserSettingsLink(t) {
    return (
      <>
        <Link to="/settings/users" className="nav-link-item">
          {t('navigation:settingsUsersLink')}
        </Link>
      </>
    );
  }

  @renderPermission(PERMISSIONS.generalSettingsView)
  createGeneralSettingsLink(t) {
    return (
      <>
        <Link to="/settings/general" className="nav-link-item">
          {t('navigation:settingsGeneralLink')}
        </Link>
      </>
    );
  }

  @renderPermission(PERMISSIONS.companySettingsView)
  createCompanySettingsLink(t) {
    return (
      <>
        <Link to="/settings/company" className="nav-link-item">
          {t('navigation:settingsCompanyLink')}
        </Link>
      </>
    );
  }

  @renderPermission(PERMISSIONS.reportsSettingsView)
  @renderModule(MODULES.report)
  createReportsSettingsLink(t) {
    return (
      <>
        <Link to="/settings/reports" className="nav-link-item">
          {t('navigation:settingsReportsLink')}
        </Link>
      </>
    );
  }

  @renderPermission(PERMISSIONS.generalNotificationView)
  createNotificationsSettingsLink(t) {
    return (
      <>
        <Link to="/settings/notifications" className="nav-link-item">
          {t('navigation:settingsNotificationsLink')}
        </Link>
      </>
    );
  }

  @renderPermission(PERMISSIONS.viewAllEquipments)
  @renderModule(MODULES.equipment)
  renderEquipmentMenu(t) {
    return (
      <div className="nav-link single-link">
        <Link to="/equipment" className="nav-link-item">
          {t('navigation:equipmentMenu')}
        </Link>
      </div>
    );
  }

  @renderModule(MODULES.library)
  renderLibraryMenu(t) {
    return (
      <div className="nav-link single-link">
        <Link to="/library" className="nav-link-item">
          {t('navigation:libraryMenu')}
        </Link>
      </div>
    );
  }

  @renderModule(MODULES.fleet)
  @renderPermission(PERMISSIONS.viewFleets)
  renderFleetMenu(t) {
    return (
      <div className="nav-link">
        {t('navigation:fleetMenu')}
        {this.renderArrow()}
        <div className="dropdown">
          <Link to="/fleet" className="nav-link-item">
            {t('navigation:fleetLink')}
          </Link>
          <Link to="/parts" className="nav-link-item">
            {t('navigation:partsLink')}
          </Link>
          <Link to="/warehouse" className="nav-link-item">
            {t('warehouse:warehouseLink')}
          </Link>
        </div>
      </div>
    );
  }

  @renderModule(MODULES.fleet)
  @renderPermission(PERMISSIONS.viewFleets)
  renderRiskManagementMenu(t) {
    return (
      <div className="nav-link single-link">
        <Link to="/sms" className="nav-link-item">
          {t('navigation:sms')}
        </Link>
      </div>
    );
  } 

  renderSettingsMenu(t) {
    if (
      hasPermission(PERMISSIONS.generalSettingsView) ||
      hasPermission(PERMISSIONS.companySettingsView) ||
      hasPermission(PERMISSIONS.userSettingsView) ||
      hasPermission(PERMISSIONS.reportsSettingsView) ||
      hasPermission(PERMISSIONS.generalNotificationView)
    ) {
      return (
        <div className="nav-link">
          {t('navigation:settingsMenu')}
          {this.renderArrow()}
          <div className="dropdown">
            {this.createGeneralSettingsLink(t)}
            {this.createCompanySettingsLink(t)}
            {this.createUserSettingsLink(t)}
            {this.createReportsSettingsLink(t)}
            {this.createNotificationsSettingsLink(t)}
          </div>
        </div>
      );
    }

    return null;
  }

  @renderPermission(PERMISSIONS.leftNavigationMenuView)
  renderNavigationMenu(t) {
    return (
      <nav className="nav-lower">
        {this.renderUserMenu(t)}
        {this.renderTrainingMenu(t)}
        {this.renderExamMenu(t)}
        {this.renderBookingMenu(t)}
        {this.renderReportMenu(t)}
        {this.renderLogbookMenu(t)}
        {this.renderEquipmentMenu(t)}
        {this.renderLibraryMenu(t)}
        {this.renderFleetMenu(t)}
        {this.renderRiskManagementMenu(t)}
        {this.renderSettingsMenu(t)}
      </nav>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <>
      {this.renderNavigationMenu(t)}
      </>
    );
  }
}
