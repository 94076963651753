import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _each from 'lodash/each';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import TypeList from './TypeList';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import Loader from '../../../../common/components/Loader';
import Form from '../../../../common/components/forms/Form';

import { fetchAllTypes, fetchUserSettingsTypes, fetchTypes, addType, removeType } from '../../../actions/types';
import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  typeName: '',
};

const mapStateToProps = (state) => {
  return {
    typeList: state.types.typeList,
    allTypeList: state.types.allTypeList.filter((type)=> !type.user),
    typeRequest: state.types.typeRequest,
    userTypeList: state.types.userTypeList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAllTypes: () => dispatch(fetchAllTypes()),
    fetchTypes: (userId) => dispatch(fetchTypes(userId)),
    fetchUserSettingsTypes: (userId) => dispatch(fetchUserSettingsTypes(userId)),
    addType: (name) => dispatch(addType(name)),
    removeType: (name) => dispatch(removeType(name)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class TypesManager extends Component {
  static propTypes = {
    userId: PropTypes.string,
    typeList: PropTypes.array,
    allTypeList: PropTypes.array,
    userTypeList: PropTypes.array,
    typeRequest: PropTypes.bool.isRequired,
    fetchAllTypes: PropTypes.func.isRequired,
    fetchTypes: PropTypes.func.isRequired,
    fetchUserSettingsTypes: PropTypes.func.isRequired,
    addType: PropTypes.func.isRequired,
    removeType: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const { userId, fetchAllTypes, fetchUserSettingsTypes } = this.props;

    if (userId) {
      fetchUserSettingsTypes(userId);
    } else {
      fetchAllTypes();
    }
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleAddClose = () => {
    this.setState(INITIAL_STATE);
  };

  addType = () => {
    const { typeName } = this.state;
    const { userId } = this.props;

    const dataToSave = { name: typeName };

    if (userId) {
      dataToSave.user = userId;
    }

    this.props.addType(dataToSave);
    this.handleAddClose();
  };

  removeType = (type) => {
    this.props.removeType(type);
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.addType();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { userId, allTypeList, userTypeList, typeRequest, onElementDelete, t } = this.props;
    const { addOpen, typeName } = this.state;

    if (typeRequest) {
      return <Loader />;
    }

    return (
      <div className="types-settings">
        <div className={userId ? 'pl-sm-3' : 'pl-sm-5'}>
          <h4>{userId ? t('settings:myTypes') : t('settings:typesSection')} </h4>
        </div>
        <TypeList
          userId={userId}
          typeList={userId ? userTypeList : allTypeList}
          removeType={this.removeType}
          handleAddOpen={this.handleAddOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen}
            onClose={this.handleAddClose}
            aria-labelledby="add-type-dialog"
            fullWidth
          >
            <DialogTitle id="add-type-dialog">{t('settings:addTypeDialogTitle')}</DialogTitle>
            <DialogContent>
              <MaterialInput
                label={t('input:name')}
                autoFocus
                name="typeName"
                defaultValue={typeName}
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleAddClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
