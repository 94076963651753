import {
  FETCH_INSPECTIONS_REQUEST,
  FETCH_INSPECTIONS_SUCCESS,
  FETCH_INSPECTION_REQUEST,
  FETCH_INSPECTION_SUCCESS,
  FETCH_INSPECTIONS,
  ADD_INSPECTION,
  EDIT_INSPECTION,
  REMOVE_INSPECTION,
  CLEAN_INSPECTION_DATA
} from '../actions/inspections';
import {
  REMOVE_INSPECTION_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  inspection: {},
  inspectionList: [],
  inspectionsRequest: false,
};

function fetchInspectionsSuccess(state, payload) {
  return {
    ...state,
    inspectionList: payload.inspections,
    inspectionsRequest: false,
  };
}

function fetchInspectionsRequest(state) {
  return {
    ...state,
    inspectionsRequest: true,
  };
}

function fetchInspectionSuccess(state, payload) {
  return {
    ...state,
    inspection: payload.inspection,
    inspectionsRequest: false,
  };
}

function fetchInspectionRequest(state) {
  return {
    ...state,
    inspectionsRequest: true,
  };
}

function fetchInspections(state) {
  return {
    ...state,
    inspectionsRequest: true
  };
}

function addInspection(state, payload) {
  return {
    ...state,
    inspectionList: [
      ...state.inspectionList,
      payload.inspection
    ]
  };
}

function editInspection(state, payload) {
  return {
    ...state,
    inspectionList: state.inspectionList.map((inspection) => {
      if (inspection.id === payload.inspection.id) {
        return payload.inspection;
      }

      return inspection;
    })
  };
}

function removeInspection(state, payload) {
  return {
    ...state,
    inspectionList: state.inspectionList.filter((inspection) => inspection !== payload.inspection)
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    inspectionList: state.inspectionList.map((inspection) => {
      if (inspection.id === payload.inspectionId) {
        return {
          ...inspection,
          files: inspection.files.filter((file) => file.id !== payload.fileId)
        };
      }

      return inspection;
    })
  };
}

function cleanInspection(state) {
  return {
    ...state,
    inspection: null
  };
}

const actions = {
  [FETCH_INSPECTIONS_SUCCESS]: fetchInspectionsSuccess,
  [FETCH_INSPECTIONS_REQUEST]: fetchInspectionsRequest,
  [FETCH_INSPECTION_SUCCESS]: fetchInspectionSuccess,
  [FETCH_INSPECTION_REQUEST]: fetchInspectionRequest,
  [FETCH_INSPECTIONS]: fetchInspections,
  [ADD_INSPECTION]: addInspection,
  [REMOVE_INSPECTION]: removeInspection,
  [EDIT_INSPECTION]: editInspection,
  [REMOVE_INSPECTION_FILE_SUCCESS]: removeFile,
  [CLEAN_INSPECTION_DATA]: cleanInspection,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
