import {
  FETCH_VEHICLES_REQUEST,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLES_FAILURE,
  ADD_VEHICLE,
  EDIT_VEHICLE,
  REMOVE_VEHICLE,
} from '../actions/vehicles';
import {
  REMOVE_VEHICLE_VEHICLE_TYPE,
} from '../actions/vehicleTypes';

const INITIAL_STATE = {
  vehicleList: [],
  vehicleRequest: false,
};

function fetchVehiclesSuccess(state, payload) {
  return {
    ...state,
    vehicleList: payload.vehicles,
    vehicleRequest: false,
  };
}

function fetchVehiclesRequest(state) {
  return {
    ...state,
    vehicleRequest: true,
  };
}

function fetchVehiclesFailure(state) {
  return {
    ...state,
    vehicleRequest: false,
  };
}

function addVehicle(state, payload) {
  return {
    ...state,
    vehicleList: [
      ...state.vehicleList,
      payload.vehicle
    ],
  };
}

function editVehicle(state, payload) {
  return {
    ...state,
    vehicleList: state.vehicleList.map((vehicle) => {
      if (vehicle.id === payload.vehicle.id) {
        return payload.vehicle;
      }

      return vehicle;
    }),
  };
}

function removeVehicle(state, payload) {
  return {
    ...state,
    vehicleList: state.vehicleList.filter((vehicle) => vehicle.id !== payload.vehicle.id),
  };
}

function removeVehicleVehicleType(state, payload) {
  return {
    ...state,
    vehicleList: state.vehicleList.filter((vehicle) => vehicle.type.id !== payload.vehicleType.id),
  };
}

const actions = {
  [FETCH_VEHICLES_SUCCESS]: fetchVehiclesSuccess,
  [FETCH_VEHICLES_FAILURE]: fetchVehiclesFailure,
  [FETCH_VEHICLES_REQUEST]: fetchVehiclesRequest,
  [ADD_VEHICLE]: addVehicle,
  [EDIT_VEHICLE]: editVehicle,
  [REMOVE_VEHICLE]: removeVehicle,
  [REMOVE_VEHICLE_VEHICLE_TYPE]: removeVehicleVehicleType,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
