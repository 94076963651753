import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import EquipmentNew from '../EquipmentNew';
import Loader from '../../../../common/components/Loader';
import { fetchEquipment } from '../../../actions/equipment';

const mapStateToProps = (state) => {
  return {
    equipment: state.equipment.equipment,
    equipmentRequest: state.equipment.equipmentRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchEquipment: (id) => dispatch(fetchEquipment(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossEquipmentDetailsView extends Component {
  static propTypes = {
    equipment: PropTypes.object.isRequired,
    fetchEquipment: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    equipmentRequest: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { fetchEquipment, match } = this.props;

    fetchEquipment(match.params.id);
  }

  render() {
    const { equipment, equipmentRequest, match } = this.props;

    if (equipmentRequest && _isEmpty(equipment)) {
      return <Loader />;
    }

    return (
      <EquipmentNew
        equipment={equipment}
        editMode={false}
        previewMode
        linkTo={'/equipment'}
        match={match}
      />
    );
  }
}
