import {
  FETCH_TYPES_SUCCESS,
  FETCH_ALL_TYPES_SUCCESS,
  FETCH_USER_TYPES_SUCCESS,
  FETCH_TYPES_REQUEST,
  FETCH_TYPES_FAILURE,
  ADD_TYPE,
  REMOVE_TYPE,
} from '../actions/types';

const INITIAL_STATE = {
  typeList: [],
  allTypeList: [],
  userTypeList: [],
  typeRequest: false,
};

function fetchTypes(state, payload) {
  return {
    ...state,
    typeList: payload.types,
    typeRequest: false,
  };
}

function fetchAllTypesSuccess(state, payload) {
  return {
    ...state,
    allTypeList: payload.allTypes,
    typeRequest: false,
  };
}

function fetchTypesRequest(state) {
  return {
    ...state,
    typeRequest: true,
  };
}

function fetchTypesFailure(state) {
  return {
    ...state,
    typeRequest: false,
  };
}

function fetchUserTypesSuccess(state, payload) {
  return {
    ...state,
    userTypeList: payload.userTypes,
    typeRequest: false
  };
}

function addType(state, payload) {
  return {
    ...state,
    typeList: [
      ...state.typeList,
      payload.types
    ],
    allTypeList: [
      ...state.allTypeList,
      payload.types
    ],
    userTypeList: [
      ...state.userTypeList,
      payload.types
    ],
  };
}

function removeType(state, payload) {
  return {
    ...state,
    typeList: state.typeList.filter((type) => type !== payload.types),
    allTypeList: state.allTypeList.filter((type) => type !== payload.types),
    userTypeList: state.userTypeList.filter((type) => type !== payload.types),
  };
}

const actions = {
  [FETCH_ALL_TYPES_SUCCESS]: fetchAllTypesSuccess,
  [FETCH_USER_TYPES_SUCCESS]: fetchUserTypesSuccess,
  [FETCH_TYPES_SUCCESS]: fetchTypes,
  [FETCH_TYPES_REQUEST]: fetchTypesRequest,
  [FETCH_TYPES_FAILURE]: fetchTypesFailure,
  [ADD_TYPE]: addType,
  [REMOVE_TYPE]: removeType,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
