import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Menu from './Menu';

@withNamespaces()
export default class UserMenu extends Menu {
  renderHeader() {
    const { t } = this.props;

    return (
      <>
        {t('navigation:usersMenu')}
      </>
    );
  }

  renderDropdownMenu() {
    const { t } = this.props;
    const { open } = this.state;
    const isOpen = open ? 'open' : '';

    return (
      <div className={`dropdown ${isOpen}`}>
        <Link to="/users" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:usersLink')}
        </Link>
        <Link to="/crew_status" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:crewStatusLink')}
        </Link>
      </div>
    );
  }
}
