import {
  CLEAN_HISTORYS_DATA,
  FETCH_HISTORYS_SUCCESS,
} from '../actions/historys';

const INITIAL_STATE = {
  historyList: [],
};

function fetchHistorysSuccess(state, payload) {
  return {
    ...state,
    historyList: payload.historys,
  };
}

function cleanHistorys(state) {
  return {
    ...state,
    ...INITIAL_STATE
  };
}

const actions = {
  [FETCH_HISTORYS_SUCCESS]: fetchHistorysSuccess,
  [CLEAN_HISTORYS_DATA]: cleanHistorys,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
