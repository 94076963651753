import {
  FETCH_QUESTION_CATEGORIES_SUCCESS,
  FETCH_QUESTION_CATEGORIES_REQUEST,
  FETCH_QUESTION_CATEGORIES_FAILURE,
  ADD_QUESTION_CATEGORY,
  REMOVE_QUESTION_CATEGORY,
} from '../actions/questionCategories';

const INITIAL_STATE = {
  questionCategoryList: [],
  questionCategoryRequest: false,
};

function fetchQuestionCategories(state, payload) {
  return {
    ...state,
    questionCategoryList: payload.questionCategories,
    questionCategoryRequest: false,
  };
}

function fetchQuestionCategoriesRequest(state) {
  return {
    ...state,
    questionCategoryRequest: true,
  };
}

function fetchQuestionCategoriesFailure(state) {
  return {
    ...state,
    questionCategoryRequest: false,
  };
}

function addQuestionCategory(state, payload) {
  return {
    ...state,
    questionCategoryList: [
      ...state.questionCategoryList,
      payload.questionCategory
    ]
  };
}

function removeQuestionCategory(state, payload) {
  return {
    ...state,
    questionCategoryList: state.questionCategoryList.filter((questionCategory) => questionCategory !== payload.questionCategory)
  };
}

const actions = {
  [FETCH_QUESTION_CATEGORIES_SUCCESS]: fetchQuestionCategories,
  [FETCH_QUESTION_CATEGORIES_REQUEST]: fetchQuestionCategoriesRequest,
  [FETCH_QUESTION_CATEGORIES_FAILURE]: fetchQuestionCategoriesFailure,
  [ADD_QUESTION_CATEGORY]: addQuestionCategory,
  [REMOVE_QUESTION_CATEGORY]: removeQuestionCategory,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
