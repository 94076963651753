import React, { Component } from 'react';

export default class Loader extends Component {
  render() {
    return (
      <div>
        <div className="lock-container" />
        <div className="fixed-loader" />
      </div>
    );
  }
}
