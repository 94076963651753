import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Icon from '@material-ui/core/Icon';

import { logout } from '../../actions/authentication';


function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}

@withNamespaces()
@connect(null, mapDispatchToProps)
export default class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  submit() {
    this.props.logout();
  }

  render() {
    const { t } = this.props;

    return (
      <Link to="/login" className="nav-link" onClick={() => this.submit()}>
        <Icon color="primary" className="mx-1">power_settings_new</Icon>
        {t('navigation:logout')}
      </Link>
    );
  }
}
