export const TABLE_HEADER = [
  {
    prop: 'firstName',
    sort: true,
  },
  {
    prop: 'lastName',
    sort: true,
  },
  {
    prop: 'relation',
    sort: true,
  },
  {
    prop: 'phone',
    sort: true,
  },
  {
    prop: 'email',
    sort: true,
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true,
    iconWidth: true,
  },
];
