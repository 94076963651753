import axios from 'axios';

import { getUsersRequest } from './users';
import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const REMOVE_ROLE_SUCCESS = 'REMOVE_ROLE_SUCCESS';


export function fetchRolesRequest() {
  return {
    type: FETCH_ROLES_REQUEST
  };
}

export function fetchRolesSuccess(roleList) {
  return {
    type: FETCH_ROLES_SUCCESS,
    payload: {
      roleList
    }
  };
}

export function fetchRolesFailure() {
  return {
    type: FETCH_ROLES_FAILURE
  };
}

export function addRoleSuccess(group) {
  return {
    type: ADD_ROLE_SUCCESS,
    payload: {
      group
    }
  };
}

export function removeRoleSuccess(user, group) {
  return {
    type: REMOVE_ROLE_SUCCESS,
    payload: {
      user,
      group
    }
  };
}

export function fetchRoles() {
  return (dispatch) => {
    dispatch(getUsersRequest());
    dispatch(fetchRolesRequest());

    return axios.get(URLS.groups)
      .then(({ data }) => dispatch(fetchRolesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchRolesFailure());
        dispatch(addError(`Error during roles fetching ${error.message}`, error));
      });
  };
}

export function addRole(group, users) {
  const usersIds = users.map((user) => {
    return {
      id: user.id
    };
  });

  return (dispatch) => {
    return axios.patch(urlJoin(URLS.groups, group.id), { userSet: usersIds })
      .then(({ data }) => dispatch(addRoleSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during role adding ${error.message}`, error));
      });
  };
}

export function removeRole(user, role) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.groups, role.id, 'users', user.id))
      .then(() => dispatch(removeRoleSuccess(user, role)))
      .catch((error) => {
        dispatch(addError(`Error during role removing ${error.message}`, error));
      });
  };
}
