import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import RoleList from './RoleList';
import Loader from '../../../../common/components/Loader';
import { ROLES } from '../../../../constants';

import { fetchUsers } from '../../../actions/users';
import { fetchRoles, addRole, removeRole } from '../../../actions/roles';

const INITIAL_STATE = {
  role: {
    id: null,
    name: ''
  },
  addOpen: false,
  newUsers: []
};

const mapStateToProps = (state) => {
  return {
    userList: state.users.userList,
    roleList: state.users.roleList,
    roleRequest: state.users.roleRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    fetchRoles: () => dispatch(fetchRoles()),
    addRole: (role, users) => dispatch(addRole(role, users)),
    removeRole: (user, role) => dispatch(removeRole(user, role)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class RolesManager extends Component {
  static propTypes = {
    userList: PropTypes.array,
    roleList: PropTypes.array,
    roleRequest: PropTypes.bool.isRequired,
    fetchRoles: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    addRole: PropTypes.func.isRequired,
    removeRole: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    ...INITIAL_STATE
  }

  componentDidMount() {
    this.props.fetchUsers();
    this.props.fetchRoles();
  }

  handleAddOpen = (role) => {
    this.setState({
      addOpen: true,
      role
    });
  }

  handleAddClose = () => {
    this.setState(INITIAL_STATE);
  }

  addRole = () => {
    this.props.addRole(this.state.role, this.state.newUsers);
    this.handleAddClose();
  }

  removeRole = (user, role) => {
    this.props.removeRole(user, role);
  }

  addNewUser = (newUser) => (event) => {
    if (event.target.checked) {
      this.setState({
        newUsers: this.state.newUsers.concat(newUser)
      });
    } else {
      this.setState({
        newUsers: this.state.newUsers.filter((user) => user.id !== newUser.id)
      });
    }
  };

  addDialogContent = () => {
    const { userList, t } = this.props;
    const addUserList = userList
      .filter((user) => {
        if (!user.groups) {
          return true;
        }

        return user.groups.every((group) => {
          return !(ROLES.hasOwnProperty(group.name));
        });
      });

    if (_isEmpty(addUserList)) {
      return this.getDailogContent(<h4>{t('settings:noUsers')}</h4>, false);
    }

    return this.getDailogContent(this.getUserList(_sortBy(addUserList, ['firstName'])), true);
  };

  getDailogContent = (content, user) => {
    const { t } = this.props;

    return (
      <div>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleAddClose}>{t('buttonCancel')}</Button>
          {user && <Button color="primary" onClick={this.addRole}>{t('buttonSave')}</Button>}
        </DialogActions>
      </div>
    );
  };

  getUserList = (addUserList) => {
    return addUserList
      .map((user) => {
        return (
          <div
            key={user.id}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={this.state.isMultiEngine}
                  onChange={this.addNewUser(user)}
                />
              }
              label={`${user.firstName} ${user.lastName}`}
            />
          </div>
        );
      });
  };

  render() {
    const { userList, roleList, roleRequest, onElementDelete, t } = this.props;
    const { role } = this.state;

    if (roleRequest) {
      return <Loader />;
    } else if (_isEmpty(roleList)) {
      return (
        <div className="roles-settings">
          <div className="pl-sm-5">
            <h4>
              {t('settings:noRoles')}
            </h4>
          </div>
        </div>
      );
    }

    const adminRole = roleList.find((group) => group.name === ROLES.admin);
    const managerRole = roleList.find((group) => group.name === ROLES.manager);
    const instructorRole = roleList.find((group) => group.name === ROLES.instructor);

    const dialogTitle = `settings:${role.name}DialogTitle`;

    return (
      <div className="roles-settings">
        <RoleList
          role={adminRole}
          userList={userList}
          removeRole={(user, role) => onElementDelete(() => this.removeRole(user, role))}
          handleAddOpen={this.handleAddOpen}
        />
        <Divider className="mx-sm-3 my-4" />
        <RoleList
          role={managerRole}
          userList={userList}
          removeRole={(user, role) => onElementDelete(() => this.removeRole(user, role))}
          handleAddOpen={this.handleAddOpen}
        />
        <Divider className="mx-sm-3 my-4" />
        <RoleList
          role={instructorRole}
          userList={userList}
          removeRole={(user, role) => onElementDelete(() => this.removeRole(user, role))}
          handleAddOpen={this.handleAddOpen}
        />
        <Dialog
          open={this.state.addOpen}
          onClose={this.handleAddClose}
          aria-labelledby="add-role"
          fullWidth
        >
          <DialogTitle id="add-role">{t(dialogTitle)}</DialogTitle>
          {this.addDialogContent()}
        </Dialog>
      </div>
    );
  }
}
