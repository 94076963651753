import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';

import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import validators from '../../../../utils/validators';

import { editSetting } from '../../../actions/settings';

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editSetting: (setting, errorCallback) => dispatch(editSetting(setting, errorCallback)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class CompanyInfoManager extends Component {
  static propTypes = {
    settings: PropTypes.array.isRequired,
    editSetting: PropTypes.func.isRequired,
    onInfoSuccessfullySaved: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    infoSuccessfullySaved: true,
  };

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    return _find(settings, { name: fieldName }).textValue;
  }

  onChange = (formData) => {
    this.formData = formData;
  };

  handleEditSettingError = () => {
    if (this.state.infoSuccessfullySaved) {
      this.setState({
        infoSuccessfullySaved: false,
      });
    }
  };

  onFormValidated = async (isFormValid) => {
    const { settings, editSetting } = this.props;

    this.setState({ infoSuccessfullySaved: true });

    if (isFormValid && this.formData) {
      const flightNumber = this.formData.flightNumber.value;
      const departure = this.formData.departure.value;
      const destination = this.formData.destination.value;
      const issue = this.formData.issue.value;
      const solution = this.formData.solution.value;

      const flightNumberPromise = editSetting({
        id: _find(settings, { name: 'flight_number' }).id,
        textValue: flightNumber,
      }, this.handleEditSettingError);

      const departurePromise = editSetting({
        id: _find(settings, { name: 'departure' }).id,
        textValue: departure,
      }, this.handleEditSettingError);

      const destinationPromise = editSetting({
        id: _find(settings, { name: 'destination' }).id,
        textValue: destination,
      }, this.handleEditSettingError);

      const issuePromise = editSetting({
        id: _find(settings, { name: 'issue' }).id,
        textValue: issue,
      }, this.handleEditSettingError);

      const solutionPromise = editSetting({
        id: _find(settings, { name: 'solution' }).id,
        textValue: solution,
      }, this.handleEditSettingError);

      await flightNumberPromise;
      await departurePromise;
      await destinationPromise;
      await issuePromise;
      await solutionPromise;

      if (this.state.infoSuccessfullySaved) {
        this.props.onInfoSuccessfullySaved();
      }
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { t } = this.props;

    return (
      <div className="report-fields-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:reportFieldsSection')}</h4>
          <div className="ml-sm-3 my-2">
            <Form
              onChange={this.onChange}
              onFormValidated={this.onFormValidated}
              registerForm={this.registerForm}
            >
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="flightNumber"
                  label={t('input:field1')}
                  defaultValue={this.setDefaultValue('flight_number')}
                  validators={[
                    new validators.IsRequired(t),
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="departure"
                  label={t('input:field2')}
                  defaultValue={this.setDefaultValue('departure')}
                  validators={[
                    new validators.IsRequired(t)
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="destination"
                  label={t('input:field3')}
                  defaultValue={this.setDefaultValue('destination')}
                  validators={[
                    new validators.IsRequired(t)
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="issue"
                  label={t('input:issue')}
                  defaultValue={this.setDefaultValue('issue')}
                  validators={[
                    new validators.IsRequired(t)
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="solution"
                  label={t('input:solution')}
                  defaultValue={this.setDefaultValue('solution')}
                  validators={[
                    new validators.IsRequired(t)
                  ]}
                />
              </div>
              <Button
                className="my-3"
                onClick={this.sendHandler}
                color="primary"
                variant="raised"
              >
                {t('buttonSave')}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
