import {
  FETCH_BOOKING_OBJECTS_SUCCESS,
  FETCH_BOOKING_OBJECTS_REQUEST,
  FETCH_BOOKING_OBJECTS_FAILURE,
  ADD_BOOKING_OBJECT,
  REMOVE_BOOKING_OBJECT,
} from '../actions/bookingObjects';

const INITIAL_STATE = {
  bookingObjectList: [],
  bookingObjectRequest: false,
};

function fetchBookingObjects(state, payload) {
  return {
    ...state,
    bookingObjectList: payload.bookingObjects,
    bookingObjectRequest: false,
  };
}

function fetchBookingObjectsRequest(state) {
  return {
    ...state,
    bookingObjectRequest: true,
  };
}

function fetchBookingObjectsFailure(state) {
  return {
    ...state,
    bookingObjectRequest: false,
  };
}

function addBookingObject(state, payload) {
  return {
    ...state,
    bookingObjectList: [
      ...state.bookingObjectList,
      payload.bookingObject
    ]
  };
}

function removeBookingObject(state, payload) {
  return {
    ...state,
    bookingObjectList: state.bookingObjectList.filter((bookingObject) => bookingObject !== payload.bookingObject)
  };
}

const actions = {
  [FETCH_BOOKING_OBJECTS_SUCCESS]: fetchBookingObjects,
  [FETCH_BOOKING_OBJECTS_REQUEST]: fetchBookingObjectsRequest,
  [FETCH_BOOKING_OBJECTS_FAILURE]: fetchBookingObjectsFailure,
  [ADD_BOOKING_OBJECT]: addBookingObject,
  [REMOVE_BOOKING_OBJECT]: removeBookingObject,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
