import _get from 'lodash/get';
import axios from 'axios';

import { addError } from './errors';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_RSS_NEWS_REQUEST = 'FETCH_RSS_NEWS_REQUEST';
export const FETCH_RSS_NEWS_SUCCESS = 'FETCH_RSS_NEWS_SUCCESS';
export const FETCH_RSS_NEWS_FAILURE = 'FETCH_RSS_NEWS_FAILURE';

export function fetchRssNewsRequest() {
  return {
    type: FETCH_RSS_NEWS_REQUEST
  };
}

export function fetchRssNewsSuccess(rssNews) {
  return {
    type: FETCH_RSS_NEWS_SUCCESS,
    payload: {
      rssNews: _get(rssNews, 'data.rss.channel.item', [])
    }
  };
}

export function fetchRssNewsFailure() {
  return {
    type: FETCH_RSS_NEWS_FAILURE
  };
}

export function fetchRssNews(rssUrl) {
  return (dispatch) => {
    dispatch(fetchRssNewsRequest());

    return axios.post(URLS.rssNews, rssUrl)
      .then((data) => {
        return dispatch(fetchRssNewsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchRssNewsFailure());
        dispatch(addError(`Error during rss news fetching ${error.message}`, error));
      });
  };
}
