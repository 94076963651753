import {
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICE_REQUEST,
  FETCH_SERVICE_SUCCESS,
  FETCH_SERVICES,
  ADD_SERVICE,
  EDIT_SERVICE,
  REMOVE_SERVICE,
  CLEAN_SERVICE_DATA
} from '../actions/services';
import {
  REMOVE_SERVICE_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  service: {},
  serviceList: [],
  servicesRequest: false,
};

function fetchServicesSuccess(state, payload) {
  return {
    ...state,
    serviceList: payload.services,
    servicesRequest: false,
  };
}

function fetchServicesRequest(state) {
  return {
    ...state,
    servicesRequest: true,
  };
}

function fetchServiceSuccess(state, payload) {
  return {
    ...state,
    service: payload.service,
    servicesRequest: false,
  };
}

function fetchServiceRequest(state) {
  return {
    ...state,
    servicesRequest: true,
  };
}

function fetchServices(state) {
  return {
    ...state,
    servicesRequest: true
  };
}

function addService(state, payload) {
  return {
    ...state,
    serviceList: [
      ...state.serviceList,
      payload.service
    ]
  };
}


function editService(state, payload) {
  return {
    ...state,
    serviceList: state.serviceList.map((service) => {
      if (service.id === payload.service.id) {
        return payload.service;
      }

      return service;
    })
  };
}

function removeService(state, payload) {
  return {
    ...state,
    serviceList: state.serviceList.filter((service) => service !== payload.service)
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    serviceList: state.serviceList.map((service) => {
      if (service.id === payload.serviceId) {
        return {
          ...service,
          files: service.files.filter((file) => file.id !== payload.fileId)
        };
      }

      return service;
    })
  };
}

function cleanService(state) {
  return {
    ...state,
    service: null
  };
}

const actions = {
  [FETCH_SERVICES_SUCCESS]: fetchServicesSuccess,
  [FETCH_SERVICES_REQUEST]: fetchServicesRequest,
  [FETCH_SERVICE_SUCCESS]: fetchServiceSuccess,
  [FETCH_SERVICE_REQUEST]: fetchServiceRequest,
  [FETCH_SERVICES]: fetchServices,
  [ADD_SERVICE]: addService,
  [REMOVE_SERVICE]: removeService,
  [EDIT_SERVICE]: editService,
  [REMOVE_SERVICE_FILE_SUCCESS]: removeFile,
  [CLEAN_SERVICE_DATA]: cleanService,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
