export const tabActiveHeader = [
  {
    prop: 'startDate',
    sort: false,
    profileView: false
  },
  {
    prop: 'firstName',
    sort: true,
    profileView: false
  },
  {
    prop: 'lastName',
    sort: true,
    profileView: false
  },
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'progress',
    sort: false,
    profileView: false
  },
  {
    prop: 'timeRequired',
    sort: false,
    profileView: false,
    isNumeric: true
  },
  {
    prop: 'timeTaken',
    sort: false,
    profileView: false,
    isNumeric: true
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true
  },
];

export const tabInactiveHeader = [
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'firstName',
    sort: true,
    profileView: false
  },
  {
    prop: 'lastName',
    sort: true,
    profileView: false
  },
  {
    prop: 'timeRequired',
    sort: false,
    profileView: false
  },
  {
    prop: 'scheduleTime',
    sort: false,
    profileView: false
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true
  },
];

export const tabCompletedHeader = [
  {
    prop: 'dateCompleted',
    sort: false,
    profileView: false
  },
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'firstName',
    sort: true,
    profileView: false
  },
  {
    prop: 'lastName',
    sort: true,
    profileView: false
  },
  {
    prop: 'timeTaken',
    sort: false,
    profileView: false
  },
  {
    prop: 'certificate',
    sort: false,
    profileView: false,
    isNumeric: true
  },
];
