import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


const INITIAL_STATE = {
  typeDialogOpen: false,
  newTypeList: [],
};

@withNamespaces()
export default class TypeList extends Component {
  static propTypes = {
    typeList: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    preview: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  renderMenuItems() {
    const { typeList, defaultValue } = this.props;
    const typeId = _get(defaultValue, 'typeId', '');
    const typeName = _get(defaultValue, 'typeName', '');
    const newTypeList = typeName ? { id: typeId, name: typeName } : [];

    if (_isEmpty(typeList) && _isEmpty(newTypeList)) {
      return null;
    }

    return typeList.concat(newTypeList).map((type) => {
      return <MenuItem key={`type-${type.id}`} value={type.id}>{type.name}</MenuItem>;
    });
  }

  render() {
    const { label, handleSelectChange, defaultValue, preview, t } = this.props;
    const type = _get(defaultValue, 'type', '');
    const inputLabel = `input:${label}`;


    return (
      <div className="practical-lesson-wrapper">
        <FormControl disabled={preview} fullWidth >
          <InputLabel htmlFor={label}>{t(inputLabel)}</InputLabel>
          <Select
            value={type}
            onChange={handleSelectChange}
            className={preview ? 'svg-arrow-hidden' : ''}
            inputProps={{
              name: `${label}.type`,
              id: label,
            }}
          >
            {this.renderMenuItems()}
          </Select>
        </FormControl>
      </div>
    );
  }
}
