import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _find from 'lodash/find';

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { editSetting } from '../../../actions/settings';
import DATE_FORMAT, { VALUES } from '../../../../constants/dateformat';


const styles = () => ({
  languages: {
    minWidth: '220px',
  },
});

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editSetting: (setting) => dispatch(editSetting(setting)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class DateFormatManager extends Component {
  static propTypes = {
    settings: PropTypes.array.isRequired,
    editSetting: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  handleChange = (event) => {
    const { settings, editSetting } = this.props;

    this.setState({ [event.target.name]: event.target.value });
    editSetting({
      id: _find(settings, { name: event.target.name }).id,
      textValue: event.target.value
    });
  };

  getDateFormatValue = () => {
    const { settings } = this.props;

    return settings.find((setting) => setting.name === DATE_FORMAT).textValue;
  };

  render() {
    const { t, classes } = this.props;
    const dateFormatList = VALUES;
    const dateFormatValue = this.getDateFormatValue();

    return (
      <div className="pl-sm-5">
        <h4>{t('settings:dateFormatSection')}</h4>
        <form autoComplete="off">
          <FormControl className="ml-2 ml-sm-3 my-2">
            <InputLabel className={classes.languages} htmlFor="date-format">{t('input:dateFormat')}</InputLabel>
            <Select
              className={classes.languages}
              value={dateFormatValue}
              onChange={this.handleChange}
              inputProps={{
                name: 'dateformat',
                id: 'date-format'
              }}
            >
              {
                dateFormatList
                  .map((dateFormat) => <MenuItem key={`date-format-${dateFormat}`} value={dateFormat}>{dateFormat}</MenuItem>)
              }
            </Select>
          </FormControl>
        </form>
      </div>
    );
  }
}
