import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  minColWidth: {
    minWidth: 50,
  }
});

@withNamespaces()
@withStyles(styles)
export default class AircraftList extends Component {
  static propTypes = {
    userId: PropTypes.string,
    aircraftList: PropTypes.array,
    removeAircraft: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderAircraftList = (aircraftList) => {
    const { removeAircraft, handleEditOpen, classes, onElementDelete, t } = this.props;
    const tableColumns = ['type', 'pilot', 'engine', 'propulsion'];

    return aircraftList.map((aircraft) => {
      return (
        <TableRow key={`aircraft-${aircraft.id}`}>
          <TableCell className={classes.minColWidth}>{aircraft.registration}</TableCell>
          <TableCell className={classes.minColWidth}>{aircraft.name}</TableCell>
          { tableColumns.map((item, i) => {
            const translation = `table:${aircraft[item] || ''}`;

            return <TableCell key={`table-cell-${i}`} className={classes.minColWidth}>{t(translation)}</TableCell>;
          })}
          <TableCell numeric>
            <IconButton onClick={() => handleEditOpen(aircraft)}><Icon color="primary">mode_edit</Icon></IconButton>
            <IconButton onClick={() => onElementDelete(() => removeAircraft(aircraft))}><Icon color="primary">delete</Icon></IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { classes, handleAddOpen, aircraftList, t, userId } = this.props;

    if (_isEmpty(aircraftList)) {
      return (
        <div className={userId ? 'pl-sm-3' : 'pl-sm-5'}>
          <Button color="primary" onClick={handleAddOpen}>
            {t('settings:addAircraftButton')}
          </Button>
        </div>
      );
    }

    return (
      <div className={userId ? 'pl-sm-3' : 'pl-sm-5'}>
        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table:registration')}</TableCell>
                  <TableCell>{t('table:name')}</TableCell>
                  <TableCell>{t('table:type')}</TableCell>
                  <TableCell>{t('table:pilots')}</TableCell>
                  <TableCell>{t('table:engines')}</TableCell>
                  <TableCell>{t('table:propulsion')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderAircraftList(aircraftList)}
              </TableBody>
            </Table>
          </div>
        </div>

        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addAircraftButton')}
        </Button>
      </div>
    );
  }
}
