import {
  editEngineerAircraftTypeExperience,
  removeEngineerAircraftTypeExperienceSuccess,
} from './engineerAircraftTypeExperience';

import { URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { editExternalCourseSuccess } from './externalCourses';
import { editFolderSuccess } from './folders';
import { editItemSuccess } from './items';
import { editLicenseSuccess } from './licenses';
import { editMedicalSuccess } from './medicals';
import { editPartSuccess } from './parts';
import { editPersonalFileSuccess } from './personalFiles';
import { editQualificationSuccess } from './qualifications';
import { editRatingSuccess } from './ratings';
import { fileUpload } from '../../utils/fileUpload';
import { updateUserProfileSuccess } from './users';
import { urlJoin } from '../../utils';

export const ATTACH_PROFILE_IMAGE_SUCCESS = 'ATTACH_PROFILE_IMAGE_SUCCESS';
export const REMOVE_FOLDER_FILE_SUCCESS = 'REMOVE_FOLDER_FILE_SUCCESS';
export const EDIT_FOLDER_FILE_SUCCESS = 'EDIT_FOLDER_FILE_SUCCESS';
export const REMOVE_RATING_FILE_SUCCESS = 'REMOVE_RATING_FILE_SUCCESS';
export const REMOVE_LICENSE_FILE_SUCCESS = 'REMOVE_LICENSE_FILE_SUCCESS';
export const REMOVE_MEDICAL_FILE_SUCCESS = 'REMOVE_MEDICAL_FILE_SUCCESS';
export const REMOVE_EXTERNAL_COURSE_FILE_SUCCESS =
  'REMOVE_EXTERNAL_COURSE_FILE_SUCCESS';
export const REMOVE_QUALIFICATION_FILE_SUCCESS =
  'REMOVE_QUALIFICATION_FILE_SUCCESS';
export const REMOVE_ITEM_FILE_SUCCESS = 'REMOVE_ITEM_FILE_SUCCESS';
export const REMOVE_PERSONAL_FILE_FILE_SUCCESS =
  'REMOVE_PERSONAL_FILE_FILE_SUCCESS';
export const FETCH_SCHOOL_LOGO_SUCCESS = 'FETCH_SCHOOL_LOGO_SUCCESS';
export const FETCH_SCHOOL_LOGO_FAILURE = 'FETCH_SCHOOL_LOGO_FAILURE';
export const FETCH_SCHOOL_LOGO_REQUEST = 'FETCH_SCHOOL_LOGO_REQUEST';
export const UPDATE_SCHOOL_LOGO_SUCCESS = 'UPDATE_SCHOOL_LOGO_SUCCESS';
export const FETCH_DIGITAL_SIGNATURE_SUCCESS =
  'FETCH_DIGITAL_SIGNATURE_SUCCESS';
export const FETCH_DIGITAL_SIGNATURE_FAILURE =
  'FETCH_DIGITAL_SIGNATURE_FAILURE';
export const FETCH_DIGITAL_SIGNATURE_REQUEST =
  'FETCH_DIGITAL_SIGNATURE_REQUEST';
export const UPDATE_DIGITAL_SIGNATURE_SUCCESS =
  'UPDATE_DIGITAL_SIGNATURE_SUCCESS';
export const FETCH_ADVERT_IMAGE_SUCCESS = 'FETCH_ADVERT_IMAGE_SUCCESS';
export const FETCH_ADVERT_IMAGE_FAILURE = 'FETCH_ADVERT_IMAGE_FAILURE';
export const FETCH_ADVERT_IMAGE_REQUEST = 'FETCH_ADVERT_IMAGE_REQUEST';
export const UPDATE_ADVERT_IMAGE_SUCCESS = 'UPDATE_ADVERT_IMAGE_SUCCESS';
export const REMOVE_PART_FILE_SUCCESS = 'REMOVE_PART_FILE_SUCCESS';
export const REMOVE_DOCUMENT_FILE_SUCCESS = 'REMOVE_DOCUMENT_FILE_SUCCESS';
export const REMOVE_INSPECTION_FILE_SUCCESS = 'REMOVE_INSPECTION_FILE_SUCCESS';
export const REMOVE_SERVICE_FILE_SUCCESS = 'REMOVE_SERVICE_FILE_SUCCESS';
export const REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP_FILE_SUCCESS =
  'REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP_FILE_SUCCESS';

export function removeFolderFileSuccess(id, folderId) {
  return {
    type: REMOVE_FOLDER_FILE_SUCCESS,
    payload: {
      fileId: id,
      folderId,
    },
  };
}

export function editFolderFileSuccess(file, folderId) {
  return {
    type: EDIT_FOLDER_FILE_SUCCESS,
    payload: {
      file,
      folderId,
    },
  };
}

export function removeRatingFileSuccess(id, ratingId) {
  return {
    type: REMOVE_RATING_FILE_SUCCESS,
    payload: {
      fileId: id,
      ratingId,
    },
  };
}

export function removeEngineerAircraftTypeExperienceFileSuccess(id, expId) {
  return {
    type: REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP_FILE_SUCCESS,
    payload: {
      fileId: id,
      expId,
    },
  };
}

export function removeMedicalFileSuccess(id, medicalId) {
  return {
    type: REMOVE_MEDICAL_FILE_SUCCESS,
    payload: {
      fileId: id,
      medicalId,
    },
  };
}

export function removeLicenseFileSuccess(id, licenseId) {
  return {
    type: REMOVE_LICENSE_FILE_SUCCESS,
    payload: {
      fileId: id,
      licenseId,
    },
  };
}

export function removeExternalCourseFileSuccess(id, externalCourseId) {
  return {
    type: REMOVE_EXTERNAL_COURSE_FILE_SUCCESS,
    payload: {
      fileId: id,
      externalCourseId,
    },
  };
}

export function removeQualificationFileSuccess(id, qualificationId) {
  return {
    type: REMOVE_QUALIFICATION_FILE_SUCCESS,
    payload: {
      fileId: id,
      qualificationId,
    },
  };
}

export function removeItemFileSuccess(id, itemId) {
  return {
    type: REMOVE_ITEM_FILE_SUCCESS,
    payload: {
      fileId: id,
      itemId,
    },
  };
}

export function removePersonalFileFileSuccess(id, personalFileId) {
  return {
    type: REMOVE_PERSONAL_FILE_FILE_SUCCESS,
    payload: {
      fileId: id,
      personalFileId,
    },
  };
}

export function removePartFileSuccess(id, partId) {
  return {
    type: REMOVE_PART_FILE_SUCCESS,
    payload: {
      fileId: id,
      partId,
    },
  };
}

export function removeDocumentFileSuccess(id, documentId) {
  return {
    type: REMOVE_DOCUMENT_FILE_SUCCESS,
    payload: {
      fileId: id,
      documentId,
    },
  };
}

export function removeInspectionFileSuccess(id, inspectionId) {
  return {
    type: REMOVE_INSPECTION_FILE_SUCCESS,
    payload: {
      fileId: id,
      inspectionId,
    },
  };
}

export function removeServiceFileSuccess(id, serviceId) {
  return {
    type: REMOVE_SERVICE_FILE_SUCCESS,
    payload: {
      fileId: id,
      serviceId,
    },
  };
}

export function fetchSchoolLogoSuccess(schoolLogo) {
  return {
    type: FETCH_SCHOOL_LOGO_SUCCESS,
    payload: {
      schoolLogo,
    },
  };
}

export function fetchSchoolLogoFailure() {
  return {
    type: FETCH_SCHOOL_LOGO_FAILURE,
  };
}

export function fetchSchoolLogoRequest() {
  return {
    type: FETCH_SCHOOL_LOGO_REQUEST,
  };
}

export function updateSchoolLogoSuccess(file) {
  return {
    type: UPDATE_SCHOOL_LOGO_SUCCESS,
    payload: file,
  };
}

export function fetchDigitalSignatureSuccess(digitalSignature) {
  return {
    type: FETCH_DIGITAL_SIGNATURE_SUCCESS,
    payload: {
      digitalSignature,
    },
  };
}

export function fetchDigitalSignatureFailure() {
  return {
    type: FETCH_DIGITAL_SIGNATURE_FAILURE,
  };
}

export function fetchDigitalSignatureRequest() {
  return {
    type: FETCH_DIGITAL_SIGNATURE_REQUEST,
  };
}

export function updateDigitalSignatureSuccess(file) {
  return {
    type: UPDATE_DIGITAL_SIGNATURE_SUCCESS,
    payload: file,
  };
}

export function fetchAdvertImageSuccess(advertImage) {
  return {
    type: FETCH_ADVERT_IMAGE_SUCCESS,
    payload: {
      advertImage,
    },
  };
}

export function fetchAdvertImageFailure() {
  return {
    type: FETCH_ADVERT_IMAGE_FAILURE,
  };
}

export function fetchAdvertImageRequest() {
  return {
    type: FETCH_ADVERT_IMAGE_REQUEST,
  };
}

export function updateAdvertImageSuccess(file) {
  return {
    type: UPDATE_ADVERT_IMAGE_SUCCESS,
    payload: file,
  };
}

export function attachProfileImage(file, id) {
  return (dispatch) => {
    fileUpload(file, 'PATCH', urlJoin(URLS.attachProfileImage, id), {
      onFinished: (data) => dispatch(updateUserProfileSuccess(data)),
      onFailure: (error) =>
        dispatch(
          addError(`Error during file uploading ${error.message}`, error),
        ),
      onProgress: () => {},
    });
  };
}

export function removeProfileImage(id) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.usersProfileUpdate, id), { profilePicture: null })
      .then(({ data }) => dispatch(updateUserProfileSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function attachEngineerAircraftTypeExperienceFile(file, expId, userId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachEngineerAircraftTypeExperience, expId, userId),
      {
        onFinished: (data) =>
          dispatch(editEngineerAircraftTypeExperience(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function attachRatingFile(file, ratingId, userId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachRatingFile, ratingId, userId),
      {
        onFinished: (data) => dispatch(editRatingSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function attachMedicalFile(file, medicalId, userId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachMedicalFile, medicalId, userId),
      {
        onFinished: (data) => dispatch(editMedicalSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function attachLicenseFile(file, licenseId, userId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachLicenseFile, licenseId, userId),
      {
        onFinished: (data) => dispatch(editLicenseSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function attachQualificationFile(file, qualificationId, userId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachQualificationFile, qualificationId, userId),
      {
        onFinished: (data) => dispatch(editQualificationSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function attachExternalCourseFile(file, externalCourseId, userId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachExternalCourseFile, externalCourseId, userId),
      {
        onFinished: (data) => dispatch(editExternalCourseSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function attachPersonalFileFile(file, personalFileId, userId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachPersonalFileFile, personalFileId, userId),
      {
        onFinished: (data) => dispatch(editPersonalFileSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function attachPartFile(file, partId) {
  return (dispatch) => {
    fileUpload(file, 'PATCH', urlJoin(URLS.attachPartFile, partId), {
      onFinished: (data) => dispatch(editPartSuccess(data)),
      onFailure: (error) =>
        dispatch(
          addError(`Error during file uploading ${error.message}`, error),
        ),
      onProgress: () => {},
    });
  };
}

export function attachDocumentFile(file, documentId, fleetId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachDocumentFile, documentId, fleetId),
      {
        onFinished: (data) => dispatch(editDocumentSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function attachInspectionFile(file, inspectionId, fleetId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachInspectionFile, inspectionId, fleetId),
      {
        onFinished: (data) => dispatch(editInspectionSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function attachServiceFile(file, serviceId, fleetId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.attachServiceFile, serviceId, fleetId),
      {
        onFinished: (data) => dispatch(editServiceSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function removeEngineerAircraftExperienceFile(id, expId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() =>
        dispatch(removeEngineerAircraftTypeExperienceFileSuccess(id, expId)),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removeRatingFile(id, ratingId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removeRatingFileSuccess(id, ratingId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removeMedicalFile(id, medicalId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removeMedicalFileSuccess(id, medicalId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removeLicenseFile(id, licenseId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removeLicenseFileSuccess(id, licenseId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removeQualificationFile(id, qualificationId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removeQualificationFileSuccess(id, qualificationId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removeExternalCourseFile(id, externalCourseId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() =>
        dispatch(removeExternalCourseFileSuccess(id, externalCourseId)),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removePersonalFileFile(id, personalFileId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removePersonalFileFileSuccess(id, personalFileId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removePartFile(id, partId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removePartFileSuccess(id, partId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removeDocumentFile(id, documentId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removeDocumentFileSuccess(id, documentId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removeInspectionFile(id, inspectionId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removeInspectionFileSuccess(id, inspectionId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function removeServiceFile(id, serviceId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removeServiceFileSuccess(id, serviceId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function attachSchoolLogoImage(file, id) {
  return (dispatch) => {
    fileUpload(file, 'PATCH', urlJoin(URLS.schoolLogoFile, id), {
      onFinished: (data) => dispatch(updateSchoolLogoSuccess(data)),
      onFailure: (error) =>
        dispatch(
          addError(`Error during file uploading ${error.message}`, error),
        ),
      onProgress: () => {},
    });
  };
}

export function fetchSchoolLogo(id) {
  return (dispatch) => {
    dispatch(fetchSchoolLogoRequest());

    return axios
      .get(urlJoin(URLS.schoolLogoFile, id))
      .then(({ data }) => {
        dispatch(fetchSchoolLogoSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchSchoolLogoFailure());
        dispatch(
          addError(`Error during question fetching ${error.message}`, error),
        );
      });
  };
}

export function attachItemFile(file, itemId) {
  return (dispatch) => {
    fileUpload(file, 'PATCH', urlJoin(URLS.itemFile, itemId), {
      onFinished: (data) => dispatch(editItemSuccess(data)),
      onFailure: (error) =>
        dispatch(
          addError(`Error during file uploading ${error.message}`, error),
        ),
      onProgress: () => {},
    });
  };
}

export function removeItemFile(id, itemId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removeItemFileSuccess(id, itemId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function attachDigitalSignatureImage(file, id) {
  return (dispatch) => {
    fileUpload(file, 'PATCH', urlJoin(URLS.digitalSignatureFile, id), {
      onFinished: (data) => dispatch(updateDigitalSignatureSuccess(data)),
      onFailure: (error) =>
        dispatch(
          addError(`Error during file uploading ${error.message}`, error),
        ),
      onProgress: () => {},
    });
  };
}

export function fetchDigitalSignature(id) {
  return (dispatch) => {
    dispatch(fetchDigitalSignatureRequest());

    return axios
      .get(urlJoin(URLS.digitalSignatureFile, id))
      .then(({ data }) => {
        dispatch(fetchDigitalSignatureSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchDigitalSignatureFailure());
        dispatch(
          addError(`Error during question fetching ${error.message}`, error),
        );
      });
  };
}

export function attachFolderFile(file, folderId, notify) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(URLS.libraryFile, folderId, notify ? '?notify' : ''),
      {
        onFinished: (data) => dispatch(editFolderSuccess(data)),
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function removeFolderFile(id, folderId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fileRemove, id))
      .then(() => dispatch(removeFolderFileSuccess(id, folderId)))
      .catch((error) => {
        dispatch(
          addError(`Error during file removing ${error.message}`, error),
        );
      });
  };
}

export function editFolderFile(file, folderId) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.files, file.id), file)
      .then(({ data }) => dispatch(editFolderFileSuccess(data, folderId)))
      .catch((error) => {
        dispatch(addError(`Error during file editing ${error.message}`, error));
      });
  };
}

export function fetchAdvertImage(id) {
  return (dispatch) => {
    dispatch(fetchAdvertImageRequest());

    return axios
      .get(urlJoin(URLS.advertImage, id))
      .then(({ data }) => {
        dispatch(fetchAdvertImageSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchAdvertImageFailure());
        dispatch(
          addError(
            `Error during advert image fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function attachAdvertImage(file, id) {
  return (dispatch) => {
    fileUpload(file, 'PATCH', urlJoin(URLS.advertImage, id), {
      onFinished: (data) => dispatch(updateAdvertImageSuccess(data)),
      onFailure: (error) =>
        dispatch(
          addError(
            `Error during advert image uploading ${error.message}`,
            error,
          ),
        ),
      onProgress: () => {},
    });
  };
}

export function fileMarkAsRead(file) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.files, file.id, '/read'))
      .then(({ data }) => {
        dispatch(editFolderFileSuccess(data, null));
      })
      .catch((error) => {
        dispatch(
          addError(`Error during mark file as read ${error.message}`, error),
        );
      });
  };
}
