import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddJourneyLog from '../LogbookNew/addJourneyLog';
import Loader from '../../../../common/components/Loader';
import { fetchJourneyLog } from '../../../actions/journeyLog';

const mapStateToProps = (state) => {
  return {
    journeyLog: state.journeyLog.journeyLog,
    journeyLogRequest: state.journeyLog.journeyLogDetailsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchJourneyLog: (id) => dispatch(fetchJourneyLog(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class LogbookEdit extends Component {
  static propTypes = {
    journeyLog: PropTypes.object.isRequired,
    fetchJourneyLog: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    journeyLogRequest: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.fetchJourneyLog(this.props.match.params.id);
  }

  render() {
    const { journeyLog, journeyLogRequest } = this.props;

    if (journeyLogRequest || !journeyLog) {
      return <Loader />;
    }

    return (
      <AddJourneyLog
        journeyLog={journeyLog}
        previewMode
      />
    );
  }
}
