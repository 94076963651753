import { URLS_RAIL as URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { getUsersRequest } from './users';
import { urlJoin } from '../../utils';

export const FETCH_VEHICLE_GROUP_USERS_REQUEST =
  'FETCH_VEHICLE_GROUP_USERS_REQUEST';
export const FETCH_VEHICLE_GROUP_USERS_SUCCESS =
  'FETCH_VEHICLE_GROUP_USERS_SUCCESS';
export const FETCH_VEHICLE_GROUP_USERS_FAILURE =
  'FETCH_VEHICLE_GROUP_USERS_FAILURE';
export const GET_VEHICLE_GROUP_USERS_SUCCESS =
  'GET_VEHICLE_GROUP_USERS_SUCCESS';
export const ADD_VEHICLE_GROUP_USER_SUCCESS = 'ADD_VEHICLE_GROUP_USER_SUCCESS';
export const REMOVE_VEHICLE_GROUP_USER_SUCCESS =
  'REMOVE_VEHICLE_GROUP_USER_SUCCESS';

export function fetchVehicleGroupUsersRequest() {
  return {
    type: FETCH_VEHICLE_GROUP_USERS_REQUEST,
  };
}

export function fetchVehicleGroupUsersSuccess(vehicleGroupUserList) {
  return {
    type: FETCH_VEHICLE_GROUP_USERS_SUCCESS,
    payload: {
      vehicleGroupUserList,
    },
  };
}

export function fetchVehicleGroupUsersFailure() {
  return {
    type: FETCH_VEHICLE_GROUP_USERS_FAILURE,
  };
}

export function addVehicleGroupUserSuccess(vehicleGroupUserList) {
  return {
    type: ADD_VEHICLE_GROUP_USER_SUCCESS,
    payload: {
      vehicleGroupUserList,
    },
  };
}

export function removeVehicleGroupUserSuccess(vehiceGroupUserId) {
  return {
    type: REMOVE_VEHICLE_GROUP_USER_SUCCESS,
    payload: {
      vehiceGroupUserId,
    },
  };
}

export function fetchVehicleGroupUsers() {
  return (dispatch) => {
    dispatch(getUsersRequest());
    dispatch(fetchVehicleGroupUsersRequest());

    return axios
      .get(URLS.vehicleGroupUsers)
      .then(({ data }) => dispatch(fetchVehicleGroupUsersSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchVehicleGroupUsersFailure());
        dispatch(
          addError(
            `Error during vehicleGroupUsers fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function addVehicleGroupUser(vehicleId, userId) {
  return (dispatch) => {
    return axios
      .post(urlJoin(URLS.vehicleGroupUsers), {
        vehicle: vehicleId,
        user: userId,
      })
      .then(({ data }) => dispatch(addVehicleGroupUserSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during vehicleGroupUser adding ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function removeVehicleGroupUser(vehicleGroupUserId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.vehicleGroupUsers, vehicleGroupUserId))
      .then(dispatch(removeVehicleGroupUserSuccess(vehicleGroupUserId)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during vehicleGroupUser removing ${error.message}`,
            error,
          ),
        );
      });
  };
}
