import '../styles.scss';

import React, { Component } from 'react';
import {
  addUserCompetence,
  editUserCompetence,
  removeUserCompetence,
} from '../../../actions/userCompetencies';
import {
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import UserCompetenciesList from './UserCompetenciesList';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { getDateFormat } from '../../../utils/time';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  competence: null,
  expiryDate: moment(),
  id: null,
  isFormValid: false,
  userCompetenceId: null,
};

const mapStateToProps = (state) => {
  return {
    userCompetenceList: state.userCompetencies.userCompetenceList || [],
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addUserCompetence: (userCompetence) =>
      dispatch(addUserCompetence(userCompetence)),
    removeUserCompetence: (userCompetence) =>
      dispatch(removeUserCompetence(userCompetence)),
    editUserCompetence: (userCompetence) =>
      dispatch(editUserCompetence(userCompetence)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UserExternalCourses extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    editMode: PropTypes.bool,
    addUserCompetence: PropTypes.func.isRequired,
    fetchUserProfile: PropTypes.func.isRequired,
    removeUserCompetence: PropTypes.func.isRequired,
    editUserCompetence: PropTypes.func.isRequired,
    deactivateExternalCourse: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    documentTypeList: PropTypes.array.isRequired,
    competenciesList: PropTypes.array.isRequired,
    userCompetenceList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    ...INITIAL_STATE,
  };

  onChange = (formData) => {
    this.formData = formData;
    _each(formData, (value, key) => {
      this.setState({
        [key]: value.value,
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.state.addOpen
          ? this.addUserCompetence()
          : this.editUserCompetence();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (userCompetence) => {
    this.setState({
      editOpen: true,
      id: userCompetence.id,
      expiryDate: userCompetence.expiryDate,
      userCompetenceId: _get(userCompetence, 'competence.id', null),
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addUserCompetence = () => {
    const { expiryDate, userCompetenceId } = this.state;
    const { userId } = this.props;
    const newUserCompetence = {
      competence: userCompetenceId,
      user: userId,
      expiryDate: reformatPickerDateToDB(expiryDate),
    };

    this.props.addUserCompetence(newUserCompetence);
    this.handleDialogClose();
  };

  removeUserCompetence = (userCompetence) => {
    this.props.removeUserCompetence(userCompetence);
  };

  editUserCompetence = () => {
    const { id, expiryDate, userCompetenceId } = this.state;
    const { userId, editUserCompetence } = this.props;
    const newExternalCourse = {
      id,
      competence: userCompetenceId,
      user: userId,
      expiryDate: reformatPickerDateToDB(expiryDate),
    };

    editUserCompetence(newExternalCourse);
    this.handleDialogClose();
  };

  handleDatePickerChange = (date) => {
    this.setState({
      expiryDate: date,
    });
  };

  getAvailableCompetencies = (competenciesList) => {
    const { editOpen, userCompetenceId } = this.state;

    const selectedCompetencies = _get(this.props, 'userCompetenceList', []);

    if (_isEmpty(competenciesList) || _isEmpty(selectedCompetencies)) {
      return competenciesList;
    }

    const selectedCategoryNames = selectedCompetencies.map(({ competence }) => {
      return _get(competence, 'name', '');
    });

    return competenciesList.filter((competence) => {
      if (editOpen) {
        return (
          selectedCategoryNames.indexOf(competence.name) === -1 ||
          userCompetenceId === competence.id
        );
      }

      return selectedCategoryNames.indexOf(competence.name) === -1;
    });
  };

  dialogContent = (classes, title, t) => {
    const { expiryDate, userCompetenceId } = this.state;
    const { competenciesList } = this.props;
    const competencies = this.getAvailableCompetencies(competenciesList);

    return (
      <div>
        <DialogTitle id="add-external-course-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className="row">
            <div className="col-6">
              <MaterialSelect
                id="userCompetenceId"
                name="userCompetenceId"
                label={t('input:competence')}
                defaultValue={userCompetenceId}
                options={competencies.map((competence) => ({
                  value: competence.id,
                  label: competence.name,
                }))}
                validators={[new validators.IsRequired(t)]}
              />
            </div>
            <div className="col-6">
              <DateTimePicker
                value={reformatPickerDateFromDB(expiryDate)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange}
                dateFormat={getDateFormat()}
                name="expiryDate"
                label={t('input:expiryDate')}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleDialogClose}>
            {t('buttonCancel')}
          </Button>
          <Button color="primary" onClick={this.sendHandler}>
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </div>
    );
  };

  render() {
    const {
      userCompetenceList,
      onElementDelete,
      editMode,
      classes,
      addSimpleError,
      t,
    } = this.props;
    const { addOpen, editOpen } = this.state;

    return (
      <div className="col-12 user">
        <UserCompetenciesList
          userCompetenciesList={userCompetenceList}
          removeUserCompetence={(userCompetence) =>
            onElementDelete(() => this.removeUserCompetence(userCompetence))
          }
          handleAddOpen={this.handleAddOpen}
          editMode={editMode}
          handleEditOpen={this.handleEditOpen}
          addSimpleError={addSimpleError}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={addOpen || editOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="add-user-competence-dialog-title"
          >
            {this.dialogContent(
              classes,
              addOpen
                ? t('settings:addUserCompetence')
                : t('settings:editUserCompetence'),
              t,
            )}
          </Dialog>
        </Form>
      </div>
    );
  }
}
