import axios from 'axios';
import _get from 'lodash/get';
import { addError, addSimpleError } from './errors';
import { URLS } from '../../constants';
import { urlJoin } from '../../utils';

export const FETCH_FLIGHT_EXPERIENCE_REQUEST = 'FETCH_FLIGHT_EXPERIENCE_REQUEST';
export const FETCH_FLIGHT_EXPERIENCE_SUCCESS = 'FETCH_FLIGHT_EXPERIENCE_SUCCESS';
export const FETCH_FLIGHT_EXPERIENCE_FAILURE = 'FETCH_FLIGHT_EXPERIENCE_FAILURE';
export const EDIT_FLIGHT_EXPERIENCE = 'EDIT_FLIGHT_EXPERIENCE';
export const RESET_FLIGHT_EXPERIENCE_SUCCESS = 'RESET_FLIGHT_EXPERIENCE_SUCCESS';


export function fetchFlightExperienceRequest() {
  return {
    type: FETCH_FLIGHT_EXPERIENCE_REQUEST
  };
}

export function fetchFlightExperienceSuccess(flightExperience) {
  return {
    type: FETCH_FLIGHT_EXPERIENCE_SUCCESS,
    payload: {
      flightExperience
    }
  };
}

export function fetchFlightExperienceFailure() {
  return {
    type: FETCH_FLIGHT_EXPERIENCE_FAILURE
  };
}

export function editFlightExperienceSuccess(flightExperience) {
  return {
    type: EDIT_FLIGHT_EXPERIENCE,
    payload: { flightExperience }
  };
}

export function resetChangeFlightExperienceSuccess() {
  return {
    type: RESET_FLIGHT_EXPERIENCE_SUCCESS,
  };
}

export function fetchFlightExperience(userId) {
  return (dispatch) => {
    dispatch(fetchFlightExperienceRequest());

    return axios.get(urlJoin(URLS.flightExperience, userId))
      .then(({ data }) => {
        dispatch(fetchFlightExperienceSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchFlightExperienceFailure());
        dispatch(addError(`Error during flight experience fetching ${error.message}`, error));
      });
  };
}

export function editFlightExperience(userId, flightExperience) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.flightExperience, userId), flightExperience)
      .then(({ data }) => {
        dispatch(editFlightExperienceSuccess(data));
      })
      .catch((error) => {
        const responseData = _get(error, 'response.data', '');

        if (typeof responseData === 'string' && responseData.includes('ValidationError')) {
          dispatch(addSimpleError(('Flight experience validation error')));
        } else {
          dispatch(addError(`Error during flight experience editing ${error.message}`, error));
        }
      });
  };
}

export function resetSaveFlightExperienceSuccess() {
  return (dispatch) => dispatch(resetChangeFlightExperienceSuccess());
}
