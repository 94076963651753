import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { getClassName } from '../../../../utils/courseService';
import ItemDraggable from './ItemDraggable';

const styles = () => ({
  root: {
    maxHeight: 465,
    overflowY: 'auto',
  },
  small: {
    maxHeight: 120,
    overflowY: 'auto',
  },
  medium: {
    maxHeight: 230,
    overflowY: 'auto',
  },
  large: {
    maxHeight: 340,
    overflowY: 'auto',
  },
});

@withNamespaces()
@withStyles(styles)
export default class ItemList extends Component {
  static propTypes = {
    itemList: PropTypes.array.isRequired,
    lessons: PropTypes.array.isRequired,
    removeItem: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    handleDuplicate: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    innerHeight: PropTypes.number.isRequired,
    innerWidth: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderItemList = (itemList) => {
    const { handleEditOpen, handleDuplicate, removeItem, classes, lessons, innerHeight, innerWidth } = this.props;
    const steps = {
      small: 550,
      medium: 650,
      large: 770,
    };

    return (
      <div className={classes[getClassName(innerHeight, innerWidth, steps)]}>
        {
          itemList.filter((item) => item.isActive).map((item) => {
            const isUsed = lessons.some((lesson) => {
              return lesson.items.some((lessonItem) => {
                return lessonItem.id === item.id;
              });
            });

            return (
              <ItemDraggable
                key={`item-available-${item.id}`}
                item={item}
                estimatedTime={item.estimatedTime}
                handleEditOpen={handleEditOpen}
                handleDuplicate={handleDuplicate}
                removeItem={removeItem}
                isUsed={isUsed}
                editMode
              />
            );
          })
        }
      </div>
    );
  }

  render() {
    const { itemList, handleAddOpen, t } = this.props;

    return (
      <div>
        {!_isEmpty(itemList) && this.renderItemList(itemList)}
        <div className="col-12 text-center my-3">
          <Button color="primary" onClick={handleAddOpen}>
            {t('course:addContentButton')}
          </Button>
        </div>
      </div>
    );
  }
}

