import axios from 'axios';
import queryString from 'query-string';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';

export const FETCH_BOOKINGS_REQUEST = 'FETCH_BOOKINGS_REQUEST';
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_FAILURE = 'FETCH_BOOKINGS_FAILURE';
export const ADD_BOOKING = 'ADD_BOOKING';
export const EDIT_BOOKING = 'EDIT_BOOKING';
export const REMOVE_BOOKING = 'REMOVE_BOOKING';
export const BOOKING_NOTIFICATION = 'BOOKING_NOTIFICATION';

export function fetchBookingsRequest() {
  return {
    type: FETCH_BOOKINGS_REQUEST
  };
}

export function fetchBookingsSuccess(bookings) {
  return {
    type: FETCH_BOOKINGS_SUCCESS,
    payload: {
      bookings
    }
  };
}

export function fetchBookingsFailure() {
  return {
    type: FETCH_BOOKINGS_FAILURE
  };
}

export function addBookingSuccess(booking) {
  return {
    type: ADD_BOOKING,
    payload: {
      booking
    }
  };
}

export function editBookingSuccess(booking) {
  return {
    type: EDIT_BOOKING,
    payload: {
      booking
    }
  };
}

export function removeBookingSuccess(booking) {
  return {
    type: REMOVE_BOOKING,
    payload: {
      booking
    }
  };
}


export function fetchBookings(queryParameters) {
  return (dispatch) => {
    dispatch(fetchBookingsRequest());

    const bookingQueryParameters = queryString.stringify(queryParameters);

    return axios.get(`${URLS.bookings}?${bookingQueryParameters}`)
      .then(({ data }) => dispatch(fetchBookingsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchBookingsFailure());
        dispatch(addError(`Error during bookings fetching ${error.message}`, error));
      });
  };
}

export function addBooking(booking) {
  return (dispatch) => {
    return axios.post(URLS.bookings, booking)
      .then(({ data }) => dispatch(addBookingSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during booking adding ${error.message}`, error));
      });
  };
}

export function editBooking(bookingId, booking) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.bookings, bookingId), booking)
      .then(({ data }) => dispatch(editBookingSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during booking editing ${error.message}`, error));
      });
  };
}

export function removeBooking(booking) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.bookings, booking.id))
      .then(() => dispatch(removeBookingSuccess(booking)))
      .catch((error) => {
        dispatch(addError(`Error during booking removing ${error.message}`, error));
      });
  };
}

export function notify(data) {
  return (dispatch) => {
    return axios.post(URLS.bookingNotification, data)
      .then(() => dispatch({
        type: BOOKING_NOTIFICATION,
        payload: data
      }))
      .catch((error) => {
        dispatch(addError(`Error during booking notification ${error.message}`, error));
      });
  };
}
