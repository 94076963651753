import React, { Component } from 'react';
import {
  editEmergencyContact,
  removeEmergencyContact,
} from '../../../../actions/emergencyContact';

import AddEditEmergencyContactDialog from './dialogs/AddEditEmergencyContact';
import { Dialog } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import RemoveDialog from '../../../../../common/components/RemoveDialog';
import { TABLE_HEADER } from './EmergencyContactConstants';
import Table from '../../../../../common/components/Table';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { isAdmin } from '../../../../../utils';
import { withNamespaces } from 'react-i18next';

function mapDispatchToProps(dispatch) {
  return {
    removeEmergencyContact: (userId, emergencyContact) =>
      dispatch(removeEmergencyContact(userId, emergencyContact)),
    editEmergencyContact: (emergencyContact) =>
      dispatch(editEmergencyContact(emergencyContact)),
  };
}

@withNamespaces()
@connect(() => ({}), mapDispatchToProps)
class EmergencyContactList extends Component {
  static propTypes = {
    isMyProfile: PropTypes.bool,
    user: PropTypes.object,
    userId: PropTypes.string.isRequired,
    myProfile: PropTypes.object.isRequired,
    emergencyContactList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func,
    onSelectedEmergencyContact: PropTypes.func.isRequired,
    removeEmergencyContact: PropTypes.func.isRequired,
    editEmergencyContact: PropTypes.func.isRequired,
    openRemoveDialog: PropTypes.func.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: '',
      secondSearch: '',
      userId: '',
      user: _get(this.props.user, 'user', ''),
    },
    removeDialogOpen: false,
    editDialogOpen: false,
    removeEmergencyContact: null,
  };

  getTotalRow = () => {
    const sumEmergencyContacts = {
      firstName: '-',
      lastName: '-',
      relation: '-',
      phone: '-',
      email: '-',
    };

    return sumEmergencyContacts;
  };

  manageEmergencyContactList(emergencyContactList) {
    let parsedEmergencyContact = emergencyContactList.map(
      (emergencyContact) => ({
        ...emergencyContact,
        firstName: emergencyContact.firstName,
        lastName: emergencyContact.lastName,
        relation: emergencyContact.relation,
        phone: emergencyContact.phone,
        email: emergencyContact.email,
      }),
    );

    const totalRow = this.getTotalRow(parsedEmergencyContact);

    parsedEmergencyContact = parsedEmergencyContact.map((emergencyContact) => ({
      ...emergencyContact,
      firstName: emergencyContact.firstName,
      lastName: emergencyContact.lastName,
      relation: emergencyContact.relation,
      phone: emergencyContact.phone,
      email: emergencyContact.email,
    }));

    if (!_isEmpty(parsedEmergencyContact)) {
      parsedEmergencyContact.unshift(totalRow);
    }

    return parsedEmergencyContact;
  }

  handleSort = (columnName) => {
    const { search, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData,
    });
  };

  handleSearch = (search) => {
    const { columnToSort, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort,
      sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData,
    });
  };

  renderTrashIcon(emergencyContact) {
    return (
      <IconButton
        key="report-button-delete"
        onClick={() => this.openRemoveDialog(emergencyContact)}
      >
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  renderEditIcon(emergencyContact) {
    return (
      <IconButton
        key="emergency-contact-button-edit"
        onClick={() => this.openEditDialog(emergencyContact)}
      >
        <Icon color="primary">edit_emergency_contact</Icon>
      </IconButton>
    );
  }

  renderEditDialog() {
    const { isMyProfile, myProfile, userId } = this.props;
    const { emergencyContactToEdit, editDialogOpen } = this.state;

    return isMyProfile || isAdmin(myProfile) ? (
      <div>
        <AddEditEmergencyContactDialog
          userId={userId}
          emergencyContact={emergencyContactToEdit}
          open={editDialogOpen}
          edit
          onDialogClose={this.closeEditDialog}
        />
      </div>
    ) : (
      ''
    );
  }

  renderIcons(emergencyContact) {
    const { isMyProfile, myProfile } = this.props;

    return isMyProfile || isAdmin(myProfile)
      ? [
          this.renderEditIcon(emergencyContact),
          this.renderTrashIcon(emergencyContact),
        ]
      : [];
  }

  renderRemoveDialog = () => {
    const { isMyProfile, myProfile } = this.props;

    return isMyProfile || isAdmin(myProfile) ? (
      <Dialog
        open={this.state.removeDialogOpen}
        onClose={this.closeRemoveDialog}
        aria-labelledby="remove-dialog"
        fullWidth
      >
        <RemoveDialog
          message={'Remove emergency contact'}
          closeRemoveDialog={this.closeRemoveDialog}
          dialogTitle={'Remove emergency contact'}
          removeFunction={this.removeEmergencyContact}
        />
      </Dialog>
    ) : (
      ''
    );
  };

  removeEmergencyContact = () => {
    const { userId } = this.props;
    const { emergencyContactToRemove } = this.state;

    this.closeRemoveDialog();
    this.props.removeEmergencyContact(userId, emergencyContactToRemove);
  };

  openRemoveDialog = (emergencyContact) => {
    this.setState({
      removeDialogOpen: true,
      emergencyContactToRemove: emergencyContact,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeEmergencyContact: null,
    });
  };

  editEmergencyContact = () => {
    const { emergencyContactToEdit } = this.state;

    this.closeEditDialog();
    this.props.editEmergencyContact(emergencyContactToEdit);
  };

  openEditDialog = (emergencyContact) => {
    this.setState({
      editDialogOpen: true,
      emergencyContactToEdit: emergencyContact,
    });
  };

  closeEditDialog = () => {
    this.setState({
      editDialogOpen: false,
      emergencyContactToEdit: null,
    });
  };

  getGroupList() {
    const { emergencyContactList } = this.props;

    const parsedEmergencyContact = emergencyContactList.map(
      (emergencyContact) => ({
        firstName: emergencyContact.firstName,
        lastName: emergencyContact.lastName,
        relation: emergencyContact.relation,
        phone: emergencyContact.phone,
        email: emergencyContact.email,
        icons: this.renderIcons(emergencyContact),
      }),
    );

    return parsedEmergencyContact;
  }

  render() {
    const { t } = this.props;
    const { columnToSort, sortAsc } = this.state.searchSettings;

    return (
      <div className="d-flex flex-column">
        <Table
          className="table-wrapper col-12"
          data={this.getGroupList()}
          header={TABLE_HEADER}
          handleSort={this.handleSort}
          sortAsc={sortAsc}
          columnToSort={columnToSort}
          handleSearch={this.handleSearch}
          handleChangePage={this.onSelectedEmergencyContact}
        />
        {this.renderRemoveDialog(t)}
        {this.renderEditDialog(t)}
      </div>
    );
  }
}

export default EmergencyContactList;
