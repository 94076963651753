import axios from 'axios';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';

import history from '../store/history';
import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

import { fetchRatingsSuccess } from './ratings';
import { fetchQualificationsSuccess } from './qualifications';
import { fetchLicensesSuccess } from './licenses';
import { fetchMedicalsSuccess } from './medicals';
import { fetchExternalCoursesSuccess } from './externalCourses';
import { fetchComplementaryCertificatesSuccess } from './complementaryCertificates';
import { fetchPersonalFilesSuccess } from './personalFiles';
import { fetchUserCompetenciesSuccess } from './userCompetencies';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_USERS_CATEGORIES_REQUEST = 'GET_USERS_CATEGORIES_REQUEST';
export const GET_USERS_CATEGORIES_SUCCESS = 'GET_USERS_CATEGORIES_SUCCESS';
export const GET_USERS_CATEGORIES_FAILURE = 'GET_USERS_CATEGORIES_FAILURE';

export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const CLEAN_USER_PROFILE_DATA = 'CLEAN_USER_PROFILE_DATA';

export const CHANGE_USER_PROFILE_SUCCESS = 'CHANGE_USER_PROFILE_SUCCESS';
export const SIMPLE_CHANGE_USER_PROFILE_SUCCESS = 'SIMPLE_CHANGE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';
export const RESET_ADD_USER_SUCCESS = 'RESET_ADD_USER_SUCCESS';
export const RESET_SAVE_DETAILS_SUCCESS = 'RESET_SAVE_DETAILS_SUCCESS';

export const FETCH_USER_PERMISSIONS_REQUEST = 'FETCH_USER_PERMISSIONS_REQUEST';
export const FETCH_USER_PERMISSIONS_SUCCESS = 'FETCH_USER_PERMISSIONS_SUCCESS';


export function getUsersRequest() {
  return {
    type: GET_USERS_REQUEST
  };
}

export function getUsersCategoriesRequest() {
  return {
    type: GET_USERS_CATEGORIES_REQUEST
  };
}

export function getUsersSuccess(userList) {
  return {
    type: GET_USERS_SUCCESS,
    payload: {
      userList
    }
  };
}

export function getUsersCategoriesSuccess(usersCategoriesList) {
  return {
    type: GET_USERS_CATEGORIES_SUCCESS,
    payload: {
      usersCategoriesList
    }
  };
}

export function fetchUserPermissionsRequest() {
  return {
    type: FETCH_USER_PERMISSIONS_REQUEST
  };
}

export function fetchUserPermissionsSuccess(permissions) {
  return {
    type: FETCH_USER_PERMISSIONS_SUCCESS,
    payload: {
      permissions
    }
  };
}

export function addUserSuccess(user) {
  return {
    type: ADD_USER_SUCCESS,
    payload: {
      user
    }
  };
}

export function removeUserSuccess(userId) {
  return {
    type: REMOVE_USER_SUCCESS,
    payload: {
      userId
    }
  };
}

export function getUsersFailure() {
  return {
    type: GET_USERS_FAILURE,
  };
}

export function getUsersCategoriesFailure() {
  return {
    type: GET_USERS_CATEGORIES_FAILURE,
  };
}

export function getUserProfileRequest() {
  return {
    type: GET_USER_PROFILE_REQUEST
  };
}

export function getUserProfileSuccess(userDetails) {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    payload: {
      userDetails
    }
  };
}

export function cleanUserProfile() {
  return {
    type: CLEAN_USER_PROFILE_DATA,
  };
}

export function resetAddUserSuccess() {
  return {
    type: RESET_ADD_USER_SUCCESS,
  };
}

export function updateUserProfileSuccess(userProfile) {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: userProfile,
  };
}

export function resetSaveDetailsSuccess() {
  return {
    type: RESET_SAVE_DETAILS_SUCCESS,
  };
}

export function resetSaveDetailsFlag() {
  return (dispatch) => dispatch(resetSaveDetailsSuccess());
}

export function fetchUsers(sortingData) {
  return (dispatch) => {
    dispatch(getUsersRequest());

    let queryParameters = {};
    let queryParametersFilter = '';

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData && !_isEmpty(sortingData.columnToSort)) {
      const parsedColumnName = _snakeCase(sortingData.columnToSort);

      queryParameters.ordering = `${(sortingData.sortAsc ? '' : '-')}${parsedColumnName}`;
    }

    if (sortingData && !_isEmpty(sortingData.filter)) {
      queryParametersFilter = sortingData.filter.map((filter) => {
        return `${filter.tag}=${encodeURIComponent(filter.name)}`;
      }).join('&');
    }

    queryParameters = `${qs.stringify(queryParameters)}&${queryParametersFilter}`;
    queryParameters = _isEmpty(queryParameters) ? '' : `?${queryParameters}`;

    return axios.get(urlJoin(URLS.users, queryParameters))
      .then(({ data }) => dispatch(getUsersSuccess(data.results)))
      .catch((error) => {
        dispatch(getUsersFailure());
        dispatch(addError(`Error during users fetching ${error.message}`, error));
      });
  };
}

export function addUser(userData) {
  return (dispatch) => {
    const { email } = userData;

    return axios.post(URLS.users, userData)
      .then(({ data }) => dispatch(addUserSuccess(data)))
      .then(() => axios.post(URLS.passwordSet, { email }))
      .catch((error) => {
        dispatch(addError(`Error during user adding ${error.message}`, error));
      });
  };
}

export function removeUser(userId) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.users, userId))
      .then(() => dispatch(removeUserSuccess(userId)))
      .catch((error) => {
        dispatch(addError(`Error during user removing ${error.message}`, error));
      });
  };
}

export function getUserProfileFailure() {
  return {
    type: GET_USER_PROFILE_FAILURE,
  };
}

export function fetchUserProfile(userId) {
  return (dispatch) => {
    dispatch(getUserProfileRequest());

    return axios.get(urlJoin(URLS.users, userId))
      .then(({ data }) => {
        dispatch(getUserProfileSuccess(data));
        dispatch(fetchRatingsSuccess(data.ratings));
        dispatch(fetchQualificationsSuccess(data.qualifications));
        dispatch(fetchLicensesSuccess(data.licenses));
        dispatch(fetchMedicalsSuccess(data.medicals));
        dispatch(fetchExternalCoursesSuccess(data.externalCourses));
        dispatch(fetchPersonalFilesSuccess(data.personalFiles));
        dispatch(fetchComplementaryCertificatesSuccess(data.complementaryCertificates));
        dispatch(fetchUserCompetenciesSuccess(data.competencies));
      })
      .catch((error) => {
        dispatch(getUserProfileFailure());
        dispatch(addError(`Error during user details fetching ${error.message}`, error));
      });
  };
}

export function cleanUserProfileData() {
  return (dispatch) => dispatch(cleanUserProfile());
}

export function editUserProfileSuccess(userDetails) {
  return {
    type: CHANGE_USER_PROFILE_SUCCESS,
    payload: userDetails,
  };
}

export function simpleEditUserProfileSuccess(userDetails) {
  return {
    type: SIMPLE_CHANGE_USER_PROFILE_SUCCESS,
    payload: userDetails,
  };
}

export function saveUserDetails(id, userDetails, myProfile = false) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.usersProfile, id), userDetails)
      .then(({ data }) => {
        if (myProfile) {
          history.push('/');
        } else {
          dispatch(editUserProfileSuccess(data));
          history.push('/users');
        }
      })
      .catch((error) => {
        dispatch(addError(`Error during user profile editing ${error.message}`, error));
      });
  };
}

export function resetAddUserFlag() {
  return (dispatch) => dispatch(resetAddUserSuccess());
}

export function fetchUserPermissions(id) {
  return (dispatch) => {
    return axios.get(urlJoin(URLS.userPermissions, id))
      .then(({ data }) => dispatch(fetchUserPermissionsSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during user permissions fetching ${error.message}`, error));
      });
  };
}

export function editUsersProfile(id, userDetails) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.usersProfileSimple, id), userDetails)
      .then(({ data }) => {
        dispatch(simpleEditUserProfileSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during user profile editing ${error.message}`, error));
      });
  };
}

export function fetchUsersCategories(sortingData) {
  return (dispatch) => {
    dispatch(getUsersCategoriesRequest());

    let queryParameters = {};
    let queryParametersFilter = '';

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData && !_isEmpty(sortingData.filter)) {
      queryParametersFilter = sortingData.filter.map((filter) => {
        return `${'user_competencies'}=${encodeURIComponent(filter)}`;
      }).join('&');
    }

    queryParameters = `${qs.stringify(queryParameters)}&${queryParametersFilter}`;
    queryParameters = _isEmpty(queryParameters) ? '' : `?${queryParameters}`;

    return axios.get(urlJoin(URLS.usersCategoriesFilter, queryParameters))
      .then(({ data }) => dispatch(getUsersCategoriesSuccess(data.results)))
      .catch((error) => {
        dispatch(getUsersCategoriesFailure());
        dispatch(addError(`Error during users fetching ${error.message}`, error));
      });
  };
}
