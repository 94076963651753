import advertImage from './advertImage';
import aircrafts from './aircrafts';
import assignedCourses from './assignedCourses';
import assignedTests from './assignedTests';
import authentication from './authentication';
import bookingObjectsReducer from './bookingObjects';
import bookings from './bookings';
import { combineReducers } from 'redux';
import competencies from './competencies';
import complementaryCertificates from './complementaryCertificates';
import courses from './courses';
import defaultNotificationRecipients from './defaultNotificationRecipients';
import digitalSignature from './digitalSignature';
import documentTypes from './documentTypes';
import documents from './documents';
import eLearning from './eLearning';
import equipment from './equipment';
import errors from './errors';
import examCompetencies from './examCompetencies';
import externalCourses from './externalCourses';
import fleet from './fleet';
import flightExperience from './flightExperience';
import folders from './folders';
import gradings from './gradings';
import groups from './groups';
import historys from './historys';
import inspections from './inspections';
import installations from './installations';
import itemTypes from './itemTypes';
import items from './items';
import journeyLog from './journeyLog';
import lessonTypes from './lessonTypes';
import licenses from './licenses';
import locations from './locations';
import medicals from './medicals';
import modules from './modules';
import notificationCategories from './notificationCategories';
import notificationRecipients from './notificationRecipients';
import partStatuses from './partStatuses';
import parts from './parts';
import personalFiles from './personalFiles';
import practicalFields from './practicalFields';
import qualifications from './qualifications';
import questionCategories from './questionCategories';
import questionTypes from './questionTypes';
import questions from './questions';
import ratings from './ratings';
import reportCategories from './reportCategories';
import reportFleetCategories from './reportFleetCategories';
import reportRecipents from './reportRecipents';
import reports from './reports';
import riskAssessments from './riskAssessments';
import { routerReducer } from 'react-router-redux';
import rssNews from './rssNews';
import schoolLogo from './schoolLogo';
import services from './services';
import settings from './settings';
import staffCategories from './staffCategories';
import tests from './tests';
import types from './types';
import userCompetencies from './userCompetencies';
import userSettings from './userSettings';
import users from './users';
import vehicleGroupUsers from './vehicleGroupUsers';
import vehicleGroups from './vehicleGroups';
import vehicleTypes from './vehicleTypes';
import vehicles from './vehicles';
import warehouse from './warehouse';
import warehouseConsumables from './warehouseConsumables';

export default combineReducers({
  routing: routerReducer,
  authentication,
  groups,
  users,
  errors,
  itemTypes,
  aircrafts,
  locations,
  questionCategories,
  gradings,
  settings,
  modules,
  questionTypes,
  practicalFields,
  ratings,
  courses,
  items,
  lessonTypes,
  assignedCourses,
  externalCourses,
  questions,
  tests,
  assignedTests,
  licenses,
  medicals,
  personalFiles,
  qualifications,
  schoolLogo,
  digitalSignature,
  bookings,
  bookingObjects: bookingObjectsReducer,
  reportRecipents,
  reportFleetCategories,
  reportCategories,
  reports,
  flightExperience,
  journeyLog,
  userSettings,
  types,
  equipment,
  documentTypes,
  folders,
  notificationCategories,
  notificationRecipients,
  defaultNotificationRecipients,
  eLearning,
  vehicleTypes,
  complementaryCertificates,
  advertImage,
  vehicles,
  rssNews,
  competencies,
  staffCategories,
  userCompetencies,
  examCompetencies,
  fleet,
  vehicleGroups,
  vehicleGroupUsers,
  parts,
  warehouse,
  warehouseConsumables,
  documents,
  inspections,
  services,
  installations,
  partStatuses,
  historys,
  riskAssessments,
});
