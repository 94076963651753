import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import Table from '../../../components/Table';
import { TABLE_HEADER } from './equipmentConstants';
import RemoveDialog from '../../../components/RemoveDialog';


@withNamespaces()
export default class EquipmentList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    equipmentList: PropTypes.array.isRequired,
    handleSort: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    removeEquipment: PropTypes.func.isRequired,
    openRemoveDialog: PropTypes.func.isRequired,
    closeRemoveDialog: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    columnToSort: PropTypes.string.isRequired,
    sortAsc: PropTypes.bool.isRequired,
    removeDialogOpen: PropTypes.bool
  };

  state = {
    searchSettings: {
      columnToSort: _get(this.props, 'columnToSort', ''),
      sortAsc: _get(this.props, 'sortAsc', ''),
      search: _get(this.props, 'search', ''),
    }
  };

  openRemoveDialog = (equipmentId) => {
    this.props.openRemoveDialog(equipmentId);
  };

  closeRemoveDialog = () => {
    this.props.closeRemoveDialog();
  };

  handleSort = (column) => {
    this.props.handleSort(column);
  };

  handleSearch = (search) => {
    this.props.handleSearch(search);
  };

  removeEquipment = () => {
    this.props.removeEquipment();

    this.closeRemoveDialog();
  };

  render() {
    const { t, equipmentList, removeDialogOpen, search, columnToSort, sortAsc } = this.props;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center equipment">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">
                category
              </Icon>
              <h1>{t('equipment:name')}</h1>
            </span>
            <p>{t('equipment:description')}</p>
          </header>
        </div>
        <div className="col-auto add-equipment">
          <Link to="/equipment/new">
            <Button color="primary">
              <Icon color="primary" className="mr-3">playlist_add</Icon>
              {t('equipment:addEquipmentButton')}
            </Button>
          </Link>
        </div>
        <div className="col-12">
          <Table
            data={equipmentList}
            header={TABLE_HEADER}
            handleSort={this.handleSort}
            sortAsc={sortAsc}
            columnToSort={columnToSort}
            handleSearch={this.handleSearch}
            search={search}
            searchLabel={t('input:searchLabel')}
          />
          <Dialog
            open={removeDialogOpen}
            onClose={this.closeRemoveDialog}
            aria-labelledby="remove-dialog"
            fullWidth
          >
            <RemoveDialog
              message={t('equipment:removeEquipment')}
              closeRemoveDialog={this.closeRemoveDialog}
              dialogTitle={t('equipment:removeEquipmentDialogTitle')}
              removeFunction={this.removeEquipment}
            />
          </Dialog>
        </div>
      </div>
    );
  }
}
