import axios from 'axios';

import _get from 'lodash/get';

import { addError } from './errors';
import { URLS } from '../../constants';
import { urlJoin } from '../../utils';

export const FETCH_METAR_REQUEST = 'FETCH_METAR_REQUEST';
export const FETCH_METAR_SUCCESS = 'FETCH_METAR_SUCCESS';
export const FETCH_METAR_FAILURE = 'FETCH_METAR_FAILURE';

export const FETCH_TAF_REQUEST = 'FETCH_TAF_REQUEST';
export const FETCH_TAF_SUCCESS = 'FETCH_TAF_SUCCESS';
export const FETCH_TAF_FAILURE = 'FETCH_TAF_FAILURE';


export function fetchMetarRequest() {
  return {
    type: FETCH_METAR_REQUEST
  };
}

export function fetchMetarSuccess(metar, location) {
  return {
    type: FETCH_METAR_SUCCESS,
    payload: {
      metar: _get(metar, 'data.response.data.METAR[0]', []),
      location
    }
  };
}

export function fetchMetarFailure() {
  return {
    type: FETCH_METAR_FAILURE
  };
}

export function fetchTafRequest() {
  return {
    type: FETCH_TAF_REQUEST
  };
}

export function fetchTafSuccess(taf, location) {
  return {
    type: FETCH_TAF_SUCCESS,
    payload: {
      taf: _get(taf, 'data.response.data.TAF[0]', []),
      location
    }
  };
}

export function fetchTafFailure() {
  return {
    type: FETCH_TAF_FAILURE
  };
}


export function fetchMetar(queryParameters) {
  return (dispatch) => {
    dispatch(fetchMetarRequest());

    return axios.get(urlJoin(URLS.aviationWeatherServer, queryParameters.dataSource, queryParameters.stationString))
      .then((data) => {
        return dispatch(fetchMetarSuccess(data, queryParameters.location));
      })
      .catch((error) => {
        dispatch(fetchMetarFailure());
        dispatch(addError(`Error during metar fetching ${error.message}`, error));
      });
  };
}

export function fetchTaf(queryParameters) {
  return (dispatch) => {
    dispatch(fetchTafRequest());

    return axios.get(urlJoin(URLS.aviationWeatherServer, queryParameters.dataSource, queryParameters.stationString))
      .then((data) => {
        return dispatch(fetchTafSuccess(data, queryParameters.location));
      })
      .catch((error) => {
        dispatch(fetchTafFailure());
        dispatch(addError(`Error during taf fetching ${error.message}`, error));
      });
  };
}
