import React, { Component } from 'react';
import {
  getDateFormat,
  reformatPickerDateFromDB,
} from '../../../../utils/time';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DateTimePicker from '../../../../../common/components/forms/DateTimePicker';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Form from '../../../../../common/components/forms/Form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialInput from '../../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import validators from '../../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class Page2 extends Component {
  static propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    isFirstPage: PropTypes.func.isRequired,
    isLastPage: PropTypes.func.isRequired,
    saveComplementaryCourse: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    holderDateOfBirth: reformatPickerDateFromDB(
      this.props.defaultSettings.holderDateOfBirth,
    ),
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  onFormValidated = (isFormValid) => {
    const { changePage } = this.props;
    const formData = Object.keys(this.formData).reduce((result, keyName) => {
      result[keyName] = this.formData[keyName].value;

      return result;
    }, {});
    const state = this.state || {};

    if (isFormValid && this.formData) {
      changePage(this.page, { ...formData, ...state });
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e, page) => {
    e.preventDefault();
    e.stopPropagation();

    this.page = page;
    this.triggerFormValidation();
  };

  render() {
    const {
      t,
      defaultSettings,
      handleDialogClose,
      isFirstPage,
      isLastPage,
      saveComplementaryCourse,
    } = this.props;
    const { holderDateOfBirth } = this.state;

    return (
      <Form
        onChange={this.onChange}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <h4 className="text-center">
                {t('complementaryCertificate:employerSection')}
              </h4>
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="legalName"
                label={t('input:legalName')}
                validators={[new validators.MaxLength(t, 58)]}
                defaultValue={defaultSettings.legalName}
                fullWidth
              />
            </div>
            <div className="col-6">
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={defaultSettings.railwayUndertaking}
                      onChange={this.handleCheckboxChange('railwayUndertaking')}
                    />
                  }
                  label={t('input:railwayUndertaking')}
                />
              </FormControl>
            </div>
            <div className="col-6">
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={defaultSettings.infrastructureManager}
                      onChange={this.handleCheckboxChange(
                        'infrastructureManager',
                      )}
                    />
                  }
                  label={t('input:infrastructureManager')}
                />
              </FormControl>
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="workingPlace"
                label={t('input:workingPlace')}
                validators={[new validators.MaxLength(t, 58)]}
                defaultValue={defaultSettings.workingPlace}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="workingPlacePostalAddress"
                label={t('input:workingPlacePostalAddress')}
                validators={[new validators.MaxLength(t, 58)]}
                defaultValue={defaultSettings.workingPlacePostalAddress}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="workingPlaceTownCountry"
                label={t('input:workingPlaceTownCountry')}
                validators={[new validators.MaxLength(t, 58)]}
                defaultValue={defaultSettings.workingPlaceTownCountry}
                fullWidth
              />
            </div>
            <div className="col-12">
              <h4 className="text-center">
                {t('complementaryCertificate:holderSection')}
              </h4>
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="holderPlaceOfBirth"
                label={t('input:holderPlaceOfBirth')}
                validators={[new validators.MaxLength(t, 58)]}
                defaultValue={defaultSettings.holderPlaceOfBirth}
                fullWidth
              />
            </div>
            <div className="col-12">
              <DateTimePicker
                value={holderDateOfBirth}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'holderDateOfBirth',
                )}
                dateFormat={getDateFormat()}
                name="holderDateOfBirth"
                label={t('input:holderDateOfBirth')}
                acceptEmptyInput
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="holderNationality"
                label={t('input:holderNationality')}
                validators={[new validators.MaxLength(t, 58)]}
                defaultValue={defaultSettings.holderNationality}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="holderPostalAddress"
                label={t('input:holderPostalAddress')}
                validators={[new validators.MaxLength(t, 58)]}
                defaultValue={defaultSettings.holderPostalAddress}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="holderTown"
                label={t('input:holderTown')}
                validators={[new validators.MaxLength(t, 30)]}
                defaultValue={defaultSettings.holderTown}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="holderTownCountry"
                label={t('input:holderTownCountry')}
                validators={[new validators.MaxLength(t, 30)]}
                defaultValue={defaultSettings.holderTownCountry}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {!isFirstPage() ? (
            <Button onClick={(e) => this.sendHandler(e, -1)}>
              {t('buttonPrevious')}
            </Button>
          ) : (
            <div />
          )}
          {!isLastPage() ? (
            <Button onClick={(e) => this.sendHandler(e, 1)}>
              {t('buttonNext')}
            </Button>
          ) : (
            <div />
          )}
          <Button color="secondary" onClick={handleDialogClose}>
            {t('buttonCancel')}
          </Button>
          {isLastPage() ? (
            <Button color="primary" onClick={saveComplementaryCourse}>
              {t('buttonSave')}
            </Button>
          ) : (
            <div />
          )}
        </DialogActions>
      </Form>
    );
  }
}
