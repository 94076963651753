import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import Table from '../../../../common/components/Table';
import { TABLE_HEADER } from './warehouseConstants';
import RemoveDialog from '../../../../common/components/RemoveDialog';


@withNamespaces()
export default class WarehouseList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    warehouseList: PropTypes.array.isRequired,
    handleSort: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    removeWarehouse: PropTypes.func.isRequired,
    openRemoveDialog: PropTypes.func.isRequired,
    closeRemoveDialog: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    columnToSort: PropTypes.string.isRequired,
    sortAsc: PropTypes.bool.isRequired,
    removeDialogOpen: PropTypes.bool
  };

  state = {
    searchSettings: {
      columnToSort: _get(this.props, 'columnToSort', ''),
      sortAsc: _get(this.props, 'sortAsc', ''),
      search: _get(this.props, 'search', ''),
    }
  };

  removeWarehouse = () => {
    const { removeWarehouse, closeRemoveDialog } = this.props;

    removeWarehouse();
    closeRemoveDialog();
  };

  render() {
    const {
      t, warehouseList, removeDialogOpen, search, columnToSort, sortAsc,
      closeRemoveDialog, handleSearch, handleSort
    } = this.props;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center warehouse">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">
                store
              </Icon>
              <h1>{t('warehouse:name')}</h1>
            </span>
            <p>{t('warehouse:description')}</p>
          </header>
        </div>
        <div className="col-auto add-warehouse">
          <Link to="/warehouse/new">
            <Button color="primary">
              <Icon color="primary" className="mr-3">playlist_add</Icon>
              {t('warehouse:addWarehouseButton')}
            </Button>
          </Link>
        </div>
        <div className="col-12">
          <Table
            data={warehouseList}
            header={TABLE_HEADER}
            handleSort={handleSort}
            sortAsc={sortAsc}
            columnToSort={columnToSort}
            handleSearch={handleSearch}
            search={search}
            searchLabel={t('input:searchLabel')}
          />
          <Dialog
            open={removeDialogOpen}
            onClose={closeRemoveDialog}
            aria-labelledby="remove-dialog"
            fullWidth
          >
            <RemoveDialog
              message={t('warehouse:removeWarehouse')}
              closeRemoveDialog={closeRemoveDialog}
              dialogTitle={t('warehouse:removeWarehouseDialogTitle')}
              removeFunction={this.removeWarehouse}
            />
          </Dialog>
        </div>
      </div>
    );
  }
}
