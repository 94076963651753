import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { withNamespaces } from 'react-i18next';

import Icon from '@material-ui/core/Icon';

import { DRAG_TYPES } from '../../../../constants';
import ItemSortable from './ItemSortable';

import './styles.scss';

const lessonTarget = {
  canDrop(props) {
    return !props.preview;
  },
  drop(props, monitor) {
    props.dropMoveItem(props.lesson, monitor.getItem());
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

@withNamespaces()
@DropTarget(DRAG_TYPES.item, lessonTarget, collect)
export default class LessonsManager extends Component {
  static propTypes = {
    lesson: PropTypes.object.isRequired,
    dropMoveItem: PropTypes.func.isRequired,
    dropRemoveItem: PropTypes.func.isRequired,
    moveItemSortable: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    preview: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  renderDropArea = (preview, t) => {
    if (preview) {
      return null;
    }

    return (
      <div className="m-3 row justify-content-center align-items-center drop-item">
        <div className="col-auto">
          {t('course:dropItems')}
        </div>
        <div className="col-auto py-2">
          <Icon color="primary">file_download</Icon>
        </div>
      </div>
    );
  }

  render() {
    const { lesson, connectDropTarget, preview, moveItemSortable, dropRemoveItem, t } = this.props;

    return connectDropTarget(
      <div>
        {
          lesson.items.map((item, index) => {
            return (
              <ItemSortable
                key={`item-${lesson.uniqueId}-${item.id}-${item.uniqueId}`}
                index={index}
                item={item}
                preview={preview}
                lessonId={lesson.uniqueId}
                moveItemSortable={moveItemSortable}
                dropRemoveItem={dropRemoveItem}
              />
            );
          })
        }
        {this.renderDropArea(preview, t)}
      </div>
    );
  }
}
