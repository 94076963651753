import React, { Component } from 'react';

import LibraryList from './LibraryList';

export default class QrosscheckLibraryList extends Component {
  render() {
    const query = new URLSearchParams(this.props.location.search);

    return <LibraryList preselectFolder={query.get('folderId')} />;
  }
}
