import { PRACTICAL_LESSON_DATETIME_FIELDS, ZERO_TIME } from '../../../../../constants';
import React, { Component } from 'react';

import AircraftList from './AircraftList';
import AutoSuggestion from '../../../../../common/components/AutoSuggestion';
import Datetime from './Datetime';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import Text from './Text';
import Time from './Time';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { reformatPickerDateTimeFromDB } from '../../../../utils/time';
import { toLowercase } from '../../../../../utils';

export default class PracticalLesson extends Component {
  static propTypes = {
    practicalFieldList: PropTypes.array.isRequired,
    locationList: PropTypes.array.isRequired,
    aircraftList: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleDatePickerChange: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    practicalLessonData: PropTypes.object.isRequired,
    preview: PropTypes.bool,
    handleRegisterComponentForm: PropTypes.func.isRequired,
    handleFormValidated: PropTypes.func.isRequired,
    isAircraftSimulatorSelected: PropTypes.bool.isRequired
  };

  dateQuestion = (props) => {
    const { practicalFieldList, practicalLessonData } = this.props;
    const field = _find(practicalFieldList, { name: props.pickerName });
    const label = toLowercase(_get(field, 'name', ''));
    const value = reformatPickerDateTimeFromDB(_get(practicalLessonData, props.preparedFileName, null));

    return <Datetime {...props} label={label} value={value} />;
  };

  timeQuestion = (props) => {
    const totalBlock = this.props.practicalLessonData['total block'] || ZERO_TIME;

    return <Time {...props} renderFillInButton totalBlock={totalBlock} />;
  };

  numberQuestion = (props) => {
    return <Text type="number" {...props} />;
  };

  textQuestion = (props) => {
    return <Text {...props} />;
  };

  listQuestion = (props) => {
    const { aircraftList, locationList } = this.props;

    if (toLowercase(props.label) === 'aircraft') {
      return <AircraftList {...props} aircraftList={aircraftList} />;
    }

    return <AutoSuggestion {...props} typeList={locationList} fullWidth />;
  };

  renderQuestion(field, disabled = false, lowerCase = true, customFieldName) {
    const { handleChange, handleSelectChange, handleCheckboxChange, preview, practicalLessonData,
      handleRegisterComponentForm, handleFormValidated } = this.props;
    const questionTypes = {
      'D': this.dateQuestion,
      'TXT': this.textQuestion,
      'L': this.listQuestion,
      'T': this.timeQuestion,
      'NO': this.numberQuestion,
    };

    if (!field) {
      return null;
    }

    const fieldName = customFieldName? customFieldName : (lowerCase ? toLowercase(field.name) : field.name);
    const questionTypeShortname = field.questionType.shortname;
    let props = {
      label: fieldName,
      inputName: fieldName.replace(' ', '_'),
      defaultValue: this.getDefaultValue(practicalLessonData, customFieldName ? customFieldName : toLowercase(fieldName), questionTypeShortname),
      handleChange,
      handleSelectChange,
      handleCheckboxChange,
      preview: preview || disabled,
    };

    if (this.isTimeInput(questionTypeShortname)) {
      props = {
        ...props,
        registerComponentForm: handleRegisterComponentForm(`PracticalLesson-${fieldName.replace(' ', '_')}`),
        formValidated: handleFormValidated
      };
    }
    return questionTypes[questionTypeShortname](props) || null;
  }

  renderBlockOff = () => {
    const { preview, handleDatePickerChange } = this.props;

    const props = {
      handleDatePickerChange,
      pickerName: 'Block off',
      preparedFileName: PRACTICAL_LESSON_DATETIME_FIELDS.blockOff,
      disabled: preview,
    };

    return this.dateQuestion(props);
  };

  renderTakeOff = () => {
    const { preview, practicalLessonData, handleDatePickerChange } = this.props;
    const blockOffValue = reformatPickerDateTimeFromDB(_get(practicalLessonData, PRACTICAL_LESSON_DATETIME_FIELDS.blockOff, null));
    const takeOffValue = reformatPickerDateTimeFromDB(_get(practicalLessonData, PRACTICAL_LESSON_DATETIME_FIELDS.takeOff, null));

    const minTime = takeOffValue
      ? takeOffValue.isSame(blockOffValue, 'day') ? blockOffValue : null
      : blockOffValue;

    const props = {
      handleDatePickerChange,
      pickerName: 'Take off',
      preparedFileName: PRACTICAL_LESSON_DATETIME_FIELDS.takeOff,
      disabled: preview || !blockOffValue,
      minDate: blockOffValue,
      minTime,
    };

    return this.dateQuestion(props);
  };

  renderLanding = () => {
    const { preview, practicalLessonData, handleDatePickerChange } = this.props;
    const takeOffValue = reformatPickerDateTimeFromDB(_get(practicalLessonData, PRACTICAL_LESSON_DATETIME_FIELDS.takeOff, null));
    const landingValue = reformatPickerDateTimeFromDB(_get(practicalLessonData, PRACTICAL_LESSON_DATETIME_FIELDS.landing, null));
    const minTime = landingValue
      ? landingValue.isSame(takeOffValue, 'day') ? takeOffValue : null
      : takeOffValue;

    const props = {
      handleDatePickerChange,
      pickerName: 'Landing',
      preparedFileName: PRACTICAL_LESSON_DATETIME_FIELDS.landing,
      disabled: preview || !takeOffValue,
      minDate: takeOffValue,
      minTime,
    };

    return this.dateQuestion(props);
  };

  renderBlockOn = () => {
    const { preview, practicalLessonData, handleDatePickerChange, isAircraftSimulatorSelected } = this.props;
    let subsidiaryValue = reformatPickerDateTimeFromDB(_get(practicalLessonData, PRACTICAL_LESSON_DATETIME_FIELDS.landing, null));

    if (isAircraftSimulatorSelected) {
      subsidiaryValue = reformatPickerDateTimeFromDB(_get(practicalLessonData, PRACTICAL_LESSON_DATETIME_FIELDS.blockOff, null));
    }

    const blockOnValue = reformatPickerDateTimeFromDB(_get(practicalLessonData, PRACTICAL_LESSON_DATETIME_FIELDS.blockOn, null));
    const minTime = blockOnValue
      ? blockOnValue.isSame(subsidiaryValue, 'day') ? subsidiaryValue : null
      : subsidiaryValue;

    const props = {
      practicalLessonData,
      handleDatePickerChange,
      pickerName: 'Block on',
      preparedFileName: PRACTICAL_LESSON_DATETIME_FIELDS.blockOn,
      disabled: preview || !subsidiaryValue,
      minDate: subsidiaryValue,
      minTime,
    };

    return this.dateQuestion(props);
  };

  isTimeInput = (type) => {
    return type === 'T';
  };

  getDefaultValue = (practicalLessonData, fieldName, type) => {
    if (this.isTimeInput(type) || fieldName === 'total block' || fieldName === 'total flight') {
      return _get(practicalLessonData, fieldName, ZERO_TIME);
    } else if (fieldName.toLowerCase() === 'departure' || fieldName.toLowerCase() === 'arrival') {
      return practicalLessonData[fieldName] || '';
    }

    return _get(practicalLessonData, fieldName, '');
  };

  findPracticalField = (searchedFieldName) => {
    const { practicalFieldList } = this.props;

    return _find(practicalFieldList, { name: searchedFieldName });
  };

  renderFieldsForSimulator = () => {
    const { practicalFieldList } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Aircraft' })) }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.findPracticalField('Block off') && this.renderBlockOff() }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.findPracticalField('Block on') && this.renderBlockOn() }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Total block' }), true) }
          </div>
          <div className="col-12">
            <Divider className="my-3" />
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'PIC' }), false, false) }
          </div>
        </div>
      </div>
    );
  };

  renderFieldsForHelicopterORAirplane = () => {
    const { practicalFieldList } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Aircraft' })) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6" />
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Departure' })) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Arrival' })) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.findPracticalField('Block off') && this.renderBlockOff() }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.findPracticalField('Take off') && this.renderTakeOff() }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.findPracticalField('Block on') && this.renderBlockOn() }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.findPracticalField('Landing') && this.renderLanding() }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Total block' }), true) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Total flight' }), true) }
          </div>
          <div className="col-12">
            <Divider className="my-3" />
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'VFR' }), false, false) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'IFR' }), false, false) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'PIC' }), false, false) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Dual' })) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'SIC' }), false, false) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'X-Country' }), false, false, 'xCountry') }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Day landings' })) }
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            { this.renderQuestion(_find(practicalFieldList, { name: 'Night landings' })) }
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isAircraftSimulatorSelected } = this.props;

    return isAircraftSimulatorSelected ? this.renderFieldsForSimulator() : this.renderFieldsForHelicopterORAirplane();
  }
}
