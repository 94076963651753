import { ALLOWED_FILES_EXT, MAX_FILE_SIZE } from '../../../constants';
import React, { Component } from 'react';
import { bytesToMegaBYTES, toLowercase } from '../../../utils';

import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  fileInput: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    height: '100%',
    width: 'inherit',
  },
};

class FileInput extends Component {
  static propTypes = {
    file: PropTypes.object,
    required: PropTypes.bool,
    isEmail: PropTypes.bool,
    attachFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    stopPropagation: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    validationResults: '',
    file: this.props.file,
    isDirty: false,
    maxFileSize: MAX_FILE_SIZE,
  };

  getIncorrectFileFormatError() {
    const { t } = this.props;

    return t('error:incorrectFileFormat');
  }

  hasExtension(fileName, exts) {
    const { addSimpleError } = this.props;
    const fileNameExt = toLowercase(fileName).split('.').pop();
    const isExtentionCorrect = exts.includes(fileNameExt);

    if (!isExtentionCorrect) {
      const errorMessage = this.getIncorrectFileFormatError();

      addSimpleError(errorMessage);
    }

    return isExtentionCorrect;
  }

  avaliableExtentions() {
    return ALLOWED_FILES_EXT;
  }

  getFileSizeToBigError(megaBYTESSize) {
    const { t } = this.props;

    return `${t('error:fileSizeToBig')} ${megaBYTESSize} MB`;
  }

  isSizeCorrect(size) {
    const { addSimpleError } = this.props;
    const { maxFileSize } = this.state;
    const isSizeCorrect = size <= maxFileSize;
    const megaBYTESSize = bytesToMegaBYTES(maxFileSize);

    if (!isSizeCorrect) {
      const errorMessage = this.getFileSizeToBigError(megaBYTESSize);

      addSimpleError(errorMessage);
    }

    return isSizeCorrect;
  }

  isDimentionCorrect() {
    // eslint-disable-next-line promise/avoid-new
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  async validate(file) {
    const { required } = this.props;
    const { isDirty } = this.state;
    let validationResults = this.state;

    if (!isDirty) {
      return true;
    }

    if (required) {
      const isDimentionCorrect = await this.isDimentionCorrect(file);

      if (
        isNil(file) ||
        !this.hasExtension(file.name, this.avaliableExtentions()) ||
        !this.isSizeCorrect(file.size) ||
        !isDimentionCorrect
      ) {
        validationResults = 'upload';
      } else {
        validationResults = '';
      }

      this.setState({ validationResults });
    }

    return isEmpty(validationResults);
  }

  createOnChangeFileHandler() {
    const { attachFile } = this.props;

    return async (event) => {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      this.setState({ isDirty: true, file });

      if (await this.validate(file)) {
        attachFile(file);
      }
    };
  }

  createOnBlurHandler() {
    return (e) => {
      if (this.props.stopPropagation) {
        e.stopPropagation();
      }

      this.setState({ isDirty: true });
      this.validate();
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <input
        className={classes.fileInput}
        id="button-file"
        type="file"
        onClick={this.createOnBlurHandler()}
        onChange={this.createOnChangeFileHandler()}
      />
    );
  }
}

export default compose(withNamespaces(), withStyles(styles))(FileInput);
export { FileInput as FileInputWithoutStyles };
