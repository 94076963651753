import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Question from './Question';
import { answerLetters } from '../../../../utils/index';

export default class QuestionList extends Component {
  static propTypes = {
    questionList: PropTypes.array.isRequired,
    saveSelectedAnswer: PropTypes.func,
    completedTestPreviewMode: PropTypes.bool.isRequired
  };

  renderQuestion = (question) => {
    return (
      <div key={`question-${question.id}`}>
        <Question
          question={this.prepareQuestion(question)}
          selectAnswer={this.selectAnswer}
          completedTestPreviewMode={this.props.completedTestPreviewMode}
        />
      </div>
    );
  };

  selectAnswer = (questionId, selectedAnswer) => {
    this.props.saveSelectedAnswer(questionId, selectedAnswer);
  };

  prepareAnswer = (question, questionOrder, selector) => {
    return _get(question.answers.find((answer) => answer.order === questionOrder), selector, '');
  };

  prepareQuestion = (question) => {
    if (_isEmpty(question)) {
      return question;
    }

    return {
      questionName: _get(question, 'name', ''),
      files: _get(question, 'files', []),
      answered: _get(question.answers.find((answer) => answer.isAnswered === true), 'id', ''),
      answers: answerLetters.reduce((answers, anwser, index) => {
        answers[anwser] = {
          id: this.prepareAnswer(question, index + 1, 'id'),
          name: this.prepareAnswer(question, index + 1, 'name'),
          color: this.markAnswer(question, index + 1)
        };

        return answers;
      }, {}),
      id: _get(question, 'id', ''),
    };
  };

  markAnswer = (question, answerOrder) => {
    const answer = question.answers.find((answer) => answer.order === answerOrder);

    if (answer.isCorrect && answer.isAnswered) {
      return 'answer-correct';
    } else if (!answer.isCorrect && answer.isAnswered) {
      return 'answer-wrong';
    } else if (answer.isCorrect && !answer.isAnswered) {
      return 'answer-correct';
    }

    return 'answer-another';
  };

  render() {
    const { questionList } = this.props;

    if (!questionList) {
      return null;
    }

    return (
      <div>
        {questionList.map((question) => this.renderQuestion(question))}
      </div>
    );
  }
}
