import React, { Component } from 'react';
import {
  addPersonalFile,
  deletePersonalFile,
  editPersonalFile,
} from '../../../actions/personalFiles';
import {
  attachPersonalFileFile,
  removePersonalFileFile,
} from '../../../actions/fileUpload';
import {
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import PersonalFileList from './PersonalFileList';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { getDateFormat } from '../../../utils/time';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  type: '',
  dateOfIssue: moment(),
  expiryDate: null,
  id: null,
  isFormValid: false,
};

function mapStateToProps(state) {
  return {
    personalFileList: state.personalFiles.personalFileList || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPersonalFile: (personalFile) => dispatch(addPersonalFile(personalFile)),
    removePersonalFile: (personalFile) =>
      dispatch(deletePersonalFile(personalFile)),
    editPersonalFile: (personalFile) =>
      dispatch(editPersonalFile(personalFile)),
    attachPersonalFileFile: (file, id, userId) =>
      dispatch(attachPersonalFileFile(file, id, userId)),
    removePersonalFileFile: (fileId, id) =>
      dispatch(removePersonalFileFile(fileId, id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UserPersonalFiles extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    personalFileList: PropTypes.array,
    editMode: PropTypes.bool,
    addPersonalFile: PropTypes.func.isRequired,
    removePersonalFile: PropTypes.func.isRequired,
    editPersonalFile: PropTypes.func.isRequired,
    attachPersonalFileFile: PropTypes.func.isRequired,
    removePersonalFileFile: PropTypes.func.isRequired,
    documentTypeList: PropTypes.array.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  onChange = (formData) => {
    this.formData = formData;
    _each(formData, (value, key) => {
      this.setState({
        [key]: value.value,
      });
    });
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid) {
      this.createOrUpdatePersonalFile();
    }
  };

  createOrUpdatePersonalFile = () => {
    const { type, dateOfIssue, expiryDate } = this.state;
    const { userId } = this.props;
    const newPersonalFile = {
      type,
      userId,
      dateOfIssue: reformatPickerDateToDB(dateOfIssue ? dateOfIssue : moment()),
      expiryDate: reformatPickerDateToDB(expiryDate),
    };

    if (this.state.editOpen) {
      newPersonalFile.id = this.state.id;
    }

    this.state.addOpen
      ? this.props.addPersonalFile(newPersonalFile)
      : this.props.editPersonalFile(newPersonalFile);
    this.handleDialogClose();
  };

  removePersonalFile = (personalFile) => {
    this.props.removePersonalFile(personalFile);
  };

  handleEditOpen = (personalFile) => {
    this.setState({
      editOpen: true,
      id: personalFile.id,
      type: personalFile.type,
      dateOfIssue: personalFile.dateOfIssue,
      expiryDate: personalFile.expiryDate,
    });
  };

  handleAddOpen = () => {
    this.setState({ addOpen: true });
  };

  attachPersonalFileFile = (file) => (id) => {
    const { userId } = this.props;

    this.props.attachPersonalFileFile(file, id, userId);
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  renderSelect = (inputName, inputLabel, value, changeFunction) => {
    const { documentTypeList } = this.props;

    return (
      <AutoSuggestion
        label={inputName}
        inputName={inputLabel}
        defaultValue={value ? value : ''}
        handleChange={changeFunction}
        typeList={documentTypeList}
        fullWidth
      />
    );
  };

  dialogContent = (classes, title, t) => {
    const { type, dateOfIssue, expiryDate } = this.state;

    return (
      <div>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              {this.renderSelect('type', 'type', type, this.handleSelectChange)}
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                value={reformatPickerDateFromDB(dateOfIssue)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'dateOfIssue',
                )}
                dateFormat={getDateFormat()}
                name="dateOfIssue"
                label={t('input:dateOfIssue')}
                fullWidth
              />
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                acceptEmptyInput
                value={reformatPickerDateFromDB(expiryDate)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'expiryDate',
                )}
                dateFormat={getDateFormat()}
                name="expiryDate"
                label={t('input:expiryDate')}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
      </div>
    );
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  render() {
    const {
      personalFileList,
      editMode,
      classes,
      removePersonalFileFile,
      addSimpleError,
      onElementDelete,
      t,
    } = this.props;
    const { addOpen, editOpen } = this.state;

    return (
      <div className="col-12 user">
        <PersonalFileList
          personalFileList={personalFileList}
          removePersonalFile={(personalFile) =>
            onElementDelete(() => this.removePersonalFile(personalFile))
          }
          handleAddOpen={this.handleAddOpen}
          editMode={editMode}
          handleEditOpen={this.handleEditOpen}
          attachPersonalFileFile={this.attachPersonalFileFile}
          removePersonalFileFile={(fileId, id) =>
            onElementDelete(() => removePersonalFileFile(fileId, id))
          }
          addSimpleError={addSimpleError}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={addOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="add-personal-file-dialog-title"
          >
            {this.dialogContent(classes, t('user:addPersonalFile'), t)}
            {this.dialogActions(t)}
          </Dialog>
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={editOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="edit-personal-file-dialog-title"
          >
            {this.dialogContent(classes, t('user:editPersonalFile'), t)}
            {this.dialogActions(t)}
          </Dialog>
        </Form>
      </div>
    );
  }
}
