import {
  FETCH_JOURNEY_LOGS_SUCCESS,
  FETCH_JOURNEY_LOG_SUCCESS,
  FETCH_JOURNEY_LOGS_REQUEST,
  FETCH_JOURNEY_LOGS_FAILURE,
  REMOVE_JOURNEY_LOG_SUCCESS,
  ADD_JOURNEY_LOG,
  RESET_ADD_JOURNEY_LOG_SUCCESS,
  CLEAN_JOURNEY_LOG_DATA,
  EDIT_JOURNEY_LOG
} from '../actions/journeyLog';

const INITIAL_STATE = {
  journeyLogs: [],
  journeyLog: {},
  journeyLogsRequest: false,
  addJourneyLogSuccess: false,
};

function fetchJourneyLogs(state, payload) {
  return {
    ...state,
    journeyLogs: payload.journeyLogs,
    journeyLogsRequest: false,
  };
}

function fetchJourneyLog(state, payload) {
  return {
    ...state,
    journeyLog: payload.journeyLog,
    journeyLogsRequest: false,
  };
}

function fetchJourneyLogsRequest(state) {
  return {
    ...state,
    journeyLogsRequest: true,
  };
}

function fetchJourneyLogsFailure(state) {
  return {
    ...state,
    journeyLogsRequest: false,
  };
}

function removeJourneyLogSuccess(state, payload) {
  return {
    ...state,
    journeyLogs: state.journeyLogs.filter((journeyLog) => journeyLog.id !== payload.journeyLogId)
  };
}

function addJourneyLog(state, payload) {
  return {
    ...state,
    journeyLogs: [
      ...state.journeyLogs,
      payload.journeyLog
    ],
    addJourneyLogSuccess: true,
  };
}

function resetAddJourneyLogSuccess(state) {
  return {
    ...state,
    addJourneyLogSuccess: false
  };
}

function cleanJourneyLog(state) {
  return {
    ...state,
    journeyLog: null
  };
}

function editJourneyLog(state, payload) {
  return {
    ...state,
    journeyLogs: state.journeyLogs.map((journeyLog) => {
      if (journeyLog.id === payload.journeyLog.id) {
        return payload.journeyLog;
      }

      return journeyLog;
    })
  };
}


const actions = {
  [FETCH_JOURNEY_LOGS_SUCCESS]: fetchJourneyLogs,
  [FETCH_JOURNEY_LOG_SUCCESS]: fetchJourneyLog,
  [FETCH_JOURNEY_LOGS_REQUEST]: fetchJourneyLogsRequest,
  [FETCH_JOURNEY_LOGS_FAILURE]: fetchJourneyLogsFailure,
  [REMOVE_JOURNEY_LOG_SUCCESS]: removeJourneyLogSuccess,
  [ADD_JOURNEY_LOG]: addJourneyLog,
  [RESET_ADD_JOURNEY_LOG_SUCCESS]: resetAddJourneyLogSuccess,
  [CLEAN_JOURNEY_LOG_DATA]: cleanJourneyLog,
  [EDIT_JOURNEY_LOG]: editJourneyLog,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
