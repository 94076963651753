import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import _find from 'lodash/find';

import Button from '@material-ui/core/Button';

import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import validators from '../../../../utils/validators';

import { editSetting } from '../../../actions/settings';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editSetting: (setting, errorCallback) => dispatch(editSetting(setting, errorCallback)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class CompanyInfoManager extends Component {
  static propTypes = {
    settings: PropTypes.array.isRequired,
    editSetting: PropTypes.func.isRequired,
    onInfoSuccessfullySaved: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    infoSuccessfullySaved: true,
  };

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    return _find(settings, { name: fieldName }).textValue;
  }

  onChange = (formData) => {
    this.formData = formData;
  };

  handleEditSettingError = () => {
    if (this.state.infoSuccessfullySaved) {
      this.setState({
        infoSuccessfullySaved: false,
      });
    }
  };

  onFormValidated = async (isFormValid) => {
    const { settings, editSetting } = this.props;

    this.setState({ infoSuccessfullySaved: true });

    if (isFormValid && this.formData) {
      const companyName = this.formData.companyName.value;
      const companyPostalAddress = this.formData.companyPostalAddress.value;
      const companyTownCountry = this.formData.companyTownCountry.value;
      const companyInternalReferenceNumber = this.formData.companyInternalReferenceNumber.value || '';
      const companyApproval = this.formData.companyApproval.value;
      const companyContact = this.formData.companyContact.value;
      const signatoryName = this.formData.signatoryName.value;
      const signatoryTitle = this.formData.signatoryTitle.value;
      const companyEmail = this.formData.companyEmail.value;

      const companyNamePromise = editSetting({
        id: _find(settings, { name: 'company_name' }).id,
        textValue: companyName,
      }, this.handleEditSettingError);

      const companyPostalAddressPromise = editSetting({
        id: _find(settings, { name: 'company_postal_address' }).id,
        textValue: companyPostalAddress,
      }, this.handleEditSettingError);

      const companyTownCountryPromise = editSetting({
        id: _find(settings, { name: 'company_town_country' }).id,
        textValue: companyTownCountry,
      }, this.handleEditSettingError);

      const companyInternalReferenceNumberPromise = editSetting({
        id: _find(settings, { name: 'company_internal_reference_number' }).id,
        textValue: companyInternalReferenceNumber,
      }, this.handleEditSettingError);

      const companyApprovalPromise = editSetting({
        id: _find(settings, { name: 'company_approval' }).id,
        textValue: companyApproval,
      }, this.handleEditSettingError);

      const companyContactPromise = editSetting({
        id: _find(settings, { name: 'company_contact' }).id,
        textValue: companyContact,
      }, this.handleEditSettingError);

      const signatoryNamePromise = editSetting({
        id: _find(settings, { name: 'signatory_name' }).id,
        textValue: signatoryName,
      }, this.handleEditSettingError);

      const signatoryTitlePromise = editSetting({
        id: _find(settings, { name: 'signatory_title' }).id,
        textValue: signatoryTitle,
      }, this.handleEditSettingError);

      const companyEmailPromise = editSetting({
        id: _find(settings, { name: 'company_email' }).id,
        textValue: companyEmail,
      }, this.handleEditSettingError);

      await companyNamePromise;
      await companyPostalAddressPromise;
      await companyTownCountryPromise;
      await companyInternalReferenceNumberPromise;
      await companyApprovalPromise;
      await companyContactPromise;
      await signatoryNamePromise;
      await signatoryTitlePromise;
      await companyEmailPromise;

      if (this.state.infoSuccessfullySaved) {
        this.props.onInfoSuccessfullySaved();
      }
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { t } = this.props;

    return (
      <div className="company-info-settings">
        <div className="pl-sm-5">
          <div className="ml-sm-3 my-2">
            <Form
              onChange={this.onChange}
              onFormValidated={this.onFormValidated}
              registerForm={this.registerForm}
            >
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="companyName"
                  label={t('input:companyName')}
                  defaultValue={this.setDefaultValue('company_name')}
                  validators={[
                    new validators.IsRequired(t),
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="companyPostalAddress"
                  label={t('input:companyPostalAddress')}
                  defaultValue={this.setDefaultValue('company_postal_address')}
                  validators={[
                    new validators.IsRequired(t),
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="companyTownCountry"
                  label={t('input:companyTownCountry')}
                  defaultValue={this.setDefaultValue('company_town_country')}
                  validators={[
                    new validators.IsRequired(t),
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="companyInternalReferenceNumber"
                  label={t('input:companyInternalReferenceNumber')}
                  defaultValue={this.setDefaultValue('company_internal_reference_number')}
                  validators={[]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="companyContact"
                  label={t('input:companyContact')}
                  defaultValue={this.setDefaultValue('company_contact')}
                  validators={[
                    new validators.IsRequired(t)
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="companyEmail"
                  label={t('input:companyEmail')}
                  defaultValue={this.setDefaultValue('company_email')}
                  validators={[
                    new validators.IsRequired(t),
                    new validators.Email(t)
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="companyApproval"
                  label={t('input:companyApproval')}
                  defaultValue={this.setDefaultValue('company_approval')}
                  validators={[
                    new validators.IsRequired(t)
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="signatoryName"
                  label={t('input:signatoryName')}
                  defaultValue={this.setDefaultValue('signatory_name')}
                  validators={[
                    new validators.IsRequired(t)
                  ]}
                />
              </div>
              <div>
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="signatoryTitle"
                  label={t('input:signatoryTitle')}
                  defaultValue={this.setDefaultValue('signatory_title')}
                  validators={[
                    new validators.IsRequired(t)
                  ]}
                />
              </div>
              <Button
                className="my-3"
                onClick={this.sendHandler}
                color="primary"
                variant="raised"
              >
                {t('buttonSave')}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
