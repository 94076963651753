import './styles.scss';

import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import Loader from '../../../common/components/Loader';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { fetchUnreadFoldersFiles } from '../../actions/folders';
import { fileMarkAsRead } from '../../actions/fileUpload';
import { reformatPickerDateFromDB } from '../../utils/time';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 105,
  },
  header: {
    background: '#549358',
  },
  iconColor: {
    color: '#549358',
  },
});

const mapStateToProps = (state) => {
  return {
    folderUnreadFilesRequest: state.folders.folderUnreadFilesRequest,
    folderUnreadFiles: state.folders.folderUnreadFiles,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUnreadFoldersFiles: () => dispatch(fetchUnreadFoldersFiles()),
    fileMarkAsRead: (file) => dispatch(fileMarkAsRead(file)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UnreadDocumentsInLibraryList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    fetchUnreadFoldersFiles: PropTypes.func.isRequired,
    fileMarkAsRead: PropTypes.func.isRequired,
    folderUnreadFiles: PropTypes.array.isRequired,
    folderUnreadFilesRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchUnreadFoldersFiles();
  }

  renderList() {
    const { fileMarkAsRead, folderUnreadFiles, classes } = this.props;

    return folderUnreadFiles.map((file) => {
      return (
        <TableRow key={`unread-file-list-${file.id}`}>
          <TableCell className={classes.longCell}>
            <a
              target="_blank"
              href={file.path}
              onClick={() => {
                fileMarkAsRead(file);
              }}
            >
              {file.name}
            </a>
          </TableCell>
          <TableCell className={classes.longCell}>
            <Link to={`/library/?folderId=${file.folder.id}`}>
              {file.folder.name}
            </Link>
          </TableCell>
          <TableCell className={classes.longCell}>
            {reformatPickerDateFromDB(file.createdAt, false)}
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { folderUnreadFiles, folderUnreadFilesRequest, classes, t } =
      this.props;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <header className="header-dashboard">
          <span className={classNames('span-dashboard', 'py-3')}>
            <Icon
              className={classNames(classes.iconColor, 'notification-icon')}
            >
              book
            </Icon>
            <h4 className="h4-dashboard">{t('dashboard:Unread documents')}</h4>
            <Link to="/library">
              <Icon
                className={classNames(classes.iconColor, 'notification-icon')}
              >
                keyboard_arrow_right
              </Icon>
            </Link>
          </span>
        </header>
        <div className={classNames(classes.root, 'px-2')}>
          {folderUnreadFilesRequest && <Loader />}
          {!_isEmpty(folderUnreadFiles) && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header}>
                    {t('table:name')}
                  </TableCell>
                  <TableCell className={classes.header}>
                    {t('table:folder')}
                  </TableCell>
                  <TableCell className={classes.header}>
                    {t('table:date')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderList()}</TableBody>
            </Table>
          )}
        </div>
      </Paper>
    );
  }
}
