import React, { Component } from 'react';
import {
  addWarehouse,
  cleanWarehouseData,
  editWarehouse,
} from '../../../actions/warehouse';

import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import WarehouseNew from './WarehouseNew';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { fetchPartStatuses } from '../../../actions/partStatuses';
import moment from 'moment';
import { reformatPickerDateFromDB } from '../../../utils/time';
import userService from '../../../../utils/userService';

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    warehouse: state.warehouse.warehouse,
    warehouseRequest: state.warehouse.warehouseRequest,
    partStatusList: state.partStatuses.partStatusList,
    inspectionList: state.inspections.inspectionList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addWarehouse: (warehouse) => dispatch(addWarehouse(warehouse)),
    editWarehouse: (id, warehouse) => dispatch(editWarehouse(id, warehouse)),
    cleanWarehouseData: () => dispatch(cleanWarehouseData()),
    fetchPartStatuses: () => dispatch(fetchPartStatuses()),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossWarehouseNewView extends Component {
  static propTypes = {
    warehouse: PropTypes.object.isRequired,
    warehouseRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    cleanWarehouseData: PropTypes.func.isRequired,
    addWarehouse: PropTypes.func.isRequired,
    fetchPartStatuses: PropTypes.func.isRequired,
    partStatusList: PropTypes.array.isRequired,
    editWarehouse: PropTypes.func.isRequired,
    settings: PropTypes.array.isRequired,
    inspectionList: PropTypes.array.isRequired,
    previewMode: PropTypes.bool,
    editMode: PropTypes.bool,
  };

  componentDidMount() {
    const { fetchPartStatuses } = this.props;

    fetchPartStatuses();
  }

  componentWillUnmount() {
    const { cleanWarehouseData } = this.props;

    cleanWarehouseData();
  }

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    return _find(settings, { name: fieldName }).textValue;
  }

  render() {
    const {
      warehouse,
      warehouseRequest,
      editMode,
      previewMode,
      addWarehouse,
      editWarehouse,
      partStatusList,
      inspectionList,
    } = this.props;

    const currentUser = userService.getCurrentUser();
    const preparedWarehouse = {
      id: _get(warehouse, 'id', ''),
      name: _get(warehouse, 'name', ''),
      address: _get(warehouse, 'address', ''),
      location: _get(warehouse, 'location', ''),
      contactDetails: _get(warehouse, 'contactDetails', ''),
      updatedBy: _get(warehouse, 'updatedBy', ''),
      updatedAt: reformatPickerDateFromDB(
        _get(warehouse, 'updatedAt', moment()),
      ),
      parts: _get(warehouse, 'parts', []),
    };

    const warehouseSettings = {};

    if (warehouseRequest) {
      return <Loader />;
    }

    return (
      <WarehouseNew
        warehouse={preparedWarehouse}
        previewMode={previewMode}
        editMode={editMode}
        addWarehouse={addWarehouse}
        editWarehouse={editWarehouse}
        linkTo={'/warehouse'}
        currentUser={currentUser}
        warehouseSettings={warehouseSettings}
        inspectionList={inspectionList}
        partStatusList={partStatusList}
      />
    );
  }
}
