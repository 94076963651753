import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';

import RemoveDialog from '../../../../common/components/RemoveDialog';
import AssignView from '../../../containers/AssignView';
import TestList from './TestList';
import Loader from '../../../../common/components/Loader';
import Confirmation from '../../../../common/components/Confirmation';

import { fetchTests, removeTest } from '../../../actions/tests';
import { resetAssignTestFlag, assignTest } from '../../../actions/assignedTests';
import { PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission, renderPermission } from '../../../decorators/permissions';

const styles = () => ({
  root: {
    overflowY: 'visible'
  }
});

const INITIAL_STATE = {
  searchSettings: {
    sortAsc: true,
    search: '',
  },
  removeDialogOpen: false,
  removeTest: null,
  assignOpen: false,
  testId: 0
};

const mapStateToProps = (state) => {
  return {
    testList: state.tests.testList.filter((test) => test.isActive),
    testRequest: state.tests.testRequest,
    assignTestSuccess: state.assignedTests.assignTestSuccess,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchTests: (sortData) => dispatch(fetchTests(sortData)),
    removeTest: (test) => dispatch(removeTest(test)),
    assignTest: (assignData) => dispatch(assignTest(assignData)),
    resetAssignTestFlag: () => dispatch(resetAssignTestFlag()),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.examView)
export default class ManageTests extends Component {
  static propTypes = {
    testList: PropTypes.array,
    testRequest: PropTypes.bool.isRequired,
    fetchTests: PropTypes.func.isRequired,
    removeTest: PropTypes.func.isRequired,
    assignTest: PropTypes.func.isRequired,
    assignTestSuccess: PropTypes.bool.isRequired,
    resetAssignTestFlag: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.fetchTests();
  }

  handleSort = () => {
    const { sortAsc, search } = this.state.searchSettings;
    const searchData = {
      sortAsc: !sortAsc,
      search
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchTests(searchData);
  };

  handleSearch = (search) => {
    const { sortAsc } = this.state.searchSettings;
    const searchData = {
      sortAsc,
      search
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchTests(searchData);
  };

  removeTest = () => {
    this.closeRemoveDialog();
    this.props.removeTest(this.state.removeTest);
  };

  openRemoveDialog = (test) => {
    this.setState({
      removeDialogOpen: true,
      removeTest: test,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeTest: null,
    });
  };

  renderRemoveDialog = (t) => {
    return (
      <Dialog
        open={this.state.removeDialogOpen}
        onClose={this.closeRemoveDialog}
        aria-labelledby="remove-dialog"
        fullWidth
      >
        <RemoveDialog
          message={t('test:removeTest')}
          closeRemoveDialog={this.closeRemoveDialog}
          dialogTitle={t('test:removeTestDialogTitle')}
          removeFunction={this.removeTest}
        />
      </Dialog>
    );
  };

  renderAssignDialog = (t) => {
    const { assignTestSuccess, resetAssignTestFlag } = this.props;

    return (
      <div className="col-12">
        <Dialog
          open={this.state.assignOpen}
          onClose={this.handleAssignTestClose}
          aria-labelledby="assign-test"
          maxWidth="md"
          fullWidth
        >
          <AssignView
            handleAssignViewClose={this.handleAssignTestClose}
            serializeData={this.serializeData}
            viewLabel={t('test:assignUsersDialogTitle')}
          />
        </Dialog>
        <Dialog
          open={assignTestSuccess}
          onClose={resetAssignTestFlag}
          aria-labelledby="confirmation-assign"
          fullWidth
        >
          <Confirmation
            message={t('test:testAssigned')}
            closeConfirmationDialog={resetAssignTestFlag}
          />
        </Dialog>
      </div>
    );
  };

  handleAssignTestOpen = (testId) => {
    this.setState({
      assignOpen: true,
      testId
    });
  };

  handleAssignTestClose = () => {
    this.setState({
      assignOpen: false,
    });
  };

  serializeData = (users, scheduleTime, isActive) => {
    const { testId } = this.state;

    users.forEach((userId) => {
      const dataToSend = {
        testId,
        userId,
        isActive,
      };

      if (!_isEmpty(scheduleTime) && !isActive) {
        dataToSend.scheduleTime = scheduleTime;
      }

      this.props.assignTest(dataToSend);
    });
  };

  @renderPermission(PERMISSIONS.addNewExam)
  renderAddExamIcon(t) {
    return (
      <div className="col-auto">
        <Link to="/exam_admin/new">
          <Button color="primary">
            <Icon color="primary" className="mr-3">playlist_add</Icon>
            {t('test:addExamButton')}
          </Button>
        </Link>
      </div>
    );
  }

  render() {
    const { testList = [], testRequest, t } = this.props;
    const { sortAsc, search } = this.state.searchSettings;

    if (testRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-lg-8 col-sm-12 section-title">
        <header>
            <span>
              <Icon color="primary">
                list_add_check
              </Icon>
              <h1>{t('test:name')}<span className="h1-subheader"> /{t('test:examAdminSection')}</span></h1>
            </span>
          <p>{t('test:descriptionAdmin')}</p>
          </header>
        </div>
        {this.renderAddExamIcon(t)}
        <div className="col-12">
          <TestList
            testList={testList}
            sortAsc={sortAsc}
            handleSort={this.handleSort}
            handleSearch={this.handleSearch}
            search={search}
            openRemoveDialog={this.openRemoveDialog}
            handleAssignTestOpen={this.handleAssignTestOpen}
          />
          {this.renderAssignDialog(t)}
          {this.renderRemoveDialog(t)}
        </div>
      </div>
    );
  }
}
