import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import validators from '../../../utils/validators';
import Form from '../../../common/components/forms/Form';
import AuthenticationError from './AuthenticationError';
import MaterialInput from '../../../common/components/forms/MaterialInput';
import AuthenticationBase from './AuthenticationBase';

import { changePassword, isTokenValid, resetPasswordRecovering } from '../../actions/authentication';
import { removeError } from '../../actions/errors';


const mapStateToProps = (state) => {
  return {
    loginFailure: state.authentication.loginFailure,
    message: state.authentication.passwordRecoveryMessage,
    error: state.authentication.passwordRecoveryError,
    tokenIsValid: state.authentication.tokenIsValid,
    changePasswordSuccess: state.authentication.changePasswordSuccess,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changePassword: (formData, token) => dispatch(changePassword(formData, token)),
    isTokenValid: (token) => dispatch(isTokenValid(token)),
    removeError: () => dispatch(removeError()),
    resetRecovering: () => dispatch(resetPasswordRecovering()),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class ChangePassword extends AuthenticationBase {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    tokenIsValid: PropTypes.bool.isRequired,
    changePasswordSuccess: PropTypes.bool.isRequired,
    changePassword: PropTypes.func.isRequired,
    isTokenValid: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    removeError: PropTypes.func.isRequired,
    resetRecovering: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    isFormValid: true
  };

  componentDidMount() {
    const token = this.props.match.params.id;

    this.props.isTokenValid(token);
  }

  componentWillUnmount() {
    this.props.removeError();
  }

  validateForm = (formData) => {
    return formData.password === formData.passwordConfirmation;
  };

  onChange = (formData) => {
    this.formData = formData;
    this.props.removeError();
  };

  onFormValidated = (isFormValid) => {
    this.setState({
      isFormValid
    });

    if (isFormValid && this.formData) {
      this.props.changePassword(this.formData.password.value, this.props.match.params.id);
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  renderContent = () => {
    const { changePasswordSuccess, resetRecovering, t } = this.props;

    return (
      <div>
        <Form
          onChange={this.onChange}
          validateForm={this.validateForm}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <div className="col-12 row input-row">
            <MaterialInput
              className="col-lg-8"
              margin="dense"
              id="password"
              name="password"
              label={t('input:password')}
              type="password"
              validators={[
                new validators.IsRequired(t),
                new validators.Password(t),
              ]}
            />
          </div>
          <div className="col-12 row input-row">
            <MaterialInput
              className="col-lg-8"
              margin="dense"
              id="passwordConfirmation"
              name="passwordConfirmation"
              label={t('input:passwordConfirmation')}
              type="password"
              validators={[
                new validators.IsRequired(t),
                new validators.Password(t),
              ]}
            />
          </div>
          <AuthenticationError />
          <Button
            className="my-3"
            onClick={this.sendHandler}
            color="primary"
            variant="raised"
          >
            {t('buttonSave')}
          </Button>
        </Form>
        <Link to="/login">{t('authentication:loginLink')}</Link>
        <Dialog
          className="text-center"
          open={changePasswordSuccess}
          onClose={resetRecovering}
          aria-labelledby="change-password"
          fullWidth
        >
          <DialogTitle id="set-password">{t('authentication:passwordChangedDialogTitle')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('authentication:passwordChanged')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="my-3"
              onClick={resetRecovering}
              color="primary"
            >
              {t('buttonOk')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  renderTokenNotValidContent = () => {
    const { t } = this.props;

    return (
      <h2 className="text-center">{t('authentication:tokenNotValid')}</h2>
    );
  }

  render() {
    const { isAuthenticated, tokenIsValid } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    if (!tokenIsValid) {
      return this.renderBaseTemplate(this.renderTokenNotValidContent);
    }

    return this.renderBaseTemplate();

  }
}
