import {
  FETCH_QUALIFICATIONS_SUCCESS,
  FETCH_ALL_QUALIFICATIONS_SUCCESS,
  ADD_QUALIFICATION,
  REMOVE_QUALIFICATION,
  EDIT_QUALIFICATION,
  FETCH_QUALIFICATIONS
} from '../actions/qualifications';
import {
  REMOVE_QUALIFICATION_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  qualificationList: [],
  allQualificationList: [],
  qualificationsRequest: false,
};

function fetchQualificationsSuccess(state, payload) {
  return {
    ...state,
    qualificationList: payload.qualifications,
    qualificationsRequest: false,
  };
}

function fetchAllQualificationsSuccess(state, payload) {
  return {
    ...state,
    allQualificationList: payload.allQualifications,
    qualificationsRequest: false
  };
}

function fetchAllQualifications(state) {
  return {
    ...state,
    qualificationsRequest: true
  };
}

function addQualification(state, payload) {
  return {
    ...state,
    qualificationList: [
      ...state.qualificationList,
      payload.qualification
    ]
  };
}

function removeQualification(state, payload) {
  return {
    ...state,
    qualificationList: state.qualificationList.filter((rating) => rating !== payload.qualification)
  };
}

function editQualification(state, payload) {
  return {
    ...state,
    qualificationList: state.qualificationList.map((qualification) => {
      if (qualification.id === payload.qualification.id) {
        return payload.qualification;
      }

      return qualification;
    }),
    allQualificationList: state.allQualificationList.map((qualification) => {
      if (qualification.id === payload.qualification.id) {
        return payload.qualification;
      }

      return qualification;
    })
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    qualificationList: state.qualificationList.map((qualification) => {
      if (qualification.id === payload.qualificationId) {
        return {
          ...qualification,
          files: qualification.files.filter((file) => file.id !== payload.fileId)
        };
      }

      return qualification;
    })
  };
}

const actions = {
  [FETCH_QUALIFICATIONS_SUCCESS]: fetchQualificationsSuccess,
  [FETCH_QUALIFICATIONS]: fetchAllQualifications,
  [FETCH_ALL_QUALIFICATIONS_SUCCESS]: fetchAllQualificationsSuccess,
  [ADD_QUALIFICATION]: addQualification,
  [REMOVE_QUALIFICATION]: removeQualification,
  [EDIT_QUALIFICATION]: editQualification,
  [REMOVE_QUALIFICATION_FILE_SUCCESS]: removeFile,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
