import {
  REMOVE_ERROR,
  AUTHENTICATION_ERROR,
  ERROR,
} from '../actions/errors';

const INITIAL_STATE = {
  message: null,
  authenticationError: false,
  generalError: false,
};

function addAuthenticationError(state, payload) {
  return {
    ...state,
    message: payload.message,
    authenticationError: true,
  };
}

function addError(state, payload) {
  return {
    ...state,
    message: payload.message,
    generalError: true,
  };
}

function removeError(state) {
  return {
    ...state,
    message: null,
    generalError: false,
    authenticationError: false,
  };
}

const actions = {
  [AUTHENTICATION_ERROR]: addAuthenticationError,
  [ERROR]: addError,
  [REMOVE_ERROR]: removeError,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
