import React, { Component } from 'react';

import PropTypes from 'prop-types';
import TechnicalFaultReportList from './TechnicalFaultReportList';
import { connect } from 'react-redux';
import { fetchUsers } from '../../../../actions/users';

const mapStateToProps = (state) => {
  return {
    users: state.users.userList,
    getUsersRequest: state.users.getUsersRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class QrosscheckTechnicalFaultReportList extends Component {
  static propTypes = {
    fetchUsers: PropTypes.func.isRequired,
    getUsersRequest: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
  };

  render() {
    return <TechnicalFaultReportList />;
  }
}
