export const tableHeader = [
  {
    prop: 'color',
    sort: false,
    profileView: false,
    background: ''
  },
  {
    prop: 'name',
    sort: false,
    profileView: false
  },
  {
    prop: 'competencies',
    sort: false,
    profileView: false
  },
  {
    prop: 'icons',
    profileView: false,
    sort: false,
    isNumeric: true
  }
];

export const styles = ({
  competencyOk: {
    background: '#d2ffce'
  },
  competencyAboutExpiration: {
    background: '#ffedb8'
  },
  competencyExpired: {
    background: '#ffc9c9'
  },
});

export const defaultStaffCategory = 1;
