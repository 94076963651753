import {
  FETCH_DOCUMENT_TYPES_SUCCESS,
  FETCH_DOCUMENT_TYPES_REQUEST,
  FETCH_DOCUMENT_TYPES_FAILURE,
  ADD_DOCUMENT_TYPE,
  REMOVE_DOCUMENT_TYPE,
} from '../actions/documentTypes';

const INITIAL_STATE = {
  documentTypeList: [],
  documentTypeRequest: false,
};

function fetchDocumentTypes(state, payload) {
  return {
    ...state,
    documentTypeList: payload.documentTypes,
    documentTypeRequest: false,
  };
}

function fetchDocumentTypesRequest(state) {
  return {
    ...state,
    documentTypeRequest: true,
  };
}

function fetchDocumentTypesFailure(state) {
  return {
    ...state,
    documentTypeRequest: false,
  };
}

function addDocumentType(state, payload) {
  return {
    ...state,
    documentTypeList: [
      ...state.documentTypeList,
      payload.documentType
    ]
  };
}

function removeDocumentType(state, payload) {
  return {
    ...state,
    documentTypeList: state.documentTypeList.filter((documentType) => documentType !== payload.documentType)
  };
}

const actions = {
  [FETCH_DOCUMENT_TYPES_SUCCESS]: fetchDocumentTypes,
  [FETCH_DOCUMENT_TYPES_REQUEST]: fetchDocumentTypesRequest,
  [FETCH_DOCUMENT_TYPES_FAILURE]: fetchDocumentTypesFailure,
  [ADD_DOCUMENT_TYPE]: addDocumentType,
  [REMOVE_DOCUMENT_TYPE]: removeDocumentType,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
