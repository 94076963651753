import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import QuestionList from '../AssignedTestDetails/QuestionList';
import Loader from '../../../../common/components/Loader';
import { fetchAssignedTest } from '../../../actions/assignedTests';
import { reformatPickerDateTimeFromDB } from '../../../utils/time';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { hasPermission } from '../../../decorators/permissions';

const styles = () => ({
  root: {
    width: '180px'
  },
});

const mapStateToProps = (state) => {
  return {
    assignedTest: state.assignedTests.assignedTest,
    assignedTestRequest: state.assignedTests.assignedTestRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAssignedTest: (id) => dispatch(fetchAssignedTest(id))
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class CompletedTestPreview extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    assignedTest: PropTypes.object.isRequired,
    fetchAssignedTest: PropTypes.func.isRequired,
    assignedTestRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    answers: []
  };

  componentDidMount() {
    this.props.fetchAssignedTest(this.props.match.params.id);
  }

  renderButtons = (t) => {
    let redirectionPath = '/my_exams';

    if (hasPermission(PERMISSIONS.examView)) {
      redirectionPath = '/exam_list';
    }

    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to={redirectionPath}>
            <Button variant="raised">{t('buttonBack')}</Button>
          </Link>
        </div>
      </div>
    );
  };

  renderQuestions = (testId, questionList) => {
    return (
      <QuestionList
        questionList={questionList}
        testId={testId}
        completedTestPreviewMode
      />
    );
  };

  getTestQuestions = (testData) => {
    return testData.questions && testData.questions.map((question) => {
      return {
        ...question,
        answers: _sortBy(question.answers, ['order'])
      };
    });
  };

  render() {
    const { assignedTest, assignedTestRequest, classes, t } = this.props;
    const testId = this.props.match.params.id;

    if (assignedTestRequest) {
      return <Loader />;
    }

    const testData = _get(assignedTest, 'testData', {});
    const testQuestions = this.getTestQuestions(testData);

    return (
      <div className="col-12 assigned-test-details justify-content-lg-between justify-content-end align-items-center section-title">
        <header>
          <span>
            <Icon color="primary">
              list_add_check
            </Icon>
            <h1>{assignedTest.testData && assignedTest.testData.name}</h1>
          </span>
        </header>
        <div className="row align-items-center justify-content-center">
          <div className="col">
            <TextField
              className={classes.root}
              disabled
              margin="dense"
              id="result"
              name="result"
              value={assignedTest.result ? t('test:passed') : t('test:failed')}
              label={t('input:result')}
            />
          </div>
          <div className="col">
            <TextField
              className={classes.root}
              disabled
              margin="dense"
              id="score"
              name="score"
              value={assignedTest.score}
              label={t('input:score')}
            />
          </div>
          <div className="col">
            <TextField
              className={classes.root}
              disabled
              margin="dense"
              id="passCriteria"
              name="passCriteria"
              value={testData.passCriteria}
              label={t('input:passCriteria')}
            />
          </div>
          <div className="col">
            <TextField
              className={classes.root}
              disabled
              margin="dense"
              id="startDate"
              name="startDate"
              value={reformatPickerDateTimeFromDB(assignedTest.startDate, false)}
              label={t('input:startDate')}
            />
          </div>
          <div className="col">
            <TextField
              className={classes.root}
              disabled
              margin="dense"
              id="endDate"
              name="endDate"
              value={reformatPickerDateTimeFromDB(assignedTest.endDate, false)}
              label={t('input:endDate')}
            />
          </div>
        </div>
        {testQuestions && this.renderQuestions(testId, testQuestions)}
        <div className="col-12 my-3">
          {this.renderButtons(t)}
        </div>
      </div>
    );
  }
}

