import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _each from 'lodash/each';
import _isUndefined from 'lodash/isUndefined';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Form from '../../common/components/forms/Form';
import MaterialInput from '../../common/components/forms/MaterialInput';
import Loader from '../../common/components/Loader';
import Table from '../components/Table';
import validators from '../../utils/validators';

const styles = () => ({
  textField: {
    marginRight: 30
  },
});

const tableHeader = [
  {
    prop: 'name',
    sort: false,
    profileView: false
  },
  {
    prop: 'competencies',
    sort: false,
    profileView: false,
    isNumeric: true
  },
];

@withNamespaces()
@withStyles(styles)
export default class StaffCategoriesCompetenceManager extends Component {
  static propTypes = {
    competenciesList: PropTypes.array,
    competenciesRequest: PropTypes.bool.isRequired,
    fetchCompetencies: PropTypes.func.isRequired,
    handleAssignViewClose: PropTypes.func.isRequired,
    serializeAndSendData: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    staffCategoryToEdit: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    viewLabel: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    competenciesList: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      searchSettings: {
        search: '',
      },
      competencies: _get(this.props.staffCategoryToEdit, 'competencies', []).map((competenceId) => competenceId.id),
      name: _get(this.props.staffCategoryToEdit, 'name', '')
    };
  }

  componentDidMount() {
    const { searchSettings } = this.state;

    this.props.fetchCompetencies(searchSettings);
  }

  handleSearch = (search) => {
    const searchData = {
      search
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.handleSearch(searchData);
  };

  checkIfCheckIsNew = (competenceId) => {
    const { staffCategoryToEdit } = this.props;

    return staffCategoryToEdit.competencies.find((competence) => {
      return competence.id.toString() === competenceId.toString();
    });
  };

  isChecked(name, id) {
    const { editMode, staffCategoryToEdit } = this.props;
    const { competencies } = this.state;

    if (editMode) {
      if (this.checkIfCheckIsNew(id)) {
        return staffCategoryToEdit.competencies.find((competence) => {
          const checkFromState = this.state[`checkbox-${competence.id}`];

          if (_isUndefined(checkFromState)) {
            return `checkbox-${competence.id}` === name;
          }

          return !!this.state[name];
        });
      }

      const checkFromNewCompetence = competencies.find((competenceId)=> {
        return competenceId.toString() === id.toString();
      });

      return !_isUndefined(checkFromNewCompetence);

    }

    return !!this.state[name];
  }

  handleCheckedChange = (name) => (event) => {
    const { competencies } = this.state;
    let newCompetencies;

    const parsedId = parseInt(event.target.id, 10);
    const isChoosen = (competencies.indexOf(parseInt(event.target.id, 10)) > -1);

    if (!isChoosen) {
      newCompetencies = [
        ...competencies,
        parsedId,
      ];
    } else {
      newCompetencies = _filter(competencies, (competence) => {
        return competence !== parsedId;
      });
    }

    this.setState({
      competencies: newCompetencies,
      [name]: event.target.checked
    });
  };

  prepareCompetencies = (collection) => {
    return collection.map((competenceId) => ({ id: competenceId.id || competenceId }));
  };

  addLicense = () => {
    const { competencies, name } = this.state;
    const { editMode, serializeAndSendData } = this.props;
    const preparedCompetencies = this.prepareCompetencies(competencies);

    serializeAndSendData(preparedCompetencies, name, editMode);
  };

  editLicense = () => {
    const { competencies, name } = this.state;
    const { editMode, staffCategoryToEdit, serializeAndSendData } = this.props;
    const preparedCompetencies = this.prepareCompetencies(competencies);

    serializeAndSendData(preparedCompetencies, name, editMode, staffCategoryToEdit.id);
  };

  renderCheckbox(competence) {
    return (
      <Checkbox
        color="primary"
        checked={this.isChecked(`checkbox-${competence.id}`, competence.id)}
        onChange={this.handleCheckedChange(`checkbox-${competence.id}`)}
        value={`checkbox-${competence.id}`}
        id={competence.id.toString()}
      />
    );
  }

  getDataFromCompetenciesList(competenciesList) {
    const parsedCompetenciesList = competenciesList.map((competence) => ({
      ...competence,
      competencies: this.renderCheckbox(competence)
    }));

    return parsedCompetenciesList;
  }

  onChange = (formData) => {
    this.formData = formData;
    _each(formData, (value, key) => {
      this.setState({
        [key]: value.value
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.props.editMode ? this.editLicense() : this.addLicense();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };


  render() {
    const { handleAssignViewClose, classes, competenciesRequest, viewLabel, competenciesList, t } = this.props;
    const { searchSettings } = this.state;

    if (competenciesRequest) {
      return <Loader />;
    }

    return (
      <div>
        <DialogTitle id="assign-course">{viewLabel}</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <Table
                data={this.getDataFromCompetenciesList(competenciesList)}
                header={tableHeader}
                handleSort={this.handleSearch}
                sortAsc={searchSettings.sortAsc}
                columnToSort={searchSettings.columnToSort}
                handleSearch={this.handleSearch}
                search={searchSettings.search}
              />
            </div>
            <Form
              onChange={this.onChange}
              onFormValidated={this.onFormValidated()}
              registerForm={this.registerForm}
            >
              <div className="ml-4 pl-2 mt-4">
                <MaterialInput
                  className={classes.textField}
                  margin="dense"
                  name="name"
                  label={t('input:name')}
                  fullWidth
                  validators={[
                    new validators.IsRequired(t),
                  ]}
                  defaultValue={this.state.name}
                />
              </div>
            </Form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleAssignViewClose}>{t('buttonCancel')}</Button>
          <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
        </DialogActions>
      </div>
    );
  }
}
