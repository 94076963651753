import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { fetchCompetencies } from '../../actions/competencies';
import ExamsAndCoursesCompetencyManager from '../../../common/components/ExamsAndCoursesCompetencyManager';

const mapStateToProps = (state) => {
  return {
    competenciesList: state.competencies.competenciesList.filter((competency) => competency.isActive),
    competenciesRequest: state.competencies.competenciesRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCompetencies: (searchData) => dispatch(fetchCompetencies(searchData)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class AssignCompetencyToExamOrCourse extends Component {
  static propTypes = {
    competenciesList: PropTypes.array,
    examOrCourseCompetenciesList: PropTypes.array,
    handleManageClose: PropTypes.func.isRequired,
    fetchCompetencies: PropTypes.func.isRequired,
    saveCompetenciesList: PropTypes.func.isRequired,
    competenciesRequest: PropTypes.bool.isRequired,
    viewLabel: PropTypes.string.isRequired,
    editMode: PropTypes.bool
  };

  state = {
    searchSettings: {
      search: ''
    },
  };

  componentDidMount() {
    const { searchSettings } = this.state;

    this.props.fetchCompetencies(searchSettings);
  }

  handleSearch = (search) => {
    this.props.fetchCompetencies(search);
  };

  prepareCompetenciesList = () => {
    const { examOrCourseCompetenciesList, competenciesList } = this.props;

    if (_isEmpty(examOrCourseCompetenciesList)) {
      return competenciesList;
    }

    return competenciesList.map((competency) => {

      const foundExamOrCourseCompetency = examOrCourseCompetenciesList.find((examOrCourseCompetency) => {
        const competencyId = _get(examOrCourseCompetency, 'competence.id', _get(examOrCourseCompetency, 'competency.id', ''));

        return competencyId === competency.id;
      });

      if (foundExamOrCourseCompetency) {

        return {
          competency,
          number: foundExamOrCourseCompetency.number,
          period: foundExamOrCourseCompetency.period,
        };
      }

      return competency;
    });
  };

  render() {
    const { handleManageClose, competenciesRequest, viewLabel, saveCompetenciesList, editMode } = this.props;

    return (
      <ExamsAndCoursesCompetencyManager
        saveCompetenciesList={saveCompetenciesList}
        competenciesList={this.prepareCompetenciesList()}
        competenciesRequest={competenciesRequest}
        handleManageClose={handleManageClose}
        handleSearch={this.handleSearch}
        editMode={editMode}
        viewLabel={viewLabel}
      />
    );
  }
}
