import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';
import { getUserSettingsParametersFilter } from '../../utils';

export const FETCH_AIRCRAFTS_REQUEST = 'FETCH_AIRCRAFTS_REQUEST';
export const FETCH_AIRCRAFTS_FAILURE = 'FETCH_AIRCRAFTS_FAILURE';
export const FETCH_AIRCRAFTS_SUCCESS = 'FETCH_AIRCRAFTS_SUCCESS';
export const FETCH_ALL_AIRCRAFTS_SUCCESS = 'FETCH_ALL_AIRCRAFTS_SUCCESS';
export const FETCH_USER_AIRCRAFTS_SUCCESS = 'FETCH_USER_AIRCRAFTS_SUCCESS';
export const ADD_AIRCRAFT = 'ADD_AIRCRAFT';
export const EDIT_AIRCRAFT = 'EDIT_AIRCRAFT';
export const REMOVE_AIRCRAFT = 'REMOVE_AIRCRAFT';

export function fetchAllAircraftsRequest() {
  return {
    type: FETCH_AIRCRAFTS_REQUEST
  };
}

export function fetchAircraftsSuccess(aircrafts) {
  return {
    type: FETCH_AIRCRAFTS_SUCCESS,
    payload: {
      aircrafts
    }
  };
}

export function fetchUserAircraftsSuccess(userAircrafts) {
  return {
    type: FETCH_USER_AIRCRAFTS_SUCCESS,
    payload: {
      userAircrafts
    }
  };
}

export function fetchAllAircraftsSuccess(allAircrafts) {
  return {
    type: FETCH_ALL_AIRCRAFTS_SUCCESS,
    payload: {
      allAircrafts
    }
  };
}

export function fetchAircraftsFailure() {
  return {
    type: FETCH_AIRCRAFTS_FAILURE
  };
}

export function addAircraftSuccess(aircraft) {
  return {
    type: ADD_AIRCRAFT,
    payload: {
      aircraft
    }
  };
}

export function editAircraftSuccess(aircraft) {
  return {
    type: EDIT_AIRCRAFT,
    payload: { aircraft }
  };
}

export function removeAircraftSuccess(aircraft) {
  return {
    type: REMOVE_AIRCRAFT,
    payload: {
      aircraft
    }
  };
}

export function fetchAllAircrafts() {
  return (dispatch) => {
    dispatch(fetchAllAircraftsRequest());

    return axios.get(URLS.aircrafts)
      .then(({ data }) => dispatch(fetchAllAircraftsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchAircraftsFailure());
        dispatch(addError(`Error during aircrafts fetching ${error.message}`, error));
      });
  };
}

export function addAircraft(aircraft) {
  return (dispatch) => {
    return axios.post(URLS.aircrafts, aircraft)
      .then(({ data }) => dispatch(addAircraftSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during aircraft adding: ${error.message}`, error));
      });
  };
}

export function removeAircraft(aircraft) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.aircrafts, aircraft.id))
      .then(() => dispatch(removeAircraftSuccess(aircraft)))
      .catch((error) => {
        dispatch(addError(`Error during aircraft removing ${error.message}`, error));
      });
  };
}

export function editAircraft(aircraft) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.aircrafts, aircraft.id), aircraft)
      .then(({ data }) => dispatch(editAircraftSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during aircraft editing ${error.message}`, error));
      });
  };
}

export function fetchAircrafts(userId) {
  return (dispatch) => {
    const queryParameters = `?${getUserSettingsParametersFilter(userId)}`;

    return axios.get(urlJoin(URLS.aircrafts, queryParameters))
      .then(({ data }) => dispatch(fetchAircraftsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during aircraft fetching ${error.message}`, error));
      });
  };
}

export function fetchUserSettingsAircrafts(userId) {
  return (dispatch) => {
    const queryParameters = `?${getUserSettingsParametersFilter(userId)}`;

    return axios.get(urlJoin(URLS.userSettingsAircrafts, queryParameters))
      .then(({ data }) => dispatch(fetchUserAircraftsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during user aircraft fetching ${error.message}`, error));
      });
  };
}
