import {
  ADD_ROLE_SUCCESS,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  REMOVE_ROLE_SUCCESS,
} from '../actions/roles';
import {
  ADD_USER_SUCCESS,
  CHANGE_USER_PROFILE_SUCCESS,
  CLEAN_USER_PROFILE_DATA,
  GET_MY_USER_PROFILE_REQUEST,
  GET_MY_USER_PROFILE_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  REMOVE_USER_SUCCESS,
  RESET_ADD_USER_SUCCESS,
  RESET_SAVE_DETAILS_SUCCESS,
  SIMPLE_CHANGE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_SUCCESS
} from '../actions/users';

import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';

const INITIAL_STATE = {
  roleList: [],
  userList: [],
  userProfile: null,
  getUsersSuccess: false,
  getUsersRequest: false,
  roleRequest: false,
  getUserProfileSuccess: false,
  getUserProfileRequest: false,
  getUserProfileFailure: false,
  changeUserProfileSuccess: false,
  addUserSuccess: false,
  getMyUserProfileRequest: false,
  myProfile: null,
};

function getMyUserProfileRequest(state) {
  return {
    ...state,
    getMyUserProfileRequest: true,
  };
}

function getMyUserProfileSuccess(state, payload) {
  return {
    ...state,
    myProfile: payload.profile,
    getMyUserProfileRequest: false,
  };
}

function getUserRequest(state) {
  return {
    ...state,
    getUsersRequest: true,
  };
}

function getUserSuccess(state, payload) {
  return {
    ...state,
    userList: payload.userList,
    getUsersRequest: false,
    getUsersSuccess: true,
  };
}

function getUserFailure(state) {
  return {
    ...state,
    getUsersRequest: false,
  };
}

function addUserSuccess(state, payload) {
  return {
    ...state,
    userList: [
      ...state.userList,
      payload.user,
    ],
    addUserSuccess: true,
  };
}

function removeUserSuccess(state, payload) {
  return {
    ...state,
    userList: state.userList.filter((user) => user.id !== payload.userId)
  };
}

function fetchRolesSuccess(state, payload) {
  return {
    ...state,
    roleList: payload.roleList.map((role) => ({ ...role, tag: 'groups' })),
    roleRequest: false,
  };
}

function fetchRolesRequest(state) {
  return {
    ...state,
    roleRequest: true,
  };
}

function fetchRolesFailure(state) {
  return {
    ...state,
    roleRequest: false,
  };
}

function addRoleSuccess(state, payload) {
  const updatedUserList = _cloneDeep(state.userList);

  payload.group.userSet.forEach((user) => {
    const updatedUserIndex = _findIndex(updatedUserList, { id: user.id });

    if (updatedUserIndex >= 0) {
      updatedUserList[updatedUserIndex].groups = user.groups;
    }
  });

  return {
    ...state,
    userList: updatedUserList
  };
}

function removeRoleSuccess(state, payload) {
  return {
    ...state,
    userList: state.userList.map((user) => {
      if (user.id === payload.user.id) {
        return {
          ...user,
          groups: user.groups.filter((group) => {
            return group.id !== payload.group.id;
          })
        };
      }

      return user;
    })
  };
}

function getUserProfileRequest(state) {
  return {
    ...state,
    getUserProfileRequest: true,
  };
}

function getUserProfileSuccess(state, payload) {
  return {
    ...state,
    userDetails: {
      ...payload.userDetails,
      schoolgroupSet: payload.userDetails.schoolgroupSet.map((group) => ({ ...group, tag: 'schoolgroup_set' }))
    },
    getUserProfileRequest: false,
    getUserProfileSuccess: true,
  };
}

function getUserProfileFailure(state) {
  return {
    ...state,
    getUserProfileFailure: true
  };
}

function cleanUserProfile(state) {
  return {
    ...state,
    userDetails: null
  };
}

function changeUserProfileSuccess(state, payload) {
  return {
    ...state,
    userDetails: { ...payload },
    changeUserProfileSuccess: true,
  };
}

function updateUserProfileSuccess(state, payload) {
  return {
    ...state,
    userDetails: {
      ...state.userDetails,
      userprofile: { ...payload },
    }
  };
}

function resetAddUserSuccess(state) {
  return {
    ...state,
    addUserSuccess: false
  };
}

function resetSaveDetailsSuccess(state) {
  return {
    ...state,
    changeUserProfileSuccess: false
  };
}

function simpleChangeUserProfileSuccess(state, payload) {
  return {
    ...state,
    userDetails: {
      ...state.userDetails,
      userprofile: {
        ...payload
      }
    },
  };
}

const actions = {
  [GET_USERS_REQUEST]: getUserRequest,
  [GET_USERS_SUCCESS]: getUserSuccess,
  [GET_USERS_FAILURE]: getUserFailure,

  [ADD_USER_SUCCESS]: addUserSuccess,
  [REMOVE_USER_SUCCESS]: removeUserSuccess,

  [FETCH_ROLES_SUCCESS]: fetchRolesSuccess,
  [FETCH_ROLES_REQUEST]: fetchRolesRequest,
  [FETCH_ROLES_FAILURE]: fetchRolesFailure,
  [ADD_ROLE_SUCCESS]: addRoleSuccess,
  [REMOVE_ROLE_SUCCESS]: removeRoleSuccess,

  [GET_MY_USER_PROFILE_REQUEST]: getMyUserProfileRequest,
  [GET_MY_USER_PROFILE_SUCCESS]: getMyUserProfileSuccess,

  [GET_USER_PROFILE_REQUEST]: getUserProfileRequest,
  [GET_USER_PROFILE_SUCCESS]: getUserProfileSuccess,
  [GET_USER_PROFILE_FAILURE]: getUserProfileFailure,
  [CLEAN_USER_PROFILE_DATA]: cleanUserProfile,
  [CHANGE_USER_PROFILE_SUCCESS]: changeUserProfileSuccess,
  [UPDATE_USER_PROFILE_SUCCESS]: updateUserProfileSuccess,
  [RESET_ADD_USER_SUCCESS]: resetAddUserSuccess,
  [RESET_SAVE_DETAILS_SUCCESS]: resetSaveDetailsSuccess,
  [SIMPLE_CHANGE_USER_PROFILE_SUCCESS]: simpleChangeUserProfileSuccess
};

export default function getUsersReducer(state = INITIAL_STATE, action) {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
}
