import {
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_REQUEST,
  FETCH_BOOKINGS_FAILURE,
  ADD_BOOKING,
  EDIT_BOOKING,
  REMOVE_BOOKING,
  BOOKING_NOTIFICATION,
} from '../actions/bookings';

const INITIAL_STATE = {
  bookingList: [],
  bookingRequest: false,
};

function fetchBookings(state, payload) {
  return {
    ...state,
    bookingList: payload.bookings,
    bookingRequest: false,
  };
}

function fetchBookingsRequest(state) {
  return {
    ...state,
    bookingRequest: true,
  };
}

function fetchBookingsFailure(state) {
  return {
    ...state,
    bookingRequest: false,
  };
}

function addBooking(state, payload) {
  return {
    ...state,
    bookingList: [
      ...state.bookingList,
      ...payload.booking
    ]
  };
}

function editBooking(state, payload) {
  return {
    ...state,
    bookingList: state.bookingList.map((booking) => {
      if (booking.id === payload.booking.id) {
        return payload.booking;
      }

      return booking;
    })
  };
}

function removeBooking(state, payload) {
  return {
    ...state,
    bookingList: state.bookingList.filter((booking) => booking.id !== payload.booking.id),
  };
}

function bookingNotification(state) {
  return {
    ...state
  };
}


const actions = {
  [FETCH_BOOKINGS_SUCCESS]: fetchBookings,
  [FETCH_BOOKINGS_REQUEST]: fetchBookingsRequest,
  [FETCH_BOOKINGS_FAILURE]: fetchBookingsFailure,
  [ADD_BOOKING]: addBooking,
  [EDIT_BOOKING]: editBooking,
  [REMOVE_BOOKING]: removeBooking,
  [BOOKING_NOTIFICATION]: bookingNotification,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
