import axios from 'axios';
import { addError } from './errors';
import { urlJoin, } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_INSTALLTIONS_SUCCESS = 'FETCH_INSTALLTIONS_SUCCESS';
export const ADD_INSTALLTION = 'ADD_INSTALLTION';
export const EDIT_INSTALLTION = 'EDIT_INSTALLTION';
export const CLEAN_INSTALLATION_DATA = 'CLEAN_INSTALLATION_DATA';

export function fetchInstallationsSuccess(installations) {
  return {
    type: FETCH_INSTALLTIONS_SUCCESS,
    payload: {
      installations
    }
  };
}

export function addInstallationSuccess(installation) {
  return {
    type: ADD_INSTALLTION,
    payload: {
      installation
    }
  };
}

export function editInstallationSuccess(installation) {
  return {
    type: EDIT_INSTALLTION,
    payload: {
      installation
    }
  };
}

export function addInstallation(installation) {
  return (dispatch) => {
    return axios.post(URLS.installations, installation)
      .then(({ data }) => dispatch(addInstallationSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during installation adding ${error.message}`, error));
      });
  };
}

export function editInstallation(installation) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.installations, installation.id), installation)
      .then(({ data }) => dispatch(editInstallationSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during installation editing ${error.message}`, error));
      });
  };
}

export function cleanInstallations() {
  return {
    type: CLEAN_INSTALLATION_DATA,
  };
}
