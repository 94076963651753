import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import './styles.scss';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 80,
  },
  header: {
    background: 'linear-gradient(#9E3B3B, #EA6442)',
  },
  iconColor: {
    color: '#9E3B3B'
  }
});

@withNamespaces()
@withStyles(styles)
export default class ExpirationList extends Component {
  static propTypes = {
    expirationList: PropTypes.array,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderList() {
    const { expirationList, classes } = this.props;

    return expirationList.map((content) => {
      return (
        <TableRow key={`expirationList-${content.document}-${content.id}`}>
          <TableCell className={classNames(classes.longCell, 'pl-3')}>{content.type}</TableCell>
          <TableCell className={classes.longCell}>{content.document}</TableCell>
          <TableCell className={classes.longCell}>{content.expiryDate}</TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { expirationList, classes, t } = this.props;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <header className="header-dashboard">
          <span className={classNames('span-dashboard', 'py-3')}>
            <Icon className={classNames(classes.iconColor, 'notification-icon')}>
              timelapse
            </Icon>
            <h4 className="h4-dashboard">{t('dashboard:expirations')}</h4>
            <Link to="/my_profile">
              <Icon className={classNames(classes.iconColor, 'notification-icon')}>
              keyboard_arrow_right
              </Icon>
            </Link>
          </span>
        </header>
        <div className={classNames(classes.root, 'px-2')}>
          { !_isEmpty(expirationList) && <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classNames(classes.header, 'pl-3')}>{t('table:type')}</TableCell>
                <TableCell className={classes.header}>{t('table:document')}</TableCell>
                <TableCell className={classes.header}>{t('table:expiryDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderList()}
            </TableBody>
          </Table> }
        </div>
      </Paper>
    );
  }
}

