import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import CustomIconButton from '../../../../common/components/CustomIconButton';
import { DRAG_TYPES } from '../../../../constants';
import { trimToLongText } from '../../../../utils/index';

const itemSortableSource = {
  canDrag(props) {
    return !props.preview;
  },
  beginDrag(props) {
    return {
      ...props.item,
      index: props.index,
    };
  },
};

const itemSortableTarget = {
  canDrop(props) {
    return !props.preview;
  },
  hover(props, monitor, component) {
    const dragItem = monitor.getItem();
    const dragIndex = dragItem.index;
    const hoverIndex = props.index;

    if (!props.lessonId || dragItem.parent !== props.lessonId || dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
       (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) {
      return;
    }

    props.moveItemSortable(dragIndex, hoverIndex, props.lessonId);
    monitor.getItem().index = hoverIndex;
  },
};

function collectDrop(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function collectDrag(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const styles = () => ({
  smallerIconSize: {
    fontSize: 18,
  },
  smallerTextSize: {
    fontSize: 16,
    lineHeight: '48px',
  }
});

@withStyles(styles)
@DropTarget(DRAG_TYPES.itemSortable, itemSortableTarget, collectDrop)
@DragSource(DRAG_TYPES.itemSortable, itemSortableSource, collectDrag)
export default class ItemSortable extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    isItemDragging: PropTypes.bool,
    estimatedTime: PropTypes.string,
    dropRemoveItem: PropTypes.func,
    removeItem: PropTypes.func,
    handleEditOpen: PropTypes.func,
    handleDuplicate: PropTypes.func,
    moveItemSortable: PropTypes.func,
    lessonId: PropTypes.string,
    editMode: PropTypes.bool,
    preview: PropTypes.bool,
    isUsed: PropTypes.bool,
    index: PropTypes.number,
    classes: PropTypes.object.isRequired,
  };

  renderButtons(item, preview) {
    const { editMode, handleEditOpen, handleDuplicate, removeItem, connectDragSource, classes, dropRemoveItem } = this.props;

    if (preview) {
      return null;
    }

    if (editMode) {
      return (
        <div className="col-auto">
          { this.isItemUsed() }
          <IconButton onClick={() => handleDuplicate(item)}><Icon color="primary" className={classes.smallerIconSize}>content_copy</Icon></IconButton>
          <IconButton onClick={() => handleEditOpen(item)}><Icon color="primary" className={classes.smallerIconSize}>edit</Icon></IconButton>
          <IconButton onClick={() => removeItem(item)}><Icon color="primary" className={classes.smallerIconSize}>delete</Icon></IconButton>
        </div>
      );
    }

    return (
      <div className="col-auto">
        {
          connectDragSource(
            <div>
              <IconButton onClick={() => dropRemoveItem(item)}>
                <Icon color="primary">
                  delete
                </Icon>
              </IconButton>
              <CustomIconButton>
                <Icon color="primary">
                  drag_handle
                </Icon>
              </CustomIconButton>
            </div>
          )
        }
      </div>
    );
  }

  isItemUsed() {
    const { isUsed, classes } = this.props;

    if (!isUsed) {
      return null;
    }

    return (
      <IconButton><Icon className={classes.smallerIconSize} color="primary">check</Icon></IconButton>
    );
  }

  render() {
    const {
      item,
      editMode,
      preview,
      isItemDragging,
      isDragging,
      connectDragPreview,
      connectDropTarget,
      classes,
    } = this.props;

    const itemObject = (
      <Paper
        elevation={4}
        className={preview ? 'row m-3 px-2 justify-content-between align-items-center' : 'm-3 px-2 justify-content-between align-items-center'}
        style={{
          opacity: isItemDragging ? 0.5 : 1,
          cursor: editMode ? 'pointer' : 'auto',
          wordWrap: 'break-word',
          textAlign: 'justify'
        }}
      >
        <div className={preview ? 'col-12 col-sm-auto' : ''}>
          <div className="text-center text-xl-left">
            <span className={classes.smallerTextSize}>{trimToLongText(item.name, 35)}</span>
          </div>
        </div>
        <div className={preview ? 'col-12 col-sm-auto' : ''}>
          <div className="row justify-content-xl-between justify-content-center align-items-center">
            <div className="col-auto">
              <span className={classes.smallerTextSize}>{item.estimatedTime}</span>
            </div>
            {this.renderButtons(item, preview)}
          </div>
        </div>
      </Paper>
    );

    if (editMode || preview) {
      return itemObject;
    }

    return connectDragPreview(
      connectDropTarget(
        <div
          style={{
            opacity: isDragging ? 0 : 1,
          }}
        >
          {itemObject}
        </div>
      )
    );
  }
}
