import React, { Component } from 'react';
import {
  getDateFormat,
  reformatPickerDateFromDB,
} from '../../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../../common/components/forms/DateTimePicker';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Form from '../../../../../common/components/forms/Form';
import MaterialInput from '../../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import validators from '../../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class FieldsRenderer extends Component {
  static propTypes = {
    elementsNumber: PropTypes.number.isRequired,
    nameField1: PropTypes.string.isRequired,
    nameField2: PropTypes.string.isRequired,
    nameField3: PropTypes.string.isRequired,
    translationNameField1: PropTypes.string,
    translationNameField2: PropTypes.string,
    translationNameField3: PropTypes.string,
    defaultSettings: PropTypes.object.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    isFirstPage: PropTypes.func.isRequired,
    isLastPage: PropTypes.func.isRequired,
    title1: PropTypes.string.isRequired,
    title2: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = [...Array(this.props.elementsNumber).keys()].reduce((result, key) => {
    result[`${this.props.nameField1}_${key}`] = reformatPickerDateFromDB(
      this.props.defaultSettings[`${this.props.nameField1}_${key}`],
    );

    return result;
  }, {});

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  onFormValidated = (isFormValid) => {
    const { changePage } = this.props;
    const formData = Object.keys(this.formData).reduce((result, keyName) => {
      result[keyName] = this.formData[keyName].value;

      return result;
    }, {});
    const state = this.state || {};

    if (isFormValid && this.formData) {
      changePage(this.page, { ...formData, ...state });
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e, page) => {
    e.preventDefault();
    e.stopPropagation();

    this.page = page;
    this.triggerFormValidation();
  };

  renderFields = () => {
    const {
      t,
      defaultSettings,
      nameField1,
      nameField2,
      nameField3,
      translationNameField1 = this.props.nameField1,
      translationNameField2 = this.props.nameField2,
      translationNameField3 = this.props.nameField3,
      elementsNumber,
    } = this.props;

    return [...Array(elementsNumber).keys()].map((index) => {
      const count = index + 1;
      const field1 = `${nameField1}_${count}`;
      const field2 = `${nameField2}_${count}`;
      const field3 = `${nameField3}_${count}`;

      const translationField1 = `input:${translationNameField1}`;
      const translationField2 = `input:${translationNameField2}`;
      const translationField3 = `input:${translationNameField3}`;

      return (
        <div
          key={`${field2}-field-list`}
          className="row align-items-center justify-content-between"
        >
          <div className="col">
            <DateTimePicker
              value={this.state[field1]}
              showMonthDropdown
              showTimeSelect={false}
              showYearDropdown
              handleDatePickerChange={this.handleDatePickerChange(field1)}
              dateFormat={getDateFormat()}
              name={field1}
              label={t(translationField1)}
              acceptEmptyInput
              fullWidth
            />
          </div>
          <div className="col">
            <MaterialInput
              margin="dense"
              name={field2}
              label={t(translationField2)}
              validators={[new validators.MaxLength(t, 20)]}
              defaultValue={defaultSettings[field2]}
              fullWidth
            />
          </div>
          <div className="col">
            <MaterialInput
              margin="dense"
              name={field3}
              label={t(translationField3)}
              validators={[new validators.MaxLength(t, 23)]}
              defaultValue={defaultSettings[field3]}
              fullWidth
            />
          </div>
        </div>
      );
    });
  };

  render() {
    const { t, handleDialogClose, isFirstPage, isLastPage, title1, title2 } =
      this.props;

    return (
      <Form
        onChange={this.onChange}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <h4 className="text-center">{title1}</h4>
              <h4 className="text-center">{title2}</h4>
            </div>
            <div className="col-12">{this.renderFields()}</div>
          </div>
        </DialogContent>
        <DialogActions>
          {!isFirstPage() ? (
            <Button onClick={(e) => this.sendHandler(e, -1)}>
              {t('buttonPrevious')}
            </Button>
          ) : (
            <div />
          )}
          {!isLastPage() ? (
            <Button onClick={(e) => this.sendHandler(e, 1)}>
              {t('buttonNext')}
            </Button>
          ) : (
            <div />
          )}
          <Button color="secondary" onClick={handleDialogClose}>
            {t('buttonCancel')}
          </Button>
          {isLastPage() ? (
            <Button color="primary" onClick={(e) => this.sendHandler(e, 0)}>
              {t('buttonSave')}
            </Button>
          ) : (
            <div />
          )}
        </DialogActions>
      </Form>
    );
  }
}
