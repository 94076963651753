import {
  FETCH_ELEARNINGS_SUCCESS,
  FETCH_ELEARNINGS_REQUEST,
  FETCH_ELEARNING_LINK_REQUEST,
  FETCH_ELEARNINGS_FAILURE,
  FETCH_ELEARNING_LINK_FAILURE,
  FETCH_ELEARNING_LINK_SUCCESS,
} from '../actions/eLearning';

const INITIAL_STATE = {
  eLearningList: [],
  eLearningRequest: false,
  eLearningLinkRequest: false,
};

function fetchElearnings(state, payload) {
  return {
    ...state,
    eLearningList: payload,
    eLearningRequest: false,
  };
}

function fetchElearningLink(state) {
  return {
    ...state,
    eLearningLinkRequest: false,
  };
}

function fetchElearningsRequest(state) {
  return {
    ...state,
    eLearningRequest: true,
  };
}

function fetchElearningsFailure(state) {
  return {
    ...state,
    eLearningRequest: false,
  };
}

function fetchElearningLinkRequest(state) {
  return {
    ...state,
    eLearningLinkRequest: true,
  };
}

function fetchElearningLinkFailure(state) {
  return {
    ...state,
    eLearningLinkRequest: false,
  };
}


const actions = {
  [FETCH_ELEARNING_LINK_SUCCESS]: fetchElearningLink,
  [FETCH_ELEARNING_LINK_REQUEST]: fetchElearningLinkRequest,
  [FETCH_ELEARNING_LINK_FAILURE]: fetchElearningLinkFailure,
  [FETCH_ELEARNINGS_SUCCESS]: fetchElearnings,
  [FETCH_ELEARNINGS_REQUEST]: fetchElearningsRequest,
  [FETCH_ELEARNINGS_FAILURE]: fetchElearningsFailure,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
