import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import './styles.scss';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  header: {
    background: 'linear-gradient(#9E3B3B, #EA6442)',
  },
  bootstrapRoot: {
    fontSize: 12,
    boxShadow: 'none',
    textTransform: 'none',
    padding: '0px 10px',
    margin: '10px 10px',
    border: '1px solid',
    borderRadius: 25,
  },
  bootstrapRootElearning: {
    borderColor: '#00B8CC',
    '&:hover': {
      backgroundColor: '#E5F8FA'
    },
  },
  bootstrapRootExamsAndCourses: {
    borderColor: '#549358',
    '&:hover': {
      backgroundColor: '#DEEADE'
    }
  },
  textColor: {
    color: '#1A1A1A'
  },
  iconColor: {
    color: '#549358'
  },
  eLearningIconColor: {
    color: '#00B8CC'
  }
});

@withNamespaces()
@withStyles(styles)
export default class ExpirationList extends Component {
  static propTypes = {
    courseList: PropTypes.array,
    examList: PropTypes.array,
    firstName: PropTypes.string,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  filterElementsToNotify = (list) => {
    return list.filter((element)=> !element.isNotified);
  };

  checkIfRender = (list) => {
    const elementsToNotify = this.filterElementsToNotify(list);

    return elementsToNotify.length > 0;
  };

  getCourseNotification = (courseList, t) => {
    const notNotifiedCourses = this.filterElementsToNotify(courseList);

    return notNotifiedCourses && t('dashboard:courseToNotifyNumber', { count: notNotifiedCourses.length });
  };

  getExamNotification = (examList, t) => {
    const notNotifiedExams = this.filterElementsToNotify(examList);

    return notNotifiedExams && t('dashboard:examToNotifyNumber', { count: notNotifiedExams.length });
  };

  render() {
    const { firstName, courseList, examList, classes, t } = this.props;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <div className="pl-4 py-2">
          <h4>{t('dashboard:welcomeNew')} { firstName }!</h4>
          { ! _isEmpty(courseList) && this.checkIfRender(courseList) && <Link to="/notify_assigned_course">
            <div className="row py-3">
              <div className="col-2 pb-2">
                <Icon className={classNames(classes.iconColor, 'notification-icon')}>
                assignment_turned_in
                </Icon>
              </div>
              <div className={classNames(classes.textColor, 'col-8 px-0 pt-2')}>
                {this.getCourseNotification(courseList, t)}
              </div>
              <div className="col-2 pt-1">
                <Icon className={classes.iconColor}>
                keyboard_arrow_right
                </Icon>
              </div>
            </div>
          </Link>}

          {!_isEmpty(examList) && this.checkIfRender(examList) && <Link to="/notify_assigned_test">
            <div className="row py-3">
              <div className="col-2 pb-2">
                <Icon className={classNames(classes.iconColor, 'notification-icon')}>
                assignment_turned_in
                </Icon>
              </div>
              <div className={classNames(classes.textColor, 'col-8 px-0 pt-2')}>
                {this.getExamNotification(examList, t)}
              </div>
              <div className="col-2 pt-1">
                <Icon className={classes.iconColor}>
                keyboard_arrow_right
                </Icon>
              </div>
            </div>
          </Link>
          }

          <div className="row">
            <Link to="/my_courses">
              <Button className={classNames(classes.bootstrapRoot, classes.bootstrapRootExamsAndCourses)} variant="outlined">
                <Icon className={classNames(classes.iconColor, 'mx-1')}>
                  assignment_turned_in
                </Icon>
                {t('dashboard:coursesUpperCase')}
              </Button>
            </Link>
            <Link to="/my_exams">
              <Button className={classNames(classes.bootstrapRoot, classes.bootstrapRootExamsAndCourses)} variant="outlined">
                <Icon className={classNames(classes.iconColor, 'mx-1')}>
                  assignment_turned_in
                </Icon>
                {t('dashboard:examsUpperCase')}
              </Button>
            </Link>
            <a href="https://qrosscheck-learning.com/uncanny-group-login/" target="_blank">
              <Button className={classNames(classes.bootstrapRoot, classes.bootstrapRootElearning)} variant="outlined">
                <Icon className={classNames(classes.eLearningIconColor, 'mx-1')}>
                  school
                </Icon>
                {t('dashboard:eLearningUpperCase')}
              </Button>
            </a>
          </div>
        </div>
      </Paper>
    );
  }
}
