import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { complementaryCertificateLanguages } from '../../../../../constants/languages';


@withNamespaces()
export default class Page0 extends Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    handleLanguageChange: PropTypes.func.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    isFirstPage: PropTypes.func.isRequired,
    isLastPage: PropTypes.func.isRequired,
    saveComplementaryCourse: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      t, language, handleLanguageChange, changePage,
      handleDialogClose, isFirstPage, isLastPage, saveComplementaryCourse
    } = this.props;

    return (
      <>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <h4 className="text-center">{t('complementaryCertificate:languageSection')}</h4>
            </div>
            <div className="col-12">
              <FormControl>
                <InputLabel>{t('input:language')}</InputLabel>
                <Select
                  value={language}
                  onChange={handleLanguageChange('language')}
                  inputProps={{
                    name: 'language',
                    id: 'language',
                  }}
                >
                  {complementaryCertificateLanguages.map((language) => {
                    return (
                      <MenuItem key={language} value={language}>{language}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {
            !isFirstPage() ? (<Button onClick={() => changePage(-1)}>
              {t('buttonPrevious')}
            </Button>) : <div />
          }
          {
            !isLastPage() ? (<Button onClick={() => changePage(1)}>
              {t('buttonNext')}
            </Button>) : <div />
          }
          <Button color="secondary" onClick={handleDialogClose} >
            {t('buttonCancel')}
          </Button>
          {
            isLastPage() ? (<Button color="primary" onClick={saveComplementaryCourse}>
              {t('buttonSave')}
            </Button>) : <div />
          }
        </DialogActions>
      </>
    );
  }
}
