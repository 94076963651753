import React, { Component } from 'react';
import { getDateFormat, reformatPickerDateToDB } from '../../../utils/time';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Divider from '@material-ui/core/Divider';
import FileInput from '../../../../common/components/upload/FileInput';
import Form from '../../../../common/components/forms/Form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HistoryList from './HistoryList';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InstallationsManager from './InstallationsManager';
import { Link } from 'react-router-dom';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import uuidv4 from 'uuid/v4';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class PartNew extends Component {
  static propTypes = {
    part: PropTypes.object.isRequired,
    linkTo: PropTypes.string.isRequired,
    addPart: PropTypes.func.isRequired,
    editPart: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    partSettings: PropTypes.object.isRequired,
    installation: PropTypes.object.isRequired,
    installationList: PropTypes.array.isRequired,
    historyList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    previewMode: PropTypes.bool,
    editMode: PropTypes.bool,
    partStatusList: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.formData = {};
    this.state = {
      ...this.props.part,
      updatedFiles: [],
      deletedFiles: [],
    };
  }

  renderViewLabel = (preview, editMode, t) => {
    if (preview) {
      return t('fleet:previewPartSection');
    } else if (editMode) {
      return t('fleet:editPartSection');
    }

    return t('fleet:addPartSection');
  };

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      [name]: dateFromPicker,
    });
  };

  onChange = (formData, name) => {
    const { value } = formData[name];

    this.formData = formData;
    this.setState(() => ({ [name]: value }));
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  onFormValidated = (isFormValid) => {
    const { addPart, editPart, editMode, part } = this.props;
    const {
      name,
      partNumber,
      serialNumber,
      manufacturer,
      description,
      serviceHours,
      serviceMileage,
      eolMileage,
      serviceCycle,
      eolCycle,
      serviceDate,
      eolDate,
      files,
      updatedFiles,
      deletedFiles,
      nonSafetyRelated,
      partStatus,
      changeReason,
      milage,
      usageHours,
      activeSince,
    } = this.state;

    if (isFormValid && this.formData) {
      const data = {
        name,
        partNumber,
        serialNumber,
        manufacturer,
        description,
        nonSafetyRelated,
        serviceHours: serviceHours || null,
        serviceMileage: serviceMileage || null,
        eolMileage: eolMileage || null,
        serviceCycle: serviceCycle || null,
        eolCycle: eolCycle || null,
        serviceDate: reformatPickerDateToDB(serviceDate),
        eolDate: reformatPickerDateToDB(eolDate),
        partStatus,
        changeReason,
        milage,
        usageHours,
        activeSince: reformatPickerDateToDB(activeSince),
      };

      editMode
        ? editPart({ ...data, id: part.id }, updatedFiles, deletedFiles)
        : addPart(data, files);
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  renderButtons = (t) => {
    const { part, previewMode, linkTo, installation } = this.props;

    return (
      <div className="justify-content-center row pt-5">
        <InstallationsManager
          part={part}
          editMode="true"
          installation={installation}
        />
        <div className="col-auto">
          <Link to={linkTo}>
            <Button variant="raised">{t('buttonCancel')}</Button>
          </Link>
        </div>
        <div className="col-auto">
          {!previewMode && (
            <Button color="primary" variant="raised" onClick={this.sendHandler}>
              {t('buttonSave')}
            </Button>
          )}
        </div>
      </div>
    );
  };

  renderEditButton() {
    const { part } = this.props;

    return (
      <div className="col-auto">
        <Link
          to={{
            pathname: `/parts/edit/${part.id}`,
            state: { editMode: true },
          }}
        >
          <IconButton>
            <Icon>edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  attachPartFile = (file) => {
    const { files, updatedFiles } = this.state;
    const { editMode } = this.props;

    file.id = uuidv4();

    if (editMode) {
      this.setState({
        files: [...files, file],
        updatedFiles: [...updatedFiles, file],
      });
    } else {
      this.setState({
        files: [...files, file],
      });
    }
  };

  removePartFile = (file) => {
    const { files, deletedFiles, updatedFiles } = this.state;
    const { editMode } = this.props;

    const fileId = file.id;
    const preparedFiles = files.filter((file) => file.id !== fileId);
    let preparedDeleteFiles = deletedFiles;
    let preparedUpdatedFiles = updatedFiles;
    const isServerFile = _get(file, 'path', false);

    if (editMode) {
      if (isServerFile) {
        preparedDeleteFiles = [...deletedFiles, fileId];
      } else {
        preparedUpdatedFiles = updatedFiles.filter(
          (file) => file.id !== fileId,
        );
      }
    }

    this.setState({
      files: preparedFiles,
      deletedFiles: preparedDeleteFiles,
      updatedFiles: preparedUpdatedFiles,
    });
  };

  renderAttachButton = () => {
    const { addSimpleError, previewMode } = this.props;

    if (previewMode) {
      return <></>;
    }

    return (
      <span className="file-inputs">
        <CustomIconButton>
          <FileInput
            attachFile={this.attachPartFile}
            addSimpleError={addSimpleError}
            required
            isEmail
          />
          <Icon color="primary">attach_file</Icon>
        </CustomIconButton>
      </span>
    );
  };

  renderFiles = () => {
    const { previewMode } = this.props;
    const { files } = this.state;

    return files.map((file) => {
      const onClick = () => window.open(file.path, '_blank');
      const onDelete = () => this.removePartFile(file);

      if (previewMode) {
        return (
          <Chip
            key={`part-file-${file.id}`}
            label={file.name}
            onClick={onClick}
            className="m-1"
          />
        );
      }

      return (
        <Chip
          key={`part-file-${file.id}`}
          label={file.name}
          onDelete={onDelete}
          className="m-1"
        />
      );
    });
  };

  renderFilesSection = () => {
    const { previewMode, t } = this.props;
    const { files } = this.state;

    if (_isEmpty(files) && previewMode) {
      return <></>;
    }

    return (
      <>
        <div>
          <p>{t('fleet:attachments')}</p>
        </div>
        {this.renderAttachButton(previewMode)}
        <span>{this.renderFiles(previewMode)}</span>
      </>
    );
  };

  renderInstallationInfo = () => {
    const { t, previewMode, partStatusList, editMode } = this.props;
    const {
      serviceHours,
      serviceMileage,
      eolMileage,
      serviceDate,
      eolDate,
      usageHours,
      milage,
      partStatus,
    } = this.state;

    return (
      <>
        <div className="col-6">
          <MaterialInput
            label={t('input:Total usage hours')}
            name="usageHours"
            defaultValue={usageHours}
            margin="dense"
            disabled={previewMode}
            fullWidth
          />
        </div>
        <div className="col-6">
          <MaterialInput
            label={t('input:Total milage (km)')}
            name="milage"
            defaultValue={milage}
            margin="dense"
            disabled={previewMode}
            fullWidth
          />
        </div>
        <div className="col-6">
          <MaterialInput
            label={t('input:serviceHours')}
            name="serviceHours"
            defaultValue={serviceHours}
            margin="dense"
            validators={[new validators.IsNaturalNumber(t)]}
            disabled={previewMode}
            fullWidth
          />
        </div>
        <div className="col-6" />
        <div className="col-6">
          <MaterialInput
            label={t('input:serviceMileage')}
            name="serviceMileage"
            defaultValue={serviceMileage}
            margin="dense"
            validators={[new validators.IsNaturalNumber(t)]}
            disabled={previewMode}
            fullWidth
          />
        </div>
        <div className="col-6">
          <MaterialInput
            label={t('input:eolMileage')}
            name="eolMileage"
            defaultValue={eolMileage}
            margin="dense"
            validators={[new validators.IsNaturalNumber(t)]}
            disabled={previewMode}
            fullWidth
          />
        </div>
        <div className="col-6">
          <DateTimePicker
            label={t('input:serviceDate')}
            name="serviceDate"
            value={serviceDate}
            showMonthDropdown
            showTimeSelect={false}
            showYearDropdown
            handleDatePickerChange={this.handleDatePickerChange('serviceDate')}
            dateFormat={getDateFormat()}
            disabled={previewMode}
            acceptEmptyInput
            fullWidth
          />
        </div>
        <div className="col-6">
          <DateTimePicker
            label={t('input:eolDate')}
            name="eolDate"
            value={eolDate}
            showMonthDropdown
            showTimeSelect={false}
            showYearDropdown
            handleDatePickerChange={this.handleDatePickerChange('eolDate')}
            dateFormat={getDateFormat()}
            disabled={previewMode}
            acceptEmptyInput
            fullWidth
          />
        </div>
        <div className="col-6">
          <MaterialSelect
            id="partStatus"
            name="partStatus"
            label={t('input:partStatus')}
            className={previewMode ? 'svg-arrow-hidden' : ''}
            defaultValue={partStatus}
            options={partStatusList.map((partStatus) => ({
              value: partStatus.value,
              label: partStatus.key,
            }))}
            disabled={!editMode}
            validators={[new validators.IsRequired(t)]}
          />
        </div>
      </>
    );
  };

  render() {
    const { t, previewMode, editMode, historyList } = this.props;
    const {
      name,
      partNumber,
      serialNumber,
      manufacturer,
      nonSafetyRelated,
      description,
      changeReason,
      activeSince,
    } = this.state;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center part">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">train</Icon>
              <h1>
                {t('fleet:name')}
                <span className="h1-subheader">
                  {' '}
                  /{this.renderViewLabel(previewMode, editMode, t)}
                </span>
              </h1>
            </span>
          </header>
        </div>
        {previewMode && this.renderEditButton()}

        <div className="mt-4 col-12">
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <div className="row">
              <div className="col-6">
                <MaterialInput
                  label={t('input:name')}
                  autoFocus
                  name="name"
                  defaultValue={name}
                  margin="dense"
                  validators={[
                    new validators.IsRequired(t),
                    new validators.MaxLength(t, 200),
                  ]}
                  disabled={previewMode}
                  fullWidth
                />
              </div>
              <div className="col-6">
                <DateTimePicker
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={this.handleDatePickerChange(
                    'activeSince',
                  )}
                  dateFormat={getDateFormat()}
                  value={activeSince}
                  name="activeSince"
                  acceptEmptyInput
                  label={t('input:active since')}
                  disabled={previewMode}
                />
              </div>
              <div className="col-6">
                <MaterialInput
                  label={t('input:partNumber')}
                  name="partNumber"
                  defaultValue={partNumber}
                  margin="dense"
                  validators={[
                    new validators.IsRequired(t),
                    new validators.MaxLength(t, 200),
                  ]}
                  disabled={previewMode}
                  fullWidth
                />
              </div>
              <div className="col-6">
                <MaterialInput
                  label={t('input:serialNumber')}
                  name="serialNumber"
                  defaultValue={serialNumber}
                  margin="dense"
                  validators={[
                    new validators.IsRequired(t),
                    new validators.MaxLength(t, 200),
                  ]}
                  disabled={previewMode}
                  fullWidth
                />
              </div>
              <div className="col-6">
                <MaterialInput
                  label={t('input:manufacturer')}
                  name="manufacturer"
                  defaultValue={manufacturer}
                  margin="dense"
                  validators={[
                    new validators.IsRequired(t),
                    new validators.MaxLength(t, 200),
                  ]}
                  disabled={previewMode}
                  fullWidth
                />
              </div>
              <div className="col-6">
                <MaterialInput
                  label={t('input:description')}
                  name="description"
                  defaultValue={description}
                  margin="dense"
                  validators={[new validators.IsRequired(t)]}
                  disabled={previewMode}
                  fullWidth
                />
              </div>
            </div>
            <div className="row">
              {this.renderInstallationInfo()}
              <div className="col-12">
                <FormControl disabled={previewMode}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={nonSafetyRelated}
                        onChange={this.handleCheckboxChange('nonSafetyRelated')}
                      />
                    }
                    label={t('input:nonSafetyRelated')}
                  />
                </FormControl>
              </div>
              <div className="col-12">{this.renderFilesSection()}</div>
            </div>
            <div className="my-3">
              <Divider />
            </div>
            <div className="my-3">
              <HistoryList editMode={editMode} historyList={historyList} />
            </div>
            {editMode ? (
              <div class="row justify-content-md-center">
                <div className="col-6">
                  <MaterialInput
                    label={t('input:Change reason')}
                    name="changeReason"
                    defaultValue={changeReason}
                    stateValue={changeReason}
                    margin="dense"
                    validators={[new validators.IsRequired(t)]}
                    fullWidth
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="my-3">{this.renderButtons(t)}</div>
          </Form>
        </div>
      </div>
    );
  }
}
