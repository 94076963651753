import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import TextField from '@material-ui/core/TextField';

@withNamespaces()
export default class Text extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    numeric: PropTypes.bool,
    preview: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    t: PropTypes.func.isRequired,
  };

  render() {
    const { handleChange, type, label, defaultValue, preview, t } = this.props;
    const inputLabel = `input:${label}`;

    return (
      <TextField
        margin="dense"
        onChange={handleChange(label)}
        type={type}
        label={t(inputLabel)}
        value={defaultValue}
        disabled={preview}
      />
    );
  }
}
