import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { sortString } from '../../../../utils/sort';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  reportFleetSubcategory: {
    paddingLeft: '3em',
  },
});

@withNamespaces()
@withStyles(styles)
export default class ReportFleetCategoryList extends Component {
  static propTypes = {
    fleetReportCategoryList: PropTypes.array,
    removeFleetReportCategory: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderFleetReportCategoryList = (reportFleetCategoryList) => {
    const {
      classes,
      removeFleetReportCategory,
      handleEditOpen,
      onElementDelete,
    } = this.props;

    const rows = [];

    const categories = reportFleetCategoryList.filter(
      (category) => !category.parent,
    );
    categories.sort((catA, catB) => sortString(catA.name, catB.name));

    categories.forEach((reportFleetCategory) => {
      rows.push(
        <TableRow key={`report-fleet-category-${reportFleetCategory.id}`}>
          <TableCell>{reportFleetCategory.name}</TableCell>
          <TableCell numeric>
            <IconButton onClick={() => handleEditOpen(reportFleetCategory)}>
              <Icon color="primary">mode_edit</Icon>
            </IconButton>
            <IconButton
              onClick={() =>
                onElementDelete(() =>
                  removeFleetReportCategory(reportFleetCategory),
                )
              }
            >
              <Icon color="primary">delete</Icon>
            </IconButton>
          </TableCell>
        </TableRow>,
      );

      const subcategories = reportFleetCategoryList.filter(
        (category) => category.parent === reportFleetCategory.id,
      );
      subcategories.sort((catA, catB) => sortString(catA.name, catB.name));

      subcategories.forEach((subcategory) => {
        rows.push(
          <TableRow key={`report-fleet-category-${subcategory.id}`}>
            <TableCell className={classes.reportFleetSubcategory}>
              {subcategory.name}
            </TableCell>
            <TableCell numeric>
              <IconButton onClick={() => handleEditOpen(subcategory)}>
                <Icon color="primary">mode_edit</Icon>
              </IconButton>
              <IconButton
                onClick={() =>
                  onElementDelete(() => removeFleetReportCategory(subcategory))
                }
              >
                <Icon color="primary">delete</Icon>
              </IconButton>
            </TableCell>
          </TableRow>,
        );
      });
    });

    return rows;
  };

  render() {
    const { classes, handleAddOpen, fleetReportCategoryList, t } = this.props;

    if (_isEmpty(fleetReportCategoryList)) {
      return (
        <div className="pl-sm-5">
          <Button color="primary" onClick={handleAddOpen}>
            ADD FLEET CATEGORY
          </Button>
        </div>
      );
    }

    return (
      <div className="pl-sm-5">
        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table:name')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderFleetReportCategoryList(fleetReportCategoryList)}
              </TableBody>
            </Table>
          </div>
        </div>
        <Button color="primary" onClick={handleAddOpen}>
          ADD FLEET CATEGORY
        </Button>
      </div>
    );
  }
}
