import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import every from 'lodash/every';

import MaterialInput from './MaterialInput';
import Input from './Input';
import MaterialSelect from './MaterialSelect';
import MaterialMultipleSelect from './MaterialMultipleSelect';
import AutoSuggestion from '../AutoSuggestion';


export default class Form extends React.Component {
  static propTypes = {
    onFormValidated: PropTypes.func,
    validateForm: PropTypes.func,
    onChange: PropTypes.func,
    forceInitialValidation: PropTypes.bool,
    registerForm: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]).isRequired,
  };

  static defaultProps = {
    forceInitialValidation: false,
    validateForm: () => true,
    onChange: () => {},
    onFormValidated: () => {},
  };

  static FORM_COMPONENTS = [MaterialInput, Input, MaterialSelect, MaterialMultipleSelect, AutoSuggestion];

  constructor(props) {
    super(props);
    this.state = {};
    this.inputs = {};
    this.props.registerForm(this.validateForm);
  }

  registerInput = (name, value, validateInput) => {
    this.inputs[name] = { validateInput, value, isValid: false, errors: [] };
  };

  inputValidatedHandler = (name, value, { isValid, errors, disabled }) => {
    this.inputs[name] = {
      ...this.inputs[name], value, isValid, errors, disabled
    };

    this.setState({
      [name]: {
        value,
        errors,
        isValid
      }
    }, () => {
      const formData = mapValues(this.inputs, (input) => ({ value: input.value, disabled: input.disabled }));

      this.props.onChange(formData, name, this);
    });
  };
  invalidateRequired = (name, errorTxt) => {
    this.inputs[name].value = '';
    this.inputs[name].isValid = false;

    this.setState({
      [name]: {
        value: '',
        errors: [errorTxt],
        isValid: false
      }
    }, () => {
      const formData = mapValues(this.inputs, (input) => ({ value: input.value, disabled: input.disabled }));

      this.props.onChange(formData, name, this);
    });
  }

  validateForm = async () => {
    for (const input of map(this.inputs, (val) => val)) {
      await input.validateInput();
    }

    let inputValidationResult = every(this.inputs, (value, key) => this.inputs[key].isValid);
    const formData = mapValues(this.inputs, ({ value }) => value);

    inputValidationResult = inputValidationResult && this.props.validateForm(formData);
    this.props.onFormValidated(inputValidationResult);

    return inputValidationResult;
  };

  prepareItem(item, index = 0) {
    if (!isString(item) && Form.FORM_COMPONENTS.some((componentType) => item.type === componentType)) {

      const formData = this.state;
      const { name } = item.props;

      return React.cloneElement(item, {
        ...item.props,
        validationError: formData[name] && formData[name].errors,
        inputValidated: this.inputValidatedHandler,
        registerInput: this.registerInput,
        forceInitialValidation: this.props.forceInitialValidation,
        key: index
      });
    } else if (!isString(item) && item.props.children) {
      return React.cloneElement(item, { ...item.props, key: index }, this.getPreparedChildren(item.props.children));
    }

    return item;
  }

  getPreparedChildren(children) {
    if (!isArray(children)) {
      return this.prepareItem(children);
    }

    return children.map((item, index) => this.prepareItem(item, index));
  }

  render() {
    const extendedChildren = this.getPreparedChildren(this.props.children);

    return (
      <form>
        {extendedChildren}
      </form>
    );
  }
}
