import { URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { urlJoin } from '../../utils';

export const FETCH_VEHICLE_TYPES_REQUEST = 'FETCH_VEHICLE_TYPES_REQUEST';
export const FETCH_VEHICLE_TYPES_SUCCESS = 'FETCH_VEHICLE_TYPES_SUCCESS';
export const FETCH_VEHICLE_TYPES_FAILURE = 'FETCH_VEHICLE_TYPES_FAILURE';
export const ADD_VEHICLE_TYPE = 'ADD_VEHICLE_TYPE';
export const EDIT_VEHICLE_TYPE = 'EDIT_VEHICLE_TYPE';
export const REMOVE_VEHICLE_TYPE = 'REMOVE_VEHICLE_TYPE';
export const REMOVE_VEHICLE_VEHICLE_TYPE = 'REMOVE_VEHICLE_VEHICLE_TYPE';

export function fetchVehicleTypesRequest() {
  return {
    type: FETCH_VEHICLE_TYPES_REQUEST
  };
}

export function fetchVehicleTypesSuccess(vehicleTypes) {
  return {
    type: FETCH_VEHICLE_TYPES_SUCCESS,
    payload: {
      vehicleTypes
    }
  };
}

export function fetchVehicleTypesFailure() {
  return {
    type: FETCH_VEHICLE_TYPES_FAILURE
  };
}

export function addVehicleTypeSuccess(vehicleType) {
  return {
    type: ADD_VEHICLE_TYPE,
    payload: {
      vehicleType
    }
  };
}

export function editVehicleTypeSuccess(vehicleType) {
  return {
    type: EDIT_VEHICLE_TYPE,
    payload: {
      vehicleType
    }
  };
}

export function removeVehicleTypeSuccess(vehicleType) {
  return {
    type: REMOVE_VEHICLE_TYPE,
    payload: {
      vehicleType
    }
  };
}

export function removeVehiclesVehicleTypeSuccess(vehicleType) {
  return {
    type: REMOVE_VEHICLE_VEHICLE_TYPE,
    payload: {
      vehicleType
    }
  };
}

export function fetchVehicleTypes() {
  return (dispatch) => {
    dispatch(fetchVehicleTypesRequest());

    return axios.get(URLS.vehicleTypes)
      .then(({ data }) => dispatch(fetchVehicleTypesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchVehicleTypesFailure());
        dispatch(addError(`Error during vehicle types fetching ${error.message}`, error));
      });
  };
}

export function addVehicleType(vehicleType) {
  return (dispatch) => {
    return axios.post(URLS.vehicleTypes, vehicleType)
      .then(({ data }) => dispatch(addVehicleTypeSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during vehicle type adding ${error.message}`, error));
      });
  };
}

export function removeVehicleType(vehicleType) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.vehicleTypes, vehicleType.id))
      .then(() => {
        dispatch(removeVehicleTypeSuccess(vehicleType));
        dispatch(removeVehiclesVehicleTypeSuccess(vehicleType));
      })
      .catch((error) => {
        dispatch(addError(`Error during vehicle type removing ${error.message}`, error));
      });
  };
}
