import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import _isNil from 'lodash/isNil';
import _invert from 'lodash/invert';
import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';

import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';

import TabContainer from '../../../../common/components/TabContainer';
import Table from '../../../../common/components/Table';
import { TABS, URLS_RAIL as URLS } from '../../../../constants';
import {
  calculateCourseProgress,
  reformatPickerDateFromDB,
  reformatPickerDateTimeFromDB,
  getDistance,
  calculateAccumulatedTime,
  dateTimeDiff,
} from '../../../utils/time';
import { renderPermission } from '../../../decorators/permissions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { tabActiveHeader, tabInactiveHeader, tabCompletedHeader } from './tabsHeaders';

const headersMap = {
  [TABS.active]: tabActiveHeader,
  [TABS.inactive]: tabInactiveHeader,
  [TABS.completed]: tabCompletedHeader
};

const coursesFilters = {
  [TABS.active]: (assignedCourse) => {
    const activatedTime = reformatPickerDateTimeFromDB(assignedCourse.scheduleTime);

    if (_isNil(assignedCourse.scheduleTime)) {
      return assignedCourse.isActive === true && _isNil(assignedCourse.endDate);
    }
    const distance = getDistance(activatedTime);

    return (assignedCourse.isActive === true ||
      (assignedCourse.isActive === false && distance < 0)) &&
      _isNil(assignedCourse.endDate);

  },
  [TABS.inactive]: (assignedCourse) => {
    const activatedTime = reformatPickerDateTimeFromDB(assignedCourse.scheduleTime);

    if (_isNil(assignedCourse.scheduleTime)) {
      return assignedCourse.isActive === false;
    }
    const distance = getDistance(activatedTime);

    return assignedCourse.isActive === false && distance > 0;
  },
  [TABS.completed]: (assignedCourse) => {
    return !_isNil(assignedCourse.endDate);
  }
};

@withNamespaces()
export default class TabsView extends Component {
  static propTypes = {
    assignedCourseList: PropTypes.array,
    handleSort: PropTypes.func.isRequired,
    sortAsc: PropTypes.bool.isRequired,
    columnToSort: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    handleActivateCourse: PropTypes.func.isRequired,
    openRemoveDialog: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    currentTab: TABS.active,
  };

  handleChange = (event, value) => {
    this.setState({
      currentTab: value
    });
  };

  @renderPermission(PERMISSIONS.assignedCourseDelete)
  renderTrashIcon(course) {
    return <IconButton onClick={() => this.props.openRemoveDialog(course)}><Icon color="primary">delete</Icon></IconButton>;
  }

  @renderPermission(PERMISSIONS.editCourseInCourseList)
  renderEditIcon(courseId) {
    return (
      <Link to={`/course_list/edit/${courseId}/`}>
        <IconButton><Icon color="primary">mode_edit</Icon></IconButton>
      </Link>
    );
  }

  renderManageIcons(course, isInactiveTab) {
    const { handleActivateCourse } = this.props;
    const courseId = course.id;

    return (
      <div className="icons-display-wrapper" >
        { this.renderEditIcon(courseId)}
        { this.renderTrashIcon(course) }
        { isInactiveTab && <IconButton onClick={() => handleActivateCourse(courseId)}><Icon color="primary">assignment</Icon></IconButton> }
      </div>
    );
  }

  calculateCourseDuration(activeCourse) {
    return _isObject(activeCourse.courseData.lessons) ? _reduce(activeCourse.courseData.lessons, (sum, lesson) => {
      if (!lesson.startDate || !lesson.endDate) {
        return sum;
      }

      const distance = dateTimeDiff(reformatPickerDateTimeFromDB(lesson.endDate), reformatPickerDateTimeFromDB(lesson.startDate));
      const newSum = sum += distance > 0 ? distance : 0;

      return newSum;
    }, 0) : 0;
  }

  renderLinearProgress(activeCourse) {
    const progress = calculateCourseProgress(this.calculateCourseDuration(activeCourse), activeCourse.courseData.estimatedTime);

    return <LinearProgress variant="determinate" value={progress} />;
  }

  mapActiveCourseList = (activeCourse) => {
    const courseId = activeCourse.id;

    const accumulatedTime = calculateAccumulatedTime(activeCourse.courseData.lessons).accumulatedTotal;

    return ({
      id: courseId,
      startDate: this.makeLink(courseId, reformatPickerDateFromDB(activeCourse.startDate, false)),
      firstName: this.makeLink(courseId, activeCourse.user.firstName),
      lastName: this.makeLink(courseId, activeCourse.user.lastName),
      name: this.makeLink(courseId, activeCourse.courseData.name),
      progress: this.renderLinearProgress(activeCourse),
      timeRequired: this.makeLink(courseId, activeCourse.courseData.minTime),
      timeTaken: this.makeLink(courseId, accumulatedTime),
      icons: this.renderManageIcons(activeCourse, false),
    });
  };

  mapInactiveCourseList = (inactiveCourse) => {
    const courseId = inactiveCourse.id;

    return ({
      id: courseId,
      firstName: this.makeLink(courseId, inactiveCourse.user.firstName),
      lastName: this.makeLink(courseId, inactiveCourse.user.lastName),
      name: this.makeLink(courseId, inactiveCourse.courseData.name),
      timeRequired: this.makeLink(courseId, inactiveCourse.courseData.minTime),
      scheduleTime: this.makeLink(courseId, reformatPickerDateFromDB(inactiveCourse.scheduleTime, false)),
      icons: this.renderManageIcons(inactiveCourse, true),
    });
  };

  mapCompletedCourseList = (completedCourse) => {
    const courseId = completedCourse.id;
    const accumulatedTime = calculateAccumulatedTime(completedCourse.courseData.lessons).accumulatedTotal;

    return ({
      id: courseId,
      firstName: this.makeLink(courseId, completedCourse.user.firstName),
      lastName: this.makeLink(courseId, completedCourse.user.lastName),
      name: this.makeLink(courseId, completedCourse.courseData.name),
      dateCompleted: this.makeLink(courseId, reformatPickerDateFromDB(completedCourse.endDate, false)),
      timeTaken: this.makeLink(courseId, accumulatedTime),
      certificate: this.makeCertificateLink(courseId)
    });
  };

  makeLink = (courseId, text) => {
    return (
      <Link className="table-row-link" to={`/course_list/details/${courseId}`}>
        {text}
      </Link>
    );
  };

  makeCertificateLink = (courseId) => {
    const href = `${URLS.courseCertificate}${courseId}/`;

    return (
      <a className="table-row-link" href={href} target="_blank">
        <IconButton><Icon color="primary">print</Icon></IconButton>
      </a>
    );
  };

  getCoursesData(type) {
    const { assignedCourseList } = this.props;
    const coursesMappers = {
      [TABS.active]: this.mapActiveCourseList,
      [TABS.inactive]: this.mapInactiveCourseList,
      [TABS.completed]: this.mapCompletedCourseList,
    };

    return assignedCourseList.filter(coursesFilters[type]).map(coursesMappers[type]);
  }

  renderTab(currentTab) {
    const { handleSort, sortAsc, columnToSort, handleSearch, search, t } = this.props;

    if (currentTab in _invert(TABS)) {
      return (
        <TabContainer>
          <Table
            data={this.getCoursesData(currentTab)}
            header={headersMap[currentTab]}
            handleSort={handleSort}
            sortAsc={sortAsc}
            columnToSort={columnToSort}
            handleSearch={handleSearch}
            search={search}
            searchLabel={t('input:searchLabel')}
          />
        </TabContainer>
      );
    }

    return (<div />);
  }

  render() {
    const { t } = this.props;
    const { currentTab } = this.state;

    return (
      <div>
        <Paper>
          <Tabs
            value={currentTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value={TABS.active} label={t('course:activeTab')} />
            <Tab value={TABS.inactive} label={t('course:inactiveTab')} />
            <Tab value={TABS.completed} label={t('course:completedTab')} />
          </Tabs>
        </Paper>
        {this.renderTab(currentTab)}
      </div>
    );
  }
}
