import React, { Component } from 'react';
import { addQuestion, editQuestion } from '../../../actions/questions';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import FileInput from '../../../../common/components/upload/FileInput';
import Form from '../../../../common/components/forms/Form';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import SelectCollections from '../../Select/SelectCollections';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { fetchQuestionCategories } from '../../../actions/questionCategories';
import uuidv4 from 'uuid/v4';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  input: {
    fontSize: '2rem',
  },
  formControl: {
    width: '100%',
  },
  root: {
    width: '100%',
  },
});

const mapStateToProps = (state) => {
  return {
    questionCategoryList: state.questionCategories.questionCategoryList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addQuestion: (question, files, goBack) =>
      dispatch(addQuestion(question, files, goBack)),
    editQuestion: (question, updatedFiles, deletedFiles) =>
      dispatch(editQuestion(question, updatedFiles, deletedFiles)),
    fetchQuestionCategories: () => dispatch(fetchQuestionCategories()),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class Question extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    addQuestion: PropTypes.func.isRequired,
    editQuestion: PropTypes.func.isRequired,
    fetchQuestionCategories: PropTypes.func.isRequired,
    questionCategoryList: PropTypes.array,
    preview: PropTypes.bool,
    question: PropTypes.object,
    editMode: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  state = {
    formKey: 0,
    addAnother: false,
    value: _get(this.props.question, 'correctAnswer', '1'),
    isFormValid: false,
    questionName: _get(this.props.question, 'questionName', ''),
    answers: {
      a: {
        id: _get(this.props.question, 'answers.a.id', ''),
        name: _get(this.props.question, 'answers.a.name', ''),
      },
      b: {
        id: _get(this.props.question, 'answers.b.id', ''),
        name: _get(this.props.question, 'answers.b.name', ''),
      },
      c: {
        id: _get(this.props.question, 'answers.c.id', ''),
        name: _get(this.props.question, 'answers.c.name', ''),
      },
      d: {
        id: _get(this.props.question, 'answers.d.id', ''),
        name: _get(this.props.question, 'answers.d.name', ''),
      },
    },
    collection: _get(this.props.question, 'collection', []),
    questionId: _get(this.props.question, 'id', ''),
    files: _get(this.props.question, 'files', []),
    updatedFiles: [],
    deletedFiles: [],
  };

  componentDidMount() {
    this.props.fetchQuestionCategories();
  }

  handleChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  handleSelectChange = (name) => (value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  renderRadioLabel = (t) => {
    return (
      <div className="col-auto text-right pt-3 px-0 d-none d-sm-block">
        <FormLabel component="legend">{t('test:correctAnswer')}</FormLabel>
      </div>
    );
  };

  renderAnswerRow = (answerLabel, order) => {
    const { classes, preview, t } = this.props;
    const { value } = this.state;
    const answer = `test:${answerLabel}`;
    const label = `test:${answerLabel}${'Answer'}`;

    return (
      <div className="row justify-content-center">
        <div className="col-auto align-self-center d-none d-sm-block">
          {t(answer)}
        </div>
        <div className="col py-3">
          <MaterialInput
            className={classes.root}
            disabled={preview}
            name={answerLabel}
            label={t(label)}
            validators={[
              new validators.IsRequired(t),
              new validators.MaxLength(t, 500),
            ]}
            defaultValue={_get(this.state.answers[answerLabel], 'name', '')}
          />
        </div>
        <div className="row">
          <div className="col-auto align-self-center mr-2">
            <Radio
              checked={value.toString() === order}
              disabled={preview}
              color="primary"
              onChange={this.handleChange('value')}
              value={order.toString()}
              name="radio-button-demo"
              aria-label={answerLabel}
            />
          </div>
          <div className="col-auto align-self-center d-block d-sm-none">
            {t('test:correctAnswer')}
          </div>
        </div>
      </div>
    );
  };

  prepareAnswers = (answerOrder, answer, answerId) => {
    const { value } = this.state;
    const { editMode } = this.props;
    const newAnswer = {
      order: answerOrder,
      name: answer,
      isCorrect: value.toString() === answerOrder.toString(),
    };

    if (editMode) {
      return {
        ...newAnswer,
        id: answerId,
      };
    }

    return newAnswer;
  };

  prepareCategories = (collection) => {
    return collection.map((category) => ({
      id: category.id || category.value,
    }));
  };

  prepareQuestion = () => {
    const { questionName, answers, collection, questionId } = this.state;
    const { editMode } = this.props;
    const preparedQuestion = {
      name: questionName,
      answers: [
        this.prepareAnswers('1', answers.a.name, answers.a.id),
        this.prepareAnswers('2', answers.b.name, answers.b.id),
        this.prepareAnswers('3', answers.c.name, answers.c.id),
        this.prepareAnswers('4', answers.d.name, answers.d.id),
      ],
      categories: _isEmpty(collection)
        ? []
        : this.prepareCategories(collection),
    };

    if (editMode) {
      return {
        ...preparedQuestion,
        id: questionId,
      };
    }

    return preparedQuestion;
  };

  updateStateAndSaveQuestion = (e) => {
    this.setState({
      ...this.state,
      addAnother: true,
    });

    this.sendHandler(e);
  };

  renderSaveAndAddAnotherButton = (t) => {
    const { editMode } = this.props;

    if (editMode) {
      return <div />;
    }

    return (
      <div className="col-auto">
        <Link to="/question_bank/new">
          <Button
            color="primary"
            variant="raised"
            onClick={(event) => this.updateStateAndSaveQuestion(event)}
          >
            {t('buttonSaveAndAddAnother')}
          </Button>
        </Link>
      </div>
    );
  };

  renderButtons = (t) => {
    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to="/question_bank">
            <Button variant="raised">{t('buttonCancel')}</Button>
          </Link>
        </div>
        <div className="col-auto">
          <Button color="primary" variant="raised" onClick={this.sendHandler}>
            {t('buttonSave')}
          </Button>
        </div>
        {this.renderSaveAndAddAnotherButton(t)}
      </div>
    );
  };

  onChange = (formData) => {
    this.formData = formData;
    _each(formData, ({ value }, key) => {
      if (key !== 'questionName') {
        this.setState(_set(this.state, `answers.${key}.name`, value));
      }
    });

    this.setState({
      questionName: formData.questionName.value,
    });
  };

  onFormValidated = (isFormValid) => {
    const { editMode } = this.props;
    const { updatedFiles, deletedFiles } = this.state;

    if (isFormValid && this.formData) {
      const question = this.prepareQuestion();

      editMode
        ? this.props.editQuestion(question, updatedFiles, deletedFiles)
        : this.performAction(question);

      if (this.state.addAnother) {
        this.setState({
          addAnother: false,
          value: '1',
          isFormValid: false,
          questionName: '',
          answers: {},
          collection: '',
          questionId: '',
          formKey: this.state.formKey + 1,
          files: [],
          updatedFiles: [],
          deletedFiles: [],
        });
      }
    }
  };

  attachQuestionFile = (file) => {
    const { editMode } = this.props;
    const { files, updatedFiles } = this.state;

    file.id = uuidv4();

    if (editMode) {
      this.setState({
        ...this.state,
        files: [...files, file],
        updatedFiles: [...updatedFiles, file],
      });
    } else {
      this.setState({
        ...this.state,
        files: [...files, file],
      });
    }
  };

  removeQuestionFile = (fileId) => {
    const { editMode } = this.props;
    const { files, deletedFiles, updatedFiles } = this.state;

    if (editMode) {
      this.setState({
        ...this.state,
        files: files.filter((file) => file.id !== fileId),
        deletedFiles: [...deletedFiles, fileId],
        updatedFiles: updatedFiles.filter((file) => file.id !== fileId),
      });
    } else {
      this.setState({
        ...this.state,
        files: files.filter((file) => file.id !== fileId),
      });
    }
  };

  renderAttachButton = (preview) => {
    if (preview) {
      return <></>;
    }

    return (
      <span className="file-inputs">
        <CustomIconButton>
          <FileInput
            attachFile={this.attachQuestionFile}
            addSimpleError={addSimpleError}
            required
          />
          <Icon color="primary">attach_file</Icon>
        </CustomIconButton>
      </span>
    );
  };

  renderFiles = (preview) => {
    const { files } = this.state;

    return files.map((file) => {
      const onClick = preview
        ? () => window.open(file.path, '_blank')
        : () => {};
      const onDelete = preview
        ? () => {}
        : () => this.removeQuestionFile(file.id);

      if (preview) {
        return (
          <Chip
            key={`report-file-${file.id}`}
            label={file.name}
            onClick={onClick}
            className="m-1"
          />
        );
      }

      return (
        <Chip
          key={`report-file-${file.id}`}
          label={file.name}
          onDelete={onDelete}
          className="m-1"
        />
      );
    });
  };

  performAction = (question) => {
    const { addAnother, files } = this.state;

    addAnother
      ? this.props.addQuestion(question, files, '/question_bank/new')
      : this.props.addQuestion(question, files, '/question_bank');
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation; // this function can be used for full form validation
  };

  prepareCollection = (collection) => {
    if (_isEmpty(collection) || typeof collection[0].label !== 'undefined') {
      return collection;
    }

    return collection.map((category) => ({
      value: category.id,
      label: category.name,
    }));
  };

  render() {
    const { classes, questionCategoryList, preview, t } = this.props;
    const { questionName, collection, formKey } = this.state;

    return (
      <Form
        key={formKey}
        onChange={this.onChange}
        validateForm={this.validateForm}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <div>
          <FormControl
            className={classes.formControl}
            margin="dense"
            disabled={false}
          >
            <MaterialInput
              name="questionName"
              label={t('input:question')}
              disabled={preview}
              defaultValue={questionName}
              className={classes.input}
              validators={[new validators.IsRequired(t)]}
            />
          </FormControl>
        </div>
        {this.renderRadioLabel(t)}
        <div className="col-12">
          {this.renderAnswerRow('a', '1')}
          {this.renderAnswerRow('b', '2')}
          {this.renderAnswerRow('c', '3')}
          {this.renderAnswerRow('d', '4')}
        </div>
        <div className="col-12 my-3">
          <div>
            <p>{t('report:attachments')}</p>
          </div>
          {this.renderAttachButton(preview)}
          <span>{this.renderFiles(preview)}</span>
        </div>
        <div className="col-12 pt-3">
          <SelectCollections
            preview={preview}
            collection={this.prepareCollection(collection)}
            collectionList={questionCategoryList}
            handleSelectChange={this.handleSelectChange}
            placeholder={t('input:categoriesSelectPlaceholder')}
          />
        </div>
        <div className="col-12">{!preview && this.renderButtons(t)}</div>
      </Form>
    );
  }
}
