import { capitalizeFirstLetter, urlJoin } from '../../utils';

import { URLS } from '../../constants';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { addError } from './errors';
import axios from 'axios';
import history from '../store/history';

export const FETCH_ASSIGNED_TESTS_PAGINATION_REQUEST =
  'FETCH_ASSIGNED_TESTS_PAGINATION_REQUEST';
export const FETCH_ASSIGNED_TESTS_PAGINATION_SUCCESS =
  'FETCH_ASSIGNED_TESTS_PAGINATION_SUCCESS';
export const FETCH_ASSIGNED_TESTS_REQUEST = 'FETCH_ASSIGNED_TESTS_REQUEST';
export const FETCH_ASSIGNED_TESTS_SUCCESS = 'FETCH_ASSIGNED_TESTS_SUCCESS';
export const FETCH_ASSIGNED_TESTS_FAILURE = 'FETCH_ASSIGNED_TESTS_FAILURE';
export const FETCH_ASSIGNED_TEST_REQUEST = 'FETCH_ASSIGNED_TEST_REQUEST';
export const FETCH_ASSIGNED_TEST_SUCCESS = 'FETCH_ASSIGNED_TEST_SUCCESS';
export const FETCH_ASSIGNED_TEST_FAILURE = 'FETCH_ASSIGNED_TEST_FAILURE';
export const REMOVE_ASSIGNED_TEST = 'REMOVE_ASSIGNED_TEST';
export const EDIT_ASSIGNED_TEST = 'EDIT_ASSIGNED_TEST';
export const ASSIGN_TEST_SUCCESS = 'ASSIGN_TEST_SUCCESS';
export const RESET_ASSIGN_TEST_SUCCESS = 'RESET_ASSIGN_TEST_SUCCESS';

const sortingPossibitilies = {
  firstName: 'user__first_name',
  lastName: 'user__last_name',
  name: 'test__name',
};

export function fetchAssignedTestsPaginationRequest() {
  return {
    type: FETCH_ASSIGNED_TESTS_PAGINATION_REQUEST,
  };
}

export function fetchAssignedTestsPaginationSuccess(assignedTests) {
  return {
    type: FETCH_ASSIGNED_TESTS_PAGINATION_SUCCESS,
    payload: {
      assignedTests,
    },
  };
}

export function fetchAssignedTestsRequest() {
  return {
    type: FETCH_ASSIGNED_TESTS_REQUEST,
  };
}

export function fetchAssignedTestsSuccess(assignedTests) {
  return {
    type: FETCH_ASSIGNED_TESTS_SUCCESS,
    payload: {
      assignedTests,
    },
  };
}

export function fetchAssignedTestsFailure() {
  return {
    type: FETCH_ASSIGNED_TESTS_FAILURE,
  };
}

export function removeAssignedTestSuccess(assignedTest) {
  return {
    type: REMOVE_ASSIGNED_TEST,
    payload: {
      assignedTest,
    },
  };
}

export function editAssignedTestSuccess(assignedTest) {
  return {
    type: EDIT_ASSIGNED_TEST,
    payload: {
      assignedTest,
    },
  };
}

export function assignTestSuccess() {
  return {
    type: ASSIGN_TEST_SUCCESS,
  };
}

export function resetAssignTestSuccess() {
  return {
    type: RESET_ASSIGN_TEST_SUCCESS,
  };
}

export function fetchAssignedTestRequest() {
  return {
    type: FETCH_ASSIGNED_TEST_REQUEST,
  };
}

export function fetchAssignedTestSuccess(assignedTest) {
  return {
    type: FETCH_ASSIGNED_TEST_SUCCESS,
    payload: {
      assignedTest,
    },
  };
}

export function fetchAssignedTestFailure() {
  return {
    type: FETCH_ASSIGNED_TEST_FAILURE,
  };
}

export function fetchAssignedTests(sortingData, paginationParams) {
  return (dispatch) => {
    const params = [];

    if (paginationParams) {
      dispatch(fetchAssignedTestsPaginationRequest());

      params.push(paginationParams);
    } else {
      dispatch(fetchAssignedTestsRequest());

      if (sortingData) {
        if (!_isEmpty(sortingData.search)) {
          params.push(`search=${sortingData.search}`);
        }

        if (sortingData.userId) {
          params.push(`user__id=${sortingData.userId}`);
        }

        if (!_isEmpty(sortingData.columnToSort)) {
          params.push(
            `ordering=${sortingData.sortAsc ? '' : '-'}${
              sortingPossibitilies[sortingData.columnToSort]
            }`,
          );
        }
      }
    }

    const queryParameters = _isEmpty(params) ? '' : `?${params.join('&')}`;

    return axios
      .get(urlJoin(URLS.assignedTests, queryParameters))
      .then(({ data }) =>
        dispatch(
          paginationParams
            ? fetchAssignedTestsPaginationSuccess(data)
            : fetchAssignedTestsSuccess(data),
        ),
      )
      .catch((error) => {
        dispatch(fetchAssignedTestsFailure());
        dispatch(
          addError(
            `Error during assigned exams fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function removeAssignedTest(assignedTest) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.assignedTests, assignedTest.id))
      .then(() => dispatch(removeAssignedTestSuccess(assignedTest)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during assigned exam removing ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function editAssignedTest(testId, testData, returnPath) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.assignedTests, testId), testData)
      .then(({ data }) => {
        dispatch(editAssignedTestSuccess(data));
        returnPath && history.push(returnPath);
      })
      .catch((error) => {
        dispatch(
          addError(
            `Error during assigned exam editing ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function assignTest(assignedData) {
  return (dispatch) => {
    return axios
      .post(URLS.assignedTests, assignedData)
      .then(() => dispatch(assignTestSuccess()))
      .catch((error) => {
        const errorData = _get(
          error,
          'response.data[0]',
          `error during exam assigning ${error.message}`,
        );

        dispatch(addError(capitalizeFirstLetter(errorData), error));
      });
  };
}

export function resetAssignTestFlag() {
  return (dispatch) => dispatch(resetAssignTestSuccess());
}

export function fetchAssignedTest(testId) {
  return (dispatch) => {
    dispatch(fetchAssignedTestRequest());

    return axios
      .get(urlJoin(URLS.assignedTests, testId))
      .then(({ data }) => dispatch(fetchAssignedTestSuccess(data)))
      .catch((error) => {
        dispatch(fetchAssignedTestFailure());
        dispatch(
          addError(
            `Error during assigned exam fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function notifyAssignedTests(userId) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.notifyAssignedTest, userId), { isNotified: true })
      .catch((error) => {
        dispatch(addError(`Error during notify exam ${error.message}`, error));
      });
  };
}
