import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';

import '../../styles/components/table.scss';

import { CHOICE_THROTTLING } from '../../constants';
const radioButtonType = 'radio';

@withNamespaces()
export default class CustomFilter extends Component {
  static propTypes = {
    groups: PropTypes.array,
    roles: PropTypes.array,
    radioButton: PropTypes.bool,
    handleFilter: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isFilterUsed: PropTypes.bool,
    filterSettings: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      values: this.props.filterSettings,
    };

    this.handleFilter = _debounce(this.props.handleFilter, CHOICE_THROTTLING);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterSettings !== prevProps.filterSettings) {
      this.updateChosen();
    }
  }

  isRadioButton = (event) => {
    return event.target.type === radioButtonType;
  };

  updateChosen = () => {
    this.setState({
      values: this.props.filterSettings,
    });
  };

  throttledChoice = (values, event) => {
    const { radioButton } = this.props;

    values = values.filter((value)=> value && value.id);
    const allChoices = [...this.props.groups, ...this.props.roles];
    const choice = allChoices.find((choice) => {
      return choice.name === event.target.value;
    });
    let newValues = [];

    if (radioButton && this.isRadioButton(event)) {
      values = values.filter((value) => value.type !== radioButtonType);
    }

    if (_isEmpty(choice)) {
      return;
    }

    const isChoosen = _find(values, choice);

    if (_isEmpty(isChoosen)) {
      newValues = [
        ...values,
        choice
      ];
    } else {
      newValues = _filter(values, (value) => {
        return !(value.id === choice.id && value.name === choice.name && value.tag === choice.tag);
      });
    }

    this.setState({
      values: newValues
    });

    this.handleFilter(newValues);
  };

  checkElement(values, item) {
    if (values && item) {
      const element = _find(values, item);

      return !_isEmpty(element);
    }

    return false;
  }

  renderOption = (values, item, radioButton) => {
    if (radioButton) {
      return <Radio color="primary" checked={this.checkElement(values, item)} value={item.name} />;
    }

    return <Checkbox color="primary" checked={this.checkElement(values, item)} value={item.name} />;
  };

  menuItems(values, items, radioButton = false) {
    const { t } = this.props;

    return items.map((item) => {
      const name = `logbook:${item.name}`;

      return (
        <MenuItem key={`${item.name}-${item.id}`}>
          {this.renderOption(values, item, radioButton)}
          <ListItemText primary={item.displayName || t(name)} />
        </MenuItem>
      );
    });
  }

  manageDivider(groups, roles) {
    if (_isEmpty(groups) || _isEmpty(roles)) {
      return (
        null
      );
    }

    return <Divider />;
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { values } = this.state;
    const { groups, roles, isFilterUsed, radioButton, t } = this.props;

    return (
      <div className="col-auto table-wrapper">
        <Button
          aria-owns={this.state.anchorEl ? 'filter-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <Icon className={isFilterUsed ? 'filterIsUsed mr-3' : 'mr-3'}>filter_list</Icon>
          <span className={isFilterUsed ? 'filterIsUsed' : ''}>
            {t('table:filter')}
          </span>
        </Button>
        <Menu
          id="filter-menu"
          anchorEl={this.state.anchorEl}
          value={values}
          open={Boolean(this.state.anchorEl)}
          onChange={(event) => this.throttledChoice(values, event)}
          onClose={this.handleClose}
        >
          {this.menuItems(values, groups)}
          {this.manageDivider(groups, roles)}
          {this.menuItems(values, roles, radioButton)}
        </Menu>
      </div>
    );
  }
}
