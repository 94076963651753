import {
  AIRCRAFT_ENGINES,
  AIRCRAFT_ENGINE_CHOICES,
  AIRCRAFT_PILOTS,
  AIRCRAFT_PILOT_CHOICES,
  AIRCRAFT_TYPES,
  AIRCRAFT_TYPE_CHOICES,
  AIRPLANE_PROPULSIONS,
  AIRPLANE_PROPULSION_CHOICES,
  HELICOPTER_PROPULSION_CHOICES,
} from '../../../../constants/index';
import React, { Component } from 'react';

import Divider from '@material-ui/core/Divider';
import Form from '../../../../common/components/forms/Form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import _each from 'lodash/each';
import _get from 'lodash/get';
import classNames from 'classnames';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    minHeight: 300,
  },
});

@withNamespaces()
@withStyles(styles)
export default class AircraftDialogContent extends Component {
  static propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    registration: PropTypes.string,
    type: PropTypes.string,
    engine: PropTypes.string,
    pilot: PropTypes.string,
    propulsion: PropTypes.string,
    label: PropTypes.string,
    preview: PropTypes.bool,
    registerComponentForm: PropTypes.func.isRequired,
    formValidated: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    id: _get(this.props, 'id', ''),
    name: _get(this.props, 'name', ''),
    registration: _get(this.props, 'registration', ''),
    type: _get(this.props, 'type', '') || AIRCRAFT_TYPES.airplane,
    pilot: _get(this.props, 'pilot', '') || AIRCRAFT_PILOTS.singlePilot,
    engine: _get(this.props, 'engine', '') || AIRCRAFT_ENGINES.singleEngine,
    propulsion: _get(this.props, 'propulsion', '') || AIRPLANE_PROPULSIONS.jet,
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value,
      });
    });
  };

  mapState() {
    const { registration, name, type, pilot, engine, propulsion } = this.state;
    const { id } = this.props;

    const formChanges = {
      registration,
      name,
      type,
      pilot,
      engine,
      propulsion,
    };

    if (id !== '') {
      formChanges.id = id;
    }

    return formChanges;
  }

  onFormValidated = (isFormValid) => {
    this.props.formValidated(isFormValid, this.mapState());
  };

  registerForm = (triggerFormValidation) => {
    this.props.registerComponentForm(triggerFormValidation);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });

    if (name === 'type') {
      this.setState({
        pilot: AIRCRAFT_PILOTS.singlePilot,
        engine: AIRCRAFT_ENGINES.singleEngine,
        propulsion:
          event.target.value === AIRCRAFT_TYPES.airplane
            ? AIRPLANE_PROPULSIONS.jet
            : AIRPLANE_PROPULSIONS.piston,
      });
    }
  };

  renderPropulsion = () => {
    const { type } = this.state;

    if (type === AIRCRAFT_TYPES.helicopter) {
      return this.renderRadioGroup(
        'propulsion',
        'propulsion',
        HELICOPTER_PROPULSION_CHOICES,
      );
    } else if (type === AIRCRAFT_TYPES.airplane) {
      return this.renderRadioGroup(
        'propulsion',
        'propulsion',
        AIRPLANE_PROPULSION_CHOICES,
      );
    }

    return <div />;
  };

  renderRadioGroup = (name, stateName, labels) => {
    const value = this.state[stateName];

    return (
      <RadioGroup
        name={name}
        value={value}
        onChange={this.handleChange(stateName)}
        className="flex-row row"
      >
        {labels.map((label) => this.renderFormControlLabel(label))}
      </RadioGroup>
    );
  };

  renderFormControlLabel = (value) => {
    const { t } = this.props;
    const label = `settings:${value}`;

    return (
      <FormControlLabel
        key={`form-control-key-${value}`}
        value={value}
        control={<Radio color="primary" />}
        label={t(label)}
        className="col-12 col-md-4 mx-0"
      />
    );
  };

  renderRealMachineProperties = () => {
    const { type } = this.state;

    if (type === AIRCRAFT_TYPES.simulator) {
      return <div />;
    }

    return (
      <div>
        {this.renderRadioGroup('pilot', 'pilot', AIRCRAFT_PILOT_CHOICES)}
        {this.renderRadioGroup('engine', 'engine', AIRCRAFT_ENGINE_CHOICES)}
        {this.renderPropulsion()}
      </div>
    );
  };

  render() {
    const { preview, classes, t } = this.props;
    const { registration, name } = this.state;

    return (
      <Form
        onChange={this.onChange}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
        className="col-12"
      >
        <FormControl className={classNames(classes.root, 'col-12')}>
          <div className="row">
            <div className="col-6">
              <MaterialInput
                fullWidth
                label={t('input:registration')}
                autoFocus
                name="registration"
                defaultValue={registration}
                disabled={preview}
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200),
                ]}
              />
            </div>
            <div className="col-6">
              <MaterialInput
                fullWidth
                label={t('input:name')}
                name="name"
                defaultValue={name}
                disabled={preview}
                validators={[new validators.MaxLength(t, 200)]}
              />
            </div>
            <div className="col-12 mt-3">
              {this.renderRadioGroup('type', 'type', AIRCRAFT_TYPE_CHOICES)}
            </div>
            <div className="col-12">
              <Divider className="my-3" />
            </div>
            <div className="col-12">{this.renderRealMachineProperties()}</div>
          </div>
        </FormControl>
      </Form>
    );
  }
}
