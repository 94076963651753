export const tableHeader = [
  {
    prop: 'check',
    sort: false,
    profileView: false,
    isNumeric: true
  },
  {
    prop: 'name',
    sort: false,
    profileView: false
  },
  {
    prop: 'number',
    sort: false,
    profileView: false
  },
  {
    prop: 'period',
    sort: false,
    profileView: false
  },
];

export const periods = ['days', 'months', 'years'];
export const defaultNumber = 1;
export const defaultPeriod = 'days';
