import { URLS } from '../../constants';
import _isEmpty from 'lodash/isEmpty';
import { addError } from './errors';
import axios from 'axios';
import { getDashboardFleetParametersFilter } from '../utils/time';
import history from '../store/history';
import qs from 'qs';
import { urlJoin } from '../../utils';

export const FETCH_WAREHOUSES_REQUEST = 'FETCH_WAREHOUSES_REQUEST';
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS';
export const FETCH_WAREHOUSE_REQUEST = 'FETCH_WAREHOUSE_REQUEST';
export const FETCH_WAREHOUSE_SUCCESS = 'FETCH_WAREHOUSE_SUCCESS';
export const ADD_WAREHOUSE = 'ADD_WAREHOUSE';
export const EDIT_WAREHOUSE = 'EDIT_WAREHOUSE';
export const REMOVE_WAREHOUSE = 'REMOVE_WAREHOUSE';
export const CLEAN_WAREHOUSE_DATA = 'CLEAN_WAREHOUSE_DATA';
export const FETCH_WAREHOUSES_PAGINATION_REQUEST =
  'FETCH_WAREHOUSES_PAGINATION_REQUEST';
export const FETCH_WAREHOUSES_PAGINATION_SUCCESS =
  'FETCH_WAREHOUSES_PAGINATION_SUCCESS';

const sortingPossibitilies = {
  name: 'vehicle__name',
  type: 'vehicle__type__name',
  evn: 'vehicle__evn',
  maintainer: 'maintainer',
  currentHours: 'current_hours',
  currentCycle: 'current_cycle',
  lastService: 'last_service',
};

export function fetchWarehousesRequest() {
  return {
    type: FETCH_WAREHOUSES_REQUEST,
  };
}

export function fetchWarehousesSuccess(warehouse) {
  return {
    type: FETCH_WAREHOUSES_SUCCESS,
    payload: {
      warehouse,
    },
  };
}

export function fetchWarehousesPaginationRequest() {
  return {
    type: FETCH_WAREHOUSES_PAGINATION_REQUEST,
  };
}

export function fetchWarehousesPaginationSuccess(warehouse) {
  return {
    type: FETCH_WAREHOUSES_PAGINATION_SUCCESS,
    payload: {
      warehouse,
    },
  };
}

export function fetchWarehouseRequest() {
  return {
    type: FETCH_WAREHOUSE_REQUEST,
  };
}

export function fetchWarehouseSuccess(warehouse) {
  return {
    type: FETCH_WAREHOUSE_SUCCESS,
    payload: {
      warehouse,
    },
  };
}

export function addWarehouseSuccess(warehouse) {
  return {
    type: ADD_WAREHOUSE,
    payload: {
      warehouse,
    },
  };
}

export function editWarehouseSuccess(warehouse) {
  return {
    type: EDIT_WAREHOUSE,
    payload: {
      warehouse,
    },
  };
}

export function removeWarehouseSuccess(warehouseId) {
  return {
    type: REMOVE_WAREHOUSE,
    payload: {
      warehouseId,
    },
  };
}

export function cleanWarehouse() {
  return {
    type: CLEAN_WAREHOUSE_DATA,
  };
}

export function fetchWarehouses(sortingData, paginationParams) {
  return (dispatch) => {
    const params = [];

    if (paginationParams) {
      dispatch(fetchWarehousesPaginationRequest());
      params.push(paginationParams);
    } else {
      dispatch(fetchWarehousesRequest());
    }

    if (sortingData) {
      if (sortingData.search && !_isEmpty(sortingData.search)) {
        params.push(`search=${sortingData.search}`);
      }

      if (sortingData.columnToSort && !_isEmpty(sortingData.columnToSort)) {
        params.push(
          `ordering=${sortingData.sortAsc ? '' : '-'}${
            sortingPossibitilies[sortingData.columnToSort]
          }`,
        );
      }
    }

    const queryParameters = params.length ? `?${params.join('&')}` : '';

    return axios
      .get(urlJoin(URLS.warehouse, queryParameters))
      .then(({ data }) =>
        dispatch(
          paginationParams
            ? fetchWarehousesPaginationSuccess(data)
            : fetchWarehousesSuccess(data),
        ),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during warehouse fetching ${error.message}`, error),
        );
      });
  };
}

export function fetchDashboardWarehouses() {
  return (dispatch) => {
    const queryParameters = `?${getDashboardFleetParametersFilter()}`;

    return axios
      .get(urlJoin(URLS.warehouse, queryParameters))
      .then(({ data }) => dispatch(fetchWarehousesSuccess(data.results)))
      .catch((error) => {
        dispatch(
          addError(`Error during warehouse fetching ${error.message}`, error),
        );
      });
  };
}

export function fetchWarehouse(warehouseId) {
  return (dispatch) => {
    dispatch(fetchWarehouseRequest());

    return axios
      .get(urlJoin(URLS.warehouse, warehouseId))
      .then(({ data }) => {
        dispatch(fetchWarehouseSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during warehouse fetching ${error.message}`));
      });
  };
}

export function removeWarehouse(warehouseId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.warehouse, warehouseId))
      .then(() => dispatch(removeWarehouseSuccess(warehouseId)))
      .catch((error) => {
        dispatch(
          addError(`Error during warehouse removing ${error.message}`, error),
        );
      });
  };
}

export function editWarehouse(warehouse) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.warehouse, warehouse.id), warehouse)
      .then(({ data }) => {
        dispatch(editWarehouseSuccess(data));
        history.push('/warehouse');
      })
      .catch((error) => {
        dispatch(
          addError(`Error during warehouse editing ${error.message}`, error),
        );
      });
  };
}

export function addWarehouse(warehouse) {
  return (dispatch) => {
    return axios
      .post(URLS.warehouse, warehouse)
      .then(({ data }) => {
        dispatch(addWarehouseSuccess(data));
        history.push(`/warehouse/edit/${data.id}`);
      })
      .catch((error) => {
        dispatch(
          addError(`Error during warehouse adding ${error.message}`, error),
        );
      });
  };
}

export function cleanWarehouseData() {
  return (dispatch) => dispatch(cleanWarehouse());
}
