import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import _each from 'lodash/each';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import ReportRecipentList from './ReportRecipentList';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import Loader from '../../../../common/components/Loader';
import Form from '../../../../common/components/forms/Form';

import { fetchReportRecipents, addReportRecipent, removeReportRecipent, editReportRecipent } from '../../../actions/reportRecipents';
import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  firstName: '',
  lastName: '',
  email: '',
  reportRecipentId: null,
};

const mapStateToProps = (state) => {
  return {
    reportRecipentList: state.reportRecipents.reportRecipentList,
    reportRecipentRequest: state.reportRecipents.reportRecipentRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchReportRecipents: () => dispatch(fetchReportRecipents()),
    addReportRecipent: (reportRecipent) => dispatch(addReportRecipent(reportRecipent)),
    editReportRecipent: (reportRecipent) => dispatch(editReportRecipent(reportRecipent)),
    removeReportRecipent: (reportRecipent) => dispatch(removeReportRecipent(reportRecipent)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class ReportRecipentsManager extends Component {
  static propTypes = {
    reportRecipentList: PropTypes.array,
    reportRecipentRequest: PropTypes.bool.isRequired,
    fetchReportRecipents: PropTypes.func.isRequired,
    addReportRecipent: PropTypes.func.isRequired,
    editReportRecipent: PropTypes.func.isRequired,
    removeReportRecipent: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.fetchReportRecipents();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleEditOpen = (reportRecipent) => {
    this.setState({
      editOpen: true,
      firstName: reportRecipent.firstName,
      lastName: reportRecipent.lastName,
      email: reportRecipent.email,
      reportRecipentId: reportRecipent.id,
    });
  };

  handleClose = () => {
    this.setState(INITIAL_STATE);
  };

  addReportRecipent = () => {
    const { firstName, lastName, email } = this.state;

    this.props.addReportRecipent({ firstName, lastName, email });
    this.handleClose();
  };

  editReportRecipent = () => {
    const { reportRecipentId, firstName, lastName, email } = this.state;

    this.props.editReportRecipent({ id: reportRecipentId, firstName, lastName, email });
    this.handleClose();
  };

  removeReportRecipent = (reportRecipent) => {
    this.props.removeReportRecipent(reportRecipent);
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    const { addOpen } = this.state;

    return (isFormValid) => {
      if (isFormValid) {
        addOpen ? this.addReportRecipent() : this.editReportRecipent();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { reportRecipentList, reportRecipentRequest, onElementDelete, t } = this.props;
    const { addOpen, editOpen, firstName, lastName, email } = this.state;

    if (reportRecipentRequest) {
      return <Loader />;
    }

    return (
      <div className="report-recipents-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:reportRecipientsSection')}</h4>
        </div>
        <ReportRecipentList
          reportRecipentList={reportRecipentList}
          removeReportRecipent={this.removeReportRecipent}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen || editOpen}
            onClose={this.handleAddClose}
            aria-labelledby="add-report-recipent-dialog"
            fullWidth
          >
            <DialogTitle id="add-report-recipent-dialog">
              {addOpen ? t('settings:addReportRecipient') : t('settings:editReportRecipient')}
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-6">
                  <MaterialInput
                    label={t('input:firstName')}
                    name="firstName"
                    defaultValue={firstName}
                    autoFocus
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200)
                    ]}
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:lastName')}
                    name="lastName"
                    defaultValue={lastName}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200)
                    ]}
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:email')}
                    name="email"
                    defaultValue={email}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                      new validators.Email(t),
                    ]}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
