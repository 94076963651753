import {
  FETCH_NOTIFICATION_CATEGORIES,
  FETCH_NOTIFICATION_CATEGORIES_REQUEST
} from '../actions/notificationCategories';
const INITIAL_STATE = {
  notificationCategoryList: [],
  notificationCategoryRequest: false,
};

function fetchNotificationCategories(state, payload) {
  return {
    ...state,
    notificationCategoryList: payload.notificationCategories,
    notificationCategoryRequest: false,
  };
}

function fetchNotificationCategoriesRequest(state) {
  return {
    ...state,
    notificationCategoryRequest: true,
  };
}

const actions = {
  [FETCH_NOTIFICATION_CATEGORIES]: fetchNotificationCategories,
  [FETCH_NOTIFICATION_CATEGORIES_REQUEST]: fetchNotificationCategoriesRequest,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
