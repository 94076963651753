import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';

export default class MaterialMultipleSelect extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array
    ]),
    disabled: PropTypes.bool.isRequired,
    validationError: PropTypes.array,
    helperText: PropTypes.string,
    validators: PropTypes.any,
    forceInitialValidation: PropTypes.any,
    options: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.array.isRequired,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.any.isRequired
      })
    ]),
    inputValidated: PropTypes.oneOf([
      PropTypes.function,
      PropTypes.undefined
    ]),
    registerInput: PropTypes.oneOf([
      PropTypes.function,
      PropTypes.undefined
    ]),
    stateValue: PropTypes.any
  };

  static defaultProps = {
    validators: [],
    type: 'text',
    defaultValue: null,
    forceInitialValidation: false,
    disabled: false,
    error: false
  };

  constructor(props) {
    super(props);

    this.state = {
      values: props.defaultValue ? [`${props.defaultValue}`] : []
    };
  }


  async componentDidMount() {
    if (this.props.registerInput) {
      this.props.registerInput(
        this.props.name,
        this.input.value,
        this.validateAndPropagateValue
      );
    }

    if (this.props.forceInitialValidation) {
      const errors = await this.validate(this.props.defaultValue);

      this.props.inputValidated(this.props.name, this.input.value, {
        isValid: _isEmpty(errors),
        errors,
        disabled: this.props.disabled
      });
    }
  }

  validateAndPropagateValue = async (event) => {
    const values = event ? event.target.value : this.state.values;

    if (event) {
      this.setState({ values });
    }

    const errors = await this.validate(values);
    const isValid = this.props.disabled || _isEmpty(errors);

    if (this.props.inputValidated) {
      this.props.inputValidated(this.props.name, values, {
        isValid,
        errors,
        disabled: this.props.disabled
      });
    }
  };

  validate = async (values) => {
    const allErrors = [];

    for (const validator of this.props.validators) {
      const isValid = await validator.isValid(values);

      if (!isValid) {
        allErrors.push(validator.getValidationMessage());
      }
    }

    return allErrors;
  };

  getOptions() {
    const { options } = this.props;
    const { values } = this.state;

    return options.map((option, index) => (
      <MenuItem key={index} value={`${option.value}`}>
        <Checkbox color="primary" checked={values && values.indexOf(option.value.toString()) > -1} />
        <ListItemText primary={option.label} />
      </MenuItem>
    ));
  }

  renderValue = (selected) => {
    const { options } = this.props;
    let data = [];

    options.forEach((option) => {
      if (selected.indexOf(option.value.toString()) > -1) {
        data = [...data, option.label];
      }
    });

    return data.join(', ');
  }

  render() {
    const { label, disabled, validationError, helperText, stateValue, ...rest } = this.props;

    delete rest.defaultValue;
    delete rest.forceInitialValidation;
    delete rest.inputValidated;
    delete rest.registerInput;
    delete rest.error;

    const errorLabel =
      !disabled && validationError ? validationError.join('; ') : helperText;

    const error = !disabled && !_isEmpty(validationError);

    if (typeof(stateValue) !== 'undefined' && stateValue !== null && Array(this.state.values) !== Array(stateValue)) {
      setImmediate(() => {
        if (this.input) {
          this.setState({ values: stateValue });
        }
      });
    }

    return (
      <FormControl
        error={error}
        disabled={disabled}
        fullWidth
        margin="dense"
      >
        <InputLabel htmlFor="">{label}</InputLabel>
        <Select
          {...rest}
          multiple
          value={this.state.values}
          inputRef={(input) => {
            this.input = input;
          }}
          onChange={this.validateAndPropagateValue}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={this.renderValue}
        >
          <MenuItem value="">
            <em>---</em>
          </MenuItem>
          {
            this.getOptions()
          }
        </Select>
        <FormHelperText>{errorLabel}</FormHelperText>
      </FormControl>
    );
  }
}
