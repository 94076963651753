import axios from 'axios';
import qs from 'qs';
import _get from 'lodash/get';

import { addError } from './errors';
import { urlJoin, defaultCrewStatusFilerMonthsValue } from '../../utils';
import { prepareCrewStatusFilter, getDashboardQueryParametersFilter } from '../utils/time';
import { URLS } from '../../constants';
import _isEmpty from 'lodash/isEmpty';

export const FETCH_RATINGS_SUCCESS = 'FETCH_RATINGS_SUCCESS';
export const FETCH_RATINGS = 'FETCH_RATINGS';
export const FETCH_ALL_RATINGS_SUCCESS = 'FETCH_ALL_RATINGS_SUCCESS';
export const ADD_RATING = 'ADD_RATING';
export const REMOVE_RATING = 'REMOVE_RATING';
export const EDIT_RATING = 'EDIT_RATING';

export function fetchRatingsSuccess(ratings) {
  return {
    type: FETCH_RATINGS_SUCCESS,
    payload: {
      ratings
    }
  };
}

export function fetchAllRatingsSuccess(allRatings) {
  return {
    type: FETCH_ALL_RATINGS_SUCCESS,
    payload: {
      allRatings
    }
  };
}

export function fetchAllRatingsRequest() {
  return {
    type: FETCH_RATINGS,
  };
}

export function addRatingSuccess(rating) {
  return {
    type: ADD_RATING,
    payload: {
      rating
    }
  };
}

export function removeRatingSuccess(rating) {
  return {
    type: REMOVE_RATING,
    payload: {
      rating
    }
  };
}

export function editRatingSuccess(rating) {
  return {
    type: EDIT_RATING,
    payload: {
      rating
    }
  };
}

export function addRating(rating) {
  return (dispatch) => {
    return axios.post(URLS.ratings, rating)
      .then(({ data }) => dispatch(addRatingSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during rating adding ${error.message}`, error));
      });
  };
}

export function addRatingPlannedAction(rating) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.ratingsPlannedAction, rating.id), rating)
      .then(({ data }) => dispatch(editRatingSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during adding rating planned action ${error.message}`, error));
      });
  };
}

export function removeRating(rating) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.ratings, rating.id))
      .then(() => dispatch(removeRatingSuccess(rating)))
      .catch((error) => {
        dispatch(addError(`Error during rating removing ${error.message}`, error));
      });
  };
}

export function editRating(rating) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.ratings, rating.id), rating)
      .then(({ data }) => dispatch(editRatingSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during rating editing ${error.message}`, error));
      });
  };
}

export function deactivateRating(rating) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.ratingsDeactivate, rating.id), rating)
      .then(({ data }) => dispatch(editRatingSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during rating editing ${error.message}`, error));
      });
  };
}

export function fetchRatings(userId) {
  return (dispatch) => {
    const queryParameters = `?${getDashboardQueryParametersFilter(userId)}&ordering=expiry_date`;

    return axios.get(urlJoin(URLS.ratings, queryParameters))
      .then(({ data }) => dispatch(fetchRatingsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during ratings fetching ${error.message}`, error));
      });
  };
}

export function fetchAllRatings(sortingData) {
  return (dispatch) => {
    dispatch(fetchAllRatingsRequest());
    let crewStatusParameters = '';
    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData && !_isEmpty(sortingData.filter)) {
      const monthId = _get(sortingData.filter, 'months[0].id', defaultCrewStatusFilerMonthsValue);

      crewStatusParameters = prepareCrewStatusFilter(monthId);

      if (sortingData.filter.isVerified) {
        crewStatusParameters += '&is_verified=False';
      }
    }

    queryParameters = _isEmpty(queryParameters) ? `?${crewStatusParameters}` : `?${qs.stringify(queryParameters)}&${crewStatusParameters}`;

    return axios.get(urlJoin(URLS.allRatings, queryParameters))
      .then(({ data }) => dispatch(fetchAllRatingsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during ratings fetching ${error.message}`, error));
      });
  };
}
