import React, { Component } from 'react';

import LogbookList from '../LogbookList/LogbookList';
import userService from '../../../../utils/userService';


export default class MyLogbook extends Component {
  render() {
    const { id } = userService.getCurrentUser();

    return (
      <LogbookList userId={Number(id)} />
    );
  }
}


