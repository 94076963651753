import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from '@material-ui/core';
import React, { Component } from 'react';
import { addBooking, editBooking } from '../../../actions/bookings';
import {
  dateTimeDiff,
  getDateTimeFormat,
  getTimeFormat,
  reformatPickerDateTimeToDB,
} from '../../../utils/time';

import { BOOKING_TYPE } from '../../../../constants';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Form from '../../../../common/components/forms/Form';
import MaterialMultipleSelect from '../../../../common/components/forms/MaterialMultipleSelect';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import { PERMISSIONS } from '../../../../constants/permissions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUsers } from '../../../actions/users';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const bookingTypeConfig = [
  {
    id: BOOKING_TYPE.PRIVATE,
    label: 'private',
    translate: 'booking:private',
  },
  {
    id: BOOKING_TYPE.FLIGHT_WITH,
    label: 'flight_with',
    select: 'students',
    translate: 'booking:flight_with',
  },
  {
    id: BOOKING_TYPE.MAINTENANCE,
    label: 'maintenance',
    translate: 'booking:maintenance',
  },
  {
    id: BOOKING_TYPE.OTHER,
    label: 'other',
    translate: 'booking:other',
  },
];
const BOOKING_DEFAULT_DURATION = 1;

const styles = () => ({
  formContainer: {
    width: 300,
  },
});

const mapStateToProps = (state) => {
  return {
    userList: state.users.userList,
    userListRequest: state.users.getUsersRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: (searchData) => {
      dispatch(fetchUsers(searchData));
    },
    addBooking: (booking) => dispatch(addBooking(booking)),
    editBooking: (bookingId, booking) =>
      dispatch(editBooking(bookingId, booking)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
@withStyles(styles)
export default class AddEditBookingDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    userList: PropTypes.array.isRequired,
    userListRequest: PropTypes.bool.isRequired,
    bookingObjectList: PropTypes.array.isRequired,
    classes: PropTypes.object,
    bookingData: PropTypes.object,
    editData: PropTypes.object,
    permission: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    addBooking: PropTypes.func.isRequired,
    editBooking: PropTypes.func.isRequired,
    checkBookingTimeDidntOverlap: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    permission: {},
  };

  constructor(props) {
    super(props);
    const {
      bookingData: { start, end, slotId },
      editData,
    } = this.props;

    const bookingData = editData
      ? {
          ...editData,
          bookingObject: editData.resourceId,
          user: editData.userId,
          startDate: moment(editData.start),
          endDate: moment(editData.end),
        }
      : {
          type: BOOKING_TYPE.PRIVATE,
          startDate: start ? moment(start) : moment().minutes(0),
          endDate: end
            ? moment(end)
            : moment().add(BOOKING_DEFAULT_DURATION, 'hour').minutes(0),
          bookingObject: slotId,
        };

    this.state = {
      bookingData,
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  onChange = (formData) => {
    const data = Object.entries(formData).reduce((data, [name, val]) => {
      data[name] = val.value;

      return data;
    }, {});

    this.setState(({ bookingData }) => ({
      bookingData: {
        ...bookingData,
        ...data,
      },
    }));
  };

  save = (isFormValid) => {
    const {
      bookingData: {
        startDate,
        endDate,
        bookingObject,
        user,
        type,
        withUser,
        comment,
        active = true,
      },
    } = this.state;
    const {
      editData,
      editBooking,
      addBooking,
      onClose,
      checkBookingTimeDidntOverlap,
    } = this.props;
    let booking = {
      startDate: reformatPickerDateTimeToDB(startDate),
      endDate: reformatPickerDateTimeToDB(endDate),
      bookingObjectId: bookingObject,
      userId: user,
      comment,
      type,
      active,
    };

    if (type === BOOKING_TYPE.FLIGHT_WITH) {
      booking.withUserId = withUser;
    }

    if (
      isFormValid &&
      this.validateType() &&
      this.validateDates() &&
      this.validateStartDate() &&
      checkBookingTimeDidntOverlap({
        id: editData ? editData.id : -1,
        bookingObjectId: bookingObject,
        startDate: booking.startDate,
        endDate: booking.endDate,
      })
    ) {
      let data = [];

      user.forEach((u) => {
        booking = Object.assign({}, booking, { userId: u });
        data = [...data, booking];
      });

      if (editData) {
        editBooking(editData.id, data[0]);
      } else {
        addBooking(data);
      }
      onClose();
    }
  };

  validateType = () => {
    const {
      bookingData: { type, withUser },
    } = this.state;

    return type === BOOKING_TYPE.FLIGHT_WITH ? !!withUser : true;
  };

  validateDates = () => {
    const {
      bookingData: { startDate, endDate },
    } = this.state;

    return endDate && dateTimeDiff(endDate, startDate) > 0;
  };

  validateStartDate = () => {
    const {
      bookingData: { startDate },
    } = this.state;

    return startDate - moment() > 0;
  };

  getUser = () => {
    const { userList } = this.props;
    const {
      bookingData: { user },
    } = this.props;

    return userList.find((userInList) => userInList.id === user.id);
  };

  getOnChangeBookingData = (propName) => (data) => {
    const value = ['startDate', 'endDate'].includes(propName)
      ? data
      : data.target.value;

    this.setBookingData(propName, value);
  };

  setBookingData = (propName, value) => {
    this.setState((prevState) => ({
      ...prevState,
      bookingData: { ...prevState.bookingData, [propName]: value },
    }));
  };

  getUserList = () => {
    const { userList } = this.props;
    const {
      bookingData: { user, withUser },
    } = this.state;

    return userList.filter(({ id }) => id !== user.id && id !== withUser);
  };

  render() {
    const {
      open,
      permission,
      editData,
      onClose,
      userList,
      userListRequest,
      bookingObjectList,
      classes,
      t,
    } = this.props;
    const {
      bookingData: {
        startDate,
        endDate,
        bookingObject,
        type,
        user,
        withUser,
        comment,
        active = true,
      },
    } = this.state;
    const loggedUser = this.getUser();

    if (userListRequest) {
      return null;
    }

    return (
      <Dialog
        PaperProps={{
          classes: { root: classes.root },
        }}
        open={open}
        onClose={onClose}
        aria-labelledby="book-booking-object"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editData ? t('booking:editBooking') : t('booking:addBooking')}
        </DialogTitle>
        <DialogContent>
          <Form
            onChange={this.onChange}
            onFormValidated={this.save}
            registerForm={(triggerFormValidation) => {
              this.triggerFormValidation = triggerFormValidation;
            }}
          >
            <MaterialMultipleSelect
              id="user"
              name="user"
              label={t('input:user')}
              defaultValue={user || (loggedUser && loggedUser.id)}
              options={userList.map((user) => ({
                value: user.id,
                label: `${user.lastName} ${user.firstName}`,
              }))}
              validators={[new validators.IsArrayRequired(t)]}
              disabled={!permission[PERMISSIONS.createAllBookings]}
            />
            <div className={classes.formContainer}>
              <MaterialSelect
                id="bookingObject"
                name="bookingObject"
                label={t('input:bookingObject')}
                defaultValue={bookingObject}
                options={bookingObjectList.map((bookingObject) => ({
                  value: bookingObject.id,
                  label: bookingObject.name,
                }))}
                validators={[new validators.IsRequired(t)]}
              />
              <DateTimePicker
                disabled={false}
                timeIntervals={15}
                value={startDate}
                showMonthDropdown
                showTimeSelect
                showYearDropdown
                dateFormat={getDateTimeFormat()}
                timeFormat={getTimeFormat()}
                handleDatePickerChange={this.getOnChangeBookingData(
                  'startDate',
                )}
                name="start_date"
                label={t('input:startDate')}
                fullWidth
                acceptEmptyInput
              />
              <FormHelperText error={!this.validateStartDate()}>
                {!this.validateStartDate() &&
                  t('booking:startDateCannotBePastThenNow')}
              </FormHelperText>
              <DateTimePicker
                disabled={false}
                timeIntervals={15}
                value={endDate}
                showMonthDropdown
                showTimeSelect
                showYearDropdown
                dateFormat={getDateTimeFormat()}
                timeFormat={getTimeFormat()}
                handleDatePickerChange={this.getOnChangeBookingData('endDate')}
                name="end_date"
                label={t('input:endDate')}
                fullWidth
                acceptEmptyInput
              />
            </div>
            <FormHelperText error={!this.validateDates()}>
              {!this.validateDates() &&
                t('booking:endDateCannotBeEarlierThanStartDate')}
            </FormHelperText>
          </Form>
          {bookingTypeConfig.map(({ id, label, select, translate }, index) => (
            <div key={index} className="d-flex flex-row align-items-center">
              <Radio
                value={id}
                checked={type === id}
                aria-label={label}
                color="primary"
                onChange={({ target: { value } }) => {
                  this.setBookingData('type', value);

                  if (value !== BOOKING_TYPE.FLIGHT_WITH) {
                    this.setBookingData('withUser', null);
                  }
                }}
              />
              <span>{t(translate)}</span>
              {select && type === id && (
                <div className="d-flex flex-row align-items-center flex-grow-1">
                  <FormControl error={!this.validateType()} className="mx-5">
                    <InputLabel />
                    <Select
                      value={withUser || ''}
                      onChange={this.getOnChangeBookingData('withUser')}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {userList.map((listUser) => {
                        const groupIndex = listUser.groups.findIndex(
                          (group) =>
                            group.name === 'instructor' ||
                            group.name === 'manager' ||
                            group.name === 'admin',
                        );

                        if (groupIndex !== -1) {
                          return (
                            <MenuItem key={listUser.id} value={listUser.id}>
                              {`${listUser.lastName} ${listUser.firstName}`}
                            </MenuItem>
                          );
                        }

                        return null;
                      })}
                    </Select>
                    <FormHelperText>
                      {!this.validateType() && t('error:isRequired')}
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={!active}
                          onChange={({ target: { checked } }) =>
                            this.setBookingData('active', !checked)
                          }
                        />
                      }
                      label={t('booking:requireConfirmation')}
                    />
                  </FormControl>
                </div>
              )}
            </div>
          ))}
          <TextField
            label={t('input:comment')}
            onChange={this.getOnChangeBookingData('comment')}
            defaultValue={comment}
            fullWidth
          />
        </DialogContent>
        <DialogActions className="row justify-content-end">
          <Button color="secondary" onClick={onClose}>
            {t('buttonCancel')}
          </Button>
          <Button color="primary" onClick={this.sendHandler}>
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
