import React, { Component } from 'react';
import {
  calculateLessonTime,
  dateTimeDiffInTimeFormat,
  getDateTimeFormat,
  getTimeFormat,
  reformatPickerDateTimeFromDB,
} from '../../../utils/time';

import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  timeInputWidth: {
    width: 180,
  },
});

@withNamespaces()
@withStyles(styles)
export default class BasicLessonInfo extends Component {
  static propTypes = {
    lesson: PropTypes.object.isRequired,
    basicInfoDateHandlers: PropTypes.object.isRequired,
    handleDatePickerChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    preview: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  renderInputName = (
    nameForProgressAndPracticalLesson,
    nameForTheoreticallLesson,
  ) => {
    const { lesson } = this.props;

    return lesson.lessonType.shortname === 'TH'
      ? nameForTheoreticallLesson
      : nameForProgressAndPracticalLesson;
  };

  render() {
    const {
      lesson,
      basicInfoDateHandlers,
      preview,
      handleDatePickerChange,
      classes,
      t,
    } = this.props;

    return [
      <div className="row" key="basic-lesson-info-row2">
        <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
          <DateTimePicker
            disabled={preview}
            showTimeSelect
            value={reformatPickerDateTimeFromDB(
              basicInfoDateHandlers.startDate,
            )}
            showMonthDropdown
            showYearDropdown
            dateFormat={getDateTimeFormat()}
            timeFormat={getTimeFormat()}
            handleDatePickerChange={handleDatePickerChange('startDate')}
            name="date"
            label={this.renderInputName(
              t('input:dateOfDeparture'),
              t('input:startDate'),
            )}
            fullWidth
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
          <DateTimePicker
            disabled={preview}
            showTimeSelect
            value={reformatPickerDateTimeFromDB(basicInfoDateHandlers.endDate)}
            showMonthDropdown
            showYearDropdown
            dateFormat={getDateTimeFormat()}
            timeFormat={getTimeFormat()}
            handleDatePickerChange={handleDatePickerChange('endDate')}
            name="date"
            label={this.renderInputName(
              t('input:dateOfArrival'),
              t('input:endDate'),
            )}
            fullWidth
          />
        </div>
      </div>,
      <div className="row" key="basic-lesson-info-row3">
        <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
          <TextField
            className={classes.timeInputWidth}
            label={t('input:estimatedTime')}
            value={calculateLessonTime(lesson)}
            margin="dense"
            type="text"
            disabled
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
          <TextField
            className={classes.timeInputWidth}
            label={t('input:totalTime')}
            value={dateTimeDiffInTimeFormat(
              basicInfoDateHandlers.endDate,
              basicInfoDateHandlers.startDate,
            )}
            margin="dense"
            type="text"
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>,
    ];
  }
}
