import {
  FETCH_QUESTION_TYPES_SUCCESS,
  FETCH_QUESTION_TYPES_REQUEST,
  FETCH_QUESTION_TYPES_FAILURE,
} from '../actions/questionTypes';

const INITIAL_STATE = {
  questionTypeList: [],
  questionTypeRequest: false,
};

function fetchQuestionTypes(state, payload) {
  return {
    ...state,
    questionTypeList: payload.questionTypes,
    questionTypeRequest: false,
  };
}

function fetchQuestionTypesRequest(state) {
  return {
    ...state,
    questionTypeRequest: true,
  };
}

function fetchQuestionTypesFailure(state) {
  return {
    ...state,
    questionTypeRequest: false,
  };
}


const actions = {
  [FETCH_QUESTION_TYPES_SUCCESS]: fetchQuestionTypes,
  [FETCH_QUESTION_TYPES_REQUEST]: fetchQuestionTypesRequest,
  [FETCH_QUESTION_TYPES_FAILURE]: fetchQuestionTypesFailure,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
