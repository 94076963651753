export const TABLE_HEADER = [
  {
    prop: 'status',
    sort: true,
    profileView: false,
  },
  {
    prop: 'name',
    sort: true,
    profileView: false,
  },
  {
    prop: 'reportStatus',
    sort: true,
    profileView: false,
  },
  {
    prop: 'type',
    sort: true,
    profileView: false,
  },
  {
    prop: 'evn',
    sort: true,
    profileView: false,
  },
  {
    prop: 'maintainer',
    sort: true,
    profileView: false,
  },
  {
    prop: 'currentHours',
    sort: true,
    profileView: false,
  },
  {
    prop: 'lastService',
    sort: true,
    profileView: false,
  },
  {
    prop: 'nextService',
    sort: true,
    profileView: false,
  },
  {
    prop: 'distanceToService',
    sort: true,
    profileView: false,
  },
  {
    prop: 'operationalDaysToService',
    sort: true,
    profileView: false,
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true,
    iconWidth: true,
  },
];
