import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

import FileInput from '../../../../common/components/upload/FileInput';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import { reformatPickerDateFromDB } from '../../../utils/time';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 200,
  }
});

@withNamespaces()
@withStyles(styles)
export default class PersonalFileList extends Component {
  static propTypes = {
    personalFileList: PropTypes.array.isRequired,
    removePersonalFile: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    handleEditOpen: PropTypes.func.isRequired,
    attachPersonalFileFile: PropTypes.func.isRequired,
    removePersonalFileFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderFiles(personalFile) {
    const { editMode, removePersonalFileFile } = this.props;

    return personalFile.files.map((file) => {
      const onDelete = editMode ? () => removePersonalFileFile(file.id, personalFile.id) : null;

      return (
        <Chip
          key={`personal-file-${personalFile.id}-file-${file.id}`}
          label={file.name}
          onClick={() => window.open(file.path, '_blank')}
          onDelete={onDelete}
          className="my-1"
        />
      );
    });
  }

  renderActionIcons(personalFile) {
    const { editMode, attachPersonalFileFile, addSimpleError } = this.props;

    if (editMode) {
      return (
        <div className="file-inputs">
          <CustomIconButton>
            <FileInput
              attachFile={(file) => attachPersonalFileFile(file)(personalFile.id)}
              addSimpleError={addSimpleError}
              required
            />
            <Icon color="primary">attach_file</Icon>
          </CustomIconButton>
          <IconButton onClick={() => this.props.handleEditOpen(personalFile)}><Icon color="primary">mode_edit</Icon></IconButton>
          <IconButton onClick={() => this.props.removePersonalFile(personalFile)}><Icon color="primary">delete</Icon></IconButton>
        </div>
      );
    }

    return <div />;
  }

  renderPersonalFileList() {
    const { personalFileList, classes } = this.props;

    return personalFileList.map((personalFile) => {
      return (
        <TableRow key={`personal-file-${personalFile.id}`}>
          <TableCell className={classes.longCell}>{personalFile.type}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(personalFile.expiryDate, false)}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(personalFile.dateOfIssue, false)}</TableCell>
          <TableCell className={classes.longCell}>{this.renderFiles(personalFile)}</TableCell>
          <TableCell className={classes.iconsCell} numeric>
            {this.renderActionIcons(personalFile)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderAddButton(t) {
    const { editMode, handleAddOpen } = this.props;

    if (editMode) {
      return (<Button className="my-2" color="primary" onClick={handleAddOpen}>{t('user:addPersonalFileButton')}</Button>);
    }

    return <div />;
  }

  render() {
    const { classes, personalFileList, t } = this.props;

    if (_isEmpty(personalFileList)) {
      return (
        <div>
          <h4>{t('user:personalFileSection')}</h4>
          {this.renderAddButton(t)}
          <Divider className="my-3" />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <h4>{t('user:personalFileSection')}</h4>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:type')}</TableCell>
              <TableCell>{t('table:expiryDate')}</TableCell>
              <TableCell>{t('table:dateOfIssue')}</TableCell>
              <TableCell>{t('table:attachments')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderPersonalFileList()}
          </TableBody>
        </Table>
        {this.renderAddButton(t)}
        <Divider className="my-3" />
      </div>
    );
  }
}
