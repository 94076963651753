import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _get from 'lodash/get';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import ComplementaryCertificate from '../ComplementaryCertificate';
import UserCompetencies from '../Competencies/UserCompetencies';
import UserRatings from '../Ratings/UserRatings';
import UserQualifications from '../Qualifications/UserQualifications';
import UserDetails from './UserDetails';
import UserExternalCourses from '../ExternalCourses/UserExternalCourses';
import UserLicenses from '../Licenses/UserLicenses';
import UserMedicals from '../Medicals/UserMedicals';
import UserPersonalFiles from '../PersonalFiles/UserPersonalFiles';
import Loader from '../../../../common/components/Loader';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';

import { fetchUserProfile, cleanUserProfileData, saveUserDetails } from '../../../actions/users';
import { fetchDocumentTypes } from '../../../actions/documentTypes';
import { fetchGroups } from '../../../actions/groups';
import { attachProfileImage, removeProfileImage } from '../../../actions/fileUpload';
import { fetchCompetencies } from '../../../actions/competencies';
import { addSimpleError } from '../../../actions/errors';

import { capitalizeFirstLetter } from '../../../../utils/index';
import { reformatPickerDateToDB } from '../../../utils/time';
import { MODULES } from '../../../../constants/modules';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { renderPermission } from '../../../decorators/permissions';
import { renderModule } from '../../../decorators/modules';


const mapStateToProps = (state) => {
  return {
    userDetails: state.users.userDetails,
    groups: (state.groups.groupList || []),
    userProfileRequest: state.users.getUserProfileRequest,
    assignedCourseList: state.assignedCourses.assignedCourseList,
    documentTypeList: state.documentTypes.documentTypeList || [],
    documentTypeRequest: state.documentTypes.documentTypeRequest,
    complementaryCertificateList: state.complementaryCertificates.complementaryCertificateList,
    competenciesList: state.competencies.competenciesList.filter((competence) => competence.isActive),
    competenciesRequest: state.competencies.competenciesRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchDocumentTypes: () => dispatch(fetchDocumentTypes()),
    fetchUserProfile: (userId) => dispatch(fetchUserProfile(userId)),
    cleanUserProfileData: () => dispatch(cleanUserProfileData()),
    saveUserDetails: (id, userDetails, myProfile) => dispatch(saveUserDetails(id, userDetails, myProfile)),
    fetchGroups: () => dispatch(fetchGroups()),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    attachProfileImage: (fileData, userProfileId) => dispatch(attachProfileImage(fileData, userProfileId)),
    removeProfileImage: (userProfileId) => dispatch(removeProfileImage(userProfileId)),
    fetchCompetencies: (searchData) => dispatch(fetchCompetencies(searchData)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class UserProfile extends Component {
  static propTypes = {
    fetchUserProfile: PropTypes.func.isRequired,
    fetchDocumentTypes: PropTypes.func.isRequired,
    userDetails: PropTypes.object,
    cleanUserProfileData: PropTypes.func.isRequired,
    saveUserDetails: PropTypes.func.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    groups: PropTypes.array,
    userProfileRequest: PropTypes.bool.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    attachProfileImage: PropTypes.func.isRequired,
    removeProfileImage: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    editMode: PropTypes.bool.isRequired,
    myProfile: PropTypes.bool,
    assignedCourseList: PropTypes.array,
    documentTypeList: PropTypes.array,
    complementaryCertificateList: PropTypes.array.isRequired,
    documentTypeRequest: PropTypes.bool.isRequired,
    competenciesList: PropTypes.array,
    competenciesRequest: PropTypes.bool.isRequired,
    fetchCompetencies: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = {
      userDetailsisValid: false,
      showRemoveDialog: false,
      removeCallback: () => {},
    };
  }

  componentDidMount() {
    const { userId } = this.props;

    this.props.fetchGroups();
    this.props.fetchDocumentTypes();
    this.props.fetchUserProfile(userId);
    this.props.fetchCompetencies();
  }

  componentWillUnmount() {
    this.props.cleanUserProfileData();
  }

  prepareSchoolGroups(groups) {
    return groups.map((group) => ({ id: this.getGroupId(group) }));
  }

  getGroupId(groupName) {
    return Number(_filter(this.props.groups, { 'name': groupName })['0'].id);
  }

  saveUserDetails = async () => {
    const { userDetails, myProfile, t } = this.props;

    await this.triggers.userDetails();

    if (this.formData.userDetailsisValid) {
      const userProfileChanges = {
        ...this.formData.userDetailsChanges,
        profilePicture: userDetails.userprofile.profilePicture
      };

      this.props.saveUserDetails(userDetails.id, this.mapChangesToUserDetails(userProfileChanges), myProfile);
    } else {
      this.props.addSimpleError(t('error:fieldsWrongFilled'));
    }
  };

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  mapChangesToUserDetails(userDetailsChanges) {
    const userProfile = {
      userprofile: {
        pin: userDetailsChanges.pin,
        address: userDetailsChanges.address,
        dateOfBirth: reformatPickerDateToDB(userDetailsChanges.dateOfBirth),
        flightExp: userDetailsChanges.flightExp,
        license: userDetailsChanges.license,
        medical: userDetailsChanges.medical,
        phone: userDetailsChanges.phone,
        personalIdNumber: userDetailsChanges.personalIdNumber,
        placeOfBirth: userDetailsChanges.placeOfBirth,
        nationality: userDetailsChanges.nationality,
        town: userDetailsChanges.town,
        townCountry: userDetailsChanges.townCountry,
        profilePicture: userDetailsChanges.profilePicture,
      },
      firstName: userDetailsChanges.firstName,
      lastName: userDetailsChanges.lastName,
      email: userDetailsChanges.email,
    };

    if (!_isEmpty(userDetailsChanges.name)) {
      userProfile.schoolgroupSet = this.prepareSchoolGroups(userDetailsChanges.name);
    }

    if (!_isEmpty(userDetailsChanges.password)) {
      userProfile.password = userDetailsChanges.password;
    }

    return userProfile;
  }

  renderSaveButton() {
    const { editMode, t } = this.props;

    if (editMode) {
      return (
        <div className="justify-content-center row">
          <div className="col-auto py-1">
            <Button color="primary" variant="raised" onClick={this.saveUserDetails}>
              {t('buttonSave')}
            </Button>
          </div>
        </div>
      );
    }

    return <div />;
  }

  renderCancelButton() {
    const { editMode, myProfile, t } = this.props;

    let pathName = '/users/';

    if (myProfile) {
      pathName = '/';
    }

    if (editMode) {
      return (
        <div className="justify-content-center row">
          <div className="col-auto py-1">
            <Link to={{
              pathname: pathName,
              state: { editMode: false }
            }}
            >
              <Button variant="raised">
                {t('buttonCancel')}
              </Button>
            </Link>
          </div>
        </div>
      );
    }

    return <div />;
  }

  renderUserName() {
    const { userDetails } = this.props;

    if (_isNil(userDetails)) {
      return '';
    }

    return `${capitalizeFirstLetter(userDetails.firstName)} ${capitalizeFirstLetter(userDetails.lastName)}`;
  }

  registerComponentForm(componentName) {
    return (triggerFn) =>{
      this.triggers[componentName] = triggerFn;
    };
  }

  formValidated = (formName, isFormValid, formChanges) => {
    this.formData = {
      ...this.formData,
      [`${formName}Changes`]: formChanges,
      [`${formName}isValid`]: isFormValid,
    };
  };

  @renderPermission(PERMISSIONS.userEdit)
  renderEditButton() {
    return (
      <div className="col-auto">
        <Link to={{
          pathname: `/users/${this.props.userDetails.id}`,
          state: { editMode: true }
        }}
        >
          <IconButton><Icon>edit</Icon></IconButton>
        </Link>
      </div>
    );
  }

  @renderModule(MODULES.complementaryCertificate)
  renderComplementaryCertificates(userId, editMode, documentTypeList, userDetails) {
    const { complementaryCertificateList } = this.props;

    return (
      <ComplementaryCertificate
        userId={userId}
        editMode={editMode}
        userDetails={userDetails}
        complementaryCertificateList={complementaryCertificateList}
      />
    );
  }

  renderCompetenciesList(userId, editMode, competenciesList, userDetails) {

    return (
      <UserCompetencies
        userId={userId}
        editMode={editMode}
        competenciesList={competenciesList}
        userCompetenciesList={_get(userDetails, 'competencies', [])}
        onElementDelete={this.openRemoveDialog}
      />
    );
  }

  @renderModule(MODULES.userProfilePersonalFile)
  renderPersonalFiles(userId, documentTypeList, editMode) {
    return (
      <UserPersonalFiles userId={userId} documentTypeList={documentTypeList} editMode={editMode} onElementDelete={this.openRemoveDialog} />
    );
  }

  @renderModule(MODULES.userProfileLicence)
  renderLicences(userId, documentTypeList, editMode) {
    return (
      <UserLicenses userId={userId} documentTypeList={documentTypeList} editMode={editMode} onElementDelete={this.openRemoveDialog} />
    );
  }

  @renderModule(MODULES.userProfileMedical)
  renderMedicals(userId, documentTypeList, editMode) {
    return (
      <UserMedicals userId={userId} documentTypeList={documentTypeList} editMode={editMode} onElementDelete={this.openRemoveDialog} />
    );
  }

  @renderModule(MODULES.userProfileType)
  renderTypes(userId, documentTypeList, editMode) {
    return (
      <UserRatings userId={userId} documentTypeList={documentTypeList} editMode={editMode} onElementDelete={this.openRemoveDialog} />
    );
  }

  @renderModule(MODULES.userProfileQualification)
  renderQualifications(userId, documentTypeList, editMode) {
    return (
      <UserQualifications userId={userId} documentTypeList={documentTypeList} editMode={editMode} onElementDelete={this.openRemoveDialog} />
    );
  }

  @renderModule(MODULES.userProfileExternalCourse)
  renderExternalCourses(userId, documentTypeList, editMode) {
    return (
      <UserExternalCourses userId={userId} documentTypeList={documentTypeList} editMode={editMode} onElementDelete={this.openRemoveDialog} />
    );
  }

  render() {
    const {
      editMode, userProfileRequest, userDetails, userId, groups,
      attachProfileImage, removeProfileImage, documentTypeRequest, documentTypeList, competenciesList
    } = this.props;
    const { showRemoveDialog, removeCallback } = this.state;

    if (userProfileRequest || documentTypeRequest) {
      return <Loader />;
    }

    if (!_isNil(userDetails)) {
      return (
        <div className="user-wrapper">
          <div className="row justify-content-end align-items-center">
            {!editMode && this.renderEditButton()}
          </div>
          <UserDetails
            userDetails={userDetails}
            editMode={editMode}
            groups={groups}
            registerComponentForm={this.registerComponentForm('userDetails')}
            formValidated={this.formValidated}
            addSimpleError={this.props.addSimpleError}
            attachProfileImage={attachProfileImage}
            removeProfileImage={removeProfileImage}
          />
          {editMode && this.renderSaveButton()}
          {this.renderComplementaryCertificates(userId, editMode, documentTypeList, userDetails)}
          {this.renderCompetenciesList(userId, editMode, competenciesList, userDetails)}
          {this.renderPersonalFiles(userId, documentTypeList, editMode)}
          {this.renderLicences(userId, documentTypeList, editMode)}
          {this.renderMedicals(userId, documentTypeList, editMode)}
          {this.renderTypes(userId, documentTypeList, editMode)}
          {this.renderQualifications(userId, documentTypeList, editMode)}
          {this.renderExternalCourses(userId, documentTypeList, editMode)}
          {editMode && this.renderCancelButton()}
          <RemoveDialogContainer
            openDialog={showRemoveDialog}
            onClose={this.closeRemoveDialog}
            removeFunction={removeCallback}
          />
        </div>
      );
    }

    return <div />;
  }
}
