import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';

import Loader from '../../../common/components/Loader';
import logo from '../../../images/qcLogo.png';
import { URLS_RAIL as URLS } from '../../../constants';
import { removeTimezone } from '../../utils/time';
import { urlJoin } from '../../../utils';

const BOOKING_REQUEST_STATUS = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  NOT_FOUND: 'not_found'
};

@withNamespaces()
export default class BookingRequest extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string.isRequired
      })
    })
  };

  state = {
    status: BOOKING_REQUEST_STATUS.LOADING,
    bookingRequest: null,
  };

  componentDidMount() {
    const token = this.props.match.params.token;

    axios.get(urlJoin(URLS.bookingRequest, token))
      .then(({ data }) => this.setState({ status: BOOKING_REQUEST_STATUS.LOADED, bookingRequest: data }))
      .catch(({ response: { status } }) => {
        status === 404 ? this.setState({ status: BOOKING_REQUEST_STATUS.NOT_FOUND }) : null;
      });
  }

  updateBookingActiveStatus = (active) => {
    return () => {
      const { token, booking: { id } } = this.state.bookingRequest;

      this.setState({ status: BOOKING_REQUEST_STATUS.LOADING });
      axios.patch(urlJoin(URLS.bookings, id, token), { active })
        .then(() => {
          this.setState({ status: active ? BOOKING_REQUEST_STATUS.ACCEPTED : BOOKING_REQUEST_STATUS.DECLINED });
        })
        .catch((error) => {
          console.error(error);
        });
    };

  };

  renderBookingRequest = () => {
    const { t } = this.props;
    const {
      booking: {
        user: { firstName, lastName },
        bookingObject: { name: bookingObject },
        withUser: { firstName: withFirstName, lastName: withLastName },
        startDate,
        endDate
      }
    } = this.state.bookingRequest;

    return (
      <Fragment>
        <section className="list-table">
          {
            Object.entries({
              [`${t('userUpper')}:`]: `${firstName} ${lastName}`,
              [`${t('booking:trainingWithUser')}:`]: `${withFirstName} ${withLastName}`,
              [`${t('booking:bookingObjectUpper')}:`]: bookingObject,
              [`${t('booking:startDate')}:`]: removeTimezone(startDate),
              [`${t('booking:endDate')}:`]: removeTimezone(endDate)
            }).map(([label, value], index) => (
              <Fragment key={index}>
                <div className="text-right font-weight-bold my-1">{label}</div>
                <div className="text-left my-1">{value}</div>
              </Fragment>
            ))
          }
        </section>
        <Button
          className="my-3 mx-3"
          onClick={this.updateBookingActiveStatus(false)}
          color="secondary"
          variant="raised"
        >
          {t('buttonDecline')}
        </Button>
        <Button
          className="my-3 mx-3"
          onClick={this.updateBookingActiveStatus(true)}
          color="primary"
          variant="raised"
        >
          {t('buttonAccept')}
        </Button>
      </Fragment>
    );
  };

  renderBooking = () => {
    const { status } = this.state;

    const bookingRequestResultComponent = status === BOOKING_REQUEST_STATUS.LOADING ?
      <Loader /> :
      status === BOOKING_REQUEST_STATUS.LOADED ?
        this.renderBookingRequest() :
        status === BOOKING_REQUEST_STATUS.ACCEPTED ?
          <div>ACCEPTED</div> :
          status === BOOKING_REQUEST_STATUS.DECLINED ?
            <div>DECLINED</div> :
            <div>Booking request is invalid or activation link expired.</div>;

    return (
      <Paper elevation={4} className="col-xl-5 col-lg-6 col-md-7 text-center p-5">
        <img className="logo pb-3" src={logo} />
        <h3>Booking request</h3>
        {bookingRequestResultComponent}
      </Paper>
    );
  };

  render() {
    return (
      <div className="container-fluid page-content booking-request">
        <div className="container-fluid page-content login">
          <div className="row align-items-center justify-content-center full-height">
            {this.renderBooking()}
          </div>
        </div>
      </div>
    );
  }
}
