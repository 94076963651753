import {
  FETCH_COMPETENCIES_SUCCESS,
  FETCH_COMPETENCIES_REQUEST,
  FETCH_COMPETENCIES_FAILURE,
  ADD_COMPETENCE,
  REMOVE_COMPETENCE,
} from '../actions/competencies';

const INITIAL_STATE = {
  competenciesList: [],
  competenciesRequest: false,
};

function fetchCompetencies(state, payload) {
  return {
    ...state,
    competenciesList: payload.competencies,
    competenciesRequest: false,
  };
}

function fetchCompetenciesRequest(state) {
  return {
    ...state,
    competenciesRequest: true,
  };
}

function fetchCompetenciesFailure(state) {
  return {
    ...state,
    competenciesRequest: false,
  };
}

function addCompetence(state, payload) {
  return {
    ...state,
    competenciesList: [
      ...state.competenciesList,
      payload.competence
    ]
  };
}

function removeCompetence(state, payload) {
  return {
    ...state,
    competenciesList: state.competenciesList.filter((competence) => competence !== payload.competence)
  };
}

const actions = {
  [FETCH_COMPETENCIES_SUCCESS]: fetchCompetencies,
  [FETCH_COMPETENCIES_REQUEST]: fetchCompetenciesRequest,
  [FETCH_COMPETENCIES_FAILURE]: fetchCompetenciesFailure,
  [ADD_COMPETENCE]: addCompetence,
  [REMOVE_COMPETENCE]: removeCompetence,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
