import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';

import CourseList from './CourseList';
import AssignView from '../../../containers/AssignView';
import Loader from '../../../../common/components/Loader';
import Confirmation from '../../../../common/components/Confirmation';
import RemoveDialog from '../../../../common/components/RemoveDialog';

import { fetchCourses, removeCourse } from '../../../actions/courses';
import { resetAssignCourseFlag, assignCourse } from '../../../actions/assignedCourses';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission, renderPermission } from '../../../decorators/permissions';


const INITIAL_STATE = {
  searchSettings: {
    sortAsc: true,
    search: ''
  },
  assignOpen: false,
  removeDialogOpen: false,
  removeCourse: null,
};

const mapStateToProps = (state) => {
  return {
    courseList: state.courses.courseList.filter((course) => course.isActive),
    courseRequest: state.courses.courseRequest,
    assignCourseSuccess: state.assignedCourses.assignCourseSuccess
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCourses: (sortData) => dispatch(fetchCourses(sortData)),
    removeCourse: (course) => dispatch(removeCourse(course)),
    resetAssignCourseFlag: () => dispatch(resetAssignCourseFlag()),
    assignCourse: (assignData) => dispatch(assignCourse(assignData))
  };
}
@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.coursesView)
export default class ManageCourses extends Component {
  static propTypes = {
    courseList: PropTypes.array,
    courseRequest: PropTypes.bool.isRequired,
    fetchCourses: PropTypes.func.isRequired,
    removeCourse: PropTypes.func.isRequired,
    resetAssignCourseFlag: PropTypes.func.isRequired,
    assignCourseSuccess: PropTypes.bool.isRequired,
    assignCourse: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.fetchCourses();
  }

  @renderPermission(PERMISSIONS.addNewCourse)
  renderAddCourseIcon(t) {
    return (
      <div className="col-auto">
        <Link to="/course_admin/new">
          <Button color="primary">
            <Icon color="primary" className="mr-3">playlist_add</Icon>
            {t('course:addCourseButton')}
          </Button>
        </Link>
      </div>
    );
  }

  handleSort = () => {
    const searchData = {
      sortAsc: !this.state.searchSettings.sortAsc,
      search: this.state.searchSettings.search
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchCourses(searchData);
  };

  handleSearch = (search) => {
    const searchData = {
      sortAsc: this.state.searchSettings.sortAsc,
      search
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchCourses(searchData);
  };

  handleAssignCourseOpen = (courseId) => {
    this.setState({
      assignOpen: true,
      courseId
    });
  };

  handleAssignCourseClose = () => {
    this.setState({
      assignOpen: false,
    });
  };

  serializeData = (users, scheduleTime, isActive) => {
    const { courseId } = this.state;

    users.forEach((userId) => {
      const dataToSend = {
        courseId,
        userId,
        isActive,
      };

      if (!_isEmpty(scheduleTime) && !isActive) {
        dataToSend.scheduleTime = scheduleTime;
      }

      this.props.assignCourse(dataToSend);
    });
  };

  removeCourse = () => {
    const { removeCourse } = this.state;

    this.closeRemoveDialog();
    this.props.removeCourse(removeCourse);
  };

  openRemoveDialog = (course) => {
    this.setState({
      removeDialogOpen: true,
      removeCourse: course,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeCourse: null,
    });
  };

  renderRemoveDialog = (t) => {
    return (
      <Dialog
        open={this.state.removeDialogOpen}
        onClose={this.closeRemoveDialog}
        aria-labelledby="remove-dialog"
        fullWidth
      >
        <RemoveDialog
          message={t('course:removeCourse')}
          closeRemoveDialog={this.closeRemoveDialog}
          dialogTitle={t('course:removeCourseDialogTitle')}
          removeFunction={this.removeCourse}
        />
      </Dialog>
    );
  };

  render() {
    const { courseList, courseRequest, assignCourseSuccess, resetAssignCourseFlag, t } = this.props;
    const { searchSettings, assignOpen } = this.state;

    if (courseRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">
                content_paste
              </Icon>
              <h1>{t('course:name')}<span className="h1-subheader"> /{t('course:courseAdminSection')}</span></h1>
            </span>
            <p>{t('course:descriptionAdmin')}</p>
          </header>
        </div>
        { this.renderAddCourseIcon(t) }
        <div className="col-12">
          <CourseList
            courseList={courseList}
            removeCourse={this.removeCourse}
            sortAsc={searchSettings.sortAsc}
            handleSort={this.handleSort}
            handleSearch={this.handleSearch}
            search={searchSettings.search}
            handleAssignCourseOpen={this.handleAssignCourseOpen}
            openRemoveDialog={this.openRemoveDialog}
          />
          {this.renderRemoveDialog(t)}
          <Dialog
            open={assignOpen}
            onClose={this.handleAssignCourseClose}
            aria-labelledby="assign-course"
            maxWidth="md"
            fullWidth
          >
            <AssignView
              handleAssignViewClose={this.handleAssignCourseClose}
              serializeData={this.serializeData}
              viewLabel={t('course:assignViewDialogTitle')}
            />
          </Dialog>
          <Dialog
            open={assignCourseSuccess}
            onClose={resetAssignCourseFlag}
            aria-labelledby="confirmation-assign"
            fullWidth
          >
            <Confirmation
              message={t('course:courseSuccessfulyAssigned')}
              closeConfirmationDialog={resetAssignCourseFlag}
            />
          </Dialog>
        </div>
      </div>
    );
  }
}
