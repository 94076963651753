import {
  FETCH_PILOT_FUNCTIONS_SUCCESS,
  FETCH_PILOT_FUNCTIONS_REQUEST,
  FETCH_PILOT_FUNCTIONS_FAILURE,
} from '../actions/pilotFunction';

const INITIAL_STATE = {
  pilotFunctionList: [],
  pilotFunctionsRequest: false,
};

function fetchPilotFunctions(state, payload) {
  return {
    ...state,
    pilotFunctionList: payload.pilotFunctionList,
    pilotFunctionsRequest: false,
  };
}

function fetchPilotFunctionsRequest(state) {
  return {
    ...state,
    pilotFunctionsRequest: true,
  };
}

function fetchPilotFunctionsFailure(state) {
  return {
    ...state,
    pilotFunctionsRequest: false,
  };
}

const actions = {
  [FETCH_PILOT_FUNCTIONS_SUCCESS]: fetchPilotFunctions,
  [FETCH_PILOT_FUNCTIONS_REQUEST]: fetchPilotFunctionsRequest,
  [FETCH_PILOT_FUNCTIONS_FAILURE]: fetchPilotFunctionsFailure,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
