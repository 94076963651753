import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _find from 'lodash/find';
import { withNamespaces } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';

import Confirmation from '../../../common/components/Confirmation';
import RemoveDialogContainer from '../../../common/components/RemoveDialogContainer';

import ReportRecipentsManager from './Reports/ReportRecipentsManager';
import ReportCategoriesManager from './Reports/ReportCategoriesManager';
import ReportFleetCategoriesManager from './Reports/ReportFleetCategoriesManager';
import ReportFieldsManager from './Reports/ReportFieldsManager';
import Loader from '../../../common/components/Loader';

import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';
import { componentPermission } from '../../decorators/permissions';

import './styles.scss';

const INITIAL_STATE = {
  showRemoveDialog: false,
  removeCallback: () => {},
  companyInfoSavedDialogOpen: false,
};

const mapStateToProps = (state) => {
  return {
    schoolLogo: _find(state.settings.settings, { name: 'school_logo' }),
    digitalSignature: _find(state.settings.settings, { name: 'digital_signature' }),
    settingsRequest: state.settings.settingsRequest,
  };
};

@withNamespaces()
@connect(mapStateToProps)
@componentPermission(PERMISSIONS.reportsSettingsView)
export default class UserSettings extends Component {
  static propTypes = {
    schoolLogo: PropTypes.object,
    digitalSignature: PropTypes.object,
    fetchSettings: PropTypes.func,
    settingsRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  openCompanyInfoSavedDialog = () => {
    this.setState({
      companyInfoSavedDialogOpen: true,
    });
  };

  closeCompanyInfoSavedDialog = () => {
    this.setState({
      companyInfoSavedDialogOpen: false,
    });
  };

  render() {
    const { schoolLogo, digitalSignature, settingsRequest, t } = this.props;
    const { showRemoveDialog, removeCallback, companyInfoSavedDialogOpen } = this.state;

    if (settingsRequest || !schoolLogo || !digitalSignature) {
      return <Loader />;
    }

    return (
      <div className="settings row">
        <div className="col-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">
                settings
              </Icon>
              <h1>{t('settings:name')}<span className="h1-subheader"> /{t('settings:reportsSection')}</span></h1>
            </span>
            <p>{t('settings:description')}</p>
          </header>
        </div>
        <div className="col-12">
          <ReportRecipentsManager onElementDelete={this.openRemoveDialog} />
          <ReportCategoriesManager onElementDelete={this.openRemoveDialog} />
          <ReportFleetCategoriesManager onElementDelete={this.openRemoveDialog} />
          <ReportFieldsManager onInfoSuccessfullySaved={this.openCompanyInfoSavedDialog} />
        </div>
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
        <Dialog
          open={companyInfoSavedDialogOpen}
          onClose={this.closeCompanyInfoSavedDialog}
          aria-labelledby="confirmation-save"
          fullWidth
        >
          <Confirmation
            message={t('settings:reportFormSaved')}
            closeConfirmationDialog={this.closeCompanyInfoSavedDialog}
          />
        </Dialog>
      </div>
    );
  }
}
