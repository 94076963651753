import {
  ADD_RISK_ASSESSMENT_FAILURE,
  ADD_RISK_ASSESSMENT_REQUEST,
  ADD_RISK_ASSESSMENT,
  EDIT_RISK_ASSESSMENT,
  FETCH_RISK_ASSESSMENT_FAILURE,
  FETCH_RISK_ASSESSMENT_REQUEST,
  FETCH_RISK_ASSESSMENT_SUCCESS,
  GET_RISK_ASSESSMENT,
  REMOVE_RISK_ASSESSMENT,
} from '../actions/riskAssessments';

const INITIAL_STATE = {
  riskAssessmentList: [],
  riskAssessmentRequest: false,
  addRiskAssessmentRequest: false,
};

function fetchRiskAssessmentSuccess(state, payload) {
  return {
    ...state,
    riskAssessmentList: payload.riskAssessments,
    riskAssessmentRequest: false,
  };
}

function fetchRiskAssessmentRequest(state) {
  return {
    ...state,
    riskAssessmentRequest: true,
  };
}

function fetchRiskAssessmentFailure(state) {
  return {
    ...state,
    riskAssessmentRequest: false,
  };
}

function addRiskAssessmentFailure(state, payload) {
  return {
    ...state,
    addRiskAssessmentRequest: false
  }
}

function addRiskAssessmentRequest(state, payload) {
  return {
    ...state,
    addRiskAssessmentRequest: true
  }
}

function addRiskAssessment(state, payload) {
  return {
    ...state,
    riskAssessmentList: [
      ...state.riskAssessmentList,
      payload.riskAssessment
    ],
    addRiskAssessmentRequest: false
  };
}

function getRiskAssessment(state, payload) {
  return {
    ...state,
    riskAssessment: payload.riskAssessment,
    riskAssessmentRequest: false,
  };
}

function editRiskAssessment(state, payload) {
  return {
    ...state,
    riskAssessmentList: state.riskAssessmentList.map((riskAssessment) => {
      if (riskAssessment.id === payload.riskAssessment.id) {
        return payload.riskAssessment;
      }

      return riskAssessment;
    }),
  };
}

function removeRiskAssessment(state, payload) {
  return {
    ...state,
    riskAssessmentList: state.riskAssessmentList.filter((riskAssessment) => riskAssessment.id !== payload.riskAssessment.id),
  };
}

const actions = {
  [FETCH_RISK_ASSESSMENT_SUCCESS]: fetchRiskAssessmentSuccess,
  [FETCH_RISK_ASSESSMENT_FAILURE]: fetchRiskAssessmentFailure,
  [FETCH_RISK_ASSESSMENT_REQUEST]: fetchRiskAssessmentRequest,
  [ADD_RISK_ASSESSMENT_FAILURE]: addRiskAssessmentFailure,
  [ADD_RISK_ASSESSMENT_REQUEST]: addRiskAssessmentRequest,
  [ADD_RISK_ASSESSMENT]: addRiskAssessment,
  [GET_RISK_ASSESSMENT]: getRiskAssessment,
  [EDIT_RISK_ASSESSMENT]: editRiskAssessment,
  [REMOVE_RISK_ASSESSMENT]: removeRiskAssessment,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
