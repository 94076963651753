import { URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { urlJoin, } from '../../utils';

export const FETCH_INSPECTION_REQUEST = 'FETCH_INSPECTION_REQUEST';
export const FETCH_INSPECTIONS_REQUEST = 'FETCH_INSPECTIONS_REQUEST';
export const FETCH_INSPECTIONS_SUCCESS = 'FETCH_INSPECTIONS_SUCCESS';
export const FETCH_INSPECTION_SUCCESS = 'FETCH_INSPECTION_SUCCESS';
export const FETCH_INSPECTIONS = 'FETCH_INSPECTIONS';
export const ADD_INSPECTION = 'ADD_INSPECTION';
export const REMOVE_INSPECTION = 'REMOVE_INSPECTION';
export const EDIT_INSPECTION = 'EDIT_INSPECTION';
export const CLEAN_INSPECTION_DATA = 'CLEAN_INSPECTION_DATA';


export function fetchInspectionsRequest() {
  return {
    type: FETCH_INSPECTIONS_REQUEST
  };
}

export function fetchInspectionsSuccess(inspections) {
  return {
    type: FETCH_INSPECTIONS_SUCCESS,
    payload: {
      inspections
    }
  };
}

export function fetchInspectionRequest() {
  return {
    type: FETCH_INSPECTION_REQUEST
  };
}

export function fetchInspectionSuccess(inspection) {
  return {
    type: FETCH_INSPECTION_SUCCESS,
    payload: {
      inspection
    }
  };
}

export function fetchAllInspectionsRequest() {
  return {
    type: FETCH_INSPECTIONS,
  };
}

export function addInspectionSuccess(inspection) {
  return {
    type: ADD_INSPECTION,
    payload: {
      inspection
    }
  };
}

export function removeInspectionSuccess(inspection) {
  return {
    type: REMOVE_INSPECTION,
    payload: {
      inspection
    }
  };
}

export function editInspectionSuccess(inspection) {
  return {
    type: EDIT_INSPECTION,
    payload: {
      inspection
    }
  };
}

export function cleanInspection() {
  return {
    type: CLEAN_INSPECTION_DATA,
  };
}

export function fetchInspections() {
  return (dispatch) => {
    dispatch(fetchInspectionsRequest());

    return axios.get(urlJoin(URLS.inspections))
      .then(({ data }) => dispatch(fetchInspectionsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during inspections fetching ${error.message}`, error));
      });
  };
}

export function fetchInspection(inspectionId) {
  return (dispatch) => {
    dispatch(fetchInspectionRequest());

    return axios.get(urlJoin(URLS.inspections, inspectionId))
      .then(({ data }) => {
        dispatch(fetchInspectionSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during inspection fetching ${error.message}`));
      });
  };
}

export function addInspection(inspection) {
  return (dispatch) => {
    return axios.post(URLS.inspections, inspection)
      .then(({ data }) => {
        dispatch(addInspectionSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during inspection adding ${error.message}`, error));
      });
  };
}

export function editInspection(inspection) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.inspections, inspection.id), inspection)
      .then(({ data }) => {
        dispatch(editInspectionSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during inspection editing ${error.message}`, error));
      });
  };
}

export function removeInspection(inspection) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.inspections, inspection.id))
      .then(() => dispatch(removeInspectionSuccess(inspection)))
      .catch((error) => {
        dispatch(addError(`Error during inspection removing ${error.message}`, error));
      });
  };
}

export function cleanInspectionData() {
  return (dispatch) => dispatch(cleanInspection());
}
