import axios from 'axios';
import _get from 'lodash/get';

import { addError } from './errors';
import { urlJoin, capitalizeFirstLetter, getUserSettingsParametersFilter } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_TYPES_REQUEST = 'FETCH_TYPES_REQUEST';
export const FETCH_TYPES_SUCCESS = 'FETCH_TYPES_SUCCESS';
export const FETCH_ALL_TYPES_SUCCESS = 'FETCH_ALL_TYPES_SUCCESS';
export const FETCH_TYPES_FAILURE = 'FETCH_TYPES_FAILURE';
export const FETCH_USER_TYPES_SUCCESS = 'FETCH_USER_TYPES_SUCCESS';
export const ADD_TYPE = 'ADD_TYPE';
export const REMOVE_TYPE = 'REMOVE_TYPE';


export function fetchTypesRequest() {
  return {
    type: FETCH_TYPES_REQUEST
  };
}

export function fetchAllTypesSuccess(allTypes) {
  return {
    type: FETCH_ALL_TYPES_SUCCESS,
    payload: {
      allTypes
    }
  };
}

export function fetchUserTypesSuccess(userTypes) {
  return {
    type: FETCH_USER_TYPES_SUCCESS,
    payload: {
      userTypes
    }
  };
}

export function fetchTypesSuccess(types) {
  return {
    type: FETCH_TYPES_SUCCESS,
    payload: {
      types
    }
  };
}

export function fetchTypesFailure() {
  return {
    type: FETCH_TYPES_FAILURE
  };
}

export function addTypeSuccess(types) {
  return {
    type: ADD_TYPE,
    payload: {
      types
    }
  };
}

export function removeTypeSuccess(types) {
  return {
    type: REMOVE_TYPE,
    payload: {
      types
    }
  };
}

export function fetchAllTypes() {
  return (dispatch) => {
    dispatch(fetchTypesRequest());

    return axios.get(URLS.types)
      .then(({ data }) => dispatch(fetchAllTypesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchTypesFailure());
        dispatch(addError(`Error during all types fetching ${error.message}`, error));
      });
  };
}

export function addType(type) {
  return (dispatch) => {
    return axios.post(URLS.types, type)
      .then(({ data }) => dispatch(addTypeSuccess(data)))
      .catch((error) => {
        const errorData = _get(error, 'response.data.name[0]', `error during type adding ${error.message}`);

        dispatch(addError(capitalizeFirstLetter(errorData), error));
      });
  };
}

export function removeType(type) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.types, type.id))
      .then(() => dispatch(removeTypeSuccess(type)))
      .catch((error) => {
        dispatch(addError(`Error during type removing ${error.message}`, error));
      });
  };
}

export function fetchTypes(userId) {
  return (dispatch) => {
    dispatch(fetchTypesRequest());

    const queryParameters = `?${getUserSettingsParametersFilter(userId)}`;

    return axios.get(urlJoin(URLS.types, queryParameters))
      .then(({ data }) => dispatch(fetchTypesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchTypesFailure());
        dispatch(addError(`Error during types fetching ${error.message}`, error));
      });
  };
}

export function fetchUserSettingsTypes(userId) {
  return (dispatch) => {
    const queryParameters = `?${getUserSettingsParametersFilter(userId)}`;

    return axios.get(urlJoin(URLS.userSettingsTypes, queryParameters))
      .then(({ data }) => dispatch(fetchUserTypesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchTypesFailure());
        dispatch(addError(`Error during types fetching ${error.message}`, error));
      });
  };
}
