import {
  ADD_MEDICAL,
  REMOVE_MEDICAL,
  EDIT_MEDICAL,
  FETCH_MEDICALS_SUCCESS,
  FETCH_ALL_MEDICALS_SUCCESS,
  FETCH_MEDICALS,
} from '../actions/medicals';
import {
  REMOVE_MEDICAL_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  medicalList: [],
  allMedicalsList: [],
  medicalRequest: false
};

function fetchMedicalsSuccess(state, payload) {
  return {
    ...state,
    medicalList: payload.medicals,
    medicalRequest: false,
  };
}

function fetchAllMedicalsSuccess(state, payload) {
  return {
    ...state,
    allMedicalsList: payload.allMedicals,
    medicalRequest: false
  };
}

function fetchAllMedicals(state) {
  return {
    ...state,
    medicalRequest: true
  };
}

function addMedical(state, payload) {
  return {
    ...state,
    medicalList: [
      ...state.medicalList,
      payload.medical
    ]
  };
}

function removeMedical(state, payload) {
  return {
    ...state,
    medicalList: state.medicalList.filter((medical) => medical !== payload.medical)
  };
}

function editMedical(state, payload) {
  return {
    ...state,
    medicalList: state.medicalList.map((medical) => {
      if (medical.id === payload.medical.id) {
        return payload.medical;
      }

      return medical;
    }),
    allMedicalsList: state.allMedicalsList.map((medical) => {
      if (medical.id === payload.medical.id) {
        return payload.medical;
      }

      return medical;
    })
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    medicalList: state.medicalList.map((medical) => {
      if (medical.id === payload.medicalId) {
        return {
          ...medical,
          files: medical.files.filter((file) => file.id !== payload.fileId)
        };
      }

      return medical;
    })
  };
}

const actions = {
  [ADD_MEDICAL]: addMedical,
  [REMOVE_MEDICAL]: removeMedical,
  [EDIT_MEDICAL]: editMedical,
  [FETCH_MEDICALS_SUCCESS]: fetchMedicalsSuccess,
  [FETCH_ALL_MEDICALS_SUCCESS]: fetchAllMedicalsSuccess,
  [FETCH_MEDICALS]: fetchAllMedicals,
  [REMOVE_MEDICAL_FILE_SUCCESS]: removeFile,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
