import axios from 'axios';

import { URLS_RAIL as URLS } from '../../constants';
import { addError } from './errors';
import { urlJoin } from '../../utils';

export const ADD_PERSONAL_FILE = 'ADD_PERSONAL_FILE';
export const REMOVE_PERSONAL_FILE = 'REMOVE_PERSONAL_FILE';
export const EDIT_PERSONAL_FILE = 'EDIT_PERSONAL_FILE';
export const FETCH_PERSONAL_FILES = 'FETCH_PERSONAL_FILES';

export function addPersonalFileSuccess(personalFile) {
  return {
    type: ADD_PERSONAL_FILE,
    payload: { personalFile }
  };
}

export function addPersonalFile(personalFile) {
  return (dispatch) => {
    return axios.post(URLS.personalFiles, personalFile)
      .then(({ data }) => dispatch(addPersonalFileSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during personal file adding ${error.message}`, error));
      });
  };
}

export function deletePersonalFileSuccess(personalFile) {
  return {
    type: REMOVE_PERSONAL_FILE,
    payload: { personalFile }
  };
}

export function deletePersonalFile(personalFile) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.personalFiles, personalFile.id))
      .then(() => dispatch(deletePersonalFileSuccess(personalFile)))
      .catch((error) => {
        dispatch(addError(`Error during personal file removal ${error.message}`, error));
      });
  };
}

export function editPersonalFileSuccess(personalFile) {
  return {
    type: EDIT_PERSONAL_FILE,
    payload: { personalFile }
  };
}

export function editPersonalFile(personalFile) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.personalFiles, personalFile.id), personalFile)
      .then(({ data }) => dispatch(editPersonalFileSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during personal file edition ${error.message}`, error));
      });
  };
}

export function fetchPersonalFilesSuccess(personalFiles) {
  return {
    type: FETCH_PERSONAL_FILES,
    payload: { personalFiles }
  };
}
