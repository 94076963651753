import React, { Component } from 'react';
import {
  getDocumentStatus,
  getInspectionStatus,
  getPartStatus,
  renderDateColor,
  renderDocumentColor,
  renderInspectionColor,
  renderPartColor,
  renderTickerToService,
} from '../fleetHelper';

import PropTypes from 'prop-types';
import StatusReportList from './StatusReportList';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const mapStateToProps = (state) => {
  return {
    documentList: state.documents.documentList,
  };
};

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, () => {})
export default class StatusReportManager extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    documentList: PropTypes.array.isRequired,
    inspectionList: PropTypes.array.isRequired,
    partFleetList: PropTypes.array.isRequired,
    fleetSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {}

  fromDocumentList() {
    const { documentList } = this.props;

    const result = documentList.map((document) => {
      const { name, expiryDate } = document;

      return {
        statusData: getDocumentStatus(document),
        status: renderDocumentColor(document),
        name,
        serviceDateData: '',
        eolDateData: expiryDate ? expiryDate : '',
        eolDate: renderDateColor(document, 'expiryDate'),
      };
    });

    return result;
  }

  fromInspectionList() {
    const { inspectionList, fleet, fleetSettings } = this.props;

    const result = inspectionList.map((inspection) => {
      const { name, isCompleted, serviceHours, serviceCycles, serviceDate } =
        inspection;

      const referenceCycles =
        inspection.serviceActualCycles > 0
          ? inspection.serviceActualCycles
          : inspection.serviceCycles;

      const referenceHours =
        inspection.serviceActualHours > 0
          ? inspection.serviceActualHours
          : inspection.serviceHours;

      return {
        statusData: getInspectionStatus(inspection, fleet, fleetSettings),
        status: renderInspectionColor(inspection, fleet, fleetSettings),
        name,
        hoursToServiceData: serviceHours
          ? Number(serviceHours) -
            (isCompleted ? referenceHours : fleet.currentHours)
          : null,
        hoursToService: renderTickerToService(
          inspection,
          'serviceHours',
          fleetSettings.hoursWarning,
          isCompleted ? referenceHours : fleet.currentHours,
        ),
        cyclesToServiceData: serviceCycles
          ? Number(serviceCycles) -
            (isCompleted ? referenceCycles : fleet.currentCycles)
          : null,
        cyclesToService: renderTickerToService(
          inspection,
          'serviceCycles',
          fleetSettings.distanceWarning,
          isCompleted ? referenceCycles : fleet.currentCycles,
        ),
        serviceDateData: serviceDate ? serviceDate : '',
        serviceDate: renderDateColor(inspection, 'serviceDate'),
        eolDateData: '',
      };
    });

    return result;
  }

  fromPartFleetList() {
    const { partFleetList, fleet, fleetSettings } = this.props;

    const result = partFleetList
      .filter((part) => !part.nonSafetyRelated)
      .map((part) => {
        const {
          name,
          partNumber,
          serialNumber,
          serviceHours,
          serviceCycles,
          serviceDate,
          eolDate,
        } = part;

        return {
          statusData: getPartStatus(part, fleet, fleetSettings),
          status: renderPartColor(part, fleet, fleetSettings),
          name,
          partNumber,
          serialNumber,
          hoursToServiceData: serviceHours
            ? Number(serviceHours) - fleet.currentHours
            : null,
          hoursToService: renderTickerToService(
            part,
            'serviceHours',
            fleetSettings.hoursWarning,
            fleet.currentHours,
          ),
          cyclesToServiceData: serviceCycles
            ? Number(serviceCycles) - fleet.currentCycles
            : null,
          cyclesToService: renderTickerToService(
            part,
            'serviceCycles',
            fleetSettings.distanceWarning,
            fleet.currentCycles,
          ),
          serviceDateData: serviceDate ? serviceDate : '',
          serviceDate: renderDateColor(part, 'serviceDate'),
          eolDateData: eolDate ? eolDate : '',
          eolDate: renderDateColor(part, 'eolDate'),
        };
      });

    return result;
  }

  render() {
    const { fleet, t } = this.props;

    const statusReportList = [].concat(
      this.fromInspectionList(),
      this.fromPartFleetList(),
      this.fromDocumentList(),
    );

    return (
      <div className="status-report">
        <h4>{t('fleet:Status Report')}</h4>
        <StatusReportList fleet={fleet} statusReportList={statusReportList} />
      </div>
    );
  }
}
