/* eslint-disable promise/no-nesting */

import axios from 'axios';
import queryString from 'query-string';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_STAFF_CATEGORIES_REQUEST = 'FETCH_STAFF_CATEGORIES_REQUEST';
export const FETCH_STAFF_CATEGORIES_SUCCESS = 'FETCH_STAFF_CATEGORIES_SUCCESS';
export const FETCH_STAFF_CATEGORIES_FAILURE = 'FETCH_STAFF_CATEGORIES_FAILURE';
export const ADD_STAFF_CATEGORY = 'ADD_STAFF_CATEGORY';
export const EDIT_STAFF_CATEGORY = 'EDIT_STAFF_CATEGORY';
export const REMOVE_STAFF_CATEGORY = 'REMOVE_STAFF_CATEGORY';
export const REMOVE_COMPETENCE_FROM_STAFF_CATEGORY = 'REMOVE_COMPETENCE_FROM_STAFF_CATEGORY';


export function fetchStaffCategoriesRequest() {
  return {
    type: FETCH_STAFF_CATEGORIES_REQUEST
  };
}

export function fetchStaffCategoriesSuccess(staffCategories) {
  return {
    type: FETCH_STAFF_CATEGORIES_SUCCESS,
    payload: {
      staffCategories
    }
  };
}

export function fetchStaffCategoriesFailure() {
  return {
    type: FETCH_STAFF_CATEGORIES_FAILURE
  };
}

export function addStaffCategorySuccess(staffCategory) {
  return {
    type: ADD_STAFF_CATEGORY,
    payload: {
      staffCategory
    }
  };
}

export function editStaffCategorySuccess(staffCategory) {
  return {
    type: EDIT_STAFF_CATEGORY,
    payload: {
      staffCategory
    }
  };
}

export function removeStaffCategorySuccess(staffCategory) {
  return {
    type: REMOVE_STAFF_CATEGORY,
    payload: {
      staffCategory
    }
  };
}

export function removeCompetenceFromStaffCategory(competence) {
  return {
    type: REMOVE_COMPETENCE_FROM_STAFF_CATEGORY,
    payload: {
      competence
    }
  };
}

export function fetchStaffCategories(queryParameters) {
  return (dispatch) => {
    dispatch(fetchStaffCategoriesRequest());

    const staffCategoriesQueryParameters = queryString.stringify(queryParameters);

    return axios.get(`${URLS.staffCategories}?${staffCategoriesQueryParameters}`)
      .then(({ data }) => dispatch(fetchStaffCategoriesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchStaffCategoriesFailure());
        dispatch(addError(`Error during staff categories fetching ${error.message}`, error));
      });
  };
}

export function addStaffCategory(staffCategory) {
  return (dispatch) => {
    return axios.post(URLS.staffCategories, staffCategory)
      .then(({ data }) => dispatch(addStaffCategorySuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during staff category adding ${error.message}`, error));
      });
  };
}

export function editStaffCategory(staffCategory) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.staffCategories, staffCategory.id), staffCategory)
      .then(({ data }) => dispatch(editStaffCategorySuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during staff category editing ${error.message}`, error));
      });
  };
}

export function removeStaffCategory(staffCategory) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.staffCategories, staffCategory.id))
      .then(() => dispatch(removeStaffCategorySuccess(staffCategory)))
      .catch((error) => {
        dispatch(addError(`Error during staff category removing ${error.message}`, error));
      });
  };
}
