import {
  PRACTICAL_LESSON_DATETIME_FIELDS,
  SIMULATOR_TYPE,
  ZERO_TIME,
} from '../../../../constants';
import React, { Component } from 'react';
import {
  calculateLessonTime,
  dateTimeDiffInTimeFormat,
  reformatPickerDateTimeFromDB,
  reformatPickerDateTimeToDB,
  substractTime,
  timeToMinutes,
} from '../../../utils/time';
import {
  hasPermission,
  renderPermission,
} from '../../../decorators/permissions';
import {
  isPracticalLesson,
  isProgressCheckLesson,
  isTheoreticalLesson,
} from '../../../../utils';

import BasicLessonInfo from './BasicLessonInfo';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FileInput from '../../../../common/components/upload/FileInput';
import Form from '../../../../common/components/forms/Form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import { LESSON_TYPES } from '../../../../constants/index';
import { Link } from 'react-router-dom';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import { PERMISSIONS } from '../../../../constants/permissions';
import PendingItemList from './PendingItemList';
import PracticalLesson from './PracticalLesson';
import PropTypes from 'prop-types';
import SelectGrading from './SelectGrading';
import TextField from '@material-ui/core/TextField';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _sortBy from 'lodash/sortBy';
import { addFlightLogFunction } from '../../Logbook/LogbookNew/addFlightLogHelper';
import classNames from 'classnames';
import { isDataToSync } from '../../../../utils/offline.js';
import moment from 'moment';
import userService from '../../../../utils/userService';
import uuidv4 from 'uuid/v4';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  checkbox: {
    marginLeft: 10,
    paddingRight: 6,
    width: 30,
    height: 30,
  },
  selectAttendeesIcon: {
    paddingTop: 6,
    marginRight: 10,
  },
});

@withNamespaces()
@withStyles(styles)
export default class ItemList extends Component {
  static propTypes = {
    lesson: PropTypes.object.isRequired,
    course: PropTypes.object.isRequired,
    lessons: PropTypes.array.isRequired,
    editAssignedCourse: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    assignedCourseId: PropTypes.string.isRequired,
    courseStartDate: PropTypes.string.isRequired,
    preview: PropTypes.bool,
    practicalFieldList: PropTypes.array.isRequired,
    locationList: PropTypes.array.isRequired,
    aircraftList: PropTypes.array.isRequired,
    pilotFunctionList: PropTypes.array.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    courseParticipants: PropTypes.array,
    updateTheoreticalLessons: PropTypes.func.isRequired,
    addFlightLog: PropTypes.func.isRequired,
    gradingList: PropTypes.array.isRequired,
    settings: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    handleUploadedFile: PropTypes.func.isRequired,
    documents: PropTypes.array.isRequired,
    handleRemoveUploadFile: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line
  getSelectedAircraftType = () => {
    const { lesson } = this.props;
    const aircraftId = _get(lesson, 'practicalData.aircraft.aircraft', '');

    return this.isSimulatorType(aircraftId);
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    const inheritedStartDate = reformatPickerDateTimeFromDB(
      _get(this.props.lesson, 'startDate', ''),
    );
    const startDate = inheritedStartDate !== '' ? inheritedStartDate : moment();

    const inheritedEndDate = reformatPickerDateTimeFromDB(
      _get(this.props.lesson, 'endDate', ''),
    );
    const endDate =
      inheritedEndDate !== ''
        ? inheritedEndDate
        : moment().add(
            timeToMinutes(calculateLessonTime(this.props.lesson)),
            'minutes',
          );
    const initialPracticalData = {
      aircraft: null,
      departure: null,
      arrival: null,
      blockoff: null,
      blockon: null,
      takeoff: null,
      landing: null,
      'total block': ZERO_TIME,
      'total flight': ZERO_TIME,
      'day landings': '0',
      'night landings': '0',
    };

    this.state = {
      triggers: {},
      formData: {},
      open: false,
      isFormValid: true,
      itemsState: this.props.lesson.items.reduce((result, item) => {
        result[item.id] = item.isCompleted;

        return result;
      }, {}),
      basicInfoDateHandlers: {
        startDate,
        endDate,
      },
      instructor: userService.getCurrentUser(),
      comments: this.props.lesson && this.props.lesson.comments,
      practicalLessonData: _get(
        this.props.lesson,
        'practicalData',
        initialPracticalData,
      ),
      lesson: this.props.lesson,
      studentCheckboxesController: { [this.props.assignedCourseId]: true },
      isAircraftSimulatorSelected: this.getSelectedAircraftType(),
    };
  }

  addFlightLog = () => {
    const { comments, practicalLessonData, parsedPracticalLessonData } =
      this.state;
    const { user, aircraftList, locationList, pilotFunctionList } = this.props;

    const data = {
      userId: _get(user, 'id', 0),
      remarks: comments,
      aircraft: _get(practicalLessonData, 'aircraft.aircraft', ''),
      dateOfFlight: reformatPickerDateTimeFromDB(
        _get(practicalLessonData, 'blockoff', moment()),
      ),
      vfr: _get(parsedPracticalLessonData, 'VFR', ZERO_TIME),
      ifr: _get(parsedPracticalLessonData, 'IFR', ZERO_TIME),
      day: _get(parsedPracticalLessonData, 'day', ZERO_TIME),
      night: _get(parsedPracticalLessonData, 'night', ZERO_TIME),
      totalTime: _get(practicalLessonData, 'total block', ZERO_TIME),
      dayLandings: _get(practicalLessonData, 'day landings', 0),
      nightLandings: _get(practicalLessonData, 'night landings', 0),
      departure: _get(practicalLessonData, 'departure', ''),
      departureTime: reformatPickerDateTimeFromDB(
        _get(practicalLessonData, 'blockoff', moment()),
      ),
      arrivalTime: reformatPickerDateTimeFromDB(
        _get(practicalLessonData, 'blockon', moment()),
      ),
      arrival: reformatPickerDateTimeFromDB(
        _get(practicalLessonData, 'arrival', ''),
      ),
      instructorCheck: false,
      coPilotName: '',
      aircraftList,
      pilotFunctionList,
      locationList,
      userSettings: {},
      pic: _get(parsedPracticalLessonData, 'pic', ZERO_TIME),
      sic: _get(parsedPracticalLessonData, 'sic', ZERO_TIME),
      dual: _get(parsedPracticalLessonData, 'dual', ZERO_TIME),
      xCountry: _get(parsedPracticalLessonData, 'xCountry', ZERO_TIME),
    };

    this.props.addFlightLog(addFlightLogFunction(data));
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid) {
      this.authorizeLesson();
      this.handleClose();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  openDialog = (e) => {
    const { addSimpleError } = this.props;
    const { practicalLessonData, lesson } = this.state;
    const formedFilledIn = Object.keys(practicalLessonData).every(
      (key) => !_isEmpty(practicalLessonData[key]),
    );
    const hasItems = !_isEmpty(lesson.items);
    const theoreticalLesson = isTheoreticalLesson(lesson.lessonType);

    if ((!theoreticalLesson && !formedFilledIn) || !hasItems) {
      addSimpleError('Check if all fields are correctly filled in');
    } else if (theoreticalLesson) {
      this.setState({
        open: true,
      });
    } else {
      this.sendHandler(e);
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  renderButtons = (preview, t) => {
    const { assignedCourseId } = this.props;

    if (preview) {
      return (
        <div className="row justify-content-center">
          <div className="col-auto">
            <Link to={`/course_list/details/${assignedCourseId}`}>
              <Button variant="raised">{t('buttonBack')}</Button>
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to={`/course_list/details/${assignedCourseId}`}>
            <Button variant="raised">{t('buttonBack')}</Button>
          </Link>
        </div>
        {this.renderAuthorizeCompletedButton(t)}
      </div>
    );
  };

  @renderPermission(PERMISSIONS.authorizeCompletedLesson)
  renderAuthorizeCompletedButton(t) {
    const { lesson } = this.state;

    const theoreticalLesson = isTheoreticalLesson(lesson.lessonType);

    if (!(theoreticalLesson && isDataToSync())) {
      return (
        <div className="col-auto">
          <Button onClick={this.openDialog} color="primary" variant="raised">
            {t('buttonSave')}
          </Button>
        </div>
      );
    }

    return <div />;
  }

  isLessonPassed(lessonsItems, itemsState) {
    return lessonsItems.every(({ item }) => itemsState[item.id]);
  }

  getDataFromForm = (name) => {
    return this.formData[`PracticalLesson-${name}Changes`];
  };

  parsePracticalLessonData = (practicalLessonData) => {
    const { aircraftList } = this.props;
    const aircraftData = practicalLessonData.aircraft || {};
    const departureData = practicalLessonData.departure || '';
    const arrivalData = practicalLessonData.arrival || '';

    let blockoff = practicalLessonData.blockoff || '';
    let takeoff = practicalLessonData.takeoff || '';
    let landing = practicalLessonData.landing || '';
    let blockon = practicalLessonData.blockon || '';

    const dayLanding = practicalLessonData['day landings'] || 0;
    const nightLanding = practicalLessonData['night landings'] || 0;

    blockon = reformatPickerDateTimeToDB(
      practicalLessonData.blockon || landing || takeoff || blockoff,
    );
    landing = reformatPickerDateTimeToDB(
      practicalLessonData.landing || takeoff || blockoff,
    );
    takeoff = reformatPickerDateTimeToDB(
      practicalLessonData.takeoff || blockoff,
    );
    blockoff = reformatPickerDateTimeToDB(blockoff);

    const dayTime = substractTime(
      practicalLessonData['total block'],
      this.getDataFromForm('night'),
    );

    let chosenAircraft = {};

    if (aircraftData.aircraft) {
      chosenAircraft = aircraftList.find(
        (aircraft) => aircraft.id === aircraftData.aircraft,
      );
      chosenAircraft.aircraftName = chosenAircraft.name;
    }

    const parsedPracticalLessonData = {
      ...practicalLessonData,
      pic: this.getDataFromForm('PIC'),
      sic: this.getDataFromForm('SIC'),
      dual: this.getDataFromForm('dual'),
      VFR: this.getDataFromForm('VFR'),
      IFR: this.getDataFromForm('IFR'),
      xCountry: this.getDataFromForm('X-Country'),
      dayLanding,
      nightLanding,
      aircraft: {
        ...aircraftData,
        ...chosenAircraft,
      },
      departure: departureData,
      arrival: arrivalData,
      day: dayTime,
      blockoff,
      takeoff,
      blockon,
      landing,
    };

    const newState = _cloneDeep(this.state);

    _set(newState, 'parsedPracticalLessonData', parsedPracticalLessonData);

    this.setState(newState);

    return parsedPracticalLessonData;
  };

  authorizeLesson = async () => {
    const {
      addSimpleError,
      course,
      editAssignedCourse,
      assignedCourseId,
      lessons,
    } = this.props;
    const {
      instructor,
      itemsState,
      practicalLessonData,
      comments,
      lesson,
      lesson: { id: lessonId, lessonType: editedLessonType },
      isAircraftSimulatorSelected,
      itemsGrade,
    } = this.state;
    const triggerKeys = Object.keys(this.triggers);
    let copiedLesson;
    let { startDate } = course;
    let { endDate } = course;

    if (isAircraftSimulatorSelected) {
      await this.triggers['PracticalLesson-PIC']();
    } else {
      for (let i = 0; i < triggerKeys.length; i++) {
        await this.triggers[triggerKeys[i]]();
      }
    }

    const formDataIsValidObjects = Object.keys(this.formData).filter((key) => {
      return key.indexOf('isValid') > -1;
    });

    const isFormValid = formDataIsValidObjects.every(
      (key) => this.formData[key],
    );

    if (isFormValid) {
      const newCourse = {
        ...course,
        lessons: lessons.map((courseLesson) => {
          const isEditedLesson = courseLesson.id === lessonId;
          const { lessonType } = courseLesson;

          if (!isEditedLesson) {
            if (
              isProgressCheckLesson(editedLessonType) ||
              isProgressCheckLesson(lessonType)
            ) {
              return courseLesson;
            }

            const otherLessonsItems = courseLesson.lessonsItems.map(
              (lessonItem) => {
                return {
                  ...lessonItem,
                  item: {
                    ...lessonItem.item,
                    isCompleted:
                      lessonItem.item.isCompleted ||
                      _get(itemsState, `[${lessonItem.item.id}]`),
                  },
                };
              },
            );

            return {
              ...courseLesson,
              lessonsItems: otherLessonsItems,
            };
          }

          const lessonsItems = lesson.items.map((item) => {
            return {
              id: item.id,
              order: item.order,
              item,
            };
          });
          const isPassed = this.isLessonPassed(lessonsItems, itemsState);
          const parsedPracticalLessonData =
            this.parsePracticalLessonData(practicalLessonData);

          startDate = parsedPracticalLessonData.blockoff;
          endDate = parsedPracticalLessonData.blockon;

          const startDateMoment = reformatPickerDateTimeFromDB(
            parsedPracticalLessonData.blockoff,
          );
          const endDateMoment = reformatPickerDateTimeFromDB(
            parsedPracticalLessonData.blockon,
          );

          const newLesson = {
            ...courseLesson,
            instructor,
            practicalData: parsedPracticalLessonData,
            startDate,
            endDate,
            actualTime: dateTimeDiffInTimeFormat(
              endDateMoment,
              startDateMoment,
            ),
            isCompleted: isEditedLesson,
            isPassed,
            comments,
            grades: lesson.grades,
            lessonsItems: lessonsItems.map((lessonItem) => {
              return {
                ...lessonItem,
                item: {
                  ...lessonItem.item,
                  isCompleted: _get(itemsState, `[${lessonItem.item.id}]`),
                },
              };
            }),
          };

          if (isProgressCheckLesson(lessonType) && !isPassed) {
            copiedLesson = { ...courseLesson, lessonsItems };
          }

          return newLesson;
        }),
      };

      if (copiedLesson) {
        newCourse.lessons.push({
          ...copiedLesson,
          id: uuidv4(),
          order: newCourse.lessons.length + 1,
        });
      }

      await this.addFlightLog();

      editAssignedCourse(
        assignedCourseId,
        { courseData: newCourse, startDate },
        `/course_list/details/${assignedCourseId}`,
      );
    } else {
      addSimpleError('Check if all fields are correctly filled in');
    }
  };

  countTotalTimes = (state) => {
    if (!state || !state.practicalLessonData) {
      return;
    }

    if (
      state.practicalLessonData.blockoff &&
      state.practicalLessonData.blockon
    ) {
      const blockOffTime = state.practicalLessonData.blockoff;
      const blockOnTime = state.practicalLessonData.blockon;
      const totalBlockTime = dateTimeDiffInTimeFormat(
        moment(blockOnTime),
        moment(blockOffTime),
      );

      _set(state.practicalLessonData, 'total block', totalBlockTime);
    } else {
      _set(state.practicalLessonData, 'total block', ZERO_TIME);
    }

    if (
      state.practicalLessonData.takeoff &&
      state.practicalLessonData.landing
    ) {
      const takeOffTime = state.practicalLessonData.takeoff;
      const landingTime = state.practicalLessonData.landing;
      const totalFlightTime = dateTimeDiffInTimeFormat(
        moment(landingTime),
        moment(takeOffTime),
      );

      _set(state.practicalLessonData, 'total flight', totalFlightTime);
    } else {
      _set(state.practicalLessonData, 'total flight', ZERO_TIME);
    }

    this.setState(state);
  };

  getDataFromList(id, list) {
    return list.find((data) => data.id === id);
  }

  getAircraftData = (aircraftId) => {
    const { aircraftList } = this.props;

    return this.getDataFromList(aircraftId, aircraftList);
  };

  isSimulatorType = (aircraftId) => {
    const aircraftData = this.getAircraftData(aircraftId);
    const aircraftType = _get(aircraftData, 'type', '');

    return aircraftType === SIMULATOR_TYPE;
  };

  handleItemCheckedChange = (name) => (event) => {
    this.setState({
      itemsState: {
        ...this.state.itemsState,
        [name]: event.target.checked,
      },
    });
  };

  handleGradeChange = (gradeId) => (event) => {
    const grades = [...this.state.lesson.grades].map((grade) => {
      return grade.gradeId === gradeId
        ? { ...grade, gradeValue: event.target.value }
        : grade;
    });

    this.setState({
      lesson: {
        ...this.state.lesson,
        grades,
      },
    });
  };

  handleStudentCheckedChange = (name) => (event) => {
    const { studentCheckboxesController } = this.state;

    this.setState({
      studentCheckboxesController: {
        ...studentCheckboxesController,
        [name]: event.target.checked,
      },
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      basicInfoDateHandlers: {
        ...this.state.basicInfoDateHandlers,
        [name]: dateFromPicker,
      },
    });
  };

  handleInstructor = (data) => {
    const newState = _cloneDeep(this.state);

    _set(newState, 'instructor', data.instructor);

    this.setState(newState);
  };

  handlePracticalDatePickerChange = (name) => (date) => {
    let newState = _cloneDeep(this.state);

    _set(newState, `practicalLessonData.${name}`, date);

    newState = this.validateFlightDateTime(newState);

    this.countTotalTimes(newState);
    this.setState(newState);
  };

  handlePracticalSelectChange = (event) => {
    const newState = _cloneDeep(this.state);

    _set(
      newState,
      `practicalLessonData.${event.target.name}`,
      event.target.value,
    );
    _set(
      newState,
      'isAircraftSimulatorSelected',
      this.isSimulatorType(event.target.value),
    );

    this.setState(newState);
  };

  handlePracticalChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, `practicalLessonData.${name}`, event.target.value);

    this.countTotalTimes(newState);
    this.setState(newState, callback);
  };

  handlePracticalCheckboxChange = (name) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, `practicalLessonData.${name}`, event.target.checked);

    this.setState(newState);
  };

  registerComponentForm = (componentName) => {
    return (triggerFn) => {
      this.triggers[componentName] = triggerFn;
    };
  };

  formValidated = (formName, isFormValid, formChanges) => {
    this.formData = {
      ...this.formData,
      [`${formName}Changes`]: formChanges,
      [`${formName}isValid`]: isFormValid,
    };
  };

  validateFlightDateTime = (state) => {
    const blockOff = _get(
      state.practicalLessonData,
      PRACTICAL_LESSON_DATETIME_FIELDS.blockOff,
    );
    const takeOff = _get(
      state.practicalLessonData,
      PRACTICAL_LESSON_DATETIME_FIELDS.takeOff,
    );
    const landing = _get(
      state.practicalLessonData,
      PRACTICAL_LESSON_DATETIME_FIELDS.landing,
    );
    const blockOn = _get(
      state.practicalLessonData,
      PRACTICAL_LESSON_DATETIME_FIELDS.blockOn,
    );

    if (blockOff && moment(blockOff).isAfter(moment(takeOff))) {
      _set(
        state.practicalLessonData,
        PRACTICAL_LESSON_DATETIME_FIELDS.takeOff,
        null,
      );
      _set(
        state.practicalLessonData,
        PRACTICAL_LESSON_DATETIME_FIELDS.landing,
        null,
      );
      _set(
        state.practicalLessonData,
        PRACTICAL_LESSON_DATETIME_FIELDS.blockOn,
        null,
      );
    }

    if (takeOff && moment(takeOff).isAfter(moment(landing))) {
      _set(
        state.practicalLessonData,
        PRACTICAL_LESSON_DATETIME_FIELDS.landing,
        null,
      );
      _set(
        state.practicalLessonData,
        PRACTICAL_LESSON_DATETIME_FIELDS.blockOn,
        null,
      );
    }

    if (landing && moment(landing).isAfter(moment(blockOn))) {
      _set(
        state.practicalLessonData,
        PRACTICAL_LESSON_DATETIME_FIELDS.blockOn,
        null,
      );
    }

    return state;
  };

  renderPracticalLesson = (lesson, preview) => {
    const { practicalFieldList, locationList, aircraftList } = this.props;
    const { practicalLessonData, isAircraftSimulatorSelected } = this.state;

    if (
      (!isPracticalLesson(lesson.lessonType) &&
        !isProgressCheckLesson(lesson.lessonType)) ||
      !practicalFieldList
    ) {
      return null;
    }

    return [
      <div key="practical-lesson-1" className="col-12">
        <PracticalLesson
          practicalLessonData={practicalLessonData}
          handleChange={this.handlePracticalChange}
          handleSelectChange={this.handlePracticalSelectChange}
          handleCheckboxChange={this.handlePracticalCheckboxChange}
          handleDatePickerChange={this.handlePracticalDatePickerChange}
          preview={preview}
          practicalFieldList={practicalFieldList}
          locationList={locationList}
          aircraftList={aircraftList}
          handleRegisterComponentForm={this.registerComponentForm}
          handleFormValidated={this.formValidated}
          isAircraftSimulatorSelected={isAircraftSimulatorSelected}
        />
      </div>,
      <div key="practical-lesson-2" className="col-12">
        <Divider className="my-3" />
      </div>,
    ];
  };

  renderBasicInfo = (lesson, preview) => {
    const { basicInfoDateHandlers, practicalLessonData } = this.state;
    const { firstName, lastName } = this.props.user;

    const studentFullName = `${firstName} ${lastName}`;

    return (
      <div className="col-12">
        <BasicLessonInfo
          lesson={lesson}
          practicalLessonData={practicalLessonData}
          handleDatePickerChange={this.handleDatePickerChange}
          handleInstructor={this.handleInstructor}
          preview={preview}
          basicInfoDateHandlers={basicInfoDateHandlers}
          studentFullName={studentFullName}
        />
      </div>
    );
  };

  renderCommentsTextfield(lesson, preview, t) {
    const { comments } = this.state;

    const hasEditPermission = hasPermission(
      PERMISSIONS.authorizeCompletedLesson,
    );

    return (
      <>
        <h4>{t('course:Comments')}</h4>
        <TextField
          label={t('input:comments')}
          value={comments}
          margin="dense"
          fullWidth
          multiline
          disabled={preview || !hasEditPermission}
          onChange={this.handleChange('comments')}
        />
      </>
    );
  }

  @renderPermission(PERMISSIONS.authorizeCompletedLesson)
  renderCompleteCheckbox(preview, isItemCompleted, item, t) {
    return (
      <div className="col-auto">
        <FormControlLabel
          color="primary"
          control={
            <Checkbox
              color="primary"
              disabled={preview}
              checked={isItemCompleted}
              onChange={this.handleItemCheckedChange(item.id)}
            />
          }
          label={t('course:completeItem')}
        />
      </div>
    );
  }

  renderCompletedLabel(isItemCompleted, t) {
    return (
      <div className="col-auto mx-3">
        <h6 className={isItemCompleted ? 'item-completed' : 'item-pending'}>
          {isItemCompleted
            ? t('course:completedItem')
            : t('course:inProgressItem')}
        </h6>
      </div>
    );
  }

  renderOldGrading(item, t) {
    const gradingType = `settings:${item.grade}`;

    return (
      <div className="col-auto mx-3">
        <h6>
          {t('input:grade')}: {t(gradingType)}
        </h6>
      </div>
    );
  }

  renderGrading(preview, t) {
    const { gradingList, settings } = this.props;
    const { lesson } = this.state;

    return (
      lesson.grades && (
        <>
          <h4>{t('course:Gradings')}</h4>
          <div class="row justify-content-end">
            <div class="col-auto">
              <SelectGrading
                gradingList={gradingList}
                handleGradeChange={this.handleGradeChange}
                lesson={lesson}
                preview={preview}
                settings={settings}
              />
            </div>
          </div>
        </>
      )
    );
  }

  renderItems = (items, preview, t) => {
    const { itemsState } = this.state;

    return _sortBy(items, ['order']).map((item, index, arr) => {
      const isItemCompleted = _get(itemsState, `[${item.id}]`, false);
      const isItemFinished = !!item.isCompleted;
      const newItem = [
        <div
          className="row justify-content-between align-items-center"
          key={`lesson-item-${item.id}`}
        >
          <div className="col">
            <h4>
              {index + 1}. {item.name}
            </h4>
          </div>
          <div className="col-auto mx-3">
            <div className="row align-items-center">
              <div className="col-auto">
                <Icon color="primary">alarm</Icon>
              </div>
              <div className="col-auto">
                <h4>{item.estimatedTime}</h4>
              </div>
            </div>
          </div>
        </div>,
        <div
          key={`lesson-item-description-${item.id}`}
          className="lesson-item-details"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />,
        <div
          className="row justify-content-end align-items-center"
          key={`lesson-item-completed-${item.id}`}
        >
          {preview || isItemFinished
            ? this.renderCompletedLabel(isItemCompleted, t)
            : this.renderCompleteCheckbox(preview, isItemCompleted, item, t)}
        </div>,
        <div className="row justify-content-end align-items-center">
          {item.grade && item.isCompleted && this.renderOldGrading(item, t)}
        </div>,
        <div key={`lesson-item-files-${item.id}`}>
          {this.renderFiles(item)}
        </div>,
      ];

      if (arr.length - 1 !== index) {
        newItem.push(
          <div key={`lesson-item-divider-${item.id}`}>
            <Divider className="my-3" />
          </div>,
        );
      }

      return newItem;
    });
  };

  renderFiles(item) {
    const { files } = item;

    if (files) {
      return files.map((file) => {
        return (
          <Chip
            key={`item-file-${file.id}`}
            label={file.name}
            onClick={() => window.open(file.path, '_blank')}
            className="my-1 mx-2"
          />
        );
      });
    }

    return <div />;
  }

  addPendingItem = (item) => {
    const { lesson, itemsState } = this.state;
    const newLessonId = lesson.items.length;

    this.setState({
      lesson: {
        ...lesson,
        items: [
          ...lesson.items,
          {
            ...item,
            lessonItemId: newLessonId,
            order: newLessonId,
          },
        ],
      },
      itemsState: {
        ...itemsState,
        [item.id]: item.isCompleted || itemsState[item.id] || false,
      },
    });
  };

  @renderPermission(PERMISSIONS.renderPendingItems)
  renderPendingItems(lessons, preview) {
    return (
      <div className="col-lg-5 col-xs-12">
        <PendingItemList
          lessons={lessons}
          preview={preview}
          addPendingItem={this.addPendingItem}
        />
      </div>
    );
  }

  renderUploadFile() {
    const { addSimpleError, handleUploadedFile, lesson, t } = this.props;

    return (
      <>
        <h4>{t('course:Upload file')}</h4>
        <Button>
          <FileInput
            attachFile={(file) => handleUploadedFile(file, lesson.id)}
            addSimpleError={addSimpleError}
            stopPropagation
            required
          />
          <Icon color="primary">attach_file</Icon>
          {t('folder:Add document')}
        </Button>
      </>
    );
  }

  renderDialog() {
    const { user, t } = this.props;
    const { lesson } = this.state;

    if (lesson.lessonType.shortname === LESSON_TYPES.theoretical) {
      return this.renderTheoreticalLessonDialog(t);
    }

    return (
      <Form
        validateForm={this.validateForm}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="authorize-user"
          fullWidth
        >
          <DialogTitle id="authorize-user">
            {t('course:authorizeStudentDialogTitle')}
          </DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <MaterialInput
                  autoFocus
                  name="pin"
                  label={t('input:studentsPin')}
                  margin="dense"
                  type="password"
                  validators={[
                    new validators.IsRequired(t),
                    new validators.Pin(t),
                    new validators.IsUsersPin(
                      t,
                      _get(user, 'userprofile.pin', ''),
                    ),
                  ]}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleClose}>
              {t('buttonBack')}
            </Button>
            <Button color="primary" onClick={this.sendHandler}>
              {t('buttonSave')}
            </Button>
          </DialogActions>
        </Dialog>
        <span />
      </Form>
    );
  }

  renderDocuments() {
    const {
      handleUploadedFile,
      addSimpleError,
      documents,
      handleRemoveUploadFile,
      lesson,
      t,
    } = this.props;

    const hasPermissionToDelete = hasPermission(PERMISSIONS.renderPendingItems);

    return documents.length ? (
      <div className="col-12">
        <h5>{t('course:Documents')}</h5>
        {documents.map((document) => {
          return hasPermissionToDelete ? (
            <Chip
              key={`document-${document.id}`}
              label={document.name}
              value={document.id}
              onClick={() => window.open(document.path, '_blank')}
              onDelete={() => handleRemoveUploadFile(document.id, lesson.id)}
            />
          ) : (
            <Chip
              key={`document-${document.id}`}
              label={document.name}
              onClick={() => window.open(document.path, '_blank')}
            />
          );
        })}
        <Button>
          <FileInput
            attachFile={(file) => handleUploadedFile(file, lesson.id)}
            addSimpleError={addSimpleError}
            stopPropagation
            required
          />
          <Icon color="primary">attach_file</Icon>
          {t('folder:Add document')}
        </Button>
      </div>
    ) : (
      ''
    );
  }

  renderTheoreticalLessonDialog(t) {
    const { classes, courseParticipants } = this.props;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="select-attendees"
        fullWidth
      >
        <DialogTitle id="select-attendees">
          {t('course:selectAttendeesDialogTitle')}
        </DialogTitle>
        <DialogContent>
          <div className="mb-2">
            <Icon color="primary" className={classes.selectAttendeesIcon}>
              playlist_add
            </Icon>
            <p className="d-inline">{t('course:lessonParticipants')}</p>
          </div>
          {courseParticipants &&
            courseParticipants.map((assignedCourse) => {
              return this.renderFormControlLabel(assignedCourse);
            })}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleClose}>
            {t('buttonCancel')}
          </Button>
          <Button color="primary" onClick={this.authorizeTheoreticalLesson}>
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  authorizeTheoreticalLesson = () => {
    const { updateTheoreticalLessons, assignedCourseId } = this.props;
    const {
      instructor,
      itemsState,
      comments,
      lesson,
      lesson: { id: lessonId },
      studentCheckboxesController,
      basicInfoDateHandlers: { startDate: startDate, endDate: endDate },
    } = this.state;
    const lessonsItems = lesson.items.map((item) => {
      return {
        id: item.id,
        order: item.order,
        item,
      };
    });
    const isPassed = this.isLessonPassed(lessonsItems, itemsState);
    const reformattedStartDate = reformatPickerDateTimeToDB(startDate);
    const reformattedEndDate = reformatPickerDateTimeToDB(endDate);

    const lessonData = {
      id: lessonId,
      instructor,
      startDate: reformattedStartDate,
      endDate: reformattedEndDate,
      actualTime: dateTimeDiffInTimeFormat(endDate, startDate),
      isCompleted: true,
      isPassed,
      comments,
      lessonsItems: lessonsItems.map((lessonItem) => {
        return {
          ...lessonItem,
          item: {
            ...lessonItem.item,
            isCompleted: _get(itemsState, `[${lessonItem.item.id}]`),
          },
        };
      }),
    };

    const msg = {
      startDate: reformatPickerDateTimeToDB(),
      lessonData,
      ids: Object.keys(studentCheckboxesController).filter((key) => {
        return studentCheckboxesController[key];
      }),
    };

    updateTheoreticalLessons(
      msg,
      assignedCourseId,
      `/course_list/details/${assignedCourseId}`,
    );
    this.handleClose();
  };

  renderFormControlLabel(assignedCourse) {
    const { classes } = this.props;
    const { studentCheckboxesController } = this.state;

    return (
      <FormControlLabel
        className={classNames('ml-1', 'd-flex')}
        control={
          <Checkbox
            checked={_get(
              studentCheckboxesController,
              `${assignedCourse.id}`,
              false,
            )}
            onChange={this.handleStudentCheckedChange(assignedCourse.id)}
            color="primary"
            className={classes.checkbox}
            value={assignedCourse.id}
          />
        }
        label={`${assignedCourse.user.firstName} ${assignedCourse.user.lastName}`}
      />
    );
  }

  render() {
    const { addSimpleError, handleUploadedFile, preview, lessons, t } =
      this.props;
    const { lesson } = this.state;

    return (
      <div className="row">
        <div className="col">
          <div className="row">
            {this.renderBasicInfo(lesson, preview)}
            {this.renderDocuments()}
            <div className="col-12">
              <Divider className="my-3" />
            </div>
            {this.renderPracticalLesson(lesson, preview)}
            <div className="col-12">
              {this.renderItems(lesson.items, preview, t)}
            </div>
            <div className="col-12">
              <Divider className="my-3" />
            </div>
            <div className="col-12">
              {isPracticalLesson(lesson.lessonType) &&
                this.renderGrading(preview, t)}
            </div>
            <div className="col-12">
              {this.renderCommentsTextfield(lesson, preview, t)}
            </div>
            {this.renderDialog()}
          </div>
        </div>

        {this.renderPendingItems(lessons, preview)}
        <div className="col-12 my-3">{this.renderButtons(preview, t)}</div>
      </div>
    );
  }
}
