import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import ExamOrCourseCompetencies from '../../../../common/containers/ManageExamOrCourseCompetencies/ExamOrCourseCompetencies';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import CategoriesSummary from './CategoriesSummary';
import CategoryDialog from './CategoryDialog';
import { addTest, editTest } from '../../../actions/tests';
import { fetchQuestionCategories } from '../../../actions/questionCategories';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { hasPermission } from '../../../decorators/permissions';
import { addSimpleError } from "../../../actions/errors";
import { fetchCompetencies } from "../../../actions/competencies";

const styles = () => ({
  input: {
    fontSize: '2rem'
  },
  formControl: {
    width: '100%',
  },
  root: {
    width: '170px'
  }
});

const mapStateToProps = (state) => {
  return {
    questionCategoryList: state.questionCategories.questionCategoryList,
    competenciesList: state.competencies.competenciesList.filter((competency) => competency.isActive),
    competenciesRequest: state.competencies.competenciesRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addTest: (test) => dispatch(addTest(test)),
    editTest: (test) => dispatch(editTest(test)),
    fetchQuestionCategories: () => dispatch(fetchQuestionCategories()),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    fetchCompetencies: (searchData) => dispatch(fetchCompetencies(searchData)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class Test extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    addTest: PropTypes.func.isRequired,
    editTest: PropTypes.func.isRequired,
    fetchQuestionCategories: PropTypes.func.isRequired,
    questionCategoryList: PropTypes.array,
    preview: PropTypes.bool,
    test: PropTypes.object,
    editMode: PropTypes.bool,
    competenciesList: PropTypes.array,
    competenciesRequest: PropTypes.bool.isRequired,
    fetchCompetencies: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    testName: _get(this.props.test, 'testName', ''),
    addCategoryDialogOpen: false,
    selectedQuestionCategoriesAndQuestionNumbers: _get(this.props.test, 'questionCategory', []),
    summaryNumberOfQuestion: _get(this.props.test, 'questionsNumber', 0),
    passCriteria: _get(this.props.test, 'passCriteria', ''),
    testId: _get(this.props.test, 'id', ''),
    editedCategory: {},
    selectedCompetencies: [],
    editedExamCategory: false
  };

  componentWillMount() {
    this.formData = {};
  }

  componentDidMount() {
    this.props.fetchQuestionCategories();
    this.props.fetchCompetencies();
  }

  renderButtons = (t) => {
    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to="/exam_admin">
            <Button variant="raised">{t('buttonCancel')}</Button>
          </Link>
        </div>
        <div className="col-auto">
          <Button color="primary" variant="raised" onClick={this.sendHandler}>{t('buttonSave')}</Button>
        </div>
      </div>
    );
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = (isFormValid) => {
    const { editMode } = this.props;

    if (isFormValid && this.formData) {
      const test = this.prepareTest();

      this.manageTest(test, editMode)
    }
  };

  manageTest = (test, editMode) => {
    const { selectedCompetencies } = this.state;

    const testCompetency = selectedCompetencies.map((competency) => {
      return {
        competency: competency.competency,
        number: competency.number,
        period: competency.period,
      }
    });

    if (editMode) {
      this.props.editTest({
        ...test,
        testCompetency
      });
    }
    else {
      this.props.addTest({
        ...test,
        testCompetency
      });
    }
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation; // this function can be used for full form validation
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  closeCategoryDialog = () => {
    this.setState({
      addCategoryDialogOpen: false,
      editedCategory: {},
    });
  };

   openCategoryDialog = () => {
     this.setState({
       addCategoryDialogOpen: true,
     });
   };

   editCategory = (category) => {
     this.setState({
       editedCategory: category
     });
     this.openCategoryDialog();
   };

   removeCategory = (categoryId) => {
     const { selectedQuestionCategoriesAndQuestionNumbers } = this.state;
     const temporaryArray = selectedQuestionCategoriesAndQuestionNumbers.filter((category) => category.categoryId !== categoryId);

     this.setState({
       summaryNumberOfQuestion: this.calculateSummaryNumberOfQuesion(temporaryArray),
       selectedQuestionCategoriesAndQuestionNumbers: temporaryArray
     });
   };

   saveCategoryAndQuestionNumber = (categoryName, categoryId, numberOfQuestions) => {
     if (!_isEmpty(categoryName) && categoryId) {
       const { selectedQuestionCategoriesAndQuestionNumbers } = this.state;
       const temporaryArray = selectedQuestionCategoriesAndQuestionNumbers.filter((selected) => selected.categoryId !== categoryId);

       temporaryArray.push({
         categoryId,
         categoryName,
         numberOfQuestions
       });

       this.setState({
         summaryNumberOfQuestion: this.calculateSummaryNumberOfQuesion(temporaryArray),
         addCategoryDialogOpen: false,
         selectedQuestionCategoriesAndQuestionNumbers: temporaryArray,
         editedCategory: {},
       });
     }
   };

   calculateSummaryNumberOfQuesion = (temporaryArray) => {
     return _reduce(temporaryArray, (sum, category) => {
       return sum = Number(sum) + Number(category.numberOfQuestions);
     }, 0);
   };

   prepareTestsQuestionCategories = () => {
     const { selectedQuestionCategoriesAndQuestionNumbers } = this.state;

     return selectedQuestionCategoriesAndQuestionNumbers.map((category) => {
       return {
         questionCategory: {
           id: category.categoryId,
           name: category.categoryName
         },
         questionsNumber: category.numberOfQuestions
       };
     }, '');
   };

   saveCompetenciesList = (competencies) => {
     this.setState({
       selectedCompetencies: competencies,
       editedExamCategory: true
      });
    };

   prepareTest = () => {
     const { testName, passCriteria, summaryNumberOfQuestion, testId } = this.state;
     const { editMode } = this.props;

     const preparedTest = {
       name: testName,
       testsQuestionCategories: this.prepareTestsQuestionCategories(),
       questionsNumber: summaryNumberOfQuestion,
       passCriteria
     };

     if (editMode) {
       return {
         ...preparedTest,
         id: testId
       };
     }

     return preparedTest;
   };

  getAvailableQuestionCategory = (questionCategoryList) => {
    const { editedCategory, selectedQuestionCategoriesAndQuestionNumbers } = this.state;

    if (!_isEmpty(editedCategory)) {
      return questionCategoryList.filter((category) => category.id === editedCategory.categoryId);
    }

    if (_isEmpty(questionCategoryList) || _isEmpty(selectedQuestionCategoriesAndQuestionNumbers)) {
      return questionCategoryList;
    }

    const selectedCategoryNames = selectedQuestionCategoriesAndQuestionNumbers.map((category) => {
      return category.categoryName;
    }, '');

    return questionCategoryList.filter((category) => {
      return selectedCategoryNames.indexOf(category.name) === -1;
    });
  };

  getExamCompetenciesList = () => {
    const { test } = this.props;
    const { selectedCompetencies, editedExamCategory } = this.state;

    return _isEmpty(selectedCompetencies) && !editedExamCategory ? _get(test, 'competencies', []) : selectedCompetencies;
  };

  render() {
    let { preview, editMode, addSimpleError } = this.props;
    const { classes, questionCategoryList, onElementDelete, t } = this.props;
    const { testName,
      addCategoryDialogOpen,
      selectedQuestionCategoriesAndQuestionNumbers,
      summaryNumberOfQuestion,
      editedCategory,
      passCriteria,
    } = this.state;

    if (!hasPermission(PERMISSIONS.editExam)) {
      preview = true;
    }

    return (
      <div>
        <Form
          onChange={this.onChange}
          validateForm={this.validateForm}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <div className="ml-2">
            <FormControl
              className={classes.formControl}
              margin="dense"
              disabled={false}
            >
              <MaterialInput
                disabled={preview}
                label={t('input:name')}
                name="testName"
                defaultValue={testName}
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
                className={classes.input}
              />
            </FormControl>
          </div>
          <div className="mt-4 pl-0 pr-0 col-12">
             <ExamOrCourseCompetencies
              editMode={editMode}
              preview={preview}
              saveCompetenciesList={this.saveCompetenciesList}
              addSimpleError={addSimpleError}
              examOrCourseCompetenciesList={this.getExamCompetenciesList()}
              onElementDelete={this.openRemoveDialog}
             />
          </div>
          <Divider className="my-4" />
          <div className="col-12 mt-3">
            <CategoriesSummary
              preview={preview}
              categories={selectedQuestionCategoriesAndQuestionNumbers}
              removeCategory={(category) => onElementDelete(() => this.removeCategory(category))}
              editCategory={this.editCategory}
              openCategoryDialog={this.openCategoryDialog}
            />
          </div>
          <Divider className="my-4" />
          <div className="row ml-2">
            <div className="col-xl-4 col-sm-6 col-xs-12 py-2">
              <MaterialInput
                className={classes.root}
                disabled={preview}
                name="passCriteria"
                label={t('input:passCriteria')}
                defaultValue={passCriteria}
                validators={[
                  new validators.IsRequired(t),
                  new validators.IsNumber(t),
                  new validators.Percent(t),
                ]}
              />
            </div>
            <div className="col-xl-4 col-sm-6 col-xs-12 py-2">
              <TextField
                name="numberOfQuestions"
                label={t('input:numberOfQuestions')}
                disabled
                value={summaryNumberOfQuestion}
                margin="dense"
              />
            </div>
          </div>
          <div className="col-12 pt-3">
            {!preview && this.renderButtons(t)}
          </div>
        </Form>
        <Dialog
          open={addCategoryDialogOpen}
          onClose={this.closeCategoryDialog}
          aria-labelledby="confirmation-assign"
          fullWidth
        >
          <CategoryDialog
            closeCategoryDialog={this.closeCategoryDialog}
            questionCategoryList={this.getAvailableQuestionCategory(questionCategoryList)}
            saveCategoryAndQuestionNumber={this.saveCategoryAndQuestionNumber}
            editedCategory={editedCategory}
          />
        </Dialog>
      </div>
    );
  }
}
