import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import TestNew from '../TestNew';
import Loader from '../../../../common/components/Loader';

import { fetchTest } from '../../../actions/tests';
import { PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

const mapStateToProps = (state) => {
  return {
    test: state.tests.test,
    testDetailsRequest: state.tests.testDetailsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchTest: (id) => dispatch(fetchTest(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.examEdit)
@componentPermission(PERMISSIONS.editExam)
export default class TestDetails extends Component {
  static propTypes = {
    test: PropTypes.object.isRequired,
    fetchTest: PropTypes.func.isRequired,
    testDetailsRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.fetchTest(this.props.match.params.id);
  }

  prepareQuestionCategory = (questionCategories) => {
    return questionCategories && questionCategories.map((category) => {
      return {
        categoryId: category.questionCategory.id,
        categoryName: category.questionCategory.name,
        numberOfQuestions: category.questionsNumber
      };
    });
  };

  prepareQuestion = (test) => {
    return {
      passCriteria: _get(test, 'passCriteria', '0'),
      testName: _get(test, 'name', ''),
      questionCategory: this.prepareQuestionCategory(_get(test, 'testsQuestionCategories', '')),
      id: _get(test, 'id', ''),
      questionsNumber: _get(test, 'questionsNumber', '0'),
    };
  };

  render() {
    const { test, testDetailsRequest } = this.props;

    if (testDetailsRequest) {
      return <Loader />;
    }

    return (
      <TestNew
        test={this.prepareQuestion(test)}
        editMode
      />
    );
  }
}
