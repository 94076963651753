function getCookie(name) {
  let cookieValue = null;

  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');

    for (const cookieWithSpaces of cookies) {
      const cookie = cookieWithSpaces.trim();

      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
  }

  return cookieValue;
}

export { getCookie };
