import '../styles.scss';

import React, { Component } from 'react';
import {
  addRating,
  addRatingPlannedAction,
  deactivateRating,
  editRating,
  removeRating,
} from '../../../actions/ratings';
import {
  attachRatingFile,
  removeRatingFile,
} from '../../../actions/fileUpload';
import {
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import RatingsList from './RatingsList';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { getDateFormat } from '../../../utils/time';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  id: null,
  type: '',
  expiryDate: null,
};

const mapStateToProps = (state) => {
  return {
    ratingList: state.ratings.ratingList,
    ratingRequest: state.ratings.ratingsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addRating: (rating) => dispatch(addRating(rating)),
    addRatingPlannedAction: (rating) =>
      dispatch(addRatingPlannedAction(rating)),
    removeRating: (rating) => dispatch(removeRating(rating)),
    editRating: (rating) => dispatch(editRating(rating)),
    deactivateRating: (rating) => dispatch(deactivateRating(rating)),
    attachRatingFile: (file, id, userId) =>
      dispatch(attachRatingFile(file, id, userId)),
    removeRatingFile: (fileId, id) => dispatch(removeRatingFile(fileId, id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UserRatings extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    ratingList: PropTypes.array,
    editMode: PropTypes.bool,
    ratingsRequest: PropTypes.bool,
    addRating: PropTypes.func.isRequired,
    addRatingPlannedAction: PropTypes.func.isRequired,
    removeRating: PropTypes.func.isRequired,
    editRating: PropTypes.func.isRequired,
    deactivateRating: PropTypes.func.isRequired,
    attachRatingFile: PropTypes.func.isRequired,
    removeRatingFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    documentTypeList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (rating) => {
    this.setState({
      editOpen: true,
      id: rating.id,
      expiryDate: rating.expiryDate,
      type: rating.type,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addRating = () => {
    const { type, expiryDate } = this.state;
    const { userId } = this.props;

    if (!_isEmpty(type)) {
      const newRating = {
        type,
        userId,
        expiryDate: reformatPickerDateToDB(expiryDate),
      };

      this.props.addRating(newRating);
      this.handleDialogClose();
    }
  };

  removeRating = (rating) => {
    this.props.removeRating(rating);
  };

  editRating = () => {
    const { type, id, expiryDate } = this.state;
    const { userId } = this.props;

    if (!_isEmpty(type)) {
      const newRating = {
        id,
        type,
        userId,
        expiryDate: reformatPickerDateToDB(expiryDate),
      };

      this.props.editRating(newRating);
      this.handleDialogClose();
    }
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value,
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid && this.formData) {
        this.state.addOpen ? this.addRating() : this.editRating();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  attachRatingFile = (file) => (id) => {
    const { attachRatingFile, userId } = this.props;

    attachRatingFile(file, id, userId);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDatePickerChange = (date) => {
    this.setState({
      expiryDate: date,
    });
  };

  dialogContent = (classes, title, t) => {
    const { type, expiryDate } = this.state;

    return (
      <div>
        <DialogTitle id="add-rating-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              {this.renderSelect('type', 'type', type, this.handleSelectChange)}
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                acceptEmptyInput
                value={reformatPickerDateFromDB(expiryDate)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange}
                dateFormat={getDateFormat()}
                name="expiryDate"
                label={t('input:expiryDate')}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
      </div>
    );
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  renderSelect = (inputName, inputLabel, value, changeFunction) => {
    const { documentTypeList } = this.props;

    return (
      <AutoSuggestion
        label={inputName}
        inputName={inputLabel}
        defaultValue={value ? value : ''}
        handleChange={changeFunction}
        typeList={documentTypeList}
        fullWidth
      />
    );
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  render() {
    const {
      ratingList,
      editMode,
      ratingsRequest,
      removeRatingFile,
      addSimpleError,
      classes,
      onElementDelete,
      t,
      addRatingPlannedAction,
      deactivateRating,
    } = this.props;
    const { addOpen, editOpen } = this.state;

    if (ratingsRequest) {
      return <Loader />;
    }

    return (
      <div className="col-12 user">
        <RatingsList
          ratingList={ratingList}
          addRatingPlannedAction={addRatingPlannedAction}
          removeRating={(rating) =>
            onElementDelete(() => this.removeRating(rating))
          }
          handleAddOpen={this.handleAddOpen}
          editMode={editMode}
          handleEditOpen={this.handleEditOpen}
          attachRatingFile={this.attachRatingFile}
          removeRatingFile={(fileId, id) =>
            onElementDelete(() => removeRatingFile(fileId, id))
          }
          addSimpleError={addSimpleError}
          deactivateRating={deactivateRating}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={addOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="add-rating-dialog-title"
          >
            {this.dialogContent(classes, t('user:addRating'), t)}
            {this.dialogActions(t)}
          </Dialog>
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={editOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="edit-rating-dialog-title"
          >
            {this.dialogContent(classes, t('user:editRating'), t)}
            {this.dialogActions(t)}
          </Dialog>
        </Form>
      </div>
    );
  }
}
