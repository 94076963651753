import {
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_FAILURE,
  ADD_QUESTION,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTION_REQUEST,
  FETCH_QUESTION_FAILURE,
  EDIT_QUESTION,
} from '../actions/questions';

const INITIAL_STATE = {
  question: {},
  questionList: [],
  questionRequest: false,
  questionDetailsRequest: false,
};

function fetchQuestionsSuccess(state, payload) {
  return {
    ...state,
    questionList: payload.questions,
    questionRequest: false,
  };
}

function fetchQuestionsRequest(state) {
  return {
    ...state,
    questionRequest: true,
  };
}

function fetchQuestionsFailure(state) {
  return {
    ...state,
    questionRequest: false,
  };
}

function fetchQuestionSuccess(state, payload) {
  return {
    ...state,
    question: payload.question,
    questionDetailsRequest: false,
  };
}

function fetchQuestionRequest(state) {
  return {
    ...state,
    questionDetailsRequest: true,
  };
}

function fetchQuestionFailure(state) {
  return {
    ...state,
    questionDetailsRequest: false,
  };
}

function addQuestion(state, payload) {
  return {
    ...state,
    questionList: [
      ...state.questionList,
      payload.question
    ]
  };
}

function editQuestion(state, payload) {
  return {
    ...state,
    questionList: state.questionList.map((question) => {
      if (question.id === payload.question.id) {
        return payload.question;
      }

      return question;
    })
  };
}

const actions = {
  [FETCH_QUESTIONS_SUCCESS]: fetchQuestionsSuccess,
  [FETCH_QUESTIONS_REQUEST]: fetchQuestionsRequest,
  [FETCH_QUESTIONS_FAILURE]: fetchQuestionsFailure,
  [ADD_QUESTION]: addQuestion,
  [FETCH_QUESTION_SUCCESS]: fetchQuestionSuccess,
  [FETCH_QUESTION_REQUEST]: fetchQuestionRequest,
  [FETCH_QUESTION_FAILURE]: fetchQuestionFailure,
  [EDIT_QUESTION]: editQuestion,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
