import '../../styles.scss';

import React, { Component } from 'react';
import {
  addEmergencyContact,
  editEmergencyContact,
  fetchEmergencyContactList,
} from '../../../../actions/emergencyContact';

import AddEditEmergencyContactDialog from './dialogs/AddEditEmergencyContact';
import Button from '@material-ui/core/Button';
import EmergencyContactList from './EmergencyContactList';
import Icon from '@material-ui/core/Icon';
import Loader from '../../../../../common/components/Loader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMyUserProfile } from '../../../../actions/users';
import { isAdmin } from '../../../../../utils';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    getMyUserProfileRequest: state.users.getMyUserProfileRequest,
    myProfile: state.users.myProfile,
    emergencyContactList: state.emergencyContact.emergencyContactList,
    emergencyContactListRequest: state.emergencyContact.emergencyContactListRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getMyUserProfile: () => dispatch(getMyUserProfile()),
    addEmergencyContact: (emergencyContact) => dispatch(addEmergencyContact(emergencyContact)),
    fetchEmergencyContactList: (userId) => dispatch(fetchEmergencyContactList(userId)),
    editEmergencyContact: (emergencyContact) => dispatch(editEmergencyContact(emergencyContact)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class EmergencyContacts extends Component {

    static propTypes = {
      userId: PropTypes.string.isRequired,
      getMyUserProfile: PropTypes.func.isRequired,
      getMyUserProfileRequest: PropTypes.object.isRequired,
      myProfile: PropTypes.object.isRequired,
      fetchEmergencyContactList: PropTypes.object.isRequired,
      emergencyContactList: PropTypes.object.isRequired,
      emergencyContactListRequest: PropTypes.object.isRequired,
      emergencyContact: PropTypes.object,
      t: PropTypes.func.isRequired,
      isMyProfile: PropTypes.bool,
    }

    state = {
      dialogOpen: false
    }

    componentDidMount() {
      const { fetchEmergencyContactList, getMyUserProfile, userId } = this.props;

      fetchEmergencyContactList(userId);
      getMyUserProfile();
    }

    onDialogOpen = () => {
      this.setState({
        dialogOpen: true
      });
    }

    onDialogClose = () => {
      this.setState({
        dialogOpen: false
      });
    }

    renderAddButton() {
      const { isMyProfile, myProfile, t, userId } = this.props;
      const { dialogOpen } = this.state;

      return isMyProfile || isAdmin(myProfile) ? (
        <div>
          <Button color="primary"
            className="align-self-end mb-3"
            onClick={this.onDialogOpen}
          >
            <Icon color="primary" className="mr-3">playlist_add</Icon>
            {t('user:Add emergency contact')}
          </Button>
          <AddEditEmergencyContactDialog
            t={t}
            userId={userId}
            edit={false}
            open={dialogOpen}
            onDialogClose={this.onDialogClose}
          />
        </div>
      ) : '';
    }

    render() {

      const { emergencyContactList, emergencyContactListRequest, getMyUserProfileRequest, myProfile, isMyProfile, t, userId } = this.props;

      if (emergencyContactListRequest || getMyUserProfileRequest || !myProfile) {
        return (<Loader />);
      }

      return (
        <div class="row">
          <div class="col-12">
            <EmergencyContactList
              userId={userId}
              onSelectedEmergencyContact={this.onSelectedEmergencyContact}
              emergencyContactList={emergencyContactList}
              isMyProfile={isMyProfile}
              myProfile={myProfile}
              t={t}
            />
          </div>
          <div class="col-auto">
            { this.renderAddButton() }
          </div>
        </div>
      );
    }
}

