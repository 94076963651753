import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import { removeError } from '../../actions/errors';
import { ERROR_AUTO_HIDE } from '../../../constants';

class GeneralError extends Component {
  static propTypes = {
    message: PropTypes.string,
    isError: PropTypes.bool.isRequired,
    removeError: PropTypes.func.isRequired
  };

  static defaultProps = {
    message: '',
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.removeError();
  };

  render() {
    const { message, isError } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isError}
        autoHideDuration={ERROR_AUTO_HIDE}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            onClick={this.handleClose}
          >
            <Icon color="primary">close</Icon>
          </IconButton>,
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isError: state.errors.generalError,
    message: state.errors.message,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    removeError: () => dispatch(removeError()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralError);
