import '../styles.scss';

import React, { Component } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Divider from '@material-ui/core/Divider';
import Form from '../../../../common/components/forms/Form';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ImageInput from '../../../../common/components/upload/ImageInput';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MenuItem from '@material-ui/core/MenuItem';
import { PERMISSIONS } from '../../../../constants/permissions';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';
import { getDateFormat } from '../../../utils/time';
import { hasPermission } from '../../../decorators/permissions';
import moment from 'moment';
import { reformatPickerDateFromDB } from '../../../utils/time';
import userDefault from '../../../../images/profileDefault.png';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  bigAvatar: {
    width: 95,
    height: 95,
  },
  root: {
    width: '170px',
  },
  groupLabelStyle: {
    fontSize: '0.75em',
  },
  uploadContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  pinInput: {
    width: '120px',
    marginRight: '5px',
  },
  eyeButton: {
    width: '50px',
    marginRight: '5px',
  },
  selectDense: {
    marginTop: 8,
    marginBottom: 4,
  },
};

@withNamespaces()
@withStyles(styles)
export default class UserDetails extends Component {
  static propTypes = {
    userDetails: PropTypes.object,
    editMode: PropTypes.bool,
    groups: PropTypes.array,
    classes: PropTypes.object.isRequired,
    registerComponentForm: PropTypes.func.isRequired,
    formValidated: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    attachProfileImage: PropTypes.func.isRequired,
    removeProfileImage: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    isPinVisible: false,
    isFormValid: false,
    name: this.schoolGroupNames(this.props.userDetails.schoolgroupSet),
    firstName: _get(this.props.userDetails, 'firstName', ''),
    lastName: _get(this.props.userDetails, 'lastName', ''),
    email: _get(this.props.userDetails, 'email', ''),
    phone: _get(this.props.userDetails.userprofile, 'phone', ''),
    personalIdNumber: _get(
      this.props.userDetails.userprofile,
      'personalIdNumber',
      '',
    ),
    caaReferenceNumber: _get(
      this.props.userDetails.userprofile,
      'caaReferenceNumber',
      '',
    ),
    placeOfBirth: _get(this.props.userDetails.userprofile, 'placeOfBirth', ''),
    dateOfBirth: _get(
      this.props.userDetails.userprofile,
      'dateOfBirth',
      moment(),
    ),
    nationality: _get(this.props.userDetails.userprofile, 'nationality', ''),
    address: _get(this.props.userDetails.userprofile, 'address', ''),
    town: _get(this.props.userDetails.userprofile, 'town', ''),
    townCountry: _get(this.props.userDetails.userprofile, 'townCountry', ''),
    pin: _get(this.props.userDetails.userprofile, 'pin', ''),
    passwordConfirmation: '',
    password: '',
  };

  manageUserProfileData(userBasicData, editMode, groups) {
    let schoolGroups = userBasicData.schoolgroupSet;

    if (_isObject(schoolGroups)) {
      schoolGroups = _reduce(
        userBasicData.schoolgroupSet,
        (memo, schoolGroup) => {
          return `${memo}${schoolGroup.name} `;
        },
        '',
      );
    }

    return this.manageView(
      Object.assign({ schoolGroups }, userBasicData),
      editMode,
      groups,
    );
  }

  changePinType = () => {
    this.setState((prevState) => ({
      isPinVisible: !prevState.isPinVisible,
    }));
  };

  renderEyeIcon = () => {
    const { isPinVisible } = this.state;

    if (isPinVisible) {
      return <Icon color="primary">visibility_off</Icon>;
    }

    return <Icon color="primary">remove_red_eye</Icon>;
  };

  renderAccountSection() {
    const { classes, t } = this.props;

    return (
      <div className="col-lg-10 row">
        <div className="col-xl-4 col-sm-6 col-xs-12 py-2">
          <MaterialInput
            className={classes.root}
            name="password"
            label={t('input:newPassword')}
            type="password"
            validators={[new validators.Password(t)]}
          />
        </div>
        <div className="col-xl-4 col-sm-6 col-xs-12 py-2">
          <MaterialInput
            className={classes.root}
            name="passwordConfirmation"
            label={t('input:passwordConfirmation')}
            type="password"
            validators={[new validators.Password(t)]}
          />
        </div>
      </div>
    );
  }

  renderAccountView(userDetails, editMode, accountOwner) {
    const { t } = this.props;

    if (!accountOwner || !editMode) {
      return <div />;
    }

    return (
      <div>
        <div className="row align-items-center">
          <div className="col-lg-2 col-xs-12 pl-lg-0 pl-xl-3">
            <h4>{t('user:accountSection')}</h4>
          </div>
          {this.renderAccountSection()}
        </div>
        <Divider className="my-3" />
      </div>
    );
  }

  menuItems(values, items) {
    return items.map((item) => (
      <MenuItem key={item.name} value={item.name}>
        <Checkbox
          color="primary"
          checked={values && values.indexOf(item.name) > -1}
        />
        <ListItemText primary={item.name} />
      </MenuItem>
    ));
  }

  canUserChangeSchoolGroupSet = () => {
    const { editMode } = this.props;

    return hasPermission(PERMISSIONS.schoolGroupEdit) ? !editMode : true;
  };

  handleChange(event) {
    this.setState({ ['name']: event.target.value });
  }

  handleDatePickerChange = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  schoolGroupNames(schoolGroups) {
    return schoolGroups.map((group) => group.name);
  }

  attachFile = (file) => {
    const { userDetails, attachProfileImage } = this.props;

    return attachProfileImage(file, userDetails.userprofile.id);
  };

  removeFileIcon(profilePicture, userProfileId, editMode) {
    const { removeProfileImage } = this.props;

    if (!profilePicture || !editMode) {
      return <div />;
    }

    return (
      <IconButton onClick={() => removeProfileImage(userProfileId)}>
        <Icon color="primary">close</Icon>
      </IconButton>
    );
  }

  renderManageButtons(editMode, profilePicture, userProfileId) {
    const { classes, addSimpleError } = this.props;

    if (!editMode) {
      return <div />;
    }

    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <div className={classes.uploadContainer}>
            <CustomIconButton>
              <ImageInput
                attachFile={this.attachFile}
                addSimpleError={addSimpleError}
                required
              />
              <Icon color="primary">file_upload</Icon>
            </CustomIconButton>
            {this.removeFileIcon(profilePicture, userProfileId, editMode)}
          </div>
        </div>
      </div>
    );
  }

  renderBasicInfo(userDetails, editMode, groups) {
    const { classes, t } = this.props;
    const { name } = this.state;
    const userProfileId = userDetails.id;
    const profileImage = userDetails.userprofile
      ? userDetails.userprofile.profilePicture
      : userDefault;

    return (
      <div className="row align-items-center ">
        <div className="col-xl-2 col-sm-6 col-xs-12 py-2">
          <div className="row justify-content-center">
            <Avatar src={profileImage} className={classes.bigAvatar} />
          </div>
          {this.renderManageButtons(editMode, profileImage, userProfileId)}
        </div>
        <div className="col-lg-10 col-xs-12 row">
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode || !hasPermission(PERMISSIONS.editUserName)}
              name="firstName"
              label={t('input:firstName')}
              defaultValue={this.state.firstName}
              validators={[
                new validators.IsRequired(t),
                new validators.MaxLength(t, 250),
              ]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode || !hasPermission(PERMISSIONS.editUserName)}
              name="lastName"
              label={t('input:lastName')}
              defaultValue={this.state.lastName}
              validators={[
                new validators.IsRequired(t),
                new validators.MaxLength(t, 250),
              ]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode}
              name="email"
              label={t('input:email')}
              validators={[
                new validators.IsRequired(t),
                new validators.Email(t),
              ]}
              defaultValue={this.state.email}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode}
              name="phone"
              label={t('input:phone')}
              defaultValue={this.state.phone}
              validators={[
                new validators.IsRequired(t),
                new validators.MaxLength(t, 20),
              ]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode}
              name="personalIdNumber"
              label={t('input:personalIdNumber')}
              defaultValue={this.state.personalIdNumber}
              validators={[
                new validators.IsRequired(t),
                new validators.MaxLength(t, 250),
              ]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode}
              name="caaReferenceNumber"
              label={t('input:CAA reference number')}
              defaultValue={this.state.caaReferenceNumber}
              validators={[new validators.MaxLength(t, 50)]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode}
              name="placeOfBirth"
              label={t('input:placeOfBirth')}
              defaultValue={this.state.placeOfBirth}
              validators={[
                new validators.IsRequired(t),
                new validators.MaxLength(t, 250),
              ]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <DateTimePicker
              fullWidth
              disabled={!editMode}
              value={reformatPickerDateFromDB(this.state.dateOfBirth)}
              showMonthDropdown
              showTimeSelect={false}
              showYearDropdown
              handleDatePickerChange={this.handleDatePickerChange}
              dateFormat={getDateFormat()}
              name="dateOfBirth"
              label={t('input:dateOfBirth')}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode}
              name="nationality"
              label={t('input:nationality')}
              defaultValue={this.state.nationality}
              validators={[
                new validators.IsRequired(t),
                new validators.MaxLength(t, 250),
              ]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode}
              name="address"
              label={t('input:postalAddress')}
              defaultValue={this.state.address}
              validators={[
                new validators.IsRequired(t),
                new validators.MaxLength(t, 250),
              ]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode}
              name="town"
              label={t('input:town')}
              defaultValue={this.state.town}
              validators={[
                new validators.IsRequired(t),
                new validators.MaxLength(t, 250),
              ]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <MaterialInput
              fullWidth
              multiline
              margin="dense"
              disabled={!editMode}
              name="townCountry"
              label={t('input:townCountry')}
              defaultValue={this.state.townCountry}
              validators={[
                new validators.IsRequired(t),
                new validators.MaxLength(t, 250),
              ]}
            />
          </div>
          <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
            <div className={classes.selectDense}>
              <div>
                <InputLabel
                  className={classes.groupLabelStyle}
                  htmlFor="select-multiple-checkbox"
                >
                  {t('input:groups')}
                </InputLabel>
              </div>
              <div>
                <Select
                  fullWidth
                  multiline
                  className={editMode ? '' : 'svg-arrow-hidden'}
                  multiple
                  disabled={this.canUserChangeSchoolGroupSet()}
                  value={name}
                  onChange={(event) => this.handleChange(event)}
                  input={<Input id="select-multiple-checkbox" />}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {this.menuItems(name, groups)}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  detectAccountOwner(userId) {
    const userFormSession = localStorage.getItem('user');

    return JSON.parse(userFormSession).id === userId;
  }

  manageView(userDetails, editMode, groups) {
    return (
      <Form
        onChange={this.onChange}
        validateForm={this.validateForm}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        {this.renderBasicInfo(userDetails, editMode, groups)}
        <Divider className="my-3" />
        {this.renderAccountView(
          userDetails,
          editMode,
          this.detectAccountOwner(userDetails.id),
        )}
      </Form>
    );
  }

  validateForm = (formData) => {
    const { addSimpleError, t } = this.props;

    if (formData.password === formData.passwordConfirmation) {
      return true;
    }

    addSimpleError(t('error:passwordDoNotMatch'));

    return false;
  };

  onChange = (formData) => {
    this.formData = formData;

    _each(formData, (value, key) => {
      this.setState({
        [key]: value.value,
      });
    });
  };

  mapState() {
    const formChanges = {
      name: this.state.name,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      personalIdNumber: this.state.personalIdNumber,
      caaReferenceNumber: this.state.caaReferenceNumber,
      placeOfBirth: this.state.placeOfBirth,
      dateOfBirth: this.state.dateOfBirth,
      nationality: this.state.nationality,
      address: this.state.address,
      town: this.state.town,
      townCountry: this.state.townCountry,
      pin: this.state.pin,
      passwordConfirmation: this.state.passwordConfirmation,
      password: this.state.password,
    };

    return formChanges;
  }

  onFormValidated = (isFormValid) => {
    this.props.formValidated('userDetails', isFormValid, this.mapState());
  };

  registerForm = (triggerFormValidation) => {
    this.props.registerComponentForm(triggerFormValidation);
  };

  render() {
    const { userDetails, editMode, groups } = this.props;

    return (
      <div className="col-12">
        {this.manageUserProfileData(userDetails, editMode, groups)}
      </div>
    );
  }
}
