import {
  FETCH_INSTALLTIONS_SUCCESS,
  ADD_INSTALLTION,
  EDIT_INSTALLTION,
  CLEAN_INSTALLATION_DATA,
} from '../actions/installations';

const INITIAL_STATE = {
  installationList: [],
};

function fetchInstallationsSuccess(state, payload) {
  return {
    ...state,
    installationList: payload.installations,
  };
}

function addInstallation(state, payload) {
  return {
    ...state,
    installationList: [
      ...state.installationList,
      payload.installation
    ]
  };
}

function editInstallation(state, payload) {
  return {
    ...state,
    installationList: state.installationList.map((installation) => {
      if (installation.id === payload.installation.id) {
        return payload.installation;
      }

      return installation;
    })
  };
}

function cleanInstallation(state) {
  return {
    ...state,
    ...INITIAL_STATE
  };
}

const actions = {
  [FETCH_INSTALLTIONS_SUCCESS]: fetchInstallationsSuccess,
  [ADD_INSTALLTION]: addInstallation,
  [EDIT_INSTALLTION]: editInstallation,
  [CLEAN_INSTALLATION_DATA]: cleanInstallation,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
