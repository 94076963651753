import _find from 'lodash/find';

function returnInitialObjects(elementsNumber, name) {
  const initialObjects = {};

  [...Array(elementsNumber).keys()].forEach((index) => {
    const count = index + 1;
    const field = `${name}_${count}`;

    initialObjects[field] = '';
  });

  return initialObjects;
}

function setSettingsDefaultValue(settings, fieldName) {
  return _find(settings, { name: fieldName }).textValue;
}

export function initialState(props) {
  const { settings, userDetails } = props;


  return {
    licence: '',
    firstName: userDetails.firstName || '',
    lastName: userDetails.lastName || '',

    referenceNumber: '',
    issuingDate: '',
    expiryDate: '',
    organisation: setSettingsDefaultValue(settings, 'company_name'),
    organisationPa: setSettingsDefaultValue(settings, 'company_postal_address'),
    organisationIrn: setSettingsDefaultValue(settings, 'company_internal_reference_number'),
    legalName: setSettingsDefaultValue(settings, 'company_name'),

    railwayUndertaking: false,
    infrastructureManager: false,

    workingPlace: '',
    workingPlacePostalAddress: setSettingsDefaultValue(settings, 'company_postal_address'),
    workingPlaceTownCountry: setSettingsDefaultValue(settings, 'company_town_country'),

    holderPlaceOfBirth: userDetails.userprofile && userDetails.userprofile.placeOfBirth || '',
    holderDateOfBirth: userDetails.userprofile && userDetails.userprofile.dateOfBirth || '',
    holderNationality: userDetails.userprofile && userDetails.userprofile.nationality || '',
    holderPostalAddress: userDetails.userprofile && userDetails.userprofile.address || '',
    holderTown: userDetails.userprofile && userDetails.userprofile.town || '',
    holderTownCountry: userDetails.userprofile && userDetails.userprofile.townCountry || '',
    notes: '',
    additionalInformation: '',
    restrictions: '',

    ...returnInitialObjects(4, 'languageDate'),
    ...returnInitialObjects(4, 'language'),
    ...returnInitialObjects(4, 'languageNotes'),

    ...returnInitialObjects(9, 'category'),

    ...returnInitialObjects(23, 'rollingStockDate'),
    ...returnInitialObjects(23, 'rollingStockDescription'),
    ...returnInitialObjects(23, 'rollingStockNotes'),

    ...returnInitialObjects(23, 'infrastructureDate'),
    ...returnInitialObjects(23, 'infrastructureExtension'),
    ...returnInitialObjects(23, 'infrastructureNotes'),

    ...returnInitialObjects(23, 'infrastructureDate_2'),
    ...returnInitialObjects(23, 'infrastructureDescription_2'),
    ...returnInitialObjects(23, 'infrastructureNotes_2'),
  };
}
