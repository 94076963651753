import axios from 'axios';

import { addError } from './errors';
import { urlJoin, } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_SERVICE_REQUEST = 'FETCH_SERVICE_REQUEST';
export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
export const FETCH_SERVICES = 'FETCH_SERVICES';
export const ADD_SERVICE = 'ADD_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const EDIT_SERVICE = 'EDIT_SERVICE';
export const CLEAN_SERVICE_DATA = 'CLEAN_SERVICE_DATA';


export function fetchServicesRequest() {
  return {
    type: FETCH_SERVICES_REQUEST
  };
}

export function fetchServicesSuccess(services) {
  return {
    type: FETCH_SERVICES_SUCCESS,
    payload: {
      services
    }
  };
}

export function fetchServiceRequest() {
  return {
    type: FETCH_SERVICE_REQUEST
  };
}

export function fetchServiceSuccess(service) {
  return {
    type: FETCH_SERVICE_SUCCESS,
    payload: {
      service
    }
  };
}

export function fetchAllServicesRequest() {
  return {
    type: FETCH_SERVICES,
  };
}

export function addServiceSuccess(service) {
  return {
    type: ADD_SERVICE,
    payload: {
      service
    }
  };
}

export function removeServiceSuccess(service) {
  return {
    type: REMOVE_SERVICE,
    payload: {
      service
    }
  };
}

export function editServiceSuccess(service) {
  return {
    type: EDIT_SERVICE,
    payload: {
      service
    }
  };
}

export function cleanService() {
  return {
    type: CLEAN_SERVICE_DATA,
  };
}

export function fetchServices() {
  return (dispatch) => {
    dispatch(fetchServicesRequest());

    return axios.get(urlJoin(URLS.services))
      .then(({ data }) => dispatch(fetchServicesSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during services fetching ${error.message}`, error));
      });
  };
}

export function fetchService(serviceId) {
  return (dispatch) => {
    dispatch(fetchServiceRequest());

    return axios.get(urlJoin(URLS.services, serviceId))
      .then(({ data }) => {
        dispatch(fetchServiceSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during service fetching ${error.message}`));
      });
  };
}

export function addService(service) {
  return (dispatch) => {
    return axios.post(URLS.services, service)
      .then(({ data }) => {
        dispatch(addServiceSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during service adding ${error.message}`, error));
      });
  };
}

export function editService(service) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.services, service.id), service)
      .then(({ data }) => {
        dispatch(editServiceSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during service editing ${error.message}`, error));
      });
  };
}

export function removeService(service) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.services, service.id))
      .then(() => dispatch(removeServiceSuccess(service)))
      .catch((error) => {
        dispatch(addError(`Error during service removing ${error.message}`, error));
      });
  };
}

export function cleanServiceData() {
  return (dispatch) => dispatch(cleanService());
}
