import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_NOTIFICATION_RECIPIENTS_REQUEST = 'FETCH_NOTIFICATION_RECIPIENTS_REQUEST';
export const FETCH_NOTIFICATION_RECIPIENTS = 'FETCH_NOTIFICATION_RECIPIENTS';
export const ADD_NOTIFICATION_RECIPIENT = 'ADD_NOTIFICATION_RECIPIENT';
export const REMOVE_NOTIFICATION_RECIPIENT = 'REMOVE_NOTIFICATION_RECIPIENT';
export const EDIT_NOTIFICATION_RECIPIENT = 'EDIT_NOTIFICATION_RECIPIENT';


export function fetchNotificationRecipientsRequest() {
  return {
    type: FETCH_NOTIFICATION_RECIPIENTS_REQUEST
  };
}

export function fetchNotificationRecipientsSuccess(notificationRecipients) {
  return {
    type: FETCH_NOTIFICATION_RECIPIENTS,
    payload: {
      notificationRecipients
    }
  };
}

export function addNotificationRecipientSuccess(notificationRecipient) {
  return {
    type: ADD_NOTIFICATION_RECIPIENT,
    payload: {
      notificationRecipient
    }
  };
}

export function removeNotificationRecipientSuccess(notificationRecipient) {
  return {
    type: REMOVE_NOTIFICATION_RECIPIENT,
    payload: {
      notificationRecipient
    }
  };
}

export function editNotificationRecipientSuccess(notificationRecipient) {
  return {
    type: EDIT_NOTIFICATION_RECIPIENT,
    payload: {
      notificationRecipient
    }
  };
}

export function addNotificationRecipient(notificationRecipient) {
  return (dispatch) => {
    return axios.post(URLS.notificationRecipients, notificationRecipient)
      .then(({ data }) => dispatch(addNotificationRecipientSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during notification recipients adding ${error.message}`, error));
      });
  };
}

export function removeNotificationRecipient(notificationRecipient) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.notificationRecipients, notificationRecipient.id))
      .then(() => dispatch(removeNotificationRecipientSuccess(notificationRecipient)))
      .catch((error) => {
        dispatch(addError(`Error during notification recipients removing ${error.message}`, error));
      });
  };
}

export function editNotificationRecipient(notificationRecipient) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.notificationRecipients, notificationRecipient.id), notificationRecipient)
      .then(({ data }) => dispatch(editNotificationRecipientSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during notification recipients editing ${error.message}`, error));
      });
  };
}

export function fetchNotificationRecipients() {
  return (dispatch) => {
    dispatch(fetchNotificationRecipientsRequest());

    return axios.get(urlJoin(URLS.notificationRecipients))
      .then(({ data }) => dispatch(fetchNotificationRecipientsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during notification recipients fetching ${error.message}`, error));
      });
  };
}
