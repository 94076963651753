import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Menu from './Menu';

import { renderModule } from '../../../decorators/modules';
import { MODULES } from '../../../../constants/modules';
import { renderPermission } from '../../../decorators/permissions';
import { PERMISSIONS } from '../../../../constants/permissions';

@withNamespaces()
export default class ExamsMenu extends Menu {
  renderHeader() {
    const { t } = this.props;

    return (
      <>
        {t('navigation:examsMenu')}
      </>
    );
  }

  @renderPermission(PERMISSIONS.questionView)
  renderQuestionBankLink(t) {
    return (
      <Link to="/question_bank" className="nav-link-item" onClick={() => this.closeMenu()}>
        {t('navigation:questionBankLink')}
      </Link>
    );
  }

  renderDropdownMenu() {
    const { t } = this.props;
    const { open } = this.state;
    const isOpen = open ? 'open' : '';

    return (
      <div className={`dropdown ${isOpen}`}>
        <Link to="/exam_list" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:examListLink')}
        </Link>
        <Link to="/exam_admin" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:examAdminLink')}
        </Link>
        {this.renderQuestionBankLink(t)}
      </div>
    );
  }

  @renderModule(MODULES.exams)
  render() {
    return this.renderMenu();
  }
}
