/* eslint-disable no-sync */

import { APPLICATION_TYPE_URL, URLS } from '../../../../constants';
import React, { Component } from 'react';
import { addError, addSimpleError } from '../../../actions/errors';
import {
  calculateAccumulatedTime,
  reformatPickerDateTimeToDB,
  timeToMinutes,
} from '../../../utils/time';
import {
  editAssignedCourse,
  editAssignedCourseSuccess,
  fetchAssignedCourse,
} from '../../../actions/assignedCourses';
import {
  hasPermission,
  renderPermission,
} from '../../../decorators/permissions';
import {
  isCourseCompleted,
  isProgressCheckLesson,
  urlJoin,
} from '../../../../utils';

import BasicCourseInfo from '../CourseNew/BasicCourseInfo';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import LessonList from './LessonList';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import { PERMISSIONS } from '../../../../constants/permissions';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import axios from 'axios';
import { connect } from 'react-redux';
import { isDataToSync } from '../../../../utils/offline';
import userService from '../../../../utils/userService';
import { withNamespaces } from 'react-i18next';

function getAndSaveSchoolLogoResponse(schoolLogoUri) {
  return axios.get(schoolLogoUri).then((response) => {
    localStorage.setItem(schoolLogoUri, JSON.stringify(response));
  });
}

function getUri(uri) {
  return axios
    .get(uri)
    .then((response) => localStorage.setItem(uri, JSON.stringify(response)));
}

function cacheURIs(uris) {
  const promises = uris.map((uri) => {
    return typeof uri === 'string' ? getUri(uri) : uri;
  });

  return Promise.all(promises);
}

function deregisterServiceWorker() {
  return navigator.serviceWorker.getRegistrations().then((registrations) => {
    return Promise.all(
      registrations.map((registration) => registration.unregister()),
    );
  });
}

function timeout(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const mapStateToProps = (state) => {
  return {
    assignedCourse: state.assignedCourses.assignedCourse,
    assignedCourseRequest: state.assignedCourses.assignedCourseRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAssignedCourse: (id) => dispatch(fetchAssignedCourse(id)),
    editAssignedCourse: (id, courseData, goBack) =>
      dispatch(editAssignedCourse(id, courseData, goBack)),
    editAssignedCourseSuccess: (data) =>
      dispatch(editAssignedCourseSuccess(data)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    addError: (message, error) => dispatch(addError(message, error)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class AssignedCourseDetails extends Component {
  static propTypes = {
    assignedCourse: PropTypes.object.isRequired,
    fetchAssignedCourse: PropTypes.func.isRequired,
    editAssignedCourse: PropTypes.func.isRequired,
    assignedCourseRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    editAssignedCourseSuccess: PropTypes.func.isRequired,
    addError: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      goingOffline: false,
      online: false,
      dataToSync: isDataToSync(),
    };

    this._onlineInterval = null;
  }

  componentWillMount() {
    this.triggers = {};
    this.formData = {};
  }

  componentDidMount() {
    this.props.fetchAssignedCourse(this.props.match.params.id);

    this.notifyWhenOnlineChanged();
    this._onlineInterval = setInterval(this.notifyWhenOnlineChanged, 8000);
  }

  componentWillUnmount() {
    clearInterval(this._onlineInterval);
  }

  notifyWhenOnlineChanged = () => {
    axios
      .head(URLS.healthCheck)
      .then(() => {
        if (!this.state.online) {
          this.setState({ online: true });
        }
      })
      .catch(() => {
        if (this.state.online) {
          this.setState({ online: false });
        }
      });
  };

  completeCourse = () => {
    const courseId = this.props.match.params.id;

    this.props.editAssignedCourse(
      courseId,
      { endDate: reformatPickerDateTimeToDB() },
      '/course_list',
    );
  };

  areLessonsCompleted = (courseLessons) => {
    return courseLessons.every(
      (lesson) =>
        (lesson.isCompleted && !lesson.itemsPending) || lesson.itemsCompleted,
    );
  };

  isOfflineButtonDisabled = () => {
    return !this.state.online || this.state.goingOffline;
  };

  goOnline = () => {
    const { t } = this.props;
    const courseId = this.props.match.params.id;
    const courseUri = urlJoin(URLS.assignedCourses, courseId);

    const courseResponseString = localStorage.getItem(courseUri);
    const flightLogResponseString = localStorage.getItem(URLS.flightLog);

    if (!courseResponseString) {
      return;
    }

    const courseResponse = JSON.parse(courseResponseString);
    const flightLogResponse = flightLogResponseString
      ? JSON.parse(flightLogResponseString)
      : [];

    localStorage.setItem('dataToSync', false);
    this.setState({
      dataToSync: false,
      goingOffline: true,
    });

    deregisterServiceWorker(t)
      .then(() => {
        const assignedCourseUrl = urlJoin(URLS.assignedCourses, courseId);
        const courseData = {
          id: courseResponse.data.id,
          courseId: courseResponse.data.courseId,
          courseData: courseResponse.data.courseData,
        };

        return axios.patch(assignedCourseUrl, courseData);
      })
      .then(({ data }) => this.props.editAssignedCourseSuccess(data))
      .then(() => {
        flightLogResponse.forEach((response) => {
          return axios.post(URLS.flightLog, response.data);
        });
      })
      .then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem(URLS.flightLog);
      })
      .finally(() => {
        this.setState({
          goingOffline: false,
        });
      })
      .catch((error) => {
        localStorage.setItem('dataToSync', true);
        this.setState({
          dataToSync: true,
        });

        this.props.addError(t('error:courseAssigning'), error);
      });
  };

  goOffline = () => {
    const courseId = this.props.match.params.id;
    const { assignedCourse } = this.props;
    const courseData = _get(assignedCourse, 'courseData', {});
    const swPromise = navigator.serviceWorker
      .register(`${URLS.serviceWorker}${courseId}`, { scope: '/' })
      .then(function (registration) {
        console.log('Service worker registration succeeded:', registration);
      });

    const courseUri = urlJoin(URLS.assignedCourses, courseId);
    const permissionUri = urlJoin(
      URLS.userPermissions,
      userService.getCurrentUser().id,
    );
    const theoreticalLessonsUris = courseData.lessons.map(({ id }) =>
      urlJoin(URLS.assignedTheoreticalLessons, courseId, id),
    );

    const settingAndSchoolLogoPromise = axios
      .get(URLS.settings)
      .then((response) => {
        const schoolLogo = (response.data.results || []).find(
          ({ name }) => name === 'school_logo',
        );
        const schoolLogoUri =
          schoolLogo && urlJoin(URLS.schoolLogoFile, schoolLogo.value);

        localStorage.setItem(URLS.settings, JSON.stringify(response));

        if (schoolLogo) {
          return getAndSaveSchoolLogoResponse(schoolLogoUri);
        }

        return null;
      });

    this.setState({
      goingOffline: true,
    });

    swPromise
      .then(() =>
        cacheURIs([
          permissionUri,
          courseUri,
          URLS.locations,
          URLS.gradings,
          URLS.aircrafts,
          URLS.practicalFields,
          URLS.assignedCourses,
          URLS.modules,
          URLS.pilotFunction,
          APPLICATION_TYPE_URL,
          ...theoreticalLessonsUris,
          settingAndSchoolLogoPromise,
        ]),
      )
      .then(() => timeout(5000))
      .then(() => {
        localStorage.setItem('courseId', courseId);
        localStorage.setItem('token', localStorage.getItem('token'));
        localStorage.setItem('user', localStorage.getItem('user'));
        localStorage.setItem('dataToSync', true);

        this.setState({
          dataToSync: true,
        });
      })
      .finally(() => {
        this.setState({
          goingOffline: false,
        });

        window.location.reload(false);
      })
      .catch((error) => {
        const { addError, t } = this.props;

        addError(t('error:cantGoOffline'), error);

        return deregisterServiceWorker();
      });
  };

  areThereDataToSync = () => {
    return isDataToSync();
  };

  renderBackButton(t) {
    if (!this.state.online) {
      return null;
    }

    let redirectionPath = '/my_courses';

    if (hasPermission(PERMISSIONS.examView)) {
      redirectionPath = '/course_list';
    }

    return (
      <div className="col-auto">
        <Link to={redirectionPath}>
          <Button variant="raised">{t('buttonBack')}</Button>
        </Link>
      </div>
    );
  }

  renderButtons = (basicInfo, courseLessons, t) => {
    const { endDate } = this.props.assignedCourse;

    if (endDate) {
      return (
        <div className="row justify-content-center">
          {/*this.renderOfflineButton(courseLessons, t)*/}
          {this.renderBackButton(t)}
        </div>
      );
    }

    return (
      <div className="row justify-content-center">
        {this.renderBackButton(t)}
        {this.renderCompleteCourseButton(basicInfo, courseLessons, t)}
        {/*this.renderOfflineButton(courseLessons, t)*/}
      </div>
    );
  };

  renderOfflineButton(courseLessons, t) {
    const { endDate } = this.props.assignedCourse;
    const { dataToSync } = this.state;

    if (endDate && this.areLessonsCompleted(courseLessons)) {
      return null;
    }

    return (
      <div className="col-auto">
        <Button
          color={this.isOfflineButtonDisabled() ? null : 'primary'}
          disabled={this.isOfflineButtonDisabled()}
          onClick={!dataToSync ? this.goOffline : this.goOnline}
          variant="raised"
        >
          {!dataToSync ? t('buttonGoOffline') : t('buttonGoOnline')}
        </Button>
      </div>
    );
  }

  @renderPermission(PERMISSIONS.completeCourse)
  renderCompleteCourseButton(basicInfo, courseLessons, t) {
    if (!this.state.online || this.state.dataToSync) {
      return null;
    }

    const areLessonsCompleted = this.areLessonsCompleted(courseLessons);
    const accumulatedTime = calculateAccumulatedTime(courseLessons);

    const isMinTimeReached =
      timeToMinutes(basicInfo.minTime) <=
      timeToMinutes(accumulatedTime.accumulatedTotal);

    return (
      <div className="col-auto">
        <Button
          color={areLessonsCompleted ? 'primary' : null}
          disabled={!areLessonsCompleted || !isMinTimeReached}
          onClick={this.completeCourse}
          variant="raised"
        >
          {t('buttonComplete')}
        </Button>
      </div>
    );
  }

  @renderPermission(PERMISSIONS.assignedCourseEdit)
  @renderPermission(PERMISSIONS.editCourseInCourseList)
  renderCourseButtons(course, t) {
    const { dataToSync } = this.state;
    const buttons = [];

    if (!isCourseCompleted(course)) {
      buttons.push(
        <div key="add-practical-lesson" className="col-auto">
          <Link to={`/course_list/details/${course.id}/lesson/new`}>
            <Button color="primary">
              <Icon color="primary" className="mr-3">
                playlist_add
              </Icon>
              {t('course:addPracticalLessonButton')}
            </Button>
          </Link>
        </div>,
      );

      if (!dataToSync) {
        buttons.push(
          <div key="edit-practical-lesson" className="col-auto">
            <Link to={`/course_list/edit/${course.id}`}>
              <IconButton>
                <Icon>mode_edit</Icon>
              </IconButton>
            </Link>
          </div>,
        );
      }
    }

    return buttons;
  }

  registerComponentForm(componentName) {
    return (triggerFn) => {
      this.triggers[componentName] = triggerFn;
    };
  }

  formValidated = (formName, isFormValid, formChanges) => {
    this.formData = {
      ...this.formData,
      [`${formName}Changes`]: formChanges,
      [`${formName}isValid`]: isFormValid,
    };
  };

  prepareCourseLessons = (lessons) => {
    const itemList = {};

    lessons.forEach((lesson) => {
      lesson.lessonsItems.forEach(({ item }) => {
        if (!isProgressCheckLesson(lesson.lessonType)) {
          itemList[item.id] = itemList[item.id] || item.isCompleted;
        }
      });
    });

    return lessons.map((lesson) => {
      const itemsCompleted =
        lesson.lessonsItems.every(({ item }) => itemList[item.id]) &&
        lesson.lessonsItems.length > 0;
      const isLessonCompleted = lesson.isCompleted && !itemsCompleted;
      const itemsPending =
        lesson.lessonsItems.some(({ item }) => itemList[item.id]) ||
        isLessonCompleted;

      let lessonStatus = {};

      if (!isProgressCheckLesson(lesson.lessonType)) {
        lessonStatus = {
          itemsCompleted,
          itemsPending,
        };
      }

      return {
        ...lesson,
        items: _sortBy(
          lesson.lessonsItems.map(({ item, order }) => {
            if (!isProgressCheckLesson(lesson.lessonType)) {
              itemList[item.id] = itemList[item.id] || item.isCompleted;
            }

            return {
              ...item,
              order,
            };
          }),
          ['order'],
        ),
        ...lessonStatus,
      };
    });
  };

  render() {
    const { assignedCourse, assignedCourseRequest, t } = this.props;
    const { goingOffline } = this.state;
    const courseId = this.props.match.params.id;
    const courseData = _get(assignedCourse, 'courseData', {});

    if (assignedCourseRequest || _isEmpty(courseData) || goingOffline) {
      return <Loader />;
    }

    const courseLessons = this.prepareCourseLessons(courseData.lessons);
    const accumulatedTime = calculateAccumulatedTime(courseLessons);
    const basicInfo = {
      id: _get(courseData, 'id', ''),
      name: _get(courseData, 'name', ''),
      minTime: _get(courseData, 'minTime', 0),
      maxTime: _get(courseData, 'maxTime', 0),
      estimatedTime: _get(courseData, 'estimatedTime', 0),
      description: _get(courseData, 'description', ''),
    };
    const studentFullName = `${assignedCourse.user.firstName} ${assignedCourse.user.lastName}`;

    return (
      <div className="assigned-course-details row justify-content-lg-between justify-content-end align-items-center">
        <div className="col section-title pb-5">
          <header>
            <span>
              <Icon color="primary">content_paste</Icon>
              <h1>
                {t('course:name')}
                <span className="h1-subheader">
                  {' '}
                  /{t('course:courseDetailsSection')}
                </span>
              </h1>
            </span>
          </header>
        </div>
        {this.renderCourseButtons(assignedCourse, t)}
        <div className="col-12">
          <BasicCourseInfo
            basicInfo={basicInfo}
            preview
            assignedCourse
            estimatedTime={basicInfo.estimatedTime}
            registerComponentForm={this.registerComponentForm('LessonsManager')}
            formValidated={this.formValidated}
            accumulatedPractical={accumulatedTime.accumulatedPractical}
            accumulatedTheoretical={accumulatedTime.accumulatedTheoretical}
            studentFullName={studentFullName}
          />
        </div>
        <div className="col-12">
          <Divider className="my-3" />
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-lg-12">
              <LessonList lessonList={courseLessons} courseId={courseId} />
            </div>
          </div>
        </div>
        <div className="col-12 my-3">
          {this.renderButtons(basicInfo, courseLessons, t)}
        </div>
      </div>
    );
  }
}
