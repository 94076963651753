import {
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  FETCH_QUESTION_REQUEST,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTION_FAILURE,
  ADD_QUESTION,
  EDIT_QUESTION,
  REMOVE_QUESTION,
} from '../actions/questions';

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from '../../constants';

const INITIAL_STATE = {
  question: {},
  questionList: [],
  questionRequest: false,
  questionDetailsRequest: false,
  questionTotalCount: 0,
  questionRemoveSuccess: false,
  searchSettings: {
    sortAsc: true,
    search: '',
    filter: [],
    filterIsUsed: false,
    page: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE
  },
};

function fetchQuestionsRequest(state, payload) {
  return {
    ...state,
    questionRequest: true,
    questionRemoveSuccess: false,
    searchSettings: Object.assign({}, state.searchSettings, payload.searchSettings)
  };
}

function fetchQuestionsSuccess(state, payload) {
  return {
    ...state,
    questionList: payload.questions.results,
    questionTotalCount: payload.questions.count,
    questionRequest: false
  };
}

function fetchQuestionsFailure(state) {
  return {
    ...state,
    questionRequest: false,
  };
}

function fetchQuestionRequest(state) {
  return {
    ...state,
    questionDetailsRequest: true,
    questionRemoveSuccess: false
  };
}

function fetchQuestionSuccess(state, payload) {
  return {
    ...state,
    question: payload.question,
    questionDetailsRequest: false,
  };
}

function fetchQuestionFailure(state) {
  return {
    ...state,
    questionDetailsRequest: false,
  };
}

function addQuestion(state) {
  return {
    ...state,
    questionRemoveSuccess: false
  };
}

function editQuestion(state, payload) {
  return {
    ...state,
    questionRemoveSuccess: false,
    questionList: state.questionList.map((question) => {
      if (question.id === payload.question.id) {
        return payload.question;
      }

      return question;
    })
  };
}

function removeQuestion(state) {
  return {
    ...state,
    questionRemoveSuccess: true
  };
}

const actions = {
  [FETCH_QUESTIONS_REQUEST]: fetchQuestionsRequest,
  [FETCH_QUESTIONS_SUCCESS]: fetchQuestionsSuccess,
  [FETCH_QUESTIONS_FAILURE]: fetchQuestionsFailure,
  [FETCH_QUESTION_REQUEST]: fetchQuestionRequest,
  [FETCH_QUESTION_SUCCESS]: fetchQuestionSuccess,
  [FETCH_QUESTION_FAILURE]: fetchQuestionFailure,
  [ADD_QUESTION]: addQuestion,
  [EDIT_QUESTION]: editQuestion,
  [REMOVE_QUESTION]: removeQuestion
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
