export const FETCH_HISTORYS_SUCCESS = 'FETCH_HISTORYS_SUCCESS';
export const CLEAN_HISTORYS_DATA = 'CLEAN_HISTORYS_DATA';

export function fetchHistorysSuccess(historys) {
  return {
    type: FETCH_HISTORYS_SUCCESS,
    payload: {
      historys
    }
  };
}

export function cleanHistorys() {
  return {
    type: CLEAN_HISTORYS_DATA,
  };
}
