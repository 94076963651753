import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import Loader from '../../../../common/components/Loader';
import ImageInput from '../../../../common/components/upload/ImageInput';
import digitalSignature from '../../../../images/digitalSignature.png';
import { addSimpleError } from '../../../actions/errors';
import { attachDigitalSignatureImage, fetchDigitalSignature } from '../../../actions/fileUpload';

const styles = () => ({
  bigAvatar: {
    width: '100%',
    maxWidth: 300,
    maxHeight: 300,
    objectFit: 'contain',
  },
  uploadContainer: {
    position: 'relative',
    overflow: 'hidden'
  }
});

const mapStateToProps = (state) => {
  return {
    ratingRequest: state.ratings.ratingsRequest,
    file: state.digitalSignature.file,
    fileRequest: state.digitalSignature.fileRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attachDigitalSignatureImage: (fileData, digitalSignatureId) => dispatch(attachDigitalSignatureImage(fileData, digitalSignatureId)),
    fetchDigitalSignature: (id) => dispatch(fetchDigitalSignature(id)),
    addSimpleError: (message) => dispatch(addSimpleError(message))
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class DigitalSignatureManager extends Component {
  static propTypes = {
    digitalSignatureId: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    attachDigitalSignatureImage: PropTypes.func.isRequired,
    fetchDigitalSignature: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    fileRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const { digitalSignatureId, fetchDigitalSignature } = this.props;

    fetchDigitalSignature(digitalSignatureId);
  }

  removeFileIcon() {
    const { file, digitalSignatureId, attachDigitalSignatureImage } = this.props;

    if (!file || _isEmpty(file.path)) {
      return null;
    }

    return (
      <IconButton
        onClick={() => attachDigitalSignatureImage({}, digitalSignatureId)}
      >
        <Icon color="primary">close</Icon>
      </IconButton>
    );
  }

  attachFile = (file) => {
    const { digitalSignatureId, attachDigitalSignatureImage } = this.props;

    return attachDigitalSignatureImage(file, digitalSignatureId);
  };

  renderManageButtons() {
    const { classes, addSimpleError } = this.props;

    return (
      <div className="row justify-content-center pl-md-5">
        <div className="col-auto">
          <div className={classes.uploadContainer}>
            <div tabIndex="0" className="custom-upload-button">
              <span className="custom-upload-icon-button">
                <ImageInput
                  attachFile={this.attachFile}
                  addSimpleError={addSimpleError}
                  required
                />
                <Icon color="primary">file_upload</Icon>
              </span>
              <span className="custom-upload-touch-button" />
            </div>
            {this.removeFileIcon()}
          </div>
        </div>
      </div>
    );
  }

  setDigitalSignature = () => {
    const { file } = this.props;

    return file ? _isEmpty(file.path) ? digitalSignature : file.path : digitalSignature;
  };

  render() {
    const { classes, fileRequest, t } = this.props;

    if (fileRequest) {
      return <Loader />;
    }

    return (
      <div>
        <div className="pl-sm-5">
          <h4>{t('settings:digitalSignatureSection')}</h4>
        </div>
        <div className="row align-items-center">
          <div className="col-12">
            <div className="row justify-content-center">
              <img
                src={this.setDigitalSignature()}
                className={classes.bigAvatar}
              />
            </div>
            {this.renderManageButtons()}
          </div>
        </div>
      </div>
    );
  }
}
