import axios from 'axios';
import { URLS } from '../../constants';
import { addError } from './errors';
import _get from 'lodash/get';
import { capitalizeFirstLetter, urlJoin } from '../../utils';

export const FETCH_BOOKING_OBJECTS_REQUEST = 'FETCH_BOOKING_OBJECTS_REQUEST';
export const FETCH_BOOKING_OBJECTS_SUCCESS = 'FETCH_BOOKING_OBJECTS_SUCCESS';
export const FETCH_BOOKING_OBJECTS_FAILURE = 'FETCH_BOOKING_OBJECTS_FAILURE';
export const ADD_BOOKING_OBJECT = 'ADD_BOOKING_OBJECT';
export const EDIT_BOOKING_OBJECT = 'EDIT_BOOKING_OBJECT';
export const REMOVE_BOOKING_OBJECT = 'REMOVE_BOOKING_OBJECT';


function fetchBookingObjectsRequest() {
  return {
    type: FETCH_BOOKING_OBJECTS_REQUEST
  };
}

function fetchBookingObjectsSuccess(bookingObjects) {
  return {
    type: FETCH_BOOKING_OBJECTS_SUCCESS,
    payload: {
      bookingObjects
    }
  };
}

function fetchBookingObjectsFailure() {
  return {
    type: FETCH_BOOKING_OBJECTS_FAILURE
  };
}

export function addBookingObjectSuccess(bookingObject) {
  return {
    type: ADD_BOOKING_OBJECT,
    payload: {
      bookingObject
    }
  };
}

export function removeBookingObjectSuccess(bookingObject) {
  return {
    type: REMOVE_BOOKING_OBJECT,
    payload: {
      bookingObject
    }
  };
}

export function fetchBookingObjects() {
  return (dispatch) => {
    dispatch(fetchBookingObjectsRequest());

    return axios.get(URLS.bookingObjects)
      .then(({ data }) => {
        dispatch(fetchBookingObjectsSuccess(data.results)
        );
      })
      .catch((error) => {
        dispatch(fetchBookingObjectsFailure());
        dispatch(addError(`Error during locations fetching ${error.message}`, error));
      });
  };
}

export function addBookingObject(bookingObject) {
  return (dispatch) => {
    return axios.post(URLS.bookingObjects, bookingObject)
      .then(({ data }) => dispatch(addBookingObjectSuccess(data)))
      .catch((error) => {
        const errorData = _get(error, 'response.data.name[0]', `error during booking object adding ${error.message}`);

        dispatch(addError(capitalizeFirstLetter(errorData), error));
      });
  };
}

export function removeBookingObject(bookingObject) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.bookingObjects, bookingObject.id))
      .then(() => dispatch(removeBookingObjectSuccess(bookingObject)))
      .catch((error) => {
        dispatch(addError(`Error during booking object removing ${error.message}`, error));
      });
  };
}
