import Chip from '@material-ui/core/Chip';
import CustomIconButton from '../../../common/components/CustomIconButton';
import FileInput from '../../../common/components/upload/FileInput';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { PERMISSIONS } from '../../../constants/permissions';
import PlannedAction from './PlannedAction';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { hasPermission } from '../../decorators/permissions';
import { reformatPickerDateFromDB } from '../../utils/time';
import { verifyAction } from './PlannedAction/plannedActionHelper';

function handleDeactivateAction(item, deactivateItem) {
  const data = {
    id: item.id,
    isActive: false,
  };

  deactivateItem(data);
}

function deactivateAction(item, documentName, deactivateItem) {
  const canManageDocuments = hasPermission(
    PERMISSIONS[`deactivate${documentName}s`],
  );

  if (!item.isActive || !canManageDocuments) {
    return null;
  }

  if (item.isActive) {
    return (
      <Tooltip title="Deactivate" placement="top">
        <IconButton
          onClick={() => handleDeactivateAction(item, deactivateItem)}
        >
          <Icon color="primary">highlight_off</Icon>
        </IconButton>
      </Tooltip>
    );
  }

  return null;
}

function getRemoveIcon(item, documentName, removeItem) {
  const canManageDocuments = hasPermission(
    PERMISSIONS[`delete${documentName}s`],
  );

  if (!canManageDocuments) {
    return null;
  }

  return (
    <Tooltip title="Remove" placement="top">
      <IconButton onClick={() => removeItem(item)}>
        <Icon color="primary">delete</Icon>
      </IconButton>
    </Tooltip>
  );
}

function getEditIcon(item, documentName, handleEditOpen) {
  const canManageDocuments = hasPermission(
    PERMISSIONS[`update${documentName}s`],
  );

  if (!canManageDocuments) {
    return null;
  }

  return (
    <Tooltip title="Edit" placement="top">
      <IconButton onClick={() => handleEditOpen(item)}>
        <Icon color="primary">mode_edit</Icon>
      </IconButton>
    </Tooltip>
  );
}

function getAttachmentsIcon(
  item,
  documentName,
  attachItemFile,
  addSimpleError,
) {
  const canManageDocuments = hasPermission(
    PERMISSIONS[`update${documentName}Files`],
  );

  if (!item.isActive || !canManageDocuments) {
    return null;
  }

  return (
    <CustomIconButton>
      <FileInput
        attachFile={(file) => attachItemFile(file)(item.id)}
        addSimpleError={addSimpleError}
        required
      />
      <Icon color="primary">attach_file</Icon>
    </CustomIconButton>
  );
}

function getPlannedActionIcon(item, documentName, addItemPlannedAction) {
  const canManageDocuments = hasPermission(
    PERMISSIONS[`update${documentName}PlannedAction`],
  );

  if (!item.isActive || !canManageDocuments || !addItemPlannedAction) {
    return null;
  }

  return (
    <PlannedAction
      id={item.id}
      addPlannedAction={addItemPlannedAction}
      plannedAction={item.plannedAction}
      plannedActionDate={reformatPickerDateFromDB(item.plannedActionDate)}
    />
  );
}

export function getActionIcons(editMode, item, actions) {
  const {
    handleEditOpen,
    attachItemFile,
    addSimpleError,
    addItemPlannedAction,
    deactivateItem,
    removeItem,
    documentName,
  } = actions;

  if (editMode) {
    return (
      <div className="file-inputs">
        {getAttachmentsIcon(item, documentName, attachItemFile, addSimpleError)}
        {getPlannedActionIcon(item, documentName, addItemPlannedAction)}
        {verifyAction(item, documentName, addItemPlannedAction)}
        {deactivateAction(item, documentName, deactivateItem)}
        {getEditIcon(item, documentName, handleEditOpen)}
        {getRemoveIcon(item, documentName, removeItem)}
      </div>
    );
  }

  return null;
}

export function renderFiles(editMode, item, removeItemFile, documentName) {
  const itemId = item.id;

  return item.files.map((file) => {
    if (editMode && item.isActive) {
      return (
        <Chip
          key={`${documentName}-${itemId}-file-${file.id}`}
          label={file.name}
          onClick={() => window.open(file.path, '_blank')}
          onDelete={() => removeItemFile(file.id, itemId)}
          className="my-1"
        />
      );
    }

    return (
      <Chip
        key={`${documentName}-${itemId}-file-${file.id}`}
        label={file.name}
        onClick={() => window.open(file.path, '_blank')}
        className="my-1"
      />
    );
  });
}
