import _remove from 'lodash/remove';

import {
  FETCH_STAFF_CATEGORIES_SUCCESS,
  FETCH_STAFF_CATEGORIES_REQUEST,
  FETCH_STAFF_CATEGORIES_FAILURE,
  ADD_STAFF_CATEGORY,
  EDIT_STAFF_CATEGORY,
  REMOVE_STAFF_CATEGORY,
  REMOVE_COMPETENCE_FROM_STAFF_CATEGORY,
} from '../actions/staffCategories';

const INITIAL_STATE = {
  staffCategoryList: [],
  staffCategoryRequest: false,
};

function fetchStaffCategories(state, payload) {
  return {
    ...state,
    staffCategoryList: payload.staffCategories,
    staffCategoryRequest: false,
  };
}

function fetchStaffCategoriesRequest(state) {
  return {
    ...state,
    staffCategoryRequest: true,
  };
}

function fetchStaffCategoriesFailure(state) {
  return {
    ...state,
    staffCategoryRequest: false,
  };
}

function addStaffCategory(state, payload) {
  return {
    ...state,
    staffCategoryList: [
      ...state.staffCategoryList,
      payload.staffCategory
    ]
  };
}

function editStaffCategory(state, payload) {
  return {
    ...state,
    staffCategoryList: state.staffCategoryList.map((staffCategory) => {
      if (staffCategory.id === payload.staffCategory.id) {
        return payload.staffCategory;
      }

      return staffCategory;
    })
  };
}

function removeStaffCategory(state, payload) {
  return {
    ...state,
    staffCategoryList: state.staffCategoryList.filter((staffCategory) => staffCategory !== payload.staffCategory)
  };
}

function removeCompetenceFromStaffCategory(state, payload) {
  return {
    ...state,
    staffCategoryList: state.staffCategoryList.map((staffCategory) => {
      _remove(staffCategory.competencies, (competence) => {
        return competence.id.toString() === payload.competence.id.toString();
      });

      return staffCategory;
    })
  };
}

const actions = {
  [FETCH_STAFF_CATEGORIES_SUCCESS]: fetchStaffCategories,
  [FETCH_STAFF_CATEGORIES_REQUEST]: fetchStaffCategoriesRequest,
  [FETCH_STAFF_CATEGORIES_FAILURE]: fetchStaffCategoriesFailure,
  [ADD_STAFF_CATEGORY]: addStaffCategory,
  [EDIT_STAFF_CATEGORY]: editStaffCategory,
  [REMOVE_STAFF_CATEGORY]: removeStaffCategory,
  [REMOVE_COMPETENCE_FROM_STAFF_CATEGORY]: removeCompetenceFromStaffCategory,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
