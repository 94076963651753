import React, { Component } from 'react';
import {
  attachProfileImage,
  removeProfileImage,
} from '../../../actions/fileUpload';
import {
  cleanUserProfileData,
  fetchUserProfile,
  saveUserDetails,
} from '../../../actions/users';
import { hasModule, renderModule } from '../../../decorators/modules';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import { MODULES } from '../../../../constants/modules';
import { PERMISSIONS } from '../../../../constants/permissions';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import UserDetails from './UserDetails';
import UserExperiences from '../Experiences/UserExperiences';
import UserExternalCourses from '../ExternalCourses/UserExternalCourses';
import UserLicenses from '../Licenses/UserLicenses';
import UserMedicals from '../Medicals/UserMedicals';
import UserPersonalFiles from '../PersonalFiles/UserPersonalFiles';
import UserQualifications from '../Qualifications/UserQualifications';
import UserRatings from '../Ratings/UserRatings';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { addSimpleError } from '../../../actions/errors';
import { capitalizeFirstLetter } from '../../../../utils';
import { connect } from 'react-redux';
import { fetchAircraftTypes } from '../../../actions/engineerAircraftTypes';
import { fetchDocumentTypes } from '../../../actions/documentTypes';
import { fetchGroups } from '../../../actions/groups';
import { reformatPickerDateToDB } from '../../../utils/time';
import { renderPermission } from '../../../decorators/permissions';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    userDetails: state.users.userDetails,
    groups: state.groups.groupList || [],
    groupRequest: state.groups.groupRequest,
    userProfileRequest: state.users.getUserProfileRequest,
    assignedCourseList: state.assignedCourses.assignedCourseList,
    documentTypeList: state.documentTypes.documentTypeList || [],
    documentTypeRequest: state.documentTypes.documentTypeRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAircraftTypes: () => dispatch(fetchAircraftTypes()),
    fetchDocumentTypes: () => dispatch(fetchDocumentTypes()),
    fetchUserProfile: (userId) => dispatch(fetchUserProfile(userId)),
    cleanUserProfileData: () => dispatch(cleanUserProfileData()),
    saveUserDetails: (id, userDetails, myProfile) =>
      dispatch(saveUserDetails(id, userDetails, myProfile)),
    fetchGroups: () => dispatch(fetchGroups()),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    attachProfileImage: (fileData, userProfileId) =>
      dispatch(attachProfileImage(fileData, userProfileId)),
    removeProfileImage: (userProfileId) =>
      dispatch(removeProfileImage(userProfileId)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class UserProfile extends Component {
  static propTypes = {
    fetchAircraftTypes: PropTypes.func.isRequired,
    fetchUserProfile: PropTypes.func.isRequired,
    fetchDocumentTypes: PropTypes.func.isRequired,
    userDetails: PropTypes.object,
    cleanUserProfileData: PropTypes.func.isRequired,
    saveUserDetails: PropTypes.func.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    groups: PropTypes.array,
    groupRequest: PropTypes.bool.isRequired,
    userProfileRequest: PropTypes.bool.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    attachProfileImage: PropTypes.func.isRequired,
    removeProfileImage: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    editMode: PropTypes.bool.isRequired,
    myProfile: PropTypes.bool,
    assignedCourseList: PropTypes.array,
    documentTypeList: PropTypes.array,
    documentTypeRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = {
      userDetailsisValid: false,
      showRemoveDialog: false,
      removeCallback: () => {},
    };
  }

  componentDidMount() {
    const { userId } = this.props;

    this.props.fetchGroups();
    this.props.fetchDocumentTypes();
    this.props.fetchUserProfile(userId);
    this.props.fetchAircraftTypes();
  }

  componentWillUnmount() {
    this.props.cleanUserProfileData();
  }

  prepareSchoolGroups(groups) {
    return groups.map((group) => ({ id: this.getGroupId(group) }));
  }

  getGroupId(groupName) {
    return Number(_filter(this.props.groups, { name: groupName })['0'].id);
  }

  saveUserDetails = async () => {
    const { userDetails, myProfile, t } = this.props;

    await this.triggers.userDetails();

    if (this.formData.userDetailsisValid) {
      const userProfileChanges = {
        ...this.formData.userDetailsChanges,
        profilePicture: userDetails.userprofile.profilePicture,
      };

      this.props.saveUserDetails(
        userDetails.id,
        this.mapChangesToUserDetails(userProfileChanges),
        myProfile,
      );
    } else {
      this.props.addSimpleError(t('error:Failed to update profile'));
    }
  };

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  mapChangesToUserDetails(userDetailsChanges) {
    const userProfile = {
      userprofile: {
        pin: userDetailsChanges.pin,
        address: userDetailsChanges.address,
        dateOfBirth: reformatPickerDateToDB(userDetailsChanges.dateOfBirth),
        flightExp: userDetailsChanges.flightExp,
        license: userDetailsChanges.license,
        medical: userDetailsChanges.medical,
        phone: userDetailsChanges.phone,
        personalIdNumber: userDetailsChanges.personalIdNumber,
        caaReferenceNumber: userDetailsChanges.caaReferenceNumber,
        placeOfBirth: userDetailsChanges.placeOfBirth,
        nationality: userDetailsChanges.nationality,
        town: userDetailsChanges.town,
        townCountry: userDetailsChanges.townCountry,
        profilePicture: userDetailsChanges.profilePicture,
      },
      firstName: userDetailsChanges.firstName,
      lastName: userDetailsChanges.lastName,
      email: userDetailsChanges.email,
    };

    if (!_isEmpty(userDetailsChanges.name)) {
      userProfile.schoolgroupSet = this.prepareSchoolGroups(
        userDetailsChanges.name,
      );
    }

    if (!_isEmpty(userDetailsChanges.password)) {
      userProfile.password = userDetailsChanges.password;
    }

    return userProfile;
  }

  renderSaveButton() {
    const { editMode, t } = this.props;

    if (editMode) {
      return (
        <div className="justify-content-center row">
          <div className="col-auto py-1">
            <Button
              color="primary"
              variant="raised"
              onClick={this.saveUserDetails}
            >
              {t('buttonSave')}
            </Button>
          </div>
        </div>
      );
    }

    return <div />;
  }

  renderCancelButton() {
    const { editMode, myProfile, t } = this.props;

    let pathName = '/users/';

    if (myProfile) {
      pathName = '/';
    }

    if (editMode) {
      return (
        <div className="justify-content-center row">
          <div className="col-auto py-1">
            <Link
              to={{
                pathname: pathName,
                state: { editMode: false },
              }}
            >
              <Button variant="raised">{t('buttonCancel')}</Button>
            </Link>
          </div>
        </div>
      );
    }

    return <div />;
  }

  renderUserName() {
    const { userDetails } = this.props;

    if (_isNil(userDetails)) {
      return '';
    }

    return `${capitalizeFirstLetter(
      userDetails.firstName,
    )} ${capitalizeFirstLetter(userDetails.lastName)}`;
  }

  registerComponentForm(componentName) {
    return (triggerFn) => {
      this.triggers[componentName] = triggerFn;
    };
  }

  formValidated = (formName, isFormValid, formChanges) => {
    this.formData = {
      ...this.formData,
      [`${formName}Changes`]: formChanges,
      [`${formName}isValid`]: isFormValid,
    };
  };

  @renderPermission(PERMISSIONS.userEdit)
  renderEditButton() {
    return (
      <div className="col-auto">
        <Link
          to={{
            pathname: `/users/${this.props.userDetails.id}`,
            state: { editMode: true },
          }}
        >
          <IconButton>
            <Icon>edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  @renderModule(MODULES.engineerMode)
  renderUserExperiences(userId, documentTypeList, editMode) {
    return (
      <UserExperiences
        userId={userId}
        documentTypeList={documentTypeList}
        editMode={editMode}
        onElementDelete={this.openRemoveDialog}
      />
    );
  }

  @renderModule(MODULES.userProfilePersonalFile)
  renderPersonalFiles(userId, documentTypeList, editMode) {
    return (
      <UserPersonalFiles
        userId={userId}
        documentTypeList={documentTypeList}
        editMode={editMode}
        onElementDelete={this.openRemoveDialog}
      />
    );
  }

  @renderModule(MODULES.userProfileLicence)
  renderLicences(userId, documentTypeList, editMode) {
    return (
      <UserLicenses
        userId={userId}
        documentTypeList={documentTypeList}
        editMode={editMode}
        onElementDelete={this.openRemoveDialog}
      />
    );
  }

  @renderModule(MODULES.userProfileMedical)
  renderMedicals(userId, documentTypeList, editMode) {
    return (
      <UserMedicals
        userId={userId}
        documentTypeList={documentTypeList}
        editMode={editMode}
        onElementDelete={this.openRemoveDialog}
      />
    );
  }

  @renderModule(MODULES.userProfileRating)
  renderRatings(userId, documentTypeList, editMode) {
    return (
      <UserRatings
        userId={userId}
        documentTypeList={documentTypeList}
        editMode={editMode}
        onElementDelete={this.openRemoveDialog}
      />
    );
  }

  @renderModule(MODULES.userProfileQualification)
  renderQualifications(userId, documentTypeList, editMode) {
    return !hasModule(MODULES.engineerMode) ? (
      <UserQualifications
        userId={userId}
        documentTypeList={documentTypeList}
        editMode={editMode}
        onElementDelete={this.openRemoveDialog}
      />
    ) : (
      <></>
    );
  }

  @renderModule(MODULES.userProfileExternalCourse)
  renderExternalCourses(userId, documentTypeList, editMode) {
    return (
      <UserExternalCourses
        userId={userId}
        documentTypeList={documentTypeList}
        editMode={editMode}
        onElementDelete={this.openRemoveDialog}
      />
    );
  }

  render() {
    const {
      editMode,
      userProfileRequest,
      userDetails,
      userId,
      groups,
      groupRequest,
      attachProfileImage,
      removeProfileImage,
      documentTypeRequest,
      documentTypeList,
    } = this.props;
    const { showRemoveDialog, removeCallback } = this.state;

    if (userProfileRequest || documentTypeRequest || groupRequest) {
      return <Loader />;
    }

    if (!_isNil(userDetails)) {
      return (
        <div className="user-wrapper">
          <div className="row justify-content-end align-items-center">
            {!editMode && this.renderEditButton()}
          </div>
          <UserDetails
            userDetails={userDetails}
            editMode={editMode}
            groups={groups}
            registerComponentForm={this.registerComponentForm('userDetails')}
            formValidated={this.formValidated}
            addSimpleError={this.props.addSimpleError}
            attachProfileImage={attachProfileImage}
            removeProfileImage={removeProfileImage}
          />
          {editMode && this.renderSaveButton()}
          {this.renderUserExperiences(userId, documentTypeList, editMode)}
          {this.renderPersonalFiles(userId, documentTypeList, editMode)}
          {this.renderLicences(userId, documentTypeList, editMode)}
          {this.renderMedicals(userId, documentTypeList, editMode)}
          {this.renderRatings(userId, documentTypeList, editMode)}
          {this.renderQualifications(userId, documentTypeList, editMode)}
          {this.renderExternalCourses(userId, documentTypeList, editMode)}
          {editMode && this.renderCancelButton()}
          <RemoveDialogContainer
            openDialog={showRemoveDialog}
            onClose={this.closeRemoveDialog}
            removeFunction={removeCallback}
          />
        </div>
      );
    }

    return <div />;
  }
}
