import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { PERMISSIONS } from '../../../../constants/permissions';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { hasPermission } from '../../../decorators/permissions';
import moment from 'moment';
import { reformatPickerDateToDB } from '../../../utils/time';
import userService from '../../../../utils/userService';

function handleVerifyAction(item, addPlannedAction) {
  const data = {
    id: item.id,
    isVerified: true,
    verificationDate: reformatPickerDateToDB(moment()),
    verifiedById: userService.getCurrentUser().id,
  };

  addPlannedAction(data);
}

export function verifyAction(
  item,
  documentName,
  addPlannedAction,
  customStyle,
) {
  if (
    hasPermission(PERMISSIONS[`confirm${documentName}s`]) &&
    !item.isVerified &&
    addPlannedAction
  ) {
    return (
      <Tooltip title="Verify" placement="top">
        <IconButton onClick={() => handleVerifyAction(item, addPlannedAction)}>
          {customStyle ? (
            <Icon style={{ color: '#6b33bf' }}>check_circle_outline</Icon>
          ) : (
            <Icon color="primary">check_circle_outline</Icon>
          )}
        </IconButton>
      </Tooltip>
    );
  }

  return null;
}

export function getVerifiedBy(verifiedBy) {
  if (verifiedBy) {
    return `${verifiedBy.firstName} ${verifiedBy.lastName}`;
  }

  return '';
}

export function isVerified(isVerified) {
  if (isVerified) {
    return (
      <Icon color="action" className="mr-3">
        done
      </Icon>
    );
  }

  return (
    <Icon color="error" className="mr-3">
      clear
    </Icon>
  );
}
