import {
  checkExpiredDate,
  checkExpiryDate,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../utils/time';

import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import moment from 'moment';

export function getPartStatus(part, warehouseSettings) {
  let expiryClass = 'ok-date';

  const serviceHours = Number(part.serviceHours);
  const serviceCycles = Number(part.serviceCycle);
  const eolCycles = Number(part.eolCycle);
  const serviceDistance = Number(part.serviceMileage);
  const eolDistance = Number(part.eolMileage);

  const isExpired =
    checkExpiredDate(part.serviceDate) ||
    checkExpiredDate(part.eolDate) ||
    (!_isNil(part.serviceHours) && serviceHours <= 0) ||
    (!_isNil(part.serviceMileage) && serviceDistance <= 0) ||
    (!_isNil(part.serviceCycle) && serviceCycles <= 0) ||
    (!_isNil(part.eolMileage) && eolDistance <= 0) ||
    (!_isNil(part.eolCycle) && eolCycles <= 0) ||
    part.partStatus === 'UNSERVICEABLE';
  const expires =
    checkExpiryDate(part.serviceDate, 1) ||
    checkExpiryDate(part.eolDate, 1) ||
    (!_isNil(part.serviceHours) &&
      serviceHours <= warehouseSettings.hoursWarning) ||
    (!_isNil(part.serviceMileage) &&
      serviceDistance <= warehouseSettings.distanceWarning) ||
    (!_isNil(part.serviceCycle) &&
      serviceCycles <= warehouseSettings.cyclesWarning) ||
    (!_isNil(part.eolMileage) &&
      eolDistance <= warehouseSettings.distanceWarning) ||
    (!_isNil(part.eolCycle) && eolCycles <= warehouseSettings.cyclesWarning);

  if (isExpired) {
    expiryClass = 'expired-date';
  } else if (expires) {
    expiryClass = 'expiry-date';
  }

  return expiryClass;
}

export function renderPartColor(part, warehouseSettings) {
  const partStatus = getPartStatus(part, warehouseSettings);

  if (part.nonSafetyRelated && partStatus === 'expired-date') {
    return <div className={'status-circle non-safety-related'} />;
  }

  return <div className={`status-circle ${partStatus}`} />;
}

export function renderPartNonSafetyRelatedColor(
  part,
  warehouse,
  warehouseSettings,
) {
  const partStatus = getPartStatus(part, warehouse, warehouseSettings);

  if (part.nonSafetyRelated && partStatus === 'expired-date') {
    return <div className={'status-circle non-safety-related'} />;
  }

  return <div />;
}

export function renderHoursColor(item, fieldName, warehouseSettings) {
  if (_isNil(item[fieldName])) {
    return null;
  }

  const hoursNumber = Number(item[fieldName]);
  const hours = hoursNumber >= 0 ? hoursNumber : 0;
  let expiryClass = 'ok-date';

  if (item.isCompleted) {
    return <div>{hours}</div>;
  }

  if (hours <= 0) {
    expiryClass = 'expired-date';
  } else if (hours <= Number(warehouseSettings.hoursWarning)) {
    expiryClass = 'expiry-date';
  }

  return <div className={expiryClass}>{hours}</div>;
}

export function renderCyclesColor(item, fieldName, warehouseSettings) {
  if (_isNil(item[fieldName])) {
    return null;
  }

  const cyclesNumber = Number(item[fieldName]);
  const cycles = cyclesNumber >= 0 ? cyclesNumber : 0;
  let expiryClass = 'ok-date';

  if (item.isCompleted) {
    return <div>{cycles}</div>;
  }

  if (cycles <= 0) {
    expiryClass = 'expired-date';
  } else if (cycles <= Number(warehouseSettings.cyclesWarning)) {
    expiryClass = 'expiry-date';
  }

  return <div className={expiryClass}>{cycles}</div>;
}

export function renderDistanceColor(item, fieldName, warehouseSettings) {
  if (_isNil(item[fieldName])) {
    return null;
  }

  const distanceNumber = Number(item[fieldName]);
  const distance = distanceNumber >= 0 ? distanceNumber : 0;
  let expiryClass = 'ok-date';

  if (item.isCompleted) {
    return <div>{distance}</div>;
  }

  if (distance <= 0) {
    expiryClass = 'expired-date';
  } else if (distance <= Number(warehouseSettings.distanceWarning)) {
    expiryClass = 'expiry-date';
  }

  return <div className={expiryClass}>{distance}</div>;
}

export function renderDateColor(item, fieldName) {
  if (!item[fieldName]) {
    return null;
  }

  const expiryDateString = reformatPickerDateFromDB(item[fieldName], false);
  let expiryClass = 'ok-date';

  if (item.isCompleted) {
    return <div>{expiryDateString}</div>;
  }

  if (checkExpiredDate(item[fieldName])) {
    expiryClass = 'expired-date';
  } else if (checkExpiryDate(item[fieldName], 1)) {
    expiryClass = 'expiry-date';
  }

  return <div className={expiryClass}>{expiryDateString}</div>;
}

export function getWarehouseStatus(
  mountedPartsList,
  warehouse,
  warehouseSettings,
) {
  let expiryClass = 'ok-date';

  const partExpired =
    mountedPartsList &&
    mountedPartsList.some(
      (part) =>
        !part.nonSafetyRelated &&
        getPartStatus(part, warehouse, warehouseSettings) === 'expired-date',
    );
  const partExpires =
    mountedPartsList &&
    mountedPartsList.some(
      (part) =>
        !part.nonSafetyRelated &&
        getPartStatus(part, warehouse, warehouseSettings) === 'expiry-date',
    );

  const isExpired = partExpired;
  const expires = partExpires;

  if (isExpired) {
    expiryClass = 'expired-date';
  } else if (expires) {
    expiryClass = 'expiry-date';
  }

  return expiryClass;
}

export function renderWarehouseColor(
  mountedPartsList,
  warehouse,
  warehouseSettings,
) {
  const warehouseStatus = getWarehouseStatus(
    mountedPartsList,
    warehouse,
    warehouseSettings,
  );

  if (warehouseStatus === 'ok-date') {
    const warehouseNoNSafetyRelatedStatus =
      getWarehousePartsNoNSafetyRelatedStatus(
        mountedPartsList,
        warehouse,
        warehouseSettings,
      );

    if (warehouseNoNSafetyRelatedStatus !== 'ok-date') {
      return <div className={'status-circle non-safety-related'} />;
    }
  }

  return <div className={`status-circle ${warehouseStatus}`} />;
}

export function getWarehousePartsNoNSafetyRelatedStatus(
  mountedPartsList,
  warehouse,
  warehouseSettings,
) {
  let expiryClass = 'ok-date';

  const partExpired =
    mountedPartsList &&
    mountedPartsList.some(
      (part) =>
        getPartStatus(part, warehouse, warehouseSettings) === 'expired-date' &&
        part.nonSafetyRelated,
    );

  if (partExpired) {
    expiryClass = 'expired-date';
  }

  return expiryClass;
}

export function renderPartsNonSafetyRelatedColor(
  mountedPartsList,
  warehouse,
  warehouseSettings,
) {
  const warehouseStatus = getWarehousePartsNoNSafetyRelatedStatus(
    mountedPartsList,
    warehouse,
    warehouseSettings,
  );

  if (warehouseStatus === 'ok-date') {
    return null;
  }

  return <div className={'status-circle non-safety-related'} />;
}

export function getNextServiceDate(inspectionList, partWarehouseList) {
  const dates = [];

  partWarehouseList &&
    partWarehouseList.forEach((part) => {
      if (part.serviceDate) {
        dates.push(reformatPickerDateFromDB(part.serviceDate));
      }

      if (part.eolDate) {
        dates.push(reformatPickerDateFromDB(part.eolDate));
      }
    });

  if (_isEmpty(dates)) {
    return '';
  }

  return reformatPickerDateToDB(moment.min(dates));
}
