import {
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_FAILURE,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_REQUEST,
  FETCH_COURSE_FAILURE,
  ADD_COURSE,
  EDIT_COURSE,
} from '../actions/courses';

const INITIAL_STATE = {
  course: {},
  courseList: [],
  courseRequest: false,
  courseDetailsRequest: false,
};

function fetchCoursesSuccess(state, payload) {
  return {
    ...state,
    courseList: payload.courses,
    courseRequest: false,
  };
}

function fetchCoursesRequest(state) {
  return {
    ...state,
    courseRequest: true,
  };
}

function fetchCoursesFailure(state) {
  return {
    ...state,
    courseRequest: false,
  };
}

function fetchCourseSuccess(state, payload) {
  return {
    ...state,
    course: payload.course,
    courseDetailsRequest: false,
  };
}

function fetchCourseRequest(state) {
  return {
    ...state,
    courseDetailsRequest: true,
  };
}

function fetchCourseFailure(state) {
  return {
    ...state,
    courseDetailsRequest: false,
  };
}

function addCourse(state, payload) {
  return {
    ...state,
    courseList: [
      ...state.courseList,
      payload.course
    ]
  };
}

function editCourse(state, payload) {
  return {
    ...state,
    courseList: state.courseList.map((course) => {
      if (course.id === payload.course.id) {
        return payload.course;
      }

      return course;
    })
  };
}


const actions = {
  [FETCH_COURSES_SUCCESS]: fetchCoursesSuccess,
  [FETCH_COURSES_REQUEST]: fetchCoursesRequest,
  [FETCH_COURSES_FAILURE]: fetchCoursesFailure,
  [FETCH_COURSE_SUCCESS]: fetchCourseSuccess,
  [FETCH_COURSE_REQUEST]: fetchCourseRequest,
  [FETCH_COURSE_FAILURE]: fetchCourseFailure,
  [ADD_COURSE]: addCourse,
  [EDIT_COURSE]: editCourse,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
