import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RESET_AUTHENTICATION,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_TOKEN_SUCCESS,
  PASSWORD_TOKEN_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  RESET_PASSWORD_RECOVERING,
} from '../actions/authentication';

import {
  FETCH_USER_PERMISSIONS_REQUEST,
  FETCH_USER_PERMISSIONS_SUCCESS,
} from '../actions/users';

const INITIAL_STATE = {
  token: null,
  login: null,
  loginSuccess: false,
  loginRequest: false,
  loginFailure: false,
  passwordRecoverySuccess: false,
  changePasswordSuccess: false,
  tokenIsValid: true,
  userPermissionsRequest: false,
  userPermissions: [],
};

function loginRequest(state) {
  return {
    ...state,
    loginRequest: true,
  };
}

function loginSuccess(state, payload) {
  return {
    ...state,
    loginRequest: false,
    loginSuccess: true,
    loginFailure: false,
    token: payload.token,
    login: payload.user.email,
  };
}

function loginError(state) {
  return {
    ...state,
    loginRequest: false,
    loginFailure: true,
    token: null,
    login: null,
  };
}

function resetAuthentication(state) {
  return {
    ...state,
    ...INITIAL_STATE
  };
}

function passwordTokenSuccess(state) {
  return {
    ...state,
    tokenIsValid: true
  };
}

function passwordTokenFailure(state) {
  return {
    ...state,
    tokenIsValid: false
  };
}

function passwordRecoverySuccess(state) {
  return {
    ...state,
    passwordRecoverySuccess: true
  };
}

function changePasswordSuccess(state) {
  return {
    ...state,
    changePasswordSuccess: true
  };
}

function resetPasswordRecovering(state) {
  return {
    ...state,
    changePasswordSuccess: false,
    passwordRecoverySuccess: false
  };
}

function fetchUserPermissionsRequest(state) {
  return {
    ...state,
    userPermissionsRequest: true
  };
}

function fetchUserPermissionsSuccess(state, payload) {
  return {
    ...state,
    userPermissions: payload.permissions,
    userPermissionsRequest: false,
  };
}

const actions = {
  [LOGIN_REQUEST]: loginRequest,
  [LOGIN_SUCCESS]: loginSuccess,
  [LOGIN_ERROR]: loginError,

  [RESET_AUTHENTICATION]: resetAuthentication,

  [PASSWORD_TOKEN_SUCCESS]: passwordTokenSuccess,
  [PASSWORD_TOKEN_FAILURE]: passwordTokenFailure,

  [PASSWORD_RECOVERY_SUCCESS]: passwordRecoverySuccess,
  [CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [RESET_PASSWORD_RECOVERING]: resetPasswordRecovering,

  [FETCH_USER_PERMISSIONS_REQUEST]: fetchUserPermissionsRequest,
  [FETCH_USER_PERMISSIONS_SUCCESS]: fetchUserPermissionsSuccess,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
