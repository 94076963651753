import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const styles = () => ({
  gradings: {
    minWidth: 180,
  },
});


@withNamespaces()
@withStyles(styles)
export default class SelectGrading extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    grade: PropTypes.string.isRequired,
    handleGradeChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    gradingList: PropTypes.array.isRequired,
    settings: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    preview: PropTypes.bool,
  };

  renderMenuItems(gradingList, t) {
    if (_isEmpty(gradingList)) {
      return null;
    }

    const gradings = gradingList.map((grading) => {
      const gradingType = `settings:${grading}`;

      return <MenuItem key={`grading-${grading}`} value={grading}>{t(gradingType)}</MenuItem>;
    });

    gradings.unshift(
      <MenuItem key="grading-not-selected" value="">
        <em>{t('none')}</em>
      </MenuItem>
    );

    return gradings;
  }

  getGradingOptions() {
    const { settings, gradingList } = this.props;

    const settingsGrade = _find(settings, { name: 'grade' });
    const grading = _find(gradingList, { id: settingsGrade.value });

    if (_isEmpty(gradingList)) {
      return null;
    }

    return grading.options;
  }

  render() {
    const { classes, t, handleGradeChange, grade, item, preview } = this.props;
    const gradingOptions = this.getGradingOptions();

    return (
      <div>
        <FormControl className="ml-2 ml-sm-3 my-2">
          <InputLabel className={classes.gradings} htmlFor="grading">{t('input:grade')}</InputLabel>
          <Select
            disabled={preview}
            className={classes.gradings}
            value={grade || []}
            onChange={handleGradeChange(item.lessonItemId)}
            inputProps={{
              name: 'grading',
              id: 'grading',
            }}
          >
            {this.renderMenuItems(gradingOptions, t)}
          </Select>
        </FormControl>
      </div>
    );
  }
}
