import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Form from '../../../../common/components/forms/Form';
import { Link } from 'react-router-dom';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import RiskMatrix from './RiskMatrix';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { editRiskAssessment } from '../../../actions/riskAssessments';
import userService from '../../../../utils/userService';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {
    editRiskAssessment: (riskAssessment) =>
      dispatch(editRiskAssessment(riskAssessment)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class RiskManager extends Component {
  static propTypes = {
    editRiskAssessment: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    lock: PropTypes.bool,
    t: PropTypes.func.isRequired,
    riskAssessment: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { riskAssessment } = this.props;

    const user = userService.getCurrentUser();

    if (!riskAssessment.mitigates.length) {
      riskAssessment.mitigates.push({
        comments: '',
        createdBy: user.id,
        createdDate: '',
      });
    }

    this.setState({ riskAssessment });
  }

  onChange = (formData, name) => {
    this.formData = formData;

    const { value } = formData[name];

    const { riskAssessment } = this.state;

    if (!_isEmpty(value)) {
      riskAssessment.mitigates[0][name] = value;

      this.setState({ ...riskAssessment });
    }
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid && this.formData) {
      const { editRiskAssessment } = this.props;
      const { riskAssessment } = this.state;

      const data = {
        id: riskAssessment.id,
        mitigates: riskAssessment.mitigates,
      };

      data.mitigates[0]['createdDate'] = new Date().toISOString().slice(0, 10);

      editRiskAssessment(data);
    }
  };

  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  render() {
    const { t, riskAssessment, lock } = this.props;

    const mitigate = riskAssessment.mitigates[0] || {};

    const comments = mitigate.comments || '';

    return (
      <div>
        <RiskMatrix hazards={riskAssessment.hazards}></RiskMatrix>
        <div className="row">
          <div className="col-7">
            <Form
              onChange={this.onChange}
              onFormValidated={this.onFormValidated}
              registerForm={this.registerForm}
            >
              {lock ? (
                <MaterialInput
                  label={t('input:Comment')}
                  name="comments"
                  defaultValue={comments}
                  margin="dense"
                  multiline
                  rows={10}
                  rowsMax={10}
                  validators={[new validators.IsRequired(t)]}
                  fullWidth
                  InputProps={{
                    readOnly: { lock },
                    disableUnderline: { lock },
                  }}
                />
              ) : (
                <MaterialInput
                  label={t('input:Comment')}
                  name="comments"
                  defaultValue={comments}
                  margin="dense"
                  multiline
                  rows={10}
                  rowsMax={10}
                  validators={[new validators.IsRequired(t)]}
                  fullWidth
                />
              )}
            </Form>
          </div>
          {lock ? (
            ''
          ) : (
            <div class="mt-4 col-12 text-center">
              <Link to={{ pathname: '/sms' }}>
                <Button color="secondary">{t('buttonCancel')}</Button>
              </Link>
              <Button variant="contained" color="primary" onClick={this.onSave}>
                {t('sms:mitigate')}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
