import {
  ADD_FOLDER,
  EDIT_FOLDER,
  FETCH_FOLDERS_SUCCESS,
  FETCH_FOLDERS_UNREAD_FILES_REQUEST,
  FETCH_FOLDERS_UNREAD_FILES_SUCCESS,
  REMOVE_FOLDER,
} from '../actions/folders';
import {
  EDIT_FOLDER_FILE_SUCCESS,
  REMOVE_FOLDER_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  folderList: {},
  folderRequest: false,
  folderUnreadFilesRequest: false,
  folderUnreadFiles: [],
};

function fetchFoldersSuccess(state, payload) {
  return {
    ...state,
    folderList: payload.folders,
    folderRequest: false,
  };
}

function fetchFoldersUnreadFilesRequest(state) {
  return {
    ...state,
    folderUnreadFilesRequest: true,
  };
}

function fetchFoldersUnreadFilesSuccess(state, payload) {
  return {
    ...state,
    folderUnreadFiles: payload.files.results,
    folderUnreadFilesRequest: false,
  };
}

function addFolder(state, payload) {
  const { folders } = state.folderList;

  return {
    ...state,
    folderList: {
      ...state.folderList,
      folders: [...folders, payload.folder],
    },
  };
}

function removeFolder(state, payload) {
  const { folders } = state.folderList;

  return {
    ...state,
    folderList: {
      ...state.folderList,
      folders: folders.filter((folder) => folder.id !== payload.folder.id),
    },
  };
}

function editFolder(state, payload) {
  const { folders } = state.folderList;

  return {
    ...state,
    folderList: {
      ...state.folderList,
      folders: folders.map((folder) => {
        if (folder.id === payload.folder.id) {
          return payload.folder;
        }

        return folder;
      }),
    },
  };
}

function removeFile(state, payload) {
  const { files } = state.folderList;

  return {
    ...state,
    folderList: {
      ...state.folderList,
      files: files.filter((file) => file.id !== payload.fileId),
    },
  };
}

function editFile(state, payload) {
  const { files } = state.folderList;

  return {
    ...state,
    folderList: files
      ? {
          ...state.folderList,
          files: files.map((file) => {
            if (file.id === payload.file.id) {
              return payload.file;
            }

            return file;
          }),
        }
      : state.folderList,
    folderUnreadFiles: state.folderUnreadFiles.filter(
      (file) => !(payload.file.hasRead && file.id === payload.file.id),
    ),
  };
}

const actions = {
  [ADD_FOLDER]: addFolder,
  [REMOVE_FOLDER]: removeFolder,
  [EDIT_FOLDER]: editFolder,
  [FETCH_FOLDERS_SUCCESS]: fetchFoldersSuccess,
  [REMOVE_FOLDER_FILE_SUCCESS]: removeFile,
  [EDIT_FOLDER_FILE_SUCCESS]: editFile,
  [FETCH_FOLDERS_UNREAD_FILES_REQUEST]: fetchFoldersUnreadFilesRequest,
  [FETCH_FOLDERS_UNREAD_FILES_SUCCESS]: fetchFoldersUnreadFilesSuccess,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
