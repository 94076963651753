import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';

import _each from 'lodash/each';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CompanyImageInput from '../../../common/components/upload/CompanyImageInput';
import ImageInput from '../../../common/components/upload/ImageInput';
import CustomIconButton from '../../../common/components/CustomIconButton';
import Form from '../../../common/components/forms/Form';
import MaterialInput from '../../../common/components/forms/MaterialInput';
import Loader from '../../../common/components/Loader';
import { editSetting } from '../../actions/settings';
import { addSimpleError } from '../../actions/errors';
import { attachAdvertImage, fetchAdvertImage } from '../../actions/fileUpload';
import validators from '../../../utils/validators';

import { hasPermission } from '../../decorators/permissions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';

import defaultAdvert from '../../../images/defaultAdvert.png';
import './styles.scss';

const customWidth = 445;
const customHeight = 190;

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    maxWidth: 600,
  },
  uploadContainer: {
    position: 'relative',
    overflow: 'hidden'
  },
  advertButton: {
    background: 'linear-gradient(#00459b, #41A3F3)',
    borderRadius: 0,
    color: 'white',
    paddingTop: '10px'
  },
  bigAvatar: {
    width: '100%',
    height: '100%',
  },
  smallAvatar: {
    width: '100%',
    maxWidth: '170px',
    maxHeight: '100px',
  },
  iconButton: {
    colorPrimary: {
      backgroundColor: 'white',
      color: 'white'
    }
  },
  textField: {
    padding: '1px'
  },
});

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    file: state.advertImage.file,
    fileRequest: state.advertImage.fileRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    attachAdvertImage: (fileData, id) => dispatch(attachAdvertImage(fileData, id)),
    fetchAdvertImage: (id) => dispatch(fetchAdvertImage(id)),
    editSetting: (setting, errorCallback) => dispatch(editSetting(setting, errorCallback)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class AdvertBox extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    fetchAdvertImage: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    attachAdvertImage: PropTypes.func.isRequired,
    settings: PropTypes.array.isRequired,
    editSetting: PropTypes.func.isRequired,
    fileRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = {
      editOpen: false,
      infoSuccessfullySaved: false,
    };
  }

  componentDidMount() {
    const { settings } = this.props;
    const advertImage = _find(settings, { name: 'advert_image' });

    if (advertImage) {
      this.props.fetchAdvertImage(advertImage.value);
    }
  }

   onChange = (formData) => {
     _each(formData, ({ value }, key) => {
       this.setState({
         [key]: value,
       });
     });
   };

  handleEditSettingError = () => {
    if (this.state.infoSuccessfullySaved) {
      this.setState({
        infoSuccessfullySaved: false,
      });
    }
  };

  onFormValidated = async (isFormValid) => {
    const { settings, editSetting } = this.props;
    const { advertText, advertUrl } = this.state;

    if (isFormValid && this.formData) {
      const advertButtonTextPromise = editSetting({
        id: _find(settings, { name: 'advert_button_text' }).id,
        textValue: advertText,
      }, this.handleEditSettingError);

      const advertButtonUrlPromise = editSetting({
        id: _find(settings, { name: 'advert_button_url' }).id,
        textValue: advertUrl,
      }, this.handleEditSettingError);

      await advertButtonTextPromise;
      await advertButtonUrlPromise;
    }

    this.handleDialogClose();
  };

  setProfileImage = () => {
    const { file } = this.props;

    return (file && !_isEmpty(file.path)) ? file.path : defaultAdvert;
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  attachFile = (file) => {
    const { attachAdvertImage, settings } = this.props;
    const advertImage = _find(settings, { name: 'advert_image' });

    return attachAdvertImage(file, advertImage.value);
  };

  renderManageButtons(profilePicture) {
    const { classes, addSimpleError } = this.props;

    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <div className={classes.uploadContainer}>
            <CustomIconButton>
              <ImageInput
                attachFile={this.attachFile}
                addSimpleError={addSimpleError}
                required
              />
              <Icon color="primary">file_upload</Icon>
            </CustomIconButton>
            {this.removeFileIcon(profilePicture)}
          </div>
        </div>
      </div>
    );
  }

  removeFileIcon() {
    const { file, settings, attachAdvertImage } = this.props;
    const advertImage = _find(settings, { name: 'advert_image' });

    if (!file || _isEmpty(file.path)) {
      return null;
    }

    return (
      <IconButton
        onClick={() => attachAdvertImage({}, advertImage.value)}
      >
        <Icon color="primary">close</Icon>
      </IconButton>
    );
  }

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    return _find(settings, { name: fieldName }).textValue;
  }


  dialogContent = (classes, title, t) => {
    const { addSimpleError } = this.props;

    return (
      <div>
        <DialogTitle id="add-license-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="row justify-content-center">
                <img
                  src={this.setProfileImage()}
                  className={classes.smallAvatar}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="row justify-content-center">
                <CustomIconButton>
                  <CompanyImageInput
                    attachFile={this.attachFile}
                    addSimpleError={addSimpleError}
                    customHeight={customHeight}
                    customWidth={customWidth}
                    errorText={t('error:imageAdvertDimentionsInvalid')}
                    required
                  />
                  <Icon color="primary">file_upload</Icon>
                </CustomIconButton>
                {this.removeFileIcon()}
              </div>
            </div>
          </div>
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <div>
              <MaterialInput
                className={classes.textField}
                margin="dense"
                name={'advertUrl'}
                label={t('input:advertUrl')}
                fullWidth
                validators={[
                  new validators.IsRequired(t),
                  new validators.AdvertUrl(t)
                ]}
                defaultValue={this.setDefaultValue('advert_button_url')}
              />
            </div>
            <div>
              <MaterialInput
                className={classes.textField}
                margin="dense"
                name={'advertText'}
                label={t('input:advertText')}
                fullWidth
                validators={[
                  new validators.IsRequired(t)
                ]}
                defaultValue={this.setDefaultValue('advert_button_text')}
              />
            </div>
          </Form>
        </DialogContent>
      </div>
    );
  };

  handleDialogClose = () => {
    this.setState({
      editOpen: false,
    });
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  handleEditOpen = () => {
    this.setState({
      editOpen: true
    });
  };

  rednderEditIcon = () => {
    const { classes } = this.props;

    if (!hasPermission(PERMISSIONS.updateAdvert)) {
      return null;
    }

    return (
      <IconButton
        className={classNames(classes.root, 'edit-place icon-background')}
        color="primary"
        onClick={() => this.handleEditOpen()}
      >
        <Icon color="primary">
          mode_edit
        </Icon>
      </IconButton>
    );
  }

  render() {
    const { classes, t, fileRequest } = this.props;
    const { editOpen } = this.state;

    if (fileRequest) {
      return <Loader />;
    }

    return (
      <div className="pb-2 px-2 dashboard pt-3">
        <div className="row align-items-center">
          <div className="col elearning-container dashboard-elearning">
            {this.rednderEditIcon()}
            <img
              src={this.setProfileImage()}
              className={classNames(classes.bigAvatar, 'dashboard-elearning')}
            />
          </div>
        </div>
        <Button
          variant="contained"
          className={classes.advertButton}
          onClick={() => window.open(this.setDefaultValue('advert_button_url'), '_blank')}
          fullWidth
        >
          {this.setDefaultValue('advert_button_text')}
        </Button>
        <Dialog
          PaperProps={{
            classes: { root: classes.root }
          }}
          open={editOpen}
          onClose={this.handleDialogClose}
          fullWidth
        >
          {this.dialogContent(classes, t('dashboard:addAdvertisementDialogTitle'), t)}
          {this.dialogActions(t)}
        </Dialog>
      </div>
    );
  }
}

