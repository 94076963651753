import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = () => ({
  root: {
    flexShrink: 0,
    marginLeft: 10
  },
  pageSelect: {
    marginLeft: 30,
    textAlign: 'center'
  }
});

@withStyles(styles)
export default class CustomTablePagination extends Component {
  constructor(props) {
    super(props);
  }

  handleFirstPageButtonClick = (event) => {
    event.stopPropagation();

    this.props.onChangePage(0);
  };

  handleBackButtonClick = (event) => {
    event.stopPropagation();
    const { page } = this.props;

    this.props.onChangePage(page - 1);
  };

  handleNextButtonClick = (event) => {
    event.stopPropagation();
    const { page } = this.props;

    this.props.onChangePage(page + 1);
  };

  handleLastPageButtonClick = (event) => {
    event.stopPropagation();
    const { count, rowsPerPage } = this.props;

    this.props.onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  handlePage = (event) => {
    this.props.onChangePage(event.target.value);
  }

  render() {
    const { count, page, rowsPerPage, classes } = this.props;
    const pageRows = [];

    for (let i = 0; i < Math.ceil(count / rowsPerPage); i ++) {
      pageRows.push(
        <MenuItem key={i} value={i}>
          <em>{i + 1}</em>
        </MenuItem>
      );
    }

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
        <Select
          className={classes.pageSelect}
          value={page}
          onChange={this.handlePage}
        >
          {pageRows}
        </Select>
      </div>
    );
  }
}

CustomTablePagination.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired
};
