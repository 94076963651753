import axios from 'axios';
import { addError, addSimpleError } from './errors';
import { URLS_RAIL as URLS } from '../../constants';
import { urlJoin } from '../../utils';
import _get from 'lodash/get';

export const FETCH_USER_SETTINGS_REQUEST = 'FETCH_USER_SETTINGS_REQUEST';
export const FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS';
export const FETCH_USER_SETTINGS_FAILURE = 'FETCH_USER_SETTINGS_FAILURE';
export const EDIT_USER_SETTINGS = 'EDIT_USER_SETTINGS';
export const RESET_USER_SETTINGS_SUCCESS = 'RESET_USER_SETTINGS_SUCCESS';


export function fetchUserSettingsRequest() {
  return {
    type: FETCH_USER_SETTINGS_REQUEST
  };
}

export function fetchUserSettingsSuccess(userSettings) {
  return {
    type: FETCH_USER_SETTINGS_SUCCESS,
    payload: {
      userSettings
    }
  };
}

export function editUserSettingsSuccess(userSettings) {
  return {
    type: EDIT_USER_SETTINGS,
    payload: { userSettings }
  };
}

export function fetchUserSettingsFailure() {
  return {
    type: FETCH_USER_SETTINGS_FAILURE
  };
}

export function resetUserSettingsSuccess() {
  return {
    type: RESET_USER_SETTINGS_SUCCESS,
  };
}

export function fetchUserSettings(userId) {
  return (dispatch) => {
    dispatch(fetchUserSettingsRequest());

    return axios.get(urlJoin(URLS.userSettings, userId))
      .then(({ data }) => {
        dispatch(fetchUserSettingsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchUserSettingsFailure());
        dispatch(addError(`Error during user settings fetching ${error.message}`, error));
      });
  };
}

export function editUserSettings(userId, userSettings) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.userSettingsUpdate, userId), userSettings)
      .then(({ data }) => {
        dispatch(editUserSettingsSuccess(data));
      })
      .catch((error) => {
        const responseData = _get(error, 'response.data', '');

        if (typeof responseData === 'string' && responseData.includes('ValidationError')) {
          dispatch(addSimpleError(('Flight experience validation error')));
        } else {
          dispatch(addError(`Error during user settings editing ${error.message}`, error));
        }
      });
  };
}

export function resetSaveUserSettingsSuccess() {
  return (dispatch) => dispatch(resetUserSettingsSuccess());
}
