import {
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_FAILURE,
  ADD_GROUP,
  EDIT_GROUP,
  REMOVE_GROUP,
} from '../actions/groups';

const INITIAL_STATE = {
  groupList: [],
  groupRequest: false,
};

function fetchGroupsSuccess(state, payload) {
  return {
    ...state,
    groupList: payload.groups.map((group) => ({ ...group, tag: 'schoolgroup_set' })),
    groupRequest: false,
  };
}

function fetchGroupsRequest(state) {
  return {
    ...state,
    groupRequest: true,
  };
}

function fetchGroupsFailure(state) {
  return {
    ...state,
    groupRequest: false,
  };
}

function addGroup(state, payload) {
  return {
    ...state,
    groupList: [
      ...state.groupList,
      payload.group
    ]
  };
}

function editGroup(state, payload) {
  return {
    ...state,
    groupList: state.groupList.map((group) => {
      if (group.id === payload.group.id) {
        return payload.group;
      }

      return group;
    })
  };
}

function removeGroup(state, payload) {
  return {
    ...state,
    groupList: state.groupList.filter((group) => group !== payload.group)
  };
}

const actions = {
  [FETCH_GROUPS_SUCCESS]: fetchGroupsSuccess,
  [FETCH_GROUPS_REQUEST]: fetchGroupsRequest,
  [FETCH_GROUPS_FAILURE]: fetchGroupsFailure,
  [ADD_GROUP]: addGroup,
  [EDIT_GROUP]: editGroup,
  [REMOVE_GROUP]: removeGroup,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
