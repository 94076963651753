import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

export default class MaterialSelect extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    disabled: PropTypes.bool.isRequired,
    validationError: PropTypes.array,
    helperText: PropTypes.string,
    validators: PropTypes.any,
    forceInitialValidation: PropTypes.any,
    options: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.array.isRequired,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.any.isRequired
      })
    ]),
    inputValidated: PropTypes.oneOf([
      PropTypes.function,
      PropTypes.undefined
    ]),
    registerInput: PropTypes.oneOf([
      PropTypes.function,
      PropTypes.undefined
    ]),
    stateValue: PropTypes.any
  };

  static defaultProps = {
    validators: [],
    type: 'text',
    defaultValue: null,
    forceInitialValidation: false,
    disabled: false,
    error: false
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue ? `${props.defaultValue}` : ''
    };
  }


  async componentDidMount() {
    this.props.registerInput(
      this.props.name,
      this.input.value,
      this.validateAndPropagateValue
    );

    if (this.props.forceInitialValidation) {
      const errors = await this.validate(this.props.defaultValue);

      this.props.inputValidated(this.props.name, this.input.value, {
        isValid: _isEmpty(errors),
        errors,
        disabled: this.props.disabled
      });
    }
  }

  validateAndPropagateValue = async (event) => {
    const currentValue = event ? event.target.value : this.input.node.value;
    const errors = await this.validate(currentValue);
    const isValid = this.props.disabled || _isEmpty(errors);

    this.setState({ value: currentValue });

    this.props.inputValidated(this.props.name, currentValue, {
      isValid,
      errors,
      disabled: this.props.disabled
    });
  };

  validate = async (value) => {
    const allErrors = [];

    for (const validator of this.props.validators) {
      const isValid = await validator.isValid(value);

      if (!isValid) {
        allErrors.push(validator.getValidationMessage());
      }
    }

    return allErrors;
  };

  getOptions() {
    const { options } = this.props;

    return options.map((option, index) => {
      return typeof option === 'string' ?
        <MenuItem key={index} value={option}>{option}</MenuItem> :
        <MenuItem key={index} value={`${option.value}`}>{option.label}</MenuItem>;
    });
  }

  render() {
    const { label, disabled, validationError, helperText, stateValue, ...rest } = this.props;

    delete rest.defaultValue;
    delete rest.forceInitialValidation;
    delete rest.inputValidated;
    delete rest.registerInput;
    delete rest.error;

    const errorLabel =
      !disabled && validationError ? validationError.join('; ') : helperText;

    const error = !disabled && !_isEmpty(validationError);

    if (typeof(stateValue) !== 'undefined' && stateValue !== null && String(this.state.value) !== String(stateValue)) {
      setImmediate(() => {
        if (this.input) {
          this.setState({ value: stateValue });
        }
      });
    }

    return (
      <FormControl
        error={error}
        disabled={disabled}
        fullWidth
        margin="dense"
      >
        <InputLabel htmlFor="">{label}</InputLabel>
        <Select
          {...rest}
          value={this.state.value}
          inputRef={(input) => {
            this.input = input;
          }}
          onChange={this.validateAndPropagateValue}
        >
          <MenuItem value="">
            <em>---</em>
          </MenuItem>
          {
            this.getOptions()
          }
        </Select>
        <FormHelperText>{errorLabel}</FormHelperText>
      </FormControl>
    );
  }
}
