import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isNil from 'lodash/isNil';

import RemoveDialog from '../../../../common/components/RemoveDialog';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';

import Loader from '../../../../common/components/Loader';
import TabsView from './TabsView';

import { fetchAssignedTests, removeAssignedTest, editAssignedTest } from '../../../actions/assignedTests';

const mapStateToProps = (state) => {
  return {
    assignedTestList: state.assignedTests.assignedTestList,
    assignedTestRequest: state.assignedTests.assignedTestRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAssignedTests: (searchData) => dispatch(fetchAssignedTests(searchData)),
    editAssignedTest: (id, testData) => dispatch(editAssignedTest(id, testData)),
    removeAssignedTest: (test) => dispatch(removeAssignedTest(test)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class TestsList extends Component {
  static propTypes = {
    userId: PropTypes.number,
    isUserProfile: PropTypes.bool,
    fetchAssignedTests: PropTypes.func.isRequired,
    assignedTestsRequest: PropTypes.bool,
    assignedTestList: PropTypes.array,
    removeAssignedTest: PropTypes.func.isRequired,
    editAssignedTest: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: '',
      userId: ''
    },
    removeDialogOpen: false,
    removeTest: null,
  };

  componentDidMount() {
    const { columnToSort, sortAsc, search } = this.state.searchSettings;
    const { userId } = this.props;

    if (userId) {
      const searchSettings = {
        columnToSort,
        sortAsc,
        search,
        userId
      };

      this.setState({ searchSettings });

      return this.props.fetchAssignedTests(searchSettings);
    }

    return this.props.fetchAssignedTests();
  }

  removeTest = () => {
    this.props.removeAssignedTest(this.state.removeTest);
    this.closeRemoveDialog();
  };

  openRemoveDialog = (test) => {
    this.setState({
      removeDialogOpen: true,
      removeTest: test,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeTest: null,
    });
  };

  handleSearch = (search) => {
    const { columnToSort, sortAsc, userId } = this.state.searchSettings;

    const searchData = {
      columnToSort,
      sortAsc,
      search,
      userId
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchAssignedTests(searchData);
  };

  handleSort = (columnName) => {
    const { search, sortAsc, userId } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
      userId
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchAssignedTests(searchData);
  };

  handleActivateTest = (testId) => {
    this.props.editAssignedTest(testId, { isActive: true });
  };

  renderTabsView(t) {
    const { search, sortAsc, columnToSort } = this.state.searchSettings;
    const { assignedTestList } = this.props;

    return (
      <div>
        <TabsView
          assignedTestList={assignedTestList}
          handleSort={this.handleSort}
          handleSearch={this.handleSearch}
          sortAsc={sortAsc}
          columnToSort={columnToSort}
          search={search}
          openRemoveDialog={this.openRemoveDialog}
          handleActivateTest={this.handleActivateTest}
        />
        <Dialog
          open={this.state.removeDialogOpen}
          onClose={this.closeRemoveDialog}
          aria-labelledby="remove-dialog"
          fullWidth
        >
          <RemoveDialog
            message={t('test:removeTest')}
            closeRemoveDialog={this.closeRemoveDialog}
            dialogTitle={t('test:removeTestDialogTitle')}
            removeFunction={this.removeTest}
          />
        </Dialog>
      </div>
    );
  }

  renderHeader = (t) => {
    const { isUserProfile } = this.props;

    if (!isUserProfile) {
      return (
        <header>
          <span>
            <Icon color="primary">
              list_add_check
            </Icon>
            <h1>
              {t('test:name')}
              <span className="h1-subheader"> /{t('test:examListSection')}</span>
            </h1>
          </span>
          <p>{t('test:description')}</p>
        </header>
      );
    }

    return null;
  }
  render() {
    const { assignedTestList, t } = this.props;

    if (_isNil(assignedTestList)) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-lg-8 col-sm-12 section-title pb-5">
          {this.renderHeader(t)}
        </div>
        <div className="col-sm-12">
          {this.renderTabsView(t)}
        </div>
      </div>
    );
  }
}
