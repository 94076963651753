import { URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { urlJoin } from '../../utils';

export const FETCH_EMERGENCY_CONTACT_LIST_SUCCESS =
  'FETCH_EMERGENCY_CONTACT_LIST_SUCCESS';
export const ADD_EMERGENCY_CONTACT = 'ADD_EMERGENCY_CONTACT';
export const EDIT_EMERGENCY_CONTACT = 'EDIT_EMERGENCY_CONTACT';
export const REMOVE_EMERGENCY_CONTACT = 'REMOVE_EMERGENCY_CONTACT';
export const CLEAN_EMERGENCY_CONTACT_DATA = 'CLEAN_EMERGENCY_CONTACT_DATA';
export const FETCH_EMERGENCY_CONTACT_LIST_REQUEST =
  'FETCH_EMERGENCY_CONTACT_LIST_REQUEST';
export const FETCH_EMERGENCY_CONTACT_LIST_FAILURE =
  'FETCH_EMERGENCY_CONTACT_LIST_FAILURE';

export function fetchEmergencyContactListSuccess(emergencyContactList) {
  return {
    type: FETCH_EMERGENCY_CONTACT_LIST_SUCCESS,
    payload: {
      emergencyContactList,
    },
  };
}

export function addEmergencyContactSuccess(emergencyContact) {
  return {
    type: ADD_EMERGENCY_CONTACT,
    payload: {
      emergencyContact,
    },
  };
}

export function editEmergencyContactSuccess(emergencyContact) {
  return {
    type: EDIT_EMERGENCY_CONTACT,
    payload: {
      emergencyContact,
    },
  };
}

export function fetchEmergencyContactListRequest() {
  return {
    type: FETCH_EMERGENCY_CONTACT_LIST_REQUEST,
  };
}

export function fetchEmergencyContactListFailure() {
  return {
    type: FETCH_EMERGENCY_CONTACT_LIST_FAILURE,
  };
}

export function removeEmergencyContactSuccess(emergencyContact) {
  return {
    type: REMOVE_EMERGENCY_CONTACT,
    payload: {
      emergencyContact,
    },
  };
}

export function addEmergencyContact(userId, emergencyContact) {
  return (dispatch) => {
    return axios
      .post(
        urlJoin(URLS.emergencyContact, `?userId=${userId}`),
        emergencyContact,
      )
      .then(({ data }) => dispatch(addEmergencyContactSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during emergency contact adding ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function removeEmergencyContact(userId, emergencyContact) {
  return (dispatch) => {
    return axios
      .delete(
        urlJoin(URLS.emergencyContact, emergencyContact.id) +
          `?userId=${userId}`,
        emergencyContact,
      )
      .then(() => dispatch(removeEmergencyContactSuccess(emergencyContact)))
      .catch((error) => {
        dispatch(addError(`Error during deletion ${error.message}`, error));
      });
  };
}

export function editEmergencyContact(userId, emergencyContact) {
  return (dispatch) => {
    return axios
      .patch(
        urlJoin(URLS.emergencyContact, emergencyContact.id) +
          `?userId=${userId}`,
        emergencyContact,
      )
      .then(({ data }) => dispatch(editEmergencyContactSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during editing ${error.message}`, error));
      });
  };
}

export function fetchEmergencyContactList(userId) {
  return (dispatch) => {
    dispatch(fetchEmergencyContactListRequest());

    return axios
      .get(urlJoin(URLS.emergencyContact, `?userId=${userId}`))
      .then(({ data }) => {
        dispatch(fetchEmergencyContactListSuccess(data.results));
      })
      .catch((error) => {
        dispatch(fetchEmergencyContactListFailure());
        dispatch(
          addError(
            `Error during emergency contact fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}
