import {
  ADD_WAREHOUSE,
  CLEAN_WAREHOUSE_DATA,
  EDIT_WAREHOUSE,
  FETCH_WAREHOUSES_PAGINATION_REQUEST,
  FETCH_WAREHOUSES_PAGINATION_SUCCESS,
  FETCH_WAREHOUSES_REQUEST,
  FETCH_WAREHOUSES_SUCCESS,
  FETCH_WAREHOUSE_REQUEST,
  FETCH_WAREHOUSE_SUCCESS,
  REMOVE_WAREHOUSE,
} from '../actions/warehouse';

const INITIAL_STATE = {
  warehouse: {},
  warehouseList: [],
  warehouseRequest: false,
  warehousePaginationRequest: false,
  warehouseListPaginationNext: null,
};

function fetchWarehousesSuccess(state, payload) {
  return {
    ...state,
    warehouseList: payload.warehouse.results,
    warehouseRequest: false,
    warehousePaginationRequest: false,
    warehouseListPaginationNext: payload.warehouse.next,
  };
}

function fetchWarehousesPaginationSuccess(state, payload) {
  return {
    ...state,
    warehouseList: [...state.warehouseList, ...payload.warehouse.results],
    warehouseRequest: false,
    warehousePaginationRequest: false,
    warehouseListPaginationNext: payload.warehouse.next,
  };
}

function fetchWarehousesRequest(state) {
  return {
    ...state,
    warehouseRequest: true,
    warehousePaginationRequest: false,
  };
}

function fetchWarehousesPaginationRequest(state) {
  return {
    ...state,
    warehouseRequest: true,
    warehousePaginationRequest: false,
  };
}

function fetchWarehouseSuccess(state, payload) {
  return {
    ...state,
    warehouse: payload.warehouse,
    warehouseRequest: false,
  };
}

function fetchWarehouseRequest(state) {
  return {
    ...state,
    warehouseRequest: true,
  };
}

function addWarehouse(state, payload) {
  return {
    ...state,
    warehouseList: [...state.warehouseList, payload.warehouse],
  };
}

function editWarehouse(state, payload) {
  return {
    ...state,
    warehouseList: state.warehouseList.map((warehouse) => {
      if (warehouse.id === payload.warehouse.id) {
        return payload.warehouse;
      }

      return warehouse;
    }),
  };
}

function removeWarehouse(state, payload) {
  return {
    ...state,
    warehouseList: state.warehouseList.filter(
      (warehouse) => warehouse.id !== payload.warehouseId,
    ),
  };
}

function cleanWarehouse(state) {
  return {
    ...state,
    warehouse: {},
  };
}

const actions = {
  [FETCH_WAREHOUSES_SUCCESS]: fetchWarehousesSuccess,
  [FETCH_WAREHOUSES_REQUEST]: fetchWarehousesRequest,
  [FETCH_WAREHOUSE_SUCCESS]: fetchWarehouseSuccess,
  [FETCH_WAREHOUSES_PAGINATION_REQUEST]: fetchWarehousesPaginationRequest,
  [FETCH_WAREHOUSES_PAGINATION_SUCCESS]: fetchWarehousesPaginationSuccess,
  [FETCH_WAREHOUSE_REQUEST]: fetchWarehouseRequest,
  [ADD_WAREHOUSE]: addWarehouse,
  [EDIT_WAREHOUSE]: editWarehouse,
  [REMOVE_WAREHOUSE]: removeWarehouse,
  [CLEAN_WAREHOUSE_DATA]: cleanWarehouse,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
