import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import PartNew from '../PartNew';
import Loader from '../../../../common/components/Loader';
import { fetchPart } from '../../../actions/parts';

const mapStateToProps = (state) => {
  return {
    part: state.parts.part,
    partsRequest: state.parts.partsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchPart: (id) => dispatch(fetchPart(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossPartEditView extends Component {
  static propTypes = {
    part: PropTypes.object.isRequired,
    fetchPart: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    partsRequest: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { fetchPart, match } = this.props;

    fetchPart(match.params.id);
  }

  render() {
    const { part, partsRequest, match } = this.props;

    if (partsRequest && _isEmpty(part)) {
      return <Loader />;
    }

    return (
      <PartNew
        part={part}
        editMode
        match={match}
      />
    );
  }
}
