import React, { Component } from 'react';
import { isAdmin, isSuperAdmin } from '../../../../utils';

import AssignReportManager from '../AssignReportManager';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import ReportNew from '../ReportNew';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { editReport } from '../../../actions/reports';
import { fetchReport } from '../../../actions/reports';
import { fetchUsers } from '../../../actions/users';
import { isManager } from '../../../../utils';
import userService from '../../../../utils/userService';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    users: state.users.userList,
    report: state.reports.report,
    reportRequest: state.reports.reportDetailsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    fetchReport: (id, userId) => dispatch(fetchReport(id, userId)),
    editReport: (report, skipRedirect) =>
      dispatch(editReport(report, [], skipRedirect)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class ReportDetails extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    editReport: PropTypes.func.isRequired,
    fetchReport: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
    reportRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
  };

  state = {
    openAssignUserDialog: false,
    hasRead: false,
  };

  componentDidMount() {
    const { fetchReport, fetchUsers, match } = this.props;
    const userId = userService.getCurrentUser().id;

    fetchReport(match.params.id, userId);
    fetchUsers();
  }

  closeReport() {
    const { report, editReport, location } = this.props;

    const query = new URLSearchParams(location.search);

    editReport(
      {
        id: report.id,
        status: 'CLOSED',
      },
      query.get('buri'),
    );
  }

  hasPermissionsToAssignUser() {
    const user = userService.getCurrentUser();

    const { users } = this.props;

    return users.filter((u) => u.id === user.id && isManager(u));
  }

  isEditAllowed() {
    const { report } = this.props;

    const status = _get(report, 'status', '');

    const user = userService.getCurrentUser();

    return (
      report.user &&
      ((status.startsWith('CREATED') && report.user.id === user.id) ||
        (status.startsWith('ASSIGNED') &&
          report.assignee &&
          report.assignee.id === user.id))
    );
  }

  openAssignUserDialog() {
    this.setState({
      openAssignUserDialog: true,
    });
  }

  onCloseAssignReportDialog() {
    this.setState({
      openAssignUserDialog: false,
    });
  }

  readIt() {
    const { editReport, report } = this.props;
    const { hasRead } = this.state;

    const status = _get(report, 'status', '');

    const user = userService.getCurrentUser();

    const isUnreadByUser =
      ['ASSIGNED'].includes(status) &&
      report.assignee &&
      report.assignee.id === user.id;
    const isUnreadByMgr =
      ['SENT', 'DONE'].includes(status) && this.hasPermissionsToAssignUser();

    if (!hasRead && (isUnreadByUser || isUnreadByMgr)) {
      editReport(
        {
          id: report.id,
          status: status + '_READ',
        },
        '',
      );

      this.setState({ hasRead: true });
    }
  }

  renderComplete() {
    const { report, t, users } = this.props;

    const status = _get(report, 'status', '');

    if (!status.startsWith('DONE')) {
      return null;
    }

    const user = userService.getCurrentUser();

    const userModel = users.find((u) => user.id === u.id) || {};

    const canCloseReport = isAdmin(userModel) || isSuperAdmin(userModel);

    return this.hasPermissionsToAssignUser() || canCloseReport ? (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to="/report/">
            <Button variant="raised">{t('buttonBack')}</Button>
          </Link>
        </div>
        <div className="col-auto">
          <Button
            variant="raised"
            color="primary"
            onClick={() => this.closeReport()}
          >
            {t('Complete')}
          </Button>
        </div>
      </div>
    ) : (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to="/report/">
            <Button variant="raised">{t('buttonBack')}</Button>
          </Link>
        </div>
      </div>
    );
  }

  renderAssignUser() {
    const { report, t, users } = this.props;

    const status = _get(report, 'status', '');

    if (!status.startsWith('SENT')) {
      return null;
    }

    const user = userService.getCurrentUser();

    const userModel = users.find((u) => user.id === u.id) || {};

    const canCloseReport = isAdmin(userModel) || isSuperAdmin(userModel);

    return this.hasPermissionsToAssignUser() || canCloseReport ? (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to="/report/">
            <Button variant="raised">{t('buttonBack')}</Button>
          </Link>
        </div>
        {this.renderAssignUserDialog()}
        {!canCloseReport ? (
          ''
        ) : (
          <div className="col-auto">
            <Button
              color="secondary"
              variant="raised"
              onClick={() => this.closeReport()}
            >
              {t('Close report')}
            </Button>
          </div>
        )}
      </div>
    ) : null;
  }

  renderAssignUserDialog() {
    const { report, t } = this.props;
    const { openAssignUserDialog } = this.state;

    return (
      <div className="col-auto">
        <Button
          color="primary"
          variant="raised"
          onClick={() => this.openAssignUserDialog()}
        >
          {t('assign to user')}
        </Button>
        <AssignReportManager
          report={report}
          open={openAssignUserDialog}
          onCloseDialog={() => this.onCloseAssignReportDialog()}
        />
      </div>
    );
  }

  render() {
    const { report, reportRequest } = this.props;

    if (reportRequest) {
      return <Loader />;
    }

    this.readIt();

    const query = new URLSearchParams(this.props.location.search);

    return (
      <>
        <ReportNew
          report={report}
          preview
          canEdit={this.isEditAllowed()}
          redirectUrl={query.get('buri')}
          closeReport={() => this.closeReport()}
        />
        {this.renderAssignUser()}
        {this.renderComplete()}
      </>
    );
  }
}
