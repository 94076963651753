import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';

import Table from '../../../../common/components/Table';
import CustomFilter from '../../../../common/components/CustomFilter';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
});

const tableHeader = [
  {
    prop: 'id',
    sort: true,
    profileView: false
  },
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'category',
    sort: false,
    profileView: false
  },
  {
    prop: 'files',
    sort: false,
    profileView: false
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true
  }
];

@withNamespaces()
@withStyles(styles)
export default class QuestionList extends Component {
  static propTypes = {
    questionList: PropTypes.array,
    classes: PropTypes.object.isRequired,
    sortAsc: PropTypes.bool.isRequired,
    handleSort: PropTypes.func.isRequired,
    columnToSort: PropTypes.string,
    search: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleFilter: PropTypes.func.isRequired,
    filter: PropTypes.array.isRequired,
    questionCategoryList: PropTypes.array,
    openRemoveDialog: PropTypes.func.isRequired,
    filterIsUsed: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: this.props.search,
    };
  }

  renderFiles(files) {
    return files && files.map((file) => {
      return (
        <Chip
          className={'mr-1'}
          label={file.name}
        />
      );
    });
  }

  renderManageIcons(question) {
    return (
      <div className="icons-display-wrapper">
        <Link to={`/question_bank/edit/${question.id}`}><IconButton><Icon color="primary">mode_edit</Icon></IconButton></Link>
        <IconButton onClick={() => this.props.openRemoveDialog(question)}><Icon color="primary">delete</Icon></IconButton>
      </div>
    );
  }

  renderQuestionLink(id, prop) {
    return (
      <Link className="table-row-link" to={`/question_bank/details/${id}`}>{prop}</Link>
    );
  }

  getQuestionCategories(question) {
    const questions = _isObject(question.categories) ? _reduce(question.categories, (memo, category) => {
      return `${memo}${category.name}, `;
    }, '') : question.categories;

    return (
      <Link className="table-row-link" to={`/question_bank/details/${question.id}`}>{questions.substring(0, questions.length - 2)}</Link>
    );
  }

  getQuestionList() {
    const { questionList } = this.props;
    const parsedQuestion = questionList.map((question) => ({
      ...question,
      questionId: this.renderQuestionLink(question.id, question.id),
      id: question.id,
      name: this.renderQuestionLink(question.id, question.name),
      category: this.getQuestionCategories(question),
      icons: this.renderManageIcons(question),
      files: this.renderFiles(question.files)
    }));

    return parsedQuestion;
  }

  getCategoryFilters = () => {
    const { questionCategoryList, handleFilter, filter, filterIsUsed } = this.props;

    return (
      <CustomFilter
        groups={questionCategoryList || []}
        roles={[]}
        isFilterUsed={filterIsUsed}
        handleFilter={handleFilter}
        filterSettings={filter}
      />
    );
  };

  render() {
    const { sortAsc, handleSort, handleSearch, search, columnToSort = '', t } = this.props;

    return (
      <Table
        data={this.getQuestionList()}
        header={tableHeader}
        handleSort={handleSort}
        sortAsc={sortAsc}
        columnToSort={columnToSort}
        handleSearch={handleSearch}
        search={search}
        getUserFilters={this.getCategoryFilters}
        searchLabel={t('input:searchLabel')}
      />
    );
  }
}

