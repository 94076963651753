import HttpStatus from 'http-status-codes';
import { getCookie } from './cookies';

function fileUpload(file, method, path, { onFinished, onFailure, onProgress }) {
  const xhr = new XMLHttpRequest();

  xhr.open(method, path, true);

  xhr.onload = () => {
    onProgress(100);

    if (xhr.status === HttpStatus.OK) {
      const response = JSON.parse(xhr.responseText);

      onFinished(response);
    } else {
      onFailure(xhr.status, xhr.statusText, xhr.responseText);
    }
  };

  xhr.upload.onprogress = (event) => {
    const percent = Math.round(1000 * (event.loaded / event.total)) / 10;

    onProgress(percent);
  };

  xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
  xhr.setRequestHeader(
    'Authorization',
    `token ${localStorage.getItem('token')}`,
  );

  const fd = new FormData();

  fd.append('filename', file.name);
  fd.append('file', file);
  xhr.send(fd);
}

function fileUploadPromise(file, method, path, onProgress) {
  // eslint-disable-next-line promise/avoid-new
  return new Promise((resolve, reject) => {
    fileUpload(file, method, path, {
      onFinished: resolve,
      onFailure: reject,
      onProgress,
    });
  });
}

export { fileUpload, fileUploadPromise };
