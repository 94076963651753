import { FETCH_PERSONAL_FILES, EDIT_PERSONAL_FILE, ADD_PERSONAL_FILE, REMOVE_PERSONAL_FILE }
  from '../actions/personalFiles';

import { REMOVE_PERSONAL_FILE_FILE_SUCCESS } from '../actions/fileUpload';

const INITIAL_STATE = {
  personalFileList: [],
};

function fetchPersonalFiles(state, payload) {
  return {
    ...state,
    personalFileList: payload.personalFiles,
  };
}

function editPersonalFile(state, payload) {
  return {
    ...state,
    personalFileList: state.personalFileList.map((personalFile) => {
      if (personalFile.id === payload.personalFile.id) {
        return payload.personalFile;
      }

      return personalFile;
    })
  };
}

function addPersonalFile(state, payload) {
  return {
    ...state,
    personalFileList: [...state.personalFileList, payload.personalFile],
  };
}

function removePersonalFile(state, payload) {
  return {
    ...state,
    personalFileList: state.personalFileList.filter((personalFile) => personalFile !== payload.personalFile),
  };
}

function removePersonalFileFile(state, payload) {
  return {
    ...state,
    personalFileList: state.personalFileList.map((personalFile) => {
      if (personalFile.id === payload.personalFileId) {
        return {
          ...personalFile,
          files: personalFile.files.filter((personalFileFile) => personalFileFile.id !== payload.fileId)
        };
      }

      return personalFile;
    })
  };
}

const actions = {
  [FETCH_PERSONAL_FILES]: fetchPersonalFiles,
  [EDIT_PERSONAL_FILE]: editPersonalFile,
  [ADD_PERSONAL_FILE]: addPersonalFile,
  [REMOVE_PERSONAL_FILE]: removePersonalFile,
  [REMOVE_PERSONAL_FILE_FILE_SUCCESS]: removePersonalFileFile,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunction = actions[action.type];

  if (!actionFunction) {
    return state;
  }

  return actionFunction(state, action.payload);
};
