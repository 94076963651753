import '../styles.scss';

import React, { Component } from 'react';
import {
  addRating,
  addRatingPlannedAction,
  deactivateRating,
  editRating,
  removeRating,
} from '../../../actions/ratings';
import {
  attachRatingFile,
  removeRatingFile,
} from '../../../actions/fileUpload';
import {
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from '../../../../common/components/Loader';
import { MODULES } from '../../../../constants/modules';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RatingsList from './RatingsList';
import { TextField } from '@material-ui/core';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { getDateFormat } from '../../../utils/time';
import { hasModule } from '../../../decorators/modules';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  id: null,
  type: '',
  expiryDate: null,
  conductedDate: null,
  conductedAt: null,
  practice: null,
  aircraftTypeId: null,
};

const mapStateToProps = (state) => {
  return {
    aircraftTypeList: state.engineerAircraftTypes.aircraftTypeList,
    ratingList: state.ratings.ratingList,
    ratingRequest: state.ratings.ratingsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addRating: (rating) => dispatch(addRating(rating)),
    addRatingPlannedAction: (rating) =>
      dispatch(addRatingPlannedAction(rating)),
    removeRating: (rating) => dispatch(removeRating(rating)),
    editRating: (rating) => dispatch(editRating(rating)),
    deactivateRating: (rating) => dispatch(deactivateRating(rating)),
    attachRatingFile: (file, id, userId) =>
      dispatch(attachRatingFile(file, id, userId)),
    removeRatingFile: (fileId, id) => dispatch(removeRatingFile(fileId, id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UserRatings extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    aircraftTypeList: PropTypes.array,
    ratingList: PropTypes.array,
    editMode: PropTypes.bool,
    ratingsRequest: PropTypes.bool,
    addRating: PropTypes.func.isRequired,
    addRatingPlannedAction: PropTypes.func.isRequired,
    removeRating: PropTypes.func.isRequired,
    editRating: PropTypes.func.isRequired,
    deactivateRating: PropTypes.func.isRequired,
    attachRatingFile: PropTypes.func.isRequired,
    removeRatingFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    documentTypeList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (rating) => {
    this.setState({
      editOpen: true,
      aircraftTypeId: _get(rating.aircraftType, 'id', null),
      ...rating,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addRating = () => {
    const {
      type,
      aircraftTypeId,
      expiryDate,
      conductedDate,
      conductedAt,
      practice,
    } = this.state;
    const { userId } = this.props;

    if (!hasModule(MODULES.engineerMode)) {
      if (!_isEmpty(type)) {
        this.props.addRating({
          type,
          userId,
          expiryDate: reformatPickerDateToDB(expiryDate),
        });
        this.handleDialogClose();
      }
    } else {
      this.props.addRating({
        aircraftTypeId,
        userId,
        conductedAt,
        conductedDate: reformatPickerDateToDB(conductedDate),
        expiryDate: reformatPickerDateToDB(expiryDate),
        practice,
      });
      this.handleDialogClose();
    }
  };

  removeRating = (rating) => {
    this.props.removeRating(rating);
  };

  editRating = () => {
    const {
      type,
      id,
      expiryDate,
      conductedDate,
      conductedAt,
      practice,
      aircraftTypeId,
    } = this.state;
    const { userId } = this.props;

    if (!hasModule(MODULES.engineerMode)) {
      if (!_isEmpty(type)) {
        this.props.editRating({
          id,
          userId,
          type,
          expiryDate: reformatPickerDateToDB(expiryDate),
        });
        this.handleDialogClose();
      }
    } else {
      this.props.editRating({
        id,
        aircraftTypeId,
        userId,
        conductedAt,
        conductedDate: reformatPickerDateToDB(conductedDate),
        expiryDate: reformatPickerDateToDB(expiryDate),
        practice,
      });
      this.handleDialogClose();
    }
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value,
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid && this.formData) {
        this.state.addOpen ? this.addRating() : this.editRating();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  attachRatingFile = (file) => (id) => {
    const { attachRatingFile, userId } = this.props;

    attachRatingFile(file, id, userId);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  dialogContent = (classes, title, t) => {
    const {
      type,
      expiryDate,
      conductedAt,
      conductedDate,
      practice,
      aircraftTypeId,
    } = this.state;

    const { aircraftTypeList } = this.props;

    const selectedAircraftType = aircraftTypeList.find(
      (aircraft) => String(aircraft.id) === aircraftTypeId,
    ) || { info: ' ' };

    return (
      <div>
        <DialogTitle id="add-rating-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          {!hasModule(MODULES.engineerMode) ? (
            <div className="row">
              <div className="col-md-6 col-6">
                {this.renderSelect(
                  'type',
                  'type',
                  type,
                  this.handleSelectChange,
                )}
              </div>
              <div className="col-md-6 col-6">
                <DateTimePicker
                  acceptEmptyInput
                  value={reformatPickerDateFromDB(expiryDate)}
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={(date) =>
                    this.setState({ expiryDate: date })
                  }
                  dateFormat={getDateFormat()}
                  name="expiryDate"
                  label={t('input:expiryDate')}
                  fullWidth
                />
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6 col-12">
                  <MaterialSelect
                    id="aircraftType"
                    name="aircraftTypeId"
                    label={t('input:aircraft type')}
                    fullWidth
                    defaultValue={aircraftTypeId}
                    options={aircraftTypeList.map((aircraft) => ({
                      label: aircraft.name,
                      value: aircraft.id,
                    }))}
                    validators={[new validators.IsRequired(t)]}
                  />
                </div>
                <div className="col-md-6 col-12">
                  {selectedAircraftType.id ? (
                    <TextField
                      fullWidth
                      margin="dense"
                      label={t('input:information')}
                      value={selectedAircraftType.info}
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-7 py-1 px-3">
                  <h6>{t('input:practice')}</h6>
                </div>
                <div className="col-xs-5">
                  <RadioGroup
                    name="practice"
                    value={practice}
                    onChange={this.handleChange('practice')}
                    className="flex-row row"
                  >
                    <FormControlLabel
                      key="form-control-key-theoretical"
                      value="theoretical"
                      control={<Radio color="primary" />}
                      label={t('input:theoretical')}
                      className="col-12 col-md-4"
                    />
                    <FormControlLabel
                      key="form-control-key-practical"
                      value="practical"
                      control={<Radio color="primary" />}
                      label={t('input:practical')}
                      className="col-12 col-md-4"
                    />
                    <FormControlLabel
                      key="form-control-key-both"
                      value="both"
                      control={<Radio color="primary" />}
                      label={t('input:both')}
                      className="col-12 col-md-2"
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-12">
                  <MaterialInput
                    margin="dense"
                    name="conductedAt"
                    fullWidth
                    defaultValue={conductedAt}
                    label={t('input:conducted at')}
                    validators={[new validators.IsRequired(t)]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <DateTimePicker
                    acceptEmptyInput
                    value={reformatPickerDateFromDB(conductedDate)}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={(date) =>
                      this.setState({ conductedDate: date })
                    }
                    dateFormat={getDateFormat()}
                    name="conductedDate"
                    label={t('input:conducted date')}
                    fullWidth
                  />
                </div>
                <div className="col-md-6 col-12">
                  <DateTimePicker
                    acceptEmptyInput
                    value={reformatPickerDateFromDB(expiryDate)}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={(date) =>
                      this.setState({ expiryDate: date })
                    }
                    dateFormat={getDateFormat()}
                    name="expiryDate"
                    label={t('input:expiryDate')}
                    fullWidth
                  />
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </div>
    );
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  renderSelect = (inputName, inputLabel, value, changeFunction) => {
    const { documentTypeList } = this.props;

    return (
      <AutoSuggestion
        label={inputName}
        inputName={inputLabel}
        defaultValue={value ? value : ''}
        handleChange={changeFunction}
        typeList={documentTypeList}
        fullWidth
      />
    );
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  render() {
    const {
      ratingList,
      editMode,
      ratingsRequest,
      removeRatingFile,
      addSimpleError,
      classes,
      onElementDelete,
      t,
      addRatingPlannedAction,
      deactivateRating,
    } = this.props;
    const { addOpen, editOpen } = this.state;

    if (ratingsRequest) {
      return <Loader />;
    }

    return (
      <div className="col-12 user">
        <RatingsList
          ratingList={ratingList}
          addRatingPlannedAction={addRatingPlannedAction}
          removeRating={(rating) =>
            onElementDelete(() => this.removeRating(rating))
          }
          handleAddOpen={this.handleAddOpen}
          editMode={editMode}
          handleEditOpen={this.handleEditOpen}
          attachRatingFile={this.attachRatingFile}
          removeRatingFile={(fileId, id) =>
            onElementDelete(() => removeRatingFile(fileId, id))
          }
          addSimpleError={addSimpleError}
          deactivateRating={deactivateRating}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={addOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="add-rating-dialog-title"
          >
            {this.dialogContent(classes, t('user:addRating'), t)}
            {this.dialogActions(t)}
          </Dialog>
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={editOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="edit-rating-dialog-title"
          >
            {this.dialogContent(classes, t('user:editRating'), t)}
            {this.dialogActions(t)}
          </Dialog>
        </Form>
      </div>
    );
  }
}
