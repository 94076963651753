import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _each from 'lodash/each';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import MaterialInput from '../../../../../common/components/forms/MaterialInput';
import validators from '../../../../../utils/validators';
import Form from '../../../../../common/components/forms/Form';
import { getMinutes } from '../../../../utils/time';
import { ZERO_TIME } from '../../../../../constants';

const styles = () => ({
  textField: {
    width: 200,
  },
});

@withNamespaces()
@withStyles(styles)
export default class Time extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    type: PropTypes.string,
    numeric: PropTypes.bool,
    preview: PropTypes.bool,
    classes: PropTypes.object,
    registerComponentForm: PropTypes.func.isRequired,
    formValidated: PropTypes.func.isRequired,
    renderFillInButton: PropTypes.bool,
    totalBlock: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    t: PropTypes.func.isRequired,
  };

  onChange = (formData) => {
    _each(formData, ({ value, forced }, key) => {
      this.handleTimeInputChange(key, forced, value);
    });
  };

  renderFillInButton = () => {
    const { label, preview, renderFillInButton, totalBlock } = this.props;

    if (!preview && renderFillInButton) {
      return (
        <div className="align-self-end">
          <IconButton
            onClick={() => this.onChange({ [label]: { value: totalBlock, disabled: preview, forced: true } })}
          >
            <Icon color="primary">arrow_back</Icon>
          </IconButton>
        </div>
      );
    }

    return <div />;
  };

  handleTimeInputChange = (key, forced, value) =>{
    if (value.indexOf(':') > -1) {
      this.setState({ [this.getForcedInputLabel(key)]: forced || false });

      if (_isEmpty(value)) {
        this.setState({ [key]: ZERO_TIME });
      } else if (getMinutes(value)) {
        this.setState({ [key]: value });
      }
    }
  };

  onFormValidated = (isFormValid) => {
    const { inputName, label } = this.props;

    this.props.formValidated(`PracticalLesson-${inputName}`, isFormValid, this.state[label]);
  };

  registerForm = (triggerFormValidation) => {
    this.props.registerComponentForm(triggerFormValidation);
  };

  getForcedInputLabel = (inputName) => {
    return `forced-value-${inputName}`;
  };

  renderInput = (forced) => {
    const { label, inputName, classes, preview, defaultValue, t } = this.props;
    const inputLabel = `input:${label}`;

    if (forced) {
      return (
        <MaterialInput
          disabled={preview}
          margin="dense"
          type="text"
          name={inputName}
          label={t(inputLabel)}
          className={classes.textField}
          defaultValue={defaultValue}
          value={(this.state && this.state[inputName]) || defaultValue}
          InputLabelProps={{
            shrink: true,
          }}
          validators={[
            new validators.IsTimeFormatValid(t),
          ]}
        />
      );
    }

    return (
      <MaterialInput
        disabled={preview}
        margin="dense"
        type="text"
        name={inputName}
        label={t(inputLabel)}
        className={classes.textField}
        defaultValue={defaultValue}
        InputLabelProps={{
          shrink: true,
        }}
        validators={[
          new validators.IsTimeFormatValid(t),
        ]}
      />
    );
  };

  render() {
    const { label } = this.props;
    const forced = (this.state || {})[this.getForcedInputLabel(label)] || false;

    if (forced) {
      setImmediate(() => {
        this.setState({
          [this.getForcedInputLabel(label)]: false
        });
      });
    }

    return (
      <div className="icons-display-wrapper">
        <Form
          onChange={this.onChange}
          validateForm={this.validateForm}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          {this.renderInput(forced)}
        </Form>
        {this.renderFillInButton()}
      </div>
    );
  }
}
