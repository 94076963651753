import { DragSource, DropTarget } from 'react-dnd';
import React, { Component } from 'react';

import CustomIconButton from '../../../../common/components/CustomIconButton';
import { DRAG_TYPES } from '../../../../constants';
import Divider from '@material-ui/core/Divider';
import Form from '../../../../common/components/forms/Form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import LessonDroppable from './LessonDroppable';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { findDOMNode } from 'react-dom';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const lessonSource = {
  canDrag(props) {
    return !props.preview;
  },
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const lessonTarget = {
  canDrop(props) {
    return !props.preview;
  },
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveLesson(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

function collectDrop(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function collectDrag(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const styles = () => ({
  gradings: {
    minWidth: '150px',
    maxWidth: '150px',
  },

  addGradings: {
    alignSelf: 'center',
  },
});

@withNamespaces()
@withStyles(styles)
@DropTarget(DRAG_TYPES.lesson, lessonTarget, collectDrop)
@DragSource(DRAG_TYPES.lesson, lessonSource, collectDrag)
export default class Lesson extends Component {
  static propTypes = {
    lesson: PropTypes.object.isRequired,
    lessonTypeList: PropTypes.array.isRequired,
    gradingList: PropTypes.array.isRequired,
    dropMoveItem: PropTypes.func.isRequired,
    dropRemoveItem: PropTypes.func.isRequired,
    moveLesson: PropTypes.func.isRequired,
    moveItemSortable: PropTypes.func.isRequired,
    removeLesson: PropTypes.func.isRequired,
    changeLesson: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    registerComponentForm: PropTypes.func.isRequired,
    formValidated: PropTypes.func.isRequired,
    preview: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  state = {
    name: _get(this.props.lesson, 'name', ''),
  };

  componentDidMount() {
    this.formData = {};
  }

  renderButtons = (lesson, preview) => {
    const { removeLesson, connectDragSource } = this.props;

    if (preview) {
      return null;
    }

    return (
      <div className="col-auto">
        <IconButton onClick={() => removeLesson(lesson)}>
          <Icon color="primary">delete</Icon>
        </IconButton>
        {connectDragSource(
          <span>
            <CustomIconButton>
              <Icon color="primary">drag_handle</Icon>
            </CustomIconButton>
          </span>,
        )}
      </div>
    );
  };

  renderLessonType = (lesson, changeLesson, preview) => {
    const { lessonTypeList, t } = this.props;

    return (
      <FormControl margin="dense">
        <RadioGroup
          aria-label="lessonType"
          className="col-auto flex-row"
          name="lessonType"
          value={lesson.lessonType}
          onChange={changeLesson({
            uniqueId: lesson.uniqueId,
            name: 'lessonType',
          })}
        >
          {lessonTypeList.map((type) => {
            const typeLabel = `course:${type.shortname}`;

            return (
              <FormControlLabel
                key={`lessonType-${type.id}`}
                value={String(type.id)}
                control={<Radio color="primary" />}
                label={t(typeLabel)}
                disabled={preview}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  renderGradingItems(gradingList, t) {
    if (_isEmpty(gradingList)) {
      return null;
    }

    return gradingList.map((grading) => {
      const gradingType = `settings:${grading.name}`;

      return (
        <MenuItem key={`grading-${grading.id}`} value={grading.id}>
          {t(gradingType)}
        </MenuItem>
      );
    });
  }

  renderPreferredGrades = (lesson, changeLesson) => {
    const { gradingList, t, classes, preview } = this.props;

    const grades = lesson.grades || [];

    return grades.map(({ gradeId, expectedGradeValue }, index) => (
      <div className="col-3">
        <FormControl>
          <InputLabel className={classes.gradings} htmlFor="grading">
            {t('input:Grading')}
          </InputLabel>
          <Select
            className={classes.gradings}
            value={gradeId}
            onChange={changeLesson({
              uniqueId: lesson.uniqueId,
              name: 'gradeId',
              gradeIndex: index,
            })}
            inputProps={{
              name: 'gradeId',
              id: 'grading',
            }}
            disabled={preview}
          >
            {gradingList.map((grading) => (
              <MenuItem key={`grading-${grading.id}`} value={grading.id}>
                {t(`settings:${grading.name}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel className={classes.gradings} htmlFor="expectedGradeValue">
            {t('input:Expected value')}
          </InputLabel>
          <Select
            className={classes.gradings}
            value={expectedGradeValue}
            onChange={changeLesson({
              uniqueId: lesson.uniqueId,
              name: 'expectedGradeValue',
              gradeIndex: index,
            })}
            inputProps={{
              name: 'expectedGradeValue',
              id: 'expectedGradeValue',
            }}
            disabled={preview}
          >
            {gradingList
              .find(({ id }) => id === gradeId)
              .options.map((option) => (
                <MenuItem key={`grading-${option}`} value={option}>
                  {t(`settings:${option}`)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    ));
  };

  onChange = (formData) => {
    const { changeLesson, lesson } = this.props;
    const event = {
      target: {
        value: formData.name.value,
      },
    };

    this.setState({
      name: formData.name.value,
    });

    changeLesson({ uniqueId: lesson.uniqueId, name: 'name' })(event);
  };

  onFormValidated = (isFormValid) => {
    const { name } = this.state;
    const { lesson, formValidated } = this.props;

    formValidated(`LessonsManager-${lesson.uniqueId}`, isFormValid, { name });
  };

  registerForm = (triggerFormValidation) => {
    this.props.registerComponentForm(triggerFormValidation);
  };

  render() {
    const {
      lesson,
      dropMoveItem,
      dropRemoveItem,
      changeLesson,
      preview,
      isDragging,
      connectDragPreview,
      connectDropTarget,
      moveItemSortable,
      t,
      classes,
    } = this.props;
    const { name } = this.state;

    return connectDragPreview(
      connectDropTarget(
        <div
          style={{
            opacity: isDragging ? 0 : 1,
          }}
        >
          <div className="row align-items-end">
            <div className="col">
              <Form
                onChange={this.onChange}
                validateForm={this.validateForm}
                onFormValidated={this.onFormValidated}
                registerForm={this.registerForm}
              >
                <MaterialInput
                  name="name"
                  label={t('input:name')}
                  defaultValue={name}
                  margin="dense"
                  fullWidth
                  validators={[
                    new validators.IsRequired(t),
                    new validators.MaxLength(t, 200),
                  ]}
                  disabled={preview}
                />
              </Form>
            </div>
            {this.renderButtons(lesson, preview)}
          </div>
          <div className="row justify-content-start">
            {this.renderLessonType(lesson, changeLesson, preview)}
          </div>
          {(!preview || (lesson.grades || []).length > 0) && (
            <div className="row">
              <div className="col">
                <h4>{t('label:Gradings')}</h4>
              </div>
            </div>
          )}
          <div className="row justify-content-start">
            {this.renderPreferredGrades(lesson, changeLesson)}
            {!preview && (
              <div className={classNames(classes.addGradings, 'col-auto')}>
                <IconButton
                  onClick={() =>
                    changeLesson({
                      uniqueId: lesson.uniqueId,
                      name: 'addGrading',
                    })()
                  }
                >
                  <Icon fontSize="inherit">add_circle_outline</Icon>
                </IconButton>
              </div>
            )}
          </div>
          <div
            style={{
              opacity: preview ? 0.5 : 1,
            }}
          >
            <LessonDroppable
              lesson={lesson}
              dropMoveItem={dropMoveItem}
              dropRemoveItem={dropRemoveItem}
              preview={preview}
              moveItemSortable={moveItemSortable}
            />
          </div>
          <Divider color="secondary" />
        </div>,
      ),
    );
  }
}
