import React, { Component } from 'react';
import {
  addService,
  editService,
  removeService,
} from '../../../actions/services';
import {
  attachServiceFile,
  removeServiceFile,
} from '../../../actions/fileUpload';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import ServiceList from './ServiceList';
import _get from 'lodash/get';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  id: '',
  description: '',
  date: moment(),
  showRemoveDialog: false,
  removeCallback: () => {},
};

const mapStateToProps = (state) => {
  return {
    serviceList: state.services.serviceList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addService: (data) => dispatch(addService(data)),
    editService: (data) => dispatch(editService(data)),
    removeService: (data) => dispatch(removeService(data)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    attachServiceFile: (fileData, id, fleetId) =>
      dispatch(attachServiceFile(fileData, id, fleetId)),
    removeServiceFile: (fileId, id) => dispatch(removeServiceFile(fileId, id)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class ServicesManager extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    serviceList: PropTypes.array.isRequired,
    addService: PropTypes.func.isRequired,
    editService: PropTypes.func.isRequired,
    removeService: PropTypes.func.isRequired,
    attachServiceFile: PropTypes.func.isRequired,
    removeServiceFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    fleetSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.formData = {};
  }

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (service) => {
    this.setState({
      editOpen: true,
      id: service.id,
      description: service.description,
      date: reformatPickerDateFromDB(_get(service, 'date', moment())),
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      [name]: dateFromPicker,
    });
  };

  onChange = (formData, name) => {
    this.setState({
      [name]: formData[name].value,
    });
  };

  addService = () => {
    const { fleet } = this.props;
    const { description, date } = this.state;
    const data = {
      description,
      date: reformatPickerDateToDB(date || moment()),
      fleet: fleet.id,
    };

    this.props.addService(data);
    this.handleDialogClose();
  };

  editService = () => {
    const { fleet } = this.props;
    const { id, description, date } = this.state;
    const data = {
      id,
      description,
      date: reformatPickerDateToDB(date || moment()),
      fleet: fleet.id,
    };

    this.props.editService(data);
    this.handleDialogClose();
  };

  onFormValidated = (isFormValid) => {
    const { addOpen } = this.state;

    if (isFormValid && this.formData) {
      addOpen ? this.addService() : this.editService();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const {
      fleet,
      serviceList,
      removeService,
      classes,
      t,
      attachServiceFile,
      removeServiceFile,
      addSimpleError,
      editMode,
      fleetSettings,
    } = this.props;
    const {
      addOpen,
      editOpen,
      date,
      description,
      showRemoveDialog,
      removeCallback,
    } = this.state;

    return (
      <div className="service-settings">
        <h4>{t('fleet:servicesSection')}</h4>
        <ServiceList
          fleet={fleet}
          serviceList={serviceList}
          removeService={removeService}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          onElementDelete={this.openRemoveDialog}
          attachServiceFile={attachServiceFile}
          removeServiceFile={removeServiceFile}
          addSimpleError={addSimpleError}
          editMode={editMode}
          fleetSettings={fleetSettings}
        />
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={addOpen || editOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="add-service-type-dialog"
          maxWidth="md"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle id="add-edit-service-type-dialog">
              {addOpen
                ? t('fleet:addServiceDialogTitle')
                : t('fleet:editServiceDialogTitle')}
            </DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row">
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:date')}
                    name="date"
                    value={date}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange('date')}
                    dateFormat={getDateFormat()}
                    fullWidth
                  />
                </div>
                <div className="col-12">
                  <MaterialInput
                    label={t('input:description')}
                    name="description"
                    defaultValue={description}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                    ]}
                    multiline
                    fullWidth
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </div>
    );
  }
}
