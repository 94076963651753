import moment from 'moment';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import FieldsRenderer from './pages/FieldsRenderer';
import Page0 from './pages/Page0';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import { initialState } from './certificateHelper';
import { reformatPickerDateToDB } from '../../../utils/time';


const styles = () => ({
  textField: {},
  textFieldSM: {
    width: 120,
    marginRight: '1rem',
  },
});

@withNamespaces()
@withStyles(styles)
export default class ComplementaryCertificate extends Component {
  static propTypes = {
    userDetails: PropTypes.object.isRequired,
    settings: PropTypes.array.isRequired,
    complementaryCertificateList: PropTypes.array.isRequired,
    addComplementaryCertificate: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    page: 0,
    defaultSettings: _get(this.props.complementaryCertificateList.slice(-1).pop(), 'data', initialState(this.props)),
    language: _get(this.props.complementaryCertificateList.slice(-1).pop(), 'language', 'en'),
  }

  saveComplementaryCourse = () => {
    const { addComplementaryCertificate, userId, handleDialogClose } = this.props;
    const { defaultSettings, language } = this.state;

    const data = Object.keys(defaultSettings).reduce((result, keyName) => {
      let value = defaultSettings[keyName];

      if (moment.isMoment(value)) {
        value = reformatPickerDateToDB(value);
      }

      result[keyName] = value;

      return result;
    }, {});

    addComplementaryCertificate({ data, userId, language });
    handleDialogClose();
  }

  handleLanguageChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    });
  };

  renderPage0 = () => {
    const { handleDialogClose } = this.props;
    const { language } = this.state;

    return (
      <Page0
        language={language}
        handleLanguageChange={this.handleLanguageChange}
        changePage={this.changePage}
        isFirstPage={this.isFirstPage}
        isLastPage={this.isLastPage}
        handleDialogClose={handleDialogClose}
        saveComplementaryCourse={this.saveComplementaryCourse}
      />
    );
  }

  renderPage1 = () => {
    const { handleDialogClose } = this.props;
    const { defaultSettings } = this.state;

    return (
      <Page1
        defaultSettings={defaultSettings}
        changePage={this.changePage}
        isFirstPage={this.isFirstPage}
        isLastPage={this.isLastPage}
        handleDialogClose={handleDialogClose}
        saveComplementaryCourse={this.saveComplementaryCourse}
      />
    );
  }

  renderPage2 = () => {
    const { handleDialogClose } = this.props;
    const { defaultSettings } = this.state;

    return (
      <Page2
        defaultSettings={defaultSettings}
        changePage={this.changePage}
        isFirstPage={this.isFirstPage}
        isLastPage={this.isLastPage}
        handleDialogClose={handleDialogClose}
        saveComplementaryCourse={this.saveComplementaryCourse}
      />
    );
  }

  renderPage3 = () => {
    const { handleDialogClose } = this.props;
    const { defaultSettings } = this.state;

    return (
      <Page3
        defaultSettings={defaultSettings}
        changePage={this.changePage}
        isFirstPage={this.isFirstPage}
        isLastPage={this.isLastPage}
        handleDialogClose={handleDialogClose}
        saveComplementaryCourse={this.saveComplementaryCourse}
      />
    );
  }

  renderPage4 = () => {
    const { t, handleDialogClose } = this.props;
    const { defaultSettings } = this.state;

    return (
      <FieldsRenderer
        defaultSettings={defaultSettings}
        changePage={this.changePage}
        isFirstPage={this.isFirstPage}
        isLastPage={this.isLastPage}
        handleDialogClose={handleDialogClose}
        saveComplementaryCourse={this.saveComplementaryCourse}
        elementsNumber={23}
        nameField1={'rollingStockDate'}
        nameField2={'rollingStockDescription'}
        nameField3={'rollingStockNotes'}
        title1={t('complementaryCertificate:rollingStockSection')}
        title2={t('complementaryCertificate:rollingStockSection2')}
      />
    );
  }

  renderPage5 = () => {
    const { t, handleDialogClose } = this.props;
    const { defaultSettings } = this.state;

    return (
      <FieldsRenderer
        defaultSettings={defaultSettings}
        changePage={this.changePage}
        isFirstPage={this.isFirstPage}
        isLastPage={this.isLastPage}
        handleDialogClose={handleDialogClose}
        saveComplementaryCourse={this.saveComplementaryCourse}
        elementsNumber={23}
        nameField1={'infrastructureDate'}
        nameField2={'infrastructureExtension'}
        nameField3={'infrastructureNotes'}
        title1={t('complementaryCertificate:infrastructureSection')}
        title2={t('complementaryCertificate:infrastructureSection2')}
      />
    );
  }

  renderPage6 = () => {
    const { t, handleDialogClose } = this.props;
    const { defaultSettings } = this.state;

    return (
      <FieldsRenderer
        defaultSettings={defaultSettings}
        changePage={this.changePage}
        isFirstPage={this.isFirstPage}
        isLastPage={this.isLastPage}
        handleDialogClose={handleDialogClose}
        elementsNumber={23}
        nameField1={'infrastructureDate_2'}
        nameField2={'infrastructureDescription_2'}
        nameField3={'infrastructureNotes_2'}
        translationNameField1={'infrastructureDate'}
        translationNameField2={'infrastructureDescription'}
        translationNameField3={'infrastructureNotes'}
        title1={t('complementaryCertificate:infrastructureSection')}
        title2={t('complementaryCertificate:infrastructureSection2')}
      />
    );
  }

  changePage = (pageValue, componentState) => {
    const { defaultSettings } = this.state;

    this.setState({
      canRender: false,
      page: this.state.page + pageValue,
      defaultSettings: {
        ...defaultSettings,
        ...componentState,
      }
    }, () => {
      if (pageValue === 0) {
        this.saveComplementaryCourse();
      }
    });
  }

  changeRendering = () => {
    this.setState({
      canRender: true,
    });
  }

  renderForm = () => {
    const { page, canRender } = this.state;
    const pages = {
      0: this.renderPage0,
      1: this.renderPage1,
      2: this.renderPage2,
      3: this.renderPage3,
      4: this.renderPage4,
      5: this.renderPage5,
      6: this.renderPage6,
    };

    if (!canRender) {
      setImmediate(() => this.changeRendering());

      return (<div />);
    }

    return pages[page]();
  }

  isFirstPage = () => {
    const { page } = this.state;

    return page === 0;
  }

  isLastPage = () => {
    const { page } = this.state;

    return page === 6;
  }

  render() {
    const { t, open, handleDialogClose } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="add-complementary-certificate"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="add-complementary-certificate"
        >
          {t('complementaryCertificate:addComplementaryCertificateDialogTitle')}
        </DialogTitle>
        {this.renderForm()}
      </Dialog>
    );
  }
}
