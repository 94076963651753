import 'bootstrap';
import '../styles/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './containers/Root/Root';
import axios from 'axios';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import decorateAxios from '../utils/axios-decorator';
import { getCookie } from '../utils/cookies';
import history from './store/history';
import initTranslations from './translations.config.js';
import { isDataToSync } from '../utils/offline';
import { loginSuccess } from './actions/authentication';
import store from './store';

require('es6-promise').polyfill();

export { store };

function getSessionKey(key) {
  const offline = isDataToSync();

  return offline ? localStorage.getItem(key) : localStorage.getItem(key);
}

axios.interceptors.request.use(
  function (config) {
    const csrf = getCookie('csrftoken');
    const token = localStorage.getItem('token');

    return {
      ...config,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token ? `token ${token}` : '',
        'X-CSRFToken': csrf ? csrf : '',
        ...config.headers,
      },
    };
  },
  function (error) {
    return Promise.reject(error);
  },
);

decorateAxios();

const token = getSessionKey('token');
let user = {};

try {
  user = JSON.parse(getSessionKey('user'));
} catch (e) {
  // Failed to parse
}

if (token !== null) {
  store.dispatch(loginSuccess(token, user));
}

const bugsnagClient = bugsnag({
  apiKey: '7ed6b2fe68370709488a1b9573a9413e',
  notifyReleaseStages: ['testing', 'production'],
  releaseStage: process.env.NODE_ENV,
});

bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

initTranslations()
  .then(() => {
    ReactDOM.render(
      <ErrorBoundary>
        <Root store={store} history={history} />
      </ErrorBoundary>,
      document.getElementById('root'),
    );
  })
  .catch((error) => {
    console.log(`Translations error: ${error}`);
  });
