import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

import { withNamespaces } from 'react-i18next';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
});

@withNamespaces()
@withStyles(styles)
export default class StaffCategoriesList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    staffCategoryList: PropTypes.array,
    removeStaffCategory: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  getCompetence = (competencies) => {
    const competenciesChip = _reduce(competencies, (memo, competence) => {
      return `${memo}${competence.name}, `;
    }, '');

    return competenciesChip.substring(0, competenciesChip.length - 2);
  };

  renderCompetenciesChip(competencies) {
    if (_isObject(competencies) && !_isEmpty(competencies)) {
      return (
        <Chip
          label={this.getCompetence(competencies)}
        />
      );
    }

    return competencies;
  }

  renderStaffCategoryList = (staffCategoryList) => {
    const { handleEditOpen, onElementDelete, removeStaffCategory } = this.props;

    return staffCategoryList.map((staffCategory) => {
      return (
        <TableRow key={`staff-category-${staffCategory.id}`}>
          <TableCell>{staffCategory.name}</TableCell>
          <TableCell>{this.renderCompetenciesChip(staffCategory.competencies)}</TableCell>
          <TableCell numeric>
            <IconButton onClick={() => handleEditOpen(staffCategory)}><Icon color="primary">mode_edit</Icon></IconButton>
            <IconButton onClick={() => onElementDelete(() => removeStaffCategory(staffCategory))}><Icon color="primary">delete</Icon></IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { classes, handleAddOpen, staffCategoryList, t } = this.props;

    if (_isEmpty(staffCategoryList)) {
      return (
        <div className="pl-sm-5">
          <Button color="primary" onClick={handleAddOpen}>{t('settings:addStaffCategoryButton')}</Button>
        </div>
      );
    }

    return (
      <div className="pl-sm-5">
        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table:name')}</TableCell>
                  <TableCell>{t('table:competencies')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderStaffCategoryList(staffCategoryList)}
              </TableBody>
            </Table>
          </div>
        </div>

        <Button color="primary" onClick={handleAddOpen}>{t('settings:addStaffCategoryButton')}</Button>
      </div>
    );
  }
}
