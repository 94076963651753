import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import ElearningList from '../../../../common/containers/Elearning/ElearningList';
import Loader from '../../../../common/components/Loader';
import { fetchElearnings, fetchElearningLink } from '../../../actions/eLearning';
import { reformatPickerDateTimeFromDB } from '../../../utils/time';
import userService from '../../../../utils/userService';
import { hasPermission } from '../../../../aviation/decorators/permissions';
import { PERMISSIONS } from '../../../../constants/permissions';


const mapStateToProps = (state) => {
  return {
    eLearningList: state.eLearning.eLearningList,
    eLearningRequest: state.eLearning.eLearningRequest,
    eLearningLinkRequest: state.eLearning.eLearningLinkRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchElearnings: (id) => dispatch(fetchElearnings(id)),
    fetchElearningLink: (id) => dispatch(fetchElearningLink(id)),
  };
}


@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class CrossElearningListView extends Component {
  static propTypes = {
    fetchElearnings: PropTypes.func.isRequired,
    fetchElearningLink: PropTypes.func.isRequired,
    eLearningList: PropTypes.array.isRequired,
    eLearningRequest: PropTypes.bool.isRequired,
    eLearningLink: PropTypes.array.isRequired,
    eLearningLinkRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { fetchElearnings } = this.props;
    const user = userService.getCurrentUser();

    fetchElearnings(user.id);
  }

  prepareElearningList = (eLearningList) => {
    let parsedElearningList = [];

    if (_isEmpty(eLearningList)) {
      return parsedElearningList;
    }

    parsedElearningList = eLearningList.map((eLearning) => ({
      ...eLearning,
      startDate: reformatPickerDateTimeFromDB(_get(eLearning, 'user_enrollment.start_date', null), false),
      endDate: reformatPickerDateTimeFromDB(_get(eLearning, 'user_enrollment.completed_date', null), false),
    }));

    return parsedElearningList;
  };

  fetchElearningLink = () => {
    const { fetchElearningLink } = this.props;
    const user = userService.getCurrentUser();

    fetchElearningLink(user.id);
  }

  renderButtonText = () => {
    const { t } = this.props;

    if (hasPermission(PERMISSIONS.renderElearningAdminText)) {
      return t('eLearning:goToThePlatformAdmin');
    }

    return t('eLearning:goToThePlatform');
  };

  render() {
    const { eLearningList, eLearningRequest, eLearningLinkRequest } = this.props;

    if (eLearningRequest || eLearningLinkRequest) {
      return <Loader />;
    }

    return (
      <ElearningList
        eLearningList={this.prepareElearningList(eLearningList)}
        fetchElearningLink={this.fetchElearningLink}
        buttonText={this.renderButtonText()}
      />
    );
  }
}
