import axios from 'axios';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { addError } from './errors';
import { urlJoin, defaultCrewStatusFilerMonthsValue } from '../../utils';
import { prepareCrewStatusFilter, getDashboardQueryParametersFilter } from '../utils/time';
import { URLS } from '../../constants';

export const ADD_LICENSE = 'ADD_LICENSE';
export const REMOVE_LICENSE = 'REMOVE_LICENSE';
export const EDIT_LICENSE = 'EDIT_LICENSE';
export const FETCH_LICENSES_SUCCESS = 'FETCH_LICENSES_SUCCESS';
export const FETCH_ALL_LICENSES_SUCCESS = 'FETCH_ALL_LICENSES_SUCCESS';
export const FETCH_LICENSES = 'FETCH_LICENSES';

export function fetchLicensesSuccess(licenses) {
  return {
    type: FETCH_LICENSES_SUCCESS,
    payload: {
      licenses
    }
  };
}

export function fetchAllLicensesSuccess(allLicenses) {
  return {
    type: FETCH_ALL_LICENSES_SUCCESS,
    payload: {
      allLicenses
    }
  };
}

export function fetchAllLicensesRequest() {
  return {
    type: FETCH_LICENSES,
  };
}

export function addLicenseSuccess(license) {
  return {
    type: ADD_LICENSE,
    payload: {
      license
    }
  };
}

export function removeLicenseSuccess(license) {
  return {
    type: REMOVE_LICENSE,
    payload: {
      license
    }
  };
}

export function editLicenseSuccess(license) {
  return {
    type: EDIT_LICENSE,
    payload: {
      license
    }
  };
}

export function addLicense(license) {
  return (dispatch) => {
    return axios.post(URLS.licenses, license)
      .then(({ data }) => dispatch(addLicenseSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during license adding ${error.message}`, error));
      });
  };
}

export function addLicensePlannedAction(license) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.licensesPlannedAction, license.id), license)
      .then(({ data }) => dispatch(editLicenseSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during adding license planned action ${error.message}`, error));
      });
  };
}

export function removeLicense(license) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.licenses, license.id))
      .then(() => dispatch(removeLicenseSuccess(license)))
      .catch((error) => {
        dispatch(addError(`Error during license removing ${error.message}`, error));
      });
  };
}

export function editLicense(license) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.licenses, license.id), license)
      .then(({ data }) => dispatch(editLicenseSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during license editing ${error.message}`, error));
      });
  };
}

export function deactivateLicense(license) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.licensesDeactivate, license.id), license)
      .then(({ data }) => dispatch(editLicenseSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during license editing ${error.message}`, error));
      });
  };
}

export function fetchLicenses(userId) {
  return (dispatch) => {
    const queryParameters = `?${getDashboardQueryParametersFilter(userId)}&ordering=expiry_date`;

    return axios.get(urlJoin(URLS.licenses, queryParameters))
      .then(({ data }) => dispatch(fetchLicensesSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during licenses fetching ${error.message}`, error));
      });
  };
}

export function fetchAllLicenses(sortingData) {
  return (dispatch) => {
    dispatch(fetchAllLicensesRequest());
    let crewStatusParameters = '';
    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData && !_isEmpty(sortingData.filter)) {
      const monthId = _get(sortingData.filter, 'months[0].id', defaultCrewStatusFilerMonthsValue);

      crewStatusParameters = prepareCrewStatusFilter(monthId);

      if (sortingData.filter.isVerified) {
        crewStatusParameters += '&is_verified=False';
      }
    }

    queryParameters = _isEmpty(queryParameters) ? `?${crewStatusParameters}` : `?${qs.stringify(queryParameters)}&${crewStatusParameters}`;

    return axios.get(urlJoin(URLS.allLicenses, queryParameters))
      .then(({ data }) => dispatch(fetchAllLicensesSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during licenses fetching ${error.message}`, error));
      });
  };
}
