import store from '../store';


function _getModules() {
  return store.getState().modules.moduleList;
}

export function hasModule(permission) {
  const userModules = _getModules();

  return !!userModules.find((module) => module.name === permission && module.isActive === true);
}

function _checkModulesAndCall(permission, originalFunction, args = []) {
  return hasModule(permission) ? originalFunction.call(this, ...args) : null;
}

export function renderModule(permission) {
  return function(target, name, descriptor) {
    const originalFunction = descriptor.value;

    descriptor.value = function(...args) {
      return _checkModulesAndCall.call(this, permission, originalFunction, args);
    };

    return descriptor;
  };
}

