import React, { Component } from 'react';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import _each from 'lodash/each';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 150,
    marginRight: 30,
  },
  root: {
    overflowY: 'visible',
  },
});

@withNamespaces()
@withStyles(styles)
export default class PlannedAction extends Component {
  static propTypes = {
    addPlannedAction: PropTypes.func.isRequired,
    plannedAction: PropTypes.string,
    plannedActionDate: PropTypes.object,
    classes: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    open: false,
    plannedAction: this.props.plannedAction || '',
    plannedActionDate: this.props.plannedActionDate || '',
  };

  componentDidMount() {
    this.formData = {};
  }

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value,
      });
    });
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid && this.formData) {
      const { id, addPlannedAction } = this.props;
      const { plannedAction, plannedActionDate } = this.state;
      const data = {
        id,
        plannedAction,
        plannedActionDate: reformatPickerDateToDB(plannedActionDate),
      };

      this.handleClose();

      addPlannedAction(data);
    }

    this.setState({
      isFormValid,
    });
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDatePickerChange = (date) => {
    this.setState({
      plannedActionDate: date,
    });
  };

  renderForm() {
    const { t, classes } = this.props;
    const { open, plannedAction, plannedActionDate } = this.state;

    return (
      <Dialog
        PaperProps={{
          classes: { root: classes.root },
        }}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="add-planned-action"
        fullWidth
      >
        <Form
          onChange={this.onChange}
          validateForm={this.validateForm}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <DialogTitle id="add-planned-action">
            {t('user:createPlannedActionDialogTitle')}
          </DialogTitle>
          <DialogContent>
            <MaterialInput
              className={classes.textField}
              margin="dense"
              name="plannedAction"
              label={t('input:plannedAction')}
              validators={[new validators.MaxLength(t, 250)]}
              defaultValue={plannedAction}
              autoFocus
              fullWidth
            />
            <DateTimePicker
              acceptEmptyInput
              value={reformatPickerDateFromDB(plannedActionDate)}
              showMonthDropdown
              showTimeSelect={false}
              showYearDropdown
              handleDatePickerChange={this.handleDatePickerChange}
              dateFormat={getDateFormat()}
              name="plannedActionDate"
              label={t('input:plannedActionDate')}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleClose}>
              {t('buttonCancel')}
            </Button>
            <Button color="primary" onClick={this.sendHandler}>
              {t('buttonSave')}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    );
  }

  render() {
    return (
      <>
        <Tooltip title="Plan action" placement="top">
          <IconButton onClick={this.handleClickOpen}>
            <Icon color="primary">add_alarm</Icon>
          </IconButton>
        </Tooltip>
        {this.renderForm()}
      </>
    );
  }
}
