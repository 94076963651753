import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';

export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';
export const EDIT_SETTING_REQUEST = 'EDIT_SETTING_REQUEST';
export const EDIT_SETTING = 'EDIT_SETTING';

export function fetchSettingsRequest() {
  return {
    type: FETCH_SETTINGS_REQUEST
  };
}

export function fetchSettingsSuccess(settings) {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    payload: {
      settings
    }
  };
}

export function fetchSettingsFailure() {
  return {
    type: FETCH_SETTINGS_FAILURE
  };
}

export function editSettingRequest() {
  return {
    type: EDIT_SETTING_REQUEST
  };
}

export function editSettingSuccess(setting) {
  return {
    type: EDIT_SETTING,
    payload: {
      setting
    }
  };
}

export function fetchSettings() {
  return (dispatch) => {
    dispatch(fetchSettingsRequest());

    return axios.get(URLS.settings)
      .then(({ data }) => dispatch(fetchSettingsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchSettingsFailure());
        dispatch(addError(`Error during settings fetching ${error.message}`, error));
      });
  };
}

export function editSetting(setting, errorCallback = () => {}) {
  return (dispatch) => {
    dispatch(editSettingRequest());

    return axios.patch(urlJoin(URLS.settings, setting.id), { ...setting })
      .then(({ data }) => dispatch(editSettingSuccess(data)))
      .catch((error) => {
        errorCallback();
        dispatch(addError(`Error during settings editing ${error.message}`, error));
      });
  };
}
