import { URLS_RAIL as URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { urlJoin } from '../../utils';

export const FETCH_PART_STATUSES_REQUEST = 'FETCH_PART_STATUSES_REQUEST';
export const FETCH_PART_STATUSES_SUCCESS = 'FETCH_PART_STATUSES_SUCCESS';
export const FETCH_PART_STATUSES_FAILURE = 'FETCH_PART_STATUSES_FAILURE';

export function fetchPartStatusesRequest() {
  return {
    type: FETCH_PART_STATUSES_REQUEST,
  };
}

export function fetchPartStatusesSuccess(partStatuses) {
  return {
    type: FETCH_PART_STATUSES_SUCCESS,
    payload: {
      partStatuses,
    },
  };
}

export function fetchPartStatusesFailure() {
  return {
    type: FETCH_PART_STATUSES_FAILURE,
  };
}

export function fetchPartStatuses() {
  return (dispatch) => {
    dispatch(fetchPartStatusesRequest());

    return axios
      .get(URLS.partStatuses)
      .then(({ data }) => dispatch(fetchPartStatusesSuccess(data)))
      .catch((error) => {
        dispatch(fetchPartStatusesFailure());
        dispatch(
          addError(
            `Error during part statuses fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}
