import React, { Component } from 'react';
import {
  addRiskAssessment,
  editRiskAssessment,
} from '../../../actions/riskAssessments';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';
import { isAdmin, isSuperAdmin } from '../../../../utils';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Form from '../../../../common/components/forms/Form';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { fetchFleets } from '../../../actions/fleet';
import { fetchUsers } from '../../../actions/users';
import userService from '../../../../utils/userService';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    users: state.users.userList,
    usersRequest: state.users.getUsersRequest,
    fleets: state.fleet.fleetList,
    fleetsRequest: state.fleet.fleetRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    fetchFleets: () => dispatch(fetchFleets()),
    addRiskAssessment: (riskAssessment) =>
      dispatch(addRiskAssessment(riskAssessment)),
    editRiskAssessment: (riskAssessment) =>
      dispatch(editRiskAssessment(riskAssessment)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class RiskManager extends Component {
  static propTypes = {
    addRiskAssessment: PropTypes.func.isRequired,
    editRiskAssessment: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    lock: PropTypes.bool,
    t: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    fleets: PropTypes.array.isRequired,
    fetchFleets: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    riskAssessment: PropTypes.object.isRequired,
    usersRequest: PropTypes.bool.isRequired,
    fleetsRequest: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const { t } = props;

    this.state = {
      likelihoodsValues: [
        t('sms:Extremely Improbable'),
        t('sms:Improbable'),
        t('sms:Remote'),
        t('sms:Occasional'),
        t('sms:Frequent'),
      ],
      severityValues: [
        t('sms:Negligible'),
        t('sms:Minor'),
        t('sms:Major'),
        t('sms:Hazardous'),
        t('sms:Catastrophic'),
      ],
    };
  }

  componentDidMount() {
    const { fleets, users, fetchUsers, fetchFleets, riskAssessment } =
      this.props;

    if (users.length === 0) {
      fetchUsers();
    }

    if (fleets.length === 0) {
      fetchFleets();
    }

    this.setState({
      riskAssessment,
    });
  }

  hasPermissionsToSetUser() {
    const user = userService.getCurrentUser();

    const { users, lock } = this.props;

    return (
      !lock &&
      users.filter((u) => u.id === user.id && (isAdmin(u) || isSuperAdmin(u)))
    );
  }

  onChange = (formData, name) => {
    this.formData = formData;

    const { value } = formData[name];

    const { riskAssessment } = this.state;

    if (!_isEmpty(value)) {
      if (['outcome', 'likelihood', 'severity'].indexOf(name) >= 0) {
        riskAssessment.hazards[0][name] = value;
      } else {
        riskAssessment[name] = value;
      }

      this.setState({ ...riskAssessment });
    }
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid && this.formData) {
      const { addRiskAssessment, editRiskAssessment, editMode } = this.props;
      const { riskAssessment } = this.state;

      !editMode
        ? addRiskAssessment(riskAssessment)
        : editRiskAssessment(riskAssessment);
    }
  };

  onSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleDatePickerChange = (name) => (date) => {
    const { riskAssessment } = this.state;

    riskAssessment[name] = reformatPickerDateToDB(date);

    this.setState({ ...riskAssessment });
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  render() {
    const { t, users, fleets, riskAssessment, lock } = this.props;
    const { likelihoodsValues, severityValues } = this.state;

    if (!riskAssessment) {
      return <Loader />;
    }

    const userId = riskAssessment.createdBy || userService.getCurrentUser().id;
    const date = riskAssessment.date
      ? reformatPickerDateFromDB(riskAssessment.date)
      : null;
    const hazard = riskAssessment.hazards.length
      ? riskAssessment.hazards[0]
      : {};

    const likelihoodValueAsText = likelihoodsValues[hazard.likelihood];
    const severityValueAsText = severityValues[hazard.severity];
    const fleet = fleets.find((fleet) => fleet.id === riskAssessment.fleet) || {
      vehicle: {},
    };
    const user = users.find((user) => user.id === userId) || {
      lastName: '',
      firstName: '',
    };

    return (
      <div className="row">
        <div className="col-12">
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <div className="row">
              <div className="col-4">
                {lock ? (
                  <MaterialInput
                    fullWidth
                    margin="dense"
                    name="title"
                    label={t('input:Title')}
                    defaultValue={riskAssessment.title}
                    validators={[new validators.IsRequired(t)]}
                    InputProps={{
                      readOnly: { lock },
                      disableUnderline: { lock },
                    }}
                  />
                ) : (
                  <MaterialInput
                    fullWidth
                    margin="dense"
                    name="title"
                    label={t('input:Title')}
                    defaultValue={riskAssessment.title}
                    validators={[new validators.IsRequired(t)]}
                  />
                )}
              </div>
              <div className="col-2 offset-1">
                {lock ? (
                  <MaterialInput
                    fullWidth
                    margin="dense"
                    name="date"
                    label={t('input:date')}
                    defaultValue={riskAssessment.date}
                    validators={[new validators.IsRequired(t)]}
                    InputProps={{
                      readOnly: { lock },
                      disableUnderline: { lock },
                    }}
                  />
                ) : (
                  <DateTimePicker
                    label={t('input:Date')}
                    name="date"
                    value={date}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange('date')}
                    dateFormat={getDateFormat()}
                    fullWidth
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                {lock ? (
                  <MaterialInput
                    label={t('input:Description')}
                    name="description"
                    defaultValue={riskAssessment.description}
                    margin="dense"
                    multiline
                    rows={5}
                    rowsMax={5}
                    validators={[new validators.IsRequired(t)]}
                    fullWidth
                    InputProps={{
                      readOnly: { lock },
                      disableUnderline: { lock },
                    }}
                  />
                ) : (
                  <MaterialInput
                    label={t('input:Description')}
                    name="description"
                    defaultValue={riskAssessment.description}
                    margin="dense"
                    multiline
                    rows={5}
                    rowsMax={5}
                    validators={[new validators.IsRequired(t)]}
                    fullWidth
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                {lock ? (
                  <MaterialInput
                    label={t('input:Identified hazard')}
                    name="outcome"
                    defaultValue={hazard.outcome}
                    margin="dense"
                    multiline
                    rows={5}
                    rowsMax={5}
                    validators={[new validators.IsRequired(t)]}
                    fullWidth
                    InputProps={{
                      readOnly: { lock },
                      disableUnderline: { lock },
                    }}
                  />
                ) : (
                  <MaterialInput
                    label={t('input:Identified hazard')}
                    name="outcome"
                    defaultValue={hazard.outcome}
                    margin="dense"
                    multiline
                    rows={5}
                    rowsMax={5}
                    validators={[new validators.IsRequired(t)]}
                    fullWidth
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                {lock ? (
                  <MaterialInput
                    label={t('input:Likelihood')}
                    name="likelihood"
                    defaultValue={likelihoodValueAsText}
                    margin="dense"
                    fullWidth
                    InputProps={{
                      readOnly: { lock },
                      disableUnderline: { lock },
                    }}
                  />
                ) : (
                  <MaterialSelect
                    id="likelihood"
                    name="likelihood"
                    label={t('input:Likelihood')}
                    defaultValue={hazard.likelihood}
                    options={likelihoodsValues.map((likelihood, idx) => ({
                      value: idx,
                      label: likelihood,
                    }))}
                    validators={[new validators.IsRequired(t)]}
                    disabled={lock}
                  />
                )}
              </div>
              <div className="col-2">
                {lock ? (
                  <MaterialInput
                    label={t('input:Severity')}
                    name="severity"
                    defaultValue={severityValueAsText}
                    margin="dense"
                    fullWidth
                    InputProps={{
                      readOnly: { lock },
                      disableUnderline: { lock },
                    }}
                  />
                ) : (
                  <MaterialSelect
                    id="severity"
                    name="severity"
                    label={t('input:Severity')}
                    defaultValue={hazard.severity}
                    options={severityValues.map((severity, idx) => ({
                      value: idx,
                      label: severity,
                    }))}
                    validators={[new validators.IsRequired(t)]}
                    disabled={lock}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                {lock ? (
                  <MaterialInput
                    label={t('input:Fleet')}
                    name="fleet"
                    value={`${fleet.vehicle.name} (${fleet.vehicle.evn})`}
                    margin="dense"
                    fullWidth
                    InputProps={{
                      readOnly: { lock },
                      disableUnderline: { lock },
                    }}
                  />
                ) : (
                  <MaterialSelect
                    id="fleet"
                    name="fleet"
                    label={t('input:Fleet')}
                    defaultValue={riskAssessment.fleet}
                    options={fleets.map((fleet) => ({
                      value: fleet.id,
                      label: `${fleet.vehicle.name} (${fleet.vehicle.evn})`,
                    }))}
                    disabled={lock}
                  />
                )}
              </div>
              <div className="col-3 offset-1">
                {lock ? (
                  <MaterialInput
                    label={t('input:Created by')}
                    name="createdBy"
                    value={`${user.lastName} ${user.firstName}`}
                    margin="dense"
                    fullWidth
                    InputProps={{
                      readOnly: { lock },
                      disableUnderline: { lock },
                    }}
                  />
                ) : (
                  <MaterialSelect
                    id="createdBy"
                    name="createdBy"
                    label={t('input:Created by')}
                    defaultValue={userId}
                    options={users.map((user) => ({
                      value: user.id,
                      label: `${user.lastName} ${user.firstName}`,
                    }))}
                    validators={[new validators.IsRequired(t)]}
                    disabled={this.hasPermissionsToSetUser()}
                  />
                )}
              </div>
            </div>
          </Form>
        </div>
        {lock ? (
          ''
        ) : (
          <div class="mt-4 col-12 text-center">
            <Link to={{ pathname: '/sms' }}>
              <Button color="secondary">{t('buttonCancel')}</Button>
            </Link>
            <Button color="primary" onClick={this.onSave}>
              {t('buttonSave')}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
