import {
  FETCH_ASSIGNED_TESTS_REQUEST,
  FETCH_ASSIGNED_TESTS_SUCCESS,
  FETCH_ASSIGNED_TESTS_FAILURE,
  FETCH_ASSIGNED_TEST_REQUEST,
  FETCH_ASSIGNED_TEST_SUCCESS,
  FETCH_ASSIGNED_TEST_FAILURE,
  REMOVE_ASSIGNED_TEST,
  EDIT_ASSIGNED_TEST,
  ASSIGN_TEST_SUCCESS,
  RESET_ASSIGN_TEST_SUCCESS
} from '../actions/assignedTests';

const INITIAL_STATE = {
  assignedTest: {},
  assignedTestList: [],
  assignedTestRequest: false,
  assignedTestsRequest: false,
  assignTestSuccess: false,
};

function fetchAssignedTestsSuccess(state, payload) {
  return {
    ...state,
    assignedTestList: payload.assignedTests,
    assignedTestsRequest: false,
  };
}

function fetchAssignedTestsRequest(state) {
  return {
    ...state,
    assignedTestsRequest: true,
  };
}

function fetchAssignedTestsFailure(state) {
  return {
    ...state,
    assignedTestsRequest: false,
  };
}

function removeAssignedTest(state, payload) {
  return {
    ...state,
    assignedTestList: state.assignedTestList.filter((assignedTest) => assignedTest !== payload.assignedTest)
  };
}

function editAssignedTest(state, payload) {
  return {
    ...state,
    assignedTest: payload.assignedTest,
    assignedTestList: state.assignedTestList.map((assignedTest) => {
      if (assignedTest.id === payload.assignedTest.id) {
        return payload.assignedTest;
      }

      return assignedTest;
    })
  };
}

function assignTestSuccess(state) {
  return {
    ...state,
    assignTestSuccess: true,
  };
}

function resetAssignTestSuccess(state) {
  return {
    ...state,
    assignTestSuccess: false,
  };
}

function fetchAssignedTestSuccess(state, payload) {
  return {
    ...state,
    assignedTest: payload.assignedTest,
    assignedTestRequest: false,
  };
}

function fetchAssignedTestRequest(state) {
  return {
    ...state,
    assignedTestRequest: true,
  };
}

function fetchAssignedTestFailure(state) {
  return {
    ...state,
    assignedTestRequest: false,
  };
}

const actions = {
  [FETCH_ASSIGNED_TESTS_REQUEST]: fetchAssignedTestsRequest,
  [FETCH_ASSIGNED_TESTS_SUCCESS]: fetchAssignedTestsSuccess,
  [FETCH_ASSIGNED_TESTS_FAILURE]: fetchAssignedTestsFailure,
  [FETCH_ASSIGNED_TEST_REQUEST]: fetchAssignedTestRequest,
  [FETCH_ASSIGNED_TEST_SUCCESS]: fetchAssignedTestSuccess,
  [FETCH_ASSIGNED_TEST_FAILURE]: fetchAssignedTestFailure,
  [REMOVE_ASSIGNED_TEST]: removeAssignedTest,
  [EDIT_ASSIGNED_TEST]: editAssignedTest,
  [ASSIGN_TEST_SUCCESS]: assignTestSuccess,
  [RESET_ASSIGN_TEST_SUCCESS]: resetAssignTestSuccess
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
