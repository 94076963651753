import React, { Component } from 'react';

import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '../../../../../common/components/Table';
import _isEmpty from 'lodash/isEmpty';
import { reformatPickerDateFromDB } from '../../../../utils/time';
import { sortString } from '../../../../../utils/sort';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
});

const tableHeader = [
  {
    prop: 'date',
    sort: true,
    profileView: false,
  },
  {
    prop: 'vehicle',
    sort: true,
    profileView: false,
  },
  {
    prop: 'fault category',
    sort: true,
    profileView: false,
  },
  {
    prop: 'fault subcategory',
    sort: false,
    profileView: false,
  },
  {
    prop: 'subject',
    sort: true,
    profileView: false,
  },
  {
    prop: 'critical',
    sort: false,
    profileView: false,
  },
  {
    prop: 'status',
    sort: true,
    profileView: false,
  },
  {
    prop: 'dueDate',
    sort: true,
    profileView: false,
  },
  {
    prop: 'assignedTo',
    sort: true,
    profileView: false,
  },
];

const INITIAL_STATE = {
  searchSettings: {
    sortAsc: true,
  },
};

@withNamespaces()
@withStyles(styles)
export default class TechnicalFaultReportList extends Component {
  static propTypes = {
    reportList: PropTypes.array,
    classes: PropTypes.object.isRequired,
    sortAsc: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    openRemoveDialog: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  createCriticalLabel(report) {
    return (
      <Icon className="mr-2">
        {report.nonSafetyRelated ? 'remove' : 'build'}
      </Icon>
    );
  }

  getAssigneeFullName(report) {
    if (report.assignee) {
      return `${report.assignee.firstName} ${report.assignee.lastName}`;
    }
  }

  getGroupList() {
    const { reportList, t } = this.props;

    const parsedReport = reportList
      .filter((report) => report.fleet)
      .map((report) => ({
        source: report,
        date: this.renderReportLink(
          report,
          reformatPickerDateFromDB(report.date, false),
        ),
        vehicleName: report.fleetVehicleName,
        vehicle: this.renderFleetLink(report.fleet, report.fleetVehicleName),
        subject: this.renderReportLink(report, report.subject),
        type: this.renderReportLink(report, this.getReportCategories(report)),
        status: this.renderReportLink(report, this.getReportStatus(report, t)),
        critical: this.renderReportLink(
          report,
          this.createCriticalLabel(report),
        ),
        dueDate: this.renderReportLink(
          report,
          reformatPickerDateFromDB(report.dueDate, false),
        ),
        assignedToFullName: this.getAssigneeFullName(report),
        assignedTo: this.renderReportLink(
          report,
          this.getAssigneeFullName(report),
        ),
        'fault category': this.renderReportLink(report, report.category),
        'fault subcategory': this.renderReportLink(report, report.subcategory),
      }));

    return parsedReport;
  }

  getReportStatus(report, t) {
    const className = `report-status-${report.status}`;

    return <span className={className}>{t(`report:${report.status}`)}</span>;
  }

  getReportCategories(report) {
    if (_isEmpty(report.reportCategories)) {
      return null;
    }

    return report.reportCategories.map((reportCategory) => {
      return (
        <Chip
          key={`report-${report}-category-${reportCategory.id}`}
          label={reportCategory.category}
          className="my-1 mx-2"
        />
      );
    });
  }

  handleSort = (columnName) => {
    const { sortAsc } = this.state.searchSettings;

    this.setState({
      searchSettings: {
        sortAsc: !sortAsc,
        columnToSort: columnName,
      },
    });
  };

  renderFleetLink(fleet, fieldContent) {
    const className = 'table-row-link';

    return (
      <Link className={className} to={`/fleet/details/${fleet}`}>
        {fieldContent}
      </Link>
    );
  }

  renderReportLink(report, fieldContent) {
    const className = 'table-row-link';

    return (
      <Link className={className} to={`/report/${report.id}`}>
        {fieldContent}
      </Link>
    );
  }

  sort(list, column, sortAsc) {
    switch (column) {
      case 'date':
        list.sort((a, b) =>
          sortAsc
            ? sortString(a.source.date, b.source.date)
            : sortString(b.source.date, a.source.date),
        );

        break;
      case 'vehicle':
        list.sort((a, b) =>
          sortAsc
            ? sortString(a.vehicleName, b.vehicleName)
            : sortString(b.vehicleName, a.vehicleName),
        );

        break;
      case 'fault category':
        list.sort((a, b) =>
          sortAsc
            ? sortString(a.source.category, b.source.category)
            : sortString(b.source.category, a.source.category),
        );

        break;
      case 'fault subcategory':
        list.sort((a, b) =>
          sortAsc
            ? sortString(a.source.subcategory, b.source.subcategory)
            : sortString(b.source.subcategory, a.source.subcategory),
        );

        break;
      case 'subject':
        list.sort((a, b) =>
          sortAsc
            ? sortString(a.source.subject, b.source.subject)
            : sortString(b.source.subject, a.source.subject),
        );

        break;
      case 'status':
        list.sort((a, b) =>
          sortAsc
            ? sortString(a.source.status, b.source.status)
            : sortString(b.source.status, a.source.status),
        );

        break;
      case 'dueDate':
        list.sort((a, b) =>
          sortAsc
            ? sortString(a.source.dueDate, b.source.dueDate)
            : sortString(b.source.dueDate, a.source.dueDate),
        );

        break;
      case 'assignedTo':
        list.sort((a, b) =>
          sortAsc
            ? sortString(a.assignedToFullName, b.assignedToFullName)
            : sortString(b.assignedToFullName, a.assignedToFullName),
        );

        break;
    }
  }

  render() {
    const { handleSearch, search, t } = this.props;
    const { columnToSort, sortAsc } = this.state.searchSettings;

    const reports = this.getGroupList();

    this.sort(reports, columnToSort, sortAsc);

    return (
      <Table
        data={reports}
        header={tableHeader}
        handleSort={this.handleSort}
        sortAsc={sortAsc}
        columnToSort={columnToSort}
        handleSearch={handleSearch}
        search={search}
        searchLabel={t('input:searchLabel')}
      />
    );
  }
}
