import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import Loader from '../../../../../common/components/Loader';
import LogbookSummary from './LogbookSummary';
import PreviousFlightHistory from './PreviousFlightsHistory';
import Confirmation from '../../../../../common/components/Confirmation';
import { fetchFlightExperience, editFlightExperience } from '../../../../actions/flightExperience';
import { fetchFlightLogs } from '../../../../actions/flightLog';
import { addSimpleError } from '../../../../actions/errors';
import { resetSaveFlightExperienceSuccess } from '../../../../actions/flightExperience';

import '../../styles.scss';

@withNamespaces()
class flightExperience extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    flightExperienceRequest: PropTypes.bool,
    flightLogsRequest: PropTypes.bool,
    flightExperience: PropTypes.object,
    flightLogList: PropTypes.array,
    fetchFlightExperience: PropTypes.func.isRequired,
    fetchFlightLogs: PropTypes.func.isRequired,
    editFlightExperience: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func,
    resetSaveFlightExperienceSuccess: PropTypes.func.isRequired,
    changeFlightExperienceSuccess: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = {
      previousFlightHistoryIsValid: false,
    };
  }

  componentDidMount() {
    const { userId, fetchFlightExperience, fetchFlightLogs } = this.props;
    const searchData = {
      userId
    };

    fetchFlightExperience(userId);
    fetchFlightLogs(searchData);
  }

  registerComponentForm(componentName) {
    return (triggerFn) => {
      this.triggers[componentName] = triggerFn;
    };
  }

  saveFlightExperience = async () => {
    const { addSimpleError, editFlightExperience, userId } = this.props;

    await this.triggers.previousFlightHistory();

    if (this.formData.previousFlightHistoryIsValid) {
      const userFlightExperience = {
        ...this.formData.previousFlightHistoryChanges,
      };

      editFlightExperience(userId, userFlightExperience);
    } else {
      addSimpleError('Check if all fields are correctly filled in');
    }
  };

 formValidated = (formName, isFormValid, formChanges) => {
   this.formData = {
     ...this.formData,
     ['previousFlightHistoryChanges']: formChanges,
     ['previousFlightHistoryIsValid']: isFormValid,
   };
 };

 renderButtons() {
   const { userId, t } = this.props;
   const pathname = `/users/${userId}`;

   return (
     <div className="justify-content-center row">
       <div className="col-auto">
         <Link to={pathname}>
           <Button variant="raised">
             {t('buttonCancel')}
           </Button>
         </Link>
       </div>
       <div className="col-auto">
         <Button color="primary" variant="raised" onClick={() => this.saveFlightExperience()}>
           {t('buttonSave')}
         </Button>
       </div>
     </div>
   );
 }

 render() {
   const { flightExperienceRequest, flightLogsRequest, flightExperience,
     flightLogList, resetSaveFlightExperienceSuccess, changeFlightExperienceSuccess } = this.props;

   if (flightExperienceRequest || flightLogsRequest) {
     return <Loader />;
   }

   return (
     <div className="col-12">
       <LogbookSummary
         flightLogList={flightLogList}
       />
       { !_isEmpty(flightLogList) ? <Divider className="my-3" /> : <div />}
       <PreviousFlightHistory
         registerComponentForm={this.registerComponentForm('previousFlightHistory')}
         formValidated={this.formValidated}
         flightExperience={flightExperience}
       />
       { this.renderButtons() }
       <Dialog
         open={changeFlightExperienceSuccess}
         onClose={resetSaveFlightExperienceSuccess}
         aria-labelledby="confirmation-assign"
         fullWidth
       >
         <Confirmation
           message={'Changes in flight experience successfully saved'}
           closeConfirmationDialog={resetSaveFlightExperienceSuccess}
         />
       </Dialog>
     </div>
   );
 }
}

const mapStateToProps = (state) => {
  return {
    flightExperience: state.flightExperience.flightExperience,
    flightExperienceRequest: state.flightExperience.flightExperienceRequest,
    flightLogList: state.flightLog.flightLogs,
    flightLogsRequest: state.flightLog.flightLogsRequest,
    changeFlightExperienceSuccess: state.flightExperience.changeFlightExperienceSuccess,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchFlightExperience: (userId) => dispatch(fetchFlightExperience(userId)),
    fetchFlightLogs: (searchData) => dispatch(fetchFlightLogs(searchData)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    editFlightExperience: (userId, flightExperience) => dispatch(editFlightExperience(userId, flightExperience)),
    resetSaveFlightExperienceSuccess: () => dispatch(resetSaveFlightExperienceSuccess()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(flightExperience);

