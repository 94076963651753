export const languages = ['en', 'se'];

export const complementaryCertificateLanguages = ['en', 'sv'];

export const nameSpaces = [
  'authentication',
  'booking',
  'complementaryCertificate',
  'course',
  'crewStatus',
  'dashboard',
  'eLearning',
  'equipment',
  'error',
  'fleet',
  'folder',
  'global',
  'input',
  'logbook',
  'navigation',
  'report',
  'sms',
  'settings',
  'table',
  'test',
  'user',
  'usersCategories',
  'warehouse',
];

export const fallbackLanguage = 'en';
export const defaultNamespace = 'global';
