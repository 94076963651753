import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';

import RemoveDialog from '../../../../common/components/RemoveDialog';
import QuestionList from './QuestionList';

import { fetchQuestions, removeQuestion } from '../../../actions/questions';
import { fetchQuestionCategories } from '../../../actions/questionCategories';
import { PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

const INITIAL_STATE = {
  removeDialogOpen: false,
  removeQuestion: null,
};

const mapStateToProps = (state) => {
  return {
    questionList: state.questions.questionList.filter((question) => question.isActive),
    searchSettings: state.questions.searchSettings,
    questionTotalCount: state.questions.questionTotalCount,
    questionCategoryList: state.questionCategories.questionCategoryList,
    questionRequest: state.questions.questionRequest,
    questionRemoveSuccess: state.questions.questionRemoveSuccess
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchQuestions: (sortData) => dispatch(fetchQuestions(sortData)),
    fetchQuestionCategories: () => dispatch(fetchQuestionCategories()),
    removeQuestion: (question) => dispatch(removeQuestion(question)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.questionListView)
export default class ManageQuestions extends Component {
  static propTypes = {
    questionCategoryList: PropTypes.array,
    questionList: PropTypes.array,
    searchSettings: PropTypes.object.isRequired,
    questionTotalCount: PropTypes.number,
    questionRequest: PropTypes.bool.isRequired,
    questionRemoveSuccess: PropTypes.bool.isRequired,
    fetchQuestions: PropTypes.func.isRequired,
    removeQuestion: PropTypes.func.isRequired,
    fetchQuestionCategories: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { searchSettings } = this.props;

    this.fetchQuestions({ page: searchSettings.page, pageSize: searchSettings.pageSize });
    this.props.fetchQuestionCategories();
  }

  componentDidUpdate(prevProps) {
    const { questionRemoveSuccess, searchSettings } = this.props;

    if (prevProps.questionRemoveSuccess !== questionRemoveSuccess) {
      if (questionRemoveSuccess === true) {
        // reload the table data after removed item
        this.fetchQuestions({ page: searchSettings.page, pageSize: searchSettings.pageSize });
      }
    }
  }

  handleSort = (columnName) => {
    const { searchSettings: { sortAsc } } = this.props;
    const data = { sortAsc: !sortAsc, columnToSort: columnName };

    this.fetchQuestions(data);
  };

  handleSearch = (search) => {
    this.fetchQuestions({ search });
  };

  handleFilter = (values) => {
    const preparedValues = values.filter((value)=> value && value.id);
    const data = { filter: preparedValues, filterIsUsed: !_isEmpty(preparedValues) };

    this.fetchQuestions(data);
  };

  handleChangePage = (page, pageSize) => {
    this.fetchQuestions({ page, pageSize });
  };

  fetchQuestions = (data) => {
    let { searchSettings } = this.props;

    searchSettings.page = 0;
    searchSettings = Object.assign({}, searchSettings, data);
    this.setState({ searchSettings });
    this.props.fetchQuestions(searchSettings);
  }

  removeQuestion = () => {
    const { removeQuestion } = this.state;

    this.closeRemoveDialog();
    this.props.removeQuestion(removeQuestion);
  };

  openRemoveDialog = (question) => {
    this.setState({
      removeDialogOpen: true,
      removeQuestion: question,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeQuestion: null,
    });
  };

  renderRemoveDialog = (t) => {
    return (
      <Dialog
        open={this.state.removeDialogOpen}
        onClose={this.closeRemoveDialog}
        aria-labelledby="remove-dialog"
        fullWidth
      >
        <RemoveDialog
          message={t('test:removeQuestion')}
          closeRemoveDialog={this.closeRemoveDialog}
          dialogTitle={t('test:removeQuestionDialogTitle')}
          removeFunction={this.removeQuestion}
        />
      </Dialog>
    );
  };

  render() {
    const {
      questionRequest, searchSettings, questionList,
      questionTotalCount, questionCategoryList, t
    } = this.props;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">
                list_add_check
              </Icon>
              <h1>{t('test:name')}<span className="h1-subheader"> /{t('test:questionBankSection')}</span></h1>
            </span>
            <p>{t('test:descriptionQuestionBank')}</p>
          </header>
        </div>
        <div className="col-auto">
          <Link to="/question_bank/new">
            <Button color="primary">
              <Icon color="primary" className="mr-3">playlist_add</Icon>
              {t('test:addQuestionButton')}
            </Button>
          </Link>
        </div>
        <div className="col-12">
          <QuestionList
            {...searchSettings}
            loading={questionRequest}
            questionList={questionList}
            questionTotalCount={questionTotalCount}
            questionCategoryList={questionCategoryList}
            handleSort={this.handleSort}
            handleSearch={this.handleSearch}
            handleFilter={this.handleFilter}
            openRemoveDialog={this.openRemoveDialog}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPageSize={this.handleChangePage}
          />
          {this.renderRemoveDialog(t)}
        </div>
      </div>
    );
  }
}
