export const ROLES = {
  admin: 'admin',
  manager: 'manager',
  instructor: 'instructor',
};
export const APPLICATION_TYPE_URL = '/application_type';
const _URLS = {
  base: '/api/v1/',
  practicalFields: '/api/v1/practical_fields/',
  aircrafts: '/api/v1/aircrafts/',
  userSettingsAircrafts: '/api/v1/user_settings_aircrafts/',
  accounts: '/api/v1/accounts/',
  passwordRecovery: '/api/v1/password_recovery/',
  changePassword: '/api/v1/password_change/',
  changePin: '/api/v1/pin_change/',
  gradings: '/api/v1/gradings/',
  schoolGroups: '/api/v1/school_groups/',
  itemTypes: '/api/v1/item_types/',
  locations: '/api/v1/locations/',
  questionCategories: '/api/v1/question_categories/',
  questionTypes: '/api/v1/question_types/',
  groups: '/api/v1/groups/',
  settings: '/api/v1/settings/',
  modules: '/api/v1/modules/',
  users: '/api/v1/users/',
  usersCategoriesFilter: '/api/v1/users_categories_filter/',
  usersProfile: '/api/v1/users_profile/',
  usersProfileUpdate: '/api/v1/users_profile_update/',
  passwordSet: '/api/v1/password_set/',
  ratings: '/api/v1/ratings/',
  courses: '/api/v1/courses/',
  manageCourses: '/api/v1/course_admin/',
  items: '/api/v1/items/',
  lessonTypes: '/api/v1/lesson_types/',
  assignedCourses: '/api/v1/assigned_courses/',
  assignedTheoreticalLessons: '/api/v1/assigned_course_theoretical_lesson/',
  externalCourses: '/api/v1/external_courses/',
  questions: '/api/v1/question_bank/',
  removeQuestion: '/api/v1/remove_question/',
  manageTests: '/api/v1/exam_admin/',
  tests: '/api/v1/tests/',
  assignedTests: '/api/v1/assigned_tests/',
  completedTests: '/api/v1/completed_test/',
  licenses: '/api/v1/licenses/',
  medicals: '/api/v1/medicals/',
  personalFiles: '/api/v1/personal_files/',
  attachProfileImage: '/api/v1/files/users_profile_image/',
  attachRatingFile: '/api/v1/files/rating/',
  attachMedicalFile: '/api/v1/files/medical/',
  attachLicenseFile: '/api/v1/files/license/',
  attachEngineerAircraftTypeExperience:
    '/api/v1/files/engineer_aircraft_type_experience/',
  attachExternalCourseFile: '/api/v1/files/external_course/',
  attachQualificationFile: '/api/v1/files/qualification/',
  attachPersonalFileFile: '/api/v1/files/personal_file/',
  schoolLogoFile: '/api/v1/files/school_logo/',
  itemFile: '/api/v1/files/item/',
  digitalSignatureFile: '/api/v1/files/digital_signature/',
  attachReportFile: '/api/v1/files/report/',
  attachEquipmentFile: '/api/v1/files/equipment/',
  files: '/api/v1/files/',
  fileRemove: '/api/v1/remove_file/',
  attachQuestionFile: '/api/v1/files/question/',
  qualifications: '/api/v1/qualifications/',
  userPermissions: '/api/v1/permissions/',
  allRatings: '/api/v1/all_ratings/',
  allLicenses: '/api/v1/all_licenses/',
  allMedicals: '/api/v1/all_medicals/',
  allQualifications: '/api/v1/all_qualifications/',
  allExternalCourses: '/api/v1/all_external_courses/',
  bookings: '/api/v1/bookings/',
  flightExperience: '/api/v1/flight_experience/',
  flightLog: '/api/v1/flight_log/',
  flightLogEdit: '/api/v1/flight_log/edit/',
  journeyLog: '/api/v1/journey_log/',
  journeyLogEdit: '/api/v1/journey_log/edit/',
  pilotFunction: '/api/v1/pilot_function/',
  userSettings: '/api/v1/user_settings/',
  userSettingsUpdate: '/api/v1/user_settings_update/',
  bookingObjects: '/api/v1/booking_object/',
  bookingRequest: '/api/v1/booking_request/',
  bookingNotification: '/api/v1/bookings_notifications/',
  reportRecipents: '/api/v1/report_recipents/',
  reportCategories: '/api/v1/report_categories/',
  reportFleetCategories: '/api/v1/report_fleet_categories/',
  reports: '/api/v1/reports',
  reportsCreate: '/api/v1/reports_create/',
  types: '/api/v1/types/',
  userSettingsTypes: '/api/v1/types_user_settings/',
  courseCertificate: '/api/v1/course_certificate/',
  testCertificate: '/api/v1/test_certificate/',
  healthCheck: '/api/v1/healthcheck/',
  serviceWorker: '/api/v1/service_worker/',
  equipments: '/api/v1/equipment/',
  folders: '/api/v1/folders/',
  documentTypes: '/api/v1/document_types/',
  emergencyContact: '/api/v1/user_emergency_contact/',

  ratingsPlannedAction: '/api/v1/ratings_planned_action/',
  licensesPlannedAction: '/api/v1/licenses_planned_action/',
  medicalsPlannedAction: '/api/v1/medicals_planned_action/',
  qualificationsPlannedAction: '/api/v1/qualifications_planned_action/',
  externalCoursesPlannedAction: '/api/v1/external_courses_planned_action/',
  personalFilesPlannedAction: '/api/v1/personal_files_planned_action/',

  libraryFile: '/api/v1/files/library/',

  notificationCategories: '/api/v1/notification_categories/',
  notificationRecipients: '/api/v1/notification_recipients/',
  defaultNotificationRecipients: '/api/v1/default_notification_recipients/',

  complementaryCertificates: '/api/v1/complementary_certificates/',

  ratingsDeactivate: '/api/v1/ratings_deactivate/',
  licensesDeactivate: '/api/v1/licenses_deactivate/',
  medicalsDeactivate: '/api/v1/medicals_deactivate/',
  qualificationsDeactivate: '/api/v1/qualifications_deactivate/',
  externalCoursesDeactivate: '/api/v1/external_courses_deactivate/',

  notifyAssignedTest: '/api/v1/notify_assigned_test/',
  notifyAssignedCourse: '/api/v1/notify_assigned_course/',

  aviationWeatherServer: '/api/v1/aviation_weather/',

  advertImage: '/api/v1/files/elearning_image/',

  eLearning: '/api/v1/elearning/',
  eLearningLink: '/api/v1/elearning_link/',

  rssNews: '/api/v1/rss_news/',

  vehicles: '/api/v1/vehicles/',
  vehicleTypes: '/api/v1/vehicle_types/',
  vehicleGroupUsers: '/api/v1/vehicle_group_users/',
  vehicleGroups: '/api/v1/vehicle_groups/',
  fleet: '/api/v1/fleet/',
  usersProfileSimple: '/api/v1/users_profile_simple/',
  myUserProfile: '/api/v1/my_user_profile/',

  competencies: '/api/v1/competencies/',
  staffCategories: '/api/v1/staff_categories/',
  parts: '/api/v1/parts/',
  unmountedParts: '/api/v1/parts/unmounted/',
  partStatuses: '/api/v1/parts/status/',
  attachPartFile: '/api/v1/files/part/',

  warehouse: '/api/v1/warehouse/',
  warehouseConsumables: '/api/v1/warehouse/:id/consumables/',

  userCompetencies: '/api/v1/user_competencies/',

  examCompetencies: '/api/v1/exam_competencies/',

  components: '/api/v1/components/',
  attachComponentFile: '/api/v1/files/component/',

  documents: '/api/v1/documents/',
  attachDocumentFile: '/api/v1/files/document/',
  inspections: '/api/v1/inspections/',
  attachInspectionFile: '/api/v1/files/inspection/',
  services: '/api/v1/services/',
  attachServiceFile: '/api/v1/files/service/',
  installations: '/api/v1/installations/',
  riskAssessments: '/api/v1/risk_assessments/',
  engineerAircraftType: '/api/v1/engineer/aircraft_type/',
  engineerAircraftTypeExperience: '/api/v1/engineer/aircraft_type_experience/',
  engineerAllAircraftTypeExperience:
    '/api/v1/engineer/all_aircraft_type_experience/',
  engineerAircraftTypeExperienceDeactivate:
    '/api/v1/engineer/aircraft_type_experience_deactivate/',
};

export const URLS = Object.keys(_URLS).reduce((dict, url) => {
  dict[url] = `/a${_URLS[url]}`;

  return dict;
}, {});
export const URLS_RAIL = Object.keys(_URLS).reduce((dict, url) => {
  dict[url] = `/r${_URLS[url]}`;

  return dict;
}, {});

export const CHOICE_THROTTLING = 1000;
export const SEARCH_THROTTLING = 500;
export const ERROR_AUTO_HIDE = 5000;
export const CLOSE_USER_FILTERING_TABLE_DELAY = 1000;
export const EDITOR_OPTIONS = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'list',
  'textAlign',
  'colorPicker',
  'link',
  'embedded',
  'remove',
  'history',
];
export const TABS = {
  active: 'active',
  inactive: 'inactive',
  completed: 'completed',
};
export const DRAG_TYPES = {
  item: 'item',
  itemSortable: 'itemSortable',
  lesson: 'lesson',
};
export const USER_PROFILE_TABS = {
  profile: 'profile',
  courses: 'courses',
  tests: 'tests',
};
export const USER_PROFILE_TABS_RAIL = {
  profile: 'profile',
  courses: 'education',
  tests: 'tests',
};
export const USER_TABS = {
  account: 'account',
  settings: 'settings',
  flightExp: 'flight experience',
  staffCategories: 'staff categories',
  emergencyContacts: 'emergency contacts',
};
export const QUESTION_TYPES = {
  date: 'D',
  text: 'TXT',
  list: 'L',
  time: 'T',
  datetime: 'DT',
  number: 'NO',
};
export const LESSON_TYPES = {
  practical: 'PR',
  theoretical: 'TH',
  progressCheck: 'PC',
};
export const MAX_FILE_SIZE = 150000000;
export const MAX_EMAIL_FILE_SIZE = 1000000;
export const ALLOWED_IMG_FILES_EXT = ['jpg', 'jpeg', 'png', 'gif'];
export const ALLOWED_FILES_EXT = [
  'avi',
  'bmp',
  'doc',
  'gif',
  'html',
  'ico',
  'icon',
  'img',
  'jpg',
  'jpeg',
  'mov',
  'mp3',
  'mpe',
  'mpg',
  'mpeg',
  'pdf',
  'php',
  'php3',
  'png',
  'pps',
  'ppt',
  'rar',
  'swf',
  'tar',
  'tif',
  'tiff',
  'txt',
  'url',
  'wav',
  'wave',
  'xls',
  'zip',
  'odt',
  'ods',
  'odp',
  'odg',
  'odf',
  'odb',
  'ppt',
  'pptx',
  'docx',
  'xlsx',
];
export const DEPENDENT_NAMES_LIST = ['day', 'night', 'vfr', 'ifr'];
export const DEPENDENT_NAMES_DICTIONARY = {
  day: 'night',
  night: 'day',
  ifr: 'vfr',
  vfr: 'ifr',
};

export const AIRCRAFT_TYPES = {
  airplane: 'airplane',
  helicopter: 'helicopter',
  simulator: 'simulator',
};
export const AIRCRAFT_TYPE_CHOICES = Object.values(AIRCRAFT_TYPES);

export const AIRCRAFT_PILOTS = {
  singlePilot: 'singlePilot',
  multiPilot: 'multiPilot',
};
export const AIRCRAFT_PILOT_CHOICES = Object.values(AIRCRAFT_PILOTS);

export const AIRCRAFT_ENGINES = {
  singleEngine: 'singleEngine',
  multiEngine: 'multiEngine',
};
export const AIRCRAFT_ENGINE_CHOICES = Object.values(AIRCRAFT_ENGINES);

export const AIRPLANE_PROPULSIONS = {
  jet: 'jet',
  piston: 'piston',
  turboprop: 'turboprop',
};
export const AIRPLANE_PROPULSION_CHOICES = Object.values(AIRPLANE_PROPULSIONS);

export const HELICOPTER_PROPULSIONS = { piston: 'piston', turbine: 'turbine' };
export const HELICOPTER_PROPULSION_CHOICES = Object.values(
  HELICOPTER_PROPULSIONS,
);

export const CONDITION_TIME = { day: 'day', night: 'night' };
export const CONDITION_TIME_CHOICES = Object.values(CONDITION_TIME);

export const FLIGHT_RULE = { vfr: 'vfr', ifr: 'ifr' };
export const FLIGHT_RULE_CHOICES = Object.values(FLIGHT_RULE);

export const ZERO_TIME = '00:00';
export const DAY_IN_MINUTES = 1440;

export const INSTRUCTOR_ONLY = 'instructor only';
export const SIMULATOR_TYPE = 'Simulator';
export const CO_PILOT = 'co-pilot';
export const SELF = 'SELF';

export const TOTAL_ROW = 'TOTAL';

// table pagination
export const PAGE_SIZES = [10, 25, 50, 100];
export const DEFAULT_PAGE_NUMBER = 0;
export const DEFAULT_PAGE_SIZE = 25;

export const DATE_FORMAT_EU = 'YYYY-MM-DD';
export const DATE_FORMAT_US = 'MM/DD/YYYY';
export const TIME_FORMAT_EU = 'HH:mm';
export const TIME_FORMAT_US = 'hh:mm a';
export const DATETIME_FORMAT_EU = `${DATE_FORMAT_EU} ${TIME_FORMAT_EU}`;
export const DATETIME_FORMAT_US = `${DATE_FORMAT_US} ${TIME_FORMAT_US}`;
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const DASHBOARD_DATE_FORMAT = 'DD LL, YYYY';
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';

// change colors
export const BOOKING_EVENT_TYPE = {
  currentUser: 'currentUser',
  currentUserDisabled: 'currentUserDisabled',
  otherUser: 'otherUser',
  otherUserDisabled: 'otherUserDisabled',
};

export const PRACTICAL_LESSON_DATETIME_FIELDS = {
  blockOff: 'blockoff',
  takeOff: 'takeoff',
  landing: 'landing',
  blockOn: 'blockon',
};

export const FLIGHT_LOG_OPTION = {
  pilotFunction: 'pilotFunction',
  pilotFunctionInstructor: 'pilotFunctionInstructor',
  aircraft: 'aircraft',
  piston: 'piston',
};

export const BOOKING_TYPE = {
  OWN: 'own',
  PRIVATE: 'private',
  FLIGHT_WITH: 'flight_with',
  MAINTENANCE: 'maintenance',
  OTHER: 'other',
};

export const S3_FRONTEND_URL =
  'https://s3-eu-west-1.amazonaws.com/qrosscheck-static/frontend/';
