import { capitalizeFirstLetter, urlJoin } from '../../utils';

import { URLS } from '../../constants';
import _get from 'lodash/get';
import { addError } from './errors';
import axios from 'axios';

export const FETCH_GRADINGS_REQUEST = 'FETCH_GRADINGS_REQUEST';
export const FETCH_GRADINGS_SUCCESS = 'FETCH_GRADINGS_SUCCESS';
export const FETCH_GRADINGS_FAILURE = 'FETCH_GRADINGS_FAILURE';
export const ADD_GRADING = 'ADD_GRADING';
export const EDIT_GRADING = 'EDIT_GRADING';
export const REMOVE_GRADING = 'REMOVE_GRADING';

export function fetchGradingsRequest() {
  return {
    type: FETCH_GRADINGS_REQUEST,
  };
}

export function fetchGradingsSuccess(gradings) {
  return {
    type: FETCH_GRADINGS_SUCCESS,
    payload: {
      gradings,
    },
  };
}

export function fetchGradingsFailure() {
  return {
    type: FETCH_GRADINGS_FAILURE,
  };
}

export function addGradingSuccess(grading) {
  return {
    type: ADD_GRADING,
    payload: {
      grading,
    },
  };
}

export function editGradingSuccess(grading) {
  return {
    type: EDIT_GRADING,
    payload: {
      grading,
    },
  };
}

export function removeGradingSuccess(grading) {
  return {
    type: REMOVE_GRADING,
    payload: {
      grading,
    },
  };
}

export function fetchGradings() {
  return (dispatch) => {
    dispatch(fetchGradingsRequest());

    return axios
      .get(URLS.gradings)
      .then(({ data }) => dispatch(fetchGradingsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchGradingsFailure());
        dispatch(
          addError(`Error during gradings fetching ${error.message}`, error),
        );
      });
  };
}

export function addGrading(grading) {
  return (dispatch) => {
    return axios
      .post(URLS.gradings, grading)
      .then(({ data }) => dispatch(addGradingSuccess(data)))
      .catch((error) => {
        const errorData = _get(
          error,
          'response.data.name[0]',
          `error during grading adding ${error.message}`,
        );

        dispatch(addError(capitalizeFirstLetter(errorData), error));
      });
  };
}

export function editGrading(grading) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.gradings, grading.id), {
        name: grading.name,
        options: grading.options,
      })
      .then(({ data }) => dispatch(editGradingSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during grading editing ${error.message}`, error),
        );
      });
  };
}

export function removeGrading(grading) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.gradings, grading.id))
      .then(() => dispatch(removeGradingSuccess(grading)))
      .catch((error) => {
        dispatch(
          addError(`Error during grading removing ${error.message}`, error),
        );
      });
  };
}
