import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
});

@withNamespaces()
@withStyles(styles)
export default class ReportCategoryList extends Component {
  static propTypes = {
    reportCategoryList: PropTypes.array,
    removeReportCategory: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderReportCategoryList = (reportCategoryList) => {
    const { removeReportCategory, handleEditOpen, onElementDelete } = this.props;

    return reportCategoryList.map((reportCategory) => {
      return (
        <TableRow key={`report-category-${reportCategory.id}`}>
          <TableCell>{reportCategory.category}</TableCell>
          <TableCell>{reportCategory.firstName}</TableCell>
          <TableCell>{reportCategory.lastName}</TableCell>
          <TableCell>{reportCategory.email}</TableCell>
          <TableCell numeric>
            <IconButton onClick={() => handleEditOpen(reportCategory)}><Icon color="primary">mode_edit</Icon></IconButton>
            <IconButton onClick={() => onElementDelete(() => removeReportCategory(reportCategory))}><Icon color="primary">delete</Icon></IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { classes, handleAddOpen, reportCategoryList, t } = this.props;

    if (_isEmpty(reportCategoryList)) {
      return (
        <div className="pl-sm-5">
          <Button color="primary" onClick={handleAddOpen}>
            {t('settings:addReportCategoryButton')}
          </Button>
        </div>
      );
    }

    return (
      <div className="pl-sm-5">
        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table:category')}</TableCell>
                  <TableCell>{t('table:firstName')}</TableCell>
                  <TableCell>{t('table:lastName')}</TableCell>
                  <TableCell>{t('table:email')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderReportCategoryList(reportCategoryList)}
              </TableBody>
            </Table>
          </div>
        </div>
        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addReportCategoryButton')}
        </Button>
      </div>
    );
  }
}
