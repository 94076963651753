import { URLS_RAIL as URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { urlJoin } from '../../utils';

export const FETCH_VEHICLE_GROUPS_REQUEST = 'FETCH_VEHICLE_GROUPS_REQUEST';
export const FETCH_VEHICLE_GROUPS_SUCCESS = 'FETCH_VEHICLE_GROUPS_SUCCESS';
export const FETCH_VEHICLE_GROUPS_FAILURE = 'FETCH_VEHICLE_GROUPS_FAILURE';
export const ADD_VEHICLE_GROUP = 'ADD_VEHICLE_GROUP';
export const EDIT_VEHICLE_GROUP = 'EDIT_VEHICLE_GROUP';
export const REMOVE_VEHICLE_GROUP = 'REMOVE_VEHICLE_GROUP';

export function fetchVehicleGroupsRequest() {
  return {
    type: FETCH_VEHICLE_GROUPS_REQUEST,
  };
}

export function fetchVehicleGroupsSuccess(vehicleGroups) {
  return {
    type: FETCH_VEHICLE_GROUPS_SUCCESS,
    payload: {
      vehicleGroups,
    },
  };
}

export function fetchVehicleGroupsFailure() {
  return {
    type: FETCH_VEHICLE_GROUPS_FAILURE,
  };
}

export function addVehicleGroupSuccess(vehicleGroup) {
  return {
    type: ADD_VEHICLE_GROUP,
    payload: {
      vehicleGroup,
    },
  };
}

export function editVehicleGroupSuccess(vehicleGroup) {
  return {
    type: EDIT_VEHICLE_GROUP,
    payload: {
      vehicleGroup,
    },
  };
}

export function removeVehicleGroupSuccess(vehicleGroup) {
  return {
    type: REMOVE_VEHICLE_GROUP,
    payload: {
      vehicleGroup,
    },
  };
}

export function fetchVehicleGroups() {
  return (dispatch) => {
    dispatch(fetchVehicleGroupsRequest());

    return axios
      .get(URLS.vehicleGroups)
      .then(({ data }) => dispatch(fetchVehicleGroupsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchVehicleGroupsFailure());
        dispatch(
          addError(`Error during fleet group fetching ${error.message}`, error),
        );
      });
  };
}

export function addVehicleGroup(vehicleGroup) {
  return (dispatch) => {
    return axios
      .post(URLS.vehicleGroups, vehicleGroup)
      .then(({ data }) => dispatch(addVehicleGroupSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during fleet groups adding ${error.message}`, error),
        );
      });
  };
}

export function removeVehicleGroup(vehicleGroup) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.vehicleGroups, vehicleGroup.id))
      .then(() => {
        dispatch(removeVehicleGroupSuccess(vehicleGroup));
      })
      .catch((error) => {
        dispatch(
          addError(
            `Error during fleet groups removing ${error.message}`,
            error,
          ),
        );
      });
  };
}
