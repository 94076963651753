import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';

import Icon from '@material-ui/core/Icon';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import './styles.scss';

@withNamespaces()
export default class ElearningList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    eLearningList: PropTypes.array.isRequired,
    fetchElearningLink: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: _get(this.props, 'columnToSort', ''),
      sortAsc: _get(this.props, 'sortAsc', ''),
      search: _get(this.props, 'search', ''),
    }
  };

  getCourseStatus = (eLearning) => {
    const { t } = this.props;

    if (eLearning.startDate) {
      if (eLearning.endDate) {
        return (
          <h5 className={'item-completed'}>
            {t('course:completed')}
          </h5>
        );
      }

      return (
        <h5 className={'item-pending'}>
          {t('course:inProgress')}
        </h5>);
    }

    return (
      <h5 className={'item-not-completed'}>
        {t('course:notStarted')}
      </h5>
    );
  };

  renderElearningList() {
    const {
      eLearningList
    } = this.props;

    const documentName = 'license';

    return eLearningList.map((eLearning) => {
      return (
        <TableRow key={`${documentName}-${eLearning.id}`}>
          <TableCell >{eLearning.title}</TableCell>
          <TableCell >{eLearning.description}</TableCell>
          <TableCell >{eLearning.startDate}</TableCell>
          <TableCell >{eLearning.endDate}</TableCell>
          <TableCell >{this.getCourseStatus(eLearning)}</TableCell>
          <TableCell />
        </TableRow>
      );
    });
  }

  render() {
    const { t, buttonText } = this.props;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">
                school
              </Icon>
              <h1>{t('eLearning:name')}</h1>
            </span>
            <p>{t('eLearning:description')}</p>
          </header>
        </div>
        <div className="col-auto eLearning-link">
          <Button color="primary" onClick={this.props.fetchElearningLink}>
            <Icon color="primary" className="mr-3">laptop_chromebook</Icon>
            {buttonText}
          </Button>
        </div>
        <div className="col-12 my-5">
          <Table className="eLearning">
            <TableHead>
              <TableRow>
                <TableCell>{t('table:name')}</TableCell>
                <TableCell>{t('table:description')}</TableCell>
                <TableCell>{t('table:startDate')}</TableCell>
                <TableCell>{t('table:endDate')}</TableCell>
                <TableCell>{t('table:status')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderElearningList()}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}
