import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import Table from '../../../common/components/Table';
import { connect } from 'react-redux';
import { fetchRiskAssessments, removeRiskAssessment } from '../../actions/riskAssessments';
import { withNamespaces } from 'react-i18next';
import RemoveDialog from '../../../common/components/RemoveDialog';


const TABLE_HEADERS = [
  {
    prop: 'id',
    sort: false,
    profileView: false
  },
  {
    prop: 'title',
    sort: false,
    profileView: false
  },
  {
    prop: 'description',
    sort: false,
    profileView: false
  },
  {
    prop: 'date',
    sort: false,
    profileView: false
  },
  {
    prop: 'mitigated',
    sort: false,
    profileView: false
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true,
    iconWidth: true
  }
];

const mapStateToProps = (state) => {
  const { riskAssessmentRequest, riskAssessmentList } = state.riskAssessments;

  return {
    riskAssessmentList,
    riskAssessmentRequest
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchRiskAssessments: () => dispatch(fetchRiskAssessments()),
    removeRiskAssessment: (riskAssessment) => dispatch(removeRiskAssessment(riskAssessment))
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class RiskManagement extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    riskAssessmentList: PropTypes.array.isRequired,
    fetchRiskAssessments: PropTypes.func.isRequired,
    removeRiskAssessment: PropTypes.func.isRequired,
  };

  state = {
    removeDialogOpen: false
  }

  componentDidMount() {
    const { fetchRiskAssessments } = this.props;

    fetchRiskAssessments();
  }

  handleSearch() {
  }

  search() {
  }

  openRemoveDialog = (risk) => {
    this.setState({
      removeDialogOpen: true,
      riskAssessmentToBeRemove: risk,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      riskAssessmentToBeRemove: null,
    });
  };

  riskAsLink(risk, title) {
    return (
      <Link className="table-row-link" to={`/sms/risk-assessments/${risk.id}`}>{title}</Link>
    );
  }

  riskAssessmentsAsData() {
    const { riskAssessmentList, t } = this.props;

    return riskAssessmentList.map((risk) => {

      const { id, title, description, date } = risk;

      return {
        id: this.riskAsLink(risk, id),
        description: this.riskAsLink(risk, description),
        date,
        icons: this.renderIcons(risk),
        title: this.riskAsLink(risk, title),
        mitigated: t(_isEmpty(risk.mitigates) ? 'sms:no' : 'sms:yes')
      };
    });
  }

  removeRisk = () => {
    const { removeRiskAssessment } = this.props;
    const { riskAssessmentToBeRemove } = this.state;

    removeRiskAssessment(riskAssessmentToBeRemove);
    this.closeRemoveDialog();
  };

  renderDeleteIcon(risk) {
    return (
      <IconButton
        key="remove-icon"
        onClick={() => this.openRemoveDialog(risk)}
      >
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  renderEditIcon(risk) {
    return (
      <Link key="fleet-button-edit" to={`/sms/risk-assessments/${risk.id}`}>
        <IconButton>
          <Icon color="primary">
            mode_edit
          </Icon>
        </IconButton>
      </Link>
    );
  }

  renderIcons(risk) {
    return [
      this.renderEditIcon(risk),
      this.renderDeleteIcon(risk),
    ];
  }

  renderRiskAssessments() {
    const { t } = this.props;

    const { handleSearch, search } = this;

    return (
      <div className="col-12">
        <Table
          data={this.riskAssessmentsAsData()}
          header={TABLE_HEADERS}
          handleSearch={handleSearch}
          search={search}
          searchLabel={t('input:searchLabel')}
        />
      </div>
    );
  }

  render() {

    const { t } = this.props;
    const { removeDialogOpen, riskAssessmentToBeRemove } = this.state;

    return (
      <div className="row justify-content-lg-between justify-content-end">
        <div className="col-lg-12 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">article</Icon>
              <h1>{t('sms:Safety Management System')}</h1>
            </span>
            <p>{t('sms:smsDescription')}</p>
          </header>
          <div class="text-right">
            <Link to={{ pathname: '/sms/risk-assessments/' }}>
              <Button color="primary">+ {t('sms:Add risk assessment')}</Button>
            </Link>
          </div>
        </div>
        { this.renderRiskAssessments() }
        <Dialog
          open={removeDialogOpen}
          onClose={this.closeRemoveDialog}
          aria-labelledby="remove-dialog"
          fullWidth
        >
          <RemoveDialog
            message={t(`sms:Are you sure to remove risk assessment with id ${ riskAssessmentToBeRemove ? riskAssessmentToBeRemove.id : ''}?`)}
            closeRemoveDialog={this.closeRemoveDialog}
            dialogTitle={t('fleet:Remove risk assessment')}
            removeFunction={this.removeRisk}
          />
        </Dialog>
      </div>
    );
  }
}
