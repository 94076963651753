import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import TabContainer from '../../../common/components/TabContainer';
import UserProfile from '../Users/UserProfile/UserProfile';
import MyCourses from '../Training/MyCourses';
import MyTests from '../Tests/MyTests';
import { URLS, USER_PROFILE_TABS } from '../../../constants';
import { PERMISSIONS } from '../../../constants/permissions';
import { componentPermission } from '../../decorators/permissions';


const tabs = {
  [USER_PROFILE_TABS.profile]: (userId, editMode) => {
    return (
      <TabContainer>
        <UserProfile
          userId={userId}
          editMode={editMode}
        />
      </TabContainer>
    );
  },
  [USER_PROFILE_TABS.courses]: (userId) => {
    return (
      <TabContainer>
        <MyCourses
          userId={userId}
          isUserProfile
        />
      </TabContainer>
    );
  },
  [USER_PROFILE_TABS.tests]: (userId) => {
    return (
      <TabContainer>
        <MyTests
          userId={userId}
          isUserProfile
        />
      </TabContainer>
    );
  },
};

@withNamespaces()
@componentPermission(PERMISSIONS.myProfileView)
export default class UserProfileTabsView extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    editMode: PropTypes.bool.isRequired,
    isMyProfile: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  state = {
    currentTab: USER_PROFILE_TABS.profile,
    anchorEl: null,
  };

  componentDidMount() {
    this.overridePrintBehavior();
  }

  componentWillUnmount() {
    this.restoreDefaultPrintBehavior();
  }

  handleMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {

    this.setState({ anchorEl: null });
  };

  handlePrint = (url, userId) => {
    this.handleMenuClose();

    const userHistoryLink = `${URLS.base}${url}/${userId}/`;

    window.open(userHistoryLink, '_blank');
  };

  restoreDefaultPrintBehavior = () => {
    const element = document.getElementById('global-print-icon');

    if (element) {
      element.removeEventListener('click', this.handleMenuClick, false);
    }
  };

  overridePrintBehavior = () => {
    const element = document.getElementById('global-print-icon');

    if (element) {
      element.addEventListener('click', this.handleMenuClick, false);
    }
  };


  handleChange = (event, value) => {
    this.setState({
      currentTab: value
    });
  };

  renderTabsContent = () => {
    const { currentTab } = this.state;
    const { t } = this.props;

    return (
      <div className="pt-5">
        <Paper>
          <Tabs
            value={currentTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value={USER_PROFILE_TABS.profile} label={t('profile')} />
            <Tab value={USER_PROFILE_TABS.courses} label={t('courses')} />
            <Tab value={USER_PROFILE_TABS.tests} label={t('exams')} />
          </Tabs>
        </Paper>
        { tabs[currentTab](this.props.userId, this.props.editMode) }
      </div>
    );
  };

  renderMyProfileContent = () => {
    const { userId } = this.props;

    return (
      <UserProfile
        userId={userId}
        editMode
        myProfile
      />
    );
  };

  render() {
    const { isMyProfile = false, t, userId } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        { isMyProfile ? this.renderMyProfileContent() : this.renderTabsContent() }
        <Menu
          id="print-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.handleMenuClose()}
        >
          <MenuItem onClick={() => this.handlePrint('user_history_basic', userId)}>{t('user:basicHistory')}</MenuItem>
          <MenuItem onClick={() => this.handlePrint('user_history', userId)}>{t('user:extendedHistory')}</MenuItem>
        </Menu>
      </div>
    );
  }
}
