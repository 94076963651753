import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

export function initializeReportCategories(report) {
  const reportCategories = _get(report, 'reportCategories', {});

  if (_isEmpty(reportCategories)) {
    return {};
  }

  return reportCategories.reduce((result, category) => {
    result[category.id.toString()] = true;

    return result;
  }, {});
}
