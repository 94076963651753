import axios from 'axios';

import { addError } from './errors';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_GRADINGS_REQUEST = 'FETCH_GRADINGS_REQUEST';
export const FETCH_GRADINGS_SUCCESS = 'FETCH_GRADINGS_SUCCESS';
export const FETCH_GRADINGS_FAILURE = 'FETCH_GRADINGS_FAILURE';


export function fetchGradingsRequest() {
  return {
    type: FETCH_GRADINGS_REQUEST
  };
}

export function fetchGradingsSuccess(gradings) {
  return {
    type: FETCH_GRADINGS_SUCCESS,
    payload: {
      gradings
    }
  };
}

export function fetchGradingsFailure() {
  return {
    type: FETCH_GRADINGS_FAILURE
  };
}

export function fetchGradings() {
  return (dispatch) => {
    dispatch(fetchGradingsRequest());

    return axios.get(URLS.gradings)
      .then(({ data }) => dispatch(fetchGradingsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchGradingsFailure());
        dispatch(addError(`Error during gradings fetching ${error.message}`, error));
      });
  };
}
