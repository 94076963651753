import {
  FLIGHT_LOG_OPTION,
  INSTRUCTOR_ONLY,
  ZERO_TIME,
} from '../../../../constants/index';
import {
  reformatPickerDateTimeToDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import _get from 'lodash/get';

const options = {
  [FLIGHT_LOG_OPTION.pilotFunction]: (name, pilotFunction) => {
    return name === pilotFunction;
  },
  [FLIGHT_LOG_OPTION.pilotFunctionInstructor]: (
    pilotFunction,
    instructorCheck,
  ) => {
    return pilotFunction === INSTRUCTOR_ONLY || instructorCheck;
  },
  [FLIGHT_LOG_OPTION.aircraft]: (option, name) => {
    return option === name;
  },
  [FLIGHT_LOG_OPTION.piston]: (type, currentType, option, name) => {
    return type === currentType && option === name;
  },
};

export function getDataFromList(id, list, name, userSettings) {
  const selectedId = id ? id : _get(userSettings, name, '');

  return list.find((data) => {
    return data.id.toString() === selectedId.toString();
  });
}

export function getCheckedData(condition, totalTime) {
  return condition ? totalTime : ZERO_TIME;
}

export function addFlightLogFunction(data) {
  const aircraftData = getDataFromList(
    data.aircraft,
    data.aircraftList,
    'aircraft.id',
    data.userSettings,
  );
  const pilotFunctionData = getDataFromList(
    data.pilotFunction,
    data.pilotFunctionList,
    'pilotFunction.id',
    data.userSettings,
  );

  let flightLog = {
    userId: data.userId,
    day: _get(data, 'day', ZERO_TIME),
    night: _get(data, 'night', ZERO_TIME),
    vfr: _get(data, 'vfr', ZERO_TIME),
    ifr: _get(data, 'ifr', ZERO_TIME),
    xCountry: _get(data, 'xCountry', ZERO_TIME),
    totalTime: _get(data, 'totalTime', null),
    dayLandings: Number(_get(data, 'dayLandings', 0)),
    nightLandings: Number(_get(data, 'nightLandings', 0)),
    remarks: _get(data, 'remarks', ''),
    departure: _get(data, 'departure', ''),
    arrival: _get(data, 'arrival', ''),
  };

  if (aircraftData) {
    flightLog = {
      ...flightLog,
      aircraftId: aircraftData.id,
      singlePilot: getCheckedData(
        options.aircraft(aircraftData.pilot, 'singlePilot'),
        data.totalTime,
      ),
      multiPilot: getCheckedData(
        options.aircraft(aircraftData.pilot, 'multiPilot'),
        data.totalTime,
      ),
      singleEngine: getCheckedData(
        options.aircraft(aircraftData.engine, 'singleEngine'),
        data.totalTime,
      ),
      multiEngine: getCheckedData(
        options.aircraft(aircraftData.engine, 'multiEngine'),
        data.totalTime,
      ),
      airplane: getCheckedData(
        options.aircraft(aircraftData.type, 'airplane'),
        data.totalTime,
      ),
      helicopter: getCheckedData(
        options.aircraft(aircraftData.type, 'helicopter'),
        data.totalTime,
      ),
      simulator: getCheckedData(
        options.aircraft(aircraftData.type, 'aimulator'),
        data.totalTime,
      ),
      turboprop: getCheckedData(
        options.aircraft(aircraftData.propulsion, 'turboprop'),
        data.totalTime,
      ),
      jet: getCheckedData(
        options.aircraft(aircraftData.propulsion, 'jet'),
        data.totalTime,
      ),
      turbine: getCheckedData(
        options.aircraft(aircraftData.propulsion, 'turbine'),
        data.totalTime,
      ),
      pistonAirplane: getCheckedData(
        options.piston(
          aircraftData.type,
          'airplane',
          aircraftData.propulsion,
          'piston',
        ),
        data.totalTime,
      ),
      pistonHelicopter: getCheckedData(
        options.piston(
          aircraftData.type,
          'helicopter',
          aircraftData.propulsion,
          'piston',
        ),
        data.totalTime,
      ),
    };
  }

  if (pilotFunctionData) {
    flightLog = {
      ...flightLog,
      pilotFunction: data.pilotFunction,
    };
  }

  if (data.departureTime) {
    flightLog = {
      ...flightLog,
      departureDate: reformatPickerDateTimeToDB(data.departureTime),
    };
  }

  if (data.arrivalTime) {
    flightLog = {
      ...flightLog,
      arrivalDate: reformatPickerDateTimeToDB(data.arrivalTime),
    };
  }

  if (data.dateOfFlight) {
    flightLog = {
      ...flightLog,
      dateOfFlight: reformatPickerDateToDB(data.dateOfFlight),
    };
  }

  if (data.additionalPilot) {
    flightLog = {
      ...flightLog,
      additionalPilotId: Number(data.additionalPilot),
      additionalPilotFunction: data.additionalPilotFunction,
    };
  }

  return flightLog;
}
