/* eslint-disable promise/no-nesting */

import axios from 'axios';
import qs from 'qs';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';
import { removeCompetenceFromStaffCategory } from './staffCategories';
import _isEmpty from 'lodash/isEmpty';

export const FETCH_COMPETENCIES_REQUEST = 'FETCH_COMPETENCIES_REQUEST';
export const FETCH_COMPETENCIES_SUCCESS = 'FETCH_COMPETENCIES_SUCCESS';
export const FETCH_COMPETENCIES_FAILURE = 'FETCH_COMPETENCIES_FAILURE';
export const ADD_COMPETENCE = 'ADD_COMPETENCE';
export const REMOVE_COMPETENCE = 'REMOVE_COMPETENCE';


export function fetchCompetenciesRequest() {
  return {
    type: FETCH_COMPETENCIES_REQUEST
  };
}

export function fetchCompetenciesSuccess(competencies) {
  return {
    type: FETCH_COMPETENCIES_SUCCESS,
    payload: {
      competencies
    }
  };
}

export function fetchCompetenciesFailure() {
  return {
    type: FETCH_COMPETENCIES_FAILURE
  };
}

export function addCompetenceSuccess(competence) {
  return {
    type: ADD_COMPETENCE,
    payload: {
      competence
    }
  };
}

export function removeCompetenceSuccess(competence) {
  return {
    type: REMOVE_COMPETENCE,
    payload: {
      competence
    }
  };
}

export function fetchCompetencies(sortingData) {
  return (dispatch) => {
    dispatch(fetchCompetenciesRequest());

    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    queryParameters = qs.stringify(queryParameters);
    queryParameters = _isEmpty(queryParameters) ? '' : `?${queryParameters}`;

    return axios.get(urlJoin(URLS.competencies, queryParameters))
      .then(({ data }) => dispatch(fetchCompetenciesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchCompetenciesFailure());
        dispatch(addError(`Error during competencies fetching ${error.message}`, error));
      });
  };
}

export function addCompetence(competence) {
  return (dispatch) => {
    return axios.post(URLS.competencies, competence)
      .then(({ data }) => dispatch(addCompetenceSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error competence adding ${error.message}`, error));
      });
  };
}

export function removeCompetence(competence) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.competencies, competence.id))
      .then(() => {
        dispatch(removeCompetenceSuccess(competence));
        dispatch(removeCompetenceFromStaffCategory(competence));
      })
      .catch((error) => {
        dispatch(addError(`Error competence removing ${error.message}`, error));
      });
  };
}
