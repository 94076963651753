import {
  FETCH_EMERGENCY_CONTACT_LIST_SUCCESS,
  FETCH_EMERGENCY_CONTACT_LIST_REQUEST,
  ADD_EMERGENCY_CONTACT,
  EDIT_EMERGENCY_CONTACT,
  CLEAN_EMERGENCY_CONTACT_DATA,
  REMOVE_EMERGENCY_CONTACT,
} from '../actions/emergencyContact';

const INITIAL_STATE = {
  emergencyContactList: [],
  emergencyContactRequest: false,
};

function fetchEmergencyContactList(state, payload) {
  return {
    ...state,
    emergencyContactList: payload.emergencyContactList,
    emergencyContactRequest: false
  };
}

function fetchEmergencyContactListRequest(state) {
  return {
    ...state,
    emergencyContactRequest: true
  };
}

function addEmergencyContact(state, payload) {
  return {
    ...state,
    emergencyContactList: [
      ...state.emergencyContactList,
      payload.emergencyContact
    ],
  };
}

function cleanEmergencyContact(state) {
  return {
    ...state,
    emergencyContact: null
  };
}

function editEmergencyContact(state, payload) {
  return {
    ...state,
    emergencyContactList: state.emergencyContactList.map((emergencyContact) => {
      if (emergencyContact.id === payload.emergencyContact.id) {
        return payload.emergencyContact;
      }

      return emergencyContact;
    })
  };
}

function removeEmergencyContact(state, payload) {
  return {
    ...state,
    emergencyContactList: state.emergencyContactList.filter((emergencyContact) => emergencyContact.id !== payload.emergencyContact.id),
  };
}


const actions = {
  [FETCH_EMERGENCY_CONTACT_LIST_SUCCESS]: fetchEmergencyContactList,
  [FETCH_EMERGENCY_CONTACT_LIST_REQUEST]: fetchEmergencyContactListRequest,
  [ADD_EMERGENCY_CONTACT]: addEmergencyContact,
  [EDIT_EMERGENCY_CONTACT]: editEmergencyContact,
  [CLEAN_EMERGENCY_CONTACT_DATA]: cleanEmergencyContact,
  [REMOVE_EMERGENCY_CONTACT]: removeEmergencyContact,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
