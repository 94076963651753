import axios from 'axios';

import { addError } from './errors';
import { URLS } from '../../constants';

export const FETCH_QUESTION_TYPES_REQUEST = 'FETCH_QUESTION_TYPES_REQUEST';
export const FETCH_QUESTION_TYPES_SUCCESS = 'FETCH_QUESTION_TYPES_SUCCESS';
export const FETCH_QUESTION_TYPES_FAILURE = 'FETCH_QUESTION_TYPES_FAILURE';


export function fetchQuestionTypesRequest() {
  return {
    type: FETCH_QUESTION_TYPES_REQUEST
  };
}

export function fetchQuestionTypesSuccess(questionTypes) {
  return {
    type: FETCH_QUESTION_TYPES_SUCCESS,
    payload: {
      questionTypes
    }
  };
}

export function fetchQuestionTypesFailure() {
  return {
    type: FETCH_QUESTION_TYPES_FAILURE
  };
}

export function fetchQuestionTypes() {
  return (dispatch) => {
    dispatch(fetchQuestionTypesRequest());

    return axios.get(URLS.questionTypes)
      .then(({ data }) => dispatch(fetchQuestionTypesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchQuestionTypesFailure());
        dispatch(addError(`Error during question types fetching ${error.message}`, error));
      });
  };
}
