import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';

export const FETCH_REPORT_RECIPENTS_REQUEST = 'FETCH_REPORT_RECIPENTS_REQUEST';
export const FETCH_REPORT_RECIPENTS_SUCCESS = 'FETCH_REPORT_RECIPENTS_SUCCESS';
export const FETCH_REPORT_RECIPENTS_FAILURE = 'FETCH_REPORT_RECIPENTS_FAILURE';
export const ADD_REPORT_RECIPENT = 'ADD_REPORT_RECIPENT';
export const EDIT_REPORT_RECIPENT = 'EDIT_REPORT_RECIPENT';
export const REMOVE_REPORT_RECIPENT = 'REMOVE_REPORT_RECIPENT';

export function fetchReportRecipentsRequest() {
  return {
    type: FETCH_REPORT_RECIPENTS_REQUEST
  };
}

export function fetchReportRecipentsSuccess(reportRecipents) {
  return {
    type: FETCH_REPORT_RECIPENTS_SUCCESS,
    payload: {
      reportRecipents
    }
  };
}

export function fetchReportRecipentsFailure() {
  return {
    type: FETCH_REPORT_RECIPENTS_FAILURE
  };
}

export function addReportRecipentSuccess(reportRecipent) {
  return {
    type: ADD_REPORT_RECIPENT,
    payload: {
      reportRecipent
    }
  };
}

export function editReportRecipentSuccess(reportRecipent) {
  return {
    type: EDIT_REPORT_RECIPENT,
    payload: {
      reportRecipent
    }
  };
}

export function removeReportRecipentSuccess(reportRecipent) {
  return {
    type: REMOVE_REPORT_RECIPENT,
    payload: {
      reportRecipent
    }
  };
}


export function fetchReportRecipents() {
  return (dispatch) => {
    dispatch(fetchReportRecipentsRequest());

    return axios.get(URLS.reportRecipents)
      .then(({ data }) => dispatch(fetchReportRecipentsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchReportRecipentsFailure());
        dispatch(addError(`Error during report recipents fetching ${error.message}`, error));
      });
  };
}

export function addReportRecipent(reportRecipent) {
  return (dispatch) => {
    return axios.post(URLS.reportRecipents, reportRecipent)
      .then(({ data }) => dispatch(addReportRecipentSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during report recipent adding ${error.message}`, error));
      });
  };
}

export function editReportRecipent(reportRecipent) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.reportRecipents, reportRecipent.id), reportRecipent)
      .then(({ data }) => dispatch(editReportRecipentSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during report recipent editing ${error.message}`, error));
      });
  };
}

export function removeReportRecipent(reportRecipent) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.reportRecipents, reportRecipent.id))
      .then(() => dispatch(removeReportRecipentSuccess(reportRecipent)))
      .catch((error) => {
        dispatch(addError(`Error during report recipent removing ${error.message}`, error));
      });
  };
}
