export default function generateDuplicateName(name, namesList, copyName) {

  const re = new RegExp(`(.*)\\(${copyName} (\\d+)\\)$`);

  const nextDuplicateNumber = namesList.filter((itemName) => itemName.startsWith(name)).reduce((highestNumber, itemName) => {
    const matchResult = itemName.match(re);

    if (matchResult && matchResult[1] === name) {
      const currentNumber = Number(matchResult[2]);

      return currentNumber > highestNumber ? currentNumber : highestNumber;
    }

    return highestNumber;
  }, 0) + 1;

  return `${name}(${copyName} ${nextDuplicateNumber})`;
}

