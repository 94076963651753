import axios from 'axios';

import { languages, nameSpaces } from '../../constants/languages';
import { S3_FRONTEND_URL } from '../../constants';


export default function getTranslations() {
  const promises = languages.reduce((allPromises, language) => {
    const filePromises = nameSpaces.map((namespace) => {
      return axios.get(`${S3_FRONTEND_URL}${language}/${namespace}.json`)
        .then(({ data }) => ({
          language,
          namespace,
          translation: data
        })).catch((error) => ({
          error
        }));
    });

    allPromises.push(...filePromises);

    return allPromises;
  }, []);

  return Promise.all(promises).then((results) => {
    const translations = {};

    results.forEach(({ language, namespace, translation }) => {
      translations[language] = translations[language] || {};
      translations[language][namespace] = translation || {};
    });

    return translations;
  });
}
