import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

const styles = (theme) => ({
  chip: {
    margin: theme.spacing.unit / 2,
  },
});

@withNamespaces()
@withStyles(styles)
export default class BookingObjectList extends Component {
  static propTypes = {
    bookingObjectList: PropTypes.array.isRequired,
    removeBookingObject: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderBookingObjectList = (bookingObjectList) => {
    const { removeBookingObject, onElementDelete, classes } = this.props;

    if (_isEmpty(bookingObjectList)) {
      return null;
    }

    return (
      <div elevation={4} className="ml-sm-3 my-2">
        {
          bookingObjectList.map((bookingObject) => {
            return (
              <Chip
                key={`bookingObject-${bookingObject.id}`}
                label={bookingObject.name}
                onDelete={() => onElementDelete(() => removeBookingObject(bookingObject))}
                className={classes.chip}
              />
            );
          })
        }
      </div>
    );
  };

  render() {
    const { handleAddOpen, bookingObjectList, t } = this.props;

    return (
      <div className="pl-sm-5">
        {this.renderBookingObjectList(bookingObjectList)}
        <Button color="primary" onClick={handleAddOpen}>{t('settings:addBookingObjectButton')}</Button>
      </div>
    );
  }
}
