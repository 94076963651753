import axios from 'axios';

import { addError } from './errors';
import { urlJoin, capitalizeFirstLetter } from '../../utils';
import { URLS } from '../../constants';
import _get from 'lodash/get';

export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';
export const ADD_GROUP = 'ADD_GROUP';
export const EDIT_GROUP = 'EDIT_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';


export function fetchGroupsRequest() {
  return {
    type: FETCH_GROUPS_REQUEST
  };
}

export function fetchGroupsSuccess(groups) {
  return {
    type: FETCH_GROUPS_SUCCESS,
    payload: {
      groups
    }
  };
}

export function fetchGroupsFailure() {
  return {
    type: FETCH_GROUPS_FAILURE
  };
}

export function addGroupSuccess(group) {
  return {
    type: ADD_GROUP,
    payload: {
      group
    }
  };
}

export function editGroupSuccess(group) {
  return {
    type: EDIT_GROUP,
    payload: {
      group
    }
  };
}

export function removeGroupSuccess(group) {
  return {
    type: REMOVE_GROUP,
    payload: {
      group
    }
  };
}

export function fetchGroups() {
  return (dispatch) => {
    dispatch(fetchGroupsRequest());

    return axios.get(URLS.schoolGroups)
      .then(({ data }) => dispatch(fetchGroupsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchGroupsFailure());
        dispatch(addError(`Error during school groups fetching ${error.message}`, error));
      });
  };
}

export function addGroup(name) {
  return (dispatch) => {
    return axios.post(URLS.schoolGroups, { name })
      .then(({ data }) => dispatch(addGroupSuccess(data)))
      .catch((error) => {
        const errorData = _get(error, 'response.data.name[0]', `error during school group adding ${error.message}`);

        dispatch(addError(capitalizeFirstLetter(errorData), error));
      });
  };
}

export function editGroup(group) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.schoolGroups, group.id), { name: group.name })
      .then(({ data }) => dispatch(editGroupSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during school group editing ${error.message}`, error));
      });
  };
}

export function removeGroup(group) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.schoolGroups, group.id))
      .then(() => dispatch(removeGroupSuccess(group)))
      .catch((error) => {
        dispatch(addError(`Error during school group removing ${error.message}`, error));
      });
  };
}
