import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { renderDateColor } from '../warehouseHelper';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 180,
  },
  colorCell: {
    width: 20,
  },
});

@withNamespaces()
@withStyles(styles)
export default class ConsumableList extends Component {
  static propTypes = {
    warehouse: PropTypes.object.isRequired,
    consumables: PropTypes.array.isRequired,
    removeWarehouseConsumable: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    handleDeleteOpen: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    warehouseSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderButtons(consumable) {
    const {
      handleEditOpen,
      handleDeleteOpen,
      removeWarehouseConsumable,
      editMode,
    } = this.props;

    if (!editMode) {
      return null;
    }

    return (
      <>
        <IconButton onClick={() => handleEditOpen(consumable)}>
          <Icon color="primary">mode_edit</Icon>
        </IconButton>
        <IconButton
          onClick={() =>
            handleDeleteOpen(consumable, () =>
              removeWarehouseConsumable(consumable.warehouse, consumable.id),
            )
          }
        >
          <Icon color="primary">delete</Icon>
        </IconButton>
      </>
    );
  }

  renderConsumableList() {
    const { consumables, classes } = this.props;

    return consumables.map((consumable) => {
      return (
        <TableRow key={`service-${consumable.id}`}>
          <TableCell className={classes.longCell}>{consumable.id}</TableCell>
          <TableCell className={classes.longCell}>{consumable.name}</TableCell>
          <TableCell className={classes.longCell}>
            {consumable.partNumber}
          </TableCell>
          <TableCell className={classes.longCell}>
            {consumable.serialNumber}
          </TableCell>
          <TableCell className={classes.longCell}>
            {consumable.quantity}
          </TableCell>
          <TableCell className={classes.longCell}>
            {renderDateColor(consumable, 'expirationDate')}
          </TableCell>
          <TableCell className={classes.longCell}>
            {this.renderButtons(consumable)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderAddGoodsButton() {
    const { editMode, handleAddOpen, t } = this.props;

    if (!editMode) {
      return null;
    }

    return (
      <Button color="primary" onClick={handleAddOpen}>
        + {t('warehouse:Add goods')}
      </Button>
    );
  }

  render() {
    const { consumables, classes, t } = this.props;

    if (_isEmpty(consumables)) {
      return this.renderAddGoodsButton();
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:id')}</TableCell>
              <TableCell>{t('table:name ')}</TableCell>
              <TableCell>{t('table:part number')}</TableCell>
              <TableCell>{t('table:serial/batch number')}</TableCell>
              <TableCell>{t('table:quantity')}</TableCell>
              <TableCell>{t('table:expiration date')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderConsumableList()}</TableBody>
        </Table>
        {this.renderAddGoodsButton()}
      </div>
    );
  }
}
