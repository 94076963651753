import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip'

import './styles.scss';
import { answerLetters } from '../../../../utils/index';

const styles = () => ({
  input: {
    fontSize: '2rem'
  },
  formControl: {
    width: '100%',
  },
  root: {
    width: '100%'
  }
});

@withNamespaces()
@withStyles(styles)
export default class Question extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    question: PropTypes.object,
    selectAnswer: PropTypes.func.isRequired,
    completedTestPreviewMode: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    value: this.props.completedTestPreviewMode ? this.props.question.answered || '1' : '',
    isFormValid: false,
    questionName: _get(this.props.question, 'questionName', ''),
    answers: answerLetters.reduce((answers, anwser) => {
      answers[anwser] = {
        id: _get(this.props.question, `answers.${anwser}.id`, ''),
        name: _get(this.props.question, `answers.${anwser}.name`, ''),
        color: _get(this.props.question, `answers.${anwser}.color`, ''),
      };

      return answers;
    }, {}),
    collection: _get(this.props.question, 'collection', ''),
    questionId: _get(this.props.question, 'id', ''),
  };

  handleChange = (name) => (event) => {
    const { questionId } = this.state;

    this.setState({
      [name]: event.target.value,
    });

    this.props.selectAnswer(questionId, event.target.value);
  };

  renderAnswerRow = (answerLabel, order, answerColor) => {
    const { classes, completedTestPreviewMode } = this.props;
    const { value, questionId } = this.state;

    return (
      <TableRow className={completedTestPreviewMode ? answerColor : 'answer-standard'} key={`answer-${questionId}-${answerLabel}`}>
        <TableCell>
          <p className="mx-2">{answerLabel.toUpperCase()}</p>
        </TableCell>
        <TableCell>
          <p className={classes.root}>{this.state.answers[answerLabel].name}</p></TableCell>
        <TableCell numeric>
          <Radio
            checked={value.toString() === order.toString()}
            color="primary"
            disabled={completedTestPreviewMode}
            onChange={this.handleChange('value')}
            value={order.toString()}
            name="radio-button"
            aria-label={answerLabel}
          />
        </TableCell>
      </TableRow>
    );
  };

  renderFiles() {
    const { question } = this.props;

    const files = question.files;

    if (_isEmpty(files)) {
      return <div />;
    }

    return files.map((file) => {
      return (
        <Chip
          key={`item-file-${file.id}`}
          label={file.name}
          onClick={() => window.open(file.path, '_blank')}
          className="my-1 mx-2"
        />
      );
    });
  }

  renderAnswer = () => {
    const { answers } = this.state;

    return answerLetters.map((answer) => {
      return this.renderAnswerRow(answer, answers[answer].id, answers[answer].color);
    });
  };

  render() {
    const { classes, t } = this.props;
    const { questionName } = this.state;

    return (
      <div className={classes.root}>
        <h4 className="mb-0">{questionName}</h4>
        <div className="col-auto text-right pt-3 px-3 py-3">
          <p>{t('test:selectAnswer')}</p>
        </div>
        <div className="col-12 question-background">
          <Table className={classes.table}>
            <TableBody>
              {this.renderAnswer()}
            </TableBody>
          </Table>
        </div>
        <div>
          <p>{t('report:attachments')}</p>
        </div>
        {this.renderFiles()}
        <Divider className="my-4" />
      </div>
    );
  }
}
