import {
  FETCH_ITEM_TYPES_SUCCESS,
  FETCH_ITEM_TYPES_REQUEST,
  FETCH_ITEM_TYPES_FAILURE,
  ADD_ITEM_TYPE,
  REMOVE_ITEM_TYPE,
} from '../actions/itemTypes';

const INITIAL_STATE = {
  itemTypeList: [],
  itemTypeRequest: false,
};

function fetchItemTypes(state, payload) {
  return {
    ...state,
    itemTypeList: payload.itemTypes,
    itemTypeRequest: false,
  };
}

function fetchItemTypesRequest(state) {
  return {
    ...state,
    itemTypeRequest: true,
  };
}

function fetchItemTypesFailure(state) {
  return {
    ...state,
    itemTypeRequest: false,
  };
}

function addItemType(state, payload) {
  return {
    ...state,
    itemTypeList: [
      ...state.itemTypeList,
      payload.itemType
    ]
  };
}

function removeItemType(state, payload) {
  return {
    ...state,
    itemTypeList: state.itemTypeList.filter((itemType) => itemType !== payload.itemType)
  };
}

const actions = {
  [FETCH_ITEM_TYPES_SUCCESS]: fetchItemTypes,
  [FETCH_ITEM_TYPES_REQUEST]: fetchItemTypesRequest,
  [FETCH_ITEM_TYPES_FAILURE]: fetchItemTypesFailure,
  [ADD_ITEM_TYPE]: addItemType,
  [REMOVE_ITEM_TYPE]: removeItemType,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
