import './styles.scss';

import React, { Component } from 'react';
import { fetchReports } from '../../actions/reports';

import Loader from '../../../common/components/Loader';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { reformatPickerDateFromDB } from '../../utils/time';


const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 80,
    '& a': {
      color: 'black'
    }
  },
  header: {
    background: 'linear-gradient(#1d7ad6, #50bbed)',
  },
  iconColor: {
    color: '#1d7ad6'
  }
});

const mapStateToProps = (state) => {
  return {
    reportList: state.reports.reportList,
    reportRequest: state.reports.reportRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchReports: (sortData) => dispatch(fetchReports(sortData)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UnreadReportList extends Component {
  static propTypes = {
    fetchReports: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    settings: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchReports({unread: true});
  }

  renderReports() {
    const { reportList, classes } = this.props;

    return reportList.map((report) => { 

      const date = reformatPickerDateFromDB(report.date, false);
      const dueDate = report.dueDate ? reformatPickerDateFromDB(report.dueDate, false) : '';

      return (
        <TableRow key={`fleet-list-${report.id}`}>
          <TableCell className={classes.longCell}>
            <Link className="table-row-link" to={`/report/${report.id}`}>{date}</Link>
          </TableCell>
          <TableCell className={classes.longCell}>
            <Link className="table-row-link" to={`/report/${report.id}`}>{report.subject}</Link>
          </TableCell>
          <TableCell className={classes.longCell}>
            <Link className="table-row-link" to={`/report/${report.id}`}>{report.status}</Link>
          </TableCell>
          <TableCell className={classes.longCell}>
            <Link className="table-row-link" to={`/report/${report.id}`}>{dueDate}</Link>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { reportList, reportRequest, classes, t } = this.props;

    if (reportRequest) {
      return <Loader />;
    }

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <header className="header-dashboard">
          <span className={classNames('span-dashboard', 'py-3')}>
            <Icon className={classNames(classes.iconColor, 'notification-icon')}>
            description
            </Icon>
            <h4 className="h4-dashboard">{t('report:Unread reports')}</h4>
            <Link to="/report">
              <Icon className={classNames(classes.iconColor, 'notification-icon')}>
                keyboard_arrow_right
              </Icon>
            </Link>
          </span>
        </header>
        <div className={classNames(classes.root, 'px-2')}>
          { !_isEmpty(reportList) && <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header}>{t('table:date')}</TableCell>
                <TableCell className={classes.header}>{t('table:subject')}</TableCell>
                <TableCell className={classes.header}>{t('table:status')}</TableCell>
                <TableCell className={classes.header}>{t('table:dueDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderReports()}
            </TableBody>
          </Table> }
        </div>
      </Paper>
    );
  }
}

