import React, { Component } from 'react';
import {
  dateTimeDiffInTimeFormat,
  getDateTimeFormat,
  getTimeFormat,
  reformatPickerDateFromDB,
} from '../../../utils/time';
import { fetchUserProfile, fetchUsers } from '../../../actions/users';

import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Form from '../../../../common/components/forms/Form';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { ZERO_TIME } from '../../../../constants';
import _has from 'lodash/has';
import { connect } from 'react-redux';
import { isTheoreticalLesson } from '../../../../utils';
import userService from '../../../../utils/userService';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  timeInputWidth: {
    width: 180,
  },
});

const mapStateToProps = (state) => {
  return {
    userList: state.users.userList,
    userDetails: state.users.userDetails,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: (searchData) => {
      dispatch(fetchUsers(searchData));
    },
    fetchUserProfile: (userId) => {
      dispatch(fetchUserProfile(userId));
    },
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class BasicLessonInfo extends Component {
  static propTypes = {
    lesson: PropTypes.object.isRequired,
    basicInfoDateHandlers: PropTypes.object.isRequired,
    handleDatePickerChange: PropTypes.func.isRequired,
    practicalLessonData: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    preview: PropTypes.bool,
    permission: PropTypes.object,
    userList: PropTypes.array.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    fetchUserProfile: PropTypes.func.isRequired,
    handleInstructor: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    studentFullName: PropTypes.string,
  };

  static defaultProps = {
    permission: {},
  };

  componentDidMount() {
    const user = localStorage.getItem('user');
    const userId = JSON.parse(user).id.toString();

    this.props.fetchUsers();
    this.props.fetchUserProfile(userId);
  }

  renderTheoreticalFields = (theoreticalLesson) => {
    const { basicInfoDateHandlers, preview, handleDatePickerChange, t } =
      this.props;

    if (theoreticalLesson) {
      return (
        <>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            <DateTimePicker
              disabled={preview}
              value={reformatPickerDateFromDB(basicInfoDateHandlers.startDate)}
              showMonthDropdown
              showTimeSelect
              showYearDropdown
              dateFormat={getDateTimeFormat()}
              timeFormat={getTimeFormat()}
              handleDatePickerChange={handleDatePickerChange('startDate')}
              name="date"
              label={t('input:startDate')}
              fullWidth
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
            <DateTimePicker
              disabled={preview}
              value={reformatPickerDateFromDB(basicInfoDateHandlers.endDate)}
              showMonthDropdown
              showTimeSelect
              showYearDropdown
              dateFormat={getDateTimeFormat()}
              timeFormat={getTimeFormat()}
              handleDatePickerChange={handleDatePickerChange('endDate')}
              name="date"
              label={t('input:endDate')}
              fullWidth
            />
          </div>
        </>
      );
    }

    return null;
  };

  onChange = (formData, instructorList) => {
    const data = Object.entries(formData).reduce((data, [name, val]) => {
      data['instructor'] = instructorList.find(
        (user) => parseInt(user.id) === parseInt(val.value),
      );
      return data;
    }, {});

    this.props.handleInstructor(data);
  };

  renderTotalTime = (theoreticalLesson) => {
    const {
      lesson,
      userList,
      userDetails,
      preview,
      basicInfoDateHandlers,
      practicalLessonData,
      classes,
      t,
      studentFullName,
    } = this.props;
    let value = dateTimeDiffInTimeFormat(
      basicInfoDateHandlers.endDate,
      basicInfoDateHandlers.startDate,
    );

    if (!theoreticalLesson) {
      value = practicalLessonData['total block'] || ZERO_TIME;
    }

    let instructorList = userList;
    if (userDetails) {
      if (
        !instructorList.find(
          (user) => parseInt(user.id) === parseInt(userDetails.id),
        )
      ) {
        instructorList = [userDetails, ...userList];
      }
    }

    if (instructorList.length > 0) {
      instructorList = instructorList.filter((user) => {
        const groupIndex = user.groups.findIndex(
          (group) =>
            group.name === 'instructor' ||
            group.name === 'manager' ||
            group.name === 'admin',
        );
        return groupIndex !== -1;
      });
    }

    let defaultInstructor = null;
    if (lesson && _has(lesson, 'instructor.id')) {
      defaultInstructor = lesson.instructor.id;
    } else {
      const user = userService.getCurrentUser();
      defaultInstructor = user.id;
    }

    return (
      <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-xs-12">
            <TextField
              className={classes.timeInputWidth}
              label={t('input:student name')}
              value={studentFullName}
              margin="dense"
              type="text"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-xs-12">
            <TextField
              className={classes.timeInputWidth}
              label={t('input:totalTime')}
              value={value}
              margin="dense"
              type="text"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-xs-6 col-sm-6 col-xs-12">
            <Form
              onChange={(data) => this.onChange(data, instructorList)}
              registerForm={() => {}}
            >
              <MaterialSelect
                id="user"
                name="user"
                label={t('input:instructor')}
                defaultValue={defaultInstructor}
                displayEmpty
                options={instructorList.map((user) => ({
                  value: user.id,
                  label: `${user.firstName} ${user.lastName}`,
                }))}
                disabled={preview}
                validators={[new validators.IsRequired(t)]}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { lesson } = this.props;
    const theoreticalLesson = isTheoreticalLesson(lesson.lessonType);

    return [
      <div className="row" key="basic-lesson-info-row2">
        {this.renderTheoreticalFields(theoreticalLesson)}

        {this.renderTotalTime(theoreticalLesson)}
      </div>,
    ];
  }
}
