import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TestList from '../Tests/TestList/TestsList';
import userService from '../../../utils/userService';
import { notifyAssignedTests } from "../../actions/assignedTests";

const mapStateToProps = (state) => {
  return {
    assignedTestList: state.assignedTests.assignedTestList,
    assignedTestRequest: state.assignedTests.assignedTestRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    notifyAssignedTests: (userId) => dispatch(notifyAssignedTests(userId)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class NotifyTests extends Component {
  static propTypes = {
    userId: PropTypes.string,
    notifyAssignedTests: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const currentUser = userService.getCurrentUser();
    const userId = currentUser.id;
    this.props.notifyAssignedTests(userId);
  }

  render() {
    const id = this.props.userId || userService.getCurrentUser().id;

    return (
      <TestList userId={Number(id)} />
    );
  }
}
