import {
  FETCH_FLIGHT_LOGS_SUCCESS,
  FETCH_FLIGHT_LOG_SUCCESS,
  FETCH_FLIGHT_LOGS_REQUEST,
  FETCH_FLIGHT_LOGS_FAILURE,
  REMOVE_FLIGHT_LOG_SUCCESS,
  ADD_FLIGHT_LOG,
  RESET_ADD_FLIGHT_LOG_SUCCESS,
  CLEAN_FLIGHT_LOG_DATA,
  EDIT_FLIGHT_LOG
} from '../actions/flightLog';

const INITIAL_STATE = {
  flightLogs: [],
  flightLog: {},
  flightLogsRequest: false,
  addFlightLogSuccess: false,
};

function fetchFlightLogs(state, payload) {
  return {
    ...state,
    flightLogs: payload.flightLogs,
    flightLogsRequest: false,
  };
}

function fetchFlightLog(state, payload) {
  return {
    ...state,
    flightLog: payload.flightLog,
    flightLogsRequest: false,
  };
}

function fetchFlightLogsRequest(state) {
  return {
    ...state,
    flightLogsRequest: true,
  };
}

function fetchFlightLogsFailure(state) {
  return {
    ...state,
    flightLogsRequest: false,
  };
}

function removeFlightLogSuccess(state, payload) {
  return {
    ...state,
    flightLogs: state.flightLogs.filter((flightLog) => flightLog.id !== payload.flightLogId)
  };
}

function addFlightLog(state, payload) {
  return {
    ...state,
    flightLogs: [
      ...state.flightLogs,
      payload.flightLog
    ],
    addFlightLogSuccess: true,
  };
}

function resetAddFlightLogSuccess(state) {
  return {
    ...state,
    addFlightLogSuccess: false
  };
}

function cleanFlightLog(state) {
  return {
    ...state,
    flightLog: null
  };
}

function editFlightLog(state, payload) {
  return {
    ...state,
    flightLogs: state.flightLogs.map((flightLog) => {
      if (flightLog.id === payload.flightLog.id) {
        return payload.flightLog;
      }

      return flightLog;
    })
  };
}


const actions = {
  [FETCH_FLIGHT_LOGS_SUCCESS]: fetchFlightLogs,
  [FETCH_FLIGHT_LOG_SUCCESS]: fetchFlightLog,
  [FETCH_FLIGHT_LOGS_REQUEST]: fetchFlightLogsRequest,
  [FETCH_FLIGHT_LOGS_FAILURE]: fetchFlightLogsFailure,
  [REMOVE_FLIGHT_LOG_SUCCESS]: removeFlightLogSuccess,
  [ADD_FLIGHT_LOG]: addFlightLog,
  [RESET_ADD_FLIGHT_LOG_SUCCESS]: resetAddFlightLogSuccess,
  [CLEAN_FLIGHT_LOG_DATA]: cleanFlightLog,
  [EDIT_FLIGHT_LOG]: editFlightLog,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
