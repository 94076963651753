import {
  FETCH_FLIGHT_EXPERIENCE_SUCCESS,
  FETCH_FLIGHT_EXPERIENCE_REQUEST,
  FETCH_FLIGHT_EXPERIENCE_FAILURE,
  EDIT_FLIGHT_EXPERIENCE,
  RESET_FLIGHT_EXPERIENCE_SUCCESS
} from '../actions/flightExperience';

const INITIAL_STATE = {
  flightExperience: {},
  flightExperienceRequest: false,
  changeFlightExperienceSuccess: false
};

function fetchFlightExperience(state, payload) {
  return {
    ...state,
    flightExperience: payload.flightExperience,
    flightExperienceRequest: false,
  };
}

function fetchFlightExperienceRequest(state) {
  return {
    ...state,
    flightExperienceRequest: true,
  };
}

function fetchFlightExperienceFailure(state) {
  return {
    ...state,
    flightExperienceRequest: false,
  };
}

function editFlightExperienceSuccess(state, payload) {
  return {
    ...state,
    flightExperience: payload.flightExperience,
    changeFlightExperienceSuccess: true,
  };
}

function resetSaveFlightExperienceSuccess(state) {
  return {
    ...state,
    changeFlightExperienceSuccess: false
  };
}


const actions = {
  [FETCH_FLIGHT_EXPERIENCE_SUCCESS]: fetchFlightExperience,
  [FETCH_FLIGHT_EXPERIENCE_REQUEST]: fetchFlightExperienceRequest,
  [FETCH_FLIGHT_EXPERIENCE_FAILURE]: fetchFlightExperienceFailure,
  [EDIT_FLIGHT_EXPERIENCE]: editFlightExperienceSuccess,
  [RESET_FLIGHT_EXPERIENCE_SUCCESS]: resetSaveFlightExperienceSuccess,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
