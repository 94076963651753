import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import FileInput from '../../../../common/components/upload/FileInput';
import Form from '../../../../common/components/forms/Form';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class EquipmentNew extends Component {
  static propTypes = {
    equipment: PropTypes.object,
    previewMode: PropTypes.bool.isRequired,
    editMode: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    getDateFormat: PropTypes.func.isRequired,
    handleDatePickerChange: PropTypes.func.isRequired,
    linkTo: PropTypes.string.isRequired,
    addEquipment: PropTypes.func.isRequired,
    editEquipment: PropTypes.func.isRequired,
    attachEquipmentFile: PropTypes.func.isRequired,
    removeEquipmentFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.formData = {};
  }

  renderViewLabel = (preview, editMode, t) => {
    if (preview) {
      return t('equipment:previewEquipmentSection');
    } else if (editMode) {
      return t('equipment:editEquipmentSection');
    }

    return t('equipment:addEquipmentSection');
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  onFormValidated = (isFormValid) => {
    const { addEquipment, editEquipment, editMode, equipment } = this.props;

    if (isFormValid && this.formData) {
      const equipmentData = {
        name: this.formData.name.value,
        type: this.formData.type.value,
        location: this.formData.location.value,
        certificate: this.formData.certificate.value,
        issueDate: equipment.issueDate,
        expiryDate: equipment.expiryDate,
      };

      editMode
        ? editEquipment(equipment.id, equipmentData)
        : addEquipment(equipmentData);
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  renderButtons = (t) => {
    const { previewMode, linkTo } = this.props;

    return (
      <div className="justify-content-center row pt-5">
        <div className="col-auto">
          <Link to={linkTo}>
            <Button variant="raised">{t('buttonCancel')}</Button>
          </Link>
        </div>
        <div className="col-auto">
          {!previewMode && (
            <Button color="primary" variant="raised" onClick={this.sendHandler}>
              {t('buttonSave')}
            </Button>
          )}
        </div>
      </div>
    );
  };

  renderAttachButton = (preview) => {
    const { attachEquipmentFile, addSimpleError } = this.props;

    if (preview) {
      return <></>;
    }

    return (
      <span className="file-inputs">
        <CustomIconButton>
          <FileInput
            attachFile={attachEquipmentFile}
            addSimpleError={addSimpleError}
            required
            isEmail
          />
          <Icon color="primary">attach_file</Icon>
        </CustomIconButton>
      </span>
    );
  };

  renderEditButton() {
    return (
      <div className="col-auto">
        <Link
          to={{
            pathname: `/equipment/edit/${this.props.equipment.id}`,
            state: { editMode: true },
          }}
        >
          <IconButton>
            <Icon>edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  renderFiles = (preview) => {
    const { equipment, removeEquipmentFile } = this.props;
    const { files } = equipment;

    return files.map((file) => {
      const onClick = preview
        ? () => window.open(file.path, '_blank')
        : () => {};
      const onDelete = preview ? () => {} : () => removeEquipmentFile(file);

      if (preview) {
        return (
          <Chip
            key={`report-file-${file.id}`}
            label={file.name}
            onClick={onClick}
            className="m-1"
          />
        );
      }

      return (
        <Chip
          key={`report-file-${file.id}`}
          label={file.name}
          onDelete={onDelete}
          className="m-1"
        />
      );
    });
  };

  render() {
    const {
      t,
      previewMode,
      editMode,
      getDateFormat,
      handleDatePickerChange,
      equipment,
    } = this.props;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center reports">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">category</Icon>
              <h1>{t('equipment:name')}</h1>
            </span>
            <p>{this.renderViewLabel(previewMode, editMode, t)}</p>
          </header>
        </div>
        {previewMode && this.renderEditButton()}
        <div className="mt-4 col-12">
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <div className="row">
              <div className="col">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="name"
                  label={t('table:name')}
                  defaultValue={equipment.name}
                  disabled={previewMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="type"
                  label={t('table:type')}
                  defaultValue={equipment.type}
                  disabled={previewMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="location"
                  label={t('table:location')}
                  defaultValue={equipment.location}
                  disabled={previewMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="certificate"
                  label={t('table:certificate')}
                  defaultValue={equipment.certificate}
                  disabled={previewMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DateTimePicker
                  fullWidth
                  value={equipment.issueDate}
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={handleDatePickerChange('issueDate')}
                  dateFormat={getDateFormat}
                  disabled={previewMode}
                  name="issueDate"
                  label={t('table:issueDate')}
                />
              </div>
              <div className="col">
                <DateTimePicker
                  fullWidth
                  value={equipment.expiryDate}
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={handleDatePickerChange('expiryDate')}
                  dateFormat={getDateFormat}
                  disabled={previewMode}
                  name="expiryDate"
                  label={t('table:expiryDate')}
                />
              </div>
            </div>
            <div className="my-3">
              <div>
                <p>{t('report:attachments')}</p>
              </div>
              {this.renderAttachButton(previewMode)}
              <span>{this.renderFiles(previewMode)}</span>
            </div>
            <div className="my-3">{this.renderButtons(t)}</div>
          </Form>
        </div>
      </div>
    );
  }
}
