import { ZERO_TIME } from '../constants';
import axios from 'axios';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import isInteger from 'lodash/isInteger';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import toNumber from 'lodash/toNumber';

class GenericValidation {
  constructor(t, validationMessage) {
    this.validationMessage = t(validationMessage);
    this.t = t;
  }

  getValidationMessage() {
    return this.validationMessage;
  }

  isValid() {
    throw new Error(this.t('formError'));
  }
}

class IsRequired extends GenericValidation {
  constructor(t, validationMessage = 'error:isRequired') {
    super(t, validationMessage);
  }

  isValid(value) {
    if (isString(value)) {
      return !isEmpty(value);
    }

    return !isNil(value);
  }
}

class IsArrayRequired extends GenericValidation {
  constructor(t, validationMessage = 'error:isRequired') {
    super(t, validationMessage);
  }

  isValid(value) {
    if (isArray(value)) {
      return value.length > 0;
    }

    return false;
  }
}

class IsNumber extends GenericValidation {
  constructor(t, validationMessage = 'error:isNumber') {
    super(t, validationMessage);
  }

  isValid(value) {
    const valAsNumber = toNumber(value);

    return isFinite(valAsNumber);
  }
}

class IsNaturalNumber extends GenericValidation {
  constructor(t, validationMessage = 'error:isNumber') {
    super(t, validationMessage);
  }

  isValid(value) {
    const valAsNumber = toNumber(value);

    return isFinite(valAsNumber) && valAsNumber >= 0 && isInteger(valAsNumber);
  }
}

class Max extends GenericValidation {
  constructor(t, maxValue, validationMessage) {
    super(t, validationMessage);
    this.validationMessage = validationMessage || t('error:max', maxValue);
    this.maxValue = maxValue;
  }

  isValid(value) {
    const valAsNumber = toNumber(value);

    return valAsNumber < this.maxValue;
  }
}

class Unique extends GenericValidation {
  constructor(t, uniquenessUrl, validationMessage = 'unique') {
    super(t, validationMessage);
    this.uniquenessUrl = uniquenessUrl;
  }

  async isValid(value) {
    if (isEmpty(value)) {
      return true;
    }

    try {
      await axios.head(`${this.uniquenessUrl}${value}/`);

      return false;
    } catch (error) {
      return error.status !== 404;
    }
  }
}

class Email extends GenericValidation {
  constructor(t, validationMessage = 'error:email') {
    super(t, validationMessage);
  }

  isValid(value) {
    const emailRegExp = new RegExp([/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]/,
      /(?:[a-z0-9-]*[a-z0-9])?/].map((regexpChunk) => regexpChunk.source).join(''));

    return emailRegExp.test(String(value).toLowerCase());
  }
}

class Password extends GenericValidation {
  constructor(t, validationMessage = 'error:password') {
    super(t, validationMessage);
  }

  isValid(value) {
    const passwordRegExp = /^($|(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\d$@$!%*?&#^]{8,})$/;

    return passwordRegExp.test(value);
  }
}

class Pin extends GenericValidation {
  constructor(t, validationMessage = 'error:pin') {
    super(t, validationMessage);
  }

  isValid(value) {
    const pinRegExp = /^[0-9]{4,4}$/;

    return pinRegExp.test(value);
  }
}

class IsUsersPin extends GenericValidation {
  constructor(t, userPin, validationMessage = 'error:isUsersPin') {
    super(t, validationMessage);
    this.userPin = userPin;
  }

  isValid(value) {
    if (this.userPin === '') {
      return false;
    }

    return value.toString() === this.userPin;
  }
}

class Date extends GenericValidation {
  constructor(t, validationMessage = 'error:date') {
    super(t, validationMessage);
  }

  isValid(value) {
    const dateRegExp = /^(\d{4})-(\d{2})-(\d{2})$/;

    return dateRegExp.test(value);
  }
}

class Percent extends GenericValidation {
  constructor(t, validationMessage = 'error:percent') {
    super(t, validationMessage);
  }

  isValid(value) {
    const percentRegExp = /^[0-9][0-9]?$|^100$/;

    return percentRegExp.test(value);
  }
}

class MaxLength extends GenericValidation {
  constructor(t, maxValue, validationMessage) {
    super(t, validationMessage);
    this.validationMessage = validationMessage || t('error:maxLength', { maxValue });
    this.maxValue = maxValue;
  }

  isValid(value) {
    const { length } = value;

    return length <= this.maxValue;
  }
}

class IsTimeRequired extends GenericValidation {
  constructor(t, validationMessage = 'error:isTimeFormatValid') {
    super(t, validationMessage);
  }

  isValid(value) {
    if (isString(value) && value === ZERO_TIME) {
      return false;
    }

    return true;
  }
}

class IsTimeFormatValid extends GenericValidation {
  constructor(t, validationMessage = 'error:maxLength') {
    super(t, validationMessage);
  }

  isValid(value) {
    const timeRegExp = /^([1-9][0-9]{1,}|0[0-9]):[0-5][0-9]$/;

    return timeRegExp.test(value);
  }
}

class StationId extends GenericValidation {
  constructor(t, validationMessage = 'error:stationId') {
    super(t, validationMessage);
  }

  isValid(value) {
    const stationIdRegExp = /^[A-Z]{4}$/;

    return stationIdRegExp.test(value);
  }
}

class AdvertUrl extends GenericValidation {
  constructor(t, validationMessage = 'error:advertUrl') {
    super(t, validationMessage);
  }

  isValid(value) {
    const advertUrlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    return advertUrlRegExp.test(value);
  }
}

class CAAReferenceNumber extends GenericValidation {
  constructor(t, validationMessage = 'error:Invalid CCA reference number') {
    super(t, validationMessage);
  }

  isValid(value) {
    return !value || /^[0-9]{6}[A-Z]$/.test(value);
  }
}

export default {
  IsRequired, IsArrayRequired, IsNumber, Max, Unique,
  Email, Password, Pin, Date, IsUsersPin,
  Percent, IsTimeRequired, IsTimeFormatValid,
  MaxLength, StationId, AdvertUrl, IsNaturalNumber, CAAReferenceNumber
};
