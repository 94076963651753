import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import Test from './Test';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission, renderPermission } from '../../../decorators/permissions';

const INITIAL_STATE = {
  showRemoveDialog: false,
  removeCallback: () => {}
};

@withNamespaces()
@componentPermission(PERMISSIONS.examCreate)
export default class TestNew extends Component {
  static propTypes = {
    test: PropTypes.object,
    preview: PropTypes.bool,
    editMode: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  renderViewLabel = (test, preview, editMode, t) => {
    if (editMode) {
      return t('test:editExamSection');
    } else if (preview) {
      return t('test:previewExamSection');
    }

    return t('test:addExamSection');
  };

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState(INITIAL_STATE);
  };

  @renderPermission(PERMISSIONS.editExam)
  renderTestEditButton(test) {
    return (
      <div className="col-auto">
        <Link to={`/exam_admin/edit/${test.id}`}>
          <IconButton>
            <Icon>mode_edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  render() {
    const { test, preview = false, editMode = false, competenciesList, t } = this.props;
    const { showRemoveDialog, removeCallback } = this.state;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-lg-8 col-sm-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">
                list_add_check
              </Icon>
              <h1>
                {t('test:name')}
                <span className="h1-subheader"> /
                  {this.renderViewLabel(test, preview, editMode, t)}
                </span>
              </h1>
            </span>
          </header>
        </div>
        {preview && this.renderTestEditButton(test)}
        <div className="col-12">
          <Test
            preview={preview}
            test={test}
            editMode={editMode}
            competenciesList={competenciesList}
            onElementDelete={this.openRemoveDialog}
          />
        </div>
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
      </div>
    );
  }
}
