import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Button from '@material-ui/core/Button';

import Loader from '../../../../../common/components/Loader';
import UserExtendedInfo from './UserExperience';
import { fetchJourneyLogs } from '../../../../actions/journeyLog';
import { addSimpleError } from '../../../../actions/errors';
import { chceckLessonDate, sumTime } from '../../../../utils/time';
import { ZERO_TIME } from '../../../../../constants';

import '../../styles.scss';

const mapStateToProps = (state) => {
  return {
    journeyLogList: state.journeyLog.journeyLogs,
    journeyLogsRequest: state.journeyLog.journeyLogsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchJourneyLogs: (searchData) => dispatch(fetchJourneyLogs(searchData)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}


@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class JourneyExperience extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    addSimpleError: PropTypes.func,
    fetchJourneyLogs: PropTypes.func.isRequired,
    journeyLogsRequest: PropTypes.bool.isRequired,
    journeyLogList: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { userId, fetchJourneyLogs } = this.props;

    fetchJourneyLogs({ userId });
  }

  renderButtons() {
    const { userId, t } = this.props;
    const pathname = `/users/${userId}`;

    return (
      <div className="justify-content-center row">
        <Link to={pathname}>
          <Button variant="raised">
            {t('buttonCancel')}
          </Button>
        </Link>
      </div>
    );
  }

  preparePracticalData() {
    const { journeyLogList } = this.props;

    const parsedPracticalData = {
      hoursOfOperation: ZERO_TIME,
      lastDayOfOperation: '',
      numberOfOperation: 0,
      numberOfOperationFromLast6Months: 0
    };

    if (!_isEmpty(journeyLogList)) {

      journeyLogList.forEach((log) => {
        const lastDayOfOperation = moment(log.dateOfJourneyLog);

        parsedPracticalData.lastDayOfOperation = _isEmpty(parsedPracticalData.lastDayOfOperation) ?
          lastDayOfOperation :
          moment.max(lastDayOfOperation, parsedPracticalData.lastDayOfOperation);
        parsedPracticalData.hoursOfOperation = sumTime(parsedPracticalData.hoursOfOperation, log.totalTime);
        parsedPracticalData.numberOfOperation += 1;

        if (chceckLessonDate(lastDayOfOperation)) {
          parsedPracticalData.numberOfOperationFromLast6Months += 1;
        }
      });
    }

    return parsedPracticalData;
  }

  render() {
    const { journeyLogsRequest } = this.props;

    if (journeyLogsRequest) {
      return <Loader />;
    }

    return (
      <div className="col-12">
        <UserExtendedInfo
          practicalData={this.preparePracticalData()}
        />
        {this.renderButtons()}
      </div>
    );
  }
}
