import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import MitigateManager from './MitigateManager';
import PropTypes from 'prop-types';
import RiskManager from './RiskManager';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getRiskAssessment } from '../../../actions/riskAssessments';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const TABS = {
  risk: 'Risk',
  mitigate: 'Mitigate',
};

const TAB_COMPONENTS = {
  [TABS.risk]: RiskManager,
  [TABS.mitigate]: MitigateManager,
};

const styles = () => ({
  tabs: {
    borderBottom: '4px solid #41A3F3',
    marginTop: '2em',
    marginBottom: '1em',
  },
  basicTab: {
    marginRight: '5px',
  },
  completed: {
    marginTop: '2em',
  },
  active: {
    color: 'white',
    background: 'linear-gradient(#0D5AA7, #41A3F3)',
  },
  root: {
    color: '#4B4B4B',
    background: '#D3E5F6',
    '&:hover': {
      color: 'black',
      background: 'linear-gradient(#BCDEFF, #9CD1F9)',
    },
  },
  indicator: {
    display: 'none',
  },
});

const mapStateToProps = (state) => {
  const { riskAssessments } = state;

  return {
    riskAssessment: riskAssessments.riskAssessment,
    riskAssessmentRequest: riskAssessments.riskAssessmentRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getRiskAssessment: (id) => dispatch(getRiskAssessment(id)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class RiskAssessment extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    riskAssessment: PropTypes.object.isRequired,
    getRiskAssessment: PropTypes.func.isRequired,
    riskAssessmentRequest: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  state = {
    currentTab: TABS.risk,
  };

  constructor(props) {
    if (!props.match.params.id) {
      props.riskAssessment = { hazards: [{}] };
    }

    super(props);
  }

  componentDidMount() {
    const { getRiskAssessment, match } = this.props;

    if (match.params.id) {
      getRiskAssessment(match.params.id);
    }
  }

  handleTabChange = (event, value) => {
    this.setState({
      currentTab: value,
    });
  };

  renderCompleteMode() {
    const { riskAssessment, classes, t } = this.props;

    return (
      <div className={classes.completed}>
        <RiskManager riskAssessment={riskAssessment} lock />
        <MitigateManager riskAssessment={riskAssessment} lock />
        <div class="mt-4 col-12 text-center">
          <Link to={{ pathname: '/sms' }}>
            <Button variant="raised">{t('buttonBack')}</Button>
          </Link>
        </div>
      </div>
    );
  }

  renderEditMode() {
    const editMode = !_isEmpty(this.props.match.params.id);

    return (
      <>
        {editMode ? this.renderTabs() : ''}
        {this.renderTab()}
      </>
    );
  }

  renderTabs() {
    const { t, classes } = this.props;
    const { currentTab } = this.state;

    return (
      <>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          onChange={this.handleTabChange}
          classes={{ indicator: classes.indicator }}
        >
          {Object.keys(TABS).map((tab) => {
            const label = `sms:${TABS[tab]}`;
            const isActive = TABS[tab] === currentTab;
            const className = classNames(
              classes.basicTab,
              isActive ? classes.active : classes.root,
            );

            return (
              <Tab
                className={className}
                key={`tab-${TABS[tab]}`}
                value={TABS[tab]}
                label={t(label)}
              />
            );
          })}
        </Tabs>
      </>
    );
  }

  renderTab() {
    const { currentTab } = this.state;
    const TabName = TAB_COMPONENTS[currentTab];

    const { match, riskAssessment } = this.props;

    const editMode = !_isEmpty(match.params.id);

    return <TabName riskAssessment={riskAssessment} editMode={editMode} />;
  }

  render() {
    const { t, match, riskAssessment, riskAssessmentRequest } = this.props;

    const editMode = !_isEmpty(match.params.id);

    if (riskAssessmentRequest || !riskAssessment) {
      return <Loader />;
    }

    const mitigated = !_isEmpty(riskAssessment.mitigates);

    return (
      <div className="row justify-content-lg-between justify-content-end">
        <div className="col-lg-12 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">article</Icon>
              <h1>{t('sms:Risk assessment')}</h1>
            </span>
            <p>
              {!editMode
                ? t('sms:Add new risk assessment')
                : t('sms:Edit risk assessment')}
            </p>
          </header>
        </div>
        <div className="col-12">
          {mitigated ? this.renderCompleteMode() : this.renderEditMode()}
        </div>
      </div>
    );
  }
}
