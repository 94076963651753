import React, { Component } from 'react';
import { addPart, cleanPartData, editPart } from '../../../actions/parts';

import Loader from '../../../../common/components/Loader';
import PartNew from './PartNew';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { fetchPartStatuses } from '../../../actions/partStatuses';
import { reformatPickerDateFromDB } from '../../../utils/time';

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    part: state.parts.part,
    partsRequest: state.parts.partsRequest,
    installationList: state.installations.installationList,
    partStatusList: state.partStatuses.partStatusList,
    historyList: state.historys.historyList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addPart: (part, files) => dispatch(addPart(part, true, files)),
    editPart: (part, updatedFiles, deletedFiles) =>
      dispatch(editPart(part, true, updatedFiles, deletedFiles)),
    cleanPartData: () => dispatch(cleanPartData()),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    fetchPartStatuses: () => dispatch(fetchPartStatuses()),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossPartNewView extends Component {
  static propTypes = {
    part: PropTypes.object.isRequired,
    installationList: PropTypes.array.isRequired,
    historyList: PropTypes.array.isRequired,
    partsRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    cleanPartData: PropTypes.func.isRequired,
    addPart: PropTypes.func.isRequired,
    editPart: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    settings: PropTypes.array.isRequired,
    previewMode: PropTypes.bool,
    editMode: PropTypes.bool,
    fetchPartStatuses: PropTypes.func.isRequired,
    partStatusList: PropTypes.array.isRequired,
  };

  componentDidMount() {
    const { fetchPartStatuses } = this.props;

    fetchPartStatuses();
  }

  componentWillUnmount() {
    const { cleanPartData } = this.props;

    cleanPartData();
  }

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    return _find(settings, { name: fieldName }).textValue;
  }

  render() {
    const {
      partsRequest,
      editMode,
      previewMode,
      addPart,
      editPart,
      part,
      addSimpleError,
      installationList,
      partStatusList,
      historyList,
    } = this.props;

    if (partsRequest || ((editMode || previewMode) && _isEmpty(part))) {
      return <Loader />;
    }

    const preparedPart = {
      id: _get(part, 'id', ''),
      name: _get(part, 'name', ''),
      partNumber: _get(part, 'partNumber', ''),
      serialNumber: _get(part, 'serialNumber', ''),
      nonSafetyRelated: _get(part, 'nonSafetyRelated', false),
      manufacturer: _get(part, 'manufacturer', ''),
      description: _get(part, 'description', ''),
      serviceHours: _get(part, 'serviceHours', ''),
      serviceMileage: _get(part, 'serviceMileage', ''),
      eolMileage: _get(part, 'eolMileage', ''),
      serviceDate: reformatPickerDateFromDB(_get(part, 'serviceDate', '')),
      eolDate: reformatPickerDateFromDB(_get(part, 'eolDate', '')),
      files: _get(part, 'files', []),
      usageHours: _get(part, 'usageHours', 0),
      milage: _get(part, 'milage', 0),
      partStatus: _get(part, 'partStatus', 'NEW'),
      activeSince: reformatPickerDateFromDB(_get(part, 'activeSince', '')),
    };
    const partSettings = {
      hoursWarning: this.setDefaultValue('hours_warning'),
      distanceWarning: this.setDefaultValue('distance_warning'),
    };
    const installation = _find(installationList, 'isActive') || {};

    return (
      <PartNew
        part={preparedPart}
        installationList={installationList}
        historyList={historyList}
        installation={installation}
        previewMode={previewMode}
        editMode={editMode}
        addPart={addPart}
        editPart={editPart}
        linkTo={'/parts'}
        partStatusList={partStatusList}
        partSettings={partSettings}
        addSimpleError={addSimpleError}
      />
    );
  }
}
