import axios from 'axios';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { addError } from './errors';
import { urlJoin, defaultCrewStatusFilerMonthsValue } from '../../utils';
import { prepareCrewStatusFilter, getDashboardQueryParametersFilter } from '../utils/time';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_EXTERNAL_COURSES_SUCCESS = 'FETCH_EXTERNAL_COURSES_SUCCESS';
export const FETCH_EXTERNAL_COURSES = 'FETCH_EXTERNAL_COURSES';
export const FETCH_ALL_EXTERNAL_COURSES_SUCCESS = 'FETCH_ALL_EXTERNAL_COURSES_SUCCESS';
export const ADD_EXTERNAL_COURSE = 'ADD_EXTERNAL_COURSE';
export const REMOVE_EXTERNAL_COURSE = 'REMOVE_EXTERNAL_COURSE';
export const EDIT_EXTERNAL_COURSE = 'EDIT_EXTERNAL_COURSE';

export function fetchExternalCoursesSuccess(externalCourses) {
  return {
    type: FETCH_EXTERNAL_COURSES_SUCCESS,
    payload: {
      externalCourses
    }
  };
}

export function fetchAllExternalCoursesSuccess(allExternalCourses) {
  return {
    type: FETCH_ALL_EXTERNAL_COURSES_SUCCESS,
    payload: {
      allExternalCourses
    }
  };
}

export function fetchAllExternalCoursesRequest() {
  return {
    type: FETCH_EXTERNAL_COURSES,
  };
}

export function addExternalCourseSuccess(externalCourse) {
  return {
    type: ADD_EXTERNAL_COURSE,
    payload: {
      externalCourse
    }
  };
}

export function removeExternalCourseSuccess(externalCourse) {
  return {
    type: REMOVE_EXTERNAL_COURSE,
    payload: {
      externalCourse
    }
  };
}

export function editExternalCourseSuccess(externalCourse) {
  return {
    type: EDIT_EXTERNAL_COURSE,
    payload: {
      externalCourse
    }
  };
}

export function addExternalCourse(externalCourse) {
  return (dispatch) => {
    return axios.post(URLS.externalCourses, externalCourse)
      .then(({ data }) => dispatch(addExternalCourseSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during external course adding ${error.message}`, error));
      });
  };
}

export function addExternalCoursePlannedAction(externalCourse) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.externalCoursesPlannedAction, externalCourse.id), externalCourse)
      .then(({ data }) => dispatch(editExternalCourseSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during adding externalCourse planned action ${error.message}`, error));
      });
  };
}

export function removeExternalCourse(externalCourse) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.externalCourses, externalCourse.id))
      .then(() => dispatch(removeExternalCourseSuccess(externalCourse)))
      .catch((error) => {
        dispatch(addError(`Error during external course removing ${error.message}`, error));
      });
  };
}

export function editExternalCourse(externalCourse) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.externalCourses, externalCourse.id), externalCourse)
      .then(({ data }) => dispatch(editExternalCourseSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during external course editing ${error.message}`, error));
      });
  };
}

export function deactivateExternalCourse(externalCourse) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.externalCoursesDeactivate, externalCourse.id), externalCourse)
      .then(({ data }) => dispatch(editExternalCourseSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during external course editing ${error.message}`, error));
      });
  };
}

export function fetchExternalCourses(userId) {
  return (dispatch) => {
    const queryParameters = `?${getDashboardQueryParametersFilter(userId)}&ordering=expiry_date`;

    return axios.get(urlJoin(URLS.externalCourses, queryParameters))
      .then(({ data }) => dispatch(fetchExternalCoursesSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during external courses fetching ${error.message}`, error));
      });
  };
}

export function fetchAllExternalCourses(sortingData) {
  return (dispatch) => {
    dispatch(fetchAllExternalCoursesRequest());
    let crewStatusParameters = '';
    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData && !_isEmpty(sortingData.filter)) {
      const monthId = _get(sortingData.filter, 'months[0].id', defaultCrewStatusFilerMonthsValue);

      crewStatusParameters = prepareCrewStatusFilter(monthId);

      if (sortingData.filter.isVerified) {
        crewStatusParameters += '&is_verified=False';
      }
    }

    queryParameters = _isEmpty(queryParameters) ? `?${crewStatusParameters}` : `?${qs.stringify(queryParameters)}&${crewStatusParameters}`;

    return axios.get(urlJoin(URLS.allExternalCourses, queryParameters))
      .then(({ data }) => dispatch(fetchAllExternalCoursesSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during external courses fetching ${error.message}`, error));
      });
  };
}
