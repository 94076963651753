import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  minColWidth: {
    minWidth: 50,
  },
});

@withNamespaces()
@withStyles(styles)
export default class GradingList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    deleteGrading: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    gradingList: PropTypes.array,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderGradingList = () => {
    const {
      classes,
      deleteGrading,
      gradingList,
      handleEditOpen,
      onElementDelete,
    } = this.props;

    return gradingList.map((grading) => {
      return (
        <TableRow key={`grading-${grading.id}`}>
          <TableCell className={classes.minColWidth}>{grading.id}</TableCell>
          <TableCell className={classes.minColWidth}>{grading.name}</TableCell>
          <TableCell className={classes.minColWidth}>
            {grading.options.join(',')}
          </TableCell>
          <TableCell numeric>
            <IconButton onClick={() => handleEditOpen(grading)}>
              <Icon color="primary">mode_edit</Icon>
            </IconButton>
            <IconButton
              onClick={() => onElementDelete(() => deleteGrading(grading))}
            >
              <Icon color="primary">delete</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <>
        <div className="ml-sm-3 my-2">
          <h4>{t('settings:Available gradings')}</h4>
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table:id')}</TableCell>
                  <TableCell>{t('table:title')}</TableCell>
                  <TableCell>{t('table:gradings')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>{this.renderGradingList()}</TableBody>
            </Table>
          </div>
        </div>
      </>
    );
  }
}
