import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';

import { getVerifiedBy, isVerified } from '../PlannedAction/plannedActionHelper';
import { getActionIcons, renderFiles } from '../usersHelper';
import { reformatPickerDateFromDB } from '../../../utils/time';


const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 320,
  }
});

@withNamespaces()
@withStyles(styles)
export default class QualificationList extends Component {
  static propTypes = {
    qualificationList: PropTypes.array,
    addQualificationPlannedAction: PropTypes.func.isRequired,
    removeQualification: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    handleEditOpen: PropTypes.func.isRequired,
    attachQualificationFile: PropTypes.func.isRequired,
    removeQualificationFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    deactivateQualification: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderQualificationList() {
    const {
      qualificationList, editMode, classes,
      handleEditOpen, attachQualificationFile, addSimpleError,
      addQualificationPlannedAction, deactivateQualification,
      removeQualification, removeQualificationFile,
    } = this.props;
    const actions = {
      handleEditOpen,
      addSimpleError,
      deactivateItem: deactivateQualification,
      removeItem: removeQualification,
      attachItemFile: attachQualificationFile,
      addItemPlannedAction: addQualificationPlannedAction,
      documentName: 'Qualification',
    };
    const documentName = 'qualification';

    return qualificationList.map((qualification) => {
      return (
        <TableRow key={`${documentName}-${qualification.id}`}>
          <TableCell className={classes.longCell}>{qualification.type}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(qualification.expiryDate, false)}</TableCell>
          <TableCell className={classes.longCell}>{isVerified(qualification.isVerified)}</TableCell>
          <TableCell className={classes.longCell}>{getVerifiedBy(qualification.verifiedBy)}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(qualification.verificationDate, false)}</TableCell>
          <TableCell className={classes.longCell}>{qualification.plannedAction}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(qualification.plannedActionDate, false)}</TableCell>
          <TableCell>{renderFiles(editMode, qualification, removeQualificationFile, documentName)}</TableCell>
          <TableCell className={classes.longCell}>{isVerified(qualification.isActive)}</TableCell>
          <TableCell numeric className={classes.iconsCell}>
            {getActionIcons(editMode, qualification, actions)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderAddButton(editMode, handleAddOpen, t) {
    if (editMode) {
      return (
        <Button className="my-2" color="primary" onClick={handleAddOpen}>{t('user:addQualificationButton')}</Button>
      );
    }

    return null;
  }

  render() {
    const { classes, handleAddOpen, editMode, qualificationList, t } = this.props;

    if (_isEmpty(qualificationList)) {
      return (
        <div>
          <h4>{t('user:qualificationsSection')}</h4>
          {this.renderAddButton(editMode, handleAddOpen, t)}
          <Divider className="my-3" />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <h4>{t('user:qualificationsSection')}</h4>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:type')}</TableCell>
              <TableCell>{t('table:expiryDate')}</TableCell>
              <TableCell>{t('table:isVerified')}</TableCell>
              <TableCell>{t('table:verifiedBy')}</TableCell>
              <TableCell>{t('table:verificationDate')}</TableCell>
              <TableCell>{t('table:plannedAction')}</TableCell>
              <TableCell>{t('table:plannedActionDate')}</TableCell>
              <TableCell>{t('table:attachments')}</TableCell>
              <TableCell>{t('table:isActive')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderQualificationList()}
          </TableBody>
        </Table>
        {this.renderAddButton(editMode, handleAddOpen, t)}
        <Divider className="my-3" />
      </div>
    );
  }
}
