import {
  FETCH_EXAM_COMPETENCIES_SUCCESS,
  FETCH_EXAM_COMPETENCIES,
  ADD_EXAM_COMPETENCE,
  REMOVE_EXAM_COMPETENCE,
  EDIT_EXAM_COMPETENCE,
} from '../actions/examCompetencies';

const INITIAL_STATE = {
  examCompetenceList: [],
  examCompetenciesRequest: false,
};

function fetchExamCompetenciesSuccess(state, payload) {
  return {
    ...state,
    examCompetenceList: payload.examCompetencies,
    examCompetenciesRequest: false,
  };
}

function fetchExamCompetencies(state) {
  return {
    ...state,
    examCompetenciesRequest: true
  };
}

function addExamCompetence(state, payload) {
  return {
    ...state,
    examCompetenceList: [
      ...state.examCompetenceList,
      payload.examCompetence
    ]
  };
}

function removeExamCompetence(state, payload) {
  return {
    ...state,
    examCompetenceList: state.examCompetenceList.filter((examCompetence) => examCompetence !== payload.examCompetence)
  };
}

function editExamCompetence(state, payload) {
  return {
    ...state,
    examCompetenceList: state.examCompetenceList.map((examCompetence) => {
      if (examCompetence.id === payload.examCompetence.id) {
        return payload.examCompetence;
      }

      return examCompetence;
    }),
  };
}

const actions = {
  [FETCH_EXAM_COMPETENCIES_SUCCESS]: fetchExamCompetenciesSuccess,
  [FETCH_EXAM_COMPETENCIES]: fetchExamCompetencies,
  [ADD_EXAM_COMPETENCE]: addExamCompetence,
  [REMOVE_EXAM_COMPETENCE]: removeExamCompetence,
  [EDIT_EXAM_COMPETENCE]: editExamCompetence,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
