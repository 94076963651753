import React, { Component } from 'react';

import AdditionalMenus from './MobileMenus';
import Icon from '@material-ui/core/Icon';
import LanguageSelect from '../../../common/components/LanguageSelect';
import { Link } from 'react-router-dom';
import Logout from '../Authentication/Logout';
import { MODULES } from '../../../constants/modules';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import companyLogo from '../../../images/qcLogo.png';
import { connect } from 'react-redux';
import { fetchSchoolLogo } from '../../actions/fileUpload';
import { renderModule } from '../../decorators/modules';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    file: state.schoolLogo.file,
    fileRequest: state.schoolLogo.fileRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchSchoolLogo: (id) => dispatch(fetchSchoolLogo(id)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class Navigation extends Component {
  static propTypes = {
    schoolLogoId: PropTypes.number.isRequired,
    fetchSchoolLogo: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    fileRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    open: false,
  };

  componentWillMount() {
    const { schoolLogoId, fetchSchoolLogo } = this.props;

    fetchSchoolLogo(schoolLogoId);
  }

  @renderModule(MODULES.training)
  renderMyCourses(t) {
    return (
      <Link
        to="/my_education"
        className="nav-link"
        onClick={this.closeMobileMenu}
      >
        <Icon color="primary" className="mx-1">
          content_paste
        </Icon>
        {t('navigation:myCourses')}
      </Link>
    );
  }

  @renderModule(MODULES.exams)
  renderMyExams(t) {
    return (
      <Link to="/my_exams" className="nav-link" onClick={this.closeMobileMenu}>
        <Icon color="primary" className="mx-1">
          playlist_add_check
        </Icon>
        {t('navigation:myExams')}
      </Link>
    );
  }

  @renderModule(MODULES.logbook)
  renderMyLogbook(t) {
    return (
      <Link
        to="/my_logbook"
        className="nav-link"
        onClick={this.closeMobileMenu}
      >
        <Icon color="primary" className="mx-1">
          directions_railway
        </Icon>
        {t('navigation:myLogbook')}
      </Link>
    );
  }

  @renderModule(MODULES.report)
  renderMyReports(t) {
    return (
      <Link
        to="/my_reports"
        className="nav-link"
        onClick={this.closeMobileMenu}
      >
        <Icon color="primary" className="mx-1">
          description
        </Icon>
        {t('navigation:myReports')}
      </Link>
    );
  }

  @renderModule(MODULES.library)
  renderLibrary(t) {
    return (
      <Link to="/library" className="nav-link">
        <Icon color="primary" className="mx-1">
          book
        </Icon>
        {t('library')}
      </Link>
    );
  }

  @renderModule(MODULES.booking)
  renderBooking(t) {
    return (
      <Link to="/booking" className="nav-link">
        <Icon color="primary" className="mx-1">
          event
        </Icon>
        {t('booking')}
      </Link>
    );
  }

  renderMyProfile(t) {
    return (
      <Link
        to="/my_profile"
        className="nav-link"
        onClick={this.closeMobileMenu}
      >
        <Icon color="primary" className="mx-1">
          person
        </Icon>
        {t('navigation:myProfile')}
      </Link>
    );
  }

  @renderModule(MODULES.eLearning)
  renderElearning(t) {
    return (
      <a
        href="https://qrosscheck-learning.com/uncanny-group-login/"
        className="nav-link"
        onClick={this.closeMobileMenu}
      >
        <Icon color="primary" className="mx-1">
          school
        </Icon>
        {t('navigation:eLearning')}
      </a>
    );
  }

  getSchoolLogo = () => {
    const { file } = this.props;

    return file ? (_isEmpty(file.path) ? companyLogo : file.path) : companyLogo;
  };

  toggleMobileMenu = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  };

  closeMobileMenu = () => {
    this.setState({ open: false });
  };

  render() {
    const { t } = this.props;

    return (
      <div
        className={`navigation-wrapper no-print ${
          this.state.open ? 'open' : ''
        }`}
      >
        <div className="navbar-mobile">
          <div>
            <Link to="/">
              <img className="nav-img" src={this.getSchoolLogo()} />
            </Link>
          </div>
          <Icon className="pr-5" onClick={this.toggleMobileMenu}>
            {this.state.open ? 'close' : 'menu'}
          </Icon>
        </div>
        <div className="mobile-menu">
          <div className="basic-menus">
            {this.renderLibrary(t)}
            {this.renderBooking(t)}
            {this.renderMyCourses(t)}
            {this.renderMyExams(t)}
            {this.renderMyReports(t)}
            {this.renderMyLogbook(t)}
            {this.renderMyProfile(t)}
            {this.renderElearning(t)}
            <LanguageSelect />
            <Logout />
          </div>
          <div className="additional-menus">
            <AdditionalMenus closeMobileMenu={this.closeMobileMenu} />
          </div>
        </div>
      </div>
    );
  }
}
