import React, { Component } from 'react';

import { Chrono } from 'react-chrono';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { capitalizeFirstLetter } from '../../../../utils';
import { reformatPickerDateTimeFromDB } from '../../../utils/time';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  historyList: {
    padding: 0,
    margin: 0,
    width: '100%',
  },
  historyListItemChanges: {
    marginBottom: '16px',
  },
  historyListChangeReason: {
    marginBottom: '16px',
  },
});

@withNamespaces()
@withStyles(styles)
export default class StatusHistoryList extends Component {
  static propTypes = {
    historyList: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  getHistoryList() {
    const { historyList } = this.props;

    return historyList.map((history) => {
      return {
        ...history,
        title: reformatPickerDateTimeFromDB(
          new Date(history.timestamp).toISOString(),
          false,
        ),
      };
    });
  }

  renderHistoryChangeReason(changeReason) {
    const { classes, t } = this.props;

    if (!_isEmpty(changeReason)) {
      return (
        <ul className={classes.historyListChangeReason}>
          {t('fleet:Change Reason')}
          <li>{changeReason}</li>
        </ul>
      );
    }

    return '';
  }

  renderHistoryDataChanges(data) {
    const { t } = this.props;

    return Object.entries(data).map(([key, d]) => {
      return (
        <li>
          {capitalizeFirstLetter(t(`input:${key}`))} changed to {d.newValue}{' '}
          (previous {d.oldValue || '-'})
        </li>
      );
    });
  }

  renderHistoryList(historyList) {
    const { classes, t } = this.props;

    return historyList.map((history) => {
      if (history.type === 'installation') {
        return (
          <div className={classes.historyList}>
            <h4>{t('fleet:Unmount part')}</h4>
            <ul className={classes.historyListItemChanges}>
              <li>mountingDate: {history.mountingDate}</li>
              <li>unmountingDate: {history.unmountingDate}</li>
              <li>milage: {history.milage}</li>
              <li>usageHours: {history.usageHours}</li>
            </ul>
          </div>
        );
      } else if (history.type === 'part_status_updates') {
        return (
          <div className={classes.historyList}>
            <h4>{t('fleet:Part status changes')}</h4>
            <ul className={classes.historyListItemChanges}>
              <li>From: {history.from}</li>
              <li>To: {history.to}</li>
            </ul>
          </div>
        );
      }

      return (
        <div className={classes.historyList}>
          <h4>
            {t('fleet:Part changed by')} {history.modifiedBy}
          </h4>
          <ul className={classes.historyListItemChanges}>
            {t('fleet:Changes')}
            {this.renderHistoryDataChanges(history.data || {})}
          </ul>
          {this.renderHistoryChangeReason(history.changeReason)}
        </div>
      );
    });
  }

  render() {
    const { historyList, classes, t } = this.props;

    if (_isEmpty(historyList)) {
      return <></>;
    }

    return (
      <div className={classes.root}>
        <h4>{t('fleet:History')}</h4>
        <div style={{ height: '450px' }}>
          <Chrono
            theme={{
              primary: '#0D5AA7',
              secondary: '#fff',
              cardBgColor: 'white',
              cardForeColor: 'black',
            }}
            mode="VERTICAL"
            items={this.getHistoryList()}
            scrollable={{ scrollbar: true }}
            hideControls
          >
            {this.renderHistoryList(this.getHistoryList())}
          </Chrono>
        </div>
      </div>
    );
  }
}
