import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _each from 'lodash/each';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Form from '../../../common/components/forms/Form';
import MaterialInput from '../../../common/components/forms/MaterialInput';
import validators from '../../../utils/validators';

@withNamespaces()
export default class EditFileDialog extends Component {
  static propTypes = {
    closeEditDialog: PropTypes.func.isRequired,
    editFunction: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    editDialogOpenState: PropTypes.bool.isRequired,
  };

  state = {
    name: this.props.file && this.props.file.name
  }

  componentDidMount() {
    this.formData = {};
  }

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = (isFormValid) => {
    const { file, editFunction } = this.props;
    const { name } = this.state;

    if (isFormValid && this.formData) {
      const newFile = {
        id: file.id,
        name,
      };

      editFunction(newFile);
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { closeEditDialog, editDialogOpenState, t } = this.props;
    const { name } = this.state;

    return (
      <Form
        onChange={this.onChange}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <Dialog
          open={editDialogOpenState}
          onClose={closeEditDialog}
          aria-labelledby="edit-dialog"
          fullWidth
        >
          <DialogTitle id="edit-dialog">{t('folder:editFileDialogTitle')}</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <MaterialInput
                  label={t('input:name')}
                  defaultValue={name}
                  name="name"
                  margin="dense"
                  autoFocus
                  validators={[
                    new validators.IsRequired(t),
                    new validators.MaxLength(t, 250),
                  ]}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditDialog} color="secondary">
              {t('buttonCancel')}
            </Button>
            <Button onClick={this.sendHandler} color="primary">
              {t('buttonSave')}
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    );
  }
}
