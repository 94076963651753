import {
  ADD_ENGINEER_AIRCRAFT_TYPE,
  EDIT_ENGINEER_AIRCRAFT_TYPE,
  FETCH_ENGINEER_AIRCRAFT_TYPE_FAILURE,
  FETCH_ENGINEER_AIRCRAFT_TYPE_REQUEST,
  FETCH_ENGINEER_AIRCRAFT_TYPE_SUCCESS,
  REMOVE_ENGINEER_AIRCRAFT_TYPE,
} from '../actions/engineerAircraftTypes';

const INITIAL_STATE = {
  aircraftTypeList: [],
  aircraftTypeRequest: false,
};

function fetchAircraftTypesSuccess(state, payload) {
  return {
    ...state,
    aircraftTypeList: payload.aircraftTypes,
    aircraftTypeRequest: false,
  };
}

function fetchAircraftTypesRequest(state) {
  return {
    ...state,
    aircraftTypeRequest: true,
  };
}

function fetchAircraftTypesFailure(state) {
  return {
    ...state,
    aircraftTypeRequest: false,
  };
}

function addAircraftType(state, payload) {
  return {
    ...state,
    aircraftTypeList: [...state.aircraftTypeList, payload.aircraftType],
  };
}

function editAircraftType(state, payload) {
  return {
    ...state,
    aircraftTypeList: state.aircraftTypeList.map((aircraftType) => {
      if (aircraftType.id === payload.aircraftType.id) {
        return payload.aircraftType;
      }

      return aircraftType;
    }),
  };
}

function removeAircraftType(state, payload) {
  return {
    ...state,
    aircraftTypeList: state.aircraftTypeList.filter(
      (aircraftType) => aircraftType.id !== payload.aircraftType.id,
    ),
  };
}

const actions = {
  [FETCH_ENGINEER_AIRCRAFT_TYPE_SUCCESS]: fetchAircraftTypesSuccess,
  [FETCH_ENGINEER_AIRCRAFT_TYPE_REQUEST]: fetchAircraftTypesRequest,
  [FETCH_ENGINEER_AIRCRAFT_TYPE_FAILURE]: fetchAircraftTypesFailure,
  [ADD_ENGINEER_AIRCRAFT_TYPE]: addAircraftType,
  [EDIT_ENGINEER_AIRCRAFT_TYPE]: editAircraftType,
  [REMOVE_ENGINEER_AIRCRAFT_TYPE]: removeAircraftType,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
