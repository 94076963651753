import {
  ADD_DEFAULT_NOTIFICATION_RECIPIENT,
  REMOVE_DEFAULT_NOTIFICATION_RECIPIENT,
  EDIT_DEFAULT_NOTIFICATION_RECIPIENT,
  FETCH_DEFAULT_NOTIFICATION_RECIPIENTS,
  FETCH_DEFAULT_NOTIFICATION_RECIPIENTS_REQUEST
} from '../actions/defaultNotificationRecipients';
const INITIAL_STATE = {
  defaultNotificationRecipientList: [],
  defaultNotificationRecipientRequest: false,
};

function fetchDefaultNotificationRecipients(state, payload) {
  return {
    ...state,
    defaultNotificationRecipientList: payload.defaultNotificationRecipients,
    defaultNotificationRecipientRequest: false,
  };
}

function fetchDefaultNotificationRecipientsRequest(state) {
  return {
    ...state,
    defaultNotificationRecipientRequest: true,
  };
}


function addDefaultNotificationRecipient(state, payload) {
  return {
    ...state,
    defaultNotificationRecipientList: [
      ...state.defaultNotificationRecipientList,
      payload.defaultNotificationRecipient
    ]
  };
}

function removeDefaultNotificationRecipient(state, payload) {
  return {
    ...state,
    defaultNotificationRecipientList: state.defaultNotificationRecipientList.filter((defaultNotificationRecipient) =>
      defaultNotificationRecipient !== payload.defaultNotificationRecipient)
  };
}

function editDefaultNotificationRecipient(state, payload) {
  return {
    ...state,
    defaultNotificationRecipientList: state.defaultNotificationRecipientList.map((defaultNotificationRecipient) => {
      if (defaultNotificationRecipient.id === payload.defaultNotificationRecipient.id) {
        return payload.defaultNotificationRecipient;
      }

      return defaultNotificationRecipient;
    })
  };
}

const actions = {
  [FETCH_DEFAULT_NOTIFICATION_RECIPIENTS]: fetchDefaultNotificationRecipients,
  [FETCH_DEFAULT_NOTIFICATION_RECIPIENTS_REQUEST]: fetchDefaultNotificationRecipientsRequest,
  [ADD_DEFAULT_NOTIFICATION_RECIPIENT]: addDefaultNotificationRecipient,
  [REMOVE_DEFAULT_NOTIFICATION_RECIPIENT]: removeDefaultNotificationRecipient,
  [EDIT_DEFAULT_NOTIFICATION_RECIPIENT]: editDefaultNotificationRecipient,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
