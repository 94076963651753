import axios from 'axios';

import { S3_FRONTEND_URL } from '../../constants';
import { languages, nameSpaces } from '../../constants/languages';

function getTranslationsPromises(application = '') {
  const promises = languages.reduce((allPromises, language) => {
    const filePromises = nameSpaces.map((namespace) => {
      return axios.get(`${S3_FRONTEND_URL}${language}${application}/${namespace}.json`)
        .then(({ data }) => ({
          language,
          namespace,
          translation: data
        })).catch((error) => ({
          error
        }));
    });

    allPromises.push(...filePromises);

    return allPromises;
  }, []);

  return promises;
}

export default function getTranslations() {
  const normalpromises = getTranslationsPromises();
  const railpromises = getTranslationsPromises('/rail');
  const promises = [...normalpromises, ...railpromises];

  return Promise.all(promises).then((results) => {
    const translations = {};

    results.forEach(({ language, namespace, translation, error }) => {
      if (error) {
        return;
      }

      translations[language] = translations[language] || {};
      translations[language][namespace] = { ...translations[language][namespace], ...translation };
    });

    return translations;
  });
}

