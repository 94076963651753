import {
  FETCH_RATINGS_SUCCESS,
  FETCH_RATINGS,
  FETCH_ALL_RATINGS_SUCCESS,
  ADD_RATING,
  REMOVE_RATING,
  EDIT_RATING,
} from '../actions/ratings';
import {
  REMOVE_RATING_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  ratingList: [],
  ratingsRequest: false,
  allRatingsList: [],
};

function fetchRatingsSuccess(state, payload) {
  return {
    ...state,
    ratingList: payload.ratings,
    ratingsRequest: false,
  };
}

function fetchAllRatingsSuccess(state, payload) {
  return {
    ...state,
    allRatingsList: payload.allRatings,
    ratingsRequest: false
  };
}

function fetchAllRatings(state) {
  return {
    ...state,
    ratingsRequest: true
  };
}

function addRating(state, payload) {
  return {
    ...state,
    ratingList: [
      ...state.ratingList,
      payload.rating
    ]
  };
}

function removeRating(state, payload) {
  return {
    ...state,
    ratingList: state.ratingList.filter((rating) => rating !== payload.rating)
  };
}

function editRating(state, payload) {
  return {
    ...state,
    ratingList: state.ratingList.map((rating) => {
      if (rating.id === payload.rating.id) {
        return payload.rating;
      }

      return rating;
    }),
    allRatingsList: state.allRatingsList.map((rating) => {
      if (rating.id === payload.rating.id) {
        return payload.rating;
      }

      return rating;
    }),
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    ratingList: state.ratingList.map((rating) => {
      if (rating.id === payload.ratingId) {
        return {
          ...rating,
          files: rating.files.filter((file) => file.id !== payload.fileId)
        };
      }

      return rating;
    })
  };
}

const actions = {
  [FETCH_RATINGS_SUCCESS]: fetchRatingsSuccess,
  [FETCH_RATINGS]: fetchAllRatings,
  [FETCH_ALL_RATINGS_SUCCESS]: fetchAllRatingsSuccess,
  [ADD_RATING]: addRating,
  [REMOVE_RATING]: removeRating,
  [EDIT_RATING]: editRating,
  [REMOVE_RATING_FILE_SUCCESS]: removeFile,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
