import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  longCell: {
    minWidth: 100,
  },
});

@withNamespaces()
@withStyles(styles)
export default class NotificationRecipientList extends Component {
  static propTypes = {
    notificationRecipientList: PropTypes.array,
    removeNotificationRecipient: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderNotificationRecipientList = (notificationRecipientList) => {
    const { removeNotificationRecipient, handleEditOpen, onElementDelete, classes } = this.props;

    return notificationRecipientList && notificationRecipientList.map((notificationRecipient) => {
      return (
        <TableRow key={`notification-${notificationRecipient.id}`}>
          <TableCell className={classes.longCell}>{notificationRecipient.firstName }</TableCell>
          <TableCell className={classes.longCell}>{notificationRecipient.lastName }</TableCell>
          <TableCell className={classes.longCell}>{notificationRecipient.email }</TableCell>
          <TableCell className={classes.longCell}>{notificationRecipient.notificationCategory.name }</TableCell>
          <TableCell numeric>
            <IconButton onClick={() => handleEditOpen(notificationRecipient)}>
              <Icon color="primary">mode_edit</Icon>
            </IconButton>
            <IconButton onClick={() => onElementDelete(() => removeNotificationRecipient(notificationRecipient))}>
              <Icon color="primary">delete</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { classes, handleAddOpen, notificationRecipientList, t } = this.props;

    if (_isEmpty(notificationRecipientList)) {
      return (
        <div className="pl-sm-5">
          <Button color="primary" onClick={handleAddOpen}>
            {t('settings:addNotificationsRecipientButton')}
          </Button>
        </div>
      );
    }

    return (
      <div className="pl-sm-5">
        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table:firstName')}</TableCell>
                  <TableCell>{t('table:lastName')}</TableCell>
                  <TableCell>{t('table:email')}</TableCell>
                  <TableCell>{t('table:notificationCategory')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderNotificationRecipientList(notificationRecipientList)}
              </TableBody>
            </Table>
          </div>
        </div>

        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addNotificationsRecipientButton')}
        </Button>
      </div>
    );
  }
}
