import React, { Component } from 'react';
import { getActionIcons, renderFiles } from '../usersHelper';
import {
  getVerifiedBy,
  isVerified,
} from '../PlannedAction/plannedActionHelper';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { reformatPickerDateFromDB } from '../../../utils/time';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 320,
  },
});

@withNamespaces()
@withStyles(styles)
export default class UserExperiencesList extends Component {
  static propTypes = {
    aircraftTypeExperienceList: PropTypes.array,
    removeExperience: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    handleEditOpen: PropTypes.func.isRequired,
    attachExperienceFile: PropTypes.func.isRequired,
    removeExperienceFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    deactivateExperience: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderAircraftTypeExperienceList() {
    const {
      aircraftTypeExperienceList,
      editMode,
      classes,
      handleEditOpen,
      attachExperienceFile,
      addSimpleError,
      deactivateExperience,
      removeExperience,
      removeExperienceFile,
    } = this.props;
    const actions = {
      handleEditOpen,
      addSimpleError,
      deactivateItem: deactivateExperience,
      removeItem: removeExperience,
      attachItemFile: attachExperienceFile,
      documentName: 'Medical',
    };
    const documentName = 'medical';

    return aircraftTypeExperienceList.map((experience) => {
      return (
        <TableRow key={`${documentName}-${experience.id}`}>
          <TableCell className={classes.longCell}>
            {`${experience.type.name} (${experience.type.info})`}
          </TableCell>
          <TableCell className={classes.longCell}>{experience.value}</TableCell>
          <TableCell className={classes.longCell}>
            {reformatPickerDateFromDB(experience.expiryDate, false)}
          </TableCell>
          <TableCell className={classes.longCell}>
            {reformatPickerDateFromDB(experience.dateOfIssue, false)}
          </TableCell>
          <TableCell className={classes.longCell}>
            {isVerified(experience.isVerified)}
          </TableCell>
          <TableCell className={classes.longCell}>
            {getVerifiedBy(experience.verifiedBy)}
          </TableCell>
          <TableCell className={classes.longCell}>
            {reformatPickerDateFromDB(experience.verificationDate, false)}
          </TableCell>
          <TableCell>
            {renderFiles(
              editMode,
              experience,
              removeExperienceFile,
              documentName,
            )}
          </TableCell>
          <TableCell className={classes.longCell}>
            {isVerified(experience.isActive)}
          </TableCell>
          <TableCell numeric className={classes.iconsCell}>
            {getActionIcons(editMode, experience, actions)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderAddButton(editMode, handleAddOpen, t) {
    if (editMode) {
      return (
        <Button className="my-2" color="primary" onClick={handleAddOpen}>
          {t('user:addUserExperience')}
        </Button>
      );
    }

    return null;
  }

  render() {
    const { classes, handleAddOpen, editMode, aircraftTypeExperienceList, t } =
      this.props;

    if (_isEmpty(aircraftTypeExperienceList)) {
      return (
        <div>
          <h4>{t('user:userExperiences')}</h4>
          {this.renderAddButton(editMode, handleAddOpen, t)}
          <Divider className="my-3" />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <h4>{t('user:userExperiences')}</h4>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:aircraft type')}</TableCell>
              <TableCell>{t('table:value')}</TableCell>
              <TableCell>{t('table:expiryDate')}</TableCell>
              <TableCell>{t('table:dateOfIssue')}</TableCell>
              <TableCell>{t('table:isVerified')}</TableCell>
              <TableCell>{t('table:verifiedBy')}</TableCell>
              <TableCell>{t('table:verificationDate')}</TableCell>
              <TableCell>{t('table:attachments')}</TableCell>
              <TableCell>{t('table:isActive')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderAircraftTypeExperienceList()}</TableBody>
        </Table>
        {this.renderAddButton(editMode, handleAddOpen, t)}
        <Divider className="my-3" />
      </div>
    );
  }
}
