import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { reformatPickerDateFromDB } from '../../../utils/time';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 320,
  },
});

@withNamespaces()
@withStyles(styles)
export default class VehicleList extends Component {
  static propTypes = {
    vehicleList: PropTypes.array,
    removeVehicle: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderVehicleList() {
    const {
      vehicleList,
      handleEditOpen,
      removeVehicle,
      onElementDelete,
      classes,
    } = this.props;

    return vehicleList.map((vehicle) => {
      return (
        <TableRow key={`vehicle-${vehicle.id}`}>
          <TableCell className={classes.longCell}>{vehicle.name}</TableCell>
          <TableCell className={classes.longCell}>
            {vehicle.type.name}
          </TableCell>
          <TableCell className={classes.longCell}>
            {vehicle.group && vehicle.group.name}
          </TableCell>
          <TableCell className={classes.longCell}>{vehicle.evn}</TableCell>
          <TableCell className={classes.longCell}>
            {reformatPickerDateFromDB(vehicle.activeSince, false)}
          </TableCell>
          <TableCell className={classes.longCell} numeric>
            <IconButton onClick={() => handleEditOpen(vehicle)}>
              <Icon color="primary">mode_edit</Icon>
            </IconButton>
            <IconButton
              onClick={() => onElementDelete(() => removeVehicle(vehicle))}
            >
              <Icon color="primary">delete</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes, handleAddOpen, vehicleList, t } = this.props;

    if (_isEmpty(vehicleList)) {
      return (
        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addVehicleButton')}
        </Button>
      );
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:name')}</TableCell>
              <TableCell>{t('table:type')}</TableCell>
              <TableCell>{t('table:group')}</TableCell>
              <TableCell>{t('table:evn')}</TableCell>
              <TableCell>{t('table:active since')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderVehicleList()}</TableBody>
        </Table>
        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addVehicleButton')}
        </Button>
      </div>
    );
  }
}
