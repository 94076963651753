import React, { Component } from 'react';
import {
  addReportFleetCategory,
  editReportFleetCategory,
  fetchReportFleetCategories,
  removeReportFleetCategory,
} from '../../../actions/reportFleetCategories';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import Loader from '../../../../common/components/Loader';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import ReportFleetCategoryList from './ReportFleetCategoryList';
import { connect } from 'react-redux';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  name: '',
  parent: '',
  reportFleetCategoryId: '',
};

const mapStateToProps = (state) => {
  return {
    reportFleetCategoryList:
      state.reportFleetCategories.reportFleetCategoryList,
    reportFleetCategoryRequest:
      state.reportFleetCategories.reportFleetCategoryRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchReportFleetCategories: () => dispatch(fetchReportFleetCategories()),
    addReportFleetCategory: (reportFleetCategory) =>
      dispatch(addReportFleetCategory(reportFleetCategory)),
    editReportFleetCategory: (reportFleetCategory) =>
      dispatch(editReportFleetCategory(reportFleetCategory)),
    removeReportFleetCategory: (reportFleetCategory) =>
      dispatch(removeReportFleetCategory(reportFleetCategory)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class FleetReportCategoriesManager extends Component {
  static propTypes = {
    reportFleetCategoryList: PropTypes.array,
    reportFleetCategoryRequest: PropTypes.bool.isRequired,
    fetchReportFleetCategories: PropTypes.func.isRequired,
    addReportFleetCategory: PropTypes.func.isRequired,
    editReportFleetCategory: PropTypes.func.isRequired,
    removeReportFleetCategory: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.fetchReportFleetCategories();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (reportFleetCategory) => {
    this.setState({
      editOpen: true,
      name: reportFleetCategory.name,
      parent: reportFleetCategory.parent,
      reportFleetCategoryId: reportFleetCategory.id,
    });
  };

  handleClose = () => {
    this.setState(INITIAL_STATE);
  };

  addReportFleetCategory = () => {
    const { name, parent } = this.state;

    this.props.addReportFleetCategory({ name, parent });
    this.handleClose();
  };

  editReportFleetCategory = () => {
    const { reportFleetCategoryId, name, parent } = this.state;

    this.props.editReportFleetCategory({
      id: reportFleetCategoryId,
      name,
      parent,
    });
    this.handleClose();
  };

  removeReportFleetCategory = (fleetReportCategory) => {
    this.props.removeReportFleetCategory(fleetReportCategory);
    this.handleClose();
  };

  onChange = (formData, name) => {
    this.setState({
      [name]: formData[name].value,
    });
  };

  onFormValidated = () => {
    const { addOpen } = this.state;

    return (isFormValid) => {
      if (isFormValid) {
        addOpen
          ? this.addReportFleetCategory()
          : this.editReportFleetCategory();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const {
      reportFleetCategoryList,
      reportFleetCategoryRequest,
      onElementDelete,
      t,
    } = this.props;
    const { addOpen, editOpen, name, parent, reportFleetCategoryId } =
      this.state;

    if (reportFleetCategoryRequest) {
      return <Loader />;
    }

    const hasSubcategories = reportFleetCategoryList.filter(
      (cat) => cat.parent === reportFleetCategoryId,
    ).length;

    return (
      <div className="report-fleet-categories-settings">
        <div className="pl-sm-5">
          <h4>Report fleet categories</h4>
        </div>
        <ReportFleetCategoryList
          fleetReportCategoryList={reportFleetCategoryList}
          removeFleetReportCategory={this.removeReportFleetCategory}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen || editOpen}
            onClose={this.handleClose}
            aria-labelledby="add-report-fleet-category-dialog"
            fullWidth
          >
            <DialogTitle id="add-report-fleet-category-dialog">
              {addOpen
                ? 'Add report fleet category'
                : 'Edit report fleet category'}
            </DialogTitle>
            <DialogContent>
              {hasSubcategories ? (
                <div className="row">
                  <div className="col-12">
                    <div class="alert alert-info" role="alert">
                      {t(
                        'You cannot set parent subcategory since current category has subcategories',
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="row">
                <div className="col-6">
                  <MaterialInput
                    label={t('input:name')}
                    name="name"
                    autoFocus
                    defaultValue={name}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                    ]}
                  />
                </div>
                {hasSubcategories ? (
                  ''
                ) : (
                  <div className="col-6">
                    <MaterialSelect
                      id="parent"
                      name="parent"
                      label={t('input:Parent category')}
                      defaultValue={parent}
                      options={reportFleetCategoryList
                        .filter(
                          (category) => category.id !== reportFleetCategoryId,
                        )
                        .filter((category) => !category.parent)
                        .map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                    />
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
