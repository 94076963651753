import {
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_REQUEST,
  FETCH_ITEMS_FAILURE,
  ADD_ITEM,
  EDIT_ITEM,
  REMOVE_ITEM,
} from '../actions/items';
import {
  REMOVE_ITEM_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  itemList: [],
  itemRequest: false,
  currentItem: {}
};

function fetchItems(state, payload) {
  return {
    ...state,
    itemList: payload.items,
    itemRequest: false,
  };
}

function fetchItemsRequest(state) {
  return {
    ...state,
    itemRequest: true,
  };
}

function fetchItemsFailure(state) {
  return {
    ...state,
    itemRequest: false,
  };
}

function addItem(state, payload) {
  return {
    ...state,
    itemList: [
      ...state.itemList,
      payload.item
    ],
    currentItem: payload.item
  };
}

function editItem(state, payload) {
  return {
    ...state,
    itemList: state.itemList.map((item) => {
      if (item.id === payload.item.id) {
        return payload.item;
      }

      return item;
    }),
    currentItem: payload.item
  };
}

function removeItem(state, payload) {
  return {
    ...state,
    itemList: state.itemList.filter((item) => item.id !== payload.item.id),
    currentItem: {}
  };
}

function removeFile(state, payload) {
  let myItem = {};

  return {
    ...state,
    itemList: state.itemList.map((item) => {
      if (item.id === payload.itemId) {
        myItem = {
          ...item,
          files: item.files.filter((file) => file.id !== payload.fileId)
        };

        return myItem;
      }

      return item;
    }),
    currentItem: myItem,
  };
}

const actions = {
  [FETCH_ITEMS_SUCCESS]: fetchItems,
  [FETCH_ITEMS_REQUEST]: fetchItemsRequest,
  [FETCH_ITEMS_FAILURE]: fetchItemsFailure,
  [ADD_ITEM]: addItem,
  [EDIT_ITEM]: editItem,
  [REMOVE_ITEM]: removeItem,
  [REMOVE_ITEM_FILE_SUCCESS]: removeFile
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
