import { FETCH_MODULES_REQUEST, FETCH_MODULES_SUCCESS } from '../actions/modules';

const INITIAL_STATE = {
  modulesRequest: false,
  moduleList: [],
};

function fetchModulesRequest(state) {
  return {
    ...state,
    modulesRequest: true,
  };
}

function fetchModules(state, payload) {
  return {
    ...state,
    moduleList: payload.modules.results,
    modulesRequest: false,
  };
}

const actions = {
  [FETCH_MODULES_REQUEST]: fetchModulesRequest,
  [FETCH_MODULES_SUCCESS]: fetchModules,
};

export default function modulesReducer(state = INITIAL_STATE, action) {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
}
