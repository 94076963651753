import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import { trimToLongText } from '../../../../utils/index';

const styles = () => ({
  smallerIconSize: {
    fontSize: 18,
  },
  smallerTextSize: {
    fontSize: 14,
    lineHeight: '32px',
  },
  itemStatusTextSize: {
    fontSize: 12,
  },
});

@withNamespaces()
@withStyles(styles)
export default class Item extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    addPendingItem: PropTypes.func.isRequired,
    preview: PropTypes.bool,
    isPending: PropTypes.bool,
    isCompleted: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderButtons(item, preview) {
    const { addPendingItem, classes } = this.props;

    if (preview) {
      return null;
    }

    return (
      <div className="col-auto pl-0">
        <IconButton onClick={() => addPendingItem(item)}><Icon color="primary" className={classes.smallerIconSize}>add</Icon></IconButton>
      </div>
    );
  }

  pendingItem() {
    const { classes, t } = this.props;

    return (
      <div className="col-auto">
        <span className={classNames(classes.itemStatusTextSize, 'item-pending')}>
          {t('course:inProgressItem')}
        </span>
      </div>
    );
  }

  completedItem() {
    const { classes, t } = this.props;

    return (
      <div className="col-auto">
        <span className={classNames(classes.itemStatusTextSize, 'item-completed')}>
          {t('course:completedItem')}
        </span>
      </div>
    );
  }

  notStartedItem() {
    const { classes, t } = this.props;

    return (
      <div className="col-auto">
        <span className={classNames(classes.itemStatusTextSize, 'item-not-completed')}>
          {t('course:notStartedItem')}
        </span>
      </div>
    );
  }

  render() {
    const {
      preview,
      item,
      isPending,
      isCompleted,
      classes,
    } = this.props;

    return (
      <Paper
        elevation={4}
        className="m-3 px-2 justify-content-between align-items-center"
      >
        <div>
          <span className={classes.smallerTextSize}>{trimToLongText(item.name, 30)}</span>
        </div>
        <div>
          <div className="row py-1 justify-content-between align-items-center">
            <div className="col-auto  pr-0">
              <span className={classes.smallerTextSize}>{item.estimatedTime}</span>
            </div>
            {isPending && this.pendingItem()}
            {isCompleted && this.completedItem()}
            {!isPending && !isCompleted && this.notStartedItem()}
            {this.renderButtons(item, preview)}
          </div>
        </div>
      </Paper>
    );
  }
}
