import React, { Component } from 'react';
import {
  addWarehouseConsumable,
  fetchWarehouseConsumables,
  removeWarehouseConsumable,
  updateWarehouseConsumable,
} from '../../../actions/warehouseConsumables';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import ConsumableList from './ConsumableList';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  selected: {},
  editData: {},
  showRemoveDialog: false,
  removeCallback: () => {},
  consumables: [],
  consumableRequest: false,
};

const mapStateToProps = (state) => {
  return {
    consumables: state.warehouseConsumables.consumableList,
    consumableRequest: state.warehouseConsumables.consumableRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    addWarehouseConsumable: (warehouseId, data) =>
      dispatch(addWarehouseConsumable(warehouseId, data)),
    updateWarehouseConsumable: (wareHouseId, id, data) =>
      dispatch(updateWarehouseConsumable(wareHouseId, id, data)),
    removeWarehouseConsumable: (warehouseId, id) =>
      dispatch(removeWarehouseConsumable(warehouseId, id)),
    fetchWarehouseConsumables: (id) => dispatch(fetchWarehouseConsumables(id)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsumablesManager extends Component {
  static propTypes = {
    warehouse: PropTypes.object.isRequired,
    consumables: PropTypes.object.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    warehouseSettings: PropTypes.object.isRequired,
    addWarehouseConsumable: PropTypes.object.isRequired,
    updateWarehouseConsumable: PropTypes.object.isRequired,
    removeWarehouseConsumable: PropTypes.object.isRequired,
    fetchWarehouseConsumables: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { fetchWarehouseConsumables, warehouse } = this.props;

    this.formData = {};

    fetchWarehouseConsumables(warehouse.id);
  }

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
      selected: {},
    });
  };

  handleEditOpen = (consumable) => {
    if (consumable.expirationDate) {
      this.setState({
        editOpen: true,
        selected: consumable,
        editData: {
          ...this.state.editData,
          expirationDate: reformatPickerDateFromDB(consumable.expirationDate),
        },
      });
    } else {
      this.setState({
        editOpen: true,
        selected: consumable,
      });
    }
  };

  handleDeleteOpen = (consumable, removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      selected: consumable,
      removeCallback,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      editData: {
        ...this.state.editData,
        [name]: date,
      },
    });
  };

  onChange = (formData, name) => {
    this.setState({
      editData: { ...this.state.editData, [name]: formData[name].value },
    });
  };

  add = () => {
    const { warehouse, addWarehouseConsumable } = this.props;
    const { editData } = this.state;

    if (editData.expirationDate) {
      editData.expirationDate = reformatPickerDateToDB(editData.expirationDate);
    }

    addWarehouseConsumable(warehouse.id, editData);

    this.handleDialogClose();
  };

  update = () => {
    const { updateWarehouseConsumable } = this.props;
    const { selected, editData } = this.state;

    if (editData.expirationDate) {
      editData.expirationDate = reformatPickerDateToDB(editData.expirationDate);
    }

    updateWarehouseConsumable(selected.warehouse, selected.id, editData);

    this.handleDialogClose();
  };

  onFormValidated = (isFormValid) => {
    const { addOpen } = this.state;

    if (isFormValid && this.formData) {
      addOpen ? this.add() : this.update();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const {
      warehouse,
      consumables,
      removeWarehouseConsumable,
      classes,
      t,
      addSimpleError,
      editMode,
      warehouseSettings,
    } = this.props;
    const {
      addOpen,
      editOpen,
      selected,
      editData,
      showRemoveDialog,
      removeCallback,
    } = this.state;

    return (
      <div className="warehouse-consumables">
        <h4>{t('warehouse:Goods')}</h4>
        <ConsumableList
          warehouse={warehouse}
          consumables={consumables}
          removeWarehouseConsumable={removeWarehouseConsumable}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          handleDeleteOpen={this.handleDeleteOpen}
          addSimpleError={addSimpleError}
          editMode={editMode}
          warehouseSettings={warehouseSettings}
        />
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          dialogTitle={t('warehouse:Remove' + ` ${selected.name}`)}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={addOpen || editOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="add-service-type-dialog"
          maxWidth="md"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle id="add-edit-service-type-dialog">
              {addOpen
                ? t('warehouse:Add goods')
                : `${t('warehouse:Edit')} ${selected.name}`}
            </DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row">
                <div className="col-12">
                  <MaterialInput
                    label={t('input:Name')}
                    name="name"
                    defaultValue={selected.name}
                    stateValue={selected.name}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                    ]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:Part number')}
                    name="partNumber"
                    defaultValue={selected.partNumber}
                    stateValue={selected.partNumber}
                    margin="dense"
                    validators={[new validators.MaxLength(t, 200)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:Serial/batch number')}
                    name="serialNumber"
                    defaultValue={selected.serialNumber}
                    stateValue={selected.serialNumber}
                    margin="dense"
                    validators={[new validators.MaxLength(t, 200)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    type="number"
                    label={t('input:Quantity')}
                    name="quantity"
                    defaultValue="1"
                    stateValue={selected.quantity}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:Expiration date')}
                    name="expirationDate"
                    value={editData.expirationDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange(
                      'expirationDate',
                    )}
                    dateFormat={getDateFormat()}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </div>
    );
  }
}
