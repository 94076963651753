import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

import getTranslations from './translations';
import { nameSpaces, languages, fallbackLanguage, defaultNamespace } from '../constants/languages';

const resources = getTranslations();
const formats = {
  capitalize: (value) => value[0].toUpperCase().concat(value.slice(1, value.length)),
  upperCase: (value) => value.toUpperCase(),
  lowerCase: (value) => value.toLowerCase(),
};
const options = {
  order: ['localStorage'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
};

export default function init() {
  return resources.then((resources) => {
    return i18next
      .use(detector)
      .use(reactI18nextModule)
      .init({
        resources,
        fallbackLng: fallbackLanguage,
        keySeparator: false,
        interpolation: {
          escapeValue: false,
          format(value, format) {
            if (format in formats) {
              return formats[format](value);
            }

            return value;
          }
        },
        react: {
          wait: true
        },
        whitelist: languages,
        ns: nameSpaces,
        defaultNS: defaultNamespace,
        fallbackNS: defaultNamespace,
        detection: options
      });
  }).catch((error) => {
    console.log(`Translations error: ${error}`);
  });
}
