import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  FETCH_RSS_NEWS_SUCCESS,
  FETCH_RSS_NEWS_REQUEST,
  FETCH_RSS_NEWS_FAILURE,
} from '../actions/rssNews';

const INITIAL_STATE = {
  rssNews: [],
  rssNewsRequest: false,
};

function fetchRssNews(state, payload) {
  return {
    ...state,
    rssNews: !_isEmpty(payload.rssNews) && [
      {
        title: _get(payload.rssNews[0], 'title.$', ''),
        description: _get(payload.rssNews[0], 'description.$', ''),
        link: _get(payload.rssNews[0], 'link.$', ''),
      },
      {
        title: _get(payload.rssNews[1], 'title.$', ''),
        description: _get(payload.rssNews[1], 'description.$', ''),
        link: _get(payload.rssNews[1], 'link.$', ''),
      },
      {
        title: _get(payload.rssNews[2], 'title.$', ''),
        description: _get(payload.rssNews[2], 'description.$', ''),
        link: _get(payload.rssNews[2], 'link.$', ''),
      },
    ],
    rssNewsRequest: false,
  };
}

function fetchRssNewsRequest(state) {
  return {
    ...state,
    rssNewsRequest: true,
  };
}

function fetchRssNewsFailure(state) {
  return {
    ...state,
    rssNewsRequest: false,
  };
}


const actions = {
  [FETCH_RSS_NEWS_SUCCESS]: fetchRssNews,
  [FETCH_RSS_NEWS_REQUEST]: fetchRssNewsRequest,
  [FETCH_RSS_NEWS_FAILURE]: fetchRssNewsFailure,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
