import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import NotFound from '../Errors/NotFound';
import ServerError from '../Errors/ServerError';
import UserList from '../Users/UserList';
import UserTabs from '../Users/UserTabsView';
import DashboardHome from '../Dashboard';
import GeneralSettings from '../Settings/General';
import CompanySettings from '../Settings/Company';
import UsersSettings from '../Settings/User';
import ReportsSettings from '../Settings/Reports';
import NotificationsSettings from '../Settings/Notifications';
import ManageCourses from '../Training/ManageCourses';
import CourseNew from '../Training/CourseNew';
import CourseDetails from '../Training/CourseDetails';
import CourseEdit from '../Training/CourseEdit';
import CourseList from '../Training/CourseList';
import AssignedCourseDetails from '../Training/AssignedCourseDetails';
import AssignedCourseEdit from '../Training/AssignedCourseEdit';
import LessonNew from '../Training/LessonNew';
import LessonEdit from '../Training/LessonEdit';
import LessonDetails from '../Training/LessonDetails';
import MyCourses from '../Training/MyCourses';
import NotifyCourses from '../Training/NotifyCourses';
import MyTests from '../Tests/MyTests';
import MyProfile from '../Users/UserProfile/MyProfile';
import ManageQuestions from '../Tests/ManageQuestions';
import ManageTests from '../Tests/ManageTests';
import QuestionNew from '../Tests/QuestionNew';
import QuestionDetails from '../Tests/QuestionDetails';
import QuestionEdit from '../Tests/QuestionEdit';
import TestNew from '../Tests/TestNew';
import TestDetails from '../Tests/TestDetails';
import TestList from '../Tests/TestList';
import TestEdit from '../Tests/TestEdit';
import AssignedTestDetails from '../Tests/AssignedTestDetails';
import CompletedTestPreview from '../Tests/CompletedTestPreview';
import NotifyTests from '../Tests/NotifyTests';
import Booking from '../Booking';
import CrewStatus from '../CrewStatus';
import UsersCategories from '../UsersCategories';
import MyLogbook from '../Logbook/MyLogbook';
import LogbookList from '../Logbook/LogbookList';
import LogbookNew from '../Logbook/LogbookNew';
import LogbookEdit from '../Logbook/LogbookEdit';
import LogbookDetails from '../Logbook/LogbookDetails';
import ReportList from '../Report/ReportList';
import ReportNew from '../Report/ReportNew';
import ReportDetails from '../Report/ReportDetails';
import RiskAssessment from '../RiskManagement/RiskAssessment';
import RiskManagement from '../RiskManagement';
import MyReports from '../Report/MyReports';
import EquipmentList from '../Equipment/EquipmentList';
import EquipmentNew from '../Equipment/EquipmentNew';
import EquipmentEdit from '../Equipment/EquipmentEdit';
import EquipmentDetails from '../Equipment/EquipmentDetails';
import Library from '../Library';
import ElearningList from '../Elearning/ElearningList';
import FleetTechnicalFaultReportList from '../Fleet/FleetAnalysis/TechnicalFaultReportList';
import FleetList from '../Fleet/FleetList';
import FleetNew from '../Fleet/FleetNew';
import FleetEdit from '../Fleet/FleetEdit';
import FleetDetails from '../Fleet/FleetDetails';
import PartList from '../Fleet/PartList';
import PartNew from '../Fleet/PartNew';
import PartEdit from '../Fleet/PartEdit';
import PartDetails from '../Fleet/PartDetails';
import WarehouseList from '../Warehouse/WarehouseList';
import WarehouseNew from '../Warehouse/WarehouseNew';
import WarehouseEdit from '../Warehouse/WarehouseEdit';
import WarehouseDetails from '../Warehouse/WarehouseDetails';

import { MODULES } from '../../../constants/modules';
import { renderModule } from '../../decorators/modules';
import { renderPermission } from '../../decorators/permissions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';

export default class Routes extends Component {

  @renderModule(MODULES.exams)
  renderExam() {
    return (
      [
        <Route
          exact
          path="/my_exams"
          key="/my_exams"
          component={MyTests}
        />,
        <Route
          exact
          path="/exam_admin"
          key="/exam_admin"
          component={ManageTests}
        />,
        <Route
          exact
          path="/question_bank"
          key="/question_bank"
          component={ManageQuestions}
        />,
        <Route
          exact
          path="/question_bank/new"
          key="/question_bank/new"
          component={QuestionNew}
        />,
        <Route
          exact
          path="/question_bank/details/:id"
          key="/question_bank/details/:id"
          component={QuestionDetails}
        />,
        <Route
          exact
          path="/question_bank/edit/:id"
          key="/question_bank/edit/:id"
          component={QuestionEdit}
        />,
        <Route
          exact
          path="/exam_admin/new"
          key="/exam_admin/new"
          component={TestNew}
        />,
        <Route
          exact
          path="/exam_list"
          key="/exam_list"
          component={TestList}
        />,
        <Route
          exact
          path="/exam_list/details/:id"
          key="/exam_list/details/:id"
          component={AssignedTestDetails}
        />,
        <Route
          exact
          path="/exam_admin/details/:id"
          key="/exam_admin/details/:id"
          component={TestDetails}
        />,
        <Route
          exact
          path="/exam_admin/edit/:id"
          key="/exam_admin/edit/:id"
          component={TestEdit}
        />,
        <Route
          exact
          path="/completed_test/preview/:id"
          key="/completed_test/preview/:id"
          component={CompletedTestPreview}
        />,
        <Route
          exact
          path="/notify_assigned_test"
          key="/notify_assigned_test"
          component={NotifyTests}
        />,
      ]
    );
  }

  @renderPermission(PERMISSIONS.userProfileView)
  renderUsers() {
    return ([
      <Route
        exact
        path="/users/:id"
        key="/users/:id"
        component={UserTabs}
      />,
      <Route
        exact
        path="/users"
        key="/users"
        component={UserList}
      />
    ]);
  }

  @renderModule(MODULES.training)
  renderTraining() {
    return (
      [
        <Route
          exact
          path="/my_education"
          key="/my_education"
          component={MyCourses}
        />,
        <Route
          exact
          path="/course_list"
          key="/course_list"
          component={CourseList}
        />,
        <Route
          exact
          path="/course_list/details/:id"
          key="/course_list/details/:id"
          component={AssignedCourseDetails}
        />,
        <Route
          exact
          path="/course_list/edit/:id"
          key="/course_list/edit/:id"
          component={AssignedCourseEdit}
        />,
        <Route
          exact
          path="/course_list/details/:id/lesson/new"
          key="/course_list/details/:id/lesson/new"
          component={LessonNew}
        />,
        <Route
          exact
          path="/course_list/details/:id/lesson/edit/:lessonId"
          key="/course_list/details/:id/lesson/edit/:lessonId"
          component={LessonEdit}
        />,
        <Route
          exact
          path="/course_list/details/:id/lesson/details/:lessonId"
          key="/course_list/details/:id/lesson/details/:lessonId"
          component={LessonDetails}
        />,
        <Route
          exact
          path="/course_admin"
          key="/course_admin"
          component={ManageCourses}
        />,
        <Route
          exact
          path="/course_admin/new"
          key="/course_admin/new"
          component={CourseNew}
        />,
        <Route
          exact
          path="/course_admin/details/:id"
          key="/course_admin/details/:id"
          component={CourseDetails}
        />,
        <Route
          exact
          path="/course_admin/edit/:id"
          key="/course_admin/edit/:id"
          component={CourseEdit}
        />,
        <Route
          exact
          path="/notify_assigned_course"
          key="/notify_assigned_course"
          component={NotifyCourses}
        />
      ]
    );
  }

  @renderModule(MODULES.booking)
  renderBooking() {
    return (
      <Route
        exact
        path="/booking"
        key="/booking"
        component={Booking}
      />
    );
  }

  @renderModule(MODULES.logbook)
  renderLogbook() {
    return ([
      <Route
        exact
        path="/my_logbook"
        key="/my_logbook"
        component={MyLogbook}
      />,
      <Route
        exact
        path="/journey_log/new"
        key="/journey_log/new"
        component={LogbookNew}
      />,
      <Route
        exact
        path="/journey_log/details/:id"
        key="/journey_log/details/:id"
        component={LogbookDetails}
      />,
      <Route
        exact
        path="/journey_log/edit/:id"
        key="/journey_log/edit/:id"
        component={LogbookEdit}
      />,
      <Route
        exact
        path="/logbook_list"
        key="/logbook_list"
        component={LogbookList}
      />,
    ]);
  }

  @renderPermission(PERMISSIONS.viewAllEquipments)
  viewEquipment() {
    return ([
      <Route
        exact
        path="/equipment"
        key="/equipment"
        component={EquipmentList}
      />,
      <Route
        exact
        path="/equipment/details/:id"
        key="/equipment/details/:id"
        component={EquipmentDetails}
      />
    ]);
  }

  @renderPermission(PERMISSIONS.createEquipment)
  createEquipment() {
    return ([
      <Route
        exact
        path="/equipment/new"
        key="/equipment/new"
        component={EquipmentNew}
      />,
    ]);
  }

  @renderPermission(PERMISSIONS.updateEquipment)
  editEquipment() {
    return ([
      <Route
        exact
        path="/equipment/edit/:id"
        key="/equipment/edit/:id"
        component={EquipmentEdit}
      />
    ]);
  }

  @renderModule(MODULES.equipment)
  renderEquipment() {
    return [
      this.viewEquipment(),
      this.createEquipment(),
      this.editEquipment()
    ];
  }

  @renderModule(MODULES.report)
  renderReport() {
    return (
      [
        <Route
          exact
          path="/report"
          key="/report"
          component={ReportList}
        />,
        <Route
          exact
          path="/report/new"
          key="/report/new"
          component={ReportNew}
        />,
        <Route
          exact
          path="/report/:id"
          key="/report/:id"
          component={ReportDetails}
        />,
        <Route
          exact
          path="/my_reports"
          key="/my_reports"
          component={MyReports}
        />,
      ]
    );
  }

  @renderModule(MODULES.library)
  renderLibrary() {
    return (
      <Route
        exact
        path="/library"
        key="/library"
        component={Library}
      />
    );
  }

  @renderModule(MODULES.eLearning)
  renderElearning() {
    return (
      <Route
        exact
        path="/elearning"
        key="/elearning"
        component={ElearningList}
      />
    );
  }

  @renderModule(MODULES.report)
  renderReportSettings() {
    return (
      <Route
        exact
        path="/settings/reports"
        component={ReportsSettings}
      />
    );
  }

  @renderModule(MODULES.fleet)
  @renderPermission(PERMISSIONS.viewFleets)
  renderFleet() {
    return ([
      <Route
        exact
        path="/fleet"
        key="/fleet"
        component={FleetList}
      />,
      <Route
        exact
        path="/fleet/analysis"
        key="/fleet/analysis"
        component={FleetTechnicalFaultReportList}
      />,
      <Route
        exact
        path="/fleet/details/:id"
        key="/fleet/details/:id"
        component={FleetDetails}
      />,
      <Route
        exact
        path="/fleet/new"
        key="/fleet/new"
        component={FleetNew}
      />,
      <Route
        exact
        path="/fleet/edit/:id"
        key="/fleet/edit/:id"
        component={FleetEdit}
      />,
      <Route
        exact
        path="/parts"
        key="/parts"
        component={PartList}
      />,
      <Route
        exact
        path="/parts/new"
        key="/parts/new"
        component={PartNew}
      />,
      <Route
        exact
        path="/parts/details/:id"
        key="/parts/details/:id"
        component={PartDetails}
      />,
      <Route
        exact
        path="/parts/edit/:id"
        key="/parts/edit/:id"
        component={PartEdit}
      />,
      <Route
        exact
        path="/warehouse"
        key="/warehouse"
        component={WarehouseList}
      />,
      <Route
        exact
        path="/warehouse/new"
        key="/warehouse/new"
        component={WarehouseNew}
      />,
      <Route
        exact
        path="/warehouse/details/:id"
        key="/warehouse/details/:id"
        component={WarehouseDetails}
      />,
      <Route
        exact
        path="/warehouse/edit/:id"
        key="/warehouse/edit/:id"
        component={WarehouseEdit}
      />,
      <Route
        exact
        path="/sms"
        key="/sms"
        component={RiskManagement}
      />,
      <Route
        exact
        path="/sms/risk-assessments/:id?"
        key="/sms/risk-assessments/"
        component={RiskAssessment}
      />,
    ]);
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          component={DashboardHome}
        />
        <Route
          exact
          path="/my_profile"
          component={MyProfile}
        />
        {this.renderTraining()}
        {this.renderExam()}
        {this.renderBooking()}
        {this.renderReport()}
        {this.renderLogbook()}
        {this.renderUsers()}
        {this.renderEquipment()}
        {this.renderLibrary()}
        {this.renderElearning()}
        {this.renderFleet()}
        <Route
          exact
          path="/settings/general"
          component={GeneralSettings}
        />
        <Route
          exact
          path="/settings/company"
          component={CompanySettings}
        />
        <Route
          exact
          path="/settings/users"
          component={UsersSettings}
        />
        {this.renderReportSettings()}
        <Route
          exact
          path="/personnel_status"
          component={CrewStatus}
        />
        <Route
          exact
          path="/users_categories"
          component={UsersCategories}
        />
        <Route
          exact
          path="/settings/notifications"
          component={NotificationsSettings}
        />
        <Route
          exact
          path="/server-error"
          component={ServerError}
        />
        <Route
          path="*"
          component={NotFound}
        />
      </Switch>
    );
  }
}
