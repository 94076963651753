import {
  ADD_ENGINEER_AIRCRAFT_TYPE_EXP,
  EDIT_ENGINEER_AIRCRAFT_TYPE_EXP,
  FETCH_ALL_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS,
  FETCH_ENGINEER_AIRCRAFT_TYPE_EXP,
  FETCH_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS,
  REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP,
} from '../actions/engineerAircraftTypeExperience';

import { REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP_FILE_SUCCESS } from '../actions/fileUpload';

const INITIAL_STATE = {
  aircraftTypeExperienceList: [],
  allAircraftTypeExperienceList: [],
  aircraftTypeExperience: false,
};

function fetchAircraftTypeExperienceSuccess(state, payload) {
  return {
    ...state,
    aircraftTypeExperienceList: payload.experiences,
    aircraftTypeExperience: false,
  };
}

function fetchAllAircraftTypeExperienceSuccess(state, payload) {
  return {
    ...state,
    allAircraftTypeExperienceList: payload.allEngineerAircraftTypeExperience,
    aircraftTypeExperience: false,
  };
}

function fetchAllAircraftTypeExperience(state) {
  return {
    ...state,
    aircraftTypeExperience: true,
  };
}

function addAircraftTypeExperience(state, payload) {
  return {
    ...state,
    aircraftTypeExperienceList: [
      ...state.aircraftTypeExperienceList,
      payload.experience,
    ],
  };
}

function removeAircraftTypeExperience(state, payload) {
  return {
    ...state,
    aircraftTypeExperienceList: state.aircraftTypeExperienceList.filter(
      (experience) => experience !== payload.experience,
    ),
  };
}

function editAircraftTypeExperience(state, payload) {
  return {
    ...state,
    aircraftTypeExperienceList: state.aircraftTypeExperienceList.map(
      (experience) => {
        if (experience.id === payload.experience.id) {
          return payload.experience;
        }

        return experience;
      },
    ),
    allAircraftTypeExperienceList: state.allAircraftTypeExperienceList.map(
      (experience) => {
        if (experience.id === payload.experience.id) {
          return payload.experience;
        }

        return experience;
      },
    ),
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    aircraftTypeExperienceList: state.aircraftTypeExperienceList.map(
      (experience) => {
        if (experience.id === payload.expId) {
          return {
            ...experience,
            files: experience.files.filter(
              (file) => file.id !== payload.fileId,
            ),
          };
        }

        return experience;
      },
    ),
  };
}

const actions = {
  [ADD_ENGINEER_AIRCRAFT_TYPE_EXP]: addAircraftTypeExperience,
  [REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP]: removeAircraftTypeExperience,
  [EDIT_ENGINEER_AIRCRAFT_TYPE_EXP]: editAircraftTypeExperience,
  [FETCH_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS]:
    fetchAircraftTypeExperienceSuccess,
  [FETCH_ALL_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS]:
    fetchAllAircraftTypeExperienceSuccess,
  [FETCH_ENGINEER_AIRCRAFT_TYPE_EXP]: fetchAllAircraftTypeExperience,
  [REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP_FILE_SUCCESS]: removeFile,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
