import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { fetchCompetencies } from '../../actions/competencies';

import StaffCategoriesCompetenceManager from '../../../common/components/StaffCategoriesCompetenceManager';

const mapStateToProps = (state) => {
  return {
    competenciesList: state.competencies.competenciesList.filter((competence) => competence.isActive),
    competenciesRequest: state.competencies.competenciesRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCompetencies: (searchData) => dispatch(fetchCompetencies(searchData)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class CrossAssignCompetence extends Component {
  static propTypes = {
    competenciesList: PropTypes.array,
    handleAssignViewClose: PropTypes.func.isRequired,
    fetchCompetencies: PropTypes.func.isRequired,
    competenciesRequest: PropTypes.bool.isRequired,
    serializeData: PropTypes.func.isRequired,
    staffCategoryToEdit: PropTypes.object.isRequired,
    viewLabel: PropTypes.string.isRequired,
    editMode: PropTypes.bool
  };

  state = {
    searchSettings: {
      search: ''
    }
  };

  componentDidMount() {
    const { searchSettings } = this.state;

    this.props.fetchCompetencies(searchSettings);
  }

  handleSearch = (search) => {
    this.props.fetchCompetencies(search);
  };

  serializeAndSendData = (competencies, name, editMode, id) => {
    const { serializeData, handleAssignViewClose } = this.props;

    serializeData(competencies, name, editMode, id);
    handleAssignViewClose();
  };

  render() {
    const { handleAssignViewClose, competenciesRequest, viewLabel, competenciesList, fetchCompetencies, staffCategoryToEdit, editMode } = this.props;

    return (
      <StaffCategoriesCompetenceManager
        competenciesList={competenciesList}
        competenciesRequest={competenciesRequest}
        handleAssignViewClose={handleAssignViewClose}
        fetchCompetencies={fetchCompetencies}
        handleSearch={this.handleSearch}
        serializeAndSendData={this.serializeAndSendData}
        staffCategoryToEdit={staffCategoryToEdit}
        editMode={editMode}
        viewLabel={viewLabel}
      />
    );
  }
}
