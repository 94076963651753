import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import './styles.scss';

export default class CustomInput extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.any,
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
  };

  render() {
    return (
      <TextField
        {...this.props}
      />
    );
  }
}
