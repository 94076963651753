import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Menu from './Menu';

import { renderModule } from '../../../decorators/modules';
import { MODULES } from '../../../../constants/modules';
import { renderPermission, hasPermission } from '../../../decorators/permissions';
import { PERMISSIONS } from '../../../../constants/permissions';

@withNamespaces()
export default class SettingsMenu extends Menu {
  renderHeader() {
    const { t } = this.props;

    return (
      <>
        {t('navigation:settingsMenu')}
      </>
    );
  }


  @renderPermission(PERMISSIONS.userSettingsView)
  createUserSettingsLink(t) {
    return (
      <>
        <Link to="/settings/users" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:settingsUsersLink')}
        </Link>
      </>
    );
  }

  @renderPermission(PERMISSIONS.generalSettingsView)
  createGeneralSettingsLink(t) {
    return (
      <>
        <Link to="/settings/general" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:settingsGeneralLink')}
        </Link>
      </>
    );
  }

  @renderPermission(PERMISSIONS.companySettingsView)
  createCompanySettingsLink(t) {
    return (
      <>
        <Link to="/settings/company" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:settingsCompanyLink')}
        </Link>
      </>
    );
  }

  @renderPermission(PERMISSIONS.reportsSettingsView)
  @renderModule(MODULES.report)
  createReportsSettingsLink(t) {
    return (
      <>
        <Link to="/settings/reports" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:settingsReportsLink')}
        </Link>
      </>
    );
  }

  @renderPermission(PERMISSIONS.generalNotificationView)
  createNotificationsSettingsLink(t) {
    return (
      <>
        <Link to="/settings/notifications" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:settingsNotificationsLink')}
        </Link>
      </>
    );
  }

  renderDropdownMenu() {
    const { t } = this.props;
    const { open } = this.state;
    const isOpen = open ? 'open' : '';

    if (
      hasPermission(PERMISSIONS.generalSettingsView) ||
      hasPermission(PERMISSIONS.userSettingsView) ||
      hasPermission(PERMISSIONS.companySettingsView) ||
      hasPermission(PERMISSIONS.generalNotificationView) ||
      hasPermission(PERMISSIONS.ratingsSettingsView)
    ) {
      return (
        <div className={`dropdown ${isOpen}`}>
          {this.createGeneralSettingsLink(t)}
          {this.createCompanySettingsLink(t)}
          {this.createUserSettingsLink(t)}
          {this.createReportsSettingsLink(t)}
          {this.createNotificationsSettingsLink(t)}
        </div>
      );
    }

    return null;
  }
}
