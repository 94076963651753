import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _each from 'lodash/each';
import _get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import Form from '../../../../common/components/forms/Form';
import Loader from '../../../../common/components/Loader';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import validators from '../../../../utils/validators';
import NotificationRecipientList from './NotificationRecipientList';

import {
  editNotificationRecipient,
  addNotificationRecipient,
  removeNotificationRecipient,
  fetchNotificationRecipients
} from '../../../actions/notificationRecipients';
import { fetchNotificationCategories } from '../../../actions/notificationCategories';

const firstElementFromList = 1;
const styles = () => ({
  textField: {
    width: 150,
    marginRight: 30,
  },
  categoryLabelStyle: {
    fontSize: '0.75em'
  }
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  notificationRecipientFirstName: '',
  notificationRecipientLastName: '',
  notificationRecipientEmail: '',
  notificationRecipientNotificationCategoryId: null,
  notificationRecipientId: null
};

const mapStateToProps = (state) => {
  return {
    notificationRecipientList: state.notificationRecipients.notificationRecipientList,
    notificationCategoryList: state.notificationCategories.notificationCategoryList,
    notificationRecipientRequest: state.notificationRecipients.notificationRecipientRequest,
    notificationCategoryRequest: state.notificationCategories.notificationCategoryRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchNotificationRecipients: () => dispatch(fetchNotificationRecipients()),
    fetchNotificationCategories: () => dispatch(fetchNotificationCategories()),
    addNotificationRecipient: (name) => dispatch(addNotificationRecipient(name)),
    removeNotificationRecipient: (name) => dispatch(removeNotificationRecipient(name)),
    editNotificationRecipient: (name) => dispatch(editNotificationRecipient(name)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class NotificationRecipientsManager extends Component {
  static propTypes = {
    notificationRecipientList: PropTypes.array,
    notificationCategoryList: PropTypes.array,
    notificationRecipientRequest: PropTypes.bool.isRequired,
    notificationCategoryRequest: PropTypes.bool.isRequired,
    fetchNotificationRecipients: PropTypes.func.isRequired,
    fetchNotificationCategories: PropTypes.func.isRequired,
    addNotificationRecipient: PropTypes.func.isRequired,
    removeNotificationRecipient: PropTypes.func.isRequired,
    editNotificationRecipient: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = {
      ...INITIAL_STATE,
      notificationRecipientNotificationCategoryId: _get(this.state, 'notificationRecipientNotificationCategoryId', firstElementFromList)
    };
  }

  componentDidMount() {
    this.props.fetchNotificationCategories();
    this.props.fetchNotificationRecipients();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleEditOpen = (notificationRecipient) => {
    this.setState({
      editOpen: true,
      notificationRecipientId: notificationRecipient.id,
      notificationRecipientFirstName: notificationRecipient.firstName,
      notificationRecipientLastName: notificationRecipient.lastName,
      notificationRecipientEmail: notificationRecipient.email,
      notificationRecipientNotificationCategoryId: notificationRecipient.notificationCategory.id,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addNotificationRecipient = () => {
    const dataToSave = {
      firstName: this.state.notificationRecipientFirstName,
      lastName: this.state.notificationRecipientLastName,
      email: this.state.notificationRecipientEmail,
      notificationCategory: this.state.notificationRecipientNotificationCategoryId
    };

    this.props.addNotificationRecipient(dataToSave);
    this.handleDialogClose();
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid && this.formData) {
      this.state.addOpen ? this.addNotificationRecipient() : this.editNotificationRecipient();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  menuItems(categories) {
    return categories.map((category) => (
      <MenuItem key={`category-${category.id}`} value={category.id}>
        {category.name}
      </MenuItem>
    ));
  }

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  editNotificationRecipient = () => {
    const dataToUpdate = {
      id: this.state.notificationRecipientId,
      firstName: this.state.notificationRecipientFirstName,
      lastName: this.state.notificationRecipientLastName,
      email: this.state.notificationRecipientEmail,
      notificationCategory: this.state.notificationRecipientNotificationCategoryId,
    };


    this.props.editNotificationRecipient(dataToUpdate);
    this.handleDialogClose();
  };

  removeNotificationRecipient = (notificationRecipient) => {
    this.props.removeNotificationRecipient(notificationRecipient);
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  render() {
    const {
      notificationCategoryList, notificationRecipientList, notificationRecipientRequest,
      notificationCategoryRequest, onElementDelete, classes, t
    } = this.props;
    const {
      notificationRecipientFirstName, notificationRecipientLastName, notificationRecipientEmail,
      notificationRecipientNotificationCategoryId, addOpen, editOpen
    } = this.state;

    if (notificationRecipientRequest || notificationCategoryRequest) {
      return <Loader />;
    }

    return (
      <div className="notification-recipients-settings">
        <div className="pl-sm-5">
          <h4> {t('settings:notificationRecipients')}</h4>
        </div>
        <NotificationRecipientList
          notificationRecipientList={notificationRecipientList}
          removeNotificationRecipient={this.removeNotificationRecipient}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen || editOpen}
            onClose={this.handleDialogClose}
            aria-labelledby="add-notification-recipient-dialog"
            fullWidth
          >
            <DialogTitle id="add-notification-recipient-dialog">
              {addOpen ? t('settings:addNotificationRecipient') : t('settings:editNotificationRecipient')}
            </DialogTitle>
            <DialogContent>
              <MaterialInput
                className={classes.textField}
                margin="dense"
                name="notificationRecipientFirstName"
                label={t('table:firstName')}
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
                defaultValue={notificationRecipientFirstName}
                autoFocus
              />
              <MaterialInput
                className={classes.textField}
                margin="dense"
                name="notificationRecipientLastName"
                label={t('table:lastName')}
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
                defaultValue={notificationRecipientLastName}
              />
              <MaterialInput
                className={classes.textField}
                margin="dense"
                name="notificationRecipientEmail"
                label={t('table:email')}
                validators={[
                  new validators.IsRequired(t),
                  new validators.Email(t)
                ]}
                defaultValue={notificationRecipientEmail}
              />
              <div>
                <InputLabel className={classes.categoryLabelStyle} htmlFor="categories">{t('table:notificationCategory')}</InputLabel>
              </div>
              <Select
                className={classes.textField}
                value={notificationRecipientNotificationCategoryId}
                onChange={this.handleChange('notificationRecipientNotificationCategoryId')}
                validators={[
                  new validators.IsRequired(t),
                ]}
              >
                {this.menuItems(notificationCategoryList)}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
