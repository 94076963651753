import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getCompetenceActionIcons } from '../usersHelper';
import { reformatPickerDateFromDB } from '../../../utils/time';


const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 320,
  }
});

@withNamespaces()
@withStyles(styles)
export default class UserCompetenciesList extends Component {
  static propTypes = {
    removeUserCompetence: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    handleEditOpen: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    userCompetenciesList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderUserCompetenciesList() {
    const {
      userCompetenciesList, classes, editMode,
      handleEditOpen, addSimpleError, removeUserCompetence,
    } = this.props;
    const actions = {
      handleEditOpen,
      addSimpleError,
      removeItem: removeUserCompetence,
      documentName: 'UsersCompetence'
    };
    const documentName = 'competence';

    return userCompetenciesList.map((competence) => {
      return (
        <TableRow key={`${documentName}-${competence.id}`}>
          <TableCell className={classes.longCell}>{_get(competence, 'competence.name', '')}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(competence.expiryDate, false)}</TableCell>
          <TableCell numeric className={classes.iconsCell}>
            {getCompetenceActionIcons(editMode, competence, actions)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderAddButton(editMode, handleAddOpen, t) {
    if (editMode) {
      return (
        <Button className="my-2" color="primary" onClick={handleAddOpen}>{t('settings:addCompetenceButton')}</Button>
      );
    }

    return null;
  }

  render() {
    const { classes, handleAddOpen, editMode, userCompetenciesList, t } = this.props;

    if (_isEmpty(userCompetenciesList)) {
      return (
        <div>
          <h4>{t('settings:userCompetenciesSection')}</h4>
          {this.renderAddButton(editMode, handleAddOpen, t)}
          <Divider className="my-3" />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <h4>{t('settings:userCompetenciesSection')}</h4>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:name')}</TableCell>
              <TableCell>{t('table:expiryDate')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderUserCompetenciesList()}
          </TableBody>
        </Table>
        {this.renderAddButton(editMode, handleAddOpen, t)}
        <Divider className="my-3" />
      </div>
    );
  }
}
