import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

@withNamespaces()
export default class FolderNavBar extends Component {
  static propTypes = {
    paths: PropTypes.array,
    handlePathClick: PropTypes.func.isRequired,
    currentFolderId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    loading: PropTypes.bool,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    paths: [],
    loading: false,
    currentFolderId: ''
  };

  handlePath = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    const { loading } = this.props;

    if (!loading) {
      this.props.handlePathClick(e, path);
    }
  }

  render() {
    const { paths, currentFolderId, loading, t } = this.props;

    return (
      <div className="d-flex flex-row align-items-center flex-wrap folder-navbar">
        <div className="d-flex flex-row align-items-center">
          <Button
            className="no-outline"
            onClick={(e) => this.handlePath(e, null)}
          >
            {currentFolderId === '' && loading
              ? <div className="small-loader" />
              : t('folder:root')
            }
          </Button>
          {paths.length > 0 && (
            <Typography>/</Typography>
          )}
        </div>
        {paths.map((path, index) => (
          <div key={index} className="d-flex flex-row align-items-center">
            <Button
              color={currentFolderId === path.id ? 'primary' : 'default'}
              onClick={(e) => this.handlePath(e, path)}
            >
              {currentFolderId === path.id && loading
                ? <div className="small-loader" />
                : path.name
              }
            </Button>
            {index !== paths.length - 1 &&
              <Typography>/</Typography>
            }
          </div>
        ))}
      </div>
    );
  }
}
