import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import _find from 'lodash/find';

import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';

import Confirmation from '../../../common/components/Confirmation';
import Loader from '../../../common/components/Loader';
import CompanyInfoManager from './Company/CompanyInfoManager';
import SchoolLogoManager from './Company/SchoolLogoManager';
import DigitalSignatureManager from './Company/DigitalSignatureManager';

import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';
import { componentPermission } from '../../decorators/permissions';

import './styles.scss';

const INITIAL_STATE = {
  companyInfoSavedDialogOpen: false,
};

const mapStateToProps = (state) => {
  return {
    schoolLogo: _find(state.settings.settings, { name: 'school_logo' }),
    digitalSignature: _find(state.settings.settings, { name: 'digital_signature' }),
    settingsRequest: state.settings.settingsRequest,
  };
};

@withNamespaces()
@connect(mapStateToProps)
@componentPermission(PERMISSIONS.companySettingsView)
export default class Company extends Component {
  static propTypes = {
    schoolLogo: PropTypes.object,
    digitalSignature: PropTypes.object,
    fetchSettings: PropTypes.func,
    settingsRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  openCompanyInfoSavedDialog = () => {
    this.setState({
      companyInfoSavedDialogOpen: true,
    });
  };

  closeCompanyInfoSavedDialog = () => {
    this.setState({
      companyInfoSavedDialogOpen: false,
    });
  };

  render() {
    const { schoolLogo, digitalSignature, settingsRequest, t } = this.props;
    const { companyInfoSavedDialogOpen } = this.state;

    if (settingsRequest || !schoolLogo || !digitalSignature) {
      return <Loader />;
    }

    return (
      <div className="settings row">
        <div className="col-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">
                settings
              </Icon>
              <h1>{t('settings:name')}<span className="h1-subheader"> /{t('settings:company')}</span></h1>
            </span>
          </header>
        </div>
        <div className="col-12 col-xl-6">
          <SchoolLogoManager
            schoolLogoId={schoolLogo.value}
          />
          <DigitalSignatureManager
            digitalSignatureId={digitalSignature.value}
          />
          <CompanyInfoManager
            onInfoSuccessfullySaved={this.openCompanyInfoSavedDialog}
          />
        </div>
        <Dialog
          open={companyInfoSavedDialogOpen}
          onClose={this.closeCompanyInfoSavedDialog}
          aria-labelledby="confirmation-save"
          fullWidth
        >
          <Confirmation
            message={t('settings:companyInfoSaved')}
            closeConfirmationDialog={this.closeCompanyInfoSavedDialog}
          />
        </Dialog>
      </div>
    );
  }
}
