import { BOOKING_TYPE } from '../../../constants';

export const backgroundColors = {
  [BOOKING_TYPE.OWN]: '#E74378',
  [BOOKING_TYPE.PRIVATE]: '#0D5AA7',
  [BOOKING_TYPE.FLIGHT_WITH]: '#FF8249',
  FLIGHT_WITH_INACTIVE: '#FC4F00',
  [BOOKING_TYPE.MAINTENANCE]: '#9FDB26',
  [BOOKING_TYPE.OTHER]: '#C72EFF'
};
