import {
  ADD_NOTIFICATION_RECIPIENT,
  REMOVE_NOTIFICATION_RECIPIENT,
  EDIT_NOTIFICATION_RECIPIENT,
  FETCH_NOTIFICATION_RECIPIENTS,
  FETCH_NOTIFICATION_RECIPIENTS_REQUEST
} from '../actions/notificationRecipients';
const INITIAL_STATE = {
  notificationRecipientList: [],
  notificationRecipientRequest: false,
};

function fetchNotificationRecipients(state, payload) {
  return {
    ...state,
    notificationRecipientList: payload.notificationRecipients,
    notificationRecipientRequest: false,
  };
}

function fetchNotificationRecipientsRequest(state) {
  return {
    ...state,
    notificationRecipientRequest: true,
  };
}


function addQualification(state, payload) {
  return {
    ...state,
    notificationRecipientList: [
      ...state.notificationRecipientList,
      payload.notificationRecipient
    ]
  };
}

function removeQualification(state, payload) {
  return {
    ...state,
    notificationRecipientList: state.notificationRecipientList.filter((notificationRecipient) =>
      notificationRecipient !== payload.notificationRecipient)
  };
}

function editQualification(state, payload) {
  return {
    ...state,
    notificationRecipientList: state.notificationRecipientList.map((notificationRecipient) => {
      if (notificationRecipient.id === payload.notificationRecipient.id) {
        return payload.notificationRecipient;
      }

      return notificationRecipient;
    })
  };
}

const actions = {
  [FETCH_NOTIFICATION_RECIPIENTS]: fetchNotificationRecipients,
  [FETCH_NOTIFICATION_RECIPIENTS_REQUEST]: fetchNotificationRecipientsRequest,
  [ADD_NOTIFICATION_RECIPIENT]: addQualification,
  [REMOVE_NOTIFICATION_RECIPIENT]: removeQualification,
  [EDIT_NOTIFICATION_RECIPIENT]: editQualification,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
