import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

import ItemSortable from './ItemSortable';
import { DRAG_TYPES } from '../../../../constants';

const itemSource = {
  canDrag(props) {
    return !props.preview;
  },
  beginDrag(props) {
    return {
      ...props.item
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    canDrag: monitor.canDrag(),
  };
}


@DragSource(DRAG_TYPES.item, itemSource, collect)
export default class ItemDraggable extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    estimatedTime: PropTypes.string,
    removeItem: PropTypes.func,
    handleEditOpen: PropTypes.func,
    handleDuplicate: PropTypes.func,
    isDragging: PropTypes.bool.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    moveItemSortable: PropTypes.func,
    editMode: PropTypes.bool,
    preview: PropTypes.bool,
    isUsed: PropTypes.bool,
    lessonId: PropTypes.string,
    index: PropTypes.number,
  };

  render() {
    const {
      isDragging,
      connectDragSource,
      item,
      estimatedTime,
      preview,
      editMode,
      handleEditOpen,
      handleDuplicate,
      removeItem,
      isUsed,
      moveItemSortable,
      lessonId,
      index,

    } = this.props;

    return connectDragSource(
      <div>
        <ItemSortable
          index={index}
          item={item}
          estimatedTime={estimatedTime}
          preview={preview}
          editMode={editMode}
          handleEditOpen={handleEditOpen}
          handleDuplicate={handleDuplicate}
          removeItem={removeItem}
          isUsed={isUsed}
          isItemDragging={isDragging}
          lessonId={lessonId}
          moveItemSortable={moveItemSortable}
        />
      </div>
    );
  }
}
