import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { fetchUsers, removeUser, resetSaveDetailsFlag } from '../../actions/users';
import { fetchRoles } from '../../actions/roles';
import { fetchGroups } from '../../actions/groups';
import { isAdmin, isManager, isSuperAdmin } from '../../../utils';

import Loader from '../../../common/components/Loader';
import Table from '../../../common/components/Table';
import RemoveDialog from '../../../common/components/RemoveDialog';
import CustomFilter from '../../../common/components/CustomFilter';
import AddUser from './AddUser';
import Confirmation from '../../../common/components/Confirmation';
import { PERMISSIONS } from '../../../constants/permissions';
import { componentPermission, hasPermission, renderPermission } from '../../decorators/permissions';
import { URLS } from '../../../constants';

const mapStateToProps = (state) => {
  return {
    userList: state.users.userList,
    groups: (state.groups.groupList || []),
    roleList: (state.users.roleList || []),
    getUsersSuccess: state.users.getUsersSuccess,
    getUsersRequest: state.users.getUsersRequest,
    changeUserProfileSuccess: state.users.changeUserProfileSuccess,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: (sortData) => {
      dispatch(fetchUsers(sortData));
    },
    fetchGroups: () => dispatch(fetchGroups()),
    fetchRoles: () => dispatch(fetchRoles()),
    removeUser: (id) => dispatch(removeUser(id)),
    resetSaveDetailsFlag: () => dispatch(resetSaveDetailsFlag()),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.userListView)
export default class Users extends Component {
  static propTypes = {
    userList: PropTypes.array,
    fetchUsers: PropTypes.func.isRequired,
    groups: PropTypes.array,
    roleList: PropTypes.array,
    fetchGroups: PropTypes.func.isRequired,
    fetchRoles: PropTypes.func.isRequired,
    removeUser: PropTypes.func.isRequired,
    getUsersSuccess: PropTypes.bool.isRequired,
    getUsersRequest: PropTypes.bool.isRequired,
    changeUserProfileSuccess: PropTypes.bool.isRequired,
    resetSaveDetailsFlag: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userList: [],
    groups: [],
    roleList: []
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: '',
      filter: [],
      filterIsUsed: false
    },
    removeDialogOpen: false,
    removeUser: null,
    anchorEl: {},
  };

  componentDidMount() {
    const searchData = {
      ...this.state.searchSettings
    };

    this.props.fetchUsers(searchData);
    this.props.fetchGroups();
    this.props.fetchRoles();
  }

  handleSearch(search) {
    const { columnToSort, sortAsc, filter, filterIsUsed } = this.state.searchSettings;
    const searchData = {
      columnToSort,
      sortAsc,
      filter,
      filterIsUsed,
      search
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchUsers(searchData);
  }

  handleSort(columnName) {
    const { sortAsc, filter, search, filterIsUsed } = this.state.searchSettings;
    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      filter,
      search,
      filterIsUsed
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchUsers(searchData);
  }

  handleFilter(values) {
    const { sortAsc, search, columnToSort } = this.state.searchSettings;
    const filterData = {
      columnToSort,
      sortAsc,
      filter: values,
      search,
      filterIsUsed: !_isEmpty(values)
    };

    this.setState({
      searchSettings: filterData,
    });

    this.props.fetchUsers(filterData);
  }

  getUserFilters() {
    const { searchSettings } = this.state;
    const { groups, roleList, t } = this.props;

    const newRoleList = roleList.map((role) => {
      return {
        ...role,
        displayName: t(role.name),
      };
    });

    return (
      <CustomFilter
        groups={groups}
        roles={newRoleList}
        handleFilter={this.handleFilter.bind(this)}
        filterSettings={searchSettings.filter}
        isFilterUsed={searchSettings.filterIsUsed}
      />
    );
  }

  handleMenuClick = (event, userId) => {
    this.setState({ anchorEl: {
      [userId]: event.currentTarget
    } });
  };

  handleMenuClose = (userId) => {
    this.setState({ anchorEl: {
      [userId]: null
    } });
  };

  handlePrint = (url, userId) => {
    this.handleMenuClose(userId);

    const userHistoryLink = `${URLS.base}${url}/${userId}/`;

    window.open(userHistoryLink, '_blank');
  };

  renderPrintIcon = (userId, t) => {
    const { anchorEl } = this.state;

    return (
      <span key="user-print-icon">
        <IconButton
          aria-owns={anchorEl[userId] ? 'print-menu' : null}
          aria-haspopup="true"
          onClick={(event) => this.handleMenuClick(event, userId)}
        >
          <Icon color="primary">print</Icon>
        </IconButton>
        <Menu
          id="print-menu"
          anchorEl={anchorEl[userId]}
          open={Boolean(anchorEl[userId])}
          onClose={() => this.handleMenuClose(userId)}
        >
          <MenuItem onClick={() => this.handlePrint('user_history_basic', userId)}>{t('user:basicHistory')}</MenuItem>
          <MenuItem onClick={() => this.handlePrint('user_history', userId)}>{t('user:extendedHistory')}</MenuItem>
        </Menu>
      </span>
    );
  };

  @renderPermission(PERMISSIONS.userEdit)
  renderEditIcon(icons, userId) {
    icons.push(
      <Link className="table-row-link"
        key="user-edit-icon"
        to={{
          pathname: `/users/${userId}`,
          state: { editMode: true }
        }}
      >
        <IconButton><Icon color="primary">mode_edit</Icon></IconButton>
      </Link>
    );
  }

  @renderPermission(PERMISSIONS.userDelete)
  renderDeleteIcon(icons, user) {
    if (!isAdmin(user) && !isSuperAdmin(user)) {
      if (!isManager(user) || hasPermission(PERMISSIONS.userSettingsView)) {
        icons.push(
          <IconButton
            key="user-remove-icon"
            onClick={() => this.openRemoveDialog(user)}
          >
            <Icon color="primary">delete</Icon>
          </IconButton>
        );
      }
    }
  }

  renderIcons(user, t) {
    const userId = user.id;
    const icons = [];

    this.renderEditIcon(icons, userId);
    this.renderDeleteIcon(icons, user);

    icons.push(this.renderPrintIcon(userId, t));

    return icons;
  }

  getRole = (groups, t) => {
    const roles = _reduce(groups, (memo, group) => {
      const groupName = t(group.name);

      return `${memo}${groupName}, `;
    }, '');

    return roles.substring(0, roles.length - 2);
  };

  renderRoleIcon(user, t) {
    const { groups } = user;

    if (_isObject(groups) && !_isEmpty(groups)) {
      return (
        <Chip
          label={this.getRole(groups, t)}
        />
      );
    }

    return user.groups;
  }

  getParsedGroups(user) {
    return _isObject(user.groups) ? _reduce(user.groups, (memo, group) => {
      return `${memo}${group.name} `;
    }, '') : user.groups;
  }

  getParsedSchoolGroupSet(user) {
    const groups = _isObject(user.schoolgroupSet) ? _reduce(user.schoolgroupSet, (memo, schoolGroup) => {
      return `${memo}${schoolGroup.name}, `;
    }, '') : user.schoolgroupSet;

    return groups.substring(0, groups.length - 2);
  }

  manageUserList(userList, t) {
    const parsedUser = userList.map((user) => ({
      ...user,
      parsedGroups: this.getParsedGroups(user),
      phone: _get(user, 'userprofile.phone', ''),
      parsedSchoolGroupSet: this.getParsedSchoolGroupSet(user),
      icons: this.renderIcons(user, t),
      roleIcon: this.renderRoleIcon(user, t)
    }));

    return parsedUser;
  }

  removeUser = () => {
    this.props.removeUser(this.state.removeUser.id);

    this.closeRemoveDialog();
  };

  openRemoveDialog = (user) => {
    this.setState({
      removeDialogOpen: true,
      removeUser: user,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeUser: null,
    });
  };

  render() {
    const {
      changeUserProfileSuccess,
      resetSaveDetailsFlag,
      t,
    } = this.props;
    const tableHeader = [
      {
        prop: 'id',
        sort: true,
        profileView: true
      },
      {
        prop: 'firstName',
        sort: true,
        profileView: true
      },
      {
        prop: 'lastName',
        sort: true,
        profileView: true
      },
      {
        prop: 'email',
        sort: true,
        profileView: true
      },
      {
        prop: 'phone',
        sort: false,
        profileView: true
      },
      {
        prop: 'parsedSchoolGroupSet',
        sort: false,
        profileView: true
      },
      {
        prop: 'roleIcon',
        sort: false,
        profileView: true
      },
      {
        prop: 'icons',
        sort: false,
        profileView: false,
        isNumeric: true,
        iconWidth: hasPermission(PERMISSIONS.userEdit)
      }
    ];

    if (this.props.getUsersRequest) {
      return <Loader />;
    } else if (!this.props.getUsersSuccess) {
      return (
        <div>
          <div className="col-12 section-title">
            <header>
              <span>
                <Icon color="primary">
                  person
                </Icon>
                <h1>{t('user:name')}<span className="h1-subheader"> /{t('user:userListSection')}</span></h1>
              </span>
            </header>
          </div>
          <div className="col-12">
            <h2>{t('user:errorUsersFetching')}</h2>
          </div>
        </div>
      );
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">
                person
              </Icon>
              <h1>{t('user:name')}<span className="h1-subheader"> /{t('user:userListSection')}</span></h1>
            </span>
            <p>{t('user:description')}</p>
          </header>
        </div>
        <AddUser />
        <Table
          data={this.manageUserList(this.props.userList, t)}
          header={tableHeader}
          handleSort={this.handleSort.bind(this)}
          sortAsc={this.state.searchSettings.sortAsc}
          columnToSort={this.state.searchSettings.columnToSort}
          handleSearch={this.handleSearch.bind(this)}
          search={this.state.searchSettings.search}
          getUserFilters={this.getUserFilters.bind(this)}
        />
        <Dialog
          open={this.state.removeDialogOpen}
          onClose={this.closeRemoveDialog}
          aria-labelledby="remove-dialog"
          fullWidth
        >
          <RemoveDialog
            message={t('user:removeUser')}
            closeRemoveDialog={this.closeRemoveDialog}
            dialogTitle={t('user:removeUserDialogTitle')}
            removeFunction={this.removeUser}
          />
        </Dialog>
        <Dialog
          open={changeUserProfileSuccess}
          onClose={resetSaveDetailsFlag}
          aria-labelledby="confirmation-assign"
          fullWidth
        >
          <Confirmation
            message={t('user:userProfileChangesSaved')}
            closeConfirmationDialog={resetSaveDetailsFlag}
          />
        </Dialog>
      </div>
    );
  }
}
