import { URLS } from '../../constants';
import _isEmpty from 'lodash/isEmpty';
import { addError } from './errors';
import axios from 'axios';
import { fetchDocumentsSuccess } from './documents';
import { fetchInspectionsSuccess } from './inspections';
import { fetchPartFleetSuccess } from './parts';
import { fetchServicesSuccess } from './services';
import { getDashboardFleetParametersFilter } from '../utils/time';
import history from '../store/history';
import { urlJoin } from '../../utils';

export const FETCH_FLEETS_REQUEST = 'FETCH_FLEETS_REQUEST';
export const FETCH_FLEETS_PAGINATION_REQUEST =
  'FETCH_FLEETS_PAGINATION_REQUEST';
export const FETCH_FLEETS_SUCCESS = 'FETCH_FLEETS_SUCCESS';
export const FETCH_FLEETS_PAGINATION_SUCCESS =
  'FETCH_FLEETS_PAGINATION_SUCCESS';
export const FETCH_FLEET_REQUEST = 'FETCH_FLEET_REQUEST';
export const FETCH_FLEET_SUCCESS = 'FETCH_FLEET_SUCCESS';
export const ADD_FLEET = 'ADD_FLEET';
export const EDIT_FLEET = 'EDIT_FLEET';
export const REMOVE_FLEET = 'REMOVE_FLEET';
export const CLEAN_FLEET_DATA = 'CLEAN_FLEET_DATA';

const sortingPossibitilies = {
  name: 'vehicle__name',
  type: 'vehicle__type__name',
  evn: 'vehicle__evn',
  maintainer: 'maintainer',
  currentHours: 'current_hours',
  currentCycle: 'current_cycle',
  lastService: 'last_service',
};

export function fetchFleetsRequest() {
  return {
    type: FETCH_FLEETS_REQUEST,
  };
}

export function fetchFleetsSuccess(fleet) {
  return {
    type: FETCH_FLEETS_SUCCESS,
    payload: {
      fleet,
    },
  };
}

export function fetchFleetsPaginationRequest() {
  return {
    type: FETCH_FLEETS_PAGINATION_REQUEST,
  };
}

export function fetchFleetsPaginationSuccess(fleet) {
  return {
    type: FETCH_FLEETS_PAGINATION_SUCCESS,
    payload: {
      fleet,
    },
  };
}

export function fetchFleetRequest() {
  return {
    type: FETCH_FLEET_REQUEST,
  };
}

export function fetchFleetSuccess(fleet) {
  return {
    type: FETCH_FLEET_SUCCESS,
    payload: {
      fleet,
    },
  };
}

export function addFleetSuccess(fleet) {
  return {
    type: ADD_FLEET,
    payload: {
      fleet,
    },
  };
}

export function editFleetSuccess(fleet) {
  return {
    type: EDIT_FLEET,
    payload: {
      fleet,
    },
  };
}

export function removeFleetSuccess(fleetId) {
  return {
    type: REMOVE_FLEET,
    payload: {
      fleetId,
    },
  };
}

export function cleanFleet() {
  return {
    type: CLEAN_FLEET_DATA,
  };
}

export function fetchFleets(sortingData, paginationParams) {
  return (dispatch) => {
    const params = [];

    if (paginationParams) {
      dispatch(fetchFleetsPaginationRequest());
      params.push(paginationParams);
    } else {
      dispatch(fetchFleetsRequest());

      if (sortingData) {
        if (sortingData.search && !_isEmpty(sortingData.search)) {
          params.push(`search=${sortingData.search}`);
        }

        if (sortingData.columnToSort && !_isEmpty(sortingData.columnToSort)) {
          params.push(
            `ordering=${sortingData.sortAsc ? '' : '-'}${
              sortingPossibitilies[sortingData.columnToSort]
            }`,
          );
        }
      }
    }

    const queryParameters = params.length ? `?${params.join('&')}` : '';

    return axios
      .get(urlJoin(URLS.fleet, queryParameters))
      .then(({ data }) =>
        dispatch(
          paginationParams
            ? fetchFleetsPaginationSuccess(data)
            : fetchFleetsSuccess(data),
        ),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during fleet fetching ${error.message}`, error),
        );
      });
  };
}

export function fetchDashboardFleets() {
  return (dispatch) => {
    const queryParameters = `?${getDashboardFleetParametersFilter()}`;

    return axios
      .get(urlJoin(URLS.fleet, queryParameters))
      .then(({ data }) => dispatch(fetchFleetsSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during fleet fetching ${error.message}`, error),
        );
      });
  };
}

export function fetchFleet(fleetId) {
  return (dispatch) => {
    dispatch(fetchFleetRequest());

    return axios
      .get(urlJoin(URLS.fleet, fleetId))
      .then(({ data }) => {
        dispatch(fetchFleetSuccess(data));
        dispatch(fetchPartFleetSuccess(data.installations));
        dispatch(fetchDocumentsSuccess(data.documents));
        dispatch(fetchInspectionsSuccess(data.inspections));
        dispatch(fetchServicesSuccess(data.services));
      })
      .catch((error) => {
        dispatch(addError(`Error during fleet fetching ${error.message}`));
      });
  };
}

export function removeFleet(fleetId) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.fleet, fleetId))
      .then(() => dispatch(removeFleetSuccess(fleetId)))
      .catch((error) => {
        dispatch(
          addError(`Error during fleet removing ${error.message}`, error),
        );
      });
  };
}

export function editFleet(fleet) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.fleet, fleet.id), fleet)
      .then(({ data }) => {
        dispatch(editFleetSuccess(data));
        history.push('/fleet');
      })
      .catch((error) => {
        dispatch(
          addError(`Error during fleet editing ${error.message}`, error),
        );
      });
  };
}

export function addFleet(fleet) {
  return (dispatch) => {
    return axios
      .post(URLS.fleet, fleet)
      .then(({ data }) => {
        dispatch(addFleetSuccess(data));
        history.push(`/fleet/edit/${data.id}`);
      })
      .catch((error) => {
        dispatch(addError(`Error during fleet adding ${error.message}`, error));
      });
  };
}

export function cleanFleetData() {
  return (dispatch) => dispatch(cleanFleet());
}
