import {
  FETCH_USER_COMPETENCE_REQUEST,
  FETCH_USER_COMPETENCIES_SUCCESS,
  ADD_USER_COMPETENCE,
  REMOVE_USER_COMPETENCE,
  EDIT_USER_COMPETENCE,
} from '../actions/userCompetencies';

const INITIAL_STATE = {
  userCompetenceList: [],
  userCompetenciesRequest: false,
};

function fetchCompetenciesRequest(state) {
  return {
    ...state,
    userCompetenciesRequest: true,
  };
}

function fetchUserCompetenciesSuccess(state, payload) {
  return {
    ...state,
    userCompetenceList: payload.userCompetencies,
    userCompetenciesRequest: false,
  };
}

function addUserCompetence(state, payload) {
  return {
    ...state,
    userCompetenceList: [
      ...state.userCompetenceList,
      payload.userCompetence
    ]
  };
}

function removeUserCompetence(state, payload) {
  return {
    ...state,
    userCompetenceList: state.userCompetenceList.filter((userCompetence) => userCompetence !== payload.userCompetence)
  };
}

function editUserCompetence(state, payload) {
  return {
    ...state,
    userCompetenceList: state.userCompetenceList.map((userCompetence) => {
      if (userCompetence.id === payload.userCompetence.id) {
        return payload.userCompetence;
      }

      return userCompetence;
    }),
  };
}

const actions = {
  [FETCH_USER_COMPETENCE_REQUEST]: fetchCompetenciesRequest,
  [FETCH_USER_COMPETENCIES_SUCCESS]: fetchUserCompetenciesSuccess,
  [ADD_USER_COMPETENCE]: addUserCompetence,
  [REMOVE_USER_COMPETENCE]: removeUserCompetence,
  [EDIT_USER_COMPETENCE]: editUserCompetence,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
