import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import PropTypes from 'prop-types';

import { MuiThemeProvider } from '@material-ui/core/styles';
import getTheme from './theme';
import GeneralRouting from './GeneralRouting';
import GeneralError from './GeneralError';

export default class Root extends Component {
  static propTypes = {
    store: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired
  };

  render() {
    return (
      <Provider store={this.props.store}>
        <ConnectedRouter history={this.props.history}>
          <MuiThemeProvider theme={getTheme()}>
            <div>
              <GeneralRouting />
              <GeneralError />
            </div>
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}
