import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import WarehouseNew from '../WarehouseNew';
import Loader from '../../../../common/components/Loader';
import { fetchWarehouse } from '../../../actions/warehouse';

const mapStateToProps = (state) => {
  return {
    warehouse: state.warehouse.warehouse,
    warehouseRequest: state.warehouse.warehouseRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchWarehouse: (id) => dispatch(fetchWarehouse(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossWarehouseDetailsView extends Component {
  static propTypes = {
    warehouse: PropTypes.object.isRequired,
    fetchWarehouse: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    warehouseRequest: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { fetchWarehouse, match } = this.props;

    fetchWarehouse(match.params.id);
  }

  render() {
    const { warehouse, warehouseRequest, match } = this.props;

    if (warehouseRequest && _isEmpty(warehouse)) {
      return <Loader />;
    }

    return (
      <WarehouseNew
        warehouse={warehouse}
        editMode={false}
        previewMode
        linkTo={'/warehouse'}
        match={match}
      />
    );
  }
}
