export const TABLE_HEADER = [
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'partNumber',
    sort: true,
    profileView: false
  },
  {
    prop: 'serialNumber',
    sort: true,
    profileView: false
  },
  {
    prop: 'status',
    sort: true,
    profileView: false
  },
  {
    prop: 'location',
    sort: false,
    profileView: false,
  },
  // {
  //   prop: 'isInstalled',
  //   sort: false,
  //   profileView: false,
  // },
  {
    prop: 'installationDate',
    sort: true,
    profileView: false
  },
  {
    prop: 'removalDate',
    sort: false,
    profileView: false,
  },
  {
    prop: 'attachments',
    sort: false,
    profileView: false,
    isNumeric: true,
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true,
    iconWidth: true
  }
];
