import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import NotificationCategoryList from './NotificationCategoryList';
import Loader from '../../../../common/components/Loader';

import { fetchNotificationCategories } from '../../../actions/notificationCategories';

const mapStateToProps = (state) => {
  return {
    notificationCategoryList: state.notificationCategories.notificationCategoryList,
    notificationCategoryRequest: state.notificationCategories.notificationCategoryRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchNotificationCategories: () => dispatch(fetchNotificationCategories())
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class NotificationCategoriesManager extends Component {
  static propTypes = {
    notificationCategoryList: PropTypes.array,
    notificationCategoryRequest: PropTypes.bool.isRequired,
    fetchNotificationCategories: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchNotificationCategories();
  }

  render() {
    const { notificationCategoryList, notificationCategoryRequest, t } = this.props;

    if (notificationCategoryRequest) {
      return <Loader />;
    }

    return (
      <div className="notification-categories-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:notificationCategoriesSection')}</h4>
        </div>
        <NotificationCategoryList
          notificationCategoryList={notificationCategoryList}
        />
      </div>
    );
  }
}
