import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

const styles = (theme) => ({
  chip: {
    margin: theme.spacing.unit / 2,
  },
});

@withNamespaces()
@withStyles(styles)
export default class ItemTypeList extends Component {
  static propTypes = {
    itemTypeList: PropTypes.array,
    removeItemType: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderItemTypeList = (itemTypeList) => {
    const { removeItemType, classes, onElementDelete } = this.props;

    return itemTypeList.map((itemType) => {
      return (
        <Chip
          key={`itemType-${itemType.id}`}
          label={itemType.name}
          onDelete={() => onElementDelete(() => removeItemType(itemType))}
          className={classes.chip}
        />
      );
    });
  }

  render() {
    const { handleAddOpen, itemTypeList, t } = this.props;

    if (_isEmpty(itemTypeList)) {
      return (
        <div className="pl-sm-5">
          <Button color="primary" onClick={handleAddOpen}>
            {t('settings:addContentTypeButton')}
          </Button>
        </div>
      );
    }

    return (
      <div className="pl-sm-5">
        <div elevation={4} className="ml-sm-3 my-2">
          {this.renderItemTypeList(itemTypeList)}
        </div>
        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addContentTypeButton')}
        </Button>
      </div>
    );
  }
}
