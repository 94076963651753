import {
  FETCH_LESSON_TYPES_SUCCESS,
  FETCH_LESSON_TYPES_REQUEST,
  FETCH_LESSON_TYPES_FAILURE,
} from '../actions/lessonTypes';

const INITIAL_STATE = {
  lessonTypeList: [],
  lessonTypeRequest: false,
};

function fetchLessonTypes(state, payload) {
  return {
    ...state,
    lessonTypeList: payload.lessonTypes,
    lessonTypeRequest: false,
  };
}

function fetchLessonTypesRequest(state) {
  return {
    ...state,
    lessonTypeRequest: true,
  };
}

function fetchLessonTypesFailure(state) {
  return {
    ...state,
    lessonTypeRequest: false,
  };
}


const actions = {
  [FETCH_LESSON_TYPES_SUCCESS]: fetchLessonTypes,
  [FETCH_LESSON_TYPES_REQUEST]: fetchLessonTypesRequest,
  [FETCH_LESSON_TYPES_FAILURE]: fetchLessonTypesFailure,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
