import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddFlightLog from './addFlightLog';
import Loader from '../../../../common/components/Loader';

import { fetchUserSettings } from '../../../actions/userSettings';
import { addSimpleError } from '../../../actions/errors';

class FlightLogDataPreparation extends Component {
  static propTypes = {
    fetchUserSettings: PropTypes.func,
    userSettingsRequest: PropTypes.bool,
    userSettings: PropTypes.object,
  };

  componentDidMount() {
    const { fetchUserSettings } = this.props;
    const user = localStorage.getItem('user');
    const userId = JSON.parse(user).id.toString();

    fetchUserSettings(userId);
  }

  render() {
    const { userSettings, userSettingsRequest } = this.props;

    if (userSettingsRequest) {
      return <Loader />;
    }

    return <AddFlightLog userSettings={userSettings} />;
  }
}

const mapStateToProps = (state) => {
  return {
    userSettings: state.userSettings.userSettings,
    userSettingsRequest: state.userSettings.userSettingsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    fetchUserSettings: (userId) => dispatch(fetchUserSettings(userId)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FlightLogDataPreparation);
