import {
  ASSIGN_COURSE_SUCCESS,
  EDIT_ASSIGNED_COURSE,
  FETCH_ASSIGNED_COURSES_FAILURE,
  FETCH_ASSIGNED_COURSES_PAGINATION_REQUEST,
  FETCH_ASSIGNED_COURSES_PAGINATION_SUCCESS,
  FETCH_ASSIGNED_COURSES_REQUEST,
  FETCH_ASSIGNED_COURSES_SUCCESS,
  FETCH_ASSIGNED_COURSE_FAILURE,
  FETCH_ASSIGNED_COURSE_REQUEST,
  FETCH_ASSIGNED_COURSE_STUDENTS_FAILURE,
  FETCH_ASSIGNED_COURSE_STUDENTS_REQUEST,
  FETCH_ASSIGNED_COURSE_STUDENTS_SUCCESS,
  FETCH_ASSIGNED_COURSE_SUCCESS,
  REMOVE_ASSIGNED_COURSE,
  RESET_ASSIGN_COURSE_SUCCESS,
} from '../actions/assignedCourses';

const INITIAL_STATE = {
  assignedCourse: {},
  assignedCourseList: [],
  assignedCourseStudentsList: [],
  assignedCourseRequest: false,
  assignedCoursesRequest: false,
  assignedCoursesPaginationNext: null,
  assignedCoursesPaginationRequest: false,
  assignedCourseStudentsRequest: false,
  assignCourseSuccess: false,
};

function fetchAssignedCourseSuccess(state, payload) {
  return {
    ...state,
    assignedCourse: payload.assignedCourse,
    assignedCourseRequest: false,
  };
}

function fetchAssignedCourseRequest(state) {
  return {
    ...state,
    assignedCourseRequest: true,
  };
}

function fetchAssignedCourseFailure(state) {
  return {
    ...state,
    assignedCourseRequest: false,
  };
}

function fetchAssignedCoursesPaginationSuccess(state, payload) {
  return {
    ...state,
    assignedCourseList: [
      ...state.assignedCourseList,
      ...payload.assignedCourses.results,
    ],
    assignedCoursesPaginationNext: payload.assignedCourses.next,
    assignedCoursesPaginationRequest: false,
  };
}

function fetchAssignedCoursesPaginationRequest(state) {
  return {
    ...state,
    assignedCoursesPaginationRequest: true,
  };
}

function fetchAssignedCoursesSuccess(state, payload) {
  return {
    ...state,
    assignedCourseList: payload.assignedCourses.results,
    assignedCoursesPaginationNext: payload.assignedCourses.next,
    assignedCoursesRequest: false,
  };
}

function fetchAssignedCoursesRequest(state) {
  return {
    ...state,
    assignedCoursesRequest: true,
  };
}

function fetchAssignedCoursesFailure(state) {
  return {
    ...state,
    assignedCoursesRequest: false,
  };
}

function editAssignedCourse(state, payload) {
  return {
    ...state,
    assignedCourseList: state.assignedCourseList.map((assignedCourse) => {
      if (assignedCourse.id === payload.assignedCourse.id) {
        return payload.assignedCourse;
      }

      return assignedCourse;
    }),
  };
}

function removeAssignedCourse(state, payload) {
  return {
    ...state,
    assignedCourseList: state.assignedCourseList.filter(
      (assignedCourse) => assignedCourse !== payload.assignedCourse,
    ),
  };
}

function assignCoursesSuccess(state) {
  return {
    ...state,
    assignCourseSuccess: true,
  };
}

function resetAssignCoursesSuccess(state) {
  return {
    ...state,
    assignCourseSuccess: false,
  };
}

function fetchAssignedCourseStudentsSuccess(state, payload) {
  return {
    ...state,
    assignedCourseStudentsList: payload.assignedCourseStudents,
    assignedCourseStudentsRequest: false,
  };
}

function fetchAssignedCourseStudentsRequest(state) {
  return {
    ...state,
    assignedCourseStudentsRequest: true,
  };
}

function fetchAssignedCourseStudentsFailure(state) {
  return {
    ...state,
    assignedCourseStudentsRequest: false,
  };
}

const actions = {
  [FETCH_ASSIGNED_COURSE_REQUEST]: fetchAssignedCourseRequest,
  [FETCH_ASSIGNED_COURSE_SUCCESS]: fetchAssignedCourseSuccess,
  [FETCH_ASSIGNED_COURSE_FAILURE]: fetchAssignedCourseFailure,
  [FETCH_ASSIGNED_COURSES_REQUEST]: fetchAssignedCoursesRequest,
  [FETCH_ASSIGNED_COURSES_SUCCESS]: fetchAssignedCoursesSuccess,
  [FETCH_ASSIGNED_COURSES_PAGINATION_REQUEST]:
    fetchAssignedCoursesPaginationRequest,
  [FETCH_ASSIGNED_COURSES_PAGINATION_SUCCESS]:
    fetchAssignedCoursesPaginationSuccess,
  [FETCH_ASSIGNED_COURSES_FAILURE]: fetchAssignedCoursesFailure,
  [FETCH_ASSIGNED_COURSE_STUDENTS_REQUEST]: fetchAssignedCourseStudentsRequest,
  [FETCH_ASSIGNED_COURSE_STUDENTS_SUCCESS]: fetchAssignedCourseStudentsSuccess,
  [FETCH_ASSIGNED_COURSE_STUDENTS_FAILURE]: fetchAssignedCourseStudentsFailure,
  [EDIT_ASSIGNED_COURSE]: editAssignedCourse,
  [REMOVE_ASSIGNED_COURSE]: removeAssignedCourse,
  [ASSIGN_COURSE_SUCCESS]: assignCoursesSuccess,
  [RESET_ASSIGN_COURSE_SUCCESS]: resetAssignCoursesSuccess,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
