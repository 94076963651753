import React, { Component } from 'react';

import LessonEdit from '../LessonEdit';


export default class AssignedCourseNew extends Component {
  render() {
    return (
      <LessonEdit {...this.props} newLesson />
    );
  }
}

