import React, { Component } from 'react';
import {
  getDateFormat,
  reformatPickerDateFromDB,
} from '../../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../../common/components/forms/DateTimePicker';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Form from '../../../../../common/components/forms/Form';
import MaterialInput from '../../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import validators from '../../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class Page1 extends Component {
  static propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    isFirstPage: PropTypes.func.isRequired,
    isLastPage: PropTypes.func.isRequired,
    saveComplementaryCourse: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    issuingDate: reformatPickerDateFromDB(
      this.props.defaultSettings.issuingDate,
    ),
    expiryDate: reformatPickerDateFromDB(this.props.defaultSettings.expiryDate),
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  onFormValidated = (isFormValid) => {
    const { changePage } = this.props;
    const formData = Object.keys(this.formData).reduce((result, keyName) => {
      result[keyName] = this.formData[keyName].value;

      return result;
    }, {});
    const state = this.state || {};

    if (isFormValid && this.formData) {
      changePage(this.page, { ...formData, ...state });
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e, page) => {
    e.preventDefault();
    e.stopPropagation();

    this.page = page;
    this.triggerFormValidation();
  };

  render() {
    const {
      t,
      defaultSettings,
      handleDialogClose,
      isFirstPage,
      isLastPage,
      saveComplementaryCourse,
    } = this.props;
    const { issuingDate, expiryDate } = this.state;

    return (
      <Form
        onChange={this.onChange}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <h4 className="text-center">
                {t('complementaryCertificate:name')}
              </h4>
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="licence"
                label={t('input:licenceNumber')}
                validators={[
                  new validators.MaxLength(t, 12),
                  new validators.IsRequired(t),
                ]}
                defaultValue={defaultSettings.licence}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="firstName"
                label={t('input:firstName')}
                validators={[
                  new validators.MaxLength(t, 58),
                  new validators.IsRequired(t),
                ]}
                defaultValue={defaultSettings.firstName}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="lastName"
                label={t('input:lastName')}
                validators={[
                  new validators.MaxLength(t, 58),
                  new validators.IsRequired(t),
                ]}
                defaultValue={defaultSettings.lastName}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="referenceNumber"
                label={t('input:referenceNumber')}
                validators={[
                  new validators.MaxLength(t, 12),
                  new validators.IsRequired(t),
                ]}
                defaultValue={defaultSettings.referenceNumber}
                fullWidth
              />
            </div>
            <div className="col-12">
              <DateTimePicker
                value={issuingDate}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'issuingDate',
                )}
                dateFormat={getDateFormat()}
                name="issuingDate"
                label={t('input:issuingDate')}
                acceptEmptyInput
                fullWidth
              />
            </div>
            <div className="col-12">
              <DateTimePicker
                value={expiryDate}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'expiryDate',
                )}
                dateFormat={getDateFormat()}
                name="expiryDate"
                label={t('input:expiryDate')}
                acceptEmptyInput
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="organisation"
                label={t('input:organisation')}
                validators={[new validators.MaxLength(t, 58)]}
                defaultValue={defaultSettings.organisation}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="organisationPa"
                label={t('input:organisationPa')}
                validators={[new validators.MaxLength(t, 58)]}
                defaultValue={defaultSettings.organisationPa}
                fullWidth
              />
            </div>
            <div className="col-12">
              <MaterialInput
                margin="dense"
                name="organisationIrn"
                label={t('input:organisationIrn')}
                validators={[new validators.MaxLength(t, 30)]}
                defaultValue={defaultSettings.organisationIrn}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {!isFirstPage() ? (
            <Button onClick={(e) => this.sendHandler(e, -1)}>
              {t('buttonPrevious')}
            </Button>
          ) : (
            <div />
          )}
          {!isLastPage() ? (
            <Button onClick={(e) => this.sendHandler(e, 1)}>
              {t('buttonNext')}
            </Button>
          ) : (
            <div />
          )}
          <Button color="secondary" onClick={handleDialogClose}>
            {t('buttonCancel')}
          </Button>
          {isLastPage() ? (
            <Button color="primary" onClick={saveComplementaryCourse}>
              {t('buttonSave')}
            </Button>
          ) : (
            <div />
          )}
        </DialogActions>
      </Form>
    );
  }
}
