import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';

import { getVerifiedBy, isVerified } from '../PlannedAction/plannedActionHelper';
import { getActionIcons, renderFiles } from '../usersHelper';
import { reformatPickerDateFromDB } from '../../../utils/time';


const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 320,
  }
});

@withNamespaces()
@withStyles(styles)
export default class MedicalList extends Component {
  static propTypes = {
    medicalList: PropTypes.array,
    addMedicalPlannedAction: PropTypes.func.isRequired,
    removeMedical: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    handleEditOpen: PropTypes.func.isRequired,
    attachMedicalFile: PropTypes.func.isRequired,
    removeMedicalFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    deactivateMedical: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderMedicalList() {
    const {
      medicalList, editMode, classes,
      handleEditOpen, attachMedicalFile, addSimpleError,
      addMedicalPlannedAction, deactivateMedical,
      removeMedical, removeMedicalFile,
    } = this.props;
    const actions = {
      handleEditOpen,
      addSimpleError,
      deactivateItem: deactivateMedical,
      removeItem: removeMedical,
      attachItemFile: attachMedicalFile,
      addItemPlannedAction: addMedicalPlannedAction,
      documentName: 'Medical',
    };
    const documentName = 'medical';

    return medicalList.map((medical) => {
      return (
        <TableRow key={`${documentName}-${medical.id}`}>
          <TableCell className={classes.longCell}>{medical.type}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(medical.expiryDate, false)}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(medical.dateOfIssue, false)}</TableCell>
          <TableCell className={classes.longCell}>{isVerified(medical.isVerified)}</TableCell>
          <TableCell className={classes.longCell}>{getVerifiedBy(medical.verifiedBy)}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(medical.verificationDate, false)}</TableCell>
          <TableCell className={classes.longCell}>{medical.plannedAction}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(medical.plannedActionDate, false)}</TableCell>
          <TableCell>{renderFiles(editMode, medical, removeMedicalFile, documentName)}</TableCell>
          <TableCell className={classes.longCell}>{isVerified(medical.isActive)}</TableCell>
          <TableCell numeric className={classes.iconsCell}>
            {getActionIcons(editMode, medical, actions)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderAddButton(editMode, handleAddOpen, t) {
    if (editMode) {
      return (
        <Button className="my-2" color="primary" onClick={handleAddOpen}>{t('user:addMedicalButton')}</Button>
      );
    }

    return null;
  }

  render() {
    const { classes, handleAddOpen, editMode, medicalList, t } = this.props;

    if (_isEmpty(medicalList)) {
      return (
        <div>
          <h4>{t('user:medicalsSection')}</h4>
          {this.renderAddButton(editMode, handleAddOpen, t)}
          <Divider className="my-3" />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <h4>{t('user:medicalsSection')}</h4>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:type')}</TableCell>
              <TableCell>{t('table:expiryDate')}</TableCell>
              <TableCell>{t('table:dateOfIssue')}</TableCell>
              <TableCell>{t('table:isVerified')}</TableCell>
              <TableCell>{t('table:verifiedBy')}</TableCell>
              <TableCell>{t('table:verificationDate')}</TableCell>
              <TableCell>{t('table:plannedAction')}</TableCell>
              <TableCell>{t('table:plannedActionDate')}</TableCell>
              <TableCell>{t('table:attachments')}</TableCell>
              <TableCell>{t('table:isActive')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderMedicalList()}
          </TableBody>
        </Table>
        {this.renderAddButton(editMode, handleAddOpen, t)}
        <Divider className="my-3" />
      </div>
    );
  }
}

