import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 180,
  },
  colorCell: {
    width: 20,
  },
});

@withNamespaces()
@withStyles(styles)
export default class RiskList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    riskAssessmentList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderButtons(risk) {
    return (
      <>
        <Link to={`/sms/risk-assessments/${risk.id}`}>
          <Icon color="primary">mode_edit</Icon>
        </Link>
      </>
    );
  }

  renderRisks() {
    const { riskAssessmentList, classes } = this.props;

    return riskAssessmentList.map((risk) => {
      return (
        <TableRow key={`risk-${risk.id}`}>
          <TableCell className={classes.longCell}>{risk.id}</TableCell>
          <TableCell className={classes.longCell}>{risk.title}</TableCell>
          <TableCell className={classes.longCell}>{risk.description}</TableCell>
          <TableCell className={classes.longCell}>{risk.date}</TableCell>
          <TableCell className={classes.iconsCell}>
            {this.renderButtons(risk)}
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { riskAssessmentList, classes, t } = this.props;

    if (_isEmpty(riskAssessmentList)) {
      return <></>;
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:id')}</TableCell>
              <TableCell>{t('table:title')}</TableCell>
              <TableCell>{t('table:description')}</TableCell>
              <TableCell>{t('table:date')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderRisks()}</TableBody>
        </Table>
      </div>
    );
  }
}
