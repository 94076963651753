import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import classNames from 'classnames';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import _set from 'lodash/set';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _cloneDeep from 'lodash/cloneDeep';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

import PracticalLesson from './PracticalLesson';
import BasicLessonInfo from './BasicLessonInfo';
import PendingItemList from './PendingItemList';
import SelectGrading from './SelectGrading';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import validators from '../../../../utils/validators';
import userService from '../../../../utils/userService';
import { isProgressCheckLesson, isPracticalLesson, isTheoreticalLesson } from '../../../../utils';
import {
  reformatPickerDateTimeToDB,
  reformatPickerDateTimeFromDB,
  dateTimeDiffInTimeFormat,
  timeToMinutes,
  calculateLessonTime
} from '../../../utils/time';
import { renderPermission } from '../../../decorators/permissions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { ZERO_TIME } from '../../../../constants';
import { LESSON_TYPES } from '../../../../constants/index';
import { isDataToSync } from '../../../../utils/offline.js';
import { addJourneyLogFunction } from '../../Logbook/LogbookNew/addJourneyLogHelper';

const styles = () => ({
  checkbox: {
    marginLeft: 10,
    paddingRight: 6,
    width: 30,
    height: 30,
  },
  selectAttendeesIcon: {
    paddingTop: 6,
    marginRight: 10,
  },
});

@withNamespaces()
@withStyles(styles)
export default class ItemList extends Component {
  static propTypes = {
    lesson: PropTypes.object.isRequired,
    course: PropTypes.object.isRequired,
    lessons: PropTypes.array.isRequired,
    editAssignedCourse: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    practicalFieldList: PropTypes.array.isRequired,
    assignedCourseId: PropTypes.string.isRequired,
    courseStartDate: PropTypes.string.isRequired,
    preview: PropTypes.bool,
    typeList: PropTypes.array.isRequired,
    locationList: PropTypes.array.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    courseParticipants: PropTypes.array,
    updateTheoreticalLessons: PropTypes.func.isRequired,
    addJourneyLog: PropTypes.func.isRequired,
    gradingList: PropTypes.array.isRequired,
    settings: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    const inheritedStartDate = reformatPickerDateTimeFromDB(_get(this.props.lesson, 'startDate', ''));
    const startDate = inheritedStartDate !== '' ? inheritedStartDate : moment();

    const inheritedEndDate = reformatPickerDateTimeFromDB(_get(this.props.lesson, 'endDate', ''));
    const endDate = inheritedEndDate !== '' ? inheritedEndDate
      : moment().add(timeToMinutes(calculateLessonTime(this.props.lesson)), 'minutes');
    const initialPracticalData = {
      'type': null,
      'departure': null,
      'arrival': null,
    };

    this.state = {
      triggers: {},
      formData: {},
      open: false,
      isFormValid: true,
      itemsState: this.props.lesson.items.reduce((result, item) => {
        result[item.id] = item.isCompleted;

        return result;
      }, {}),
      itemsGrade: this.props.lesson.items.reduce((result, item) => {
        result[item.lessonItemId] = item.grade;

        return result;
      }, {}),
      basicInfoDateHandlers: {
        startDate,
        endDate,
      },
      comments: this.props.lesson && this.props.lesson.comments,
      practicalLessonData: _get(this.props.lesson, 'practicalData', initialPracticalData),
      lesson: this.props.lesson,
      studentCheckboxesController: { [this.props.assignedCourseId]: true },
    };
  }

  addJourneyLog = () => {
    const { comments, practicalLessonData, parsedPracticalLessonData } = this.state;
    const { user, typeList, locationList } = this.props;


    const data = {
      userId: _get(user, 'id', 0),
      remarks: comments,
      type: _get(parsedPracticalLessonData, 'type.id', ''),
      dateOfJourneyLog: _get(parsedPracticalLessonData, 'departureTime', moment()),
      totalTime: _get(parsedPracticalLessonData, 'totalTime', ZERO_TIME),
      departure: _get(practicalLessonData, 'departure', ''),
      departureTime: _get(parsedPracticalLessonData, 'departureTime', moment()),
      arrivalTime: _get(parsedPracticalLessonData, 'arrivalTime', moment()),
      arrival: _get(practicalLessonData, 'arrival', ''),
      typeList,
      locationList,
      userSettings: {}
    };

    this.props.addJourneyLog(addJourneyLogFunction(data));
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid) {
      this.authorizeLesson();
      this.handleClose();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  openDialog = () => {
    this.setState({
      open: true,
    });
  };

  openDialog = () => {
    const { addSimpleError } = this.props;
    const { practicalLessonData, lesson } = this.state;
    const formedFilledIn = Object.keys(practicalLessonData).every((key) => !_isEmpty(practicalLessonData[key]));
    const hasItems = !_isEmpty(lesson.items);
    const theoreticalLesson = isTheoreticalLesson(lesson.lessonType);

    if ((!theoreticalLesson && !formedFilledIn) || !hasItems) {
      addSimpleError('Check if all fields are correctly filled in');
    } else {
      this.setState({
        open: true,
      });
    }
  };


  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  renderButtons = (preview, t) => {
    const { assignedCourseId } = this.props;

    if (preview) {
      return (
        <div className="row justify-content-center">
          <div className="col-auto">
            <Link to={`/course_list/details/${assignedCourseId}`}>
              <Button variant="raised">{t('buttonBack')}</Button>
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to={`/course_list/details/${assignedCourseId}`}>
            <Button variant="raised">{t('buttonBack')}</Button>
          </Link>
        </div>
        {this.renderAuthorizeCompletedButton(t)}
      </div>
    );
  };

  @renderPermission(PERMISSIONS.authorizeCompletedLesson)
  renderAuthorizeCompletedButton(t) {
    const { lesson } = this.state;

    const isTheoreticalLesson = lesson.lessonType.shortname === LESSON_TYPES.theoretical;

    if (!(isTheoreticalLesson && isDataToSync())) {
      return (
        <div className="col-auto">
          <Button onClick={this.openDialog} color="primary" variant="raised">{t('buttonSave')}</Button>
        </div>
      );
    }

    return (
      <div />
    );
  }

  isLessonPassed(lessonsItems, itemsState) {
    return lessonsItems.every(({ item }) => itemsState[item.id]);
  }

  parsePracticalLessonData = (practicalLessonData, startDate, endDate) => {
    const departureData = practicalLessonData.departure || '';
    const arrivalData = practicalLessonData.arrival || '';

    const totalTime = dateTimeDiffInTimeFormat(endDate, startDate);

    const { typeList } = this.props;
    const typeData = practicalLessonData.type || {};
    let chosenType = {};

    if (typeData.type) {
      chosenType = typeList.find((type) => type.id === typeData.type);
      chosenType.typeName = chosenType.name;
    }

    const parsedPracticalLessonData = {
      ...practicalLessonData,
      departure: departureData,
      departureTime: startDate,
      arrival: arrivalData,
      arrivalTime: endDate,
      totalTime,
      type: {
        ...typeData,
        ...chosenType,
      }
    };

    const newState = _cloneDeep(this.state);

    _set(newState, 'parsedPracticalLessonData', parsedPracticalLessonData);

    this.setState(newState);

    return parsedPracticalLessonData;
  };

  authorizeLesson = async () => {
    const { addSimpleError, course, editAssignedCourse, assignedCourseId, lessons } = this.props;
    const { itemsState, practicalLessonData, basicInfoDateHandlers: { startDate, endDate },
      comments, lesson, lesson: { id: lessonId, lessonType: editedLessonType }, itemsGrade } = this.state;
    const reformattedStartDate = reformatPickerDateTimeToDB(startDate);
    const reformattedEndDate = reformatPickerDateTimeToDB(endDate);
    let copiedLesson;

    const triggerKeys = Object.keys(this.triggers);

    for (let i = 0; i < triggerKeys.length; i++) {
      await this.triggers[triggerKeys[i]]();
    }

    const formDataIsValidObjects = Object.keys(this.formData).filter((key) => {
      return key.indexOf('isValid') > -1;
    });

    const isFormValid = formDataIsValidObjects.every((key) => this.formData[key]);

    if (isFormValid) {
      const newCourse = {
        ...course,
        lessons: lessons.map((courseLesson) => {
          const isEditedLesson = courseLesson.id === lessonId;
          const { lessonType } = courseLesson;

          if (!isEditedLesson) {
            if (isProgressCheckLesson(editedLessonType) || isProgressCheckLesson(lessonType)) {
              return courseLesson;
            }

            const otherLessonsItems = courseLesson.lessonsItems.map((lessonItem) => {
              return {
                ...lessonItem,
                item: {
                  ...lessonItem.item,
                  isCompleted: lessonItem.item.isCompleted || _get(itemsState, `[${lessonItem.item.id}]`),
                }
              };
            });

            return {
              ...courseLesson,
              lessonsItems: otherLessonsItems,
            };
          }

          const lessonsItems = lesson.items.map((item) => {
            return {
              id: item.id,
              order: item.order,
              item,
            };
          });
          const isPassed = this.isLessonPassed(lessonsItems, itemsState);
          const parsedPracticalLessonData = isPracticalLesson(lessonType) ||
          isProgressCheckLesson(lessonType) ?
            this.parsePracticalLessonData(practicalLessonData, startDate, endDate) :
            {};
          const user = userService.getCurrentUser();
          const newLesson = {
            ...courseLesson,
            instructor: user,
            practicalData: parsedPracticalLessonData,
            startDate: reformattedStartDate,
            endDate: reformattedEndDate,
            actualTime: dateTimeDiffInTimeFormat(endDate, startDate),
            isCompleted: isEditedLesson,
            isPassed,
            comments,
            lessonsItems: lessonsItems.map((lessonItem) => {
              const grade = isPracticalLesson(lessonType) ? _get(itemsGrade, `[${lessonItem.item.lessonItemId}]`) : '';

              return {
                ...lessonItem,
                item: {
                  ...lessonItem.item,
                  isCompleted: _get(itemsState, `[${lessonItem.item.id}]`),
                  grade,
                }
              };
            }),
          };

          if (isProgressCheckLesson(lessonType) && !isPassed) {
            copiedLesson = { ...courseLesson, lessonsItems };
          }

          return newLesson;
        })
      };

      if (copiedLesson) {
        newCourse.lessons.push({ ...copiedLesson, id: uuidv4(), order: newCourse.lessons.length + 1 });
      }

      await this.addJourneyLog();
      editAssignedCourse(assignedCourseId, { courseData: newCourse, startDate: reformattedStartDate }, `/course_list/details/${assignedCourseId}`);
    } else {
      addSimpleError('Check if all fields are correctly filled in');
    }
  };

  handleItemCheckedChange = (name) => (event) => {
    this.setState({
      itemsState: {
        ...this.state.itemsState,
        [name]: event.target.checked
      }
    });
  };

  handleGradeChange = (itemId) => (event) => {
    this.setState({
      itemsGrade: {
        ...this.state.itemsGrade,
        [itemId]: event.target.value,
      }
    });
  };

  handleStudentCheckedChange = (name) => (event) => {
    const { studentCheckboxesController } = this.state;

    this.setState({
      studentCheckboxesController: {
        ...studentCheckboxesController,
        [name]: event.target.checked,
      }
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      basicInfoDateHandlers: { ...this.state.basicInfoDateHandlers, [name]: dateFromPicker },
    });
  };

  handlePracticalDatePickerChange = (name) => (date) => {
    const newState = _cloneDeep(this.state);

    _set(newState, `practicalLessonData.${name}`, date);

    this.setState(newState);
  };

  handlePracticalSelectChange = (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, `practicalLessonData.${event.target.name}`, event.target.value);

    this.setState(newState);
  };

  handlePracticalChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, `practicalLessonData.${name}`, event.target.value);

    this.setState(newState, callback);
  };

  handlePracticalCheckboxChange = (name) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, `practicalLessonData.${name}`, event.target.checked);

    this.setState(newState);
  };

  registerComponentForm = (componentName) => {
    return (triggerFn) =>{
      this.triggers[componentName] = triggerFn;
    };
  };

  formValidated = (formName, isFormValid, formChanges) => {
    this.formData = {
      ...this.formData,
      [`${formName}Changes`]: formChanges,
      [`${formName}isValid`]: isFormValid,
    };
  };

  renderPracticalLesson = (lesson, preview) => {
    const { practicalFieldList, locationList, typeList } = this.props;
    const { practicalLessonData } = this.state;

    if ((!isPracticalLesson(lesson.lessonType) && !isProgressCheckLesson(lesson.lessonType)) || !practicalFieldList) {
      return null;
    }

    return [
      <div key="practical-lesson-1" className="col-12">
        <PracticalLesson
          practicalLessonData={practicalLessonData}
          handleChange={this.handlePracticalChange}
          handleSelectChange={this.handlePracticalSelectChange}
          handleCheckboxChange={this.handlePracticalCheckboxChange}
          handleDatePickerChange={this.handlePracticalDatePickerChange}
          preview={preview}
          practicalFieldList={practicalFieldList}
          locationList={locationList}
          typeList={typeList}
          handleRegisterComponentForm={this.registerComponentForm}
          handleFormValidated={this.formValidated}
        />
      </div>,
      <div key="practical-lesson-2" className="col-12">
        <Divider className="my-3" />
      </div>
    ];
  };

  renderBasicInfo = (lesson, preview) => {
    const { basicInfoDateHandlers } = this.state;

    return (
      <div className="col-12">
        <BasicLessonInfo
          lesson={lesson}
          handleDatePickerChange={this.handleDatePickerChange}
          preview={preview}
          basicInfoDateHandlers={basicInfoDateHandlers}
        />
      </div>
    );
  };

  @renderPermission(PERMISSIONS.authorizeCompletedLesson)
  renderCommentsTextfield(lesson, preview, t) {
    const { comments } = this.state;

    return (
      <TextField
        label={t('input:comments')}
        value={comments}
        margin="dense"
        fullWidth
        multiline
        disabled={preview}
        onChange={this.handleChange('comments')}
      />
    );
  }

  @renderPermission(PERMISSIONS.authorizeCompletedLesson)
  renderCompleteCheckbox(preview, isItemCompleted, item, t) {
    return (
      <div className="col-auto">
        <FormControlLabel
          color="primary"
          control={
            <Checkbox
              color="primary"
              disabled={preview}
              checked={isItemCompleted}
              onChange={this.handleItemCheckedChange(item.id)}
            />
          }
          label={t('course:completeItem')}
        />
      </div>
    );
  }

  renderCompletedLabel(isItemCompleted, t) {
    return (
      <div className="col-auto mx-3">
        <h6 className={isItemCompleted ? 'item-completed' : 'item-pending'}>
          {isItemCompleted ? t('course:completedItem') : t('course:inProgressItem')}
        </h6>
      </div>
    );
  }

  renderGrading(item, preview, t) {
    const { gradingList, settings } = this.props;
    const { itemsGrade } = this.state;
    const grade = _get(itemsGrade, `[${item.lessonItemId}]`, '');

    if (preview) {
      if (!grade) {
        return null;
      }

      const gradingType = `settings:${grade}`;

      return (
        <div className="col-auto mx-3">
          <h6>
            {t('input:grade')}: {t(gradingType)}
          </h6>
        </div>
      );
    }

    return (
      <SelectGrading
        gradingList={gradingList}
        settings={settings}
        handleGradeChange={this.handleGradeChange}
        item={item}
        grade={grade}
        preview={preview}
      />
    );
  }

  renderItems = (items, lessonType, preview, t) => {
    const { itemsState } = this.state;

    return _sortBy(items, ['order']).map((item, index, arr) => {
      const isItemCompleted = _get(itemsState, `[${item.id}]`, false);
      const isItemFinished = !!item.isCompleted;
      const newItem = [
        <div className="row justify-content-between align-items-center" key={`lesson-item-${item.id}`}>
          <div className="col">
            <h4>{index + 1}. {item.name}</h4>
          </div>
          <div className="col-auto mx-3">
            <div className="row align-items-center">
              <div className="col-auto">
                <Icon color="primary">alarm</Icon>
              </div>
              <div className="col-auto"><h4>{item.estimatedTime}</h4></div>
            </div>
          </div>
        </div>,
        <div key={`lesson-item-description-${item.id}`} className="lesson-item-details" dangerouslySetInnerHTML={{ __html: item.description }} />,
        <div className="row justify-content-end align-items-center" key={`lesson-item-completed-${item.id}`}>
          {preview || isItemFinished ? this.renderCompletedLabel(isItemCompleted, t) : this.renderCompleteCheckbox(preview, isItemCompleted, item, t)}
        </div>,
        <div className="row justify-content-end align-items-center" key={`lesson-item-grading-${item.id}`}>
          {isPracticalLesson(lessonType) && this.renderGrading(item, preview, t)}
        </div>,
        <div key={`lesson-item-files-${item.id}`}>
          {this.renderFiles(item)}
        </div>
      ];

      if (arr.length - 1 !== index) {
        newItem.push(
          <div key={`lesson-item-divider-${item.id}`}>
            <Divider className="my-3" />
          </div>
        );
      }

      return newItem;
    });
  };

  renderFiles(item) {
    const { files } = item;

    if (files) {
      return files.map((file) => {
        return (
          <Chip
            key={`item-file-${file.id}`}
            label={file.name}
            onClick={() => window.open(file.path, '_blank')}
            className="my-1 mx-2"
          />
        );
      });
    }

    return <div />;
  }

  addPendingItem = (item) => {
    const { lesson, itemsState } = this.state;
    const newLessonId = lesson.items.length;

    this.setState({
      lesson: {
        ...lesson,
        items: [
          ...lesson.items,
          {
            ...item,
            lessonItemId: newLessonId,
            order: newLessonId,
          }
        ]
      },
      itemsState: {
        ...itemsState,
        [item.id]: item.isCompleted || itemsState[item.id] || false,
      }
    });
  };

  @renderPermission(PERMISSIONS.renderPendingItems)
  renderPendingItems(lessons, preview) {
    return (
      <div className="col-lg-5 col-xs-12">
        <PendingItemList
          lessons={lessons}
          preview={preview}
          addPendingItem={this.addPendingItem}
        />
      </div>
    );
  }

  renderDialog() {
    const { user, t } = this.props;
    const { lesson } = this.state;

    if (lesson.lessonType.shortname === LESSON_TYPES.theoretical) {
      return this.renderTheoreticalLessonDialog(t);
    }

    return (
      <Form
        validateForm={this.validateForm}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="authorize-user"
          fullWidth
        >
          <DialogTitle id="authorize-user">{t('course:authorizeStudentDialogTitle')}</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <MaterialInput
                  autoFocus
                  name="pin"
                  label={t('input:studentsPin')}
                  margin="dense"
                  type="password"
                  validators={[
                    new validators.IsRequired(t),
                    new validators.Pin(t),
                    new validators.IsUsersPin(t, _get(user, 'userprofile.pin', '')),
                  ]}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleClose}>{t('buttonBack')}</Button>
            <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
          </DialogActions>
        </Dialog>
        <span />
      </Form>
    );
  }

  renderTheoreticalLessonDialog(t) {
    const { classes, courseParticipants } = this.props;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="select-attendees"
        fullWidth
      >
        <DialogTitle id="select-attendees">{t('course:selectAttendeesDialogTitle')}</DialogTitle>
        <DialogContent>
          <div className="mb-2">
            <Icon color="primary" className={classes.selectAttendeesIcon} >group_add</Icon>
            <p className="d-inline">{t('course:lessonParticipants')}</p>
          </div>
          {courseParticipants && courseParticipants.map((assignedCourse) => {
            return this.renderFormControlLabel(assignedCourse);
          })}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleClose}>{t('buttonCancel')}</Button>
          <Button color="primary" onClick={this.authorizeTheoreticalLesson}>{t('buttonSave')}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  authorizeTheoreticalLesson = () => {
    const { updateTheoreticalLessons, assignedCourseId } = this.props;
    const { itemsState, comments, lesson, lesson: { id: lessonId }, studentCheckboxesController,
      basicInfoDateHandlers: { startDate: startDate, endDate: endDate } } = this.state;
    const lessonsItems = lesson.items.map((item) => {
      return {
        id: item.id,
        order: item.order,
        item,
      };
    });
    const isPassed = this.isLessonPassed(lessonsItems, itemsState);
    const user = userService.getCurrentUser();
    const reformattedStartDate = reformatPickerDateTimeToDB(startDate);
    const reformattedEndDate = reformatPickerDateTimeToDB(endDate);

    const lessonData = {
      id: lessonId,
      instructor: user,
      startDate: reformattedStartDate,
      endDate: reformattedEndDate,
      actualTime: dateTimeDiffInTimeFormat(endDate, startDate),
      isCompleted: true,
      isPassed,
      comments,
      lessonsItems: lessonsItems.map((lessonItem) => {
        return {
          ...lessonItem,
          item: {
            ...lessonItem.item,
            isCompleted: _get(itemsState, `[${lessonItem.item.id}]`),
          }
        };
      })
    };

    const msg = {
      startDate: reformatPickerDateTimeToDB(),
      lessonData,
      ids: Object.keys(studentCheckboxesController).filter((key) => {
        return studentCheckboxesController[key];
      }),
    };

    updateTheoreticalLessons(msg, assignedCourseId, `/course_list/details/${assignedCourseId}`);
    this.handleClose();
  };

  renderFormControlLabel(assignedCourse) {
    const { classes } = this.props;
    const { studentCheckboxesController } = this.state;

    return (
      <FormControlLabel
        className={classNames('ml-1', 'd-flex')}
        control={
          <Checkbox
            checked={_get(studentCheckboxesController, `${assignedCourse.id}`, false)}
            onChange={this.handleStudentCheckedChange(assignedCourse.id)}
            color="primary"
            className={classes.checkbox}
            value={assignedCourse.id}
          />
        }
        label={`${assignedCourse.user.firstName} ${assignedCourse.user.lastName}`}
      />
    );
  }

  render() {
    const { preview, lessons, t } = this.props;
    const { lesson } = this.state;

    return (
      <div className="row">
        <div className="col">
          <div className="row">
            {this.renderBasicInfo(lesson, preview)}
            <div className="col-12">
              <Divider className="my-3" />
            </div>
            {this.renderPracticalLesson(lesson, preview)}
            <div className="col-12">
              {this.renderItems(lesson.items, lesson.lessonType, preview, t)}
            </div>
            <div className="col-12">
              <Divider className="my-3" />
            </div>
            <div className="col-12">
              {this.renderCommentsTextfield(lesson, preview, t)}
            </div>
            {this.renderDialog()}
          </div>
        </div>

        {this.renderPendingItems(lessons, preview)}

        <div className="col-12 my-3">
          {this.renderButtons(preview, t)}
        </div>
      </div>
    );
  }
}
