import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import { fetchSchoolLogo } from '../../actions/fileUpload';

import { renderModule } from '../../decorators/modules';
import { MODULES } from '../../../constants/modules';
import Logout from '../Authentication/Logout';
import LanguageSelect from '../../../common/components/LanguageSelect';

import companyLogo from '../../../images/qcLogo.png';

const mapStateToProps = (state) => {
  return {
    file: state.schoolLogo.file,
    fileRequest: state.schoolLogo.fileRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchSchoolLogo: (id) => dispatch(fetchSchoolLogo(id)),
  };
}


@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class Navigation extends Component {
  static propTypes = {
    schoolLogoId: PropTypes.number.isRequired,
    fetchSchoolLogo: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    fileRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const { schoolLogoId, fetchSchoolLogo } = this.props;

    fetchSchoolLogo(schoolLogoId);
  }

  @renderModule(MODULES.training)
  renderMyCourses(t) {
    return (
      <Link to="/my_courses" className="nav-link">
        <Tooltip title={t('navigation:myCourses')} placement="top">
          <Icon color="primary" className="mx-1">content_paste</Icon>
        </Tooltip>
        <div className="nav-text">
          {t('navigation:myCourses')}
        </div>
      </Link>
    );
  }

  @renderModule(MODULES.exams)
  renderMyExams(t) {
    return (
      <Link to="/my_exams" className="nav-link">
        <Tooltip title={t('navigation:myExams')} placement="top">
          <Icon color="primary" className="mx-1">playlist_add_check</Icon>
        </Tooltip>
        <div className="nav-text">
          {t('navigation:myExams')}
        </div>
      </Link>
    );
  }

  @renderModule(MODULES.logbook)
  renderMyLogbook(t) {
    return (
      <Link to="/my_logbook" className="nav-link">
        <Tooltip title={t('navigation:myLogbook')} placement="top">
          <Icon color="primary" className="mx-1">airplanemode_active</Icon>
        </Tooltip>
        <div className="nav-text">
          {t('navigation:myLogbook')}
        </div>
      </Link>
    );
  }

  @renderModule(MODULES.report)
  renderMyReports(t) {
    return (
      <Link to="/my_reports" className="nav-link">
        <Tooltip title={t('navigation:myReports')} placement="top">
          <Icon color="primary" className="mx-1">description</Icon>
        </Tooltip>
        <div className="nav-text">
          {t('navigation:myReports')}
        </div>
      </Link>
    );
  }

  @renderModule(MODULES.library)
  renderLibrary(t) {
    return (
      <Link to="/library" className="nav-link">
        <Tooltip title={t('navigation:libraryMenu')} placement="top">
          <Icon color="primary" className="mx-1">book</Icon>
        </Tooltip>
        <div className="nav-text">
          {t('navigation:libraryMenu')}
        </div>
      </Link>
    );
  }

  @renderModule(MODULES.booking)
  renderBooking(t) {
    return (
      <Link to="/booking" className="nav-link">
        <Tooltip title={t('navigation:bookingMenu')} placement="top">
          <Icon color="primary" className="mx-1">event</Icon>
        </Tooltip>
        <div className="nav-text">
          {t('navigation:bookingMenu')}
        </div>
      </Link>
    );
  }

  renderMyProfile(t) {
    return (
      <Link to="/my_profile" className="nav-link">
        <Tooltip title={t('navigation:myProfile')} placement="top">
          <Icon color="primary" className="mx-1">person</Icon>
        </Tooltip>
        <div className="nav-text">
          {t('navigation:myProfile')}
        </div>
      </Link>
    );
  }

  @renderModule(MODULES.eLearning)
  renderElearning(t) {
    return (
      <a href="https://qrosscheck-learning.com/uncanny-group-login/" className="nav-link" target="_blank">
        <Tooltip title={t('navigation:eLearning')} placement="top">
          <Icon color="primary" className="mx-1">school</Icon>
        </Tooltip>
        <div className="nav-text">
          {t('navigation:eLearning')}
        </div>
      </a>
    );
  }

  getSchoolLogo = () => {
    const { file } = this.props;

    return file ? _isEmpty(file.path) ? companyLogo : file.path : companyLogo;
  };


  render() {
    const { t } = this.props;

    return (
      <div className="nav-wrapper">
        <div>
          <Link to="/">
            <img className="nav-img" src={this.getSchoolLogo()} />
          </Link>
        </div>
        <nav>
          {this.renderLibrary(t)}
          {this.renderBooking(t)}
          {this.renderMyCourses(t)}
          {this.renderMyExams(t)}
          {this.renderMyReports(t)}
          {this.renderMyLogbook(t)}
          {this.renderMyProfile(t)}
          {this.renderElearning(t)}
          <LanguageSelect />
          <Logout />
        </nav>
      </div>
    );
  }
}
