import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';

export const FETCH_ITEM_TYPES_REQUEST = 'FETCH_ITEM_TYPES_REQUEST';
export const FETCH_ITEM_TYPES_SUCCESS = 'FETCH_ITEM_TYPES_SUCCESS';
export const FETCH_ITEM_TYPES_FAILURE = 'FETCH_ITEM_TYPES_FAILURE';
export const ADD_ITEM_TYPE = 'ADD_ITEM_TYPE';
export const EDIT_ITEM_TYPE = 'EDIT_ITEM_TYPE';
export const REMOVE_ITEM_TYPE = 'REMOVE_ITEM_TYPE';


export function fetchItemTypesRequest() {
  return {
    type: FETCH_ITEM_TYPES_REQUEST
  };
}

export function fetchItemTypesSuccess(itemTypes) {
  return {
    type: FETCH_ITEM_TYPES_SUCCESS,
    payload: {
      itemTypes
    }
  };
}

export function fetchItemTypesFailure() {
  return {
    type: FETCH_ITEM_TYPES_FAILURE
  };
}

export function addItemTypeSuccess(itemType) {
  return {
    type: ADD_ITEM_TYPE,
    payload: {
      itemType
    }
  };
}

export function removeItemTypeSuccess(itemType) {
  return {
    type: REMOVE_ITEM_TYPE,
    payload: {
      itemType
    }
  };
}

export function fetchItemTypes() {
  return (dispatch) => {
    dispatch(fetchItemTypesRequest());

    return axios.get(URLS.itemTypes)
      .then(({ data }) => dispatch(fetchItemTypesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchItemTypesFailure());
        dispatch(addError(`Error during itemTypes fetching ${error.message}`, error));
      });
  };
}

export function addItemType(itemType) {
  return (dispatch) => {
    return axios.post(URLS.itemTypes, itemType)
      .then(({ data }) => dispatch(addItemTypeSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during itemType adding ${error.message}`, error));
      });
  };
}

export function removeItemType(itemType) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.itemTypes, itemType.id))
      .then(() => dispatch(removeItemTypeSuccess(itemType)))
      .catch((error) => {
        dispatch(addError(`Error during itemType removing ${error.message}`, error));
      });
  };
}
