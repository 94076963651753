export const MODULES = {
  training: 'Training',
  exams: 'Exams',
  booking: 'Booking',
  logbook: 'Logbook',
  report: 'Report',
  equipment: 'Equipment',
  library: 'Library',
  eLearning: 'Elearning',
  engineerMode: 'EngineerMode',
  fleet: 'Fleet',
  advert: 'Advertisement',
  complementaryCertificate: 'ComplementaryCertificate',
  userProfilePersonalFile: 'UserProfilePersonalFile',
  userProfileLicence: 'UserProfileLicence',
  userProfileMedical: 'UserProfileMedical',
  userProfileRating: 'UserProfileRating',
  userProfileType: 'UserProfileType',
  userProfileQualification: 'UserProfileQualification',
  userProfileExternalCourse: 'UserProfileExternalCourse',
};
