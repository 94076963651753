import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import CourseNew from '../CourseNew';
import Loader from '../../../../common/components/Loader';
import { fetchAssignedCourse } from '../../../actions/assignedCourses';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';
import { isCourseCompleted } from '../../../../utils';

const mapStateToProps = (state) => {
  return {
    assignedCourse: state.assignedCourses.assignedCourse,
    assignedCourseRequest: state.assignedCourses.assignedCourseRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAssignedCourse: (id) => dispatch(fetchAssignedCourse(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.assignedCourseEdit)
@componentPermission(PERMISSIONS.editCourseInCourseList)
export default class AssignedCourseEdit extends Component {
  static propTypes = {
    assignedCourse: PropTypes.object.isRequired,
    assignedCourseRequest: PropTypes.bool.isRequired,
    fetchAssignedCourse: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.fetchAssignedCourse(this.props.match.params.id);
  }

  render() {
    const { assignedCourse, assignedCourseRequest, match } = this.props;
    const courseData = _get(assignedCourse, 'courseData');

    if (assignedCourseRequest || !courseData) {
      return <Loader />;
    }

    if (isCourseCompleted(assignedCourse)) {
      return <Redirect to={{ pathname: match.url.replace('edit', 'details') }} />;
    }

    return (
      <CourseNew course={courseData} editMode assignedCourseId={match.params.id} />
    );
  }
}
