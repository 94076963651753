import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _each from 'lodash/each';
import _get from 'lodash/get';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import Form from '../../../common/components/forms/Form';
import MaterialInput from '../../../common/components/forms/MaterialInput';
import validators from '../../../utils/validators';
import SelectCollections from '../Select/SelectCollections';


import './styles.scss';


@withNamespaces()
export default class AddFolder extends Component {
  static propTypes = {
    addFolder: PropTypes.func.isRequired,
    editFolder: PropTypes.func.isRequired,
    addDialogOpen: PropTypes.bool.isRequired,
    editDialogOpen: PropTypes.bool.isRequired,
    closeAddEditDialog: PropTypes.func.isRequired,
    folder: PropTypes.object,
    roleList: PropTypes.array.isRequired,
    schoolGroupList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    addDialogOpenState: PropTypes.bool.isRequired,
    editDialogOpenState: PropTypes.bool.isRequired,
  };

  state = {
    name: _get(this.props, 'folder.name', ''),
    roles: this.props.folder && this.props.folder.groups.reduce((roles, group) => {
      roles[group.name] = true;

      return roles;
    }, {}) || { 'admin': true },
    collection: _get(this.props.folder, 'schoolGroups', []).map((group) => ({ value: group.id, label: group.name })),
  };

  componentDidMount() {
    this.formData = {};
  }

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  handleSelectChange = (name) => (value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onFormValidated = (isFormValid) => {
    const { addDialogOpen } = this.props;

    this.setState({
      isFormValid
    });

    if (isFormValid) {
      addDialogOpen ? this.addFolder() : this.editFolder();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  addFolder = () => {
    const { name, roles, collection } = this.state;
    const { roleList } = this.props;

    const folderForm = {
      name,
      groups: roleList.filter((group) => roles[group.name]),
      schoolGroups: collection.map((group) => ({ id: group.value, name: group.label })),
    };

    this.props.addFolder(folderForm);
    this.closeAddEditDialog();
  };

  editFolder = () => {
    const { folder, editFolder, roleList } = this.props;
    const { name, roles, collection } = this.state;
    const newFolder = {
      id: folder.id,
      name,
      groups: roleList.filter((group) => roles[group.name]),
      schoolGroups: collection.map((group) => ({ id: group.value, name: group.label })),
    };

    editFolder(newFolder);
    this.closeAddEditDialog();
  };

  closeAddEditDialog = () => {
    this.props.closeAddEditDialog();
  }

  handleCheck = (role) => (event) => {
    this.setState({
      roles: {
        ...this.state.roles,
        [role.name]: event.target.checked
      }
    });
  };

  isAdminRole = (roleName) => {
    return roleName === 'admin';
  }

  renderChecklist = () => {
    const { roleList, t } = this.props;

    return roleList
      .map((role) => {
        return (
          <div
            className="col-6"
            key={`role-${role.id}`}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={this.state.roles[role.name]}
                  disabled={this.isAdminRole(role.name)}
                  onChange={this.handleCheck(role)}
                />
              }
              label={t(role.name)}
            />
          </div>
        );
      });
  }

  render() {
    const { addDialogOpen, t, addDialogOpenState, editDialogOpenState, schoolGroupList } = this.props;
    const { name, collection } = this.state;

    return (
      <Form
        onChange={this.onChange}
        onFormValidated={this.onFormValidated}
        registerForm={this.registerForm}
      >
        <Dialog
          open={addDialogOpenState || editDialogOpenState}
          onClose={this.closeAddEditDialog}
          aria-labelledby="add-folder"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="add-folder">
            {addDialogOpen ? t('folder:createFolderDialogTitle') : t('folder:editFolderDialogTitle')}
          </DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-6">
                <MaterialInput
                  label={t('input:name')}
                  defaultValue={addDialogOpenState ? '' : name}
                  name="name"
                  margin="dense"
                  autoFocus
                  fullWidth
                  validators={[
                    new validators.IsRequired(t),
                    new validators.MaxLength(t, 250),
                  ]}
                />
              </div>
              <div className="col-12 pt-3">
                <FormLabel>{t('folder:addFolderPermissions')}</FormLabel>
                <FormGroup row>
                  {this.renderChecklist()}
                </FormGroup>
              </div>
              <div className="col-12 pt-3">
                <SelectCollections
                  preview={false}
                  collection={collection}
                  collectionList={schoolGroupList}
                  handleSelectChange={this.handleSelectChange}
                  placeholder={t('input:groups')}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.closeAddEditDialog} >
              {t('buttonCancel')}
            </Button>
            <Button color="primary" onClick={this.sendHandler}>
              {t('buttonSave')}
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    );
  }
}
