import React, { Component } from 'react';
import PropTypes from 'prop-types';


export default class CustomIconButton extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]).isRequired,
  };

  render() {
    const { children } = this.props;

    return (
      <div tabIndex="0" className="custom-upload-button">
        <span className="custom-upload-icon-button">
          {children}
        </span>
        <span className="custom-upload-touch-button" />
      </div>
    );
  }
}
