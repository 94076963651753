import { utils, write } from 'xlsx';

import { saveAs } from 'file-saver';

const FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const FILE_EXTENSION = 'xlsx';

export function downloadAsExcel(fileName, header, data) {
  const ws = utils.json_to_sheet([header].concat(data), {
    skipHeader: true,
  });

  const wb = {
    Sheets: { qrosscheck: ws },
    SheetNames: ['qrosscheck'],
  };
  const excelBuffer = write(wb, {
    bookType: FILE_EXTENSION,
    type: 'array',
  });
  const blob = new Blob([excelBuffer], { type: FILE_TYPE });
  saveAs(blob, `${fileName}.${FILE_EXTENSION}`);
}
