import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';

export const FETCH_PRACTICAL_FIELDS_REQUEST = 'FETCH_PRACTICAL_FIELDS_REQUEST';
export const FETCH_PRACTICAL_FIELDS_SUCCESS = 'FETCH_PRACTICAL_FIELDS_SUCCESS';
export const FETCH_PRACTICAL_FIELDS_FAILURE = 'FETCH_PRACTICAL_FIELDS_FAILURE';
export const EDIT_PRACTICAL_FIELD = 'EDIT_PRACTICAL_FIELD';


export function fetchPracticalFieldsRequest() {
  return {
    type: FETCH_PRACTICAL_FIELDS_REQUEST
  };
}

export function fetchPracticalFieldsSuccess(practicalFields) {
  return {
    type: FETCH_PRACTICAL_FIELDS_SUCCESS,
    payload: {
      practicalFields
    }
  };
}

export function fetchPracticalFieldsFailure() {
  return {
    type: FETCH_PRACTICAL_FIELDS_FAILURE
  };
}

export function editPracticalFieldSuccess(practicalField) {
  return {
    type: EDIT_PRACTICAL_FIELD,
    payload: {
      practicalField
    }
  };
}

export function fetchPracticalFields() {
  return (dispatch) => {
    dispatch(fetchPracticalFieldsRequest());

    return axios.get(URLS.practicalFields)
      .then(({ data }) => dispatch(fetchPracticalFieldsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchPracticalFieldsFailure());
        dispatch(addError(`Error during school practical fields fetching ${error.message}`, error));
      });
  };
}

export function editPracticalField(practicalField) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.practicalFields, practicalField.id), { isActive: practicalField.isActive })
      .then(({ data }) => dispatch(editPracticalFieldSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during school practical field editing ${error.message}`, error));
      });
  };
}

