import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import AssignCompetence from '../../../containers/AssignCompetence';
import StaffCategoriesList from './StaffCategoriesList';
import Loader from '../../../../common/components/Loader';

import { fetchStaffCategories, removeStaffCategory, addStaffCategory, editStaffCategory } from '../../../actions/staffCategories';
import { resetAssignTestFlag } from '../../../actions/assignedTests';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

const styles = () => ({
  root: {
    overflowY: 'visible'
  }
});

const INITIAL_STATE = {
  searchSettings: {
    search: '',
  },
  removeDialogOpen: false,
  addOpen: false,
  editOpen: false,
  staffCategory: {}
};

const mapStateToProps = (state) => {
  return {
    staffCategoryList: state.staffCategories.staffCategoryList,
    staffCategoryRequest: state.staffCategories.staffCategoryRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchStaffCategories: (sortData) => dispatch(fetchStaffCategories(sortData)),
    removeStaffCategory: (staffCategory) => dispatch(removeStaffCategory(staffCategory)),
    addStaffCategory: (staffCategory) => dispatch(addStaffCategory(staffCategory)),
    editStaffCategory: (staffCategory) => dispatch(editStaffCategory(staffCategory)),
    resetAssignTestFlag: () => dispatch(resetAssignTestFlag()),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.examView)
export default class ManageTests extends Component {
  static propTypes = {
    staffCategoryList: PropTypes.array,
    staffCategoryRequest: PropTypes.bool.isRequired,
    fetchStaffCategories: PropTypes.func.isRequired,
    removeStaffCategory: PropTypes.func.isRequired,
    addStaffCategory: PropTypes.func.isRequired,
    editStaffCategory: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.fetchStaffCategories();
  }

  removeStaffCategory = () => {
    const { removeStaffCategory } = this.props;
    const { staffCategory } = this.state;

    this.closeRemoveDialog();
    removeStaffCategory(staffCategory);
  };

  handleEditOpen = (staffCategory) => {
    this.setState({
      editOpen: true,
      staffCategory
    });
  };

  renderAddDialog = (t) => {
    const { addOpen } = this.state;

    return (
      <div className="col-12">
        <Dialog
          open={addOpen}
          onClose={this.handleAddStaffCategoryClose}
          maxWidth="md"
          fullWidth
        >
          <AssignCompetence
            handleAssignViewClose={this.handleAddStaffCategoryClose}
            serializeData={this.serializeData}
            viewLabel={t('settings:addStaffCategoryDialogTitle')}
          />
        </Dialog>
      </div>
    );
  };

  renderEditDialog = (t) => {
    const { editOpen, staffCategory } = this.state;

    return (
      <div className="col-12">
        <Dialog
          open={editOpen}
          onClose={this.handleEditStaffCategoryClose}
          maxWidth="md"
          fullWidth
        >
          <AssignCompetence
            handleAssignViewClose={this.handleEditStaffCategoryClose}
            serializeData={this.serializeData}
            viewLabel={t('settings:editStaffCategoryDialogTitle')}
            staffCategoryToEdit={staffCategory}
            editMode={editOpen}
          />
        </Dialog>
      </div>
    );
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleAddStaffCategoryClose = () => {
    this.setState({
      addOpen: false,
    });
  };

  handleEditStaffCategoryClose = () => {
    this.setState({
      editOpen: false,
    });
  };

  serializeData = (competencies, name, editMode, id) => {
    let dataToSend = {};

    dataToSend = {
      name,
      competencies
    };

    if (editMode) {
      dataToSend = {
        ...dataToSend,
        id
      };

      return this.props.editStaffCategory(dataToSend);
    }

    return this.props.addStaffCategory(dataToSend);
  };

  render() {
    const { staffCategoryList, staffCategoryRequest, removeStaffCategory, onElementDelete, t } = this.props;

    if (staffCategoryRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-12">
          <StaffCategoriesList
            staffCategoryList={staffCategoryList}
            handleAddOpen={this.handleAddOpen}
            handleEditOpen={this.handleEditOpen}
            removeStaffCategory={removeStaffCategory}
            onElementDelete={onElementDelete}
          />
          {this.renderAddDialog(t)}
          {this.renderEditDialog(t)}
        </div>
      </div>
    );
  }
}
