import React from 'react';
import moment from 'moment';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { reformatPickerDateToDB } from '../../../utils/time';
import userService from '../../../../utils/userService';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { hasPermission } from '../../../decorators/permissions';

function handleVerifyAction(item, addPlannedAction) {
  const data = {
    id: item.id,
    isVerified: true,
    verificationDate: reformatPickerDateToDB(moment()),
    verifiedById: userService.getCurrentUser().id,
  };

  addPlannedAction(data);
}

export function verifyAction(item, documentName, addPlannedAction, customStyle) {
  if (hasPermission(PERMISSIONS[`confirm${documentName}s`]) && (!item.isVerified)) {
    return (
      <Tooltip title="Verify" placement="top">
        <IconButton onClick={() => handleVerifyAction(item, addPlannedAction)}>
          { customStyle ?
            <Icon style={{ color: '#6b33bf' }}>check_circle_outline</Icon> :
            <Icon color="primary">check_circle_outline</Icon> }
        </IconButton>
      </Tooltip>
    );
  }

  return null;
}

export function getVerifiedBy(verifiedBy) {
  if (verifiedBy) {
    return `${verifiedBy.firstName} ${verifiedBy.lastName}`;
  }

  return '';
}

export function isVerified(isVerified) {
  if (isVerified) {
    return <Icon color="action" className="mr-3">done</Icon>;
  }

  return <Icon color="error" className="mr-3">clear</Icon>;
}
