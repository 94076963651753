import '../styles.scss';

import React, { Component } from 'react';
import {
  addQualification,
  addQualificationPlannedAction,
  deactivateQualification,
  editQualification,
  removeQualification,
} from '../../../actions/qualifications';
import {
  attachQualificationFile,
  removeQualificationFile,
} from '../../../actions/fileUpload';
import {
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import QualificationsList from './QualificationsList';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { getDateFormat } from '../../../utils/time';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  type: '',
  expiryDate: null,
  id: null,
};

const mapStateToProps = (state) => {
  return {
    qualificationList: state.qualifications.qualificationList,
    qualificationRequest: state.qualifications.qualificationsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addQualification: (qualification) =>
      dispatch(addQualification(qualification)),
    addQualificationPlannedAction: (qualification) =>
      dispatch(addQualificationPlannedAction(qualification)),
    removeQualification: (qualification) =>
      dispatch(removeQualification(qualification)),
    editQualification: (qualification) =>
      dispatch(editQualification(qualification)),
    deactivateQualification: (qualification) =>
      dispatch(deactivateQualification(qualification)),
    attachQualificationFile: (file, id, userId) =>
      dispatch(attachQualificationFile(file, id, userId)),
    removeQualificationFile: (fileId, id) =>
      dispatch(removeQualificationFile(fileId, id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UserQualifications extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    qualificationList: PropTypes.array,
    editMode: PropTypes.bool,
    qualificationsRequest: PropTypes.bool,
    addQualification: PropTypes.func.isRequired,
    addQualificationPlannedAction: PropTypes.func.isRequired,
    removeQualification: PropTypes.func.isRequired,
    editQualification: PropTypes.func.isRequired,
    deactivateQualification: PropTypes.func.isRequired,
    attachQualificationFile: PropTypes.func.isRequired,
    removeQualificationFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    documentTypeList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (qualification) => {
    this.setState({
      editOpen: true,
      id: qualification.id,
      expiryDate: qualification.expiryDate,
      type: qualification.type,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addQualification = () => {
    const { type, expiryDate } = this.state;
    const { userId } = this.props;

    if (!_isEmpty(type)) {
      const newQualification = {
        type,
        userId,
        expiryDate: reformatPickerDateToDB(expiryDate),
      };

      this.props.addQualification(newQualification);
      this.handleDialogClose();
    }
  };

  removeQualification = (qualification) => {
    this.props.removeQualification(qualification);
  };

  editQualification = () => {
    const { type, expiryDate, id } = this.state;
    const { userId } = this.props;

    if (!_isEmpty(type)) {
      const newQualification = {
        id,
        type,
        userId,
        expiryDate: reformatPickerDateToDB(expiryDate),
      };

      this.props.editQualification(newQualification);
      this.handleDialogClose();
    }
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value,
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid && this.formData) {
        this.state.addOpen ? this.addQualification() : this.editQualification();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  attachQualificationFile = (file) => (id) => {
    const { attachQualificationFile, userId } = this.props;

    attachQualificationFile(file, id, userId);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDatePickerChange = (date) => {
    this.setState({
      expiryDate: date,
    });
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  renderSelect = (inputName, inputLabel, value, changeFunction) => {
    const { documentTypeList } = this.props;

    return (
      <AutoSuggestion
        label={inputName}
        inputName={inputLabel}
        defaultValue={value ? value : ''}
        handleChange={changeFunction}
        typeList={documentTypeList}
        fullWidth
      />
    );
  };

  dialogContent = (classes, title, t) => {
    const { expiryDate, type } = this.state;

    return (
      <div>
        <DialogTitle id="add-qualification-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              {this.renderSelect('type', 'type', type, this.handleSelectChange)}
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                acceptEmptyInput
                value={reformatPickerDateFromDB(expiryDate)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange}
                dateFormat={getDateFormat()}
                name="expiryDate"
                label={t('input:expiryDate')}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
      </div>
    );
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  render() {
    const {
      qualificationList,
      editMode,
      qualificationsRequest,
      removeQualificationFile,
      addSimpleError,
      classes,
      onElementDelete,
      t,
      addQualificationPlannedAction,
      deactivateQualification,
    } = this.props;
    const { addOpen, editOpen } = this.state;

    if (qualificationsRequest) {
      return <Loader />;
    }

    return (
      <div className="col-12 user">
        <QualificationsList
          qualificationList={qualificationList}
          addQualificationPlannedAction={addQualificationPlannedAction}
          removeQualification={(qualification) =>
            onElementDelete(() => this.removeQualification(qualification))
          }
          handleAddOpen={this.handleAddOpen}
          editMode={editMode}
          handleEditOpen={this.handleEditOpen}
          attachQualificationFile={this.attachQualificationFile}
          removeQualificationFile={(fileId, id) =>
            onElementDelete(() => removeQualificationFile(fileId, id))
          }
          addSimpleError={addSimpleError}
          deactivateQualification={deactivateQualification}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={addOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="add-qualification-dialog-title"
          >
            {this.dialogContent(classes, t('user:addQualification'), t)}
            {this.dialogActions(t)}
          </Dialog>
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={editOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="edit-qualification-dialog-title"
          >
            {this.dialogContent(classes, t('user:editQualification'), t)}
            {this.dialogActions(t)}
          </Dialog>
        </Form>
      </div>
    );
  }
}
