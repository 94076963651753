import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


function AuthenticationError(props) {
  const { message, isError } = props;

  if (!isError) {
    return null;
  }

  return (
    <div className="py-3 error-text">
      {message}
    </div>
  );
}

AuthenticationError.propTypes = {
  message: PropTypes.string,
  isError: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  return {
    isError: state.errors.authenticationError,
    message: state.errors.message,
  };
};

export default connect(mapStateToProps)(AuthenticationError);
