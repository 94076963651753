import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 320,
  },
});

@withNamespaces()
@withStyles(styles)
export default class EngineerAircraftTypeList extends Component {
  static propTypes = {
    aircraftTypeList: PropTypes.array,
    removeAircraftType: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderAircraftTypeList() {
    const {
      aircraftTypeList,
      handleEditOpen,
      removeAircraftType,
      onElementDelete,
      classes,
    } = this.props;

    return aircraftTypeList.map((aircraft) => {
      return (
        <TableRow key={`aircraft-${aircraft.id}`}>
          <TableCell className={classes.longCell}>{aircraft.name}</TableCell>
          <TableCell className={classes.longCell}>{aircraft.info}</TableCell>
          <TableCell className={classes.longCell} numeric>
            <IconButton onClick={() => handleEditOpen(aircraft)}>
              <Icon color="primary">mode_edit</Icon>
            </IconButton>
            <IconButton
              onClick={() =>
                onElementDelete(() => removeAircraftType(aircraft))
              }
            >
              <Icon color="primary">delete</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes, handleAddOpen, aircraftTypeList, t } = this.props;

    if (_isEmpty(aircraftTypeList)) {
      return (
        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addVehicleButton')}
        </Button>
      );
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:name')}</TableCell>
              <TableCell>{t('table:information')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderAircraftTypeList()}</TableBody>
        </Table>
        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addVehicleButton')}
        </Button>
      </div>
    );
  }
}
