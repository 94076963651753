import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DocumentTypeList from './DocumentTypeList';
import Loader from '../../../../common/components/Loader';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';

import {
  fetchDocumentTypes,
  addDocumentType,
  removeDocumentType
} from '../../../actions/documentTypes';
import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  documentTypeName: '',
  documentTypeId: null
};

const mapStateToProps = (state) => {
  return {
    documentTypeList: state.documentTypes.documentTypeList,
    documentTypeRequest: state.documentTypes.documentTypeRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchDocumentTypes: () => dispatch(fetchDocumentTypes()),
    addDocumentType: (name) => dispatch(addDocumentType(name)),
    removeDocumentType: (name) => dispatch(removeDocumentType(name)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class DocumentTypesManager extends Component {
  static propTypes = {
    documentTypeList: PropTypes.array,
    documentTypeRequest: PropTypes.bool.isRequired,
    fetchDocumentTypes: PropTypes.func.isRequired,
    addDocumentType: PropTypes.func.isRequired,
    removeDocumentType: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.props.fetchDocumentTypes();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleAddClose = () => {
    this.setState(INITIAL_STATE);
  };

  addDocumentType = () => {
    const documentType = {
      name: this.state.documentTypeName
    };

    this.props.addDocumentType(documentType);
    this.handleAddClose();
  };

  removeDocumentType = (documentType) => {
    this.props.removeDocumentType(documentType);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid && this.formData) {
        this.addDocumentType();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  addDialogContent = (t) => {
    return (
      <MaterialInput
        margin="dense"
        autoFocus
        name="documentTypeName"
        label={t('input:name')}
        validators={[
          new validators.IsRequired(t),
          new validators.MaxLength(t, 200)
        ]}
        defaultValue={this.state.documentTypeName}
      />
    );
  };

  render() {
    const { documentTypeList, documentTypeRequest, onElementDelete, t } = this.props;

    if (documentTypeRequest) {
      return <Loader />;
    }

    return (
      <div className="document">
        <div className="pl-sm-5">
          <h4>{t('settings:documentTypesSection')}</h4>
        </div>
        <DocumentTypeList
          documentTypeList={documentTypeList}
          removeDocumentType={this.removeDocumentType}
          handleAddOpen={this.handleAddOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={this.state.addOpen}
            onClose={this.handleAddClose}
            aria-labelledby="add-document-type-dialog"
            fullWidth
          >
            <DialogTitle id="add-document-type-dialog">{t('settings:addDocumentTypeDialogTitle')}</DialogTitle>
            <DialogContent>
              { this.addDialogContent(t) }
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleAddClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
