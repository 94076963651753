import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

import Form from '../../../common/components/forms/Form';
import MaterialInput from '../../../common/components/forms/MaterialInput';
import validators from '../../../utils/validators';
import AuthenticationError from './AuthenticationError';
import AuthenticationBase from './AuthenticationBase';

import { login } from '../../actions/authentication';
import { removeError } from '../../actions/errors';


const mapStateToProps = (state) => {
  return {
    loginFailure: state.authentication.loginFailure,
    loginFetching: state.authentication.loginRequest
  };
};

function mapDispatchToProps(dispatch) {
  return {
    login: (formData) => dispatch(login(formData)),
    removeError: () => dispatch(removeError()),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class Login extends AuthenticationBase {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    loginFailure: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    removeError: PropTypes.func.isRequired,
    loginFetching: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    isFormValid: false
  };

  componentWillUpdate() {
    setTimeout(this.props.removeError, 5000);
  }

  onChange = (formData) => {
    this.formData = formData;
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid && this.formData) {
      const loginData = {
        login: this.formData.login.value.toLocaleLowerCase(),
        password: this.formData.password.value,
      };

      this.props.login(loginData);
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.sendHandler(e);
    }
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  renderContent = () => {
    const { location, t } = this.props;
    const defaultLogin = new URLSearchParams(location.search).get('login');

    return (
      <div>
        <div className="error-text">
          {t('authentication:loginCaseSensitive')}
        </div>
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <div className="col-12 row input-row">
            <MaterialInput
              className="col col-lg-8"
              margin="dense"
              id="login"
              name="login"
              label={t('input:login')}
              defaultValue={defaultLogin}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>person</Icon>
                  </InputAdornment>
                ),
              }}
              onKeyPress={this.handleKeyPress}
              validators={[
                new validators.IsRequired(t),
                new validators.Email(t),
              ]}
            />
          </div>
          <div className="col-12 row input-row">
            <MaterialInput
              className="col col-lg-8"
              margin="dense"
              id="password"
              name="password"
              label={t('input:password')}
              type="password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>lock</Icon>
                  </InputAdornment>
                ),
              }}
              onKeyPress={this.handleKeyPress}
              validators={[
                new validators.IsRequired(t)
              ]}
            />
          </div>
          <AuthenticationError />
          <Button
            className="my-3"
            onClick={this.sendHandler}
            color="primary"
            variant="raised"
          >
            {t('buttonLogin')}
          </Button>
        </Form>
        <Link to="/password-recovery">{t('authentication:resetPassword')}</Link>
      </div>
    );
  }

  render() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return this.renderBaseTemplate();
  }
}
