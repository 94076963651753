import React, { Component } from 'react';
import {
  hasPermission,
  renderPermission,
} from '../../../decorators/permissions';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import PropTypes from 'prop-types';
import RemoveDialog from '../../../../common/components/RemoveDialog';
import { TABLE_HEADER } from './fleetConstants';
import Table from '../../../../common/components/Table';
import _get from 'lodash/get';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class FleetList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fleetList: PropTypes.array.isRequired,
    handleSort: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    removeFleet: PropTypes.func.isRequired,
    openRemoveDialog: PropTypes.func.isRequired,
    closeRemoveDialog: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    columnToSort: PropTypes.string.isRequired,
    sortAsc: PropTypes.bool.isRequired,
    removeDialogOpen: PropTypes.bool,
  };

  state = {
    searchSettings: {
      columnToSort: _get(this.props, 'columnToSort', ''),
      sortAsc: _get(this.props, 'sortAsc', ''),
      search: _get(this.props, 'search', ''),
    },
  };

  removeFleet = () => {
    const { removeFleet, closeRemoveDialog } = this.props;

    removeFleet();
    closeRemoveDialog();
  };

  renderAddFleet = (t) => {
    return (
      <Link to="/fleet/new">
        <Button color="primary">
          <Icon color="primary" className="mr-3">
            playlist_add
          </Icon>
          {t('fleet:addFleetButton')}
        </Button>
      </Link>
    );
  };

  render() {
    const {
      t,
      fleetList,
      removeDialogOpen,
      search,
      columnToSort,
      sortAsc,
      closeRemoveDialog,
      handleSearch,
      handleSort,
    } = this.props;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center fleet">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">train</Icon>
              <h1>{t('fleet:name')}</h1>
            </span>
            <p>{t('fleet:description')}</p>
          </header>
        </div>
        <div className="col-auto add-fleet">
          <Link to="/fleet/analysis">
            <Button color="primary">Analysis</Button>
          </Link>
          {hasPermission(PERMISSIONS.createFleets) && this.renderAddFleet(t)}
        </div>
        <div className="col-12">
          <Table
            data={fleetList}
            header={TABLE_HEADER}
            handleSort={handleSort}
            sortAsc={sortAsc}
            columnToSort={columnToSort}
            handleSearch={handleSearch}
            search={search}
            searchLabel={t('input:searchLabel')}
          />
          <Dialog
            open={removeDialogOpen}
            onClose={closeRemoveDialog}
            aria-labelledby="remove-dialog"
            fullWidth
          >
            <RemoveDialog
              message={t('fleet:removeFleet')}
              closeRemoveDialog={closeRemoveDialog}
              dialogTitle={t('fleet:removeFleetDialogTitle')}
              removeFunction={this.removeFleet}
            />
          </Dialog>
        </div>
      </div>
    );
  }
}
