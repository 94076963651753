import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import EditFileDialog from './EditFileDialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { PERMISSIONS } from '../../../constants/permissions';
import PropTypes from 'prop-types';
import ReadStatusDialog from './ReadStatusDialog';
import RemoveDialog from '../../../common/components/RemoveDialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { fileMarkAsRead } from '../../actions/fileUpload';
import { reformatPickerDateFromDB } from '../../utils/time';
import { renderPermission } from '../../decorators/permissions';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  badge: {
    marginLeft: '4px',
  },
});

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {
    fileMarkAsRead: (file) => dispatch(fileMarkAsRead(file)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class LibraryFileList extends Component {
  static propTypes = {
    fileList: PropTypes.array.isRequired,
    folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    editFolderFile: PropTypes.func.isRequired,
    removeFolderFile: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    removeDialogOpen: false,
    editDialogOpen: false,
    file: null,
    folderId: '',
  };

  openReadStatusDialog = (file) => {
    this.setState({
      readStatusDialogOpen: true,
      file,
    });
  };

  closeReadStatusDialog = () => {
    this.setState({
      readStatusDialogOpen: false,
      file: null,
    });
  };

  openRemoveDialog = (file) => {
    this.setState({
      removeDialogOpen: true,
      file,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      file: null,
    });
  };

  openEditDialog = (file) => {
    this.setState({
      editDialogOpen: true,
      file,
    });
  };

  closeEditDialog = () => {
    this.setState({
      editDialogOpen: false,
      file: null,
    });
  };

  editFile = (file) => {
    const { folderId } = this.props;

    this.closeEditDialog();
    this.props.editFolderFile(file, folderId);
  };

  removeFile = () => {
    const { folderId } = this.props;
    const { file } = this.state;

    this.closeRemoveDialog();
    this.props.removeFolderFile(file.id, folderId);
  };

  markAsRead = (file) => {
    this.props.fileMarkAsRead(file);
  };

  @renderPermission(PERMISSIONS.deleteFolders)
  renderRemoveDialog(t) {
    return (
      <Dialog
        open={this.state.removeDialogOpen}
        onClose={this.closeRemoveDialog}
        aria-labelledby="remove-dialog"
        fullWidth
      >
        <RemoveDialog
          dialogTitle={t('folder:removeFileDialogTitle')}
          message={t('folder:removeFile')}
          closeRemoveDialog={this.closeRemoveDialog}
          removeFunction={this.removeFile}
        />
      </Dialog>
    );
  }

  @renderPermission(PERMISSIONS.updateFolders)
  renderEditDialog() {
    const { file, editDialogOpen } = this.state;

    if (!editDialogOpen) {
      return null;
    }

    return (
      <EditFileDialog
        closeEditDialog={this.closeEditDialog}
        editFunction={this.editFile}
        file={file}
        editDialogOpenState={editDialogOpen}
      />
    );
  }

  @renderPermission(PERMISSIONS.updateFolders)
  renderReadStatus(file) {
    return !_isEmpty(file.readStatus) ? (
      <IconButton onClick={() => this.openReadStatusDialog(file)}>
        <Icon color="primary">info</Icon>
      </IconButton>
    ) : null;
  }

  @renderPermission(PERMISSIONS.updateFolders)
  renderReadStatusDialog() {
    const { file, readStatusDialogOpen } = this.state;

    if (!readStatusDialogOpen) {
      return null;
    }

    return (
      <ReadStatusDialog
        file={file}
        onCloseDialog={this.closeReadStatusDialog}
        open={readStatusDialogOpen}
      />
    );
  }

  @renderPermission(PERMISSIONS.deleteFolders)
  renderRemoveFileButton(file) {
    return (
      <IconButton onClick={() => this.openRemoveDialog(file)}>
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  @renderPermission(PERMISSIONS.updateFolders)
  renderEditFileButton(file) {
    return (
      <IconButton onClick={() => this.openEditDialog(file)}>
        <Icon color="primary">mode_edit</Icon>
      </IconButton>
    );
  }

  renderFileList = (renderFileList) => {
    const { t, classes } = this.props;

    return renderFileList.map((file) => {
      return (
        <TableRow key={`file-${file.id}`}>
          <TableCell>
            <a
              target="_blank"
              href={file.path}
              onClick={() => {
                this.markAsRead(file);
              }}
              className="folder-pannel-title"
            >
              <Icon color="primary" className="mr-1">
                insert_drive_file
              </Icon>
              {file.name}
              {file.hasRead ? (
                ''
              ) : (
                <span class={classes.badge + ' badge bg-success text-light'}>
                  {t(`folder:unread`)}
                </span>
              )}
            </a>
          </TableCell>
          <TableCell>
            {reformatPickerDateFromDB(file.createdAt, false)}
          </TableCell>
          <TableCell numeric>
            {this.renderReadStatus(file)}
            {this.renderEditFileButton(file)}
            {this.renderRemoveFileButton(file)}
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    const { classes, fileList, t } = this.props;

    if (_isEmpty(fileList)) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:name')}</TableCell>
              <TableCell>{t('table:date')}</TableCell>
              <TableCell numeric>{t('table:icons')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderFileList(fileList)}</TableBody>
        </Table>
        {this.renderRemoveDialog(t)}
        {this.renderEditDialog(t)}
        {this.renderReadStatusDialog()}
      </div>
    );
  }
}
