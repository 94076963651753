import React, { Component } from 'react';
import {
  editAssignedCourse,
  fetchAssignedCourses,
  removeAssignedCourse,
} from '../../../actions/assignedCourses';

import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import RemoveDialog from '../../../../common/components/RemoveDialog';
import TabsView from './TabsView';
import _isNil from 'lodash/isNil';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    assignedCourseList: state.assignedCourses.assignedCourseList,
    assignedCoursesRequest: state.assignedCourses.assignedCoursesRequest,
    assignedCoursesPaginationNext:
      state.assignedCourses.assignedCoursesPaginationNext,
    assignedCoursesPaginationRequest:
      state.assignedCourses.assignedCoursesPaginationRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAssignedCourses: (searchData) =>
      dispatch(fetchAssignedCourses(searchData)),
    fetchNextAssignedCourses: (paginationNext) =>
      dispatch(fetchAssignedCourses(null, paginationNext)),
    editAssignedCourse: (id, courseData) =>
      dispatch(editAssignedCourse(id, courseData)),
    removeAssignedCourse: (course) => dispatch(removeAssignedCourse(course)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class CoursesList extends Component {
  static propTypes = {
    userId: PropTypes.number,
    isUserProfile: PropTypes.bool,
    fetchAssignedCourses: PropTypes.func.isRequired,
    fetchNextAssignedCourses: PropTypes.func.isRequired,
    assignedCoursesRequest: PropTypes.bool.isRequired,
    assignedCoursesPaginationNext: PropTypes.string,
    assignedCoursesPaginationRequest: PropTypes.bool.isRequired,
    assignedCourseList: PropTypes.array,
    editAssignedCourse: PropTypes.func.isRequired,
    removeAssignedCourse: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: '',
      userId: '',
    },
    removeDialogOpen: false,
    removeCourse: null,
  };

  componentDidMount() {
    const { columnToSort, sortAsc, search } = this.state.searchSettings;
    const { userId } = this.props;

    if (userId) {
      const searchData = {
        columnToSort,
        sortAsc,
        search,
        userId,
      };

      this.updateSearchData(searchData);
      this.props.fetchAssignedCourses(searchData);

      return;
    }

    this.props.fetchAssignedCourses();
  }

  updateSearchData = (searchData) => {
    this.setState({ searchSettings: searchData });
  };

  handleSearch = (search) => {
    const { columnToSort, sortAsc, userId } = this.state.searchSettings;

    const searchData = {
      columnToSort,
      sortAsc,
      search,
      userId,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchAssignedCourses(searchData);
  };

  handleSort = (columnName) => {
    const { search, sortAsc, userId } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
      userId,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchAssignedCourses(searchData);
  };

  handleActivateCourse = (courseId) => {
    this.props.editAssignedCourse(courseId, { isActive: true });
  };

  renderTabsView(t) {
    const { search, sortAsc, columnToSort } = this.state.searchSettings;
    const {
      assignedCourseList,
      assignedCoursesPaginationNext,
      assignedCoursesPaginationRequest,
    } = this.props;

    return (
      <div>
        <TabsView
          assignedCourseList={assignedCourseList}
          handleSort={this.handleSort}
          handleSearch={this.handleSearch}
          sortAsc={sortAsc}
          columnToSort={columnToSort}
          search={search}
          handleActivateCourse={this.handleActivateCourse}
          openRemoveDialog={this.openRemoveDialog}
        />
        <InfiniteScroll
          loadMore={this.onFetchNextAssignedCourses}
          hasMore={
            !assignedCoursesPaginationRequest && assignedCoursesPaginationNext
          }
          loader={<></>}
        />
        <Dialog
          open={this.state.removeDialogOpen}
          onClose={this.closeRemoveDialog}
          aria-labelledby="remove-dialog"
          fullWidth
        >
          <RemoveDialog
            message={t('course:removeCourse')}
            closeRemoveDialog={this.closeRemoveDialog}
            dialogTitle={t('course:removeCourseDialogTitle')}
            removeFunction={this.removeCourse}
          />
        </Dialog>
      </div>
    );
  }

  removeCourse = () => {
    this.props.removeAssignedCourse(this.state.removeCourse);
    this.closeRemoveDialog();
  };

  openRemoveDialog = (course) => {
    this.setState({
      removeDialogOpen: true,
      removeCourse: course,
    });
  };

  onFetchNextAssignedCourses = () => {
    const { assignedCoursesPaginationNext, fetchNextAssignedCourses } =
      this.props;

    fetchNextAssignedCourses(assignedCoursesPaginationNext);
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeCourse: null,
    });
  };

  renderHeader = (t) => {
    const { isUserProfile } = this.props;

    if (!isUserProfile) {
      return (
        <header>
          <span>
            <Icon color="primary">content_paste</Icon>
            <h1>
              {t('course:name')}
              <span className="h1-subheader">
                {' '}
                /{t('course:courseListSection')}
              </span>
            </h1>
          </span>
          <p>{t('course:description')}</p>
        </header>
      );
    }

    return null;
  };

  render() {
    const { assignedCourseList, t } = this.props;

    if (_isNil(assignedCourseList)) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-lg-8 col-sm-12 section-title pb-5">
          {this.renderHeader(t)}
        </div>
        <div className="col-sm-12">{this.renderTabsView(t)}</div>
      </div>
    );
  }
}
