import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import QuestionList from './QuestionList';
import Loader from '../../../../common/components/Loader';
import { fetchAssignedTest, editAssignedTest } from '../../../actions/assignedTests';
import { calculateResult } from '../../../actions/completedTests';
import { renderPermission, hasPermission } from '../../../decorators/permissions';
import { PERMISSIONS } from '../../../../constants/permissions';
import { reformatPickerDateTimeToDB } from '../../../utils/time';

const mapStateToProps = (state) => {
  return {
    assignedTest: state.assignedTests.assignedTest,
    assignedTestRequest: state.assignedTests.assignedTestRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAssignedTest: (id) => dispatch(fetchAssignedTest(id)),
    editAssignedTest: (id, testData, goBack) => dispatch(editAssignedTest(id, testData, goBack)),
    calculateResult: (id, testData) => dispatch(calculateResult(id, testData)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class AssignedTestDetails extends Component {
  static propTypes = {
    assignedTest: PropTypes.object.isRequired,
    fetchAssignedTest: PropTypes.func.isRequired,
    editAssignedTest: PropTypes.func.isRequired,
    calculateResult: PropTypes.func.isRequired,
    assignedTestRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.object.isRequired,
  };

  state = {
    allAnswersGiven: false,
    answers: []
  };

  componentDidMount() {
    this.props.fetchAssignedTest(this.props.match.params.id);
  }

  completeTest = () => {
    const { answers } = this.state;
    const { assignedTest, match } = this.props;

    const testId = match.params.id;
    const newTestData = assignedTest.testData;
    const currentDate = reformatPickerDateTimeToDB();

    newTestData.questions.forEach((question) => {
      const userAnswer = answers.find((answer) => { return answer.questionId === question.id; });

      const updatedAnswers = question.answers.map((answer) => {
        return {
          ...answer,
          isAnswered: answer.id === Number(userAnswer.answerId),
        };
      });

      question.answers = updatedAnswers;
    });

    this.props.calculateResult(testId, { endDate: currentDate, testData: newTestData });
  };

  startTest = () => {
    const testId = this.props.match.params.id;

    this.props.editAssignedTest(testId, { startDate: reformatPickerDateTimeToDB() });
  };

  saveSelectedAnswer = (questionId, answerId) => {
    const { assignedTest } = this.props;
    const { answers } = this.state;

    const testData = _get(assignedTest, 'testData', {});
    const testQuestions = this.getTestQuestions(testData);

    _remove(answers, (userAnswer) => { return userAnswer.questionId === questionId;});

    const updatedAnswers = [
      ...answers,
      { questionId, answerId }
    ];

    this.setState({
      answers: [
        ...updatedAnswers
      ]
    });

    if (testQuestions.length === updatedAnswers.length) {
      this.setState({
        allAnswersGiven: true,
      });
    }
  };

  renderButtons = () => {
    const { startDate, endDate } = this.props.assignedTest;
    const { t } = this.props;
    const { allAnswersGiven } = this.state;
    let redirectionPath = '/my_exams';

    if (hasPermission(PERMISSIONS.examView)) {
      redirectionPath = '/exam_list';
    }

    if (!startDate) {
      return (
        <div className="row justify-content-center">
          <div className="col-auto">
            <Button variant="raised" color="primary" onClick={this.startTest}>{t('test:startTest')}</Button>
          </div>
        </div>
      );
    }

    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Link to={redirectionPath}>
            <Button variant="raised">{t('buttonBack')}</Button>
          </Link>
        </div>
        {!endDate && this.renderStartTestButton(allAnswersGiven, t)}
      </div>
    );
  };

  @renderPermission(PERMISSIONS.startTest)
  renderStartTestButton = (allAnswersGiven, t) => {
    return (
      <div className="col-auto">
        <Button disabled={!allAnswersGiven} color="primary" onClick={this.completeTest} variant="raised">{t('buttonComplete')}</Button>
      </div>
    );
  };

  renderQuestions = (testId, questionList) => {
    const { startDate, endDate } = this.props.assignedTest;

    if (!startDate || endDate) {
      return null;
    }

    return (
      <div className="col-12">
        <QuestionList
          questionList={questionList}
          testId={testId}
          saveSelectedAnswer={this.saveSelectedAnswer}
          completedTestPreviewMode={false}
        />
      </div>
    );
  };

  getTestQuestions = (testData) => {
    return testData.questions && testData.questions.map((question) => {
      return {
        ...question,
        answers: _sortBy(question.answers, ['order'])
      };
    });
  };

  render() {
    const { assignedTest, assignedTestRequest } = this.props;
    const testId = this.props.match.params.id;

    if (assignedTestRequest) {
      return <Loader />;
    }

    const testData = _get(assignedTest, 'testData', {});
    const testQuestions = this.getTestQuestions(testData);

    return (
      <div className="assigned-test-details row justify-content-lg-between justify-content-end align-items-center">
        <div className="col section-title">
        <header>
            <span>
              <Icon color="primary">
                list_add_check
              </Icon>
              <h1>{assignedTest.testData && assignedTest.testData.name}</h1>
            </span>
          </header>
        </div>
        {this.renderQuestions(testId, testQuestions)}
        <div className="col-12 my-3">
          {this.renderButtons()}
        </div>
      </div>
    );
  }
}
