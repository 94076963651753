import {
  FETCH_EQUIPMENTS_REQUEST,
  FETCH_EQUIPMENTS_SUCCESS,
  FETCH_EQUIPMENT_REQUEST,
  FETCH_EQUIPMENT_SUCCESS,
  ADD_EQUIPMENT,
  EDIT_EQUIPMENT,
  REMOVE_EQUIPMENT,
  CLEAN_EQUIPMENT_DATA
} from '../actions/equipment';

const INITIAL_STATE = {
  equipment: {},
  equipmentList: [],
  equipmentRequest: false,
};

function fetchEquipmentsSuccess(state, payload) {
  return {
    ...state,
    equipmentList: payload.equipments,
    equipmentRequest: false,
  };
}

function fetchEquipmentsRequest(state) {
  return {
    ...state,
    equipmentRequest: true,
  };
}

function fetchEquipmentSuccess(state, payload) {
  return {
    ...state,
    equipment: payload.equipment,
    equipmentRequest: false,
  };
}

function fetchEquipmentRequest(state) {
  return {
    ...state,
    equipmentRequest: true,
  };
}

function addEquipment(state, payload) {
  return {
    ...state,
    equipmentList: [
      ...state.equipmentList,
      payload.equipment
    ]
  };
}

function editEquipment(state, payload) {
  return {
    ...state,
    equipmentList: state.equipmentList.map((equipment) => {
      if (equipment.id === payload.equipment.id) {
        return payload.equipment;
      }

      return equipment;
    })
  };
}

function removeEquipment(state, payload) {
  return {
    ...state,
    equipmentList: state.equipmentList.filter((equipment) => equipment.id !== payload.equipmentId),
  };
}

function cleanEquipment(state) {
  return {
    ...state,
    equipment: null
  };
}

const actions = {
  [FETCH_EQUIPMENTS_SUCCESS]: fetchEquipmentsSuccess,
  [FETCH_EQUIPMENTS_REQUEST]: fetchEquipmentsRequest,
  [FETCH_EQUIPMENT_SUCCESS]: fetchEquipmentSuccess,
  [FETCH_EQUIPMENT_REQUEST]: fetchEquipmentRequest,
  [ADD_EQUIPMENT]: addEquipment,
  [EDIT_EQUIPMENT]: editEquipment,
  [REMOVE_EQUIPMENT]: removeEquipment,
  [CLEAN_EQUIPMENT_DATA]: cleanEquipment,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
