import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';
import _each from 'lodash/each';

import { withStyles } from '@material-ui/core/styles';

import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import MaterialInput from '../../common/components/forms/MaterialInput';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import validators from '../../utils/validators';

const styles = (theme) => ({
  root: {
    display: 'inline-flex',
    '-webkit-flex-direction': 'column',
    flexDirection: 'column',
    minWidth: 0,
    padding: 0,
    margin: 0,
    border: 0,
    verticalAlign: 'top',
    marginTop: 5,
    marginBottom: 4,
    width: '100%',
  },
  container: {
    position: 'relative',
    flexGrow: 1,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    maxHeight: 250,
    overflowY: 'auto',
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '8px 0'
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    marginRight: 30,
    width: 150,
  }
});

@withNamespaces()
@withStyles(styles)
export default class AutoSuggestion extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    typeList: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    preview: PropTypes.bool,
    fullWidth: PropTypes.bool,
    t: PropTypes.func.isRequired,
    validationRequired: PropTypes.bool.isRequired,
    registerComponentForm: PropTypes.func.isRequired,
    formValidated: PropTypes.func.isRequired,
    formName: PropTypes.string,
    validationError: PropTypes.func,
    inputValidated: PropTypes.func,
    registerInput: PropTypes.func,
    forceInitialValidation: PropTypes.func,
    key: PropTypes.object,
  };

  state = {
    suggestions: _get(this.props, 'typeList', '')
  };

  mapState() {
    const formChanges = {
      suggestions: this.state.suggestions,
    };

    return formChanges;
  }

  onChange = (formData) => {
    _each(formData, (value, key) => {
      this.setState({
        [key]: value.value
      });
    });
  };

  onFormValidated = (isFormValid) => {
    const { formName } = this.props;

    this.props.formValidated(`autoSuggestion${formName}`, isFormValid, this.mapState());
  };

  registerForm = (triggerFormValidation) => {
    this.props.registerComponentForm(triggerFormValidation);
  };

  renderInputComponent = (inputPros) => {
    const { t, validationRequired, preview, validationError, inputValidated, registerInput, forceInitialValidation, key } = this.props;
    const { classes, ref, ...other } = inputPros;

    if (validationRequired) {
      return (
        <MaterialInput
          fullWidth
          disabled={preview}
          name={other.label}
          label={other.label}
          type={other.type}
          defaultValue={other.value}
          className={classes.input}
          validationError={validationError}
          inputValidated={inputValidated}
          registerInput={registerInput}
          forceInitialValidation={forceInitialValidation}
          key={key}
          InputLabelProps={{
            shrink: true,
          }}
          validators={[
            new validators.IsRequired(t),
          ]}
        />
      );
    }

    return (
      <TextField
        onClick={this.handleAllSuggestionsFetchRequested}
        fullWidth
        InputProps={{
          inputRef: ref,
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    );
  };

  handleAllSuggestionsFetchRequested = () => {
    this.setState({
      suggestions: this.props.typeList,
    });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestions = (value) => {
    const { typeList } = this.props;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const maxNumberOfSuggestions = 5;
    let count = 0;

    return inputLength === 0
      ? []
      : typeList.filter((suggestion) => {
        const keep = count < maxNumberOfSuggestions && suggestion.name.toLowerCase().slice(0, inputLength) === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
  };

  getSuggestionValue = (suggestion) => {
    const { handleChange, label } = this.props;
    const newEvent = { target: { value: suggestion.name, } };

    handleChange(`${label}`, () => handleChange(`${label}`)(newEvent))(newEvent);

    return suggestion.name;
  };

  renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {suggestion.name}
        </div>
      </MenuItem>
    );
  };

  renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options;

    return (
      <Paper
        square
        {...containerProps}
      >
        {children}
      </Paper>
    );
  };

  renderAutosuggest = () => {
    const { classes, defaultValue, handleChange, label, preview, fullWidth, t } = this.props;
    const { suggestions } = this.state;
    const inputLabel = `input:${label}`;
    const className = fullWidth ? classes.root : classNames(classes.root, classes.textField);

    return (
      <div className={className}>
        <Autosuggest
          renderInputComponent={this.renderInputComponent}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionContainer={this.renderSuggestionsContainer}
          shouldRenderSuggestions={() => (true)}
          inputProps={{
            classes,
            placeholder: t(inputLabel),
            label: t(inputLabel),
            value: defaultValue,
            onChange: handleChange(`${label}`),
            disabled: preview,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classNames(classes.suggestionsContainerOpen),
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
        />
      </div>
    );
  }

  render() {
    // TODO put autosuggest into form.

    // const { validationRequired } = this.props;
    // if (validationRequired) {
    //   return (
    //     <Form
    //       onChange={this.onChange}
    //       onFormValidated={this.onFormValidated}
    //       registerForm={this.registerForm}
    //     >
    //       {this.renderAutosuggest()}
    //     </Form>
    //   );
    // }

    return this.renderAutosuggest();
  }
}
