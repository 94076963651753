import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import PracticalFieldList from './PracticalFieldList';
import Loader from '../../../../common/components/Loader';

import { fetchPracticalFields, editPracticalField } from '../../../actions/practicalFields';
import { fetchQuestionTypes } from '../../../actions/questionTypes';

const mapStateToProps = (state) => {
  return {
    practicalFieldList: state.practicalFields.practicalFieldList,
    practicalFieldRequest: state.practicalFields.practicalFieldRequest,
    questionTypeList: state.questionTypes.questionTypeList,
    questionTypeRequest: state.questionTypes.questionTypeRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchQuestionTypes: () => dispatch(fetchQuestionTypes()),
    fetchPracticalFields: () => dispatch(fetchPracticalFields()),
    editPracticalField: (practicalField) => dispatch(editPracticalField(practicalField)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class PracticalFieldsManager extends Component {
  static propTypes = {
    practicalFieldList: PropTypes.array,
    practicalFieldRequest: PropTypes.bool.isRequired,
    fetchPracticalFields: PropTypes.func.isRequired,
    editPracticalField: PropTypes.func.isRequired,
    questionTypeList: PropTypes.array,
    fetchQuestionTypes: PropTypes.func.isRequired,
    questionTypeRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchQuestionTypes();
    this.props.fetchPracticalFields();
  }

  editPracticalField = (practicalField) => {
    const newPracticalField = {
      id: practicalField.id,
      isActive: !practicalField.isActive,
    };

    this.props.editPracticalField(newPracticalField);
  }

  render() {
    const { practicalFieldList, practicalFieldRequest, questionTypeList, questionTypeRequest, t } = this.props;

    if (practicalFieldRequest || questionTypeRequest) {
      return <Loader />;
    }

    return (
      <div className="practical-fields-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:practicalCourseFieldsSection')}</h4>
        </div>
        <PracticalFieldList
          questionTypeList={questionTypeList}
          practicalFieldList={practicalFieldList}
          editPracticalField={this.editPracticalField}
        />
      </div>
    );
  }
}
