import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddFlightLog from '../LogbookNew/addFlightLog';
import Loader from '../../../../common/components/Loader';
import userService from '../../../../utils/userService';
import { fetchFlightLog } from '../../../actions/flightLog';
import { addSimpleError } from '../../../actions/errors';
import { fetchUserSettings } from '../../../actions/userSettings';

const mapStateToProps = (state) => {
  return {
    flightLog: state.flightLog.flightLog,
    flightLogsRequest: state.flightLog.flightLogsRequest,
    userSettings: state.userSettings.userSettings,
    userSettingsRequest: state.userSettings.userSettingsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchFlightLog: (id) => dispatch(fetchFlightLog(id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    fetchUserSettings: (userId) => dispatch(fetchUserSettings(userId)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class LogbookDetails extends Component {
  static propTypes = {
    flightLog: PropTypes.object,
    fetchFlightLog: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    flightLogsRequest: PropTypes.bool,
    fetchUserSettings: PropTypes.func,
    userSettingsRequest: PropTypes.bool,
    userSettings: PropTypes.object,
  };

  componentDidMount() {
    const { fetchUserSettings, fetchFlightLog } = this.props;
    const userId = userService.getCurrentUser().id;

    fetchUserSettings(userId);
    fetchFlightLog(this.props.match.params.id);
  }

  render() {
    const { flightLog, flightLogsRequest, userSettingsRequest, userSettings } = this.props;

    if (flightLogsRequest || !flightLog || userSettingsRequest) {
      return <Loader />;
    }

    return (
      <AddFlightLog
        flightLog={flightLog}
        userSettings={userSettings}
        previewMode
      />
    );
  }
}
