import axios from 'axios';
import { addError } from './errors';
import { URLS } from '../../constants';
import { urlJoin } from '../../utils';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export const FETCH_ELEARNINGS_REQUEST = 'FETCH_ELEARNINGS_REQUEST';
export const FETCH_ELEARNINGS_SUCCESS = 'FETCH_ELEARNINGS_SUCCESS';
export const FETCH_ELEARNINGS_FAILURE = 'FETCH_ELEARNINGS_FAILURE';

export const FETCH_ELEARNING_LINK_SUCCESS = 'FETCH_ELEARNING_LINK_SUCCESS';
export const FETCH_ELEARNING_LINK_REQUEST = 'FETCH_ELEARNING_LINK_REQUEST';
export const FETCH_ELEARNING_LINK_FAILURE = 'FETCH_ELEARNING_LINK_FAILURE';


export function fetchElearningsRequest() {
  return {
    type: FETCH_ELEARNINGS_REQUEST
  };
}

export function fetchElearningLinkRequest() {
  return {
    type: FETCH_ELEARNING_LINK_REQUEST
  };
}

export function fetchElearningsSuccess(eLearnings) {
  return {
    type: FETCH_ELEARNINGS_SUCCESS,
    payload: eLearnings
  };
}

export function fetchElearningLinkSuccess(eLearningLink) {
  return {
    type: FETCH_ELEARNING_LINK_SUCCESS,
    payload: eLearningLink
  };
}

export function fetchElearningsFailure() {
  return {
    type: FETCH_ELEARNINGS_FAILURE
  };
}

export function fetchElearningLinkFailure() {
  return {
    type: FETCH_ELEARNING_LINK_FAILURE
  };
}

export function fetchElearnings(userId) {
  return (dispatch) => {
    dispatch(fetchElearningsRequest());

    return axios.get(urlJoin(URLS.eLearning, userId))
      .then((results) => dispatch(fetchElearningsSuccess(_get(results, 'data.courses', []))))
      .catch((error) => {
        dispatch(addError(`Error during e-learning list fetching ${error.message}`, error));
        dispatch(fetchElearningsFailure());
      });
  };
}

export function fetchElearningLink(userId) {
  return (dispatch) => {
    dispatch(fetchElearningLinkRequest());

    return axios.get(urlJoin(URLS.eLearningLink, userId))
      .then((results) => {
        const loginUrl = _get(results, 'data.login_url', '');

        if (_isEmpty(loginUrl)) {
          dispatch(addError('Error during e-learning link fetching'));
          dispatch(fetchElearningLinkFailure());
        } else {
          window.open(loginUrl, '_blank');
          dispatch(fetchElearningLinkSuccess());
        }
      })
      .catch((error) => {
        dispatch(addError('Error during e-learning link fetching for this user', error));
        dispatch(fetchElearningLinkFailure());
      });
  };
}
