import axios from 'axios';
import { addError } from './errors';
import { URLS } from '../../constants';
export const FETCH_PILOT_FUNCTIONS_REQUEST = 'FETCH_PILOT_FUNCTIONS_REQUEST';
export const FETCH_PILOT_FUNCTIONS_SUCCESS = 'FETCH_PILOT_FUNCTIONS_SUCCESS';
export const FETCH_PILOT_FUNCTIONS_FAILURE = 'FETCH_PILOT_FUNCTIONS_FAILURE';


export function fetchPilotFunctionsRequest() {
  return {
    type: FETCH_PILOT_FUNCTIONS_REQUEST
  };
}

export function fetchPilotFunctionsSuccess(pilotFunctionList) {
  return {
    type: FETCH_PILOT_FUNCTIONS_SUCCESS,
    payload: {
      pilotFunctionList
    }
  };
}

export function fetchPilotFunctionsFailure() {
  return {
    type: FETCH_PILOT_FUNCTIONS_FAILURE
  };
}

export function fetchPilotFunctions() {
  return (dispatch) => {
    dispatch(fetchPilotFunctionsRequest());

    return axios.get(URLS.pilotFunction)
      .then(({ data }) => {
        dispatch(fetchPilotFunctionsSuccess(data.results));
      })
      .catch((error) => {
        dispatch(fetchPilotFunctionsFailure());
        dispatch(addError(`Error during pilot functions fetching ${error.message}`, error));
      });
  };
}
