import { defaultCrewStatusFilerMonthsValue, urlJoin } from '../../utils';
import {
  getDashboardQueryParametersFilter,
  prepareCrewStatusFilter,
} from '../utils/time';

import { URLS } from '../../constants';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { addError } from './errors';
import axios from 'axios';
import qs from 'qs';

export const ADD_ENGINEER_AIRCRAFT_TYPE_EXP = 'ADD_ENGINEER_AIRCRAFT_TYPE_EXP';
export const REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP =
  'REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP';
export const EDIT_ENGINEER_AIRCRAFT_TYPE_EXP =
  'EDIT_ENGINEER_AIRCRAFT_TYPE_EXP';
export const FETCH_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS =
  'FETCH_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS';
export const FETCH_ALL_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS =
  'FETCH_ALL_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS';
export const FETCH_ENGINEER_AIRCRAFT_TYPE_EXP =
  'FETCH_ENGINEER_AIRCRAFT_TYPE_EXP';

export function fetchEngineerAircraftTypeExperienceSuccess(experiences) {
  return {
    type: FETCH_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS,
    payload: {
      experiences,
    },
  };
}

export function fetchAllEngineerAircraftTypeExperienceSuccess(
  allEngineerAircraftTypeExperience,
) {
  return {
    type: FETCH_ALL_ENGINEER_AIRCRAFT_TYPE_EXP_SUCCESS,
    payload: {
      allEngineerAircraftTypeExperience,
    },
  };
}

export function fetchAllEngineerAircraftTypeExperienceRequest() {
  return {
    type: FETCH_ENGINEER_AIRCRAFT_TYPE_EXP,
  };
}

export function addEngineerAircraftTypeExperienceSuccess(experience) {
  return {
    type: ADD_ENGINEER_AIRCRAFT_TYPE_EXP,
    payload: {
      experience,
    },
  };
}

export function removeEngineerAircraftTypeExperienceSuccess(experience) {
  return {
    type: REMOVE_ENGINEER_AIRCRAFT_TYPE_EXP,
    payload: {
      experience,
    },
  };
}

export function editEngineerAircraftTypeExperienceSuccess(experience) {
  return {
    type: EDIT_ENGINEER_AIRCRAFT_TYPE_EXP,
    payload: {
      experience,
    },
  };
}

export function addEngineerAircraftTypeExperience(experience) {
  return (dispatch) => {
    return axios
      .post(URLS.engineerAircraftTypeExperience, experience)
      .then(({ data }) =>
        dispatch(addEngineerAircraftTypeExperienceSuccess(data)),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during experience adding ${error.message}`, error),
        );
      });
  };
}

export function removeEngineerAircraftTypeExperience(experience) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.engineerAircraftTypeExperience, experience.id))
      .then(() =>
        dispatch(removeEngineerAircraftTypeExperienceSuccess(experience)),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during experience removing ${error.message}`, error),
        );
      });
  };
}

export function editEngineerAircraftTypeExperience(experience) {
  return (dispatch) => {
    return axios
      .patch(
        urlJoin(URLS.engineerAircraftTypeExperience, experience.id),
        experience,
      )
      .then(({ data }) =>
        dispatch(editEngineerAircraftTypeExperienceSuccess(data)),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during experience editing ${error.message}`, error),
        );
      });
  };
}

export function deactivateEngineerAircraftTypeExperience(experience) {
  return (dispatch) => {
    return axios
      .patch(
        urlJoin(URLS.engineerAircraftTypeExperienceDeactivate, experience.id),
        experience,
      )
      .then(({ data }) =>
        dispatch(editEngineerAircraftTypeExperienceSuccess(data)),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during experience editing ${error.message}`, error),
        );
      });
  };
}

export function fetchEngineerAircraftTypeExperience(userId) {
  return (dispatch) => {
    const queryParameters = `?${getDashboardQueryParametersFilter(
      userId,
    )}&ordering=expiry_date`;

    return axios
      .get(urlJoin(URLS.engineerAircraftTypeExperience, queryParameters))
      .then(({ data }) =>
        dispatch(fetchEngineerAircraftTypeExperienceSuccess(data.results)),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during experiences fetching ${error.message}`, error),
        );
      });
  };
}

export function fetchAllEngineerAircraftTypeExperience(sortingData) {
  return (dispatch) => {
    dispatch(fetchAllEngineerAircraftTypeExperienceRequest());
    let crewStatusParameters = '';
    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData && !_isEmpty(sortingData.filter)) {
      const monthId = _get(
        sortingData.filter,
        'months[0].id',
        defaultCrewStatusFilerMonthsValue,
      );

      crewStatusParameters = prepareCrewStatusFilter(monthId);

      if (sortingData.filter.isVerified) {
        crewStatusParameters += '&is_verified=False';
      }
    }

    queryParameters = _isEmpty(queryParameters)
      ? `?${crewStatusParameters}`
      : `?${qs.stringify(queryParameters)}&${crewStatusParameters}`;

    return axios
      .get(urlJoin(URLS.engineerAllAircraftTypeExperience, queryParameters))
      .then(({ data }) =>
        dispatch(fetchAllEngineerAircraftTypeExperienceSuccess(data.results)),
      )
      .catch((error) => {
        dispatch(
          addError(`Error during experiences fetching ${error.message}`, error),
        );
      });
  };
}
