import axios from 'axios';
import _merge from 'lodash/merge';

import localStorage from './local-storage-facade';
import { isDataToSync } from './offline.js';

function isOffline() {
  return isDataToSync();
}

export default function decorate() {
  if (axios.__revolve_decorated) {
    return;
  }

  const originalAxiosGet = axios.get;
  const originalAxiosPatch = axios.patch;
  const originalAxiosPost = axios.post;

  axios.get = (...params) => {
    const [uri] = params;
    const offline = isOffline();
    const response = localStorage.getItem(uri);

    if (!response) {
      return originalAxiosGet.apply(axios, params);
    }

    if (offline) {
      return Promise.resolve(response && JSON.parse(response));
    }

    return originalAxiosGet.apply(axios, params);
  };

  axios.patch = (...params) => {
    const [uri] = params;
    const data = {
      data: params[1]
    };
    const offline = isOffline();
    const response = localStorage.getItem(uri);

    if (!response) {
      return originalAxiosPatch.apply(axios, params);
    }

    if (offline) {
      const newResponse = _merge({}, JSON.parse(response), data);

      window.localStorage.removeItem(uri);
      window.localStorage.setItem(uri, JSON.stringify(newResponse));

      return Promise.resolve(newResponse);
    }

    return originalAxiosPatch.apply(axios, params);
  };

  axios.post = (...params) => {
    const [uri] = params;
    const data = {
      data: params[1]
    };
    const offline = isOffline();
    const response = localStorage.getItem(uri);

    if (offline) {
      const newResponse = JSON.parse(response) || [];

      newResponse.push(data);

      window.localStorage.removeItem(uri);
      window.localStorage.setItem(uri, JSON.stringify(newResponse));

      return Promise.resolve(newResponse);
    }

    return originalAxiosPost.apply(axios, params);
  };

  // eslint-disable-next-line camelcase
  axios.__revolve_decorated = (new Date()).getTime();
}
