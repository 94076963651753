import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Menu from './Menu';

import { renderPermission } from '../../../decorators/permissions';
import { PERMISSIONS } from '../../../../constants/permissions';
import { renderModule } from '../../../decorators/modules';
import { MODULES } from '../../../../constants/modules';

@withNamespaces()
export default class EquipmentMenu extends Menu {
  renderHeader() {
    const { t } = this.props;

    return (
      <Link to="/equipment_list" className="nav-link-item" onClick={() => this.closeMenu()}>
        {t('navigation:equipmentMenu')}
      </Link>
    );
  }

  renderArrow() {
    return null;
  }

  @renderPermission(PERMISSIONS.viewAllEquipments)
  @renderModule(MODULES.equipment)
  render() {
    return this.renderMenu();
  }
}
