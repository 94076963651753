import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Menu from './Menu';

import { renderModule } from '../../../decorators/modules';
import { MODULES } from '../../../../constants/modules';

@withNamespaces()
export default class TrainingMenu extends Menu {
  renderHeader() {
    const { t } = this.props;

    return (
      <>
        {t('navigation:trainingMenu')}
      </>
    );
  }

  renderDropdownMenu() {
    const { t } = this.props;
    const { open } = this.state;
    const isOpen = open ? 'open' : '';

    return (
      <div className={`dropdown ${isOpen}`}>
        <Link to="/course_list" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:courseListLink')}
        </Link>
        <Link to="/course_admin" className="nav-link-item" onClick={() => this.closeMenu()}>
          {t('navigation:courseAdminLink')}
        </Link>
      </div>
    );
  }

  @renderModule(MODULES.training)
  render() {
    return this.renderMenu();
  }
}
