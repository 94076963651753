import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import LocationList from './LocationList';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import Loader from '../../../../common/components/Loader';
import Form from '../../../../common/components/forms/Form';

import { fetchAllLocations, fetchLocations, addLocation, removeLocation } from '../../../actions/locations';
import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  locationName: '',
};

const mapStateToProps = (state) => {
  return {
    allLocationList: state.locations.allLocationList.filter((location) => location.isActive && !location.user),
    locationList: state.locations.locationList.filter((location) => location.isActive),
    locationRequest: state.locations.locationRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAllLocations: () => dispatch(fetchAllLocations()),
    fetchLocations: (userId) => dispatch(fetchLocations(userId)),
    addLocation: (name) => dispatch(addLocation(name)),
    removeLocation: (name) => dispatch(removeLocation(name)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class LocationsManager extends Component {
  static propTypes = {
    userId: PropTypes.string,
    allLocationList: PropTypes.array,
    locationList: PropTypes.array,
    locationRequest: PropTypes.bool.isRequired,
    fetchAllLocations: PropTypes.func.isRequired,
    fetchLocations: PropTypes.func.isRequired,
    addLocation: PropTypes.func.isRequired,
    removeLocation: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { userId, fetchAllLocations, fetchLocations } = this.props;

    if (userId) {
      fetchLocations(userId);
    } else {
      fetchAllLocations();
    }
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleAddClose = () => {
    this.setState(INITIAL_STATE);
  };

  addLocation = () => {
    const { locationName } = this.state;
    const { userId } = this.props;
    const dataToSave = { name: locationName };

    if (userId) {
      dataToSave.user = userId;
    }

    this.props.addLocation(dataToSave);
    this.handleAddClose();
  };

  removeLocation = (location) => {
    this.props.removeLocation(location);
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.addLocation();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { userId, allLocationList, locationList, locationRequest, onElementDelete, t } = this.props;
    const { addOpen, locationName } = this.state;

    if (locationRequest) {
      return <Loader />;
    }

    return (
      <div className="locations-settings">
        <div className={userId ? 'pl-sm-3' : 'pl-sm-5'}>
          <h4> { userId ? t('settings:myLocations') : t('settings:locationsSection') } </h4>
        </div>
        <LocationList
          userId={userId}
          locationList={userId ? locationList : allLocationList}
          removeLocation={this.removeLocation}
          handleAddOpen={this.handleAddOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen}
            onClose={this.handleAddClose}
            aria-labelledby="add-location-dialog"
            fullWidth
          >
            <DialogTitle id="add-location-dialog">{t('settings:addLocationDialogTitle')}</DialogTitle>
            <DialogContent>
              <MaterialInput
                label={t('table:name')}
                autoFocus
                name="locationName"
                defaultValue={locationName}
                margin="dense"
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleAddClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
