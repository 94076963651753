import axios from 'axios';
import { URLS_RAIL as URLS } from '../../constants';
import { addError } from './errors';

export const FETCH_MODULES_REQUEST = 'FETCH_MODULES_REQUEST';
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS';

export function fetchModulesRequest() {
  return {
    type: FETCH_MODULES_REQUEST
  };
}

export function fetchModulesSuccess(modules) {
  return {
    type: FETCH_MODULES_SUCCESS,
    payload: {
      modules
    }
  };
}

export function fetchModules() {
  return (dispatch) => {
    dispatch(fetchModulesRequest());

    return axios.get(URLS.modules)
      .then(({ data }) => dispatch(fetchModulesSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during user fetching Modules: ${error.message}`, error));
      });
  };
}
