import React, { Component } from 'react';
import {
  addRiskAssessment,
  fetchRiskAssessments,
} from '../../../actions/riskAssessments';

import PropTypes from 'prop-types';
import RiskList from './RiskList';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  const { riskAssessmentList } = state.riskAssessments;

  return {
    riskAssessmentList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addRiskAssessment: (riskAssessment) =>
      dispatch(addRiskAssessment(riskAssessment)),
    fetchRiskAssessments: (filter) => dispatch(fetchRiskAssessments(filter)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class RisksManager extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    addRiskAssessment: PropTypes.func.isRequired,
    fetchRiskAssessments: PropTypes.func.isRequired,
    riskAssessmentList: PropTypes.array.isRequired,
    editMode: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { fleet, fetchRiskAssessments } = this.props;

    fetchRiskAssessments(`fleet_id=${fleet.id}`);
  }

  render() {
    const { t, riskAssessmentList } = this.props;

    return (
      <div>
        <h4>{t('fleet:Risk assessments')}</h4>
        <RiskList riskAssessmentList={riskAssessmentList} />
      </div>
    );
  }
}
