import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import { reformatPickerDateFromDB } from '../../../utils/time';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 72,
  },
  header: {
    background: 'linear-gradient(#6b33bf, #8B5EBF)',
  },
  iconColor: {
    color: '#6b33bf'
  }
});


@withNamespaces()
@withStyles(styles)
export default class DocumentsConfirmation extends Component {
  static propTypes = {
    ratings: PropTypes.array.isRequired,
    licenses: PropTypes.array.isRequired,
    medicals: PropTypes.array.isRequired,
    qualifications: PropTypes.array.isRequired,
    externalCourses: PropTypes.array.isRequired,
    addLicensePlannedAction: PropTypes.func.isRequired,
    addMedicalPlannedAction: PropTypes.func.isRequired,
    addExternalCoursePlannedAction: PropTypes.func.isRequired,
    addQualificationPlannedAction: PropTypes.func.isRequired,
    addRatingPlannedAction: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderIcons(user) {
    const { classes } = this.props;

    return (
      <div className="file-inputs">
        <Link className="table-row-link"
          key="user-profile-icon"
          to={{
            pathname: `/users/${user.id}`,
            state: { editMode: true }
          }}
        >
          <IconButton><Icon className={classes.iconColor}>person</Icon></IconButton>
        </Link>
      </div>
    );
  }

  renderFiles(element) {
    return element.files.map((file) => {
      return (
        <Chip
          key={`document-${element.document}-${element.id}-file-${file.id}`}
          label={file.name}
          onClick={()=> window.open(file.path, '_blank')}
          className="my-1"
        />
      );
    });
  }

  renderList(elements) {
    const { classes } = this.props;

    return elements.map((element) => {
      return (
        <TableRow key={`document-confirmation-${element.id}`}>
          <TableCell className={classNames(classes.longCell, 'pl-3')}>{element.type}</TableCell>
          <TableCell className={classes.longCell}>{element.document}</TableCell>
          <TableCell className={classes.longCell}>{`${element.user.firstName} ${element.user.lastName}`}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(element.expiryDate, false)}</TableCell>
          <TableCell numeric>
            {this.renderIcons(element.user)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderTable = () => {
    const { ratings, licenses, medicals, qualifications, externalCourses, classes,
      addLicensePlannedAction, addMedicalPlannedAction,
      addExternalCoursePlannedAction, addQualificationPlannedAction,
      addRatingPlannedAction, t
    } = this.props;


    return (
      <div className={classNames(classes.root, 'px-2')}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classNames(classes.header, 'pl-3')}>{t('table:type')}</TableCell>
              <TableCell className={classes.header}>{t('table:document')}</TableCell>
              <TableCell className={classes.header}>{t('table:userName')}</TableCell>
              <TableCell className={classes.header}>{t('table:expiryDate')}</TableCell>
              <TableCell className={classes.header} numeric>{t('table:icons')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderList(ratings.map((rating) => {
              return {
                ...rating,
                documentName: 'Rating',
                document: t('type', { context: 'lowercase' }),
                addPlannedAction: addRatingPlannedAction
              };
            }))}
            {this.renderList(licenses.map((license) => {
              return {
                ...license,
                documentName: 'License',
                document: t('license', { context: 'lowercase' }),
                addPlannedAction: addLicensePlannedAction
              };
            }))}
            {this.renderList(medicals.map((medical) => {
              return {
                ...medical,
                documentName: 'Medical',
                document: t('medical', { context: 'lowercase' }),
                addPlannedAction: addMedicalPlannedAction
              };
            }))}
            {this.renderList(qualifications.map((qualification) => {
              return {
                ...qualification,
                documentName: 'Qualification',
                document: t('qualification', { context: 'lowercase' }),
                addPlannedAction: addQualificationPlannedAction
              };
            }))}
            {this.renderList(externalCourses.map((externalCourse) => {
              return {
                ...externalCourse,
                documentName: 'ExternalCourse',
                document: t('externalCourse', { context: 'lowercase' }),
                addPlannedAction: addExternalCoursePlannedAction
              };
            }))}
          </TableBody>
        </Table>
      </div>
    );
  };


  checkData = () => {
    const { ratings, licenses, medicals, qualifications, externalCourses } = this.props;

    return [ratings, licenses, medicals, qualifications, externalCourses].some((x) => !_isEmpty(x));
  };

  render() {
    const { classes, t } = this.props;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <header className="header-dashboard">
          <span className={classNames('span-dashboard', 'py-3')}>
            <Icon className={classNames(classes.iconColor, 'notification-icon')}>
              folder_shared
            </Icon>
            <h4 className="h4-dashboard">{t('dashboard:notConfirmedUsersItems')}</h4>
          </span>
        </header>
        {this.checkData() && this.renderTable()}
      </Paper>
    );
  }
}
