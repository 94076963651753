import axios from 'axios';

import { addError } from './errors';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_LESSON_TYPES_REQUEST = 'FETCH_LESSON_TYPES_REQUEST';
export const FETCH_LESSON_TYPES_SUCCESS = 'FETCH_LESSON_TYPES_SUCCESS';
export const FETCH_LESSON_TYPES_FAILURE = 'FETCH_LESSON_TYPES_FAILURE';


export function fetchLessonTypesRequest() {
  return {
    type: FETCH_LESSON_TYPES_REQUEST
  };
}

export function fetchLessonTypesSuccess(lessonTypes) {
  return {
    type: FETCH_LESSON_TYPES_SUCCESS,
    payload: {
      lessonTypes
    }
  };
}

export function fetchLessonTypesFailure() {
  return {
    type: FETCH_LESSON_TYPES_FAILURE
  };
}

export function fetchLessonTypes() {
  return (dispatch) => {
    dispatch(fetchLessonTypesRequest());

    return axios.get(URLS.lessonTypes)
      .then(({ data }) => dispatch(fetchLessonTypesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchLessonTypesFailure());
        dispatch(addError(`Error during lesson types fetching ${error.message}`, error));
      });
  };
}
