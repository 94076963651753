import '../styles.scss';

import React, { Component } from 'react';
import {
  addEngineerAircraftTypeExperience,
  deactivateEngineerAircraftTypeExperience,
  editEngineerAircraftTypeExperience,
  removeEngineerAircraftTypeExperience,
} from '../../../actions/engineerAircraftTypeExperience';
import {
  attachEngineerAircraftTypeExperienceFile,
  removeEngineerAircraftExperienceFile,
} from '../../../actions/fileUpload';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import AutoSuggestion from '../../../../common/components/AutoSuggestion';
import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import UserExperiencesList from './UserExperiencesList';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';
import _set from 'lodash/set';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  typeId: '',
  expiryDate: null,
  dateOfIssue: moment(),
  id: null,
  value: null,
  isFormValid: false,
};

const mapStateToProps = (state) => {
  return {
    aircraftTypeExperienceList:
      state.engineerAircraftTypeExperience.aircraftTypeExperienceList,
    aircraftTypeList: state.engineerAircraftTypes.aircraftTypeList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addExperience: (experience) =>
      dispatch(addEngineerAircraftTypeExperience(experience)),
    editExperience: (experience) =>
      dispatch(editEngineerAircraftTypeExperience(experience)),
    removeExperience: (experience) =>
      dispatch(removeEngineerAircraftTypeExperience(experience)),
    deactivateExperience: (experience) =>
      dispatch(deactivateEngineerAircraftTypeExperience(experience)),
    attachExperienceFile: (file, id, userId) =>
      dispatch(attachEngineerAircraftTypeExperienceFile(file, id, userId)),
    removeExperienceFile: (fileId, id) =>
      dispatch(removeEngineerAircraftExperienceFile(fileId, id)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class UserExperiences extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    aircraftTypeList: PropTypes.array,
    aircraftTypeExperienceList: PropTypes.array,
    addExperience: PropTypes.func.isRequired,
    editExperience: PropTypes.func.isRequired,
    removeExperience: PropTypes.func.isRequired,
    deactivateExperience: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    attachExperienceFile: PropTypes.func.isRequired,
    removeExperienceFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    documentTypeList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  onChange = (formData) => {
    this.formData = formData;
    _each(formData, (value, key) => {
      this.setState({
        [key]: value.value,
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.state.addOpen ? this.addExperience() : this.editExperience();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (experience) => {
    this.setState({
      editOpen: true,
      id: experience.id,
      dateOfIssue: experience.dateOfIssue,
      expiryDate: experience.expiryDate,
      typeId: experience.type.id,
      value: experience.value,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addExperience = () => {
    const { typeId, dateOfIssue, expiryDate, value } = this.state;
    const { userId } = this.props;

    this.props.addExperience({
      typeId,
      dateOfIssue: reformatPickerDateToDB(dateOfIssue ? dateOfIssue : moment()),
      userId,
      expiryDate: reformatPickerDateToDB(expiryDate),
      value,
    });
    this.handleDialogClose();
  };

  editExperience = () => {
    const { typeId, id, dateOfIssue, expiryDate, value } = this.state;
    const { userId } = this.props;

    this.props.editExperience({
      id,
      typeId,
      value,
      dateOfIssue: reformatPickerDateToDB(dateOfIssue),
      userId,
      expiryDate: reformatPickerDateToDB(expiryDate),
    });
    this.handleDialogClose();
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  handleSelectChange = (name, callback) => (event) => {
    const newState = _cloneDeep(this.state);

    _set(newState, name, event.target.value);
    this.setState(newState, callback);
  };

  renderSelect = (inputName, inputLabel, value, changeFunction) => {
    const { documentTypeList } = this.props;

    return (
      <AutoSuggestion
        label={inputName}
        inputName={inputLabel}
        defaultValue={value ? value : ''}
        handleChange={changeFunction}
        typeList={documentTypeList}
        fullWidth
      />
    );
  };

  dialogContent = (classes, title, t) => {
    const { typeId, expiryDate, dateOfIssue, value } = this.state;
    const { aircraftTypeList } = this.props;

    const selectedAircraftType = aircraftTypeList.find(
      (aircraft) => String(aircraft.id) === typeId,
    ) || { info: ' ' };

    return (
      <div>
        <DialogTitle id="add-user-experience-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <MaterialSelect
                id="aircraftType"
                name="typeId"
                label={t('input:aircraft type')}
                fullWidth
                defaultValue={typeId}
                options={aircraftTypeList.map((aircraft) => ({
                  label: aircraft.name,
                  value: aircraft.id,
                }))}
                validators={[new validators.IsRequired(t)]}
              />
            </div>
            <div className="col-md-6 col-12">
              {selectedAircraftType.id ? (
                <TextField
                  fullWidth
                  margin="dense"
                  label={t('input:information')}
                  value={selectedAircraftType.info}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <MaterialInput
                type="number"
                margin="dense"
                name="value"
                fullWidth
                defaultValue={value}
                label={t('input:value')}
                validators={[new validators.IsRequired(t)]}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <DateTimePicker
                value={reformatPickerDateFromDB(dateOfIssue)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'dateOfIssue',
                )}
                dateFormat={getDateFormat()}
                name="dateOfIssue"
                label={t('input:startDate')}
                fullWidth
              />
            </div>
            <div className="col-md-6 col-12">
              <DateTimePicker
                acceptEmptyInput
                value={reformatPickerDateFromDB(expiryDate)}
                showMonthDropdown
                showTimeSelect={false}
                showYearDropdown
                handleDatePickerChange={this.handleDatePickerChange(
                  'expiryDate',
                )}
                dateFormat={getDateFormat()}
                name="expiryDate"
                label={t('input:endDate')}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
      </div>
    );
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  attachExperienceFile = (file) => (id) => {
    const { attachExperienceFile, userId } = this.props;

    attachExperienceFile(file, id, userId);
  };

  render() {
    const {
      aircraftTypeExperienceList,
      editMode,
      classes,
      removeExperienceFile,
      addSimpleError,
      onElementDelete,
      t,
      deactivateExperience,
      removeExperience,
    } = this.props;
    const { addOpen, editOpen } = this.state;

    return (
      <div className="col-12 user">
        <UserExperiencesList
          aircraftTypeExperienceList={aircraftTypeExperienceList}
          removeExperience={(experience) =>
            onElementDelete(() => removeExperience(experience))
          }
          handleAddOpen={this.handleAddOpen}
          editMode={editMode}
          handleEditOpen={this.handleEditOpen}
          attachExperienceFile={this.attachExperienceFile}
          removeExperienceFile={(fileId, id) =>
            onElementDelete(() => removeExperienceFile(fileId, id))
          }
          addSimpleError={addSimpleError}
          deactivateExperience={deactivateExperience}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={addOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="add-user-experience-dialog-title"
          >
            {this.dialogContent(classes, t('user:addUserExperience'), t)}
            {this.dialogActions(t)}
          </Dialog>
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={editOpen}
            onClose={this.handleDialogClose}
            fullWidth
            aria-labelledby="edit-engineer-aircraft-type-experience-dialog-title"
          >
            {this.dialogContent(classes, t('user:editUserExperience'), t)}
            {this.dialogActions(t)}
          </Dialog>
        </Form>
      </div>
    );
  }
}
