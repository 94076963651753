import React, { Component } from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LessonsManager from '../CourseNew/LessonDroppable';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  gradings: {
    minWidth: 180,
  },
});

@withNamespaces()
@withStyles(styles)
export default class SelectGrading extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    gradingList: PropTypes.array.isRequired,
    lesson: PropTypes.array.isRequired,
    gradings: PropTypes.object.isRequired,
    handleGradeChange: PropTypes.func.isRequired,
    settings: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    preview: PropTypes.bool,
  };

  renderMenuItems(gradeId, gradingList, t) {
    if (_isEmpty(gradingList)) {
      return null;
    }

    const gradings = gradingList.map((grading) => {
      const gradingType = `settings:${grading}`;

      return (
        <MenuItem key={`${gradeId}-${grading}`} value={grading}>
          {t(gradingType)}
        </MenuItem>
      );
    });

    gradings.unshift(
      <MenuItem key="grading-not-selected" value="">
        <em>{t('none')}</em>
      </MenuItem>,
    );

    return gradings;
  }

  getGradingOptions() {
    const { settings, gradingList } = this.props;

    const settingsGrade = _find(settings, { name: 'grade' });
    const grading = _find(gradingList, { id: settingsGrade.value });

    if (_isEmpty(gradingList)) {
      return null;
    }

    return grading.options;
  }

  render() {
    const { classes, t, gradingList, handleGradeChange, lesson, preview } =
      this.props;

    return lesson.grades ? (
      lesson.grades.map((grade) => {
        const { gradeId } = grade;

        const grading = _find(gradingList, { id: gradeId });

        const elemId = `grades-${gradeId}`;

        return (
          <FormControl className="ml-2 my-2">
            <InputLabel className={classes.gradings} htmlFor={elemId} shrink>
              {grading.name}
            </InputLabel>
            <Select
              disabled={preview}
              className={classes.gradings}
              value={grade.gradeValue}
              onChange={handleGradeChange(gradeId)}
              inputProps={{
                name: { elemId },
                id: { elemId },
              }}
            >
              {this.renderMenuItems(elemId, grading.options, t)}
            </Select>
          </FormControl>
        );
      })
    ) : (
      <></>
    );
  }
}
