import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CompetenciesList from './CompetenciesList';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import Loader from '../../../../common/components/Loader';
import Form from '../../../../common/components/forms/Form';

import { fetchCompetencies, addCompetence, removeCompetence } from '../../../actions/competencies';
import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  competenceName: '',
  competenceId: null
};

const mapStateToProps = (state) => {
  return {
    competenciesList: state.competencies.competenciesList.filter((competence) => competence.isActive),
    competenciesRequest: state.competencies.competenciesRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCompetencies: () => dispatch(fetchCompetencies()),
    addCompetence: (name) => dispatch(addCompetence(name)),
    removeCompetence: (name) => dispatch(removeCompetence(name)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class CompetenciesManager extends Component {
  static propTypes = {
    competenciesList: PropTypes.array,
    competenciesRequest: PropTypes.bool.isRequired,
    fetchCompetencies: PropTypes.func.isRequired,
    addCompetence: PropTypes.func.isRequired,
    removeCompetence: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.props.fetchCompetencies();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleAddClose = () => {
    this.setState(INITIAL_STATE);
  };

  addCompetence = () => {
    const { competenceName } = this.state;
    const dataToSave = { name: competenceName };

    this.props.addCompetence(dataToSave);
    this.handleAddClose();
  };

  removeCompetence = (competence) => {
    this.props.removeCompetence(competence);
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.addCompetence();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { competenciesList, competenciesRequest, onElementDelete, t } = this.props;
    const { addOpen, competenceName } = this.state;

    if (competenciesRequest) {
      return <Loader />;
    }

    return (
      <div className="competencies-settings">
        <div className={'pl-sm-5'}>
          <h4> {t('settings:competenciesSection') } </h4>
        </div>
        <CompetenciesList
          competenciesList={competenciesList}
          removeCompetence={this.removeCompetence}
          handleAddOpen={this.handleAddOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen}
            onClose={this.handleAddClose}
            aria-labelledby="add-competence-dialog"
            fullWidth
          >
            <DialogTitle id="add-competence-dialog">{t('settings:addCompetenceDialogTitle')}</DialogTitle>
            <DialogContent>
              <MaterialInput
                label={t('input:name')}
                autoFocus
                name="competenceName"
                defaultValue={competenceName}
                margin="dense"
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleAddClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
