import './styles.scss';

import React, { Component } from 'react';
import {
  getNextServiceDate,
  renderDateColor,
  getMinTickerToService,
  renderTickerToService,
  renderFleetColor,
  renderPartsNonSafetyRelatedColor,
} from '../Fleet/fleetHelper';

import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 80,
  },
  header: {
    background: 'linear-gradient(#C19915, #FFE76E)',
  },
  iconColor: {
    color: '#C19915',
  },
});

@withNamespaces()
@withStyles(styles)
export default class FleetList extends Component {
  static propTypes = {
    fleetList: PropTypes.array,
    classes: PropTypes.object.isRequired,
    settings: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    const found = _find(settings, { name: fieldName });

    if (!found) {
      return 0;
    }

    return found.value == parseInt(found.textValue)
      ? found.value
      : parseInt(found.textValue);
  }

  manageFleetList(fleetList) {
    let parsedFleetList = [];

    if (_isEmpty(fleetList)) {
      return parsedFleetList;
    }

    const fleetSettings = {
      hoursWarning: this.setDefaultValue('hours_warning'),
      distanceWarning: this.setDefaultValue('distance_warning'),
      operationalDaysWarning: this.setDefaultValue('operational_days_warning'),
    };

    parsedFleetList = fleetList.map((fleet) => {
      fleet.parts = fleet.installations.reduce((result, installation) => {
        if (installation.isActive) {
          result.push(installation.part);
        }

        return result;
      }, []);

      fleet.nextService = getNextServiceDate(
        fleet.inspections,
        fleet.parts,
      ).value;
      fleet.serviceMileage = getMinTickerToService(
        fleet.parts,
        fleet.inspections,
        'serviceMileage',
      ).value;

      return {
        id: fleet.id,
        name: fleet.vehicle.name,
        type: fleet.vehicle.type.name,
        evn: fleet.vehicle.evn,
        maintainer: fleet.maintainer,
        nextService: renderDateColor(fleet, 'nextService'),
        distanceToService: renderTickerToService(
          fleet,
          'serviceMileage',
          fleetSettings.distanceWarning,
          fleet.currentMileage,
        ),
        color: renderFleetColor(fleet, fleetSettings),
        colorNonSafetyRelated: renderPartsNonSafetyRelatedColor(
          fleet,
          fleetSettings,
        ),
      };
    });

    return parsedFleetList;
  }

  renderList() {
    const { fleetList, classes } = this.props;

    return this.manageFleetList(fleetList).map((fleet) => {
      return (
        <TableRow key={`fleet-list-${fleet.id}`}>
          <TableCell className={classes.longCell}>{fleet.color}</TableCell>
          <TableCell className={classes.longCell}>{fleet.name}</TableCell>
          <TableCell className={classes.longCell}>{fleet.type}</TableCell>
          <TableCell className={classes.longCell}>{fleet.evn}</TableCell>
          <TableCell className={classes.longCell}>
            {fleet.nextService}
          </TableCell>
          <TableCell className={classes.longCell}>
            {fleet.distanceToService}
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { fleetList, classes, t } = this.props;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <header className="header-dashboard">
          <span className={classNames('span-dashboard', 'py-3')}>
            <Icon
              className={classNames(classes.iconColor, 'notification-icon')}
            >
              train
            </Icon>
            <h4 className="h4-dashboard">{t('dashboard:fleet')}</h4>
            <Link to="/fleet">
              <Icon
                className={classNames(classes.iconColor, 'notification-icon')}
              >
                keyboard_arrow_right
              </Icon>
            </Link>
          </span>
        </header>
        <div className={classNames(classes.root, 'px-2')}>
          {!_isEmpty(fleetList) && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header}>
                    {t('table:color')}
                  </TableCell>
                  <TableCell className={classes.header}>
                    {t('table:name')}
                  </TableCell>
                  <TableCell className={classes.header}>
                    {t('table:type')}
                  </TableCell>
                  <TableCell className={classes.header}>
                    {t('table:evn')}
                  </TableCell>
                  <TableCell className={classes.header}>
                    {t('table:nextService')}
                  </TableCell>
                  <TableCell className={classes.header}>
                    {t('table:distanceToService')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderList()}</TableBody>
            </Table>
          )}
        </div>
      </Paper>
    );
  }
}
