import { createMuiTheme } from '@material-ui/core/styles';
import * as Colors from '@material-ui/core/colors';

// const black = '#000000';
const white = '#ffffff';

// const transparent = 'rgba(0, 0, 0, 0)';
// const darkBlack = 'rgba(0, 0, 0, 0.87)';
const lightBlack = 'rgba(0, 0, 0, 0.54)';
// const minBlack = 'rgba(0, 0, 0, 0.26)';
// const faintBlack = 'rgba(0, 0, 0, 0.12)';
// const darkWhite = 'rgba(255, 255, 255, 0.87)';
// const lightWhite = 'rgba(255, 255, 255, 0.54)';


export default function getTheme() {
  const overrides = {
    palette: {
      primary: {
        main: '#0D5AA7',
      },
      secondary: {
        main: Colors.red.A700,
      },
    },
    overrides: {
      action: {
        focus: {
          outline: 'none',
        }
      },
      MuiTable: {
        root: {
          verticalAlign: 'middle',
          padding: '0px 10px',
          overflow: 'hidden',

          '&:last-child': {
            paddingRight: '2px',
          },
        }
      },
      MuiTableCell: {
        root: {
          padding: '0px 10px',
        },
        head: {
          backgroundColor: '#0D5AA7',
          color: white,
          fontWeight: 500,
          fontSize: '14px',
        },
      },
      MuiTableRow: {
        head: {
          height: '34px',
        },
      },
      MuiTableSortLabel: {
        root: {
          color: white,
          '&:focus': {
            color: white,
          },
        },
        active: {
          color: white,
          '&:hover': {
            color: white,
          },
        }
      },
      MuiIconButton: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        }
      },
      MuiTab: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        }
      },
      MuiButton: {
        raisedPrimary: {
          color: white,
        },
        raisedSecondary: {
          color: white,
        },
        disabled: {
          color: white,
        }
      },
      MuiDivider: {
        root: {
          backgroundImage: 'linear-gradient(90deg, transparent, #707070, transparent)',
          margin: '0 10px',
        }
      },
      MuiIcon: {
        root: {
          color: lightBlack
        },
        colorSecondary: {
          color: Colors.amber.A400
        },
        colorAction: {
          color: Colors.green.A400
        }
      },
      MuiInput: {
        root: {
          minWidth: 120
        },
        underline: {
          '&$disabled:before': {
            borderBottom: 'none'
          },
        },
      },
      MuiChip: {
        root: {
          backgroundColor: 'rgba(66, 165, 245, 0.2)',
          '&:focus': {
            backgroundColor: 'rgba(66, 165, 245, 0.2)',
          },
        },
        deletable: {
          backgroundColor: 'rgba(66, 165, 245, 0.2)',
          '&:focus': {
            backgroundColor: 'rgba(66, 165, 245, 0.2)',
          },
        },
        deleteIcon: {
          color: '#0D5AA7',
          '&:focus': {
            color: '#0D5AA7',
          },
          '&:hover': {
            color: '#0D5AA7',
          },
        },
      },
      MuiExpansionPanel: {
        expanded: {
          backgroundColor: Colors.grey[100],
          paddingLeft: 20,
          borderLeft: '2px solid',
          borderColor: '#0D5AA7',
        }
      }
    }
  };

  return createMuiTheme(overrides);
}


