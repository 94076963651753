import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';

import { getVerifiedBy, isVerified } from '../PlannedAction/plannedActionHelper';
import { getActionIcons, renderFiles } from '../usersHelper';
import { reformatPickerDateFromDB } from '../../../utils/time';


const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 320,
  }
});

@withNamespaces()
@withStyles(styles)
export default class LicenseList extends Component {
  static propTypes = {
    licenseList: PropTypes.array,
    addLicensePlannedAction: PropTypes.func.isRequired,
    removeLicense: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    handleEditOpen: PropTypes.func.isRequired,
    attachLicenseFile: PropTypes.func.isRequired,
    removeLicenseFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    deactivateLicense: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderLicenseList() {
    const {
      licenseList, editMode, classes,
      handleEditOpen, attachLicenseFile, addSimpleError,
      addLicensePlannedAction, deactivateLicense,
      removeLicense, removeLicenseFile,
    } = this.props;
    const actions = {
      handleEditOpen,
      addSimpleError,
      deactivateItem: deactivateLicense,
      removeItem: removeLicense,
      attachItemFile: attachLicenseFile,
      addItemPlannedAction: addLicensePlannedAction,
      documentName: 'License',
    };
    const documentName = 'license';

    return licenseList.map((license) => {
      return (
        <TableRow key={`${documentName}-${license.id}`}>
          <TableCell className={classes.longCell}>{license.type}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(license.expiryDate, false)}</TableCell>
          <TableCell className={classes.longCell}>{license.number}</TableCell>
          <TableCell className={classes.longCell}>{license.stateOfIssue}</TableCell>
          <TableCell className={classes.longCell}>{isVerified(license.isVerified)}</TableCell>
          <TableCell className={classes.longCell}>{getVerifiedBy(license.verifiedBy)}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(license.verificationDate, false)}</TableCell>
          <TableCell className={classes.longCell}>{license.plannedAction}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(license.plannedActionDate, false)}</TableCell>
          <TableCell>{renderFiles(editMode, license, removeLicenseFile, documentName)}</TableCell>
          <TableCell className={classes.longCell}>{isVerified(license.isActive)}</TableCell>
          <TableCell numeric className={classes.iconsCell}>
            {getActionIcons(editMode, license, actions)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderAddButton(editMode, handleAddOpen, t) {
    if (editMode) {
      return (
        <Button className="my-2" color="primary" onClick={handleAddOpen}>{t('user:addLicenseButton')}</Button>
      );
    }

    return null;
  }

  render() {
    const { classes, handleAddOpen, editMode, licenseList, t } = this.props;

    if (_isEmpty(licenseList)) {
      return (
        <div>
          <h4>{t('user:licensesSection')}</h4>
          {this.renderAddButton(editMode, handleAddOpen, t)}
          <Divider className="my-3" />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <h4>{t('user:licensesSection')}</h4>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:type')}</TableCell>
              <TableCell>{t('table:expiryDate')}</TableCell>
              <TableCell>{t('table:number')}</TableCell>
              <TableCell>{t('table:stateOfIssue')}</TableCell>
              <TableCell>{t('table:isVerified')}</TableCell>
              <TableCell>{t('table:verifiedBy')}</TableCell>
              <TableCell>{t('table:verificationDate')}</TableCell>
              <TableCell>{t('table:plannedAction')}</TableCell>
              <TableCell>{t('table:plannedActionDate')}</TableCell>
              <TableCell>{t('table:attachments')}</TableCell>
              <TableCell>{t('table:isActive')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderLicenseList()}
          </TableBody>
        </Table>
        {this.renderAddButton(editMode, handleAddOpen, t)}
        <Divider className="my-3" />
      </div>
    );
  }
}
