import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import uuidv4 from 'uuid/v4';

import _get from 'lodash/get';

import EquipmentNew from '../../../../common/containers/Equipment/EquipmentNew';
import Loader from '../../../../common/components/Loader';
import { reformatPickerDateFromDB, reformatPickerDateToDB } from '../../../utils/time';
import { addEquipment, editEquipment, cleanEquipmentData } from '../../../actions/equipment';
import { addSimpleError } from '../../../actions/errors';

const mapStateToProps = (state) => {
  return {
    equipment: state.equipment.equipment,
    equipmentRequest: state.equipment.equipmentRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addEquipment: (equipment, files) => dispatch(addEquipment(equipment, files)),
    editEquipment: (id, equipment, updatedFiles, deletedFiles) => dispatch(editEquipment(id, equipment, updatedFiles, deletedFiles)),
    cleanEquipmentData: () => dispatch(cleanEquipmentData()),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossEquipmentNewView extends Component {
  static propTypes = {
    equipment: PropTypes.object,
    equipmentRequest: PropTypes.bool.isRequired,
    previewMode: PropTypes.bool.isRequired,
    editMode: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    cleanEquipmentData: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    getDateFormat: PropTypes.func,
    addEquipment: PropTypes.func,
    editEquipment: PropTypes.func,
  };

  state = {
    preparedEquipment: {
      id: _get(this.props.equipment, 'id', ''),
      name: _get(this.props.equipment, 'name', ''),
      type: _get(this.props.equipment, 'type', ''),
      location: _get(this.props.equipment, 'location', ''),
      certificate: _get(this.props.equipment, 'certificate', ''),
      issueDate: reformatPickerDateFromDB(_get(this.props.equipment, 'issueDate', moment())),
      expiryDate: reformatPickerDateFromDB(_get(this.props.equipment, 'expiryDate', moment())),
      files: _get(this.props.equipment, 'files', [])
    },
    updatedFiles: [],
    deletedFiles: []
  };

  componentWillUnmount() {
    this.props.cleanEquipmentData();
  }

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      preparedEquipment: { ...this.state.preparedEquipment, [name]: dateFromPicker },
    });
  };

  crossAddEquipment = (equipmentToAdd) => {
    const { files } = this.state.preparedEquipment;

    const preparedEquipment = {
      ...equipmentToAdd,
      issueDate: reformatPickerDateToDB(equipmentToAdd.issueDate),
      expiryDate: reformatPickerDateToDB(equipmentToAdd.expiryDate),
    };

    this.props.addEquipment(preparedEquipment, files);
  };

  crossEditEquipment = (equipmentId, equipmentToEdit) => {
    const { updatedFiles, deletedFiles } = this.state;

    const preparedEquipment = {
      ...equipmentToEdit,
      id: equipmentId,
      issueDate: reformatPickerDateToDB(equipmentToEdit.issueDate),
      expiryDate: reformatPickerDateToDB(equipmentToEdit.expiryDate),
    };

    this.props.editEquipment(preparedEquipment, updatedFiles, deletedFiles);
  };

  attachEquipmentFile = (file) => {
    const { files } = this.state.preparedEquipment;
    const { updatedFiles } = this.state;
    const { editMode } = this.props;

    file.id = uuidv4();

    if (editMode) {
      this.setState({
        ...this.state,
        preparedEquipment: {
          ...this.state.preparedEquipment,
          files: [
            ...files,
            file,
          ]
        },
        updatedFiles: [
          ...updatedFiles,
          file,
        ]
      });
    } else {
      this.setState({
        preparedEquipment: {
          ...this.state.preparedEquipment,
          files: [
            ...files,
            file,
          ]
        },
      });
    }
  };

  removeEquipmentFile = (file) => {
    const { files } = this.state.preparedEquipment;
    const { deletedFiles, updatedFiles } = this.state;
    const { editMode } = this.props;

    const fileId = file.id;
    const preparedFiles = files.filter((file) => file.id !== fileId);
    let preparedDeleteFiles = deletedFiles;
    let preparedUpdatedFiles = updatedFiles;
    const isServerFile = _get(file, 'path', false);

    if (editMode) {
      if (isServerFile) {
        preparedDeleteFiles = [
          ...deletedFiles,
          fileId,
        ];
      } else {
        preparedUpdatedFiles = updatedFiles.filter((file) => file.id !== fileId);
      }
    }

    this.setState({
      preparedEquipment: {
        ...this.state.preparedEquipment,
        files: preparedFiles,
      },
      deletedFiles: preparedDeleteFiles,
      updatedFiles: preparedUpdatedFiles
    });
  };

  render() {
    const { preparedEquipment } = this.state;
    const { equipmentRequest, editMode, previewMode, getDateFormat, addSimpleError } = this.props;

    if (equipmentRequest) {
      return <Loader />;
    }

    return (
      <EquipmentNew
        equipment={preparedEquipment}
        previewMode={previewMode}
        editMode={editMode}
        getDateFormat={getDateFormat}
        handleDatePickerChange={this.handleDatePickerChange}
        addEquipment={this.crossAddEquipment}
        editEquipment={this.crossEditEquipment}
        linkTo={'/equipment'}
        attachEquipmentFile={this.attachEquipmentFile}
        removeEquipmentFile={this.removeEquipmentFile}
        addSimpleError={addSimpleError}
      />
    );
  }
}
