import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { isDataToSync } from '../../utils/offline.js';

@withNamespaces()
export default class OfflineView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const { t } = this.props;
    const courseId = localStorage.getItem('courseId');
    const uri = `/course_list/details/${courseId}`;

    return (
      <div>
        {t('offlineMode')} <a href={uri}>{t('here')}</a>.
      </div>
    );
  }
}

export function isOffline(routePath) {
  const routeRegexp = /^\/course_list\/details/;

  routePath = routePath || '';

  return isDataToSync() && !routeRegexp.test(routePath);
}
