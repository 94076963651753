import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

import _get from 'lodash/get';

import Chip from '@material-ui/core/Chip';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';

import Table from '../../../../common/components/Table';
import Loader from '../../../../common/components/Loader';

const tableHeader = [
  {
    prop: 'check',
    sort: false,
    profileView: false,
    background: ''
  },
  {
    prop: 'name',
    sort: false,
    profileView: false,
    background: ''
  },
  {
    prop: 'competencies',
    sort: false,
    profileView: false
  }
];

@withNamespaces()
export default class SelectStaffCategory extends Component {
  static propTypes = {
    selectedStaffCategory: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    staffCategoriesList: PropTypes.array.isRequired,
    search: PropTypes.string.isRequired,
    staffCategoriesRequest: PropTypes.bool.isRequired,
    handleSearchInStaffCategories: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleFilter: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    search: '',
    selectedStaffCategory: _get(this.props, 'selectedStaffCategory', 1)
  };

  handleSearch = (search) => {
    this.props.handleSearchInStaffCategories(search);
  };

  renderCompetencies = (competencies) => {
    return competencies.map((competency) => {
      return (
        <Chip
          key={`staff-competency-${competency.name}-${competency.id}`}
          label={competency.name}
          className={'m-1'}
        />
      );
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
      selectedStaffCategory: event.target.value
    });
  };

  renderRadioButton = (staffCategoryId) => {
    const { selectedStaffCategory } = this.state;

    return (
      <Radio
        checked={staffCategoryId.toString() === selectedStaffCategory.toString()}
        color="primary"
        onChange={this.handleChange(`rbtn-${staffCategoryId}`)}
        value={staffCategoryId}
        name={`rbtn-${staffCategoryId}`}
      />
    );
  };

  manageData() {
    const { staffCategoriesList } = this.props;

    return staffCategoriesList && staffCategoriesList.map((staffCategory) => ({
      check: this.renderRadioButton(staffCategory.id),
      name: staffCategory.name,
      competencies: this.renderCompetencies(staffCategory.competencies)
    }));
  }

  closeDialog = () => {
    this.props.handleClick();
  };

  clearFilter = () => {
    this.props.clearFilter();
  };

  saveChanges = () => {
    const { selectedStaffCategory } = this.state;
    const { staffCategoriesList } = this.props;

    const competenciesIds = staffCategoriesList.reduce((results, staffCategory) => {

      if (staffCategory.id.toString() === selectedStaffCategory.toString()) {
        results = staffCategory.competencies.map((competence) => {

          return competence.id;
        });
      }

      return results;
    }, []);

    this.props.handleFilter(competenciesIds, selectedStaffCategory);
    this.props.handleClick();
  };

  renderButtons = () => {
    const { t } = this.props;

    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <Button variant="raised" onClick={this.closeDialog}>{t('buttonCancel')}</Button>
        </div>
        <div className="col-auto">
          <Button variant="raised" onClick={this.clearFilter}>{t('buttonClear')}</Button>
        </div>
        <div className="col-auto">
          <Button color="primary" variant="raised" onClick={this.saveChanges}>{t('buttonSave')}</Button>
        </div>
      </div>
    );
  };

  render() {
    const { search, staffCategoriesRequest, t } = this.props;

    if (staffCategoriesRequest) {
      return <Loader />;
    }

    return (
      <div className="col-12 my-4">
        <h4>{t('usersCategories:setStaffCategoriesFilter')}</h4>
        <div className="col-12 pb-3 mt-5 mb-5 px-0">
          <Table
            data={this.manageData()}
            header={tableHeader}
            handleSort={()=>{}}
            handleSearch={this.handleSearch}
            search={search}
          />
        </div>
        {this.renderButtons()}
      </div>
    );
  }
}
