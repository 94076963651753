import { checkExpiredDate, checkExpiryDate } from '../../utils/time';

import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { sortString } from '../../../utils/sort';

const CLASS_NAME_OK = 'ok-date';
const CLASS_NAME_YELLOW = 'expiry-date';
const CLASS_NAME_RED = 'expired-date';

export function getDateStatus(date) {
  let expiryClass = CLASS_NAME_OK;

  if (!date) {
    return expiryClass;
  }

  if (checkExpiredDate(date)) {
    expiryClass = CLASS_NAME_RED;
  } else if (checkExpiryDate(date, 1)) {
    expiryClass = CLASS_NAME_YELLOW;
  }

  return expiryClass;
}

export function getDocumentStatus(document) {
  let expiryClass = CLASS_NAME_OK;

  if (checkExpiredDate(document.expiryDate)) {
    expiryClass = CLASS_NAME_RED;
  } else if (checkExpiryDate(document.expiryDate, 1)) {
    expiryClass = CLASS_NAME_YELLOW;
  }

  return expiryClass;
}

export function getFleetStatus(fleet, settings) {
  const classes = new Set();

  fleet.parts
    .filter((p) => !p.nonSafetyRelated)
    .forEach((p) => classes.add(getPartStatus(p, fleet, settings)));

  fleet.inspections
    .filter((i) => !i.isCompleted)
    .forEach((i) => classes.add(getInspectionStatus(i, fleet, settings)));

  fleet.documents.forEach((d) => classes.add(getDocumentStatus(d)));

  let expiryClass = CLASS_NAME_OK;

  if (classes.has(CLASS_NAME_RED)) {
    expiryClass = CLASS_NAME_RED;
  } else if (classes.has(CLASS_NAME_YELLOW)) {
    expiryClass = CLASS_NAME_YELLOW;
  }

  return expiryClass;
}

export function getInspectionStatus(inspection, fleet, settings) {
  let expiryClass = CLASS_NAME_OK;

  if (inspection.isCompleted) {
    return expiryClass;
  }

  const classes = new Set();

  classes.add(
    getTickerClassName(
      Number(inspection.serviceHours),
      Number(fleet.currentHours),
      settings.hoursWarning,
    ),
  );

  classes.add(
    getTickerClassName(
      Number(inspection.serviceMileage),
      Number(fleet.currentMileage),
      settings.distanceWarning,
    ),
  );

  classes.add(
    getTickerClassName(
      Number(inspection.serviceOperationalDays),
      Number(fleet.operationalDays),
      settings.operationalDaysWarning,
    ),
  );

  if (!inspection.isCompleted) {
    classes.add(getDateStatus(inspection.serviceDate));
  }

  if (classes.has(CLASS_NAME_RED)) {
    expiryClass = CLASS_NAME_RED;
  } else if (classes.has(CLASS_NAME_YELLOW)) {
    expiryClass = CLASS_NAME_YELLOW;
  }

  return expiryClass;
}

export function getLastInspection(inspections) {
  const result = inspections
    .filter((inspection) => inspection.isCompleted)
    .map((inspection) => inspection.inspectionDate)
    .sort((a, b) => sortString(b, a));

  return result.length ? result[0] : '';
}

export function getMinTickerToService(parts, inspections, tickerName) {
  const result = [];

  parts
    .filter((part) => !part.nonSafetyRelated)
    .filter((part) => parseInt(part[tickerName]))
    .forEach((part) =>
      result.push({ part: part, value: Number(part[tickerName]) }),
    );

  inspections
    .filter((inspection) => !inspection.isCompleted)
    .filter((inspection) => parseInt(inspection[tickerName]))
    .forEach((inspection) =>
      result.push({
        inspection: inspection,
        value: Number(inspection[tickerName]),
      }),
    );

  result.sort((a, b) => a.value - b.value);

  return result.length ? result[0] : '';
}

export function getNextServiceDate(inspections, parts) {
  const result = [];

  parts
    .filter((part) => !part.nonSafetyRelated)
    .forEach((part) => {
      if (part.serviceDate) {
        result.push({ part: part, value: part.serviceDate });
      }

      if (part.eolDate) {
        result.push({ part: part, value: part.eolDate });
      }
    });

  inspections.forEach((inspection) => {
    if (!inspection.isCompleted && inspection.serviceDate) {
      result.push({ inspection: inspection, value: inspection.serviceDate });
    }
  });

  if (_isEmpty(result)) {
    return { value: '' };
  }

  result.sort((a, b) => sortString(a.value, b.value));

  return result[0];
}

export function getPartStatus(part, fleet, settings) {
  const classes = new Set();

  classes.add(
    getTickerClassName(
      part.serviceHours,
      fleet.currentHours,
      settings.hoursWarning,
    ),
  );
  classes.add(
    getTickerClassName(
      part.serviceMileage,
      fleet.currentMileage,
      settings.distanceWarning,
    ),
  );
  classes.add(
    getTickerClassName(
      part.eolMileage,
      fleet.currentMileage,
      settings.distanceWarning,
    ),
  );
  classes.add(getDateStatus(part.serviceDate));
  classes.add(getDateStatus(part.eolDate));

  let expiryClass = CLASS_NAME_OK;

  if (classes.has(CLASS_NAME_RED)) {
    expiryClass = CLASS_NAME_RED;
  } else if (classes.has(CLASS_NAME_YELLOW)) {
    expiryClass = CLASS_NAME_YELLOW;
  }

  return expiryClass;
}

export function getTickerClassName(value, refValue, warnValue) {
  let customClass = CLASS_NAME_OK;

  // If value is empty or not valid number set as OK
  if (!value || value <= 0) {
    return customClass;
  }

  const diff = value - refValue;

  if (value < refValue || diff <= 0) {
    customClass = CLASS_NAME_RED;
  } else if (diff <= warnValue) {
    customClass = CLASS_NAME_YELLOW;
  }

  return customClass;
}

export function renderDocumentColor(document) {
  const documentStatus = getDocumentStatus(document);

  return <div className={`status-circle ${documentStatus}`} />;
}

export function renderInspectionColor(inspection, fleet, fleetSettings) {
  const inspectionStatus = getInspectionStatus(
    inspection,
    fleet,
    fleetSettings,
  );

  return <div className={`status-circle ${inspectionStatus}`} />;
}

export function renderPartColor(part, fleet, fleetSettings) {
  const partStatus = getPartStatus(part, fleet, fleetSettings);

  if (part.nonSafetyRelated && partStatus === 'expired-date') {
    return <div className={'status-circle non-safety-related'} />;
  }

  return <div className={`status-circle ${partStatus}`} />;
}

export function renderPartNonSafetyRelatedColor(part, fleet, fleetSettings) {
  const partStatus = getPartStatus(part, fleet, fleetSettings);

  if (part.nonSafetyRelated && partStatus === 'expired-date') {
    return <div className={'status-circle non-safety-related'} />;
  }

  return <div />;
}

export function renderTickerToService(item, fieldName, warn, reference) {
  if (!item[fieldName]) {
    return null;
  }

  const ticker = Number(item[fieldName]);

  return (
    <div
      className={
        !item.isCompleted ? getTickerClassName(ticker, reference, warn) : ''
      }
    >
      {ticker - reference}
    </div>
  );
}

export function renderDateColor(item, fieldName) {
  if (!item[fieldName]) {
    return null;
  }

  const date = item[fieldName];

  if (item.isCompleted) {
    return <div>{date}</div>;
  }

  return <div className={getDateStatus(date)}>{date}</div>;
}

export function getFleetReportStatus(fleet) {
  let expiryClass = 'ok-date';

  const openReports = fleet.reports.filter((report) => {
    return !report.isClosed;
  });

  const openCriticalReports = openReports.filter((report) => {
    return report.nonSafetyRelated;
  });

  if (openCriticalReports.length > 0) {
    expiryClass = 'expired-date';
  } else if (openReports.length > 0) {
    expiryClass = 'expiry-date';
  }

  return expiryClass;
}

export function getFleetPartsNoNSafetyRelatedStatus(fleet, fleetSettings) {
  let expiryClass = 'ok-date';

  const partExpired =
    fleet.parts &&
    fleet.parts.some(
      (part) =>
        getPartStatus(part, fleet, fleetSettings) === 'expired-date' &&
        part.nonSafetyRelated,
    );

  if (partExpired) {
    expiryClass = 'expired-date';
  }

  return expiryClass;
}

export function renderFleetColor(fleet, fleetSettings) {
  const fleetStatus = getFleetStatus(fleet, fleetSettings);

  return <div className={`status-circle ${fleetStatus}`} />;
}

export function renderFleetReportColor(fleet) {
  const fleetReportStatus = getFleetReportStatus(fleet);

  return <div className={`status-circle ${fleetReportStatus}`} />;
}

export function renderPartsNonSafetyRelatedColor(fleet, fleetSettings) {
  const fleetStatus = getFleetPartsNoNSafetyRelatedStatus(fleet, fleetSettings);

  if (fleetStatus === 'ok-date') {
    return null;
  }

  return <div className={'status-circle non-safety-related'} />;
}
