import React, { Component } from 'react';

import LessonEdit from '../LessonEdit';

export default class CourseDetails extends Component {
  render() {
    return (
      <LessonEdit {...this.props} preview />
    );
  }
}
