import React, { Component } from 'react';
import { hasModule, renderModule } from '../../decorators/modules';

import EmergencyContacts from './UserProfile/EmergencyContacts';
import FlightExperience from './UserProfile/FlightExperience';
import Icon from '@material-ui/core/Icon';
import { MODULES } from '../../../constants/modules';
import { PERMISSIONS } from '../../../constants/permissions';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Settings from './UserProfile/Settings';
import Tab from '@material-ui/core/Tab';
import TabContainer from '../../../common/components/TabContainer';
import Tabs from '@material-ui/core/Tabs';
import { USER_TABS } from '../../../constants';
import UserProfileTabsView from './UserProfileTabsView';
import { componentPermission } from '../../decorators/permissions';
import { withNamespaces } from 'react-i18next';

const tabs = {
  [USER_TABS.account]: (userId, editMode, isMyProfile) => {
    return (
      <TabContainer>
        <UserProfileTabsView
          userId={userId}
          editMode={editMode}
          isMyProfile={isMyProfile}
        />
      </TabContainer>
    );
  },
  [USER_TABS.settings]: (userId) => {
    return (
      <TabContainer>
        <Settings userId={userId} />
      </TabContainer>
    );
  },
  [USER_TABS.flightExp]: (userId) => {
    return (
      <TabContainer>
        <FlightExperience userId={userId} />
      </TabContainer>
    );
  },
  [USER_TABS.emergencyContacts]: (userId, editMode, isMyProfile) => {
    return (
      <TabContainer>
        <EmergencyContacts
          userId={userId}
          editMode={editMode}
          isMyProfile={isMyProfile}
        />
      </TabContainer>
    );
  },
};

@withNamespaces()
@componentPermission(PERMISSIONS.myProfileView)
export default class UserTabsView extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    userId: PropTypes.string,
    isMyProfile: PropTypes.bool,
    editMode: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  state = {
    currentTab: USER_TABS.account,
  };

  handleChange = (event, value) => {
    this.setState({
      currentTab: value,
    });
  };

  isEditMode() {
    return !!this.props.location.state && !!this.props.location.state.editMode;
  }

  @renderModule(MODULES.logbook)
  renderLogbookTabs(t) {
    if (hasModule(MODULES.engineerMode)) {
      return [
        <Tab
          key={USER_TABS.settings}
          value={USER_TABS.settings}
          label={t('settings')}
        />,
        <Tab
          key={USER_TABS.emergencyContacts}
          value={USER_TABS.emergencyContacts}
          label={t('Emergency contacts')}
        />,
      ];
    }

    return [
      <Tab
        key={USER_TABS.settings}
        value={USER_TABS.settings}
        label={t('settings')}
      />,
      <Tab
        key={USER_TABS.flightExp}
        value={USER_TABS.flightExp}
        label={t('flightExperience')}
      />,
      <Tab
        key={USER_TABS.emergencyContacts}
        value={USER_TABS.emergencyContacts}
        label={t('Emergency contacts')}
      />,
    ];
  }

  render() {
    const { currentTab } = this.state;
    const { userId, editMode, t } = this.props;

    return (
      <div>
        <div className="row justify-content-lg-between align-items-center">
          <div className="col section-title pb-3">
            <header>
              <span>
                <Icon color="primary">person</Icon>
                <h1>{userId ? t('user:myProfile') : t('user:userProfile')}</h1>
              </span>
            </header>
          </div>
        </div>
        <Paper>
          <Tabs
            value={currentTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value={USER_TABS.account} label={t('account')} />
            {this.renderLogbookTabs(t)}
          </Tabs>
        </Paper>
        {userId
          ? tabs[currentTab](userId, editMode, true)
          : tabs[currentTab](
              this.props.match.params.id,
              this.isEditMode(),
              false,
            )}
      </div>
    );
  }
}
