import React, { Component } from 'react';

import CourseList from './CoursesList';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

@componentPermission(PERMISSIONS.assignedCourseView)
export default class QrosscheckCourseList extends Component {
  render() {
    return (
      <CourseList />
    );
  }
}


