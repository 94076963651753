import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import Table from '../../../../../common/components/Table';
import Loader from '../../../../../common/components/Loader';
import { checkExpiredDate, checkExpiryDate } from '../../../../utils/time';

const tableHeader = [
  {
    prop: 'color',
    sort: false,
    profileView: false,
    background: ''
  },
  {
    prop: 'name',
    sort: false,
    profileView: false,
    background: ''
  },
  {
    prop: 'competencies',
    sort: false,
    profileView: false
  }
];

const styles = ({
  competencyOk: {
    background: '#d2ffce'
  },
  competencyAboutExpiration: {
    background: '#ffedb8'
  },
  competencyExpired: {
    background: '#ffc9c9'
  },
});

@withNamespaces()
@withStyles(styles)
export default class StaffCategoriesList extends Component {
  static propTypes = {
    staffCategoriesList: PropTypes.array.isRequired,
    search: PropTypes.string.isRequired,
    userCompetenciesList: PropTypes.array.isRequired,
    staffCategoriesRequest: PropTypes.bool.isRequired,
    handleSearchInStaffCategories: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  findExpiryStatus = (statuses) => {
    return statuses.find((status) => {
      return status === 'expiry-date';
    });
  };

  findExpiredStatus = (statuses) => {
    return statuses.find((status) => {
      return status === 'expired-date';
    });
  };

  findCompetence = (category) => {
    const { userCompetenciesList } = this.props;

    return userCompetenciesList.find(({ competence }) => competence.id === category.id);
  };

  getBackgroundColor = (staffCategory) => {
    let expiryClass = 'ok-date';

    const statuses = staffCategory.map((category) => {
      const userCompetence = this.findCompetence(category);

      if (userCompetence) {
        const isExpired = checkExpiredDate(userCompetence.expiryDate);
        const expires = checkExpiryDate(userCompetence.expiryDate, 1);

        if (isExpired) {
          return 'expired-date';
        } else if (expires) {
          return 'expiry-date';
        }
      }

      return 'expired-date';
    });

    const isExpired = this.findExpiredStatus(statuses);
    const isExpiry = this.findExpiryStatus(statuses);

    if (isExpired) {
      expiryClass = 'expired-date';
    } else if (isExpiry) {
      expiryClass = 'expiry-date';
    }

    return <div className={`status-circle ${expiryClass}`} />;
  };

  handleSearch = (search) => {
    this.props.handleSearchInStaffCategories(search);
  };

  renderCompetencies = (competencies) => {
    const { classes } = this.props;

    return competencies.map((competency) => {
      let competenceBackgroundColor = classes.competencyOk;

      const foundUserCompetence = this.findCompetence(competency);

      if (foundUserCompetence) {
        const isExpired = checkExpiredDate(foundUserCompetence.expiryDate);
        const expires = checkExpiryDate(foundUserCompetence.expiryDate, 1);

        if (isExpired) {
          competenceBackgroundColor = classes.competencyExpired;
        } else if (expires) {
          competenceBackgroundColor = classes.competencyAboutExpiration;
        }
      } else {
        competenceBackgroundColor = classes.competencyExpired;
      }

      return (
        <Chip
          key={`staff-competency-${competency.name}-${competency.id}`}
          label={competency.name}
          className={`m-1 ${competenceBackgroundColor}`}
        />
      );
    });
  };

  manageData() {
    const { staffCategoriesList } = this.props;

    return staffCategoriesList && staffCategoriesList.map((staffCategory) => ({
      color: this.getBackgroundColor(staffCategory.competencies),
      name: staffCategory.name,
      competencies: this.renderCompetencies(staffCategory.competencies)
    }));
  }

  render() {
    const { search, staffCategoriesRequest } = this.props;

    if (staffCategoriesRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-12 section-title pb-3 mt-5 mb-5">
          <Table
            data={this.manageData()}
            header={tableHeader}
            handleSort={()=>{}}
            handleSearch={this.handleSearch}
            search={search}
          />
        </div>
      </div>
    );
  }
}
