import React from 'react';

export default function Footer() {
  return (
    <footer className="footer no-print pr-4">
      <div className="row justify-content-end">
        <p className="text">
                Qrosscheck © 2020 |
          <a className="text" target="_blank" rel="noopener noreferrer" href="https://www.qrosscheck.com/about"> about </a>
          |
          <a className="text" target="_blank" rel="noopener noreferrer" href="https://www.qrosscheck.com/help"> help </a>
        </p>
      </div>
    </footer>
  );
}
