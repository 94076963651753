import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileInput from '../../../common/components/upload/FileInput';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { addSimpleError } from '../../actions/errors';
import { attachFolderFile } from '../../actions/fileUpload';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

function mapDispatchToProps(dispatch) {
  return {
    addFileToFolder: (file, itemId, notify) =>
      dispatch(attachFolderFile(file, itemId, notify)),
  };
}

@withNamespaces()
@connect(() => {}, mapDispatchToProps)
export default class AddFileDialog extends Component {
  static propTypes = {
    folder: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    addFileToFolder: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    fileToUpload: null,
    fileName: '',
  };

  componentDidMount() {
    this.formData = {};
  }

  handleCheckboxChange = (event) => {
    this.setState({
      notifyUsers: event.target.checked,
    });
  };

  handleUploadedFile = (file) => {
    this.setState({
      fileToUpload: file,
      fileName: file.name,
    });
  };

  onSave = () => {
    const { addFileToFolder, folder, onCloseDialog } = this.props;
    const { fileToUpload, notifyUsers } = this.state;

    addFileToFolder(fileToUpload, folder.id, notifyUsers);
    onCloseDialog();
  };

  render() {
    const { onCloseDialog, open, t } = this.props;
    const { fileName, fileToUpload } = this.state;

    return (
      <Dialog
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="add-file-dialog"
        fullWidth
      >
        <DialogTitle id="add-file-dialog">
          {t('folder:addFileDialogTitle')}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <Button>
                <FileInput
                  attachFile={this.handleUploadedFile}
                  addSimpleError={addSimpleError}
                  stopPropagation
                  required
                />
                <Icon color="primary">attach_file</Icon>
                {' ' + t('folder:Upload file')}
              </Button>
            </div>
            <div className="col-12">
              <FormControl margin="dense">
                <FormControlLabel
                  control={<Checkbox onChange={this.handleCheckboxChange} />}
                  label={t('folder:notify users to read it')}
                />
              </FormControl>
            </div>
            {!fileName ? (
              ''
            ) : (
              <div className="col-12">
                {t('folder:Uploaded file')}:{fileName}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog} color="secondary">
            {t('buttonCancel')}
          </Button>
          <Button
            onClick={this.onSave}
            color="primary"
            disabled={!fileToUpload}
          >
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
