import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Form from '../../common/components/forms/Form';
import MaterialInput from '../../common/components/forms/MaterialInput';
import Loader from '../../common/components/Loader';
import Table from '../components/Table';
import { tableHeader, periods, defaultNumber, defaultPeriod } from './ExamsAndCoursesCompetencyManagerConfig';

import validators from '../../utils/validators';

const styles = () => ({
  textField: {
    width: 195,
  },
  numberLabelStyle: {
    fontSize: '0.75em'
  },
  selectInput: {
    width: 195,
  },
});

@withNamespaces()
@withStyles(styles)
export default class ExamsAndCoursesCompetencyManager extends Component {
  static propTypes = {
    competenciesList: PropTypes.array,
    competenciesRequest: PropTypes.bool.isRequired,
    saveCompetenciesList: PropTypes.func.isRequired,
    handleManageClose: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    viewLabel: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    competenciesList: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      searchSettings: {
        search: '',
      },
      number: defaultNumber,
      period: defaultPeriod,
      selectedCompetenciesList: this.props.competenciesList.filter((competency) => {
        return !(_isUndefined(competency.number) || _isUndefined(competency.period));
      }).map((competency) => {
        return competency.competency;
      }),
      editing: false,
      ...this.restoreSelectedCompetencies()
    };
  }

  restoreSelectedCompetencies = () => {
    let restoredCompetencies = {};

    this.props.competenciesList.filter((competency) => {
      return !(_isUndefined(competency.number) || _isUndefined(competency.period));
    }).map((competency) => {
      const competencyId = _get(competency, 'competency.id', '');
      const competencyName = _get(competency, 'competency.name', '');

      restoredCompetencies = {
        ...restoredCompetencies,
        [`${competencyName}number`]: _get(competency, 'number', defaultNumber),
        [`${competencyName}period`]: _get(competency, 'period', defaultPeriod),
        [`checkbox-${competencyId}`]: true,
      };

      restoredCompetencies;
    });

    return restoredCompetencies;
  };


  handleSearch = (search) => {
    const searchData = {
      search
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.handleSearch(searchData);
  };

  isChecked(name) {
    const { editMode } = this.props;

    if (editMode) {
      const checkFromState = this.state[name];

      if (_isUndefined(checkFromState)) {
        return false;
      }

      return checkFromState;
    }

    return !!this.state[name];
  }

  getCompetency = (competencyId) => {
    const { competenciesList } = this.props;

    return competenciesList.find((competency) => {

      return competencyId === this.getCompetencyId(competency);
    });
  };

  getCompetencyName = (competencyName) => {
    const { competenciesList } = this.props;

    return competenciesList.find((competency) => {

      return competencyName === _get(competency, 'competency.name', '');
    });
  };

  getCompetencyId = (competency) => {
    return _get(competency, 'id', _get(competency, 'competency.id', ''));
  };

  getCompetencyNumber = (competencyName) => {
    const foundCompetence = this.getCompetencyName(competencyName);

    return _get(this.state, `${competencyName}number`, _get(foundCompetence, 'number', defaultNumber));
  };

  getCompetencyPeriod = (competencyName) => {
    const { t } = this.props;
    const defaultPeriodTranslate = `test:${defaultPeriod}`;
    const foundCompetence = this.getCompetencyName(competencyName);

    return _get(this.state, `${competencyName}period`, _get(foundCompetence, 'period', t(defaultPeriodTranslate)));
  };

  handleCheckedChange = (name) => (event) => {
    const { selectedCompetenciesList } = this.state;
    let newCompetencies;

    const parsedId = parseInt(event.target.id, 10);
    const competency = this.getCompetency(parsedId);

    const isChoosen = !_isEmpty(selectedCompetenciesList) && selectedCompetenciesList.some((competency) => {
      const id = this.getCompetencyId(competency);

      return parsedId === id;
    });

    if (!isChoosen) {
      newCompetencies = [
        ...selectedCompetenciesList,
        competency
      ];
    } else {
      newCompetencies = selectedCompetenciesList.filter((selectedCompetence) => {
        const id = _get(selectedCompetence, 'id', _get(selectedCompetence, 'competency.id', ''));

        return id !== parsedId;
      });
    }

    this.setState({
      selectedCompetenciesList: newCompetencies,
      [name]: event.target.checked,
      editing: true,
    });
  };

  getCheckboxStatus = (checkboxId) => {
    const { competenciesList } = this.props;

    return competenciesList.some((competency) => {
      if (competency.competency) {
        return competency.competency.id === checkboxId;
      }
    });
  };

  renderCheckbox(competency) {
    const { editing } = this.state;

    let competencyId = '';
    let isChecked = false;

    if (editing) {
      competencyId = this.getCompetencyId(competency);
      isChecked = _get(this.state, `checkbox-${competencyId}`, this.getCheckboxStatus(competencyId));
    } else if (competency.id) {
      competencyId = competency.id;
      isChecked = this.isChecked(`checkbox-${competencyId}`);
    } else {
      competencyId = _get(competency, 'competency.id', '');
      isChecked = true;
    }

    return (
      <Checkbox
        color="primary"
        checked={isChecked}
        onChange={this.handleCheckedChange(`checkbox-${competencyId}`)}
        value={`checkbox-${competencyId}`}
        id={competencyId.toString()}
      />
    );
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      editing: true,
    });
  };

  menuItems(name, items) {
    const { t } = this.props;

    return items.map((item) => {
      let nameToDispaly = item;

      if (name === 'period') {
        const translation = `test:${item}`;

        nameToDispaly = t(translation);
      }

      return (
        <MenuItem key={`${name}-${item}`} value={item}>
          {t(nameToDispaly)}
        </MenuItem>
      );
    });
  }

  onChange = (formData, name) => {
    const value = formData[name].value;

    this.formData = formData;
    this.setState(() => ({ [name]: value }));
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.save();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  renderNumberSelect(competency) {
    const { classes, t } = this.props;
    const { editing } = this.state;

    let competencyName = '';
    let numberValue = '';

    if (editing) {
      competencyName = this.getCompetencyNameFromCompetency(competency);
      numberValue = _get(this.state, `${competencyName}number`, _get(competency, 'number', defaultNumber));
    } else if (competency.name) {
      competencyName = competency.name;
      numberValue = _get(this.state, `${competencyName}number`, defaultNumber);
    } else {
      competencyName = competency.competency.name;
      numberValue = competency.number;
    }

    return (
      <div className="col-xl-4 col-sm-6 col-xs-12 py-2 mr-4 pl-0">
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <div>
            <MaterialInput
              className={classes.textField}
              margin="dense"
              name={`${competencyName}number`}
              label={t('input:number')}
              fullWidth
              validators={[
                new validators.IsRequired(t),
                new validators.IsNaturalNumber(t),
              ]}
              defaultValue={numberValue}
            />
          </div>
        </Form>
      </div>
    );
  }

  getCompetencyNameFromCompetency = (competency) => {
    return _get(competency, 'name', _get(competency, 'competency.name', ''));
  };

  renderPeriodSelect(competency) {
    const { classes, t } = this.props;
    const { editing } = this.state;
    const defaultPeriodTranslate = `test:${defaultPeriod}`;

    let competencyName = '';
    let periodValue = '';

    if (editing) {
      competencyName = this.getCompetencyNameFromCompetency(competency);
      periodValue = _get(this.state, `${competencyName}period`, _get(competency, 'period', t(defaultPeriodTranslate)));
    } else if (competency.name) {
      competencyName = competency.name;
      periodValue = _get(this.state, `${competencyName}period`, t(defaultPeriodTranslate));
    } else {
      competencyName = competency.competency.name;
      periodValue = competency.period;
    }

    const periodValueTranslated = `test:${periodValue}`;

    return (
      <div className="col-xl-4 col-sm-6 col-xs-12 py-2 mr-4 pl-0 mt-1">
        <div>
          <InputLabel className={classes.numberLabelStyle} htmlFor="categories">{t('test:period')}</InputLabel>
        </div>
        <Select
          className={classes.selectInput}
          value={t(periodValueTranslated)}
          onChange={this.handleChange(`${competencyName}period`)}
          validators={[
            new validators.IsRequired(t),
          ]}
        >
          {this.menuItems('period', periods)}
        </Select>
      </div>
    );
  }

  getDataFromCompetenciesList(competenciesList) {
    const parsedCompetenciesList = competenciesList.map((competency) => {

      let preparedCompetence = competency.competency;

      if (_isUndefined(preparedCompetence)) {
        preparedCompetence = competency;
      }

      return ({
        ...preparedCompetence,
        check: this.renderCheckbox(competency),
        number: this.renderNumberSelect(competency),
        period: this.renderPeriodSelect(competency)
      });

    });

    return parsedCompetenciesList;
  }

  save = () => {
    const { selectedCompetenciesList } = this.state;
    const { handleManageClose, saveCompetenciesList } = this.props;

    handleManageClose();

    const preparedCompetenciesList = selectedCompetenciesList.map((selectedCompetency) => {
      const competencyName = selectedCompetency.name || _get(selectedCompetency, 'competency.name', '');
      const competency = selectedCompetency.competency || selectedCompetency;

      const selectedCompetencyNumber = parseInt(this.getCompetencyNumber(competencyName), 10);
      const selectedCompetencyPeriod = this.getCompetencyPeriod(competencyName);

      return {
        competency,
        number: selectedCompetencyNumber,
        period: selectedCompetencyPeriod,
      };
    });

    saveCompetenciesList(preparedCompetenciesList);
  };


  render() {
    const { handleManageClose, competenciesRequest, viewLabel, competenciesList, t } = this.props;
    const { searchSettings } = this.state;

    if (competenciesRequest) {
      return <Loader />;
    }

    return (
      <div className="col-12">
        <DialogTitle id="assign-course-or-exam">{viewLabel}</DialogTitle>
        <DialogContent>
          <Table
            data={this.getDataFromCompetenciesList(competenciesList)}
            header={tableHeader}
            handleSort={this.handleSearch}
            sortAsc={searchSettings.sortAsc}
            columnToSort={searchSettings.columnToSort}
            handleSearch={this.handleSearch}
            search={searchSettings.search}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleManageClose}>{t('buttonCancel')}</Button>
          <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
        </DialogActions>
      </div>
    );
  }
}
