import React, { Component } from 'react';
import {
  addVehicle,
  editVehicle,
  fetchVehicles,
  removeVehicle,
} from '../../../actions/vehicles';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import Loader from '../../../../common/components/Loader';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import VehicleList from './VehicleList';
import _each from 'lodash/each';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  id: '',
  name: '',
  type: '',
  evn: '',
  activeSince: null,
};

const mapStateToProps = (state) => {
  return {
    vehicleList: state.vehicles.vehicleList,
    vehicleRequest: state.vehicles.vehicleRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchVehicles: () => dispatch(fetchVehicles()),
    addVehicle: (data) => dispatch(addVehicle(data)),
    editVehicle: (data) => dispatch(editVehicle(data)),
    removeVehicle: (data) => dispatch(removeVehicle(data)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class VehiclesManager extends Component {
  static propTypes = {
    vehicleList: PropTypes.array.isRequired,
    vehicleRequest: PropTypes.bool.isRequired,
    fetchVehicles: PropTypes.func.isRequired,
    addVehicle: PropTypes.func.isRequired,
    editVehicle: PropTypes.func.isRequired,
    removeVehicle: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    vehicleTypeList: PropTypes.array.isRequired,
    vehicleGroupList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { fetchVehicles } = this.props;

    this.formData = {};
    fetchVehicles();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (vehicle) => {
    this.setState({
      editOpen: true,
      id: vehicle.id,
      name: vehicle.name,
      type: vehicle.type.id,
      group: _get(vehicle, 'group.id', ''),
      evn: vehicle.evn,
      activeSince: reformatPickerDateFromDB(_get(vehicle, 'activeSince', null)),
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value,
      });
    });
  };

  addVehicle = () => {
    const { name, type, group, evn, activeSince } = this.state;
    const data = {
      name,
      type,
      group,
      evn,
      activeSince,
    };

    this.props.addVehicle(data);
    this.handleDialogClose();
  };

  editVehicle = () => {
    const { id, name, type, group, evn, activeSince } = this.state;
    const data = {
      id,
      name,
      type,
      group,
      evn,
      activeSince: reformatPickerDateToDB(activeSince),
    };

    this.props.editVehicle(data);
    this.handleDialogClose();
  };

  handleDatePickerChange = (activeSince) => {
    this.setState({
      activeSince,
    });
  };

  onFormValidated = (isFormValid) => {
    const { addOpen } = this.state;

    if (isFormValid && this.formData) {
      addOpen ? this.addVehicle() : this.editVehicle();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const {
      vehicleList,
      vehicleRequest,
      onElementDelete,
      removeVehicle,
      vehicleTypeList,
      vehicleGroupList,
      t,
    } = this.props;
    const { addOpen, editOpen, name, type, group, evn, activeSince } =
      this.state;

    if (vehicleRequest) {
      return <Loader />;
    }

    return (
      <div className="vehicle-types-settings">
        <div className={'pl-sm-5'}>
          <h4>{t('settings:vehiclesSection')}</h4>
          <VehicleList
            vehicleList={vehicleList}
            removeVehicle={removeVehicle}
            handleAddOpen={this.handleAddOpen}
            handleEditOpen={this.handleEditOpen}
            onElementDelete={onElementDelete}
          />
        </div>
        <Dialog
          open={addOpen || editOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="add-vehicle-type-dialog"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle id="add-edit-vehicle-type-dialog">
              {addOpen
                ? t('settings:addVehicleDialogTitle')
                : t('settings:editVehicleDialogTitle')}
            </DialogTitle>
            <DialogContent style={{ height: '20em' }}>
              <div className="row">
                <div className="col-6">
                  <MaterialInput
                    label={t('input:name')}
                    autoFocus
                    name="name"
                    defaultValue={name}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                    ]}
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    fullWidth
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange}
                    dateFormat={getDateFormat()}
                    value={activeSince}
                    name="date"
                    acceptEmptyInput
                    label={t('input:active since')}
                  />
                </div>
                <div className="col-6">
                  <MaterialSelect
                    id="type"
                    name="type"
                    label={t('input:type')}
                    defaultValue={type}
                    options={vehicleTypeList.map((type) => ({
                      value: type.id,
                      label: type.name,
                    }))}
                    validators={[new validators.IsRequired(t)]}
                  />
                </div>
                <div className="col-6">
                  <MaterialSelect
                    id="group"
                    name="group"
                    label={t('input:group')}
                    defaultValue={group}
                    options={vehicleGroupList.map((group) => ({
                      value: group.id,
                      label: group.name,
                    }))}
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:evn')}
                    name="evn"
                    defaultValue={evn}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 20),
                    ]}
                    fullWidth
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </div>
    );
  }
}
