import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Form from '../../../../common/components/forms/Form';
import validators from '../../../../utils/validators';
import MaterialInput from '../../../../common/components/forms/MaterialInput';

const styles = () => ({
  root: {
    width: '100%'
  },
  selectInput: {
    width: 195,
  },
  categoryLabelStyle: {
    fontSize: '0.75em'
  }
});

@withNamespaces()
@withStyles(styles)
export default class CategoryDialog extends Component {
  static propTypes = {
    closeCategoryDialog: PropTypes.func.isRequired,
    questionCategoryList: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    saveCategoryAndQuestionNumber: PropTypes.func.isRequired,
    editedCategory: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    numberOfQuestions: _isEmpty(this.props.editedCategory) ? '' : this.props.editedCategory.numberOfQuestions,
    questionCategoryId: _isEmpty(this.props.editedCategory) ? '' : this.props.editedCategory.categoryId,
    isFormValid: false,
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  menuItems(categories) {
    return categories.map((category) => (
      <MenuItem key={`category-${category.id}`} value={category.id}>
        {category.name}
      </MenuItem>
    ));
  }

  onChange = (formData) => {
    this.formData = formData;
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  getCategoryName = () => {
    const { questionCategoryId } = this.state;
    const { questionCategoryList } = this.props;

    return _get(questionCategoryList.find((category) => category.id === questionCategoryId), 'name', '');
  };

  onFormValidated = (isFormValid) => {
    const { numberOfQuestions, questionCategoryId } = this.state;

    if (isFormValid && this.formData) {
      this.props.saveCategoryAndQuestionNumber(this.getCategoryName(), questionCategoryId, numberOfQuestions);
    }
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation; // this function can be used for full form validation
  };

  render() {
    const { closeCategoryDialog, questionCategoryList, classes, editedCategory, t } = this.props;
    const { numberOfQuestions, questionCategoryId } = this.state;

    return (
      <div>
        <Form
          onChange={this.onChange}
          validateForm={this.validateForm}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <DialogTitle id="assign-course">{t('test:selectQuestionsDialogTitle')}</DialogTitle>
          <DialogContent>
            <div className="row justify-content-start">
              <div className="col-xl-4 col-sm-6 col-xs-12 py-2 mr-4">
                <div>
                  <InputLabel className={classes.categoryLabelStyle} htmlFor="categories">{t('input:questionCategory')}</InputLabel>
                </div>
                <Select
                  disabled={!_isEmpty(editedCategory)}
                  className={classes.selectInput}
                  value={questionCategoryId}
                  onChange={this.handleChange('questionCategoryId')}
                  validators={[
                    new validators.IsRequired(t),
                  ]}
                >
                  {this.menuItems(questionCategoryList)}
                </Select>
              </div>
              <div className="col-xl-4 col-sm-6 col-xs-12 py-2">
                <MaterialInput
                  className={classes.root}
                  name="numberOfQuestions"
                  label={t('input:numberOfQuestions')}
                  validators={[
                    new validators.IsRequired(t),
                    new validators.IsNumber(t),
                  ]}
                  defaultValue={numberOfQuestions}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="row justify-content-end">
            <Button color="secondary" onClick={closeCategoryDialog}>{t('buttonCancel')}</Button>
            <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
          </DialogActions>
        </Form>
      </div>
    );
  }
}
