export const PERMISSIONS = {
  dashboardView: 'aviation_app.view_dashboard',
  coursesView: 'aviation_app.view_courses',
  examView: 'aviation_app.view_tests',
  expiryRatingsView: 'aviation_app.view_expiry_ratings',
  expiryLicensesView: 'aviation_app.view_expiry_licenses',
  expiryMedicalsView: 'aviation_app.view_expiry_medicals',
  expiryQualificationsView: 'aviation_app.view_expiry_qualifications',
  expiryExternalCoursesView: 'aviation_app.view_expiry_external_courses',
  myCoursesView: 'aviation_app.view_my_course',
  myExamsView: 'aviation_app.view_my_exams',
  myProfileView: 'aviation_app.view_my_profile',
  assignedCourseView: 'aviation_app.view_assigned_course',
  assignedExamView: 'aviation_app.view_assigned_tests',
  userListView: 'accounts.view_users',
  questionListView: 'aviation_app.view_questions',
  generalSettingsView: 'aviation_app.view_general_settings',
  companySettingsView: 'aviation_app.view_company_settings',
  generalNotificationView: 'aviation_app.view_notification_settings',
  userSettingsView: 'aviation_app.view_user_settings',
  reportsSettingsView: 'aviation_app.view_reports_settings',
  leftNavigationMenuView: 'accounts.view_left_navigation_menu',
  questionView: 'aviation_app.view_questions',
  userProfileView: 'aviation_app.view_user_profile',
  crewStatusView: 'accounts.view_crew_status',

  assignedCoursePreview: 'aviation_app.preview_assigned_course',
  assignedTestPreview: 'aviation_app.preview_assigned_test',

  courseCreate: 'aviation_app.create_courses',
  examCreate: 'aviation_app.create_tests',
  questionCreate: 'aviation_app.create_questions',
  addNewCourse: 'aviation_app.course_admin_add_course',
  addNewExam: 'aviation_app.test_admin_add_test',

  assignedCourseEdit: 'aviation_app.update_assigned_course',
  editCourseInCourseList: 'aviation_app.course_list_edit_course',
  assignedTestEdit: 'aviation_app.update_assigned_tests',
  lessonEdit: 'aviation_app.update_lessons',
  questionEdit: 'aviation_app.update_questions',
  examEdit: 'aviation_app.update_tests',
  courseEdit: 'aviation_app.update_courses',
  settingsEdit: 'aviation_app.update_settings',
  userEdit: 'accounts.user_edit',
  schoolGroupEdit: 'aviation_app.can_select_school_group',
  editCourse: 'aviation_app.course_admin_edit_course',
  editExam: 'aviation_app.test_admin_edit_test',

  assignedCourseDelete: 'aviation_app.delete_assigned_course',
  assignedTestDelete: 'aviation_app.delete_assigned_tests',
  userDelete: 'accounts.user_delete',
  deleteCourse: 'aviation_app.course_admin_delete_course',
  deleteExam: 'aviation_app.test_admin_delete_test',

  completeCourse: 'aviation_app.complete_course',
  startTest: 'aviation_app.start_test',
  authorizeCompletedLesson: 'aviation_app.authorize_completed_lesson',
  renderPendingItems: 'aviation_app.render_pending_items',

  createAllBookings: 'aviation_app.create_all_bookings',
  updateAllBookings: 'aviation_app.update_all_bookings',
  deleteAllBookings: 'aviation_app.delete_all_bookings',

  viewAllLogbooks: 'aviation_app.view_all_logbooks',

  allReportsView: 'aviation_app.view_all_reports',

  viewAllEquipments: 'equipment.view_equipments',
  createEquipment: 'equipment.create_equipments',
  updateEquipment: 'equipment.update_equipments',
  deleteEquipment: 'equipment.delete_equipments',

  unconfirmedLicensesView: 'aviation_app.view_unconfirmed_licenses',
  confirmLicenses: 'aviation_app.confirm_licenses',
  unconfirmedMedicalsView: 'aviation_app.view_unconfirmed_medicals',
  confirmMedicals: 'aviation_app.confirm_medicals',
  unconfirmedRatingView: 'aviation_app.view_unconfirmed_ratings',
  confirmRatings: 'aviation_app.confirm_ratings',
  unconfirmedQualificationsView: 'aviation_app.view_unconfirmed_qualifications',
  confirmQualifications: 'aviation_app.confirm_qualifications',
  unconfirmedExternalCoursesView:
    'aviation_app.view_unconfirmed_external_courses',
  confirmExternalCourses: 'aviation_app.confirm_external_courses',

  createFolders: 'aviation_app.create_folders',
  updateFolders: 'aviation_app.update_folders',
  deleteFolders: 'aviation_app.delete_folders',

  deleteRatings: 'aviation_app.delete_ratings',
  deleteLicenses: 'aviation_app.delete_licenses',
  deleteMedicals: 'aviation_app.delete_medicals',
  deleteQualifications: 'aviation_app.delete_qualifications',
  deleteExternalCourses: 'aviation_app.delete_external_courses',

  editUserName: 'accounts.edit_user_name',

  updateRatings: 'aviation_app.update_ratings',
  updateLicenses: 'aviation_app.update_licenses',
  updateMedicals: 'aviation_app.update_medicals',
  updateQualifications: 'aviation_app.update_qualifications',
  updateExternalCourses: 'aviation_app.update_external_courses',

  deactivateRatings: 'aviation_app.deactivate_ratings',
  deactivateLicenses: 'aviation_app.deactivate_licenses',
  deactivateMedicals: 'aviation_app.deactivate_medicals',
  deactivateQualifications: 'aviation_app.deactivate_qualifications',
  deactivateExternalCourses: 'aviation_app.deactivate_external_courses',

  updateRatingFiles: 'aviation_app.update_rating_files',
  updateLicenseFiles: 'aviation_app.update_license_files',
  updateMedicalFiles: 'aviation_app.update_medical_files',
  updateQualificationFiles: 'aviation_app.update_qualification_files',
  updateExternalCourseFiles: 'aviation_app.update_external_course_files',

  updateRatingPlannedAction: 'aviation_app.update_rating_planned_action',
  updateLicensePlannedAction: 'aviation_app.update_license_planned_action',
  updateMedicalPlannedAction: 'aviation_app.update_medical_planned_action',
  updateQualificationPlannedAction:
    'aviation_app.update_qualification_planned_action',
  updateExternalCoursePlannedAction:
    'aviation_app.update_external_course_planned_action',

  updateAdvert: 'aviation_app.update_advert',

  renderElearningAdminText: 'aviation_app.render_elearning_admin_text',

  viewEquipmentListOnDashboard: 'aviation_app.view_equipment_list_on_dashboard',
  viewFleets: 'aviation_app.view_fleets',
};

export const RAIL_PERMISSIONS = {
  dashboardView: 'rail_app.view_dashboard',
  coursesView: 'rail_app.view_courses',
  examView: 'rail_app.view_tests',
  expiryRatingsView: 'rail_app.view_expiry_ratings',
  expiryLicensesView: 'rail_app.view_expiry_licenses',
  expiryMedicalsView: 'rail_app.view_expiry_medicals',
  expiryQualificationsView: 'rail_app.view_expiry_qualifications',
  expiryExternalCoursesView: 'rail_app.view_expiry_external_courses',
  myCoursesView: 'rail_app.view_my_course',
  myExamsView: 'rail_app.view_my_exams',
  myProfileView: 'rail_app.view_my_profile',
  assignedCourseView: 'rail_app.view_assigned_course',
  assignedExamView: 'rail_app.view_assigned_tests',
  userListView: 'accounts.view_users',
  questionListView: 'rail_app.view_questions',
  generalSettingsView: 'rail_app.view_general_settings',
  companySettingsView: 'rail_app.view_company_settings',
  generalNotificationView: 'rail_app.view_notification_settings',
  userSettingsView: 'rail_app.view_user_settings',
  reportsSettingsView: 'rail_app.view_reports_settings',
  leftNavigationMenuView: 'accounts.view_left_navigation_menu',
  questionView: 'rail_app.view_questions',
  userProfileView: 'rail_app.view_user_profile',
  crewStatusView: 'accounts.view_crew_status',

  assignedCoursePreview: 'rail_app.preview_assigned_course',
  assignedTestPreview: 'rail_app.preview_assigned_test',

  courseCreate: 'rail_app.create_courses',
  examCreate: 'rail_app.create_tests',
  questionCreate: 'rail_app.create_questions',
  addNewCourse: 'rail_app.course_admin_add_course',
  addNewExam: 'rail_app.test_admin_add_test',

  assignedCourseEdit: 'rail_app.update_assigned_course',
  editCourseInCourseList: 'rail_app.course_list_edit_course',
  assignedTestEdit: 'rail_app.update_assigned_tests',
  lessonEdit: 'rail_app.update_lessons',
  questionEdit: 'rail_app.update_questions',
  examEdit: 'rail_app.update_tests',
  courseEdit: 'rail_app.update_courses',
  settingsEdit: 'rail_app.update_settings',
  userEdit: 'accounts.user_edit',
  schoolGroupEdit: 'rail_app.can_select_school_group',
  editCourse: 'rail_app.course_admin_edit_course',
  editExam: 'rail_app.test_admin_edit_test',

  assignedCourseDelete: 'rail_app.delete_assigned_course',
  assignedTestDelete: 'rail_app.delete_assigned_tests',
  userDelete: 'accounts.user_delete',
  deleteCourse: 'rail_app.course_admin_delete_course',
  deleteExam: 'rail_app.test_admin_delete_test',

  completeCourse: 'rail_app.complete_course',
  startTest: 'rail_app.start_test',
  authorizeCompletedLesson: 'rail_app.authorize_completed_lesson',
  renderPendingItems: 'rail_app.render_pending_items',

  createAllBookings: 'rail_app.create_all_bookings',
  updateAllBookings: 'rail_app.update_all_bookings',
  deleteAllBookings: 'rail_app.delete_all_bookings',

  viewAllLogbooks: 'rail_app.view_all_logbooks',

  allReportsView: 'rail_app.view_all_reports',

  viewAllEquipments: 'equipment.view_equipments',
  createEquipment: 'equipment.create_equipments',
  updateEquipment: 'equipment.update_equipments',
  deleteEquipment: 'equipment.delete_equipments',

  unconfirmedLicensesView: 'rail_app.view_unconfirmed_licenses',
  confirmLicenses: 'rail_app.confirm_licenses',
  unconfirmedMedicalsView: 'rail_app.view_unconfirmed_medicals',
  confirmMedicals: 'rail_app.confirm_medicals',
  unconfirmedRatingView: 'rail_app.view_unconfirmed_ratings',
  confirmRatings: 'rail_app.confirm_ratings',
  unconfirmedQualificationsView: 'rail_app.view_unconfirmed_qualifications',
  confirmQualifications: 'rail_app.confirm_qualifications',
  unconfirmedExternalCoursesView: 'rail_app.view_unconfirmed_external_courses',
  confirmExternalCourses: 'rail_app.confirm_external_courses',

  createFolders: 'rail_app.create_folders',
  updateFolders: 'rail_app.update_folders',
  deleteFolders: 'rail_app.delete_folders',

  deleteRatings: 'rail_app.delete_ratings',
  deleteLicenses: 'rail_app.delete_licenses',
  deleteMedicals: 'rail_app.delete_medicals',
  deleteQualifications: 'rail_app.delete_qualifications',
  deleteExternalCourses: 'rail_app.delete_external_courses',

  editUserName: 'accounts.edit_user_name',

  updateRatings: 'rail_app.update_ratings',
  updateLicenses: 'rail_app.update_licenses',
  updateMedicals: 'rail_app.update_medicals',
  updateQualifications: 'rail_app.update_qualifications',
  updateExternalCourses: 'rail_app.update_external_courses',

  deactivateRatings: 'rail_app.deactivate_ratings',
  deactivateLicenses: 'rail_app.deactivate_licenses',
  deactivateMedicals: 'rail_app.deactivate_medicals',
  deactivateQualifications: 'rail_app.deactivate_qualifications',
  deactivateExternalCourses: 'rail_app.deactivate_external_courses',

  updateRatingFiles: 'rail_app.update_rating_files',
  updateLicenseFiles: 'rail_app.update_license_files',
  updateMedicalFiles: 'rail_app.update_medical_files',
  updateQualificationFiles: 'rail_app.update_qualification_files',
  updateExternalCourseFiles: 'rail_app.update_external_course_files',

  updateRatingPlannedAction: 'rail_app.update_rating_planned_action',
  updateLicensePlannedAction: 'rail_app.update_license_planned_action',
  updateMedicalPlannedAction: 'rail_app.update_medical_planned_action',
  updateQualificationPlannedAction:
    'rail_app.update_qualification_planned_action',
  updateExternalCoursePlannedAction:
    'rail_app.update_external_course_planned_action',

  renderElearningAdminText: 'rail_app.render_elearning_admin_text',

  updateAdvert: 'rail_app.update_advert',
  createFleets: 'rail_app.create_fleets',
  viewFleets: 'rail_app.view_fleets',

  viewEquipmentListOnDashboard: 'rail_app.view_equipment_list_on_dashboard',

  viewUsersCompetences: 'rail_app.view_users_competencies',
  createUsersCompetences: 'rail_app.create_users_competence',
  updateUsersCompetences: 'rail_app.update_users_competence',
  deleteUsersCompetences: 'rail_app.delete_users_competence',

  viewExamsCompetences: 'rail_app.view_exams_competencies',
  createExamsCompetences: 'rail_app.create_exams_competence',
  updateExamsCompetences: 'rail_app.update_exams_competence',
  deleteExamsCompetences: 'rail_app.delete_exams_competence',
};
