import {
  FETCH_COMPLEMENTARY_CERTIFICATES_SUCCESS,
  ADD_COMPLEMENTARY_CERTIFICATE,
  REMOVE_COMPLEMENTARY_CERTIFICATE,
} from '../actions/complementaryCertificates';

const INITIAL_STATE = {
  complementaryCertificateList: [],
};

function fetchComplementaryCertificatesSuccess(state, payload) {
  return {
    ...state,
    complementaryCertificateList: payload.complementaryCertificates,
  };
}

function addComplementaryCertificate(state, payload) {
  return {
    ...state,
    complementaryCertificateList: [
      ...state.complementaryCertificateList,
      payload.complementaryCertificate
    ]
  };
}

function removeComplementaryCertificate(state, payload) {
  return {
    ...state,
    complementaryCertificateList: state.complementaryCertificateList.filter((complementaryCertificate) => {
      return complementaryCertificate !== payload.complementaryCertificate;
    })
  };
}


const actions = {
  [FETCH_COMPLEMENTARY_CERTIFICATES_SUCCESS]: fetchComplementaryCertificatesSuccess,
  [ADD_COMPLEMENTARY_CERTIFICATE]: addComplementaryCertificate,
  [REMOVE_COMPLEMENTARY_CERTIFICATE]: removeComplementaryCertificate,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
