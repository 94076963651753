import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = () => ({
  select: {
    width: '250px'
  },
});

@withNamespaces()
class UserSettings extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    departure: PropTypes.number,
    arrival: PropTypes.number,
    type: PropTypes.number,
    userSettings: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    userTypes: PropTypes.array,
    userLocations: PropTypes.array,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  menuItems(dataToShow, name, registration) {
    if (_isEmpty(dataToShow)) {
      return null;
    }

    return dataToShow.map((data) => {
      return <MenuItem key={`${name}-${data.id}`} value={data.id}>{registration ? data.registration : data.name}</MenuItem>;
    });
  }

   renderFormControlLabel = (value) => {
     return (
       <FormControlLabel
         key={`form-control-key-${value}`}
         value={value}
         control={<Radio color="primary" />}
         label={value}
         className="col-12 col-md-4 mx-0"
       />
     );
   };

   renderRadioGroup = (name, stateName, labels) => {
     const { handleChange } = this.props;
     const value = this.props[stateName];

     return (
       <RadioGroup
         name={name}
         value={value}
         onChange={handleChange(stateName)}
         className="flex-row row"
       >
         { labels.map((label) => this.renderFormControlLabel(label)) }
       </RadioGroup>
     );
   };

   renderSelect = (name, items, label, registration = false) => {
     const { handleChange, classes } = this.props;

     return (
       <div>
         <Select
           value={name}
           onChange={handleChange(label)}
           className={classes.select}
         >
           { this.menuItems(items, label, registration) }
         </Select>
       </div>
     );
   };

   render() {
     const {
       departure,
       arrival,
       type
     } = this.props;

     const { userLocations, userTypes, t } = this.props;

     return (
       <div>
         <div className="pl-sm-3">
           <h4>{t('settings:myDefaultJourneyLogSettings')}</h4>
         </div>
         <div className="pl-sm-5">
           <div className="row">
             <div className="col-xl-4 col-sm-6 col-xs-12 py-2 pl-lg-0">
               <div>
                 <InputLabel htmlFor="departure">{t('input:defaultDepartureLocation')}</InputLabel>
               </div>
               { this.renderSelect(departure, userLocations, 'departure') }
             </div>
             <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
               <div>
                 <InputLabel htmlFor="arrival">{t('input:defaultArrivalLocation')}</InputLabel>
               </div>
               { this.renderSelect(arrival, userLocations, 'arrival') }
             </div>
           </div>
           <div className="row">
             <div className="col-xl-4 col-sm-6 col-xs-12 py-2 pl-lg-0">
               <div>
                 <InputLabel htmlFor="type">{t('input:defaultType')}</InputLabel>
               </div>
               { this.renderSelect(type, userTypes, 'type') }
             </div>
           </div>
         </div>
       </div>
     );
   }
}

export default withStyles(styles)(UserSettings);
