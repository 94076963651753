import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_COMPLEMENTARY_CERTIFICATES_SUCCESS = 'FETCH_COMPLEMENTARY_CERTIFICATES_SUCCESS';
export const ADD_COMPLEMENTARY_CERTIFICATE = 'ADD_COMPLEMENTARY_CERTIFICATE';
export const REMOVE_COMPLEMENTARY_CERTIFICATE = 'REMOVE_COMPLEMENTARY_CERTIFICATE';

export function fetchComplementaryCertificatesSuccess(complementaryCertificates) {
  return {
    type: FETCH_COMPLEMENTARY_CERTIFICATES_SUCCESS,
    payload: {
      complementaryCertificates
    }
  };
}

export function addComplementaryCertificateSuccess(complementaryCertificate) {
  return {
    type: ADD_COMPLEMENTARY_CERTIFICATE,
    payload: {
      complementaryCertificate
    }
  };
}

export function removeComplementaryCertificateSuccess(complementaryCertificate) {
  return {
    type: REMOVE_COMPLEMENTARY_CERTIFICATE,
    payload: {
      complementaryCertificate
    }
  };
}

export function addComplementaryCertificate(complementaryCertificate) {
  return (dispatch) => {
    return axios.post(URLS.complementaryCertificates, complementaryCertificate)
      .then(({ data }) => {
        dispatch(addComplementaryCertificateSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during complementary certificate adding ${error.message}`, error));
      });
  };
}

export function removeComplementaryCertificate(complementaryCertificate) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.complementaryCertificates, complementaryCertificate.id))
      .then(() => dispatch(removeComplementaryCertificateSuccess(complementaryCertificate)))
      .catch((error) => {
        dispatch(addError(`Error during complementary certificate removing ${error.message}`, error));
      });
  };
}
