/* eslint-disable promise/no-nesting */

import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';

export const FETCH_QUESTION_CATEGORIES_REQUEST = 'FETCH_QUESTION_CATEGORIES_REQUEST';
export const FETCH_QUESTION_CATEGORIES_SUCCESS = 'FETCH_QUESTION_CATEGORIES_SUCCESS';
export const FETCH_QUESTION_CATEGORIES_FAILURE = 'FETCH_QUESTION_CATEGORIES_FAILURE';
export const ADD_QUESTION_CATEGORY = 'ADD_QUESTION_CATEGORY';
export const EDIT_QUESTION_CATEGORY = 'EDIT_QUESTION_CATEGORY';
export const REMOVE_QUESTION_CATEGORY = 'REMOVE_QUESTION_CATEGORY';


export function fetchQuestionCategoriesRequest() {
  return {
    type: FETCH_QUESTION_CATEGORIES_REQUEST
  };
}

export function fetchQuestionCategoriesSuccess(questionCategories) {
  return {
    type: FETCH_QUESTION_CATEGORIES_SUCCESS,
    payload: {
      questionCategories
    }
  };
}

export function fetchQuestionCategoriesFailure() {
  return {
    type: FETCH_QUESTION_CATEGORIES_FAILURE
  };
}

export function addQuestionCategorySuccess(questionCategory) {
  return {
    type: ADD_QUESTION_CATEGORY,
    payload: {
      questionCategory
    }
  };
}

export function removeQuestionCategorySuccess(questionCategory) {
  return {
    type: REMOVE_QUESTION_CATEGORY,
    payload: {
      questionCategory
    }
  };
}

export function fetchQuestionCategories() {
  return (dispatch) => {
    dispatch(fetchQuestionCategoriesRequest());

    return axios.get(URLS.questionCategories)
      .then(({ data }) => dispatch(fetchQuestionCategoriesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchQuestionCategoriesFailure());
        dispatch(addError(`Error during question categories fetching ${error.message}`, error));
      });
  };
}

export function addQuestionCategory(questionCategory) {
  return (dispatch) => {
    return axios.post(URLS.questionCategories, questionCategory)
      .then(({ data }) => dispatch(addQuestionCategorySuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during question category adding ${error.message}`, error));
      });
  };
}

export function removeQuestionCategory(questionCategory) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.questionCategories, questionCategory.id))
      .then(() => dispatch(removeQuestionCategorySuccess(questionCategory)))
      .catch((error) => {
        dispatch(addError(`Error during question category removing ${error.message}`, error));
      });
  };
}
