import {
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS,
  ADD_DOCUMENT,
  EDIT_DOCUMENT,
  REMOVE_DOCUMENT,
} from '../actions/documents';
import {
  REMOVE_DOCUMENT_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  documentList: [],
  documentsRequest: false,
};

function fetchDocumentsSuccess(state, payload) {
  return {
    ...state,
    documentList: payload.documents,
    documentsRequest: false,
  };
}

function fetchDocuments(state) {
  return {
    ...state,
    documentsRequest: true
  };
}

function addDocument(state, payload) {
  return {
    ...state,
    documentList: [
      ...state.documentList,
      payload.document
    ]
  };
}


function editDocument(state, payload) {
  return {
    ...state,
    documentList: state.documentList.map((document) => {
      if (document.id === payload.document.id) {
        return payload.document;
      }

      return document;
    })
  };
}

function removeDocument(state, payload) {
  return {
    ...state,
    documentList: state.documentList.filter((document) => document !== payload.document)
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    documentList: state.documentList.map((document) => {
      if (document.id === payload.documentId) {
        return {
          ...document,
          files: document.files.filter((file) => file.id !== payload.fileId)
        };
      }

      return document;
    })
  };
}

const actions = {
  [FETCH_DOCUMENTS_SUCCESS]: fetchDocumentsSuccess,
  [FETCH_DOCUMENTS]: fetchDocuments,
  [ADD_DOCUMENT]: addDocument,
  [REMOVE_DOCUMENT]: removeDocument,
  [EDIT_DOCUMENT]: editDocument,
  [REMOVE_DOCUMENT_FILE_SUCCESS]: removeFile,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
