import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _each from 'lodash/each';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import BookingObjectList from './BookingObjectList';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import Loader from '../../../../common/components/Loader';
import Form from '../../../../common/components/forms/Form';
import { fetchBookingObjects, addBookingObject, removeBookingObject } from '../../../actions/bookingObjects';

import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  name: '',
};

const mapStateToProps = (state) => {
  return {
    bookingObjectList: state.bookingObjects.bookingObjectList,
    bookingObjectRequest: state.bookingObjects.bookingObjectRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchBookingObjects: () => dispatch(fetchBookingObjects()),
    addBookingObject: (bookingObject) => dispatch(addBookingObject(bookingObject)),
    removeBookingObject: (bookingObject) => dispatch(removeBookingObject(bookingObject)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class BookingObjectsManager extends Component {
  static propTypes = {
    bookingObjectList: PropTypes.array.isRequired,
    bookingObjectRequest: PropTypes.bool.isRequired,
    fetchBookingObjects: PropTypes.func.isRequired,
    removeBookingObject: PropTypes.func.isRequired,
    addBookingObject: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.fetchBookingObjects();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleAddClose = () => {
    this.setState(INITIAL_STATE);
  };

  addBookingObject = () => {
    const { name } = this.state;

    this.props.addBookingObject({ name });
    this.handleAddClose();
  };

  removeBookingObject = (bookingObject) => {
    this.props.removeBookingObject(bookingObject);
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid) {
        this.addBookingObject();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { bookingObjectList, bookingObjectRequest, onElementDelete, t } = this.props;
    const { addOpen, name } = this.state;

    if (bookingObjectRequest) {
      return <Loader />;
    }

    return (
      <div className="booking-object-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:bookingObjectsSection')}</h4>
        </div>
        <BookingObjectList
          bookingObjectList={bookingObjectList}
          removeBookingObject={this.removeBookingObject}
          handleAddOpen={this.handleAddOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen}
            onClose={this.handleAddClose}
            aria-labelledby="add-booking-object-dialog"
            fullWidth
          >
            <DialogTitle id="add-booking-object-dialog">{t('settings:addBookingObjectDialogTitle')}</DialogTitle>
            <DialogContent>
              <MaterialInput
                label={t('input:name')}
                autoFocus
                name="name"
                defaultValue={name}
                validators={[
                  new validators.IsRequired(t),
                  new validators.MaxLength(t, 200)
                ]}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleAddClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
