import {
  ADD_REPORT,
  EDIT_REPORT,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_PAGINATION_REQUEST,
  FETCH_REPORTS_PAGINATION_SUCCESS,
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  REMOVE_REPORT,
  SAVE_REPORT,
} from '../actions/reports';

const INITIAL_STATE = {
  report: {},
  reportList: [],
  reportRequest: false,
  reportPaginationRequest: false,
  reportPaginationNext: null,
  reportSaveRequest: false,
  reportDetailsRequest: false,
};

function fetchReportsSuccess(state, payload) {
  return {
    ...state,
    reportList: payload.reports.results,
    reportPaginationNext: payload.reports.next,
    reportRequest: false,
  };
}

function fetchReportsPaginationRequest(state) {
  return {
    ...state,
    reportPaginationRequest: true,
  };
}

function fetchReportsPaginationSuccess(state, payload) {
  return {
    ...state,
    reportList: [...state.reportList, ...payload.reports.results],
    reportPaginationNext: payload.reports.next,
    reportPaginationRequest: false,
  };
}

function fetchReportsRequest(state) {
  return {
    ...state,
    reportRequest: true,
  };
}

function saveReportRequest(state) {
  return {
    ...state,
    reportSaveRequest: true,
  };
}

function fetchReportsFailure(state) {
  return {
    ...state,
    reportRequest: false,
  };
}

function fetchReportSuccess(state, payload) {
  return {
    ...state,
    report: payload.report,
    reportDetailsRequest: false,
  };
}

function fetchReportRequest(state) {
  return {
    ...state,
    reportDetailsRequest: true,
  };
}

function fetchReportFailure(state) {
  return {
    ...state,
    reportDetailsRequest: false,
  };
}

function addReport(state, payload) {
  return {
    ...state,
    reportSaveRequest: false,
    reportList: [...state.reportList, payload.report],
  };
}

function editReport(state, payload) {
  return {
    ...state,
    reportSaveRequest: false,
    report: payload.report,
    reportList: state.reportList.map((report) => {
      if (report.id === payload.report.id) {
        return payload.report;
      }

      return report;
    }),
  };
}

function removeReport(state, payload) {
  return {
    ...state,
    reportList: state.reportList.filter(
      (report) => report.id !== payload.report.id,
    ),
  };
}

const actions = {
  [FETCH_REPORTS_SUCCESS]: fetchReportsSuccess,
  [FETCH_REPORTS_REQUEST]: fetchReportsRequest,
  [FETCH_REPORTS_FAILURE]: fetchReportsFailure,
  [FETCH_REPORTS_PAGINATION_REQUEST]: fetchReportsPaginationRequest,
  [FETCH_REPORTS_PAGINATION_SUCCESS]: fetchReportsPaginationSuccess,
  [FETCH_REPORT_SUCCESS]: fetchReportSuccess,
  [FETCH_REPORT_REQUEST]: fetchReportRequest,
  [FETCH_REPORT_FAILURE]: fetchReportFailure,
  [SAVE_REPORT]: saveReportRequest,
  [ADD_REPORT]: addReport,
  [EDIT_REPORT]: editReport,
  [REMOVE_REPORT]: removeReport,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
