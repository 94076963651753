import './styles.scss';

import React, { Component } from 'react';

import BookingObjectsManager from './General/BookingObjectsManager';
import CompetenciesManager from './General/CompetenciesManager';
import Confirmation from '../../../common/components/Confirmation';
import DateFormatManager from './General/DateFormatManager';
import Dialog from '@material-ui/core/Dialog';
import DocumentTypesManager from './General/DocumentTypesManager';
import FleetFieldsManager from './Fleet/FleetFieldsManager';
import FleetGroupManager from './Fleet/VehicleGroupManager';
import GradingsManager from './General/GradingsManager';
import Icon from '@material-ui/core/Icon';
import ItemTypesManager from './General/ItemTypesManager';
import LanguageManager from './General/LanguageManager';
import Loader from '../../../common/components/Loader';
import LocationsManager from './General/LocationsManager';
import { MODULES } from '../../../constants/modules';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';
import PracticalFieldsManager from './General/PracticalFieldsManager';
import PropTypes from 'prop-types';
import QuestionCategoriesManager from './General/QuestionCategoriesManager';
import RemoveDialogContainer from '../../../common/components/RemoveDialogContainer';
import StaffCategoriesManager from './General/StaffCategoriesManager';
import TypesManager from './General/TypesManager';
import VehicleTypesManager from './Fleet/VehicleTypesManager';
import VehiclesManager from './Fleet/VehiclesManager';
import { componentPermission } from '../../decorators/permissions';
import { connect } from 'react-redux';
import { renderModule } from '../../decorators/modules';
import { withNamespaces } from 'react-i18next';

const INITIAL_STATE = {
  showRemoveDialog: false,
  companyInfoSavedDialogOpen: false,
  removeCallback: () => {},
};

const mapStateToProps = (state) => {
  return {
    settingsRequest: state.settings.settingsRequest,
    vehicleTypeList: state.vehicleTypes.vehicleTypeList.filter(
      (vehicleType) => vehicleType.isActive,
    ),
    vehicleGroupList: state.vehicleGroups.vehicleGroupList,
  };
};

@withNamespaces()
@connect(mapStateToProps)
@componentPermission(PERMISSIONS.generalSettingsView)
export default class UserSettings extends Component {
  static propTypes = {
    fetchSettings: PropTypes.func,
    settingsRequest: PropTypes.bool.isRequired,
    vehicleTypeList: PropTypes.array.isRequired,
    vehicleGroupList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  openCompanyInfoSavedDialog = () => {
    this.setState({
      companyInfoSavedDialogOpen: true,
    });
  };

  closeCompanyInfoSavedDialog = () => {
    this.setState({
      companyInfoSavedDialogOpen: false,
    });
  };

  @renderModule(MODULES.fleet)
  renderFleet(t) {
    const { vehicleTypeList, vehicleGroupList } = this.props;

    return (
      <div className="col-12">
        <h2>{t('settings:fleetSection')}</h2>
        <FleetFieldsManager
          onInfoSuccessfullySaved={this.openCompanyInfoSavedDialog}
        />
        <VehicleTypesManager
          vehicleTypeList={vehicleTypeList}
          onElementDelete={this.openRemoveDialog}
        />
        <FleetGroupManager
          vehicleGroupList={vehicleGroupList}
          onElementDelete={this.openRemoveDialog}
        />
        <VehiclesManager
          vehicleTypeList={vehicleTypeList}
          vehicleGroupList={vehicleGroupList}
          onElementDelete={this.openRemoveDialog}
        />
      </div>
    );
  }

  render() {
    const { settingsRequest, t } = this.props;
    const { showRemoveDialog, removeCallback, companyInfoSavedDialogOpen } =
      this.state;

    if (settingsRequest) {
      return <Loader />;
    }

    return (
      <div className="settings row">
        <div className="col-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">settings</Icon>
              <h1>
                {t('settings:name')}
                <span className="h1-subheader">
                  {' '}
                  /{t('settings:coursesExamsSection')}
                </span>
              </h1>
            </span>
            <p>{t('settings:description')}</p>
          </header>
        </div>
        <div className="col-12">
          <h2>{t('settings:generalSection')}</h2>
          <LanguageManager />
          <DateFormatManager />
          <DocumentTypesManager onElementDelete={this.openRemoveDialog} />
        </div>
        <div className="col-12">
          <h2>{t('settings:coursesSection')}</h2>
          <GradingsManager />
          <PracticalFieldsManager />
          <ItemTypesManager onElementDelete={this.openRemoveDialog} />
          <TypesManager onElementDelete={this.openRemoveDialog} />
          <LocationsManager onElementDelete={this.openRemoveDialog} />
          <BookingObjectsManager onElementDelete={this.openRemoveDialog} />
        </div>
        <div className="col-12">
          <h2>{t('settings:examsSection')}</h2>
          <QuestionCategoriesManager onElementDelete={this.openRemoveDialog} />
        </div>
        {this.renderFleet(t)}
        <div className="col-12">
          <h2>{t('settings:competenciesManagerSection')}</h2>
          <CompetenciesManager onElementDelete={this.openRemoveDialog} />
        </div>
        <div className="col-12">
          <h2>{t('settings:StaffCategorySection')}</h2>
          <StaffCategoriesManager onElementDelete={this.openRemoveDialog} />
        </div>
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
        <Dialog
          open={companyInfoSavedDialogOpen}
          onClose={this.closeCompanyInfoSavedDialog}
          aria-labelledby="confirmation-save"
          fullWidth
        >
          <Confirmation
            message={t('settings:fleetFormSaved')}
            closeConfirmationDialog={this.closeCompanyInfoSavedDialog}
          />
        </Dialog>
      </div>
    );
  }
}
