import {
  FETCH_EXTERNAL_COURSES_SUCCESS,
  FETCH_EXTERNAL_COURSES,
  ADD_EXTERNAL_COURSE,
  REMOVE_EXTERNAL_COURSE,
  EDIT_EXTERNAL_COURSE,
  FETCH_ALL_EXTERNAL_COURSES_SUCCESS
} from '../actions/externalCourses';
import {
  REMOVE_EXTERNAL_COURSE_FILE_SUCCESS,
} from '../actions/fileUpload';

const INITIAL_STATE = {
  externalCourseList: [],
  allExternalCoursesList: [],
  externalCoursesRequest: false,
};

function fetchExternalCoursesSuccess(state, payload) {
  return {
    ...state,
    externalCourseList: payload.externalCourses,
  };
}

function fetchAllExternalCoursesSuccess(state, payload) {
  return {
    ...state,
    allExternalCoursesList: payload.allExternalCourses,
    externalCoursesRequest: false
  };
}

function fetchAllExternalCourses(state) {
  return {
    ...state,
    externalCoursesRequest: true
  };
}

function addExternalCourse(state, payload) {
  return {
    ...state,
    externalCourseList: [
      ...state.externalCourseList,
      payload.externalCourse
    ]
  };
}

function removeExternalCourse(state, payload) {
  return {
    ...state,
    externalCourseList: state.externalCourseList.filter((externalCourse) => externalCourse !== payload.externalCourse)
  };
}

function editExternalCourse(state, payload) {
  return {
    ...state,
    externalCourseList: state.externalCourseList.map((externalCourse) => {
      if (externalCourse.id === payload.externalCourse.id) {
        return payload.externalCourse;
      }

      return externalCourse;
    }),
    allExternalCoursesList: state.allExternalCoursesList.map((externalCourse) => {
      if (externalCourse.id === payload.externalCourse.id) {
        return payload.externalCourse;
      }

      return externalCourse;
    })
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    externalCourseList: state.externalCourseList.map((externalCourse) => {
      if (externalCourse.id === payload.externalCourseId) {
        return {
          ...externalCourse,
          files: externalCourse.files.filter((file) => file.id !== payload.fileId)
        };
      }

      return externalCourse;
    })
  };
}

const actions = {
  [FETCH_EXTERNAL_COURSES_SUCCESS]: fetchExternalCoursesSuccess,
  [FETCH_EXTERNAL_COURSES]: fetchAllExternalCourses,
  [ADD_EXTERNAL_COURSE]: addExternalCourse,
  [REMOVE_EXTERNAL_COURSE]: removeExternalCourse,
  [EDIT_EXTERNAL_COURSE]: editExternalCourse,
  [REMOVE_EXTERNAL_COURSE_FILE_SUCCESS]: removeFile,
  [FETCH_ALL_EXTERNAL_COURSES_SUCCESS]: fetchAllExternalCoursesSuccess
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
