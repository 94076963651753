import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_VEHICLES_REQUEST = 'FETCH_VEHICLES_REQUEST';
export const FETCH_VEHICLES_FAILURE = 'FETCH_VEHICLES_FAILURE';
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS';
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const EDIT_VEHICLE = 'EDIT_VEHICLE';
export const REMOVE_VEHICLE = 'REMOVE_VEHICLE';

export function fetchVehiclesRequest() {
  return {
    type: FETCH_VEHICLES_REQUEST
  };
}

export function fetchVehiclesSuccess(vehicles) {
  return {
    type: FETCH_VEHICLES_SUCCESS,
    payload: {
      vehicles
    }
  };
}

export function fetchVehiclesFailure() {
  return {
    type: FETCH_VEHICLES_FAILURE
  };
}

export function addVehicleSuccess(vehicle) {
  return {
    type: ADD_VEHICLE,
    payload: {
      vehicle
    }
  };
}

export function editVehicleSuccess(vehicle) {
  return {
    type: EDIT_VEHICLE,
    payload: { vehicle }
  };
}

export function removeVehicleSuccess(vehicle) {
  return {
    type: REMOVE_VEHICLE,
    payload: {
      vehicle
    }
  };
}

export function fetchVehicles() {
  return (dispatch) => {
    dispatch(fetchVehiclesRequest());

    return axios.get(URLS.vehicles)
      .then(({ data }) => dispatch(fetchVehiclesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchVehiclesFailure());
        dispatch(addError(`Error during vehicles fetching ${error.message}`, error));
      });
  };
}

export function addVehicle(vehicle) {
  return (dispatch) => {
    return axios.post(URLS.vehicles, vehicle)
      .then(({ data }) => dispatch(addVehicleSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during vehicle adding: ${error.message}`, error));
      });
  };
}

export function editVehicle(vehicle) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.vehicles, vehicle.id), vehicle)
      .then(({ data }) => dispatch(editVehicleSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during vehicle editing ${error.message}`, error));
      });
  };
}

export function removeVehicle(vehicle) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.vehicles, vehicle.id))
      .then(() => dispatch(removeVehicleSuccess(vehicle)))
      .catch((error) => {
        dispatch(addError(`Error during vehicle removing ${error.message}`, error));
      });
  };
}
