import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_NOTIFICATION_CATEGORIES_REQUEST = 'FETCH_NOTIFICATION_CATEGORIES_REQUEST';
export const FETCH_NOTIFICATION_CATEGORIES = 'FETCH_NOTIFICATION_CATEGORIES';


export function fetchNotificationCategoriesRequest() {
  return {
    type: FETCH_NOTIFICATION_CATEGORIES_REQUEST
  };
}

export function fetchNotificationCategoriesSuccess(notificationCategories) {
  return {
    type: FETCH_NOTIFICATION_CATEGORIES,
    payload: {
      notificationCategories
    }
  };
}

export function fetchNotificationCategories() {
  return (dispatch) => {
    dispatch(fetchNotificationCategoriesRequest());

    return axios.get(urlJoin(URLS.notificationCategories))
      .then(({ data }) => dispatch(fetchNotificationCategoriesSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during notification category fetching ${error.message}`, error));
      });
  };
}
