import axios from 'axios';

import { addError } from './errors';
import { urlJoin, getUserSettingsParametersFilter } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_ALL_LOCATIONS_SUCCESS = 'FETCH_ALL_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const ADD_LOCATION = 'ADD_LOCATION';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const REMOVE_LOCATION = 'REMOVE_LOCATION';

export function fetchLocationsRequest() {
  return {
    type: FETCH_LOCATIONS_REQUEST
  };
}

export function fetchAllLocationsSuccess(allLocations) {
  return {
    type: FETCH_ALL_LOCATIONS_SUCCESS,
    payload: {
      allLocations
    }
  };
}

export function fetchLocationsSuccess(locations) {
  return {
    type: FETCH_LOCATIONS_SUCCESS,
    payload: {
      locations
    }
  };
}

export function fetchLocationsFailure() {
  return {
    type: FETCH_LOCATIONS_FAILURE
  };
}

export function addLocationSuccess(location) {
  return {
    type: ADD_LOCATION,
    payload: {
      location
    }
  };
}

export function editLocationSuccess(location) {
  return {
    type: EDIT_LOCATION,
    payload: {
      location
    }
  };
}

export function removeLocationSuccess(location) {
  return {
    type: REMOVE_LOCATION,
    payload: {
      location
    }
  };
}

export function fetchAllLocations() {
  return (dispatch) => {
    dispatch(fetchLocationsRequest());

    return axios.get(URLS.locations)
      .then(({ data }) => dispatch(fetchAllLocationsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchLocationsFailure());
        dispatch(addError(`Error during locations fetching ${error.message}`, error));
      });
  };
}

export function addLocation(location) {
  return (dispatch) => {
    return axios.post(URLS.locations, location)
      .then(({ data }) => dispatch(addLocationSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during location adding ${error.message}`, error));
      });
  };
}

export function fetchLocations(userId) {
  return (dispatch) => {
    const queryParameters = `?${getUserSettingsParametersFilter(userId)}`;

    return axios.get(urlJoin(URLS.locations, queryParameters))
      .then(({ data }) => dispatch(fetchLocationsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during location fetching ${error.message}`, error));
      });
  };
}

export function removeLocation(location) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.locations, location.id))
      .then(() => dispatch(removeLocationSuccess(location)))
      .catch((error) => {
        dispatch(addError(`Error during location removing ${error.message}`, error));
      });
  };
}
