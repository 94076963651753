import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';
import CustomTablePagination from './CustomTablePagination';

import {
  SEARCH_THROTTLING,
  TOTAL_ROW,
  PAGE_SIZES,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from '../../constants';
import classNames from 'classnames';
import '../../styles/components/table.scss';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  iconRow: {
    minWidth: 180,
  },
  logbookRow: {
    minWidth: 80,
  },
  longLogbookRow: {
    minWidth: 120,
  },
  boldText: {
    fontWeight: 'bold',
  },
  hideIcon: {
    visibility: 'hidden',
  },
  showIcon: {
    visibility: 'visible',
  },
  pagination: {
    padding: 0,
    border: 0,
  },
});

@withNamespaces()
@withStyles(styles)
export default class CustomTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: this.props.search,
      secondSearchValue: _get(this.props, 'secondSearch', ''),
    };

    this.handleSearch = _debounce(this.props.handleSearch, SEARCH_THROTTLING);
  }

  moveCaretAtEnd(input) {
    const inputValue = input.target.value;

    input.target.value = '';
    input.target.value = inputValue;
  }

  throttledSearch = (name) => (event) => {
    this.handleSearch(event.target.value, event.target.name);
    this.setState({
      [name]: event.target.value,
    });
  };

  renderRowColumn(colName, user) {
    if (colName.profileView) {
      return (
        <Link className="table-row-link" to={`/users/${user.id.toString()}`}>
          {user[colName.prop]}
        </Link>
      );
    }

    return user[colName.prop];
  }

  getRowDecoration = (colName, user) => {
    const { classes } = this.props;

    return colName.boldText && user.dateOfFlight === TOTAL_ROW
      ? classes.boldText
      : '';
  };

  renderRow(user, index, header) {
    return (
      <TableRow key={`user-${user.id}`}>
        {header.map((colName, colIndex) => (
          <TableCell
            className={
              (colName.prop === 'color' && user.background) ||
              this.getRowDecoration(colName, user)
            }
            numeric={colName.isNumeric}
            key={`tableColumn-${colIndex}`}
          >
            {this.renderRowColumn(colName, user)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  displaySortArrow(column, sortAsc) {
    const { columnToSort, classes, t } = this.props;
    const columnName = `table:${column.prop}`;

    if (column.sort) {
      return (
        <TableSortLabel
          active
          direction={sortAsc ? 'asc' : 'desc'}
          classes={
            columnToSort !== column.prop
              ? { icon: classes.hideIcon }
              : { icon: classes.showIcon }
          }
        >
          {t(columnName)}
        </TableSortLabel>
      );
    }

    return column.hideLabel ? '' : t(columnName);
  }

  getClassName = (column) => {
    const { classes } = this.props;

    if (column.iconWidth) {
      return classes.iconRow;
    } else if (column.logbook) {
      return column.longLogbook ? classes.longLogbookRow : classes.logbookRow;
    }

    return '';
  };

  renderHeaderRow(header, sortAsc, handleSort) {
    return (
      <TableRow>
        {header.map((column, index) => {
          return (
            <TableCell
              className={this.getClassName(column)}
              numeric={column.isNumeric}
              key={`tableHeaderColumn-${index}`}
              onClick={column.sort ? () => handleSort(column.prop) : null}
            >
              {this.displaySortArrow(column, sortAsc)}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  renderSearchInput = (value, valueName, searchLabel) => {
    return (
      <div className="col-auto">
        <TextField
          onFocus={(input) => this.moveCaretAtEnd(input)}
          margin="dense"
          id={valueName}
          name={valueName}
          value={value}
          label={searchLabel}
          onChange={this.throttledSearch(valueName)}
        />
      </div>
    );
  };

  handleChangePage = (page) => {
    const { pageSize } = this.props;

    this.props.handleChangePage(page, pageSize);
  };

  handleChangeRowsPageSize = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const pageSize = event.target.value;

    this.props.handleChangeRowsPageSize(DEFAULT_PAGE_NUMBER, pageSize);
  };

  render() {
    const {
      data,
      header,
      handleSort,
      hideSearch,
      sortAsc,
      columnToSort,
      getUserFilters,
      classes,
      searchLabel,
      secondSearchLabel = 'Search',
      doubleSearch = false,
      showPagination,
      page,
      pageSize,
      loading,
      questionTotalCount,
    } = this.props;

    return (
      <div className="table-wrapper col-12">
        <div className="justify-content-end align-items-end row pb-3">
          <div className="col-auto">
            {hideSearch ? (
              ''
            ) : (
              <div className="row align-items-end">
                <Icon className="mb-1 mr-2">search</Icon>
                {this.renderSearchInput(
                  this.state.searchValue,
                  'searchValue',
                  searchLabel,
                )}
                {doubleSearch && <Icon className="mt-4">arrow_forward</Icon>}
                {doubleSearch &&
                  this.renderSearchInput(
                    this.state.secondSearchValue,
                    'secondSearchValue',
                    secondSearchLabel,
                  )}
              </div>
            )}
          </div>
          {getUserFilters && getUserFilters()}
        </div>
        <div className={classes.root}>
          <Table className={classNames(classes.table, 'col-12')}>
            <TableHead>
              {this.renderHeaderRow(header, sortAsc, handleSort, columnToSort)}
            </TableHead>
            <TableBody className={loading ? 'table-placeholder' : ''}>
              {data.map((user, index) => this.renderRow(user, index, header))}
            </TableBody>
          </Table>
          {showPagination && (
            <TablePagination
              component="div"
              className={classes.pagination}
              rowsPerPageOptions={PAGE_SIZES}
              count={questionTotalCount}
              rowsPerPage={pageSize}
              page={page}
              onChangeRowsPerPage={this.handleChangeRowsPageSize}
              onChangePage={this.handleChangePage}
              ActionsComponent={CustomTablePagination}
            />
          )}
        </div>
      </div>
    );
  }
}

CustomTable.propTypes = {
  data: PropTypes.array.isRequired,
  questionTotalCount: PropTypes.number,
  header: PropTypes.array.isRequired,
  handleSort: PropTypes.func.isRequired,
  hideSearch: PropTypes.bool,
  sortAsc: PropTypes.bool.isRequired,
  columnToSort: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  getUserFilters: PropTypes.func,
  search: PropTypes.string.isRequired,
  secondSearch: PropTypes.string,
  classes: PropTypes.object.isRequired,
  searchLabel: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  secondSearchLabel: PropTypes.string,
  doubleSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.bool,
  showPagination: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPageSize: PropTypes.func,
};

CustomTable.defaultProps = {
  loading: false,
  showPagination: false,
  questionTotalCount: 0,
  page: DEFAULT_PAGE_NUMBER,
  pageSize: DEFAULT_PAGE_SIZE,
  handleChangePage: () => {},
  handleChangeRowsPageSize: () => {},
};
