import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { reformatPickerDateFromDB } from '../../../utils/time';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';


const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 200,
  }
});
const INITIAL_STATE = {
  showRemoveDialog: false,
  removeCallback: () => {},
};

@withNamespaces()
@withStyles(styles)
export default class ComplementaryCertificateList extends Component {
  static propTypes = {
    complementaryCertificateList: PropTypes.array,
    removeComplementaryCertificate: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  getActionIcons(editMode, complementaryCertificate) {
    const { removeComplementaryCertificate } = this.props;
    const file = complementaryCertificate.files[0];

    if (editMode) {
      return (
        <div className="file-inputs">
          <IconButton onClick={() => window.open(file.path, '_blank')}>
            <Icon color="primary">print</Icon>
          </IconButton>
          <IconButton onClick={() => this.openRemoveDialog(() => removeComplementaryCertificate(complementaryCertificate))}>
            <Icon color="primary">delete</Icon>
          </IconButton>
        </div>
      );
    }

    return (
      <div className="file-inputs">
        <IconButton onClick={() => window.open(file.path, '_blank')}>
          <Icon color="primary">print</Icon>
        </IconButton>
      </div>
    );
  }

  renderComplementaryCertificateList() {
    const { complementaryCertificateList, editMode, classes } = this.props;

    return complementaryCertificateList.map((complementaryCertificate) => {
      const { data } = complementaryCertificate;

      return (
        <TableRow key={`complementaryCertificate-${complementaryCertificate.id}`}>
          <TableCell className={classes.longCell}>{data.licence}</TableCell>
          <TableCell className={classes.longCell}>{complementaryCertificate.language}</TableCell>
          <TableCell className={classes.longCell}>{data.organisation}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(data.issuingDate, false)}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateFromDB(data.expiryDate, false)}</TableCell>
          <TableCell numeric className={classes.iconsCell}>
            {this.getActionIcons(editMode, complementaryCertificate)}
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes, complementaryCertificateList, t } = this.props;
    const { showRemoveDialog, removeCallback } = this.state;

    if (_isEmpty(complementaryCertificateList)) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:licenceNumber')}</TableCell>
              <TableCell>{t('table:language')}</TableCell>
              <TableCell>{t('table:organisation')}</TableCell>
              <TableCell>{t('table:issueDate')}</TableCell>
              <TableCell>{t('table:expiryDate')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderComplementaryCertificateList()}
          </TableBody>
        </Table>
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
      </div>
    );
  }
}
