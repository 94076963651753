import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';


export default class Menu extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    closeMobileMenu: PropTypes.func.isRequired,
  };

  state = {
    open: false
  }

  renderDropdownMenu() {
    return null;
  }

  renderHeader() {
    return null;
  }

  toggleMenu() {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  closeMenu() {
    const { closeMobileMenu } = this.props;

    this.setState({ open: false });

    closeMobileMenu();
  }

  renderArrow() {
    const { open } = this.state;
    const icon = open ? 'arrow_drop_up' : 'arrow_drop_down';

    return (
      <Icon className="mx-1 nav-drop-icon">{icon}</Icon>
    );
  }

  renderMenu() {
    return (
      <div className="nav-link single-link">
        <div className="header" onClick={() => this.toggleMenu()}>
          {this.renderHeader()}
          {this.renderArrow()}
        </div>
        {this.renderDropdownMenu()}
      </div>
    );
  }

  render() {
    return this.renderMenu();
  }
}
