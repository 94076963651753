import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReportList from './ReportList';
import { PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';
import { fetchUsers } from '../../../actions/users';
import Loader from '../../../../common/components/Loader';

const mapStateToProps = (state) => {
  return {
    users: state.users.userList,
    getUsersRequest: state.users.getUsersRequest
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: () => dispatch(fetchUsers())
  };
}

@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.allReportsView)
export default class QrosscheckReportList extends Component {
  static propTypes = {
    fetchUsers: PropTypes.func.isRequired,
    getUsersRequest: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    props.fetchUsers();
  }

  render() {

    const { getUsersRequest } = this.props;

    if (getUsersRequest) {
      return <Loader />;
    }

    return (
      <ReportList />
    );
  }
}


