import {
  FETCH_ADVERT_IMAGE_SUCCESS,
  FETCH_ADVERT_IMAGE_FAILURE,
  FETCH_ADVERT_IMAGE_REQUEST,
  UPDATE_ADVERT_IMAGE_SUCCESS
} from '../actions/fileUpload';

const INITIAL_STATE = {
  file: {},
  fileRequest: false,
};

function fetchFileSuccess(state, payload) {
  return {
    ...state,
    file: payload.advertImage,
    fileRequest: false,
  };
}

function updateFileSuccess(state, payload) {
  return {
    ...state,
    file: payload,
    fileRequest: false,
  };
}

function fetchFileRequest(state) {
  return {
    ...state,
    fileRequest: true,
  };
}

function fetchFileFailure(state) {
  return {
    ...state,
    fileRequest: false,
  };
}

const actions = {
  [FETCH_ADVERT_IMAGE_SUCCESS]: fetchFileSuccess,
  [FETCH_ADVERT_IMAGE_FAILURE]: fetchFileFailure,
  [FETCH_ADVERT_IMAGE_REQUEST]: fetchFileRequest,
  [UPDATE_ADVERT_IMAGE_SUCCESS]: updateFileSuccess,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
