import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_REPORT_CATEGORIES_REQUEST = 'FETCH_REPORT_CATEGORIES_REQUEST';
export const FETCH_REPORT_CATEGORIES_SUCCESS = 'FETCH_REPORT_CATEGORIES_SUCCESS';
export const FETCH_REPORT_CATEGORIES_FAILURE = 'FETCH_REPORT_CATEGORIES_FAILURE';
export const ADD_REPORT_CATEGORY = 'ADD_REPORT_CATEGORY';
export const EDIT_REPORT_CATEGORY = 'EDIT_REPORT_CATEGORY';
export const REMOVE_REPORT_CATEGORY = 'REMOVE_REPORT_CATEGORY';

export function fetchReportCategoriesRequest() {
  return {
    type: FETCH_REPORT_CATEGORIES_REQUEST
  };
}

export function fetchReportCategoriesSuccess(reportCategories) {
  return {
    type: FETCH_REPORT_CATEGORIES_SUCCESS,
    payload: {
      reportCategories
    }
  };
}

export function fetchReportCategoriesFailure() {
  return {
    type: FETCH_REPORT_CATEGORIES_FAILURE
  };
}

export function addReportCategorySuccess(reportCategory) {
  return {
    type: ADD_REPORT_CATEGORY,
    payload: {
      reportCategory
    }
  };
}

export function editReportCategorySuccess(reportCategory) {
  return {
    type: EDIT_REPORT_CATEGORY,
    payload: {
      reportCategory
    }
  };
}

export function removeReportCategorySuccess(reportCategory) {
  return {
    type: REMOVE_REPORT_CATEGORY,
    payload: {
      reportCategory
    }
  };
}


export function fetchReportCategories() {
  return (dispatch) => {
    dispatch(fetchReportCategoriesRequest());

    return axios.get(URLS.reportCategories)
      .then(({ data }) => dispatch(fetchReportCategoriesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchReportCategoriesFailure());
        dispatch(addError(`Error during reportCategories fetching ${error.message}`, error));
      });
  };
}

export function addReportCategory(reportCategory) {
  return (dispatch) => {
    return axios.post(URLS.reportCategories, reportCategory)
      .then(({ data }) => dispatch(addReportCategorySuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during reportCategory adding ${error.message}`, error));
      });
  };
}

export function editReportCategory(reportCategory) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.reportCategories, reportCategory.id), reportCategory)
      .then(({ data }) => dispatch(editReportCategorySuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during reportCategory editing ${error.message}`, error));
      });
  };
}

export function removeReportCategory(reportCategory) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.reportCategories, reportCategory.id))
      .then(() => dispatch(removeReportCategorySuccess(reportCategory)))
      .catch((error) => {
        dispatch(addError(`Error during reportCategory removing ${error.message}`, error));
      });
  };
}
