import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames';

import _isEmpty from 'lodash/isEmpty';
import _each from 'lodash/each';
import _get from 'lodash/get';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';

import Form from '../../../common/components/forms/Form';
import MaterialInput from '../../../common/components/forms/MaterialInput';
import validators from '../../../utils/validators';
import { fetchMetar, fetchTaf } from '../../actions/weather';
import { reformatPickerDateTimeFromDB } from '../../utils/time';

import Airplane from '../../../images/airplane.svg';
import './styles.scss';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  location: {
    background: 'linear-gradient(#0B66B4, #00BAFF)',
    color: '#fff',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    height: '37px',
  },
  tab: {
    width: '215px',
    minHeight: 'auto',
    padding: 0
  },
  iconColor: {
    color: '#0D5AA7'
  }
});

const mapStateToProps = (state) => {
  return {
    metarData: state.weather.metarData,
    tafData: state.weather.tafData,
    metarRequest: state.weather.metarRequest,
    tafRequest: state.weather.tafRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchMetar: (queryParameters) => dispatch(fetchMetar(queryParameters)),
    fetchTaf: (queryParameters) => dispatch(fetchTaf(queryParameters)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class WeatherBox extends Component {
  static propTypes = {
    expirationList: PropTypes.array,
    classes: PropTypes.object.isRequired,
    metarData: PropTypes.array.isRequired,
    metarRequest: PropTypes.bool.isRequired,
    tafData: PropTypes.array.isRequired,
    tafRequest: PropTypes.bool.isRequired,
    fetchMetar: PropTypes.func.isRequired,
    fetchTaf: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    editOpen: false,
    firstLocation: '',
    secondLocation: '',
    editingLocation: '',
  };

  prepareTafData = (rawText) => {
    const splitedText = rawText.split('FM');

    return splitedText.map((text, index) => {
      if (index > 0) {
        return <h6 className="pl-3 my-1">FM{text}</h6>;
      }

      return <h6 className="pl-2 my-1">{text}</h6>;
    });
  };

  renderWeather = (metar, taf, t) => {
    if (_isEmpty(metar) && _isEmpty(taf)) {
      return (
        <h5 className="pl-2">{t('dashboard:weatherNoWeatherDataText')}</h5>
      );
    }

    return (
      <>
        <div>
          <h5 className="data-source-name">{t('dashboard:weatherMetar')}</h5>
          <h6 className="pl-2 my-1 metar">{metar}</h6>
        </div>
        <div>
          <h5 className="data-source-name">{t('dashboard:weatherTaf')}</h5>
          <div>{this.prepareTafData(taf)}</div>
        </div>
      </>
    );
  };

  renderWeatherData = (location) => {
    const { metarData, tafData, t } = this.props;

    const dateText = t('dashboard:weatherDateAt');
    const metar = metarData[location];
    const taf = tafData[location];

    return (
      <div className="p-2">
        <div className="date-aviation">
          <h6 className="pl-2 my-1">
            { !_isEmpty(_get(taf, 'issueTime', '')) && `${dateText} ${reformatPickerDateTimeFromDB(taf.issueTime, false)}`}
          </h6>
        </div>
        {this.renderWeather(_get(metar, 'rawText', ''), _get(taf, 'rawText', ''), t)}
      </div>
    );
  };

  handleDialogClose = () => {
    this.setState({
      editOpen: false
    });
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value,
      });
    });
  };

  onFormValidated = () => {
    const { fetchTaf, fetchMetar } = this.props;
    const { editingLocation } = this.state;

    return (isFormValid) => {
      if (isFormValid) {
        this.setState({
          editOpen: false,
        });

        fetchMetar({
          location: editingLocation,
          dataSource: 'metars',
          stationString: this.state[editingLocation],
        });

        fetchTaf({
          location: editingLocation,
          dataSource: 'tafs',
          stationString: this.state[editingLocation],
        });
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  renderInput = (editingLocation, classes, t) => {
    return (
      <MaterialInput
        className={classes.textField}
        margin="dense"
        name={editingLocation}
        label={t('input:station')}
        validators={[
          new validators.StationId(t)
        ]}
        defaultValue={this.state[editingLocation]}
      />
    );
  };

  dialogContent = (classes, title, t) => {
    const { editingLocation } = this.state;

    return (
      <div>
        <DialogTitle id="add-license-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.root}>
          { this.renderInput(editingLocation, classes, t) }
        </DialogContent>
      </div>
    );
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  handleEditOpen = (editingLocation) => {
    this.setState({
      editOpen: true,
      editingLocation
    });
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  renderDialog = () => {
    const { classes, t } = this.props;
    const { editOpen } = this.state;

    return (
      <Form
        onChange={this.onChange}
        onFormValidated={this.onFormValidated()}
        registerForm={this.registerForm}
      >
        <Dialog
          PaperProps={{
            classes: { root: classes.root }
          }}
          open={editOpen}
          onClose={this.handleDialogClose}
          fullWidth
        >
          {this.dialogContent(classes, t('dashboard:weatherDialogTitle'), t)}
          {this.dialogActions(t)}
        </Dialog>
      </Form>
    );
  };

  renderHerader = (location, station) => {
    const { classes } = this.props;

    return (
      <div className="row justify-content-between">
        <h5
          className={classNames(classes.iconColor, 'rss-news-title col-auto')}
        >
          {station}
        </h5>
        <IconButton
          className="col-auto pr-2"
          color="primary"
          onClick={() => this.handleEditOpen(location)}
        >
          <Icon
            color="primary"
            className={'edit-pen-icon'}
          >
            mode_edit
          </Icon>
        </IconButton>
      </div>
    );
  };

  render() {
    const { classes, t } = this.props;
    const { firstLocation, secondLocation, editOpen } = this.state;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3 pb-3')}>
        <header className="header-dashboard">
          <span className={classNames('span-dashboard', 'py-3')}>
            <img src={Airplane} />
            <h4 className="h4-dashboard">{t('dashboard:weatherName')}</h4>
          </span>
        </header>
        <div className={classNames(classes.root, 'px-2')}>
          <div className={'px-2'}>
            {this.renderHerader('firstLocation', _isEmpty(firstLocation) ? t('dashboard:weatherFirstStationUpperCase') : firstLocation)}
            {this.renderWeatherData('firstLocation')}
          </div>
          <div className="py-3">
            <Divider />
          </div>
          <div className={'px-2'}>
            {this.renderHerader('secondLocation', _isEmpty(secondLocation) ? t('dashboard:weatherSecondStationUpperCase') : secondLocation)}
            {this.renderWeatherData('secondLocation')}
          </div>
        </div>
        { editOpen && this.renderDialog() }
      </Paper>
    );
  }
}

