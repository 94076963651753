import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

const styles = (theme) => ({
  chip: {
    margin: theme.spacing.unit / 2,
  },
});

@withNamespaces()
@withStyles(styles)
export default class LocationList extends Component {
  static propTypes = {
    userId: PropTypes.string,
    locationList: PropTypes.array,
    removeLocation: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderLocationList = (locationList) => {
    const { removeLocation, onElementDelete, classes } = this.props;

    return locationList.map((location) => {
      return (
        <Chip
          key={`location-item-${location.id}`}
          label={location.name}
          onDelete={() => onElementDelete(() => removeLocation(location))}
          className={classes.chip}
        />
      );
    });
  };

  render() {
    const { handleAddOpen, locationList, userId, t } = this.props;

    if (_isEmpty(locationList)) {
      return (
        <div className={userId ? 'pl-sm-3' : 'pl-sm-5'}>
          <Button color="primary" onClick={handleAddOpen}>
            {t('settings:addLocationButton')}
          </Button>
        </div>
      );
    }

    return (
      <div className={userId ? 'pl-sm-3' : 'pl-sm-5'}>
        <div elevation={4} className="ml-sm-3 my-2">
          { this.renderLocationList(locationList) }
        </div>
        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addLocationButton')}
        </Button>
      </div>
    );
  }
}
