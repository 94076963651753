import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _find from 'lodash/find';

import AutoSuggestion from '../../../../../common/components/AutoSuggestion';
import TypeList from './TypeList';
import { toLowercase } from '../../../../../utils';

export default class PracticalLesson extends Component {
  static propTypes = {
    practicalFieldList: PropTypes.array.isRequired,
    typeList: PropTypes.array.isRequired,
    locationList: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleDatePickerChange: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    practicalLessonData: PropTypes.object.isRequired,
    preview: PropTypes.bool,
    handleRegisterComponentForm: PropTypes.func.isRequired,
    handleFormValidated: PropTypes.func.isRequired,
  };

  listQuestion = (props) => {
    const { typeList, locationList } = this.props;

    if (toLowercase(props.label) === 'type') {
      return <TypeList {...props} typeList={typeList} />;
    }

    return <AutoSuggestion {...props} typeList={locationList} fullWidth />;
  };

  getDefaultValue = (practicalLessonData, fieldName) => {
    if (fieldName.toLowerCase() === 'departure' || fieldName.toLowerCase() === 'arrival') {
      return practicalLessonData[fieldName] || '';
    }

    return _get(practicalLessonData, fieldName, '');
  };

  renderQuestion(field, disabled = false) {
    const { handleChange, handleSelectChange, handleCheckboxChange, preview, practicalLessonData } = this.props;
    const questionTypes = {
      'L': this.listQuestion,
    };

    if (!field) {
      return null;
    }

    const fieldName = toLowercase(field.name);
    const questionTypeShortname = field.questionType.shortname;
    const props = {
      label: fieldName,
      inputName: fieldName.replace(' ', '_'),
      defaultValue: this.getDefaultValue(practicalLessonData, fieldName),
      handleChange,
      handleSelectChange,
      handleCheckboxChange,
      preview: preview || disabled,
    };

    return questionTypes[questionTypeShortname](props) || null;
  }

  render() {
    const { practicalFieldList } = this.props;

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
          {this.renderQuestion(_find(practicalFieldList, { name: 'Type' }))}
        </div>
        <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6" />
        <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
          {this.renderQuestion(_find(practicalFieldList, { name: 'Departure' }))}
        </div>
        <div className="col-xs-12 col-sm-6 col-lg-12 col-xl-6">
          {this.renderQuestion(_find(practicalFieldList, { name: 'Arrival' }))}
        </div>
      </div>
    );
  }
}
