import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import QuestionNew from '../QuestionNew';
import Loader from '../../../../common/components/Loader';

import { fetchQuestion } from '../../../actions/questions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

const mapStateToProps = (state) => {
  return {
    question: state.questions.question,
    questionDetailsRequest: state.questions.questionDetailsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchQuestion: (id) => dispatch(fetchQuestion(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.questionView)
export default class QuestionDetails extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    fetchQuestion: PropTypes.func.isRequired,
    questionDetailsRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.fetchQuestion(this.props.match.params.id);
  }

  prepareAnswer = (question, questionOrder) => {
    return _get(question.answers.find((answer) => answer.order === questionOrder), 'name', '');
  };

  prepareQuestion = (question) => {
    if (_isEmpty(question)) {
      return question;
    }

    return {
      correctAnswer: _get(question.answers.find((answer) => answer.isCorrect === true), 'order', '1'),
      questionName: _get(this.props.question, 'name', ''),
      answers: {
        a: {
          name: this.prepareAnswer(question, 1),
        },
        b: {
          name: this.prepareAnswer(question, 2),
        },
        c: {
          name: this.prepareAnswer(question, 3),
        },
        d: {
          name: this.prepareAnswer(question, 4),
        }
      },
      id: _get(this.props.question, 'id', ''),
      collection: _get(this.props.question, 'categories', ''),
      files: _get(this.props.question, 'files', []),
    };
  };

  render() {
    const { question, questionDetailsRequest } = this.props;

    if (questionDetailsRequest) {
      return <Loader />;
    }

    return (
      <QuestionNew
        question={this.prepareQuestion(question)}
        preview
      />
    );
  }
}
