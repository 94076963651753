import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';
import _intersectionBy from 'lodash/intersectionBy';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';

import Table from '../../../common/components/Table';
import Loader from '../../../common/components/Loader';
import Filter from './Filter';

import { checkExpiredDate, checkExpiryDate } from '../../utils/time';
import { componentPermission } from '../../decorators/permissions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';
import { tableHeader, styles, defaultStaffCategory } from './usersCategoriesConfig';

import { fetchUsersCategories } from '../../actions/users';
import { fetchUsers } from '../../actions/users';


const mapStateToProps = (state) => {
  return {
    userList: state.users.userList,
    usersCategoriesList: state.users.usersCategoriesList,
    getUsersRequest: state.users.getUsersRequest,
    getUsersCategoriesRequest: state.users.getUsersCategoriesRequest,
  };
};


function mapDispatchToProps(dispatch) {
  return {
    fetchUsersCategories: (sortData) => dispatch(fetchUsersCategories(sortData)),
    fetchUsers: (sortData) => dispatch(fetchUsers(sortData)),
  };
}

@withStyles(styles)
@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.crewStatusView)
export default class UsersCategories extends Component {
  static propTypes = {
    userList: PropTypes.array,
    usersCategoriesList: PropTypes.array,
    fetchUsersCategories: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    getUsersRequest: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    searchSettings: {
      search: '',
      filter: {},
      filterIsUsed: false,
      selectedStaffCategory: defaultStaffCategory
    },
    isCleared: false
  };

  componentDidMount() {
    const searchData = {
      ...this.state.searchSettings
    };

    this.props.fetchUsers(searchData);
  }

  renderIcons(user) {
    return (
      <Link className="table-row-link"
        key="user-profile-icon"
        to={{
          pathname: `/users/${user.id}`,
          state: { editMode: false }
        }}
      >
        <IconButton><Icon color="primary">person</Icon></IconButton>
      </Link>
    );
  }

  findExpiryStatus = (statuses) => {
    return statuses.find((status) => {
      return status === 'expiry-date';
    });
  };

  findExpiredStatus = (statuses) => {
    return statuses.find((status) => {
      return status === 'expired-date';
    });
  };

  getBackgroundColor = (competencies) => {
    let expiryClass = 'ok-date';

    const statuses = competencies.map((category) => {

      if (category.expiryDate) {
        const isExpired = checkExpiredDate(category.expiryDate);
        const expires = checkExpiryDate(category.expiryDate, 1);

        if (isExpired) {
          return 'expired-date';
        } else if (expires) {
          return 'expiry-date';
        }
      }

      return 'expired-date';
    });

    const isExpired = this.findExpiredStatus(statuses);
    const isExpiry = this.findExpiryStatus(statuses);

    if (isExpired) {
      expiryClass = 'expired-date';
    } else if (isExpiry) {
      expiryClass = 'expiry-date';
    }

    return <div className={`status-circle ${expiryClass}`} />;
  };

  handleSearch = (search) => {
    const { filter, filterIsUsed, selectedStaffCategory } = this.state.searchSettings;
    const searchData = {
      filter,
      filterIsUsed,
      selectedStaffCategory,
      search
    };

    this.setState({
      searchSettings: searchData,
      isCleared: false
    });

    this.props.fetchUsers(searchData);
    this.props.fetchUsersCategories(searchData);
  };

  getUserFilters = () => {
    const { filterIsUsed, selectedStaffCategory } = this.state.searchSettings;

    return (
      <Filter
        handleFilter={this.handleFilter}
        clearFilter={this.clearFilter}
        filterIsUsed={filterIsUsed}
        selectedStaffCategory={selectedStaffCategory}
      />
    );
  };

  prepareUsers = () => {
    const { userList, usersCategoriesList } = this.props;
    const { isCleared } = this.state;
    const { filterIsUsed, search } = this.state.searchSettings;

    const prepareUsersCategoriesList = filterIsUsed ? usersCategoriesList : [];

    if (isCleared || (_isEmpty(prepareUsersCategoriesList) && !filterIsUsed)) {
      return userList;
    }

    if (filterIsUsed && _isEmpty(search)) {
      return prepareUsersCategoriesList;
    }

    return _intersectionBy(userList, prepareUsersCategoriesList, 'id');
  };

  renderCompetencies = (competencies) => {
    const { classes } = this.props;

    return competencies.map((competency) => {
      let competenceBackgroundColor = classes.competencyOk;

      if (competency.expiryDate) {
        const isExpired = checkExpiredDate(competency.expiryDate);
        const expires = checkExpiryDate(competency.expiryDate, 1);

        if (isExpired) {
          competenceBackgroundColor = classes.competencyExpired;
        } else if (expires) {
          competenceBackgroundColor = classes.competencyAboutExpiration;
        }
      } else {
        competenceBackgroundColor = classes.competencyExpired;
      }

      return (
        <Chip
          key={`staff-competency-${competency.competence.name}-${competency.competence.id}`}
          label={competency.competence.name}
          className={`m-1 ${competenceBackgroundColor}`}
        />
      );
    });
  }

  manageData() {
    const preparedUsers = this.prepareUsers();

    const parsedUser = preparedUsers.map((element) => ({
      color: this.getBackgroundColor(element.competencies),
      name: `${element.firstName} ${element.lastName}`,
      competencies: this.renderCompetencies(element.competencies),
      icons: this.renderIcons(element),
    }));

    return parsedUser;
  }

  handleFilter = (selectedStaffCategoryId, selectedStaffCategory) => {
    const { search } = this.state.searchSettings;

    const filterData = {
      search,
      filter: selectedStaffCategoryId,
      filterIsUsed: true,
      selectedStaffCategory
    };

    this.setState({
      searchSettings: filterData,
      isCleared: false
    });

    this.props.fetchUsersCategories(filterData);
  };

  clearFilter = () => {
    const { search } = this.state.searchSettings;

    const searchData = {
      ...this.state.searchSettings
    };

    const filterData = {
      search,
      filter: {},
      filterIsUsed: false,
      selectedStaffCategory: defaultStaffCategory
    };

    this.setState({
      searchSettings: filterData,
      isCleared: true
    });

    this.props.fetchUsers(searchData);
  };

  renderTable = () => {
    const { searchSettings } = this.state;

    return (
      <Table
        data={this.manageData()}
        header={tableHeader}
        handleSort={this.handleSort}
        sortAsc={searchSettings.sortAsc}
        columnToSort={'name'}
        handleSearch={this.handleSearch}
        search={searchSettings.search}
        getUserFilters={this.getUserFilters}
      />
    );
  };

  render() {
    const { getUsersRequest, t } = this.props;

    if (getUsersRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center crew-status">
        <div className="col-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">
                group
              </Icon>
              <h1>{t('usersCategories:name')}</h1>
            </span>
            <p>{t('usersCategories:description')}</p>
          </header>
        </div>
        {this.renderTable()}
      </div>
    );
  }
}
