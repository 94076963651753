import { URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { urlJoin, } from '../../utils';

export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';

export function fetchDocumentsSuccess(documents) {
  return {
    type: FETCH_DOCUMENTS_SUCCESS,
    payload: {
      documents
    }
  };
}

export function fetchAllDocumentsRequest() {
  return {
    type: FETCH_DOCUMENTS,
  };
}

export function addDocumentSuccess(document) {
  return {
    type: ADD_DOCUMENT,
    payload: {
      document
    }
  };
}

export function removeDocumentSuccess(document) {
  return {
    type: REMOVE_DOCUMENT,
    payload: {
      document
    }
  };
}

export function editDocumentSuccess(document) {
  return {
    type: EDIT_DOCUMENT,
    payload: {
      document
    }
  };
}

export function addDocument(document) {
  return (dispatch) => {
    return axios.post(URLS.documents, document)
      .then(({ data }) => dispatch(addDocumentSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during document adding ${error.message}`, error));
      });
  };
}

export function editDocument(document) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.documents, document.id), document)
      .then(({ data }) => dispatch(editDocumentSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during document editing ${error.message}`, error));
      });
  };
}

export function removeDocument(document) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.documents, document.id))
      .then(() => dispatch(removeDocumentSuccess(document)))
      .catch((error) => {
        dispatch(addError(`Error during document removing ${error.message}`, error));
      });
  };
}
