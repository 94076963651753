import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import userService from '../../../utils/userService';
import Loader from '../../../common/components/Loader';
import Navigation from './Navigation';
import NavigationLower from './NavigationLower';
import NavigationMobile from './NavigationMobile';
import Footer from './Footer';
import Routes from './routes';

import { fetchSettings } from '../../actions/settings';
import { fetchUserPermissions } from '../../actions/users';
import { fetchModules } from '../../actions/modules';

import './styles.scss';
import './menu.scss';
import './mobile-menu.scss';

const mapStateToProps = (state) => {
  return {
    schoolLogo: _find(state.settings.settings, { name: 'school_logo' }),
    settingsRequest: state.settings.settingsRequest,
    loginFetching: state.authentication.loginRequest,
    userPermissionsRequest: state.authentication.userPermissionsRequest,
    userPermissions: state.authentication.userPermissions,
    moduleList: state.modules.moduleList,
    modulesRequest: state.modules.modulesRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchSettings: () => dispatch(fetchSettings()),
    fetchUserPermissions: (id) => dispatch(fetchUserPermissions(id)),
    fetchModules: () => dispatch(fetchModules()),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class Main extends Component {
  static propTypes = {
    schoolLogo: PropTypes.object,
    loginFetching: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    fetchSettings: PropTypes.func.isRequired,
    fetchUserPermissions: PropTypes.func.isRequired,
    userPermissionsRequest: PropTypes.bool.isRequired,
    userPermissions: PropTypes.array,
    settingsRequest: PropTypes.bool.isRequired,
    fetchModules: PropTypes.func.isRequired,
    modulesRequest: PropTypes.bool.isRequired,
    moduleList: PropTypes.array.isRequired,
  };

  componentDidMount() {
    const { id } = userService.getCurrentUser();

    this.props.fetchSettings();
    this.props.fetchUserPermissions(id);
    this.props.fetchModules();
  }

  render() {
    const { loginFetching, settingsRequest, schoolLogo, userPermissionsRequest, userPermissions,
      moduleList, modulesRequest } = this.props;

    if (loginFetching || settingsRequest || !schoolLogo || userPermissionsRequest || _isEmpty(userPermissions)
        || modulesRequest || _isEmpty(moduleList)) {
      return <Loader />;
    }

    return (
      <main className="qrosscheck">
        <div className="content-wrapper page-container">
          <header className="navigation">
            <div className="nav-classic">
              <Navigation
                schoolLogoId={schoolLogo.value}
              />
              <NavigationLower />
            </div>
            <div className="nav-mobile">
              <NavigationMobile
                schoolLogoId={schoolLogo.value}
              />
            </div>
          </header>
          <article>
            <section className="p-5">
              <Paper elevation={4} className="col-12 mt-5 py-5">
                <div className="no-print print-icon">
                  <IconButton id="global-print-icon" onClick={() => { window.print(); }}><Icon>print</Icon></IconButton>
                </div>
                <div id="inner-section">
                  <Routes />
                </div>
              </Paper>
            </section>
          </article>
        </div>
        <Footer />
      </main>
    );
  }
}
