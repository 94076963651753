import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';

import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Form from '../../../../common/components/forms/Form';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import { DRAG_TYPES } from '../../../../constants';
import LessonDroppable from './LessonDroppable';
import validators from '../../../../utils/validators';

const lessonSource = {
  canDrag(props) {
    return !props.preview;
  },
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const lessonTarget = {
  canDrop(props) {
    return !props.preview;
  },
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveLesson(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

function collectDrop(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function collectDrag(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

@withNamespaces()
@DropTarget(DRAG_TYPES.lesson, lessonTarget, collectDrop)
@DragSource(DRAG_TYPES.lesson, lessonSource, collectDrag)
export default class Lesson extends Component {
  static propTypes = {
    lesson: PropTypes.object.isRequired,
    lessonTypeList: PropTypes.array.isRequired,
    dropMoveItem: PropTypes.func.isRequired,
    dropRemoveItem: PropTypes.func.isRequired,
    moveLesson: PropTypes.func.isRequired,
    moveItemSortable: PropTypes.func.isRequired,
    removeLesson: PropTypes.func.isRequired,
    changeLesson: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    registerComponentForm: PropTypes.func.isRequired,
    formValidated: PropTypes.func.isRequired,
    preview: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  state = {
    name: _get(this.props.lesson, 'name', '')
  };

  componentDidMount() {
    this.formData = {};
  }

  renderButtons = (lesson, preview) => {
    const { removeLesson, connectDragSource } = this.props;

    if (preview) {
      return null;
    }

    return (
      <div className="col-auto">
        <IconButton onClick={() => removeLesson(lesson)}><Icon color="primary">delete</Icon></IconButton>
        {
          connectDragSource(
            <span>
              <CustomIconButton>
                <Icon color="primary">
                  drag_handle
                </Icon>
              </CustomIconButton>
            </span>
          )
        }
      </div>
    );
  }

  renderLessonType = (lesson, changeLesson, preview) => {
    const { lessonTypeList, t } = this.props;

    return (
      <FormControl margin="dense">
        <RadioGroup
          aria-label="lessonType"
          className="col-auto flex-row"
          name="lessonType"
          value={lesson.lessonType}
          onChange={changeLesson({ uniqueId: lesson.uniqueId, name: 'lessonType' })}
        >
          {
            lessonTypeList.map((type) => {
              const typeLabel = `course:${type.shortname}`;

              return (
                <FormControlLabel
                  key={`lessonType-${type.id}`}
                  value={String(type.id)}
                  control={<Radio color="primary" />}
                  label={t(typeLabel)}
                  disabled={preview}
                />
              );
            })
          }
        </RadioGroup>
      </FormControl>
    );
  }

  onChange = (formData) => {
    const { changeLesson, lesson } = this.props;
    const event = {
      target: {
        value: formData.name.value
      }
    };

    this.setState({
      name: formData.name.value
    });

    changeLesson({ uniqueId: lesson.uniqueId, name: 'name' })(event);
  };

  onFormValidated = (isFormValid) => {
    const { name } = this.state;
    const { lesson, formValidated } = this.props;

    formValidated(`LessonsManager-${lesson.uniqueId}`, isFormValid, { name });
  };

  registerForm = (triggerFormValidation) => {
    this.props.registerComponentForm(triggerFormValidation);
  };

  render() {
    const {
      lesson,
      dropMoveItem,
      dropRemoveItem,
      changeLesson,
      preview,
      isDragging,
      connectDragPreview,
      connectDropTarget,
      moveItemSortable,
      t,
    } = this.props;
    const { name } = this.state;

    return connectDragPreview(
      connectDropTarget(
        <div
          style={{
            opacity: isDragging ? 0 : 1,
          }}
        >
          <div className="row align-items-end">
            <div className="col">
              <Form
                onChange={this.onChange}
                validateForm={this.validateForm}
                onFormValidated={this.onFormValidated}
                registerForm={this.registerForm}
              >
                <MaterialInput
                  name="name"
                  label={t('input:name')}
                  defaultValue={name}
                  margin="dense"
                  fullWidth
                  validators={[
                    new validators.IsRequired(t),
                    new validators.MaxLength(t, 200)
                  ]}
                  disabled={preview}
                />
              </Form>
            </div>
            {this.renderButtons(lesson, preview)}
          </div>
          <div className="row justify-content-start">
            {this.renderLessonType(lesson, changeLesson, preview)}
          </div>
          <div style={{
            opacity: preview ? 0.5 : 1,
          }}
          >
            <LessonDroppable
              lesson={lesson}
              dropMoveItem={dropMoveItem}
              dropRemoveItem={dropRemoveItem}
              preview={preview}
              moveItemSortable={moveItemSortable}
            />
          </div>
          <Divider color="secondary" />
        </div>
      )
    );
  }
}
