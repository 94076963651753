import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


const INITIAL_STATE = {
  aircraftDialogOpen: false,
  newAircraftList: [],
};

@withNamespaces()
export default class AircraftList extends Component {
  static propTypes = {
    aircraftList: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    preview: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  renderMenuItems() {
    const { aircraftList, defaultValue, t } = this.props;
    const aircraftId = _get(defaultValue, 'aircraftId', '');
    const aircraftName = _get(defaultValue, 'aircraftName', '');
    const newAircraftList = aircraftName ? { id: aircraftId, name: aircraftName } : [];

    if (_isEmpty(aircraftList) && _isEmpty(newAircraftList)) {
      return null;
    }

    return aircraftList.concat(newAircraftList).map((aircraft) => {
      return <MenuItem key={`aircraft-${aircraft.id}`} value={aircraft.id}>{aircraft.registration} ({t('table:' + aircraft.pilot)})</MenuItem>;
    });
  }

  render() {
    const { label, handleSelectChange, defaultValue, preview, t } = this.props;
    const aircraft = _get(defaultValue, 'aircraft', '');
    const inputLabel = `input:${label}`;

    return (
      <div className="practical-lesson-wrapper">
        <FormControl disabled={preview} fullWidth >
          <InputLabel htmlFor={label}>{t(inputLabel)}</InputLabel>
          <Select
            value={aircraft}
            onChange={handleSelectChange}
            className={preview ? 'svg-arrow-hidden' : ''}
            inputProps={{
              name: `${label}.aircraft`,
              id: label,
            }}
          >
            {this.renderMenuItems()}
          </Select>
        </FormControl>
      </div>
    );
  }
}
