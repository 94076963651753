import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import _isEmpty from 'lodash/isEmpty';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import { reformatPickerDateTimeFromDB } from '../../utils/time';

import './styles.scss';
import { backgroundColors } from '../Booking/config';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 80,
  },
  header: {
    background: 'linear-gradient(#00B8CC, #1FD9C5)',
  },
  iconColor: {
    color: '#00B8CC'
  }
});

@withNamespaces()
@withStyles(styles)
export default class BookingList extends Component {
  static propTypes = {
    bookings: PropTypes.array,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderList() {
    const { bookings, classes, t } = this.props;

    return bookings
      .map(({ id, bookingObject, startDate, endDate, type, user }) => (
        <TableRow key={`booking-${id}`}>
          <TableCell>
            <Tooltip
              title={t(`booking:${type}`)}
              placement="top"
            >
              <div
                className="status-circle"
                style={{
                  borderColor: backgroundColors[type]
                }}
              />
            </Tooltip>
          </TableCell>
          <TableCell className={classes.longCell}>{bookingObject.name}</TableCell>
          <TableCell className={classes.longCell}>{`${user.firstName} ${user.lastName}`}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateTimeFromDB(startDate, false)}</TableCell>
          <TableCell className={classes.longCell}>{reformatPickerDateTimeFromDB(endDate, false)}</TableCell>
        </TableRow>
      ));
  }

  render() {
    const { classes, bookings, t } = this.props;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <header className="header-dashboard">
          <span className={classNames('span-dashboard', 'py-3')}>
            <Icon className={classNames(classes.iconColor, 'notification-icon')}>
                date_range
            </Icon>
            <h4 className="h4-dashboard">{t('dashboard:myBookings')}</h4>
            <Link to="/booking" className="pt-1">
              <Icon className={classNames(classes.iconColor, 'notification-icon')}>
                keyboard_arrow_right
              </Icon>
            </Link>
          </span>
        </header>
        <div className={classNames(classes.root, 'px-2')}>
          { !_isEmpty(bookings) && <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header} />
                <TableCell className={classes.header}>{t('table:bookingObjectLower')}</TableCell>
                <TableCell className={classes.header}>{t('table:userName')}</TableCell>
                <TableCell className={classes.header}>{t('table:startDate')}</TableCell>
                <TableCell className={classes.header}>{t('table:endDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderList()}
            </TableBody>
          </Table> }
        </div>
      </Paper>
    );
  }
}

