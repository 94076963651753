import './styles.scss';

import React, { Component, Fragment } from 'react';
import {
  addFolder,
  editFolder,
  fetchFolders,
  removeFolder,
} from '../../actions/folders';
import {
  attachFolderFile,
  editFolderFile,
  removeFolderFile,
} from '../../actions/fileUpload';

import AddFileDialog from './AddFileDialog';
import AddFolderDialog from './AddFolderDialog';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FolderNavBar from '../../../common/components/FolderNavBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import LibraryFileList from './LibraryFileList';
import Loader from '../../../common/components/Loader';
import { PERMISSIONS } from '../../../constants/permissions';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import RemoveDialog from '../../../common/components/RemoveDialog';
import _isEmpty from 'lodash/isEmpty';
import { addSimpleError } from '../../actions/errors';
import { connect } from 'react-redux';
import { fetchGroups } from '../../actions/groups';
import { fetchRoles } from '../../actions/roles';
import { reformatPickerDateFromDB } from '../../utils/time';
import { renderPermission } from '../../decorators/permissions';
import userService from '../../../utils/userService';
import { withNamespaces } from 'react-i18next';

const INITIAL_STATE = {
  removeDialogOpen: false,
  addDialogOpen: false,
  editDialogOpen: false,
  addFileDialogOpen: false,
  folder: null,
  loading: false,
  currentFolderId: '',
};

const mapStateToProps = (state) => {
  return {
    folderList: state.folders.folderList,
    folderRequest: state.folders.folderRequest,
    roleList: state.users.roleList,
    roleRequest: state.users.roleRequest,
    schoolGroupList: state.groups.groupList,
    schoolGroupRequest: state.groups.groupRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchFolders: (userId, folderId) =>
      dispatch(fetchFolders(userId, folderId)),
    addFolder: (folder) => dispatch(addFolder(folder)),
    editFolder: (folder) => dispatch(editFolder(folder)),
    removeFolder: (folder) => dispatch(removeFolder(folder)),
    attachFolderFile: (file, itemId) =>
      dispatch(attachFolderFile(file, itemId)),
    editFolderFile: (file, folderId) =>
      dispatch(editFolderFile(file, folderId)),
    removeFolderFile: (fileId, folderId) =>
      dispatch(removeFolderFile(fileId, folderId)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    fetchRoles: () => dispatch(fetchRoles()),
    fetchSchoolGroups: () => dispatch(fetchGroups()),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class FolderList extends Component {
  static propTypes = {
    userId: PropTypes.number,
    folderList: PropTypes.object,
    folderRequest: PropTypes.bool.isRequired,
    fetchFolders: PropTypes.func.isRequired,
    roleList: PropTypes.array.isRequired,
    roleRequest: PropTypes.bool.isRequired,
    fetchRoles: PropTypes.func.isRequired,
    schoolGroupList: PropTypes.array.isRequired,
    schoolGroupRequest: PropTypes.bool.isRequired,
    fetchSchoolGroups: PropTypes.func.isRequired,
    addFolder: PropTypes.func.isRequired,
    editFolder: PropTypes.func.isRequired,
    removeFolder: PropTypes.func.isRequired,
    attachFolderFile: PropTypes.func.isRequired,
    editFolderFile: PropTypes.func.isRequired,
    removeFolderFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    lng: PropTypes.string.isRequired,
    preselectFolder: PropTypes.number,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.preselectFolder
      ? this.handleFolder(this.props.preselectFolder)
      : this.fetchFolders('');
    this.props.fetchRoles();
    this.props.fetchSchoolGroups();
  }

  componentDidUpdate(prevProps) {
    const { folderList, lng } = this.props;

    if (folderList !== prevProps.folderList || lng !== prevProps.lng) {
      this.updateCurrentFolder(folderList);
    }
  }

  updateCurrentFolder = (folderList) => {
    const { currentFolder } = folderList;

    this.setState({
      loading: false,
      currentFolderId: _isEmpty(currentFolder) ? '' : currentFolder.id,
    });
  };

  fetchFolders = (folderId) => {
    const userId = userService.getCurrentUser().id;

    this.setState({ loading: true }, () => {
      this.props.fetchFolders(userId, folderId);
    });
  };

  removeFolder = () => {
    const { folder } = this.state;

    this.closeRemoveDialog();
    this.props.removeFolder(folder);
  };

  openRemoveDialog = (e, folder) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      removeDialogOpen: true,
      folder,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      folder: null,
    });
  };

  openAddDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      folderList: { currentFolder },
    } = this.props;

    this.setState({
      addDialogOpen: true,
      folder: _isEmpty(currentFolder) ? null : currentFolder,
    });
  };

  openAddFileDialog = (e, folder) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      addFileDialogOpen: true,
      folder,
    });
  };

  openEditDialog = (e, folder) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      editDialogOpen: true,
      folder,
    });
  };

  closeAddEditDialog = () => {
    this.setState({
      addDialogOpen: false,
      editDialogOpen: false,
      folder: null,
    });
  };

  closeAddFileDialog = () => {
    this.setState({
      addFileDialogOpen: false,
      folder: null,
    });
  };

  @renderPermission(PERMISSIONS.deleteFolders)
  renderRemoveDialog(t) {
    return (
      <Dialog
        open={this.state.removeDialogOpen}
        onClose={this.closeRemoveDialog}
        aria-labelledby="remove-dialog"
        fullWidth
      >
        <RemoveDialog
          dialogTitle={t('folder:removeFolderDialogTitle')}
          message={t('folder:removeFolder')}
          closeRemoveDialog={this.closeRemoveDialog}
          removeFunction={this.removeFolder}
        />
      </Dialog>
    );
  }

  onAddFolder = (folder) => {
    const { currentFolderId } = this.state;

    folder = Object.assign({}, folder, { folderId: currentFolderId });
    this.props.addFolder(folder);
  };

  @renderPermission(PERMISSIONS.createFolders)
  renderAddEditDialog() {
    const { editFolder, roleList, schoolGroupList } = this.props;
    const { addDialogOpen, editDialogOpen, folder } = this.state;

    if (!addDialogOpen && !editDialogOpen) {
      return null;
    }

    return (
      <AddFolderDialog
        addDialogOpen={addDialogOpen}
        editDialogOpen={editDialogOpen}
        closeAddEditDialog={this.closeAddEditDialog}
        addFolder={this.onAddFolder}
        editFolder={editFolder}
        folder={folder}
        roleList={roleList}
        addDialogOpenState={addDialogOpen}
        editDialogOpenState={editDialogOpen}
        schoolGroupList={schoolGroupList}
      />
    );
  }

  @renderPermission(PERMISSIONS.deleteFolders)
  renderRemoveFolderButton(folder) {
    return (
      <IconButton onClick={(e) => this.openRemoveDialog(e, folder)}>
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  @renderPermission(PERMISSIONS.updateFolders)
  renderEditFolderButton(folder) {
    return (
      <IconButton onClick={(e) => this.openEditDialog(e, folder)}>
        <Icon color="primary">mode_edit</Icon>
      </IconButton>
    );
  }

  @renderPermission(PERMISSIONS.createFolders)
  renderAddFileDialog(t) {
    const { addFileDialogOpen, folder } = this.state;

    if (!addFileDialogOpen) {
      return null;
    }

    return (
      <AddFileDialog
        open={addFileDialogOpen}
        onCloseDialog={this.closeAddFileDialog}
        folder={folder}
      />
    );
  }

  @renderPermission(PERMISSIONS.createFolders)
  renderAddFileButton(folder) {
    const { addSimpleError, attachFolderFile } = this.props;

    return (
      <span className="file-inputs">
        <IconButton
          color="primary"
          onClick={(e) => this.openAddFileDialog(e, folder)}
        >
          <Icon color="primary">file_upload</Icon>
        </IconButton>
      </span>
    );
  }

  handlePathClick = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    this.handleFolder(path ? path.id : '');
  };

  handleFolder = (folderId) => {
    this.setState({ currentFolderId: folderId }, () => {
      this.fetchFolders(folderId);
    });
  };

  renderFolders() {
    const { folderList, editFolderFile, removeFolderFile } = this.props;
    const { loading, currentFolderId } = this.state;

    if (_isEmpty(folderList)) {
      return null;
    }

    return (
      <div className="folder-list-wrapper">
        {folderList.currentPath.length > 0 && (
          <FolderNavBar
            paths={folderList.currentPath}
            handlePathClick={this.handlePathClick}
            currentFolderId={currentFolderId}
            loading={loading}
          />
        )}

        {folderList.files.length > 0 && (
          <Fragment>
            <h3>Files</h3>
            <LibraryFileList
              folderId={currentFolderId}
              fileList={folderList.files}
              editFolderFile={editFolderFile}
              removeFolderFile={removeFolderFile}
            />
          </Fragment>
        )}

        <div className="folder-list">
          {folderList.folders.length > 0 && <h3>Folders</h3>}
          {folderList.folders.map((folder) => (
            <Paper
              elevation={3}
              className="folder-pannel"
              key={folder.id}
              onClick={() => this.handleFolder(folder.id)}
            >
              <div className="folder-pannel-title col">
                {currentFolderId === folder.id && loading && (
                  <div className="small-loader" />
                )}
                <span className="mx-1">{folder.name}</span>
              </div>
              <div className="mx-1 col-auto">
                {reformatPickerDateFromDB(folder.createdAt, false)}
              </div>
              <div className="col-auto">
                {this.renderAddFileButton(folder)}
                {this.renderEditFolderButton(folder)}
                {this.renderRemoveFolderButton(folder)}
              </div>
            </Paper>
          ))}
        </div>
      </div>
    );
  }

  @renderPermission(PERMISSIONS.createFolders)
  renderAddFolderButton(t) {
    return (
      <Button color="primary" onClick={this.openAddDialog}>
        <Icon color="primary" className="mr-3">
          playlist_add
        </Icon>
        {t('folder:addFolderButton')}
      </Button>
    );
  }

  render() {
    const { folderRequest, roleRequest, schoolGroupRequest, t } = this.props;

    if (folderRequest || roleRequest || schoolGroupRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center folders">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">book</Icon>
              <h1>{t('folder:name')}</h1>
            </span>
            <p>{t('folder:description')}</p>
          </header>
        </div>
        {this.renderAddFolderButton(t)}
        <div className="col-12 pt-5">{this.renderFolders()}</div>
        {this.renderRemoveDialog(t)}
        {this.renderAddEditDialog(t)}
        {this.renderAddFileDialog(t)}
      </div>
    );
  }
}
