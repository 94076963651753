import '../../styles.scss';

import React, { Component } from 'react';
import { calculateTotalTime, reformatTime } from '../../../../utils/time';

import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { ZERO_TIME } from '../../../../../constants';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100px'
  },
  totalTime: {
    width: '100px',
    fontWeight: 'bold'
  }
});

@withNamespaces()
class LogbookSummary extends Component {
 static propTypes = {
   flightLogList: PropTypes.array,
   classes: PropTypes.object.isRequired,
   t: PropTypes.func.isRequired,
 };

 renderFlightExperience = (name, label, value, style = this.props.classes.root) => {

   return (
     <div className="col-sm-1 col-xs-12 py-2 mr-4 ml-1">
       <TextField
         className={style}
         disabled
         name={name}
         label={label}
         type="text"
         value={value}
       />
     </div>
   );
 };

 prepareValues = (fieldName) => {
   const { flightLogList } = this.props;

   return calculateTotalTime(_reduce(flightLogList, (fieldsToSum, flightLog) => {
     fieldsToSum.push(reformatTime(flightLog[fieldName] || ZERO_TIME));

     return fieldsToSum;
   }, []));
 };

 prepareNumberValues = (fieldName) => {
   const { flightLogList } = this.props;

   return _reduce(flightLogList, (sum, flightLog) => {
     return sum = Number(sum) + Number(flightLog[fieldName] || 0);
   }, 0);
 };

 render() {
   const { flightLogList, t } = this.props;

   if (_isEmpty(flightLogList)) {
     return null;
   }

   return (
     <div className="col-12">
       <div className="py-2">
         <h4>{t('settings:logbookSummary')}</h4>
       </div>
       <div className="pb-4">
         {t('settings:totalFlightTime')}
       </div>
       <div className="row">
         { this.renderFlightExperience('totalTime', 'total time', this.prepareValues('totalTime')) }
         { this.renderFlightExperience('pic', 'pic', this.prepareValues('pic')) }
         { this.renderFlightExperience('sic', 'sic', this.prepareValues('sic')) }
         { this.renderFlightExperience('picus', 'picus', this.prepareValues('picus')) }
         { this.renderFlightExperience('dual', 'dual', this.prepareValues('dual')) }
         { this.renderFlightExperience('singlePilot', 'single-pilot', this.prepareValues('singlePilot')) }
         { this.renderFlightExperience('multiPilot', 'multi-pilot', this.prepareValues('multiPilot')) }
         { this.renderFlightExperience('instructor', 'instructor', this.prepareValues('instructor')) }
       </div>
       <div className="py-4">
         {t('settings:aircraftExperience')}
       </div>
       <div className="row">
         { this.renderFlightExperience('singleEngine', 'single-engine', this.prepareValues('singleEngine')) }
         { this.renderFlightExperience('multiEngine', 'multi-engine', this.prepareValues('multiEngine')) }
       </div>
       <div className="row">
         { this.renderFlightExperience('airplane', 'airplane', this.prepareValues('airplane')) }
         { this.renderFlightExperience('pistonAirplane', 'piston', this.prepareValues('pistonAirplane')) }
         { this.renderFlightExperience('turboprop', 'turboprop', this.prepareValues('turboprop')) }
         { this.renderFlightExperience('jet', 'jet', this.prepareValues('jet'))}
       </div>
       <div className="row">
         { this.renderFlightExperience('helicopter', 'helicopter', this.prepareValues('helicopter')) }
         { this.renderFlightExperience('pistonHelicopter', 'piston', this.prepareValues('pistonHelicopter')) }
         { this.renderFlightExperience('turbine', 'turbine', this.prepareValues('turbine')) }
       </div>
       <div className="row">
         { this.renderFlightExperience('simulator', 'simulator', this.prepareValues('simulator')) }
       </div>
       <div className="py-4">
         {t('settings:operationalFlightConditions')}
       </div>
       <div className="row">
         { this.renderFlightExperience('day', 'day', this.prepareValues('day')) }
         { this.renderFlightExperience('night', 'night', this.prepareValues('night')) }
         { this.renderFlightExperience('vfr', 'VFR', this.prepareValues('vfr')) }
         { this.renderFlightExperience('ifr', 'IFR', this.prepareValues('ifr')) }
         { this.renderFlightExperience('xCountry', 'X-Country', this.prepareValues('xCountry')) }
       </div>
       <div className="py-4">
         {t('settings:landings')}
       </div>
       <div className="row">
         { this.renderFlightExperience('dayLandings', 'day landings', this.prepareNumberValues('dayLandings')) }
         { this.renderFlightExperience('nightLandings', 'night landings', this.prepareNumberValues('nightLandings')) }
       </div>
     </div>
   );
 }
}


export default withStyles(styles)(LogbookSummary);

