import './styles.scss';

import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  header: {
    background: 'linear-gradient(#9E3B3B, #EA6442)',
  },
  bootstrapRoot: {
    fontSize: 12,
    boxShadow: 'none',
    textTransform: 'none',
    padding: '0px 10px',
    margin: '10px 10px',
    border: '1px solid',
    borderRadius: 25,
  },
  bootstrapRootElearning: {
    borderColor: '#00B8CC',
    '&:hover': {
      backgroundColor: '#E5F8FA',
    },
  },
  bootstrapRootExamsAndCourses: {
    borderColor: '#549358',
    '&:hover': {
      backgroundColor: '#DEEADE',
    },
  },
  textColor: {
    color: '#1A1A1A',
  },
  iconColor: {
    color: '#549358',
  },
  eLearningIconColor: {
    color: '#00B8CC',
  },
});

@withNamespaces()
@withStyles(styles)
export default class ExpirationList extends Component {
  static propTypes = {
    courseList: PropTypes.array,
    examList: PropTypes.array,
    firstName: PropTypes.string,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  filterElementsToNotify = (list) => {
    return list.filter((element) => !element.isNotified);
  };

  checkIfRender = (list) => {
    const elementsToNotify = this.filterElementsToNotify(list);

    return elementsToNotify.length > 0;
  };

  getCourseNotification = (courseList, t) => {
    const notNotifiedCourses = this.filterElementsToNotify(courseList);

    return (
      notNotifiedCourses &&
      t('dashboard:courseToNotifyNumber', { count: notNotifiedCourses.length })
    );
  };

  getExamNotification = (examList, t) => {
    const notNotifiedExams = this.filterElementsToNotify(examList);

    return (
      notNotifiedExams &&
      t('dashboard:examToNotifyNumber', { count: notNotifiedExams.length })
    );
  };

  render() {
    const { firstName, classes, t } = this.props;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <div className="pl-4 py-2">
          <h4>
            {t('dashboard:welcomeNew')} {firstName}!
          </h4>
        </div>
      </Paper>
    );
  }
}
