import React, { Component } from 'react';
import { attachPartFile, removePartFile } from '../../../actions/fileUpload';
import {
  editPart,
  fetchUnmountedParts,
  mountPart,
  undoPart,
  unmountPart,
} from '../../../actions/parts';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PartList from './PartList';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import TextField from '@material-ui/core/TextField';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  showRemoveDialog: false,
  unmountOpen: false,
  id: '',
  installationId: '',
  name: '',
  partNumber: '',
  serialNumber: '',
  manufacturer: '',
  description: '',
  hoursToService: 0,
  cyclesToService: 0,
  cyclesToEOL: 0,
  cycles: 0,
  usageHours: 0,
  serviceDate: moment(),
  eolDate: moment(),
  mountingDate: moment(),
  unmountingDate: moment(),
  removeCallback: () => {},
  part: null,
  savingForm: false,
  partStatus: '',
  editLocked: true,
  changeReason: '',
};

const mapStateToProps = (state) => {
  return {
    unmountedParts: state.parts.unmountedParts,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUnmountedParts: () => dispatch(fetchUnmountedParts()),
    mountPart: (fleet, data) => dispatch(mountPart(fleet, data)),
    editPart: (data) => dispatch(editPart(data)),
    unmountPart: (data) => dispatch(unmountPart(data)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    attachPartFile: (fileData, id, fleetId) =>
      dispatch(attachPartFile(fileData, id, fleetId)),
    removePartFile: (fileId, id) => dispatch(removePartFile(fileId, id)),
    undoPart: (data) => dispatch(undoPart(data)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(() => mapStateToProps, mapDispatchToProps)
export default class PartsManager extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    partList: PropTypes.array.isRequired,
    partFleetList: PropTypes.array.isRequired,
    partStatusList: PropTypes.array.isRequired,
    fetchUnmountedParts: PropTypes.func.isRequired,
    mountPart: PropTypes.func.isRequired,
    editPart: PropTypes.func.isRequired,
    unmountPart: PropTypes.func.isRequired,
    undoPart: PropTypes.func.isRequired,
    attachPartFile: PropTypes.func.isRequired,
    removePartFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    fleetSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    unmountedParts: PropTypes.array.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.formData = {};
  }

  handleAddOpen = () => {
    const { fetchUnmountedParts } = this.props;

    this.setState({
      addOpen: true,
    });

    fetchUnmountedParts();
  };

  handleUnlockFields = () => {
    this.setState({
      editLocked: false,
    });
  };

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  handleEditOpen = (part) => {
    const { fleet } = this.props;

    const hoursToService = part.serviceHours
      ? Number(part.serviceHours) - Number(fleet.currentHours)
      : null;
    const cyclesToService = part.serviceCycles
      ? Number(part.serviceCycles) - Number(fleet.currentCycles)
      : null;
    const cyclesToEOL = part.eolCycles
      ? Number(part.eolCycles) - Number(fleet.currentCycles)
      : null;

    this.setState({
      editOpen: true,
      part: part.id,
      id: part.id,
      name: part.name,
      partNumber: part.partNumber,
      serialNumber: part.serialNumber,
      manufacturer: part.manufacturer,
      description: part.description,
      hoursToService,
      cyclesToService,
      cyclesToEOL,
      usageHours: part.usageHours,
      cycles: part.cycles,
      serviceDate: reformatPickerDateFromDB(
        _get(part, 'serviceDate', moment()),
      ),
      eolDate: reformatPickerDateFromDB(_get(part, 'eolDate', moment())),
      mountingDate: reformatPickerDateFromDB(
        _get(part, 'mountingDate', moment()),
      ),
      partStatus: part.partStatus,
    });
  };

  handleUnmountOpen = (part) => {
    this.setState({
      id: part.id,
      unmountOpen: true,
      installationId: part.installationId,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  onChange = (formData, name) => {
    const { partList, fleet } = this.props;
    const { savingForm } = this.state;
    const { value } = formData[name];

    this.formData = formData;

    if (name === 'id' && !savingForm) {
      const part = _find(partList, ['id', Number(value)]);

      if (part) {
        const hoursToService = part.serviceHours
          ? Number(part.serviceHours) - Number(fleet.currentHours)
          : 0;
        const cyclesToService = part.serviceCycles
          ? Number(part.serviceCycles) - Number(fleet.currentCycles)
          : 0;
        const cyclesToEOL = part.eolCycles
          ? Number(part.eolCycles) - Number(fleet.currentCycles)
          : 0;

        this.setState(() => ({
          [name]: value,
          id: part.id,
          name: part.name,
          partNumber: part.partNumber,
          serialNumber: part.serialNumber,
          manufacturer: part.manufacturer,
          description: part.description,
          hoursToService,
          cyclesToService,
          cyclesToEOL,
          usageHours: part.usageHours,
          cycles: part.cycles,
          serviceDate:
            reformatPickerDateFromDB(_get(part, 'serviceDate')) || moment(),
          eolDate: reformatPickerDateFromDB(_get(part, 'eolDate')) || moment(),
          partStatus: part.partStatus,
        }));

        return;
      }
    }

    this.setState(() => ({ [name]: value }));
  };

  mountPart = () => {
    const { fleet, mountPart } = this.props;
    const {
      id,
      cyclesToService,
      cyclesToEOL,
      serviceDate,
      eolDate,
      mountingDate,
      hoursToService,
      usageHours,
      cycles,
    } = this.state;

    const serviceHours = hoursToService
      ? Number(fleet.currentHours) + Number(hoursToService)
      : null;
    const serviceCycles = cyclesToService
      ? Number(fleet.currentCycles) + Number(cyclesToService)
      : null;
    const eolCycles = cyclesToEOL
      ? Number(fleet.currentCycles) + Number(cyclesToEOL)
      : null;

    const data = {
      id,
      serviceHours,
      serviceCycles,
      eolCycles,
      usageHours,
      cycles,
      serviceDate: reformatPickerDateToDB(serviceDate),
      eolDate: reformatPickerDateToDB(eolDate),
      mountingDate: reformatPickerDateToDB(mountingDate || moment()),
      partStatus: 'SERVICEABLE',
    };

    mountPart(fleet.id, data);
    this.handleDialogClose();
  };

  editPart = () => {
    const { fleet, editPart } = this.props;
    const {
      id,
      name,
      partNumber,
      serialNumber,
      manufacturer,
      description,
      cyclesToService,
      cyclesToEOL,
      serviceDate,
      eolDate,
      mountingDate,
      hoursToService,
      partStatus,
      changeReason,
      usageHours,
      cycles,
    } = this.state;

    const serviceHours = hoursToService
      ? Number(fleet.currentHours) + Number(hoursToService)
      : null;
    const serviceCycles = cyclesToService
      ? Number(fleet.currentCycles) + Number(cyclesToService)
      : null;
    const eolCycles = cyclesToEOL
      ? Number(fleet.currentCycles) + Number(cyclesToEOL)
      : null;

    const data = {
      id,
      name,
      partNumber,
      serialNumber,
      manufacturer,
      description,
      serviceHours,
      serviceCycles,
      eolCycles,
      serviceDate: reformatPickerDateToDB(serviceDate),
      eolDate: reformatPickerDateToDB(eolDate),
      mountingDate: reformatPickerDateToDB(mountingDate || moment()),
      fleet: fleet.id,
      partStatus: partStatus,
      changeReason: changeReason,
      usageHours: Number(usageHours),
      cycles: Number(cycles),
    };

    editPart(data);
    this.handleDialogClose();
  };

  saveUnmountPart = () => {
    const { unmountPart } = this.props;
    const { id, installationId, unmountingDate } = this.state;
    const data = {
      id: installationId,
      partId: id,
      unmountingDate: reformatPickerDateToDB(unmountingDate || moment()),
    };

    unmountPart(data);
    this.handleDialogClose();
  };

  onFormValidated = (isFormValid) => {
    const { addOpen } = this.state;

    if (isFormValid && this.formData) {
      addOpen ? this.mountPart() : this.editPart();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(
      {
        savingForm: true,
      },
      () => {
        this.triggerFormValidation();

        setImmediate(() => {
          this.setState({ savingForm: false });
        });
      },
    );
  };

  renderPartList() {
    const { partList, unmountedParts } = this.props;
    const { editOpen } = this.state;

    if (editOpen) {
      return partList.map((part) => ({
        value: part.id,
        label: `${part.name} / ${part.partNumber}`,
      }));
    }

    return unmountedParts
      .filter(
        (part) =>
          _isEmpty(part.installations) ||
          part.installations.every((installation) => !installation.isActive),
      )
      .map((part) => ({
        value: part.id,
        label: `${part.name} / ${part.partNumber} / ${part.serialNumber}`,
      }));
  }

  render() {
    const {
      fleet,
      classes,
      t,
      partFleetList,
      undoPart,
      attachPartFile,
      removePartFile,
      addSimpleError,
      editMode,
      fleetSettings,
      partStatusList,
    } = this.props;
    const {
      addOpen,
      editOpen,
      name,
      serialNumber,
      manufacturer,
      cyclesToService,
      cyclesToEOL,
      serviceDate,
      eolDate,
      mountingDate,
      showRemoveDialog,
      removeCallback,
      id,
      unmountOpen,
      unmountingDate,
      hoursToService,
      usageHours,
      cycles,
      partStatus,
      editLocked,
      changeReason,
    } = this.state;

    const editTitle = t('fleet:editPartDialogTitle') + ' - ' + name;

    return (
      <div className="part-settings">
        <h4>{t('fleet:partsSection')}</h4>
        <PartList
          fleet={fleet}
          partList={partFleetList}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          handleUnmountOpen={this.handleUnmountOpen}
          onElementDelete={this.openRemoveDialog}
          attachPartFile={attachPartFile}
          removePartFile={removePartFile}
          addSimpleError={addSimpleError}
          editMode={editMode}
          fleetSettings={fleetSettings}
          undoPart={undoPart}
        />
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={addOpen || editOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="add-edit-part-type-dialog"
          maxWidth="md"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle id="add-edit-part-type-dialog">
              {addOpen ? t('fleet:mountPartDialogTitle') : editTitle}
            </DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row">
                <div className="col-12">
                  {editOpen ? (
                    ''
                  ) : (
                    <MaterialSelect
                      id="id"
                      name="id"
                      label={t('input:name')}
                      className={editOpen ? 'svg-arrow-hidden' : ''}
                      defaultValue={id}
                      options={this.renderPartList()}
                      disabled={editOpen}
                      validators={[new validators.IsRequired(t)]}
                    />
                  )}
                </div>
                <div className="col-6">
                  <TextField
                    label={t('input:serialNumber')}
                    name="serialNumber"
                    value={serialNumber}
                    margin="dense"
                    disabled={editLocked}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <TextField
                    label={t('input:manufacturer')}
                    name="manufacturer"
                    value={manufacturer}
                    margin="dense"
                    disabled={editLocked}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:Total usage hours')}
                    name="usageHours"
                    defaultValue={usageHours}
                    stateValue={usageHours}
                    disabled={editLocked}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:Total cycles')}
                    name="cycles"
                    defaultValue={cycles}
                    stateValue={cycles}
                    disabled={editLocked}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:hoursToService')}
                    name="hoursToService"
                    defaultValue={hoursToService}
                    stateValue={hoursToService}
                    disabled={editLocked && !addOpen}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6" />
                <div className="col-6">
                  <MaterialInput
                    label={t('input:cyclesToService')}
                    name="cyclesToService"
                    defaultValue={cyclesToService}
                    stateValue={cyclesToService}
                    disabled={editLocked && !addOpen}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:cyclesToEOL')}
                    name="cyclesToEOL"
                    defaultValue={cyclesToEOL}
                    stateValue={cyclesToEOL}
                    disabled={editLocked && !addOpen}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:serSviceDate')}
                    name="serviceDate"
                    value={serviceDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    disabled={editLocked && !addOpen}
                    handleDatePickerChange={this.handleDatePickerChange(
                      'serviceDate',
                    )}
                    dateFormat={getDateFormat()}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:eolDate')}
                    name="eolDate"
                    value={eolDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    disabled={editLocked && !addOpen}
                    handleDatePickerChange={this.handleDatePickerChange(
                      'eolDate',
                    )}
                    dateFormat={getDateFormat()}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:mountingDate')}
                    name="mountingDate"
                    value={mountingDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    disabled={editLocked && !addOpen}
                    handleDatePickerChange={this.handleDatePickerChange(
                      'mountingDate',
                    )}
                    dateFormat={getDateFormat()}
                    fullWidth
                  />
                  {addOpen ? (
                    ''
                  ) : (
                    <MaterialSelect
                      id="partStatus"
                      name="partStatus"
                      label={t('input:partStatus')}
                      options={partStatusList.map((partStatus) => ({
                        value: partStatus.value,
                        label: partStatus.key,
                      }))}
                      defaultValue={partStatus}
                      disabled={editLocked}
                      validators={[new validators.IsRequired(t)]}
                    />
                  )}
                </div>
                {editLocked ? (
                  ''
                ) : (
                  <div className="col-6">
                    <MaterialInput
                      label={t('input:Change reason')}
                      name="changeReason"
                      defaultValue={changeReason}
                      stateValue={changeReason}
                      margin="dense"
                      validators={[new validators.IsRequired(t)]}
                      fullWidth
                    />
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              {addOpen ? (
                ''
              ) : (
                <Button color="secondary" onClick={this.handleUnlockFields}>
                  {t('Unlock')}
                </Button>
              )}
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>

        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={unmountOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="unmount-part-type-dialog"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="unmount-part-type-dialog">
            {t('fleet:unmountPartDialogTitle')}
          </DialogTitle>
          <DialogContent className={classes.root}>
            <div className="row">
              <div className="col-6">
                <DateTimePicker
                  label={t('input:unmountingDate')}
                  name="unmountingDate"
                  value={unmountingDate}
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={this.handleDatePickerChange(
                    'unmountingDate',
                  )}
                  dateFormat={getDateFormat()}
                  disabled={editOpen}
                  fullWidth
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleDialogClose}>
              {t('buttonCancel')}
            </Button>
            <Button color="primary" onClick={this.saveUnmountPart}>
              {t('buttonSave')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
