import '../../styles.scss';

import React, { Component } from 'react';
import {
  getDateFormat,
  handleTimeInputChange,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
  reformatTime,
  sumTime,
} from '../../../../utils/time';

import DateTimePicker from '../../../../../common/components/forms/DateTimePicker';
import Form from '../../../../../common/components/forms/Form';
import MaterialInput from '../../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import _each from 'lodash/each';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import moment from 'moment';
import validators from '../../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100px'
  },
  totalTime: {
    width: '100px',
    fontWeight: 'bold'
  }
});

@withNamespaces()
@withStyles(styles)
export default class PreviousFlightsHistory extends Component {
 static propTypes = {
   flightExperience: PropTypes.object,
   classes: PropTypes.object.isRequired,
   registerComponentForm: PropTypes.func.isRequired,
   formValidated: PropTypes.func.isRequired,
   t: PropTypes.func.isRequired,
 };

 state = {
   upToDate: moment(),
   ...this.props.flightExperience,
 };

 removeDateFromData = (collection) => {

   return _omit(collection, ['upToDate']);
 };

 getFlightTime = (totalFlightTime) => {
   const { airplane, helicopter } = this.state;

   if (totalFlightTime) {
     return reformatTime(totalFlightTime);
   }

   return sumTime(airplane, helicopter);
 };

 registerForm = (triggerFormValidation) => {
   this.props.registerComponentForm(triggerFormValidation);
 };

 onChange = (formData) => {
   const valuesToSave = {};
   const preparedFormData = this.removeDateFromData(formData);

   _each(preparedFormData, ({ value }, key) => {
     const data = handleTimeInputChange(key, value);

     valuesToSave[data.key] = data.value;
   });

   const totalFlightTime = sumTime(preparedFormData.airplane.value, preparedFormData.helicopter.value);

   valuesToSave.totalFlightTime = totalFlightTime;

   this.setState(valuesToSave);
 };

 onFormValidated = (isFormValid) => {
   this.props.formValidated('previousFlightHistory', isFormValid, this.prepareFlightExperience());
 };

 prepareFlightExperience = () => {
   const preparedState = {
     ...this.state,
     upToDate: reformatPickerDateToDB(this.state.upToDate)
   };

   return _omit(preparedState, ['totalFlightTime']);
 };

 renderFlightExperience = (name, label, value) => {
   const { classes, t } = this.props;

   return (
     <div className="col-sm-1 col-xs-12 py-2 mr-4 ml-1">
       <MaterialInput
         className={classes.root}
         name={name}
         label={label}
         type="text"
         defaultValue={reformatTime(value)}
         InputLabelProps={{
           shrink: true,
         }}
         validators={[
           new validators.IsTimeFormatValid(t),
         ]}
       />
     </div>
   );
 }

 handleDatePickerChange = (date) => {
   this.setState({
     upToDate: date
   });
 };

 render() {
   const { classes, flightExperience, t } = this.props;
   const {
     upToDate,
     airplane,
     day,
     dual,
     helicopter,
     ifr,
     instructor,
     jet,
     multiEngine,
     multiPilot,
     night,
     pic,
     picus,
     pistonAirplane,
     pistonHelicopter,
     sic,
     simulator,
     singleEngine,
     singlePilot,
     turbine,
     turboprop,
     vfr,
     xCountry,
     totalFlightTime
   } = this.state;

   if (_isEmpty(flightExperience)) {
     return null;
   }

   return (
     <Form
       onChange={this.onChange}
       onFormValidated={this.onFormValidated}
       registerForm={this.registerForm}
     >
       <div className="col-12 pb-3">
         <div className="py-2">
           <h4>{t('settings:previousFlightsHistory')}</h4>
         </div>
         <div className="col-sm-2 col-xs-12 py-2 pl-0">
           <DateTimePicker
             fullWidth
             value={reformatPickerDateFromDB(upToDate)}
             showMonthDropdown
             showTimeSelect={false}
             showYearDropdown
             handleDatePickerChange={this.handleDatePickerChange}
             dateFormat={getDateFormat()}
             name="upToDate"
             label={t('input:upToDate')}
           />
         </div>
         <div className="py-4">
           {t('settings:totalFlightTime')}
         </div>
         <div className="row">
           <div className="col-sm-1 col-xs-12 py-2 mr-4 ml-1">
             <TextField
               className={classes.totalTime}
               name="totalTime"
               label="total time"
               value={this.getFlightTime(totalFlightTime)}
             />
           </div>
           { this.renderFlightExperience('pic', 'pic', pic) }
           { this.renderFlightExperience('sic', 'sic', sic) }
           { this.renderFlightExperience('picus', 'picus', picus) }
           { this.renderFlightExperience('dual', 'dual', dual) }
           { this.renderFlightExperience('singlePilot', 'single-pilot', singlePilot) }
           { this.renderFlightExperience('multiPilot', 'multi-pilot', multiPilot) }
           { this.renderFlightExperience('instructor', 'instructor', instructor) }
         </div>
         <div className="py-4">
           {t('settings:aircraftExperience')}
         </div>
         <div className="row">
           { this.renderFlightExperience('singleEngine', 'single-engine', singleEngine) }
           { this.renderFlightExperience('multiEngine', 'multi-engine', multiEngine) }
         </div>
         <div className="row">
           { this.renderFlightExperience('airplane', 'airplane', airplane) }
           { this.renderFlightExperience('pistonAirplane', 'piston', pistonAirplane) }
           { this.renderFlightExperience('turboprop', 'turboprop', turboprop) }
           { this.renderFlightExperience('jet', 'jet', jet)}
         </div>
         <div className="row">
           { this.renderFlightExperience('helicopter', 'helicopter', helicopter) }
           { this.renderFlightExperience('pistonHelicopter', 'piston', pistonHelicopter) }
           { this.renderFlightExperience('turbine', 'turbine', turbine) }
         </div>
         <div className="row">
           { this.renderFlightExperience('simulator', 'simulator', simulator) }
         </div>
         <div className="py-4">
           {t('settings:operationalFlightConditions')}
         </div>
         <div className="row">
           { this.renderFlightExperience('day', 'day', day) }
           { this.renderFlightExperience('night', 'night', night) }
           { this.renderFlightExperience('vfr', 'vfr', vfr) }
           { this.renderFlightExperience('ifr', 'ifr', ifr) }
           { this.renderFlightExperience('xCountry', 'X-Country', xCountry) }
         </div>
       </div>
     </Form>
   );
 }
}
