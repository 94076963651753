import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { editReport } from '../../../actions/reports';
import { getDateFormat } from '../../../utils/time';
import moment from 'moment';
import { reformatPickerDateToDB } from '../../../utils/time';
import userService from '../../../../utils/userService';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    overflowY: 'visible',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 180,
  },
  colorCell: {
    width: 20,
  },
  link: {
    color: 'black',
  },
});

function mapDispatchToProps(dispatch) {
  return {
    completeReport: (report) => dispatch(editReport(report, [], true)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(null, mapDispatchToProps)
export default class ReportList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    completeReport: PropTypes.func.isRequired,
    fleet: PropTypes.object.isRequired,
    reports: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    open: false,
    closedDate: moment(),
  };

  complete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  handleDatePickerChange = (closedDate) => {
    this.setState({
      closedDate,
    });
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  onCloseDialog() {
    this.setState({ open: false });
  }

  onOpenCompleteDialog(report) {
    this.setState({
      open: true,
      report,
    });
  }

  onFormValidated = (isFormValid) => {
    if (isFormValid && this.formData) {
      const { completeReport } = this.props;
      const { closedDate, report } = this.state;

      const userId = userService.getCurrentUser().id;

      completeReport({
        id: report.id,
        assignee: userId,
        comments: this.formData.comments.value,
        closedDate: reformatPickerDateToDB(closedDate),
        status: 'CLOSED',
      });

      this.onCloseDialog();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  renderButtons(report) {
    return (
      <>
        <Link to={`/report/${report.id}`}>
          <Icon color="primary">mode_edit</Icon>
        </Link>
      </>
    );
  }

  renderCompleteDialog() {
    const { classes, t } = this.props;
    const { closedDate, open } = this.state;

    return (
      <Dialog
        PaperProps={{
          classes: { root: classes.root },
        }}
        open={open}
        aria-labelledby="complete-report-dialog"
        maxWidth="md"
        fullWidth
      >
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <DialogTitle id="assign-report-dialog">
            {t('report:Complete a report')}
          </DialogTitle>
          <DialogContent className={classes.root}>
            <div className="row">
              <div className="col-auto">
                <DateTimePicker
                  fullWidth
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={this.handleDatePickerChange}
                  dateFormat={getDateFormat()}
                  value={closedDate}
                  name="closedDate"
                  label={t('input:Close date')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MaterialInput
                  fullWidth
                  multiline
                  margin="dense"
                  name="comments"
                  rows={5}
                  label={t('report:Comments')}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => this.onCloseDialog()}>
              {t('buttonCancel')}
            </Button>
            <Button color="primary" onClick={this.complete}>
              {t('Close report')}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    );
  }

  renderReports() {
    const { reports, classes, fleet, t } = this.props;

    return reports.map((report) => {
      const link = `/report/${report.id}?buri=/fleet/details/${fleet.id}`;

      return (
        <TableRow key={`report-${report.id}`}>
          <TableCell className={classes.longCell}>
            <Link className={classes.link} to={link}>
              {report.date}
            </Link>
          </TableCell>
          <TableCell className={classes.longCell}>
            <Link className={classes.link} to={link}>
              {report.subject}
            </Link>
          </TableCell>
          <TableCell className={classes.longCell}>
            <Link className={classes.link} to={link}>
              {report.category}
            </Link>
          </TableCell>
          <TableCell className={classes.longCell}>
            <Link className={classes.link} to={link}>
              {report.subcategory}
            </Link>
          </TableCell>
          <TableCell className={classes.longCell}>
            <Link className={classes.link} to={link}>
              {t(`report:${report.status}`)}
            </Link>
          </TableCell>
          <TableCell className={classes.longCell}>
            {report.status === 'CREATED' ? (
              <IconButton onClick={() => this.onOpenCompleteDialog(report)}>
                <Icon color="primary">check_circle</Icon>
              </IconButton>
            ) : (
              ''
            )}
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { reports, classes, t } = this.props;

    if (_isEmpty(reports)) {
      return <></>;
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:date')}</TableCell>
              <TableCell>{t('table:subject')}</TableCell>
              <TableCell>{t('table:category')}</TableCell>
              <TableCell>{t('table:subcategory')}</TableCell>
              <TableCell>{t('table:status')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderReports()}</TableBody>
        </Table>
        {this.renderCompleteDialog()}
      </div>
    );
  }
}
