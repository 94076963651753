import axios from 'axios';
import qs from 'qs';
import history from '../store/history';
import _isEmpty from 'lodash/isEmpty';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';
import { getDashboardQueryParametersFilter } from '../utils/time';
import { fileUploadPromise } from '../../utils/fileUpload';

export const FETCH_EQUIPMENTS_REQUEST = 'FETCH_EQUIPMENTS_REQUEST';
export const FETCH_EQUIPMENTS_SUCCESS = 'FETCH_EQUIPMENTS_SUCCESS';
export const FETCH_EQUIPMENT_REQUEST = 'FETCH_EQUIPMENT_REQUEST';
export const FETCH_EQUIPMENT_SUCCESS = 'FETCH_EQUIPMENT_SUCCESS';
export const ADD_EQUIPMENT = 'ADD_EQUIPMENT';
export const EDIT_EQUIPMENT = 'EDIT_EQUIPMENT';
export const REMOVE_EQUIPMENT = 'REMOVE_EQUIPMENT';
export const CLEAN_EQUIPMENT_DATA = 'CLEAN_EQUIPMENT_DATA';

const sortingPossibitilies = {
  name: 'name',
  type: 'type',
  location: 'location',
  certificate: 'certificate',
  issueDate: 'issue_date',
  expiryDate: 'expiry_date'
};

export function fetchEquipmentsRequest() {
  return {
    type: FETCH_EQUIPMENTS_REQUEST
  };
}

export function fetchEquipmentsSuccess(equipments) {
  return {
    type: FETCH_EQUIPMENTS_SUCCESS,
    payload: {
      equipments
    }
  };
}

export function fetchEquipmentRequest() {
  return {
    type: FETCH_EQUIPMENT_REQUEST
  };
}

export function fetchEquipmentSuccess(equipment) {
  return {
    type: FETCH_EQUIPMENT_SUCCESS,
    payload: {
      equipment
    }
  };
}

export function addEquipmentSuccess(equipment) {
  return {
    type: ADD_EQUIPMENT,
    payload: {
      equipment
    }
  };
}

export function editEquipmentSuccess(equipment) {
  return {
    type: EDIT_EQUIPMENT,
    payload: {
      equipment
    }
  };
}

export function removeEquipmentSuccess(equipmentId) {
  return {
    type: REMOVE_EQUIPMENT,
    payload: {
      equipmentId
    }
  };
}

export function cleanEquipment() {
  return {
    type: CLEAN_EQUIPMENT_DATA,
  };
}

export function fetchEquipments(sortingData) {
  return (dispatch) => {
    dispatch(fetchEquipmentsRequest());

    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (!_isEmpty(sortingData.columnToSort)) {
      queryParameters.ordering = `${sortingData.sortAsc ? '' : '-'}${sortingPossibitilies[sortingData.columnToSort]}`;
    }

    queryParameters = qs.stringify(queryParameters);
    queryParameters = _isEmpty(queryParameters) ? '' : `?${queryParameters}`;

    return axios.get(urlJoin(URLS.equipments, queryParameters))
      .then(({ data }) => dispatch(fetchEquipmentsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during equipments fetching ${error.message}`, error));
      });
  };
}

export function fetchDashboardEquipments() {
  return (dispatch) => {
    const queryParameters = `?${getDashboardQueryParametersFilter()}&ordering=expiry_date`;

    return axios.get(urlJoin(URLS.equipments, queryParameters))
      .then(({ data }) => dispatch(fetchEquipmentsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during equipments fetching ${error.message}`, error));
      });
  };
}

export function fetchEquipment(equipmentId) {
  return (dispatch) => {
    dispatch(fetchEquipmentRequest());

    return axios.get(urlJoin(URLS.equipments, equipmentId))
      .then(({ data }) => {
        dispatch(fetchEquipmentSuccess(data));
      })
      .catch((error) => {
        dispatch(addError(`Error during equipment fetching ${error.message}`));
      });
  };
}

export function removeEquipment(equipmentId) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.equipments, equipmentId))
      .then(() => dispatch(removeEquipmentSuccess(equipmentId)))
      .catch((error) => {
        dispatch(addError(`Error during equipment removing ${error.message}`, error));
      });
  };
}

export function editEquipment(equipment, updatedFiles, deletedFiles) {
  return (dispatch) => {

    return axios.patch(urlJoin(URLS.equipments, equipment.id), equipment)
      .then((data) => {
        deletedFiles.map((fileId) => {
          axios.delete(urlJoin(URLS.fileRemove, fileId));
        });

        return data;
      })
      .then(({ data }) => {
        if (!_isEmpty(updatedFiles)) {
          const filesPromises = updatedFiles.map((file) => {
            return fileUploadPromise(file, 'PATCH', urlJoin(URLS.attachEquipmentFile, data.id), () => {
            });
          });

          data.files = [];

          return Promise.all(filesPromises).then(() => {
            return data;
          });
        }

        return data;
      })
      .then((data) => {
        dispatch(editEquipmentSuccess(data));
        history.push('/equipment');
      })
      .catch((error) => {
        dispatch(addError(`Error during equipment editing ${error.message}`, error));
      });
  };
}

export function addEquipment(equipment, files) {
  return (dispatch) => {
    return axios.post(URLS.equipments, equipment)
      .then(({ data }) => {
        if (!_isEmpty(files)) {
          const filesPromises = files.map((file) => {
            return fileUploadPromise(file, 'PATCH', urlJoin(URLS.attachEquipmentFile, data.id), () => {
            });
          });

          data.files = [];

          return Promise.all(filesPromises).then(() => {
            return data;
          });
        }

        return data;
      })
      .then((data) => {
        dispatch(addEquipmentSuccess(data));
        history.push('/equipment');
      })
      .catch((error) => {
        dispatch(addError(`Error during equipment adding ${error.message}`, error));
      });
  };
}

export function cleanEquipmentData() {
  return (dispatch) => dispatch(cleanEquipment());
}
