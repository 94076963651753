import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';

export const FETCH_EXAM_COMPETENCIES_SUCCESS = 'FETCH_EXAM_COMPETENCIES_SUCCESS';
export const FETCH_EXAM_COMPETENCIES = 'FETCH_EXAM_COMPETENCIES';
export const ADD_EXAM_COMPETENCE = 'ADD_EXAM_COMPETENCE';
export const REMOVE_EXAM_COMPETENCE = 'REMOVE_EXAM_COMPETENCE';
export const EDIT_EXAM_COMPETENCE = 'EDIT_EXAM_COMPETENCE';

export function fetchExamCompetenciesSuccess(examCompetencies) {
  return {
    type: FETCH_EXAM_COMPETENCIES_SUCCESS,
    payload: {
      examCompetencies
    }
  };
}

export function addExamCompetenceSuccess(examCompetence) {
  return {
    type: ADD_EXAM_COMPETENCE,
    payload: {
      examCompetence
    }
  };
}

export function removeExamCompetenceSuccess(examCompetence) {
  return {
    type: REMOVE_EXAM_COMPETENCE,
    payload: {
      examCompetence
    }
  };
}

export function editExamCompetenceSuccess(examCompetence) {
  return {
    type: EDIT_EXAM_COMPETENCE,
    payload: {
      examCompetence
    }
  };
}

export function addExamCompetence(examCompetence) {
  return (dispatch) => {
    return axios.post(URLS.examCompetencies, examCompetence)
      .then(({ data }) => dispatch(addExamCompetenceSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during exam competence adding ${error.message}`, error));
      });
  };
}

export function removeExamCompetence(examCompetence) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.examCompetencies, examCompetence.id))
      .then(() => dispatch(removeExamCompetenceSuccess(examCompetence)))
      .catch((error) => {
        dispatch(addError(`Error during exam competence removing ${error.message}`, error));
      });
  };
}

export function editExamCompetence(examCompetence) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.examCompetencies, examCompetence.id), examCompetence)
      .then(({ data }) => dispatch(editExamCompetenceSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during exam competence editing ${error.message}`, error));
      });
  };
}
