import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Redirect
} from 'react-router-dom';

import OfflineView, { isOffline } from '../../common/components/Offline';
import { isDataToSync } from '../../utils/offline.js';

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const dataToSync = isDataToSync();

        if (isOffline(props.location.pathname)) {
          return <OfflineView />;
        }

        if (isAuthenticated || dataToSync) {
          return <Component {...props} {...rest} />;
        }

        return <Redirect to={{ pathname: '/login' }} />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default PrivateRoute;
