import React, { Component } from 'react';

import BookingMenu from './BookingMenu';
import EquipmentMenu from './EquipmentMenu';
import ExamsMenu from './ExamsMenu';
import FleetMenu from './FleetMenu';
import LibraryMenu from './LibraryMenu';
import LogbookMenu from './LogbookMenu';
import { PERMISSIONS } from '../../../../constants/permissions';
import PropTypes from 'prop-types';
import ReportsMenu from './ReportsMenu';
import RiskManagementMenu from './RiskManagementMenu';
import SettingsMenu from './SettingsMenu';
import TrainingMenu from './TrainingMenu';
import UsersMenu from './UsersMenu';
import { renderPermission } from '../../../decorators/permissions';

export default class Menu extends Component {
  static propTypes = {
    closeMobileMenu: PropTypes.func.isRequired,
  };

  @renderPermission(PERMISSIONS.leftNavigationMenuView)
  render() {
    const { closeMobileMenu } = this.props;

    return (
      <>
        <UsersMenu
          closeMobileMenu={closeMobileMenu}
        />
        <TrainingMenu
          closeMobileMenu={closeMobileMenu}
        />
        <ExamsMenu
          closeMobileMenu={closeMobileMenu}
        />
        <BookingMenu
          closeMobileMenu={closeMobileMenu}
        />
        <ReportsMenu
          closeMobileMenu={closeMobileMenu}
        />
        <LogbookMenu
          closeMobileMenu={closeMobileMenu}
        />
        <EquipmentMenu
          closeMobileMenu={closeMobileMenu}
        />
        <LibraryMenu
          closeMobileMenu={closeMobileMenu}
        />
        <FleetMenu
          closeMobileMenu={closeMobileMenu}
        />
        <RiskManagementMenu
          closeMobileMenu={closeMobileMenu}
        />
        <SettingsMenu
          closeMobileMenu={closeMobileMenu}
        />
      </>
    );
  }
}
