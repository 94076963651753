import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import Question from './Question';
import { PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

@withNamespaces()
@componentPermission(PERMISSIONS.questionCreate)
export default class QuestionNew extends Component {
  static propTypes = {
    question: PropTypes.object,
    preview: PropTypes.bool,
    editMode: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  renderQuestionEditButton(question) {
    return (
      <div className="col-auto">
        <Link to={`/question_bank/edit/${question.id}`}>
          <IconButton>
            <Icon>mode_edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  renderViewLabel = (preview, editMode, t) => {
    if (editMode) {
      return t('test:editQuestionSection');
    } else if (preview) {
      return t('test:previewQuestionSection');
    }

    return t('test:addQuestionSection');
  };

  render() {
    const { question, preview = false, editMode = false, t } = this.props;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-lg-8 col-sm-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">
                list_add_check
              </Icon>
              <h1>{t('test:name')}<span className="h1-subheader"> /{this.renderViewLabel(preview, editMode, t)}</span></h1>
            </span>
          </header>
        </div>
        {preview && this.renderQuestionEditButton(question)}
        <div className="col-12">
          <Question
            preview={preview}
            question={question}
            editMode={editMode}
          />
        </div>
      </div>
    );
  }
}
