import axios from 'axios';
import _maxBy from 'lodash/maxBy';
import _isEmpty from 'lodash/isEmpty';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';
import { fileUploadPromise } from '../../utils/fileUpload';

export const FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';
export const ADD_ITEM = 'ADD_ITEM';
export const EDIT_ITEM = 'EDIT_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';

export function fetchItemsRequest() {
  return {
    type: FETCH_ITEMS_REQUEST
  };
}

export function fetchItemsSuccess(items) {
  return {
    type: FETCH_ITEMS_SUCCESS,
    payload: {
      items
    }
  };
}

export function fetchItemsFailure() {
  return {
    type: FETCH_ITEMS_FAILURE
  };
}

export function addItemSuccess(item) {
  return {
    type: ADD_ITEM,
    payload: {
      item
    }
  };
}

export function editItemSuccess(item) {
  return {
    type: EDIT_ITEM,
    payload: {
      item
    }
  };
}

export function removeItemSuccess(item) {
  return {
    type: REMOVE_ITEM,
    payload: {
      item
    }
  };
}


export function fetchItems(sortingData) {
  return (dispatch) => {
    dispatch(fetchItemsRequest());
    let queryParameters = '';

    if (sortingData && !_isEmpty(sortingData.filter)) {
      queryParameters = sortingData.filter.map((filter) => {
        return `${'item_types'}=${encodeURIComponent(filter.name)}`;
      }).join('&');
    }

    queryParameters = _isEmpty(queryParameters) ? '' : `?${queryParameters}`;

    return axios.get(urlJoin(URLS.items, queryParameters))
      .then(({ data }) => dispatch(fetchItemsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchItemsFailure());
        dispatch(addError(`Error during items fetching ${error.message}`, error));
      });
  };
}

export function addItem(item, files) {
  return (dispatch) => {
    return axios.post(URLS.items, item)
      .then(({ data }) => {
        if (!_isEmpty(files) && _isEmpty(item.files)) {
          const filesPromises = files.map((file) => {
            return fileUploadPromise(file, 'PATCH', urlJoin(URLS.itemFile, data.id), () => {});
          });

          data.files = [];

          // eslint-disable-next-line promise/no-nesting
          return Promise.all(filesPromises).then((itemList) => {
            return _maxBy(itemList, ((item) => item.files.length));
          });
        }

        return data;
      })
      .then((data) => dispatch(addItemSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during item adding ${error.message}`, error));
      });
  };
}

export function editItem(item, updatedFiles, deletedFiles) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.items, item.id), item)
      .then(({ data }) => {
        if (!_isEmpty(deletedFiles)) {
          const filesPromises = deletedFiles.map((file) => {
            return axios.delete(urlJoin(URLS.fileRemove, file));
          });

          // eslint-disable-next-line promise/no-nesting
          return Promise.all(filesPromises).then(() => {
            return { ...data, files: data.files.filter((file) => !deletedFiles.includes(file.id)) };
          });
        }

        return data;
      })
      .then((data) => {
        if (!_isEmpty(updatedFiles)) {
          const filesPromises = updatedFiles.map((file) => {
            return fileUploadPromise(file, 'PATCH', urlJoin(URLS.itemFile, data.id), () => {
            });
          });

          // eslint-disable-next-line promise/no-nesting
          return Promise.all(filesPromises).then((itemList) => {
            return _maxBy(itemList, ((item) => item.files.length));
          });
        }

        return data;
      })
      .then((data) => dispatch(editItemSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during item editing ${error.message}`, error));
      });
  };
}

export function removeItem(item) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.items, item.id))
      .then(() => dispatch(removeItemSuccess(item)))
      .catch((error) => {
        dispatch(addError(`Error during item removing ${error.message}`, error));
      });
  };
}
