import {
  ADD_WAREHOUSE_CONSUMABLE,
  FETCH_WAREHOUSE_CONSUMABLES_REQUEST,
  FETCH_WAREHOUSE_CONSUMABLES_SUCCESS,
  REMOVE_WAREHOUSE_CONSUMABLE,
  UPDATE_WAREHOUSE_CONSUMABLE,
} from '../actions/warehouseConsumables';

const INITIAL_STATE = {
  consumable: {},
  consumableList: [],
  consumableRequest: false,
};

function fetchWarehouseConsumablesSuccess(state, payload) {
  return {
    ...state,
    consumableList: payload.consumables,
    consumableRequest: false,
  };
}

function fetchWarehouseConsumablesRequest(state) {
  return {
    ...state,
    consumableRequest: true,
  };
}

function addWarehouseConsumableSuccess(state, payload) {
  return {
    ...state,
    consumableList: [...state.consumableList, payload.consumable],
  };
}

function updateWarehouseConsumableSuccess(state, payload) {
  return {
    ...state,
    consumableList: state.consumableList.map((consumable) => {
      if (consumable.id !== payload.consumable.id) {
        return consumable;
      }

      return payload.consumable;
    }),
  };
}

function removeWarehouseConsumableSuccess(state, payload) {
  return {
    ...state,
    consumableList: state.consumableList.filter(
      (consumable) => consumable.id !== payload.id,
    ),
  };
}

const actions = {
  [FETCH_WAREHOUSE_CONSUMABLES_SUCCESS]: fetchWarehouseConsumablesSuccess,
  [FETCH_WAREHOUSE_CONSUMABLES_REQUEST]: fetchWarehouseConsumablesRequest,
  [ADD_WAREHOUSE_CONSUMABLE]: addWarehouseConsumableSuccess,
  [UPDATE_WAREHOUSE_CONSUMABLE]: updateWarehouseConsumableSuccess,
  [REMOVE_WAREHOUSE_CONSUMABLE]: removeWarehouseConsumableSuccess,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
