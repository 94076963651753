import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import RemoveDialog from './RemoveDialog';

@withNamespaces()
export default class RemoveDialogContainer extends Component {
  static propTypes = {
    openDialog: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    removeFunction: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string,
    message: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  removeElement = () => {
    const { removeFunction, onClose } = this.props;

    removeFunction();
    onClose();
  };

  render() {
    const { onClose, openDialog, t } = this.props;
    let { dialogTitle, message } = this.props;

    dialogTitle = dialogTitle || t('removeElement');
    message = message || t('doYouWantRemove');


    return (
      <div>
        <Dialog
          open={openDialog}
          onClose={onClose}
          aria-labelledby="remove-dialog"
          fullWidth
        >
          <RemoveDialog
            message={message}
            closeRemoveDialog={onClose}
            dialogTitle={dialogTitle}
            removeFunction={this.removeElement}
          />
        </Dialog>
      </div>
    );
  }
}
