import './styles.scss';

import React, { Component } from 'react';
import {
  addExternalCoursePlannedAction,
  fetchAllExternalCourses,
  fetchExternalCourses,
} from '../../actions/externalCourses';
import {
  addLicensePlannedAction,
  fetchAllLicenses,
  fetchLicenses,
} from '../../actions/licenses';
import {
  addMedicalPlannedAction,
  fetchAllMedicals,
  fetchMedicals,
} from '../../actions/medicals';
import {
  addQualificationPlannedAction,
  fetchAllQualifications,
  fetchQualifications,
} from '../../actions/qualifications';
import {
  addRatingPlannedAction,
  fetchAllRatings,
  fetchRatings,
} from '../../actions/ratings';
import {
  componentPermission,
  hasPermission,
  renderPermission,
} from '../../decorators/permissions';

import AdvertBox from './AdvertBox';
import BookingList from './BookingList';
import Clock from 'react-live-clock';
import DocumentsConfirmation from './DocumentsConfirmation';
import EquipmentList from './EquipmentList';
import ExpirationList from './ExpirationList';
import FleetList from './FleetList';
import Icon from '@material-ui/core/Icon';
import Loader from '../../../common/components/Loader';
import { MODULES } from '../../../constants/modules';
import NotificationBox from './NotificationBox';
import { PERMISSIONS } from '../../../constants/permissions';
import PropTypes from 'prop-types';
import RssNews from './RssNews';
import UnreadDocumentsInLibraryList from './UnreadDocumentsInLibraryList';
import WeatherBox from './WeatherBox';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { fetchAssignedCourses } from '../../actions/assignedCourses';
import { fetchAssignedTests } from '../../actions/assignedTests';
import { fetchBookings } from '../../actions/bookings';
import { fetchDashboardEquipments } from '../../actions/equipment';
import { fetchDashboardFleets } from '../../actions/fleet';
import { fetchUserProfile } from '../../actions/users';
import moment from 'moment';
import { reformatPickerDateTimeToDB } from '../../utils/time';
import { renderModule } from '../../decorators/modules';
import userService from '../../../utils/userService';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    bookingList: state.bookings.bookingList,

    ratingList: state.ratings.ratingList,
    allRatings: state.ratings.allRatingsList.filter((item) => !item.isVerified),
    ratingsRequest: state.ratings.ratingsRequest,

    licenseList: state.licenses.licenseList,
    allLicenses: state.licenses.allLicensesList.filter(
      (item) => !item.isVerified,
    ),
    licensesRequest: state.licenses.licensesRequest,

    medicalList: state.medicals.medicalList,
    allMedicals: state.medicals.allMedicalsList.filter(
      (item) => !item.isVerified,
    ),
    medicalsRequest: state.medicals.medicalsRequest,

    qualificationList: state.qualifications.qualificationList,
    allQualifications: state.qualifications.allQualificationList.filter(
      (item) => !item.isVerified,
    ),
    qualificationRequest: state.qualifications.qualificationRequest,

    externalCourseList: state.externalCourses.externalCourseList,
    allExternalCourses: state.externalCourses.allExternalCoursesList.filter(
      (item) => !item.isVerified,
    ),
    externalCoursesRequest: state.externalCourses.externalCoursesRequest,

    equipmentList: state.equipment.equipmentList,
    equipmentRequest: state.equipment.equipmentRequest,

    assignedCourseList: state.assignedCourses.assignedCourseList,
    assignedCoursesRequest: state.assignedCourses.assignedCoursesRequest,

    assignedTestList: state.assignedTests.assignedTestList,
    assignedTestRequest: state.assignedTests.assignedTestRequest,

    userProfile: _get(state.users, 'userDetails.userprofile', {}),

    fleetRequest: state.fleet.fleetRequest,
    fleetList: state.fleet.fleetList,
    settings: state.settings.settings,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchData: (userId) => {
      dispatch(fetchRatings(userId));
      dispatch(fetchLicenses(userId));
      dispatch(fetchMedicals(userId));
      dispatch(fetchQualifications(userId));
      dispatch(fetchExternalCourses(userId));
      dispatch(
        fetchBookings({
          user: userId,
          started_after: reformatPickerDateTimeToDB(moment()),
        }),
      );
    },
    fetchAllDocuments: (searchData) => {
      dispatch(fetchAllRatings(searchData));
      dispatch(fetchAllLicenses(searchData));
      dispatch(fetchAllMedicals(searchData));
      dispatch(fetchAllQualifications(searchData));
      dispatch(fetchAllExternalCourses(searchData));
    },
    addLicensePlannedAction: (license) =>
      dispatch(addLicensePlannedAction(license)),
    addMedicalPlannedAction: (medical) =>
      dispatch(addMedicalPlannedAction(medical)),
    addExternalCoursePlannedAction: (externalCourse) =>
      dispatch(addExternalCoursePlannedAction(externalCourse)),
    addQualificationPlannedAction: (qualification) =>
      dispatch(addQualificationPlannedAction(qualification)),
    addRatingPlannedAction: (rating) =>
      dispatch(addRatingPlannedAction(rating)),
    fetchEquipments: () => dispatch(fetchDashboardEquipments()),
    fetchAssignedCourses: (searchData) =>
      dispatch(fetchAssignedCourses(searchData)),
    fetchAssignedTests: (searchData) =>
      dispatch(fetchAssignedTests(searchData)),
    fetchUserProfile: (userId) => dispatch(fetchUserProfile(userId)),
    fetchDashboardFleets: () => dispatch(fetchDashboardFleets()),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.dashboardView)
export default class Dashboard extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    fetchAllDocuments: PropTypes.func.isRequired,
    qualificationList: PropTypes.array.isRequired,
    ratingList: PropTypes.array.isRequired,
    licenseList: PropTypes.array.isRequired,
    medicalList: PropTypes.array.isRequired,
    externalCourseList: PropTypes.array.isRequired,
    bookingList: PropTypes.array.isRequired,
    fetchBookings: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    allRatings: PropTypes.array.isRequired,
    ratingsRequest: PropTypes.bool,
    allLicenses: PropTypes.array.isRequired,
    licensesRequest: PropTypes.bool,
    allMedicals: PropTypes.array.isRequired,
    medicalsRequest: PropTypes.bool,
    allQualifications: PropTypes.array.isRequired,
    qualificationRequest: PropTypes.bool,
    allExternalCourses: PropTypes.array.isRequired,
    externalCoursesRequest: PropTypes.bool,
    addLicensePlannedAction: PropTypes.func.isRequired,
    addMedicalPlannedAction: PropTypes.func.isRequired,
    addExternalCoursePlannedAction: PropTypes.func.isRequired,
    addQualificationPlannedAction: PropTypes.func.isRequired,
    addRatingPlannedAction: PropTypes.func.isRequired,
    fetchEquipments: PropTypes.func.isRequired,
    equipmentList: PropTypes.array.isRequired,
    equipmentRequest: PropTypes.bool.isRequired,
    fetchAssignedCourses: PropTypes.func.isRequired,
    assignedCoursesRequest: PropTypes.bool,
    assignedCourseList: PropTypes.array,
    fetchAssignedTests: PropTypes.func.isRequired,
    fetchDashboardFleets: PropTypes.func.isRequired,
    assignedTestsRequest: PropTypes.bool,
    assignedTestList: PropTypes.array,
    fetchUserProfile: PropTypes.func.isRequired,
    fleetRequest: PropTypes.bool.isRequired,
    userProfile: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const currentUser = userService.getCurrentUser();
    const userId = currentUser.id;
    const searchData = {
      filter: {
        isVerified: true,
      },
    };

    this.props.fetchUserProfile(userId);
    this.fetchThings(userId);
    this.fetchAllDocuments(searchData);
    this.fetchEquipment();
    this.fetchFleet();
    this.props.fetchAssignedCourses({ userId });
    this.props.fetchAssignedTests({ userId });
  }

  @renderModule(MODULES.fleet)
  @renderPermission(PERMISSIONS.viewFleets)
  fetchFleet() {
    const { fetchDashboardFleets } = this.props;

    fetchDashboardFleets();
  }

  @renderPermission(PERMISSIONS.viewAllEquipments)
  @renderModule(MODULES.equipment)
  fetchEquipment() {
    const { fetchEquipments } = this.props;

    fetchEquipments();
  }

  @renderPermission(PERMISSIONS.expiryRatingsView)
  @renderPermission(PERMISSIONS.expiryLicensesView)
  @renderPermission(PERMISSIONS.expiryMedicalsView)
  @renderPermission(PERMISSIONS.expiryQualificationsView)
  @renderPermission(PERMISSIONS.expiryExternalCoursesView)
  fetchThings(id) {
    const { fetchData } = this.props;

    fetchData(id);
    fetchBookings({ userId: id, startDate: moment() });
  }

  fetchAllDocuments = (searchData) => {
    if (
      hasPermission(PERMISSIONS.unconfirmedLicensesView) ||
      hasPermission(PERMISSIONS.unconfirmedMedicalsView) ||
      hasPermission(PERMISSIONS.unconfirmedRatingView) ||
      hasPermission(PERMISSIONS.unconfirmedQualificationsView) ||
      hasPermission(PERMISSIONS.unconfirmedExternalCoursesView)
    ) {
      const { fetchAllDocuments } = this.props;

      fetchAllDocuments(searchData);
    }
  };

  renderDocumentsConfirmation() {
    const {
      allRatings,
      allLicenses,
      allMedicals,
      allQualifications,
      allExternalCourses,
      addLicensePlannedAction,
      addMedicalPlannedAction,
      addExternalCoursePlannedAction,
      addQualificationPlannedAction,
      addRatingPlannedAction,
    } = this.props;

    if (
      hasPermission(PERMISSIONS.unconfirmedLicensesView) ||
      hasPermission(PERMISSIONS.unconfirmedMedicalsView) ||
      hasPermission(PERMISSIONS.unconfirmedRatingView) ||
      hasPermission(PERMISSIONS.unconfirmedQualificationsView) ||
      hasPermission(PERMISSIONS.unconfirmedExternalCoursesView)
    ) {
      return (
        <DocumentsConfirmation
          ratings={allRatings}
          licenses={allLicenses}
          medicals={allMedicals}
          qualifications={allQualifications}
          externalCourses={allExternalCourses}
          addLicensePlannedAction={addLicensePlannedAction}
          addMedicalPlannedAction={addMedicalPlannedAction}
          addExternalCoursePlannedAction={addExternalCoursePlannedAction}
          addQualificationPlannedAction={addQualificationPlannedAction}
          addRatingPlannedAction={addRatingPlannedAction}
        />
      );
    }

    return null;
  }

  prepareList = (list, document) => {
    if (_isEmpty(list)) {
      return [];
    }

    return list.map((element) => {
      return {
        ...element,
        document,
      };
    });
  };

  prepareElements = () => {
    const {
      ratingList,
      licenseList,
      medicalList,
      qualificationList,
      externalCourseList,
      t,
    } = this.props;
    const preparedRatingList = this.prepareList(
      ratingList,
      t('rating', { context: 'lowercase' }),
    );
    const preparedLicensesList = this.prepareList(
      licenseList,
      t('license', { context: 'lowercase' }),
    );
    const preparedMedicalList = this.prepareList(
      medicalList,
      t('medical', { context: 'lowercase' }),
    );
    const preparedQualificationList = this.prepareList(
      qualificationList,
      t('qualification', { context: 'lowercase' }),
    );
    const preparedExternalCourseList = this.prepareList(
      externalCourseList,
      t('externalCourse', { context: 'lowercase' }),
    );
    const elements = [];

    return elements.concat(
      preparedRatingList,
      preparedLicensesList,
      preparedMedicalList,
      preparedQualificationList,
      preparedExternalCourseList,
    );
  };

  @renderModule(MODULES.advert)
  renderAdvert() {
    return <AdvertBox />;
  }

  @renderModule(MODULES.fleet)
  @renderPermission(PERMISSIONS.viewFleets)
  renderFleetList() {
    const { fleetList, settings } = this.props;

    return <FleetList fleetList={fleetList} settings={settings} />;
  }

  @renderPermission(PERMISSIONS.viewEquipmentListOnDashboard)
  renderEquipmentList() {
    const { equipmentList } = this.props;

    return <EquipmentList equipmentList={equipmentList} />;
  }

  render() {
    const {
      bookingList,
      ratingsRequest,
      licensesRequest,
      medicalsRequest,
      qualificationRequest,
      externalCoursesRequest,
      equipmentRequest,
      assignedCoursesRequest,
      assignedCourseList,
      assignedTestsRequest,
      assignedTestList,
      userProfile,
      fleetRequest,
      t,
    } = this.props;

    if (
      ratingsRequest ||
      licensesRequest ||
      medicalsRequest ||
      qualificationRequest ||
      externalCoursesRequest ||
      equipmentRequest ||
      assignedCoursesRequest ||
      assignedTestsRequest ||
      fleetRequest
    ) {
      return <Loader />;
    }
    const user = userService.getCurrentUser();
    const { firstName } = user;

    return (
      <div className="dashboard">
        <div className="col-12 section-title dashboard-tab-title">
          <div className=" dashboard-tab-title">
            <header>
              <span>
                <Icon color="primary">dashboard</Icon>
                <h1>{t('dashboard:name')}</h1>
                {/* <p className="ml-3 date">{moment().format('MMM D, YYYY')}</p> */}
                <Clock
                  filter={(time) => `${time} UTC`}
                  format={'MMM D, YYYY HH:mm:ss'}
                  ticking
                  timezone={'UTC'}
                  className="ml-3 date"
                />
              </span>
            </header>
          </div>
          <div className="row">
            <div className="col-xl-4 col-sm-6 col-xs-12">
              <NotificationBox
                firstName={firstName}
                courseList={assignedCourseList}
                examList={assignedTestList}
              />
              {this.renderAdvert()}
              {this.renderDocumentsConfirmation()}
              <UnreadDocumentsInLibraryList />
            </div>
            <div className="col-xl-4 col-sm-6 col-xs-12">
              <BookingList
                title="My Bookings"
                bookings={bookingList.slice(0, 5)}
              />
              <ExpirationList expirationList={this.prepareElements()} />
              {this.renderFleetList()}
              {this.renderEquipmentList()}
            </div>
            <div className="col-xl-4 col-sm-6 col-xs-12">
              <WeatherBox />
              <RssNews userProfile={userProfile} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
