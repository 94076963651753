import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  reformatPickerDateFromDB,
  checkExpiredDate,
  checkExpiryDate,
} from '../../../utils/time';
import FileInput from '../../../../common/components/upload/FileInput';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import { renderDocumentColor } from '../fleetHelper';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 180,
  },
  colorCell: {
    width: 20,
  },
});

@withNamespaces()
@withStyles(styles)
export default class DocumentList extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    documentList: PropTypes.array.isRequired,
    removeDocument: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    attachDocumentFile: PropTypes.func.isRequired,
    removeDocumentFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    fleetSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderDateColor = (document, fieldName) => {
    const expiryDateString = reformatPickerDateFromDB(
      document[fieldName],
      false,
    );

    if (document[fieldName]) {
      if (checkExpiredDate(document[fieldName])) {
        return <span className="expired-date">{expiryDateString}</span>;
      } else if (checkExpiryDate(document[fieldName], 1)) {
        return <span className="expiry-date">{expiryDateString}</span>;
      }
    }

    return <span className="ok-date">{expiryDateString}</span>;
  };

  renderFiles(document) {
    const { editMode, removeDocumentFile, onElementDelete } = this.props;
    const documentId = document.id;

    return document.files.map((file) => {
      if (editMode) {
        return (
          <Chip
            key={`document-${documentId}-file-${file.id}`}
            label={file.name}
            onClick={() => window.open(file.path, '_blank')}
            onDelete={() =>
              onElementDelete(() => removeDocumentFile(file.id, documentId))
            }
            className="my-1"
          />
        );
      }

      return (
        <Chip
          key={`document-${documentId}-file-${file.id}`}
          label={file.name}
          onClick={() => window.open(file.path, '_blank')}
          className="my-1"
        />
      );
    });
  }

  renderButtons(document) {
    const {
      handleEditOpen,
      removeDocument,
      onElementDelete,
      fleet,
      attachDocumentFile,
      addSimpleError,
      editMode,
    } = this.props;

    if (!editMode) {
      return null;
    }

    return (
      <>
        <span className="file-inputs">
          <CustomIconButton>
            <FileInput
              attachFile={(file) =>
                attachDocumentFile(file, document.id, fleet.id)
              }
              addSimpleError={addSimpleError}
              required
            />
            <Icon color="primary">attach_file</Icon>
          </CustomIconButton>
        </span>
        <IconButton onClick={() => handleEditOpen(document)}>
          <Icon color="primary">mode_edit</Icon>
        </IconButton>
        <IconButton
          onClick={() => onElementDelete(() => removeDocument(document))}
        >
          <Icon color="primary">delete</Icon>
        </IconButton>
      </>
    );
  }

  renderDocumentList() {
    const { documentList, classes } = this.props;

    return documentList.map((document) => {
      return (
        <TableRow key={`document-${document.id}`}>
          <TableCell className={classes.colorCell}>
            {renderDocumentColor(document)}
          </TableCell>
          <TableCell className={classes.longCell}>{document.name}</TableCell>
          <TableCell className={classes.longCell}>
            {document.documentDate}
          </TableCell>
          <TableCell className={classes.longCell}>
            {this.renderDateColor(document, 'expiryDate')}
          </TableCell>
          <TableCell className={classes.longCell}>
            {this.renderFiles(document)}
          </TableCell>
          <TableCell className={classes.iconsCell} numeric>
            {this.renderButtons(document)}
          </TableCell>
        </TableRow>
      );
    });
  }

  renderAddDocumentButton() {
    const { editMode, handleAddOpen, t } = this.props;

    if (!editMode) {
      return null;
    }

    return (
      <Button color="primary" onClick={handleAddOpen}>
        {t('fleet:addDocumentButton')}
      </Button>
    );
  }

  render() {
    const { documentList, classes, t } = this.props;

    if (_isEmpty(documentList)) {
      return this.renderAddDocumentButton();
    }

    return (
      <div className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:color')}</TableCell>
              <TableCell>{t('table:name')}</TableCell>
              <TableCell>{t('table:documentDate')}</TableCell>
              <TableCell>{t('table:expiryDate')}</TableCell>
              <TableCell>{t('table:attachments')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.renderDocumentList()}</TableBody>
        </Table>
        {this.renderAddDocumentButton()}
      </div>
    );
  }
}
