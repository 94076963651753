import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import Table from '../../../../common/components/Table';

import { PERMISSIONS } from '../../../../constants/permissions';
import { renderPermission } from '../../../decorators/permissions';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
});

const tableHeader = [
  {
    prop: 'testName',
    sort: true,
    profileView: false
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true
  },
];

@withStyles(styles)
export default class TestList extends Component {
  static propTypes = {
    testList: PropTypes.array,
    classes: PropTypes.object.isRequired,
    sortAsc: PropTypes.bool.isRequired,
    handleSort: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    openRemoveDialog: PropTypes.func.isRequired,
    handleAssignTestOpen: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: this.props.search,
    };
  }

  @renderPermission(PERMISSIONS.deleteExam)
  renderTrashIcon(test) {
    return <IconButton onClick={() => this.props.openRemoveDialog(test)}><Icon color="primary">delete</Icon></IconButton>;
  }

  @renderPermission(PERMISSIONS.editExam)
  renderEditIcon(testId) {
    return <Link to={`/exam_admin/edit/${testId}`}><IconButton><Icon color="primary">mode_edit</Icon></IconButton></Link>;
  }

  renderManageIcons(test) {
    const { handleAssignTestOpen } = this.props;
    const testId = test.id;

    return (
      <div className="icons-display-wrapper">
        { this.renderEditIcon(testId) }
        { this.renderTrashIcon(test) }
        <IconButton onClick={() => handleAssignTestOpen(testId)}><Icon color="primary">assignment</Icon></IconButton>
      </div>
    );
  }

  renderTestName(test) {
    return (
      <Link className="table-row-link" to={`/exam_admin/details/${test.id}`}>{test.name}</Link>
    );
  }

  getTestList() {
    const { testList } = this.props;

    const parsedTests = testList.map((test) => ({
      ...test,
      testName: this.renderTestName(test),
      icons: this.renderManageIcons(test)
    }));

    return parsedTests;
  }

  render() {
    const { sortAsc, handleSort, handleSearch, search } = this.props;

    return (
      <Table
        data={this.getTestList()}
        header={tableHeader}
        handleSort={handleSort}
        sortAsc={sortAsc}
        columnToSort={'testName'}
        handleSearch={handleSearch}
        search={search}
      />
    );
  }
}
