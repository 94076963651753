import {
  FETCH_AIRCRAFTS_REQUEST,
  FETCH_ALL_AIRCRAFTS_SUCCESS,
  FETCH_USER_AIRCRAFTS_SUCCESS,
  FETCH_AIRCRAFTS_SUCCESS,
  FETCH_AIRCRAFTS_FAILURE,
  ADD_AIRCRAFT,
  EDIT_AIRCRAFT,
  REMOVE_AIRCRAFT,
} from '../actions/aircrafts';

const INITIAL_STATE = {
  aircraftList: [],
  userAircraftList: [],
  allAircraftList: [],
  aircraftRequest: false,
};

function fetchAircraftsSuccess(state, payload) {
  return {
    ...state,
    aircraftList: payload.aircrafts,
    aircraftRequest: false,
  };
}

function fetchAllAircraftsSuccess(state, payload) {
  return {
    ...state,
    allAircraftList: payload.allAircrafts,
    aircraftRequest: false
  };
}

function fetchUserAircraftsSuccess(state, payload) {
  return {
    ...state,
    userAircraftList: payload.userAircrafts,
    aircraftRequest: false
  };
}

function fetchAircraftsRequest(state) {
  return {
    ...state,
    aircraftRequest: true,
  };
}

function fetchAircraftsFailure(state) {
  return {
    ...state,
    aircraftRequest: false,
  };
}

function addAircraft(state, payload) {
  return {
    ...state,
    allAircraftList: [
      ...state.allAircraftList,
      payload.aircraft
    ],
    aircraftList: [
      ...state.aircraftList,
      payload.aircraft
    ],
    userAircraftList: [
      ...state.userAircraftList,
      payload.aircraft
    ],
  };
}

function editAircraft(state, payload) {
  return {
    ...state,
    allAircraftList: state.allAircraftList.map((aircraft) => {
      if (aircraft.id === payload.aircraft.id) {
        return payload.aircraft;
      }

      return aircraft;
    }),
    aircraftList: state.aircraftList.map((aircraft) => {
      if (aircraft.id === payload.aircraft.id) {
        return payload.aircraft;
      }

      return aircraft;
    }),
    userAircraftList: state.userAircraftList.map((aircraft) => {
      if (aircraft.id === payload.aircraft.id) {
        return payload.aircraft;
      }

      return aircraft;
    }),
  };
}

function removeAircraft(state, payload) {
  return {
    ...state,
    allAircraftList: state.allAircraftList.filter((aircraft) => aircraft !== payload.aircraft),
    aircraftList: state.aircraftList.filter((aircraft) => aircraft !== payload.aircraft),
    userAircraftList: state.userAircraftList.filter((aircraft) => aircraft !== payload.aircraft),
  };
}

const actions = {
  [FETCH_ALL_AIRCRAFTS_SUCCESS]: fetchAllAircraftsSuccess,
  [FETCH_USER_AIRCRAFTS_SUCCESS]: fetchUserAircraftsSuccess,
  [FETCH_AIRCRAFTS_SUCCESS]: fetchAircraftsSuccess,
  [FETCH_AIRCRAFTS_FAILURE]: fetchAircraftsFailure,
  [FETCH_AIRCRAFTS_REQUEST]: fetchAircraftsRequest,
  [ADD_AIRCRAFT]: addAircraft,
  [EDIT_AIRCRAFT]: editAircraft,
  [REMOVE_AIRCRAFT]: removeAircraft,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
