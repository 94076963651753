import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  longCell: {
    minWidth: 100,
  },
});

@withNamespaces()
@withStyles(styles)
export default class NotificationCategoryList extends Component {
  static propTypes = {
    notificationCategoryList: PropTypes.array,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  getCategoryName = (notificationCategoryName, t) => {
    return t(notificationCategoryName);
  };

  renderNotificationCategoriesList = (notificationCategoryList, t) => {
    const { classes } = this.props;

    return notificationCategoryList && notificationCategoryList.map((notificationCategory) => {
      return (
        <TableRow key={`notification-${notificationCategory.id}`}>
          <TableCell className={classes.longCell}>{this.getCategoryName(notificationCategory.name, t)}</TableCell>
          <TableCell numeric />
        </TableRow>
      );
    });
  };

  render() {
    const { classes, notificationCategoryList, t } = this.props;

    return (
      <div className="pl-sm-5">
        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table:name')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderNotificationCategoriesList(notificationCategoryList, t)}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}
