import '../styles.scss';

import React, { Component } from 'react';
import { SIMULATOR_TYPE, TOTAL_ROW, ZERO_TIME } from '../../../../constants';
import { fetchFlightLogs, removeFlightLog } from '../../../actions/flightLog';
import {
  minutesToTime,
  reformatPickerDateFromDB,
  reformatPickerDateTimeFromDB,
  timeToMinutes,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import RemoveDialog from '../../../../common/components/RemoveDialog';
import { TABLE_HEADER } from './logbookConstants';
import Table from '../../../../common/components/Table';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _sumBy from 'lodash/sumBy';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
class LogbookList extends Component {
  static propTypes = {
    fetchFlightLogs: PropTypes.func.isRequired,
    removeFlightLog: PropTypes.func.isRequired,
    flightLogList: PropTypes.array,
    flightLogsRequest: PropTypes.bool.isRequired,
    t: PropTypes.func,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: '',
      secondSearch: '',
      userId: '',
    },
    removeDialogOpen: false,
    removeFlightLog: null,
  };

  componentDidMount() {
    const { columnToSort, sortAsc, search, secondSearch } =
      this.state.searchSettings;
    const { userId } = this.props;

    if (userId) {
      const searchSettings = {
        columnToSort,
        sortAsc,
        search,
        secondSearch,
        userId,
      };

      this.updateSearchSettings(searchSettings);

      return this.props.fetchFlightLogs(searchSettings);
    }

    return this.props.fetchFlightLogs();
  }

  updateSearchSettings = (searchSettings) => {
    this.setState({ searchSettings });
  };

  handleSort = (columnName) => {
    const { search, secondSearch, sortAsc, userId } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
      secondSearch,
      userId,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchFlightLogs(searchData);
  };

  handleSearch = (searchInputValue, name) => {
    const { columnToSort, sortAsc, userId, search, secondSearch } =
      this.state.searchSettings;
    const searchValue = name === 'searchValue' ? searchInputValue : search;
    const secondSearchValue =
      name === 'secondSearchValue' ? searchInputValue : secondSearch;

    const searchData = {
      columnToSort,
      sortAsc,
      search: searchValue,
      secondSearch: secondSearchValue,
      userId,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchFlightLogs(searchData);
  };

  getUserFilters = () => {};

  openRemoveDialog = (flightLog) => {
    this.setState({
      removeDialogOpen: true,
      removeFlightLog: flightLog,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeFlightLog: null,
    });
  };

  renderDeleteIcon(flightLogId) {
    return (
      <IconButton
        key="remove-icon"
        onClick={() => this.openRemoveDialog(flightLogId)}
      >
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  renderEditIcon(flightLogId) {
    return (
      <Link key="flight-log-button-edit" to={`/flight_log/edit/${flightLogId}`}>
        <IconButton>
          <Icon color="primary">mode_edit</Icon>
        </IconButton>
      </Link>
    );
  }

  renderFlightLogDetailsLink(id, prop) {
    return (
      <Link className="table-row-link" to={`/flight_log/details/${id}`}>
        {prop}
      </Link>
    );
  }

  renderIcons(flightLog) {
    const flightLogId = flightLog.id;

    return [
      this.renderEditIcon(flightLogId),
      this.renderDeleteIcon(flightLogId),
    ];
  }

  getSinglePilotSum = (firstComponent, secondComponent) => {
    if (firstComponent === ZERO_TIME || secondComponent === ZERO_TIME) {
      return ZERO_TIME;
    }

    return firstComponent;
  };

  isSimulatorChecked = (aircraft) => {
    return aircraft && aircraft.type === SIMULATOR_TYPE;
  };

  getTotalRow = (parsedFlightLog) => {
    const sumFlightLog = {
      dateOfFlight: TOTAL_ROW,
      totalTime: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) =>
          timeToMinutes(flightLog.totalTime),
        ),
      ),
      spSe: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) => timeToMinutes(flightLog.spSe)),
      ),
      spMe: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) => timeToMinutes(flightLog.spMe)),
      ),
      dayLandings: _sumBy(
        parsedFlightLog,
        (flightLog) => flightLog.dayLandings,
      ),
      nightLandings: _sumBy(
        parsedFlightLog,
        (flightLog) => flightLog.nightLandings,
      ),
      multiPilot: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) =>
          timeToMinutes(flightLog.multiPilot),
        ),
      ),
      day: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) => timeToMinutes(flightLog.day)),
      ),
      night: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) => timeToMinutes(flightLog.night)),
      ),
      vfr: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) => timeToMinutes(flightLog.vfr)),
      ),
      ifr: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) => timeToMinutes(flightLog.ifr)),
      ),
      pic: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) => timeToMinutes(flightLog.pic)),
      ),
      sic: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) => timeToMinutes(flightLog.sic)),
      ),
      dual: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) => timeToMinutes(flightLog.dual)),
      ),
      instructor: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) =>
          timeToMinutes(flightLog.instructor),
        ),
      ),
      fstdTime: minutesToTime(
        _sumBy(parsedFlightLog, (flightLog) =>
          flightLog.fstdTime === '-' ? 0 : timeToMinutes(flightLog.fstdTime),
        ),
      ),
      departureDate: '-',
      arrivalDate: '-',
      arrival: '-',
      aircraft: '-',
      departure: '-',
      registration: '-',
      firstName: '-',
      lastName: '-',
    };

    return sumFlightLog;
  };

  manageLogbookList(flightLogList) {
    let parsedFlightLog = flightLogList.map((flightLog) => ({
      ...flightLog,
      crewOne: this.renderFlightLogDetailsLink(
        flightLog.id,
        `${flightLog.user.firstName} ${flightLog.user.lastName}`,
      ),
      crewTwo: this.renderFlightLogDetailsLink(
        flightLog.id,
        flightLog.additionalPilot
          ? `${flightLog.additionalPilot.firstName} ${flightLog.additionalPilot.lastName}`
          : '',
      ),
      dateOfFlight: reformatPickerDateFromDB(flightLog.dateOfFlight, false),
      departureDate: reformatPickerDateTimeFromDB(
        flightLog.departureDate,
        false,
      ),
      arrivalDate: reformatPickerDateTimeFromDB(flightLog.arrivalDate, false),
      arrival: _get(flightLog, 'arrival', ''),
      aircraft: _get(flightLog, 'aircraft.name', ''),
      departure: _get(flightLog, 'departure', ''),
      registration: _get(flightLog, 'aircraft.registration', ''),
      spSe: this.getSinglePilotSum(
        flightLog.singlePilot,
        flightLog.singleEngine,
      ),
      spMe: this.getSinglePilotSum(
        flightLog.singlePilot,
        flightLog.multiEngine,
      ),
      icons: this.renderIcons(flightLog),
      fstdType: this.isSimulatorChecked(flightLog.aircraft)
        ? flightLog.aircraft.name
        : '',
      fstdTime: this.isSimulatorChecked(flightLog.aircraft)
        ? flightLog.totalTime
        : ZERO_TIME,
    }));

    const totalRow = this.getTotalRow(parsedFlightLog);

    parsedFlightLog = parsedFlightLog.map((flightLog) => ({
      ...flightLog,
      dateOfFlight: this.renderFlightLogDetailsLink(
        flightLog.id,
        flightLog.dateOfFlight,
      ),
      departureDate: this.renderFlightLogDetailsLink(
        flightLog.id,
        flightLog.departureDate,
      ),
      arrivalDate: this.renderFlightLogDetailsLink(
        flightLog.id,
        flightLog.arrivalDate,
      ),
      arrival: this.renderFlightLogDetailsLink(flightLog.id, flightLog.arrival),
      aircraft: this.renderFlightLogDetailsLink(
        flightLog.id,
        flightLog.aircraft,
      ),
      departure: this.renderFlightLogDetailsLink(
        flightLog.id,
        flightLog.departure,
      ),
      registration: this.renderFlightLogDetailsLink(
        flightLog.id,
        flightLog.registration,
      ),
      spSe: this.renderFlightLogDetailsLink(flightLog.id, flightLog.spSe),
      spMe: this.renderFlightLogDetailsLink(flightLog.id, flightLog.spMe),
      icons: flightLog.icons,
      fstdType: this.renderFlightLogDetailsLink(
        flightLog.id,
        flightLog.fstdType,
      ),
      fstdTime: this.renderFlightLogDetailsLink(
        flightLog.id,
        flightLog.fstdTime,
      ),
    }));

    if (!_isEmpty(parsedFlightLog)) {
      parsedFlightLog.unshift(totalRow);
    }

    return parsedFlightLog;
  }

  removeFlightLog = () => {
    const { removeFlightLog } = this.state;

    this.props.removeFlightLog(removeFlightLog);

    this.closeRemoveDialog();
  };

  render() {
    const { flightLogList, flightLogsRequest, t } = this.props;
    const { search, secondSearch, columnToSort, sortAsc } =
      this.state.searchSettings;

    if (flightLogsRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center logbook-wrapper">
        <div className="col-lg-8 col-sm-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">airplanemode_active</Icon>
              <h1>{t('logbook:name')}</h1>
            </span>
            <p>{t('logbook:description')}</p>
          </header>
        </div>
        <div className="col-auto add-logbook">
          <Link to="/flight_log/new">
            <Button color="primary">
              <Icon color="primary" className="mr-3">
                playlist_add
              </Icon>
              {t('logbook:addLogbookButton')}
            </Button>
          </Link>
        </div>
        <div className="col-sm-12">
          <Table
            data={this.manageLogbookList(flightLogList)}
            header={TABLE_HEADER}
            handleSort={this.handleSort}
            sortAsc={sortAsc}
            columnToSort={columnToSort}
            handleSearch={this.handleSearch}
            search={search}
            secondSearch={secondSearch}
            getUserFilters={this.getUserFilters}
            searchLabel={t('input:departure')}
            secondSearchLabel={t('input:arrival')}
            doubleSearch
          />
          <Dialog
            open={this.state.removeDialogOpen}
            onClose={this.closeRemoveDialog}
            aria-labelledby="remove-dialog"
            fullWidth
          >
            <RemoveDialog
              message={
                'Are you sure you want to COMPLETELY REMOVE this flight log ?'
              }
              closeRemoveDialog={this.closeRemoveDialog}
              dialogTitle={'Remove flight log'}
              removeFunction={this.removeFlightLog}
            />
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    flightLogList: state.flightLog.flightLogs,
    flightLogsRequest: state.flightLog.flightLogsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchFlightLogs: (searchData) => dispatch(fetchFlightLogs(searchData)),
    removeFlightLog: (id) => dispatch(removeFlightLog(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogbookList);
