export const TABLE_HEADER = [
  {
    prop: 'color',
    sort: false,
    profileView: false,
  },
  {
    prop: 'name',
    sort: true,
    profileView: false
  },
  {
    prop: 'type',
    sort: true,
    profileView: false
  },
  {
    prop: 'location',
    sort: true,
    profileView: false
  },
  {
    prop: 'certificate',
    sort: true,
    profileView: false
  },
  {
    prop: 'issueDate',
    sort: true,
    profileView: false
  },
  {
    prop: 'expiryDate',
    sort: true,
    profileView: false
  },
  {
    prop: 'files',
    sort: true,
    profileView: false
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true,
    iconWidth: true
  }
];
