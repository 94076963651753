import axios from 'axios';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { addError } from './errors';
import { urlJoin, defaultCrewStatusFilerMonthsValue } from '../../utils';
import { prepareCrewStatusFilter, getDashboardQueryParametersFilter } from '../utils/time';
import { URLS_RAIL as URLS } from '../../constants';

export const ADD_MEDICAL = 'ADD_MEDICAL';
export const REMOVE_MEDICAL = 'REMOVE_MEDICAL';
export const EDIT_MEDICAL = 'EDIT_MEDICAL';
export const FETCH_MEDICALS_SUCCESS = 'FETCH_MEDICALS_SUCCESS';
export const FETCH_ALL_MEDICALS_SUCCESS = 'FETCH_ALL_MEDICALS_SUCCESS';
export const FETCH_MEDICALS = 'FETCH_MEDICALS';

export function fetchMedicalsSuccess(medicals) {
  return {
    type: FETCH_MEDICALS_SUCCESS,
    payload: {
      medicals
    }
  };
}

export function fetchAllMedicalsSuccess(allMedicals) {
  return {
    type: FETCH_ALL_MEDICALS_SUCCESS,
    payload: {
      allMedicals
    }
  };
}

export function fetchAllMedicalsRequest() {
  return {
    type: FETCH_MEDICALS,
  };
}

export function addMedicalSuccess(medical) {
  return {
    type: ADD_MEDICAL,
    payload: {
      medical
    }
  };
}

export function removeMedicalSuccess(medical) {
  return {
    type: REMOVE_MEDICAL,
    payload: {
      medical
    }
  };
}

export function editMedicalSuccess(medical) {
  return {
    type: EDIT_MEDICAL,
    payload: {
      medical
    }
  };
}

export function addMedical(medical) {
  return (dispatch) => {
    return axios.post(URLS.medicals, medical)
      .then(({ data }) => dispatch(addMedicalSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during medical adding ${error.message}`, error));
      });
  };
}

export function addMedicalPlannedAction(medical) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.medicalsPlannedAction, medical.id), medical)
      .then(({ data }) => dispatch(editMedicalSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during adding medical planned action ${error.message}`, error));
      });
  };
}

export function removeMedical(medical) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.medicals, medical.id))
      .then(() => dispatch(removeMedicalSuccess(medical)))
      .catch((error) => {
        dispatch(addError(`Error during medical removing ${error.message}`, error));
      });
  };
}

export function editMedical(medical) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.medicals, medical.id), medical)
      .then(({ data }) => dispatch(editMedicalSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during medical editing ${error.message}`, error));
      });
  };
}

export function deactivateMedical(medical) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.medicalsDeactivate, medical.id), medical)
      .then(({ data }) => dispatch(editMedicalSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during medical editing ${error.message}`, error));
      });
  };
}

export function fetchMedicals(userId) {
  return (dispatch) => {
    const queryParameters = `?${getDashboardQueryParametersFilter(userId)}&ordering=expiry_date`;

    return axios.get(urlJoin(URLS.medicals, queryParameters))
      .then(({ data }) => dispatch(fetchMedicalsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during medicals fetching ${error.message}`, error));
      });
  };
}

export function fetchAllMedicals(sortingData) {
  return (dispatch) => {
    dispatch(fetchAllMedicalsRequest());
    let crewStatusParameters = '';
    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData && !_isEmpty(sortingData.filter)) {
      const monthId = _get(sortingData.filter, 'months[0].id', defaultCrewStatusFilerMonthsValue);

      crewStatusParameters = prepareCrewStatusFilter(monthId);

      if (sortingData.filter.isVerified) {
        crewStatusParameters += '&is_verified=False';
      }
    }

    queryParameters = _isEmpty(queryParameters) ? `?${crewStatusParameters}` : `?${qs.stringify(queryParameters)}&${crewStatusParameters}`;

    return axios.get(urlJoin(URLS.allMedicals, queryParameters))
      .then(({ data }) => dispatch(fetchAllMedicalsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during medicals fetching ${error.message}`, error));
      });
  };
}

