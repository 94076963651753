import React, { Component } from 'react';

import TestList from './TestsList';
import { PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

@componentPermission(PERMISSIONS.assignedExamView)
export default class CrossTestList extends Component {
  render() {
    return (
      <TestList />
    );
  }
}


