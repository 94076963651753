import _get from 'lodash/get';

import {
  FETCH_METAR_SUCCESS,
  FETCH_METAR_REQUEST,
  FETCH_METAR_FAILURE,
  FETCH_TAF_SUCCESS,
  FETCH_TAF_REQUEST,
  FETCH_TAF_FAILURE,
} from '../actions/weather';

const INITIAL_STATE = {
  metarData: [],
  tafData: [],
  metarRequest: false,
  tafRequest: false,
};

function fetchMetar(state, payload) {
  return {
    ...state,
    metarData: {
      ...state.metarData,
      [payload.location]: {
        rawText: _get(payload.metar, 'raw_text.$', ''),
        issueTime: _get(payload.metar, 'issue_time.$', ''),
        stationId: _get(payload.metar, 'station_id.$', ''),
      }
    },
    metarRequest: false,
  };
}

function fetchMetarRequest(state) {
  return {
    ...state,
    metarRequest: true,
  };
}

function fetchMetarFailure(state) {
  return {
    ...state,
    metarRequest: false,
  };
}

function fetchTaf(state, payload) {
  return {
    ...state,
    tafData: {
      ...state.tafData,
      [payload.location]: {
        rawText: _get(payload.taf, 'raw_text.$', ''),
        issueTime: _get(payload.taf, 'issue_time.$', ''),
        stationId: _get(payload.taf, 'station_id.$', ''),
      }
    },
    tafRequest: false,
  };
}

function fetchTafRequest(state) {
  return {
    ...state,
    tafRequest: true,
  };
}

function fetchTafFailure(state) {
  return {
    ...state,
    tafRequest: false,
  };
}


const actions = {
  [FETCH_METAR_SUCCESS]: fetchMetar,
  [FETCH_METAR_REQUEST]: fetchMetarRequest,
  [FETCH_METAR_FAILURE]: fetchMetarFailure,
  [FETCH_TAF_SUCCESS]: fetchTaf,
  [FETCH_TAF_REQUEST]: fetchTafRequest,
  [FETCH_TAF_FAILURE]: fetchTafFailure,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
