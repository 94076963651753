import { URLS } from '../../constants';
import _isEmpty from 'lodash/isEmpty';
import { addError } from './errors';
import axios from 'axios';
import history from '../store/history';
import qs from 'qs';
import { urlJoin } from '../../utils';

export const ADD_FLIGHT_LOG = 'ADD_FLIGHT_LOG';
export const FETCH_FLIGHT_LOGS_REQUEST = 'FETCH_FLIGHT_LOGS_REQUEST';
export const FETCH_FLIGHT_LOGS_SUCCESS = 'FETCH_FLIGHT_LOGS_SUCCESS';
export const FETCH_FLIGHT_LOG_SUCCESS = 'FETCH_FLIGHT_LOG_SUCCESS';
export const FETCH_FLIGHT_LOGS_FAILURE = 'FETCH_FLIGHT_LOGS_FAILURE';
export const REMOVE_FLIGHT_LOG_SUCCESS = 'REMOVE_FLIGHT_LOG_SUCCESS';
export const RESET_ADD_FLIGHT_LOG_SUCCESS = 'RESET_ADD_FLIGHT_LOG_SUCCESS';
export const CLEAN_FLIGHT_LOG_DATA = 'CLEAN_FLIGHT_LOG_DATA';
export const EDIT_FLIGHT_LOG = 'EDIT_FLIGHT_LOG';

const sortingPossibitilies = {
  totalTime: 'total_time',
  dateOfFlight: 'date_of_flight',
  departure: 'departure',
  departureDate: 'departure_date',
  arrival: 'arrival',
  arrivalDate: 'arrival_date',
  aircraft: 'aircraft__name',
  registration: 'aircraft__registration',
  multiPilot: 'multi_pilot',
  dayLandings: 'day_landings',
  nightLandings: 'night_landings',
  day: 'day',
  night: 'night',
  vfr: 'vfr',
  ifr: 'ifr',
  pic: 'pic',
  sic: 'sic',
  dual: 'dual',
  instructor: 'instructor',
  firstName: 'user__first_name',
  lastName: 'user__last_name'
};


export function fetchFlightLogsRequest() {
  return {
    type: FETCH_FLIGHT_LOGS_REQUEST
  };
}

export function fetchFlightLogsSuccess(flightLogs) {
  return {
    type: FETCH_FLIGHT_LOGS_SUCCESS,
    payload: {
      flightLogs
    }
  };
}

export function fetchFlightLogSuccess(flightLog) {
  return {
    type: FETCH_FLIGHT_LOG_SUCCESS,
    payload: {
      flightLog
    }
  };
}

export function fetchFlightLogsFailure() {
  return {
    type: FETCH_FLIGHT_LOGS_FAILURE
  };
}

export function removeFlightLogSuccess(flightLogId) {
  return {
    type: REMOVE_FLIGHT_LOG_SUCCESS,
    payload: {
      flightLogId
    }
  };
}

export function addFlightLogSuccess(flightLog) {
  return {
    type: ADD_FLIGHT_LOG,
    payload: {
      flightLog
    }
  };
}

export function cleanFlightLog() {
  return {
    type: CLEAN_FLIGHT_LOG_DATA,
  };
}

export function editFlightLogSuccess(flightLog) {
  return {
    type: EDIT_FLIGHT_LOG,
    payload: {
      flightLog
    }
  };
}

export function getQueryParametrFilter(queryParametersFilter) {
  if (_isEmpty(queryParametersFilter)) {
    return '';
  }

  return `${queryParametersFilter}&`;
}

export function generateQueryParameters(sortingData) {
  let queryParameters = {};
  let queryParametersFilter = '';

  if (sortingData) {
    if (sortingData.userId || !_isEmpty(sortingData.secondSearch) || !_isEmpty(sortingData.search)) {
      queryParametersFilter = `user__id=${encodeURIComponent(sortingData.userId)}`;
    }

    if (!_isEmpty(sortingData.search)) {
      queryParametersFilter = `${getQueryParametrFilter(queryParametersFilter)}${'departure'}=${encodeURIComponent(sortingData.search)}`;
    }

    if (!_isEmpty(sortingData.secondSearch)) {
      queryParametersFilter = `${getQueryParametrFilter(queryParametersFilter)}${'arrival'}=${encodeURIComponent(sortingData.secondSearch)}`;
    }

    if (!_isEmpty(sortingData.columnToSort)) {
      queryParameters.ordering = `${sortingData.sortAsc ? '' : '-'}${sortingPossibitilies[sortingData.columnToSort]}`;
    }
  }

  queryParameters = qs.stringify(queryParameters);

  if (!_isEmpty(queryParametersFilter)) {
    queryParameters = `${queryParameters}&${queryParametersFilter}`;
  }

  return _isEmpty(queryParameters) ? '' : `?${queryParameters}`;
}

export function fetchFlightLogs(sortingData) {
  return (dispatch) => {
    dispatch(fetchFlightLogsRequest());

    const queryParameters = generateQueryParameters(sortingData);

    return axios.get(urlJoin(URLS.flightLog, queryParameters))
      .then(({ data }) => {
        dispatch(fetchFlightLogsSuccess(data.results));
      })
      .catch((error) => {
        dispatch(fetchFlightLogsFailure());
        dispatch(addError(`Error during flight logs fetching ${error.message}`, error));
      });
  };
}

export function fetchFlightLog(flightLogId) {
  return (dispatch) => {
    dispatch(fetchFlightLogsRequest());

    return axios.get(urlJoin(URLS.flightLog, flightLogId))
      .then(({ data }) => {
        dispatch(fetchFlightLogSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchFlightLogsFailure());
        dispatch(addError(`Error during flight log fetching ${error.message}`, error));
      });
  };
}

export function removeFlightLog(flightLogId) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.flightLog, flightLogId))
      .then(() => dispatch(removeFlightLogSuccess(flightLogId)))
      .catch((error) => {
        dispatch(addError(`Error during flight log removing ${error.message}`, error));
      });
  };
}

export function addFlightLog(flightLog, changeDestination = true) {
  return (dispatch) => {
    return axios.post(URLS.flightLog, flightLog)
      .then(({ data }) => {
        dispatch(addFlightLogSuccess(data));

        if (changeDestination) {
          history.push('/my_logbook');
        }
      })
      .catch((error) => {
        dispatch(addError(`Error during flight log adding ${error.message}`, error));
      });
  };
}

export function cleanFlightLogData() {
  return (dispatch) => dispatch(cleanFlightLog());
}

export function editFlightLog(flightLogId, updatedFlightLog) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.flightLog, flightLogId), updatedFlightLog)
      .then(({ data }) => {
        dispatch(editFlightLogSuccess(data));
        history.push('/logbook_list');
      })
      .catch((error) => {
        dispatch(addError(`Error during flight log editing ${error.message}`, error));
      });
  };
}
