import { URLS } from '../../constants';
import _isEmpty from 'lodash/isEmpty';
import { addError } from './errors';
import axios from 'axios';
import { fileUpload } from '../../utils/fileUpload';
import history from '../store/history';
import { urlJoin } from '../../utils';

export const FETCH_ASSIGNED_COURSES_REQUEST = 'FETCH_ASSIGNED_COURSES_REQUEST';
export const FETCH_ASSIGNED_COURSES_SUCCESS = 'FETCH_ASSIGNED_COURSES_SUCCESS';
export const FETCH_ASSIGNED_COURSES_FAILURE = 'FETCH_ASSIGNED_COURSES_FAILURE';
export const FETCH_ASSIGNED_COURSES_PAGINATION_REQUEST =
  'FETCH_ASSIGNED_COURSES_PAGINATION_REQUEST';
export const FETCH_ASSIGNED_COURSES_PAGINATION_SUCCESS =
  'FETCH_ASSIGNED_COURSES_PAGINATION_SUCCESS';
export const FETCH_ASSIGNED_COURSE_REQUEST = 'FETCH_ASSIGNED_COURSE_REQUEST';
export const FETCH_ASSIGNED_COURSE_SUCCESS = 'FETCH_ASSIGNED_COURSE_SUCCESS';
export const FETCH_ASSIGNED_COURSE_FAILURE = 'FETCH_ASSIGNED_COURSE_FAILURE';
export const FETCH_ASSIGNED_COURSE_STUDENTS_REQUEST =
  'FETCH_ASSIGNED_COURSE_STUDENTS_REQUEST';
export const FETCH_ASSIGNED_COURSE_STUDENTS_SUCCESS =
  'FETCH_ASSIGNED_COURSE_STUDENTS_SUCCESS';
export const FETCH_ASSIGNED_COURSE_STUDENTS_FAILURE =
  'FETCH_ASSIGNED_COURSE_STUDENTS_FAILURE';
export const EDIT_ASSIGNED_COURSE = 'EDIT_ASSIGNED_COURSE';
export const REMOVE_ASSIGNED_COURSE = 'REMOVE_ASSIGNED_COURSE';
export const ASSIGN_COURSE_SUCCESS = 'ASSIGN_COURSE_SUCCESS';
export const RESET_ASSIGN_COURSE_SUCCESS = 'RESET_ASSIGN_COURSE_SUCCESS';

export const ASSIGNED_COURSE_LESSON_ADD_DOCUMENT_SUCCESS =
  'ADD_DOCUMENT_ASSIGN_COURSE_LESSON_SUCCESS';

const sortingPossibitilies = {
  firstName: 'user__first_name',
  lastName: 'user__last_name',
  name: 'course__name',
};

export function assignedCourseLessonAddDocumentSuccess(assignedCourse) {
  return {
    type: EDIT_ASSIGNED_COURSE,
    payload: {
      assignedCourse,
    },
  };
}

export function fetchAssignedCoursesPaginationRequest() {
  return {
    type: FETCH_ASSIGNED_COURSES_PAGINATION_REQUEST,
  };
}

export function fetchAssignedCoursesPaginationSuccess(assignedCourses) {
  return {
    type: FETCH_ASSIGNED_COURSES_PAGINATION_SUCCESS,
    payload: {
      assignedCourses,
    },
  };
}

export function fetchAssignedCoursesRequest() {
  return {
    type: FETCH_ASSIGNED_COURSES_REQUEST,
  };
}

export function fetchAssignedCoursesSuccess(assignedCourses) {
  return {
    type: FETCH_ASSIGNED_COURSES_SUCCESS,
    payload: {
      assignedCourses,
    },
  };
}

export function fetchAssignedCoursesFailure() {
  return {
    type: FETCH_ASSIGNED_COURSES_FAILURE,
  };
}

export function fetchAssignedCourseRequest() {
  return {
    type: FETCH_ASSIGNED_COURSE_REQUEST,
  };
}

export function fetchAssignedCourseSuccess(assignedCourse) {
  return {
    type: FETCH_ASSIGNED_COURSE_SUCCESS,
    payload: {
      assignedCourse,
    },
  };
}

export function fetchAssignedCourseFailure() {
  return {
    type: FETCH_ASSIGNED_COURSE_FAILURE,
  };
}

export function editAssignedCourseSuccess(assignedCourse) {
  return {
    type: EDIT_ASSIGNED_COURSE,
    payload: {
      assignedCourse,
    },
  };
}

export function removeAssignedCourseSuccess(assignedCourse) {
  return {
    type: REMOVE_ASSIGNED_COURSE,
    payload: {
      assignedCourse,
    },
  };
}

export function assignCourseSuccess() {
  return {
    type: ASSIGN_COURSE_SUCCESS,
  };
}

export function resetAssignCourseSuccess() {
  return {
    type: RESET_ASSIGN_COURSE_SUCCESS,
  };
}

export function addDocumentToLesson(file, assignedCourseId, lessonId) {
  return (dispatch) => {
    fileUpload(
      file,
      'PATCH',
      urlJoin(
        URLS.assignedCourses,
        `${assignedCourseId}/lesson/${lessonId}/upload_document/`,
      ),
      {
        onFinished: (data) => {
          dispatch(fetchAssignedCourseSuccess(data));
        },
        onFailure: (error) =>
          dispatch(
            addError(`Error during file uploading ${error.message}`, error),
          ),
        onProgress: () => {},
      },
    );
  };
}

export function removeDocumentFromLesson(fileId, assignedCourseId, lessonId) {
  return (dispatch) => {
    axios
      .delete(
        urlJoin(
          URLS.assignedCourses,
          `${assignedCourseId}/lesson/${lessonId}/remove_document/${fileId}/`,
        ),
      )
      .then((data) => {
        dispatch(fetchAssignedCourseSuccess(data.data));
      })
      .catch((error) => {
        dispatch(
          addError(`Error during delete document ${error.message}`, error),
        );
      });
  };
}

export function assignCourse(assignedData) {
  return (dispatch) => {
    return axios
      .post(URLS.assignedCourses, assignedData)
      .then(() => dispatch(assignCourseSuccess()))
      .catch((error) => {
        dispatch(
          addError(`Error during course assigning ${error.message}`, error),
        );
      });
  };
}

export function fetchAssignedCourses(sortingData, paginationParams) {
  return (dispatch) => {
    const params = [];

    if (paginationParams) {
      dispatch(fetchAssignedCoursesPaginationRequest());

      params.push(paginationParams);
    } else {
      dispatch(fetchAssignedCoursesRequest());

      if (sortingData && !_isEmpty(sortingData.search)) {
        params.push(`search=${sortingData.search}`);
      }

      if (sortingData && !!sortingData.userId) {
        params.push(`user__id=${sortingData.userId}`);
      }

      if (sortingData && !_isEmpty(sortingData.columnToSort)) {
        params.push(
          `ordering=${sortingData.sortAsc ? '' : '-'}${
            sortingPossibitilies[sortingData.columnToSort]
          }`,
        );
      }
    }

    const queryParameters = _isEmpty(params) ? '' : `?${params.join('&')}`;

    return axios
      .get(urlJoin(URLS.assignedCourses, queryParameters))
      .then(({ data }) =>
        dispatch(
          paginationParams
            ? fetchAssignedCoursesPaginationSuccess(data)
            : fetchAssignedCoursesSuccess(data),
        ),
      )
      .catch((error) => {
        dispatch(fetchAssignedCoursesFailure());
        dispatch(
          addError(
            `Error during assigned course fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function fetchAssignedCourse(courseId) {
  return (dispatch) => {
    dispatch(fetchAssignedCourseRequest());

    return axios
      .get(urlJoin(URLS.assignedCourses, courseId))
      .then(({ data }) => dispatch(fetchAssignedCourseSuccess(data)))
      .catch((error) => {
        dispatch(fetchAssignedCourseFailure());
        dispatch(
          addError(
            `Error during assigned course fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function editAssignedCourse(courseId, courseData, goBack) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.assignedCourses, courseId), courseData)
      .then(({ data }) => {
        dispatch(editAssignedCourseSuccess(data));

        if (goBack) {
          if (typeof goBack === 'string') {
            history.push(goBack);
          } else {
            history.goBack();
          }
        }
      })
      .catch((error) => {
        dispatch(
          addError(
            `Error during assigned course editing ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function removeAssignedCourse(assignedCourse) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.assignedCourses, assignedCourse.id))
      .then(() => dispatch(removeAssignedCourseSuccess(assignedCourse)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during assigned course removing ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function resetAssignCourseFlag() {
  return (dispatch) => dispatch(resetAssignCourseSuccess());
}

export function fetchAssignedCourseStudentsRequest() {
  return {
    type: FETCH_ASSIGNED_COURSE_STUDENTS_REQUEST,
  };
}

export function fetchAssignedCourseStudentsSuccess(assignedCourseStudents) {
  return {
    type: FETCH_ASSIGNED_COURSE_STUDENTS_SUCCESS,
    payload: {
      assignedCourseStudents,
    },
  };
}

export function fetchAssignedCourseStudentsFailure() {
  return {
    type: FETCH_ASSIGNED_COURSE_STUDENTS_FAILURE,
  };
}

export function fetchAssignedCourseStudents(assignedCourseId, lessonId) {
  return (dispatch) => {
    dispatch(fetchAssignedCourseStudentsRequest());

    return axios
      .get(urlJoin(URLS.assignedTheoreticalLessons, assignedCourseId, lessonId))
      .then(({ data }) =>
        dispatch(fetchAssignedCourseStudentsSuccess(data.results)),
      )
      .catch((error) => {
        dispatch(fetchAssignedCourseStudentsFailure());
        dispatch(
          addError(
            `Error during fetching assigned course students: ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function updateTheoreticalLessons(
  message,
  assignedCourseId,
  lessonId,
  goBack,
) {
  return (dispatch) => {
    return axios
      .post(URLS.assignedTheoreticalLessons, message)
      .then(() => {
        dispatch(fetchAssignedCourse(assignedCourseId));
        dispatch(fetchAssignedCourseStudents(assignedCourseId, lessonId));

        if (goBack) {
          if (typeof goBack === 'string') {
            history.push(goBack);
          } else {
            history.goBack();
          }
        }
      })
      .catch((error) => {
        dispatch(
          addError(
            `Error during updating theoretical lessons: ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function notifyAssignedCourses(userId) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.notifyAssignedCourse, userId), { isNotified: true })
      .catch((error) => {
        dispatch(
          addError(`Error during notify courses ${error.message}`, error),
        );
      });
  };
}
