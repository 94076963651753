import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Icon from '@material-ui/core/Icon';

import RemoveDialogContainer from '../../../common/components/RemoveDialogContainer';
import NotificationRecipientManager from './Notifications/NotificationRecipientManager';
import DefaultNotificationRecipientManager from './Notifications/DefaultNotificationRecipientManager';
import NotificationCategoriesManager from './Notifications/NotificationCategoriesManager';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';
import { componentPermission } from '../../decorators/permissions';

import './styles.scss';

const INITIAL_STATE = {
  showRemoveDialog: false,
  removeCallback: () => {},
};

@withNamespaces()
@componentPermission(PERMISSIONS.generalSettingsView)
export default class UserSettings extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  render() {
    const { t } = this.props;
    const { showRemoveDialog, removeCallback } = this.state;

    return (
      <div className="settings row">
        <div className="col-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">
                settings
              </Icon>
              <h1>{t('settings:name')}<span className="h1-subheader">/{t('settings:notificationSubTitle')}</span></h1>
            </span>
            <p>{t('settings:description')}</p>
          </header>
        </div>
        <div className="col-12">
          <h2>{t('settings:notificationsSection')}</h2>
          <NotificationCategoriesManager />
        </div>
        <div className="col-12">
          <NotificationRecipientManager onElementDelete={this.openRemoveDialog} />
        </div>
        <div className="col-12">
          <h2>{t('settings:defaultNotificationSection')}</h2>
          <DefaultNotificationRecipientManager onElementDelete={this.openRemoveDialog} />
        </div>
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
      </div>
    );
  }
}
