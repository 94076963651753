import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CourseNew from '../CourseNew/index';
import Loader from '../../../../common/components/Loader';
import { fetchCourse } from '../../../actions/courses';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

const mapStateToProps = (state) => {
  return {
    course: state.courses.course,
    courseRequest: state.courses.courseDetailsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCourse: (id) => dispatch(fetchCourse(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.assignedCoursePreview)
@componentPermission(PERMISSIONS.coursesView)
export default class CourseDetails extends Component {
  static propTypes = {
    course: PropTypes.object.isRequired,
    fetchCourse: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    courseRequest: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { fetchCourse, match } = this.props;

    fetchCourse(match.params.id);
  }

  render() {
    const { course, courseRequest } = this.props;

    if (courseRequest) {
      return <Loader />;
    }

    return (
      <CourseNew course={course} preview />
    );
  }
}

