import '../../../styles.scss';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { Component } from 'react';
import {
  addEmergencyContact,
  editEmergencyContact,
} from '../../../../../actions/emergencyContact';

import Button from '@material-ui/core/Button';
import Form from '../../../../../../common/components/forms/Form';
import MaterialInput from '../../../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { connect } from 'react-redux';
import validators from '../../../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

function mapDispatchToProps(dispatch) {
  return {
    addEmergencyContact: (userId, emergencyContact) => dispatch(addEmergencyContact(userId, emergencyContact)),
    editEmergencyContact: (userId, emergencyContact) => dispatch(editEmergencyContact(userId, emergencyContact)),
  };
}

const INITIAL_STATE = {
  firstName: null,
  lastName: null,
  relation: null,
  phone: null,
  email: null,
};

@withNamespaces()
@connect(() => ({}), mapDispatchToProps)
@withStyles()
export default class AddEditEmergencyContactDialog extends Component {

    static propTypes = {
      userId: PropTypes.string.isRequired,
      addEmergencyContact: PropTypes.func.isRequired,
      editEmergencyContact: PropTypes.func.isRequired,
      classes: PropTypes.object.isRequired,
      emergencyContact: PropTypes.object,
      t: PropTypes.func.isRequired,
      edit: PropTypes.bool.isRequired,
      open: PropTypes.bool.isRequired,
      onDialogClose: PropTypes.func.isRequired
    }

    state = INITIAL_STATE;

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ ...this.props.emergencyContact });
      }
    }

    onChange = (formData) => {
      _each(formData, ({ value }, key) => {
        this.setState({
          [key]: value
        });
      });
    }

    registerForm = (triggerFormValidation) => {
      this.triggerFormValidation = triggerFormValidation;
    }

    onSave = (isFormValid) => {
      const { edit, userId } = this.props;

      if (isFormValid && this.state) {
        edit ? this.props.editEmergencyContact(userId, this.state) : this.props.addEmergencyContact(userId, this.state);
        this.setState(INITIAL_STATE);
        this.closeDialog();
      }
    }

    closeDialog = () => {
      const { onDialogClose } = this.props;

      this.setState(INITIAL_STATE);
      onDialogClose();
    }

    triggerValidation = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.triggerFormValidation();
    }

    render() {
      const { t, classes, open } = this.props;

      return (
        <div>
          <Dialog
            PaperProps={{
              classes: { root: classes.root },
            }}
            open={open}
            aria-labelledby="emergency-contact-dialog"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>{t('user:Add emergency contact')}</DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row align-items-center ">
                <Form
                  onChange={this.onChange}
                  onFormValidated={this.onSave}
                  registerForm={this.registerForm}
                >

                  <div className="col-lg-10 col-xs-12 row">
                    <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
                      <MaterialInput
                        fullWidth
                        multiline
                        id="firstName"
                        name="firstName"
                        label={t('input:firstName')}
                        defaultValue={this.state.firstName}
                        validators={[
                          new validators.IsRequired(t),
                        ]}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
                      <MaterialInput
                        fullWidth
                        multiline
                        id="lastName"
                        name="lastName"
                        label={t('input:lastName')}
                        defaultValue={this.state.lastName}
                        validators={[
                          new validators.IsRequired(t),
                        ]}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
                      <MaterialInput
                        fullWidth
                        multiline
                        id="relation"
                        name="relation"
                        label={t('input:relation')}
                        defaultValue={this.state.relation}
                        validators={[
                          new validators.IsRequired(t),
                        ]}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
                      <MaterialInput
                        fullWidth
                        multiline
                        id="phone"
                        name="phone"
                        label={t('input:phone')}
                        defaultValue={this.state.phone}
                      />
                    </div>
                    <div className="col-xl-6 col-sm-6 col-xs-12 py-2">
                      <MaterialInput
                        fullWidth
                        multiline
                        id="email"
                        name="email"
                        label={t('input:email')}
                        defaultValue={this.state.email}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </DialogContent>
            <DialogActions className="row justify-content-end">
              <Button color="secondary" onClick={this.closeDialog}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.triggerValidation}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
}
