import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import _sumBy from 'lodash/sumBy';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import Table from '../../../../common/components/Table';
import RemoveDialog from '../../../../common/components/RemoveDialog';
import Loader from '../../../../common/components/Loader';
import CustomFilter from '../../../../common/components/CustomFilter';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { fetchJourneyLogs, removeJourneyLog } from '../../../actions/journeyLog';
import { minutesToTime, reformatPickerDateFromDB, reformatPickerDateTimeFromDB, timeToMinutes } from '../../../utils/time';
import { TOTAL_ROW } from '../../../../constants';
import { TABLE_HEADER, MONTHS } from './LogbookConstants';

import '../styles.scss';

@withNamespaces()
class LogbookList extends Component {
  static propTypes = {
    fetchJourneyLogs: PropTypes.func.isRequired,
    removeJourneyLog: PropTypes.func.isRequired,
    journeyLogList: PropTypes.array,
    journeyLogsRequest: PropTypes.bool,
    t: PropTypes.func,
    userId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: '',
      secondSearch: '',
      userId: '',
      filter: [],
      filterIsUsed: false
    },
    removeDialogOpen: false,
    removeJourneyLog: null,
  };

  componentDidMount() {
    const { columnToSort, sortAsc, search, secondSearch, filter, filterIsUsed } = this.state.searchSettings;
    const { userId } = this.props;

    if (userId) {
      const searchSettings = {
        columnToSort,
        sortAsc,
        search,
        secondSearch,
        userId,
        filter,
        filterIsUsed
      };

      this.updateSearchSettings(searchSettings);

      return this.props.fetchJourneyLogs(searchSettings);
    }

    return this.props.fetchJourneyLogs();
  }

  updateSearchSettings = (searchSettings) => {
    this.setState({ searchSettings });
  }

  handleSort = (columnName) => {
    const { search, secondSearch, sortAsc, userId, filter, filterIsUsed } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
      secondSearch,
      userId,
      filter,
      filterIsUsed
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchJourneyLogs(searchData);
  };

  handleSearch = (searchInputValue, name) => {
    const { columnToSort, sortAsc, userId, search, secondSearch, filter, filterIsUsed } = this.state.searchSettings;
    const searchValue = name === 'searchValue' ? searchInputValue : search;
    const secondSearchValue = name === 'secondSearchValue' ? searchInputValue : secondSearch;

    const searchData = {
      columnToSort,
      sortAsc,
      search: searchValue,
      secondSearch: secondSearchValue,
      userId,
      filter,
      filterIsUsed
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchJourneyLogs(searchData);
  };

  handleFilter = (values) => {
    const { columnToSort, sortAsc, search, secondSearch, userId } = this.state.searchSettings;

    const filterData = {
      columnToSort,
      sortAsc,
      search,
      secondSearch,
      userId,
      filter: values,
      filterIsUsed: !_isEmpty(values)
    };

    this.setState({
      searchSettings: filterData,
    });

    this.props.fetchJourneyLogs(filterData);
  };

  getUserFilters = () => {
    const { filterIsUsed, filter } = this.state.searchSettings;

    return (
      <CustomFilter
        groups={[]}
        roles={MONTHS}
        radioButton
        isFilterUsed={filterIsUsed}
        handleFilter={this.handleFilter}
        filterSettings={filter}
      />
    );
  };

  openRemoveDialog = (journeyLog) => {
    this.setState({
      removeDialogOpen: true,
      removeJourneyLog: journeyLog,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeJourneyLog: null,
    });
  };

  renderDeleteIcon(journeyLogId) {
    return (
      <IconButton
        key="user-remove-icon"
        onClick={() => this.openRemoveDialog(journeyLogId)}
      >
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  renderEditIcon(journeyLogId) {
    return (
      <Link key="flight-log-button-edit" to={`/journey_log/edit/${journeyLogId}`}>
        <IconButton>
          <Icon color="primary">
            mode_edit
          </Icon>
        </IconButton>
      </Link>
    );
  }

  renderJourneyLogDetailsLink(id, prop) {
    return (
      <Link className="table-row-link" to={`/journey_log/details/${id}`}>{prop}</Link>
    );
  }

  renderIcons(journeyLog) {
    const journeyLogId = journeyLog.id;

    return [
      this.renderEditIcon(journeyLogId),
      this.renderDeleteIcon(journeyLogId)
    ];
  }

  getTotalRow = (parsedJourneyLog) => {
    const sumJourneyLog = {
      dateOfJourneyLog: TOTAL_ROW,
      totalTime: minutesToTime(_sumBy(parsedJourneyLog, (journeyLog) => timeToMinutes(journeyLog.totalTime))),
      type: '-',
      firstName: '-',
      lastName: '-',
      departureDate: '-',
      arrivalDate: '-',
      departure: '-',
      arrival: '-',
    };

    return sumJourneyLog;
  };

  manageLogbookList(journeyLogList) {
    let parsedJourneyLog = journeyLogList.map((journeyLog) => ({
      ...journeyLog,
      firstName: this.renderJourneyLogDetailsLink(journeyLog.id, journeyLog.user.firstName),
      lastName: this.renderJourneyLogDetailsLink(journeyLog.id, journeyLog.user.lastName),
      dateOfJourneyLog: reformatPickerDateFromDB(journeyLog.dateOfJourneyLog, false),
      departureDate: reformatPickerDateTimeFromDB(journeyLog.departureDate, false),
      arrivalDate: reformatPickerDateTimeFromDB(journeyLog.arrivalDate, false),
      arrival: _get(journeyLog, 'arrival', ''),
      departure: _get(journeyLog, 'departure', ''),
      type: _get(journeyLog, 'type.name', ''),
      icons: this.renderIcons(journeyLog)
    }));

    const totalRow = this.getTotalRow(parsedJourneyLog);

    parsedJourneyLog = parsedJourneyLog.map((journeyLog) => ({
      ...journeyLog,
      dateOfJourneyLog: this.renderJourneyLogDetailsLink(journeyLog.id, journeyLog.dateOfJourneyLog),
      departureDate: this.renderJourneyLogDetailsLink(journeyLog.id, journeyLog.departureDate),
      arrivalDate: this.renderJourneyLogDetailsLink(journeyLog.id, journeyLog.arrivalDate),
      arrival: this.renderJourneyLogDetailsLink(journeyLog.id, journeyLog.arrival),
      departure: this.renderJourneyLogDetailsLink(journeyLog.id, journeyLog.departure),
      type: this.renderJourneyLogDetailsLink(journeyLog.id, journeyLog.type),
      icons: this.renderIcons(journeyLog)
    }));

    if (!_isEmpty(parsedJourneyLog)) {
      parsedJourneyLog.unshift(totalRow);
    }

    return parsedJourneyLog;
  }

  removeJourneyLog = () => {
    const { removeJourneyLog } = this.state;

    this.props.removeJourneyLog(removeJourneyLog);

    this.closeRemoveDialog();
  };

  render() {
    const { journeyLogList, journeyLogsRequest, t } = this.props;
    const { search, secondSearch, columnToSort, sortAsc } = this.state.searchSettings;

    if (journeyLogsRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center logbook-wrapper">
        <div className="col-lg-8 col-sm-12 section-title pb-3">
          <header>
            <span>
              <Icon color="primary">
                directions_railway
              </Icon>
              <h1>{t('logbook:name')}</h1>
            </span>
            <p>{t('logbook:description')}</p>
          </header>
        </div>
        <div className="col-auto add-logbook">
          <Link to="/journey_log/new">
            <Button color="primary">
              <Icon color="primary" className="mr-3">playlist_add</Icon>
              {t('logbook:addJourneyButton')}
            </Button>
          </Link>
        </div>
        <div className="col-sm-12">
          <Table
            data={this.manageLogbookList(journeyLogList)}
            header={TABLE_HEADER}
            handleSort={this.handleSort}
            sortAsc={sortAsc}
            columnToSort={columnToSort}
            handleSearch={this.handleSearch}
            search={search}
            secondSearch={secondSearch}
            getUserFilters={this.getUserFilters}
            searchLabel={t('input:departure')}
            secondSearchLabel={t('input:arrival')}
            doubleSearch
          />
          <Dialog
            open={this.state.removeDialogOpen}
            onClose={this.closeRemoveDialog}
            aria-labelledby="remove-dialog"
            fullWidth
          >
            <RemoveDialog
              message={'Are you sure you want to COMPLETELY REMOVE this journey  log ?'}
              closeRemoveDialog={this.closeRemoveDialog}
              dialogTitle={'Remove journey log'}
              removeFunction={this.removeJourneyLog}
            />
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    journeyLogList: state.journeyLog.journeyLogs,
    journeyLogsRequest: state.journeyLog.journeyLogsRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchJourneyLogs: (searchData) => dispatch(fetchJourneyLogs(searchData)),
    removeJourneyLog: (id) => dispatch(removeJourneyLog(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogbookList);
