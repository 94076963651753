import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';

import LanguageSelect from '../../../common/components/LanguageSelect';

import logo from '../../../images/qcLogo.png';
import './styles.scss';


export default class Base extends Component {
  renderContent = () => {
    return null;
  }

  renderBaseTemplate = (renderContent = this.renderContent) => {
    return (
      <div className="container-fluid page-content authentication">
        <div className="languages">
          <Paper elevation={4} className="row align-items-center justify-content-center text-center">
            <LanguageSelect />
          </Paper>
        </div>
        <div className="row align-items-center justify-content-center full-height">
          <Paper elevation={4} className="col-xl-5 col-lg-6 col-md-7 text-center py-5">
            <img className="logo pb-5" src={logo} />
            {renderContent()}
          </Paper>
        </div>
      </div>
    );
  }

  render() {
    return this.renderBaseTemplate();
  }
}
