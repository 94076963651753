import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';

import RemoveDialog from '../../../../common/components/RemoveDialog';
import QuestionList from './QuestionList';
import Loader from '../../../../common/components/Loader';

import { fetchQuestions, removeQuestion } from '../../../actions/questions';
import { fetchQuestionCategories } from '../../../actions/questionCategories';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { componentPermission } from '../../../decorators/permissions';

const INITIAL_STATE = {
  searchSettings: {
    sortAsc: true,
    search: '',
    filter: [],
    filterIsUsed: false
  },
  removeDialogOpen: false,
  removeQuestion: null,
};

const mapStateToProps = (state) => {
  return {
    questionList: state.questions.questionList.filter((question) => question.isActive),
    questionCategoryList: state.questionCategories.questionCategoryList,
    questionRequest: state.questions.questionRequest
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchQuestions: (sortData) => dispatch(fetchQuestions(sortData)),
    fetchQuestionCategories: () => dispatch(fetchQuestionCategories()),
    removeQuestion: (question) => dispatch(removeQuestion(question)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
@componentPermission(PERMISSIONS.questionListView)
export default class ManageQuestions extends Component {
  static propTypes = {
    questionCategoryList: PropTypes.array,
    questionList: PropTypes.array,
    questionRequest: PropTypes.bool.isRequired,
    fetchQuestions: PropTypes.func.isRequired,
    removeQuestion: PropTypes.func.isRequired,
    fetchQuestionCategories: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.fetchQuestions();
    this.props.fetchQuestionCategories();
  }

  handleSort = (columnName) => {
    const { sortAsc, search, filter, filterIsUsed } = this.state.searchSettings;
    const searchData = {
      sortAsc: !sortAsc,
      columnToSort: columnName,
      search,
      filter,
      filterIsUsed
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchQuestions(searchData);
  };

  handleSearch = (search) => {
    const { sortAsc, columnToSort, filter, filterIsUsed } = this.state.searchSettings;
    const searchData = {
      sortAsc,
      columnToSort,
      search,
      filter,
      filterIsUsed
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchQuestions(searchData);
  };

  handleFilter = (values) => {
    const { sortAsc, columnToSort, search } = this.state.searchSettings;
    const preparedValues = values.filter((value)=> value && value.id);
    const filterData = {
      sortAsc,
      columnToSort,
      filter: preparedValues,
      search,
      filterIsUsed: !_isEmpty(preparedValues)
    };

    this.setState({
      searchSettings: filterData,
    });

    this.props.fetchQuestions(filterData);
  };

  removeQuestion = () => {
    const { removeQuestion } = this.state;

    this.closeRemoveDialog();
    this.props.removeQuestion(removeQuestion);
  };

  openRemoveDialog = (question) => {
    this.setState({
      removeDialogOpen: true,
      removeQuestion: question,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeQuestion: null,
    });
  };

  renderRemoveDialog = (t) => {
    return (
      <Dialog
        open={this.state.removeDialogOpen}
        onClose={this.closeRemoveDialog}
        aria-labelledby="remove-dialog"
        fullWidth
      >
        <RemoveDialog
          message={t('test:removeQuestion')}
          closeRemoveDialog={this.closeRemoveDialog}
          dialogTitle={t('test:removeQuestionDialogTitle')}
          removeFunction={this.removeQuestion}
        />
      </Dialog>
    );
  };

  render() {
    const { questionList, questionRequest, questionCategoryList, t } = this.props;
    const { sortAsc, columnToSort, search, filter, filterIsUsed } = this.state.searchSettings;

    if (questionRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">
                list_add_check
              </Icon>
              <h1>{t('test:name')}<span className="h1-subheader"> /{t('test:questionBankSection')}</span></h1>
            </span>
            <p>{t('test:descriptionQuestionBank')}</p>
          </header>
        </div>
        <div className="col-auto">
          <Link to="/question_bank/new">
            <Button color="primary">
              <Icon color="primary" className="mr-3">playlist_add</Icon>
                {t('test:addQuestionButton')}
            </Button>
          </Link>
        </div>
        <div className="col-12">
          <QuestionList
            questionList={questionList}
            sortAsc={sortAsc}
            handleSort={this.handleSort}
            columnToSort={columnToSort}
            handleSearch={this.handleSearch}
            search={search}
            handleFilter={this.handleFilter}
            filterIsUsed={filterIsUsed}
            filter={filter}
            questionCategoryList={questionCategoryList}
            openRemoveDialog={this.openRemoveDialog}
          />
          {this.renderRemoveDialog(t)}
        </div>
      </div>
    );
  }
}
