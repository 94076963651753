import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CourseList from '../Training/CourseList/CoursesList';
import userService from '../../../utils/userService';
import { notifyAssignedCourses } from '../../actions/assignedCourses';

const mapStateToProps = (state) => {
  return {
    assignedCourseList: state.assignedCourses.assignedCourseList,
    assignedCourseRequest: state.assignedCourses.assignedCourseRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    notifyAssignedCourses: (userId) => dispatch(notifyAssignedCourses(userId)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class NotifyTests extends Component {
  static propTypes = {
    userId: PropTypes.string,
    notifyAssignedCourses: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const currentUser = userService.getCurrentUser();
    const userId = currentUser.id;

    this.props.notifyAssignedCourses(userId);
  }

  render() {
    const id = this.props.userId || userService.getCurrentUser().id;

    return (
      <CourseList userId={Number(id)} />
    );
  }
}
