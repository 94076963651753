/* eslint-disable promise/no-nesting */

import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';

export const FETCH_DOCUMENT_TYPES_REQUEST = 'FETCH_DOCUMENT_TYPES_REQUEST';
export const FETCH_DOCUMENT_TYPES_SUCCESS = 'FETCH_DOCUMENT_TYPES_SUCCESS';
export const FETCH_DOCUMENT_TYPES_FAILURE = 'FETCH_DOCUMENT_TYPES_FAILURE';
export const ADD_DOCUMENT_TYPE = 'ADD_DOCUMENT_TYPE';
export const REMOVE_DOCUMENT_TYPE = 'REMOVE_DOCUMENT_TYPE';


export function fetchDocumentTypesRequest() {
  return {
    type: FETCH_DOCUMENT_TYPES_REQUEST
  };
}

export function fetchDocumentTypesSuccess(documentTypes) {
  return {
    type: FETCH_DOCUMENT_TYPES_SUCCESS,
    payload: {
      documentTypes
    }
  };
}

export function fetchDocumentTypesFailure() {
  return {
    type: FETCH_DOCUMENT_TYPES_FAILURE
  };
}

export function addDocumentTypeSuccess(documentType) {
  return {
    type: ADD_DOCUMENT_TYPE,
    payload: {
      documentType
    }
  };
}

export function removeDocumentTypeSuccess(documentType) {
  return {
    type: REMOVE_DOCUMENT_TYPE,
    payload: {
      documentType
    }
  };
}

export function fetchDocumentTypes() {
  return (dispatch) => {
    dispatch(fetchDocumentTypesRequest());

    return axios.get(URLS.documentTypes)
      .then(({ data }) => dispatch(fetchDocumentTypesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchDocumentTypesFailure());
        dispatch(addError(`Error during document types fetching ${error.message}`, error));
      });
  };
}

export function addDocumentType(documentType) {
  return (dispatch) => {
    return axios.post(URLS.documentTypes, documentType)
      .then(({ data }) => dispatch(addDocumentTypeSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during document type adding ${error.message}`, error));
      });
  };
}

export function removeDocumentType(documentType) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.documentTypes, documentType.id))
      .then(() => dispatch(removeDocumentTypeSuccess(documentType)))
      .catch((error) => {
        dispatch(addError(`Error during document type removing ${error.message}`, error));
      });
  };
}
