import {
  FETCH_REPORT_FLEET_CATEGORIES_SUCCESS,
  FETCH_REPORT_FLEET_CATEGORIES_REQUEST,
  FETCH_REPORT_FLEET_CATEGORIES_FAILURE,
  ADD_REPORT_FLEET_CATEGORY,
  EDIT_REPORT_FLEET_CATEGORY,
  REMOVE_REPORT_FLEET_CATEGORY,
} from '../actions/reportFleetCategories';

const INITIAL_STATE = {
  reportFleetCategoryList: [],
  reportFleetCategoryRequest: false,
};

function fetchReportFleetCategories(state, payload) {
  return {
    ...state,
    reportFleetCategoryList: payload.reportFleetCategories,
    reportFleetCategoryRequest: false,
  };
}

function fetchReportFleetCategoriesRequest(state) {
  return {
    ...state,
    reportFleetCategoryRequest: true,
  };
}

function fetchReportFleetCategoriesFailure(state) {
  return {
    ...state,
    reportFleetCategoryRequest: false,
  };
}

function addReportFleetCategory(state, payload) {
  return {
    ...state,
    reportFleetCategoryList: [
      ...state.reportFleetCategoryList,
      payload.reportFleetCategory
    ]
  };
}

function editReportFleetCategory(state, payload) {
  return {
    ...state,
    reportFleetCategoryList: state.reportFleetCategoryList.map((reportFleetCategory) => {
      if (reportFleetCategory.id === payload.reportFleetCategory.id) {
        return payload.reportFleetCategory;
      }

      return reportFleetCategory;
    })
  };
}

function removeReportFleetCategory(state, payload) {
  return {
    ...state,
    reportFleetCategoryList: state.reportFleetCategoryList.filter((reportFleetCategory) => reportFleetCategory.id !== payload.reportFleetCategory.id),
  };
}


const actions = {
  [FETCH_REPORT_FLEET_CATEGORIES_SUCCESS]: fetchReportFleetCategories,
  [FETCH_REPORT_FLEET_CATEGORIES_REQUEST]: fetchReportFleetCategoriesRequest,
  [FETCH_REPORT_FLEET_CATEGORIES_FAILURE]: fetchReportFleetCategoriesFailure,
  [ADD_REPORT_FLEET_CATEGORY]: addReportFleetCategory,
  [EDIT_REPORT_FLEET_CATEGORY]: editReportFleetCategory,
  [REMOVE_REPORT_FLEET_CATEGORY]: removeReportFleetCategory,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
