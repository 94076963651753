import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  chip: {
    margin: theme.spacing.unit / 2,
  },
});

@withNamespaces()
@withStyles(styles)
export default class VehicleGroupList extends Component {
  static propTypes = {
    vehicleGroupList: PropTypes.array,
    removeVehicleGroup: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderVehicleGroupList = (vehicleGroupList) => {
    const { removeVehicleGroup, onElementDelete, classes } = this.props;

    return vehicleGroupList.map((vehicleGroup) => {
      return (
        <Chip
          key={`fleet-group-item-${vehicleGroup.id}`}
          label={vehicleGroup.name}
          onDelete={() =>
            onElementDelete(() => removeVehicleGroup(vehicleGroup))
          }
          className={classes.chip}
        />
      );
    });
  };

  render() {
    const { handleAddOpen, vehicleGroupList, t } = this.props;

    if (_isEmpty(vehicleGroupList)) {
      return (
        <div className="pl-sm-5">
          <Button color="primary" onClick={handleAddOpen}>
            {t('settings:addVehicleGroupButton')}
          </Button>
        </div>
      );
    }

    return (
      <div className="pl-sm-5">
        <div className="ml-sm-3 my-2">
          {this.renderVehicleGroupList(vehicleGroupList)}
        </div>
        <Button color="primary" onClick={handleAddOpen}>
          {t('settings:addVehicleGroupButton')}
        </Button>
      </div>
    );
  }
}
