import {
  FETCH_REPORT_RECIPENTS_SUCCESS,
  FETCH_REPORT_RECIPENTS_REQUEST,
  FETCH_REPORT_RECIPENTS_FAILURE,
  ADD_REPORT_RECIPENT,
  EDIT_REPORT_RECIPENT,
  REMOVE_REPORT_RECIPENT,
} from '../actions/reportRecipents';

const INITIAL_STATE = {
  reportRecipentList: [],
  reportRecipentRequest: false,
};

function fetchReportRecipents(state, payload) {
  return {
    ...state,
    reportRecipentList: payload.reportRecipents,
    reportRecipentRequest: false,
  };
}

function fetchReportRecipentsRequest(state) {
  return {
    ...state,
    reportRecipentRequest: true,
  };
}

function fetchReportRecipentsFailure(state) {
  return {
    ...state,
    reportRecipentRequest: false,
  };
}

function addReportRecipent(state, payload) {
  return {
    ...state,
    reportRecipentList: [
      ...state.reportRecipentList,
      payload.reportRecipent
    ]
  };
}

function editReportRecipent(state, payload) {
  return {
    ...state,
    reportRecipentList: state.reportRecipentList.map((reportRecipent) => {
      if (reportRecipent.id === payload.reportRecipent.id) {
        return payload.reportRecipent;
      }

      return reportRecipent;
    })
  };
}

function removeReportRecipent(state, payload) {
  return {
    ...state,
    reportRecipentList: state.reportRecipentList.filter((reportRecipent) => reportRecipent.id !== payload.reportRecipent.id),
  };
}


const actions = {
  [FETCH_REPORT_RECIPENTS_SUCCESS]: fetchReportRecipents,
  [FETCH_REPORT_RECIPENTS_REQUEST]: fetchReportRecipentsRequest,
  [FETCH_REPORT_RECIPENTS_FAILURE]: fetchReportRecipentsFailure,
  [ADD_REPORT_RECIPENT]: addReportRecipent,
  [EDIT_REPORT_RECIPENT]: editReportRecipent,
  [REMOVE_REPORT_RECIPENT]: removeReportRecipent,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
