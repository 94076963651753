import React, { Component } from 'react';
import { isAdmin, isSuperAdmin } from '../../../utils';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../common/components/forms/Form';
import MaterialSelect from '../../../common/components/forms/MaterialSelect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editReport } from '../../actions/reports';
import { getDateFormat } from '../../utils/time';
import moment from 'moment';
import { reformatPickerDateToDB } from '../../utils/time';
import userService from '../../../utils/userService';
import validators from '../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const STYLES = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  open: false,
  dueDate: moment(),
};

const mapStateToProps = (state) => {
  return {
    users: state.users.userList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editReport: (report, files, myReports) =>
      dispatch(editReport(report, files, myReports)),
  };
}

@withNamespaces()
@withStyles(STYLES)
@connect(mapStateToProps, mapDispatchToProps)
export default class AssignReportManager extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    editReport: PropTypes.func.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    report: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  hasPermissionsToAssignUser() {
    const user = userService.getCurrentUser();

    const { users } = this.props;

    return users.filter(
      (u) => u.id === user.id && (isAdmin(u) || isSuperAdmin(u)),
    );
  }

  handleDatePickerChange = (dueDate) => {
    this.setState({
      dueDate,
    });
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid && this.formData) {
      const { editReport, report } = this.props;
      const { dueDate } = this.state;
      const { assignee } = this.formData;

      const updateReport = {
        id: report.id,
        status: 'ASSIGNED',
        assigneeId: assignee.value,
        dueDate: reformatPickerDateToDB(dueDate),
      };

      editReport(updateReport, []);
    }
  };

  onAssignUser = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  render() {
    const { users, t, classes, open, onCloseDialog } = this.props;
    const { dueDate } = this.state;

    return (
      <Dialog
        PaperProps={{
          classes: { root: classes.root },
        }}
        open={open}
        onClose={this.handleDialogClose}
        aria-labelledby="asign-report-dialog"
        maxWidth="md"
        fullWidth
      >
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <DialogTitle id="assign-report-dialog">
            {t('report:Assign to a user')}
          </DialogTitle>
          <DialogContent className={classes.root}>
            <div className="row">
              <div className="col-6">
                <MaterialSelect
                  id="responsibleUser"
                  name="assignee"
                  label={t('input:Responsible person')}
                  options={users.map((user) => ({
                    value: user.id,
                    label: `${user.lastName} ${user.firstName}`,
                  }))}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-auto">
                <DateTimePicker
                  fullWidth
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={this.handleDatePickerChange}
                  dateFormat={getDateFormat()}
                  value={dueDate}
                  name="dueDate"
                  label={t('input:Due date')}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <Button variant="raised" onClick={() => onCloseDialog()}>
                  {t('buttonCancel')}
                </Button>
              </div>
              {this.hasPermissionsToAssignUser() ? (
                <div className="col-auto">
                  <Button
                    color="primary"
                    variant="raised"
                    onClick={this.onAssignUser}
                  >
                    {t('assign')}
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </DialogContent>
        </Form>
      </Dialog>
    );
  }
}
