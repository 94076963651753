import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { capitalizeFirstLetter } from '../../../../utils';
import userDefault from '../../../../images/profileDefault.png';
import { ROLES } from '../../../../constants';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  smallAvatar: {
    width: 32,
    height: 32,
  },
  avatarCell: {
    width: 100,
  },
});

@withNamespaces()
@withStyles(styles)
export default class RoleList extends Component {
  static propTypes = {
    userList: PropTypes.array.isRequired,
    role: PropTypes.object.isRequired,
    removeRole: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderRemoveIcon = (removeRole, user, role, length) => {
    if (role.name === ROLES.admin && length <= 1) {
      return null;
    }

    return (
      <IconButton onClick={() => removeRole(user, role)}>
        <Icon color="primary">
          delete
        </Icon>
      </IconButton>
    );
  }

  renderRoleList = (role) => {
    const { userList, removeRole, classes } = this.props;
    const roleUsers = userList
      .filter((user) => {
        if (!user.groups) {
          return false;
        }

        return user.groups.some((group) => group.name === role.name);
      });

    return roleUsers.map((user, index, users) => {
      const profilePicture = user.userprofile && user.userprofile.profilePicture;
      const profileImage = profilePicture ? profilePicture : userDefault;

      return (
        <TableRow key={`group-${user.id}`}>
          <TableCell className={classes.avatarCell}>
            <Avatar
              src={profileImage}
              className={classes.smallAvatar}
            />
          </TableCell>
          <TableCell>
            {capitalizeFirstLetter(user.firstName)} {capitalizeFirstLetter(user.lastName)}
          </TableCell>
          <TableCell numeric>
            {this.renderRemoveIcon(removeRole, user, role, users.length)}
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { role, handleAddOpen, classes, t } = this.props;
    const addRole = `settings:add${capitalizeFirstLetter(role.name)}Button`;
    const userAccounts = `settings:${role.name}Accounts`;

    return (
      <div className="pl-sm-5">
        <h4>
          {t(userAccounts)}
        </h4>

        <div className="ml-sm-3 my-2">
          <div className={classes.root}>
            <Table className={classes.table}>
              <TableBody>
                {this.renderRoleList(role)}
              </TableBody>
            </Table>
          </div>
        </div>

        <Button color="primary" onClick={() => handleAddOpen(role)}>
          {t(addRole)}
        </Button>
      </div>
    );
  }
}
