import {
  FETCH_REPORT_CATEGORIES_SUCCESS,
  FETCH_REPORT_CATEGORIES_REQUEST,
  FETCH_REPORT_CATEGORIES_FAILURE,
  ADD_REPORT_CATEGORY,
  EDIT_REPORT_CATEGORY,
  REMOVE_REPORT_CATEGORY,
} from '../actions/reportCategories';

const INITIAL_STATE = {
  reportCategoryList: [],
  reportCategoryRequest: false,
};

function fetchReportCategories(state, payload) {
  return {
    ...state,
    reportCategoryList: payload.reportCategories,
    reportCategoryRequest: false,
  };
}

function fetchReportCategoriesRequest(state) {
  return {
    ...state,
    reportCategoryRequest: true,
  };
}

function fetchReportCategoriesFailure(state) {
  return {
    ...state,
    reportCategoryRequest: false,
  };
}

function addReportCategory(state, payload) {
  return {
    ...state,
    reportCategoryList: [
      ...state.reportCategoryList,
      payload.reportCategory
    ]
  };
}

function editReportCategory(state, payload) {
  return {
    ...state,
    reportCategoryList: state.reportCategoryList.map((reportCategory) => {
      if (reportCategory.id === payload.reportCategory.id) {
        return payload.reportCategory;
      }

      return reportCategory;
    })
  };
}

function removeReportCategory(state, payload) {
  return {
    ...state,
    reportCategoryList: state.reportCategoryList.filter((reportCategory) => reportCategory.id !== payload.reportCategory.id),
  };
}


const actions = {
  [FETCH_REPORT_CATEGORIES_SUCCESS]: fetchReportCategories,
  [FETCH_REPORT_CATEGORIES_REQUEST]: fetchReportCategoriesRequest,
  [FETCH_REPORT_CATEGORIES_FAILURE]: fetchReportCategoriesFailure,
  [ADD_REPORT_CATEGORY]: addReportCategory,
  [EDIT_REPORT_CATEGORY]: editReportCategory,
  [REMOVE_REPORT_CATEGORY]: removeReportCategory,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
