import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Loader from '../../../../../common/components/Loader';
import Confirmation from '../../../../../common/components/Confirmation';
import Aircrafts from './Aircrafts';
import Locations from './Locations';
import UserSettings from './UserSettings';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { fetchPilotFunctions } from '../../../../actions/pilotFunction';
import { fetchUserSettings, editUserSettings, resetSaveUserSettingsSuccess } from '../../../../actions/userSettings';
import { addSimpleError } from '../../../../actions/errors';

import '../../styles.scss';
import { fetchAircrafts } from '../../../../actions/aircrafts';
import { fetchLocations } from '../../../../actions/locations';

const mapStateToProps = (state) => {
  return {
    pilotFunctionList: state.pilotFunction.pilotFunctionList,
    userSettings: state.userSettings.userSettings,
    pilotFunctionsRequest: state.pilotFunction.pilotFunctionsRequest,
    userSettingsRequest: state.userSettings.userSettingsRequest,
    aircraftList: state.aircrafts.aircraftList.filter((aircraft)=> {return aircraft.isActive;}),
    locationList: state.locations.locationList.filter((location)=> {return location.isActive;}),
    aircraftRequest: state.aircrafts.aircraftRequest,
    locationRequest: state.locations.locationRequest,
    changeUserSettingsSuccess: state.userSettings.changeUserSettingsSuccess
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchPilotFunctions: () => dispatch(fetchPilotFunctions()),
    fetchUserSettings: (userId) => dispatch(fetchUserSettings(userId)),
    editUserSettings: (userId, userSettings) => dispatch(editUserSettings(userId, userSettings)),
    fetchAircrafts: (userId) => dispatch(fetchAircrafts(userId)),
    fetchLocations: (userId) => dispatch(fetchLocations(userId)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    resetSaveUserSettingsSuccess: () => dispatch(resetSaveUserSettingsSuccess())
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class Settings extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    pilotFunctionsRequest: PropTypes.bool,
    userSettingsRequest: PropTypes.bool,
    pilotFunctionList: PropTypes.array,
    userSettings: PropTypes.object,
    addSimpleError: PropTypes.func,
    fetchPilotFunctions: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    fetchAircrafts: PropTypes.func,
    fetchLocations: PropTypes.func,
    editUserSettings: PropTypes.func,
    aircraftList: PropTypes.array,
    locationList: PropTypes.array,
    aircraftRequest: PropTypes.bool.isRequired,
    locationRequest: PropTypes.bool.isRequired,
    changeUserSettingsSuccess: PropTypes.bool.isRequired,
    resetSaveUserSettingsSuccess: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    flightRule: null,
    conditionTime: null,
    departure: null,
    arrival: null,
    aircraft: null,
    pilotFunction: null
  };

  componentDidMount() {
    const { fetchPilotFunctions, fetchUserSettings, fetchAircrafts, fetchLocations, userId } = this.props;

    fetchPilotFunctions();
    fetchUserSettings(userId);
    fetchAircrafts(userId);
    fetchLocations(userId);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  updateUserSettingsList = (userSetting, value, name) => {
    if (!_isEmpty(value.toString())) {
      userSetting = {
        ...userSetting,
        [name]: { id: value },
      };
    }

    return userSetting;
  };

  saveUserSettings = () => {
    const { editUserSettings, userSettings } = this.props;
    const { flightRule, conditionTime, departure, arrival, aircraft, pilotFunction } = this.state;
    const departureId = this.getValuesFromList(departure, 'departure.id');
    const arrivalId = this.getValuesFromList(arrival, 'arrival.id');
    const aircraftId = this.getValuesFromList(aircraft, 'aircraft.id');
    const pilotFunctionId = this.getValuesFromList(pilotFunction, 'pilotFunction.id');

    let userSetting = {
      conditionTime: this.getValuesFromList(conditionTime, 'conditionTime'),
      flightRule: this.getValuesFromList(flightRule, 'flightRule'),
    };

    userSetting = this.updateUserSettingsList(userSetting, departureId, 'departure');
    userSetting = this.updateUserSettingsList(userSetting, arrivalId, 'arrival');
    userSetting = this.updateUserSettingsList(userSetting, aircraftId, 'aircraft');
    userSetting = this.updateUserSettingsList(userSetting, pilotFunctionId, 'pilotFunction');

    editUserSettings(userSettings.id, userSetting);
  };

  getValuesFromList = (name, value) => {
    return name ? name : _get(this.props.userSettings, value, '');
  };

  renderButtons() {
    const { userId, t } = this.props;
    const pathname = `/users/${userId}`;

    return (
      <div className="justify-content-center row">
        <div className="col-auto">
          <Link to={pathname}>
            <Button variant="raised">
              {t('buttonCancel')}
            </Button>
          </Link>
        </div>
        <div className="col-auto">
          <Button color="primary" variant="raised" onClick={() => this.saveUserSettings()}>
            {t('buttonSave')}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const {
      pilotFunctionsRequest,
      userSettingsRequest,
      locationRequest,
      aircraftRequest,
      aircraftList,
      locationList,
      userSettings,
      pilotFunctionList,
      resetSaveUserSettingsSuccess,
      changeUserSettingsSuccess,
      userId,
      t } = this.props;
    const { conditionTime, flightRule, departure, arrival, aircraft, pilotFunction } = this.state;

    if (pilotFunctionsRequest || userSettingsRequest || locationRequest || aircraftRequest) {
      return <Loader />;
    }

    return (
      <div>
        <Aircrafts
          userId={userId}
        />
        <Locations
          userId={userId}
        />
        <UserSettings
          userId={userId}
          pilotFunctionList={pilotFunctionList}
          userSettings={userSettings}
          userAircrafts={aircraftList}
          userLocations={locationList}
          handleChange={this.handleChange}
          conditionTime={this.getValuesFromList(conditionTime, 'conditionTime')}
          flightRule={this.getValuesFromList(flightRule, 'flightRule')}
          departure={this.getValuesFromList(departure, 'departure.id')}
          arrival={this.getValuesFromList(arrival, 'arrival.id')}
          aircraft={this.getValuesFromList(aircraft, 'aircraft.id')}
          pilotFunction={this.getValuesFromList(pilotFunction, 'pilotFunction.id')}
        />
        { this.renderButtons() }
        <Dialog
          open={changeUserSettingsSuccess}
          onClose={resetSaveUserSettingsSuccess}
          aria-labelledby="confirmation-assign"
          fullWidth
        >
          <Confirmation
            message={t('user:userSettingsChangesSaved')}
            closeConfirmationDialog={resetSaveUserSettingsSuccess}
          />
        </Dialog>
      </div>
    );
  }
}
