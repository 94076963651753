export const documents = [
  {
    id: 'license',
  },
  {
    id: 'rating',
  },
  {
    id: 'externalCourse',
  },
  {
    id: 'medical',
  },
  {
    id: 'qualification',
  },
];

export const months = [
  {
    id: '3',
    type: 'radio',
  },
  {
    id: '6',
    type: 'radio',
  },
  {
    id: '12',
    type: 'radio',
  },
  {
    id: '24',
    type: 'radio',
  },
];

export const tableHeader = [
  {
    prop: 'status',
    sort: true,
    profileView: false,
  },
  {
    prop: 'type',
    sort: true,
    profileView: false,
  },
  {
    prop: 'document',
    sort: true,
    profileView: false,
  },
  {
    prop: 'expiryDate',
    sort: true,
    profileView: false,
  },
  {
    prop: 'firstName',
    sort: true,
    profileView: false,
  },
  {
    prop: 'lastName',
    sort: true,
    profileView: false,
  },
  {
    prop: 'isVerified',
    sort: true,
    profileView: false,
  },
  {
    prop: 'plannedAction',
    sort: true,
    profileView: false,
  },
  {
    prop: 'plannedActionDate',
    sort: true,
    profileView: false,
  },
  {
    prop: 'icons',
    profileView: false,
    sort: false,
    isNumeric: true,
  },
];
