import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import Icon from '@material-ui/core/Icon';

import DayView from './DayView';
import Loader from '../../../common/components/Loader';
import { fetchBookingObjects } from '../../actions/bookingObjects';
import { fetchBookings, editBooking, removeBooking, notify } from '../../actions/bookings';
import { fetchUsers } from '../../actions/users';

const mapStateToProps = (state) => {
  return {
    bookingObjectList: state.bookingObjects.bookingObjectList,
    bookingList: state.bookings.bookingList,
    userList: state.users.userList,
    loading: state.users.getUsersRequest && state.bookings.bookingRequest && state.bookingObjects.bookingObjectRequest
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchBookingData: () => {
      dispatch(fetchBookingObjects());
      dispatch(fetchBookings());
      dispatch(fetchUsers());
    },
    editBooking: (booking) => dispatch(editBooking(booking)),
    removeBooking: (booking) => dispatch(removeBooking(booking)),
    notify: (data) => dispatch(notify(data))
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class Booking extends Component {
  static propTypes = {
    fetchBookingData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    bookingObjectList: PropTypes.array,
    bookingList: PropTypes.array,
    userList: PropTypes.array,
    editBooking: PropTypes.func.isRequired,
    removeBooking: PropTypes.func.isRequired,
    notify: PropTypes.func.isRequired,
    t: PropTypes.func,
  };

  componentDidMount() {
    const { fetchBookingData } = this.props;

    fetchBookingData();
  }

  render() {
    const { loading, bookingObjectList, bookingList, userList, editBooking, removeBooking, notify, t } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (_isEmpty(bookingObjectList)) {
      return <div>{t('booking:thereAreNoBookingObjectsInTheSystem')}</div>;
    }

    return (
      <section className="booking section-title">
        <header>
          <span>
            <Icon color="primary">
              event
            </Icon>
            <h1>{t('booking:bookingName')}</h1>
          </span>
          <p>{t('booking:bookingSubName')}</p>
        </header>
        <DayView
          bookingObjectList={bookingObjectList}
          bookingList={bookingList}
          userList={userList}
          editBooking={editBooking}
          removeBooking={removeBooking}
          notify={notify}
        />
      </section>
    );
  }
}
