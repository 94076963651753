import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Loader from '../../../../common/components/Loader';
import GroupList from './GroupList';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';

import { fetchGroups, addGroup, editGroup, removeGroup } from '../../../actions/groups';
import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  name: '',
  groupId: null
};

const mapStateToProps = (state) => {
  return {
    groupList: state.groups.groupList,
    groupRequest: state.groups.groupRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchGroups: () => dispatch(fetchGroups()),
    addGroup: (name) => dispatch(addGroup(name)),
    editGroup: (name) => dispatch(editGroup(name)),
    removeGroup: (name) => dispatch(removeGroup(name)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class GroupsManager extends Component {
  static propTypes = {
    groupList: PropTypes.array,
    groupRequest: PropTypes.bool.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    addGroup: PropTypes.func.isRequired,
    editGroup: PropTypes.func.isRequired,
    removeGroup: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.props.fetchGroups();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleEditOpen = (group) => {
    this.setState({
      editOpen: true,
      groupId: group.id,
      name: group.name,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  addGroup = () => {
    this.props.addGroup(this.state.name);
    this.handleDialogClose();
  };

  editGroup = () => {
    const newGroup = {
      id: this.state.groupId,
      name: this.state.name,
    };

    this.props.editGroup(newGroup);
    this.handleDialogClose();
  };

  removeGroup = (group) => {
    this.props.removeGroup(group);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    return (isFormValid) => {
      if (isFormValid && this.formData) {
        this.state.addOpen ? this.addGroup() : this.editGroup();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  dialogContent = (title, t) => {
    const { name } = this.state;

    return (
      <div>
        <DialogTitle id="add-group">{title}</DialogTitle>
        <DialogContent>
          <MaterialInput
            autoFocus
            margin="dense"
            name="name"
            label={t('input:name')}
            validators={[
              new validators.IsRequired(t),
              new validators.MaxLength(t, 200)
            ]}
            defaultValue={name}
          />
        </DialogContent>
      </div>
    );
  };

  dialogActions = (t) => {
    return (
      <DialogActions>
        <Button color="secondary" onClick={this.handleDialogClose}>
          {t('buttonCancel')}
        </Button>
        <Button color="primary" onClick={this.sendHandler}>
          {t('buttonSave')}
        </Button>
      </DialogActions>
    );
  };

  render() {
    const { groupList, groupRequest, onElementDelete, t } = this.props;

    if (groupRequest) {
      return <Loader />;
    }

    return (
      <div className="groups-settings">
        <GroupList
          groupList={groupList}
          handleEditOpen={this.handleEditOpen}
          removeGroup={(group) => onElementDelete(() => this.removeGroup(group))}
          handleAddOpen={this.handleAddOpen}
        />

        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={this.state.addOpen}
            onClose={this.handleAddClose}
            aria-labelledby="add-group"
            fullWidth
          >
            { this.dialogContent(t('settings:addGroupDialogTitle'), t)}
            { this.dialogActions(t) }
          </Dialog>
          <Dialog
            open={this.state.editOpen}
            onClose={this.handleEditClose}
            aria-labelledby="edit-group"
            fullWidth
          >
            { this.dialogContent(t('settings:editGroupDialogTitle'), t)}
            { this.dialogActions(t) }
          </Dialog>
        </Form>
      </div>
    );
  }
}
