import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { connect } from 'react-redux';
import { editSetting } from '../../../actions/settings';
import { snakeCase } from 'lodash';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editSetting: (setting, errorCallback) =>
      dispatch(editSetting(setting, errorCallback)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class FleetFieldManager extends Component {
  static propTypes = {
    settings: PropTypes.array.isRequired,
    editSetting: PropTypes.func.isRequired,
    onInfoSuccessfullySaved: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    infoSuccessfullySaved: true,
  };

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    const found = _find(settings, { name: fieldName });

    return found ? found.textValue : null;
  }

  onChange = (formData) => {
    this.formData = formData;
  };

  handleEditSettingError = () => {
    if (this.state.infoSuccessfullySaved) {
      this.setState({
        infoSuccessfullySaved: false,
      });
    }
  };

  onFormValidated = async (isFormValid) => {
    const { settings, editSetting } = this.props;

    this.setState({ infoSuccessfullySaved: true });

    if (isFormValid && this.formData) {
      const updateSettingsPromises = [];

      Object.keys(this.formData).forEach((key) => {
        const setting = _find(settings, { name: snakeCase(key) });
        const newValue = this.formData[key].value;

        if (setting && setting.textValue != newValue) {
          updateSettingsPromises.push(
            editSetting(
              {
                id: setting.id,
                value: !isNaN(newValue) ? Number(newValue) : 0,
                textValue: newValue,
              },
              this.handleEditSettingError,
            ),
          );
        }
      });

      if (updateSettingsPromises.length > 0) {
        Promise.all(updateSettingsPromises).then(() => {
          if (this.state.infoSuccessfullySaved) {
            this.props.onInfoSuccessfullySaved();
          }
        });
      }
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { t } = this.props;

    return (
      <div className="fleet-fields-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:Warning limits')}</h4>
          <div className="ml-sm-3 my-2">
            <Form
              onChange={this.onChange}
              onFormValidated={this.onFormValidated}
              registerForm={this.registerForm}
            >
              <div class="row no-gutters">
                <div className="col-12">
                  <h4>{t('settings:By hours')}</h4>
                </div>
                <div className="col-6">
                  <MaterialInput
                    fullWidth
                    type="number"
                    margin="dense"
                    name="hours_warning"
                    label={t('input:Warn if hours to service is below')}
                    defaultValue={this.setDefaultValue('hours_warning')}
                    validators={[
                      new validators.IsRequired(t),
                      new validators.IsNaturalNumber(t),
                    ]}
                  />
                </div>
              </div>
              <div class="row no-gutters">
                <div className="col-12">
                  <h4>{t('settings:By cycles')}</h4>
                </div>
                <div className="col-6">
                  <MaterialInput
                    fullWidth
                    type="number"
                    margin="dense"
                    name="cycles_warning"
                    label={t('input:Warn if cycles to service is below')}
                    defaultValue={this.setDefaultValue('cycles_warning')}
                    validators={[
                      new validators.IsRequired(t),
                      new validators.IsNaturalNumber(t),
                    ]}
                  />
                </div>
              </div>
              <Button
                className="my-3"
                onClick={this.sendHandler}
                color="primary"
                variant="raised"
              >
                {t('buttonSave')}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
