import _get from 'lodash/get';
import { reformatPickerDateTimeToDB, reformatPickerDateToDB } from '../../../utils/time';

export function getDataFromList(id, list, name, userSettings) {
  const selectedId = id ? id : _get(userSettings, name, '');

  return list.find((data)=> { return data.id.toString() === selectedId.toString(); });
}

export function addJourneyLogFunction(data) {

  const typeData = getDataFromList(data.type, data.typeList, 'type.id', data.userSettings);

  let journeyLog = {
    userId: data.userId,
    totalTime: _get(data, 'totalTime', null),
    remarks: _get(data, 'remarks', ''),
    departure: _get(data, 'departure', ''),
    arrival: _get(data, 'arrival', ''),
  };

  if (typeData) {
    journeyLog = {
      ...journeyLog,
      typeId: typeData.id
    };
  }

  if (data.departureTime) {
    journeyLog = {
      ...journeyLog,
      departureDate: reformatPickerDateTimeToDB(data.departureTime)
    };
  }

  if (data.arrivalTime) {
    journeyLog = {
      ...journeyLog,
      arrivalDate: reformatPickerDateTimeToDB(data.arrivalTime),
    };
  }

  if (data.dateOfJourneyLog) {
    journeyLog = {
      ...journeyLog,
      dateOfJourneyLog: reformatPickerDateToDB(data.dateOfJourneyLog),
    };
  }

  return journeyLog;
}
