import {
  ADD_VEHICLE_GROUP,
  EDIT_VEHICLE_GROUP,
  FETCH_VEHICLE_GROUPS_FAILURE,
  FETCH_VEHICLE_GROUPS_REQUEST,
  FETCH_VEHICLE_GROUPS_SUCCESS,
  REMOVE_VEHICLE_GROUP,
} from '../actions/vehicleGroups';

const INITIAL_STATE = {
  vehicleGroupList: [],
  vehicleGroupRequest: false,
};

function fetchVehicleGroupsSuccess(state, payload) {
  return {
    ...state,
    vehicleGroupList: payload.vehicleGroups,
    vehicleGroupRequest: false,
  };
}

function fetchVehicleGroupsRequest(state) {
  return {
    ...state,
    vehicleGroupRequest: true,
  };
}

function fetchVehicleGroupsFailure(state) {
  return {
    ...state,
    vehicleGroupRequest: false,
  };
}

function addVehicleGroup(state, payload) {
  return {
    ...state,
    vehicleGroupList: [...state.vehicleGroupList, payload.vehicleGroup],
  };
}

function editVehicleGroup(state, payload) {
  return {
    ...state,
    vehicleGroupList: state.vehicleGroupList.map((vehicleGroup) => {
      if (vehicleGroup.id === payload.vehicleGroup.id) {
        return payload.vehicleGroup;
      }

      return vehicleGroup;
    }),
  };
}

function removeVehicleGroup(state, payload) {
  return {
    ...state,
    vehicleGroupList: state.vehicleGroupList.filter(
      (vehicleGroup) => vehicleGroup.id !== payload.vehicleGroup.id,
    ),
  };
}

const actions = {
  [FETCH_VEHICLE_GROUPS_SUCCESS]: fetchVehicleGroupsSuccess,
  [FETCH_VEHICLE_GROUPS_REQUEST]: fetchVehicleGroupsRequest,
  [FETCH_VEHICLE_GROUPS_FAILURE]: fetchVehicleGroupsFailure,
  [ADD_VEHICLE_GROUP]: addVehicleGroup,
  [EDIT_VEHICLE_GROUP]: editVehicleGroup,
  [REMOVE_VEHICLE_GROUP]: removeVehicleGroup,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
