import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Icon from '@material-ui/core/Icon';

import { toUppercase } from '../../utils';
import { languages } from '../../constants/languages';

@translate()
export default class LanguageSelect extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired
  };

  state = {
    lang: this.props.i18n.language,
    anchorEl: null,
  };

  onChange = (lang) => {
    const { i18n } = this.props;

    i18n.changeLanguage(lang);
    this.setState({ lang, anchorEl: null });
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderMenuItems = () => {
    return languages.map((language) => {
      return <MenuItem key={`language-${language}`} onClick={() => this.onChange(language)}>{toUppercase(language)}</MenuItem>;
    });
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <a
          className="nav-link"
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
        >
          <Icon
            color="primary"
            className="mx-1"
          >
            language
          </Icon>
          {this.state.lang}
        </a>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          value={this.state.lang}
        >
          {this.renderMenuItems()}
        </Menu>
      </div>
    );
  }
}
