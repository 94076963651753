import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import TextField from '@material-ui/core/TextField';

export default class MaterialInput extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    helperText: PropTypes.node,
    name: PropTypes.string.isRequired,
    validationError: PropTypes.array,
    inputValidated: PropTypes.func,
    registerInput: PropTypes.func,
    validators: PropTypes.array,
    forceInitialValidation: PropTypes.bool,
    stateValue: PropTypes.any
  };

  static defaultProps = {
    validators: [],
    type: 'text',
    defaultValue: null,
    forceInitialValidation: false,
    disabled: false,
    error: false
  };

  state = {
    originalStateValue: null
  }

  async componentDidMount() {
    this.props.registerInput(
      this.props.name,
      this.input.value,
      this.validateAndPropagateValue
    );

    if (this.props.forceInitialValidation) {
      const errors = await this.validate(this.props.defaultValue);

      this.props.inputValidated(this.props.name, this.input.value, {
        isValid: _isEmpty(errors),
        errors,
        disabled: this.props.disabled
      });
    }
  }

  validateAndPropagateValue = async () => {
    const currentValue = this.input.value;
    const errors = await this.validate(currentValue);
    const isValid = this.props.disabled || _isEmpty(errors);

    this.props.inputValidated(this.props.name, currentValue, {
      isValid,
      errors,
      disabled: this.props.disabled
    });
  };

  validate = async (value) => {
    const allErrors = [];

    for (const validator of this.props.validators) {
      const isValid = await validator.isValid(value);

      if (!isValid) {
        allErrors.push(validator.getValidationMessage());
      }
    }

    return allErrors;
  };

  render() {
    const { helperText, disabled, validationError, stateValue, ...textFieldProps } = this.props;
    const { originalStateValue } = this.state;
    const errorLabel =
      !disabled && validationError
        ? validationError.join('; ')
        : helperText;

    const error = !disabled && !_isEmpty(validationError);

    if (typeof(stateValue) !== 'undefined' && stateValue !== null && stateValue !== originalStateValue) {
      this.setState({ originalStateValue: stateValue });

      setImmediate(() => {
        if (this.input) {
          this.input.value = stateValue;
        }
      });
    }

    return (
      <TextField
        {...textFieldProps}
        disabled={disabled}
        error={error}
        inputRef={(input) => {
          this.input = input;
        }}
        onChange={this.validateAndPropagateValue}
        helperText={errorLabel}
      />
    );
  }
}
