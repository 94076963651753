import React, { Component } from 'react';
import { addReport, editReport } from '../../../actions/reports';
import { isAdmin, isSuperAdmin } from '../../../../utils';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { getDateFormat } from '../../../utils/time';
import moment from 'moment';
import { reformatPickerDateToDB } from '../../../utils/time';
import userService from '../../../../utils/userService';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const STYLES = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  open: false,
  date: moment(),
  editMode: true,
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    users: state.users.userList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addReport: (report, files, userId) =>
      dispatch(addReport(report, files, userId, true)),
    editReport: (report, files, myReports) =>
      dispatch(editReport(report, files, myReports)),
  };
}

@withNamespaces()
@withStyles(STYLES)
@connect(mapStateToProps, mapDispatchToProps)
export default class ReportDialog extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    addReport: PropTypes.func.isRequired,
    editReport: PropTypes.func.isRequired,
    fleet: PropTypes.object.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    report: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  hasPermissionsToAssignUser() {
    const user = userService.getCurrentUser();

    const { users } = this.props;

    return users.filter(
      (u) => u.id === user.id && (isAdmin(u) || isSuperAdmin(u)),
    );
  }

  handleDatePickerChange = (date) => {
    this.setState({
      date,
    });
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid && this.formData) {
      const { addReport, fleet, report, onCloseDialog } = this.props;
      const { date, isConfidentiality, nonSafetyRelated } = this.state;

      const existingReport = _get(report, 'id', false);

      if (!existingReport) {
        const userId = userService.getCurrentUser().id;

        const newReport = Object.keys(this.formData).reduce(
          (result, keyName) => {
            if (keyName !== 'reporter') {
              result[keyName] = this.formData[keyName].value;
            }

            return result;
          },
          {},
        );

        addReport(
          {
            ...newReport,
            date: reformatPickerDateToDB(date),
            fleet: fleet.id,
            user: { id: userId },
            isConfidentiality,
            nonSafetyRelated,
          },
          [],
          userId,
        );

        onCloseDialog();
      }
    }
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  onAssignUser = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  onChange = (formData) => {
    this.formData = formData;
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  setDefaultValue(fieldName) {
    const { settings } = this.props;

    return _find(settings, { name: fieldName }).textValue;
  }

  render() {
    const { t, classes, open, onCloseDialog, report } = this.props;
    const { editMode, date } = this.state;
    const {
      subject,
      issue,
      flightNumber,
      departure,
      destination,
      solution,
      isConfidentiality,
      nonSafetyRelated,
    } = report;

    return (
      <Dialog
        PaperProps={{
          classes: { root: classes.root },
        }}
        open={open}
        onClose={this.handleDialogClose}
        aria-labelledby="asign-report-dialog"
        maxWidth="md"
        fullWidth
      >
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated}
          registerForm={this.registerForm}
        >
          <DialogTitle id="assign-report-dialog">
            {t('report:Add a new report')}
          </DialogTitle>
          <DialogContent className={classes.root}>
            <div className="row">
              <div className="col-8">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="subject"
                  label={t('input:Subject')}
                  defaultValue={subject}
                  disabled={!editMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-auto">
                <DateTimePicker
                  fullWidth
                  showMonthDropdown
                  showTimeSelect={false}
                  showYearDropdown
                  handleDatePickerChange={this.handleDatePickerChange}
                  dateFormat={getDateFormat()}
                  value={date}
                  name="date"
                  label={t('input:Occurrance date')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="flightNumber"
                  label={this.setDefaultValue('flight_number')}
                  defaultValue={flightNumber}
                  disabled={!editMode}
                />
              </div>
              <div className="col">
                <MaterialInput
                  className="col"
                  fullWidth
                  margin="dense"
                  name="departure"
                  label={this.setDefaultValue('departure')}
                  defaultValue={departure}
                  disabled={!editMode}
                />
              </div>
              <div className="col">
                <MaterialInput
                  className="col"
                  fullWidth
                  margin="dense"
                  name="destination"
                  label={this.setDefaultValue('destination')}
                  defaultValue={destination}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <MaterialInput
                  fullWidth
                  multiline
                  margin="dense"
                  name="issue"
                  label={this.setDefaultValue('issue')}
                  rows={5}
                  defaultValue={issue}
                  disabled={!editMode}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <MaterialInput
                  fullWidth
                  multiline
                  margin="dense"
                  name="solution"
                  rows={5}
                  label={this.setDefaultValue('solution')}
                  defaultValue={solution}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isConfidentiality}
                      onChange={this.handleCheckboxChange('isConfidentiality')}
                      value="isConfidentiality"
                    />
                  }
                  label={t('input:confidentiality')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={nonSafetyRelated}
                      onChange={this.handleCheckboxChange('nonSafetyRelated')}
                    />
                  }
                  label={t('input:criticalReport')}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => onCloseDialog()}>
              {t('buttonCancel')}
            </Button>
            {this.hasPermissionsToAssignUser() ? (
              <div className="col-auto">
                <Button color="primary" onClick={this.onAssignUser}>
                  {t('Create')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </DialogActions>
        </Form>
      </Dialog>
    );
  }
}
