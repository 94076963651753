import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LocationsManager from '../../../Settings/General/LocationsManager';
import RemoveDialogContainer from '../../../../../common/components/RemoveDialogContainer';


import '../../styles.scss';

const INITIAL_STATE = {
  showRemoveDialog: false,
  removeCallback: () => {}
};

class Locations extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  state = INITIAL_STATE;

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  render() {
    const { showRemoveDialog, removeCallback } = this.state;
    const { userId } = this.props;

    return (
      <div>
        <LocationsManager
          onElementDelete={this.openRemoveDialog}
          userId={userId}
        />
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
      </div>
    );
  }
}


export default (Locations);

