// Only for mocking purpose.
export default {
  getItem(...params) {
    return window.localStorage.getItem(...params);
  },

  setItem(...params) {
    return window.localStorage.setItem(...params);
  },

  removeItem(...params) {
    return window.localStorage.removeItem(...params);
  },

  clear(...params) {
    return window.localStorage.clear(...params);
  }
};
