import moment from 'moment';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import {
  DATE_FORMAT_EU,
  DATE_FORMAT_US,
  SERVER_DATE_FORMAT,
  DATETIME_FORMAT_EU,
  DATETIME_FORMAT_US,
  TIME_FORMAT_EU,
  TIME_FORMAT_US,
  SERVER_DATETIME_FORMAT,
  ZERO_TIME,
} from '../../constants';
import DATE_FORMAT from '../../constants/dateformat';
import { isPracticalLesson, isProgressCheckLesson } from '../../utils';
import { store } from '../index';

function _getFormat(formats) {
  const settingsState = store.getState().settings;
  const dateFormatValue = settingsState.settings.find((setting) => setting.name === DATE_FORMAT).textValue;

  return formats[dateFormatValue];
}

export const getDateFormat = () => {
  return _getFormat({ eu: DATE_FORMAT_EU, us: DATE_FORMAT_US });
};

export const getDateTimeFormat = () => {
  return _getFormat({ eu: DATETIME_FORMAT_EU, us: DATETIME_FORMAT_US });
};

export const getTimeFormat = () => {
  return _getFormat({ eu: TIME_FORMAT_EU, us: TIME_FORMAT_US });
};

// date MM:DD:YYYYTHH:MMZ changes to MM:DD:YYYY HH:MM
export function removeTimezone(date) {
  return date.replace('T', ' ').replace('Z', '');
}

export function prepareTimeFormat(component) {
  if (component.toString().length < 2) {
    return `0${component}`;
  }

  return component;
}

export function prepareTotalTime(component) {
  if (component.toString().length === 4) {
    return `0${component}`;
  }

  return component;
}

export function sumTime(firstTime, secondTime) {
  let hour = 0;
  let minute = 0;

  firstTime = !firstTime ? ZERO_TIME : firstTime;
  secondTime = !secondTime ? ZERO_TIME : secondTime;
  const [firstHours, firstMinutes] = firstTime.split(':');
  const [secondHours, secondMinutes] = secondTime.split(':');

  hour = Number(firstHours) + Number(secondHours);
  minute = Number(firstMinutes) + Number(secondMinutes);

  if (minute >= 60) {
    hour += 1;
    minute -= 60;
  }

  return `${prepareTimeFormat(hour)}:${prepareTimeFormat(minute)}`;
}

export function calculateLessonTime({ items }) {
  return items.reduce((time, value) => {
    const estimatedTime = _get(value, 'estimatedTime', ZERO_TIME);

    return sumTime(time, estimatedTime);
  }, ZERO_TIME);
}

export function calculateLessonsTime(lessons) {
  return Object.values(lessons).reduce((time, lesson) => {
    const lessonTime = calculateLessonTime(lesson);

    return sumTime(time, lessonTime);
  }, ZERO_TIME);
}

export function minutesToTime(minutes) {
  let h = Math.floor(minutes / 60);
  let m = minutes % 60;

  h = h < 10 ? `0${h}` : h;
  m = m < 10 ? `0${m}` : m;

  return `${h}:${m}`;
}

export function msToTime(ms) {
  let minutes = parseInt((ms / (1000 * 60)) % 60, 10);
  let hours = parseInt(ms / (1000 * 60 * 60), 10);

  hours = (hours < 10) ? `0${hours}` : hours;
  minutes = (minutes < 10) ? `0${minutes}` : minutes;

  return `${hours}:${minutes}`;
}

export function getTotalLessonTime(startTime, endTime) {
  const duration = endTime - startTime;

  if (duration > 0) {
    return msToTime(duration);
  }

  return ZERO_TIME;
}

export function dateTimeDiff(minuend, subtrahend) {
  return minuend.seconds(0).milliseconds(0).diff(subtrahend.seconds(0).milliseconds(0));
}

export function dateTimeDiffInTimeFormat(minuend, subtrahend) {
  if (!minuend || !subtrahend) {
    return ZERO_TIME;
  }

  const diff = dateTimeDiff(minuend, subtrahend);

  return diff < 0 ? ZERO_TIME : msToTime(diff);
}

export function timeToMinutes(time) {
  const [estimatedHours, estimatedMinutes] = time.split(':');

  return Number(estimatedHours * 60) + Number(estimatedMinutes);
}

export function calculateCourseProgress(realTime, estimatedTime) {
  const realDuration = timeToMinutes(msToTime(realTime));
  const estimatedDuration = timeToMinutes(estimatedTime);
  const progress = realDuration / estimatedDuration;

  return !progress ? 0 : progress * 100;
}

export function substractTime(minuend, subtrahend) {
  if (!minuend) {
    return ZERO_TIME;
  }

  if (!subtrahend) {
    return minuend;
  }

  const minuendSeconds = timeToMinutes(minuend);
  const subtrahendSeconds = timeToMinutes(subtrahend);
  const substractionResult = minuendSeconds - subtrahendSeconds;

  if (substractionResult <= 0) {
    return ZERO_TIME;
  }

  return minutesToTime(substractionResult);
}

export function isNumeric(num) {
  return !isNaN(num);
}

export function getMinutes(time) {
  const [hours, minutes] = time.split(':');

  if (isNumeric(hours) && isNumeric(minutes)) {
    return true;
  }

  return false;
}

export function chceckTimeFormat(time) {
  const [hours, minutes] = time.split(':');

  return isNumeric(hours) && isNumeric(minutes) && minutes.length === 2 && hours.length > 1;
}

export function reformatTime(time) {
  if (time === 0 || _isEmpty(time)) {
    return ZERO_TIME;
  }

  return time;
}

export function reformatPickerDateFromDB(selectedDate, showInPicker = true) {
  if (_isEmpty(selectedDate)) {

    return null;
  }

  return showInPicker
    ? moment(selectedDate, [SERVER_DATE_FORMAT, getDateFormat()])
    : moment(selectedDate, [SERVER_DATE_FORMAT, getDateFormat()]).format(getDateFormat());
}

export function reformatPickerDateTimeFromDB(selectedDate, showInPicker = true) {
  let parsedDateTime = selectedDate;

  if (_isEmpty(selectedDate)) {
    return selectedDate;
  }

  if (typeof(selectedDate) !== 'object') {
    parsedDateTime = removeTimezone(selectedDate);
  }

  return showInPicker
    ? moment(parsedDateTime, [SERVER_DATETIME_FORMAT, SERVER_DATE_FORMAT])
    : moment(parsedDateTime, [SERVER_DATETIME_FORMAT, SERVER_DATE_FORMAT]).format(getDateTimeFormat());
}

export function checkExpiredDate(expiryDate) {
  return moment().subtract(1, 'days').endOf('day').isAfter(reformatPickerDateFromDB(expiryDate));
}

export function checkExpiryDate(expiryDate, monthNumber = 3) {
  return moment().subtract(1, 'days').endOf('day').add(monthNumber, 'months').isAfter(reformatPickerDateFromDB(expiryDate));
}

export function checkExpiredDateForBooking(expiryDate) {
  return moment().subtract(1, 'days').endOf('day').utcOffset(0).isAfter(reformatPickerDateTimeFromDB(expiryDate));
}

export function reformatPickerDateToDB(selectedDate) {
  if (_isEmpty(selectedDate)) {
    return selectedDate;
  }
  else if (typeof(selectedDate) === 'string' && moment(selectedDate).isValid()) {
    return moment(selectedDate).format(SERVER_DATE_FORMAT);
  } 
  else if (moment.isMoment(selectedDate)) {
    return selectedDate.format(SERVER_DATE_FORMAT);
  } 
  return selectedDate;
}

export function reformatPickerDateTimeToDB(selectedDate = moment()) {
  if (_isEmpty(selectedDate)) {
    return selectedDate;
  }
  else if (typeof(selectedDate) === 'string' && moment(selectedDate).isValid()) {
    return moment(selectedDate).format(SERVER_DATETIME_FORMAT);
  } 
  else if (moment.isMoment(selectedDate)) {
    return selectedDate.format(SERVER_DATETIME_FORMAT);
  } 
  return selectedDate;
}

export function getDistance(expiryDate) {
  return expiryDate ? dateTimeDiff(expiryDate, moment()) : 0;
}

export function prepareCrewStatusFilter(months) {
  const dateLowerThan = reformatPickerDateToDB(moment().add(Number(months), 'months'));

  return `end_date=${dateLowerThan}`;
}

export function getDashboardQueryParametersFilter(userId) {
  const dateLowerThan = reformatPickerDateToDB(moment().add(3, 'months'));
  let queryString = `end_date=${dateLowerThan}`;

  if (userId) {
    queryString += `&user=${encodeURIComponent(userId)}`;
  }

  return queryString;
}

export function getDashboardFleetParametersFilter(months = 1) {
  const dateLowerThan = reformatPickerDateToDB(moment().add(months, 'months'));

  return `inspections=${dateLowerThan}`;
}

export function calculateTotalTime(valuesToSum) {
  return _reduce(valuesToSum, (memo, value) => {
    const preparedValue = _get(value, 'value', value);

    return sumTime(memo, _isEmpty(preparedValue) ? ZERO_TIME : preparedValue);
  }, ZERO_TIME);
}

export function calculateAccumulatedTime(courseDataLessons) {
  return courseDataLessons.reduce((prevValue, lesson) => {
    if (!lesson.actualTime) { return prevValue; }

    if (isProgressCheckLesson(lesson.lessonType) || isPracticalLesson(lesson.lessonType)) {
      return { ...prevValue,
        accumulatedPractical: sumTime(prevValue.accumulatedPractical, lesson.actualTime),
        accumulatedTotal: sumTime(prevValue.accumulatedTotal, lesson.actualTime),
      };
    }

    return { ...prevValue,
      accumulatedTheoretical: sumTime(prevValue.accumulatedTheoretical, lesson.actualTime),
      accumulatedTotal: sumTime(prevValue.accumulatedTotal, lesson.actualTime),
    };
  }, { accumulatedTheoretical: ZERO_TIME, accumulatedPractical: ZERO_TIME, accumulatedTotal: ZERO_TIME });
}

export function handleTimeInputChange(key, value) {
  if (value.indexOf(':') > -1) {
    if (_isEmpty(value)) {
      return {
        key,
        value: ZERO_TIME
      };
    } else if (getMinutes(value)) {
      return {
        key,
        value
      };
    }
  }

  return {
    key,
    value: ZERO_TIME
  };
}

export function getDependentTimesDistance(totalTime, changedValue) {
  const distance = timeToMinutes(totalTime) - timeToMinutes(changedValue);

  return distance > 0 ? minutesToTime(distance) : ZERO_TIME;
}

export function checkEnteredTimeFormat(time) {
  return time.indexOf(':') > -1 && !_isEmpty(time) && chceckTimeFormat(time);
}

export function getTimeDistance(startDate, endDate) {
  return dateTimeDiff(msToTime(moment(endDate, [SERVER_DATETIME_FORMAT]), moment(startDate, [SERVER_DATETIME_FORMAT])));
}

export function chceckLessonDate(lessonEndDate) {
  return moment().subtract(6, 'months').isBefore(lessonEndDate);
}
