import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import ReportDialog from './ReportDialog';
import ReportList from './ReportList';
import { connect } from 'react-redux';
import { fetchReports } from '../../../actions/reports';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    reportList: state.reports.reportList,
    reportRequest: state.reports.reportRequest,
    reportPaginationRequest: state.reports.reportPaginationRequest,
    reportPaginationNext: state.reports.reportPaginationNext,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchReports: (sortData) => dispatch(fetchReports(sortData)),
    fetchNextReports: (paginationParams) =>
      dispatch(fetchReports(null, null, paginationParams)),
  };
}

const INITIAL_STATE = {
  openDialog: false,
  report: {},
};

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class ReportsManager extends Component {
  static propTypes = {
    fetchReports: PropTypes.func.isRequired,
    fetchNextReports: PropTypes.func.isRequired,
    fleet: PropTypes.object.isRequired,
    reportList: PropTypes.array.isRequired,
    reportRequest: PropTypes.bool.isRequired,
    reportPaginationRequest: PropTypes.bool.isRequired,
    reportPaginationNext: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { fetchReports, fleet } = this.props;

    fetchReports({ fleetId: fleet.id });
  }

  onCloseReportDialog() {
    this.setState({ openDialog: false });
  }

  onOpenAddReportDialog() {
    this.setState({ openDialog: true, report: {} });
  }

  onFetchNextReports = () => {
    const { fetchNextReports, reportPaginationNext } = this.props;

    fetchNextReports(reportPaginationNext);
  };

  render() {
    const {
      fleet,
      reportList,
      reportPaginationNext,
      reportPaginationRequest,
      t,
    } = this.props;
    const { openDialog, report } = this.state;

    return (
      <div>
        <h4>{t('fleet:Reports')}</h4>
        <ReportList reports={reportList} fleet={fleet} />
        <InfiniteScroll
          loadMore={this.onFetchNextReports}
          hasMore={!reportPaginationRequest && reportPaginationNext}
          loader={<>Loading...</>}
        />
        <ReportDialog
          fleet={fleet}
          open={openDialog}
          report={report}
          onCloseDialog={() => this.onCloseReportDialog()}
        />
        <div class="row">
          <div class="col-auto">
            <Button
              color="primary"
              onClick={() => this.onOpenAddReportDialog()}
            >
              + {t('report:Add report')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
