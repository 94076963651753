import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

@withNamespaces()
export default class InfoDialog extends Component {
  static propTypes = {
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    dialogTitle: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    acceptFunction: PropTypes.func.isRequired,
    singleButton: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  renderActions = () => {
    const { onClose, acceptFunction, singleButton, t } = this.props;

    if (singleButton) {
      return (
        <DialogActions>
          <Button onClick={acceptFunction} color="primary">
            {t('buttonOk')}
          </Button>
        </DialogActions>
      );
    }

    return (
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t('buttonCancel')}
        </Button>
        <Button onClick={acceptFunction} color="primary">
          {t('buttonOk')}
        </Button>
      </DialogActions>
    );
  }

  render() {
    const { dialogTitle, message } = this.props;

    return (
      <div>
        <DialogTitle id="info-dialog">{ dialogTitle }</DialogTitle>
        <DialogContent>
          { message }
        </DialogContent>
        { this.renderActions() }
      </div>
    );
  }
}
