import {
  ADD_PART,
  CLEAN_PART_DATA,
  EDIT_PART,
  FETCH_PARTS,
  FETCH_PARTS_FLEET_SUCCESS,
  FETCH_PARTS_PAGINATION_REQUEST,
  FETCH_PARTS_PAGINATION_SUCCESS,
  FETCH_PARTS_REQUEST,
  FETCH_PARTS_SUCCESS,
  FETCH_PART_REQUEST,
  FETCH_PART_SUCCESS,
  MOUNT_PART_DATA,
  MOUNT_WAREHOUSE_PART_DATA,
  REMOVE_PART,
  UNDO_PART,
  UNMOUNT_PART_DATA,
  UNMOUNT_PART_LIST,
  UNMOUNT_WAREHOUSE_PART_DATA,
} from '../actions/parts';

import { REMOVE_PART_FILE_SUCCESS } from '../actions/fileUpload';

const INITIAL_STATE = {
  part: {},
  partList: [],
  partFleetList: [],
  partsRequest: false,
  partsPaginationRequest: false,
  unmountedParts: [],
  fetchPartListPaginationNext: null,
};

function fetchPartsSuccess(state, payload) {
  return {
    ...state,
    partList: payload.parts.results,
    partListPaginationNext: payload.parts.next,
    partsRequest: false,
  };
}

function fetchPartsPaginationSuccess(state, payload) {
  return {
    ...state,
    partList: [...state.partList, ...payload.parts.results],
    partListPaginationNext: payload.parts.next,
    partsRequest: false,
    partsPaginationRequest: false,
  };
}

function fetchPartsFleetSuccess(state, payload) {
  return {
    ...state,
    partFleetList: payload.installations.reduce((fleetList, installation) => {
      if (installation.isActive) {
        const newPart = installation.part;

        newPart.installationId = installation.id;
        newPart.mountingDate = installation.mountingDate;
        fleetList.push(newPart);
      }

      return fleetList;
    }, []),
    partsRequest: false,
  };
}

function fetchPartsRequest(state) {
  return {
    ...state,
    partsPaginationRequest: false,
    partsRequest: true,
  };
}

function fetchPartsPaginationRequest(state) {
  return {
    ...state,
    partsRequest: false,
    partsPaginationRequest: true,
  };
}

function fetchPartSuccess(state, payload) {
  return {
    ...state,
    part: payload.part,
    partsRequest: false,
  };
}

function fetchPartRequest(state) {
  return {
    ...state,
    partsRequest: true,
  };
}

function fetchParts(state) {
  return {
    ...state,
    partsRequest: true,
  };
}

function fetchUnmountedPartsSuccess(state, payload) {
  return {
    ...state,
    unmountedParts: payload.parts,
  };
}

function addPart(state, payload) {
  return {
    ...state,
    partList: [...state.partList, payload.part],
  };
}

function editPart(state, payload) {
  return {
    ...state,
    partList: state.partList.map((part) => {
      if (part.id === payload.part.id) {
        return payload.part;
      }

      return part;
    }),
    partFleetList: state.partFleetList.map((part) => {
      if (part.id === payload.part.id) {
        const newPart = payload.part;
        const installation =
          newPart.installations.find((installation) => installation.isActive) ||
          {};

        newPart.installationId = installation.id;
        newPart.mountingDate = installation.mountingDate;

        return newPart;
      }

      return part;
    }),
  };
}

function mountPart(state, payload) {
  const newPart = payload.part;
  const installation =
    newPart.installations.find((installation) => installation.isActive) || {};

  newPart.installationId = installation.id;
  newPart.mountingDate = installation.mountingDate;

  return {
    ...state,
    partList: state.partList.map((part) => {
      if (part.id === payload.part.id) {
        return payload.part;
      }

      return part;
    }),
    partFleetList: [...state.partFleetList, newPart],
  };
}

function unmountPart(state, payload) {
  return {
    ...state,
    partList: state.partList.map((part) => {
      if (part.id === payload.part.id) {
        return payload.part;
      }

      return part;
    }),
    partFleetList: state.partFleetList.filter(
      (part) => part.id !== payload.part.id,
    ),
  };
}

function removePart(state, payload) {
  return {
    ...state,
    partList: state.partList.filter((part) => part !== payload.part),
  };
}

function removeFile(state, payload) {
  return {
    ...state,
    partList: state.partList.map((part) => {
      if (part.id === payload.partId) {
        return {
          ...part,
          files: part.files.filter((file) => file.id !== payload.fileId),
        };
      }

      return part;
    }),
    partFleetList: state.partFleetList.map((part) => {
      if (part.id === payload.partId) {
        return {
          ...part,
          files: part.files.filter((file) => file.id !== payload.fileId),
        };
      }

      return part;
    }),
  };
}

function mountWarehousePart(state, payload) {
  const newPart = payload.part;

  return {
    ...state,
    partList: state.partList.map((part) => {
      if (part.id === newPart.id) {
        return Object.assign({}, part, { warehouse: newPart.warehouse });
      }

      return part;
    }),
  };
}

function unmountWarehousePart(state, payload) {
  const newPart = payload.part;

  return {
    ...state,
    partList: state.partList.map((part) => {
      if (part.id === newPart.id) {
        return Object.assign({}, part, { warehouse: null });
      }

      return part;
    }),
  };
}

function cleanPart(state) {
  return {
    ...state,
    ...INITIAL_STATE,
  };
}

function undoPart(state, payload) {
  const { installation } = payload;

  return {
    ...state,
    partFleetList: state.partFleetList.filter(
      (part) => part.installationId !== installation.installationId,
    ),
    partList: state.partList.map((part) => {
      const newInstallations = part.installations.filter(
        (item) => item.id !== installation.installationId,
      );

      return Object.assign({}, part, { installations: newInstallations });
    }),
  };
}

const actions = {
  [FETCH_PARTS_FLEET_SUCCESS]: fetchPartsFleetSuccess,
  [FETCH_PARTS_SUCCESS]: fetchPartsSuccess,
  [FETCH_PARTS_REQUEST]: fetchPartsRequest,
  [FETCH_PART_SUCCESS]: fetchPartSuccess,
  [FETCH_PARTS_PAGINATION_REQUEST]: fetchPartsPaginationRequest,
  [FETCH_PARTS_PAGINATION_SUCCESS]: fetchPartsPaginationSuccess,
  [FETCH_PART_REQUEST]: fetchPartRequest,
  [FETCH_PARTS]: fetchParts,
  [ADD_PART]: addPart,
  [REMOVE_PART]: removePart,
  [EDIT_PART]: editPart,
  [REMOVE_PART_FILE_SUCCESS]: removeFile,
  [MOUNT_PART_DATA]: mountPart,
  [UNMOUNT_PART_DATA]: unmountPart,
  [CLEAN_PART_DATA]: cleanPart,
  [UNDO_PART]: undoPart,
  [MOUNT_WAREHOUSE_PART_DATA]: mountWarehousePart,
  [UNMOUNT_WAREHOUSE_PART_DATA]: unmountWarehousePart,
  [UNMOUNT_PART_LIST]: fetchUnmountedPartsSuccess,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
