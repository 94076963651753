import advertImage from './advertImage';
import aircrafts from './aircrafts';
import assignedCourses from './assignedCourses';
import assignedTests from './assignedTests';
import authentication from './authentication';
import bookingObjectsReducer from './bookingObjects';
import bookings from './bookings';
import { combineReducers } from 'redux';
import courses from './courses';
import defaultNotificationRecipients from './defaultNotificationRecipients';
import digitalSignature from './digitalSignature';
import documentTypes from './documentTypes';
import documents from './documents';
import eLearning from './eLearning';
import emergencyContact from './emergencyContact';
import engineerAircraftTypeExperience from './engineerAircraftTypeExperience';
import engineerAircraftTypes from './engineerAircraftTypes';
import equipment from './equipment';
import errors from './errors';
import externalCourses from './externalCourses';
import fleet from './fleet';
import flightExperience from './flightExperience';
import flightLog from './flightLog';
import folders from './folders';
import gradings from './gradings';
import groups from './groups';
import historys from './historys';
import inspections from './inspections';
import installations from './installations';
import itemTypes from './itemTypes';
import items from './items';
import lessonTypes from './lessonTypes';
import licenses from './licenses';
import locations from './locations';
import medicals from './medicals';
import modules from './modules';
import notificationCategories from './notificationCategories';
import notificationRecipients from './notificationRecipients';
import partStatuses from './partStatuses';
import parts from './parts';
import personalFiles from './personalFiles';
import pilotFunction from './pilotFunction';
import practicalFields from './practicalFields';
import qualifications from './qualifications';
import questionCategories from './questionCategories';
import questionTypes from './questionTypes';
import questions from './questions';
import ratings from './ratings';
import reportCategories from './reportCategories';
import reportFleetCategories from './reportFleetCategories';
import reportRecipents from './reportRecipents';
import reports from './reports';
import riskAssessments from './riskAssessments';
import { routerReducer } from 'react-router-redux';
import rssNews from './rssNews';
import schoolLogo from './schoolLogo';
import services from './services';
import settings from './settings';
import tests from './tests';
import userSettings from './userSettings';
import users from './users';
import vehicleTypes from './vehicleTypes';
import vehicles from './vehicles';
import warehouse from './warehouse';
import warehouseConsumables from './warehouseConsumables';
import weather from './weather';

export default combineReducers({
  routing: routerReducer,
  authentication,
  groups,
  users,
  errors,
  itemTypes,
  aircrafts,
  locations,
  questionCategories,
  gradings,
  settings,
  modules,
  questionTypes,
  practicalFields,
  ratings,
  courses,
  items,
  lessonTypes,
  assignedCourses,
  externalCourses,
  questions,
  tests,
  assignedTests,
  licenses,
  medicals,
  personalFiles,
  qualifications,
  schoolLogo,
  digitalSignature,
  bookings,
  bookingObjects: bookingObjectsReducer,
  reportRecipents,
  reportCategories,
  reportFleetCategories,
  reports,
  flightExperience,
  flightLog,
  pilotFunction,
  userSettings,
  equipment,
  folders,
  documentTypes,
  notificationCategories,
  notificationRecipients,
  defaultNotificationRecipients,
  weather,
  advertImage,
  eLearning,
  rssNews,
  vehicles,
  vehicleTypes,
  warehouse,
  warehouseConsumables,
  parts,
  partStatuses,
  inspections,
  installations,
  historys,
  fleet,
  documents,
  services,
  riskAssessments,
  emergencyContact,
  engineerAircraftTypes,
  engineerAircraftTypeExperience,
});
