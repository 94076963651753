import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 100,
  },
  iconsCell: {
    minWidth: 320,
  }
});

@withNamespaces()
@withStyles(styles)
export default class ExamOrCourseCompetenciesList extends Component {
  static propTypes = {
    handleAddOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    preview: PropTypes.bool,
    handleManageOpen: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    examOrCourseCompetenciesList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderExamOrCourseCompetenciesList() {
    const { examOrCourseCompetenciesList, classes } = this.props;
    const documentName = 'competency';

    return examOrCourseCompetenciesList.map((competency) => {
      return (
        <TableRow key={`${documentName}-${competency.id}`}>
          <TableCell className={classes.longCell}>{_get(competency, 'competence.name', _get(competency, 'competency.name', ''))}</TableCell>
          <TableCell className={classes.longCell}>{_get(competency, 'number', '')}</TableCell>
          <TableCell className={classes.longCell}>{_get(competency, 'period', '')}</TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes, preview, handleManageOpen, examOrCourseCompetenciesList = [], t } = this.props;

    if (_isEmpty(examOrCourseCompetenciesList)) {
      return (
        <div>
          <h4>{t('test:examOrCourseCompetenciesList')}</h4>
          <Button className="my-2" color="primary" onClick={handleManageOpen}>{t('test:manageExamOrCourseCompetenceButton')}</Button>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <h4>{t('test:examOrCourseCompetenciesList')}</h4>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('table:name')}</TableCell>
              <TableCell>{t('table:number')}</TableCell>
              <TableCell>{t('table:period')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderExamOrCourseCompetenciesList()}
          </TableBody>
        </Table>
        { !preview && <Button className="my-2" color="primary" onClick={handleManageOpen}>{t('test:manageExamOrCourseCompetenceButton')}</Button>}
      </div>
    );
  }
}
