import React, { Component } from 'react';
import {
  dateTimeDiff,
  reformatPickerDateTimeFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DocumentsManager from './DocumentsManager';
import Form from '../../../../common/components/forms/Form';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InspectionsManager from './InspectionsManager';
import { Link } from 'react-router-dom';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PartsManager from './PartsManager';
import PropTypes from 'prop-types';
import ReportsManager from './ReportManager';
import RisksManager from './RisksManager';
import ServicesManager from './ServicesManager';
import StatusReportManager from './StatusReportManager';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const TABS = {
  parts: 'parts',
  inspections: 'inspections',
  services: 'services',
  documents: 'documents',
  riskAssessment: 'risk assessments',
  reports: 'reports',
  statusReport: 'status report',
};

const TAB_COMPONENTS = {
  [TABS.parts]: PartsManager,
  [TABS.inspections]: InspectionsManager,
  [TABS.services]: ServicesManager,
  [TABS.documents]: DocumentsManager,
  [TABS.riskAssessment]: RisksManager,
  [TABS.reports]: ReportsManager,
  [TABS.statusReport]: StatusReportManager,
};

const styles = () => ({
  tabs: {
    borderBottom: '4px solid #41A3F3',
  },
  basicTab: {
    marginRight: '5px',
  },
  active: {
    color: 'white',
    background: 'linear-gradient(#0D5AA7, #41A3F3)',
  },
  root: {
    color: '#4B4B4B',
    background: '#D3E5F6',
    '&:hover': {
      color: 'black',
      background: 'linear-gradient(#BCDEFF, #9CD1F9)',
    },
  },
  indicator: {
    display: 'none',
  },
});

@withNamespaces()
@withStyles(styles)
export default class FleetNew extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    linkTo: PropTypes.string.isRequired,
    addFleet: PropTypes.func.isRequired,
    editFleet: PropTypes.func.isRequired,
    vehicleList: PropTypes.array.isRequired,
    vehicleTypeList: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    fleetSettings: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    partList: PropTypes.array.isRequired,
    partFleetList: PropTypes.array.isRequired,
    partStatusList: PropTypes.array.isRequired,
    inspectionList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    previewMode: PropTypes.bool,
    editMode: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    let updatedBy = props.currentUser;
    let updatedAt = moment();

    if (props.previewMode) {
      updatedBy = props.fleet.updatedBy;
      updatedAt = props.fleet.updatedAt;
    }

    this.formData = {};

    this.state = {
      currentTab: TABS.parts,
      maintainer: props.fleet.maintainer,
      trackingId: props.fleet.trackingId,
      currentHours: props.fleet.currentHours,
      currentMileage: props.fleet.currentMileage,
      operationalDays: props.fleet.operationalDays,
      updatedById: updatedBy.id,
      updatedBy: `${updatedBy.firstName} ${updatedBy.lastName}`,
      updatedAt,
      lastService: props.fleet.lastService,
      vehicleModel: _get(props.fleet, 'vehicle', null),
      vehicle: _get(props.fleet, 'vehicle.id', null),
      vehicleType: _get(props.fleet, 'vehicle.type.id', null),
      vehicleName: _get(props.fleet, 'vehicle.type.name', null),
      activeSince: _get(props.fleet, 'vehicle.activeSince', null),
      notificationEmailsAsString: '',
    };
  }

  renderViewLabel = (preview, editMode, t) => {
    if (preview) {
      return t('fleet:previewFleetSection');
    } else if (editMode) {
      return t('fleet:editFleetSection');
    }

    return t('fleet:addFleetSection');
  };

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      [name]: dateFromPicker,
    });
  };

  onChange = (formData, name, form) => {
    const { t } = this.props;
    const { value } = formData[name];
    const errorTxt = t('error:isRequired');

    this.formData = formData;

    if (name === 'vehicleType') {
      form.invalidateRequired('vehicle', errorTxt);

      this.setState(() => ({
        [name]: value,
      }));
    } else {
      this.setState(() => ({ [name]: value }));
    }
  };

  onFormValidated = (isFormValid) => {
    const { addFleet, editFleet, editMode, fleet } = this.props;
    const {
      vehicle,
      vehicleModel,
      maintainer,
      trackingId,
      currentMileage,
      updatedById,
      updatedAt,
      lastService,
      currentHours,
      operationalDays,
      notificationEmailsAsString,
    } = this.state;

    if (isFormValid && this.formData) {
      if (editMode) {
        editFleet({
          ...{
            maintainer,
            trackingId,
            currentHours,
            currentMileage,
            operationalDays,
            vehicle: vehicleModel.id,
            updatedBy: updatedById,
            updatedAt: reformatPickerDateToDB(updatedAt),
            notificationEmails: notificationEmailsAsString
              .replace(/\s/g, '')
              .split(',')
              .filter(function (t) {
                return !_isEmpty(t);
              }),
          },
          id: fleet.id,
        });
      } else {
        addFleet({
          vehicle,
          maintainer,
          trackingId,
          currentHours,
          currentMileage,
          updatedBy: updatedById,
          updatedAt: reformatPickerDateToDB(updatedAt),
          lastService: reformatPickerDateToDB(lastService || moment()),
        });
      }
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  renderButtons = (t) => {
    const { previewMode, linkTo } = this.props;

    return (
      <div className="justify-content-center row pt-1 no-print">
        <div className="col-auto">
          <Link to={linkTo}>
            <Button variant="raised">{t('buttonCancel')}</Button>
          </Link>
        </div>
        <div className="col-auto">
          {!previewMode && (
            <Button color="primary" variant="raised" onClick={this.sendHandler}>
              {t('buttonSave')}
            </Button>
          )}
        </div>
      </div>
    );
  };

  renderEditButton() {
    const { fleet } = this.props;

    return (
      <div className="col-auto no-print">
        <Link
          to={{
            pathname: `/fleet/edit/${fleet.id}`,
            state: { editMode: true },
          }}
        >
          <IconButton>
            <Icon>edit</Icon>
          </IconButton>
        </Link>
      </div>
    );
  }

  handleTabChange = (event, value) => {
    this.setState({
      currentTab: value,
    });
  };

  renderAdditionalFields() {
    const { fleet, t, classes } = this.props;
    const { currentTab } = this.state;

    if (!fleet.id) {
      return <div />;
    }

    return (
      <>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          onChange={this.handleTabChange}
          classes={{ indicator: classes.indicator }}
        >
          {Object.keys(TABS).map((tab) => {
            const label = `fleet:${TABS[tab]}`;
            const isActive = TABS[tab] === currentTab;
            const className = classNames(
              classes.basicTab,
              isActive ? classes.active : classes.root,
            );

            return (
              <Tab
                className={className}
                key={`tab-${TABS[tab]}`}
                value={TABS[tab]}
                label={t(label)}
              />
            );
          })}
        </Tabs>
      </>
    );
  }

  renderTab() {
    const {
      fleet,
      editMode,
      fleetSettings,
      inspectionList,
      partList,
      partFleetList,
      partStatusList,
    } = this.props;
    const { currentTab } = this.state;
    const TabName = TAB_COMPONENTS[currentTab];

    if (!fleet.id) {
      return <div />;
    }

    return (
      <TabName
        fleet={fleet}
        editMode={editMode}
        fleetSettings={fleetSettings}
        inspectionList={inspectionList}
        partList={partList}
        partFleetList={partFleetList}
        partStatusList={partStatusList}
      />
    );
  }

  renderVehicleList() {
    const { previewMode, editMode, vehicleList } = this.props;
    const { vehicleType } = this.state;

    if (editMode || previewMode) {
      return vehicleList.map((vehicle) => ({
        value: vehicle.id,
        label: `${vehicle.name} / ${vehicle.evn}`,
      }));
    }

    return vehicleList
      .filter(
        (vehicle) =>
          vehicle.type.id === Number(vehicleType) && !vehicle.isAssigned,
      )
      .map((vehicle) => ({
        value: vehicle.id,
        label: `${vehicle.name} / ${vehicle.evn}`,
      }));
  }

  renderHelperText(service) {
    let helperText = '';

    if (service && service.part) {
      const { name, partNumber, serialNumber } = service.part;

      helperText = `(${name} - ${partNumber}/${serialNumber})`;
    } else if (service && service.inspection) {
      const { name, description } = service.inspection;

      helperText = `(${name} - ${description})`;
    }

    return helperText;
  }

  render() {
    const { t, previewMode, editMode, vehicleTypeList, fleet } = this.props;
    const {
      maintainer,
      trackingId,
      vehicleType,
      vehicleName,
      currentHours,
      currentMileage,
      updatedBy,
      vehicle,
      vehicleModel,
      operationalDays,
      activeSince,
    } = this.state;

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center fleet">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">train</Icon>
              <h1>{t('fleet:name')}</h1>
            </span>
            <p>{this.renderViewLabel(previewMode, editMode, t)}</p>
          </header>
        </div>
        {previewMode && this.renderEditButton()}
        <div className="mt-4 col-12">
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <div className="row no-gutters">
              <div className="col-3">
                {previewMode || editMode ? (
                  <MaterialInput
                    fullWidth
                    margin="dense"
                    id="vehicleType"
                    name="vehicleType"
                    label={t('input:vehicleType')}
                    defaultValue={vehicleName}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <MaterialSelect
                    id="vehicleType"
                    name="vehicleType"
                    label={t('input:vehicleType')}
                    defaultValue={vehicleType}
                    options={vehicleTypeList.map((vehicleType) => ({
                      value: vehicleType.id,
                      label: vehicleType.name,
                    }))}
                    validators={[new validators.IsRequired(t)]}
                  />
                )}
              </div>
              <div className="col-3 offset-1">
                {vehicleModel ? (
                  <MaterialInput
                    fullWidth
                    margin="dense"
                    id="vehicle"
                    name="vehicle"
                    label={t('input:vehicle')}
                    defaultValue={`${vehicleModel.name} / ${vehicleModel.evn}`}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                ) : (
                  <MaterialSelect
                    id="vehicle"
                    name="vehicle"
                    label={t('input:vehicle')}
                    defaultValue={vehicle}
                    stateValue={vehicle}
                    options={this.renderVehicleList()}
                    validators={[new validators.IsRequired(t)]}
                  />
                )}
              </div>
              {activeSince ? (
                <div className="col-3 offset-1">
                  <MaterialInput
                    id="activeSince"
                    name="activeSince"
                    label={t('input:active since')}
                    defaultValue={activeSince}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="row no-gutters">
              <div className="col-3">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="maintainer"
                  label={t('input:maintainer')}
                  defaultValue={maintainer}
                  InputProps={{
                    readOnly: previewMode,
                    disableUnderline: previewMode,
                  }}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-3 offset-1">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="trackingId"
                  label={t('input:trackingId')}
                  defaultValue={trackingId}
                  InputProps={{
                    readOnly: previewMode,
                    disableUnderline: previewMode,
                  }}
                />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-3">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="currentHours"
                  label={t('input:currentHours')}
                  defaultValue={currentHours}
                  InputProps={{
                    readOnly: previewMode,
                    disableUnderline: previewMode,
                  }}
                  validators={[new validators.IsRequired(t)]}
                />
              </div>
              <div className="col-3 offset-1">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="currentMileage"
                  label={t('input:currentMileage')}
                  defaultValue={currentMileage}
                  InputProps={{
                    readOnly: previewMode,
                    disableUnderline: previewMode,
                  }}
                  validators={[
                    new validators.IsRequired(t),
                    new validators.IsNaturalNumber(t),
                  ]}
                />
              </div>
              <div className="col-3 offset-1">
                <MaterialInput
                  type="number"
                  fullWidth
                  margin="dense"
                  name="operationalDays"
                  label={t('input:operational days since last maintenance')}
                  defaultValue={operationalDays}
                  InputProps={{
                    readOnly: previewMode,
                    disableUnderline: previewMode,
                  }}
                />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-3">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  value={fleet.nextService.value}
                  name="nextService"
                  label={t('input:nextService')}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  helperText={this.renderHelperText(fleet.nextService)}
                />
              </div>
              <div className="col-3 offset-1">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  defaultValue={fleet.lastService}
                  name="lastService"
                  label={t('input:lastService')}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-3">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="serviceHours"
                  label={t('input:serviceHours')}
                  defaultValue={fleet.serviceHours.value}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  helperText={this.renderHelperText(fleet.serviceHours)}
                />
              </div>
              <div className="col-3 offset-1">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="serviceMileage"
                  label={t('input:serviceMileage')}
                  defaultValue={fleet.serviceMileage.value}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  helperText={this.renderHelperText(fleet.serviceMileage)}
                />
              </div>
              <div className="col-3 offset-1">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="serviceOperationalDays"
                  label={t('input:serviceOperationalDays')}
                  defaultValue={fleet.serviceOperationalDays.value}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  helperText={this.renderHelperText(
                    fleet.serviceOperationalDays,
                  )}
                />
              </div>
            </div>
            {fleet.notificationEmailsAsString || editMode ? (
              <div className="row no-gutters">
                <div className="col-6">
                  <MaterialInput
                    fullWidth
                    margin="dense"
                    name="notificationEmailsAsString"
                    label={t('input:noticationEmails')}
                    defaultValue={fleet.notificationEmailsAsString}
                    helperText={t('input:noticationEmailsHelperText')}
                    InputProps={{
                      readOnly: !editMode,
                      disableUnderline: !editMode,
                    }}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="row no-gutters">
              <div className="col-3">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  name="updatedBy"
                  label={t('input:updatedBy')}
                  defaultValue={updatedBy}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </div>
              <div className="col-3 offset-1">
                <MaterialInput
                  fullWidth
                  margin="dense"
                  defaultValue={reformatPickerDateToDB(fleet.updatedAt)}
                  name="updatedAt"
                  label={t('input:updatedAt')}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12 my-3">{this.renderButtons(t)}</div>
              <div className="col-12">
                <Divider className="mt-3 mb-5" />
                {this.renderAdditionalFields()}
                {this.renderTab()}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
