import React, { Component } from 'react';
import { getDateTimeFormat, getTimeFormat } from '../../../../utils/time';

import DateTimePicker from '../../../../../common/components/forms/DateTimePicker';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class Datetime extends Component {
  static propTypes = {
    handleDatePickerChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    preparedFileName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    minDate: PropTypes.object,
    minTime: PropTypes.object,
    value: PropTypes.object,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      handleDatePickerChange,
      preparedFileName,
      disabled,
      minDate,
      minTime,
      label,
      value,
      t,
    } = this.props;
    const inputLabel = `input:${label}`;

    return (
      <DateTimePicker
        fullWidth
        value={value}
        acceptEmptyInput
        showMonthDropdown
        showTimeSelect
        showYearDropdown
        handleDatePickerChange={handleDatePickerChange(preparedFileName)}
        dateFormat={getDateTimeFormat()}
        timeFormat={getTimeFormat()}
        name={preparedFileName}
        label={t(inputLabel)}
        disabled={disabled}
        timeIntervals={1}
        minDate={_isEmpty(minDate) ? null : minDate}
        maxDate={_isEmpty(minDate) ? null : minDate.clone().add(2, 'years')}
        minTime={_isEmpty(minTime) ? null : minTime}
        maxTime={_isEmpty(minTime) ? null : moment('23:59', getTimeFormat())}
      />
    );
  }
}
