import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Menu from './Menu';

import { renderModule } from '../../../decorators/modules';
import { MODULES } from '../../../../constants/modules';

@withNamespaces()
export default class LibraryMenu extends Menu {
  renderHeader() {
    const { t } = this.props;

    return (
      <Link to="/library" className="nav-link-item" onClick={() => this.closeMenu()}>
        {t('navigation:libraryMenu')}
      </Link>
    );
  }

  renderArrow() {
    return null;
  }

  @renderModule(MODULES.library)
  render() {
    return this.renderMenu();
  }
}
