import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/sv';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import Scheduler from 'react-big-scheduler';
import { withNamespaces, translate } from 'react-i18next';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import InfoDialog from './InfoDialog';
import AddEditBookingDialog from './dialogs/AddEditBookingDialog';
import schedulerSettings from './SchedulerSettings';
import { BOOKING_EVENT_TYPE, BOOKING_TYPE } from '../../../constants';
import { capitalizeFirstLetter, convertHex } from '../../../utils';
import { removeTimezone, reformatPickerDateTimeFromDB, getDateFormat, getTimeFormat, dateTimeDiff } from '../../utils/time';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';
import { hasPermission } from '../../decorators/permissions';
import userService from '../../../utils/userService';
import { backgroundColors } from './config';


import './styles.scss';
import './overwrited.scss';

const styles = () => ({
  border: {
    borderLeft: '2px solid'
  },
  bookingText: {
    marginLeft: '4px',
  },
  basicBackground: {
    backgroundColor: '#CFCFCF',
  },
  currentUserBorder: {
    borderColor: '#1094FF',
  },
  currentUserDisabledBorder: {
    borderColor: '#79BDF5',
  },
  otherUserBorder: {
    borderColor: '#676767',
  },
  otherUserDisabledBorder: {
    borderColor: '#ABABAB',
  },
  basicBorder: {
    borderColor: '#ABABAB',
  },
});

const RESET_STATE = {
  event: null,
  slotId: null,
  slotName: null,
  start: null,
  end: null,
  oldSlotId: null,
  oldStart: null,
  oldEnd: null,
  eventStartOpen: false,
  eventEndOpen: false,
  eventMoveOpen: false,
  conflictOccurredOpen: false,
  eventClickedOpen: false,
  eventCannotRemoveOpen: false,
  booking: {
    dialogOpen: false
  }
};

const languages = {
  en: 'en-gb',
  se: 'sv',
  pl: 'pl'
};

@translate()
@withNamespaces()
@DragDropContext(HTML5Backend)
@withStyles(styles)
export default class DayView extends Component {
  static propTypes = {
    bookingObjectList: PropTypes.array.isRequired,
    bookingList: PropTypes.array.isRequired,
    userList: PropTypes.array.isRequired,
    editBooking: PropTypes.func.isRequired,
    removeBooking: PropTypes.func.isRequired,
    notify: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    lng: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    const { bookingObjectList, bookingList, t, lng } = this.props;
    const events = this.prepareEvent(bookingList);
    const schedulerData = schedulerSettings(bookingObjectList, events, t, languages[lng]);

    this.state = {
      resources: bookingObjectList,
      events,
      schedulerData,
      eventStartOpen: false,
      eventEndOpen: false,
      eventMoveOpen: false,
      conflictOccurredOpen: false,
      eventClickedOpen: false,
      eventCannotRemoveOpen: false,
      booking: {
        dialogOpen: false
      }
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { bookingList, lng } = this.props;

    if (bookingList !== prevProps.bookingList || lng !== prevProps.lng) {
      this.updateEvents(bookingList, lng);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { bookingObjectList, bookingList, t, lng } = this.props;
    const events = this.prepareEvent(bookingList);
    const schedulerData = schedulerSettings(bookingObjectList, events, t, languages[lng]);

    this.setState({
      schedulerData,
    });
  }

  updateEvents = (bookingList, lng) => {
    const { schedulerData } = this.state;
    const events = this.prepareEvent(bookingList);

    moment.locale(languages[lng]);
    schedulerData.setLocaleMoment(moment);
    schedulerData.setEvents(events);

    this.setState({
      events,
      schedulerData
    });
  }

  getEventType = (disabled, isCurrentUser) => {
    if (isCurrentUser) {
      if (disabled) {
        return BOOKING_EVENT_TYPE.currentUserDisabled;
      }

      return BOOKING_EVENT_TYPE.currentUser;
    }

    if (disabled) {
      return BOOKING_EVENT_TYPE.otherUserDisabled;
    }

    return BOOKING_EVENT_TYPE.otherUser;
  }

  prepareEvent = (bookingList) => {
    return bookingList.map((booking) => {
      const user = userService.getCurrentUser();
      const hasStarted = moment(removeTimezone(booking.startDate)).isBefore(moment());
      const canEdit = hasPermission(PERMISSIONS.createAllBookings) || booking.user.id === user.id;
      const disabled = hasStarted || !canEdit;

      return {
        id: booking.id,
        start: removeTimezone(booking.startDate),
        end: removeTimezone(booking.endDate),
        userId: booking.user.id,
        resourceId: booking.bookingObject.id,
        title: `${capitalizeFirstLetter(booking.user.firstName)} ${capitalizeFirstLetter(booking.user.lastName)}`,
        movable: !disabled,
        resizable: !disabled,
        type: booking.type,
        withUserId: booking.withUser && booking.withUser.id,
        withUser: booking.withUser && booking.withUser.id,
        comment: booking.comment,
        active: booking.active
      };
    });
  }

  prevClick = (schedulerData) => {
    const { events } = this.state;

    schedulerData.prev();
    schedulerData.setEvents(events);
    this.setState({
      schedulerData
    });
  }

  nextClick = (schedulerData) => {
    const { events } = this.state;

    schedulerData.next();
    schedulerData.setEvents(events);
    this.setState({
      schedulerData
    });
  }

  onViewChange = (schedulerData, view) => {
    const { events } = this.state;

    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(events);
    this.setState({
      schedulerData
    });
  }

  onSelectDate = (schedulerData, date) => {
    const { events } = this.state;

    schedulerData.setDate(date);
    schedulerData.setEvents(events);
    this.setState({
      schedulerData
    });
  }

  reinitializeState = () => {
    this.setState({
      ...RESET_STATE,
    });
  }

  handleCannotRemoveClose = () => {
    this.reinitializeState();
  }

  handleEventClicked = () => {
    const { removeBooking } = this.props;
    const { event } = this.state;

    const booking = {
      id: event.id,
      startDate: event.start,
      endDate: event.end,
      bookingObjectId: event.slotId,
      userId: event.userId,
    };

    removeBooking(booking);
    this.reinitializeState();
  }

  handleEventClickedClose = () => {
    this.reinitializeState();
  }

  // eslint-disable-next-line max-params
  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    const newState = {
      schedulerData,
      slotId,
      slotName,
      start,
      end,
      type,
      item,
    };

    this.setState({
      ...newState,
      booking: {
        dialogOpen: true,
        permission: {
          [PERMISSIONS.createAllBookings]: hasPermission(PERMISSIONS.createAllBookings)
        }
      },
    });
  };

  handleEventBook = () => {
    const user = userService.getCurrentUser();

    this.handleBooking(`${capitalizeFirstLetter(user.firstName)} ${capitalizeFirstLetter(user.lastName)}`, user.id);
    this.reinitializeState();
  }

  handleEventBookClose = () => {
    this.reinitializeState();
  }

  updateEventStart = (schedulerData, event, start) => {
    const oldStart = event.start;
    const oldEnd = event.end;

    schedulerData.updateEventStart(event, start);

    this.setState({
      event,
      start,
      end: oldEnd,
      oldStart,
      oldEnd,
      eventStartOpen: true,
    });
  }

  handleEventStart = () => {
    const { editBooking } = this.props;
    const { event, start } = this.state;
    const booking = {
      ...event,
      startDate: start,
      bookingObjectId: event.resourceId
    };

    if (!booking.withUserId) {
      delete booking.withUserId;
    }

    editBooking(booking);
    this.reinitializeState();
  }

  handleEventStartClose = () => {
    const { event, oldStart, schedulerData } = this.state;

    schedulerData.updateEventStart(event, oldStart);
    this.reinitializeState();
  }

  updateEventEnd = (schedulerData, event, end) => {
    const oldStart = event.start;
    const oldEnd = event.end;

    schedulerData.updateEventEnd(event, end);

    this.setState({
      event,
      start: oldStart,
      end,
      oldStart,
      oldEnd,
      schedulerData,
      eventEndOpen: true,
    });
  };

  handleEventEnd = () => {
    const { editBooking } = this.props;
    const { event, end } = this.state;

    const booking = {
      ...event,
      startDate: event.start,
      endDate: end,
      bookingObjectId: event.resourceId
    };

    if (!booking.withUserId) {
      delete booking.withUserId;
    }

    editBooking(booking);
    this.reinitializeState();
  };

  handleEventEndClose = () => {
    const { event, oldEnd, schedulerData } = this.state;

    schedulerData.updateEventEnd(event, oldEnd);

    this.setState({
      ...RESET_STATE,
      schedulerData,
    });
  }

  // eslint-disable-next-line max-params
  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    const oldStart = event.start;
    const oldEnd = event.end;
    const oldSlotId = event.resourceId;

    schedulerData.moveEvent(event, slotId, slotName, start, end);

    this.setState({
      event,
      slotId,
      slotName,
      start,
      end,
      oldSlotId,
      oldStart,
      oldEnd,
      eventMoveOpen: true,
    });
  }

  handleEventMove = () => {
    const { editBooking } = this.props;
    const { event } = this.state;

    const booking = {
      ...event,
      startDate: event.start,
      endDate: event.end,
      bookingObjectId: event.resourceId
    };

    if (!booking.withUserId) {
      delete booking.withUserId;
    }

    editBooking(booking);
    this.reinitializeState();
  };

  handleEventMoveClose = () => {
    const { event, oldSlotId, slotName, oldStart, oldEnd, schedulerData } = this.state;

    schedulerData.moveEvent(event, oldSlotId, slotName, oldStart, oldEnd);

    this.setState({
      ...RESET_STATE,
      schedulerData,
    });
  }

  conflictOccurred = () => {
    this.setState({
      conflictOccurredOpen: true,
    });
  }

  handleConflictOccurredClose = () => {
    this.reinitializeState();
  }

  getBorderColor = (eventType) => {
    const borderType = BOOKING_EVENT_TYPE[eventType] || 'basic';

    return `${borderType}Border`;
  };


  getAddEditBookingDialog = () => {
    const { booking, start, end, slotId } = this.state;
    const { bookingObjectList } = this.props;

    return booking.dialogOpen && (
      <AddEditBookingDialog
        {...booking}
        open={booking.dialogOpen}
        bookingData={{
          start,
          end,
          slotId,
          user: userService.getCurrentUser(),
        }}
        onClose={() => this.setState({ ...this.state, booking: { dialogOpen: false } })}
        bookingObjectList={bookingObjectList}
        checkBookingTimeDidntOverlap={this.checkBookingTimeDidntOverlap}
      />
    );
  };

  checkBookingTimeDidntOverlap = ({ id, bookingObjectId, startDate, endDate }) => {
    const { bookingList } = this.props;

    const filteredBookings = bookingList
      .filter((booking) => Number(booking.id) !== Number(id))
      .filter((booking) => Number(booking.bookingObject.id) === Number(bookingObjectId))
      .filter(
        (booking) => {
          const bookingStartDate = reformatPickerDateTimeFromDB(booking.startDate);
          const bookingEndDate = reformatPickerDateTimeFromDB(booking.endDate);

          return moment(startDate).isBetween(bookingStartDate, bookingEndDate)
            ||
            moment(endDate).isBetween(bookingStartDate, bookingEndDate)
            ||
            moment(bookingStartDate).isBetween(startDate, endDate)
            ||
            moment(bookingEndDate).isBetween(startDate, endDate);
        }
      );

    filteredBookings.length && this.setState({ conflictOccurredOpen: true });

    return filteredBookings.length === 0;
  };

  // eslint-disable-next-line max-params
  eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => {
    const { classes } = this.props;
    const titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
    const borderClass = this.getBorderColor(event.type);
    const currentUser = userService.getCurrentUser();
    const backgroundColor = backgroundColors[currentUser.id === event.userId ? BOOKING_TYPE.OWN : event.type];
    const opacity = dateTimeDiff(moment(event.start), moment()) < 0 ? 0.4 : 1;
    const color = convertHex(backgroundColor, opacity);

    const eventStyles = {
      height: `${mustBeHeight}px`,
      backgroundColor: color,
    };

    if (!event.active) {
      eventStyles.background = `repeating-linear-gradient(
                        45deg,
                        ${color},
                        ${color} 10px,
                        ${backgroundColors.FLIGHT_WITH_INACTIVE} 10px,
                        ${backgroundColors.FLIGHT_WITH_INACTIVE} 20px
                      )`;
    }

    return (
      <div
        key={`booking-event-${event.id}`}
        style={eventStyles}
        className={classNames(mustAddCssClass, classes[borderClass], classes.border)}
      >
        <span className={`${classes.bookingText}`} style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>
          {titleText}
        </span>
      </div>
    );
  };

  // eslint-disable-next-line
  popover = (schedulerData, eventItem, title, start, end, color) => {
    const user = userService.getCurrentUser();
    const { userList, notify, t } = this.props;
    const bookingType = `booking:${eventItem.type}`;

    const editBooking = () => {
      const canUpdate = this.authorizeAction(eventItem, PERMISSIONS.updateAllBookings);

      if (canUpdate) {
        this.setState({ ...this.state, booking: { dialogOpen: true, editData: { ...eventItem } } });
      } else {
        this.setState({ cannotEditOpen: true });
      }
    };

    const deleteBooking = () => {
      const canDelete = this.authorizeAction(eventItem, PERMISSIONS.deleteAllBookings);

      this.setState({
        event: eventItem,
        eventClickedOpen: canDelete,
        eventCannotRemoveOpen: !canDelete,
      });
    };

    const notifyBooking = () => notify({
      bookingId: eventItem.id,
      userId: user.id
    });

    return (
      <article className="booking-notification">
        <div>
          <Tooltip title={t(bookingType)} placement="top">
            <div className="status-circle"
              style={{
                borderColor: `${this.getBookingBackgroundColor(eventItem)}`
              }}
            />
          </Tooltip>
          <Tooltip title="Edit" placement="top">
            <IconButton onClick={editBooking}>
              <Icon fontSize="inherit">edit</Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <IconButton
              onClick={deleteBooking}
            >
              <Icon fontSize="inherit">delete</Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Notify" placement="top">
            <IconButton
              onClick={notifyBooking}
            >
              <Icon fontSize="inherit">email</Icon>
            </IconButton>
          </Tooltip>
        </div>
        <p className="booking-popup-title">
          {schedulerData.resources.find((res) => res.id === eventItem.resourceId).name}
        </p>
        <div>
          <b>
            <span className="mr-1">{start.format(getDateFormat())}</span>&bull;
            <span className="ml-1">{start.format(getTimeFormat())}</span>
            -
            <span>{end.format(getTimeFormat())}</span>
          </b>
        </div>
        <div>
          <b>{t('booking:bookedFor')}:</b> {eventItem.title}
        </div>
        {
          eventItem.type === BOOKING_TYPE.FLIGHT_WITH && (
            <div>
              <b>{t('booking:flight_with')}:</b>
              {
                `${userList.find((user) => user.id === eventItem.withUser).firstName} 
                ${userList.find((user) => user.id === eventItem.withUser).lastName}`
              }
            </div>
          )
        }
        {
          eventItem.type === BOOKING_TYPE.FLIGHT_WITH && (
            <div>
              <b>{t('booking:comments')}:</b> {eventItem.comment}
            </div>
          )
        }
      </article>
    );
  };

  getBookingBackgroundColor = (booking) => {
    const user = userService.getCurrentUser();

    return backgroundColors[user.id === booking.userId ? BOOKING_TYPE.OWN : booking.type];
  };

  authorizeAction(event, permission) {
    const currentUser = userService.getCurrentUser();
    const hasStarted = moment(event.start).isBefore(moment());

    return hasStarted ? false : (currentUser.id === event.userId || hasPermission(permission));
  }

  render() {
    const { t } = this.props;
    const {
      schedulerData,
      eventStartOpen,
      eventEndOpen,
      eventMoveOpen,
      conflictOccurredOpen,
      eventClickedOpen,
      eventCannotRemoveOpen,
      cannotEditOpen,
      start,
      end,
    } = this.state;

    const newStart = reformatPickerDateTimeFromDB(start, false);
    const newEnd = reformatPickerDateTimeFromDB(end, false);
    const eventStartMessage = (
      <div>
        <p>{t('booking:startDate')}: {newStart}</p><p>{t('booking:endDate')}: {newEnd}</p>
        <p>{t('booking:emailWithChangeSentToPersonRelatedAndAdministrator')}</p>
      </div>);
    const eventEndMessage = (
      <div>
        <p>{t('booking:startDate')}: {newStart}</p><p>{t('booking:endDate')}: {newEnd}</p>
        <p>{t('booking:emailWithChangeSentToPersonRelatedAndAdministrator')}</p>
      </div>);
    const eventMoveMessage = (
      <div>
        <p>{t('booking:startDate')}: {newStart}</p><p>{t('booking:endDate')}: {newEnd}</p>
        <p>{t('booking:emailWithChangeSentToPersonRelatedAndAdministrator')}</p>
      </div>);
    const conflictOccurredMessage = (<div><p>{t('booking:twoBookingsCannotBeAdded')}</p></div>);
    const eventClickedMessage = (<div><p>{t('booking:emailWithChangeSentToPersonRelatedAndAdministrator')}</p></div>);
    const eventCannotRemoveMessage = (<div><p>{t('booking:cannotRemoveThisBooking')}</p></div>);
    const eventCannotEditMessage = (<div><p>{t('booking:cannotEditThisBooking')}</p></div>);


    const eventStartLabel = t('booking:wantToChangeTheStartDate');
    const eventEndLabel = t('booking:wantToChangeTheEndDate');
    const eventMoveLabel = t('booking:wantToMove');
    const conflictOccurredLabel = t('booking:conflictOccured');
    const eventClickedLabel = t('booking:wantToCompletlyRemove');
    const eventCannotRemoveLabel = t('booking:cannotRemoveBooking');
    const eventCannotEditLabel = t('booking:cannotEditBooking');

    return (
      <article className="booking-scheduler">
        <div className="d-flex flex-column">
          <Button color="primary"
            className="align-self-end mb-3"
            onClick={() => this.setState({ booking: {
              dialogOpen: true,
              permission: {
                [PERMISSIONS.createAllBookings]: hasPermission(PERMISSIONS.createAllBookings)
              }
            } })}
          >
            <Icon color="primary" className="mr-3">playlist_add</Icon>
            {t('booking:addBookingButton')}
          </Button>
          <Scheduler schedulerData={schedulerData}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            updateEventStart={this.updateEventStart}
            updateEventEnd={this.updateEventEnd}
            newEvent={this.newEvent}
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            eventItemPopoverTemplateResolver={this.popover}
            moveEvent={this.moveEvent}
            conflictOccurred={() => this.setState({ conflictOccurredOpen: true })}
          />
        </div>
        {
          this.getAddEditBookingDialog()
        }
        <Dialog
          open={eventStartOpen}
          onClose={this.handleEventStartClose}
          aria-labelledby="info-dialog"
          fullWidth
        >
          <InfoDialog
            message={eventStartMessage}
            onClose={this.handleEventStartClose}
            dialogTitle={eventStartLabel}
            acceptFunction={this.handleEventStart}
          />
        </Dialog>
        <Dialog
          open={eventEndOpen}
          onClose={this.handleEventEndClose}
          aria-labelledby="info-dialog"
          fullWidth
        >
          <InfoDialog
            message={eventEndMessage}
            onClose={this.handleEventEndClose}
            dialogTitle={eventEndLabel}
            acceptFunction={this.handleEventEnd}
          />
        </Dialog>
        <Dialog
          open={eventMoveOpen}
          onClose={this.handleEventMoveClose}
          aria-labelledby="info-dialog"
          fullWidth
        >
          <InfoDialog
            message={eventMoveMessage}
            onClose={this.handleEventMoveClose}
            dialogTitle={eventMoveLabel}
            acceptFunction={this.handleEventMove}
          />
        </Dialog>
        <Dialog
          open={conflictOccurredOpen}
          onClose={this.handleConflictOccurredClose}
          aria-labelledby="info-dialog"
          fullWidth
        >
          <InfoDialog
            message={conflictOccurredMessage}
            onClose={this.handleConflictOccurredClose}
            dialogTitle={conflictOccurredLabel}
            acceptFunction={this.handleConflictOccurredClose}
            singleButton
          />
        </Dialog>

        <Dialog
          open={eventClickedOpen}
          onClose={this.handleEventClickedClose}
          aria-labelledby="info-dialog"
          fullWidth
        >
          <InfoDialog
            message={eventClickedMessage}
            onClose={this.handleEventClickedClose}
            dialogTitle={eventClickedLabel}
            acceptFunction={this.handleEventClicked}
          />
        </Dialog>
        <Dialog
          open={eventCannotRemoveOpen}
          onClose={this.handleCannotRemoveClose}
          aria-labelledby="info-dialog"
          fullWidth
        >
          <InfoDialog
            message={eventCannotRemoveMessage}
            onClose={this.handleCannotRemoveClose}
            dialogTitle={eventCannotRemoveLabel}
            acceptFunction={this.handleCannotRemoveClose}
            singleButton
          />
        </Dialog>
        <Dialog
          open={cannotEditOpen}
          onClose={() => this.setState({ cannotEditOpen: false })}
          aria-labelledby="info-dialog"
          fullWidth
        >
          <InfoDialog
            message={eventCannotEditMessage}
            onClose={() => this.setState({ cannotEditOpen: false })}
            dialogTitle={eventCannotEditLabel}
            acceptFunction={() => this.setState({ cannotEditOpen: false })}
            singleButton
          />
        </Dialog>
      </article>
    );
  }
}
