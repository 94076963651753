import '../styles.scss';

import React, { Component } from 'react';
import { fetchReports, removeReport } from '../../../actions/reports';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import RemoveDialog from '../../../../common/components/RemoveDialog';
import ReportTable from './ReportTable';
import { connect } from 'react-redux';
import userService from '../../../../utils/userService';
import { withNamespaces } from 'react-i18next';

const INITIAL_STATE = {
  searchSettings: {
    sortAsc: true,
    search: '',
    userId: '',
  },
  removeDialogOpen: false,
  removeReport: null,
};

const mapStateToProps = (state) => {
  return {
    reportList: state.reports.reportList,
    reportRequest: state.reports.reportRequest,
    reportPaginationRequest: state.reports.reportPaginationRequest,
    reportPaginationNext: state.reports.reportPaginationNext,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchReports: (sortData) => dispatch(fetchReports(sortData)),
    fetchNextReports: (paginationParams) =>
      dispatch(fetchReports(null, null, paginationParams)),
    removeReport: (report) => dispatch(removeReport(report)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class ReportList extends Component {
  static propTypes = {
    userId: PropTypes.number,
    reportList: PropTypes.array,
    reportRequest: PropTypes.bool.isRequired,
    reportPaginationRequest: PropTypes.bool.isRequired,
    reportPaginationNext: PropTypes.string,
    fetchReports: PropTypes.func.isRequired,
    fetchNextReports: PropTypes.func.isRequired,
    removeReport: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { sortAsc, search } = this.state.searchSettings;
    const userId = userService.getCurrentUser().id;

    if (userId) {
      const searchData = {
        sortAsc,
        search,
        userId,
      };

      this.updateSearchSettings(searchData);
      this.props.fetchReports(searchData);

      return;
    }

    this.props.fetchReports();
  }

  updateSearchSettings = (searchSettings) => {
    this.setState({ searchSettings });
  };

  renderAddReportIcon(t) {
    return (
      <div className="col-auto">
        <Link to="/report/new">
          <Button color="primary">
            <Icon color="primary" className="mr-3">
              playlist_add
            </Icon>
            {t('report:create report')}
          </Button>
        </Link>
      </div>
    );
  }

  handleSort = () => {
    const { search, sortAsc, userId } = this.state.searchSettings;
    const searchData = {
      sortAsc: !sortAsc,
      search,
      userId,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchReports(searchData);
  };

  handleSearch = (search) => {
    const { sortAsc, userId } = this.state.searchSettings;
    const searchData = {
      sortAsc,
      search,
      userId,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchReports(searchData);
  };

  removeReport = () => {
    const { removeReport } = this.state;

    this.closeRemoveDialog();
    this.props.removeReport(removeReport);
  };

  openRemoveDialog = (report) => {
    this.setState({
      removeDialogOpen: true,
      removeReport: report,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      removeReport: null,
    });
  };

  onFetchNextReports = () => {
    const { fetchNextReports, reportPaginationNext } = this.props;

    console.log('next', reportPaginationNext);

    fetchNextReports(reportPaginationNext);
  };

  renderRemoveDialog = (t) => {
    return (
      <Dialog
        open={this.state.removeDialogOpen}
        onClose={this.closeRemoveDialog}
        aria-labelledby="remove-dialog"
        fullWidth
      >
        <RemoveDialog
          message={t('report:removeReportDialogTitle')}
          closeRemoveDialog={this.closeRemoveDialog}
          dialogTitle={t('report:removeReport')}
          removeFunction={this.removeReport}
        />
      </Dialog>
    );
  };

  render() {
    const {
      reportList,
      reportRequest,
      reportPaginationRequest,
      reportPaginationNext,
      t,
    } = this.props;
    const { searchSettings } = this.state;

    if (reportRequest) {
      return <Loader />;
    }

    return (
      <div className="row justify-content-lg-between justify-content-end align-items-center reports">
        <div className="col-lg-8 col-sm-12 section-title">
          <header>
            <span>
              <Icon color="primary">description</Icon>
              <h1>{t('report:name')}</h1>
            </span>
            <p>{t('report:description')}</p>
          </header>
        </div>
        {this.renderAddReportIcon(t)}
        <div className="col-12">
          <ReportTable
            reportList={reportList}
            removeReport={this.removeReport}
            sortAsc={searchSettings.sortAsc}
            handleSort={this.handleSort}
            handleSearch={this.handleSearch}
            search={searchSettings.search}
            openRemoveDialog={this.openRemoveDialog}
          />
          <InfiniteScroll
            loadMore={this.onFetchNextReports}
            hasMore={!reportPaginationRequest && reportPaginationNext}
            loader={<>Loading...</>}
          />
          {this.renderRemoveDialog(t)}
        </div>
      </div>
    );
  }
}
