import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import _isNil from 'lodash/isNil';
import _invert from 'lodash/invert';

import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';

import TabContainer from '../../../../common/components/TabContainer';
import Table from '../../../../common/components/Table';
import { TABS, URLS_RAIL as URLS } from '../../../../constants';
import { reformatPickerDateFromDB, reformatPickerDateTimeFromDB, getDistance } from '../../../utils/time';
import { renderPermission } from '../../../decorators/permissions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import { tabActiveHeader, tabInactiveHeader, tabCompletedHeader } from './testListHelper';

import './styles.scss';

const headersMap = {
  [TABS.active]: tabActiveHeader,
  [TABS.inactive]: tabInactiveHeader,
  [TABS.completed]: tabCompletedHeader
};

const testsFilters = {
  [TABS.active]: (assignedTest) => {
    const activatedTime = reformatPickerDateTimeFromDB(assignedTest.scheduleTime);

    if (_isNil(assignedTest.scheduleTime)) {
      return assignedTest.isActive === true && _isNil(assignedTest.endDate);
    }

    const distance = getDistance(activatedTime);

    return (assignedTest.isActive === true ||
      (assignedTest.isActive === false && distance < 0)) &&
      _isNil(assignedTest.endDate);

  },
  [TABS.inactive]: (assignedTest) => {
    const activatedTime = reformatPickerDateTimeFromDB(assignedTest.scheduleTime);

    if (_isNil(assignedTest.scheduleTime)) {
      return assignedTest.isActive === false;
    }

    const distance = getDistance(activatedTime);

    return assignedTest.isActive === false && distance > 0;
  },
  [TABS.completed]: (assignedTest) => {
    return !_isNil(assignedTest.endDate);
  }
};

@withNamespaces()
export default class TabsView extends Component {
  static propTypes = {
    assignedTestList: PropTypes.array,
    handleSort: PropTypes.func.isRequired,
    sortAsc: PropTypes.bool.isRequired,
    columnToSort: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    openRemoveDialog: PropTypes.func.isRequired,
    handleActivateTest: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    currentTab: TABS.active,
  };

  handleChange = (event, value) => {
    this.setState({
      currentTab: value
    });
  };

  @renderPermission(PERMISSIONS.assignedTestDelete)
  renderTrashIcon(test) {
    return <IconButton onClick={() => this.props.openRemoveDialog(test)}><Icon color="primary">delete</Icon></IconButton>;
  }

  @renderPermission(PERMISSIONS.assignedTestEdit)
  renderManageIcons(test, isInactiveTab) {
    const { handleActivateTest } = this.props;

    return (
      <div className="icons-display-wrapper">
        {this.renderTrashIcon(test)}
        {isInactiveTab && <IconButton onClick={() => handleActivateTest(test.id)}><Icon color="primary">assignment</Icon></IconButton>}
      </div>
    );
  }

  renderPreviewIcon(testId) {
    return (
      <Link to={`/completed_test/preview/${testId}`}>
        <Icon color="primary">search</Icon>
      </Link>
    );
  }

  makeCertificateLink = (courseId) => {
    const href = `${URLS.testCertificate}${courseId}/`;

    return (
      <a className="table-row-link" href={href} target="_blank">
        <IconButton><Icon color="primary">print</Icon></IconButton>
      </a>
    );
  };

  mapActiveTestList = (activeTest) => {
    const testId = activeTest.id;

    return ({
      id: testId,
      startDate: this.makeLink(testId, reformatPickerDateFromDB(activeTest.startDate, false), '/exam_list/details/'),
      firstName: this.makeLink(testId, activeTest.user.firstName, '/exam_list/details/'),
      lastName: this.makeLink(testId, activeTest.user.lastName, '/exam_list/details/'),
      name: this.makeLink(testId, activeTest.test.name, '/exam_list/details/'),
      icons: this.renderManageIcons(activeTest, false),
    });
  };

  mapInactiveTestList = (inactiveTest) => {
    const testId = inactiveTest.id;

    return ({
      id: testId,
      firstName: inactiveTest.user.firstName,
      lastName: inactiveTest.user.lastName,
      name: inactiveTest.test.name,
      scheduleTime: reformatPickerDateFromDB(inactiveTest.scheduleTime, false),
      icons: this.renderManageIcons(inactiveTest, true),
    });
  };

  mapCompletedTestList = (completedTest) => {
    const testId = completedTest.id;

    return ({
      id: testId,
      firstName: this.makeLink(testId, completedTest.user.firstName, '/completed_test/preview/'),
      lastName: this.makeLink(testId, completedTest.user.lastName, '/completed_test/preview/'),
      name: this.makeLink(testId, completedTest.test.name, '/completed_test/preview/'),
      dateCompleted: this.makeLink(testId, reformatPickerDateFromDB(completedTest.endDate, false), '/completed_test/preview/'),
      result: this.makeLink(testId, this.getResult(completedTest), '/completed_test/preview/'),
      preview: this.renderPreviewIcon(testId),
      certificate: this.makeCertificateLink(testId)
    });
  };

  getResult = (completedTest) => {
    if (completedTest.result) {
      return (
        <div className="completed-test">
          <Icon className="test-passed">check</Icon>
        </div>
      );
    }

    return (
      <div className="completed-test">
        <Icon className="test-failed">close</Icon>
      </div>
    );

  };

  makeLink = (courseId, text, destination) => {
    return (
      <Link className="table-row-link" to={`${destination}${courseId}`}>
        {text}
      </Link>
    );
  };

  getTestsData(type) {
    const { assignedTestList } = this.props;
    const testsMappers = {
      [TABS.active]: this.mapActiveTestList,
      [TABS.inactive]: this.mapInactiveTestList,
      [TABS.completed]: this.mapCompletedTestList,
    };

    return assignedTestList.filter(testsFilters[type]).map(testsMappers[type]);
  }

  renderTab(currentTab) {
    const { handleSort, sortAsc, columnToSort, handleSearch, search, t } = this.props;

    if (currentTab in _invert(TABS)) {
      return (
        <TabContainer>
          <Table
            data={this.getTestsData(currentTab)}
            header={headersMap[currentTab]}
            handleSort={handleSort}
            sortAsc={sortAsc}
            columnToSort={columnToSort}
            handleSearch={handleSearch}
            search={search}
            searchLabel={t('input:searchLabel')}
          />
        </TabContainer>
      );
    }

    return (<div />);
  }

  render() {
    const { t } = this.props;
    const { currentTab } = this.state;

    return (
      <div>
        <Paper>
          <Tabs
            value={currentTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value={TABS.active} label={t('test:activeTab')} />
            <Tab value={TABS.inactive} label={t('test:inactiveTab')} />
            <Tab value={TABS.completed} label={t('test:completedTab')} />
          </Tabs>
        </Paper>
        {this.renderTab(currentTab)}
      </div>
    );
  }
}
