import React, { Component } from 'react';
import {
  addDocument,
  editDocument,
  removeDocument,
} from '../../../actions/documents';
import {
  attachDocumentFile,
  removeDocumentFile,
} from '../../../actions/fileUpload';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DocumentList from './DocumentList';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import _get from 'lodash/get';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  id: '',
  name: '',
  expiryDate: null,
  documentDate: null,
  showRemoveDialog: false,
  removeCallback: () => {},
};

const mapStateToProps = (state) => {
  return {
    documentList: state.documents.documentList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addDocument: (data) => dispatch(addDocument(data)),
    editDocument: (data) => dispatch(editDocument(data)),
    removeDocument: (data) => dispatch(removeDocument(data)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    attachDocumentFile: (fileData, id, fleetId) =>
      dispatch(attachDocumentFile(fileData, id, fleetId)),
    removeDocumentFile: (fileId, id) =>
      dispatch(removeDocumentFile(fileId, id)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class DocumentsManager extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    documentList: PropTypes.array.isRequired,
    addDocument: PropTypes.func.isRequired,
    editDocument: PropTypes.func.isRequired,
    removeDocument: PropTypes.func.isRequired,
    attachDocumentFile: PropTypes.func.isRequired,
    removeDocumentFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    fleetSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.formData = {};
  }

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (document) => {
    this.setState({
      editOpen: true,
      id: document.id,
      name: document.name,
      expiryDate: reformatPickerDateFromDB(_get(document, 'expiryDate', null)),
      documentDate: reformatPickerDateFromDB(
        _get(document, 'documentDate', null),
      ),
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  handleDatePickerChange = (name) => (dateFromPicker) => {
    this.setState({
      [name]: dateFromPicker,
    });
  };

  onChange = (formData, name) => {
    this.setState({
      [name]: formData[name].value,
    });
  };

  addDocument = () => {
    const { fleet } = this.props;
    const { name, expiryDate, documentDate } = this.state;
    const data = {
      name,
      expiryDate: expiryDate ? reformatPickerDateToDB(expiryDate) : null,
      documentDate: documentDate ? reformatPickerDateToDB(documentDate) : null,
      fleet: fleet.id,
    };

    this.props.addDocument(data);
    this.handleDialogClose();
  };

  editDocument = () => {
    const { fleet } = this.props;
    const { id, name, expiryDate, documentDate } = this.state;
    const data = {
      id,
      name,
      expiryDate: expiryDate ? reformatPickerDateToDB(expiryDate) : null,
      documentDate: documentDate ? reformatPickerDateToDB(documentDate) : null,
      fleet: fleet.id,
    };

    this.props.editDocument(data);
    this.handleDialogClose();
  };

  onFormValidated = (isFormValid) => {
    const { addOpen } = this.state;

    if (isFormValid && this.formData) {
      addOpen ? this.addDocument() : this.editDocument();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const {
      fleet,
      documentList,
      removeDocument,
      classes,
      t,
      attachDocumentFile,
      removeDocumentFile,
      addSimpleError,
      editMode,
      fleetSettings,
    } = this.props;
    const {
      addOpen,
      editOpen,
      name,
      expiryDate,
      documentDate,
      showRemoveDialog,
      removeCallback,
    } = this.state;

    return (
      <div className="document-settings">
        <h4>{t('fleet:documentsSection')}</h4>
        <DocumentList
          fleet={fleet}
          documentList={documentList}
          removeDocument={removeDocument}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          onElementDelete={this.openRemoveDialog}
          attachDocumentFile={attachDocumentFile}
          removeDocumentFile={removeDocumentFile}
          addSimpleError={addSimpleError}
          editMode={editMode}
          fleetSettings={fleetSettings}
        />
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={addOpen || editOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="add-document-type-dialog"
          maxWidth="md"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle id="add-edit-document-type-dialog">
              {addOpen
                ? t('fleet:addDocumentDialogTitle')
                : t('fleet:editDocumentDialogTitle')}
            </DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row">
                <div className="col-12">
                  <MaterialInput
                    label={t('input:name')}
                    autoFocus
                    name="name"
                    defaultValue={name}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                    ]}
                    fullWidth
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:documentDate')}
                    name="documentDate"
                    value={documentDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange(
                      'documentDate',
                    )}
                    dateFormat={getDateFormat()}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:expiryDate')}
                    name="expiryDate"
                    value={expiryDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange(
                      'expiryDate',
                    )}
                    dateFormat={getDateFormat()}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </div>
    );
  }
}
