import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import ReportCategoryList from './ReportCategoryList';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import Loader from '../../../../common/components/Loader';
import Form from '../../../../common/components/forms/Form';

import { fetchReportCategories, addReportCategory, removeReportCategory, editReportCategory } from '../../../actions/reportCategories';
import validators from '../../../../utils/validators';

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  category: '',
  firstName: '',
  lastName: '',
  email: '',
  reportCategoryId: null,
};

const mapStateToProps = (state) => {
  return {
    reportCategoryList: state.reportCategories.reportCategoryList,
    reportCategoryRequest: state.reportCategories.reportCategoryRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchReportCategories: () => dispatch(fetchReportCategories()),
    addReportCategory: (reportCategory) => dispatch(addReportCategory(reportCategory)),
    editReportCategory: (reportCategory) => dispatch(editReportCategory(reportCategory)),
    removeReportCategory: (reportCategory) => dispatch(removeReportCategory(reportCategory)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class ReportCategoriesManager extends Component {
  static propTypes = {
    reportCategoryList: PropTypes.array,
    reportCategoryRequest: PropTypes.bool.isRequired,
    fetchReportCategories: PropTypes.func.isRequired,
    addReportCategory: PropTypes.func.isRequired,
    editReportCategory: PropTypes.func.isRequired,
    removeReportCategory: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    this.props.fetchReportCategories();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleEditOpen = (reportCategory) => {
    this.setState({
      editOpen: true,
      category: reportCategory.category,
      firstName: reportCategory.firstName,
      lastName: reportCategory.lastName,
      email: reportCategory.email,
      reportCategoryId: reportCategory.id,
    });
  };

  handleClose = () => {
    this.setState(INITIAL_STATE);
  };

  addReportCategory = () => {
    const { category, firstName, lastName, email } = this.state;

    this.props.addReportCategory({ category, firstName, lastName, email });
    this.handleClose();
  };

  editReportCategory = () => {
    const { reportCategoryId, category, firstName, lastName, email } = this.state;

    this.props.editReportCategory({ id: reportCategoryId, category, firstName, lastName, email });
    this.handleClose();
  };

  removeReportCategory = (reportCategory) => {
    this.props.removeReportCategory(reportCategory);
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value
      });
    });
  };

  onFormValidated = () => {
    const { addOpen } = this.state;

    return (isFormValid) => {
      if (isFormValid) {
        addOpen ? this.addReportCategory() : this.editReportCategory();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const { reportCategoryList, reportCategoryRequest, onElementDelete, t } = this.props;
    const { addOpen, editOpen, category, firstName, lastName, email } = this.state;

    if (reportCategoryRequest) {
      return <Loader />;
    }

    return (
      <div className="report-categories-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:reportCategories')}</h4>
        </div>
        <ReportCategoryList
          reportCategoryList={reportCategoryList}
          removeReportCategory={this.removeReportCategory}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          onElementDelete={onElementDelete}
        />
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen || editOpen}
            onClose={this.handleClose}
            aria-labelledby="add-report-category-dialog"
            fullWidth
          >
            <DialogTitle id="add-report-category-dialog">{addOpen ? t('settings:addReportCategory') : t('settings:editReportCategory')}</DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-6">
                  <MaterialInput
                    label={t('input:category')}
                    name="category"
                    autoFocus
                    defaultValue={category}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200)
                    ]}
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:firstName')}
                    name="firstName"
                    defaultValue={firstName}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200)
                    ]}
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:lastName')}
                    name="lastName"
                    defaultValue={lastName}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200)
                    ]}
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:email')}
                    name="email"
                    defaultValue={email}
                    margin="dense"
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                      new validators.Email(t),
                    ]}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleClose}>{t('buttonCancel')}</Button>
              <Button color="primary" onClick={this.sendHandler}>{t('buttonSave')}</Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
