import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS as URLS } from '../../constants';

export const FETCH_REPORT_FLEET_CATEGORIES_REQUEST =
  'FETCH_REPORT_FLEET_CATEGORIES_REQUEST';
export const FETCH_REPORT_FLEET_CATEGORIES_SUCCESS =
  'FETCH_REPORT_FLEET_CATEGORIES_SUCCESS';
export const FETCH_REPORT_FLEET_CATEGORIES_FAILURE =
  'FETCH_REPORT_FLEET_CATEGORIES_FAILURE';
export const ADD_REPORT_FLEET_CATEGORY = 'ADD_REPORT_FLEET_CATEGORY';
export const EDIT_REPORT_FLEET_CATEGORY = 'EDIT_REPORT_FLEET_CATEGORY';
export const REMOVE_REPORT_FLEET_CATEGORY = 'REMOVE_REPORT_FLEET_CATEGORY';

export function fetchReportFleetCategoriesRequest() {
  return {
    type: FETCH_REPORT_FLEET_CATEGORIES_REQUEST,
  };
}

export function fetchReportFleetCategoriesSuccess(reportFleetCategories) {
  return {
    type: FETCH_REPORT_FLEET_CATEGORIES_SUCCESS,
    payload: {
      reportFleetCategories,
    },
  };
}

export function fetchReportFleetCategoriesFailure() {
  return {
    type: FETCH_REPORT_FLEET_CATEGORIES_FAILURE,
  };
}

export function addReportFleetCategorySuccess(reportFleetCategory) {
  return {
    type: ADD_REPORT_FLEET_CATEGORY,
    payload: {
      reportFleetCategory,
    },
  };
}

export function editReportFleetCategorySuccess(reportFleetCategory) {
  return {
    type: EDIT_REPORT_FLEET_CATEGORY,
    payload: {
      reportFleetCategory,
    },
  };
}

export function removeReportFleetCategorySuccess(reportFleetCategory) {
  return {
    type: REMOVE_REPORT_FLEET_CATEGORY,
    payload: {
      reportFleetCategory,
    },
  };
}

export function fetchReportFleetCategories() {
  return (dispatch) => {
    dispatch(fetchReportFleetCategoriesRequest());

    return axios
      .get(URLS.reportFleetCategories)
      .then(({ data }) =>
        dispatch(fetchReportFleetCategoriesSuccess(data.results)),
      )
      .catch((error) => {
        dispatch(fetchReportFleetCategoriesFailure());
        dispatch(
          addError(
            `Error during reportFleetCategory fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function addReportFleetCategory(ReportFleetCategory) {
  return (dispatch) => {
    return axios
      .post(URLS.reportFleetCategories, ReportFleetCategory)
      .then(({ data }) => dispatch(addReportFleetCategorySuccess(data)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during reportFleetCategory adding ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function editReportFleetCategory(reportFleetCategory) {
  return (dispatch) => {
    return axios
      .patch(
        urlJoin(URLS.reportFleetCategories, reportFleetCategory.id),
        reportFleetCategory,
      )
      .then(({ data }) => dispatch(editReportFleetCategorySuccess(data)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during reportFleetCategory editing ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function removeReportFleetCategory(reportFleetCategory) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.reportFleetCategories, reportFleetCategory.id))
      .then(() =>
        dispatch(removeReportFleetCategorySuccess(reportFleetCategory)),
      )
      .catch((error) => {
        dispatch(
          addError(
            `Error during reportFleetCategory removing ${error.message}`,
            error,
          ),
        );
      });
  };
}
