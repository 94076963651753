import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';

import { addSimpleError } from '../../../actions/errors';
import { fetchStaffCategories } from '../../../actions/staffCategories';
import SelectStaffCategory from './selectStaffCategory';

import '../../../../styles/components/table.scss';

const mapStateToProps = (state) => {
  return {
    staffCategoriesList: state.staffCategories.staffCategoryList,
    staffCategoriesRequest: state.staffCategories.staffCategoryRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchStaffCategories: (searchData) => dispatch(fetchStaffCategories(searchData)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class UsersCategoriesFiler extends Component {
  static propTypes = {
    filterIsUsed: PropTypes.bool,
    selectedStaffCategory: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    staffCategoriesList: PropTypes.array.isRequired,
    handleFilter: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func,
    fetchStaffCategories: PropTypes.func.isRequired,
    staffCategoriesRequest: PropTypes.bool.isRequired,
    filterSettings: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    search: '',
    addOpen: false,
  };

  componentDidMount() {
    const { fetchStaffCategories } = this.props;

    fetchStaffCategories();
  }

  handleSearchInStaffCategories = (searchData) => {
    this.setState({
      search: searchData
    });

    this.props.fetchStaffCategories({ search: searchData });
  };

  renderDialog = () => {
    const { addOpen, search } = this.state;
    const { staffCategoriesList, staffCategoriesRequest, handleFilter, clearFilter, selectedStaffCategory } = this.props;

    return (
      <div className="col-12">
        <Dialog
          open={addOpen}
          onClose={this.handleDialogClose}
          maxWidth="md"
          fullWidth
        >
          <SelectStaffCategory
            staffCategoriesList={staffCategoriesList}
            handleFilter={handleFilter}
            clearFilter={clearFilter}
            search={search}
            selectedStaffCategory={selectedStaffCategory}
            staffCategoriesRequest={staffCategoriesRequest}
            handleSearchInStaffCategories={this.handleSearchInStaffCategories}
            handleClick={this.handleClick}
          />
        </Dialog>
      </div>
    );
  };

  handleClick = () => {
    const { addOpen } = this.state;

    this.setState({
      addOpen: !addOpen
    });
  };

  render() {
    const { filterIsUsed, t } = this.props;

    return (
      <div className="col-auto table-wrapper">
        <Button
          aria-owns={this.state.anchorEl ? 'filter-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <Icon className={filterIsUsed ? 'filterIsUsed mr-3' : 'mr-3'}>filter_list</Icon>
          <span className={filterIsUsed ? 'filterIsUsed' : ''}>
            {t('table:filter')}
          </span>
        </Button>
        {this.renderDialog(t)}
      </div>
    );
  }
}
