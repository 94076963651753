import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    minHeight: 300,
  },
});

@withNamespaces()
@withStyles(styles)
export default class GradingDialog extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    open: PropTypes.bool,
    options: PropTypes.array,
    handleDialogClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    formKey: 1,
    name: '',
    options: [],
  };

  componentWillReceiveProps({ name, options }) {
    this.state = { formKey: 1, name, options };
  }

  deleteOption = (index) => {
    const options = [...this.state.options];

    options.splice(index, 1);

    this.setState({ formKey: this.state.formKey + 1, options });
  };

  swapOptions = (aIndex, bIndex) => {
    const options = [...this.state.options];

    const move = options[aIndex];

    options[aIndex] = options[bIndex];
    options[bIndex] = move;

    this.setState({
      formKey: this.state.formKey + 1,
      options,
    });
  };

  onFormDataChange = (formData) => {
    const optionKeys = Object.keys(formData).filter((key) =>
      key.startsWith('options-'),
    );

    this.setState({
      name: formData.name.value,
      options: optionKeys.map((key) => formData[key].value),
    });
  };

  onFormValidated = (isFormValid) => {
    if (isFormValid) {
      const { id, editMode, onSave } = this.props;
      const { name, options } = this.state;

      onSave(editMode ? { id, name, options } : { name, options });
    }
  };

  renderOptions = () => {
    const { t } = this.props;
    const { options } = this.state;

    return (
      <>
        {options.map((option, index) => {
          return (
            <div className="row">
              <div className="col-2">
                {index !== options.length - 1 && (
                  <Button onClick={() => this.swapOptions(index, index + 1)}>
                    <Icon>keyboard_arrow_down</Icon>
                  </Button>
                )}
              </div>
              <div className="col-2">
                {index !== 0 && (
                  <Button onClick={() => this.swapOptions(index - 1, index)}>
                    <Icon>keyboard_arrow_up</Icon>
                  </Button>
                )}
              </div>
              <div className="col-8">
                <MaterialInput
                  defaultValue={option}
                  helperText={!option ? t('input: Please set a grading') : ''}
                  key={`options-${index}`}
                  name={`options-${index}`}
                  stateValue={option}
                  validators={[
                    new validators.MaxLength(t, 100),
                    new validators.IsRequired(t),
                  ]}
                />
                <Button onClick={() => this.deleteOption(index)}>
                  <Icon>delete</Icon>
                </Button>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  render() {
    const { classes, editMode, handleDialogClose, open, t } = this.props;
    const { name, options, formKey } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.handleDialogClose}
        aria-labelledby="add-aircraft-dialog"
        fullWidth
      >
        <DialogTitle id="add-aircraft-dialog">
          {editMode
            ? t('settings:addGradingDialogTitle')
            : t('settings:editGradingDialogTitle')}
        </DialogTitle>
        <DialogContent>
          <Form
            key={formKey}
            onChange={this.onFormDataChange}
            onFormValidated={this.onFormValidated}
            registerForm={(trigger) => (this.validateForm = trigger)}
          >
            <FormControl className={classNames(classes.root, 'col-12')}>
              <div className="row">
                <div className="col-12">
                  <MaterialInput
                    fullWidth
                    label={t('input:title')}
                    name="name"
                    defaultValue={name}
                    stateValue={name}
                    validators={[
                      new validators.MaxLength(t, 200),
                      new validators.IsRequired(t),
                    ]}
                  />
                </div>
              </div>
              <h4>{t('settings:Gradings')}</h4>
              {this.renderOptions()}
              <Button
                onClick={() =>
                  this.setState({
                    formKey: formKey + 1,
                    options: [...options, ''],
                  })
                }
              >
                {t('button:Add more')}
              </Button>
            </FormControl>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleDialogClose}>
            {t('buttonCancel')}
          </Button>
          <Button color="primary" onClick={() => this.validateForm()}>
            {t('buttonSave')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
