import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _isEmpty from 'lodash/isEmpty';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Lesson from './Lesson';

@withNamespaces()
export default class LessonsManager extends Component {
  static propTypes = {
    lessons: PropTypes.array.isRequired,
    lessonTypeList: PropTypes.array.isRequired,
    addLesson: PropTypes.func.isRequired,
    dropMoveItem: PropTypes.func.isRequired,
    dropRemoveItem: PropTypes.func.isRequired,
    moveLesson: PropTypes.func.isRequired,
    moveItemSortable: PropTypes.func.isRequired,
    removeLesson: PropTypes.func.isRequired,
    changeLesson: PropTypes.func.isRequired,
    handleRegisterComponentForm: PropTypes.func.isRequired,
    handleFormValidated: PropTypes.func.isRequired,
    preview: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.formData = {};
    this.triggers = {};
  }

  renderAddLesson = (addLesson, preview, t) => {
    if (preview) {
      return null;
    }

    return (
      <div className="col-12 text-center my-3">
        <Button color="primary" onClick={addLesson}>
          {t('course:addLessonButton')}
        </Button>
      </div>
    );
  }

  render() {
    const {
      lessons,
      addLesson,
      lessonTypeList,
      dropMoveItem,
      dropRemoveItem,
      removeLesson,
      moveLesson,
      moveItemSortable,
      changeLesson,
      preview,
      t,
    } = this.props;

    if (_isEmpty(lessons)) {
      return (
        <div className="lessons">
          <div className="col-xs-12">
            <h4>{t('course:lessons')}</h4>
          </div>
          <Divider color="secondary" />
          {this.renderAddLesson(addLesson, preview, t)}
        </div>
      );
    }

    return (
      <div className="lessons">
        <div className="col-xs-12">
          <h4>{t('course:lessons')}</h4>
        </div>
        <Divider color="secondary" />
        {lessons.map((lesson, index) => (
          <Lesson
            index={index}
            lesson={lesson}
            key={`lesson-${lesson.uniqueId}`}
            lessonTypeList={lessonTypeList}
            dropMoveItem={dropMoveItem}
            dropRemoveItem={dropRemoveItem}
            moveLesson={moveLesson}
            moveItemSortable={moveItemSortable}
            removeLesson={removeLesson}
            changeLesson={changeLesson}
            registerComponentForm={this.props.handleRegisterComponentForm(`LessonsManager-${lesson.uniqueId}`)}
            formValidated={this.props.handleFormValidated}
            preview={preview || lesson.isCompleted}
          />
        ))}
        {this.renderAddLesson(addLesson, preview, t)}
      </div>
    );
  }
}
