import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import AssignView from '../../../common/components/AssignView';
import { reformatPickerDateFromDB, reformatPickerDateToDB, getDateFormat } from '../../utils/time';

import { fetchUsers } from '../../actions/users';
import { fetchGroups } from '../../actions/groups';

const mapStateToProps = (state) => {
  return {
    userList: state.users.userList,
    getUsersSuccess: state.users.getUsersSuccess,
    groups: state.groups.groupList,
    getUsersRequest: state.users.getUsersRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: (searchData) => {
      dispatch(fetchUsers(searchData));
    },
    fetchGroups: () => dispatch(fetchGroups()),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class CrossAssignView extends Component {
  static propTypes = {
    userList: PropTypes.array,
    groups: PropTypes.array,
    handleAssignViewClose: PropTypes.func.isRequired,
    serializeData: PropTypes.func.isRequired,
    viewLabel: PropTypes.string.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    getUsersSuccess: PropTypes.bool.isRequired,
    getUsersRequest: PropTypes.bool.isRequired,
  };

  state = {
    searchSettings: {
      sortAsc: true,
      columnToSort: '',
      search: '',
      filter: [],
      filterIsUsed: false
    }
  };


  componentDidMount() {
    const searchData = {
      ...this.state.searchSettings
    };

    this.props.fetchUsers(searchData);
    this.props.fetchGroups();
  }


  serializeAndSendData = (users, scheduleTime, isActive) => {
    const { serializeData, handleAssignViewClose } = this.props;

    serializeData(users, reformatPickerDateToDB(scheduleTime), isActive);
    handleAssignViewClose();
  };

  render() {
    const { handleAssignViewClose, serializeData, viewLabel, groups, userList, fetchGroups, fetchUsers } = this.props;

    return (
      <AssignView
        userList={userList}
        groups={groups}
        serializeAndSendData={this.serializeAndSendData}
        getDateFormat={getDateFormat}
        reformatPickerDateFromDB={reformatPickerDateFromDB}
        handleAssignViewClose={handleAssignViewClose}
        serializeData={serializeData}
        viewLabel={viewLabel}
        fetchGroups={fetchGroups}
        fetchUsers={fetchUsers}
      />
    );
  }
}
