import axios from 'axios';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS_RAIL as URLS } from '../../constants';
import history from '../store/history';

export const FETCH_TESTS_REQUEST = 'FETCH_TESTS_REQUEST';
export const FETCH_TESTS_SUCCESS = 'FETCH_TESTS_SUCCESS';
export const FETCH_TESTS_FAILURE = 'FETCH_TESTS_FAILURE';
export const FETCH_TEST_REQUEST = 'FETCH_TEST_REQUEST';
export const FETCH_TEST_SUCCESS = 'FETCH_TEST_SUCCESS';
export const FETCH_TEST_FAILURE = 'FETCH_TEST_FAILURE';
export const ADD_TEST = 'ADD_TEST';
export const EDIT_TEST = 'EDIT_TEST';

export function fetchTestsRequest() {
  return {
    type: FETCH_TESTS_REQUEST
  };
}

export function fetchTestsSuccess(tests) {
  return {
    type: FETCH_TESTS_SUCCESS,
    payload: {
      tests
    }
  };
}

export function fetchTestFailure() {
  return {
    type: FETCH_TEST_FAILURE
  };
}

export function fetchTestRequest() {
  return {
    type: FETCH_TEST_REQUEST
  };
}

export function fetchTestSuccess(test) {
  return {
    type: FETCH_TEST_SUCCESS,
    payload: {
      test
    }
  };
}

export function fetchTestsFailure() {
  return {
    type: FETCH_TESTS_FAILURE
  };
}

export function addTestSuccess(test) {
  return {
    type: ADD_TEST,
    payload: {
      test
    }
  };
}

export function editTestSuccess(test) {
  return {
    type: EDIT_TEST,
    payload: {
      test
    }
  };
}

export function fetchTests(sortingData) {
  return (dispatch) => {
    dispatch(fetchTestsRequest());

    let queryParameters = {};

    if (sortingData && !_isEmpty(sortingData.search)) {
      queryParameters.search = `${sortingData.search}`;
    }

    if (sortingData) {
      queryParameters.ordering = `${(sortingData.sortAsc ? '' : '-')}name`;
    }

    queryParameters = qs.stringify(queryParameters);
    queryParameters = _isEmpty(queryParameters) ? '' : `?${queryParameters}`;

    return axios.get(urlJoin(URLS.manageTests, queryParameters))
      .then(({ data }) => dispatch(fetchTestsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchTestsFailure());
        dispatch(addError(`Error during exams fetching ${error.message}`, error));
      });
  };
}

export function fetchTest(testId) {
  return (dispatch) => {
    dispatch(fetchTestRequest());

    return axios.get(urlJoin(URLS.tests, testId))
      .then(({ data }) => {
        dispatch(fetchTestSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchTestFailure());
        dispatch(addError(`Error during exam fetching ${error.message}`, error));
      });
  };
}


export function addTest(test) {
  return (dispatch) => {
    return axios.post(URLS.manageTests, test)
      .then((data) => {
        dispatch(addTestSuccess(data));
        history.push('/exam_admin');
      })
      .catch((error) => {
        dispatch(addError(`Error during exam adding ${error.message}`, error));
      });
  };
}

export function removeTest(test) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.manageTests, test.id), { isActive: false })
      .then(({ data }) => dispatch(editTestSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during exam removing ${error.message}`, error));
      });
  };
}

export function editTest(test) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.tests, test.id), test)
      .then((data) => {
        dispatch(editTestSuccess(data));

        history.push('/exam_admin');
      })
      .catch((error) => {
        dispatch(addError(`Error during exam editing ${error.message}`, error));
      });
  };
}
