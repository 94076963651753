import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Loader from '../../../../../common/components/Loader';
import Confirmation from '../../../../../common/components/Confirmation';

import Types from './Types';
import Locations from './Locations';
import UserSettings from './UserSettings';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { fetchUserSettings, editUserSettings, resetSaveUserSettingsSuccess } from '../../../../actions/userSettings';
import { addSimpleError } from '../../../../actions/errors';

import '../../styles.scss';
import { fetchTypes } from '../../../../actions/types';
import { fetchLocations } from '../../../../actions/locations';

const mapStateToProps = (state) => {
  return {
    userSettings: state.userSettings.userSettings,
    userSettingsRequest: state.userSettings.userSettingsRequest,
    typeList: state.types.typeList,
    locationList: state.locations.locationList.filter((location) => location.isActive),
    typeRequest: state.types.typeRequest,
    locationRequest: state.locations.locationRequest,
    changeUserSettingsSuccess: state.userSettings.changeUserSettingsSuccess
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUserSettings: (userId) => dispatch(fetchUserSettings(userId)),
    editUserSettings: (userId, userSettings) => dispatch(editUserSettings(userId, userSettings)),
    fetchTypes: (userId) => dispatch(fetchTypes(userId)),
    fetchLocations: (userId) => dispatch(fetchLocations(userId)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    resetSaveUserSettingsSuccess: () => dispatch(resetSaveUserSettingsSuccess())
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class Settings extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    userSettingsRequest: PropTypes.bool,
    userSettings: PropTypes.object,
    addSimpleError: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    fetchTypes: PropTypes.func,
    fetchLocations: PropTypes.func,
    editUserSettings: PropTypes.func,
    typeList: PropTypes.array,
    locationList: PropTypes.array,
    typeRequest: PropTypes.bool.isRequired,
    locationRequest: PropTypes.bool.isRequired,
    changeUserSettingsSuccess: PropTypes.bool.isRequired,
    resetSaveUserSettingsSuccess: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    departure: null,
    arrival: null,
    type: null,
  };

  componentDidMount() {
    const { fetchUserSettings, fetchTypes, fetchLocations, userId } = this.props;

    fetchUserSettings(userId);
    fetchTypes(userId);
    fetchLocations(userId);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  updateUserSettingsList = (userSetting, value, name) => {
    if (!_isEmpty(value.toString())) {
      userSetting = {
        ...userSetting,
        [name]: { id: value },
      };
    }

    return userSetting;
  };

  saveUserSettings = () => {
    const { editUserSettings, userSettings } = this.props;
    const { departure, arrival, type } = this.state;
    const departureId = this.getValuesFromList(departure, 'departure.id');
    const arrivalId = this.getValuesFromList(arrival, 'arrival.id');
    const typeId = this.getValuesFromList(type, 'type.id');

    let userSetting = {};

    userSetting = this.updateUserSettingsList(userSetting, departureId, 'departure');
    userSetting = this.updateUserSettingsList(userSetting, arrivalId, 'arrival');
    userSetting = this.updateUserSettingsList(userSetting, typeId, 'type');

    editUserSettings(userSettings.id, userSetting);
  };

  getValuesFromList = (name, value) => {
    return name ? name : _get(this.props.userSettings, value, '');
  };

  renderButtons() {
    const { userId } = this.props;
    const pathname = `/users/${userId}`;

    return (
      <div className="justify-content-center row">
        <div className="col-auto">
          <Link to={pathname}>
            <Button variant="raised">
                CANCEL
            </Button>
          </Link>
        </div>
        <div className="col-auto">
          <Button color="primary" variant="raised" onClick={() => this.saveUserSettings()}>
              SAVE
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const {
      userSettingsRequest,
      locationRequest,
      typeList,
      locationList,
      userSettings,
      userId,
      resetSaveUserSettingsSuccess,
      changeUserSettingsSuccess,
      t } = this.props;
    const { departure, arrival, type } = this.state;

    if (userSettingsRequest || locationRequest) {
      return <Loader />;
    }

    return (
      <div>
        <Types
          userId={userId}
        />
        <Locations
          userId={userId}
        />
        <UserSettings
          userId={userId}
          userSettings={userSettings}
          userTypes={typeList}
          userLocations={locationList}
          handleChange={this.handleChange}
          departure={this.getValuesFromList(departure, 'departure.id')}
          arrival={this.getValuesFromList(arrival, 'arrival.id')}
          type={this.getValuesFromList(type, 'type.id')}
        />
        {this.renderButtons()}
        <Dialog
          open={changeUserSettingsSuccess}
          onClose={resetSaveUserSettingsSuccess}
          aria-labelledby="confirmation-assign"
          fullWidth
        >
          <Confirmation
            message={t('user:userSettingsChangesSaved')}
            closeConfirmationDialog={resetSaveUserSettingsSuccess}
          />
        </Dialog>
      </div>
    );
  }
}
