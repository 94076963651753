import { URLS_RAIL } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { urlJoin } from '../../utils';

export const FETCH_RISK_ASSESSMENT_REQUEST = 'FETCH_RISK_ASSESSMENT_REQUEST';
export const FETCH_RISK_ASSESSMENT_FAILURE = 'FETCH_RISK_ASSESSMENT_FAILURE';
export const FETCH_RISK_ASSESSMENT_SUCCESS = 'FETCH_RISK_ASSESSMENT_SUCCESS';
export const GET_RISK_ASSESSMENT = 'GET_RISK_ASSESSMENT';
export const ADD_RISK_ASSESSMENT_REQUEST = 'ADD_RISK_ASSESSMENT_REQUEST';
export const ADD_RISK_ASSESSMENT_FAILURE = 'ADD_RISK_ASSESSMENT';
export const ADD_RISK_ASSESSMENT = 'ADD_RISK_ASSESSMENT';
export const EDIT_RISK_ASSESSMENT = 'EDIT_RISK_ASSESSMENT';
export const REMOVE_RISK_ASSESSMENT = 'REMOVE_RISK_ASSESSMENT';

export function fetchRiskAssessmentsRequest() {
  return {
    type: FETCH_RISK_ASSESSMENT_REQUEST,
  };
}

export function fetchRiskAssessmentsSuccess(riskAssessments) {
  return {
    type: FETCH_RISK_ASSESSMENT_SUCCESS,
    payload: {
      riskAssessments,
    },
  };
}

export function fetchRiskAssessmentsFailure() {
  return {
    type: FETCH_RISK_ASSESSMENT_FAILURE,
  };
}

export function getRiskAssessmentSuccess(riskAssessment) {
  return {
    type: GET_RISK_ASSESSMENT,
    payload: {
      riskAssessment,
    },
  };
}

export function addRiskAssessmentSuccess(riskAssessment) {
  return {
    type: ADD_RISK_ASSESSMENT,
    payload: {
      riskAssessment,
    },
  };
}

export function editRiskAssessmentSuccess(riskAssessment) {
  return {
    type: EDIT_RISK_ASSESSMENT,
    payload: { riskAssessment },
  };
}

export function removeRiskAssessmentSuccess(riskAssessment) {
  return {
    type: REMOVE_RISK_ASSESSMENT,
    payload: {
      riskAssessment,
    },
  };
}

export function fetchRiskAssessments(filter) {
  return (dispatch) => {
    dispatch(fetchRiskAssessmentsRequest());

    return axios
      .get(URLS_RAIL.riskAssessments + (filter ? `?${filter}` : ''))
      .then(({ data }) => dispatch(fetchRiskAssessmentsSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchRiskAssessmentsFailure());
        dispatch(
          addError(
            `Error during riskAssessments fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function getRiskAssessment(id) {
  return (dispatch) => {
    dispatch(fetchRiskAssessmentsRequest());

    return axios
      .get(urlJoin(URLS_RAIL.riskAssessments, id))
      .then(({ data }) => dispatch(getRiskAssessmentSuccess(data)))
      .catch((error) => {
        dispatch(fetchRiskAssessmentsFailure());
        dispatch(
          addError(`Error during get riskAssessment ${error.message}`, error),
        );
      });
  };
}

export function addRiskAssessment(riskAssessment) {
  return (dispatch) => {
    return axios
      .post(URLS_RAIL.riskAssessments, riskAssessment)
      .then(({ data }) => {
        dispatch(addRiskAssessmentSuccess(data));

        window.location.replace(`/sms/risk-assessments/${data.id}`);
      })
      .catch((error) => {
        dispatch(
          addError(
            `Error during riskAssessment adding: ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function editRiskAssessment(riskAssessment) {
  return (dispatch) => {
    return axios
      .patch(
        urlJoin(URLS_RAIL.riskAssessments, riskAssessment.id),
        riskAssessment,
      )
      .then(({ data }) => {
        dispatch(editRiskAssessmentSuccess(data));
        window.location.replace(`/sms/risk-assessments/${data.id}`);
      })
      .catch((error) => {
        dispatch(
          addError(
            `Error during riskAssessment editing ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function removeRiskAssessment(riskAssessment) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS_RAIL.riskAssessments, riskAssessment.id))
      .then(() => dispatch(removeRiskAssessmentSuccess(riskAssessment)))
      .catch((error) => {
        dispatch(
          addError(
            `Error during riskAssessment removing ${error.message}`,
            error,
          ),
        );
      });
  };
}
