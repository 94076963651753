import { URLS } from '../../constants';
import { addError } from './errors';
import axios from 'axios';
import { urlJoin } from '../../utils';

export const FETCH_ENGINEER_AIRCRAFT_TYPE_REQUEST =
  'FETCH_ENGINEER_AIRCRAFT_TYPE_REQUEST';
export const FETCH_ENGINEER_AIRCRAFT_TYPE_SUCCESS =
  'FETCH_ENGINEER_AIRCRAFT_TYPE_SUCCESS';
export const FETCH_ENGINEER_AIRCRAFT_TYPE_FAILURE =
  'FETCH_ENGINEER_AIRCRAFT_TYPE_FAILURE';
export const ADD_ENGINEER_AIRCRAFT_TYPE = 'ADD_ENGINEER_AIRCRAFT_TYPE';
export const EDIT_ENGINEER_AIRCRAFT_TYPE = 'EDIT_ENGINEER_AIRCRAFT_TYPE';
export const REMOVE_ENGINEER_AIRCRAFT_TYPE = 'REMOVE_ENGINEER_AIRCRAFT_TYPE';

export function fetchAircraftTypesRequest() {
  return {
    type: FETCH_ENGINEER_AIRCRAFT_TYPE_REQUEST,
  };
}

export function fetchAircraftTypesSuccess(aircraftTypes) {
  return {
    type: FETCH_ENGINEER_AIRCRAFT_TYPE_SUCCESS,
    payload: {
      aircraftTypes,
    },
  };
}

export function fetchAircraftTypesFailure() {
  return {
    type: FETCH_ENGINEER_AIRCRAFT_TYPE_FAILURE,
  };
}

export function addAircraftTypeSuccess(aircraftType) {
  return {
    type: ADD_ENGINEER_AIRCRAFT_TYPE,
    payload: {
      aircraftType,
    },
  };
}

export function editAircraftTypeSuccess(aircraftType) {
  return {
    type: EDIT_ENGINEER_AIRCRAFT_TYPE,
    payload: {
      aircraftType,
    },
  };
}

export function removeAircraftTypeSuccess(aircraftType) {
  return {
    type: REMOVE_ENGINEER_AIRCRAFT_TYPE,
    payload: {
      aircraftType,
    },
  };
}

export function fetchAircraftTypes() {
  return (dispatch) => {
    dispatch(fetchAircraftTypesRequest());

    return axios
      .get(URLS.engineerAircraftType)
      .then(({ data }) => dispatch(fetchAircraftTypesSuccess(data.results)))
      .catch((error) => {
        dispatch(fetchAircraftTypesFailure());
        dispatch(
          addError(
            `Error during aircraft types fetching ${error.message}`,
            error,
          ),
        );
      });
  };
}

export function addAircraftType(aircraftType) {
  return (dispatch) => {
    return axios
      .post(URLS.engineerAircraftType, aircraftType)
      .then(({ data }) => dispatch(addAircraftTypeSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during aircraft type adding ${error.message}`, error),
        );
      });
  };
}

export function editAircraftType(aircraft) {
  return (dispatch) => {
    return axios
      .patch(urlJoin(URLS.engineerAircraftType, aircraft.id), aircraft)
      .then(({ data }) => dispatch(editAircraftTypeSuccess(data)))
      .catch((error) => {
        dispatch(
          addError(`Error during aircraft editing ${error.message}`, error),
        );
      });
  };
}

export function removeAircraftType(aircraftType) {
  return (dispatch) => {
    return axios
      .delete(urlJoin(URLS.engineerAircraftType, aircraftType.id))
      .then(() => {
        dispatch(removeAircraftTypeSuccess(aircraftType));
      })
      .catch((error) => {
        dispatch(
          addError(
            `Error during aircraft type removing ${error.message}`,
            error,
          ),
        );
      });
  };
}
