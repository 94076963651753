import React, { Component } from 'react';
import { attachPartFile, removePartFile } from '../../../actions/fileUpload';
import {
  editPart,
  fetchParts,
  fetchUnmountedParts,
  mountWarehousePart,
  unmountWarehousePart,
} from '../../../actions/parts';
import {
  getDateFormat,
  reformatPickerDateFromDB,
  reformatPickerDateToDB,
} from '../../../utils/time';

import Button from '@material-ui/core/Button';
import DateTimePicker from '../../../../common/components/forms/DateTimePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../../../common/components/forms/Form';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import MaterialSelect from '../../../../common/components/forms/MaterialSelect';
import PartList from './PartList';
import PropTypes from 'prop-types';
import RemoveDialogContainer from '../../../../common/components/RemoveDialogContainer';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import moment from 'moment';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  showRemoveDialog: false,
  unmountOpen: false,
  id: '',
  name: '',
  partNumber: '',
  serialNumber: '',
  manufacturer: '',
  description: '',
  hoursToService: 0,
  distanceToService: 0,
  distanceToEOL: 0,
  cyclesToService: 0,
  cyclesToEOL: 0,
  milage: 0,
  usageHours: 0,
  serviceDate: moment(),
  eolDate: moment(),
  mountingDate: moment(),
  unmountingDate: moment(),
  removeCallback: () => {},
  part: null,
  savingForm: false,
  partStatus: '',
  editLocked: true,
  changeReason: '',
};

const mapStateToProps = (state) => {
  return {
    unmountedParts: state.parts.unmountedParts,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchParts: () => dispatch(fetchParts({})),
    fetchUnmountedParts: (data) => dispatch(fetchUnmountedParts(data)),
    mountPart: (partId, warehouse) =>
      dispatch(mountWarehousePart(partId, warehouse)),
    editPart: (data) => dispatch(editPart(data)),
    unmountWarehousePart: (data) => dispatch(unmountWarehousePart(data)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    attachPartFile: (fileData, id, warehouseId) =>
      dispatch(attachPartFile(fileData, id, warehouseId)),
    removePartFile: (fileId, id) => dispatch(removePartFile(fileId, id)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(() => mapStateToProps, mapDispatchToProps)
export default class PartsManager extends Component {
  static propTypes = {
    warehouse: PropTypes.object.isRequired,
    partStatusList: PropTypes.array.isRequired,
    fetchParts: PropTypes.func.isRequired,
    fetchUnmountedParts: PropTypes.func.isRequired,
    mountPart: PropTypes.func.isRequired,
    unmountedParts: PropTypes.array.isRequired,
    mountWarehousePart: PropTypes.func.isRequired,
    editPart: PropTypes.func.isRequired,
    unmountWarehousePart: PropTypes.func.isRequired,
    attachPartFile: PropTypes.func.isRequired,
    removePartFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    warehouseSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { fetchParts } = this.props;

    this.formData = {};

    fetchParts();
  }

  handleAddOpen = () => {
    const { fetchUnmountedParts } = this.props;

    this.setState({
      addOpen: true,
    });

    fetchUnmountedParts();
  };

  openRemoveDialog = (removeCallback) => {
    this.setState({
      showRemoveDialog: true,
      removeCallback,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      showRemoveDialog: false,
      removeCallback: () => {},
    });
  };

  handleEditOpen = (part) => {
    const hoursToService = part.serviceHours ? Number(part.serviceHours) : null;
    const distanceToService = part.serviceMileage
      ? Number(part.serviceMileage)
      : null;
    const distanceToEOL = part.eolMileage ? Number(part.eolMileage) : null;
    const cyclesToService = part.serviceCycle
      ? Number(part.serviceCycle)
      : null;
    const cyclesToEOL = part.eolCycle ? Number(part.eolCycle) : null;

    this.setState({
      editOpen: true,
      part: part.id,
      id: part.id,
      name: part.name,
      partNumber: part.partNumber,
      serialNumber: part.serialNumber,
      manufacturer: part.manufacturer,
      description: part.description,
      hoursToService,
      distanceToService,
      distanceToEOL,
      cyclesToService,
      cyclesToEOL,
      usageHours: part.usageHours,
      milage: part.milage,
      serviceDate: reformatPickerDateFromDB(
        _get(part, 'serviceDate', moment()),
      ),
      eolDate: reformatPickerDateFromDB(_get(part, 'eolDate', moment())),
      mountingDate: reformatPickerDateFromDB(
        _get(part, 'mountingDate', moment()),
      ),
      partStatus: part.partStatus,
      changeReason: part.changeReason,
    });
  };

  handleUnmountOpen = (part) => {
    this.setState({
      id: part.id,
      unmountOpen: true,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  handleUnlockFields = () => {
    this.setState({
      editLocked: false,
    });
  };

  handleDatePickerChange = (name) => (date) => {
    this.setState({
      [name]: date,
    });
  };

  onChange = (formData, name) => {
    const { warehouse } = this.props;
    const { savingForm } = this.state;
    const { value } = formData[name];

    this.formData = formData;

    if (name === 'id' && !savingForm) {
      const part = _find(warehouse.parts, ['id', Number(value)]);

      if (part) {
        const hoursToService = part.serviceHours
          ? Number(part.serviceHours)
          : null;
        const distanceToService = part.serviceMileage
          ? Number(part.serviceMileage)
          : null;
        const distanceToEOL = part.eolMileage ? Number(part.eolMileage) : null;
        const cyclesToService = part.serviceCycle
          ? Number(part.serviceCycle)
          : null;
        const cyclesToEOL = part.eolCycle ? Number(part.eolCycle) : null;

        this.setState(() => ({
          [name]: value,
          id: part.id,
          name: part.name,
          partNumber: part.partNumber,
          serialNumber: part.serialNumber,
          manufacturer: part.manufacturer,
          description: part.description,
          hoursToService,
          distanceToService,
          distanceToEOL,
          cyclesToService,
          cyclesToEOL,
          usageHours: part.usageHours,
          milage: part.milage,
          serviceDate:
            reformatPickerDateFromDB(_get(part, 'serviceDate')) || moment(),
          eolDate: reformatPickerDateFromDB(_get(part, 'eolDate')) || moment(),
          partStatus: part.partStatus,
          changeReason: part.changeReason,
        }));

        return;
      }
    }

    this.setState(() => ({ [name]: value }));
  };

  mountPart = () => {
    const { warehouse, mountPart } = this.props;
    const { id } = this.state;

    mountPart(id, warehouse);
    this.handleDialogClose();
  };

  editPart = () => {
    const { warehouse, editPart } = this.props;
    const {
      id,
      name,
      partNumber,
      serialNumber,
      manufacturer,
      description,
      distanceToService,
      distanceToEOL,
      cyclesToService,
      cyclesToEOL,
      serviceDate,
      eolDate,
      mountingDate,
      hoursToService,
      partStatus,
      changeReason,
      usageHours,
      milage,
    } = this.state;

    const serviceHours = hoursToService ? Number(hoursToService) : null;
    const serviceMileage = distanceToService ? Number(distanceToService) : null;
    const eolMileage = distanceToEOL ? Number(distanceToEOL) : null;
    const serviceCycle = cyclesToService ? Number(cyclesToService) : null;
    const eolCycle = cyclesToEOL ? Number(cyclesToEOL) : null;

    const data = {
      id,
      name,
      partNumber,
      serialNumber,
      manufacturer,
      description,
      serviceHours,
      serviceMileage,
      eolMileage,
      serviceCycle,
      eolCycle,
      serviceDate: reformatPickerDateToDB(serviceDate),
      eolDate: reformatPickerDateToDB(eolDate),
      mountingDate: reformatPickerDateToDB(mountingDate || moment()),
      warehouse: warehouse,
      partStatus: partStatus,
      changeReason: changeReason,
      usageHours: Number(usageHours),
      milage: Number(milage),
    };

    editPart(data);
    this.handleDialogClose();
  };

  saveUnmountPart = () => {
    const { unmountWarehousePart, warehouse } = this.props;
    const { id, unmountingDate } = this.state;

    const part = warehouse.parts.find((part) => part.id === id);
    const data = Object.assign({}, part, {
      mountingDate: null,
      unmountingDate: reformatPickerDateToDB(unmountingDate || moment()),
      warehouse: null,
    });

    unmountWarehousePart(data);
    this.handleDialogClose();
  };

  onFormValidated = (isFormValid) => {
    const { addOpen } = this.state;

    if (isFormValid && this.formData) {
      addOpen ? this.mountPart() : this.editPart();
    }
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(
      {
        savingForm: true,
      },
      () => {
        this.triggerFormValidation();

        setImmediate(() => {
          this.setState({ savingForm: false });
        });
      },
    );
  };

  renderPartList() {
    const { warehouse, unmountedParts } = this.props;
    const { editOpen } = this.state;

    const list = editOpen
      ? warehouse.parts
      : unmountedParts.filter(({ warehouse }) => !warehouse);

    return list.map((part) => ({
      value: part.id,
      label: `${part.name} / ${part.partNumber} / ${part.serialNumber}`,
    }));
  }

  render() {
    const {
      warehouse,
      classes,
      t,
      attachPartFile,
      removePartFile,
      addSimpleError,
      editMode,
      warehouseSettings,
      partStatusList,
    } = this.props;
    const {
      addOpen,
      editOpen,
      name,
      serialNumber,
      manufacturer,
      distanceToService,
      distanceToEOL,
      serviceDate,
      eolDate,
      mountingDate,
      showRemoveDialog,
      removeCallback,
      id,
      hoursToService,
      usageHours,
      milage,
      partStatus,
      editLocked,
      changeReason,
    } = this.state;

    const editTitle = t('fleet:editPartDialogTitle') + ' - ' + name;

    return (
      <div className="part-settings">
        <h4>{t('fleet:partsSection')}</h4>
        <PartList
          warehouse={warehouse}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          handleUnmountOpen={this.handleUnmountOpen}
          onElementDelete={this.openRemoveDialog}
          attachPartFile={attachPartFile}
          removePartFile={removePartFile}
          addSimpleError={addSimpleError}
          editMode={editMode}
          warehouseSettings={warehouseSettings}
        />
        <RemoveDialogContainer
          openDialog={showRemoveDialog}
          onClose={this.closeRemoveDialog}
          removeFunction={removeCallback}
        />
        <Dialog
          PaperProps={{
            classes: { root: classes.root },
          }}
          open={addOpen || editOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="add-edit-part-type-dialog"
          maxWidth="md"
          fullWidth
        >
          <Form
            onChange={this.onChange}
            onFormValidated={this.onFormValidated}
            registerForm={this.registerForm}
          >
            <DialogTitle id="add-edit-part-type-dialog">
              {addOpen ? t('fleet:mountPartDialogTitle') : editTitle}
            </DialogTitle>
            <DialogContent className={classes.root}>
              <div className="row">
                {editOpen ? (
                  ''
                ) : (
                  <div className="col-12">
                    <MaterialSelect
                      id="id"
                      name="id"
                      label={t('input:name')}
                      className={editOpen ? 'svg-arrow-hidden' : ''}
                      defaultValue={id}
                      options={this.renderPartList()}
                      disabled={editOpen}
                      validators={[new validators.IsRequired(t)]}
                    />
                  </div>
                )}
                <div className="col-6">
                  <MaterialInput
                    label={t('input:serialNumber')}
                    name="serialNumber"
                    value={serialNumber}
                    margin="dense"
                    disabled={editLocked}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:manufacturer')}
                    name="manufacturer"
                    value={manufacturer}
                    margin="dense"
                    disabled={editLocked}
                    fullWidth
                  />
                </div>
                {/* <div className="col-12">
                  <MaterialInput
                    label={t('input:description')}
                    name="description"
                    value={description}
                    margin="dense"
                    disabled={editLocked}
                    multiline
                    fullWidth
                  />
                </div> */}
                {/* <div className="col-12">
                  <Divider />
                </div> */}
                <div className="col-6">
                  <MaterialInput
                    label={t('input:Total usage hours')}
                    name="usageHours"
                    defaultValue={usageHours}
                    stateValue={usageHours}
                    disabled={editLocked}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:Total milage (km)')}
                    name="milage"
                    defaultValue={milage}
                    stateValue={milage}
                    disabled={editLocked}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:serviceHours')}
                    name="hoursToService"
                    defaultValue={hoursToService}
                    stateValue={hoursToService}
                    disabled={editLocked}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6" />
                <div className="col-6">
                  <MaterialInput
                    label={t('input:serviceMileage')}
                    name="distanceToService"
                    defaultValue={distanceToService}
                    stateValue={distanceToService}
                    disabled={editLocked}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <MaterialInput
                    label={t('input:distanceToEOL')}
                    name="distanceToEOL"
                    defaultValue={distanceToEOL}
                    stateValue={distanceToEOL}
                    disabled={editLocked}
                    margin="dense"
                    validators={[new validators.IsNaturalNumber(t)]}
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:serviceDate')}
                    name="serviceDate"
                    value={serviceDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    disabled={editLocked}
                    handleDatePickerChange={this.handleDatePickerChange(
                      'serviceDate',
                    )}
                    dateFormat={getDateFormat()}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:eolDate')}
                    name="eolDate"
                    value={eolDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    disabled={editLocked}
                    handleDatePickerChange={this.handleDatePickerChange(
                      'eolDate',
                    )}
                    dateFormat={getDateFormat()}
                    acceptEmptyInput
                    fullWidth
                  />
                </div>
                <div className="col-6">
                  <DateTimePicker
                    label={t('input:mountingDate')}
                    name="mountingDate"
                    value={mountingDate}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    disabled={editLocked}
                    handleDatePickerChange={this.handleDatePickerChange(
                      'mountingDate',
                    )}
                    dateFormat={getDateFormat()}
                    fullWidth
                  />
                  {addOpen ? (
                    ''
                  ) : (
                    <MaterialSelect
                      id="partStatus"
                      name="partStatus"
                      label={t('input:partStatus')}
                      options={partStatusList.map((partStatus) => ({
                        value: partStatus.value,
                        label: partStatus.key,
                      }))}
                      disabled={editLocked}
                      defaultValue={partStatus}
                      validators={[new validators.IsRequired(t)]}
                    />
                  )}
                </div>
                {editLocked ? (
                  ''
                ) : (
                  <div className="col-6">
                    <MaterialInput
                      label={t('input:Change reason')}
                      name="changeReason"
                      defaultValue={changeReason}
                      stateValue={changeReason}
                      margin="dense"
                      validators={[new validators.IsRequired(t)]}
                      fullWidth
                    />
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              {addOpen ? (
                ''
              ) : (
                <Button color="secondary" onClick={this.handleUnlockFields}>
                  {t('Unlock')}
                </Button>
              )}
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </div>
    );
  }
}
