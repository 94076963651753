import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Confirm from '../../images/confirm.png';

@withNamespaces()
export default class Confirmation extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    closeConfirmationDialog: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { message, closeConfirmationDialog, t } = this.props;

    return (
      <div>
        <DialogContent className="pb-0">
          <div className="row justify-content-center">
            <img src={Confirm} />
          </div>
          <div className="my-4 row justify-content-center">
            <h4>{message}</h4>
          </div>
        </DialogContent>
        <DialogActions className="row justify-content-center">
          <Button color="primary" variant="raised" onClick={closeConfirmationDialog}>{t('buttonOk')}</Button>
        </DialogActions>
      </div>
    );
  }
}
