import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { reformatPickerDateFromDB } from '../../../../utils/time';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  root: {
    width: '300px'
  }
});

@withNamespaces()
class UserExperience extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    practicalData: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { classes, practicalData, t } = this.props;

    return (
      <div className="col-12 align-items-center">
        <div className="py-2">
          <h4>{t('settings:experience')}</h4>
        </div>
        <div className="pl-3 pb-5">
          <div className="row">
            <div className="col-auto">
              <TextField
                className={classes.root}
                disabled
                name="hoursOfOperation"
                label={t('input:hoursOfOperations')}
                value={practicalData.hoursOfOperation ? practicalData.hoursOfOperation : 0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-auto">
              <TextField
                className={classes.root}
                disabled
                name="lastDayOfOperation"
                label={t('input:lastDayOfOperations')}
                value={practicalData.lastDayOfOperation ? reformatPickerDateFromDB(practicalData.lastDayOfOperation, false) : ' '}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <TextField
                className={classes.root}
                disabled
                name="numberOfOperation"
                label={t('input:numberOfOperations')}
                value={practicalData.numberOfOperation ? practicalData.numberOfOperation : 0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-auto">
              <TextField
                className={classes.root}
                disabled
                name="numberOfOperationFromLast6Months"
                label={t('input:numberOfOperationsFromLast6Months')}
                value={practicalData.numberOfOperationFromLast6Months ? practicalData.numberOfOperationFromLast6Months : 0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UserExperience);
