import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CustomFilter from './CustomFilter';
import DateTimePicker from '../components/forms/DateTimePicker';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from '../components/Loader';
import PropTypes from 'prop-types';
import Table from '../components/Table';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    overflowY: 'visible',
  },
});

const INITIAL_STATE = {
  searchSettings: {
    sortAsc: true,
    columnToSort: '',
    search: '',
    filter: [],
    filterIsUsed: false,
  },
  checkboxScheduleTime: false,
  users: [],
  isActive: true,
  scheduleTime: moment(),
};

const tableHeader = [
  {
    prop: 'id',
    sort: true,
    profileView: false,
  },
  {
    prop: 'firstName',
    sort: true,
    profileView: false,
  },
  {
    prop: 'lastName',
    sort: true,
    profileView: false,
  },
  {
    prop: 'parsedSchoolGroupSet',
    sort: false,
    profileView: false,
  },
  {
    prop: 'assignCheckbox',
    sort: false,
    profileView: false,
    isNumeric: true,
  },
];

@withNamespaces()
@withStyles(styles)
export default class AssignView extends Component {
  static propTypes = {
    userList: PropTypes.array,
    groups: PropTypes.array,
    fetchUsers: PropTypes.func.isRequired,
    serializeAndSendData: PropTypes.func.isRequired,
    getDateFormat: PropTypes.func.isRequired,
    reformatPickerDateFromDB: PropTypes.func.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    getUsersSuccess: PropTypes.bool.isRequired,
    handleAssignViewClose: PropTypes.func.isRequired,
    getUsersRequest: PropTypes.bool.isRequired,
    serializeData: PropTypes.func.isRequired,
    viewLabel: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userList: [],
    groups: [],
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const searchData = {
      ...this.state.searchSettings,
    };

    this.props.fetchUsers(searchData);
    this.props.fetchGroups();
  }

  handleSort = (columnName) => {
    const { sortAsc, search, filter, filterIsUsed } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
      filter,
      filterIsUsed,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchUsers(searchData);
  };

  handleFilter = (values) => {
    const { columnToSort, sortAsc, search } = this.state.searchSettings;

    const filterData = {
      columnToSort,
      sortAsc,
      filter: values,
      search,
      filterIsUsed: !_isEmpty(values),
    };

    this.setState({
      searchSettings: filterData,
    });

    this.props.fetchUsers(filterData);
  };

  getUserFilters = () => {
    const { groups } = this.props;
    const { searchSettings } = this.state;

    return (
      <CustomFilter
        groups={groups}
        roles={[]}
        isFilterUsed={searchSettings.filterIsUsed}
        handleFilter={this.handleFilter}
        filterSettings={searchSettings.filter}
      />
    );
  };

  isChecked(name) {
    return !!this.state[name];
  }

  handleCheckedChange = (name) => (event) => {
    let newUsers;

    const { users } = this.state;
    const parsedId = parseInt(event.target.id, 10);
    const isChoosen = users.indexOf(parseInt(event.target.id, 10)) > -1;

    if (!isChoosen) {
      newUsers = [...users, parsedId];
    } else {
      newUsers = _filter(users, (user) => {
        return user !== parsedId;
      });
    }

    this.setState({
      users: newUsers,
      [name]: event.target.checked,
    });
  };

  handleCheckedSchedule = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleSearch = (search) => {
    const { columnToSort, sortAsc, filter, filterIsUsed } =
      this.state.searchSettings;
    const searchData = {
      columnToSort,
      sortAsc,
      filter,
      search,
      filterIsUsed,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchUsers(searchData);
  };

  handleSaveAsInactive = () => {
    const { users, scheduleTime } = this.state;

    this.setState({
      isActive: false,
    });

    this.props.serializeAndSendData(users, scheduleTime, false);
  };

  handleAssign = () => {
    const { users, scheduleTime } = this.state;

    this.setState({
      isActive: true,
    });

    this.props.serializeAndSendData(users, scheduleTime, true);
  };

  parseSchoolgroupSet(user) {
    const groups = _isObject(user.schoolgroupSet)
      ? _reduce(
          user.schoolgroupSet,
          (memo, schoolGroup) => {
            return `${memo}${schoolGroup.name}, `;
          },
          '',
        )
      : user.schoolgroupSet;

    return groups.substring(0, groups.length - 2);
  }

  renderCheckbox(user) {
    return (
      <Checkbox
        color="primary"
        checked={this.isChecked(`checkbox-${user.id}`)}
        onChange={this.handleCheckedChange(`checkbox-${user.id}`)}
        value={`checkbox-${user.id}`}
        id={user.id.toString()}
      />
    );
  }

  getDataFromUserList(userList) {
    const parsedUser = userList.map((user) => ({
      ...user,
      parsedSchoolGroupSet: this.parseSchoolgroupSet(user),
      assignCheckbox: this.renderCheckbox(user),
    }));

    return parsedUser;
  }

  handleDatePickerChange = (date) => {
    this.setState({
      scheduleTime: date,
    });
  };

  render() {
    const {
      handleAssignViewClose,
      getUsersRequest,
      userList,
      viewLabel,
      t,
      reformatPickerDateFromDB,
      getDateFormat,
    } = this.props;
    const { searchSettings, checkboxScheduleTime, scheduleTime } = this.state;

    if (getUsersRequest) {
      return <Loader />;
    }

    return (
      <div>
        <DialogTitle id="assign-course">{viewLabel}</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <Table
                data={this.getDataFromUserList(userList)}
                header={tableHeader}
                handleSort={this.handleSort}
                sortAsc={searchSettings.sortAsc}
                columnToSort={searchSettings.columnToSort}
                handleSearch={this.handleSearch}
                search={searchSettings.search}
                getUserFilters={this.getUserFilters}
                searchLabel={t('input:searchLabel')}
              />
            </div>
            <div className="col-12">
              <div className="align-items-end row mt-2">
                <div className="col-auto">
                  <Checkbox
                    color="primary"
                    checked={this.isChecked('checkboxScheduleTime')}
                    onChange={this.handleCheckedSchedule(
                      'checkboxScheduleTime',
                    )}
                    value="checkboxScheduleTime"
                  />
                </div>
                <div className="col-auto">
                  <DateTimePicker
                    disabled={!checkboxScheduleTime}
                    value={reformatPickerDateFromDB(scheduleTime)}
                    showMonthDropdown
                    showTimeSelect={false}
                    showYearDropdown
                    handleDatePickerChange={this.handleDatePickerChange}
                    dateFormat={getDateFormat()}
                    name="assignScheduleTime"
                    label={t('table:scheduleTime')}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleAssignViewClose}>
            {t('buttonCancel')}
          </Button>
          <Button color="primary" onClick={this.handleSaveAsInactive}>
            {t('buttonAssignAsInactive')}
          </Button>
          <Button color="primary" onClick={this.handleAssign}>
            {t('buttonAssign')}
          </Button>
        </DialogActions>
      </div>
    );
  }
}
