import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

import ExamOrCourseCompetenciesList from './ExamOrCourseCompetenciesList';
import AssignCompetencyToExamOrCourse from '../../../rail/containers/AssignCompetencyToExamOrCourse/index';

const styles = () => ({
  root: {
    overflowY: 'visible',
  }
});

const INITIAL_STATE = {
  manageOpen: false,
  competency: null,
  number: null,
  period: null,
  id: null,
  isFormValid: false,
};

@withNamespaces()
@withStyles(styles)
export default class ExamOrCourseCompetencies extends Component {
 static propTypes = {
   editMode: PropTypes.bool,
   preview: PropTypes.bool.isRequired,
   saveCompetenciesList: PropTypes.func.isRequired,
   classes: PropTypes.object.isRequired,
   onElementDelete: PropTypes.func.isRequired,
   examOrCourseCompetenciesList: PropTypes.array.isRequired,
   addSimpleError: PropTypes.func.isRequired,
   t: PropTypes.func.isRequired,
 };

  state = {
    ...INITIAL_STATE
  };

  handleManageOpen = () => {
    this.setState({
      manageOpen: true
    });
  };

  handleManageClose = () => {
    this.setState({
      manageOpen: false,
    });
  };

  render() {
    const { examOrCourseCompetenciesList, preview, editMode, saveCompetenciesList, addSimpleError, t,
    } = this.props;
    const { manageOpen } = this.state;

    return (
      <div className="col-12">
        <ExamOrCourseCompetenciesList
          examOrCourseCompetenciesList={examOrCourseCompetenciesList}
          handleManageOpen={this.handleManageOpen}
          editMode={editMode}
          preview={preview}
          addSimpleError={addSimpleError}
        />
        <div className="col-12">
          <Dialog
            open={manageOpen}
            fullWidth
            maxWidth="md"
          >
            <AssignCompetencyToExamOrCourse
              examOrCourseCompetenciesList={examOrCourseCompetenciesList}
              editMode={editMode}
              handleManageClose={this.handleManageClose}
              viewLabel={t('test:manageCompetencyDialogTitle')}
              saveCompetenciesList={saveCompetenciesList}
            />
          </Dialog>
        </div>
      </div>
    );
  }
}
