import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import Loader from '../../../../../common/components/Loader';
import StaffCategoriesList from './StaffCategoriesList';
import { fetchUserCompetence } from '../../../../actions/userCompetencies';
import { addSimpleError } from '../../../../actions/errors';
import { fetchStaffCategories } from '../../../../actions/staffCategories';

const mapStateToProps = (state) => {
  return {
    userCompetenciesList: state.userCompetencies.userCompetenceList,
    userCompetenciesRequest: state.userCompetencies.userCompetenciesRequest,
    staffCategoriesList: state.staffCategories.staffCategoryList,
    staffCategoriesRequest: state.staffCategories.staffCategoryRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchStaffCategories: (searchData) => dispatch(fetchStaffCategories(searchData)),
    fetchUserCompetence: (userId) => dispatch(fetchUserCompetence(userId)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class StaffCategories extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    addSimpleError: PropTypes.func,
    fetchStaffCategories: PropTypes.func.isRequired,
    fetchUserCompetence: PropTypes.func.isRequired,
    staffCategoriesRequest: PropTypes.bool.isRequired,
    userCompetenciesRequest: PropTypes.bool.isRequired,
    staffCategoriesList: PropTypes.array,
    userCompetenciesList: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    search: '',
  };

  componentDidMount() {
    const { userId, fetchUserCompetence, fetchStaffCategories } = this.props;

    fetchStaffCategories();
    fetchUserCompetence({ userId });
  }

  handleSearchInStaffCategories = (searchData) => {
    this.setState({
      search: searchData
    });

    this.props.fetchStaffCategories({ search: searchData });
  };

  renderButtons() {
    const { userId, t } = this.props;
    const pathname = `/users/${userId}`;

    return (
      <div className="justify-content-center row">
        <Link to={pathname}>
          <Button variant="raised">
            {t('buttonCancel')}
          </Button>
        </Link>
      </div>
    );
  }

  render() {
    const { staffCategoriesList, userCompetenciesList, staffCategoriesRequest, userCompetenciesRequest } = this.props;
    const { search } = this.state;

    if (staffCategoriesRequest || userCompetenciesRequest) {
      return <Loader />;
    }

    return (
      <div className="col-12">
        <StaffCategoriesList
          search={search}
          staffCategoriesList={staffCategoriesList}
          staffCategoriesRequest={staffCategoriesRequest}
          userCompetenciesList={userCompetenciesList}
          handleSearchInStaffCategories={this.handleSearchInStaffCategories}
        />
        {this.renderButtons()}
      </div>
    );
  }
}
