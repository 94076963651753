import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { checkExpiredDate, checkExpiryDate } from '../../utils/time';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  paper: {
    minWidth: 50,
  },
  table: {
    minWidth: 300,
  },
  longCell: {
    minWidth: 80,
  },
  header: {
    background: 'linear-gradient(#206B00, #76c078)',
  },
  iconColor: {
    color: '#206B00'
  }
});

@withNamespaces()
@withStyles(styles)
export default class EquipmentList extends Component {
  static propTypes = {
    equipmentList: PropTypes.array,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  getClass = (expiryDate) => {
    let expiryClass = 'ok-date';

    if (expiryDate) {
      if (checkExpiredDate(expiryDate)) {
        expiryClass = 'expired-date';
      } else if (checkExpiryDate(expiryDate)) {
        expiryClass = 'expiry-date';
      }
    }

    return expiryClass;
  };

  getBackgroundColor = (expiryDate) => {
    const expiryClass = this.getClass(expiryDate);

    return <div className={`equipment status-circle ${expiryClass}`} />;
  };

  renderList() {
    const { equipmentList, classes } = this.props;

    return equipmentList.map((content) => {
      return (
        <TableRow key={`equipmentList-${content.id}`}>
          <TableCell>{this.getBackgroundColor(content.expiryDate)}</TableCell>
          <TableCell className={classNames(classes.longCell, 'pl-3')}>{content.name}</TableCell>
          <TableCell className={classes.longCell}>{content.type}</TableCell>
          <TableCell className={classes.longCell}>{content.expiryDate}</TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { equipmentList, classes, t } = this.props;

    return (
      <Paper className={classNames(classes.paper, 'mx-2 my-3')}>
        <header className="header-dashboard">
          <span className={classNames('span-dashboard', 'py-3')}>
            <Icon className={classNames(classes.iconColor, 'notification-icon')}>
                category
            </Icon>
            <h4 className="h4-dashboard">{t('dashboard:equipments')}</h4>
            <Link to="/equipment">
              <Icon className={classNames(classes.iconColor, 'notification-icon')}>
              keyboard_arrow_right
              </Icon>
            </Link>
          </span>
        </header>
        <div className={classNames(classes.root, 'px-2')}>
          {!_isEmpty(equipmentList) && <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header} />
                <TableCell className={classNames(classes.header, 'pl-3')}>{t('table:name')}</TableCell>
                <TableCell className={classes.header}>{t('table:type')}</TableCell>
                <TableCell className={classes.header}>{t('table:expiryDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderList()}
            </TableBody>
          </Table>
          }
        </div>
      </Paper>
    );
  }
}
