import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 120
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    marginBottom: 6,
  },
  chip: {
    margin: `${theme.spacing.unit / 4}px ${theme.spacing.unit / 4}px`,
  },
  cancelIcon: {
    width: '1em',
    opacity: 0.42,
  },
  noCancelIcon: {
    margin: 0,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
});

@withNamespaces()
@withStyles(styles)
export default class IntegrationReactSelect extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    collectionList: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]).isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    collection: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    preview: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  noOptionsMessage = (props) => {
    const { t } = this.props;

    return (
      <div
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {t('noSelectResults')}
      </div>
    );
  };

  inputComponent = ({ inputRef, ...props }) => {
    return <div ref={inputRef} {...props} />;
  };

  control = (props) => {
    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent: this.inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    );
  };

  option = (props) => {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        component="div"
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  };

  placeholder = (props) => {
    return (
      <div
        color="textSecondary"
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
      >
        {props.children}
      </div>
    );
  };

  singleValue = (props) => {
    return (
      <div
        className={props.selectProps.classes.singleValue}
        {...props.innerProps}
      >
        {props.children}
      </div>
    );
  };

  valueContainer = (props) => {
    return (
      <div className={props.selectProps.classes.valueContainer}>
        {props.children}
      </div>
    );
  };

  renderDeleteIcon = (props) => {
    if (props.isDisabled) {
      return <div className={props.selectProps.classes.noCancelIcon} />;
    }

    return (
      <IconButton className={props.selectProps.classes.cancelIcon} {...props.removeProps} >
        <Icon color="primary">cancel</Icon>
      </IconButton>
    );
  };

  multiValue = (props) => {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={this.renderDeleteIcon(props)}
      />
    );
  };

  menu = (props) => {
    return (
      <Paper
        square
        className={props.selectProps.classes.paper}
        {...props.innerProps}
      >
        {props.children}
      </Paper>
    );
  };

  render() {
    const { classes, collectionList, handleSelectChange, collection, placeholder, preview } = this.props;

    const components = {
      Control: this.control,
      Menu: this.menu,
      MultiValue: this.multiValue,
      NoOptionsMessage: this.noOptionsMessage,
      Option: this.option,
      Placeholder: this.placeholder,
      SingleValue: this.singleValue,
      ValueContainer: this.valueContainer,
    };

    if (_isEmpty(collectionList)) {
      return null;
    }

    const suggestions = collectionList.map((itemType) => ({
      value: itemType.id,
      label: itemType.name,
    }));

    return (
      <div className={classes.root}>
        <Select
          options={suggestions}
          components={components}
          classes={classes}
          value={collection}
          onChange={handleSelectChange('collection')}
          placeholder={placeholder}
          isMulti
          isSearchable
          isClearable={false}
          isDisabled={preview}
        />
      </div>
    );
  }
}
