import axios from 'axios';

import { addError } from './errors';
import { urlJoin } from '../../utils';
import { URLS } from '../../constants';

export const FETCH_DEFAULT_NOTIFICATION_RECIPIENTS_REQUEST = 'FETCH_DEFAULT_NOTIFICATION_RECIPIENTS_REQUEST';
export const FETCH_DEFAULT_NOTIFICATION_RECIPIENTS = 'FETCH_DEFAULT_NOTIFICATION_RECIPIENTS';
export const ADD_DEFAULT_NOTIFICATION_RECIPIENT = 'ADD_DEFAULT_NOTIFICATION_RECIPIENT';
export const REMOVE_DEFAULT_NOTIFICATION_RECIPIENT = 'REMOVE_DEFAULT_NOTIFICATION_RECIPIENT';
export const EDIT_DEFAULT_NOTIFICATION_RECIPIENT = 'EDIT_DEFAULT_NOTIFICATION_RECIPIENT';


export function fetchDefaultNotificationRecipientsRequest() {
  return {
    type: FETCH_DEFAULT_NOTIFICATION_RECIPIENTS_REQUEST
  };
}

export function fetchDefaultNotificationRecipientsSuccess(defaultNotificationRecipients) {
  return {
    type: FETCH_DEFAULT_NOTIFICATION_RECIPIENTS,
    payload: {
      defaultNotificationRecipients
    }
  };
}

export function addDefaultNotificationRecipientSuccess(defaultNotificationRecipient) {
  return {
    type: ADD_DEFAULT_NOTIFICATION_RECIPIENT,
    payload: {
      defaultNotificationRecipient
    }
  };
}

export function removeDefaultNotificationRecipientSuccess(defaultNotificationRecipient) {
  return {
    type: REMOVE_DEFAULT_NOTIFICATION_RECIPIENT,
    payload: {
      defaultNotificationRecipient
    }
  };
}

export function editDefaultNotificationRecipientSuccess(defaultNotificationRecipient) {
  return {
    type: EDIT_DEFAULT_NOTIFICATION_RECIPIENT,
    payload: {
      defaultNotificationRecipient
    }
  };
}

export function addDefaultNotificationRecipient(defaultNotificationRecipient) {
  return (dispatch) => {
    return axios.post(URLS.defaultNotificationRecipients, defaultNotificationRecipient)
      .then(({ data }) => dispatch(addDefaultNotificationRecipientSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during default notification recipients adding ${error.message}`, error));
      });
  };
}

export function removeDefaultNotificationRecipient(defaultNotificationRecipient) {
  return (dispatch) => {
    return axios.delete(urlJoin(URLS.defaultNotificationRecipients, defaultNotificationRecipient.id))
      .then(() => dispatch(removeDefaultNotificationRecipientSuccess(defaultNotificationRecipient)))
      .catch((error) => {
        dispatch(addError(`Error during default notification recipients removing ${error.message}`, error));
      });
  };
}

export function editDefaultNotificationRecipient(defaultNotificationRecipient) {
  return (dispatch) => {
    return axios.patch(urlJoin(URLS.defaultNotificationRecipients, defaultNotificationRecipient.id), defaultNotificationRecipient)
      .then(({ data }) => dispatch(editDefaultNotificationRecipientSuccess(data)))
      .catch((error) => {
        dispatch(addError(`Error during default notification recipients editing ${error.message}`, error));
      });
  };
}

export function fetchDefaultNotificationRecipients() {
  return (dispatch) => {
    dispatch(fetchDefaultNotificationRecipientsRequest());

    return axios.get(urlJoin(URLS.defaultNotificationRecipients))
      .then(({ data }) => dispatch(fetchDefaultNotificationRecipientsSuccess(data.results)))
      .catch((error) => {
        dispatch(addError(`Error during default notification recipients fetching ${error.message}`, error));
      });
  };
}
