import { Link } from 'react-router-dom';
import { MODULES } from '../../../../constants/modules';
import Menu from './Menu';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../../constants/permissions';
import React from 'react';
import { renderModule } from '../../../decorators/modules';
import { renderPermission } from '../../../decorators/permissions';
import { withNamespaces } from 'react-i18next';

@withNamespaces()
export default class FleetMenu extends Menu {
  renderHeader() {
    const { t } = this.props;

    return <>{t('navigation:fleetMenu')}</>;
  }

  renderDropdownMenu() {
    const { t } = this.props;
    const { open } = this.state;
    const isOpen = open ? 'open' : '';

    return (
      <div className={`dropdown ${isOpen}`}>
        <Link
          to="/fleet"
          className="nav-link-item"
          onClick={() => this.closeMenu()}
        >
          {t('navigation:fleetLink')}
        </Link>
        <Link
          to="/parts"
          className="nav-link-item"
          onClick={() => this.closeMenu()}
        >
          {t('navigation:partsLink')}
        </Link>
        <Link
          to="/warehouse"
          className="nav-link-item"
          onClick={() => this.closeMenu()}
        >
          {t('warehouse:warehouseLink')}
        </Link>
      </div>
    );
  }

  @renderModule(MODULES.fleet)
  @renderPermission(PERMISSIONS.viewFleets)
  render() {
    return this.renderMenu();
  }
}
