import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CourseList from '../Training/CourseList/CoursesList';
import { PERMISSIONS } from '../../../constants/permissions';
import { componentPermission } from '../../decorators/permissions';
import userService from '../../../utils/userService';


@componentPermission(PERMISSIONS.myCoursesView)
export default class MyCourses extends Component {
    static propTypes = {
      userId: PropTypes.string,
      isUserProfile: PropTypes.bool,
    };

    render() {
      const id = this.props.userId || userService.getCurrentUser().id;
      const { isUserProfile } = this.props;

      return (
        <CourseList userId={Number(id)} isUserProfile={isUserProfile} />
      );
    }
}


