import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

import { ImageInputWithoutStyles } from './ImageInput';

const styles = ({
  fileInput: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    height: '100%',
    width: '500%'
  }
});

@withNamespaces()
@withStyles(styles)
export default class ImageInput extends ImageInputWithoutStyles {
  isDimentionCorrect(file) {
    const { addSimpleError, customWidth, customHeight, errorText } = this.props;
    const { createObjectURL } = window.URL;

    // eslint-disable-next-line promise/avoid-new
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve(false);

        return;
      }

      let image = new Image();

      image.onload = function() {
        const { width, height } = this;
        const isDimentionCorrect = (width === customWidth && height === customHeight);

        if (!isDimentionCorrect) {
          addSimpleError(errorText);
        }

        image = null;

        resolve(isDimentionCorrect);
      };

      image.onerror = () => {
        image = null;
        reject();
      };
      image.src = createObjectURL(file);
    });
  }

  getIncorrectFileFormatError() {
    const { t } = this.props;

    return t('error:incorrectFileFormat');
  }

  getFileSizeToBigError(megaBYTESSize) {
    const { t } = this.props;

    return `${t('error:fileSizeToBig')} ${megaBYTESSize} MB`;
  }
}
