import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import './styles.scss';


@withNamespaces()
export default class NotFound extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;

    return (
      <div className="text-center">
        <div className="error-header">{t('error:404')}</div>
        <h4>{t('error:404ErrorHeader')}</h4>
        <h4>{t('error:404ErrorContent')}</h4>
      </div>
    );
  }
}
