import React, { Component } from 'react';
import { fetchParts, removePart } from '../../../actions/parts';

import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import PartList from './PartList';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { reformatPickerDateFromDB } from '../../../utils/time';

// import { isVerified } from '../../Users/PlannedAction/plannedActionHelper';

const mapStateToProps = (state) => {
  return {
    partList: state.parts.partList,
    partsPaginationRequest: state.parts.partsPaginationRequest,
    partsRequest: state.parts.partsRequest,
    partListPaginationNext: state.parts.partListPaginationNext,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    fetchParts: (sortingData, paginationParams) =>
      dispatch(fetchParts(sortingData, paginationParams)),
    removePart: (part) => dispatch(removePart(part)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossPartListView extends Component {
  static propTypes = {
    fetchParts: PropTypes.func.isRequired,
    removePart: PropTypes.func.isRequired,
    partList: PropTypes.array.isRequired,
    partsRequest: PropTypes.bool.isRequired,
    partsPaginationRequest: PropTypes.bool.isRequired,
    partListPaginationNext: PropTypes.string.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: '',
    },
    partToRemove: null,
    removeDialogOpen: false,
  };

  componentDidMount() {
    const { fetchParts } = this.props;
    const { searchSettings } = this.state;

    fetchParts(searchSettings);
  }

  onFetchNextPartList = () => {
    const { fetchParts, partListPaginationNext } = this.props;
    const { searchSettings } = this.state;

    fetchParts(searchSettings, partListPaginationNext);
  };

  handleSort = (columnName) => {
    const { search, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchParts(searchData);
  };

  handleSearch = (search) => {
    const { columnToSort, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort,
      sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData,
    });

    this.props.fetchParts(searchData);
  };

  removePart = () => {
    const { partToRemove } = this.state;

    this.props.removePart(partToRemove);
  };

  openRemoveDialog = (part) => {
    this.setState({
      removeDialogOpen: true,
      partToRemove: part,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      partToRemove: null,
    });
  };

  renderDeleteIcon(part) {
    return (
      <IconButton key="remove-icon" onClick={() => this.openRemoveDialog(part)}>
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  renderEditIcon(partId) {
    return (
      <Link key="part-button-edit" to={`/parts/edit/${partId}`}>
        <IconButton>
          <Icon color="primary">mode_edit</Icon>
        </IconButton>
      </Link>
    );
  }

  renderIcons(part) {
    const partId = part.id;

    return [this.renderEditIcon(partId), this.renderDeleteIcon(part)];
  }

  renderPartDetailsLink(id, prop) {
    return (
      <Link className="table-row-link" to={`/parts/details/${id}`}>
        {prop}
      </Link>
    );
  }
  partStatus;

  getRemovalDate(isInstalled, uninstallations) {
    if (isInstalled) {
      return '';
    }

    if (uninstallations.length > 0) {
      uninstallations = _orderBy(uninstallations, 'unmountingDate', 'desc');
    }

    return uninstallations.length > 0
      ? reformatPickerDateFromDB(uninstallations[0].unmountingDate, false)
      : '';
  }

  getLocation(installation, part) {
    if (_isEmpty(installation)) {
      return part.warehouse ? part.warehouse.name : '';
    }

    const { name, evn } = installation.fleet.vehicle;
    const vehicle = `${name} / ${evn}`;

    return vehicle;
  }

  renderFiles(part) {
    const partId = part.id;

    return part.files.map((file) => {
      return (
        <Chip
          key={`part-${partId}-file-${file.id}`}
          label={file.name}
          onClick={() => window.open(file.path, '_blank')}
          className="mx-1"
        />
      );
    });
  }

  managePartList(partList) {
    let parsedPartList = [];

    if (_isEmpty(partList)) {
      return parsedPartList;
    }

    parsedPartList = partList.map((part) => {
      const isInstalled = part.installations.some(
        (installation) => installation.isActive,
      );
      const installation =
        part.installations.find((installation) => installation.isActive) || {};
      const uninstallations = part.installations.filter(
        (installation) => !installation.isActive,
      );

      return {
        id: part.id,
        name: this.renderPartDetailsLink(part.id, part.name),
        partNumber: this.renderPartDetailsLink(part.id, part.partNumber),
        serialNumber: this.renderPartDetailsLink(part.id, part.serialNumber),
        status: this.renderPartDetailsLink(part.id, part.partStatus),
        location: this.renderPartDetailsLink(
          part.id,
          this.getLocation(installation, part),
        ),
        // isInstalled: this.renderPartDetailsLink(part.id, isVerified(isInstalled)),
        installationDate: this.renderPartDetailsLink(
          part.id,
          reformatPickerDateFromDB(installation.mountingDate, false),
        ),
        removalDate: this.renderPartDetailsLink(
          part.id,
          this.getRemovalDate(isInstalled, uninstallations),
        ),
        attachments: this.renderFiles(part),
        icons: this.renderIcons(part),
      };
    });

    return parsedPartList;
  }

  render() {
    const {
      partList,
      partsPaginationRequest,
      partListPaginationNext,
      partsRequest,
    } = this.props;
    const { removeDialogOpen } = this.state;
    const { search, columnToSort, sortAsc } = this.state.searchSettings;

    if (partsRequest) {
      return <Loader />;
    }

    return (
      <>
        <PartList
          partList={this.managePartList(partList)}
          originPartList={partList}
          openRemoveDialog={this.openRemoveDialog}
          closeRemoveDialog={this.closeRemoveDialog}
          handleSort={this.handleSort}
          handleSearch={this.handleSearch}
          removePart={this.removePart}
          removeDialogOpen={removeDialogOpen}
          search={search}
          columnToSort={columnToSort}
          sortAsc={sortAsc}
        />
        <InfiniteScroll
          loadMore={this.onFetchNextPartList}
          hasMore={!partsPaginationRequest && partListPaginationNext}
          loader={<>Loading...</>}
        />
      </>
    );
  }
}
