import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _isEmpty from 'lodash/isEmpty';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';

import EquipmentList from '../../../../common/containers/Equipment/EquipmentList';
import Loader from '../../../../common/components/Loader';
import { fetchEquipments, removeEquipment } from '../../../actions/equipment';
import { reformatPickerDateFromDB, checkExpiredDate, checkExpiryDate } from '../../../utils/time';


const mapStateToProps = (state) => {
  return {
    equipmentList: state.equipment.equipmentList,
    equipmentRequest: state.equipment.equipmentRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchEquipments: (sortingData) => dispatch(fetchEquipments(sortingData)),
    removeEquipment: (id) => dispatch(removeEquipment(id)),
  };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class CrossEquipmentListView extends Component {
  static propTypes = {
    fetchEquipments: PropTypes.func.isRequired,
    removeEquipment: PropTypes.func.isRequired,
    equipmentList: PropTypes.array.isRequired,
    equipmentRequest: PropTypes.bool.isRequired
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
      search: ''
    },
    removeDialogOpen: false,
    equipmentToRemove: null,
  };

  componentDidMount() {
    const { fetchEquipments } = this.props;
    const { searchSettings } = this.state;

    fetchEquipments(searchSettings);
  }

  handleSort = (columnName) => {
    const { search, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort: columnName,
      sortAsc: !sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchEquipments(searchData);
  };

  handleSearch = (search) => {
    const { columnToSort, sortAsc } = this.state.searchSettings;

    const searchData = {
      columnToSort,
      sortAsc,
      search,
    };

    this.setState({
      searchSettings: searchData
    });

    this.props.fetchEquipments(searchData);
  };

  removeEquipment = () => {
    const { equipmentToRemove } = this.state;

    this.props.removeEquipment(equipmentToRemove);
  };

  openRemoveDialog = (equipmentId) => {
    this.setState({
      removeDialogOpen: true,
      equipmentToRemove: equipmentId,
    });
  };

  closeRemoveDialog = () => {
    this.setState({
      removeDialogOpen: false,
      equipmentToRemove: null,
    });
  };

  renderDeleteIcon(equipmentId) {
    return (
      <IconButton
        key="remove-icon"
        onClick={() => this.openRemoveDialog(equipmentId)}
      >
        <Icon color="primary">delete</Icon>
      </IconButton>
    );
  }

  renderEditIcon(equipmentId) {
    return (
      <Link key="equipment-button-edit" to={`/equipment/edit/${equipmentId}`}>
        <IconButton>
          <Icon color="primary">
            mode_edit
          </Icon>
        </IconButton>
      </Link>
    );
  }

  renderIcons(equipment) {
    const equipmentId = equipment.id;

    return [
      this.renderEditIcon(equipmentId),
      this.renderDeleteIcon(equipmentId)
    ];
  }

  renderEquipmentDetailsLink(id, prop) {
    return (
      <Link className="table-row-link" to={`/equipment/details/${id}`}>{prop}</Link>
    );
  }

  renderFiles(files) {
    return files && files.map((file) => {
      return (
        <Chip
          key={`equipment-file-${file.id}`}
          label={file.name}
          onClick={() => window.open(file.path, '_blank')}
          className="my-1 mx-2"
        />
      );
    });
  }

  getEquipmentColor = (expiryDate) => {
    const expiryDateString = reformatPickerDateFromDB(expiryDate, false);

    if (expiryDate) {
      if (checkExpiredDate(expiryDate)) {
        return <span className="expired-date">{expiryDateString}</span>;
      } else if (checkExpiryDate(expiryDate)) {
        return <span className="expiry-date">{expiryDateString}</span>;
      }
    }

    return <span className="ok-date">{expiryDateString}</span>;
  };

  getClass = (expiryDate) => {
    let expiryClass = 'ok-date';

    if (expiryDate) {
      if (checkExpiredDate(expiryDate)) {
        expiryClass = 'expired-date';
      } else if (checkExpiryDate(expiryDate)) {
        expiryClass = 'expiry-date';
      }
    }

    return expiryClass;
  };

  getEquipmentColor = (expiryDate) => {
    const expiryDateString = reformatPickerDateFromDB(expiryDate, false);
    const expiryClass = this.getClass(expiryDate);

    return <span className={`equipment ${expiryClass}`}>{expiryDateString}</span>;
  };

  getBackgroundColor = (expiryDate) => {
    const expiryClass = this.getClass(expiryDate);

    return <div className={`equipment status-circle ${expiryClass}`} />;
  };

  manageEquipmentList(equipmentList) {
    let parsedEquipmentList = [];

    if (_isEmpty(equipmentList)) {
      return parsedEquipmentList;
    }

    parsedEquipmentList = equipmentList.map((equipment) => ({
      color: this.getBackgroundColor(equipment.expiryDate),
      name: this.renderEquipmentDetailsLink(equipment.id, equipment.name),
      type: this.renderEquipmentDetailsLink(equipment.id, equipment.type),
      location: this.renderEquipmentDetailsLink(equipment.id, equipment.location),
      certificate: this.renderEquipmentDetailsLink(equipment.id, equipment.certificate),
      issueDate: this.renderEquipmentDetailsLink(equipment.id, reformatPickerDateFromDB(equipment.issueDate, false)),
      expiryDate: this.renderEquipmentDetailsLink(equipment.id, this.getEquipmentColor(equipment.expiryDate)),
      files: this.renderFiles(equipment.files),
      icons: this.renderIcons(equipment)
    }));

    return parsedEquipmentList;
  }

  render() {
    const { equipmentList, equipmentRequest } = this.props;
    const { removeDialogOpen } = this.state;
    const { search, columnToSort, sortAsc } = this.state.searchSettings;

    if (equipmentRequest) {
      return <Loader />;
    }

    return (
      <EquipmentList
        equipmentList={this.manageEquipmentList(equipmentList)}
        openRemoveDialog={this.openRemoveDialog}
        closeRemoveDialog={this.closeRemoveDialog}
        handleSort={this.handleSort}
        handleSearch={this.handleSearch}
        removeEquipment={this.removeEquipment}
        removeDialogOpen={removeDialogOpen}
        search={search}
        columnToSort={columnToSort}
        sortAsc={sortAsc}
      />
    );
  }
}
