import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { editSetting } from '../../../actions/settings';
import { languages as languageList } from '../../../../constants/languages';

const INITIAL_STATE = {
  language: false
};

const styles = () => ({
  languages: {
    minWidth: '220px',
  },
});

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editSetting: (setting) => dispatch(editSetting(setting)),
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class LanguagesManager extends Component {
  static propTypes = {
    settings: PropTypes.array.isRequired,
    editSetting: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  onChange = (formData) => {
    this.formData = formData;
  };

  handleChange = (event) => {
    const { settings, editSetting } = this.props;

    this.setState({ [event.target.name]: event.target.value });
    editSetting({
      id: _find(settings, { name: event.target.name }).id,
      textValue: event.target.value,
    });
  };

  renderMenuItems(languageList) {
    if (_isEmpty(languageList)) {
      return null;
    }

    return languageList.map((language) => {
      return <MenuItem key={`language-${language}`} value={language}>{language}</MenuItem>;
    });

  }

  setDefaultLanguage() {
    const { settings } = this.props;
    let settingsLanguage = _find(settings, { name: 'language' });

    settingsLanguage = settingsLanguage ? settingsLanguage.textValue : false;

    return this.state.language ? this.state.language : settingsLanguage;
  }

  render() {
    const { classes, t } = this.props;
    const language = this.setDefaultLanguage();

    return (
      <div className="languages-settings">
        <div className="pl-sm-5">
          <h4>{t('settings:languageSection')}</h4>
          <form autoComplete="off">
            <FormControl className="ml-2 ml-sm-3 my-2">
              <InputLabel className={classes.languages} htmlFor="language">{t('input:defaultLanguage')}</InputLabel>
              <Select
                className={classes.languages}
                value={language || []}
                onChange={this.handleChange}
                inputProps={{
                  name: 'language',
                  id: 'language',
                }}
              >
                {this.renderMenuItems(languageList, t)}
              </Select>
            </FormControl>
          </form>
        </div>
      </div>
    );
  }
}
