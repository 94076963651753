import '../styles.scss';

import React, { Component } from 'react';
import {
  addDocumentToLesson,
  editAssignedCourse,
  fetchAssignedCourse,
  fetchAssignedCourseStudents,
  removeDocumentFromLesson,
  updateTheoreticalLessons,
} from '../../../actions/assignedCourses';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ItemList from './ItemList';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import { addFlightLog } from '../../../actions/flightLog';
import { addSimpleError } from '../../../actions/errors';
import { connect } from 'react-redux';
import { fetchAllAircrafts } from '../../../actions/aircrafts';
import { fetchAllLocations } from '../../../actions/locations';
import { fetchGradings } from '../../../actions/gradings';
import { fetchPilotFunctions } from '../../../actions/pilotFunction';
import { fetchPracticalFields } from '../../../actions/practicalFields';
import uuidv4 from 'uuid/v4';
import { withNamespaces } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    assignedCourse: state.assignedCourses.assignedCourse,
    assignedCourseRequest: state.assignedCourses.assignedCourseRequest,
    practicalFieldList: state.practicalFields.practicalFieldList.filter(
      (field) => field.isActive,
    ),
    practicalFieldRequest: state.practicalFields.practicalFieldRequest,
    locationList: state.locations.allLocationList.filter(
      (location) => location.isActive && !location.user,
    ),
    locationRequest: state.locations.locationRequest,
    aircraftList: state.aircrafts.allAircraftList.filter(
      (aircraft) => aircraft.isActive && !aircraft.user,
    ),
    aircraftRequest: state.aircrafts.aircraftRequest,
    assignedCourseStudentsRequest:
      state.assignedCourses.assignedCourseStudentsRequest,
    assignedCourseStudentsList:
      state.assignedCourses.assignedCourseStudentsList,
    pilotFunctionList: state.pilotFunction.pilotFunctionList,
    pilotFunctionsRequest: state.pilotFunction.pilotFunctionsRequest,
    gradingList: state.gradings.gradingList,
    gradingRequest: state.gradings.gradingRequest,
    settings: state.settings.settings,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAllLocations: () => dispatch(fetchAllLocations()),
    fetchPilotFunctions: () => dispatch(fetchPilotFunctions()),
    fetchAllAircrafts: () => dispatch(fetchAllAircrafts()),
    fetchPracticalFields: () => dispatch(fetchPracticalFields()),
    fetchAssignedCourse: (id) => dispatch(fetchAssignedCourse(id)),
    fetchAssignedCourseStudents: (assignedCourseId, lessonId) =>
      dispatch(fetchAssignedCourseStudents(assignedCourseId, lessonId)),
    updateTheoreticalLessons: (msg, assignedCourseId, lessonId, redirect) => {
      return dispatch(
        updateTheoreticalLessons(msg, assignedCourseId, lessonId, redirect),
      );
    },
    editAssignedCourse: (id, courseData, redirect) =>
      dispatch(editAssignedCourse(id, courseData, redirect)),
    addSimpleError: (message) => dispatch(addSimpleError(message)),
    addFlightLog: (flightLog) => dispatch(addFlightLog(flightLog, false)),
    fetchGradings: () => dispatch(fetchGradings()),
    addDocumentToLesson: (file, assignedCourseId, lessonId) =>
      dispatch(addDocumentToLesson(file, assignedCourseId, lessonId)),
    removeDocumentFromLesson: (fileId, assignedCourseId, lessonId) =>
      dispatch(removeDocumentFromLesson(fileId, assignedCourseId, lessonId)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class LessonEdit extends Component {
  static propTypes = {
    addDocumentToLesson: PropTypes.func.isRequired,
    removeDocumentFromLesson: PropTypes.func.isRequired,
    editAssignedCourse: PropTypes.func.isRequired,
    fetchAssignedCourse: PropTypes.func.isRequired,
    fetchAllLocations: PropTypes.func.isRequired,
    fetchAllAircrafts: PropTypes.func.isRequired,
    fetchPracticalFields: PropTypes.func.isRequired,
    fetchPilotFunctions: PropTypes.func.isRequired,
    fetchAssignedCourseStudents: PropTypes.func.isRequired,
    fetchGradings: PropTypes.func.isRequired,
    assignedCourseStudentsRequest: PropTypes.bool.isRequired,
    assignedCourseStudentsList: PropTypes.array,
    updateTheoreticalLessons: PropTypes.func.isRequired,
    addFlightLog: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    practicalFieldList: PropTypes.array.isRequired,
    practicalFieldRequest: PropTypes.bool.isRequired,
    assignedCourse: PropTypes.object.isRequired,
    locationList: PropTypes.array.isRequired,
    locationRequest: PropTypes.bool.isRequired,
    aircraftList: PropTypes.array.isRequired,
    aircraftRequest: PropTypes.bool.isRequired,
    gradingList: PropTypes.array.isRequired,
    settings: PropTypes.array.isRequired,
    gradingRequest: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    assignedCourseRequest: PropTypes.bool.isRequired,
    preview: PropTypes.bool,
    newLesson: PropTypes.bool,
    pilotFunctionsRequest: PropTypes.bool,
    pilotFunctionList: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  state = {
    courseStartDate: null,
    course: null,
    lesson: null,
    lessons: [],
  };

  componentDidMount() {
    this.props.fetchAllLocations();
    this.props.fetchPilotFunctions();
    this.props.fetchAllAircrafts();
    this.props.fetchGradings();
    this.props.fetchPracticalFields();
    this.props.fetchAssignedCourse(this.props.match.params.id);

    if (!this.props.newLesson) {
      this.props.fetchAssignedCourseStudents(
        this.props.match.params.id,
        this.props.match.params.lessonId,
      );
    }
  }

  handleUploadedFile = (file, lessonId) => {
    const { assignedCourse, addDocumentToLesson } = this.props;

    addDocumentToLesson(file, assignedCourse.id, lessonId);
  };

  handleRemoveUploadFile = (fileId, lessonId) => {
    const { assignedCourse, removeDocumentFromLesson } = this.props;

    removeDocumentFromLesson(fileId, assignedCourse.id, lessonId);
  };

  updateTheoreticalLessons = (msg, assignedCourseId, redirect) => {
    const { updateTheoreticalLessons, match } = this.props;

    updateTheoreticalLessons(
      msg,
      assignedCourseId,
      match.params.lessonId,
      redirect,
    );
  };

  setNewLesson = () => {
    const { t, match, newLesson, assignedCourse } = this.props;
    const { lesson } = this.state;
    const { lessonId } = match.params;

    if (!_isEmpty(lesson) || _isEmpty(assignedCourse.courseData)) {
      return;
    }

    const course = _get(assignedCourse, 'courseData', '{}');
    const courseStartDate = _get(assignedCourse, 'startDate', '');
    const lessons = course.lessons.map((courseLesson) => {
      return {
        ...courseLesson,
        items: _sortBy(
          courseLesson.lessonsItems.map(({ item, order }, index) => {
            return {
              ...item,
              order,
              lessonItemId: index,
            };
          }),
          ['order'],
        ),
      };
    });

    let currentLesson = {};

    if (newLesson) {
      const nextNumber =
        lessons.filter(
          (lesson) => lesson.lessonType && lesson.lessonType.isAdditional,
        ).length + 1;

      currentLesson = {
        id: uuidv4(),
        order: lessons.length,
        name: `${t('course:newPracticalLessonName')} ${nextNumber}`,
        items: [],
        lessonType: {
          id: 1,
          name: 'practical',
          shortname: 'PR',
          isAdditional: true,
        },
      };
      lessons.push(currentLesson);
    } else {
      currentLesson = lessons.find(
        (courseLesson) => courseLesson.id.toString() === lessonId.toString(),
      );
    }

    this.setState({
      lesson: currentLesson,
      lessons,
      courseStartDate,
      course,
    });
  };

  render() {
    const {
      assignedCourse,
      assignedCourseRequest,
      editAssignedCourse,
      assignedCourseStudentsRequest,
      assignedCourseStudentsList,
      preview,
      practicalFieldList,
      locationList,
      aircraftList,
      gradingList,
      settings,
      practicalFieldRequest,
      locationRequest,
      aircraftRequest,
      pilotFunctionsRequest,
      gradingRequest,
      addSimpleError,
      addFlightLog,
      pilotFunctionList,
    } = this.props;
    const { lesson, course, courseStartDate, lessons } = this.state;

    if (_isEmpty(assignedCourse)) {
      return null;
    }

    if (
      assignedCourseRequest ||
      practicalFieldRequest ||
      locationRequest ||
      aircraftRequest ||
      assignedCourseStudentsRequest ||
      pilotFunctionsRequest ||
      gradingRequest
    ) {
      return <Loader />;
    }

    this.setNewLesson();

    if (_isEmpty(lesson)) {
      return <Loader />;
    }

    const documents = assignedCourse.lessonFiles.filter(
      (f) => f.lesson === lesson.id,
    );

    /*
    Inactivate redirect for completed lessons
    if (lesson.isCompleted && match.url.includes('edit')) {
      return <Redirect to={{ pathname: match.url.replace('edit', 'details') }} />;
    }
    */
    return (
      <div className="assigned-course-details row justify-content-lg-between justify-content-end align-items-center">
        <div className="col section-title pb-5">
          <header>
            <span>
              <Icon color="primary">content_paste</Icon>
              <h1>{lesson.name}</h1>
            </span>
          </header>
        </div>
        <div
          key="edit-lesson"
          className="col-auto"
          style={{ display: preview === true ? 'inherit' : 'none' }}
        >
          <Link
            to={`/course_list/details/${assignedCourse.id}/lesson/edit/${lesson.id}`}
          >
            <IconButton>
              <Icon>mode_edit</Icon>
            </IconButton>
          </Link>
        </div>
        <div className="col-12">
          <ItemList
            assignedCourseId={assignedCourse.id}
            lesson={lesson}
            course={course}
            lessons={lessons}
            courseStartDate={courseStartDate}
            preview={preview}
            user={assignedCourse.user}
            editAssignedCourse={editAssignedCourse}
            practicalFieldList={practicalFieldList}
            locationList={locationList}
            aircraftList={aircraftList}
            pilotFunctionList={pilotFunctionList}
            gradingList={gradingList}
            settings={settings}
            addSimpleError={addSimpleError}
            courseParticipants={assignedCourseStudentsList}
            updateTheoreticalLessons={this.updateTheoreticalLessons}
            addFlightLog={addFlightLog}
            handleUploadedFile={this.handleUploadedFile}
            handleRemoveUploadFile={this.handleRemoveUploadFile}
            documents={documents}
          />
        </div>
      </div>
    );
  }
}
