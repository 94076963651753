import React, { Component } from 'react';
import {
  renderDateColor,
  renderInspectionColor,
  renderTickerToService,
} from '../fleetHelper';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import FileInput from '../../../../common/components/upload/FileInput';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Table from '../../../../common/components/Table';
import Tooltip from '@material-ui/core/Tooltip';
import _isEmpty from 'lodash/isEmpty';
import { reformatPickerDateFromDB } from '../../../utils/time';
import { sortString } from '../../../../utils/sort';
import { withNamespaces } from 'react-i18next';

const HEADER = [
  {
    prop: 'color',
    sort: true,
  },
  {
    prop: 'name',
    sort: true,
  },
  {
    prop: 'plannedAction',
    sort: false,
  },
  {
    prop: 'hoursToService',
    sort: true,
  },
  {
    prop: 'cyclesToService',
    sort: true,
  },
  {
    prop: 'serviceDate',
    sort: true,
  },
  {
    prop: 'isCompleted',
    sort: false,
  },
  {
    prop: 'inspectionDate',
    sort: true,
  },
  {
    prop: 'maintenanceAtHours',
    sort: true,
  },
  {
    prop: 'maintenanceAtCycles',
    sort: true,
  },
  {
    prop: 'attachments',
    iconWidth: true,
  },
  {
    prop: 'buttons',
    hideLabel: true,
    iconWidth: true,
  },
];

@withNamespaces()
export default class InspectionList extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    inspectionList: PropTypes.array.isRequired,
    removeInspection: PropTypes.func.isRequired,
    handleAddOpen: PropTypes.func.isRequired,
    handleEditOpen: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    attachInspectionFile: PropTypes.func.isRequired,
    removeInspectionFile: PropTypes.func.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    fleetSettings: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    searchSettings: {
      columnToSort: '',
      sortAsc: true,
    },
  };

  asInspectionList() {
    const { inspectionList, fleet, fleetSettings } = this.props;
    const { columnToSort, sortAsc } = this.state.searchSettings;

    const result = [];

    result.push.apply(
      result,
      inspectionList
        .filter((inspection) => !inspection.isCompleted)
        .sort(
          (a, b) =>
            this.distanceToSevice(a, fleet) - this.distanceToSevice(b, fleet) ||
            this.hoursToService(a, fleet) - this.hoursToService(b, fleet) ||
            sortString(a.serviceDate, b.serviceDate),
        ),
    );

    result.push.apply(
      result,
      inspectionList
        .filter((inspection) => inspection.isCompleted)
        .sort((a, b) => sortString(b.inspectionDate, a.inspectionDate)),
    );

    const parsed = result.map((inspection) => {
      const {
        name,
        isCompleted,
        inspectionDate,
        serviceHours,
        serviceActualHours,
        serviceCycles,
        serviceActualCycles,
      } = inspection;

      const referenceCycles =
        inspection.serviceActualCycles > 0
          ? inspection.serviceActualCycles
          : inspection.serviceCycles;

      const referenceHours =
        inspection.serviceActualHours > 0
          ? inspection.serviceActualHours
          : inspection.serviceHours;

      return {
        inspection,
        color: renderInspectionColor(inspection, fleet, fleetSettings),
        name,
        hoursToServiceData: serviceHours
          ? Number(serviceHours) -
            (isCompleted ? referenceHours : fleet.currentHours)
          : 0,
        hoursToService: renderTickerToService(
          inspection,
          'serviceHours',
          fleetSettings.hoursWarning,
          isCompleted ? referenceHours : fleet.currentHours,
        ),
        cyclesToServiceData: serviceCycles
          ? Number(serviceCycles) -
            (isCompleted ? referenceCycles : fleet.currentCycles)
          : 0,
        cyclesToService: renderTickerToService(
          inspection,
          'serviceCycles',
          fleetSettings.distanceWarning,
          isCompleted ? referenceCycles : fleet.currentCycles,
        ),
        serviceDate: renderDateColor(inspection, 'serviceDate'),
        isCompleted: isCompleted ? (
          <Icon color="action" className="mr-3">
            done
          </Icon>
        ) : (
          <Icon className="mr-3">remove</Icon>
        ),
        inspectionDate: reformatPickerDateFromDB(inspectionDate, false),
        maintenanceAtHours: serviceActualHours,
        maintenanceAtCycles: serviceActualCycles,
        attachments: this.renderFiles(inspection),
        buttons: this.renderButtons(inspection),
        plannedAction: this.renderPlannedAction(inspection),
      };
    });

    this.sort(parsed, columnToSort, sortAsc);

    return parsed;
  }

  renderFiles(inspection) {
    const { editMode, removeInspectionFile, onElementDelete } = this.props;
    const inspectionId = inspection.id;

    return inspection.files.map((file) => {
      if (editMode) {
        return (
          <Chip
            key={`inspection-${inspectionId}-file-${file.id}`}
            label={file.name}
            onClick={() => window.open(file.path, '_blank')}
            onDelete={() =>
              onElementDelete(() => removeInspectionFile(file.id, inspectionId))
            }
            className="my-1"
          />
        );
      }

      return (
        <Chip
          key={`inspection-${inspectionId}-file-${file.id}`}
          label={file.name}
          onClick={() => window.open(file.path, '_blank')}
          className="my-1"
        />
      );
    });
  }

  renderButtons(inspection) {
    const {
      handleEditOpen,
      removeInspection,
      onElementDelete,
      fleet,
      attachInspectionFile,
      addSimpleError,
      editMode,
    } = this.props;

    if (!editMode) {
      return null;
    }

    return (
      <>
        <span className="file-inputs">
          <CustomIconButton>
            <FileInput
              attachFile={(file) =>
                attachInspectionFile(file, inspection.id, fleet.id)
              }
              addSimpleError={addSimpleError}
              required
            />
            <Icon color="primary">attach_file</Icon>
          </CustomIconButton>
        </span>
        <IconButton onClick={() => handleEditOpen(inspection)}>
          <Icon color="primary">mode_edit</Icon>
        </IconButton>
        <IconButton
          onClick={() => onElementDelete(() => removeInspection(inspection))}
        >
          <Icon color="primary">delete</Icon>
        </IconButton>
      </>
    );
  }

  renderPlannedAction(inspection) {
    const { handleEditOpen, editMode } = this.props;

    const plannedActionTitle = inspection.plannedActionDate
      ? `${inspection.plannedAction} (${inspection.plannedActionDate})`
      : `${inspection.plannedAction}`;

    return inspection.plannedAction ? (
      <Tooltip title={plannedActionTitle} placement="top" arrow>
        <IconButton onClick={() => !editMode || handleEditOpen(inspection)}>
          <Icon color="primary">alarm</Icon>
        </IconButton>
      </Tooltip>
    ) : (
      ''
    );
  }

  distanceToSevice(inspection, fleet) {
    return inspection.serviceCycles - fleet.currentCycles;
  }

  handleSort = (columnName) => {
    const { sortAsc } = this.state.searchSettings;

    this.setState({
      searchSettings: {
        sortAsc: !sortAsc,
        columnToSort: columnName,
      },
    });
  };

  hoursToService(inspection, fleet) {
    return inspection.serviceHours - fleet.currentHours;
  }

  sort(list, column, sortAsc) {
    switch (column) {
      case 'name':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.name, b.name)
            : sortString(b.name, a.name);
        });

        break;
      case 'hoursToService':
        list.sort((a, b) => {
          if (!a.hoursToService) {
            return sortAsc ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
          } else if (!b.hoursToService) {
            return sortAsc ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
          }

          return sortAsc
            ? a.hoursToServiceData - b.hoursToServiceData
            : b.hoursToServiceData - a.hoursToServiceData;
        });

        break;
      case 'cyclesToService':
        list.sort((a, b) => {
          if (!a.cyclesToService) {
            return sortAsc ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
          } else if (!b.cyclesToService) {
            return sortAsc ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
          }

          return sortAsc
            ? a.cyclesToServiceData - b.cyclesToServiceData
            : b.cyclesToServiceData - a.cyclesToServiceData;
        });

        break;
      case 'operationalDaysToService':
        list.sort((a, b) => {
          if (!a.operationalDaysToService) {
            return sortAsc ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
          } else if (!b.operationalDaysToService) {
            return sortAsc ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
          }

          return sortAsc
            ? a.operationalDaysToServiceData - b.operationalDaysToServiceData
            : b.operationalDaysToServiceData - a.operationalDaysToServiceData;
        });

        break;
      case 'serviceDate':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.inspection.serviceDate, b.inspection.serviceDate)
            : sortString(b.inspection.serviceDate, a.inspection.serviceDate);
        });

        break;
      case 'inspectionDate':
        list.sort((a, b) => {
          return sortAsc
            ? sortString(a.inspectionDate, b.inspectionDate)
            : sortString(b.inspectionDate, a.inspectionDate);
        });

        break;
      case 'maintenanceAtHours':
        list.sort((a, b) => {
          return sortAsc
            ? a.maintenanceAtHours - b.maintenanceAtHours
            : b.maintenanceAtHours - a.maintenanceAtHours;
        });

        break;
      case 'maintenanceAtCycles':
        list.sort((a, b) => {
          return sortAsc
            ? a.maintenanceAtCycles - b.maintenanceAtCycles
            : b.maintenanceAtCycles - a.maintenanceAtCycles;
        });

        break;
    }
  }

  renderAddInspectionButton() {
    const { editMode, handleAddOpen, t } = this.props;

    if (!editMode) {
      return null;
    }

    return (
      <Button color="primary" onClick={handleAddOpen}>
        {t('fleet:addInspectionButton')}
      </Button>
    );
  }

  render() {
    const { inspectionList } = this.props;
    const { searchSettings } = this.state;

    if (_isEmpty(inspectionList)) {
      return this.renderAddInspectionButton();
    }

    return (
      <div className="row">
        <div className="col-12 no-gutters">
          <Table
            data={this.asInspectionList()}
            header={HEADER}
            handleSort={this.handleSort}
            hideSearch
            sortAsc={searchSettings.sortAsc}
            columnToSort={searchSettings.columnToSort}
            handleSearch={() => {}}
          />
          {this.renderAddInspectionButton()}
        </div>
      </div>
    );
  }
}
