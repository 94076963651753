import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import Loader from '../../../../common/components/Loader';
import ImageInput from '../../../../common/components/upload/ImageInput';
import CustomIconButton from '../../../../common/components/CustomIconButton';
import companyLogo from '../../../../images/companyLogo.png';
import { addSimpleError } from '../../../actions/errors';
import { attachSchoolLogoImage, fetchSchoolLogo } from '../../../actions/fileUpload';

const styles = () => ({
  bigAvatar: {
    width: '100%',
    maxWidth: 300,
    maxHeight: 100,
  },
  uploadContainer: {
    position: 'relative',
    overflow: 'hidden'
  }
});

const mapStateToProps = (state) => {
  return {
    ratingRequest: state.ratings.ratingsRequest,
    file: state.schoolLogo.file,
    fileRequest: state.schoolLogo.fileRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attachSchoolLogoImage: (fileData, schoolLogoId) => dispatch(attachSchoolLogoImage(fileData, schoolLogoId)),
    fetchSchoolLogo: (id) => dispatch(fetchSchoolLogo(id)),
    addSimpleError: (message) => dispatch(addSimpleError(message))
  };
}

@withNamespaces()
@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
export default class SchoolLogoManager extends Component {
  static propTypes = {
    schoolLogoId: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    addSimpleError: PropTypes.func.isRequired,
    attachSchoolLogoImage: PropTypes.func.isRequired,
    fetchSchoolLogo: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    fileRequest: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const { schoolLogoId, fetchSchoolLogo } = this.props;

    fetchSchoolLogo(schoolLogoId);
  }

  removeFileIcon() {
    const { file, schoolLogoId, attachSchoolLogoImage } = this.props;

    if (!file || _isEmpty(file.path)) {
      return null;
    }

    return (
      <IconButton
        onClick={() => attachSchoolLogoImage({}, schoolLogoId)}
      >
        <Icon color="primary">close</Icon>
      </IconButton>
    );
  }

  attachFile = (file) => {
    const { schoolLogoId, attachSchoolLogoImage } = this.props;

    return attachSchoolLogoImage(file, schoolLogoId);
  };

  renderManageButtons() {
    const { classes, addSimpleError } = this.props;

    return (
      <div className="row justify-content-center pl-md-5">
        <div className="col-auto">
          <div className={classes.uploadContainer}>
            <CustomIconButton>
              <ImageInput
                attachFile={this.attachFile}
                addSimpleError={addSimpleError}
                required
              />
              <Icon color="primary">file_upload</Icon>
            </CustomIconButton>
            {this.removeFileIcon()}
          </div>
        </div>
      </div>
    );
  }

  setSchoolLogo = () => {
    const { file } = this.props;

    return file ? _isEmpty(file.path) ? companyLogo : file.path : companyLogo;
  };

  render() {
    const { classes, fileRequest, t } = this.props;

    if (fileRequest) {
      return <Loader />;
    }

    return (
      <div>
        <div className="pl-sm-5">
          <h4>{t('settings:companyLogoSection')}</h4>
        </div>
        <div className="row align-items-center">
          <div className="col-12">
            <div className="row justify-content-center">
              <img
                src={this.setSchoolLogo()}
                className={classes.bigAvatar}
              />
            </div>
            {this.renderManageButtons()}
          </div>
        </div>
      </div>
    );
  }
}
