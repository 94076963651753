import React, { Component } from 'react';
import {
  addAircraftType,
  editAircraftType,
  fetchAircraftTypes,
  removeAircraftType,
} from '../../../actions/engineerAircraftTypes';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EngineerAircraftTypeList from './EngineerAircraftTypeList';
import Form from '../../../../common/components/forms/Form';
import Loader from '../../../../common/components/Loader';
import MaterialInput from '../../../../common/components/forms/MaterialInput';
import PropTypes from 'prop-types';
import _each from 'lodash/each';
import { connect } from 'react-redux';
import validators from '../../../../utils/validators';
import { withNamespaces } from 'react-i18next';

const INITIAL_STATE = {
  addOpen: false,
  editOpen: false,
  name: '',
  info: '',
};

const mapStateToProps = (state) => {
  return {
    aircraftTypeRequest: state.engineerAircraftTypes.aircraftTypeRequest,
    aircraftTypeList: state.engineerAircraftTypes.aircraftTypeList,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAircraftTypes: () => dispatch(fetchAircraftTypes()),
    addAircraftType: (name) => dispatch(addAircraftType(name)),
    editAircraftType: (data) => dispatch(editAircraftType(data)),
    removeAircraftType: (name) => dispatch(removeAircraftType(name)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class EngineerAircraftTypesManager extends Component {
  static propTypes = {
    aircraftTypeList: PropTypes.array.isRequired,
    aircraftTypeRequest: PropTypes.bool.isRequired,
    fetchAircraftTypes: PropTypes.func.isRequired,
    addAircraftType: PropTypes.func.isRequired,
    editAircraftType: PropTypes.func.isRequired,
    removeAircraftType: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = INITIAL_STATE;

  componentDidMount() {
    const { fetchAircraftTypes } = this.props;

    fetchAircraftTypes();
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true,
    });
  };

  handleEditOpen = (aircraft) => {
    this.setState({
      editOpen: true,
      id: aircraft.id,
      name: aircraft.name,
      info: aircraft.info,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  onChange = (formData) => {
    _each(formData, ({ value }, key) => {
      this.setState({
        [key]: value,
      });
    });
  };

  onFormValidated = () => {
    const { addOpen, id, name, info } = this.state;
    const { addAircraftType, editAircraftType } = this.props;

    return (isFormValid) => {
      if (isFormValid) {
        addOpen
          ? addAircraftType({ name, info })
          : editAircraftType({ id, name, info });

        this.handleDialogClose();
      }
    };
  };

  registerForm = (triggerFormValidation) => {
    this.triggerFormValidation = triggerFormValidation;
  };

  sendHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.triggerFormValidation();
  };

  render() {
    const {
      aircraftTypeList,
      aircraftTypeRequest,
      onElementDelete,
      t,
      removeAircraftType,
    } = this.props;
    const { addOpen, editOpen, name, info } = this.state;

    if (aircraftTypeRequest) {
      return <Loader />;
    }

    return (
      <div className="engineer-aircraft-types-settings">
        <div className={'pl-sm-5'}>
          <h4>{t('settings:engineerAircraftTypesSection')}</h4>
          <EngineerAircraftTypeList
            aircraftTypeList={aircraftTypeList}
            removeAircraftType={removeAircraftType}
            handleAddOpen={this.handleAddOpen}
            handleEditOpen={this.handleEditOpen}
            onElementDelete={onElementDelete}
          />
        </div>
        <Form
          onChange={this.onChange}
          onFormValidated={this.onFormValidated()}
          registerForm={this.registerForm}
        >
          <Dialog
            open={addOpen || editOpen}
            onClose={this.handleAddClose}
            aria-labelledby="engineer-add-aircraft-type-dialog"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle id="engineer-add-aircraft-type-dialog-title">
              {addOpen
                ? t('settings:addEngineerAircraftTypeDialogTitle')
                : t('settings:editEngineerAircraftTypeDialogTitle')}
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-6">
                  <MaterialInput
                    label={t('input:aircraft type')}
                    autoFocus
                    name="name"
                    defaultValue={name}
                    margin="dense"
                    fullWidth
                    validators={[
                      new validators.IsRequired(t),
                      new validators.MaxLength(t, 200),
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <MaterialInput
                    label={t('input:information')}
                    autoFocus
                    name="info"
                    defaultValue={info}
                    margin="dense"
                    fullWidth
                    validators={[new validators.MaxLength(t, 500)]}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={this.handleDialogClose}>
                {t('buttonCancel')}
              </Button>
              <Button color="primary" onClick={this.sendHandler}>
                {t('buttonSave')}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      </div>
    );
  }
}
