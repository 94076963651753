import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';


export default class Input extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    validationError: PropTypes.array,
    disabled: PropTypes.bool,
    inputValidated: PropTypes.func,
    registerInput: PropTypes.func,
    validators: PropTypes.array,
    initialValue: PropTypes.any,
    forceInitialValidation: PropTypes.bool
  };

  static defaultProps = {
    validators: [],
    type: 'text',
    initialValue: null,
    forceInitialValidation: false,
    disabled: false
  };

  async componentDidMount() {
    this.props.registerInput(this.props.name, this.input.value, this.validateAndPropagateValue);

    if (this.props.forceInitialValidation) {
      const errors = await this.validate(this.props.initialValue);

      this.props.inputValidated(this.props.name, this.input.value, {
        isValid: isEmpty(errors),
        errors,
        disabled: this.props.disabled
      });
    }
  }

  validateAndPropagateValue = async () => {
    const errors = await this.validate(this.input.value);
    const isValid = this.props.disabled || isEmpty(errors);

    this.props.inputValidated(this.props.name, this.input.value, {
      isValid,
      errors,
      disabled: this.props.disabled
    });
  };

  validate = async (value) => {
    const allErrors = [];

    for (const validator of this.props.validators) {
      const isValid = await validator.isValid(value);

      if (!isValid) {
        allErrors.push(validator.getValidationMessage());
      }
    }

    return allErrors;
  };

  render() {
    const error = !this.props.disabled && this.props.validationError ? this.props.validationError.join('; ') : null;

    return (
      <div className="">
        <input
          ref={(input) => {
            this.input = input;
          }}
          name={this.props.name}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          type={this.props.type}
          defaultValue={this.props.initialValue}
          onKeyUp={this.validateAndPropagateValue}
          onBlur={this.validateAndPropagateValue}
        />
        <span>{error}</span>
      </div>
    );
  }
}
