import React, { Component } from 'react';
import {
  addVehicleGroupUser,
  fetchVehicleGroupUsers,
  removeVehicleGroupUser,
} from '../../../actions/vehicleGroupUsers';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from '../../../../common/components/Loader';
import PropTypes from 'prop-types';
import VehicleGroupUserList from './VehicleGroupUserList';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import { fetchVehicleGroups } from '../../../actions/vehicleGroups';
import { withNamespaces } from 'react-i18next';

const INITIAL_STATE = {
  addOpen: false,
  newUsers: [],
  vehicleGroup: {
    id: '',
    name: '',
  },
};

const mapStateToProps = (state) => {
  return {
    userList: state.users.userList,
    getUsersRequest: state.users.getUsersRequest,
    vehicleGroupUserList: state.vehicleGroupUsers.vehicleGroupUserList,
    vehicleGroupUserRequest: state.vehicleGroupUsers.vehicleGroupUsersRequest,
    vehicleGroupList: state.vehicleGroups.vehicleGroupList,
    vehicleGroupRequest: state.vehicleGroups.vehicleGroupRequest,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchVehicleGroups: () => dispatch(fetchVehicleGroups()),
    fetchVehicleGroupUsers: () => dispatch(fetchVehicleGroupUsers()),
    addVehicleGroupUser: (vehicleId, userId) =>
      dispatch(addVehicleGroupUser(vehicleId, userId)),
    removeVehicleGroupUser: (vehicleGroupUserId) =>
      dispatch(removeVehicleGroupUser(vehicleGroupUserId)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class VehicleGroupUsersManager extends Component {
  static propTypes = {
    vehicleGroupUserList: PropTypes.array.isRequired,
    vehicleGroupUserRequest: PropTypes.bool.isRequired,
    vehicleGroupList: PropTypes.array.isRequired,
    vehicleGroupRequest: PropTypes.bool.isRequired,
    userList: PropTypes.array,
    getUsersRequest: PropTypes.bool.isRequired,
    addVehicleGroupUser: PropTypes.func.isRequired,
    fetchVehicleGroupUsers: PropTypes.func.isRequired,
    fetchVehicleGroups: PropTypes.func.isRequired,
    removeVehicleGroupUser: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    ...INITIAL_STATE,
  };

  componentDidMount() {
    this.props.fetchVehicleGroups();
    this.props.fetchVehicleGroupUsers();
  }

  addVehicleGroupUsers = () => {
    const { vehicleGroup, newUsers } = this.state;

    newUsers.forEach((user) =>
      this.props.addVehicleGroupUser(vehicleGroup.id, user.id),
    );

    this.handleAddClose();
  };

  removeVehicleGroupUser = (user, vehicleGroup) => {
    const { vehicleGroupUserList, removeVehicleGroupUser } = this.props;

    const vehiceGroupUserToBeRemoved = vehicleGroupUserList.find(
      (vg) => vg.vehicle === vehicleGroup.id && vg.user === user.id,
    );

    removeVehicleGroupUser(vehiceGroupUserToBeRemoved.id);
  };

  handleAddOpen = (vehicleGroup) => {
    this.setState({
      addOpen: true,
      vehicleGroup,
    });
  };

  handleAddClose = () => {
    this.setState(INITIAL_STATE);
  };

  addNewUser = (newUser) => (event) => {
    if (event.target.checked) {
      this.setState({
        newUsers: this.state.newUsers.concat(newUser),
      });
    } else {
      this.setState({
        newUsers: this.state.newUsers.filter((user) => user.id !== newUser.id),
      });
    }
  };

  addDialogContent = () => {
    const { userList, vehicleGroupUserList } = this.props;
    const { vehicleGroup } = this.state;

    const vehicleGroupUsers = vehicleGroupUserList
      .filter((vg) => vg.vehicle === vehicleGroup.id)
      .map((vg) => vg.user);

    const users = userList.filter(
      (user) => !vehicleGroupUsers.includes(user.id),
    );

    return this.getDialogContent(
      this.getUserList(_sortBy(users, ['firstName'])),
      true,
    );
  };

  getDialogContent = (content, user) => {
    const { t } = this.props;

    return (
      <div>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleAddClose}>
            {t('buttonCancel')}
          </Button>
          {user && (
            <Button color="primary" onClick={this.addVehicleGroupUsers}>
              {t('buttonSave')}
            </Button>
          )}
        </DialogActions>
      </div>
    );
  };

  getUserList = (addUserList) => {
    return addUserList.map((user) => {
      return (
        <div key={user.id}>
          <FormControlLabel
            control={
              <Checkbox color="primary" onChange={this.addNewUser(user)} />
            }
            label={`${user.firstName} ${user.lastName}`}
          />
        </div>
      );
    });
  };

  render() {
    const {
      vehicleGroupUserRequest,
      vehicleGroupRequest,
      vehicleGroupUserList,
      vehicleGroupList,
      userList,
      getUsersRequest,
      onElementDelete,
      t,
    } = this.props;
    const { vehicleGroup } = this.state;

    if (vehicleGroupRequest || vehicleGroupUserRequest || getUsersRequest) {
      return <Loader />;
    } else if (_isEmpty(vehicleGroupList)) {
      return (
        <div className="vehicle-groupssettings">
          <div className="pl-sm-5">
            <h4>{t('settings:noVehicleGroups')}</h4>
          </div>
        </div>
      );
    }

    const dialogTitle = `settings:${vehicleGroup.name}`;

    return (
      <div className="vehicle-groupssettings">
        {vehicleGroupList.map((vehicleGroup) => {
          const users = vehicleGroupUserList
            .filter(({ vehicle }) => vehicleGroup.id === vehicle)
            .map(({ user }) => userList.find((u) => u.id === user));

          return (
            <>
              <VehicleGroupUserList
                vehicleGroup={vehicleGroup}
                userList={_sortBy(users, ['firstName', 'lastName'])}
                removeRole={(user, vehicleGroup) =>
                  onElementDelete(() =>
                    this.removeVehicleGroupUser(user, vehicleGroup),
                  )
                }
                handleAddOpen={this.handleAddOpen}
              />
              <Divider className="mx-sm-3 my-4" />
            </>
          );
        })}
        <Dialog
          open={this.state.addOpen}
          onClose={this.handleAddClose}
          aria-labelledby="add-vehicle-group"
          fullWidth
        >
          <DialogTitle id="add-vehicle-group">{t(dialogTitle)}</DialogTitle>
          {this.addDialogContent()}
        </Dialog>
      </div>
    );
  }
}
