import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TestList from '../Tests/TestList/TestsList';
import { componentPermission } from '../../decorators/permissions';
import { RAIL_PERMISSIONS as PERMISSIONS } from '../../../constants/permissions';
import userService from '../../../utils/userService';

@componentPermission(PERMISSIONS.myExamsView)
export default class MyTests extends Component {
  static propTypes = {
    userId: PropTypes.string,
    isUserProfile: PropTypes.bool,
  };

  render() {
    const id = this.props.userId || userService.getCurrentUser().id;
    const { isUserProfile } = this.props;

    return (
      <TestList userId={Number(id)} isUserProfile={isUserProfile} />
    );
  }
}
