import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import AircraftList from './AircraftList';
import AircraftDialogContent from './AircraftDialogContent';
import Loader from '../../../../common/components/Loader';

import { fetchAllAircrafts, fetchUserSettingsAircrafts, addAircraft, removeAircraft, editAircraft } from '../../../actions/aircrafts';

const INITIAL_STATE = {
  AircraftDialogContentIsValid: false,
  addOpen: false,
  editOpen: false,
  aircraftName: '',
  registration: '',
  type: '',
  pilot: '',
  engine: '',
  propulsion: '',
  aircraftId: null,
};

const mapStateToProps = (state) => {
  return {
    allAircraftList: state.aircrafts.allAircraftList.filter((aircraft) => aircraft.isActive && !aircraft.user),
    aircraftRequest: state.aircrafts.aircraftRequest,
    userAircraftList: state.aircrafts.userAircraftList.filter((userAircraft)=> userAircraft.isActive)
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchAllAircrafts: () => dispatch(fetchAllAircrafts()),
    fetchUserSettingsAircrafts: (userId) => dispatch(fetchUserSettingsAircrafts(userId)),
    addAircraft: (name) => dispatch(addAircraft(name)),
    removeAircraft: (name) => dispatch(removeAircraft(name)),
    editAircraft: (name) => dispatch(editAircraft(name)),
  };
}

@withNamespaces()
@connect(mapStateToProps, mapDispatchToProps)
export default class AircraftsManager extends Component {
  static propTypes = {
    userId: PropTypes.string,
    allAircraftList: PropTypes.array,
    userAircraftList: PropTypes.array,
    aircraftRequest: PropTypes.bool.isRequired,
    fetchAllAircrafts: PropTypes.func.isRequired,
    fetchUserSettingsAircrafts: PropTypes.func.isRequired,
    addAircraft: PropTypes.func.isRequired,
    removeAircraft: PropTypes.func.isRequired,
    editAircraft: PropTypes.func.isRequired,
    onElementDelete: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.triggers = {};
    this.formData = {};

    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const { userId, fetchAllAircrafts, fetchUserSettingsAircrafts } = this.props;

    if (userId) {
      fetchUserSettingsAircrafts(userId);
    } else {
      fetchAllAircrafts();
    }
  }

  handleAddOpen = () => {
    this.setState({
      addOpen: true
    });
  };

  handleEditOpen = (aircraft) => {
    this.setState({
      editOpen: true,
      registration: aircraft.registration,
      aircraftName: aircraft.name,
      type: aircraft.type,
      pilot: aircraft.pilot,
      engine: aircraft.engine,
      propulsion: aircraft.propulsion,
      aircraftId: aircraft.id,
    });
  };

  handleDialogClose = () => {
    this.setState(INITIAL_STATE);
  };

  prepareSaveData = (data) => {
    if (data.type === 'simulator') {
      data.pilot = null;
      data.engine = null;
      data.propulsion = null;
    }

    return data;
  }

  addAircraft = async () => {
    const { userId } = this.props;

    await this.triggers.AircraftDialogContent();

    if (this.formData.AircraftDialogContentIsValid) {
      let dataToSave = this.formData.AircraftDialogContentChanges;

      if (userId) {
        dataToSave = {
          ...this.formData.AircraftDialogContentChanges,
          user: userId
        };
      }

      this.props.addAircraft(this.prepareSaveData(dataToSave));
      this.handleDialogClose();
    }
  };

  editAircraft = async () => {
    await this.triggers.AircraftDialogContent();

    if (this.formData.AircraftDialogContentIsValid) {
      this.props.editAircraft(this.prepareSaveData(this.formData.AircraftDialogContentChanges));
      this.handleDialogClose();
    }
  };

  removeAircraft = (aircraft) => {
    this.props.removeAircraft(aircraft);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  formValidated = (isFormValid, formChanges) => {
    this.formData = {
      ...this.formData,
      ['AircraftDialogContentChanges']: formChanges,
      ['AircraftDialogContentIsValid']: isFormValid,
    };
  };

  registerComponentForm(componentName) {
    return (triggerFn) =>{
      this.triggers[componentName] = triggerFn;
    };
  }

  render() {
    const { allAircraftList, userAircraftList, aircraftRequest, onElementDelete, userId, t } = this.props;
    const { aircraftId, aircraftName, registration, type, pilot, engine, propulsion, addOpen, editOpen } = this.state;

    if (aircraftRequest) {
      return <Loader />;
    }

    return (
      <div className="aircrafts-settings">
        <div className={userId ? 'pl-sm-3' : 'pl-sm-5'}>
          <h4>{userId ? t('settings:myAircrafts') : t('settings:aircraftsSection')}</h4>
        </div>
        <AircraftList
          userId={userId}
          aircraftList={userId ? userAircraftList : allAircraftList}
          removeAircraft={this.removeAircraft}
          handleAddOpen={this.handleAddOpen}
          handleEditOpen={this.handleEditOpen}
          onElementDelete={onElementDelete}
        />
        <Dialog
          open={addOpen || editOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="add-aircraft-dialog"
          fullWidth
        >
          <DialogTitle id="add-aircraft-dialog">
            {addOpen ? t('settings:addAircraftDialogTitle') : t('settings:editAircraftDialogTitle')}
          </DialogTitle>
          <DialogContent>
            <AircraftDialogContent
              id={aircraftId}
              registration={registration}
              name={aircraftName}
              type={type}
              pilot={pilot}
              engine={engine}
              propulsion={propulsion}
              formValidated={this.formValidated}
              registerComponentForm={this.registerComponentForm('AircraftDialogContent')}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleDialogClose}>{t('buttonCancel')}</Button>
            <Button color="primary" onClick={addOpen ? this.addAircraft : this.editAircraft}>{t('buttonSave')}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
