export const TABLE_HEADER = [
  {
    prop: 'dateOfJourneyLog',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'firstName',
    sort: true,
    logbook: true,
    profileView: false
  },
  {
    prop: 'lastName',
    sort: true,
    logbook: true,
    profileView: false
  },
  {
    prop: 'totalTime',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'departure',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'departureDate',
    sort: true,
    profileView: false,
    logbook: true,
    longLogbook: true,
    boldText: true,
  },
  {
    prop: 'arrival',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'arrivalDate',
    sort: true,
    profileView: false,
    logbook: true,
    longLogbook: true,
    boldText: true,
  },
  {
    prop: 'type',
    sort: true,
    profileView: false,
    logbook: true,
    boldText: true,
  },
  {
    prop: 'icons',
    sort: false,
    profileView: false,
    isNumeric: true,
    iconWidth: true
  }
];

export const MONTHS = [
  {
    name: '3months',
    id: 3,
    type: 'radio'
  },
  {
    name: '6months',
    id: 6,
    type: 'radio'
  },
  {
    name: '12months',
    id: 12,
    type: 'radio'
  },
  {
    name: '24months',
    id: 24,
    type: 'radio'
  }
];
