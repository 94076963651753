import {
  FETCH_GRADINGS_SUCCESS,
  FETCH_GRADINGS_REQUEST,
  FETCH_GRADINGS_FAILURE,
} from '../actions/gradings';

const INITIAL_STATE = {
  gradingList: [],
  gradingRequest: false,
};

function fetchGradings(state, payload) {
  return {
    ...state,
    gradingList: payload.gradings,
    gradingRequest: false,
  };
}

function fetchGradingsRequest(state) {
  return {
    ...state,
    gradingRequest: true,
  };
}

function fetchGradingsFailure(state) {
  return {
    ...state,
    gradingRequest: false,
  };
}


const actions = {
  [FETCH_GRADINGS_SUCCESS]: fetchGradings,
  [FETCH_GRADINGS_REQUEST]: fetchGradingsRequest,
  [FETCH_GRADINGS_FAILURE]: fetchGradingsFailure,
};

export default (state = INITIAL_STATE, action) => {
  const actionFunc = actions[action.type];

  if (!actionFunc) {
    return state;
  }

  return actionFunc(state, action.payload);
};
